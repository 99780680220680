import { DeviceStatusType } from "api/query";
import i18n from "i18next";

export function deviceStatusesToSelectOptions() {
  const ancestries = Object.keys(DeviceStatusType);

  return ancestries.map(key => ({
    title: i18n.t(`deviceStatusType.${key}`, { ns: "translation" }),
    value: DeviceStatusType[key as keyof typeof DeviceStatusType],
  }));
}
