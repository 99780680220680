import * as React from "react";
import { SVGProps } from "react";
const SvgNavigationEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.53 1.47a.75.75 0 0 1 .148.851l-9 19a.75.75 0 0 1-1.406-.14l-1.89-7.563-7.564-1.89a.75.75 0 0 1-.14-1.406l19.001-9a.75.75 0 0 1 .851.148ZM5.215 10.78l5.967 1.492a.75.75 0 0 1 .546.546l1.491 5.967 7.204-15.208L5.215 10.78Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgNavigationEmpty;
