import { useMachine } from "@xstate/react";
import {
  AncestryType,
  CountryType,
  DeviceType,
  GenderType,
  NationalityType,
  PatientResponseModelListModel,
  PatientsFilterModel,
} from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { SearchQuery, TableContext, tableStateMachine } from "libs/ui";
import { InitialValuesModel, PersonModel, UserRoleType, UserStatusType } from "models/PersonModels";
import { TestMeasurements } from "models/TestModels";
import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import { PatientFilterOptions, PatientsTableColumns } from "utils/createGlobalConfigStore";
import { getCurrentUserData } from "utils/getCurrentUserData";
import { userStatusesToSelectOptions } from "utils/mappers/userStatusesToSelectOptions";
import { FilterSideModalState } from "../Filter/model";
import { BaseTableFilterProps } from "../Table/model";
import { DetailResponseModel } from "../model";

async function getAdminPatientsList(
  context?: TableContext<PatientResponseModelListModel, PatientsFilterModel>,
) {
  const result = await CarnaApiQuery.Admin.getPatients({
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

async function getPartnerPatientsList(
  context?: TableContext<PatientResponseModelListModel, PatientsFilterModel>,
) {
  const { organizationId } = await getCurrentUserData();

  const result = await CarnaApiQuery.Patients.get({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

export type UIPatientsFilters = Omit<
  PatientsFilterModel,
  "beginsWith" | "countries" | "states" | "name" | "userIds"
>;
async function tableFetch(
  context?: TableContext<PatientResponseModelListModel, UIPatientsFilters>,
) {
  const { isAdmin } = await getCurrentUserData();
  return isAdmin ? getAdminPatientsList(context) : getPartnerPatientsList(context);
}

export const makeTableFetchStateMachine = (
  queryParams: SearchQuery<ReturnType<typeof PATIENTS_FILTER_OPTIONS>>,
) => tableStateMachine(tableFetch, queryParams as any);

export function PATIENTS_FILTER_OPTIONS() {
  return {
    hcpIds: [],
    contains: "",
    withDevice: null,
    userStatuses: userStatusesToSelectOptions(),
    organizationIds: [],
  } as const;
}

export type PatientsFilterOptionsKey = keyof ReturnType<typeof PATIENTS_FILTER_OPTIONS>;

type TableService = ReturnType<typeof useMachine<ReturnType<typeof makeTableFetchStateMachine>>>;

export type PatientsTableStateContext = {
  tableService: TableService;
  fetch: () => void;
  filterSideModelState: FilterSideModalState<PatientsFilterOptionsKey>;
};

export interface PatientsTableProps extends BaseTableFilterProps {
  patientsTableStateContext: PatientsTableStateContext;
  appliedColumns: PatientsTableColumns[];
  hcpsFilter?: boolean;
  initialValuesAsProps?: Partial<InitialValuesModel>;
  patientsFilterOptions?: PatientFilterOptions[];
}

export interface PatientsTableFilterProps {
  tableService: TableService;
  filterSideModelState: FilterSideModalState<PatientsFilterOptionsKey>;
  hcpsFilter?: boolean;
}

export interface PatientMeasurementsModel {
  id: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  phone: string;
  street: string;
  city: string;
  country: CountryType;
  state?: number | null;
  zipCode?: string | null;
  organizationId: string;
  organizationName: string;
  isMainOrganization?: boolean | null;
  status: UserStatusType;
  role: UserRoleType;
  rowVersion: string;
  isAccountOwner: boolean;
  deviceId?: string | null;
  deviceName?: string | null;
  deviceSerialNumber?: string | null;
  deviceType?: DeviceType;
  deviceUniqueId?: string | null;
  hcpId?: string | null;
  hcpFirstName?: string | null;
  hcpLastName?: string | null;
  dateOfBirth: string;
  gender: GenderType;
  ancestry?: AncestryType | null;
  weight?: number;
  height?: number;
  nationalityType?: NationalityType | null;
  years: number;
  bmi?: number;
  registryNumber?: string | null;
  latestTestModel?: TestMeasurements | null;
  shortId: number;
}

export interface BodyRowsProps {
  tableService: TableService;
  changePatientStateClick: (patient: DetailResponseModel) => MouseEventHandler<HTMLButtonElement>;
  onDeletePatient: (patient: DetailResponseModel) => MouseEventHandler<HTMLButtonElement>;
  appliedColumns: PatientsTableColumns[];
  currentRightClickContext:
    | [React.MouseEvent<HTMLTableRowElement, MouseEvent>, PersonModel]
    | undefined;
  setCurrentRightClickContext: Dispatch<
    SetStateAction<[React.MouseEvent<HTMLTableRowElement, MouseEvent>, PersonModel] | undefined>
  >;
}
