/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PropertyContextModel } from './PropertyContextModel';
import {
    PropertyContextModelFromJSON,
    PropertyContextModelFromJSONTyped,
    PropertyContextModelToJSON,
} from './PropertyContextModel';

/**
 * 
 * @export
 * @interface AdditionalPropertyDefinitionModel
 */
export interface AdditionalPropertyDefinitionModel {
    /**
     * 
     * @type {{ [key: string]: PropertyContextModel; }}
     * @memberof AdditionalPropertyDefinitionModel
     */
    contexts: { [key: string]: PropertyContextModel; };
    /**
     * 
     * @type {string}
     * @memberof AdditionalPropertyDefinitionModel
     */
    propertyName: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalPropertyDefinitionModel
     */
    typeName: string;
}

/**
 * Check if a given object implements the AdditionalPropertyDefinitionModel interface.
 */
export function instanceOfAdditionalPropertyDefinitionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contexts" in value;
    isInstance = isInstance && "propertyName" in value;
    isInstance = isInstance && "typeName" in value;

    return isInstance;
}

export function AdditionalPropertyDefinitionModelFromJSON(json: any): AdditionalPropertyDefinitionModel {
    return AdditionalPropertyDefinitionModelFromJSONTyped(json, false);
}

export function AdditionalPropertyDefinitionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalPropertyDefinitionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contexts': (mapValues(json['Contexts'], PropertyContextModelFromJSON)),
        'propertyName': json['PropertyName'],
        'typeName': json['TypeName'],
    };
}

export function AdditionalPropertyDefinitionModelToJSON(value?: AdditionalPropertyDefinitionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Contexts': (mapValues(value.contexts, PropertyContextModelToJSON)),
        'PropertyName': value.propertyName,
        'TypeName': value.typeName,
    };
}

