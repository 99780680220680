/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LanguageType } from './LanguageType';
import {
    LanguageTypeFromJSON,
    LanguageTypeFromJSONTyped,
    LanguageTypeToJSON,
} from './LanguageType';
import type { MeasureUnitType } from './MeasureUnitType';
import {
    MeasureUnitTypeFromJSON,
    MeasureUnitTypeFromJSONTyped,
    MeasureUnitTypeToJSON,
} from './MeasureUnitType';
import type { RegionType } from './RegionType';
import {
    RegionTypeFromJSON,
    RegionTypeFromJSONTyped,
    RegionTypeToJSON,
} from './RegionType';
import type { TimeZoneType } from './TimeZoneType';
import {
    TimeZoneTypeFromJSON,
    TimeZoneTypeFromJSONTyped,
    TimeZoneTypeToJSON,
} from './TimeZoneType';

/**
 * 
 * @export
 * @interface UpdateUserPreferencesRequestModel
 */
export interface UpdateUserPreferencesRequestModel {
    /**
     * 
     * @type {LanguageType}
     * @memberof UpdateUserPreferencesRequestModel
     */
    languageType: LanguageType;
    /**
     * 
     * @type {RegionType}
     * @memberof UpdateUserPreferencesRequestModel
     */
    regionType: RegionType;
    /**
     * 
     * @type {TimeZoneType}
     * @memberof UpdateUserPreferencesRequestModel
     */
    timeZoneType: TimeZoneType;
    /**
     * 
     * @type {MeasureUnitType}
     * @memberof UpdateUserPreferencesRequestModel
     */
    measureUnitType: MeasureUnitType;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPreferencesRequestModel
     */
    rowVersion: string;
}

/**
 * Check if a given object implements the UpdateUserPreferencesRequestModel interface.
 */
export function instanceOfUpdateUserPreferencesRequestModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "languageType" in value;
    isInstance = isInstance && "regionType" in value;
    isInstance = isInstance && "timeZoneType" in value;
    isInstance = isInstance && "measureUnitType" in value;
    isInstance = isInstance && "rowVersion" in value;

    return isInstance;
}

export function UpdateUserPreferencesRequestModelFromJSON(json: any): UpdateUserPreferencesRequestModel {
    return UpdateUserPreferencesRequestModelFromJSONTyped(json, false);
}

export function UpdateUserPreferencesRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserPreferencesRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'languageType': LanguageTypeFromJSON(json['LanguageType']),
        'regionType': RegionTypeFromJSON(json['RegionType']),
        'timeZoneType': TimeZoneTypeFromJSON(json['TimeZoneType']),
        'measureUnitType': MeasureUnitTypeFromJSON(json['MeasureUnitType']),
        'rowVersion': json['RowVersion'],
    };
}

export function UpdateUserPreferencesRequestModelToJSON(value?: UpdateUserPreferencesRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'LanguageType': LanguageTypeToJSON(value.languageType),
        'RegionType': RegionTypeToJSON(value.regionType),
        'TimeZoneType': TimeZoneTypeToJSON(value.timeZoneType),
        'MeasureUnitType': MeasureUnitTypeToJSON(value.measureUnitType),
        'RowVersion': value.rowVersion,
    };
}

