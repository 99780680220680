import "./Countries.scss";
import { SelectOptionCountry } from "components/SelectOptionCountry";
import i18n from "i18next";
import { CountryCode, getCountries } from "libphonenumber-js";
import { SelectFilter, SelectFilterResult, CountryFlag } from "libs/ui";
import { autoPlacement, autoUpdate } from "@floating-ui/react-dom";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Option } from "libs/ui/Select/Select.model";
interface CountriesProps {
  value: CountryCode;
  onCountrySelect: (value: CountryCode) => void;
}

function RenderSelectedComponent(initialValue: CountryCode, props?: Option<CountryCode>) {
  return (
    <SelectFilterResult
      data-testval={JSON.stringify({ title: props?.value ?? initialValue })}
      hasValue={!!(props ?? initialValue)}
    >
      <CountryFlag
        countryCode={props?.value ?? initialValue}
        countryName={props?.title ?? i18n.t(`countries.${initialValue}`, { ns: "nationalities" })}
        data-testid="selected-country-flag"
      />
    </SelectFilterResult>
  );
}

export function Countries({ value, onCountrySelect }: Readonly<CountriesProps>) {
  const { t: tCountries } = useTranslation("nationalities", { keyPrefix: "countries" });
  const setRenderSelected = useCallback(
    (initialValue: CountryCode) => (props?: Option<CountryCode>) =>
      RenderSelectedComponent(initialValue, props),
    [],
  );

  const setRenderOption = useCallback(
    (
      props?: Readonly<{
        value: Option<CountryCode>;
        isActive: boolean;
      }>,
    ) =>
      props?.value ? (
        <SelectOptionCountry
          title={props.value.title}
          countryCode={props.value.value}
          isActive={props.isActive}
        />
      ) : null,
    [],
  );

  const countriesOptions = useMemo((): Option<CountryCode>[] => {
    return getCountries()
      .map(countryCode => ({
        title: tCountries(`${countryCode}`),
        value: countryCode,
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [tCountries]);

  return (
    <SelectFilter<CountryCode>
      className="Countries"
      data-testid="phone-number-countries"
      options={countriesOptions}
      onSelect={(code?: CountryCode) => {
        code && onCountrySelect(code);
      }}
      value={value}
      dropdownFloatingProps={{
        middleware: [autoPlacement({ alignment: "start" })],
        whileElementsMounted: autoUpdate,
      }}
      renderSelected={setRenderSelected(value)}
      renderOption={setRenderOption}
    />
  );
}
