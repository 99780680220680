import { UserGraphModel } from "api/query";
import CarnaLineChart from "components/Chart/CarnaChart/LineChart";
import { useGetMaxUrineCreatinineValueFromRuleEngineUnit } from "components/Chart/hooks/useGetMaxUrineCreatinineValueFromRuleEngineUnit";
import { useGetUrineCreatinineNormalRange } from "components/Chart/hooks/useGetUrineCreatinineNormalRange";
import { ECOption, Formatters } from "components/Chart/model";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useCallback } from "react";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { getOptions as getInitialOptions } from "./model";
import { useHideClosestTick } from "./useHideClosestTick";
// const CarnaLineChart = React.lazy(() => import("components/Chart/CarnaChart/LineChart"));

export function UrineCreatinine() {
  const { appConfig } = useGlobalConfigContext();

  const urineCreatinineNormalRange = useGetUrineCreatinineNormalRange();
  const maxUrineCreatinineRange = useGetMaxUrineCreatinineValueFromRuleEngineUnit();
  const { urineCreatinineUnit } = useGetUserPreferredMeasurementUnits();

  const getOptions = useCallback(
    (formatters: Formatters, graphUser: UserGraphModel): ECOption =>
      getInitialOptions({
        formatters,
        data: graphUser,
        measurementUnit: urineCreatinineUnit,
        urineCreatinineNormalRange: urineCreatinineNormalRange,
        maxRange: maxUrineCreatinineRange,
        showTrending: appConfig?.components.graphs.Patient.Index.showTrending,
      }),
    [
      appConfig?.components.graphs.Patient.Index.showTrending,
      maxUrineCreatinineRange,
      urineCreatinineNormalRange,
      urineCreatinineUnit,
    ],
  );

  const afterInit = useHideClosestTick();

  return (
    <CarnaLineChart
      showTrend={appConfig?.components.graphs.Patient.Index.showTrending}
      dataType="UrineCreatinine"
      afterInit={afterInit}
      unitType="urineCreatinine"
      className="UrineCreatinine"
      getOptions={getOptions}
      measurementUnit={urineCreatinineUnit}
    />
  );
}

export default UrineCreatinine;
