import { CountryType } from "api/config_service";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useCallback } from "react";
import { Enum } from "utils/createGlobalConfigStore";

export function useStatesPerCountry() {
  const { appConfig } = useGlobalConfigContext();

  const getStatesPerCountry = useCallback(
    (country?: CountryType): Enum[] =>
      (appConfig?.general?.custom?.enums as any)?.[`${country}_States`] ?? null,
    [appConfig?.general.custom.enums],
  );

  return [getStatesPerCountry] as const;
}
