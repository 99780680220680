import classNames from "classnames";
import { getCellStatusData } from "pages/PatientsPage/Details/Overview/HeatMapWidget/HeatMapTable/HeatCell/helper";
import { HeatCellProps } from "../model";
import "./HeatResultCell.scss";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";

export function HeatResultCell({ rowKey, cellKey, small }: HeatCellProps) {
  const { color } = getCellStatusData(cellKey, rowKey);
  const { numberFormatterWithDash } = useLocaleNumberFormatter();

  return (
    <td
      className={classNames("HeatResultCell", `HeatResultCell--${color}`, {
        "HeatResultCell--small": small,
      })}
    >
      {/* !!! mock */}
      {numberFormatterWithDash({ value: 256 })}
    </td>
  );
}
