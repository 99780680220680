import classNames from "classnames";
import { isKeyboardClickKey } from "libs/ui/utils";
import React, { ReactNode } from "react";
import "./SelectOption.scss";
import { Icon, IconType } from "../Icon";

interface RenderFunctionProps<T> {
  value: T;
  isActive: boolean;
}
export interface SelectOptionProps<T> {
  title: string;
  isActive: boolean;
  value: T;
  onSelect?: (
    value: T,
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
  render?: ({ value, isActive }: RenderFunctionProps<T>) => ReactNode;
  /**
   * don't use this if you use render function
   */
  icon?: IconType;
}

export function SelectOption<T>({
  title,
  value,
  isActive,
  onSelect,
  render,
  icon,
}: Readonly<SelectOptionProps<T>>) {
  const setOption = (
    newValue: T,
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (!onSelect) {
      return;
    }

    return onSelect(newValue, e);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (isKeyboardClickKey(e.key)) {
      e.preventDefault();
      setOption(value, e);
    }
  };

  if (icon !== undefined && render !== undefined) {
    throw new Error("You can't use both render and icon props");
  }

  return (
    <div
      className={classNames("UI-Components SelectOption", isActive && "SelectOption--selected")}
      role="option"
      aria-selected={isActive ? "true" : "false"}
      onClick={e => setOption(value, e)}
      onKeyDown={onKeyDown}
      title={title}
      data-testid={`SelectOption-${title.trim()}`}
      tabIndex={0}
    >
      {icon ? <Icon icon={icon} className="SelectOption__icon" /> : null}

      {render ? render({ value, isActive }) : title}

      {isActive && !render ? <Icon icon={"Check"} className="SelectOption__selectedIcon" /> : null}
    </div>
  );
}
