/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasePatientWidgetDataResponseModel } from './BasePatientWidgetDataResponseModel';
import {
    BasePatientWidgetDataResponseModelFromJSON,
    BasePatientWidgetDataResponseModelFromJSONTyped,
    BasePatientWidgetDataResponseModelToJSON,
} from './BasePatientWidgetDataResponseModel';
import type { CkdRisk } from './CkdRisk';
import {
    CkdRiskFromJSON,
    CkdRiskFromJSONTyped,
    CkdRiskToJSON,
} from './CkdRisk';
import type { TestWidgetDataModel } from './TestWidgetDataModel';
import {
    TestWidgetDataModelFromJSON,
    TestWidgetDataModelFromJSONTyped,
    TestWidgetDataModelToJSON,
} from './TestWidgetDataModel';

/**
 * 
 * @export
 * @interface HeatMapPatientWidgetResponseModel
 */
export interface HeatMapPatientWidgetResponseModel extends BasePatientWidgetDataResponseModel {
    /**
     * 
     * @type {CkdRisk}
     * @memberof HeatMapPatientWidgetResponseModel
     */
    risk?: CkdRisk;
    /**
     * 
     * @type {TestWidgetDataModel}
     * @memberof HeatMapPatientWidgetResponseModel
     */
    egfrTest?: TestWidgetDataModel;
    /**
     * 
     * @type {TestWidgetDataModel}
     * @memberof HeatMapPatientWidgetResponseModel
     */
    uacrTest?: TestWidgetDataModel;
    /**
     * 
     * @type {TestWidgetDataModel}
     * @memberof HeatMapPatientWidgetResponseModel
     */
    semiQuantitativeUacrTest?: TestWidgetDataModel;
    /**
     * 
     * @type {string}
     * @memberof HeatMapPatientWidgetResponseModel
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HeatMapPatientWidgetResponseModel
     */
    previous?: string | null;
}

/**
 * Check if a given object implements the HeatMapPatientWidgetResponseModel interface.
 */
export function instanceOfHeatMapPatientWidgetResponseModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HeatMapPatientWidgetResponseModelFromJSON(json: any): HeatMapPatientWidgetResponseModel {
    return HeatMapPatientWidgetResponseModelFromJSONTyped(json, false);
}

export function HeatMapPatientWidgetResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeatMapPatientWidgetResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...BasePatientWidgetDataResponseModelFromJSONTyped(json, ignoreDiscriminator),
        'risk': !exists(json, 'Risk') ? undefined : CkdRiskFromJSON(json['Risk']),
        'egfrTest': !exists(json, 'EgfrTest') ? undefined : TestWidgetDataModelFromJSON(json['EgfrTest']),
        'uacrTest': !exists(json, 'UacrTest') ? undefined : TestWidgetDataModelFromJSON(json['UacrTest']),
        'semiQuantitativeUacrTest': !exists(json, 'SemiQuantitativeUacrTest') ? undefined : TestWidgetDataModelFromJSON(json['SemiQuantitativeUacrTest']),
        'next': !exists(json, 'Next') ? undefined : json['Next'],
        'previous': !exists(json, 'Previous') ? undefined : json['Previous'],
    };
}

export function HeatMapPatientWidgetResponseModelToJSON(value?: HeatMapPatientWidgetResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...BasePatientWidgetDataResponseModelToJSON(value),
        'Risk': CkdRiskToJSON(value.risk),
        'EgfrTest': TestWidgetDataModelToJSON(value.egfrTest),
        'UacrTest': TestWidgetDataModelToJSON(value.uacrTest),
        'SemiQuantitativeUacrTest': TestWidgetDataModelToJSON(value.semiQuantitativeUacrTest),
        'Next': value.next,
        'Previous': value.previous,
    };
}

