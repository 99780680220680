import { PropsWithChildren } from "react";
import { CommittedToPrivacy } from "./CommittedToPrivacy";
import "./PrivacyPolicyTab.scss";

export function PrivacyPolicyTab({ children }: PropsWithChildren<{}>) {
  return (
    <div className="PrivacyPolicyTab">
      {children}
      <CommittedToPrivacy />
    </div>
  );
}
