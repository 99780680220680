import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom";
import "./ModalBase.scss";

export interface ModalProps {
  show: boolean;
  closeModal?: {
    onClick: () => void;
    disabled?: boolean;
  };
  title?: string;
  className?: string;
  modalRoot?: string;
}

export class ModalBase extends React.Component<PropsWithChildren<{ className?: string }>> {
  private el: HTMLDivElement;
  private modalRoot: HTMLElement;
  constructor(props: PropsWithChildren<ModalProps>) {
    const { children, ...rest } = props;

    super(props);
    this.el = document.createElement("div");
    this.el.className = props.className ?? "";
    this.el.ariaModal = "true";
    this.el.setAttribute("role", "dialog");

    // custom data- attributes mapping
    Object.entries(rest).forEach(([name, value]) => {
      if (/data-/.test(name)) {
        this.el.setAttribute(name, value as string);
      }
    });

    this.modalRoot = document.querySelector(props.modalRoot ?? "body")!;
  }
  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.modalRoot.appendChild(this.el);
  }

  componentDidUpdate(prevProps: { className: string }) {
    if (prevProps.className !== this.props.className) {
      this.el.className = this.props.className ?? "";
    }
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
  }
  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export function Modal({ show, className, children, ...rest }: PropsWithChildren<ModalProps>) {
  if (!show) {
    return null;
  }

  return (
    <ModalBase className={classNames("UI-Components Modal", className)} {...rest}>
      {children}
    </ModalBase>
  );
}
