import * as React from "react";
import { SVGProps } from "react";
const SvgMainOrganization = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.5 12.5a.5.5 0 0 0 0 1H21a.5.5 0 0 0 0-1h-1.5ZM19.5 14.5a.5.5 0 0 0 0 1H21a.5.5 0 0 0 0-1h-1.5ZM9.5 7.5A.5.5 0 0 1 10 7h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM10 10a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4ZM2.5 13a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5ZM2.5 15a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 5a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v4H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-4V5Zm0 14.5h4a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-4v9ZM16.5 5a.5.5 0 0 0-.5-.5H8a.5.5 0 0 0-.5.5v14.5h2V17a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v2.5H16a.5.5 0 0 0 .5-.5V5ZM6 10.5H2a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h4v-9Zm7 7v2h-2v-2h2Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMainOrganization;
