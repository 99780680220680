import classNames from "classnames";
import { MIN_LENGTH_PASSWORD_CHAR_COUNT } from "config/const";
import { Icon } from "libs/ui/Icon";
import { useTranslation } from "react-i18next";
import "./PasswordStrength.scss";

interface PasswordStrengthProps {
  minLength: boolean;
  atLeastOneUppercase: boolean;
  atLeastOneNumber: boolean;
  atLeastOneSpecial: boolean;
}

export function PasswordStrength({
  atLeastOneNumber,
  atLeastOneSpecial,
  atLeastOneUppercase,
  minLength,
}: PasswordStrengthProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Tooltip.Password" });

  return (
    <ul className="PasswordStrength">
      <li
        className={classNames("PasswordStrength__item", {
          "PasswordStrength__item--valid": minLength,
        })}
      >
        <Icon icon={"CircleCheck"} />
        <span>{t("min_num_char", { value: MIN_LENGTH_PASSWORD_CHAR_COUNT })}</span>
      </li>
      <li
        className={classNames("PasswordStrength__item", {
          "PasswordStrength__item--valid": atLeastOneUppercase,
        })}
      >
        <Icon icon={"CircleCheck"} />
        <span>{t("at_least_one_capital")}</span>
      </li>
      <li
        className={classNames("PasswordStrength__item", {
          "PasswordStrength__item--valid": atLeastOneNumber,
        })}
      >
        <Icon icon={"CircleCheck"} />
        <span>{t("at_least_one_number")}</span>
      </li>
      <li
        className={classNames("PasswordStrength__item", {
          "PasswordStrength__item--valid": atLeastOneSpecial,
        })}
      >
        <Icon icon={"CircleCheck"} />
        <span>{t("at_least_one_special")}</span>
      </li>
    </ul>
  );
}
