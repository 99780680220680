import * as React from "react";
import { SVGProps } from "react";
const SvgSkipForward = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.469 3.414A.75.75 0 0 0 4.25 4v16a.75.75 0 0 0 1.219.586l10-8a.75.75 0 0 0 0-1.172l-10-8ZM13.799 12 5.75 18.44V5.56L13.8 12Z"
      fill="currentColor"
    />
    <path d="M19.75 5a.75.75 0 0 0-1.5 0v14a.75.75 0 0 0 1.5 0V5Z" fill="currentColor" />
  </svg>
);
export default SvgSkipForward;
