import classNames from "classnames";
import { DetailedHTMLProps, PropsWithChildren } from "react";
import { Icon, IconType } from "../Icon";
import "./ActionButton.scss";

type HTMLButtonProps = DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type ActionType = "Active" | "Deactivated" | "Deleted" | "Invited" | "NoAccess";

interface ActionButtonProps extends HTMLButtonProps {
  iconType?: IconType;
  fullWidth?: boolean;
  actionType: ActionType;
}

export function ActionButton({
  className,
  iconType,
  children,
  fullWidth,
  actionType,
  ...props
}: PropsWithChildren<ActionButtonProps>) {
  return (
    <button
      {...props}
      data-testid="ActionButton"
      data-action-type={actionType}
      className={classNames(
        "UI-Components ActionButton",
        className,
        `ActionButton--${actionType}`,
        {
          "ActionButton--fullWidth": fullWidth,
        },
      )}
    >
      {iconType ? <Icon icon={iconType} className="ActionButton__icon" /> : null}
      {children}
    </button>
  );
}
