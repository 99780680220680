import { APIProvider, Map as GoogleMap } from "@vis.gl/react-google-maps";
import "utils/helpers/google";

import { PickingInfo, WebMercatorViewport } from "@deck.gl/core";
import DeckGL, { DeckGLRef } from "@deck.gl/react";

import type { IconLayerProps } from "@deck.gl/layers";

import { bbox, center, point } from "@turf/turf";
import { GOOGLE_MAP_API_KEY, GOOGLE_MAP_ID } from "config/const";
import { isLoaded } from "models/loadable";
import {
  MapData,
  useDashboardMapReportContext,
} from "pages/DashboardPage/DashboardMap/DashboardMapReportDataContext";
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MapTooltip } from "./MapTooltip";
import IconClusterLayer from "./icon-layer-cluster";
import { CustomMapControls, CustomMapControlsRef } from "components/CustomMapControls";

export type IconLayerPickingInfo<DataT> = PickingInfo<DataT, { objects?: DataT[] }>;

function getCenterAndZoom(mapData: MapData[], containerRef: RefObject<HTMLDivElement>) {
  // Note order: longitude, latitude.
  const features = mapData.map(mData => point(mData.coordinates));

  const bounds = bbox({
    type: "FeatureCollection",
    features,
  });

  const centerPoint = center({
    type: "FeatureCollection",
    features,
  });
  const [centerLongitude, centerLatitude] = centerPoint.geometry.coordinates;

  if (!containerRef.current) {
    throw new Error("No container ref");
  }

  const containerSize = containerRef.current?.getBoundingClientRect();
  const { /* centerLongitude, centerLatitude, */ zoom } = new WebMercatorViewport({
    width: containerSize.width === 0 ? 1000 : containerSize.width,
    height: containerSize.height === 0 ? 1000 : containerSize.height,
  }).fitBounds(
    [
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]],
    ],
    { padding: { top: 100, bottom: 100, left: 100, right: 100 } },
  );

  return {
    centerLongitude,
    centerLatitude,
    bounds: {
      south: bounds[1],
      west: bounds[0],
      north: bounds[3],
      east: bounds[2],
    },
    zoom,
  };
}
interface CarnaMapProps {
  containerRef: RefObject<HTMLDivElement>;
}

export function CarnaMap({ containerRef }: CarnaMapProps) {
  const deckGlRef = useRef<DeckGLRef<any> | null>(null);
  const controlRef = useRef<CustomMapControlsRef>(null);

  const [hoverInfo, setHoverInfo] = useState<PickingInfo<MapData> | null>(null);
  const [viewState, setViewState] = useState<{
    longitude: number;
    latitude: number;
    zoom: number;
  } | null>(null);

  const [bounds, setBounds] = useState<
    | {
        south: number;
        west: number;
        north: number;
        east: number;
      }
    | null
    | undefined
  >(null);

  const { reportData } = useDashboardMapReportContext();

  // ! Filter out coordinates which has no value
  const filteredData = useMemo(
    () =>
      isLoaded(reportData.MapData)
        ? reportData.MapData.value?.filter(
            data => data.coordinates && data.coordinates[0] !== 0 && data.coordinates[1] !== 0,
          ) ?? []
        : [],
    [reportData.MapData],
  );

  const expandTooltip = useCallback((info: PickingInfo) => {
    if (info.object?.cluster) {
      return;
    }

    if (info.picked) {
      setHoverInfo(info);
    } else {
      setHoverInfo(null);
    }
  }, []);

  const layerProps: IconLayerProps<MapData> = useMemo(
    () => ({
      id: "IconLayer",
      data: filteredData,
      alphaCutoff: 0.9,
      getPosition: d => d.coordinates as [number, number],
      pickable: true,
      onClick: expandTooltip,
      onHover: expandTooltip,
    }),
    [expandTooltip, filteredData],
  );

  const iconClusterLayer = useMemo(
    () =>
      new IconClusterLayer({
        ...layerProps,
        id: "icon-cluster",
      }),
    [layerProps],
  );

  useEffect(() => {
    if (isLoaded(reportData.MapData)) {
      if (filteredData.length === 0) {
        setViewState({
          longitude: 0,
          latitude: 0,
          zoom: 1,
        });
        setBounds(undefined as any);
        return;
      }

      const { centerLongitude, centerLatitude, zoom, bounds } = getCenterAndZoom(
        filteredData,
        containerRef,
      );

      /**
       * ! This works until we have viewState !== null ? in render
       */
      setViewState({
        longitude: centerLongitude,
        latitude: centerLatitude,
        zoom,
      });

      setBounds(bounds);
    }
  }, [containerRef, filteredData, reportData.MapData]);

  const setCurrentLocation = useCallback((latitude: number, longitude: number) => {
    setViewState(prev => ({
      ...prev!,
      latitude,
      longitude,
      zoom: 16,
    }));
  }, []);

  const setZoom = useCallback((zoom: number) => {
    setViewState(prev => ({
      ...prev!,
      zoom,
    }));
  }, []);

  const onViewStateChange = useCallback((e: any) => {
    setViewState(e.viewState);
  }, []);

  const layers = useMemo(() => [iconClusterLayer], [iconClusterLayer]);

  // viewState changes - https://github.com/visgl/deck.gl/issues/4550
  return viewState !== null && bounds !== null ? (
    <APIProvider apiKey={GOOGLE_MAP_API_KEY}>
      <DeckGL
        ref={deckGlRef}
        viewState={viewState}
        onViewStateChange={onViewStateChange}
        controller
        layers={layers}
      >
        <GoogleMap mapId={GOOGLE_MAP_ID} defaultBounds={bounds} />
        <CustomMapControls
          ref={controlRef}
          deckGl
          deckGlViewState={viewState}
          setZoom={setZoom}
          setCurrentLocation={setCurrentLocation}
        />

        {hoverInfo ? <MapTooltip info={hoverInfo} /> : null}
      </DeckGL>
    </APIProvider>
  ) : null;
}
