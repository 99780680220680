import * as React from "react";
import { SVGProps } from "react";
const SvgCrop = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#crop_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.88 1.006a.75.75 0 1 0-1.5-.012l-.038 4.348-4.348.038a.75.75 0 1 0 .012 1.5l4.323-.037-.079 9.15V16A2.75 2.75 0 0 0 8 18.75h9.25V23a.75.75 0 0 0 1.5 0v-4.25H23a.75.75 0 0 0 0-1.5h-4.25V8A2.75 2.75 0 0 0 16 5.25l-9.157.08.037-4.324ZM6.83 6.83l-.08 9.173A1.25 1.25 0 0 0 8 17.25h9.25V8a1.25 1.25 0 0 0-1.247-1.25l-9.173.08Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="crop_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCrop;
