import { UserGraphModel } from "api/query/models/UserGraphModel";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useCallback } from "react";
import { getOptions } from "./model";
import CarnaLineChart from "components/Chart/CarnaChart/LineChart";
import { useGetBMIRanges } from "utils/hooks/useGetBMIRanges";
import { Formatters } from "components/Chart/model";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
// const CarnaLineChart = React.lazy(() => import("components/Chart/CarnaChart/LineChart"));

export function BMI() {
  const { appConfig } = useGlobalConfigContext();
  const { orderedBMIStageRanges } = useGetBMIRanges();
  const { globalPreference } = useGlobalPreferenceContext();

  const getOptionsWithTrend = useCallback(
    (formatters: Formatters, graphUser: UserGraphModel) => {
      return getOptions(
        formatters,
        graphUser,
        orderedBMIStageRanges,
        appConfig?.components.graphs.Patient.Index.showTrending,
        globalPreference?.regionType,
      );
    },
    [
      appConfig?.components.graphs.Patient.Index.showTrending,
      globalPreference?.regionType,
      orderedBMIStageRanges,
    ],
  );

  return (
    <CarnaLineChart
      showTrend={appConfig?.components.graphs.Patient.Index.showTrending}
      dataType="BMI"
      unitType="bmi"
      className="BMI"
      getOptions={getOptionsWithTrend}
    />
  );
}

export default BMI;
