/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MeasurementsModel } from './MeasurementsModel';
import {
    MeasurementsModelFromJSON,
    MeasurementsModelFromJSONTyped,
    MeasurementsModelToJSON,
} from './MeasurementsModel';

/**
 * 
 * @export
 * @interface LaboratorySectionModel
 */
export interface LaboratorySectionModel {
    /**
     * 
     * @type {MeasurementsModel}
     * @memberof LaboratorySectionModel
     */
    measurements: MeasurementsModel;
}

/**
 * Check if a given object implements the LaboratorySectionModel interface.
 */
export function instanceOfLaboratorySectionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "measurements" in value;

    return isInstance;
}

export function LaboratorySectionModelFromJSON(json: any): LaboratorySectionModel {
    return LaboratorySectionModelFromJSONTyped(json, false);
}

export function LaboratorySectionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaboratorySectionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measurements': MeasurementsModelFromJSON(json['Measurements']),
    };
}

export function LaboratorySectionModelToJSON(value?: LaboratorySectionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Measurements': MeasurementsModelToJSON(value.measurements),
    };
}

