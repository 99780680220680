/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WidgetBusinessRulesModel } from './WidgetBusinessRulesModel';
import {
    WidgetBusinessRulesModelFromJSON,
    WidgetBusinessRulesModelFromJSONTyped,
    WidgetBusinessRulesModelToJSON,
} from './WidgetBusinessRulesModel';

/**
 * 
 * @export
 * @interface BusinessRulesModel
 */
export interface BusinessRulesModel {
    /**
     * 
     * @type {WidgetBusinessRulesModel}
     * @memberof BusinessRulesModel
     */
    widgetRules?: WidgetBusinessRulesModel;
}

/**
 * Check if a given object implements the BusinessRulesModel interface.
 */
export function instanceOfBusinessRulesModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BusinessRulesModelFromJSON(json: any): BusinessRulesModel {
    return BusinessRulesModelFromJSONTyped(json, false);
}

export function BusinessRulesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessRulesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'widgetRules': !exists(json, 'WidgetRules') ? undefined : WidgetBusinessRulesModelFromJSON(json['WidgetRules']),
    };
}

export function BusinessRulesModelToJSON(value?: BusinessRulesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'WidgetRules': WidgetBusinessRulesModelToJSON(value.widgetRules),
    };
}

