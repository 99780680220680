import * as React from "react";
import { SVGProps } from "react";
const SvgAttach = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.78 2.138c-.862 0-1.69.342-2.3.952l-9.19 9.19a5.253 5.253 0 0 0 7.43 7.43l9.19-9.19a.75.75 0 1 1 1.06 1.06l-9.19 9.19a6.753 6.753 0 1 1-9.55-9.55l9.19-9.19a4.752 4.752 0 1 1 6.72 6.72l-9.2 9.19a2.751 2.751 0 0 1-3.89-3.89l8.49-8.48a.75.75 0 0 1 1.06 1.06l-8.49 8.48a1.252 1.252 0 0 0 1.77 1.77l9.2-9.19a3.252 3.252 0 0 0-2.3-5.552Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAttach;
