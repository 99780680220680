import { useTranslation } from "react-i18next";
import { SingleReportDataProps } from "./../model";
import { Icon } from "libs/ui/Icon";
import "./SingleReportData.scss";
import { SingleReportStatus } from "./SingleReportStatus";

export function SingleReportData({
  reportId,
  reportType,
  status,
  onReportDownload,
}: SingleReportDataProps) {
  const { t } = useTranslation("translation", { keyPrefix: "ReportPage.SingleReport.Data" });

  return (
    <div className="SingleReportData">
      <p className="SingleReportData__title">
        <Icon icon="Results" />
        {t("title", { reportId })}
      </p>
      <p className="SingleReportData__type">{t(`type${reportType}`)}</p>

      <SingleReportStatus status={status} onReportDownload={onReportDownload} />
    </div>
  );
}
