import { RadioOptions } from "libs/ui";
import { withDeviceToSelectOptions } from "utils/mappers/withDeviceToSelectOptions";

interface AssignedDeviceFilterProps {
  value?: boolean | null;
  onSelect: (value: boolean | null) => void;
}

export function AssignedDeviceFilter({ value, onSelect }: AssignedDeviceFilterProps) {
  const options = withDeviceToSelectOptions();

  return (
    <div className="FilterSection">
      <RadioOptions value={value} options={options} onSelect={onSelect} />
    </div>
  );
}
