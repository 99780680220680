/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { HeatMapPatientWidgetResponseModel } from './HeatMapPatientWidgetResponseModel';
import {
    instanceOfHeatMapPatientWidgetResponseModel,
    HeatMapPatientWidgetResponseModelFromJSON,
    HeatMapPatientWidgetResponseModelFromJSONTyped,
    HeatMapPatientWidgetResponseModelToJSON,
} from './HeatMapPatientWidgetResponseModel';
import type { MeasurementPatientWidgetDataModel } from './MeasurementPatientWidgetDataModel';
import {
    instanceOfMeasurementPatientWidgetDataModel,
    MeasurementPatientWidgetDataModelFromJSON,
    MeasurementPatientWidgetDataModelFromJSONTyped,
    MeasurementPatientWidgetDataModelToJSON,
} from './MeasurementPatientWidgetDataModel';
import type { PatientInfoWidgetDataModel } from './PatientInfoWidgetDataModel';
import {
    instanceOfPatientInfoWidgetDataModel,
    PatientInfoWidgetDataModelFromJSON,
    PatientInfoWidgetDataModelFromJSONTyped,
    PatientInfoWidgetDataModelToJSON,
} from './PatientInfoWidgetDataModel';

/**
 * @type PatientWidgetResponseModelData
 * 
 * @export
 */
export type PatientWidgetResponseModelData = HeatMapPatientWidgetResponseModel | MeasurementPatientWidgetDataModel | PatientInfoWidgetDataModel;

export function PatientWidgetResponseModelDataFromJSON(json: any): PatientWidgetResponseModelData {
    return PatientWidgetResponseModelDataFromJSONTyped(json, false);
}

export function PatientWidgetResponseModelDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientWidgetResponseModelData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...HeatMapPatientWidgetResponseModelFromJSONTyped(json, true), ...MeasurementPatientWidgetDataModelFromJSONTyped(json, true), ...PatientInfoWidgetDataModelFromJSONTyped(json, true) };
}

export function PatientWidgetResponseModelDataToJSON(value?: PatientWidgetResponseModelData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfHeatMapPatientWidgetResponseModel(value)) {
        return HeatMapPatientWidgetResponseModelToJSON(value as HeatMapPatientWidgetResponseModel);
    }
    if (instanceOfMeasurementPatientWidgetDataModel(value)) {
        return MeasurementPatientWidgetDataModelToJSON(value as MeasurementPatientWidgetDataModel);
    }
    if (instanceOfPatientInfoWidgetDataModel(value)) {
        return PatientInfoWidgetDataModelToJSON(value as PatientInfoWidgetDataModel);
    }

    return {};
}

