import { useMachine } from "@xstate/react";
import { HcpResponseModelListModel, HcpsFilterModel } from "api/query";
import { TabModalControl } from "components/TabModalControl";
import { CarnaApiQuery } from "config/apiQuery";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { SearchQuery, TableContext, tableStateMachine } from "libs/ui";
import { mapTableQueryParamsFromURL, useTableURLParams } from "libs/ui/Table/utils";
import { useOrganizationDetailsServiceContext } from "pages/Settings/Organizations/Details/Context";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { RouteParams } from "router";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { HCPTable } from "../common/HCPTable";
import { HCPsFilterOptionsKey, HCPs_FILTER_OPTIONS } from "../common/HCPTable/model";
import { useFilterModal } from "../common/hooks";
import { getFilterOptionsPerConfiguration } from "../utils";

async function fetchHCPs(
  organizationId: string,
  context?: TableContext<HcpResponseModelListModel, HcpsFilterModel>,
) {
  const result = await CarnaApiQuery.Hcps.get({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

const makeTableFetchStateMachine = (
  organizationId: string,
  queryParams: SearchQuery<ReturnType<typeof HCPs_FILTER_OPTIONS>>,
) =>
  tableStateMachine(
    (context?: TableContext<HcpResponseModelListModel, HcpsFilterModel>) =>
      fetchHCPs(organizationId, context),
    queryParams as any,
  );

export function HCPsPerOrganizationTable() {
  const { organizationId = "" } = useParams<RouteParams["organizationDetails"]>();

  const [queryParams] = useTableURLParams<ReturnType<typeof HCPs_FILTER_OPTIONS>>();
  const tableMachine = useMemo(
    () =>
      makeTableFetchStateMachine(
        organizationId,
        mapTableQueryParamsFromURL<ReturnType<typeof HCPs_FILTER_OPTIONS>>(queryParams),
      ),
    [queryParams, organizationId],
  );

  const tableService = useMachine(tableMachine);
  const [state, send] = tableService;
  const { appConfig } = useGlobalConfigContext();

  const { detailsService } = useOrganizationDetailsServiceContext();
  const [detailsState] = detailsService;

  const filterSideModelState = useFilterModal<HCPsFilterOptionsKey>(
    getFilterOptionsPerConfiguration(
      HCPs_FILTER_OPTIONS(),
      NotNullOrUndefined(appConfig?.components.tables.Hcp.HcpPerOrganization.filterOptions),
    ),
  );

  const onFilterToggle = useCallback(
    () => filterSideModelState.openFilterModal(),
    [filterSideModelState],
  );

  const onSearchChange = (value?: string) =>
    send({ type: "UPDATE_FILTERS", value: { ...state.context.filters, contains: value } });

  const updateQuery = useCallback(() => {
    send({ type: "UPDATE_QUERY", value: mapTableQueryParamsFromURL(queryParams) });
  }, [send, queryParams]);

  useEffect(() => {
    updateQuery();
  }, [updateQuery]);

  const fetch = useCallback(() => {
    send({ type: "FETCH" });
  }, [send]);

  useEffect(() => {
    if (state.matches("init")) {
      fetch();
    }
  }, [fetch, state]);

  return detailsState.matches("entity.loaded") ? (
    <HCPTable
      appliedColumns={NotNullOrUndefined(
        appConfig?.components.tables.Hcp.HcpPerOrganization.columnList,
      )}
      HCPTableStateContext={{ tableService, filterSideModelState, fetch }}
      onFilterToggle={onFilterToggle}
      searchField={{
        onChange: onSearchChange,
        value: state.context.filters?.contains ?? "",
      }}
      addButton={
        <TabModalControl
          variant="HCP"
          onSuccessCb={fetch}
          initialValuesAsProps={{
            organizationId: detailsState.context.data?.id,
            organizationName: detailsState.context.data?.name,
          }}
        />
      }
      hcpFilterOptions={appConfig?.components.tables.Hcp.HcpPerOrganization.filterOptions}
      initialValuesAsProps={{
        organizationId: detailsState.context.data?.id,
        organizationName: detailsState.context.data?.name,
      }}
    />
  ) : null;
}
