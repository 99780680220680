import * as React from "react";
import { SVGProps } from "react";
const SvgHeartRate = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.949 19.401a2.484 2.484 0 0 0 2.102 0c.982-.462 2.9-1.453 4.708-2.897 2.007-1.603 3.317-3.307 3.905-5.075h-5.39l-2.354 4.225c-.264.474-.978.458-1.217-.03L9.957 10.02l-1.884 2.862a.695.695 0 0 1-1.16-.015l-.893-1.438H2.336c.588 1.768 1.898 3.472 3.905 5.075 1.809 1.444 3.726 2.435 4.708 2.897Z"
      fill="url(#heart-rate_svg__a)"
    />
    <path
      d="M2.036 10.11a5.462 5.462 0 0 1 1.27-4.166c.998-1.178 2.412-1.866 3.982-1.938.093-.004.186-.006.278-.006 1.471 0 2.86.533 3.91 1.501.19.175.365.36.524.555.16-.195.334-.38.524-.555 1.05-.968 2.439-1.5 3.91-1.5.092 0 .185.001.278.005 1.57.072 2.984.76 3.982 1.938a5.461 5.461 0 0 1 1.27 4.166h-6.098a.686.686 0 0 0-.6.347l-1.908 3.423-2.697-5.503c-.23-.469-.904-.51-1.192-.072l-1.955 2.968-.523-.843a.687.687 0 0 0-.585-.32h-4.37Z"
      fill="url(#heart-rate_svg__b)"
    />
    <path
      d="M7.566 4c1.471 0 2.86.533 3.91 1.501.19.175.365.36.524.555L9.922 8.01a.673.673 0 0 0-.453.295l-.227.345-2.12 1.992-.131-.212a.687.687 0 0 0-.585-.32h-4.37a5.462 5.462 0 0 1 1.27-4.166c.998-1.178 2.412-1.866 3.982-1.938A6.04 6.04 0 0 1 7.566 4Z"
      fill="url(#heart-rate_svg__c)"
    />
    <path
      d="M2.336 11.429c.28.84.722 1.664 1.323 2.47l2.46-2.312-.1-.158H2.337Z"
      fill="url(#heart-rate_svg__d)"
    />
    <defs>
      <linearGradient
        id="heart-rate_svg__a"
        x1={10.4}
        y1={6.46}
        x2={13.852}
        y2={13.175}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F87171" />
        <stop offset={1} stopColor="#EF4444" />
      </linearGradient>
      <linearGradient
        id="heart-rate_svg__b"
        x1={10.4}
        y1={6.46}
        x2={13.852}
        y2={13.175}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F87171" />
        <stop offset={1} stopColor="#EF4444" />
      </linearGradient>
      <linearGradient
        id="heart-rate_svg__c"
        x1={6.676}
        y1={7.709}
        x2={12.07}
        y2={4.206}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DC2626" stopOpacity={0} />
        <stop offset={0.346} stopColor="#DC2626" stopOpacity={0.3} />
        <stop offset={0.804} stopColor="#DC2626" stopOpacity={0.8} />
        <stop offset={1} stopColor="#DC2626" />
      </linearGradient>
      <linearGradient
        id="heart-rate_svg__d"
        x1={6.676}
        y1={7.709}
        x2={12.07}
        y2={4.206}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DC2626" stopOpacity={0} />
        <stop offset={0.346} stopColor="#DC2626" stopOpacity={0.3} />
        <stop offset={0.804} stopColor="#DC2626" stopOpacity={0.8} />
        <stop offset={1} stopColor="#DC2626" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgHeartRate;
