/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasePatientWidgetDataResponseModel } from './BasePatientWidgetDataResponseModel';
import {
    BasePatientWidgetDataResponseModelFromJSON,
    BasePatientWidgetDataResponseModelFromJSONTyped,
    BasePatientWidgetDataResponseModelToJSON,
} from './BasePatientWidgetDataResponseModel';
import type { DeviceModel } from './DeviceModel';
import {
    DeviceModelFromJSON,
    DeviceModelFromJSONTyped,
    DeviceModelToJSON,
} from './DeviceModel';
import type { MeasurementResponseModel } from './MeasurementResponseModel';
import {
    MeasurementResponseModelFromJSON,
    MeasurementResponseModelFromJSONTyped,
    MeasurementResponseModelToJSON,
} from './MeasurementResponseModel';

/**
 * 
 * @export
 * @interface MeasurementPatientWidgetDataModel
 */
export interface MeasurementPatientWidgetDataModel extends BasePatientWidgetDataResponseModel {
    /**
     * 
     * @type {DeviceModel}
     * @memberof MeasurementPatientWidgetDataModel
     */
    device?: DeviceModel;
    /**
     * 
     * @type {Array<MeasurementResponseModel>}
     * @memberof MeasurementPatientWidgetDataModel
     */
    measurements?: Array<MeasurementResponseModel> | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementPatientWidgetDataModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementPatientWidgetDataModel
     */
    organizationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementPatientWidgetDataModel
     */
    measurementTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementPatientWidgetDataModel
     */
    offset?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementPatientWidgetDataModel
     */
    measurementUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementPatientWidgetDataModel
     */
    rowVersion?: string | null;
}

/**
 * Check if a given object implements the MeasurementPatientWidgetDataModel interface.
 */
export function instanceOfMeasurementPatientWidgetDataModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MeasurementPatientWidgetDataModelFromJSON(json: any): MeasurementPatientWidgetDataModel {
    return MeasurementPatientWidgetDataModelFromJSONTyped(json, false);
}

export function MeasurementPatientWidgetDataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementPatientWidgetDataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...BasePatientWidgetDataResponseModelFromJSONTyped(json, ignoreDiscriminator),
        'device': !exists(json, 'Device') ? undefined : DeviceModelFromJSON(json['Device']),
        'measurements': !exists(json, 'Measurements') ? undefined : (json['Measurements'] === null ? null : (json['Measurements'] as Array<any>).map(MeasurementResponseModelFromJSON)),
        'id': !exists(json, 'Id') ? undefined : json['Id'],
        'organizationId': !exists(json, 'OrganizationId') ? undefined : json['OrganizationId'],
        'measurementTime': !exists(json, 'MeasurementTime') ? undefined : json['MeasurementTime'],
        'offset': !exists(json, 'Offset') ? undefined : json['Offset'],
        'measurementUtc': !exists(json, 'MeasurementUtc') ? undefined : json['MeasurementUtc'],
        'rowVersion': !exists(json, 'RowVersion') ? undefined : json['RowVersion'],
    };
}

export function MeasurementPatientWidgetDataModelToJSON(value?: MeasurementPatientWidgetDataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...BasePatientWidgetDataResponseModelToJSON(value),
        'Device': DeviceModelToJSON(value.device),
        'Measurements': value.measurements === undefined ? undefined : (value.measurements === null ? null : (value.measurements as Array<any>).map(MeasurementResponseModelToJSON)),
        'Id': value.id,
        'OrganizationId': value.organizationId,
        'MeasurementTime': value.measurementTime,
        'Offset': value.offset,
        'MeasurementUtc': value.measurementUtc,
        'RowVersion': value.rowVersion,
    };
}

