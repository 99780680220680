/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UacrStatusTypeStatusLimits } from './UacrStatusTypeStatusLimits';
import {
    UacrStatusTypeStatusLimitsFromJSON,
    UacrStatusTypeStatusLimitsFromJSONTyped,
    UacrStatusTypeStatusLimitsToJSON,
} from './UacrStatusTypeStatusLimits';
import type { UrineAlbumineStatusTypeStatusLimits } from './UrineAlbumineStatusTypeStatusLimits';
import {
    UrineAlbumineStatusTypeStatusLimitsFromJSON,
    UrineAlbumineStatusTypeStatusLimitsFromJSONTyped,
    UrineAlbumineStatusTypeStatusLimitsToJSON,
} from './UrineAlbumineStatusTypeStatusLimits';
import type { UrineCreatinineLimitsModel } from './UrineCreatinineLimitsModel';
import {
    UrineCreatinineLimitsModelFromJSON,
    UrineCreatinineLimitsModelFromJSONTyped,
    UrineCreatinineLimitsModelToJSON,
} from './UrineCreatinineLimitsModel';

/**
 * 
 * @export
 * @interface UrineLimitsModel
 */
export interface UrineLimitsModel {
    /**
     * 
     * @type {UrineAlbumineStatusTypeStatusLimits}
     * @memberof UrineLimitsModel
     */
    albumine: UrineAlbumineStatusTypeStatusLimits;
    /**
     * 
     * @type {UrineCreatinineLimitsModel}
     * @memberof UrineLimitsModel
     */
    creatinine: UrineCreatinineLimitsModel;
    /**
     * 
     * @type {UacrStatusTypeStatusLimits}
     * @memberof UrineLimitsModel
     */
    uacr: UacrStatusTypeStatusLimits;
}

/**
 * Check if a given object implements the UrineLimitsModel interface.
 */
export function instanceOfUrineLimitsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "albumine" in value;
    isInstance = isInstance && "creatinine" in value;
    isInstance = isInstance && "uacr" in value;

    return isInstance;
}

export function UrineLimitsModelFromJSON(json: any): UrineLimitsModel {
    return UrineLimitsModelFromJSONTyped(json, false);
}

export function UrineLimitsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrineLimitsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'albumine': UrineAlbumineStatusTypeStatusLimitsFromJSON(json['Albumine']),
        'creatinine': UrineCreatinineLimitsModelFromJSON(json['Creatinine']),
        'uacr': UacrStatusTypeStatusLimitsFromJSON(json['Uacr']),
    };
}

export function UrineLimitsModelToJSON(value?: UrineLimitsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Albumine': UrineAlbumineStatusTypeStatusLimitsToJSON(value.albumine),
        'Creatinine': UrineCreatinineLimitsModelToJSON(value.creatinine),
        'Uacr': UacrStatusTypeStatusLimitsToJSON(value.uacr),
    };
}

