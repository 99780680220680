import { fetchAuthSession } from "@aws-amplify/auth";
import { tryGetLocalStorageValue } from "utils/localStorage";

export async function fetchWithToken<TInput>(
  url: string,
  options?: {
    method: string;
    headers?: HeadersInit;
    body?: TInput;
  },
) {
  const headers = await getAccessHeaders(options?.headers);

  const fUrl =
    options?.method === "GET" && options.body ? url + `?${new URLSearchParams(options.body)}` : url;

  const response = await fetch(fUrl, {
    ...options,
    headers,
    body: options?.body && options.method !== "GET" ? JSON.stringify(options.body) : undefined,
  });

  return response;
}

export async function getAccessHeaders(options?: HeadersInit) {
  const headers = new Headers(options);

  headers.set("Content-Type", "application/json");
  headers.set("access-token", `${(await fetchAuthSession()).tokens?.accessToken}`);
  headers.set("identity-token", `${(await fetchAuthSession()).tokens?.idToken}`);
  headers.set(
    "x-tenant",
    `${tryGetLocalStorageValue<{ "X-Tenant": string }>("lastUsedAuth")?.["X-Tenant"] ?? "missing tenant name"}`,
  );

  return headers;
}
