import { BMICategory } from "utils/commonTypes";

export const HEALTHY_LOWER_LIMIT = 18.5;
export const OVERWEIGHT_LOWER_LIMIT = 24.9;
export const OBESITY_LOWER_LIMIT = 29.9;

export function getBMICategory(value: number): BMICategory {
  if (value < HEALTHY_LOWER_LIMIT) {
    return "Underweight";
  }

  if (value >= HEALTHY_LOWER_LIMIT && value < OVERWEIGHT_LOWER_LIMIT) {
    return "Healthy";
  }

  if (value >= OVERWEIGHT_LOWER_LIMIT && value < OBESITY_LOWER_LIMIT) {
    return "Overweight";
  }

  return "Obesity";
}
