/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GroupedByItemModel } from './GroupedByItemModel';
import {
    GroupedByItemModelFromJSON,
    GroupedByItemModelFromJSONTyped,
    GroupedByItemModelToJSON,
} from './GroupedByItemModel';

/**
 * 
 * @export
 * @interface IdentifiedWithCkdRiskGroupModel
 */
export interface IdentifiedWithCkdRiskGroupModel {
    /**
     * 
     * @type {GroupedByItemModel}
     * @memberof IdentifiedWithCkdRiskGroupModel
     */
    groupedBy?: GroupedByItemModel;
    /**
     * 
     * @type {number}
     * @memberof IdentifiedWithCkdRiskGroupModel
     */
    numberOfPatients?: number;
    /**
     * 
     * @type {number}
     * @memberof IdentifiedWithCkdRiskGroupModel
     */
    numberOfPatientsInCkdRisk?: number;
}

/**
 * Check if a given object implements the IdentifiedWithCkdRiskGroupModel interface.
 */
export function instanceOfIdentifiedWithCkdRiskGroupModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IdentifiedWithCkdRiskGroupModelFromJSON(json: any): IdentifiedWithCkdRiskGroupModel {
    return IdentifiedWithCkdRiskGroupModelFromJSONTyped(json, false);
}

export function IdentifiedWithCkdRiskGroupModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentifiedWithCkdRiskGroupModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupedBy': !exists(json, 'GroupedBy') ? undefined : GroupedByItemModelFromJSON(json['GroupedBy']),
        'numberOfPatients': !exists(json, 'NumberOfPatients') ? undefined : json['NumberOfPatients'],
        'numberOfPatientsInCkdRisk': !exists(json, 'NumberOfPatientsInCkdRisk') ? undefined : json['NumberOfPatientsInCkdRisk'],
    };
}

export function IdentifiedWithCkdRiskGroupModelToJSON(value?: IdentifiedWithCkdRiskGroupModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'GroupedBy': GroupedByItemModelToJSON(value.groupedBy),
        'NumberOfPatients': value.numberOfPatients,
        'NumberOfPatientsInCkdRisk': value.numberOfPatientsInCkdRisk,
    };
}

