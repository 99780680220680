/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommandResultModel
 */
export interface CommandResultModel {
    /**
     * 
     * @type {number}
     * @memberof CommandResultModel
     */
    rowVersion: number;
}

/**
 * Check if a given object implements the CommandResultModel interface.
 */
export function instanceOfCommandResultModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rowVersion" in value;

    return isInstance;
}

export function CommandResultModelFromJSON(json: any): CommandResultModel {
    return CommandResultModelFromJSONTyped(json, false);
}

export function CommandResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommandResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowVersion': json['RowVersion'],
    };
}

export function CommandResultModelToJSON(value?: CommandResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'RowVersion': value.rowVersion,
    };
}

