import classNames from "classnames";
import { PropsWithChildren } from "react";
import "./Row.scss";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  inactive?: boolean;
}

export function Row({
  children,
  className,
  onClick,
  inactive = false,
  ...props
}: PropsWithChildren<RowProps>) {
  return (
    <tr
      {...props}
      onClick={onClick}
      className={classNames(
        "Row",
        onClick && inactive === false && "Row--clickable",
        inactive && "Row--inactive",
        className,
      )}
    >
      {children}
    </tr>
  );
}
