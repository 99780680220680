import { offset } from "@floating-ui/react-dom";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "../Select";
import { TableViewType } from "../Table/model";
import "./RowsPerPage.scss";

interface RowsPerPageProps {
  setRowsPerPage: (rows: number) => void;
  currentRowPerPage?: RowsPerPageListType;
  tableView: TableViewType;
}

export enum RowsPerPageListType {
  Ten = "10",
  Twenty = "20",
  Fifty = "50",
}

interface RowsPerPageListTypeObj {
  title: string;
  value: RowsPerPageListType;
}

const dropdownList: RowsPerPageListTypeObj[] = [
  {
    title: "10",
    value: RowsPerPageListType.Ten,
  },
  {
    title: "20",
    value: RowsPerPageListType.Twenty,
  },
  {
    title: "50",
    value: RowsPerPageListType.Fifty,
  },
];

function RowsPerPage({
  tableView,
  setRowsPerPage,
  currentRowPerPage,
}: PropsWithChildren<RowsPerPageProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "ui-components" });

  const onSelect = (type?: RowsPerPageListType) => {
    setRowsPerPage(Number(type ?? RowsPerPageListType.Ten));
  };

  const selectPlaceholder = dropdownList.find(el => el.value === currentRowPerPage)?.title;

  return (
    <Select<RowsPerPageListType>
      onSelect={onSelect}
      label={tableView === "Grid" ? t("RowsPerPage.tilesPerPage") : t("RowsPerPage.rowsPerPage")}
      placeholder={selectPlaceholder}
      options={dropdownList}
      value={currentRowPerPage}
      className="RowsPerPage"
      dropdownFloatingProps={{
        strategy: "absolute",
        placement: "top",
        middleware: [offset(5)],
      }}
    />
  );
}

export default RowsPerPage;
