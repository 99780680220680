import { MeasurementUnitType, UserGraphModel } from "api/query";
import {
  CandlestickChartSeriesType,
  ECOption,
  Formatters,
  ZoomLevels,
} from "components/Chart/model";
import { tooltipFormatter } from "components/Chart/tooltip";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import * as echarts from "echarts/core";
import {
  mapBloodPressureDataToGraphData,
  mapBloodPressureDataToYearlyData,
  mapBloodPressureDataToYearlyGraphData,
  tooltipYearlyBloodPressureFormatter,
} from "./utils";
import { YAxis, setYAxis } from "../model";

export type CandleStickChartData = [string, number, number, number, number];

export type SortedBloodPressureDataType = {
  systolic: Record<string, number[]>;
  diastolic: Record<string, number[]>;
};

const candleStickBarGradient = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
  {
    offset: 0,
    color: "#8352FE",
  },
  {
    offset: 1,
    color: "#3FD5D7",
  },
]);

interface GetOptionsProps {
  formatters: Formatters;
  graphUser: UserGraphModel;
  zoomLevel?: ZoomLevels;
  showTrending?: boolean;
  yAxis?: YAxis;
}

export const getOptions = ({
  formatters,
  graphUser,
  zoomLevel,
  showTrending,
  yAxis,
}: GetOptionsProps): ECOption => {
  const bloodPressureDailyData = mapBloodPressureDataToGraphData(graphUser);
  const bloodPressureYearlyData = mapBloodPressureDataToYearlyData(bloodPressureDailyData);
  const bloodPressureYearlyDataForGraph =
    mapBloodPressureDataToYearlyGraphData(bloodPressureYearlyData);

  const bloodPressureSeries = [
    {
      name: CandlestickChartSeriesType.Monthly,
      type: "candlestick",
      barWidth: 8,
      data: bloodPressureDailyData,
      itemStyle: {
        color: candleStickBarGradient,
        color0: candleStickBarGradient,
        borderColor: candleStickBarGradient,
        borderColor0: candleStickBarGradient,
        borderWidth: 0,
        opacity: 0.5,
      },
      selectedMode: true,
      select: {
        itemStyle: {
          opacity: 1,
          borderWidth: 4,
        },
      },
    },
    {
      // Workaround to avoid tooltip error issue caused by adding and removing series on the same chart.
      // When we replace the options that have series with two sets of data to one set of data,
      // the echart keeps the second member of the series array as undefined,
      // and the tooltip component tries to call it with the get method which causes an error.
      type: "candlestick",
      data: [],
    },
  ];

  const bloodPressureSeriesYearly = [
    {
      name: CandlestickChartSeriesType.YearlyTop,
      type: "candlestick",
      barWidth: 8,
      data: bloodPressureYearlyDataForGraph[0],
      itemStyle: {
        color: "#8352FE",
        color0: "#8352FE",
        borderColor: "#8352FE",
        borderColor0: "#8352FE",
        borderWidth: 0,
        opacity: 1,
      },
      selectedMode: true,
      select: {
        itemStyle: {
          opacity: 1,
          borderWidth: 4,
        },
      },
    },
    {
      name: CandlestickChartSeriesType.YearlyBottom,
      type: "candlestick",
      barWidth: 8,
      data: bloodPressureYearlyDataForGraph[1],
      itemStyle: {
        color: "#3FD5D7",
        color0: "#3FD5D7",
        borderColor: "#3FD5D7",
        borderColor0: "#3FD5D7",
        borderWidth: 0,
        opacity: 1,
      },
    },
  ];

  return {
    tooltip:
      bloodPressureYearlyData.length !== 0 && graphUser.measurements.length !== 0
        ? {
            axisPointer: {
              type: "line",
              lineStyle: { color: "#9CA3AF" },
              z: 2,
            },
            trigger: "axis",
            triggerOn: "mousemove",
            backgroundColor: "transparent",
            borderWidth: 0,
            padding: 0,
            borderRadius: 8,
            shadowColor: "transparent",
            formatter: (params: any) => {
              return zoomLevel === "year"
                ? tooltipYearlyBloodPressureFormatter(
                    params,
                    formatters,
                    bloodPressureYearlyData,
                    MEASUREMENT_UNITS.mmHg as MeasurementUnitType,
                  )
                : tooltipFormatter(
                    params,
                    formatters,
                    graphUser,
                    "bloodPressure",
                    MEASUREMENT_UNITS.mmHg,
                    showTrending,
                  );
            },
            z: 5,
            confine: true,
          }
        : undefined,
    grid: {
      left: 30,
      top: 15,
      right: 20,
      bottom: 90,
    },
    ...setYAxis(yAxis),
    xAxis: {
      type: "time",
      splitLine: {
        show: zoomLevel !== "week",
        lineStyle: {
          color: "#E5E7EB",
        },
      },
      axisLine: {
        lineStyle: {
          color: "#E5E7EB",
        },
      },
      axisLabel: {
        fontFamily: "Poppins",
        fontSize: 11,
        hideOverlap: true,
        align: "center",
        rich: {
          firstPart: {
            color: "#47546B",
            lineHeight: 18,
          },
          secondPart: {
            color: "#9BAFD0",
            fontWeight: "normal",
          },
        },
      },
      boundaryGap: false,
      ...(zoomLevel === "year" ? { splitNumber: 12 } : {}),
    },
    series: zoomLevel === "year" ? bloodPressureSeriesYearly : bloodPressureSeries,
  } as unknown as ECOption;
};
