import { useMachine } from "@xstate/react";
import { UserDetailsResponseModelListModel, UsersFilterModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { SearchQuery, TableContext, tableStateMachine } from "libs/ui";
import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import { PartnerFilterOptions, PartnersTableColumns } from "utils/createGlobalConfigStore";
import { BASIC_FILTER_OPTIONS, FilterSideModalState } from "../Filter/model";
import { BaseTableFilterProps } from "../Table/model";
import { DetailResponseModel } from "../model";
import { PersonModel } from "models/PersonModels";

export type UIPartnersFilters = Omit<
  UsersFilterModel,
  "beginsWith" | "userIds" | "userRoles" | "organizationIds"
>;

async function fetchPartners(
  organizationId: string,
  context?: TableContext<UserDetailsResponseModelListModel, UIPartnersFilters>,
) {
  const result = CarnaApiQuery.Partners.get({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

export const makeTableFetchStateMachine = (
  organizationId: string,
  queryParams: SearchQuery<ReturnType<typeof PARTNERS_FILTER_OPTIONS>>,
) =>
  tableStateMachine(
    (context?: TableContext<UserDetailsResponseModelListModel, UIPartnersFilters>) =>
      fetchPartners(organizationId, context),
    queryParams as any,
  );

type TableService = ReturnType<typeof useMachine<ReturnType<typeof makeTableFetchStateMachine>>>;

export function PARTNERS_FILTER_OPTIONS() {
  return {
    ...BASIC_FILTER_OPTIONS(),
    contains: "",
  } as const;
}

export type PartnersFilterOptionsKey = keyof ReturnType<typeof PARTNERS_FILTER_OPTIONS>;

export type PartnersTableStateContext = {
  tableService: TableService;
  fetch: () => void;
  filterSideModelState: FilterSideModalState<PartnersFilterOptionsKey>;
};

export interface PartnersTableProps extends BaseTableFilterProps {
  tableStateContext: PartnersTableStateContext;
  appliedColumns: PartnersTableColumns[];
  filterOptions?: PartnerFilterOptions[];
}

export interface BodyRowsProps {
  tableService: TableService;
  changeUserStateClick: (user: DetailResponseModel) => MouseEventHandler<HTMLButtonElement>;
  appliedColumns: PartnersTableColumns[];
  currentRightClickContext:
    | [React.MouseEvent<HTMLTableRowElement, MouseEvent>, PersonModel]
    | undefined;
  setCurrentRightClickContext: Dispatch<
    SetStateAction<[React.MouseEvent<HTMLTableRowElement, MouseEvent>, PersonModel] | undefined>
  >;
}

export interface PartnersTableFilterProps {
  tableService: TableService;
  filterSideModelState: FilterSideModalState<PartnersFilterOptionsKey>;
}
