import { Button, DeviceImage } from "libs/ui";
import { TableUtils } from "libs/ui/Table/utils";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TableCardDescription } from "../TableCardDescription";
import "./TableCardDevice.scss";

interface DeviceData {
  id?: string | null;
  organizationId?: string | null;
  serialNumber?: string | null;
}

interface TableCardDeviceProps {
  show?: boolean;
  deviceData?: DeviceData;
  loading?: boolean;
}

export function TableCardDevice({ show, deviceData, loading }: TableCardDeviceProps) {
  const { t } = useTranslation("translation", { keyPrefix: "TableCardDevice" });
  const tableRedirection = TableUtils.useTableElementsRedirect();

  const redirectToDevice = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
      tableRedirection({
        event: e,
        path: "deviceDetails",
        params: {
          deviceId: deviceData?.id ?? "",
          organizationId: deviceData?.organizationId ?? "",
        },
      }),
    [deviceData?.id, deviceData?.organizationId, tableRedirection],
  );

  if (!show) {
    return null;
  }

  if (!deviceData || (!deviceData.id && deviceData.organizationId)) {
    return <TableCardDescription>{t("na")}</TableCardDescription>;
  }

  return (
    <Button
      className="TableCardDeviceButton"
      buttonType="link-primary"
      buttonSize="medium"
      onClick={redirectToDevice}
      disabled={loading}
      {...(deviceData?.serialNumber ? { title: `Serial number: ${deviceData?.serialNumber}` } : {})}
    >
      <DeviceImage className="DeviceCell__figure" loading={loading} />
    </Button>
  );
}
