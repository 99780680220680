import { Page } from "libs/ui";
import { useTranslation } from "react-i18next";
import { TabsForRouter } from "libs/ui/TabsForRouter";
import { reportsRoutePaths } from "router";
import { useBreadCrumbs } from "utils/hooks/Pages/useBreadCrumbs";
import { ReportsTableContextProvider, useReportsTableContext } from "./ReportsContext";
import { useCallback } from "react";
import { isLoading } from "models/loadable";

function ReportsPageComponent() {
  const breadCrumbs = useBreadCrumbs();
  const { t } = useTranslation("translation", { keyPrefix: "ReportsPage" });

  const fetchLatest = useReportsTableContext(state => state.fetchLatest);
  const tableState = useReportsTableContext(state => state.tableState);

  const onAllReportsClick = useCallback(() => {
    if (isLoading(tableState)) {
      return;
    }

    fetchLatest();
  }, [fetchLatest, tableState]);

  return (
    <Page {...(breadCrumbs && { breadCrumbs })} className="ReportsPage">
      <TabsForRouter
        tabs={[
          {
            label: t("tabs.request_new"),
            routePath: reportsRoutePaths.requestNew.substring(1),
          },
          {
            label: t("tabs.all_reports"),
            routePath: reportsRoutePaths.allReports.substring(1),
            onClick: onAllReportsClick,
          },
        ]}
      />
    </Page>
  );
}

export function ReportsPage() {
  return (
    <ReportsTableContextProvider>
      <ReportsPageComponent />
    </ReportsTableContextProvider>
  );
}
