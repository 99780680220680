import { CarnaApiEvent } from "config/apiEvent";
import { toastStore } from "config/toast";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useOnEventStatusSubscribe } from "utils/hooks/useOnEventStatusSubscribe";
import { useTranslation } from "react-i18next";
import { CreateNewSideModal } from "../common/CreateNewSideModal";
import { AddModalForwardedProps, AddModalProps } from "../model";
import { organizationFormUtils } from "components/Forms/Organization/organizationFormUtils";
import * as Form from "components/Forms/Organization";
import { stripNetworkBodyWith } from "components/Forms/helper";
import { AddOrganizationModelForStripping } from "config/binding";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import { DISABLE_FORM_EDITING, EVERY_FORM_FIELD_EDITABLE } from "components/Forms/model";
import { OrganizationModel } from "models/OrganizationModels/OrganizationModels";

export const Organization = forwardRef<AddModalForwardedProps, AddModalProps>(
  ({ onSuccessCb }, ref) => {
    const { t } = useTranslation("modals", { keyPrefix: "AddModal" });
    const [showModal, setShowModal] = useState(false);

    const { appConfig } = useGlobalConfigContext();

    const [organizationData, setOrganizationData] = useState<OrganizationModel>(
      NotNullOrUndefined(appConfig?.forms.defaultValues.organization),
    );
    const [onEvent, inLoadingState] = useOnEventStatusSubscribe();

    const resetAndClose = useCallback(() => {
      setOrganizationData(NotNullOrUndefined(appConfig?.forms.defaultValues.organization));
      setShowModal(false);
    }, [appConfig?.forms.defaultValues.organization]);

    const onSuccessCallback = useCallback(() => {
      resetAndClose();
      if (onSuccessCb) {
        onSuccessCb();
      }
    }, [onSuccessCb, resetAndClose]);

    const areFieldsReady = organizationFormUtils.getFieldsReady(organizationData, "Add", appConfig);
    const submitDisabled = !areFieldsReady || appConfig === undefined || inLoadingState;

    const onSubmit = (e?: React.FormEvent) => {
      e?.preventDefault();

      if (submitDisabled) {
        return;
      }

      onEvent(
        CarnaApiEvent.Organization.post(
          { addOrganizationRequestModel: organizationData },
          stripNetworkBodyWith(AddOrganizationModelForStripping),
        ),
        {
          complete() {
            toastStore.toast.success({ msg: t("successAddOrganizationToastText"), expire: 5000 });
            onSuccessCallback();
          },
          error(err) {
            switch (err.code) {
              case "BE_ERROR":
                showBeFieldErrors(err.err, t("errorAddOrganizationToastText"));
                break;
              case "ACTION_FAILED":
                toastStore.toast.error({ msg: t("errorAddOrganizationToastText") });
                break;
              case "STATUS_QUERY_ERROR":
                resetAndClose();
                toastStore.toast.error({ msg: t("errorFailedToUpdateOrganizationTable") });
            }
          },
        },
      );
    };

    const toggleModal = () => setShowModal(prevValue => !prevValue);

    useImperativeHandle(
      ref,
      () => ({
        toggleModal,
      }),
      [],
    );

    return (
      <CreateNewSideModal
        title={t("addProjectOrganizationTitle")}
        submitButton={{
          onClick: onSubmit,
          text: t("buttonAdd"),
          disabled: submitDisabled,
        }}
        show={showModal}
        closeModal={{
          onClick: resetAndClose,
          disabled: inLoadingState,
        }}
        isLoading={inLoadingState}
      >
        <Form.Organization
          formMode="Add"
          organizationData={organizationData}
          setOrganizationData={setOrganizationData}
          onSubmit={onSubmit}
          editableFields={inLoadingState ? DISABLE_FORM_EDITING : EVERY_FORM_FIELD_EDITABLE}
        />
      </CreateNewSideModal>
    );
  },
);
