import { RangeTableCell } from "../../RangeTableCell";
import { resolveInRange } from "../helper";
import { RowProps } from "../model";

export function HeadRow({ labels, steps, value }: RowProps) {
  return (
    <tr className="HeadRow">
      {labels.map((label, index) => {
        const [, , active] = resolveInRange(steps, value, index);

        return (
          <RangeTableCell cellCount={labels.length} key={label} active={active}>
            {label}
          </RangeTableCell>
        );
      })}
    </tr>
  );
}
