import { Avatar } from "../../../Avatar";
import { PropsWithChildren } from "react";
import { Cell } from "../Cell";
import { AvatarCellProps } from "../model";
import "./AvatarCell.scss";
import classNames from "classnames";
import { Chip } from "../../../Chip";
import { resolveStatusToVariant } from "../../../utils";

export function AvatarCell({
  show = true,
  label,
  tag,
  status,
  onCellClick,
  children,
  cellProps,
  loading,
  avatarLoading,
  className,
  ...avatarProps
}: PropsWithChildren<AvatarCellProps>) {
  const loadedState = label?.trim().length ? (
    <>
      <Avatar {...avatarProps} avatarLoading={!!avatarLoading} />
      {status && status !== "NoAccess" ? (
        <div>
          <span className="AvatarCell__label">{label}</span>
          <Chip
            size="small"
            variant={resolveStatusToVariant(status)}
            className="AvatarCell__status"
          >
            {status}
          </Chip>
        </div>
      ) : (
        <span className="AvatarCell__label">{label}</span>
      )}
      {tag && <span className="AvatarCell__tag">{tag}</span>}
    </>
  ) : (
    children
  );

  return (
    <Cell
      {...cellProps}
      show={show}
      onClick={onCellClick}
      className={classNames("AvatarCell", className, tag && "AvatarCell--with-tag")}
    >
      {loading ? (
        <>
          <Avatar avatarLoading={loading} />
          <div>
            <span
              className={classNames("AvatarCell__label", {
                "AvatarCell__label--loading": loading,
              })}
            />
            <span
              className={classNames("AvatarCell__tag", {
                "AvatarCell__tag--loading": loading,
              })}
            />
          </div>
        </>
      ) : (
        loadedState
      )}
    </Cell>
  );
}
