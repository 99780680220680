import { OrganizationDetailsResponseModelListModel } from "api/query";
import { Option } from "libs/ui/Select/Select.model";
import { isLoaded, LoadableModel } from "models/loadable";

export function organizationCollectionToSelectOptions(
  data: LoadableModel<OrganizationDetailsResponseModelListModel>,
): Option<string>[] {
  if (!isLoaded(data) || !data.value.items) {
    return [];
  }

  const organizationOptions: Option<string>[] = data.value.items.map(org => ({
    title: org.name ?? "",
    value: org.id ?? "",
  }));

  return organizationOptions;
}
