import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { Selection } from "libs/ui";
import { MeasurementItemUIModel } from "models/TestModels";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Enum } from "utils/createGlobalConfigStore";

interface SQUACRFormProps {
  items: MeasurementItemUIModel[];
  onMeasurementValueChange: (value?: string) => void;
}

export function SQUACRForm({ items, onMeasurementValueChange }: SQUACRFormProps) {
  const { appConfig } = useGlobalConfigContext();
  const { t } = useTranslation("translation", { keyPrefix: "SingleMeasurementField" });

  const SQUACREnums =
    ((appConfig?.general.custom.enums as any)["SemiQuantitativeUacrStatusType"] as Enum[]) ?? [];

  const SQUACROptions = SQUACREnums.map(el => ({
    title: t(`SQUACROption.${el.V.toString()}`),
    value: el.V,
  }));

  const onSQUACRChange = useCallback(
    (value?: number) => {
      if (!value) {
        return;
      }
      onMeasurementValueChange(value.toString());
    },
    [onMeasurementValueChange],
  );

  const value = items.find(el => el.type === "SemiQuantitativeUacr")?.value ?? 0;

  return (
    <Selection
      value={Number(value)}
      label={t("labelSQUACRCategory")}
      options={SQUACROptions}
      onSelect={onSQUACRChange}
    />
  );
}
