import Ajv from "ajv";
import { isValidPhoneNumber } from "libphonenumber-js";
import { FormType } from "models/FormModeModels";
import {
  ConfigFieldModel,
  ContextsFieldConfig,
  GlobalConfig,
  RoleEntities,
} from "utils/createGlobalConfigStore";
import { validateEmailPerVisibility } from "utils/validators/validateEmailPerVisibility";
import { UserModel } from "./model";
import { getOptionalFieldConfigForValidationSchema } from "../validation/getOptionalFieldConfigForValidationSchema";

function getOptionalFieldConfig(
  field: ConfigFieldModel<any> | undefined,
  key: keyof ContextsFieldConfig,
) {
  const { visibility } = field?.contexts[key] ?? {};

  return {
    type: "string",
    nullable: visibility !== "Required",
    ...(visibility === "Required" ? { isNotEmpty: true, minLength: 1 } : undefined),
  };
}

function getFieldsReady(userData: UserModel, formType: FormType, globalConfig?: GlobalConfig) {
  const key: keyof ContextsFieldConfig = formType === "Add" ? "Add" : "Update";
  const { zipCode, email, nationalityType } =
    globalConfig?.entities[userData.role?.toLowerCase() as RoleEntities] ?? {};

  const statesRequired = !!(globalConfig?.general.custom.enums as any)?.[
    `${userData.country}_States`
  ];
  const stateMaximum = statesRequired
    ? (globalConfig?.general.custom.enums as any)?.[`${userData.country}_States`].slice(-1)[0].V
    : null;

  const validateEmail = validateEmailPerVisibility(
    email ? email.contexts[key].visibility : "Optional",
  );

  const [emailSchema, isEmailMandatory] = getOptionalFieldConfigForValidationSchema(
    email,
    key,
    userData.email,
  );

  const ajv = new Ajv(); //{ allowUnionTypes: true }

  ajv.addKeyword({
    keyword: "isNotEmpty",
    validate: (schema: any, data: any, _current: any, rest: any) => {
      if (schema) {
        switch (rest.parentDataProperty as keyof UserModel) {
          case "email":
            return validateEmail(data);

          case "phone":
            return isValidPhoneNumber(data);

          default:
            return !!data && data.toString().trim() !== "";
        }
      }

      return false;
    },
  });

  const schema = {
    type: "object",
    properties: {
      firstName: {
        type: "string",
        nullable: false,
        isNotEmpty: true,
        minLength: 1,
      },
      lastName: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      street: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      city: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      phone: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      country: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      role: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      ...(userData.role === "Partner"
        ? {
            organizationId: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
          }
        : undefined),

      email: emailSchema,
      zipCode: getOptionalFieldConfig(zipCode, key),
      nationalityType: getOptionalFieldConfig(nationalityType, key),

      state: {
        type: "integer",
        nullable: !statesRequired,
        ...(statesRequired ? { isNotEmpty: true, minimum: 1, maximum: stateMaximum } : undefined),
      },
    },

    required: [
      "firstName",
      "lastName",
      "street",
      "city",
      "phone",
      "country",
      ...(userData.role === "Partner" ? ["organizationId"] : []),
      ...(isEmailMandatory ? ["email"] : []),
      ...(zipCode?.contexts[key].visibility === "Required" ? ["zipCode"] : []),
      ...(nationalityType?.contexts[key].visibility === "Required" ? ["nationalityType"] : []),
      ...(statesRequired ? ["state"] : []),
    ],
    additionalProperties: true,
  }; //satisfies JSONSchemaType<PatientModel>;

  const validate = ajv.compile(schema);

  const valid = validate(userData);

  return valid;
}

export const userFormUtils = { getFieldsReady };
