/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ManufacturerType = {
    NovaBiomedical: 'NovaBiomedical'
} as const;
export type ManufacturerType = typeof ManufacturerType[keyof typeof ManufacturerType];


export function ManufacturerTypeFromJSON(json: any): ManufacturerType {
    return ManufacturerTypeFromJSONTyped(json, false);
}

export function ManufacturerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManufacturerType {
    return json as ManufacturerType;
}

export function ManufacturerTypeToJSON(value?: ManufacturerType | null): any {
    return value as any;
}

