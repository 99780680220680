/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AncestryType = {
    Black: 'Black',
    Other: 'Other'
} as const;
export type AncestryType = typeof AncestryType[keyof typeof AncestryType];


export function AncestryTypeFromJSON(json: any): AncestryType {
    return AncestryTypeFromJSONTyped(json, false);
}

export function AncestryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AncestryType {
    return json as AncestryType;
}

export function AncestryTypeToJSON(value?: AncestryType | null): any {
    return value as any;
}

