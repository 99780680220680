import * as React from "react";
import { SVGProps } from "react";
const SvgLaptop = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2.25c-1.567 0-2.75 1.345-2.75 2.893v10.714c0 1.548 1.183 2.893 2.75 2.893h16c1.567 0 2.75-1.345 2.75-2.893V5.143c0-1.548-1.183-2.893-2.75-2.893H4ZM2.75 5.143c0-.819.608-1.393 1.25-1.393h16c.642 0 1.25.574 1.25 1.393v10.714c0 .819-.608 1.393-1.25 1.393H4c-.642 0-1.25-.574-1.25-1.393V5.143Z"
      fill="currentColor"
    />
    <path d="M2 20.25a.75.75 0 0 0 0 1.5h20a.75.75 0 0 0 0-1.5H2Z" fill="currentColor" />
  </svg>
);
export default SvgLaptop;
