import * as React from "react";
import type { SVGProps } from "react";
const SvgTemperature = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="url(#temperature_svg__a)"
      d="M20.458 5.1 18.9 3.544a1.85 1.85 0 0 0-2.618 0l-7.33 7.33a5.33 5.33 0 0 0-1.437 2.63 5.33 5.33 0 0 1-1.437 2.63l-2.707 2.708a1.265 1.265 0 1 0 1.79 1.79l2.707-2.708a5.33 5.33 0 0 1 2.63-1.437 5.33 5.33 0 0 0 2.63-1.438l7.33-7.33a1.85 1.85 0 0 0 0-2.617"
    />
    <path
      fill="url(#temperature_svg__b)"
      d="M4.833 19.168a32 32 0 0 0-.912-.877l-.55.55a1.266 1.266 0 0 0 1.79 1.79l.55-.55q-.419-.451-.878-.913"
    />
    <path
      fill="url(#temperature_svg__c)"
      d="m11.026 14.934-1.959-1.96a.63.63 0 0 1 0-.89l6.62-6.62a.63.63 0 0 1 .891 0l1.96 1.958a.63.63 0 0 1 0 .891l-6.621 6.62a.63.63 0 0 1-.89 0"
    />
    <path
      fill="url(#temperature_svg__d)"
      d="m11.074 14.225-1.298-1.298a.5.5 0 0 1 0-.71l6.045-6.045a.5.5 0 0 1 .71 0l1.298 1.299a.5.5 0 0 1 0 .709l-6.046 6.045a.5.5 0 0 1-.709 0"
    />
    <path
      fill="url(#temperature_svg__e)"
      d="m11.047 13.817-.863-.863a.42.42 0 0 1 0-.595l3.42-3.42a.42.42 0 0 1 .596 0l.862.862a.42.42 0 0 1 0 .596l-3.42 3.42a.42.42 0 0 1-.595 0"
    />
    <path
      fill="url(#temperature_svg__f)"
      d="M3.391 20.65a1.265 1.265 0 0 0 1.77-.02l2.707-2.707a5.33 5.33 0 0 1 2.63-1.438 5.33 5.33 0 0 0 2.63-1.437l7.33-7.33a1.85 1.85 0 0 0 0-2.617l-.759-.76z"
    />
    <path
      fill="url(#temperature_svg__g)"
      d="m20.458 5.1-.759-.758-4.905 4.904 2.068 2.068 3.596-3.595a1.85 1.85 0 0 0 0-2.618"
    />
    <path
      fill="url(#temperature_svg__h)"
      d="m20.458 7.719-.316.316a47 47 0 0 0-4.175-4.176l.315-.316a1.85 1.85 0 0 1 2.618 0l1.558 1.558a1.85 1.85 0 0 1 0 2.618"
    />
    <defs>
      <linearGradient
        id="temperature_svg__a"
        x1={9.57}
        x2={13.478}
        y1={10.519}
        y2={14.427}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset={1} stopColor="#D5E8FE" />
      </linearGradient>
      <linearGradient
        id="temperature_svg__b"
        x1={4.356}
        x2={4.356}
        y1={19.397}
        y2={22.268}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBD0E4" />
        <stop offset={1} stopColor="#6A7D83" />
      </linearGradient>
      <linearGradient
        id="temperature_svg__c"
        x1={16.01}
        x2={12.448}
        y1={12.405}
        y2={8.843}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset={1} stopColor="#D5E8FE" />
      </linearGradient>
      <linearGradient
        id="temperature_svg__d"
        x1={13.418}
        x2={15.053}
        y1={9.813}
        y2={11.448}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AE1D3" />
        <stop offset={1} stopColor="#80D1C1" />
      </linearGradient>
      <linearGradient
        id="temperature_svg__e"
        x1={12.042}
        x2={13.834}
        y1={10.794}
        y2={12.586}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset={1} stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="temperature_svg__f"
        x1={12.644}
        x2={6.183}
        y1={13.458}
        y2={7.792}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity={0} />
        <stop offset={0.271} stopColor="#074459" stopOpacity={0.271} />
        <stop offset={0.597} stopColor="#05394A" stopOpacity={0.597} />
        <stop offset={0.951} stopColor="#032632" stopOpacity={0.951} />
        <stop offset={1} stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="temperature_svg__g"
        x1={17.647}
        x2={16.012}
        y1={6.944}
        y2={2.172}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity={0} />
        <stop offset={0.271} stopColor="#074459" stopOpacity={0.271} />
        <stop offset={0.597} stopColor="#05394A" stopOpacity={0.597} />
        <stop offset={0.951} stopColor="#032632" stopOpacity={0.951} />
        <stop offset={1} stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="temperature_svg__h"
        x1={18.765}
        x2={18.201}
        y1={4.7}
        y2={5.959}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AE1D3" />
        <stop offset={1} stopColor="#80D1C1" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgTemperature;
