import "./SelectFilterAsyncNoData.scss";
import { EmptyDevices, EmptyHcp, EmptyOrgs } from "libs/ui/assets/svg";
import { MapInputIcon } from "libs/ui/assets/svg/mapInputIcon";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

type NoDataVariantType =
  | "Devices"
  | "Organizations"
  | "HCP"
  | "Patient"
  | "GeneralPractitioner"
  | "PlaceSuggestionInput";

export interface SelectFilterAsyncNoDataProps {
  variant: NoDataVariantType;
  scaleTo?: number;
  handleShowMap?: () => void;
}

const iconMap = {
  Devices: <EmptyDevices />,
  Organizations: <EmptyOrgs />,
  HCP: <EmptyHcp />,
  Patient: <EmptyHcp />,
  GeneralPractitioner: <EmptyHcp />,
  PlaceSuggestionInput: <MapInputIcon />,
} as const;

export function SelectFilterAsyncNoData({
  variant,
  scaleTo,
  handleShowMap,
}: Readonly<SelectFilterAsyncNoDataProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "ui-components.SelectFilterAsync" });

  const scaleIcon: CSSProperties | undefined = scaleTo
    ? { transform: `scale(${scaleTo})` }
    : undefined;

  return (
    <div className="SelectFilterAsyncNoData">
      <div className="SelectFilterAsyncNoData__icon" style={scaleIcon}>
        {iconMap[variant]}
      </div>
      <p className="SelectFilterAsyncNoData__title">{t(`noData.${variant}.title`)}</p>
      <p className="SelectFilterAsyncNoData__description">{t(`noData.${variant}.description`)}</p>
      {handleShowMap ? (
        <p className="SelectFilterAsyncNoData__showMap" onClick={handleShowMap}>
          {t(`noData.${variant}.select-on-the-map`)}
        </p>
      ) : null}
    </div>
  );
}
