import { BackendResponse } from "libs/ui";
import { xor } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { AppliedFilterStateMap, FilterConfig, TableFilterConfig } from "./model";

export function useFilterComponentConfig<
  T extends BackendResponse,
  A extends AppliedFilterStateMap,
>({ tableService, filterSideModelState }: TableFilterConfig<T, A>): FilterConfig<A> {
  const [state, send, actor] = tableService;
  const { activeFilters, isFilterSideModalOpen, closeModal } = filterSideModelState;

  const [appliedFilters, setAppliedFilters] = useState<A>(
    () =>
      ({
        ...state.context.filters,
      }) as A,
  );

  useEffect(() => {
    /**
     * If the general search input changes
     * we should update this state here
     * without this, you gonna have a bug
     * delete the general search
     * apply filters, general search will be replaced
     */
    const subscription = actor.subscribe(state => {
      if (state.context.filters?.contains !== appliedFilters.contains) {
        setAppliedFilters(prevValue => ({
          ...prevValue,
          contains: state.context.filters?.contains,
        }));
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [actor, appliedFilters.contains]);

  const onSearchChange = (value?: string) =>
    setAppliedFilters(prevValue => ({
      ...prevValue,
      contains: value ?? undefined,
    }));

  const onNewFilterApplied = <T>(key: keyof A, value: T) => {
    setAppliedFilters(prevValue => ({
      ...prevValue,
      [key]: xor(prevValue[key] ?? [], [value]),
    }));
  };

  const onNewRadioFilterApplied = <T>(key: keyof A, value: T) => {
    setAppliedFilters(prevValue => ({
      ...prevValue,
      [key]: value,
    }));
  };

  const onSingleFilterClear = useCallback((key: keyof A) => {
    setAppliedFilters(currentFilters => {
      const newFilterList = { ...currentFilters, [key]: undefined as A[keyof A] };

      return newFilterList;
    });
  }, []);

  const onClearAll = useCallback(() => {
    setAppliedFilters({
      ...(state.context.filters?.contains ? { contains: state.context.filters?.contains } : {}),
    } as unknown as A);
  }, [state.context.filters]);

  const onUpdateFilters = useCallback(() => {
    send({
      type: "UPDATE_FILTERS",
      value: {
        ...appliedFilters,
        ...(state.context.filters?.contains ? { contains: state.context.filters?.contains } : {}),
      },
    });
    closeModal();
  }, [appliedFilters, closeModal, send, state.context.filters]);

  const onCloseModal = useCallback(() => {
    setAppliedFilters({ ...state.context.filters } as A);
    closeModal();
  }, [closeModal, state.context.filters]);

  return {
    isFilterSideModalOpen,
    onSearchChange,
    onClearAll,
    onUpdateFilters,
    onCloseModal,
    activeFilters,
    appliedFilters,
    onNewFilterApplied,
    onNewRadioFilterApplied,
    onSingleFilterClear,
  };
}
