import { UserDetailsResponseModel } from "api/query";
import { resolveRenderCell, resolveUserStatus } from "components/AppTables/utils";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { ActionButton, TableElements } from "libs/ui";
import { statusToIcon } from "libs/ui/ActionButton/utils";
import { TableUtils } from "libs/ui/Table/utils";
import { useMemo, useSyncExternalStore } from "react";
import { useTranslation } from "react-i18next";
import { FakeData, isRealData } from "utils/guards";
import { PictureResolution } from "utils/helpers/pictures.model";
import { avatarCache } from "utils/machines/avatars";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { BodyRowsProps } from "../AdministratorsTable/model";
import { TableCard } from "../TableCard";
import { TableCardDescription } from "../TableCardDescription";
import { TableCardRole } from "../TableCardRole";
import { TableCardStatus } from "../TableCardStatus";

export function BodyRows({
  tableService,
  appliedColumns,
  changeUserStateClick,
  currentRightClickContext,
  setCurrentRightClickContext,
}: Readonly<BodyRowsProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "AdministratorsTable" });
  const { t: countriesT } = useTranslation("nationalities", { keyPrefix: "countries" });
  const { isAdmin } = useAdditionalAuthInformationContext();

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  const tableRedirection = TableUtils.useTableElementsRedirect();
  /**
   * TODO grooming
   * This should not be dependant on external stuff
   * we should have defaultProps or something
   */
  const { appConfig } = useGlobalConfigContext();
  const [state] = tableService;

  const defaultValues = useMemo(
    () =>
      Array.from({ length: state.context.linesPerPage }).map((_, idx) => {
        const defaultValue: FakeData<UserDetailsResponseModel> = {
          ...NotNullOrUndefined(appConfig?.forms.defaultValues.user),
          _fake: true,
          status: "Invited",
          organizationId: "",
          organizationName: "",
          id: idx.toString(),
          shortId: idx,
          rowVersion: "1",
          role: "Admin",
          isAccountOwner: true,
          additionalProperties: {},
        };
        return defaultValue;
      }),
    [appConfig?.forms.defaultValues.user, state.context.linesPerPage],
  );

  return (
    <>
      {(state.matches("loading") ? defaultValues : (state.context.data.items ?? [])).map(user => {
        if (state.context.tableView === "Grid") {
          return (
            <TableCard
              key={user.id}
              loading={state.matches("loading")}
              inactive={user.status === "Deactivated"}
              avatarData={resolveRenderCell(appliedColumns, "userStatuses", () => ({
                src: isRealData(user)
                  ? avatarCache.get(user.organizationId, user.id, PictureResolution.grid)
                  : undefined,
                loading: false,
                avatarType: user.role,
              }))}
              name={`${user.firstName} ${user.lastName}`}
              description={
                <>
                  <TableCardDescription loading={state.matches("loading")} textType="bold">
                    {user.email}
                  </TableCardDescription>
                  <TableCardRole loading={state.matches("loading")} role={user.role} />
                </>
              }
              organization={resolveRenderCell(appliedColumns, "organizationIds", () => ({
                id: user.organizationId,
                name: user.organizationName,
                isMain: !!user.isMainOrganization,
              }))}
              onClick={() =>
                tableRedirection({
                  path: "userDetail",
                  params: { organizationId: user.organizationId, role: user.role, userId: user.id },
                })
              }
              actionChildren={
                user.status !== "Deleted" && (
                  <ActionButton
                    onClick={changeUserStateClick(user)}
                    actionType={user.status}
                    iconType={statusToIcon(user.status)}
                  >
                    {t(`actions.${user.status}`)}
                  </ActionButton>
                )
              }
            >
              <TableCardStatus
                status={resolveUserStatus(user.status)}
                loading={state.matches("loading")}
              >
                {user.status}
              </TableCardStatus>
            </TableCard>
          );
        }

        return (
          <TableElements.Row
            key={user.id}
            inactive={user.status === "Deactivated"}
            data-testid="UserRow"
            onContextMenu={event => {
              event.preventDefault();
              setCurrentRightClickContext([event, user]);
            }}
            onClick={() => {
              if (currentRightClickContext || state.matches("loading")) {
                return null;
              }
              tableRedirection({
                path: "userDetail",
                params: {
                  organizationId: user.organizationId,
                  role: user.role,
                  userId: user.id,
                },
              });
            }}
          >
            <TableElements.AvatarCell
              show={resolveRenderCell(appliedColumns, "userStatuses")}
              src={
                isRealData(user)
                  ? avatarCache.get(user.organizationId, user.id, PictureResolution.table)
                  : undefined
              }
              avatarLoading={false}
              firstName={user.firstName}
              label={`${user.firstName} ${user.lastName}`}
              status={user.status}
              avatarType={user.role === "Admin" ? "Admin" : "Partner"}
              loading={state.matches("loading")}
            />

            <TableElements.UserRoleCell
              show={resolveRenderCell(appliedColumns, "userRoles")}
              role={user.role}
              label={t(user.role)}
              loading={state.matches("loading")}
            />

            <TableElements.UserOrganizationCell
              show={resolveRenderCell(appliedColumns, "organizationIds")}
              {...(isAdmin && {
                onCellClick: e =>
                  tableRedirection({
                    event: e,
                    path: "organizationDetails",
                    params: {
                      organizationId: user.organizationId,
                      firstTab: user.isMainOrganization ? "user-management" : "partners",
                    },
                  }),
              })}
              label={user.organizationName}
              loading={state.matches("loading")}
              isMain={!!user.isMainOrganization}
            />

            <TableElements.Cell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "email")}
              data-testid="email_cell"
            >
              {user.email}
            </TableElements.Cell>

            <TableElements.Cell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "phone")}
            >
              {user.phone}
            </TableElements.Cell>

            <TableElements.Cell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "address")}
            >{`${user.street} ${user.zipCode ?? ""}`}</TableElements.Cell>

            <TableElements.FlagCell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "countries")}
              countryName={countriesT(user.country)}
              country={user.country}
            />

            <TableElements.Cell
              show={resolveRenderCell(appliedColumns, "actions")}
              className="ActionsCell"
            >
              <TableElements.Actions disabled={user.status === "Deleted"}>
                <ActionButton
                  onClick={changeUserStateClick(user)}
                  actionType={user.status}
                  iconType={statusToIcon(user.status)}
                >
                  {t(`actions.${user.status}`)}
                </ActionButton>
              </TableElements.Actions>
            </TableElements.Cell>
          </TableElements.Row>
        );
      })}
    </>
  );
}
