import * as React from "react";
import type { SVGProps } from "react";
const SvgMale = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="url(#male_svg__a)"
      d="m14.421 11.172 4.326-4.325v4.604a1.124 1.124 0 0 0 1.126 1.127c.623 0 1.127-.504 1.127-1.127V4.127C21 3.505 20.495 3 19.873 3H12.55a1.127 1.127 0 0 0 0 2.254h4.603l-4.324 4.325c-2.442-1.689-5.823-1.449-7.995.723a6.276 6.276 0 0 0 0 8.865 6.276 6.276 0 0 0 8.865 0c2.172-2.172 2.412-5.553.723-7.995m-7.994 6.401a4.02 4.02 0 0 1 0-5.677 4.02 4.02 0 0 1 5.677 0 4.02 4.02 0 0 1 0 5.677 4.02 4.02 0 0 1-5.677 0"
    />
    <defs>
      <linearGradient
        id="male_svg__a"
        x1={12}
        x2={12}
        y1={3}
        y2={21}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CED8EE" />
        <stop offset={1} stopColor="#7186A9" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgMale;
