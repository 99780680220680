import i18n from "./i18n";

export async function fetchTranslation(
  {
    language,
    namespace,
    tenantId,
  }: {
    language: string;
    namespace: string;
    tenantId?: string;
  },
  requestInit?: RequestInit,
) {
  if (!process.env.VITE_APP_ASSETS_HOST) {
    throw new Error("Can't fetch translations");
  }

  let init: RequestInit | undefined = {
    cache: "default",
    ...requestInit,
  };

  if (tenantId) {
    return fetch(
      `${process.env.VITE_APP_ASSETS_HOST}/${language}/${tenantId.toLowerCase()}/${namespace}.json`,
      init,
    );
  }

  return fetch(`${process.env.VITE_APP_ASSETS_HOST}/${language}/${namespace}.json`, init);
}

export async function fetchTranslationsInBundle(
  languages: {
    language: string;
    namespace: string;
    tenantId?: string;
  }[],
  root?: boolean,
  requestInit?: RequestInit,
) {
  if (process.env.MODE === "test") {
    return;
  }

  const requests = languages.map(async details => {
    let response: Response | undefined;

    try {
      response = await fetchTranslation(details, requestInit);
      // ! 4xx-5xx errors are not "thrown"
      // ! this only happens if it can't connect ...etc
    } catch (error) {
      response = new Response("error", { statusText: "error" });
    }

    return { response, details } as const;
  });

  const result = await Promise.all(requests);
  const done = await Promise.all(
    result.map(async responseWithDetails => {
      // No current user ( logged out state )
      if (responseWithDetails.response === undefined) {
        return;
      }

      if (
        responseWithDetails?.response?.status === 200 &&
        responseWithDetails?.response.statusText !== "error"
      ) {
        try {
          const jsonResult = await responseWithDetails.response.json();
          i18n.addResourceBundle(
            responseWithDetails.details.language,
            responseWithDetails.details.namespace,
            jsonResult,
            true,
            true,
          );
          return;
        } catch (error) {
          return new Error("json_error", { cause: "json_error" });
        }
      } else {
        if (
          (responseWithDetails?.response?.status === 404 ||
            responseWithDetails?.response?.status === 403) &&
          root === true
        ) {
          return new Error("error", { cause: "failed to download asset from root" });
        }

        if (
          responseWithDetails?.response?.status === 404 ||
          responseWithDetails?.response?.status === 403
        ) {
          return;
        }

        return new Error("error", { cause: "failed network request" });
      }
    }),
  );
  const hasError = done.find(promise => promise instanceof Error);

  return hasError;
}
