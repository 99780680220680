import classNames from "classnames";
import { FieldLoader } from "../FieldLoader";
import { ReactElement, useRef, useState } from "react";
import { Dropdown } from "../Dropdown";
import { FieldValidation } from "../FieldValidation";
import { useOnClickOutside } from "../hooks";
import { Icon, IconType, isIconType } from "../Icon";
import { FormFieldBasic } from "../models";
import { SelectOption } from "../SelectOption";
import { isKeyboardClickKey, renderValidElement } from "../utils";
import i18n from "i18next";
import "./Selection.scss";
import { SelectProps } from "../Select/Select.model";

interface SelectionProps<T> extends FormFieldBasic, SelectProps<T> {
  className?: string;
  icon?: IconType | ReactElement<any, any>;
  optional?: boolean;
  showNa?: boolean;
}

export function Selection<T>({
  onSelect,
  options,
  value,
  label,
  validation,
  variant = "regular",
  disabled,
  className,
  dropdownFloatingProps,
  readOnly,
  loading,
  icon,
  optional,
  showNa,
  ...props
}: Readonly<SelectionProps<T>>) {
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef(null);
  const { successText, errorText } = validation ?? {};

  const onOptionSelect = (selectedValue: T) => {
    if (onSelect) {
      onSelect(selectedValue);
    }

    toggleDropdown();
  };

  const toggleDropdown = () => {
    if (disabled || readOnly) {
      return;
    }

    setShowOptions(prevValue => !prevValue);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (isKeyboardClickKey(e.key)) {
      e.preventDefault();
      toggleDropdown();
    }
  };

  useOnClickOutside(selectRef, showOptions, () => toggleDropdown());

  if (loading) {
    return <FieldLoader />;
  }

  return (
    <div
      ref={selectRef}
      className={classNames("Selection", className)}
      data-type={variant}
      data-testval={value}
      {...props}
    >
      <div className="Selection__box">
        <div className="Selection__field">
          <div
            className={classNames(
              "Selection__element",
              showOptions && "Selection__element--colored",
              errorText && "Selection__element--error",
              successText && "Selection__element--success",
              readOnly && "Selection__element--disabled",
            )}
            role="listbox"
            tabIndex={0}
            onKeyDown={onKeyDown}
            onFocus={readOnly ? undefined : toggleDropdown}
            data-readonly={readOnly}
            data-testid={"Selection-element"}
          >
            {isIconType(icon) ? (
              <Icon
                className={classNames("Selection__icon", {
                  "Selection__icon--disabled": disabled,
                })}
                icon={icon}
              />
            ) : (
              renderValidElement(icon, { className: "Selection__iconImage" })
            )}
            {showNa
              ? i18n.t("ui-components.Selection.na", { ns: "translation" })
              : options.find(o => o.value === value)?.title ?? ""}
          </div>
          {!readOnly && (
            <Icon
              data-testid="Selection-icon"
              className={classNames("Icon__selection", {
                "Icon--disabled": disabled,
              })}
              icon="ChevronDown"
            />
          )}
        </div>
        {label ? (
          <label
            data-testid="Selection-label"
            className={classNames(
              "Selection__label",
              (value || showOptions || showNa) && "Selection__label--active",
              showOptions && "Selection__label--colored",
              errorText && "Selection__label--error",
              successText && "Selection__label--success",
            )}
          >
            {optional
              ? i18n.t("ui-components.Selection.optional-label", { ns: "translation", label })
              : label}
          </label>
        ) : null}

        <FieldValidation {...validation} />
      </div>

      <Dropdown
        show={showOptions}
        targetElement={() => selectRef.current}
        floatingProps={dropdownFloatingProps}
      >
        {options.map(({ title, value: val }, index) => (
          <SelectOption<T>
            key={`${index}-${val}`}
            isActive={val === value}
            onSelect={onOptionSelect}
            title={title}
            value={val}
          />
        ))}
      </Dropdown>
    </div>
  );
}
