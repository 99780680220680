import { Route } from "react-router";
import { policyPaths } from "router";
import { PrivacyPolicyEn } from "pages/Settings/Documents/PrivacyPolicyEn";
import { CookiePolicyEn } from "pages/Settings/Documents/CookiePolicyEn";
import { TermsAndConditionsEn } from "pages/Settings/Documents/TermsAndConditionsEn";

export const PolicyRoutes = (
  <>
    <Route path={policyPaths.privacyPolicy} element={<PrivacyPolicyEn />} />
    <Route path={policyPaths.cookiePolicy} element={<CookiePolicyEn />} />
    <Route path={policyPaths.termsAndConditions} element={<TermsAndConditionsEn />} />
  </>
);
