import * as React from "react";
import { SVGProps } from "react";
const SvgSave = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2.25A2.75 2.75 0 0 0 2.25 5v14A2.75 2.75 0 0 0 5 21.75h14A2.75 2.75 0 0 0 21.75 19V8a.75.75 0 0 0-.22-.53l-5-5a.75.75 0 0 0-.53-.22H5Zm0 1.5A1.25 1.25 0 0 0 3.75 5v14A1.25 1.25 0 0 0 5 20.25h1.25V13a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 .75.75v7.25H19A1.25 1.25 0 0 0 20.25 19V8.31l-4.5-4.5V8a.75.75 0 0 1-.75.75H7A.75.75 0 0 1 6.25 8V3.75H5Zm2.75 0v3.5h6.5v-3.5h-6.5Zm0 10v6.5h8.5v-6.5h-8.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSave;
