/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import type { GeolocationModel } from './GeolocationModel';
import {
    GeolocationModelFromJSON,
    GeolocationModelFromJSONTyped,
    GeolocationModelToJSON,
} from './GeolocationModel';

/**
 * 
 * @export
 * @interface OrganizationDetailsResponseModel
 */
export interface OrganizationDetailsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailsResponseModel
     */
    rowVersion: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailsResponseModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailsResponseModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailsResponseModel
     */
    nameUpper: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailsResponseModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailsResponseModel
     */
    city: string;
    /**
     * 
     * @type {CountryType}
     * @memberof OrganizationDetailsResponseModel
     */
    country: CountryType;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDetailsResponseModel
     */
    state?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailsResponseModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {GeolocationModel}
     * @memberof OrganizationDetailsResponseModel
     */
    geolocation?: GeolocationModel;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailsResponseModel
     */
    phone: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDetailsResponseModel
     */
    isMain: boolean;
}

/**
 * Check if a given object implements the OrganizationDetailsResponseModel interface.
 */
export function instanceOfOrganizationDetailsResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rowVersion" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "nameUpper" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "isMain" in value;

    return isInstance;
}

export function OrganizationDetailsResponseModelFromJSON(json: any): OrganizationDetailsResponseModel {
    return OrganizationDetailsResponseModelFromJSONTyped(json, false);
}

export function OrganizationDetailsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationDetailsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowVersion': json['RowVersion'],
        'id': json['Id'],
        'name': json['Name'],
        'nameUpper': json['NameUpper'],
        'street': json['Street'],
        'city': json['City'],
        'country': CountryTypeFromJSON(json['Country']),
        'state': !exists(json, 'State') ? undefined : json['State'],
        'zipCode': !exists(json, 'ZipCode') ? undefined : json['ZipCode'],
        'geolocation': !exists(json, 'Geolocation') ? undefined : GeolocationModelFromJSON(json['Geolocation']),
        'phone': json['Phone'],
        'isMain': json['IsMain'],
    };
}

export function OrganizationDetailsResponseModelToJSON(value?: OrganizationDetailsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'RowVersion': value.rowVersion,
        'Id': value.id,
        'Name': value.name,
        'NameUpper': value.nameUpper,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': value.state,
        'ZipCode': value.zipCode,
        'Geolocation': GeolocationModelToJSON(value.geolocation),
        'Phone': value.phone,
        'IsMain': value.isMain,
    };
}

