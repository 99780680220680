import { TestsTableStateProvider } from "components/AppTables/AllTestsTable/context";
import { LaboratoryTestsTable } from "components/AppTables/LaboratoryTestsTable";
import { PatientLaboratoryContextProvider } from "pages/PatientsPage/Details/LaboratoryContext";
import { TestsPage } from "pages/TestsPage";
import { EventEnrichedTabsForLabaratory } from "pages/TestsPage/EventEnrichedTabsForLabaratory";
import { Navigate, Route } from "react-router";
import { mainRoutePaths } from "router";

export const TestsRoutes = (
  <Route
    path={mainRoutePaths.tests}
    element={
      <TestsTableStateProvider>
        <PatientLaboratoryContextProvider>
          <TestsPage>
            <EventEnrichedTabsForLabaratory />
          </TestsPage>
        </PatientLaboratoryContextProvider>
      </TestsTableStateProvider>
    }
  >
    <Route
      path="all"
      element={
        <LaboratoryTestsTable
          appliedColumns={[
            "measurementType",
            "date",
            "patient",
            "testedBy",
            "testResult",
            "testingMethod",
            "actions",
          ]}
          patientAppliedColumns={[
            "measurementType",
            "date",
            "testResult",
            "testedBy",
            "testingMethod",
          ]}
        />
      }
    />
    <Route
      path="serumCreatinine"
      element={
        <LaboratoryTestsTable
          measurementType="SerumCreatinine"
          measurementTypeItem="SerumCreatinine"
          appliedColumns={[
            "measurementType",
            "date",
            "serumCreatinine",
            "patient",
            "testedBy",
            "testingMethod",
            "actions",
          ]}
          patientAppliedColumns={[
            "measurementType",
            "date",
            "serumCreatinine",
            "testedBy",
            "testingMethod",
          ]}
        />
      }
    />
    <Route
      path="egfr"
      element={
        <LaboratoryTestsTable
          measurementType="SerumCreatinine"
          measurementTypeItem="Egfr"
          appliedColumns={[
            "measurementType",
            "date",
            "eGFR",
            "patient",
            "testedBy",
            "testingMethod",
            "actions",
          ]}
          patientAppliedColumns={["measurementType", "date", "eGFR", "testedBy", "testingMethod"]}
        />
      }
    />

    <Route
      path="bmi"
      element={
        <LaboratoryTestsTable
          measurementType="BMI"
          measurementTypeItem="Bmi"
          appliedColumns={[
            "measurementType",
            "date",
            "bmi",
            "patient",
            "testedBy",
            "testingMethod",
            "actions",
          ]}
          patientAppliedColumns={["measurementType", "date", "bmi", "testedBy", "testingMethod"]}
        />
      }
    />

    <Route
      path="height"
      element={
        <LaboratoryTestsTable
          measurementType="BMI"
          measurementTypeItem="Height"
          appliedColumns={[
            "measurementType",
            "date",
            "height",
            "patient",
            "testedBy",
            "testingMethod",
            "actions",
          ]}
          patientAppliedColumns={["measurementType", "date", "height", "testedBy", "testingMethod"]}
        />
      }
    />

    <Route
      path="weight"
      element={
        <LaboratoryTestsTable
          measurementType="BMI"
          measurementTypeItem="Weight"
          appliedColumns={[
            "measurementType",
            "date",
            "weight",
            "patient",
            "testedBy",
            "testingMethod",
            "actions",
          ]}
          patientAppliedColumns={["measurementType", "date", "weight", "testedBy", "testingMethod"]}
        />
      }
    />

    <Route
      path="bloodPressure"
      element={
        <LaboratoryTestsTable
          measurementType="BloodPressure"
          appliedColumns={[
            "measurementType",
            "date",
            "bloodPressure",
            "patient",
            "testedBy",
            "testingMethod",
            "actions",
          ]}
          patientAppliedColumns={[
            "measurementType",
            "date",
            "bloodPressure",
            "testedBy",
            "testingMethod",
          ]}
        />
      }
    />

    <Route
      path="glucose"
      element={
        <LaboratoryTestsTable
          measurementType="Glucose"
          appliedColumns={[
            "measurementType",
            "date",
            "glucose",
            "patient",
            "testedBy",
            "testingMethod",
            "actions",
          ]}
          patientAppliedColumns={[
            "measurementType",
            "date",
            "glucose",
            "testedBy",
            "testingMethod",
          ]}
        />
      }
    />

    <Route
      path="urineCreatinine"
      element={
        <LaboratoryTestsTable
          measurementType="UACR"
          measurementTypeItem="UrineCreatinine"
          appliedColumns={[
            "measurementType",
            "date",
            "urineCreatinine",
            "patient",
            "testedBy",
            "testingMethod",
            "actions",
          ]}
          patientAppliedColumns={[
            "measurementType",
            "date",
            "urineCreatinine",
            "testedBy",
            "testingMethod",
          ]}
        />
      }
    />

    <Route
      path="urineAlbumin"
      element={
        <LaboratoryTestsTable
          measurementType="UACR"
          measurementTypeItem="UrineAlbumin"
          appliedColumns={[
            "measurementType",
            "date",
            "urineAlbumin",
            "patient",
            "testedBy",
            "testingMethod",
            "actions",
          ]}
          patientAppliedColumns={[
            "measurementType",
            "date",
            "urineAlbumin",
            "testedBy",
            "testingMethod",
          ]}
        />
      }
    />

    <Route
      path="uacr"
      element={
        <LaboratoryTestsTable
          measurementType="UACR"
          measurementTypeItem="Uacr"
          appliedColumns={[
            "measurementType",
            "date",
            "uacr",
            "patient",
            "testedBy",
            "testingMethod",
            "actions",
          ]}
          patientAppliedColumns={["measurementType", "date", "uacr", "testedBy", "testingMethod"]}
        />
      }
    />

    <Route
      path="semiQuantitativeUacr"
      element={
        <LaboratoryTestsTable
          measurementType="SemiQuantitativeUACR"
          measurementTypeItem="SemiQuantitativeUacr"
          appliedColumns={[
            "measurementType",
            "date",
            "squacr",
            "patient",
            "testedBy",
            "testingMethod",
            "actions",
          ]}
          patientAppliedColumns={["measurementType", "date", "squacr", "testedBy", "testingMethod"]}
        />
      }
    />

    <Route path="*" element={<Navigate to="all" replace />} />
  </Route>
);
