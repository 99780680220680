import { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import "./Unauthorized.scss";
import classNames from "classnames";
import { Button } from "libs/ui";
import { useTranslation } from "react-i18next";

export function Unauthorized() {
  const { t } = useTranslation("translation", {
    keyPrefix: "Unauthorized",
  });
  const [countDown, setCountDown] = useState(30);
  const timer = useRef<any | null>(null);

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }, []);

  useEffect(() => {
    if (timer.current) {
      return;
    }

    timer.current = setInterval(() => {
      setCountDown(countdown => {
        if (countdown === 0) {
          logout();
          return 0;
        }

        return countdown - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer.current);
    };
  }, [logout]);

  return (
    <div className={classNames("UI-Components Modal")}>
      <div className="ActionModal">
        <div className="ActionModal__content">
          <h3 className="ActionModal__title">{t("title")}</h3>
          <p className="ActionModal__text">{t("text", { countDown })}</p>
          <div className="ActionModal__action">
            <Button buttonSize={"medium"} buttonType={"red"} onClick={logout}>
              {t("logout")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function injectUnauthorized() {
  const name = "securityDiv";

  let securityDiv = document.querySelector(`#${name}`);
  const rootDiv = document.querySelector(`#root`);

  if (securityDiv) {
    return;
  }

  if (rootDiv) {
    rootDiv.remove();
  }

  securityDiv = document.createElement("div");
  securityDiv.id = `${name}`;
  document.body.append(securityDiv);

  const root = ReactDOM.createRoot(securityDiv);

  root.render(<Unauthorized />);
}
