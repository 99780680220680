import classNames from "classnames";
import React, { useRef } from "react";
import { FieldLoader } from "../FieldLoader";
import { FieldValidation } from "../FieldValidation";
import { FormFieldBasic } from "../models";
import { isKeyboardClickKey } from "../utils";
import "./Switch.scss";

interface SwitchProps extends FormFieldBasic {
  loading?: boolean;
}

export function Switch({
  label,
  className,
  checked,
  validation,
  loading,
  name = "switch",
  ...props
}: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  SwitchProps) {
  const { errorText, successText } = validation ?? {};

  const refInput = useRef<HTMLInputElement>(null);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (isKeyboardClickKey(e.key)) {
      e.preventDefault();
      refInput.current?.click();
    }
  };

  if (loading) {
    return <FieldLoader data-testid={`${name}-loader`} />;
  }

  return (
    <div className={classNames("UI-Components Switch", className)}>
      {label && (
        <label
          aria-label={label}
          className={classNames(
            "Switch__label",
            successText && "Switch__label--success",
            errorText && "Switch__label--error",
          )}
        >
          {label}
        </label>
      )}
      <label data-testid={`${name}-switch`} className="Switch__toggle">
        <input
          data-testid={`${name}-checkbox`}
          ref={refInput}
          hidden
          type="checkbox"
          {...props}
          checked={checked}
        />
        <div
          className={classNames("Switch__path", checked && "Switch__path--active")}
          tabIndex={0}
          role="checkbox"
          aria-checked={checked ? "true" : "false"}
          onKeyDown={onKeyDown}
        >
          <div className={classNames("Switch__button", checked && "Switch__button--active")} />
        </div>
      </label>

      <FieldValidation {...validation} />
    </div>
  );
}
