import { Enum } from "utils/createGlobalConfigStore";
import { Option } from "libs/ui/Select/Select.model";

/**
 *
 * @deprecated try to use customEnumsToSelectOptions
 */
export function statesToSelectOptions(data: Enum[]) {
  return data.map(
    el =>
      ({
        title: el.N,
        value: el.V,
      }) satisfies Option<Enum["V"]>,
  );
}
