import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getLocalHeight } from "utils/converters/getLocalHeight";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";

export function useGetLocalHeightLabel() {
  const { globalPreference } = useGlobalPreferenceContext();
  const { heightUnit } = useGetUserPreferredMeasurementUnits();
  const { t } = useTranslation("measurements", { keyPrefix: "Measurements" });

  const getLocalHeightLabel = useCallback(
    (height: number) => {
      const localHeight = getLocalHeight(Number(height), globalPreference?.regionType);
      const localHeightFeet = localHeight.feet.length ? localHeight.feet : "0";

      return globalPreference?.measureUnitType === "Metric"
        ? localHeight.cm.concat(` ${heightUnit}`)
        : t("height-imperial", { feet: localHeightFeet, inch: localHeight.inches });
    },
    [globalPreference?.measureUnitType, globalPreference?.regionType, heightUnit, t],
  );

  return { getLocalHeightLabel };
}
