import * as React from "react";
import { SVGProps } from "react";
const SvgArrowDownRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.643 17.237a.747.747 0 0 0 .057-.287v-9.9a.75.75 0 1 0-1.5 0v8.09L7.58 6.52a.75.75 0 0 0-1.06 1.06l8.62 8.62H7.05a.75.75 0 0 0 0 1.5h9.9"
      fill="currentColor"
    />
    <path d="M16.952 17.7a.747.747 0 0 0 .69-.463" fill="currentColor" />
  </svg>
);
export default SvgArrowDownRight;
