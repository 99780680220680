import * as React from "react";
import { SVGProps } from "react";
const SvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 6.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5ZM7.75 9a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 19A2.75 2.75 0 0 0 5 21.75h14A2.75 2.75 0 0 0 21.75 19V5A2.75 2.75 0 0 0 19 2.25H5A2.75 2.75 0 0 0 2.25 5v14ZM5 3.75c-.69 0-1.25.56-1.25 1.25v14c0 .594.415 1.092.97 1.219l10.75-10.75a.75.75 0 0 1 1.06 0l3.72 3.72V5c0-.69-.56-1.25-1.25-1.25H5Zm11 7.31-9.19 9.19H19c.69 0 1.25-.56 1.25-1.25v-3.69L16 11.06Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgImage;
