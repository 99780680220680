import { MeasurementType } from "api/query";
import { MeasurementItemUIModel } from "models/TestModels";

export const sortTestFormFields = (
  measurementType: MeasurementType | undefined,
  items: MeasurementItemUIModel[],
) => {
  if (measurementType === undefined) return;

  switch (measurementType) {
    case "UACR":
      items.sort((a, b) => {
        if (a.type === "UrineCreatinine") {
          return -1; // 'a' should come first
        } else if (b.type === "UrineCreatinine") {
          return 1; // 'b' should come first
        } else {
          return 0;
        }
      });
      return;
    case "BloodPressure":
      items.sort((a, b) => {
        if (a.type === "Systolic") {
          return -1; // 'a' should come first
        } else if (b.type === "Systolic") {
          return 1; // 'b' should come first
        } else {
          return 0;
        }
      });
      return;
  }
};
