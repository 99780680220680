import { Selection, SelectFilter, SelectFilterResult } from "libs/ui";
import { GlobalPreference } from "models/globalPreference";
import { useTranslation } from "react-i18next";
import { PreferenceSection } from "./PreferenceSection";
import { RegionalPreview } from "./RegionalPreview";
import { Option } from "libs/ui/Select/Select.model";
import { languagesToSelectOptions } from "utils/mappers/languagesToSelectOptions";
import { measureUnitToSelectOptions } from "utils/mappers/measureUnitToSelectOptions";
import { regionTypeToSelectOptions } from "utils/mappers/regionTypeToSelectOptions";
import { timezoneTypeToSelectOptions } from "utils/mappers/timezoneTypeToSelectOptions";
import { FALLBACK_LANG } from "config/translations";

interface GlobalPreferenceFormProps {
  formData: GlobalPreference;
  onSelect: (value: string | undefined, inputKey: keyof GlobalPreference) => void;
}

const setRenderSelected =
  <T extends any>(testId: string) =>
  (props: Option<T> | undefined) => (
    <SelectFilterResult data-testval={props?.value ?? ""} data-testid={testId} hasValue={!!props}>
      {props?.title ?? ""}
    </SelectFilterResult>
  );

export function GlobalPreferenceForm({ formData, onSelect }: GlobalPreferenceFormProps) {
  const { t } = useTranslation("translation", { keyPrefix: "GlobalPreferenceForm" });

  return (
    <>
      <PreferenceSection title={t("language.title")} subtitle={t("language.subtitle")}>
        <Selection
          data-testid={"languageType"}
          label={t("language.placeholder")}
          options={languagesToSelectOptions()}
          //!!! TODO: value={isSupportedLanguage(formData.languageType) ? formData.languageType : FALLBACK_LANG}
          // unable language select once we introduce multi lang translations
          value={FALLBACK_LANG}
          disabled
          onSelect={value => onSelect(value, "languageType")}
        />
      </PreferenceSection>

      <PreferenceSection
        title={t("regional.title")}
        subtitle={t("regional.subtitle")}
        className="RegionalSection"
      >
        <SelectFilter
          label={t("regional.placeholder_region")}
          options={regionTypeToSelectOptions()}
          value={formData.regionType}
          onSelect={value => onSelect(value, "regionType")}
          renderSelected={setRenderSelected("regionType")}
        />

        <SelectFilter
          label={t("regional.placeholder_timezone")}
          options={timezoneTypeToSelectOptions()}
          value={formData.timeZoneType}
          onSelect={value => onSelect(value, "timeZoneType")}
          renderSelected={setRenderSelected("timeZoneType")}
        />

        <RegionalPreview locale={formData.regionType} timeZone={formData.timeZoneType} />
      </PreferenceSection>

      <PreferenceSection title={t("measure.title")} subtitle={t("measure.subtitle")}>
        <Selection
          data-testid={"measureUnitType"}
          label={t("measure.placeholder")}
          options={measureUnitToSelectOptions()}
          value={formData.measureUnitType}
          onSelect={value => onSelect(value, "measureUnitType")}
        />
      </PreferenceSection>
    </>
  );
}
