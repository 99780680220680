/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import type { StateType } from './StateType';
import {
    StateTypeFromJSON,
    StateTypeFromJSONTyped,
    StateTypeToJSON,
} from './StateType';

/**
 * 
 * @export
 * @interface FormsDefaultValuesOrganizationModel
 */
export interface FormsDefaultValuesOrganizationModel {
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesOrganizationModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesOrganizationModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesOrganizationModel
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesOrganizationModel
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesOrganizationModel
     */
    nameUpper?: string | null;
    /**
     * 
     * @type {CountryType}
     * @memberof FormsDefaultValuesOrganizationModel
     */
    country?: CountryType;
    /**
     * 
     * @type {StateType}
     * @memberof FormsDefaultValuesOrganizationModel
     */
    state?: StateType;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesOrganizationModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesOrganizationModel
     */
    rowVersion?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormsDefaultValuesOrganizationModel
     */
    isMain?: boolean | null;
}

/**
 * Check if a given object implements the FormsDefaultValuesOrganizationModel interface.
 */
export function instanceOfFormsDefaultValuesOrganizationModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormsDefaultValuesOrganizationModelFromJSON(json: any): FormsDefaultValuesOrganizationModel {
    return FormsDefaultValuesOrganizationModelFromJSONTyped(json, false);
}

export function FormsDefaultValuesOrganizationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormsDefaultValuesOrganizationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'nameUpper': !exists(json, 'nameUpper') ? undefined : json['nameUpper'],
        'country': !exists(json, 'country') ? undefined : CountryTypeFromJSON(json['country']),
        'state': !exists(json, 'state') ? undefined : StateTypeFromJSON(json['state']),
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'rowVersion': !exists(json, 'rowVersion') ? undefined : json['rowVersion'],
        'isMain': !exists(json, 'isMain') ? undefined : json['isMain'],
    };
}

export function FormsDefaultValuesOrganizationModelToJSON(value?: FormsDefaultValuesOrganizationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'phone': value.phone,
        'street': value.street,
        'city': value.city,
        'nameUpper': value.nameUpper,
        'country': CountryTypeToJSON(value.country),
        'state': StateTypeToJSON(value.state),
        'zipCode': value.zipCode,
        'rowVersion': value.rowVersion,
        'isMain': value.isMain,
    };
}

