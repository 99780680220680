export const MEASUREMENT_UNITS = {
  g: "g",
  kg: "kg",
  // TODO remove one of these
  kgm2: "kg/m\u00b2",
  kgM2: "kg/m\u00b2",
  lb: "lb",
  lbin2: "lb/inch\u00b2",
  mgdL: "mg/dL",
  mLmin173m2: "mL/min/1.73m\u00b2",
  umolL: "μmol/L",
  mmHg: "mmHg",
  mgg: "mg/g",
  mgL: "mg/L",
  cm: "cm",
  ft: "ft",
  mm: "mm",
  none: "none",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MEASUREMENT_UNITS = (typeof MEASUREMENT_UNITS)[keyof typeof MEASUREMENT_UNITS];

type ReversedMeasurementUnits = {
  [K in (typeof MEASUREMENT_UNITS)[keyof typeof MEASUREMENT_UNITS]]: Extract<
    keyof typeof MEASUREMENT_UNITS,
    {
      [P in keyof typeof MEASUREMENT_UNITS]: (typeof MEASUREMENT_UNITS)[P] extends K ? P : never;
    }[keyof typeof MEASUREMENT_UNITS]
  >;
};

export const REVERSED_MEASUREMENT_UNITS: ReversedMeasurementUnits = {
  g: "g",
  kg: "kg",
  "kg/m²": "kgm2", // Combined kgm2 and kgM2 to 'kg/m²'
  lb: "lb",
  "lb/inch²": "lbin2",
  "mg/dL": "mgdL",
  "mL/min/1.73m²": "mLmin173m2",
  "μmol/L": "umolL",
  mmHg: "mmHg",
  "mg/g": "mgg",
  "mg/L": "mgL",
  cm: "cm",
  ft: "ft",
  mm: "mm",
  none: "none",
};
