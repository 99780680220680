export type EntityStatus =
  | "Active"
  | "Invited"
  | "Deactivated"
  | "Inactive"
  | "Deleted"
  | "NoAccess";

export function resolveUserStatus(status?: EntityStatus) {
  if (!status || status === "Invited") {
    return undefined;
  }

  return status === "Active" ? "ok" : "error";
}

export function hasFilter(value: string, options: string[]) {
  return options.some(el => el === value);
}

export function resolveRenderCell<T>(columns: T[], name: T): boolean;
export function resolveRenderCell<T, B>(
  columns: T[],
  name: T,
  resultMapper?: (() => B) | undefined,
): B;
export function resolveRenderCell<T, B>(
  columns: T[],
  name: T,
  resultMapper: (() => B) | undefined,
): B;
export function resolveRenderCell<T, B>(
  columns: T[],
  name: T,
  resultMapper?: (() => B) | undefined,
) {
  const found = columns.some(el => el === name);

  if (resultMapper === undefined) {
    return found;
  }

  return found ? resultMapper() : undefined;
}

export function getFilterOptionsPerConfiguration<T>(
  defaultOptions: Record<string, any>,
  filterOptions?: T[],
): Record<string, any> {
  if (!filterOptions) return defaultOptions;

  return Object.fromEntries(
    Object.entries(defaultOptions).filter(([key]) => filterOptions.includes(key as T)),
  );
}
