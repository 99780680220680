import { PropsWithChildren, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { createSafeContext } from "utils/createSafeContext";
import { useMakePatientDetailState } from "./model";

interface PatientDetailsServiceContext {
  detailsService: ReturnType<typeof useMakePatientDetailState>;
}
const Context = createSafeContext<PatientDetailsServiceContext>();

export const usePatientDetailsServiceContext = Context.hook;

export const PatientDetailsServiceContextProvider = ({ children }: PropsWithChildren<object>) => {
  const { organizationId = "", patientId = "" } = useParams<RouteParams["patientDetails"]>();

  const detailsService = useMakePatientDetailState(patientId, organizationId);

  const value = useMemo<PatientDetailsServiceContext>(() => ({ detailsService }), [detailsService]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
