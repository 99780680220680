import { Button, Icon, SideModal, SideModalElements, SideModalProps } from "libs/ui";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import "./FilterSideModal.scss";

interface FilterSideModalFooterButton {
  onClick: () => void;
  disabled?: boolean;
}

interface FilterSideModalProps extends SideModalProps {
  submitButton: FilterSideModalFooterButton;
  clearAllButton: FilterSideModalFooterButton;
}

export function FilterSideModal({
  show,
  submitButton,
  closeModal,
  clearAllButton,
  children,
  ...rest
}: PropsWithChildren<FilterSideModalProps>) {
  const { t } = useTranslation("modals", { keyPrefix: "FilterSideModal" });

  return (
    <SideModal show={show} {...rest} bodyClass="FilterSideModal">
      <SideModalElements.Header closeModal={closeModal}>
        <Icon icon="Filter" />
        {t("title")}
      </SideModalElements.Header>

      <SideModalElements.Separator />

      <div className="FilterSideModal__body">{children}</div>

      <SideModalElements.Footer>
        <Button
          disabled={closeModal?.disabled}
          onClick={closeModal?.onClick}
          buttonType="grey"
          buttonSize="medium"
        >
          {t("buttonCancel")}
        </Button>

        <Button
          onClick={submitButton.onClick}
          buttonType="primary"
          buttonSize="medium"
          disabled={submitButton.disabled}
          type="submit"
        >
          {t("buttonApply")}
        </Button>

        <Button
          onClick={clearAllButton.onClick}
          disabled={clearAllButton.disabled}
          buttonSize="medium"
          buttonType="link-primary"
          className="FilterSideModal__ClearAllButton"
        >
          {t("buttonClearAll")}
        </Button>
      </SideModalElements.Footer>
    </SideModal>
  );
}
