import * as React from "react";
import { SVGProps } from "react";
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 1.422a3.578 3.578 0 0 0-2.53 1.048l-13.5 13.5a.75.75 0 0 0-.194.333l-1.5 5.5a.75.75 0 0 0 .921.92l5.5-1.5a.75.75 0 0 0 .333-.193l13.5-13.5A3.578 3.578 0 0 0 19 1.422Zm-.795 1.658a2.078 2.078 0 0 1 2.265 3.39L18.5 8.44 15.56 5.5l1.97-1.97c.193-.193.422-.346.675-.45ZM14.5 6.56 4.171 16.89 3.07 20.93 7.11 19.83 17.44 9.5 14.5 6.56Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEdit;
