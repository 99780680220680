import { countriesToSelectOptions } from "utils/mappers/countriesToSelectOptions";
import { statesToSelectOptions } from "utils/mappers/statesToSelectOptions";
import { userStatusesToSelectOptions } from "utils/mappers/userStatusesToSelectOptions";

export function BASIC_FILTER_OPTIONS() {
  return {
    countries: countriesToSelectOptions(),
    states: statesToSelectOptions([]),
    userStatuses: userStatusesToSelectOptions(),
  } as const;
}

export interface FilterSideModalState<K> {
  isFilterSideModalOpen: boolean;
  openFilterModal: (value?: K) => void;
  closeModal: () => void;
  activeFilters: K[];
}
