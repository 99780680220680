import * as React from "react";
import { SVGProps } from "react";
const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5ZM8.75 7a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0Z"
      fill="currentColor"
    />
    <path
      d="M8 14.25A4.75 4.75 0 0 0 3.25 19v2a.75.75 0 0 0 1.5 0v-2A3.25 3.25 0 0 1 8 15.75h8A3.25 3.25 0 0 1 19.25 19v2a.75.75 0 0 0 1.5 0v-2A4.75 4.75 0 0 0 16 14.25H8Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgUser;
