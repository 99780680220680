import * as React from "react";
import { SVGProps } from "react";
const SvgFile = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.25A2.75 2.75 0 0 0 3.25 4v16A2.75 2.75 0 0 0 6 22.75h12A2.75 2.75 0 0 0 20.75 20V9a.748.748 0 0 0-.22-.53l-7-7a.751.751 0 0 0-.53-.22m-.75 1.5H6A1.25 1.25 0 0 0 4.75 4v16A1.25 1.25 0 0 0 6 21.25h12A1.25 1.25 0 0 0 19.25 20V9.75H13a.75.75 0 0 1-.75-.75V2.75Zm5.94 5.5-4.44-4.44v4.44h4.44Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFile;
