/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CkdRisk = {
    LowRisk: 'LowRisk',
    ModerateRisk: 'ModerateRisk',
    HighRisk: 'HighRisk',
    VeryHighRisk: 'VeryHighRisk'
} as const;
export type CkdRisk = typeof CkdRisk[keyof typeof CkdRisk];


export function CkdRiskFromJSON(json: any): CkdRisk {
    return CkdRiskFromJSONTyped(json, false);
}

export function CkdRiskFromJSONTyped(json: any, ignoreDiscriminator: boolean): CkdRisk {
    return json as CkdRisk;
}

export function CkdRiskToJSON(value?: CkdRisk | null): any {
    return value as any;
}

