import * as React from "react";
import { SVGProps } from "react";
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.24 9.22a.75.75 0 0 1 0 1.06l-5 5a.748.748 0 0 1-1.06 0l-5-5a.75.75 0 1 1 1.06-1.06l3.72 3.72V2.75a.75.75 0 0 1 1.5 0v10.19l3.72-3.72a.75.75 0 0 1 1.06 0Z"
      fill="currentColor"
    />
    <path
      d="M1.005 15.999A6.251 6.251 0 0 0 7.21 21.5h9a6.251 6.251 0 0 0 6.206-5.501c.05-.412-.291-.749-.706-.749-.414 0-.743.338-.808.747A4.751 4.751 0 0 1 16.21 20h-9a4.751 4.751 0 0 1-4.691-4.003c-.065-.409-.394-.747-.809-.747-.414 0-.754.337-.705.749Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgDownload;
