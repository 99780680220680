/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddOrganizationRequestModel,
  CommonProblemDetailsModel,
  UpdateOrganizationRequestModel,
} from '../models/index';
import {
    AddOrganizationRequestModelFromJSON,
    AddOrganizationRequestModelToJSON,
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    UpdateOrganizationRequestModelFromJSON,
    UpdateOrganizationRequestModelToJSON,
} from '../models/index';

export interface OrganizationsOrganizationEntityIdPutRequest {
    organizationEntityId: string;
    acceptVersion?: string;
    updateOrganizationRequestModel?: UpdateOrganizationRequestModel;
}

export interface OrganizationsPostRequest {
    acceptVersion?: string;
    addOrganizationRequestModel?: AddOrganizationRequestModel;
}

/**
 * OrganizationsApi - interface
 * 
 * @export
 * @interface OrganizationsApiInterface
 */
export interface OrganizationsApiInterface {
    /**
     * 
     * @param {string} organizationEntityId 
     * @param {string} [acceptVersion] 
     * @param {UpdateOrganizationRequestModel} [updateOrganizationRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    organizationsOrganizationEntityIdPutRaw(requestParameters: OrganizationsOrganizationEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationEntityIdPut(requestParameters: OrganizationsOrganizationEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} [acceptVersion] 
     * @param {AddOrganizationRequestModel} [addOrganizationRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    organizationsPostRaw(requestParameters: OrganizationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsPost(requestParameters: OrganizationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class OrganizationsApi extends runtime.BaseAPI implements OrganizationsApiInterface {

     /**
     */
    async organizationsOrganizationEntityIdPutRawOriginal(requestParameters: OrganizationsOrganizationEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationEntityId === null || requestParameters.organizationEntityId === undefined) {
            throw new runtime.RequiredError('organizationEntityId','Required parameter requestParameters.organizationEntityId was null or undefined when calling organizationsOrganizationEntityIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationEntityId}`.replace(`{${"organizationEntityId"}}`, encodeURIComponent(String(requestParameters.organizationEntityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationRequestModelToJSON(requestParameters.updateOrganizationRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationEntityIdPutRaw(requestParameters: OrganizationsOrganizationEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationEntityId === null || requestParameters.organizationEntityId === undefined) {
            throw new runtime.RequiredError('organizationEntityId','Required parameter requestParameters.organizationEntityId was null or undefined when calling organizationsOrganizationEntityIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationEntityId}`.replace(`{${"organizationEntityId"}}`, encodeURIComponent(String(requestParameters.organizationEntityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationRequestModelToJSON(requestParameters.updateOrganizationRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationEntityIdPut(requestParameters: OrganizationsOrganizationEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationEntityIdPutRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsPostRawOriginal(requestParameters: OrganizationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrganizationRequestModelToJSON(requestParameters.addOrganizationRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsPostRaw(requestParameters: OrganizationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrganizationRequestModelToJSON(requestParameters.addOrganizationRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsPost(requestParameters: OrganizationsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsPostRaw(requestParameters, initOverrides);
    }

}
