import { offset } from "@floating-ui/react-dom";
import classNames from "classnames";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { Outlet, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { Selection } from "..";
import { TabVariantType } from "../Tabs/TabItem";
import "./TabItem.scss";
import "./TabsForRouter.scss";

interface TabItemProps {
  // used for matching if we are on the route or redirect
  routePath: string;
  /**
   * for cases like
   * /graph -> you want a group
   * /graph/all -> but you want to redirect to default one
   */
  to?: string;
  disabled?: boolean;
  label: string;
  subRoute?: boolean;
  tabVariant?: TabVariantType;
  onClick?: (routePath: string) => void;
}

type TabsProps = {
  selectBox?: boolean;
  subRoute?: boolean;
  className?: string;
  tabs: readonly TabItemProps[];
};

/**
 * TabsForRouter is for multilayer tabs
 * for now, its not the same as Tabs component
 */

function TabItem({
  to,
  routePath,
  disabled = false,
  label,
  subRoute,
  tabVariant = "classic",
  onClick: onTabClick,
}: Readonly<TabItemProps>) {
  const resolved = useResolvedPath(routePath);
  const match = useMatch({ path: resolved.pathname, end: !!subRoute });
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    if (onTabClick) {
      onTabClick(to ?? routePath);
    }
    navigate(to ?? routePath);
  }, [navigate, onTabClick, routePath, to]);

  const tabItem = useMemo(() => (subRoute ? "TabSubItem" : "TabItem"), [subRoute]);

  return (
    <li
      className={classNames(tabItem, `${tabItem}--${tabVariant}`)}
      data-testid={tabItem}
      data-route={to ?? routePath}
    >
      <button
        onClick={onClick}
        className={classNames(
          `${tabItem}__button`,
          { [`${tabItem}__button--${tabVariant}-active`]: match },
          `${tabItem}__button--${tabVariant}`,
        )}
        disabled={disabled}
      >
        {label}
      </button>
    </li>
  );
}

export function TabsForRouter({
  tabs = [],
  className,
  selectBox,
  subRoute = false,
  children,
}: PropsWithChildren<TabsProps>) {
  const urlSections = location.pathname.split("/");

  const navigate = useNavigate();
  const matchedTab = tabs.find(tab => urlSections.includes(tab.routePath));

  const onSelectBoxItemClick = (tab?: TabItemProps) => {
    if (tab) {
      navigate(tab.to ?? tab.routePath);

      if (tab.onClick) {
        tab.onClick(tab.to ?? tab.routePath);
      }
    }
  };

  return (
    <div
      className={classNames(
        "UI-Components TabsForRouter",
        { "TabsForRouter--subRoute": subRoute },
        className,
      )}
    >
      <div
        className={classNames(
          !!selectBox === true ? "Tabs__container-selectBox" : "Tabs__container",
        )}
      >
        {selectBox ? (
          <Selection<TabItemProps>
            variant="regular-inline"
            value={matchedTab ?? tabs[0]}
            onSelect={onSelectBoxItemClick}
            options={tabs.map(tab => ({
              title: tab.label,
              value: tab,
            }))}
            dropdownFloatingProps={{
              strategy: "absolute",
              placement: "bottom-start",
              middleware: [
                offset({
                  crossAxis: 3,
                }),
              ],
            }}
          />
        ) : (
          <ul className="Tabs__list">
            {tabs.map(tab => {
              return <TabItem key={`${tab.label}-${tab.routePath}`} subRoute={subRoute} {...tab} />;
            })}
          </ul>
        )}
      </div>
      {children ? children : <Outlet />}
    </div>
  );
}
