import {
  CreateGlobalConfigStoreRetVal,
  createGlobalConfigStore,
} from "utils/createGlobalConfigStore";

function getVersion() {
  const css = "color: red; font-weight: bold;font-size: 12px;";
  const text = JSON.stringify(
    {
      version: process.env.VERSION ?? "NOT SET",
      branch: process.env.VITE_APP_BRANCH ?? process.env.AWS_BRANCH ?? "NOT SET",
      commit_id: process.env.VITE_APP_COMMIT_ID ?? process.env.AWS_COMMIT_ID ?? "NOT SET",
      event_host: process.env.VITE_APP_UPDATE_HOST,
      query_host: process.env.VITE_APP_QUERY_HOST,
      config_host: process.env.VITE_APP_CONFIG_HOST,
    },
    null,
    4,
  );
  console.log(`%c${text}`, css);
}

export const globalConfigStore = createGlobalConfigStore
  ? createGlobalConfigStore()
  : (null as unknown as CreateGlobalConfigStoreRetVal);

declare global {
  interface Window {
    globalConfigStore: CreateGlobalConfigStoreRetVal | null;
    getVersion: any;
  }
}

window.globalConfigStore = globalConfigStore;
window.getVersion = getVersion;
