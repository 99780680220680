import * as React from "react";
import { SVGProps } from "react";
const SvgFilePlus = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.75 12a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V18a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.25A2.75 2.75 0 0 0 3.25 4v16A2.75 2.75 0 0 0 6 22.75h12A2.75 2.75 0 0 0 20.75 20V8a.748.748 0 0 0-.22-.53l-6-6a.751.751 0 0 0-.53-.22m-.75 1.5H6A1.25 1.25 0 0 0 4.75 4v16A1.25 1.25 0 0 0 6 21.25h12A1.25 1.25 0 0 0 19.25 20V8.75H14a.75.75 0 0 1-.75-.75V2.75Zm4.94 4.5-3.44-3.44v3.44h3.44Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFilePlus;
