import { useHover } from "@floating-ui/react";
import classNames from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";
import { Icon, IconType } from "./../../Icon";
import { useTooltip } from "./../../Tooltip";
import "./ToolbarButton.scss";

export type ToolbarButtonVariant = "primary" | "grey" | "red" | "yellow" | "green";

export interface ToolbarButtonProps {
  icon: IconType;
  onClick: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>["onClick"];
  variant: ToolbarButtonVariant;
  disabled?: boolean;
  active?: boolean;
  testId?: string;
  tooltip?: ReactNode;
}

export function ToolbarButton({
  icon,
  onClick,
  variant,
  disabled,
  active,
  testId,
  tooltip,
}: ToolbarButtonProps) {
  const [ref, getReferenceProps, Tooltip] = useTooltip({
    placement: "bottom",
    interactionProps: [useHover],
  });

  return (
    <>
      <button
        type="button"
        ref={ref}
        data-testid={testId}
        className={classNames("ToolbarButton", `ToolbarButton--${variant}`, {
          [`ToolbarButton--${variant}-active`]: active,
          "ToolbarButton--disabled": disabled,
        })}
        {...getReferenceProps({ onClick })}
      >
        <Icon icon={icon} className="ToolbarButton--icon" />
      </button>
      {tooltip && !disabled ? <Tooltip>{tooltip}</Tooltip> : null}
    </>
  );
}
