import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Switch } from "../Switch";

interface SwitchProps {
  classname?: string;
  title: string;
  description: string;
}
export function SimpleFormLayoutSwitch({
  title,
  description,
  classname,
  ...props
}: PropsWithChildren<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    SwitchProps
>) {
  return (
    <div className={classNames("SimpleFormLayoutSwitch", classname)}>
      <div className="SimpleFormLayoutSwitch--title">{title}</div>
      <div className="SimpleFormLayoutSwitch--description">{description}</div>
      <Switch {...props} />
    </div>
  );
}
