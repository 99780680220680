import { UserStatusType } from "models/PersonModels";
import { userStatusesToSelectOptions } from "utils/mappers/userStatusesToSelectOptions";
import { FilterSection } from "../FilterSection";

interface UserStatusFilterProps {
  values: UserStatusType[];
  onFilterApply: (value: UserStatusType) => void;
  includeDeletedUsers?: boolean;
}

export function UserStatusFilter({
  values,
  onFilterApply,
  includeDeletedUsers,
}: UserStatusFilterProps) {
  const getOptions = () => {
    if (includeDeletedUsers) {
      return userStatusesToSelectOptions();
    }

    return userStatusesToSelectOptions().filter(
      status => status.value !== "Deleted" && status.value !== "NoAccess",
    );
  };

  return (
    <FilterSection
      options={getOptions()}
      checkedValues={values}
      onFilterApply={value => onFilterApply(value)}
    />
  );
}
