/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AncestryType } from './AncestryType';
import {
    AncestryTypeFromJSON,
    AncestryTypeFromJSONTyped,
    AncestryTypeToJSON,
} from './AncestryType';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import type { GenderType } from './GenderType';
import {
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import type { NationalityType } from './NationalityType';
import {
    NationalityTypeFromJSON,
    NationalityTypeFromJSONTyped,
    NationalityTypeToJSON,
} from './NationalityType';
import type { ProfileDeviceResponseModel } from './ProfileDeviceResponseModel';
import {
    ProfileDeviceResponseModelFromJSON,
    ProfileDeviceResponseModelFromJSONTyped,
    ProfileDeviceResponseModelToJSON,
} from './ProfileDeviceResponseModel';
import type { ProfileOrganizationResponseModel } from './ProfileOrganizationResponseModel';
import {
    ProfileOrganizationResponseModelFromJSON,
    ProfileOrganizationResponseModelFromJSONTyped,
    ProfileOrganizationResponseModelToJSON,
} from './ProfileOrganizationResponseModel';
import type { UserDetailsResponseModel } from './UserDetailsResponseModel';
import {
    UserDetailsResponseModelFromJSON,
    UserDetailsResponseModelFromJSONTyped,
    UserDetailsResponseModelToJSON,
} from './UserDetailsResponseModel';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';
import type { UserStatusType } from './UserStatusType';
import {
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface ProfileResponseModel
 */
export interface ProfileResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileResponseModel
     */
    shortId: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseModel
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseModel
     */
    city: string;
    /**
     * 
     * @type {CountryType}
     * @memberof ProfileResponseModel
     */
    country: CountryType;
    /**
     * 
     * @type {number}
     * @memberof ProfileResponseModel
     */
    state?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseModel
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseModel
     */
    organizationName: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileResponseModel
     */
    isMainOrganization?: boolean | null;
    /**
     * 
     * @type {UserStatusType}
     * @memberof ProfileResponseModel
     */
    status: UserStatusType;
    /**
     * 
     * @type {UserRoleType}
     * @memberof ProfileResponseModel
     */
    role: UserRoleType;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseModel
     */
    rowVersion: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileResponseModel
     */
    isAccountOwner: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProfileResponseModel
     */
    additionalProperties: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof ProfileResponseModel
     */
    dateofBirth?: Date | null;
    /**
     * 
     * @type {GenderType}
     * @memberof ProfileResponseModel
     */
    gender?: GenderType;
    /**
     * 
     * @type {AncestryType}
     * @memberof ProfileResponseModel
     */
    ancestry?: AncestryType;
    /**
     * 
     * @type {number}
     * @memberof ProfileResponseModel
     */
    weight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfileResponseModel
     */
    height?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseModel
     */
    registryNumber?: string | null;
    /**
     * 
     * @type {NationalityType}
     * @memberof ProfileResponseModel
     */
    nationality: NationalityType;
    /**
     * 
     * @type {ProfileOrganizationResponseModel}
     * @memberof ProfileResponseModel
     */
    organization?: ProfileOrganizationResponseModel;
    /**
     * 
     * @type {UserDetailsResponseModel}
     * @memberof ProfileResponseModel
     */
    hcp?: UserDetailsResponseModel;
    /**
     * 
     * @type {ProfileDeviceResponseModel}
     * @memberof ProfileResponseModel
     */
    device?: ProfileDeviceResponseModel;
}

/**
 * Check if a given object implements the ProfileResponseModel interface.
 */
export function instanceOfProfileResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "shortId" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "organizationName" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "rowVersion" in value;
    isInstance = isInstance && "isAccountOwner" in value;
    isInstance = isInstance && "additionalProperties" in value;
    isInstance = isInstance && "nationality" in value;

    return isInstance;
}

export function ProfileResponseModelFromJSON(json: any): ProfileResponseModel {
    return ProfileResponseModelFromJSONTyped(json, false);
}

export function ProfileResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'shortId': json['ShortId'],
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'email': json['Email'],
        'phone': json['Phone'],
        'street': json['Street'],
        'city': json['City'],
        'country': CountryTypeFromJSON(json['Country']),
        'state': !exists(json, 'State') ? undefined : json['State'],
        'zipCode': !exists(json, 'ZipCode') ? undefined : json['ZipCode'],
        'organizationId': json['OrganizationId'],
        'organizationName': json['OrganizationName'],
        'isMainOrganization': !exists(json, 'IsMainOrganization') ? undefined : json['IsMainOrganization'],
        'status': UserStatusTypeFromJSON(json['Status']),
        'role': UserRoleTypeFromJSON(json['Role']),
        'rowVersion': json['RowVersion'],
        'isAccountOwner': json['IsAccountOwner'],
        'additionalProperties': json['AdditionalProperties'],
        'dateofBirth': !exists(json, 'DateofBirth') ? undefined : json['DateofBirth'],
        'gender': !exists(json, 'Gender') ? undefined : GenderTypeFromJSON(json['Gender']),
        'ancestry': !exists(json, 'Ancestry') ? undefined : AncestryTypeFromJSON(json['Ancestry']),
        'weight': !exists(json, 'Weight') ? undefined : json['Weight'],
        'height': !exists(json, 'Height') ? undefined : json['Height'],
        'registryNumber': !exists(json, 'RegistryNumber') ? undefined : json['RegistryNumber'],
        'nationality': NationalityTypeFromJSON(json['Nationality']),
        'organization': !exists(json, 'Organization') ? undefined : ProfileOrganizationResponseModelFromJSON(json['Organization']),
        'hcp': !exists(json, 'Hcp') ? undefined : UserDetailsResponseModelFromJSON(json['Hcp']),
        'device': !exists(json, 'Device') ? undefined : ProfileDeviceResponseModelFromJSON(json['Device']),
    };
}

export function ProfileResponseModelToJSON(value?: ProfileResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ShortId': value.shortId,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Email': value.email,
        'Phone': value.phone,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': value.state,
        'ZipCode': value.zipCode,
        'OrganizationId': value.organizationId,
        'OrganizationName': value.organizationName,
        'IsMainOrganization': value.isMainOrganization,
        'Status': UserStatusTypeToJSON(value.status),
        'Role': UserRoleTypeToJSON(value.role),
        'RowVersion': value.rowVersion,
        'IsAccountOwner': value.isAccountOwner,
        'AdditionalProperties': value.additionalProperties,
        'DateofBirth': value.dateofBirth,
        'Gender': GenderTypeToJSON(value.gender),
        'Ancestry': AncestryTypeToJSON(value.ancestry),
        'Weight': value.weight,
        'Height': value.height,
        'RegistryNumber': value.registryNumber,
        'Nationality': NationalityTypeToJSON(value.nationality),
        'Organization': ProfileOrganizationResponseModelToJSON(value.organization),
        'Hcp': UserDetailsResponseModelToJSON(value.hcp),
        'Device': ProfileDeviceResponseModelToJSON(value.device),
    };
}

