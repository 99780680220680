export function getRowVersion(response: Response) {
  const locationString = response.headers.get("Location");

  if (!locationString) {
    throw new Error(
      "Backend didn't sent back location in response header or we don't have access - check Access-Control-Expose-Headers",
    );
  }

  const rowVersionFromLocation = locationString.match("/events/(.+)")?.at(1);

  if (!rowVersionFromLocation) {
    throw new Error("Failed to get rowVersion from location string");
  }

  return { rowVersion: rowVersionFromLocation } as const;
}
