import * as React from "react";
import { SVGProps } from "react";
const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.204 7.499a3.75 3.75 0 1 0-.756-1.295L8.795 9.5a3.75 3.75 0 1 0 0 4.997l5.653 3.294a3.75 3.75 0 1 0 .758-1.294l-5.654-3.295c.128-.378.197-.782.197-1.203 0-.421-.07-.826-.197-1.203l5.65-3.298ZM18 2.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM7.918 10.823a.768.768 0 0 0 .052.089c.178.322.28.693.28 1.088a2.24 2.24 0 0 1-.332 1.177A2.249 2.249 0 0 1 3.75 12a2.25 2.25 0 0 1 4.168-1.177Zm8.086 7.138a.748.748 0 0 0 .112-.192 2.25 2.25 0 1 1-.113.192Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgShare;
