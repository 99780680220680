import { CarnaApiEvent } from "config/apiEvent";
import { ActionModal, Button } from "libs/ui";
import { PersonToDelete } from "models/PersonModels";
import { useCallback, useMemo } from "react";
import { useOnEventStatusSubscribe } from "utils/hooks/useOnEventStatusSubscribe";
import { toastStore } from "config/toast";
import { Trans, useTranslation } from "react-i18next";
import { getProfileEndpointPerRole } from "utils/helpers/getProfileEndpointPerRole";

interface DeletePersonModalProps {
  onClose: () => void;
  personToDelete?: PersonToDelete;
  callBack?: () => void;
}

async function deletePerson(personToDelete?: PersonToDelete) {
  const { rowVersion } = await getProfileEndpointPerRole(personToDelete);

  return CarnaApiEvent[personToDelete?.role ?? "Admin"].delete({
    organizationId: personToDelete!.organizationId,
    userEntityId: personToDelete!.id,
    rowVersion,
  });
}

export function DeletePersonModal({ onClose, personToDelete, callBack }: DeletePersonModalProps) {
  const { t } = useTranslation("modals", {
    keyPrefix: "DeletePersonModal",
  });
  const { t: tUserRoleType } = useTranslation("translation", {
    keyPrefix: "userRoleType",
  });

  const personToDeleteRole = useMemo(
    () => tUserRoleType(personToDelete?.role ?? "Admin"),
    [personToDelete?.role, tUserRoleType],
  );

  const [onEvent, isDeleteLoading] = useOnEventStatusSubscribe();

  const onConfirm = useCallback(() => {
    onEvent(deletePerson(personToDelete), {
      complete() {
        toastStore.toast.success({
          msg: t("successDeletePerson", { role: personToDeleteRole }),
          expire: 5000,
        });
        if (callBack) {
          callBack();
        }
        onClose();
      },
      error(err) {
        switch (err.code) {
          case "ACTION_FAILED":
            toastStore.toast.error({ msg: t("errorDeletePerson", { role: personToDeleteRole }) });
            break;
          case "STATUS_QUERY_ERROR":
            onClose();
            toastStore.toast.error({ msg: t(`errorFailedToUpdate${personToDelete?.role}Table`) });
        }
      },
    });
  }, [callBack, onClose, onEvent, personToDelete, personToDeleteRole, t]);

  return (
    <ActionModal
      show={!!personToDelete}
      icon={{
        iconType: "Delete",
        color: "red",
      }}
      title={t("title", { role: personToDeleteRole })}
      inLoadingState={isDeleteLoading}
      cancelButton={
        <Button
          onClick={onClose}
          buttonSize={"medium"}
          buttonType={"transparent"}
          disabled={isDeleteLoading}
        >
          {t("buttonCancel")}
        </Button>
      }
      submitButton={
        <Button
          onClick={onConfirm}
          buttonSize={"medium"}
          buttonType={"red"}
          disabled={isDeleteLoading}
        >
          {t("buttonDelete", { role: personToDeleteRole })}
        </Button>
      }
    >
      <Trans
        i18nKey={"description"}
        t={t}
        values={{ targetName: personToDelete?.firstName.concat(` ${personToDelete.lastName}`) }}
      >
        <strong></strong>
      </Trans>
    </ActionModal>
  );
}
