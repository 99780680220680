import * as React from "react";
import { SVGProps } from "react";
const SvgSync = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.563 15.781a.82.82 0 0 1-.82.82H4.62c1.491 2.963 4.415 4.758 7.88 4.758 4.691 0 8.422-3.47 8.824-8.04.04-.45.403-.819.856-.819.453 0 .823.369.79.82C22.564 18.81 18.112 23 12.5 23c-3.713 0-6.972-1.807-8.86-4.805v2.508a.82.82 0 1 1-1.64 0v-4.837c0-.5.405-.905.905-.905h4.837a.82.82 0 0 1 .82.82ZM16.41 9.219a.82.82 0 0 1 .82-.82h3.122c-1.491-2.963-4.415-4.758-7.88-4.758-4.691 0-8.422 3.47-8.824 8.04-.04.45-.403.819-.856.819-.453 0-.823-.369-.79-.82C2.408 6.19 6.86 2 12.472 2c3.713 0 6.972 1.807 8.86 4.805V4.297a.82.82 0 0 1 1.64 0v4.837c0 .5-.405.905-.905.905H17.23a.82.82 0 0 1-.82-.82Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSync;
