import { IconType } from "libs/ui/Icon";
import { MeasurementWidgetType } from "utils/createGlobalConfigStore";

export function getIcon(type: MeasurementWidgetType): IconType {
  switch (type) {
    case "SerumCreatinine":
    case "Egfr":
      return "CreatinineEgfr";

    case "Glucose":
      return "Glucose";

    case "Bmi":
      return "Bmi";

    case "Height":
      return "Height";

    case "Weight":
      return "Weight";

    case "BloodPressure":
      return "BloodPressure";

    case "Uacr":
    case "UrineAlbumin":
    case "UrineCreatinine":
    case "SemiQuantitativeUacr":
      return "Uacr";

    default:
      throw new Error(`Icon for ${type} not covered`);
  }
}
