/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OldEntitiesSectionModel } from './OldEntitiesSectionModel';
import {
    OldEntitiesSectionModelFromJSON,
    OldEntitiesSectionModelFromJSONTyped,
    OldEntitiesSectionModelToJSON,
} from './OldEntitiesSectionModel';
import type { OldInfrastructureSectionModel } from './OldInfrastructureSectionModel';
import {
    OldInfrastructureSectionModelFromJSON,
    OldInfrastructureSectionModelFromJSONTyped,
    OldInfrastructureSectionModelToJSON,
} from './OldInfrastructureSectionModel';
import type { OldLaboratorySectionModel } from './OldLaboratorySectionModel';
import {
    OldLaboratorySectionModelFromJSON,
    OldLaboratorySectionModelFromJSONTyped,
    OldLaboratorySectionModelToJSON,
} from './OldLaboratorySectionModel';
import type { OldUsersSectionModel } from './OldUsersSectionModel';
import {
    OldUsersSectionModelFromJSON,
    OldUsersSectionModelFromJSONTyped,
    OldUsersSectionModelToJSON,
} from './OldUsersSectionModel';

/**
 * 
 * @export
 * @interface OldConfigurationWithoutWebModel
 */
export interface OldConfigurationWithoutWebModel {
    /**
     * 
     * @type {OldEntitiesSectionModel}
     * @memberof OldConfigurationWithoutWebModel
     */
    entities: OldEntitiesSectionModel;
    /**
     * 
     * @type {OldInfrastructureSectionModel}
     * @memberof OldConfigurationWithoutWebModel
     */
    infrastructure: OldInfrastructureSectionModel;
    /**
     * 
     * @type {OldLaboratorySectionModel}
     * @memberof OldConfigurationWithoutWebModel
     */
    laboratory: OldLaboratorySectionModel;
    /**
     * 
     * @type {OldUsersSectionModel}
     * @memberof OldConfigurationWithoutWebModel
     */
    users: OldUsersSectionModel;
}

/**
 * Check if a given object implements the OldConfigurationWithoutWebModel interface.
 */
export function instanceOfOldConfigurationWithoutWebModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entities" in value;
    isInstance = isInstance && "infrastructure" in value;
    isInstance = isInstance && "laboratory" in value;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function OldConfigurationWithoutWebModelFromJSON(json: any): OldConfigurationWithoutWebModel {
    return OldConfigurationWithoutWebModelFromJSONTyped(json, false);
}

export function OldConfigurationWithoutWebModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OldConfigurationWithoutWebModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entities': OldEntitiesSectionModelFromJSON(json['Entities']),
        'infrastructure': OldInfrastructureSectionModelFromJSON(json['Infrastructure']),
        'laboratory': OldLaboratorySectionModelFromJSON(json['Laboratory']),
        'users': OldUsersSectionModelFromJSON(json['Users']),
    };
}

export function OldConfigurationWithoutWebModelToJSON(value?: OldConfigurationWithoutWebModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Entities': OldEntitiesSectionModelToJSON(value.entities),
        'Infrastructure': OldInfrastructureSectionModelToJSON(value.infrastructure),
        'Laboratory': OldLaboratorySectionModelToJSON(value.laboratory),
        'Users': OldUsersSectionModelToJSON(value.users),
    };
}

