/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiastolicModel } from './DiastolicModel';
import {
    DiastolicModelFromJSON,
    DiastolicModelFromJSONTyped,
    DiastolicModelToJSON,
} from './DiastolicModel';
import type { SystolicModel } from './SystolicModel';
import {
    SystolicModelFromJSON,
    SystolicModelFromJSONTyped,
    SystolicModelToJSON,
} from './SystolicModel';

/**
 * 
 * @export
 * @interface BloodPressureModel
 */
export interface BloodPressureModel {
    /**
     * 
     * @type {SystolicModel}
     * @memberof BloodPressureModel
     */
    systolic: SystolicModel;
    /**
     * 
     * @type {DiastolicModel}
     * @memberof BloodPressureModel
     */
    diastolic: DiastolicModel;
}

/**
 * Check if a given object implements the BloodPressureModel interface.
 */
export function instanceOfBloodPressureModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "systolic" in value;
    isInstance = isInstance && "diastolic" in value;

    return isInstance;
}

export function BloodPressureModelFromJSON(json: any): BloodPressureModel {
    return BloodPressureModelFromJSONTyped(json, false);
}

export function BloodPressureModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BloodPressureModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systolic': SystolicModelFromJSON(json['Systolic']),
        'diastolic': DiastolicModelFromJSON(json['Diastolic']),
    };
}

export function BloodPressureModelToJSON(value?: BloodPressureModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Systolic': SystolicModelToJSON(value.systolic),
        'Diastolic': DiastolicModelToJSON(value.diastolic),
    };
}

