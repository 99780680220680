import * as React from "react";
import { SVGProps } from "react";
const SvgHide = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#hide_svg__a)" fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M1.53.47A.75.75 0 0 0 .47 1.53l4.467 4.468A19.2 19.2 0 0 0 .34 11.645a.75.75 0 0 0-.01.69L1 12c-.67.335-.67.337-.67.337v.002l.004.006.01.02a7.278 7.278 0 0 0 .188.346 20.426 20.426 0 0 0 2.796 3.802C5.266 18.58 8.2 20.75 12 20.75h.012a10.82 10.82 0 0 0 5.847-1.83l4.61 4.61a.75.75 0 1 0 1.061-1.06l-22-22Zm15.244 17.365-2.692-2.692a3.75 3.75 0 0 1-5.225-5.225L6.002 7.063a17.7 17.7 0 0 0-4.147 4.94 18.925 18.925 0 0 0 2.568 3.484c1.81 1.932 4.373 3.76 7.57 3.763a9.321 9.321 0 0 0 4.781-1.415Zm-6.829-6.829a2.262 2.262 0 0 0-.27 1.03 2.25 2.25 0 0 0 3.32 2.019l-3.05-3.049ZM10.07 4.97a8.37 8.37 0 0 1 1.928-.22H12c3.2 0 5.766 1.83 7.578 3.763a18.925 18.925 0 0 1 2.568 3.485 17.75 17.75 0 0 1-1.88 2.71.75.75 0 0 0 1.148.965 19.253 19.253 0 0 0 2.247-3.32.75.75 0 0 0 .01-.688l-.212.046.211-.048-.015-.029a7.61 7.61 0 0 0-.187-.345 20.43 20.43 0 0 0-2.796-3.802C18.734 5.42 15.8 3.25 12.001 3.25a9.87 9.87 0 0 0-2.272.26.75.75 0 0 0 .342 1.46Zm13.389 6.74-1.313.287c.004.007.895-.192 1.313-.286Z" />
    </g>
    <defs>
      <clipPath id="hide_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHide;
