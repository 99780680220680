import { Button } from "libs/ui/Button";

import "./DraggableWidget.scss";
import {
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
  TouchEventHandler,
  useState,
} from "react";
import { autoUpdate, useFloating } from "@floating-ui/react";
import classNames from "classnames";
import { useOnClickOutside } from "libs/ui/hooks";

export interface DraggableWidgetProps {
  style?: React.CSSProperties;
  Header?: React.ReactNode;
  optionsDropDown?: React.ReactNode;
  className?: string;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
  onMouseUp?: MouseEventHandler<HTMLButtonElement>;
  onTouchEnd?: TouchEventHandler<HTMLButtonElement>;
}

export const DraggableWidget = forwardRef<HTMLDivElement, PropsWithChildren<DraggableWidgetProps>>(
  (
    {
      children,
      className,
      Header,
      optionsDropDown,
      style,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      ...rest
    },
    ref,
  ) => {
    const [showOptions, setShowOptions] = useState(false);
    const toggleShowOptions = () => setShowOptions(prev => !prev);

    // const {handleAxis, ...restProps} = rest as any;

    const { refs, floatingStyles } = useFloating({
      placement: "bottom-end",
      strategy: "absolute",
      whileElementsMounted: autoUpdate,
    });

    useOnClickOutside(refs.floating, showOptions, toggleShowOptions);

    return (
      <div className={classNames(className, "DraggableWidget")} style={style} ref={ref} {...rest}>
        <div className="DraggableWidget__head">
          <Button
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onTouchEnd={onTouchEnd}
            className="DragButton"
            buttonType={"transparent"}
            buttonSize={"small"}
            buttonIcon={{ icon: "Drag" }}
          />
          {Header}

          {optionsDropDown ? (
            <Button
              buttonType={"transparent"}
              buttonSize={"small"}
              buttonIcon={{ icon: "MoreVertical" }}
              onClick={toggleShowOptions}
              ref={refs.setReference}
              active={showOptions}
            />
          ) : null}

          {showOptions && optionsDropDown ? (
            <div ref={refs.setFloating} className="DraggableWidget__options" style={floatingStyles}>
              {optionsDropDown}
            </div>
          ) : null}
        </div>

        <div className="DraggableWidget__body">{children}</div>
      </div>
    );
  },
);
