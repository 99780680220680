/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HcpResponseModel } from './HcpResponseModel';
import {
    HcpResponseModelFromJSON,
    HcpResponseModelFromJSONTyped,
    HcpResponseModelToJSON,
} from './HcpResponseModel';

/**
 * 
 * @export
 * @interface HcpResponseModelListModel
 */
export interface HcpResponseModelListModel {
    /**
     * 
     * @type {number}
     * @memberof HcpResponseModelListModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof HcpResponseModelListModel
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof HcpResponseModelListModel
     */
    queryCount: number;
    /**
     * 
     * @type {Array<HcpResponseModel>}
     * @memberof HcpResponseModelListModel
     */
    items: Array<HcpResponseModel>;
    /**
     * 
     * @type {number}
     * @memberof HcpResponseModelListModel
     */
    readonly pages?: number;
}

/**
 * Check if a given object implements the HcpResponseModelListModel interface.
 */
export function instanceOfHcpResponseModelListModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "queryCount" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function HcpResponseModelListModelFromJSON(json: any): HcpResponseModelListModel {
    return HcpResponseModelListModelFromJSONTyped(json, false);
}

export function HcpResponseModelListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HcpResponseModelListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': json['Limit'],
        'page': json['Page'],
        'queryCount': json['QueryCount'],
        'items': ((json['Items'] as Array<any>).map(HcpResponseModelFromJSON)),
        'pages': !exists(json, 'Pages') ? undefined : json['Pages'],
    };
}

export function HcpResponseModelListModelToJSON(value?: HcpResponseModelListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Limit': value.limit,
        'Page': value.page,
        'QueryCount': value.queryCount,
        'Items': ((value.items as Array<any>).map(HcpResponseModelToJSON)),
    };
}

