import "./CarnaChartMeasurement.scss";

import classNames from "classnames";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";

interface CarnaChartMeasurementProps {
  measurementUnit?: MEASUREMENT_UNITS | null;
}

export function CarnaChartMeasurement({
  measurementUnit = null,
}: Readonly<CarnaChartMeasurementProps>) {
  return <span className={classNames("CarnaChartMeasurement")}>{measurementUnit}</span>;
}
