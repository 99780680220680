import { PropsWithChildren, useState } from "react";
import { createSafeContext } from "utils/createSafeContext";
import { createStore, useStore } from "zustand";

interface AppMenuTogglerStore {
  menuOpen: boolean;
  onMenuToggle: (value: boolean) => void;
}

const createAppMenuTogglerStore = () => {
  return createStore<AppMenuTogglerStore>()(set => ({
    menuOpen: false,
    onMenuToggle: (menuOpen: boolean) => set(() => ({ menuOpen })),
  }));
};

type AppMenuTogglerContext = ReturnType<typeof createAppMenuTogglerStore>;

const AppMenuTogglerContext = createSafeContext<AppMenuTogglerContext>();

export function AppMenuTogglerContextProvider({ children }: PropsWithChildren) {
  /**
   * useState for One-Time Initializations
   * {@link https://tkdodo.eu/blog/use-state-for-one-time-initializations}
   */
  const [value] = useState(createAppMenuTogglerStore);

  return <AppMenuTogglerContext.Provider value={value}>{children}</AppMenuTogglerContext.Provider>;
}

export function useAppMenuTogglerContext<T>(selector: (state: AppMenuTogglerStore) => T): T {
  /**
   * custom hook for atomic select
   * {@link https://zustand.docs.pmnd.rs/guides/initialize-state-with-props#extracting-context-logic-into-a-custom-hook}
   */
  return useStore(AppMenuTogglerContext.hook(), selector);
}
