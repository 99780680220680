import { Button } from "libs/ui/Button";
import { PropsWithChildren } from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./DragAndDropGrid.scss";

const ReactGridLayout = WidthProvider(Responsive);

const BOX_SIZE = 120;
const BOX_GAP = 16;

interface DragAndDropGridProps {
  layouts: Layouts;
  onLayoutChange: (currentLayout: Layout[], allLayouts: Layouts) => void;
  onBreakpointChange: (breakpoint: string, cols: number) => void;
  compactType?: "vertical" | "horizontal" | null | undefined;
}

export type Breakpoint =
  | "col-12"
  | "col-11"
  | "col-10"
  | "col-9"
  | "col-8"
  | "col-7"
  | "col-6"
  | "col-5"
  | "col-4"
  | "col-3";

export function DragAndDropGrid({
  layouts,
  onLayoutChange,
  onBreakpointChange,
  children,
  compactType,
}: PropsWithChildren<DragAndDropGridProps>) {
  return (
    <div className="DragAndDropGrid">
      <ReactGridLayout
        compactType={compactType}
        margin={[BOX_GAP, BOX_GAP]}
        onBreakpointChange={onBreakpointChange}
        breakpoints={{
          "col-12": 1631,
          "col-11": 1495,
          "col-10": 1359,
          "col-9": 1223,
          "col-8": 1087,
          "col-7": 951,
          "col-6": 799,
          "col-5": 663,
          "col-4": 527,
          "col-3": 320,
        }}
        layouts={layouts}
        cols={{
          "col-12": 12,
          "col-11": 11,
          "col-10": 10,
          "col-9": 9,
          "col-8": 8,
          "col-7": 7,
          "col-6": 6,
          "col-5": 5,
          "col-4": 4,
          "col-3": 3,
        }}
        onLayoutChange={onLayoutChange}
        draggableHandle={".DragButton"}
        rowHeight={BOX_SIZE}
        useCSSTransforms={true}
        resizeHandle={
          <div className="ResizeHandle">
            <Button
              className="ResizeButton"
              buttonType={"transparent"}
              buttonSize={"small"}
              buttonIcon={{ icon: "Resize" }}
            />
          </div>
        }
      >
        {children}
      </ReactGridLayout>
    </div>
  );
}
