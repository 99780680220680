import { CountryType } from "api/config_service/models/CountryType";
import { FieldStatusResolve } from "components/Forms/fieldStatusResolve";
import { isReadonly } from "components/Forms/helper";
import { SelectOptionCountry } from "components/SelectOptionCountry";
import { Input, SelectFilter, SelectFilterResult, SideModalElements } from "libs/ui";
import { Option } from "libs/ui/Select/Select.model";
import { PlaceType } from "models/PlaceType";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { IsNullOrWhiteSpace } from "utils/guards";
import { useStatesPerCountry } from "utils/hooks/useStatesPerCountry";
import { useTryGeolocation } from "utils/hooks/useTryGeolocation";
import { countriesToSelectOptions } from "utils/mappers/countriesToSelectOptions";
import { customEnumsToSelectOptions } from "utils/mappers/customEnumsToSelectOptions";
import { Group } from "../Group";
import { PlaceSuggestionInput, PlaceSuggestionInputRef } from "../PlaceSuggestionInput";
import { EditableFieldsModel } from "../model";
import { AddressMap, AddressMapPropsRef } from "./AddressMap";
import "./AddressSection.scss";
import { useFeatureToggles } from "utils/featureToggles";

export interface GeolocationModel {
  latitude: number;
  longitude: number;
}

export interface AddressData {
  street: string;
  country: CountryType;
  state?: number | null;
  city: string;
  zipCode?: string | null;
  geolocation?: GeolocationModel;
}

interface AddressSectionProps<T extends AddressData> extends EditableFieldsModel<T> {
  addressData: T;
  zipCodeFieldStatus: FieldStatusResolve;
}

const setRenderSelected =
  <T,>(label: string) =>
  (props?: Option<T>) => (
    <SelectFilterResult
      data-testval={JSON.stringify({ title: props?.title })}
      data-testid={`SelectFilterResult--${label}`}
      hasValue={!!props}
      label={label}
    >
      {props?.title ?? ""}
    </SelectFilterResult>
  );

const setRenderOption = (
  props?: Readonly<{
    value: Option<CountryType>;
    isActive: boolean;
  }>,
) =>
  props ? (
    <SelectOptionCountry
      title={props.value.title}
      countryCode={props.value.value}
      isActive={props.isActive}
    />
  ) : null;

export function AddressSection<T extends AddressData>({
  addressData,
  editableFields,
  onChange,
  loading,
  zipCodeFieldStatus,
  showTitle = true,
}: Readonly<AddressSectionProps<T>>) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  const placeSuggestionInputRef = useRef<PlaceSuggestionInputRef>(null);
  const mapRef = useRef<AddressMapPropsRef | null>(null);
  const featureToggles = useFeatureToggles();

  const [getStates] = useStatesPerCountry();
  const { tryGeolocationFind, tryGeolocationFindSubject } = useTryGeolocation();

  const { country, state, city, street, zipCode, geolocation } = addressData;

  useEffect(() => {
    if (featureToggles["address-map"] === false) {
      return;
    }

    const subscription = tryGeolocationFind.subscribe(result => {
      if (result instanceof Error) {
        // TODO handle this please
        console.log("Error: ", result);
        return;
      }

      const placeDetails = result.at(0);

      if (placeDetails?.viewport && mapRef.current) {
        mapRef.current.centerMap(placeDetails?.viewport);
      }

      if (placeDetails?.coordinates?.lng() && placeDetails?.coordinates?.lat()) {
        onChange(
          {
            latitude: placeDetails?.coordinates?.lat(),
            longitude: placeDetails?.coordinates?.lng(),
          },
          "geolocation",
        );
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [featureToggles, onChange, tryGeolocationFind]);

  const states = useMemo(() => getStates(country), [country, getStates]);

  const States = useMemo(() => {
    if (states === null) {
      return null;
    }

    return (
      <div className="Form__field">
        <SelectFilter
          options={customEnumsToSelectOptions(states)}
          onSelect={value => onChange(value, "state")}
          value={state}
          data-testid="State"
          readOnly={isReadonly("state", editableFields)}
          renderSelected={setRenderSelected(t("state"))}
          loading={loading}
        />
      </div>
    );
  }, [editableFields, loading, onChange, state, states, t]);

  const clearPlaceSuggestionInput = useCallback(() => {
    placeSuggestionInputRef.current?.clearPlaceSuggestionInput();
  }, []);

  const onCountryChange = useCallback(
    (value?: CountryType) => {
      onChange(value ?? CountryType.None, "country");
      onChange(null, "state");
      onChange("", "city");
      onChange("", "street");
      onChange("", "zipCode");
      clearPlaceSuggestionInput();
    },
    [clearPlaceSuggestionInput, onChange],
  );

  const onCityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      onChange(value, "city");
      onChange("", "street");
      onChange("", "zipCode");
      clearPlaceSuggestionInput();

      if (!IsNullOrWhiteSpace(value) || !IsNullOrWhiteSpace(country)) {
        tryGeolocationFindSubject.next(`${value}, ${country}`);
      }
    },
    [clearPlaceSuggestionInput, country, onChange, tryGeolocationFindSubject],
  );

  const onStreetChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      onChange(value, "street");

      clearPlaceSuggestionInput();

      if (!IsNullOrWhiteSpace(value) || !IsNullOrWhiteSpace(city) || !IsNullOrWhiteSpace(country)) {
        tryGeolocationFindSubject.next(`${value}, ${zipCode}, ${city}, ${country}`);
      }
    },
    [city, clearPlaceSuggestionInput, country, onChange, tryGeolocationFindSubject, zipCode],
  );

  const onPlaceSuggestionSelect = useCallback(
    (value?: PlaceType) => {
      if (!value) {
        onChange(undefined, "geolocation");
        return;
      }

      if (value.viewport && mapRef.current) {
        mapRef.current.centerMap(value.viewport);
      }

      // #region nullify form
      // zipCode field can be disabled, in that case we don't want to fill it's value, so we have additional checking
      (["city", "zipCode", "street"] as const).forEach(v => {
        if (v === "zipCode" && !zipCodeFieldStatus.show) {
          return;
        }
        onChange("", v);
      });

      onChange(CountryType.None, "country");
      onChange(null, "state");
      // #endregion

      if (value.countryCode && CountryType[value.countryCode as keyof typeof CountryType]) {
        onChange(CountryType[value.countryCode as keyof typeof CountryType], "country");
      }

      // only select state input if we are in US
      if (states !== null) {
        const stateFound = states.find(configState => configState.N === value.state);

        /**
         * It my happen that the state is not found in the list of states, so we have to search for the closest match
         * For example, if the result returns "Paget Parish" and the BE is giving us in their list "Paget" only,
         * the state will not be selected
         */
        const stateCloseResults = states.find(
          el =>
            value.state?.toLowerCase().includes(el.N.toLowerCase()) ||
            (value.state?.toLowerCase() && el.N.toLowerCase().includes(value.state?.toLowerCase())),
        );

        const finalStateValue = stateFound?.V ?? stateCloseResults?.V ?? null;
        onChange(finalStateValue, "state");
      }

      if (value.city) {
        onChange(value.city, "city");
      }

      // Same case as in the nullify form above
      // we don't want to pass the zipCode value to out state if the field is disabled
      if (value.postcode && zipCodeFieldStatus.show) {
        onChange(value.postcode, "zipCode");
      }

      if (value.street) {
        onChange(
          value.streetNumber ? `${value.street} ${value.streetNumber}` : value.street,
          "street",
        );
      }

      if (value.coordinates?.lat() && value.coordinates?.lng()) {
        onChange(
          {
            latitude: value.coordinates?.lat(),
            longitude: value.coordinates?.lng(),
          },
          "geolocation",
        );
      }
    },
    [onChange, states, zipCodeFieldStatus.show],
  );

  const shouldShowStreetInfoMessage =
    featureToggles["address-map"] === false
      ? false
      : !isReadonly("street") && !!country && !!city && !!street;

  return (
    <Group>
      {showTitle ? (
        <SideModalElements.SectionTitle>{t("Subtitle.address")}</SideModalElements.SectionTitle>
      ) : null}

      {isReadonly("street", editableFields) || featureToggles["address-map"] === false ? null : (
        <div className="Form__field">
          <PlaceSuggestionInput ref={placeSuggestionInputRef} onSelect={onPlaceSuggestionSelect} />
        </div>
      )}

      {featureToggles["address-map"] ? (
        <div className="Form__field">
          <AddressMap
            ref={mapRef}
            isReadonly={isReadonly("street", editableFields)}
            geolocation={geolocation}
            onMapSelect={onPlaceSuggestionSelect}
          />
        </div>
      ) : null}

      <div className="Form__field">
        <SelectFilter<CountryType>
          className="CountrySelect"
          options={countriesToSelectOptions()}
          onSelect={onCountryChange}
          value={country}
          data-testid="CountrySelect"
          readOnly={isReadonly("country", editableFields)}
          renderSelected={setRenderSelected(t("country"))}
          renderOption={setRenderOption}
          loading={loading}
        />
      </div>

      {States}

      <div className="Form__field">
        <Input
          data-testid="city"
          onChange={onCityChange}
          label={t("city")}
          value={city}
          readOnly={isReadonly("city", editableFields)}
          loading={loading}
        />
      </div>

      <div className="Form__field">
        <Input
          onChange={onStreetChange}
          label={t("street")}
          value={street}
          data-testid="Street"
          readOnly={isReadonly("street", editableFields)}
          loading={loading}
          validation={{
            infoText: shouldShowStreetInfoMessage ? t("ValidationMessages.addressInfo") : undefined,
          }}
        />
      </div>

      {zipCodeFieldStatus.show ? (
        <div data-testid="FormElements.CityZipField.zipCode" className="Form__field">
          <Input
            optional={zipCodeFieldStatus.optional}
            data-testid="zip-code"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e.target.value, "zipCode")
            }
            label={t("zipCode")}
            value={zipCodeFieldStatus.showNA ? t("na") : (zipCode ?? "")}
            readOnly={zipCodeFieldStatus.readonly}
            loading={loading}
          />
        </div>
      ) : null}
    </Group>
  );
}
