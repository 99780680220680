import { MeasurementUnitType } from "api/query";
import { WEIGHT_MAX_GRAPH_VALUE_KG, WEIGHT_MAX_GRAPH_VALUE_LB } from "config/const";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { useMemo } from "react";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";

export function useGetMaxWeightValueFromRuleEngineUnit() {
  const { weightUnit } = useGetUserPreferredMeasurementUnits();

  const value = useMemo(() => {
    switch (weightUnit) {
      case undefined:
      case MEASUREMENT_UNITS.kg:
        return WEIGHT_MAX_GRAPH_VALUE_KG;
      case MEASUREMENT_UNITS.lb:
        return WEIGHT_MAX_GRAPH_VALUE_LB;
      default:
        console.error(`${weightUnit} should be implemented`);
        return WEIGHT_MAX_GRAPH_VALUE_KG;
    }
  }, [weightUnit]);

  return { unit: MeasurementUnitType.mm, value };
}
