import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { EgfrStatusType } from "models/TestModels";

export function useEGFRStageText() {
  const { t } = useTranslation("translation", { keyPrefix: "useEGFRStageText" });

  const getEGFRStageText = useCallback(
    (status?: EgfrStatusType) => {
      if (!status) {
        return undefined;
      }

      return t(status);
    },
    [t],
  );

  return [getEGFRStageText] as const;
}
