import { UserGraphModel } from "api/query";
import { ECOption, Formatters } from "components/Chart/model";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { getSingleAreaOptions } from "../model";

type UrineCreatinineNormalRage = [number | undefined, number | undefined];

interface GetOptionsProps {
  formatters: Formatters;
  data: UserGraphModel;
  measurementUnit: MEASUREMENT_UNITS;
  urineCreatinineNormalRange?: UrineCreatinineNormalRage;
  maxRange?: number;
  showTrending?: boolean;
}

export const getOptions = ({
  formatters,
  data,
  measurementUnit,
  urineCreatinineNormalRange = [20, 300],
  maxRange = 500,
  showTrending,
}: GetOptionsProps): ECOption =>
  getSingleAreaOptions({
    formatters,
    data,
    measurementUnit,
    unitType: "urineCreatinine",
    yAxis: { max: maxRange },
    normalAreaRange: urineCreatinineNormalRange,
    showTrending,
  });
