import { MeasurementItemUIType, MeasurementUIType, UImeasurementTypes } from "models/TestModels";
import { TestFormModel } from "../../model";

function isNotEmpty(value: string) {
  return value !== "" && Number.isNaN(value) === false;
}

export function getIfSQUACRHasValue(testData: TestFormModel) {
  const sqUACRValue = testData.measurements
    .find(el => el.measurementType === "SemiQuantitativeUACR")
    ?.items.find(el => el.type === "SemiQuantitativeUacr")?.value;

  if (sqUACRValue === undefined) {
    return false;
  }

  return isNotEmpty(sqUACRValue) && Number(sqUACRValue) !== 0;
}

export const uacrSubtypes: MeasurementItemUIType[] = ["UrineCreatinine", "UrineAlbumin", "Uacr"];

export function getIfUACRHasValue(testData: TestFormModel) {
  const UACRGroup = testData.measurements.find(el => el.measurementType === "UACR");

  if (UACRGroup === undefined) {
    return false;
  }

  if (UACRGroup.items.every(el => el.type !== undefined && uacrSubtypes.includes(el.type))) {
    return UACRGroup.items.some(el => isNotEmpty(el.value));
  }

  return false;
}

export function getAvailableMeasurementTypes(testData: TestFormModel): MeasurementUIType[] {
  const activeMeasurementsTypes = testData.measurements
    .map(el => el.measurementType)
    .filter(type => type !== "None");

  const UACRMeasurementTypes: MeasurementUIType[] = ["SemiQuantitativeUACR", "UACR"];

  const nonActiveTypes = UImeasurementTypes.filter(measurementType =>
    activeMeasurementsTypes.every(
      activeMeasurementType => activeMeasurementType !== measurementType,
    ),
  );

  if (
    activeMeasurementsTypes.some(activeType => UACRMeasurementTypes.includes(activeType)) &&
    (getIfUACRHasValue(testData) || getIfSQUACRHasValue(testData))
  ) {
    return nonActiveTypes.filter(type =>
      UACRMeasurementTypes.every(singleUacr => singleUacr !== type),
    );
  }

  return nonActiveTypes;
}
