import { EgfrStatusType } from "models/TestModels";
import { ColorType } from "models/colors";
import { useCallback } from "react";

export function useEGFRColor() {
  const colorResolve = useCallback((status?: EgfrStatusType): ColorType => {
    if (!status) {
      return "white" as const;
    }

    switch (status) {
      case "None":
      case "NormalOrHigh":
      case "MildlyDecreased":
        return "white" as const;

      case "MildlyOrModeratelyDecreased":
      case "ModeratelyToSeverelyDecreased":
      case "SeverelyDecreased":
        return "yellow-100" as const;

      case "KidneyFailure":
        return "red-50" as const;

      default:
        throw new Error(`color for ${status} not covered`);
    }
  }, []);

  return [colorResolve] as const;
}
