import { PatientTestsPerHcpTable } from "components/AppTables/PatientTestsPerHcpTable";
import { PatientsPerHCPTable } from "components/AppTables/PatientsPerEntity/PatientsPerHCPTable";
import { Details } from "pages/HCPPage/Details";
import { HcpDetailsServiceContextProvider } from "pages/HCPPage/Details/Context";
import { EventEnrichedTabsForHcpLabList } from "pages/HCPPage/Details/EventEnrichedTabsForHcpLabList";
import { HcpPatientsMeasurementsLaboratoryContextProvider } from "pages/HCPPage/Details/LaboratoryContext";
import { Parent } from "pages/HCPPage/Parent";
import { applyTo } from "ramda";
import { Navigate, Route } from "react-router";
import { mainRoutePaths } from "router";
import { AccessLevel } from "../utils/AccessLevel";
import { HCPTableStateProvider } from "components/AppTables/AllHCPTable/context";
import { DetailsPageContextProvider } from "components/PageTemplate/DetailsPage/context";
import { Outlet } from "react-router-dom";

export const HcpRoutes = (
  <Route
    path={mainRoutePaths.hcp}
    element={
      <DetailsPageContextProvider>
        <Outlet />
      </DetailsPageContextProvider>
    }
  >
    <Route
      index
      element={
        <HCPTableStateProvider>
          <Parent />
        </HCPTableStateProvider>
      }
    />
    <Route
      path=":organizationId/:hcpId/*"
      element={<AccessLevel check={applyTo("view-hcp-details")} />}
    >
      <Route
        element={
          <HcpDetailsServiceContextProvider>
            <HcpPatientsMeasurementsLaboratoryContextProvider>
              <Details />
            </HcpPatientsMeasurementsLaboratoryContextProvider>
          </HcpDetailsServiceContextProvider>
        }
      >
        <Route path="patients" element={<PatientsPerHCPTable />} />
        <Route path="tests" element={<EventEnrichedTabsForHcpLabList />}>
          <Route
            path="all"
            element={
              <PatientTestsPerHcpTable
                appliedColumns={[
                  "measurementType",
                  "date",
                  "testResult",
                  "patient",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />

          <Route
            path="serumCreatinine"
            element={
              <PatientTestsPerHcpTable
                measurementType="SerumCreatinine"
                measurementTypeItem="SerumCreatinine"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "serumCreatinine",
                  "patient",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />
          <Route
            path="egfr"
            element={
              <PatientTestsPerHcpTable
                measurementType="SerumCreatinine"
                measurementTypeItem="Egfr"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "eGFR",
                  "patient",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />

          <Route
            path="bmi"
            element={
              <PatientTestsPerHcpTable
                measurementType="BMI"
                measurementTypeItem="Bmi"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "bmi",
                  "patient",
                  "testedBy",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />

          <Route
            path="height"
            element={
              <PatientTestsPerHcpTable
                measurementType="BMI"
                measurementTypeItem="Height"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "height",
                  "patient",
                  "testedBy",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />

          <Route
            path="weight"
            element={
              <PatientTestsPerHcpTable
                measurementType="BMI"
                measurementTypeItem="Weight"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "weight",
                  "patient",
                  "testedBy",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />

          <Route
            path="bloodPressure"
            element={
              <PatientTestsPerHcpTable
                measurementType="BloodPressure"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "bloodPressure",
                  "patient",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />

          <Route
            path="glucose"
            element={
              <PatientTestsPerHcpTable
                measurementType="Glucose"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "glucose",
                  "patient",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />
          <Route
            path="urineCreatinine"
            element={
              <PatientTestsPerHcpTable
                measurementType="UACR"
                measurementTypeItem="UrineCreatinine"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "urineCreatinine",
                  "patient",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />
          <Route
            path="urineAlbumin"
            element={
              <PatientTestsPerHcpTable
                measurementType="UACR"
                measurementTypeItem="UrineAlbumin"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "urineAlbumin",
                  "patient",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />
          <Route
            path="uacr"
            element={
              <PatientTestsPerHcpTable
                measurementType="UACR"
                measurementTypeItem="Uacr"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "uacr",
                  "patient",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />

          <Route
            path="semiQuantitativeUacr"
            element={
              <PatientTestsPerHcpTable
                measurementType="SemiQuantitativeUACR"
                measurementTypeItem="SemiQuantitativeUacr"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "squacr",
                  "patient",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />

          <Route path="*" element={<Navigate to="all" replace />} />
        </Route>

        <Route path="*" element={<Navigate to="patients" replace />} />
      </Route>
    </Route>
  </Route>
);
