import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export function useOnInputValidation<Model, A extends keyof Model>(
  onChange: (value: string | undefined | null, inputKey: keyof Model) => void,
  VALIDATION_MAP: Record<A, (props: any) => boolean>,
) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  const [validationFieldsText, setValidationFieldsText] = useState<{
    [K in A]?: string;
  }>({});

  const onInputWithValidation = useCallback(
    (value: string | null, inputKey: A) => {
      onChange(value, inputKey);

      if (validationFieldsText[inputKey]) {
        setValidationFieldsText(prevValue => ({
          ...prevValue,
          [inputKey]: !VALIDATION_MAP[inputKey](value)
            ? t(`ValidationMessages.${String(inputKey)}`)
            : undefined,
        }));
      }
    },
    [VALIDATION_MAP, onChange, t, validationFieldsText],
  );
  return [validationFieldsText, setValidationFieldsText, onInputWithValidation] as const;
}
