import { AdministratorsTableColumns } from "utils/createGlobalConfigStore";

interface AdministratorTableColumnsProps {
  t: TFunction;
}

const allAdministratorTableColumns = ({ t }: AdministratorTableColumnsProps) => {
  return (
    [
      "userStatuses",
      "userRoles",
      "organizationIds",
      "email",
      "phone",
      "address",
      "countries",
      "actions",
    ] as AdministratorsTableColumns[]
  ).map(name => ({
    label: t(`columns.${name}`),
    name,
  }));
};

interface AppliedColumnsProps extends AdministratorTableColumnsProps {
  appliedColumns: AdministratorsTableColumns[];
}

export function resolveAppliedColumns({ appliedColumns, ...rest }: AppliedColumnsProps) {
  return allAdministratorTableColumns(rest).filter(column =>
    appliedColumns.some(el => el === column.name),
  );
}
