import * as React from "react";
import { SVGProps } from "react";
const SvgCircleHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#circle-help_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12ZM12.043 7.564a2.297 2.297 0 0 0-2.555 1.502.766.766 0 0 1-1.444-.508 3.828 3.828 0 0 1 7.44 1.275c0 1.428-1.061 2.393-1.873 2.934a7.949 7.949 0 0 1-1.665.838l-.033.012-.01.003-.004.001s-.002.001-.244-.725l.242.726a.766.766 0 0 1-.485-1.452l.003-.001.018-.007.081-.03a6.432 6.432 0 0 0 1.248-.64c.719-.479 1.19-1.045 1.19-1.66a2.297 2.297 0 0 0-1.909-2.268Zm-.306 8.394a1.02 1.02 0 0 0 0 2.042h.01a1.02 1.02 0 0 0 0-2.042h-.01Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="circle-help_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCircleHelp;
