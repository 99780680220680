/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MeasurementItemType } from './MeasurementItemType';
import {
    MeasurementItemTypeFromJSON,
    MeasurementItemTypeFromJSONTyped,
    MeasurementItemTypeToJSON,
} from './MeasurementItemType';
import type { MeasurementUnitType } from './MeasurementUnitType';
import {
    MeasurementUnitTypeFromJSON,
    MeasurementUnitTypeFromJSONTyped,
    MeasurementUnitTypeToJSON,
} from './MeasurementUnitType';

/**
 * 
 * @export
 * @interface MeasurementItemModel
 */
export interface MeasurementItemModel {
    /**
     * 
     * @type {string}
     * @memberof MeasurementItemModel
     */
    value: string;
    /**
     * 
     * @type {MeasurementUnitType}
     * @memberof MeasurementItemModel
     */
    unit: MeasurementUnitType;
    /**
     * 
     * @type {MeasurementItemType}
     * @memberof MeasurementItemModel
     */
    type?: MeasurementItemType;
}

/**
 * Check if a given object implements the MeasurementItemModel interface.
 */
export function instanceOfMeasurementItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "unit" in value;

    return isInstance;
}

export function MeasurementItemModelFromJSON(json: any): MeasurementItemModel {
    return MeasurementItemModelFromJSONTyped(json, false);
}

export function MeasurementItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['Value'],
        'unit': MeasurementUnitTypeFromJSON(json['Unit']),
        'type': !exists(json, 'Type') ? undefined : MeasurementItemTypeFromJSON(json['Type']),
    };
}

export function MeasurementItemModelToJSON(value?: MeasurementItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Value': value.value,
        'Unit': MeasurementUnitTypeToJSON(value.unit),
        'Type': MeasurementItemTypeToJSON(value.type),
    };
}

