import { DeviceType } from "models/DeviceModels";
import { TestsTableColumns } from "../common/TestsTable/model";

const NOVA_MAX_PRO_CREATE_EGFR_METER_COLUMNS: TestsTableColumns[] = [
  "serumCreatinine",
  "eGFR",
] as const;
const NOVA_BIOMEDICAL_ALLEGRO_COLUMNS: TestsTableColumns[] = [
  "uacr",
  "urineCreatinine",
  "urineAlbumin",
] as const;

export function getMeasurementColumns(deviceType?: DeviceType): TestsTableColumns[] {
  switch (deviceType) {
    case "NovaMaxProCreateEgfrMeter":
      return NOVA_MAX_PRO_CREATE_EGFR_METER_COLUMNS;

    case "NovaBiomedicalAllegro":
      return NOVA_BIOMEDICAL_ALLEGRO_COLUMNS;

    default:
      return [] as const;
  }
}
