/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PatientResponseModel } from './PatientResponseModel';
import {
    PatientResponseModelFromJSON,
    PatientResponseModelFromJSONTyped,
    PatientResponseModelToJSON,
} from './PatientResponseModel';

/**
 * 
 * @export
 * @interface PatientResponseModelListModel
 */
export interface PatientResponseModelListModel {
    /**
     * 
     * @type {number}
     * @memberof PatientResponseModelListModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PatientResponseModelListModel
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof PatientResponseModelListModel
     */
    queryCount: number;
    /**
     * 
     * @type {Array<PatientResponseModel>}
     * @memberof PatientResponseModelListModel
     */
    items: Array<PatientResponseModel>;
    /**
     * 
     * @type {number}
     * @memberof PatientResponseModelListModel
     */
    readonly pages?: number;
}

/**
 * Check if a given object implements the PatientResponseModelListModel interface.
 */
export function instanceOfPatientResponseModelListModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "queryCount" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function PatientResponseModelListModelFromJSON(json: any): PatientResponseModelListModel {
    return PatientResponseModelListModelFromJSONTyped(json, false);
}

export function PatientResponseModelListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientResponseModelListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': json['Limit'],
        'page': json['Page'],
        'queryCount': json['QueryCount'],
        'items': ((json['Items'] as Array<any>).map(PatientResponseModelFromJSON)),
        'pages': !exists(json, 'Pages') ? undefined : json['Pages'],
    };
}

export function PatientResponseModelListModelToJSON(value?: PatientResponseModelListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Limit': value.limit,
        'Page': value.page,
        'QueryCount': value.queryCount,
        'Items': ((value.items as Array<any>).map(PatientResponseModelToJSON)),
    };
}

