import { AjaxError } from "rxjs/ajax";

export enum ModelState {
  Loading = "loading",
  Loaded = "loaded",
  Error = "error",
  Default = "default",
}
export interface Loadable {
  readonly state: any;
  readonly value?: any;
}
export interface DefaultModel<T> extends Loadable {
  readonly state: ModelState.Default;
  value?: T;
}
export interface LoadingModel<T> extends Loadable {
  readonly state: ModelState.Loading;
  readonly value?: T;
}

export interface LoadingModelWithValue<T> extends Loadable {
  readonly state: ModelState.Loading;
  readonly value: T;
}
export interface LoadedModel<T> extends Loadable {
  readonly state: ModelState.Loaded;
  readonly value: T;
}
export interface ErrorModel extends Loadable {
  readonly state: ModelState.Error;
  readonly value: any;
}

export interface AjaxErrorModel extends ErrorModel {
  readonly value: AjaxError;
}
export type LoadableModel<T> =
  | LoadingModel<T>
  | LoadedModel<T>
  | DefaultModel<T>
  | AjaxErrorModel
  | ErrorModel;

export function isLoaded<T>(model?: LoadableModel<T> | DefaultModel<T>): model is LoadedModel<T> {
  if (!model) {
    return false;
  }
  return model.state === ModelState.Loaded;
}

export function getLoadedValue<T>(model?: LoadableModel<T> | DefaultModel<T>): T | undefined {
  if (isLoaded(model)) {
    return model.value;
  }
  return undefined;
}

export function isDefaultModel<T>(
  model: LoadableModel<T> | DefaultModel<T>,
): model is DefaultModel<T> {
  return model.state === ModelState.Default;
}
export function isFailed<T>(model: LoadableModel<T> | DefaultModel<T>): model is ErrorModel {
  return model.state === ModelState.Error;
}

export function isLoading<T>(model?: LoadableModel<T> | DefaultModel<T>): model is LoadingModel<T> {
  if (!model) {
    return false;
  }
  return model.state === ModelState.Loading;
}

export function isLoadableModel<T>(model: any): model is LoadableModel<T> {
  return (
    typeof model === "object" &&
    model.state &&
    (model.state === ModelState.Default ||
      model.state === ModelState.Error ||
      model.state === ModelState.Loaded ||
      model.state === ModelState.Loading)
  );
}

export function isLoadingWithValue<T>(
  model: LoadableModel<T> | DefaultModel<T>,
): model is LoadingModelWithValue<T> {
  return model.state === ModelState.Loading && model.value !== undefined;
}

export function loading<T>(value?: T): LoadingModel<T> {
  return { state: ModelState.Loading, value };
}

export function defaultModel<T>(value?: T): DefaultModel<T> {
  return { state: ModelState.Default, value };
}

export function loaded<T>(value: T): LoadedModel<T> {
  return { state: ModelState.Loaded, value };
}

export function failed<T>(value: T): ErrorModel {
  return { state: ModelState.Error, value };
}

export function modelToJSX<T>(
  model: LoadableModel<T>,
  prop: {
    loading?: JSX.Element;
    loaded?: JSX.Element;
    error?: JSX.Element;
  },
): JSX.Element | null {
  switch (model.state) {
    case ModelState.Loading:
      return prop.loading ?? null;
    case ModelState.Loaded:
      return prop.loaded ?? null;
    case ModelState.Error:
      return prop.error ?? null;
    default:
      return null;
  }
}
