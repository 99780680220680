/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  PatientResponseModel,
  PatientResponseModelListModel,
  PatientWidgetFilterModel,
  PatientWidgetResponseModel,
  PatientWidgetsResponseModel,
  PatientsPerOrganizationFilterModel,
  SinglePatientWidgetFilterModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    PatientResponseModelFromJSON,
    PatientResponseModelToJSON,
    PatientResponseModelListModelFromJSON,
    PatientResponseModelListModelToJSON,
    PatientWidgetFilterModelFromJSON,
    PatientWidgetFilterModelToJSON,
    PatientWidgetResponseModelFromJSON,
    PatientWidgetResponseModelToJSON,
    PatientWidgetsResponseModelFromJSON,
    PatientWidgetsResponseModelToJSON,
    PatientsPerOrganizationFilterModelFromJSON,
    PatientsPerOrganizationFilterModelToJSON,
    SinglePatientWidgetFilterModelFromJSON,
    SinglePatientWidgetFilterModelToJSON,
} from '../models/index';

export interface OrganizationsOrganizationIdPatientsGetRequest {
    organizationId: string;
    filters?: PatientsPerOrganizationFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdPatientsPatientEntityIdGetRequest {
    organizationId: string;
    patientEntityId: string;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdPatientsPatientEntityIdWidgetsGetRequest {
    organizationId: string;
    patientEntityId: string;
    filter?: PatientWidgetFilterModel;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGetRequest {
    widgetId: string;
    organizationId: string;
    patientEntityId: string;
    filter?: SinglePatientWidgetFilterModel;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdPatientsReportsCsvGetRequest {
    organizationId: string;
    filters?: PatientsPerOrganizationFilterModel;
    acceptVersion?: string;
}

/**
 * PatientsApi - interface
 * 
 * @export
 * @interface PatientsApiInterface
 */
export interface PatientsApiInterface {
    /**
     * 
     * @param {string} organizationId 
     * @param {PatientsPerOrganizationFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApiInterface
     */
    organizationsOrganizationIdPatientsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientResponseModelListModel>>;

    /**
     */
    organizationsOrganizationIdPatientsGet(requestParameters: OrganizationsOrganizationIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientResponseModelListModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} patientEntityId 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApiInterface
     */
    organizationsOrganizationIdPatientsPatientEntityIdGetRaw(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientResponseModel>>;

    /**
     */
    organizationsOrganizationIdPatientsPatientEntityIdGet(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} patientEntityId 
     * @param {PatientWidgetFilterModel} [filter] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApiInterface
     */
    organizationsOrganizationIdPatientsPatientEntityIdWidgetsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdWidgetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientWidgetsResponseModel>>;

    /**
     */
    organizationsOrganizationIdPatientsPatientEntityIdWidgetsGet(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdWidgetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientWidgetsResponseModel>;

    /**
     * 
     * @param {string} widgetId 
     * @param {string} organizationId 
     * @param {string} patientEntityId 
     * @param {SinglePatientWidgetFilterModel} [filter] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApiInterface
     */
    organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGetRaw(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientWidgetResponseModel>>;

    /**
     */
    organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGet(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientWidgetResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {PatientsPerOrganizationFilterModel} [filters] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApiInterface
     */
    organizationsOrganizationIdPatientsReportsCsvGetRaw(requestParameters: OrganizationsOrganizationIdPatientsReportsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdPatientsReportsCsvGet(requestParameters: OrganizationsOrganizationIdPatientsReportsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class PatientsApi extends runtime.BaseAPI implements PatientsApiInterface {

     /**
     */
    async organizationsOrganizationIdPatientsGetRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientResponseModelListModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsGet(requestParameters: OrganizationsOrganizationIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientResponseModelListModel> {
        const response = await this.organizationsOrganizationIdPatientsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdPatientsPatientEntityIdGetRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdGet.');
        }

        if (requestParameters.patientEntityId === null || requestParameters.patientEntityId === undefined) {
            throw new runtime.RequiredError('patientEntityId','Required parameter requestParameters.patientEntityId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients/{patientEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"patientEntityId"}}`, encodeURIComponent(String(requestParameters.patientEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsPatientEntityIdGetRaw(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdGet.');
        }

        if (requestParameters.patientEntityId === null || requestParameters.patientEntityId === undefined) {
            throw new runtime.RequiredError('patientEntityId','Required parameter requestParameters.patientEntityId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{patientEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"patientEntityId"}}`, encodeURIComponent(String(requestParameters.patientEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsPatientEntityIdGet(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientResponseModel> {
        const response = await this.organizationsOrganizationIdPatientsPatientEntityIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdPatientsPatientEntityIdWidgetsGetRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdWidgetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdWidgetsGet.');
        }

        if (requestParameters.patientEntityId === null || requestParameters.patientEntityId === undefined) {
            throw new runtime.RequiredError('patientEntityId','Required parameter requestParameters.patientEntityId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdWidgetsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients/{patientEntityId}/widgets`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"patientEntityId"}}`, encodeURIComponent(String(requestParameters.patientEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsPatientEntityIdWidgetsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdWidgetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientWidgetsResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdWidgetsGet.');
        }

        if (requestParameters.patientEntityId === null || requestParameters.patientEntityId === undefined) {
            throw new runtime.RequiredError('patientEntityId','Required parameter requestParameters.patientEntityId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdWidgetsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{patientEntityId}/widgets`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"patientEntityId"}}`, encodeURIComponent(String(requestParameters.patientEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientWidgetsResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsPatientEntityIdWidgetsGet(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdWidgetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientWidgetsResponseModel> {
        const response = await this.organizationsOrganizationIdPatientsPatientEntityIdWidgetsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGetRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.widgetId === null || requestParameters.widgetId === undefined) {
            throw new runtime.RequiredError('widgetId','Required parameter requestParameters.widgetId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGet.');
        }

        if (requestParameters.patientEntityId === null || requestParameters.patientEntityId === undefined) {
            throw new runtime.RequiredError('patientEntityId','Required parameter requestParameters.patientEntityId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients/{patientEntityId}/widgets/{widgetId}`.replace(`{${"widgetId"}}`, encodeURIComponent(String(requestParameters.widgetId))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"patientEntityId"}}`, encodeURIComponent(String(requestParameters.patientEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGetRaw(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientWidgetResponseModel>> {
        if (requestParameters.widgetId === null || requestParameters.widgetId === undefined) {
            throw new runtime.RequiredError('widgetId','Required parameter requestParameters.widgetId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGet.');
        }

        if (requestParameters.patientEntityId === null || requestParameters.patientEntityId === undefined) {
            throw new runtime.RequiredError('patientEntityId','Required parameter requestParameters.patientEntityId was null or undefined when calling organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{patientEntityId}/widgets/{widgetId}`.replace(`{${"widgetId"}}`, encodeURIComponent(String(requestParameters.widgetId))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"patientEntityId"}}`, encodeURIComponent(String(requestParameters.patientEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientWidgetResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGet(requestParameters: OrganizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientWidgetResponseModel> {
        const response = await this.organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdPatientsReportsCsvGetRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsReportsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsReportsCsvGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients/reports/csv`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsReportsCsvGetRaw(requestParameters: OrganizationsOrganizationIdPatientsReportsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsReportsCsvGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/reports/csv`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdPatientsReportsCsvGet(requestParameters: OrganizationsOrganizationIdPatientsReportsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdPatientsReportsCsvGetRaw(requestParameters, initOverrides);
    }

}
