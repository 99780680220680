import { ReportsPage } from "pages/ReportsPage";
import { AllReports } from "pages/ReportsPage/tabs/AllReports";
import { RequestNew } from "pages/ReportsPage/tabs/RequestNew";
import { Route } from "react-router";
import { Navigate } from "react-router-dom";
import { mainRoutePaths, reportsRoutePaths } from "router";
import { AccessLevel } from "../utils/AccessLevel";
import { applyTo } from "ramda";

// Outlet is in TabsForRouter in ReportsPage
export const ReportRoutes = (
  <Route
    path={mainRoutePaths.reports}
    element={
      <AccessLevel check={applyTo("view-reports")}>
        <ReportsPage />
      </AccessLevel>
    }
  >
    <Route index element={<Navigate to={reportsRoutePaths.requestNew.substring(1)} replace />} />
    <Route path={reportsRoutePaths.requestNew.substring(1)} element={<RequestNew />} />
    <Route path={reportsRoutePaths.allReports.substring(1)} element={<AllReports />} />

    <Route path="*" element={<Navigate to={reportsRoutePaths.requestNew.substring(1)} replace />} />
  </Route>
);
