import "./SingleTimeStamp.scss";
import { SingleTimeStampProps } from "../../model";
import { Button } from "libs/ui/Button";
import { TooltipComponent, useGetTooltipProps } from "libs/ui/Tooltip";
import { useHover } from "@floating-ui/react";
import { PropsWithChildren, useReducer, useSyncExternalStore } from "react";
import { avatarCache } from "utils/machines/avatars";
import { Avatar } from "libs/ui";
import { PictureResolution } from "utils/helpers/pictures.model";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";

export function SingleTimeStamp({ user, children }: PropsWithChildren<SingleTimeStampProps>) {
  const { organizationId } = useAdditionalAuthInformationContext();
  const [isTooltipActive, toggleTooltip] = useReducer(state => !state, false);

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  const showTooltip = isTooltipActive && user;

  const {
    x,
    y,
    refs,
    strategy,
    arrowRef,
    arrowX,
    arrowY,
    open,
    getReferenceProps,
    getFloatingProps,
  } = useGetTooltipProps({
    interactionProps: [useHover],
    placement: "bottom-end",
    crossAxis: 0,
    mainAxis: 5,
  });

  return (
    <div className="SingleTimeStamp">
      <p className="SingleTimeStamp__timedate">{children}</p>

      {user ? (
        <Button
          ref={refs.setReference}
          {...getReferenceProps()}
          buttonType={"transparent"}
          buttonSize={"small"}
          buttonIcon={{ icon: "Info" }}
          onMouseEnter={toggleTooltip}
          onMouseLeave={toggleTooltip}
          className="SingleTimeStamp__tooltipButton"
        />
      ) : null}

      {showTooltip ? (
        <TooltipComponent
          type="grey"
          ref={refs.setFloating}
          x={x}
          y={y}
          floatingProps={getFloatingProps()}
          open={open}
          strategy={strategy}
          arrowRef={arrowRef}
          arrowX={arrowX}
          arrowY={arrowY}
          arrowSize="small"
        >
          <div className="SingleTimeStamp__avatar">
            <Avatar
              role={user.role}
              src={avatarCache.get(organizationId, user.entityId, PictureResolution.table)}
              avatarLoading={false}
              firstName={user.firstName}
            />

            <p className="SingleTimeStamp__avatar-name">
              {user.firstName.concat(` ${user.lastName}`)}
            </p>
          </div>
        </TooltipComponent>
      ) : null}
    </div>
  );
}
