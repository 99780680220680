import { GlobalPreferenceForm } from "components/GlobalPreferenceForm";
import { SplashScreen } from "components/SplashScreen";
import { Button, Modal } from "libs/ui";
import { GlobalPreference } from "models/globalPreference";
import { useState } from "react";
import { getCurrentGlobalPreferences } from "utils/getCurrentGlobalPreferences";
import { useTranslation } from "react-i18next";
import "./GlobalPreferenceModal.scss";

export interface GlobalPreferenceModalProps {
  isLoading: boolean;
  onConfirm: (updateData: GlobalPreference) => void;
  isSaving: boolean;
}

function fieldsReady(changedPreferences: GlobalPreference) {
  return Object.values(changedPreferences).every(el => el !== undefined && !!el.toString().length);
}

export function GlobalPreferenceModal({
  isLoading,
  onConfirm,
  isSaving,
}: GlobalPreferenceModalProps) {
  const { t } = useTranslation("modals", { keyPrefix: "GlobalPreferenceModal" });
  const [globalPreferencesData, setGlobalPreferencesData] = useState<GlobalPreference>(() =>
    getCurrentGlobalPreferences(),
  );

  const onSelect = (value: any, inputKey: keyof GlobalPreference) => {
    setGlobalPreferencesData(prevValue => ({
      ...prevValue,
      [inputKey]: value,
    }));
  };

  const submitDisabled = isSaving || !fieldsReady(globalPreferencesData);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <Modal show>
      <div className="GlobalPreferenceModal">
        <div className="GlobalPreferenceModal__content">
          <h2 className="GlobalPreferenceModal__title">{t("title")}</h2>
          <p className="GlobalPreferenceModal__description">{t("description")}</p>

          <GlobalPreferenceForm formData={globalPreferencesData} onSelect={onSelect} />

          <Button
            data-testid="button-save"
            onClick={() => onConfirm(globalPreferencesData)}
            buttonSize="medium"
            buttonType="primary"
            disabled={submitDisabled}
            loading={isSaving}
          >
            {t("button_save")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
