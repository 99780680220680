import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Cell } from "../Cell";

import "./DateCell.scss";

interface DateCellProps {
  time?: string;
  show?: boolean;
  loading?: boolean;
}

export function DateCell({
  time,
  show = true,
  loading,
  children,
}: PropsWithChildren<DateCellProps>) {
  const timeComponent = () => {
    if (loading) {
      return <span className="DateCell__time DateCell__time--loading" />;
    }

    return time ? <span className="DateCell__time">{time}</span> : null;
  };

  return (
    <Cell show={show} className="DateCell">
      <span
        className={classNames("DateCell__date", {
          "DateCell__date--loading": loading,
        })}
      >
        {loading ? null : children}
      </span>
      {timeComponent()}
    </Cell>
  );
}
