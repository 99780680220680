import * as React from "react";
import { SVGProps } from "react";
const SvgNewChat = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.97 1.97a2.871 2.871 0 1 1 4.06 4.06l-9.5 9.5a.75.75 0 0 1-.348.198l-4 1a.75.75 0 0 1-.91-.91l1-4a.75.75 0 0 1 .198-.348l9.5-9.5Zm2.03.659c-.364 0-.712.144-.97.401l-9.353 9.353-.646 2.586 2.586-.646L20.97 4.97A1.371 1.371 0 0 0 20 2.629Z"
      fill="currentColor"
    />
    <path
      d="M11.907 2.062c.418.044.69.446.608.858-.079.4-.465.662-.872.623A9.25 9.25 0 1 0 20 12.75c0-.416.325-.773.742-.773h.036a.71.71 0 0 1 .722.694v.079c0 5.937-4.813 10.75-10.75 10.75S0 18.687 0 12.75 4.813 2 10.75 2c.391 0 .777.02 1.157.062Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgNewChat;
