/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OldInfrastructureSectionModel
 */
export interface OldInfrastructureSectionModel {
    /**
     * 
     * @type {string}
     * @memberof OldInfrastructureSectionModel
     */
    apiGatewayId: string;
    /**
     * 
     * @type {string}
     * @memberof OldInfrastructureSectionModel
     */
    stageName: string;
    /**
     * 
     * @type {string}
     * @memberof OldInfrastructureSectionModel
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof OldInfrastructureSectionModel
     */
    dataTableName: string;
    /**
     * 
     * @type {string}
     * @memberof OldInfrastructureSectionModel
     */
    identityPoolId: string;
    /**
     * 
     * @type {string}
     * @memberof OldInfrastructureSectionModel
     */
    userPoolId: string;
    /**
     * 
     * @type {string}
     * @memberof OldInfrastructureSectionModel
     */
    userTableName: string;
    /**
     * 
     * @type {string}
     * @memberof OldInfrastructureSectionModel
     */
    s3BucketName: string;
    /**
     * 
     * @type {string}
     * @memberof OldInfrastructureSectionModel
     */
    translationTenantId: string;
    /**
     * 
     * @type {number}
     * @memberof OldInfrastructureSectionModel
     */
    rowVersion: number;
}

/**
 * Check if a given object implements the OldInfrastructureSectionModel interface.
 */
export function instanceOfOldInfrastructureSectionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apiGatewayId" in value;
    isInstance = isInstance && "stageName" in value;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "dataTableName" in value;
    isInstance = isInstance && "identityPoolId" in value;
    isInstance = isInstance && "userPoolId" in value;
    isInstance = isInstance && "userTableName" in value;
    isInstance = isInstance && "s3BucketName" in value;
    isInstance = isInstance && "translationTenantId" in value;
    isInstance = isInstance && "rowVersion" in value;

    return isInstance;
}

export function OldInfrastructureSectionModelFromJSON(json: any): OldInfrastructureSectionModel {
    return OldInfrastructureSectionModelFromJSONTyped(json, false);
}

export function OldInfrastructureSectionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OldInfrastructureSectionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiGatewayId': json['ApiGatewayId'],
        'stageName': json['StageName'],
        'clientId': json['ClientId'],
        'dataTableName': json['DataTableName'],
        'identityPoolId': json['IdentityPoolId'],
        'userPoolId': json['UserPoolId'],
        'userTableName': json['UserTableName'],
        's3BucketName': json['S3BucketName'],
        'translationTenantId': json['TranslationTenantId'],
        'rowVersion': json['RowVersion'],
    };
}

export function OldInfrastructureSectionModelToJSON(value?: OldInfrastructureSectionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ApiGatewayId': value.apiGatewayId,
        'StageName': value.stageName,
        'ClientId': value.clientId,
        'DataTableName': value.dataTableName,
        'IdentityPoolId': value.identityPoolId,
        'UserPoolId': value.userPoolId,
        'UserTableName': value.userTableName,
        'S3BucketName': value.s3BucketName,
        'TranslationTenantId': value.translationTenantId,
        'RowVersion': value.rowVersion,
    };
}

