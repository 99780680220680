import { SVGProps } from "react";

export const MapBaloonMarkerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="26"
    height="34"
    viewBox="0 0 26 34"
    fill="none"
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8819 0C5.77885 0 0 5.76762 0 12.857C0 17.2424 2.08437 21.9507 6.19539 26.8509C9.21477 30.4501 12.1931 32.7649 12.3185 32.8618C12.4844 32.9899 12.6832 33.0539 12.8821 33.0539C13.0808 33.0539 13.2797 32.9899 13.4456 32.8618C13.5709 32.7649 16.5493 30.4501 19.5687 26.8509C23.6797 21.9507 25.7641 17.2425 25.7641 12.857C25.7639 5.76762 19.985 0 12.8819 0ZM17.6526 12.8569C17.6526 15.4864 15.5166 17.6183 12.8819 17.6183C10.2472 17.6183 8.11125 15.4866 8.11125 12.8569C8.11125 10.2272 10.2472 8.09545 12.8819 8.09545C15.5166 8.09545 17.6526 10.2273 17.6526 12.8569Z"
      fill="url(#paint0_linear_29812_40094)"
    />
    <path
      d="M12.9697 21.4701C17.676 21.4701 21.4912 17.6549 21.4912 12.9486C21.4912 8.24237 17.676 4.42719 12.9697 4.42719C8.26343 4.42719 4.44824 8.24237 4.44824 12.9486C4.44824 17.6549 8.26343 21.4701 12.9697 21.4701Z"
      fill="url(#paint1_linear_29812_40094)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_29812_40094"
        x1="12.8821"
        y1="0"
        x2="12.8821"
        y2="33.0539"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" />
        <stop offset="1" stopColor="#5331A8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_29812_40094"
        x1="12.9697"
        y1="4.42719"
        x2="12.9697"
        y2="21.4701"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F0F7FC" />
      </linearGradient>
    </defs>
  </svg>
);
