export type LocalStorageKey =
  | "lastUsedAuth"
  | "DashboardStatsLayout"
  | "PatientOverviewLayout"
  | "X-Tenant";

export const tryGetLocalStorageValue = <T>(key: LocalStorageKey) => {
  try {
    const value = localStorage.getItem(key);

    if (!value) {
      return undefined;
    }

    return JSON.parse(value) as T;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    console.error("Could not retrieve item from localStorage");
    return undefined;
  }
};

export const setLocalStorageItem = <T>(key: LocalStorageKey, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};
