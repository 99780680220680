import React, { useCallback, useSyncExternalStore } from "react";
import { Icon } from "../Icon";
import { ToastMsgInternal, TOAST_ICON_MAP } from "./model";
import "./Toaster.scss";
import { createToastsStore } from "./createToastsStore";

interface ToasterProps {
  store: ReturnType<typeof createToastsStore>;
}
export const Toaster = ({ store }: ToasterProps) => {
  const toastQueue = useSyncExternalStore(store.subscribe, store.getQueue);

  const onClose = useCallback(
    function (toast: ToastMsgInternal, parent: HTMLDivElement | null) {
      setTimeout(() => {
        store.removeToast(toast.uuid);
      }, 1100);

      if (parent) {
        parent.style.animation = "bounceDown 1s";
        parent.style.opacity = "0";
      }
      toast.onClose?.();
    },
    [store],
  );

  return (
    <div className="UI-Components Toaster" data-testid="Toaster">
      {toastQueue.map((toast, idx) => {
        const toastRef = React.createRef<HTMLDivElement>();

        return (
          <div
            ref={toastRef}
            className="SingleToast"
            data-type={toast.type}
            style={
              toast.expire
                ? {
                    animation: `bounceInUp 1s, 1s bounceDown ease-in-out ${
                      toast.expire - 1000
                    }ms forwards`,
                  }
                : undefined
            }
            key={toast.uuid}
          >
            <Icon icon={TOAST_ICON_MAP[toast.type]} />
            <div className="SingleToast__body">
              {(Array.isArray(toast.msg) ? toast.msg : [toast.msg]).map(msg => {
                return (
                  <p key={msg} className="SingleToast__text">
                    {msg}
                  </p>
                );
              })}
            </div>
            <button
              data-testid={`toast-close-${idx}`}
              className="SingleToast__close"
              onClick={() => onClose(toast, toastRef.current)}
            >
              <Icon icon="Close" />
            </button>
          </div>
        );
      })}
    </div>
  );
};
