import * as React from "react";
import { SVGProps } from "react";
const SvgFastForward = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.46 4.408A.75.75 0 0 0 1.25 5v14a.75.75 0 0 0 1.21.592l9-7a.75.75 0 0 0 0-1.184l-9-7ZM9.778 12 2.75 17.466V6.534L9.778 12ZM13.46 4.408A.75.75 0 0 0 12.25 5v14a.75.75 0 0 0 1.21.592l9-7a.75.75 0 0 0 0-1.184l-9-7ZM20.779 12l-7.028 5.466V6.534L20.778 12Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFastForward;
