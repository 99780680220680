import { PropsWithChildren } from "react";
import "./FormElementsGroup.scss";
import { DataAttributeKey } from "libs/ui/models/common";

interface Props {
  [dataAttribute: DataAttributeKey]: any;
}
export function Group({ children, ...props }: PropsWithChildren<Props>) {
  return (
    <div {...props} className="FormElementsGroup">
      {children}
    </div>
  );
}
