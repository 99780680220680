/* tslint:disable */
 
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UserRoleType = {
    Admin: 'Admin',
    Partner: 'Partner',
    Hcp: 'Hcp',
    Patient: 'Patient'
} as const;
export type UserRoleType = typeof UserRoleType[keyof typeof UserRoleType];


export function UserRoleTypeFromJSON(json: any): UserRoleType {
    return UserRoleTypeFromJSONTyped(json, false);
}

export function UserRoleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRoleType {
    return json as UserRoleType;
}

export function UserRoleTypeToJSON(value?: UserRoleType | null): any {
    return value as any;
}

