import { SideModalElements } from "libs/ui";
import { useTranslation } from "react-i18next";
import { SectionDetailsProps } from "../model";

import { useGetUrineAlbuminRanges } from "utils/hooks/useGetUrineAlbuminRanges";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { useUrineAlbuminColor } from "utils/hooks/useUrineAlbuminColor";
import { useUrineAlbuminValueConvertor } from "utils/hooks/useUrineAlbuminValueConvertor";
import { ColorValue } from "../../elements/ColorValue";
import { DetailsField } from "../../elements/DetailsField";
import { RangeTable } from "../../elements/RangeTable/RangeTable";
import { TrendDetailsField } from "../../elements/TrendDetailsField";

interface UrineAlbuminSectionDetailsProps extends SectionDetailsProps {}

export function UrineAlbuminSectionDetails({
  data,
  loading,
  showTrend,
}: UrineAlbuminSectionDetailsProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "Graph.Details.UrineAlbuminSectionDetails",
  });
  const { numberFormatter } = useLocaleNumberFormatter();
  const [getUrineAlbuminColor] = useUrineAlbuminColor();
  const { urineAlbuminUnit } = useGetUserPreferredMeasurementUnits();
  const [getUrineAlbuminValue] = useUrineAlbuminValueConvertor();

  const { urineAlbuminRangePoints } = useGetUrineAlbuminRanges();

  const labels = [t("normal"), t("microalbuminuria"), t("macroalbuminuria")] as const;

  return (
    <div className="UrineAlbuminSectionDetails">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={t("labelResult")}>
        <ColorValue colorType={getUrineAlbuminColor(data?.measurement?.UACR?.UrineAlbumin?.Status)}>
          {numberFormatter({
            value: Number(
              getUrineAlbuminValue(String(data?.measurement?.UACR?.UrineAlbumin?.Value ?? 0)),
            ),
          }).concat(` ${urineAlbuminUnit}`)}
        </ColorValue>
      </DetailsField>

      <TrendDetailsField
        show={showTrend}
        loading={loading}
        trendType={data?.measurement?.UACR?.UrineAlbumin?.TrendType}
        percent={data?.measurement?.UACR?.UrineAlbumin?.Percent}
      />

      <RangeTable
        value={Number(
          getUrineAlbuminValue(String(data?.measurement?.UACR?.UrineAlbumin?.Value ?? 0)),
        )}
        labels={labels}
        steps={urineAlbuminRangePoints}
      />
    </div>
  );
}
