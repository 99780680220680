import { getMeasurementTabs } from "layout/Main/utils/getMeasurementTabs";
import { TabsForRouter } from "libs/ui/TabsForRouter";
import isEqual from "lodash-es/isEqual";
import { MeasurementUIType } from "models/TestModels";
import { useCallback, useEffect, useMemo } from "react";
import { matchPath, Outlet, resolvePath } from "react-router-dom";
import { mainRoutePaths } from "router";
import { useHcpPatientsMeasurementsLaboratoryContext } from "./LaboratoryContext";

export function EventEnrichedTabsForHcpLabList() {
  const tableContext = useHcpPatientsMeasurementsLaboratoryContext();
  const [, send, actor] = tableContext.tableService;

  const updateFilter = useCallback(
    (newStateType?: MeasurementUIType) => {
      const currentTableFilters = actor.getSnapshot().context.filters;

      const newStateObj = newStateType ? { measurementTypes: [newStateType] } : undefined;

      if (isEqual(currentTableFilters, newStateObj) === false) {
        send({ type: "UPDATE_FILTERS", value: newStateObj });
      }
    },
    [actor, send],
  );

  const onClick = useCallback(
    (routePath: string) => {
      switch (routePath as ReturnType<typeof getMeasurementTabs>[number]["routePath"]) {
        case "urineAlbumin":
        case "urineCreatinine":
        case "uacr":
          updateFilter("UACR");
          break;
        case "glucose":
          updateFilter("Glucose");
          break;
        case "bloodPressure":
          updateFilter("BloodPressure");
          break;
        case "serumCreatinine":
        case "egfr":
          updateFilter("SerumCreatinine");
          break;
        case "all":
          updateFilter();
          break;

        case "bmi":
        case "height":
        case "weight":
          updateFilter("BMI");
          break;
        case "semiQuantitativeUacr":
          updateFilter("SemiQuantitativeUACR");
          break;

        default:
          throw new Error("Not implemented");
      }
    },
    [updateFilter],
  );

  useEffect(() => {
    const trans: Parameters<typeof actor.subscribe>["0"] = state => {
      if (state.matches("init")) {
        const resolved = resolvePath(mainRoutePaths.hcpDetails, location.pathname);

        const match = matchPath(
          `${resolved.pathname}/tests/:selectedSubTabRoute`,
          location.pathname,
        );
        // Special situation, update filter callback on first init with all won't work since the state is equal with itself
        if (match?.params.selectedSubTabRoute === "all") {
          actor.send({ type: "FETCH" });
        } else if (match?.params.selectedSubTabRoute !== undefined) {
          onClick(match?.params.selectedSubTabRoute);
        }
      }
    };

    const subscription = actor.subscribe(trans);

    return () => {
      subscription.unsubscribe();
    };
  }, [actor, onClick]);

  const tabs = useMemo(() => {
    const newTabProps = getMeasurementTabs("HCPDetails.LabList").map(tabProp => ({
      ...tabProp,
      onClick,
    }));

    return newTabProps;
  }, [onClick]);

  return (
    <TabsForRouter selectBox subRoute tabs={tabs}>
      <Outlet />
    </TabsForRouter>
  );
}
