import * as React from "react";
import { SVGProps } from "react";
const SvgTrendingDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.53 5.47A.75.75 0 0 0 .47 6.53l7.5 7.5a.75.75 0 0 0 1.06 0l4.47-4.47 7.69 7.69H17a.75.75 0 0 0 0 1.5h6a.747.747 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v4.19l-8.22-8.22a.75.75 0 0 0-1.06 0L8.5 12.44 1.53 5.47Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgTrendingDown;
