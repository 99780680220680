import classNames from "classnames";
import { CSSProperties, PropsWithChildren } from "react";
import "./RangeTableCell.scss";

interface RangeTableCellProps {
  className?: string;
  active?: boolean;
  cellCount?: number;
}

export function RangeTableCell({
  active,
  className,
  children,
  cellCount,
}: PropsWithChildren<RangeTableCellProps>) {
  const cellWidth: CSSProperties | undefined = cellCount
    ? { width: `calc(100%/${cellCount})` }
    : undefined;

  return (
    <td
      style={{ ...cellWidth }}
      className={classNames("RangeTableCell", className, { "RangeTableCell--active": active })}
    >
      {children}
    </td>
  );
}
