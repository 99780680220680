import { TimeZoneType } from "api/query/models/TimeZoneType";
import i18n from "i18next";

export function timezoneTypeToSelectOptions() {
  const timeZones = Object.keys(TimeZoneType);

  return timeZones.map(key => ({
    title: i18n.t(`Timezone.${key}`, { ns: "region" }).replace("_", " "),
    value: TimeZoneType[key as keyof typeof TimeZoneType],
  }));
}
