import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useTranslation } from "react-i18next";

export function useRegistryNumberLabel() {
  const { appConfig } = useGlobalConfigContext();
  const { t } = useTranslation("translation", { keyPrefix: "RegistryNumberLabel" });

  const label = t(appConfig?.general?.registryNumber?.registryNumberType ?? "noLabel");

  return [label] as const;
}
