import { useEffect, useState } from "react";

export function useFloatingActionButtons<T>(containerElem?: HTMLDivElement | null) {
  const [floatingActionProps, setFloatingActionProps] = useState<
    [React.MouseEvent<HTMLTableRowElement, MouseEvent>, T] | undefined
  >();

  useEffect(() => {
    const hideActionBtn = () => {
      setFloatingActionProps(undefined);
    };
    window.addEventListener("click", hideActionBtn);
    containerElem?.addEventListener("scroll", hideActionBtn);
    return () => {
      window.removeEventListener("click", hideActionBtn);
    };
  }, [containerElem]);

  return [floatingActionProps, setFloatingActionProps] as const;
}
