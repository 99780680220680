import { MeasurementUnitType } from "api/config_service";
import { EgfrFormulaType } from "api/query";
import { globalConfigStore } from "config/globalConfig";
import { MEASUREMENT_UNITS, REVERSED_MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { DeviceImage, Selection, Switch } from "libs/ui";
import { cloneDeep, isEqual } from "lodash-es";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LaboratoryConfig,
  SerumCreatinineConfig,
  laboratoryConfigMapper,
} from "utils/createGlobalConfigStore";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { creatinineUnitsToSelectOptions } from "utils/mappers/creatinineUnitsToSelectOptions";
import { deviceTypeToSelectOptions } from "utils/mappers/deviceTypeToSelectOptions";
import { egfrAlgorithmToSelectOptions } from "utils/mappers/egfrAlgorithmToSelectOptions";
import { testMethodToSelectOptions } from "utils/mappers/testMethodToSelectOptions";
import {
  RuleEngineFormElement,
  RuleEngineSection,
  RuleEngineSectionActions,
  RuleEngineSectionTitle,
} from "../../../elements";
import { ChangeRangeData, NormalRange, getFromValueError, getToValueError } from "./NormalRange";
import "./SerumCreatinineEgfr.scss";
import { getCorrectedValueForCreatinine } from "utils/converters/unitsConverter";

// This is just a safety measure, in real life this cannot happen because the default data will be defined when the Tenant is created
const DEFAULT_MEASUREMENTS_FORM: LaboratoryConfig["measurements"] = {
  serumCreatinine: {
    enableMeasurements: true,
    testMethod: "Device",
    preferredDevice: "NovaMaxProCreateEgfrMeter",
    measurementUnit: MEASUREMENT_UNITS.mgdL,
    maleNormalRange: { from: 0, to: 1 },
    femaleNormalRange: { from: 0, to: 1.3 },
  },
  egfr: {
    enableMeasurements: true,
    preferredAlgorithm: "Unknown",
  },
  viewOptions: {
    enableCalendarView: false,
    enableGraphView: false,
    enableListView: true,
  },
  urineAlbumin: {
    measurementUnit: MEASUREMENT_UNITS.mgL,
  },
};

function getLaboratoryStateWithSerumCreatininePerPrefUnit(
  data: LaboratoryConfig["measurements"],
): LaboratoryConfig["measurements"] {
  return {
    egfr: {
      ...data.egfr,
    },
    viewOptions: {
      ...data.viewOptions,
    },
    urineAlbumin: {
      ...data.urineAlbumin,
    },
    serumCreatinine: {
      ...data.serumCreatinine,
      maleNormalRange: {
        from: getCorrectedValueForCreatinine(
          data.serumCreatinine.maleNormalRange.from ?? 0,
          data.serumCreatinine.measurementUnit ?? "mg/dL",
        ),
        to: getCorrectedValueForCreatinine(
          data.serumCreatinine.maleNormalRange.to ?? 0,
          data.serumCreatinine.measurementUnit ?? "mg/dL",
        ),
      },
      femaleNormalRange: {
        from: getCorrectedValueForCreatinine(
          data.serumCreatinine.femaleNormalRange.from ?? 0,
          data.serumCreatinine.measurementUnit ?? "mg/dL",
        ),
        to: getCorrectedValueForCreatinine(
          data.serumCreatinine.femaleNormalRange.to ?? 0,
          data.serumCreatinine.measurementUnit ?? "mg/dL",
        ),
      },
    },
  };
}

function initState() {
  const newConfigMapped = globalConfigStore.getOrgConfig();

  const start = newConfigMapped
    ? laboratoryConfigMapper(newConfigMapped.laboratory.measurements).measurements
    : DEFAULT_MEASUREMENTS_FORM;

  return getLaboratoryStateWithSerumCreatininePerPrefUnit(start);
}

export function SerumCreatinineEgfr() {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.rule-engine.laboratory.serum-creatinine-and-egfr",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [measurementsForm, setMeasurementsForm] =
    useState<LaboratoryConfig["measurements"]>(initState);

  const onSerumCreatinineToggle = (
    inputKey: keyof Pick<LaboratoryConfig["measurements"]["serumCreatinine"], "enableMeasurements">,
  ) => {
    setMeasurementsForm(prevVal => ({
      ...prevVal,
      serumCreatinine: {
        ...prevVal.serumCreatinine,
        [inputKey]: !prevVal.serumCreatinine[inputKey],
      },
    }));
  };

  const onSerumCreatinineSelect = (
    value: string | undefined,
    inputKey: keyof Pick<
      SerumCreatinineConfig,
      "measurementUnit" | "preferredDevice" | "testMethod"
    >,
  ) => {
    setMeasurementsForm(prevVal => ({
      ...prevVal,
      serumCreatinine: {
        ...prevVal.serumCreatinine,
        [inputKey]: value,
      },
    }));
  };

  const onSerumCreatinineUnitChange = (unit: MEASUREMENT_UNITS | undefined) => {
    setMeasurementsForm(prevVal => ({
      ...prevVal,
      serumCreatinine: {
        ...prevVal.serumCreatinine,
        measurementUnit: unit,
        maleNormalRange: {
          from: getCorrectedValueForCreatinine(
            prevVal.serumCreatinine.maleNormalRange.from ?? 0,
            prevVal.serumCreatinine.measurementUnit ?? "mg/dL",
            unit,
          ),
          to: getCorrectedValueForCreatinine(
            prevVal.serumCreatinine.maleNormalRange.to ?? 0,
            prevVal.serumCreatinine.measurementUnit ?? "mg/dL",
            unit,
          ),
        },
        femaleNormalRange: {
          from: getCorrectedValueForCreatinine(
            prevVal.serumCreatinine.femaleNormalRange.from ?? 0,
            prevVal.serumCreatinine.measurementUnit ?? "mg/dL",
            unit,
          ),
          to: getCorrectedValueForCreatinine(
            prevVal.serumCreatinine.femaleNormalRange.to ?? 0,
            prevVal.serumCreatinine.measurementUnit ?? "mg/dL",
            unit,
          ),
        },
      },
    }));
  };

  const onSerumCreatinineRangeChange = useCallback(
    (gender: "femaleNormalRange" | "maleNormalRange") => (data: ChangeRangeData) => {
      setMeasurementsForm(prevValue => {
        const temp = cloneDeep(prevValue);

        temp.serumCreatinine[gender][data.range] = data.value ?? undefined;

        return temp;
      });
    },
    [],
  );

  const onEgfrToggle = (
    inputKey: keyof Pick<LaboratoryConfig["measurements"]["egfr"], "enableMeasurements">,
  ) => {
    setMeasurementsForm(prevVal => ({
      ...prevVal,
      egfr: {
        ...prevVal.egfr,
        [inputKey]: !prevVal.egfr[inputKey],
      },
    }));
  };

  const onEgfrSelect = (
    inputKey: keyof Pick<LaboratoryConfig["measurements"]["egfr"], "preferredAlgorithm">,
    value: EgfrFormulaType = "Unknown",
  ) => {
    setMeasurementsForm(prevVal => ({
      ...prevVal,
      egfr: {
        ...prevVal.egfr,
        [inputKey]: value,
      },
    }));
  };

  const onViewOptionToggle = (inputKey: keyof LaboratoryConfig["measurements"]["viewOptions"]) => {
    setMeasurementsForm(prevVal => ({
      ...prevVal,
      viewOptions: {
        ...prevVal.viewOptions,
        [inputKey]: !prevVal.viewOptions[inputKey],
      },
    }));
  };

  const onSave = useCallback(async () => {
    setIsLoading(true);
    const orgConfig = NotNullOrUndefined(globalConfigStore.getOrgConfig());

    const newConfig = await globalConfigStore.getRawLowerCasedData();

    if (!newConfig) {
      return;
    }

    newConfig.entities.custom = orgConfig.entities.custom;
    newConfig.web.components.disabled = orgConfig.web.components.disabled;

    newConfig.laboratory.measurements.creatinine = {
      ...measurementsForm.serumCreatinine,
      maleNormalRange: {
        from: getCorrectedValueForCreatinine(
          measurementsForm.serumCreatinine.maleNormalRange.from ?? 0,
          measurementsForm.serumCreatinine.measurementUnit ?? "mg/dL",
          "mg/dL",
        ),
        to: getCorrectedValueForCreatinine(
          measurementsForm.serumCreatinine.maleNormalRange.to ?? 0,
          measurementsForm.serumCreatinine.measurementUnit ?? "mg/dL",
          "mg/dL",
        ),
      },
      femaleNormalRange: {
        from: getCorrectedValueForCreatinine(
          measurementsForm.serumCreatinine.femaleNormalRange.from ?? 0,
          measurementsForm.serumCreatinine.measurementUnit ?? "mg/dL",
          "mg/dL",
        ),
        to: getCorrectedValueForCreatinine(
          measurementsForm.serumCreatinine.femaleNormalRange.to ?? 0,
          measurementsForm.serumCreatinine.measurementUnit ?? "mg/dL",
          "mg/dL",
        ),
      },
      measurementUnit: REVERSED_MEASUREMENT_UNITS[
        measurementsForm.serumCreatinine.measurementUnit!
      ] as MeasurementUnitType,
    };

    newConfig.laboratory.measurements.egfr = measurementsForm.egfr;

    newConfig.laboratory.measurements.viewOptions = measurementsForm.viewOptions;

    await globalConfigStore.saveConfig(newConfig);
    setIsLoading(false);
  }, [measurementsForm.egfr, measurementsForm.serumCreatinine, measurementsForm.viewOptions]);

  const onCancel = useCallback(() => setMeasurementsForm(initState), []);

  const isMaleRangeError =
    (getFromValueError(
      measurementsForm.serumCreatinine.maleNormalRange.from ?? null,
      measurementsForm.serumCreatinine.maleNormalRange.to ?? null,
    ) ?? getToValueError(measurementsForm.serumCreatinine.maleNormalRange.to ?? null)) !==
    undefined;
  const isFemaleRangeError =
    (getFromValueError(
      measurementsForm.serumCreatinine.femaleNormalRange.from ?? null,
      measurementsForm.serumCreatinine.femaleNormalRange.to ?? null,
    ) ?? getToValueError(measurementsForm.serumCreatinine.femaleNormalRange.to ?? null)) !==
    undefined;

  const saveButtonDisabled =
    isEqual(measurementsForm, initState()) || isLoading || isMaleRangeError || isFemaleRangeError;

  return (
    <RuleEngineSection>
      <div className="SerumCreatinineEgfr">
        <div className="SerumCreatinineEgfr__column">
          <RuleEngineSectionTitle>{t("serumCreatinine.title")}</RuleEngineSectionTitle>
          <RuleEngineFormElement
            subtitle={t("serumCreatinine.serumCreatinineMeasurementTitle")}
            description={t("serumCreatinine.serumCreatinineMeasurementDescription")}
          >
            <Switch
              name={"creatinine-measurement"}
              className="RuleEngine__switch"
              loading={isLoading}
              onChange={() => onSerumCreatinineToggle("enableMeasurements")}
              checked={measurementsForm.serumCreatinine.enableMeasurements}
            />
          </RuleEngineFormElement>
          <RuleEngineFormElement
            subtitle={t("serumCreatinine.testMethodTitle")}
            description={t("serumCreatinine.testMethodDescription")}
          >
            <Selection
              label={t("serumCreatinine.testMethodTitle")}
              options={testMethodToSelectOptions()}
              className="RuleEngine__selection"
              value={measurementsForm.serumCreatinine.testMethod}
              onSelect={val => onSerumCreatinineSelect(val, "testMethod")}
              loading={isLoading}
            />
          </RuleEngineFormElement>
          <RuleEngineFormElement
            subtitle={t("serumCreatinine.deviceTitle")}
            description={t("serumCreatinine.deviceDescription")}
          >
            <Selection
              label={t("serumCreatinine.deviceTitle")}
              options={deviceTypeToSelectOptions()}
              className="RuleEngine__selection"
              value={measurementsForm.serumCreatinine.preferredDevice}
              onSelect={val => onSerumCreatinineSelect(val, "preferredDevice")}
              loading={isLoading}
              icon={<DeviceImage />}
            />
          </RuleEngineFormElement>
          <RuleEngineFormElement
            subtitle={t("serumCreatinine.unitTitle")}
            description={t("serumCreatinine.unitDescription")}
          >
            <Selection
              label={t("serumCreatinine.unitTitle")}
              options={creatinineUnitsToSelectOptions()}
              className="RuleEngine__selection"
              value={measurementsForm.serumCreatinine.measurementUnit}
              onSelect={val => onSerumCreatinineUnitChange(val)}
              loading={isLoading}
            />
          </RuleEngineFormElement>
          <RuleEngineFormElement
            subtitle={t("serumCreatinine.maleNormalRangeTitle")}
            description={t("serumCreatinine.maleNormalRangeDescription")}
          >
            <NormalRange
              from={measurementsForm.serumCreatinine.maleNormalRange.from ?? null}
              to={measurementsForm.serumCreatinine.maleNormalRange.to ?? null}
              onChange={onSerumCreatinineRangeChange("maleNormalRange")}
              isLoading={isLoading}
            />
          </RuleEngineFormElement>
          <RuleEngineFormElement
            subtitle={t("serumCreatinine.femaleNormalRangeTitle")}
            description={t("serumCreatinine.femaleNormalRangeDescription")}
          >
            <NormalRange
              from={measurementsForm.serumCreatinine.femaleNormalRange.from ?? null}
              to={measurementsForm.serumCreatinine.femaleNormalRange.to ?? null}
              onChange={onSerumCreatinineRangeChange("femaleNormalRange")}
              isLoading={isLoading}
            />
          </RuleEngineFormElement>
        </div>

        <div className="SerumCreatinineEgfr__column">
          <RuleEngineSectionTitle>{t("egfr.title")}</RuleEngineSectionTitle>
          <RuleEngineFormElement
            subtitle={t("egfr.egfrValuesTitle")}
            description={t("egfr.egfrValuesDescription")}
          >
            <Switch
              name={"egfr-values"}
              className="RuleEngine__switch"
              loading={isLoading}
              checked={measurementsForm.egfr.enableMeasurements}
              onChange={() => onEgfrToggle("enableMeasurements")}
            />
          </RuleEngineFormElement>
          <RuleEngineFormElement
            subtitle={t("egfr.egfrAlgorithmTitle")}
            description={t("egfr.egfrAlgorithmDescription")}
          >
            <Selection
              label={t("egfr.egfrAlgorithmTitle")}
              options={egfrAlgorithmToSelectOptions()}
              className="RuleEngine__selection"
              value={measurementsForm.egfr.preferredAlgorithm}
              onSelect={val => onEgfrSelect("preferredAlgorithm", val)}
              loading={isLoading}
            />
          </RuleEngineFormElement>
        </div>

        <div className="SerumCreatinineEgfr__column">
          <RuleEngineSectionTitle>{t("view-options.title")}</RuleEngineSectionTitle>
          <RuleEngineFormElement
            subtitle={t("view-options.listViewTitle")}
            description={t("view-options.listViewDescription")}
          >
            <Switch
              name={"view-options-list-view"}
              className="RuleEngine__switch"
              loading={isLoading}
              checked={measurementsForm.viewOptions.enableListView}
              onChange={() => onViewOptionToggle("enableListView")}
            />
          </RuleEngineFormElement>
          <RuleEngineFormElement
            subtitle={t("view-options.graphViewTitle")}
            description={t("view-options.graphViewDescription")}
          >
            <Switch
              name={"view-options-list-view"}
              className="RuleEngine__switch"
              loading={isLoading}
              checked={measurementsForm.viewOptions.enableGraphView}
              onChange={() => onViewOptionToggle("enableGraphView")}
            />
          </RuleEngineFormElement>
          <RuleEngineFormElement
            subtitle={t("view-options.calendarViewTitle")}
            description={t("view-options.calendarViewDescription")}
          >
            <Switch
              name={"view-options-list-view"}
              className="RuleEngine__switch"
              loading={isLoading}
              checked={measurementsForm.viewOptions.enableCalendarView}
              onChange={() => onViewOptionToggle("enableCalendarView")}
            />
          </RuleEngineFormElement>
        </div>
      </div>
      <RuleEngineSectionActions
        onSave={onSave}
        onCancel={onCancel}
        cancelButtonDisabled={isLoading}
        saveButtonDisabled={saveButtonDisabled}
      />
    </RuleEngineSection>
  );
}
