/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
     GreaterThanFromJSONTyped,
     GreaterThanOrEqualFromJSONTyped,
     LessThanFromJSONTyped,
     LessThanOrEqualFromJSONTyped,
     WithinRangeExclusiveFromJSONTyped,
     WithinRangeInclusiveFromJSONTyped
} from './index';

/**
 * 
 * @export
 * @interface IStatusLimit
 */
export interface IStatusLimit {
    /**
     * 
     * @type {string}
     * @memberof IStatusLimit
     */
    $type: string;
}

/**
 * Check if a given object implements the IStatusLimit interface.
 */
export function instanceOfIStatusLimit(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "$type" in value;

    return isInstance;
}

export function IStatusLimitFromJSON(json: any): IStatusLimit {
    return IStatusLimitFromJSONTyped(json, false);
}

export function IStatusLimitFromJSONTyped(json: any, ignoreDiscriminator: boolean): IStatusLimit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['$type'] === 'GreaterThan') {
            return GreaterThanFromJSONTyped(json, true);
        }
        if (json['$type'] === 'GreaterThanOrEqual') {
            return GreaterThanOrEqualFromJSONTyped(json, true);
        }
        if (json['$type'] === 'LessThan') {
            return LessThanFromJSONTyped(json, true);
        }
        if (json['$type'] === 'LessThanOrEqual') {
            return LessThanOrEqualFromJSONTyped(json, true);
        }
        if (json['$type'] === 'WithinRangeExclusive') {
            return WithinRangeExclusiveFromJSONTyped(json, true);
        }
        if (json['$type'] === 'WithinRangeInclusive') {
            return WithinRangeInclusiveFromJSONTyped(json, true);
        }
    }
    return {
        
        '$type': json['$type'],
    };
}

export function IStatusLimitToJSON(value?: IStatusLimit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '$type': value.$type,
    };
}

