import { CommonProblemDetailsModel } from "api/event/models";
import { isPlainObject } from "lodash-es";

export function IsNullOrUndefined<T>(value: T | null | undefined): boolean {
  return value === null || value === undefined;
}

export function IsNullOrWhiteSpace<T>(value: T | null | undefined): boolean {
  return IsNullOrUndefined(value) || value === "";
}

export function isBeErrorModel(error: any): error is CommonProblemDetailsModel {
  return isPlainObject(error) && Object.hasOwn(error, "errors") && Object.hasOwn(error, "type");
}

export type FakeData<T> = T & { _fake: true };
export function isFakeData(data: any): data is FakeData<any> {
  return data?._fake === true;
}
export function isRealData<T>(data: T | FakeData<T>): data is T {
  return !isFakeData(data);
}
