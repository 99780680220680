import { captureMessage, withScope } from "@sentry/react";
import pino from "pino";

const pinoToSentryLevel = {
  trace: "debug",
  debug: "debug",
  info: "info",
  warn: "warning",
  error: "error",
  fatal: "fatal",
} as const;

// Function to send logs to Sentry
const sendToSentry = (level: pino.Level, logEvent: pino.LogEvent) => {
  if (process.env.VITE_APP_SENTRY_ENABLED !== "true") {
    return;
  }
  const message = logEvent.messages[0];
  withScope(scope => {
    scope.setLevel(pinoToSentryLevel[level]);
    scope.setExtras({ logEvent });
    captureMessage(message);
  });
};

// Setup Pino with a custom transmit function for Sentry
export const logger = pino({
  browser: {
    transmit: {
      level: "error", // Only send errors to Sentry
      send: sendToSentry,
    },
  },
});
