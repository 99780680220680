import { TestFormModel, TestFormModelKey } from "../../model";
import { SingleMeasurementField } from "./SingleMeasurementField";
import "./Measurements.scss";
import { useCallback } from "react";
import cloneDeep from "lodash-es/cloneDeep";
import {
  MeasurementItemUIModel,
  MeasurementItemUIType,
  MeasurementUIType,
  UImeasurementTypes,
} from "models/TestModels";
import { getAvailableMeasurementTypes } from "./getAvailableMeasurementTypes";

interface MeasurementsProps {
  testData: TestFormModel;
  onChange: (
    value: string | ((prevValue: TestFormModel) => TestFormModel["measurements"]) | undefined,
    inputKey: TestFormModelKey,
  ) => void;
}

const UIMeasurementsHelper = {
  getDefaultValuesByType(type: MeasurementUIType): MeasurementItemUIModel[] | null {
    switch (type) {
      case "SemiQuantitativeUACR":
        return [
          { unit: "None", value: "", type: "SemiQuantitativeUacr" },
          { unit: "mgdL", value: "0", type: "SemiQuantitativeUrineCreatinine" }, // !!! BE requirement to send it with value 0
          { unit: "mgL", value: "0", type: "SemiQuantitativeUrineAlbumin" }, // !!! BE requirement to send it with value 0
        ];
      case "Glucose":
        return [{ unit: "mgdL", value: "", type: "Glucose" }];
      case "SerumCreatinine":
        return [{ unit: "mgdL", value: "", type: "SerumCreatinine" }];
      case "BloodPressure":
        return [
          {
            unit: "mmHg",
            value: "",
            type: "Systolic",
          },
          {
            unit: "mmHg",
            value: "",
            type: "Diastolic",
          },
        ];
      case "UACR":
        return [
          {
            unit: "mgdL",
            value: "",
            type: "UrineCreatinine",
          },
          {
            unit: "mgL",
            value: "",
            type: "UrineAlbumin",
          },
        ];

      case "BMI":
        return [
          {
            unit: "mm",
            value: "",
            type: "Height",
          },
          {
            unit: "g",
            value: "",
            type: "Weight",
          },
        ];
      default:
        return null;
    }
  },
} as const;

export function Measurements({ testData, onChange }: MeasurementsProps) {
  const onMeasurementTypeSelect = useCallback(
    (targetType: MeasurementUIType, previousType?: MeasurementUIType) => {
      return onChange(prevFormState => {
        const newMeasurements = cloneDeep(prevFormState.measurements);
        const itemIndexOnList = newMeasurements.findIndex(
          el => el.measurementType === previousType,
        );

        const initItemsByType = UIMeasurementsHelper.getDefaultValuesByType(targetType) ?? [];

        newMeasurements.splice(itemIndexOnList, 1, {
          measurementType: targetType,
          items: initItemsByType,
        });

        const listWithSelectedTypes = newMeasurements.filter(
          measurement => measurement.measurementType !== "None",
        );

        if (listWithSelectedTypes.length >= UImeasurementTypes.length) {
          return listWithSelectedTypes;
        }

        return [
          ...listWithSelectedTypes,
          {
            measurementType: "None",
            items: [],
          },
        ];
      }, "measurements");
    },
    [onChange],
  );

  const onMeasurementValueChange = useCallback(
    (type: MeasurementUIType, value: string, itemType?: MeasurementItemUIType) => {
      onChange(prevValue => {
        const newMeasurements = cloneDeep(prevValue.measurements);

        const typeIndex = newMeasurements.findIndex(el => el.measurementType === type);
        const itemTargetIndex = newMeasurements[typeIndex].items.findIndex(
          el => el.type === itemType,
        );

        /**
         * Unit and Type is initialized in onMeasurementTypeSelect function
         */
        newMeasurements[typeIndex].items[itemTargetIndex === -1 ? 0 : itemTargetIndex].value =
          value;

        return newMeasurements;
      }, "measurements");
    },
    [onChange],
  );

  const onMeasurementDelete = (type: MeasurementUIType) =>
    onChange(prevValue => {
      const newMeasurements = cloneDeep(prevValue.measurements);
      const typeIndex = newMeasurements.findIndex(el => el.measurementType === type);
      newMeasurements.splice(typeIndex, 1);

      const listWithSelectedTypes = newMeasurements.filter(
        measurement => measurement.measurementType !== "None",
      );
      listWithSelectedTypes.push({ measurementType: "None", items: [] });

      return listWithSelectedTypes;
    }, "measurements");

  return (
    <>
      <div className="Measurements">
        {testData.measurements.length === 0 ? (
          <SingleMeasurementField
            validationError={false}
            key={"first"}
            model={{ items: [], measurementType: "None" }}
            optionTypes={getAvailableMeasurementTypes(testData)}
            onMeasurementTypeSelect={onMeasurementTypeSelect}
            onMeasurementValueChange={onMeasurementValueChange}
          />
        ) : null}

        {testData.measurements.map(measurement => {
          return (
            <SingleMeasurementField
              key={measurement.measurementType}
              model={measurement}
              optionTypes={getAvailableMeasurementTypes(testData)}
              onMeasurementTypeSelect={onMeasurementTypeSelect}
              onMeasurementValueChange={onMeasurementValueChange}
              onMeasurementDelete={onMeasurementDelete}
              validationError={
                measurement.measurementType !== "None" &&
                measurement.items.some(item => item.value === "")
              }
            />
          );
        })}
      </div>
    </>
  );
}
