import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getLocalHeight } from "utils/converters/getLocalHeight";

export function useGetHeightNoLabel() {
  const { globalPreference } = useGlobalPreferenceContext();
  const { t } = useTranslation("measurements", { keyPrefix: "Measurements" });

  const resolveHeight = useCallback(
    (value: number) => {
      const localHeight = getLocalHeight(value, globalPreference?.regionType);

      if (globalPreference?.measureUnitType === "Metric" && localHeight.cm === "") {
        return "-";
      }

      if (
        globalPreference?.measureUnitType === "Imperial" &&
        localHeight.feet === "" &&
        localHeight.inches === ""
      ) {
        return "-";
      }

      return globalPreference?.measureUnitType === "Metric"
        ? localHeight.cm
        : t("heightImperialValue", {
            feet: localHeight.feet,
            inch: localHeight.inches,
          });
    },
    [globalPreference?.measureUnitType, globalPreference?.regionType, t],
  );

  return [resolveHeight] as const;
}
