import { DevicesTableColumns } from "utils/createGlobalConfigStore";

interface DevicesTableColumnsProps {
  t: TFunction;
  showUserIds: boolean;
  showActions: boolean;
}

const allDevicesTableColumns = ({ t, showUserIds, showActions }: DevicesTableColumnsProps) => {
  return (
    [
      "deviceType",
      "manufacturerType",
      "serialNumber",
      "organizationIds",
      "deviceStatuses",
      ...(showUserIds ? ["userIds"] : []),
      ...(showActions ? ["actions"] : []),
    ] as const
  ).map(name => ({
    label: t(`columns.${name}`),
    name,
  }));
};

interface AppliedColumnsProps extends DevicesTableColumnsProps {
  appliedColumns: DevicesTableColumns[];
}

export function resolveAppliedColumns({ appliedColumns, ...rest }: AppliedColumnsProps) {
  return allDevicesTableColumns(rest).filter(column =>
    appliedColumns.some(el => el === column.name),
  );
}
