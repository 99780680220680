import {
  AdditionalPropertyConfigField,
  ContextFieldConfig,
  ContextsFieldConfig,
} from "utils/createGlobalConfigStore";

export function isRecord(prop: any): prop is Record<string, any> {
  if (typeof prop === "object" && !Array.isArray(prop) && prop !== null) {
    return true;
  }

  return false;
}

export function isContextField(prop: any): prop is ContextFieldConfig {
  return (
    prop.visibility === "Optional" ||
    prop.visibility === "Required" ||
    prop.visibility === "Disabled"
  );
}

export function isContext(prop: any): prop is ContextsFieldConfig {
  const hasAddOrUpdate =
    isRecord(prop) && Object.keys(prop).some(val => ["Add", "Update"].includes(val));

  if (hasAddOrUpdate) {
    return Object.values(prop).every(val => isContextField(val));
  }

  return false;
}

export function isAdditionalProperties(prop: any): prop is AdditionalPropertyConfigField {
  return (
    isRecord(prop) &&
    typeof prop.typeName === "string" &&
    typeof prop.propertyName === "string" &&
    isContext(prop.contexts)
  );
}

export type FieldType =
  | { type: "SelectBox"; options: { title: string; value: any }[] }
  | { type: "CheckList"; options: { title: string; value: any }[] }
  | { type: "TextInput" }
  | { type: "NotSupported" };
