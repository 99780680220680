/* tslint:disable */
 
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MeasurementUnitType = {
    mgdL: 'mgdL',
    mLmin173m2: 'mLmin173m2',
    umolL: 'umolL',
    mmHg: 'mmHg',
    mgL: 'mgL',
    mgg: 'mgg',
    mm: 'mm',
    g: 'g',
    kgM2: 'kgM2'
} as const;
export type MeasurementUnitType = typeof MeasurementUnitType[keyof typeof MeasurementUnitType];


export function MeasurementUnitTypeFromJSON(json: any): MeasurementUnitType {
    return MeasurementUnitTypeFromJSONTyped(json, false);
}

export function MeasurementUnitTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementUnitType {
    return json as MeasurementUnitType;
}

export function MeasurementUnitTypeToJSON(value?: MeasurementUnitType | null): any {
    return value as any;
}

