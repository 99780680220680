import { Icon } from "libs/ui";
import "./CommittedToPrivacyCard.scss";

interface CommittedToPrivacyCardProps {
  title: string;
  externalLink: string;
}

export function CommittedToPrivacyCard({ title, externalLink }: CommittedToPrivacyCardProps) {
  return (
    <a href={externalLink} className="CommittedToPrivacyCard" target="_blank" rel="noreferrer">
      <Icon icon="FileText" />
      <h5 className="CommittedToPrivacyCard__title">{title}</h5>
      <Icon icon="ChevronRight" />
    </a>
  );
}
