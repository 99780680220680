/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  HcpResponseModel,
  HcpResponseModelListModel,
  HcpsPerOrganizationFilterModel,
  PatientResponseModelListModel,
  PatientsPerHcpFilterModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    HcpResponseModelFromJSON,
    HcpResponseModelToJSON,
    HcpResponseModelListModelFromJSON,
    HcpResponseModelListModelToJSON,
    HcpsPerOrganizationFilterModelFromJSON,
    HcpsPerOrganizationFilterModelToJSON,
    PatientResponseModelListModelFromJSON,
    PatientResponseModelListModelToJSON,
    PatientsPerHcpFilterModelFromJSON,
    PatientsPerHcpFilterModelToJSON,
} from '../models/index';

export interface OrganizationsOrganizationIdHcpsGetRequest {
    organizationId: string;
    filters?: HcpsPerOrganizationFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdHcpsHcpEntityIdGetRequest {
    organizationId: string;
    hcpEntityId: string;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdHcpsHcpEntityIdPatientsGetRequest {
    organizationId: string;
    hcpEntityId: string;
    filters?: PatientsPerHcpFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

/**
 * HcpsApi - interface
 * 
 * @export
 * @interface HcpsApiInterface
 */
export interface HcpsApiInterface {
    /**
     * 
     * @param {string} organizationId 
     * @param {HcpsPerOrganizationFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpsApiInterface
     */
    organizationsOrganizationIdHcpsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HcpResponseModelListModel>>;

    /**
     */
    organizationsOrganizationIdHcpsGet(requestParameters: OrganizationsOrganizationIdHcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HcpResponseModelListModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} hcpEntityId 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpsApiInterface
     */
    organizationsOrganizationIdHcpsHcpEntityIdGetRaw(requestParameters: OrganizationsOrganizationIdHcpsHcpEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HcpResponseModel>>;

    /**
     */
    organizationsOrganizationIdHcpsHcpEntityIdGet(requestParameters: OrganizationsOrganizationIdHcpsHcpEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HcpResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} hcpEntityId 
     * @param {PatientsPerHcpFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpsApiInterface
     */
    organizationsOrganizationIdHcpsHcpEntityIdPatientsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsHcpEntityIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientResponseModelListModel>>;

    /**
     */
    organizationsOrganizationIdHcpsHcpEntityIdPatientsGet(requestParameters: OrganizationsOrganizationIdHcpsHcpEntityIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientResponseModelListModel>;

}

/**
 * 
 */
export class HcpsApi extends runtime.BaseAPI implements HcpsApiInterface {

     /**
     */
    async organizationsOrganizationIdHcpsGetRawOriginal(requestParameters: OrganizationsOrganizationIdHcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/hcps`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdHcpsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HcpResponseModelListModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HcpResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsGet(requestParameters: OrganizationsOrganizationIdHcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HcpResponseModelListModel> {
        const response = await this.organizationsOrganizationIdHcpsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdHcpsHcpEntityIdGetRawOriginal(requestParameters: OrganizationsOrganizationIdHcpsHcpEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsHcpEntityIdGet.');
        }

        if (requestParameters.hcpEntityId === null || requestParameters.hcpEntityId === undefined) {
            throw new runtime.RequiredError('hcpEntityId','Required parameter requestParameters.hcpEntityId was null or undefined when calling organizationsOrganizationIdHcpsHcpEntityIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/hcps/{hcpEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"hcpEntityId"}}`, encodeURIComponent(String(requestParameters.hcpEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdHcpsHcpEntityIdGetRaw(requestParameters: OrganizationsOrganizationIdHcpsHcpEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HcpResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsHcpEntityIdGet.');
        }

        if (requestParameters.hcpEntityId === null || requestParameters.hcpEntityId === undefined) {
            throw new runtime.RequiredError('hcpEntityId','Required parameter requestParameters.hcpEntityId was null or undefined when calling organizationsOrganizationIdHcpsHcpEntityIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{hcpEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"hcpEntityId"}}`, encodeURIComponent(String(requestParameters.hcpEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HcpResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsHcpEntityIdGet(requestParameters: OrganizationsOrganizationIdHcpsHcpEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HcpResponseModel> {
        const response = await this.organizationsOrganizationIdHcpsHcpEntityIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdHcpsHcpEntityIdPatientsGetRawOriginal(requestParameters: OrganizationsOrganizationIdHcpsHcpEntityIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsHcpEntityIdPatientsGet.');
        }

        if (requestParameters.hcpEntityId === null || requestParameters.hcpEntityId === undefined) {
            throw new runtime.RequiredError('hcpEntityId','Required parameter requestParameters.hcpEntityId was null or undefined when calling organizationsOrganizationIdHcpsHcpEntityIdPatientsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/hcps/{hcpEntityId}/patients`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"hcpEntityId"}}`, encodeURIComponent(String(requestParameters.hcpEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdHcpsHcpEntityIdPatientsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsHcpEntityIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientResponseModelListModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsHcpEntityIdPatientsGet.');
        }

        if (requestParameters.hcpEntityId === null || requestParameters.hcpEntityId === undefined) {
            throw new runtime.RequiredError('hcpEntityId','Required parameter requestParameters.hcpEntityId was null or undefined when calling organizationsOrganizationIdHcpsHcpEntityIdPatientsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{hcpEntityId}/patients`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"hcpEntityId"}}`, encodeURIComponent(String(requestParameters.hcpEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsHcpEntityIdPatientsGet(requestParameters: OrganizationsOrganizationIdHcpsHcpEntityIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientResponseModelListModel> {
        const response = await this.organizationsOrganizationIdHcpsHcpEntityIdPatientsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
