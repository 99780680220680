/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  IdentifiedWithCkdRiskFilterModel,
  IdentifiedWithCkdRiskReportModel,
  WithEgfrFilterModel,
  WithEgfrReportModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    IdentifiedWithCkdRiskFilterModelFromJSON,
    IdentifiedWithCkdRiskFilterModelToJSON,
    IdentifiedWithCkdRiskReportModelFromJSON,
    IdentifiedWithCkdRiskReportModelToJSON,
    WithEgfrFilterModelFromJSON,
    WithEgfrFilterModelToJSON,
    WithEgfrReportModelFromJSON,
    WithEgfrReportModelToJSON,
} from '../models/index';

export interface DashboardsMapsIdentifiedWithCkdRiskGetRequest {
    filter?: IdentifiedWithCkdRiskFilterModel;
}

export interface DashboardsMapsWithEgfrGetRequest {
    filter?: WithEgfrFilterModel;
}

/**
 * DashboardsApi - interface
 * 
 * @export
 * @interface DashboardsApiInterface
 */
export interface DashboardsApiInterface {
    /**
     * 
     * @param {IdentifiedWithCkdRiskFilterModel} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApiInterface
     */
    dashboardsMapsIdentifiedWithCkdRiskGetRaw(requestParameters: DashboardsMapsIdentifiedWithCkdRiskGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentifiedWithCkdRiskReportModel>>;

    /**
     */
    dashboardsMapsIdentifiedWithCkdRiskGet(filter?: IdentifiedWithCkdRiskFilterModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentifiedWithCkdRiskReportModel>;

    /**
     * 
     * @param {WithEgfrFilterModel} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApiInterface
     */
    dashboardsMapsWithEgfrGetRaw(requestParameters: DashboardsMapsWithEgfrGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithEgfrReportModel>>;

    /**
     */
    dashboardsMapsWithEgfrGet(filter?: WithEgfrFilterModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithEgfrReportModel>;

}

/**
 * 
 */
export class DashboardsApi extends runtime.BaseAPI implements DashboardsApiInterface {

     /**
     */
    async dashboardsMapsIdentifiedWithCkdRiskGetRawOriginal(requestParameters: DashboardsMapsIdentifiedWithCkdRiskGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return this.request({
            path: `/dashboards/maps/identified-with-ckd-risk`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async dashboardsMapsIdentifiedWithCkdRiskGetRaw(requestParameters: DashboardsMapsIdentifiedWithCkdRiskGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentifiedWithCkdRiskReportModel>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboards/maps/identified-with-ckd-risk`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentifiedWithCkdRiskReportModelFromJSON(jsonValue));
    }

    /**
     */
    async dashboardsMapsIdentifiedWithCkdRiskGet(filter?: IdentifiedWithCkdRiskFilterModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentifiedWithCkdRiskReportModel> {
        const response = await this.dashboardsMapsIdentifiedWithCkdRiskGetRaw({ filter: filter }, initOverrides);
        return await response.value();
    }
    async dashboardsMapsIdentifiedWithCkdRiskGetOriginal(filter?: IdentifiedWithCkdRiskFilterModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.dashboardsMapsIdentifiedWithCkdRiskGetRawOriginal({ filter: filter }, initOverrides);
    }

     /**
     */
    async dashboardsMapsWithEgfrGetRawOriginal(requestParameters: DashboardsMapsWithEgfrGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return this.request({
            path: `/dashboards/maps/with-egfr`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async dashboardsMapsWithEgfrGetRaw(requestParameters: DashboardsMapsWithEgfrGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithEgfrReportModel>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboards/maps/with-egfr`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithEgfrReportModelFromJSON(jsonValue));
    }

    /**
     */
    async dashboardsMapsWithEgfrGet(filter?: WithEgfrFilterModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithEgfrReportModel> {
        const response = await this.dashboardsMapsWithEgfrGetRaw({ filter: filter }, initOverrides);
        return await response.value();
    }
    async dashboardsMapsWithEgfrGetOriginal(filter?: WithEgfrFilterModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.dashboardsMapsWithEgfrGetRawOriginal({ filter: filter }, initOverrides);
    }

}
