import { SingleMeasurement } from "../SingleMeasurement";
import { MeasurementTrendType, UacrStatusType } from "models/TestModels";
import { UACRRangeTable } from "components/MeasurementSideDetails/SideDetails/elements/UACRRangeTable";
import "./UacrResult.scss";
import classNames from "classnames";

interface UacrResultProps {
  value?: number;
  date?: Date | string;
  trendType?: MeasurementTrendType;
  percent?: number;
  status: UacrStatusType | null;
  uacrType: "UACR" | "SQUACR";
  small?: boolean;
}

export function UacrResult({
  value,
  date,
  trendType,
  percent,
  status,
  uacrType,
  small,
}: UacrResultProps) {
  return (
    <div className={classNames("UacrResult", { "UacrResult--small": small })}>
      <SingleMeasurement
        measurementUnit={uacrType}
        value={value}
        date={date}
        trendType={trendType}
        percent={percent}
      />

      <UACRRangeTable orientation="vertical" status={status} small={small} />
    </div>
  );
}
