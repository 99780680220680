import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from "react";
import { Layout, Layouts } from "react-grid-layout";
import { createSafeContext } from "utils/createSafeContext";
import { useLocalStorage } from "utils/hooks/useLocalStorage";
import { INIT_LAYOUTS } from "./layoutModels";
import { Breakpoint } from "components/DnD";
import { StatsWidgetType } from "./model";
import { getUpdatedLayout } from "./utils";

interface DashboardStatsWidgetContextData {
  latestSavedLayouts: Layouts;
  onLayoutChange: (currentLayout: Layout[], allLayouts: Layouts) => void;
  currentBreakpoint: Breakpoint | undefined;
  setCurrentBreakpoint: Dispatch<SetStateAction<Breakpoint | undefined>>;
  showAddWidgetModal: boolean;
  toggleAddWidgetModal: () => void;
  onManageWidgets: (addedWidgetTypes: StatsWidgetType[]) => void;
  onRemoveWidget: (removeWidget: StatsWidgetType) => void;
  hasWidgets: boolean;
}

const Context = createSafeContext<DashboardStatsWidgetContextData>();

export function DashboardStatsWidgetContextProvider({ children }: Readonly<PropsWithChildren>) {
  const [showAddWidgetModal, toggleAddWidgetModal] = useReducer(state => !state, false);

  const { storageValue: latestSavedLayouts = INIT_LAYOUTS, setLocalStorageItem } = useLocalStorage<
    Layouts | undefined
  >("DashboardStatsLayout");
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint | undefined>();

  const onLayoutChange = useCallback(
    (_currentLayout: Layout[], allLayouts: Layouts) => {
      setLocalStorageItem(allLayouts);
    },
    [setLocalStorageItem],
  );

  const onManageWidgets = useCallback(
    (addedWidgetTypes: StatsWidgetType[]) => {
      if (!currentBreakpoint) {
        return;
      }
      const updatedLayouts = Object.entries(latestSavedLayouts).reduce((acc, [key, layout]) => {
        return {
          ...acc,
          [key]: getUpdatedLayout(layout, addedWidgetTypes, key as Breakpoint),
        };
      }, {});

      onLayoutChange([], updatedLayouts);
    },
    [currentBreakpoint, latestSavedLayouts, onLayoutChange],
  );

  const onRemoveWidget = useCallback(
    (removeWidget: StatsWidgetType) => {
      if (!currentBreakpoint) {
        return;
      }
      const updatedListOfWidget = latestSavedLayouts[currentBreakpoint]
        .map(el => el.i)
        .filter(el => el !== removeWidget) as StatsWidgetType[];

      onManageWidgets(updatedListOfWidget);
    },
    [currentBreakpoint, latestSavedLayouts, onManageWidgets],
  );

  const hasWidgets = latestSavedLayouts[currentBreakpoint ?? "col-9"].length > 0;

  const value = useMemo(
    () => ({
      currentBreakpoint,
      latestSavedLayouts,
      onLayoutChange,
      setCurrentBreakpoint,
      showAddWidgetModal,
      toggleAddWidgetModal,
      onManageWidgets,
      onRemoveWidget,
      hasWidgets,
    }),
    [
      currentBreakpoint,
      latestSavedLayouts,
      onLayoutChange,
      showAddWidgetModal,
      onManageWidgets,
      onRemoveWidget,
      hasWidgets,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useDashboardStatsWidgetContext = Context.hook;
