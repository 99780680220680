import { Outlet, useMatch } from "react-router-dom";
import { useDashboardMapReportContext } from "./DashboardMap/DashboardMapReportDataContext";
import "./DashboardPage.scss";
import { DashboardPageNavigation } from "./DashboardPageNavigation";

import { autoPlacement, autoUpdate } from "@floating-ui/react-dom";
import { DashboardMapsGroupBy } from "api/report_service/models/DashboardMapsGroupBy";
import classNames from "classnames";
import { Select } from "libs/ui/Select";
import { Option } from "libs/ui/Select/Select.model";
import { useTranslation } from "react-i18next";
import { dashboardPaths } from "router";
import { DashboardMapAside } from "./DashboardMap/DashboardMapAside";
import { MapFilters } from "./DashboardMap/MapFilters";
import { Button } from "libs/ui/Button";
import { useDashboardStatsWidgetContext } from "./DashboardStats/DashboardStatsWidgetContext";

export function DashboardPage() {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.groupingOptions" });
  const { t: tStatsPage } = useTranslation("translation", {
    keyPrefix: "DashboardPage.DashboardStatsPage",
  });
  const { hasWidgets, toggleAddWidgetModal } = useDashboardStatsWidgetContext();

  const weAreOnDashboardMap = !!useMatch({ path: `${dashboardPaths.dashboardMap}`, end: true });
  const weAreOnDashboardStats = !!useMatch({ path: `${dashboardPaths.dashboardStats}`, end: true });

  const { groupingOption, onGroupingOptionChange } = useDashboardMapReportContext();
  const groupingOptions: Option<DashboardMapsGroupBy>[] = [
    { title: t("organizations"), value: "Organization" },
    { title: t("address-city"), value: "PatientAddressCity" },
    { title: t("address-country"), value: "PatientAddressCountry" },
  ];

  return (
    <div
      className={classNames("DashboardPage", {
        "DashboardPage__map-state": weAreOnDashboardMap,
        DashboardMapPage: weAreOnDashboardMap,
        DashboardStatsPage: weAreOnDashboardStats,
      })}
    >
      <div className="DashboardPage__header">
        <DashboardPageNavigation />

        {weAreOnDashboardMap ? (
          <div className="DashboardPage__tools">
            <MapFilters />

            <Select
              onSelect={onGroupingOptionChange}
              value={groupingOption}
              className="DashboardMap__select"
              options={groupingOptions}
              dropdownFloatingProps={{
                strategy: "absolute",
                placement: "bottom-end",
                middleware: [autoPlacement({ allowedPlacements: ["bottom-end"] })],
                whileElementsMounted: autoUpdate,
              }}
            />
          </div>
        ) : null}

        {weAreOnDashboardStats && hasWidgets ? (
          <div className="DashboardPage__tools">
            {/* !!! TODO: uncomment when filters are needed */}
            {/* <Button buttonSize="medium" buttonType="white" buttonIcon={{ icon: "Filter" }} /> */}
            <Button
              data-testid="OpenAddModal"
              buttonType="primary"
              buttonSize="medium"
              buttonIcon={{ icon: "Settings" }}
              onClick={toggleAddWidgetModal}
            >
              {tStatsPage("manageWidgetButton")}
            </Button>
          </div>
        ) : null}
      </div>

      {weAreOnDashboardMap ? <DashboardMapAside groupingOption={groupingOption} /> : null}

      <Outlet />
    </div>
  );
}
