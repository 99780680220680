import * as React from "react";
import { SVGProps } from "react";
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.46 1.408a.75.75 0 0 0-.92 0l-9 7A.75.75 0 0 0 2.25 9v11A2.75 2.75 0 0 0 5 22.75h14A2.75 2.75 0 0 0 21.75 20V9a.75.75 0 0 0-.29-.592l-9-7Zm3.29 19.842H19A1.25 1.25 0 0 0 20.25 20V9.367L12 2.95 3.75 9.367V20A1.25 1.25 0 0 0 5 21.25h3.25V14a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 .75.75v7.25Zm-6 0v-6.5h4.5v6.5h-4.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgHome;
