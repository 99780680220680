import { Route } from "react-router";
import { mainRoutePaths } from "router";
import { applyTo } from "ramda";
import { AccessLevel } from "../utils/AccessLevel";
import { Parent } from "pages/DevicesPage/Parent";
import { Details } from "pages/DevicesPage/Details";
import { DevicesTableStateProvider } from "components/AppTables/AllDevicesTable/context";
import { DetailsPageContextProvider } from "components/PageTemplate/DetailsPage/context";
import { Outlet } from "react-router-dom";

export const DevicesRoutes = (
  <Route
    path={mainRoutePaths.devices}
    element={
      <DetailsPageContextProvider>
        <Outlet />
      </DetailsPageContextProvider>
    }
  >
    <Route
      index
      element={
        <DevicesTableStateProvider>
          <Parent />
        </DevicesTableStateProvider>
      }
    />
    <Route
      path=":organizationId/:deviceId"
      element={<AccessLevel check={applyTo("manage-device")} />}
    >
      <Route index element={<Details />} />
    </Route>
  </Route>
);
