import { Button } from "libs/ui/Button";
import { Icon } from "libs/ui/Icon";
import { Loader } from "libs/ui/Loader";
import { useTranslation } from "react-i18next";
import { SingleReportStatusProps } from "../../model";
import "./SingleReportStatus.scss";
import { generatePath, Link } from "react-router-dom";
import { mainRoutePaths, reportsRoutePaths } from "router";

export function SingleReportStatus({ status, onReportDownload }: SingleReportStatusProps) {
  const { t } = useTranslation("translation", { keyPrefix: "ReportPage.SingleReport.Data" });

  if (status === "InProgress" || status === "Requested") {
    return (
      <div className="SingleReportStatus">
        <Loader inline loading loadingText={t("statusPreparing")} />
      </div>
    );
  }
  if (status === "Failed") {
    return (
      <div className="SingleReportStatus SingleReportStatus--error">
        <p className="SingleReportStatus__errorText">
          <Icon icon={"CircleError"} />
          {t("somethingWentWrongText")}
        </p>
        <Link
          className="SingleReportStatus__link"
          to={generatePath(mainRoutePaths.reports.concat(reportsRoutePaths.requestNew))}
        >
          {t("requestNewReportButton")}
        </Link>
      </div>
    );
  }

  return (
    <div className="SingleReportStatus">
      <Button
        onClick={onReportDownload}
        buttonIcon={{ icon: "Download" }}
        buttonType="primary"
        buttonSize={"medium"}
      >
        {t("downloadButton")}
      </Button>
    </div>
  );
}
