/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntitiesSectionModel } from './EntitiesSectionModel';
import {
    EntitiesSectionModelFromJSON,
    EntitiesSectionModelFromJSONTyped,
    EntitiesSectionModelToJSON,
} from './EntitiesSectionModel';
import type { InfrastructureSectionModel } from './InfrastructureSectionModel';
import {
    InfrastructureSectionModelFromJSON,
    InfrastructureSectionModelFromJSONTyped,
    InfrastructureSectionModelToJSON,
} from './InfrastructureSectionModel';
import type { LaboratorySectionModel } from './LaboratorySectionModel';
import {
    LaboratorySectionModelFromJSON,
    LaboratorySectionModelFromJSONTyped,
    LaboratorySectionModelToJSON,
} from './LaboratorySectionModel';
import type { MobileSectionModel } from './MobileSectionModel';
import {
    MobileSectionModelFromJSON,
    MobileSectionModelFromJSONTyped,
    MobileSectionModelToJSON,
} from './MobileSectionModel';
import type { UsersSectionModel } from './UsersSectionModel';
import {
    UsersSectionModelFromJSON,
    UsersSectionModelFromJSONTyped,
    UsersSectionModelToJSON,
} from './UsersSectionModel';
import type { WebSectionModel } from './WebSectionModel';
import {
    WebSectionModelFromJSON,
    WebSectionModelFromJSONTyped,
    WebSectionModelToJSON,
} from './WebSectionModel';

/**
 * 
 * @export
 * @interface ConfigurationModel
 */
export interface ConfigurationModel {
    /**
     * 
     * @type {EntitiesSectionModel}
     * @memberof ConfigurationModel
     */
    entities: EntitiesSectionModel;
    /**
     * 
     * @type {InfrastructureSectionModel}
     * @memberof ConfigurationModel
     */
    infrastructure: InfrastructureSectionModel;
    /**
     * 
     * @type {LaboratorySectionModel}
     * @memberof ConfigurationModel
     */
    laboratory: LaboratorySectionModel;
    /**
     * 
     * @type {MobileSectionModel}
     * @memberof ConfigurationModel
     */
    mobile: MobileSectionModel;
    /**
     * 
     * @type {UsersSectionModel}
     * @memberof ConfigurationModel
     */
    users: UsersSectionModel;
    /**
     * 
     * @type {WebSectionModel}
     * @memberof ConfigurationModel
     */
    web: WebSectionModel;
}

/**
 * Check if a given object implements the ConfigurationModel interface.
 */
export function instanceOfConfigurationModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entities" in value;
    isInstance = isInstance && "infrastructure" in value;
    isInstance = isInstance && "laboratory" in value;
    isInstance = isInstance && "mobile" in value;
    isInstance = isInstance && "users" in value;
    isInstance = isInstance && "web" in value;

    return isInstance;
}

export function ConfigurationModelFromJSON(json: any): ConfigurationModel {
    return ConfigurationModelFromJSONTyped(json, false);
}

export function ConfigurationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entities': EntitiesSectionModelFromJSON(json['Entities']),
        'infrastructure': InfrastructureSectionModelFromJSON(json['Infrastructure']),
        'laboratory': LaboratorySectionModelFromJSON(json['Laboratory']),
        'mobile': MobileSectionModelFromJSON(json['Mobile']),
        'users': UsersSectionModelFromJSON(json['Users']),
        'web': WebSectionModelFromJSON(json['Web']),
    };
}

export function ConfigurationModelToJSON(value?: ConfigurationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Entities': EntitiesSectionModelToJSON(value.entities),
        'Infrastructure': InfrastructureSectionModelToJSON(value.infrastructure),
        'Laboratory': LaboratorySectionModelToJSON(value.laboratory),
        'Mobile': MobileSectionModelToJSON(value.mobile),
        'Users': UsersSectionModelToJSON(value.users),
        'Web': WebSectionModelToJSON(value.web),
    };
}

