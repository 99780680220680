/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportResponseModel } from './ReportResponseModel';
import {
    ReportResponseModelFromJSON,
    ReportResponseModelFromJSONTyped,
    ReportResponseModelToJSON,
} from './ReportResponseModel';

/**
 * 
 * @export
 * @interface ReportResponseModelListModel
 */
export interface ReportResponseModelListModel {
    /**
     * 
     * @type {number}
     * @memberof ReportResponseModelListModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof ReportResponseModelListModel
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof ReportResponseModelListModel
     */
    queryCount: number;
    /**
     * 
     * @type {Array<ReportResponseModel>}
     * @memberof ReportResponseModelListModel
     */
    items: Array<ReportResponseModel>;
    /**
     * 
     * @type {number}
     * @memberof ReportResponseModelListModel
     */
    readonly pages?: number;
}

/**
 * Check if a given object implements the ReportResponseModelListModel interface.
 */
export function instanceOfReportResponseModelListModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "queryCount" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function ReportResponseModelListModelFromJSON(json: any): ReportResponseModelListModel {
    return ReportResponseModelListModelFromJSONTyped(json, false);
}

export function ReportResponseModelListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportResponseModelListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': json['Limit'],
        'page': json['Page'],
        'queryCount': json['QueryCount'],
        'items': ((json['Items'] as Array<any>).map(ReportResponseModelFromJSON)),
        'pages': !exists(json, 'Pages') ? undefined : json['Pages'],
    };
}

export function ReportResponseModelListModelToJSON(value?: ReportResponseModelListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Limit': value.limit,
        'Page': value.page,
        'QueryCount': value.queryCount,
        'Items': ((value.items as Array<any>).map(ReportResponseModelToJSON)),
    };
}

