/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VisibilityType } from './VisibilityType';
import {
    VisibilityTypeFromJSON,
    VisibilityTypeFromJSONTyped,
    VisibilityTypeToJSON,
} from './VisibilityType';

/**
 * 
 * @export
 * @interface PropertyContextModel
 */
export interface PropertyContextModel {
    /**
     * 
     * @type {VisibilityType}
     * @memberof PropertyContextModel
     */
    visibility?: VisibilityType;
    /**
     * 
     * @type {string}
     * @memberof PropertyContextModel
     */
    validatorTypeName?: string | null;
}

/**
 * Check if a given object implements the PropertyContextModel interface.
 */
export function instanceOfPropertyContextModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PropertyContextModelFromJSON(json: any): PropertyContextModel {
    return PropertyContextModelFromJSONTyped(json, false);
}

export function PropertyContextModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyContextModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visibility': !exists(json, 'Visibility') ? undefined : VisibilityTypeFromJSON(json['Visibility']),
        'validatorTypeName': !exists(json, 'ValidatorTypeName') ? undefined : json['ValidatorTypeName'],
    };
}

export function PropertyContextModelToJSON(value?: PropertyContextModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Visibility': VisibilityTypeToJSON(value.visibility),
        'ValidatorTypeName': value.validatorTypeName,
    };
}

