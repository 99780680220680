import { getMeasurementTabs } from "layout/Main/utils/getMeasurementTabs";
import { TabsForRouter } from "libs/ui/TabsForRouter";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { Outlet, useMatch, useNavigate, useParams, useResolvedPath } from "react-router-dom";
import { GraphMeasurementsKeys, useGraphDataAPIContext } from "./GraphDataAPIContext";
import { RouteParams } from "router/model";
import { NOT_FOUND_ROUTE, mainRoutePaths } from "router";
import { useGlobalConfigContext } from "context/GlobalConfigContext";

export function GraphTabsWithDataDownload() {
  const { organizationId = "", patientId = "" } = useParams<RouteParams["patientDetails"]>();
  const { getGraphData } = useGraphDataAPIContext();
  const resolved = useResolvedPath(mainRoutePaths.patientGraphs);
  const match = useMatch({ path: `${resolved.pathname}/:graph`, end: true });
  const firstRender = useRef(false);
  const { appConfig } = useGlobalConfigContext();
  const navigate = useNavigate();

  const graphsDisabled = appConfig?.laboratory.measurements.viewOptions.enableGraphView === false;

  useEffect(() => {
    if (graphsDisabled) {
      navigate(NOT_FOUND_ROUTE, { replace: true });
    }
  }, [graphsDisabled, navigate]);

  const onClick = useCallback(
    (routePath: string) => {
      if (graphsDisabled) {
        return;
      }

      const fetchData = (targetedGraphs: GraphMeasurementsKeys[] = []) => {
        getGraphData(organizationId, patientId, targetedGraphs);
      };

      switch (routePath as ReturnType<typeof getMeasurementTabs>[number]["routePath"]) {
        case "serumCreatinine":
          fetchData(["SerumCreatinine"]);
          break;

        case "egfr":
          fetchData(["Egfr"]);
          break;

        case "bloodPressure":
          fetchData(["BloodPressure"]);
          break;

        case "glucose":
          fetchData(["Glucose"]);
          break;

        case "urineCreatinine":
          fetchData(["UrineCreatinine"]);
          break;

        case "urineAlbumin":
          fetchData(["UrineAlbumin"]);
          break;

        case "uacr":
          fetchData(["UACR"]);
          break;

        case "bmi":
          fetchData(["BMI"]);
          break;

        case "height":
          fetchData(["Height"]);
          break;

        case "weight":
          fetchData(["Weight"]);
          break;

        case "semiQuantitativeUacr":
          fetchData(["SemiQuantitativeUACR"]);
          break;

        /**
         * !! We ignore /all
         * !! That component has its fetch logic inside, since its complex
         */
        case "all":
          // fetchData(Object.keys(data) as GraphMeasurementsKeys[]);
          break;

        default:
          throw new Error(`${routePath} case not implemented`);
      }
    },
    [getGraphData, graphsDisabled, organizationId, patientId],
  );

  useEffect(() => {
    if (match?.params.graph && firstRender.current === false) {
      onClick(match?.params.graph);
      firstRender.current = true;
    }
  }, [match?.params.graph, onClick]);

  const tabs = useMemo(() => {
    const newTabProps = getMeasurementTabs("PatientDetails.Graph").map(tabProp => ({
      ...tabProp,
      onClick,
    }));

    return newTabProps;
  }, [onClick]);

  return (
    <TabsForRouter selectBox className="PatientGraphTabs" subRoute tabs={tabs}>
      <Outlet />
    </TabsForRouter>
  );
}
