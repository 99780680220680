import { useTranslation } from "react-i18next";
import { MeasurementItem, MeasurementItemType } from "../model";
import { useEGFRColor } from "utils/hooks/useEGFRColor";
import { ColorValue } from "components/MeasurementSideDetails/SideDetails/elements/ColorValue";
import { BmiStatusType, EgfrStatusType, UacrStatusType } from "models/TestModels";
import { useBMIColor } from "utils/hooks/useBMIColor";
import { useUACRColor } from "utils/hooks/useUACRColor";

interface MeasurementStatusProps {
  measurements: MeasurementItem | undefined;
  itemType: MeasurementItemType;
}

function EgfrChips({ status }: Readonly<{ status?: EgfrStatusType }>) {
  const { t } = useTranslation("widgets", {
    keyPrefix: "MeasurementsWidget.Status.eGFR",
  });
  const [getEGFRColor] = useEGFRColor();

  return <ColorValue colorType={getEGFRColor(status)}>{status ? t(status) : null}</ColorValue>;
}
function BMIChips({ status }: Readonly<{ status?: BmiStatusType }>) {
  const { t } = useTranslation("widgets", {
    keyPrefix: "MeasurementsWidget.Status.BMI",
  });
  const [getBMIColor] = useBMIColor();

  return <ColorValue colorType={getBMIColor(status)}>{status ? t(status) : null}</ColorValue>;
}

function UACRChips({ status }: Readonly<{ status?: UacrStatusType }>) {
  const { t } = useTranslation("widgets", {
    keyPrefix: "MeasurementsWidget.Status.UACR",
  });
  const [getUACRColor] = useUACRColor();

  return <ColorValue colorType={getUACRColor(status)}>{status ? t(status) : null}</ColorValue>;
}

function Status({ itemType, status }: Readonly<{ itemType: MeasurementItemType; status: any }>) {
  switch (itemType) {
    case "Egfr":
      return <EgfrChips status={status} />;

    case "Bmi":
      return <BMIChips status={status} />;
    case "Uacr":
      return <UACRChips status={status} />;

    case "BloodPressure":
    case "Diastolic":
    case "Systolic":
    case "Glucose":
    case "Height":
    case "SerumCreatinine":
    case "Weight":
    case "UrineCreatinine":
    case "UrineAlbumin":
    case "SemiQuantitativeUacr":
      return null;

    default:
      throw new Error(`itemType ${itemType} not covered`);
  }
}

export function MeasurementStatus({ itemType, measurements }: Readonly<MeasurementStatusProps>) {
  const data = measurements?.[itemType];
  if (!data?.Status) {
    return null;
  }

  return (
    <div className="MeasurementStatus">{<Status itemType={itemType} status={data.Status} />}</div>
  );
}
