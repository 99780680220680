import { fetchAuthSession, signOut as amplifySignOut } from "@aws-amplify/auth";
import { ActionModal, Button } from "libs/ui";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./SignOutModal.scss";
import { useNavigate } from "react-router";
import { resetTranslationLoad } from "i18n.startup";

interface SignOutModalProps {
  show: boolean;
  onCancel: () => void;
}

export function SignOutModal({ show, onCancel }: SignOutModalProps) {
  const { t } = useTranslation("modals", { keyPrefix: "SignOutModal" });
  const navigate = useNavigate();

  const signOut = useCallback(async () => {
    try {
      /**
       * ! Unfortunately you cannot sign out if your tokens are revoked
       * We need to refresh the user in amplify lib
       */
      await fetchAuthSession({
        forceRefresh: true,
      });

      await amplifySignOut();
      navigate("/");
      resetTranslationLoad();
    } catch (err) {
      console.warn(err);
    }
  }, [navigate]);

  return (
    <ActionModal
      show={show}
      icon={{
        iconType: "SignOut",
      }}
      title={t("title")}
      cancelButton={
        <Button onClick={onCancel} buttonSize={"medium"} buttonType={"transparent"}>
          {t("buttonCancel")}
        </Button>
      }
      submitButton={
        <Button onClick={signOut} buttonSize={"medium"} buttonType={"red"}>
          {t("buttonSubmit")}
        </Button>
      }
    >
      {t("message")}
    </ActionModal>
  );
}
