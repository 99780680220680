import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import { DocumentWrapper } from "../DocumentWrapper";
import "../Policy.scss";

export function CookiePolicyEn() {
  const [dateFormatter] = useRegionDateTimeFormatter();

  return (
    <DocumentWrapper title="Cookie Policy" date={dateFormatter("Nov 15, 2022")}>
      <div className="Policy">
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Statement of Using Cookies</h2>
          <p>
            The Carna site, like most sites, uses small text files called cookies. This section
            provides information about what cookies, which cookies use Carna, and how they can be
            controlled.
          </p>
          <p>
            If the settings on the software you use to view this page (your browser) are adapted to
            accept the cookies we use, and continue to use our site, we will assume your consent.
          </p>
          <p>
            If you do not want to receive cookies, you can set your browser to restrict or block
            them. All modern browsers allow you to change cookie settings and are usually located in
            the ‘options’ menu.
          </p>
          <p>
            However, disabling cookies will probably mean that the Carna site will not work as
            expected.
          </p>
        </div>
        <div className="Policy__section">
          <h3>1. What are cookies?</h3>
          <p>
            Cookies are text files with small amounts of information your device downloads when you
            visit the site. The cookies are then returned to the original page at each subsequent
            visit or to another site that recognizes the cookie. Cookies are often used because they
            allow the site to recognize the user’s device and thus improve functionality.
          </p>
        </div>
        <div className="Policy__section">
          <h3>2. How does Carna use cookies?</h3>
          <h4>Our cookies</h4>
          <p>To make our site work best, we use cookies that include:</p>
          <ul>
            <li>Remembering the parameters of your search</li>
            <li>Allowing you to add comments on our site</li>
            <li>There is no way that these cookies are prevented, unless you use the site.</li>
          </ul>
        </div>
        <div className="Policy__section">
          <h3>3. Social network cookies</h3>
          <p>To easily share our content on social networks, our site has sharing buttons.</p>
          <p>
            The degree of data privacy protection will vary from social network to social
            networking, and will depend on the privacy settings you have chosen on these networks.
          </p>
          <p className="Policy__text--bold">
            Cookies for Anonymous Gathering Visitors Statistics – Google Analytics
          </p>
          <p>
            We use cookies to collect visitor statistics using Google Analytics, such as: the IP
            address of the psychiatrist, how many people visit our site and what type of technology
            they use (such as the operating system, which helps identify problems on certain
            platforms), how long they are using site, which pages of the site are retained, etc.
            This helps us to constantly improve the site. These programs also tell us anonymously
            how people came to our site (via Google, social networks, directly, etc.) and whether
            they were here before. For more information about Google Analytics Terms of Use, please
            read here.
          </p>
          <p className="Policy__text--bold">YouTube</p>
          <p>
            Sometimes we are installing videos from YouTube. Using cookies on YouTube is governed by
            Google’s Privacy Policy.
          </p>
        </div>

        <div className="Policy__section">
          <h3>4. Definitions</h3>
          <p className="Policy__text--bold">First Party Cookies and Third Party Cookies</p>
          <p>
            This refers to which domain dominates cookies. The first page cookies are the ones that
            set a site that the user visits at that point – the site that is shown in the URL field.
            Third Party Cookies sets a domain that does not belong to the site that the user visits
            at that time. If a user visits a site and the other entity places a cookie through that
            site, it’s a third-party cookie.
          </p>
          <p className="Policy__text--bold">Permanent cookies</p>
          <p>
            These cookies remain on the user’s device for the time period specified in the cookie.
            They are activated each time a user visits a page that created that particular cookie.
          </p>
          <p className="Policy__text--bold">Cookies of the session</p>
          <p>
            These cookies allow you to associate user actions during a browser session. The browser
            session starts when the user opens the browser window and ends when the user closes the
            browser window. Session cookies are created temporarily. When you close the browser, all
            session cookies are deleted.
          </p>
          <p className="Policy__text--bold">Turn off cookies</p>
          <p>
            You can usually turn off cookies by adjusting browser settings to prevent cookies from
            being accepted. However, you will probably limit the functionality of our and a large
            number of other sites, because cookies are a standard part of most modern sites.
          </p>
        </div>
      </div>
    </DocumentWrapper>
  );
}
