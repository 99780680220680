/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MeasurementsModel } from './MeasurementsModel';
import {
    MeasurementsModelFromJSON,
    MeasurementsModelFromJSONTyped,
    MeasurementsModelToJSON,
} from './MeasurementsModel';

/**
 * 
 * @export
 * @interface OldLaboratorySectionModel
 */
export interface OldLaboratorySectionModel {
    /**
     * 
     * @type {MeasurementsModel}
     * @memberof OldLaboratorySectionModel
     */
    measurements: MeasurementsModel;
    /**
     * 
     * @type {number}
     * @memberof OldLaboratorySectionModel
     */
    rowVersion: number;
}

/**
 * Check if a given object implements the OldLaboratorySectionModel interface.
 */
export function instanceOfOldLaboratorySectionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "measurements" in value;
    isInstance = isInstance && "rowVersion" in value;

    return isInstance;
}

export function OldLaboratorySectionModelFromJSON(json: any): OldLaboratorySectionModel {
    return OldLaboratorySectionModelFromJSONTyped(json, false);
}

export function OldLaboratorySectionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OldLaboratorySectionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measurements': MeasurementsModelFromJSON(json['Measurements']),
        'rowVersion': json['RowVersion'],
    };
}

export function OldLaboratorySectionModelToJSON(value?: OldLaboratorySectionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Measurements': MeasurementsModelToJSON(value.measurements),
        'RowVersion': value.rowVersion,
    };
}

