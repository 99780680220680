/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TablesPatientModel } from './TablesPatientModel';
import {
    TablesPatientModelFromJSON,
    TablesPatientModelFromJSONTyped,
    TablesPatientModelToJSON,
} from './TablesPatientModel';

/**
 * 
 * @export
 * @interface TablesModel
 */
export interface TablesModel {
    /**
     * 
     * @type {TablesPatientModel}
     * @memberof TablesModel
     */
    patient: TablesPatientModel;
}

/**
 * Check if a given object implements the TablesModel interface.
 */
export function instanceOfTablesModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient" in value;

    return isInstance;
}

export function TablesModelFromJSON(json: any): TablesModel {
    return TablesModelFromJSONTyped(json, false);
}

export function TablesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TablesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'patient': TablesPatientModelFromJSON(json['Patient']),
    };
}

export function TablesModelToJSON(value?: TablesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Patient': TablesPatientModelToJSON(value.patient),
    };
}

