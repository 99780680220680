import classNames from "classnames";
import { Icon, IconType } from "libs/ui";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import "./AccountAction.scss";

type ActionType = "deactivate" | "request" | "delete";

interface AccountActionsProps {
  icon: IconType;
  type: ActionType;
  onClick?: () => void;
  disabled?: boolean;
}

export function AccountAction({
  icon,
  type,
  onClick,
  disabled,
  children,
}: PropsWithChildren<AccountActionsProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "SecurityAndPrivacy.AccountData" });

  const actionButtonType = `AccountAction__button--${type}`;

  return (
    <div className="AccountAction">
      <h3 className="AccountAction__title">{t(`${type}.title`)}</h3>
      <button
        onClick={onClick}
        disabled={disabled}
        className={classNames("AccountAction__button", {
          [actionButtonType]: type,
          "AccountAction__button--disabled": disabled,
        })}
      >
        <Icon icon={icon} scaleTo={21.5} />
        {t(`${type}.button_text`)}
      </button>
      {children}
    </div>
  );
}
