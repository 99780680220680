import { UserGraphModel } from "api/query";
import CarnaLineChart from "components/Chart/CarnaChart/LineChart";
import { useGetMaxHeightValueFromRuleEngineUnit } from "components/Chart/hooks/useGetMaxHeightValueFromRuleEngineUnit";
import { ECOption, Formatters } from "components/Chart/model";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useCallback } from "react";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { getOptions as getInitialOptions } from "./model";
// const CarnaLineChart = React.lazy(() => import("components/Chart/CarnaChart/LineChart"));

export function Height() {
  const { value: maxHeightRange } = useGetMaxHeightValueFromRuleEngineUnit();
  const { heightUnit } = useGetUserPreferredMeasurementUnits();
  const { appConfig } = useGlobalConfigContext();

  const getOptions = useCallback(
    (formatters: Formatters, data: UserGraphModel): ECOption =>
      getInitialOptions({
        formatters,
        data,
        measurementUnit: heightUnit,
        maxRange: maxHeightRange,
        showTrending: appConfig?.components.graphs.Patient.Index.showTrending,
      }),
    [appConfig?.components.graphs.Patient.Index.showTrending, heightUnit, maxHeightRange],
  );

  return (
    <CarnaLineChart
      showTrend={appConfig?.components.graphs.Patient.Index.showTrending}
      dataType="Height"
      unitType="height"
      className="Height"
      getOptions={getOptions}
      measurementUnit={heightUnit}
    />
  );
}

export default Height;
