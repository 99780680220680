import { Button } from "libs/ui/Button";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { TimeStampsProps } from "../model";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import "./TimeStamps.scss";
import { SingleTimeStamp } from "./SingleTimeStamp";

export function TimeStamps({
  requestedBy,
  requestedAtDate,
  fileGeneratedAtDate,
  downloads,
}: TimeStampsProps) {
  const { t } = useTranslation("translation", { keyPrefix: "ReportPage.SingleReport.TimeStamps" });
  const [isCollapsed, onButtonToggle] = useReducer(state => !state, false);

  const [dateFormatter, timeFormatter] = useRegionDateTimeFormatter();

  return (
    <div className="TimeStamps">
      <Button
        onClick={onButtonToggle}
        buttonType={"transparent"}
        buttonSize={"medium"}
        buttonIcon={{ icon: isCollapsed ? "ChevronUp" : "ChevronDown", iconPosition: "trailing" }}
        className="TimeStamps__toggler"
      >
        {t("buttonTogglerText")}
      </Button>

      {isCollapsed ? (
        <div className="TimeStampData">
          <SingleTimeStamp key={"requested"} user={requestedBy}>
            {t("requested", {
              date: dateFormatter(requestedAtDate),
              time: timeFormatter(requestedAtDate),
            })}
          </SingleTimeStamp>

          {fileGeneratedAtDate ? (
            <SingleTimeStamp key={"created"}>
              {t("created", {
                date: dateFormatter(fileGeneratedAtDate),
                time: timeFormatter(fileGeneratedAtDate),
              })}
            </SingleTimeStamp>
          ) : null}

          {(downloads ?? []).length > 0 ? (
            <>
              {downloads?.map((single, index) => (
                <SingleTimeStamp key={single.timestamp.toString()} user={single.user}>
                  {t("download", {
                    index: index + 1,
                    date: dateFormatter(single.timestamp),
                    time: timeFormatter(single.timestamp),
                  })}
                </SingleTimeStamp>
              ))}
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
