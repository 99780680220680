import { Button, Dropdown } from "libs/ui";
import { PropsWithChildren, useCallback, useRef, useState } from "react";
import { autoPlacement, offset } from "@floating-ui/react-dom";
import { useOnClickOutside } from "libs/ui/hooks";
import "./Actions.scss";

interface ActionsProps {
  inactive?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export function Actions({ children, onClick, disabled }: PropsWithChildren<ActionsProps>) {
  const [showDropdown, setShowDropdown] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setShowDropdown(prevValue => !prevValue);

  useOnClickOutside(divRef, showDropdown, () => toggleDropdown());

  const onActionClick = useCallback(
    (e?: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      if (onClick) {
        onClick();
      }
      toggleDropdown();
    },
    [onClick],
  );
  return (
    <div className="Actions" ref={divRef}>
      <Button
        active={showDropdown}
        buttonType="transparent"
        buttonSize="small"
        buttonIcon={{ icon: "MoreVertical" }}
        onClick={onActionClick}
        disabled={disabled}
      />
      <Dropdown
        className="Dropdown--actions"
        show={showDropdown}
        targetElement={() => divRef.current}
        floatingProps={{
          middleware: [
            autoPlacement({
              allowedPlacements: ["bottom-end", "top-end"],
              alignment: "end",
              autoAlignment: true,
            }),
            offset(0),
          ],
        }}
      >
        {children}
      </Dropdown>
    </div>
  );
}
