/* tslint:disable */
 
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UserStatusType = {
    Invited: 'Invited',
    Active: 'Active',
    Deactivated: 'Deactivated',
    Deleted: 'Deleted',
    NoAccess: 'NoAccess'
} as const;
export type UserStatusType = typeof UserStatusType[keyof typeof UserStatusType];


export function UserStatusTypeFromJSON(json: any): UserStatusType {
    return UserStatusTypeFromJSONTyped(json, false);
}

export function UserStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStatusType {
    return json as UserStatusType;
}

export function UserStatusTypeToJSON(value?: UserStatusType | null): any {
    return value as any;
}

