import * as React from "react";
import { SVGProps } from "react";
const SvgRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.02 4.777a8.25 8.25 0 0 1 9.836 1.409l3.341 3.063.002.001H16a.75.75 0 0 0 0 1.5h6c.215 0 .408-.09.545-.234l.001-.002A.748.748 0 0 0 22.75 10V4a.75.75 0 0 0-1.5 0v4.264l-3.358-3.162a9.75 9.75 0 1 0 2.305 10.148.75.75 0 1 0-1.414-.5A8.25 8.25 0 1 1 7.02 4.777Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgRefresh;
