import { TestFormModel } from "components/Forms/Test/model";
import { MeasurementItemUIModel } from "models/TestModels";
import { useCallback } from "react";
import { useGetUserPreferredMeasurementUnits } from "../useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { getCorrectedValueForCreatinine } from "utils/converters/unitsConverter";
import { useUrineAlbuminValueConvertor } from "../useUrineAlbuminValueConvertor";

function useParseValue() {
  const { serumCreatinineUnit } = useGetUserPreferredMeasurementUnits();
  const [, convertUrineAlbuminForBE] = useUrineAlbuminValueConvertor();

  const parseValue = useCallback(
    (item: MeasurementItemUIModel) => {
      switch (item.type) {
        case "SerumCreatinine":
          return getCorrectedValueForCreatinine(item.value, serumCreatinineUnit, "mg/dL");

        case "UrineAlbumin":
          return convertUrineAlbuminForBE(item.value);

        default:
          return item.value;
      }
    },
    [convertUrineAlbuminForBE, serumCreatinineUnit],
  );

  return [parseValue] as const;
}

export function useMapTestFormModelForBE() {
  const [parseValue] = useParseValue();

  const mapTestModelForBE = useCallback(
    (data: TestFormModel) => {
      return {
        ...data,
        measurements: data.measurements.map(el => ({
          ...el,
          items: el.items.map(item => ({
            ...item,
            value: parseValue(item).toString(),
          })),
        })),
      };
    },
    [parseValue],
  );

  return [mapTestModelForBE] as const;
}
