import * as echarts from "echarts/core";
import { isDefaultModel } from "models/loadable";
import {
  DetailedHTMLProps,
  HTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
  useRef,
} from "react";
import { GetOptions, LineChartDataType } from "../model";

import { MeasurementGraphModel } from "api/query";
import { ECharts } from "echarts/core";
import { useGraphDataAPIContext } from "pages/PatientsPage/Details/Graph/GraphDataAPIContext";
import { useGraphLineDetails } from "../hooks/useGraphLineDetails";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { CarnaChart } from "./BasicChart";

interface LineChartProps {
  className: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>["className"];
  getOptions: GetOptions;
  unitType: Exclude<keyof MeasurementGraphModel["units"], "bloodPressure">;
  afterInit?: (chartRef: MutableRefObject<ECharts | undefined | null>) => void;
  onZoomRange?: (range: [number, number]) => void;
  chartHeaderSlot?: React.ReactElement;
  dataType: LineChartDataType;
  showTrend?: boolean;
  measurementUnit?: MEASUREMENT_UNITS;
}

export function LineChart({
  getOptions,
  unitType,
  afterInit,
  onZoomRange,
  chartHeaderSlot,
  dataType,
  showTrend,
  measurementUnit,
  ...props
}: PropsWithChildren<LineChartProps>) {
  const chartRef = useRef<echarts.ECharts>(null);
  const { data } = useGraphDataAPIContext();

  const { selectedNode, closeDetails, onNodeClick, testData } = useGraphLineDetails(
    data[dataType].value?.measurements,
    unitType,
    chartRef,
  );

  return (
    <CarnaChart
      showTrend={showTrend}
      data={data[dataType]}
      dataType={dataType}
      getOptions={getOptions}
      afterInit={afterInit}
      onZoomRange={onZoomRange}
      unitType={unitType}
      chartHeaderSlot={chartHeaderSlot}
      selectedNode={selectedNode}
      closeDetails={closeDetails}
      testData={testData}
      chartRef={chartRef}
      showSideDetails={!isDefaultModel(testData)}
      onNodeClick={onNodeClick}
      className={props.className}
      measurementUnit={measurementUnit}
    />
  );
}

export default LineChart;
