import React from "react";
import "./FieldLoader.scss";

interface FieldLoaderProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export function FieldLoader({ ...props }: FieldLoaderProps) {
  return (
    <div {...props} className="FieldLoader">
      <div className="FieldLoader--partial" />
      <div className="FieldLoader--full" />
    </div>
  );
}
