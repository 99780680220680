/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceModel } from './DeviceModel';
import {
    DeviceModelFromJSON,
    DeviceModelFromJSONTyped,
    DeviceModelToJSON,
} from './DeviceModel';
import type { IValidationError } from './IValidationError';
import {
    IValidationErrorFromJSON,
    IValidationErrorFromJSONTyped,
    IValidationErrorToJSON,
} from './IValidationError';
import type { MeasurementResponseModel } from './MeasurementResponseModel';
import {
    MeasurementResponseModelFromJSON,
    MeasurementResponseModelFromJSONTyped,
    MeasurementResponseModelToJSON,
} from './MeasurementResponseModel';
import type { PatientUserModel } from './PatientUserModel';
import {
    PatientUserModelFromJSON,
    PatientUserModelFromJSONTyped,
    PatientUserModelToJSON,
} from './PatientUserModel';
import type { UserModel } from './UserModel';
import {
    UserModelFromJSON,
    UserModelFromJSONTyped,
    UserModelToJSON,
} from './UserModel';

/**
 * 
 * @export
 * @interface TestResponseModel
 */
export interface TestResponseModel {
    /**
     * 
     * @type {PatientUserModel}
     * @memberof TestResponseModel
     */
    patient: PatientUserModel;
    /**
     * 
     * @type {UserModel}
     * @memberof TestResponseModel
     */
    testedBy?: UserModel;
    /**
     * 
     * @type {DeviceModel}
     * @memberof TestResponseModel
     */
    device?: DeviceModel;
    /**
     * 
     * @type {Array<MeasurementResponseModel>}
     * @memberof TestResponseModel
     */
    measurements: Array<MeasurementResponseModel>;
    /**
     * 
     * @type {string}
     * @memberof TestResponseModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TestResponseModel
     */
    testCountId: string;
    /**
     * 
     * @type {string}
     * @memberof TestResponseModel
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof TestResponseModel
     */
    measurementTime: string;
    /**
     * 
     * @type {string}
     * @memberof TestResponseModel
     */
    offset: string;
    /**
     * 
     * @type {string}
     * @memberof TestResponseModel
     */
    measurementUtc: string;
    /**
     * 
     * @type {string}
     * @memberof TestResponseModel
     */
    rowVersion: string;
    /**
     * 
     * @type {number}
     * @memberof TestResponseModel
     */
    sampledByDevice?: number | null;
    /**
     * 
     * @type {Array<IValidationError>}
     * @memberof TestResponseModel
     */
    validations?: Array<IValidationError> | null;
}

/**
 * Check if a given object implements the TestResponseModel interface.
 */
export function instanceOfTestResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient" in value;
    isInstance = isInstance && "measurements" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "testCountId" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "measurementTime" in value;
    isInstance = isInstance && "offset" in value;
    isInstance = isInstance && "measurementUtc" in value;
    isInstance = isInstance && "rowVersion" in value;

    return isInstance;
}

export function TestResponseModelFromJSON(json: any): TestResponseModel {
    return TestResponseModelFromJSONTyped(json, false);
}

export function TestResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'patient': PatientUserModelFromJSON(json['Patient']),
        'testedBy': !exists(json, 'TestedBy') ? undefined : UserModelFromJSON(json['TestedBy']),
        'device': !exists(json, 'Device') ? undefined : DeviceModelFromJSON(json['Device']),
        'measurements': ((json['Measurements'] as Array<any>).map(MeasurementResponseModelFromJSON)),
        'id': json['Id'],
        'testCountId': json['TestCountId'],
        'organizationId': json['OrganizationId'],
        'measurementTime': json['MeasurementTime'],
        'offset': json['Offset'],
        'measurementUtc': json['MeasurementUtc'],
        'rowVersion': json['RowVersion'],
        'sampledByDevice': !exists(json, 'SampledByDevice') ? undefined : json['SampledByDevice'],
        'validations': !exists(json, 'Validations') ? undefined : (json['Validations'] === null ? null : (json['Validations'] as Array<any>).map(IValidationErrorFromJSON)),
    };
}

export function TestResponseModelToJSON(value?: TestResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Patient': PatientUserModelToJSON(value.patient),
        'TestedBy': UserModelToJSON(value.testedBy),
        'Device': DeviceModelToJSON(value.device),
        'Measurements': ((value.measurements as Array<any>).map(MeasurementResponseModelToJSON)),
        'Id': value.id,
        'TestCountId': value.testCountId,
        'OrganizationId': value.organizationId,
        'MeasurementTime': value.measurementTime,
        'Offset': value.offset,
        'MeasurementUtc': value.measurementUtc,
        'RowVersion': value.rowVersion,
        'SampledByDevice': value.sampledByDevice,
        'Validations': value.validations === undefined ? undefined : (value.validations === null ? null : (value.validations as Array<any>).map(IValidationErrorToJSON)),
    };
}

