import { RegionType, UserGraphModel } from "api/query";
import { ECOption, Formatters } from "components/Chart/model";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import i18n from "i18next";
import { getAreaColoredOptions } from "../model";
import { URINE_ALBUMIN_MAX_GRAPH_VALUE } from "config/const";
import { getUrineAlbuminValuePerUserPreferredUnit } from "utils/hooks/useUrineAlbuminValueConvertor";
import { UrineAlbuminStageRanges } from "utils/hooks/useGetUrineAlbuminRanges";
import { getColorValue } from "utils";

function getAreas(orderedUrineAlbuminStageRanges: UrineAlbuminStageRanges) {
  const areas = [
    {
      areaColor: getColorValue("--white-100"),
      start: 0,
      end: orderedUrineAlbuminStageRanges.Normal.to,
      label: i18n.t("Graph.UrineAlbumin.Normal", { ns: "translation" }),
      labelDetails: i18n.t("Graph.UrineAlbumin.Normal-details", { ns: "translation" }),
      labelColor: getColorValue("--grey-500"),
      legendColor: getColorValue("--grey-500"),
    },
    {
      areaColor: getColorValue("--yellow-100"),
      start: orderedUrineAlbuminStageRanges.Microalbuminuria.from,
      end: orderedUrineAlbuminStageRanges.Microalbuminuria.to,
      label: i18n.t("Graph.UrineAlbumin.Microalbuminuria", { ns: "translation" }),
      labelDetails: i18n.t("Graph.UrineAlbumin.Microalbuminuria-details", { ns: "translation" }),
      labelColor: getColorValue("--yellow-500"),
      legendColor: getColorValue("--yellow-500"),
    },
    {
      areaColor: getColorValue("--red-50"),
      start: orderedUrineAlbuminStageRanges.Macroalbuminuria.from,
      end: Number.MAX_SAFE_INTEGER,
      label: i18n.t("Graph.UrineAlbumin.Macroalbuminuria", { ns: "translation" }),
      labelDetails: i18n.t("Graph.UrineAlbumin.Macroalbuminuria-details", { ns: "translation" }),
      labelColor: getColorValue("--red-500"),
      legendColor: getColorValue("--red-500"),
    },
  ];

  return areas;
}

export const getOptions = (
  formatters: Formatters,
  graphUser: UserGraphModel,
  measurementUnit: MEASUREMENT_UNITS,
  orderedUrineAlbuminStageRanges: UrineAlbuminStageRanges,
  showTrending?: boolean,
  regionType?: RegionType,
): ECOption =>
  getAreaColoredOptions({
    formatters,
    data: graphUser,
    yAxis: {
      max: Number(
        getUrineAlbuminValuePerUserPreferredUnit(
          String(URINE_ALBUMIN_MAX_GRAPH_VALUE),
          measurementUnit,
        ),
      ),
    },
    areas: getAreas(orderedUrineAlbuminStageRanges),
    unitType: "urineAlbumin",
    measurementUnit,
    markLineRanges: [Number(getUrineAlbuminValuePerUserPreferredUnit(String(30), measurementUnit))],
    showTrending,
    regionType,
  });
