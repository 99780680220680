/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
     HeatMapPatientWidgetResponseModelFromJSONTyped,
     MeasurementPatientWidgetDataModelFromJSONTyped,
     PatientInfoWidgetDataModelFromJSONTyped
} from './index';

/**
 * 
 * @export
 * @interface BasePatientWidgetDataResponseModel
 */
export interface BasePatientWidgetDataResponseModel {
    /**
     * 
     * @type {string}
     * @memberof BasePatientWidgetDataResponseModel
     */
    $type: string;
}

/**
 * Check if a given object implements the BasePatientWidgetDataResponseModel interface.
 */
export function instanceOfBasePatientWidgetDataResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "$type" in value;

    return isInstance;
}

export function BasePatientWidgetDataResponseModelFromJSON(json: any): BasePatientWidgetDataResponseModel {
    return BasePatientWidgetDataResponseModelFromJSONTyped(json, false);
}

export function BasePatientWidgetDataResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasePatientWidgetDataResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['$type'] === 'HeatMapPatientWidgetResponseModel') {
            return HeatMapPatientWidgetResponseModelFromJSONTyped(json, true);
        }
        if (json['$type'] === 'MeasurementPatientWidgetDataModel') {
            return MeasurementPatientWidgetDataModelFromJSONTyped(json, true);
        }
        if (json['$type'] === 'PatientInfoWidgetDataModel') {
            return PatientInfoWidgetDataModelFromJSONTyped(json, true);
        }
    }
    return {
        
        '$type': json['$type'],
    };
}

export function BasePatientWidgetDataResponseModelToJSON(value?: BasePatientWidgetDataResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '$type': value.$type,
    };
}

