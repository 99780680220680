import { clone } from "lodash-es";
import { PropsWithChildren, useSyncExternalStore } from "react";
import { NotFoundPage } from "pages/ErrorBoundary/NotFoundPage";
import { Outlet } from "react-router";

interface FeatureToggleStates {
  "dashboard-map": boolean;
  "address-map": boolean;
}

type Listener = () => void;

class FeatureToggles {
  #key = "future-toggles";
  #listeners = new Set<Listener>();

  #state: FeatureToggleStates = {
    "dashboard-map": false,

    // !!! ENABLE AddressSection.vite.test.tsx in case if you remove this
    "address-map": false,
  };

  constructor() {
    this.loadState();
  }

  loadState = () => {
    try {
      const savedState = localStorage.getItem(this.#key);
      const loadedState = savedState !== null ? JSON.parse(savedState) : {};

      Object.entries(this.#state).forEach(([key, _value]) => {
        this.#state[key as keyof FeatureToggleStates] = loadedState[key] ?? false;
      });
      this.#state = clone(this.#state);
      this.notifySubscribers();
    } catch (error) {
      console.log(error);
    }
  };

  saveState = () => {
    this.#state = clone(this.#state);
    localStorage.setItem(this.#key, JSON.stringify(this.#state));
    this.notifySubscribers();
  };

  on = (key?: string) => {
    if (key) {
      this.#state[key as keyof FeatureToggleStates] = true;
      this.saveState();
      return;
    }

    // this.#state["dashboard-map"] = true;
    Object.entries(this.#state).forEach(([key, _value]) => {
      this.#state[key as keyof FeatureToggleStates] = true;
    });

    this.saveState();
  };

  off = (key?: string) => {
    if (key) {
      this.#state[key as keyof FeatureToggleStates] = false;
      this.saveState();
      return;
    }

    Object.entries(this.#state).forEach(([key, _value]) => {
      this.#state[key as keyof FeatureToggleStates] = false;
    });

    this.saveState();
  };

  getState = () => {
    return this.#state;
  };

  notifySubscribers = () => {
    this.#listeners.forEach(listener => {
      listener();
    });
  };

  subscribe = (listener: Listener) => {
    this.#listeners.add(listener);
    return () => this.#listeners.delete(listener);
  };
}

export const featureToggles = new FeatureToggles();

(window as any).featureToggles = featureToggles;

export function useFeatureToggles() {
  const togglesState = useSyncExternalStore(featureToggles.subscribe, featureToggles.getState);
  return togglesState;
}

export function FeatureToggleRoute({
  keys,
  children,
}: PropsWithChildren<{ keys: ReadonlyArray<keyof FeatureToggleStates> }>) {
  const togglesState = useFeatureToggles();

  for (const key of keys) {
    if (togglesState[key] === false) {
      return <NotFoundPage />;
    }
  }

  if (children) {
    return children;
  }

  return <Outlet />;
}
