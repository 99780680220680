import { useMachine } from "@xstate/react";
import classNames from "classnames";
import * as R from "ramda";
import React, { useCallback, useMemo, useRef } from "react";
import { renderValidElement } from "../utils";
import {
  BackendResponse,
  TableContext,
  TableProps,
  TableViewType,
  tableStateMachine,
} from "./model";
import { Paginator } from "../Paginator/Paginator";
import RowsPerPage, { RowsPerPageListType } from "../RowsPerPage";
import "./Table.scss";
import * as TableElements from "./TableElements";
import { TableView } from "./TableView";

export interface TableRefs {
  containerElem: HTMLDivElement | null;
}

export function Table<T extends BackendResponse, B extends Record<string, any>>({
  className,
  theadSlot,
  send: sendEvent,
  header,
  context,
  children,
  tableContainerAdditionalChildren,
  paginator = false,
  loading = false,
  linesPerPageSelector = false,
  viewTableSelector = false,
  title,
  innerRef,
  ...divProps
}: TableProps & {
  context: TableContext<T, B>;
  send: ReturnType<typeof useMachine<ReturnType<typeof tableStateMachine<T, B>>>>["1"];
  innerRef?: React.Ref<TableRefs>;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  const { currentPage, linesPerPage, tableView, totalPages, data } = context;

  const noFilterData = useMemo(
    () =>
      context.data.items?.length === 0 &&
      context.filters !== undefined &&
      R.pipe(R.omit(["limit"]), R.values, R.all(R.isEmpty))(context.data[0]),
    [context.data, context.filters],
  );

  React.useImperativeHandle(innerRef, () => ({
    containerElem: containerRef.current,
  }));

  const onViewToggle = useCallback(
    (value: TableViewType) => sendEvent({ type: "UPDATE_TABLE_VIEW", value }),
    [sendEvent],
  );

  return (
    <div
      className={classNames("UI-Components Table", { "Table--withTitle": title }, className)}
      {...divProps}
    >
      {React.isValidElement<HTMLElement>(header)
        ? React.cloneElement(header, {
            className: classNames("Table__header", header.props.className),
          })
        : null}
      {title && <h2 className="Table__title">{title}</h2>}
      <div
        ref={containerRef}
        className={classNames("Table__container", {
          "Table__container--empty-filter-data": noFilterData,
        })}
      >
        {tableView === "Grid" ? (
          <div className="TableGridView">{children}</div>
        ) : (
          <table>
            <thead>{renderValidElement(theadSlot)}</thead>
            <tbody>{children}</tbody>
          </table>
        )}

        {tableContainerAdditionalChildren}

        {!loading && noFilterData ? <TableElements.NoFilterResults /> : null}
      </div>

      <div className="Table__footer" key="footer1">
        {viewTableSelector ? (
          <TableView currentValue={tableView} onViewToggle={onViewToggle} />
        ) : null}

        {linesPerPageSelector ? (
          <RowsPerPage
            tableView={tableView}
            currentRowPerPage={String(linesPerPage) as RowsPerPageListType}
            setRowsPerPage={lPerPage => {
              sendEvent({
                type: "UPDATE_LINES_PER_PAGE",
                value: lPerPage as 10 | 20 | 50,
              });
            }}
          />
        ) : null}
        {paginator ? (
          <Paginator
            disabledNext={currentPage >= totalPages}
            key="paginator1"
            gotoPage={pageNum =>
              sendEvent({ type: pageNum > currentPage ? "NEXT_PAGE" : "PREVIOUS_PAGE" })
            }
            currentPage={currentPage}
            queryCount={data.queryCount}
            limit={data.limit}
          />
        ) : null}
      </div>
    </div>
  );
}
