import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { useMemo } from "react";
import { MeasureSystem, IMPERIAL_SYSTEM, METRIC_SYSTEM } from "./MeasureSystem.model";

export function useGetUserPreferredMeasurementUnits(): MeasureSystem {
  const { globalPreference } = useGlobalPreferenceContext();
  const { appConfig } = useGlobalConfigContext();

  return useMemo(() => {
    return {
      ...(globalPreference?.measureUnitType === "Imperial" ? IMPERIAL_SYSTEM : METRIC_SYSTEM),
      serumCreatinineUnit:
        appConfig?.laboratory.measurements.serumCreatinine.measurementUnit ??
        MEASUREMENT_UNITS.mgdL,
      // !!! TODO: Needs to be updated to be dynamic based on Rule Engine laboratory
      // !!! Currently, this is hardcoded, since we don't have a way to change the preferred albumin unit
      urineAlbuminUnit: MEASUREMENT_UNITS.mgdL,
    };
  }, [
    appConfig?.laboratory.measurements.serumCreatinine.measurementUnit,
    globalPreference?.measureUnitType,
  ]);
}
