/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminModel } from './AdminModel';
import {
    AdminModelFromJSON,
    AdminModelFromJSONTyped,
    AdminModelToJSON,
} from './AdminModel';
import type { BusinessRulesModel } from './BusinessRulesModel';
import {
    BusinessRulesModelFromJSON,
    BusinessRulesModelFromJSONTyped,
    BusinessRulesModelToJSON,
} from './BusinessRulesModel';
import type { CustomDefinitionsModel } from './CustomDefinitionsModel';
import {
    CustomDefinitionsModelFromJSON,
    CustomDefinitionsModelFromJSONTyped,
    CustomDefinitionsModelToJSON,
} from './CustomDefinitionsModel';
import type { DeviceModel } from './DeviceModel';
import {
    DeviceModelFromJSON,
    DeviceModelFromJSONTyped,
    DeviceModelToJSON,
} from './DeviceModel';
import type { HcpModel } from './HcpModel';
import {
    HcpModelFromJSON,
    HcpModelFromJSONTyped,
    HcpModelToJSON,
} from './HcpModel';
import type { OrganizationModel } from './OrganizationModel';
import {
    OrganizationModelFromJSON,
    OrganizationModelFromJSONTyped,
    OrganizationModelToJSON,
} from './OrganizationModel';
import type { PartnerModel } from './PartnerModel';
import {
    PartnerModelFromJSON,
    PartnerModelFromJSONTyped,
    PartnerModelToJSON,
} from './PartnerModel';
import type { PatientModel } from './PatientModel';
import {
    PatientModelFromJSON,
    PatientModelFromJSONTyped,
    PatientModelToJSON,
} from './PatientModel';

/**
 * 
 * @export
 * @interface OldEntitiesSectionModel
 */
export interface OldEntitiesSectionModel {
    /**
     * 
     * @type {OrganizationModel}
     * @memberof OldEntitiesSectionModel
     */
    organization: OrganizationModel;
    /**
     * 
     * @type {AdminModel}
     * @memberof OldEntitiesSectionModel
     */
    admin: AdminModel;
    /**
     * 
     * @type {PartnerModel}
     * @memberof OldEntitiesSectionModel
     */
    partner: PartnerModel;
    /**
     * 
     * @type {HcpModel}
     * @memberof OldEntitiesSectionModel
     */
    hcp: HcpModel;
    /**
     * 
     * @type {PatientModel}
     * @memberof OldEntitiesSectionModel
     */
    patient: PatientModel;
    /**
     * 
     * @type {DeviceModel}
     * @memberof OldEntitiesSectionModel
     */
    device: DeviceModel;
    /**
     * 
     * @type {CustomDefinitionsModel}
     * @memberof OldEntitiesSectionModel
     */
    custom: CustomDefinitionsModel;
    /**
     * 
     * @type {BusinessRulesModel}
     * @memberof OldEntitiesSectionModel
     */
    businessRules: BusinessRulesModel;
    /**
     * 
     * @type {number}
     * @memberof OldEntitiesSectionModel
     */
    rowVersion: number;
}

/**
 * Check if a given object implements the OldEntitiesSectionModel interface.
 */
export function instanceOfOldEntitiesSectionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "admin" in value;
    isInstance = isInstance && "partner" in value;
    isInstance = isInstance && "hcp" in value;
    isInstance = isInstance && "patient" in value;
    isInstance = isInstance && "device" in value;
    isInstance = isInstance && "custom" in value;
    isInstance = isInstance && "businessRules" in value;
    isInstance = isInstance && "rowVersion" in value;

    return isInstance;
}

export function OldEntitiesSectionModelFromJSON(json: any): OldEntitiesSectionModel {
    return OldEntitiesSectionModelFromJSONTyped(json, false);
}

export function OldEntitiesSectionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OldEntitiesSectionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization': OrganizationModelFromJSON(json['Organization']),
        'admin': AdminModelFromJSON(json['Admin']),
        'partner': PartnerModelFromJSON(json['Partner']),
        'hcp': HcpModelFromJSON(json['Hcp']),
        'patient': PatientModelFromJSON(json['Patient']),
        'device': DeviceModelFromJSON(json['Device']),
        'custom': CustomDefinitionsModelFromJSON(json['Custom']),
        'businessRules': BusinessRulesModelFromJSON(json['BusinessRules']),
        'rowVersion': json['RowVersion'],
    };
}

export function OldEntitiesSectionModelToJSON(value?: OldEntitiesSectionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Organization': OrganizationModelToJSON(value.organization),
        'Admin': AdminModelToJSON(value.admin),
        'Partner': PartnerModelToJSON(value.partner),
        'Hcp': HcpModelToJSON(value.hcp),
        'Patient': PatientModelToJSON(value.patient),
        'Device': DeviceModelToJSON(value.device),
        'Custom': CustomDefinitionsModelToJSON(value.custom),
        'BusinessRules': BusinessRulesModelToJSON(value.businessRules),
        'RowVersion': value.rowVersion,
    };
}

