/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { GreaterThan } from './GreaterThan';
import {
    instanceOfGreaterThan,
    GreaterThanFromJSON,
    GreaterThanFromJSONTyped,
    GreaterThanToJSON,
} from './GreaterThan';
import type { GreaterThanOrEqual } from './GreaterThanOrEqual';
import {
    instanceOfGreaterThanOrEqual,
    GreaterThanOrEqualFromJSON,
    GreaterThanOrEqualFromJSONTyped,
    GreaterThanOrEqualToJSON,
} from './GreaterThanOrEqual';
import type { LessThan } from './LessThan';
import {
    instanceOfLessThan,
    LessThanFromJSON,
    LessThanFromJSONTyped,
    LessThanToJSON,
} from './LessThan';
import type { LessThanOrEqual } from './LessThanOrEqual';
import {
    instanceOfLessThanOrEqual,
    LessThanOrEqualFromJSON,
    LessThanOrEqualFromJSONTyped,
    LessThanOrEqualToJSON,
} from './LessThanOrEqual';
import type { WithinRangeExclusive } from './WithinRangeExclusive';
import {
    instanceOfWithinRangeExclusive,
    WithinRangeExclusiveFromJSON,
    WithinRangeExclusiveFromJSONTyped,
    WithinRangeExclusiveToJSON,
} from './WithinRangeExclusive';
import type { WithinRangeInclusive } from './WithinRangeInclusive';
import {
    instanceOfWithinRangeInclusive,
    WithinRangeInclusiveFromJSON,
    WithinRangeInclusiveFromJSONTyped,
    WithinRangeInclusiveToJSON,
} from './WithinRangeInclusive';

/**
 * @type BmiStatusTypeStatusLimitsLimitsValue
 * 
 * @export
 */
export type BmiStatusTypeStatusLimitsLimitsValue = GreaterThan | GreaterThanOrEqual | LessThan | LessThanOrEqual | WithinRangeExclusive | WithinRangeInclusive;

export function BmiStatusTypeStatusLimitsLimitsValueFromJSON(json: any): BmiStatusTypeStatusLimitsLimitsValue {
    return BmiStatusTypeStatusLimitsLimitsValueFromJSONTyped(json, false);
}

export function BmiStatusTypeStatusLimitsLimitsValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): BmiStatusTypeStatusLimitsLimitsValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...GreaterThanFromJSONTyped(json, true), ...GreaterThanOrEqualFromJSONTyped(json, true), ...LessThanFromJSONTyped(json, true), ...LessThanOrEqualFromJSONTyped(json, true), ...WithinRangeExclusiveFromJSONTyped(json, true), ...WithinRangeInclusiveFromJSONTyped(json, true) };
}

export function BmiStatusTypeStatusLimitsLimitsValueToJSON(value?: BmiStatusTypeStatusLimitsLimitsValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfGreaterThan(value)) {
        return GreaterThanToJSON(value as GreaterThan);
    }
    if (instanceOfGreaterThanOrEqual(value)) {
        return GreaterThanOrEqualToJSON(value as GreaterThanOrEqual);
    }
    if (instanceOfLessThan(value)) {
        return LessThanToJSON(value as LessThan);
    }
    if (instanceOfLessThanOrEqual(value)) {
        return LessThanOrEqualToJSON(value as LessThanOrEqual);
    }
    if (instanceOfWithinRangeExclusive(value)) {
        return WithinRangeExclusiveToJSON(value as WithinRangeExclusive);
    }
    if (instanceOfWithinRangeInclusive(value)) {
        return WithinRangeInclusiveToJSON(value as WithinRangeInclusive);
    }

    return {};
}

