/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Geolocation
 */
export interface Geolocation {
    /**
     * 
     * @type {number}
     * @memberof Geolocation
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Geolocation
     */
    longitude?: number;
}

/**
 * Check if a given object implements the Geolocation interface.
 */
export function instanceOfGeolocation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GeolocationFromJSON(json: any): Geolocation {
    return GeolocationFromJSONTyped(json, false);
}

export function GeolocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Geolocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'latitude': !exists(json, 'Latitude') ? undefined : json['Latitude'],
        'longitude': !exists(json, 'Longitude') ? undefined : json['Longitude'],
    };
}

export function GeolocationToJSON(value?: Geolocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Latitude': value.latitude,
        'Longitude': value.longitude,
    };
}

