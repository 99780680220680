import { ActionModal, Button } from "libs/ui";
import { useTranslation } from "react-i18next";

interface RequestPersonalDataModalProps {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

export function RequestPersonalDataModal({
  show,
  onCancel,
  onConfirm,
  isLoading,
}: RequestPersonalDataModalProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "SecurityAndPrivacy.AccountData.RequestPersonalDataModal",
  });

  return (
    <ActionModal
      inLoadingState={isLoading}
      show={show}
      icon={{
        iconType: "Download",
        color: "primary",
      }}
      title={t("title")}
      cancelButton={
        <Button onClick={onCancel} buttonSize={"medium"} buttonType={"transparent"}>
          {t("buttonCancel")}
        </Button>
      }
      submitButton={
        <Button onClick={onConfirm} buttonSize={"medium"} buttonType={"primary"}>
          {t("buttonSubmit")}
        </Button>
      }
    >
      {t("message")}
    </ActionModal>
  );
}
