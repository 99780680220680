import { HTTPQuery } from "api/query/runtime";
import { camelCase, isPlainObject, upperFirst } from "lodash-es";

function objectToCamelCase(obj: Record<string, any>) {
  return Object.entries(obj).reduce(
    (obj_, [k, v]) => {
      /**
       * why javascript.., why
       * everything is an object
       */
      obj_[upperFirst(camelCase(k))] = isPlainObject(v) ? objectToCamelCase(v) : v;
      return obj_;
    },
    {} as Record<string, any>,
  );
}

//#region copied from runtime.ts
export function querystring(params: HTTPQuery, prefix: string = ""): string {
  return Object.keys(params)
    .map(key => querystringSingleKey(key, params[key], prefix))
    .filter(part => part.length > 0)
    .join("&");
}

export function querystringSingleKey(
  key: string,
  value:
    | string
    | number
    | null
    | boolean
    | Array<string | number | null | boolean>
    | Set<string | number | null | boolean>
    | HTTPQuery,
  keyPrefix: string = "",
): string {
  const fullKey = keyPrefix + (keyPrefix.length ? `[${key}]` : key);
  if (value instanceof Array) {
    const multiValue = value
      .map(singleValue => encodeURIComponent(String(singleValue)))
      .join(`&${encodeURIComponent(fullKey)}=`);
    return `${encodeURIComponent(fullKey)}=${multiValue}`;
  }

  if (value instanceof Set) {
    const valueAsArray = Array.from(value);
    return querystringSingleKey(key, valueAsArray, keyPrefix);
  }

  if (value instanceof Date) {
    return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value.toISOString())}`;
  }

  if (value instanceof Object) {
    return `${encodeURIComponent(fullKey)}=${encodeURIComponent(
      JSON.stringify(objectToCamelCase(value)),
    )}`;
  }

  return `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`;
}
//#endregion
