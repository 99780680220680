import { Logo } from "libs/ui/Logo";
import { Trans, useTranslation } from "react-i18next";
import "./FallbackPage.scss";
import { ErrorTitle } from "components/ErrorElements/ErrorTitle";
import { Message } from "components/ErrorElements/Message";
import { ErrorButton } from "components/ErrorElements/ErrorButton";
import { OptionalMessage } from "components/ErrorElements/OptionalMessage";
import { ErrorFooter } from "components/ErrorElements/ErrorFooter";

export function FallbackPage() {
  const { t } = useTranslation("translation", { keyPrefix: "ErrorPages.FetchingErrorPage" });

  //? TODO missing design for this

  return (
    <div className="FallbackPage">
      <Logo className="FallbackPage__logo" />
      <div className="FallbackPage__content">
        <ErrorTitle>{t("title")}</ErrorTitle>
        <Message>{t("message")}</Message>
        <ErrorButton onClick={() => window.location.reload()}>{t("button_text")}</ErrorButton>
        <OptionalMessage>
          <Trans i18nKey="additional_message" t={t} values={{ boldText: "support@carna.health" }}>
            <span className="FallbackPage__additionalMessage--bold"></span>
          </Trans>
        </OptionalMessage>
      </div>
      <ErrorFooter>{t("footer", { year: new Date().getFullYear() })}</ErrorFooter>
    </div>
  );
}
