import { CountryType, NationalityType } from "api/query";
import { UserStatusType, GenderType, PersonModel } from "models/PersonModels";

export interface AvatarPersonData {
  id?: string | null;
  src?: string | null;
  loading?: boolean;
  name?: string;
  organizationId?: string | null;
}

export const CHANGE_STATUS_ALLOWED: UserStatusType[] = ["Active", "Invited", "Deactivated"];

export interface DetailResponseModel extends PersonModel {
  phone: string;
  street: string;
  state?: number | null;
  city: string;
  country: CountryType;
  zipCode?: string | null;
  organizationName: string;
  isAccountOwner?: boolean;
  nationalityType?: NationalityType | null;
  gender?: GenderType;
}
