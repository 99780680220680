/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';

/**
 * 
 * @export
 * @interface UpdateHcpRequestModel
 */
export interface UpdateHcpRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateHcpRequestModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHcpRequestModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHcpRequestModel
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHcpRequestModel
     */
    city?: string | null;
    /**
     * 
     * @type {CountryType}
     * @memberof UpdateHcpRequestModel
     */
    country?: CountryType;
    /**
     * 
     * @type {number}
     * @memberof UpdateHcpRequestModel
     */
    state?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHcpRequestModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHcpRequestModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHcpRequestModel
     */
    deviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHcpRequestModel
     */
    oldDeviceRowVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHcpRequestModel
     */
    newDeviceRowVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHcpRequestModel
     */
    rowVersion: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateHcpRequestModel
     */
    hcpType?: number | null;
}

/**
 * Check if a given object implements the UpdateHcpRequestModel interface.
 */
export function instanceOfUpdateHcpRequestModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rowVersion" in value;

    return isInstance;
}

export function UpdateHcpRequestModelFromJSON(json: any): UpdateHcpRequestModel {
    return UpdateHcpRequestModelFromJSONTyped(json, false);
}

export function UpdateHcpRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateHcpRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'FirstName') ? undefined : json['FirstName'],
        'lastName': !exists(json, 'LastName') ? undefined : json['LastName'],
        'street': !exists(json, 'Street') ? undefined : json['Street'],
        'city': !exists(json, 'City') ? undefined : json['City'],
        'country': !exists(json, 'Country') ? undefined : CountryTypeFromJSON(json['Country']),
        'state': !exists(json, 'State') ? undefined : json['State'],
        'zipCode': !exists(json, 'ZipCode') ? undefined : json['ZipCode'],
        'phone': !exists(json, 'Phone') ? undefined : json['Phone'],
        'deviceId': !exists(json, 'DeviceId') ? undefined : json['DeviceId'],
        'oldDeviceRowVersion': !exists(json, 'OldDeviceRowVersion') ? undefined : json['OldDeviceRowVersion'],
        'newDeviceRowVersion': !exists(json, 'NewDeviceRowVersion') ? undefined : json['NewDeviceRowVersion'],
        'rowVersion': json['RowVersion'],
        'hcpType': !exists(json, 'HcpType') ? undefined : json['HcpType'],
    };
}

export function UpdateHcpRequestModelToJSON(value?: UpdateHcpRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': value.state,
        'ZipCode': value.zipCode,
        'Phone': value.phone,
        'DeviceId': value.deviceId,
        'OldDeviceRowVersion': value.oldDeviceRowVersion,
        'NewDeviceRowVersion': value.newDeviceRowVersion,
        'RowVersion': value.rowVersion,
        'HcpType': value.hcpType,
    };
}

