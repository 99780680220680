import { EditableDetails } from "components/EditableDetails/EditableDetails";
import * as Forms from "components/Forms/Device";
// import { DeviceImage } from "libs/ui";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useMakeDevicesPageDetailState } from "../model";
import "./DetailsForm.scss";

interface DetailsFormProps {
  detailsService: ReturnType<typeof useMakeDevicesPageDetailState>;
}

export function DetailsForm({ detailsService, children }: PropsWithChildren<DetailsFormProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "DeviceDetails" });
  const [state] = detailsService;

  const showForm = !state.matches("entity.loading");

  return (
    <EditableDetails
      title={t("title")}
      isLoading={state.matches("entity.loading")}
      cancel={{
        disabled: state.matches("entity.saving") || state.matches("entity.loading"),
        onClick: () => null,
      }}
      save={{
        disabled: state.matches("entity.saving") || state.matches("entity.loading"),
        onClick: () => null,
        isLoading: state.matches("entity.saving"),
      }}
      className="DeviceDetails"
    >
      {showForm && (
        <>
          {children}
          {/* !!! Leaving as a reminder in case we need it back */}
          {/* <DeviceImage className="DeviceDetails__deviceImage" /> */}
          <Forms.Device
            formMode={state.matches("entity.editing") ? "Edit" : "Readonly"}
            deviceData={
              state.matches("entity.editing") || state.matches("entity.saving")
                ? {}
                : // TODO fixme
                  state.context?.data ?? ({} as any)
            }
            setDeviceData={() => null}
            onSubmit={() => null}
            editableFields={[]}
          />
        </>
      )}
    </EditableDetails>
  );
}
