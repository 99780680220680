import { Icon } from "../../../Icon";
import { Cell } from "../Cell";
import { CountryFlag } from "../../../CountryFlag";
import { FlagCellProps } from "../model";
import "./FlagCell.scss";

export function FlagCell({ onCellClick, country, countryName, loading }: FlagCellProps) {
  return (
    <Cell loading={loading} onClick={onCellClick} className="FlagCell">
      {country ? (
        <CountryFlag countryCode={country} countryName={countryName} flagSize="medium" />
      ) : (
        <Icon icon="Slash" />
      )}
    </Cell>
  );
}
