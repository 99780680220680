import * as LocaleTexts from "@mui/x-date-pickers/locales";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import * as LocaleAdapters from "date-fns/locale";

export function useMUILocaleOptions() {
  const { globalPreference } = useGlobalPreferenceContext();
  const locale = globalPreference?.regionType ?? "en-US";

  const availableLocaleKeys = Object.keys(LocaleAdapters);
  const localeKey = locale.split("-")[0];
  const preferredLocale = availableLocaleKeys.find(el => el === localeKey) ?? "en";

  const availableLocaleTextKeys = Object.keys(LocaleTexts);
  const preferredLocaleText =
    availableLocaleTextKeys.find(el => el === locale.toString().replace("-", "")) ?? "enUS";

  return {
    adapter: LocaleAdapters[preferredLocale as keyof typeof LocaleAdapters],
    text: (LocaleTexts[preferredLocaleText as keyof typeof LocaleTexts] as any).components
      .MuiLocalizationProvider.defaultProps.localeText,
  } as const;
}
