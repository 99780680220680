/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormsDefaultValuesModel } from './FormsDefaultValuesModel';
import {
    FormsDefaultValuesModelFromJSON,
    FormsDefaultValuesModelFromJSONTyped,
    FormsDefaultValuesModelToJSON,
} from './FormsDefaultValuesModel';

/**
 * 
 * @export
 * @interface FormsModel
 */
export interface FormsModel {
    /**
     * 
     * @type {FormsDefaultValuesModel}
     * @memberof FormsModel
     */
    defaultValues: FormsDefaultValuesModel;
}

/**
 * Check if a given object implements the FormsModel interface.
 */
export function instanceOfFormsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "defaultValues" in value;

    return isInstance;
}

export function FormsModelFromJSON(json: any): FormsModel {
    return FormsModelFromJSONTyped(json, false);
}

export function FormsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultValues': FormsDefaultValuesModelFromJSON(json['defaultValues']),
    };
}

export function FormsModelToJSON(value?: FormsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultValues': FormsDefaultValuesModelToJSON(value.defaultValues),
    };
}

