import * as React from "react";
import { SVGProps } from "react";
const SvgSend = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.153 12.625 3.075 21.811a.75.75 0 0 1-1.025-.945l3.432-8.924L2.05 3.02a.75.75 0 0 1 1.025-.945l19.076 9.185a.75.75 0 0 1 .002 1.366Zm-3.598-1.433L4.13 4.246l2.672 6.946h11.754Zm-11.754 1.5-2.672 6.946 14.426-6.946H6.801Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSend;
