import classNames from "classnames";
import { Link } from "react-router-dom";
import { Icon, IconType } from "../Icon";
import "./CardLink.scss";

export interface CardLinkProps {
  path: string;
  icon: IconType;
  title: string;
  disabled?: boolean;
  className?: string;
}

export function CardLink({ path, icon, title, disabled, className }: CardLinkProps) {
  return (
    <Link
      to={path}
      className={classNames(
        "UI-Components CardLink",
        { "CardLink--disabled": disabled },
        className,
      )}
    >
      <div className="CardLink__body">
        <Icon icon={icon} />
        <h3 className="CardLink__title">{title}</h3>
      </div>
    </Link>
  );
}
