import { Avatar, AvatarSize } from "libs/ui";
import "./PatientInfoHeader.scss";
import classNames from "classnames";

interface PatientInfoHeaderProps {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  registryNumber: string | null | undefined;
  avatar?: string;
  avatarSize?: AvatarSize;
}

export function PatientInfoHeader({
  firstName,
  lastName,
  avatar,
  registryNumber,
  avatarSize = "large",
}: Readonly<PatientInfoHeaderProps>) {
  return (
    <div className="PatientInfoHeader">
      <Avatar
        // TODO NEW_BACKEND
        src={avatar}
        firstName={firstName}
        avatarLoading={false}
        size={avatarSize}
      />
      <div
        className={classNames("PatientInfoHeader__info", {
          "PatientInfoHeader__info--separator": avatarSize === "large",
        })}
      >
        <h4 className="PatientInfoHeader__name">{firstName?.concat(` ${lastName}`)}</h4>

        {registryNumber ? (
          <p className="PatientInfoHeader__registryNumber">{registryNumber}</p>
        ) : null}
      </div>
    </div>
  );
}
