import { UserStatusType } from "models/PersonModels";
import { IconType } from "../Icon";

type StatusToIconMap = {
  [K in UserStatusType]?: IconType;
};

export function statusToIcon(status: UserStatusType): IconType {
  const statusMap: StatusToIconMap = {
    Active: "Slash",
    Deactivated: "CheckCircle",
    Invited: "Refresh",
  } as const;

  if (!statusMap[status]) {
    console.warn("Status to icon map not found, returning default...");
  }

  return statusMap[status] ?? "User";
}
