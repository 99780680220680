import * as React from "react";
import { SVGProps } from "react";
const SvgAtSign = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.443 1.532a10.75 10.75 0 1 0 4.093 19.003.75.75 0 1 0-.912-1.19A9.25 9.25 0 1 1 21.25 12v1a2.25 2.25 0 1 1-4.5 0V8a.75.75 0 0 0-1.5 0v.536a4.75 4.75 0 1 0 .515 6.36A3.746 3.746 0 0 0 19 16.75 3.75 3.75 0 0 0 22.75 13v-1a10.75 10.75 0 0 0-8.307-10.468ZM8.75 12a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAtSign;
