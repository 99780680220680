import { PatientsTableColumns } from "utils/createGlobalConfigStore";

type theadSuffixesProps = {
  [key in PatientsTableColumns]?: string;
};
interface PatientsTableColumnsProps {
  t: TFunction;
  theadSuffixes?: theadSuffixesProps;
}

interface AppliedColumnsProps extends PatientsTableColumnsProps {
  appliedColumns: PatientsTableColumns[];
}

export function resolveAppliedColumns({ appliedColumns, t, theadSuffixes }: AppliedColumnsProps) {
  return appliedColumns.map(column => {
    return {
      label:
        t(`columns.${column}`) + (theadSuffixes?.[column] ? `\n ${theadSuffixes[column]}` : ""),
      name: column,
    };
  });
}
