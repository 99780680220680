import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useMemo } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

type BMILimitKey = "Obesity" | "Overweight" | "Healthy" | "Underweight";

interface SingleStageRange {
  from?: number;
  to?: number;
}

export type BMIStageRanges = {
  [key in BMILimitKey]: SingleStageRange;
};

function getRowRanges(data: any, key: BMILimitKey) {
  if (Object.hasOwn(data, key) === false) {
    throw new Error(`${data} has no ${key} key`);
  }

  switch (key) {
    case "Obesity":
      return { from: data[key].value } as const;

    case "Overweight":
      return { from: data[key].from, to: data[key].to } as const;

    case "Healthy":
      return { from: data[key].from, to: data[key].to } as const;

    case "Underweight":
      return { to: data[key].value } as const;

    default:
      throw new Error(`${key} not covered`);
  }
}

const orderedStages: BMILimitKey[] = ["Obesity", "Overweight", "Healthy", "Underweight"];

export function useGetBMIRanges() {
  const { appConfig } = useGlobalConfigContext();
  let orderedBMIStageRanges: BMIStageRanges = useMemo(
    () => ({
      Obesity: {},
      Overweight: {},
      Healthy: {},
      Underweight: {},
    }),
    [],
  );

  const bmiLimits = NotNullOrUndefined(appConfig?.laboratory.measurements.limits?.bmi.limits);

  orderedStages.forEach(stage => {
    orderedBMIStageRanges = {
      ...orderedBMIStageRanges,
      [stage]: getRowRanges(bmiLimits, stage),
    };
  });

  return useMemo(() => ({ orderedBMIStageRanges }), [orderedBMIStageRanges]);
}
