import { useHover } from "@floating-ui/react";
import { TooltipComponent, useGetTooltipProps } from "libs/ui/Tooltip";
import "./Stop.scss";

interface SingleStopProps {
  number: number;
}

const NUMBER_OF_DIGITS_ALLOWED = 4;

export function SingleStop({ number }: SingleStopProps) {
  const {
    x,
    y,
    refs,
    strategy,
    arrowRef,
    arrowX,
    arrowY,
    open,
    getReferenceProps,
    getFloatingProps,
  } = useGetTooltipProps({
    interactionProps: [useHover],
    placement: "bottom",
    crossAxis: 0,
    mainAxis: 5,
  });

  const showTooltip = number.toString().length > NUMBER_OF_DIGITS_ALLOWED;

  return (
    <>
      <div className="SingleStop" ref={refs.setReference} {...getReferenceProps()}>
        {number}
      </div>
      {showTooltip ? (
        <TooltipComponent
          type="grey"
          ref={refs.setFloating}
          x={x}
          y={y}
          floatingProps={getFloatingProps()}
          open={open}
          strategy={strategy}
          arrowRef={arrowRef}
          arrowX={arrowX}
          arrowY={arrowY}
          arrowSize="small"
        >
          {number}
        </TooltipComponent>
      ) : null}
    </>
  );
}
