import { NavigationRoute } from "components/NavigationRoute";

import { mainRoutePaths, settingsRoutePaths } from "router";

import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { capitalize } from "lodash-es";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useGetNavRoutes, useGetSettingsNavRoutes } from "utils/hooks/useGetNavRoutes";
import { BasicDetails } from ".";
import "./BasicDetailsDropDown.scss";

interface Props<T extends HTMLDivElement> {
  refProp: (elem: T) => void;
  style: CSSProperties | undefined;
  onRouteClick: () => void;
  onSignOutClick: () => void;
}

export function BasicDetailsDropDown<T extends HTMLDivElement>({
  refProp,
  style,
  onRouteClick,
  onSignOutClick,
}: Readonly<Props<T>>) {
  const { t } = useTranslation("translation");
  const { appConfig } = useGlobalConfigContext();

  const [, mainRoutes] = useGetNavRoutes();
  const [, settingsRoutes] = useGetSettingsNavRoutes();

  return (
    <div ref={refProp} className="BasicDetailsDropDown" style={style}>
      <BasicDetails avatarSize="medium" />
      <hr />
      <ul>
        {settingsRoutes.users.accessLevel ? (
          <NavigationRoute
            route={settingsRoutes.users}
            handleRouteChange={onRouteClick}
            key={settingsRoutePaths.users}
          />
        ) : null}
        {settingsRoutes.projectOrganizations.accessLevel ? (
          <NavigationRoute
            route={settingsRoutes.projectOrganizations}
            handleRouteChange={onRouteClick}
            key={settingsRoutePaths.projectOrganizations}
          />
        ) : null}
        {mainRoutes.settings.accessLevel ? (
          <NavigationRoute
            route={mainRoutes.settings}
            handleRouteChange={onRouteClick}
            key={mainRoutePaths.settings}
          />
        ) : null}
      </ul>
      <hr />

      <div onClick={onSignOutClick} className="BasicDetailsDropDown__signOut">
        {t("Landing.signOut")}
      </div>
      <span className="BasicDetailsDropDown__tenantName">
        {t("Tenant.label", {
          tenantId: capitalize(appConfig?.general.translationTenantId ?? ""),
        })}
      </span>
    </div>
  );
}
