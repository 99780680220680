import * as React from "react";
import { SVGProps } from "react";
const SvgHeartFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.95 1.998a6.5 6.5 0 0 0-4.597 1.905L12 4.256l-.353-.353a6.501 6.501 0 1 0-9.194 9.194l8.84 8.84a1 1 0 0 0 1.414 0l8.84-8.84A6.5 6.5 0 0 0 16.95 1.998Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgHeartFilled;
