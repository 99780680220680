import classNames from "classnames";
import { Page, SideModal } from "libs/ui";
import { PropsWithChildren, ReactNode } from "react";
import "./DetailsPage.scss";
import { DetailsPageControls } from "./DetailsPageControls";
import { useDetailsPageContext } from "./context";
import { useFilteredBreadCrumbs } from "utils/hooks/Pages/useFilteredBreadCrumbs";

interface DetailsPageProps {
  details: ReactNode;
  showInfo?: boolean;
  showEdit?: boolean;
  controls?: JSX.Element;
  className?: string;
  customBreadcrumbsElem?: JSX.Element;
  detailsPageControlsSlot?: ReactNode;
}

export function DetailsPage({
  details,
  showInfo,
  showEdit,
  controls,
  className,
  children,
  customBreadcrumbsElem,
  detailsPageControlsSlot,
}: PropsWithChildren<DetailsPageProps>) {
  const filteredBreadCrumbs = useFilteredBreadCrumbs();
  const { detailsPageSideBarRef } = useDetailsPageContext();
  return (
    <Page
      data-testid="DetailsPage"
      breadCrumbs={filteredBreadCrumbs}
      customBreadcrumbsElem={customBreadcrumbsElem}
      controls={
        <DetailsPageControls detailsPageControlsSlot={detailsPageControlsSlot}>
          {controls}
        </DetailsPageControls>
      }
      className={classNames("DetailsPage", className)}
    >
      <div className="DetailsPage__main">{children}</div>

      <div ref={detailsPageSideBarRef} className={classNames("DetailsPage__info")}>
        <div className="DetailsPage__separator" />
        {showInfo ? details : null}
      </div>

      <SideModal show={!!showEdit}>{details}</SideModal>
    </Page>
  );
}
