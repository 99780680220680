import { Route } from "react-router-dom";
import { applyTo } from "ramda";
import { AccessLevel } from "../utils/AccessLevel";
import { DashboardPage } from "pages/DashboardPage";
import { dashboardPaths, mainRoutePaths } from "router";
import { DashboardMap } from "pages/DashboardPage/DashboardMap";
import { DashboardMapContext } from "pages/DashboardPage/DashboardMap/DashboardMapContext";
import { DashboardStats } from "pages/DashboardPage/DashboardStats";
import { FeatureToggleRoute } from "utils/featureToggles";
import { DashboardMapReportContextProvider } from "pages/DashboardPage/DashboardMap/DashboardMapReportDataContext";
import { DashboardStatsWidgetContextProvider } from "pages/DashboardPage/DashboardStats/DashboardStatsWidgetContext";

export const DashboardRoutes = (
  <Route
    path={mainRoutePaths.dashboard}
    element={
      <FeatureToggleRoute keys={["dashboard-map"] as const}>
        <AccessLevel check={applyTo("manage-dashboard-admin")}>
          <DashboardMapContext>
            <DashboardMapReportContextProvider>
              <DashboardStatsWidgetContextProvider>
                <DashboardPage />
              </DashboardStatsWidgetContextProvider>
            </DashboardMapReportContextProvider>
          </DashboardMapContext>
        </AccessLevel>
      </FeatureToggleRoute>
    }
  >
    <Route index path={dashboardPaths.dashboardMap} element={<DashboardMap />} />
    <Route path={dashboardPaths.dashboardStats} element={<DashboardStats />} />
  </Route>
);
