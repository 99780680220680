/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  TestListResponseModel,
  TestResponseModel,
  TestsFilterModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    TestListResponseModelFromJSON,
    TestListResponseModelToJSON,
    TestResponseModelFromJSON,
    TestResponseModelToJSON,
    TestsFilterModelFromJSON,
    TestsFilterModelToJSON,
} from '../models/index';

export interface OrganizationsOrganizationIdDevicesUserEntityIdTestsGetRequest {
    organizationId: string;
    userEntityId: string;
    filters?: TestsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdHcpsUserEntityIdPatientsTestsGetRequest {
    organizationId: string;
    userEntityId: string;
    filters?: TestsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdPatientsUserEntityIdTestsGetRequest {
    organizationId: string;
    userEntityId: string;
    filters?: TestsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGetRequest {
    organizationId: string;
    userEntityId: string;
    testEntityId: string;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdTestsGetRequest {
    organizationId: string;
    filters?: TestsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdTestsIdGetRequest {
    organizationId: string;
    id: string;
    acceptVersion?: string;
}

export interface TestsGetRequest {
    filters?: TestsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface TestsIdGetRequest {
    id: string;
    acceptVersion?: string;
}

/**
 * TestsApi - interface
 * 
 * @export
 * @interface TestsApiInterface
 */
export interface TestsApiInterface {
    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {TestsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApiInterface
     */
    organizationsOrganizationIdDevicesUserEntityIdTestsGetRaw(requestParameters: OrganizationsOrganizationIdDevicesUserEntityIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>>;

    /**
     */
    organizationsOrganizationIdDevicesUserEntityIdTestsGet(requestParameters: OrganizationsOrganizationIdDevicesUserEntityIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {TestsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApiInterface
     */
    organizationsOrganizationIdHcpsUserEntityIdPatientsTestsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatientsTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>>;

    /**
     */
    organizationsOrganizationIdHcpsUserEntityIdPatientsTestsGet(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatientsTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {TestsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApiInterface
     */
    organizationsOrganizationIdPatientsUserEntityIdTestsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>>;

    /**
     */
    organizationsOrganizationIdPatientsUserEntityIdTestsGet(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} testEntityId 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApiInterface
     */
    organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGetRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestResponseModel>>;

    /**
     */
    organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGet(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {TestsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApiInterface
     */
    organizationsOrganizationIdTestsGetRaw(requestParameters: OrganizationsOrganizationIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>>;

    /**
     */
    organizationsOrganizationIdTestsGet(requestParameters: OrganizationsOrganizationIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApiInterface
     */
    organizationsOrganizationIdTestsIdGetRaw(requestParameters: OrganizationsOrganizationIdTestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestResponseModel>>;

    /**
     */
    organizationsOrganizationIdTestsIdGet(requestParameters: OrganizationsOrganizationIdTestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestResponseModel>;

    /**
     * 
     * @param {TestsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApiInterface
     */
    testsGetRaw(requestParameters: TestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>>;

    /**
     */
    testsGet(requestParameters: TestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApiInterface
     */
    testsIdGetRaw(requestParameters: TestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestResponseModel>>;

    /**
     */
    testsIdGet(requestParameters: TestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestResponseModel>;

}

/**
 * 
 */
export class TestsApi extends runtime.BaseAPI implements TestsApiInterface {

     /**
     */
    async organizationsOrganizationIdDevicesUserEntityIdTestsGetRawOriginal(requestParameters: OrganizationsOrganizationIdDevicesUserEntityIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesUserEntityIdTestsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdDevicesUserEntityIdTestsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/devices/{userEntityId}/tests`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdDevicesUserEntityIdTestsGetRaw(requestParameters: OrganizationsOrganizationIdDevicesUserEntityIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesUserEntityIdTestsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdDevicesUserEntityIdTestsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/devices/{userEntityId}/tests`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestListResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdDevicesUserEntityIdTestsGet(requestParameters: OrganizationsOrganizationIdDevicesUserEntityIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel> {
        const response = await this.organizationsOrganizationIdDevicesUserEntityIdTestsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdPatientsTestsGetRawOriginal(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatientsTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPatientsTestsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPatientsTestsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/hcps/{userEntityId}/patients/tests`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdPatientsTestsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatientsTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPatientsTestsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPatientsTestsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{userEntityId}/patients/tests`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestListResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdPatientsTestsGet(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatientsTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel> {
        const response = await this.organizationsOrganizationIdHcpsUserEntityIdPatientsTestsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdTestsGetRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdTestsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdTestsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}/tests`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdTestsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdTestsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdTestsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}/tests`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestListResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdTestsGet(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel> {
        const response = await this.organizationsOrganizationIdPatientsUserEntityIdTestsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGetRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGet.');
        }

        if (requestParameters.testEntityId === null || requestParameters.testEntityId === undefined) {
            throw new runtime.RequiredError('testEntityId','Required parameter requestParameters.testEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}/tests/{testEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))).replace(`{${"testEntityId"}}`, encodeURIComponent(String(requestParameters.testEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGetRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGet.');
        }

        if (requestParameters.testEntityId === null || requestParameters.testEntityId === undefined) {
            throw new runtime.RequiredError('testEntityId','Required parameter requestParameters.testEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}/tests/{testEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))).replace(`{${"testEntityId"}}`, encodeURIComponent(String(requestParameters.testEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGet(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestResponseModel> {
        const response = await this.organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdTestsGetRawOriginal(requestParameters: OrganizationsOrganizationIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdTestsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/tests`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdTestsGetRaw(requestParameters: OrganizationsOrganizationIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdTestsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/tests`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestListResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdTestsGet(requestParameters: OrganizationsOrganizationIdTestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel> {
        const response = await this.organizationsOrganizationIdTestsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdTestsIdGetRawOriginal(requestParameters: OrganizationsOrganizationIdTestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdTestsIdGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdTestsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/tests/{id}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdTestsIdGetRaw(requestParameters: OrganizationsOrganizationIdTestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdTestsIdGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdTestsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/tests/{id}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdTestsIdGet(requestParameters: OrganizationsOrganizationIdTestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestResponseModel> {
        const response = await this.organizationsOrganizationIdTestsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async testsGetRawOriginal(requestParameters: TestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/tests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async testsGetRaw(requestParameters: TestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/tests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestListResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async testsGet(requestParameters: TestsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel> {
        const response = await this.testsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async testsIdGetRawOriginal(requestParameters: TestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling testsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/tests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async testsIdGetRaw(requestParameters: TestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling testsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/tests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async testsIdGet(requestParameters: TestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestResponseModel> {
        const response = await this.testsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
