/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GenderType = {
    Male: 'Male',
    Female: 'Female'
} as const;
export type GenderType = typeof GenderType[keyof typeof GenderType];


export function GenderTypeFromJSON(json: any): GenderType {
    return GenderTypeFromJSONTyped(json, false);
}

export function GenderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenderType {
    return json as GenderType;
}

export function GenderTypeToJSON(value?: GenderType | null): any {
    return value as any;
}

