import { useMachine } from "@xstate/react";
import { CarnaApiEvent } from "config/apiEvent";
import { CarnaApiQuery } from "config/apiQuery";
import { toastStore } from "config/toast";
import i18n from "i18next";
import { HCPModel } from "models/PersonModels";
import { useMemo } from "react";
import { getRowVersion } from "utils/helpers/getRowVersion";
import { makeDetailsPageStateMachine } from "utils/machines/pages/details/makeDetailsPageStateMachine";

import { stripNetworkBodyWith } from "components/Forms/helper";
import { UpdateHcpRequestModelForStripping } from "config/binding";
import { isEqual } from "lodash-es";
import { firstValueFrom } from "rxjs";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import {
  OnEventStatusSubscribeError,
  waitForQueryService,
} from "utils/hooks/useOnEventStatusSubscribe";
import { assign, fromPromise } from "xstate";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";

const successToast = () =>
  toastStore.pushToast({
    expire: 5000,
    type: "success",
    msg: i18n.t("HCPDetails.successPutToast", { ns: "translation" }),
  });

// ! FIXME add xstate error event model type
const errorPutToast = (input: any) => {
  const err = input.event.error as OnEventStatusSubscribeError;

  if (err.type === "OnEventStatusSubscribeError") {
    switch (err.code) {
      case "BE_ERROR":
        showBeFieldErrors(err.err, i18n.t("HCPDetails.errorPutToast", { ns: "translation" }));

        break;
      case "ACTION_FAILED":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("HCPDetails.errorPutToast", { ns: "translation" }),
        });
        break;
      case "STATUS_QUERY_ERROR":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("HCPDetails.queryServiceError", { ns: "translation" }),
        });
    }
    return;
  }

  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("HCPDetails.errorPutToast", { ns: "translation" }),
  });
};

const errorFetchToast = () => {
  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("HCPDetails.errorFetchToast", { ns: "translation" }),
  });
};

export function useMakeHcpPagesDetailState(hcpId: string, organizationId: string) {
  const userAttributes = useAdditionalAuthInformationContext();

  const hcpPageStateMachine = useMemo(
    () =>
      makeDetailsPageStateMachine<
        NonNullable<HCPModel>,
        HCPModel
        // DetailsPageServiceList<typeof fetchHCPData, typeof saveHcp, typeof saveHcpStatus>
      >("hcpPageStateMachine"),
    [],
  );

  const service = useMachine(
    hcpPageStateMachine.provide({
      actors: {
        saveData: fromPromise(async ({ input }) => {
          let result: Response | undefined;

          if (isEqual(input.context.data, input.event.value) === false) {
            const putHCP = CarnaApiEvent.Hcp.put(
              {
                organizationId,
                userEntityId: hcpId,
                updateHcpRequestModel: {
                  ...input.event.value,
                },
              },
              stripNetworkBodyWith(UpdateHcpRequestModelForStripping),
            );

            await firstValueFrom(waitForQueryService(putHCP));

            result = await putHCP;
          }

          if (input.event.saveAvatar) {
            await input.event.saveAvatar(result ? getRowVersion(result).rowVersion : undefined);
          }

          return (
            result
              ? {
                  ...input.event.value,
                  ...getRowVersion(result),
                }
              : //ignore put request if the form isnt changed
                input.context.data
          ) as HCPModel;
        }),
        fetchData: fromPromise(async () => {
          return CarnaApiQuery.Hcps.getById({
            organizationId,
            hcpEntityId: hcpId,
          });
        }),
        saveStatus: fromPromise(async () => {
          console.warn("NEW_BACKEND_NULL - not implemented");
        }),
      },
      actions: {
        dataSaved: input => {
          const event: any = input.event;

          // If the current user edites itself
          if (
            event?.output?.id === userAttributes.currentUserId &&
            (event?.output?.firstName !== userAttributes.firstName ||
              event?.output?.lastName !== userAttributes.lastName)
          ) {
            userAttributes.setUserAttributes?.({
              ...userAttributes,
              firstName: event?.output?.firstName ?? "",
              lastName: event?.output?.lastName ?? "",
            });
          }
          successToast();
        },
        savingFailed: errorPutToast,
        failedToLoadData: errorFetchToast,
        loadSavedData: assign({
          data: input => {
            return (input.event as any).output;
          },
        }),
        loadEntityData: assign({
          data: input => {
            return (input.event as any).output;
          },
        }),
        updateSaveStatus: assign({
          data: input => {
            return {
              ...(input.event as any).output,
              status: (input.event as any).output?.status ? "Active" : "Deactivated",
            } as HCPModel;
          },
        }),
        refreshTable: () => {},
      },
    }),
  );

  return service;
}
