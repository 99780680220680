import { CarnaApiEvent } from "config/apiEvent";
import { toastStore } from "config/toast";
import { useCallback, forwardRef, useImperativeHandle, useState } from "react";
import { useOnEventStatusSubscribe } from "utils/hooks/useOnEventStatusSubscribe";
import { useTranslation } from "react-i18next";
import { CreateNewSideModal } from "../common/CreateNewSideModal";
import { AddModalForwardedProps, AddModalProps } from "../model";
import { DeviceModel } from "components/Forms/Device/model";
import { deviceFormUtils } from "components/Forms/Device/deviceFormUtils";
import * as Form from "components/Forms/Device";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { stripNetworkBodyWith } from "components/Forms/helper";
import { AddDeviceModelForStripping } from "config/binding";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import { InitialValuesModel } from "models/PersonModels";
import { DISABLE_FORM_EDITING, EVERY_FORM_FIELD_EDITABLE } from "components/Forms/model";

function getInitialValues(
  defaultModel: DeviceModel,
  initialValuesAsProps?: Partial<InitialValuesModel>,
) {
  if (initialValuesAsProps) {
    return {
      ...defaultModel,
      organizationId: initialValuesAsProps.organizationId ?? "",
      organizationName: initialValuesAsProps.organizationName ?? "",
    } satisfies DeviceModel;
  }
  return defaultModel;
}

export const Device = forwardRef<AddModalForwardedProps, AddModalProps>(
  ({ onSuccessCb, initialValuesAsProps }, ref) => {
    const { t } = useTranslation("modals", { keyPrefix: "AddModal" });
    const [showModal, setShowModal] = useState(false);
    const [deviceData, setDeviceData] = useState<DeviceModel>(() =>
      getInitialValues(deviceFormUtils.DEVICE_DATA_DEFAULT, initialValuesAsProps),
    );

    const [onEvent, inLoadingState] = useOnEventStatusSubscribe();
    const { appConfig } = useGlobalConfigContext();

    const resetAndClose = useCallback(() => {
      setDeviceData(getInitialValues(deviceFormUtils.DEVICE_DATA_DEFAULT, initialValuesAsProps));
      setShowModal(false);
    }, [initialValuesAsProps]);

    const onSuccessCallback = useCallback(() => {
      resetAndClose();
      if (onSuccessCb) {
        onSuccessCb();
      }
    }, [onSuccessCb, resetAndClose]);

    const areFieldsReady = deviceFormUtils.getFieldsReady(deviceData, "Add", appConfig);
    const submitDisabled = !areFieldsReady || inLoadingState;

    const onSubmit = (e?: React.FormEvent) => {
      e?.preventDefault();

      if (submitDisabled) {
        return;
      }

      onEvent(
        CarnaApiEvent.Device.post(
          {
            organizationId: deviceData.organizationId,
            addDeviceRequestModel: deviceData,
          },
          stripNetworkBodyWith(AddDeviceModelForStripping),
        ),
        {
          complete() {
            toastStore.toast.success({ msg: t("successAddDeviceToastText"), expire: 5000 });
            onSuccessCallback();
          },
          error(err) {
            switch (err.code) {
              case "BE_ERROR":
                showBeFieldErrors(err.err, t("errorAddDeviceToastText"));
                break;

              case "ACTION_FAILED":
                toastStore.toast.error({ msg: t("errorAddDeviceToastText") });
                break;
              case "STATUS_QUERY_ERROR":
                resetAndClose();
                toastStore.toast.error({ msg: t("errorFailedToUpdateDeviceTable") });
            }
          },
        },
      );
    };

    const toggleModal = () => setShowModal(prevValue => !prevValue);

    useImperativeHandle(
      ref,
      () => ({
        toggleModal,
      }),
      [],
    );

    return (
      <CreateNewSideModal
        title={t("addDeviceTitle")}
        submitButton={{
          onClick: onSubmit,
          text: t("buttonAdd"),
          disabled: submitDisabled,
        }}
        show={showModal}
        closeModal={{
          onClick: resetAndClose,
          disabled: inLoadingState,
        }}
        isLoading={inLoadingState}
      >
        <Form.Device
          formMode="Add"
          deviceData={deviceData}
          setDeviceData={setDeviceData}
          onSubmit={onSubmit}
          editableFields={inLoadingState ? DISABLE_FORM_EDITING : EVERY_FORM_FIELD_EDITABLE}
        />
      </CreateNewSideModal>
    );
  },
);
