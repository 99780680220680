import * as React from "react";
import { SVGProps } from "react";
const SvgArrowLeftCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.53 7.47a.75.75 0 0 1 0 1.06l-2.72 2.72H16a.75.75 0 0 1 0 1.5H9.81l2.72 2.72a.75.75 0 1 1-1.06 1.06l-4-4a.748.748 0 0 1 0-1.06l4-4a.75.75 0 0 1 1.06 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12ZM12 2.75a9.25 9.25 0 1 0 0 18.5 9.25 9.25 0 0 0 0-18.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowLeftCircle;
