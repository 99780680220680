import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.scss";

import { Login } from "layout/Login";
import "./i18n";

import { GlobalConfigProvider } from "context/GlobalConfigContext";
import { GlobalPreferenceProvider } from "context/GlobalPreferenceContext";
import { Main } from "layout/Main";
import { Toaster } from "libs/ui/Toaster/Toaster";
import { toastStore } from "config/toast";
import TranslationsLoader from "layout/Login/TranslationsLoader";
import "utils/helpers/google";
import "utils/machines/avatars";
import { AdditionalAuthInformationProvider } from "context/AdditionalAuthInformationContext";

function CarnaApp() {
  return (
    <Authenticator.Provider>
      <Login>
        <AdditionalAuthInformationProvider>
          <GlobalConfigProvider>
            <TranslationsLoader>
              <GlobalPreferenceProvider>
                <Main />
              </GlobalPreferenceProvider>
            </TranslationsLoader>
          </GlobalConfigProvider>
        </AdditionalAuthInformationProvider>
        <Toaster store={toastStore} />
      </Login>
    </Authenticator.Provider>
  );
}

export default CarnaApp;
