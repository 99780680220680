import classNames from "classnames";
import { Icon } from "libs/ui";
import { roleToColor } from "libs/ui/Table/TableElements/utils";
import { useTranslation } from "react-i18next";
import { UserRoleType } from "models/PersonModels/Roles";
import "./TableCardRole.scss";

interface TableCardRoleProps {
  role: UserRoleType;
  loading?: boolean;
}

export function TableCardRole({ role, loading }: TableCardRoleProps) {
  const { t } = useTranslation("translation", { keyPrefix: "userRoleType" });
  return (
    <div className="TableCardRole">
      <Icon
        icon={role === "Admin" ? "Admin" : "User"}
        style={{ color: roleToColor(role) }}
        className="TableCardRole__icon"
        loading={loading}
      />
      <span
        className={classNames("TableCardRole__name", { "TableCardRole__name--loading": loading })}
      >
        {loading ? null : t(role)}
      </span>
    </div>
  );
}
