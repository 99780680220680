import { useMemo } from "react";
import { TableOrientation } from "../model";
import "./RangeTableBody.scss";
import classNames from "classnames";
import { RowHorizontal } from "./RowHorizontal";
import { HeadRow } from "./VerticalRows/HeadRow";
import { StepsRow } from "./VerticalRows/StepsRow";

interface RangeTableBodyProps {
  labels: ReadonlyArray<string>;
  steps: ReadonlyArray<number>;
  value?: number;
  orientation?: TableOrientation;
}

export function RangeTableBody({ labels, steps, value, orientation }: RangeTableBodyProps) {
  const rows = useMemo(() => {
    if (orientation === "horizontal") {
      return (
        <>
          {labels.map((label, index) => (
            <RowHorizontal
              key={label}
              label={label}
              from={index === 0 ? undefined : steps[index - 1]}
              to={steps[index]}
              value={value}
            />
          ))}
        </>
      );
    }

    return (
      <>
        <HeadRow labels={labels} steps={steps} value={value} />
        <StepsRow labels={labels} steps={steps} value={value} />
      </>
    );
  }, [orientation, labels, steps, value]);

  return (
    <tbody className={classNames("RangeTableBody", `RangeTableBody--${orientation}`)}>{rows}</tbody>
  );
}
