import classNames from "classnames";
import { Avatar, Button, Card, Icon, TableElements } from "libs/ui";
import { TableUtils } from "libs/ui/Table/utils";
import React, { PropsWithChildren, useCallback, isValidElement } from "react";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { isAvatarData, OrganizationData, TableCardProps } from "./model";
import "./TableCard.scss";

function OrganizationComponent({
  isAdmin,
  organization,
  loading,
}: {
  isAdmin: boolean;
  organization?: OrganizationData;
  loading?: boolean;
}) {
  const tableRedirection = TableUtils.useTableElementsRedirect();

  const redirectToOrganization = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
      tableRedirection({
        event: e,
        path: "organizationDetails",
        params: { organizationId: organization?.id ?? "", firstTab: "partners" },
      }),
    [organization?.id, tableRedirection],
  );

  if (!organization) {
    return null;
  }

  if (loading) {
    return (
      <div className="OrganizationButton__loader">
        <span className="OrganizationButton__loader__icon" />
        <span className="OrganizationButton__loader__name" />
      </div>
    );
  }

  return (
    <>
      {isAdmin ? (
        <Button
          className={classNames("OrganizationButton", {
            "OrganizationButton--main": organization.isMain,
          })}
          buttonIcon={{ icon: organization.isMain ? "MainOrganization" : "Organization" }}
          buttonType="link-grey"
          buttonSize="medium"
          onClick={redirectToOrganization}
        >
          {organization.name}
        </Button>
      ) : (
        <p
          className={classNames("OrganizationText", {
            "OrganizationText--main": organization.isMain,
          })}
        >
          <Icon icon={organization.isMain ? "MainOrganization" : "Organization"} />
          {organization.name}
        </p>
      )}
    </>
  );
}

function AvatarComponent({
  avatarData,
  name,
  loading,
}: {
  avatarData: TableCardProps["avatarData"];
  name?: string | null;
  loading?: boolean;
}) {
  if (isAvatarData(avatarData)) {
    return (
      <Avatar
        size="medium"
        src={avatarData.src}
        avatarLoading={avatarData.loading || loading}
        firstName={name ?? "A"}
        avatarType={avatarData.avatarType}
      />
    );
  }

  return React.isValidElement(avatarData) ? avatarData : null;
}

export function TableCard({
  inactive,
  avatarData,
  name,
  description,
  organization,
  children,
  actionChildren,
  className,
  loading,
  ...props
}: PropsWithChildren<TableCardProps>) {
  const { isAdmin } = useAdditionalAuthInformationContext();

  return (
    <Card {...props} className={classNames("TableCard", className)}>
      {actionChildren ? <TableElements.Actions>{actionChildren}</TableElements.Actions> : null}
      <div className={classNames("TableCard__body", { "TableCard__body--inactive": inactive })}>
        <AvatarComponent avatarData={avatarData} name={name} loading={loading} />

        {name ? (
          <h4 className={classNames("TableCard__title", { "TableCard__title--loading": loading })}>
            {loading ? null : name}
          </h4>
        ) : null}

        {isValidElement(description) ? (
          <div className="TableCard__description">{description}</div>
        ) : null}

        <OrganizationComponent isAdmin={isAdmin} organization={organization} loading={loading} />

        {children}
      </div>
    </Card>
  );
}
