import { EmptyStateIcon } from "components/EmptyStateIcon";
import { GeneralSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/GeneralSectionDetails";
import { PatientSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/PatientSectionDetails";
import { SideDetails } from "components/MeasurementSideDetails/SideDetails/SideDetails";
import { CarnaApiQuery } from "config/apiQuery";
import { EmptyState } from "libs/ui";
import { isDefaultModel, isLoaded, isLoading } from "models/loadable";
import { useCallback, useEffect, useMemo, useState, useSyncExternalStore } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "utils/hooks/useApi";
import { useDeleteTest } from "utils/hooks/useDeleteTest";
import { useEditTest } from "utils/hooks/useEditTest";
import { avatarCache } from "utils/machines/avatars";
import { BaseTable } from "../Table/BaseTable";
import { BodyRows } from "./BodyRows";
import { resolveAppliedColumns } from "./constants";
import { TestsTableProps } from "./model";
import { TestsSideDetails } from "./TestsSideDetails";
import "./TestsTable.scss";
import { TestUnitType, hasMatchingItems, mapToSideDetails } from "./utils";

export function TestsTable({
  testsTableStateContext,
  appliedColumns,
  measurementType,
  measurementTypeItem,
  showTrend,
}: Readonly<TestsTableProps>) {
  const { tableService, fetch: fetchTableTests } = testsTableStateContext;
  const { t } = useTranslation("translation", { keyPrefix: "TestsTable" });
  const [state] = tableService;
  const [selectedTestUnit, setSelectedTestUnit] = useState<TestUnitType>();

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  const onSuccessCallback = useCallback(() => {
    fetchTableTests();
  }, [fetchTableTests]);

  const { changeStateClick, DeleteTestModalRef } = useDeleteTest(onSuccessCallback);
  const { onEditClick, EditTestSideModal, ConfirmationDeleteModal } =
    useEditTest(onSuccessCallback);

  const [testData, getTestData, resetTestData] = useApi(
    CarnaApiQuery.Test.getByOrganizationPatientId,
  );

  useEffect(() => {
    return () => {
      resetTestData();
    };
  }, [appliedColumns, resetTestData]);

  const noTableData = useMemo(
    () => state.matches("loaded") && !hasMatchingItems(state.context.data.items, measurementType),
    [measurementType, state],
  );

  const columns = useMemo(
    () =>
      resolveAppliedColumns({
        appliedColumns,
        t,
      }),
    [appliedColumns, t],
  );

  const testSideDetails = useMemo(
    () => (isLoaded(testData) ? mapToSideDetails(testData.value, selectedTestUnit) : undefined),
    [selectedTestUnit, testData],
  );

  if (noTableData) {
    return (
      <EmptyState title={t("emptyTitle")} description={t("emptyDescription")}>
        <EmptyStateIcon variant="Tests" />
      </EmptyState>
    );
  }

  return (
    <div className="TestsTableWrapper">
      <BaseTable tableService={tableService} className="TestsTable" columns={columns}>
        <BodyRows
          onRowClick={{
            getTest: getTestData,
            setSelectedTestUnit,
          }}
          tableService={tableService}
          appliedColumns={appliedColumns}
          activeDetailsId={
            testSideDetails
              ? `${testSideDetails?.test?.id}-${Object.keys(testSideDetails.measurement)[0]}`
              : undefined
          }
          measurementType={measurementType}
          measurementTypeItem={measurementTypeItem}
          onDeleteClick={changeStateClick}
          onEditClick={onEditClick}
        />
      </BaseTable>
      <SideDetails
        show={!isDefaultModel(testData)}
        title={isLoaded(testData) ? `#${testSideDetails?.test.testCountId}` : ""}
        onClose={() => resetTestData()}
      >
        <>
          <TestsSideDetails
            showTrend={showTrend}
            measurementTabType={measurementTypeItem ?? selectedTestUnit}
            sideDetails={testSideDetails}
            loading={isLoading(testData)}
          />

          <GeneralSectionDetails data={testSideDetails} loading={isLoading(testData)} />
          <PatientSectionDetails
            data={testSideDetails}
            loading={isLoading(testData)}
            selectedTestUnit={selectedTestUnit}
          />
        </>
      </SideDetails>
      {EditTestSideModal}
      {DeleteTestModalRef}
      {ConfirmationDeleteModal}
    </div>
  );
}
