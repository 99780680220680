export const SampledByDeviceType = {
  Neodocs: 1,
  NovaMax: 2,
  Allegro: 3,
} as const;
export type SampledByDeviceType = keyof typeof SampledByDeviceType;

export const DeviceType = {
  None: "None",
  NovaMaxProCreateEgfrMeter: "NovaMaxProCreateEgfrMeter",
  NovaBiomedicalAllegro: "NovaBiomedicalAllegro",
} as const;
export type DeviceType = (typeof DeviceType)[keyof typeof DeviceType];

export function getSampleByDevicePerDeviceType(type: DeviceType): number | undefined {
  switch (type) {
    case "NovaMaxProCreateEgfrMeter":
      return 2;
    case "NovaBiomedicalAllegro":
      return 3;
    case "None":
    default:
      throw new Error(`Device type ${type} not covered`);
  }
}
