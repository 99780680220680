import * as React from "react";
import { SVGProps } from "react";
const SvgMinimize = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.53 3.53a.75.75 0 0 0-1.06-1.06l-5.72 5.72V4a.75.75 0 0 0-1.5 0v6a.747.747 0 0 0 .75.75h6a.75.75 0 0 0 0-1.5h-4.19l5.72-5.72ZM10.693 13.713a.748.748 0 0 0-.158-.238l-.01-.01A.747.747 0 0 0 10 13.25H4a.75.75 0 0 0 0 1.5h4.19l-5.72 5.72a.75.75 0 1 0 1.06 1.06l5.72-5.72V20a.75.75 0 0 0 1.5 0v-6a.747.747 0 0 0-.057-.287Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMinimize;
