import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Body } from "./Body";
import "./EditableDetails.scss";
import { Footer } from "./Footer";
import { Group } from "./Group";
import { Head } from "./Head";
import { EditableDetailsProps } from "./model";

export function EditableDetails({
  onClose,
  title,
  editMode,
  cancel,
  save,
  isLoading,
  isLoaded,
  isSaving,
  className,
  children,
}: PropsWithChildren<EditableDetailsProps>) {
  return (
    <Group
      className={classNames("EditableDetails", className)}
      editMode={editMode}
      isLoading={isLoading}
      isLoaded={isLoaded}
    >
      <Head isLoading={isLoading ?? isSaving} onClose={onClose}>
        {title}
      </Head>
      <Body isLoading={isLoading}>{children}</Body>
      <Footer cancel={cancel} save={save} isSaving={isSaving} />
    </Group>
  );
}
