/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceModel } from './DeviceModel';
import {
    DeviceModelFromJSON,
    DeviceModelFromJSONTyped,
    DeviceModelToJSON,
} from './DeviceModel';
import type { TestedByModel } from './TestedByModel';
import {
    TestedByModelFromJSON,
    TestedByModelFromJSONTyped,
    TestedByModelToJSON,
} from './TestedByModel';
import type { UnitsModel } from './UnitsModel';
import {
    UnitsModelFromJSON,
    UnitsModelFromJSONTyped,
    UnitsModelToJSON,
} from './UnitsModel';

/**
 * 
 * @export
 * @interface MeasurementGraphModel
 */
export interface MeasurementGraphModel {
    /**
     * 
     * @type {UnitsModel}
     * @memberof MeasurementGraphModel
     */
    units: UnitsModel;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGraphModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGraphModel
     */
    measurementTime: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGraphModel
     */
    measurementUtcTime: string;
    /**
     * 
     * @type {DeviceModel}
     * @memberof MeasurementGraphModel
     */
    device?: DeviceModel;
    /**
     * 
     * @type {TestedByModel}
     * @memberof MeasurementGraphModel
     */
    testedBy?: TestedByModel;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGraphModel
     */
    testCountId: string;
}

/**
 * Check if a given object implements the MeasurementGraphModel interface.
 */
export function instanceOfMeasurementGraphModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "units" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "measurementTime" in value;
    isInstance = isInstance && "measurementUtcTime" in value;
    isInstance = isInstance && "testCountId" in value;

    return isInstance;
}

export function MeasurementGraphModelFromJSON(json: any): MeasurementGraphModel {
    return MeasurementGraphModelFromJSONTyped(json, false);
}

export function MeasurementGraphModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementGraphModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'units': UnitsModelFromJSON(json['Units']),
        'id': json['Id'],
        'measurementTime': json['MeasurementTime'],
        'measurementUtcTime': json['MeasurementUtcTime'],
        'device': !exists(json, 'Device') ? undefined : DeviceModelFromJSON(json['Device']),
        'testedBy': !exists(json, 'TestedBy') ? undefined : TestedByModelFromJSON(json['TestedBy']),
        'testCountId': json['TestCountId'],
    };
}

export function MeasurementGraphModelToJSON(value?: MeasurementGraphModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Units': UnitsModelToJSON(value.units),
        'Id': value.id,
        'MeasurementTime': value.measurementTime,
        'MeasurementUtcTime': value.measurementUtcTime,
        'Device': DeviceModelToJSON(value.device),
        'TestedBy': TestedByModelToJSON(value.testedBy),
        'TestCountId': value.testCountId,
    };
}

