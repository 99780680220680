import { Icon } from "libs/ui";
import { PropsWithChildren } from "react";
import { getMeasurementTrendIcon } from "utils/getMeasurementTrendIcon";
import { useTranslation } from "react-i18next";
import { DetailsField } from "../DetailsField";
import { DetailsFieldDescription } from "../DetailsFieldDescription";
import "./TrendDetailsField.scss";
import { MeasurementTrendType } from "models/TestModels";

interface TrendDetailsFieldProps {
  trendType?: MeasurementTrendType;
  loading?: boolean;
  label?: string;
  percent?: number;
  show?: boolean;
}

const Percent = ({ percent }: { percent?: number }) =>
  percent ? <span>{(percent ?? 0).toString().concat("%")}</span> : null;

export function TrendDetailsField({
  trendType,
  loading,
  label,
  percent,
  show = true,
}: PropsWithChildren<TrendDetailsFieldProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.TrendDetailsField" });

  if (!!show === false) {
    return null;
  }

  // Hide trend if it's the first measurement
  if (trendType === "None") {
    return null;
  }

  return (
    <DetailsField loading={loading} label={label ?? t("label")}>
      <div className="TrendDetailsField__value">
        <Icon icon={getMeasurementTrendIcon(trendType)} />
        <Percent percent={percent} />
      </div>

      <DetailsFieldDescription>{t(`TrendingDetails-${trendType}`)}</DetailsFieldDescription>
    </DetailsField>
  );
}
