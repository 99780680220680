import classNames from "classnames";
import { getIsInRange, getRange } from "../helper";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { RangeTableCell } from "../RangeTableCell";
import "./RowHorizontal.scss";

interface RowHorizontalProps {
  label: string;
  from?: number;
  to?: number;
  value?: number;
  active?: boolean;
}

export function RowHorizontal({ label, from, to, value, active }: Readonly<RowHorizontalProps>) {
  const { numberFormatter } = useLocaleNumberFormatter();

  if (from === undefined && to === undefined) {
    throw new Error("You must provide at least one param");
  }

  return (
    <tr
      className={classNames("RowHorizontal", {
        "RowHorizontal--active": getIsInRange(value, from, to) || active,
      })}
    >
      <RangeTableCell className="RangeTableCell--label">{label}</RangeTableCell>
      <RangeTableCell className="RangeTableCell--range">
        {getRange(
          numberFormatter({
            value: from,
            additionalOptions: {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
              roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
            },
          }),
          numberFormatter({
            value: to,
            additionalOptions: {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
              roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
            },
          }),
        )}
      </RangeTableCell>
    </tr>
  );
}
