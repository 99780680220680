/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProfileResponseModel,
} from '../models/index';
import {
    ProfileResponseModelFromJSON,
    ProfileResponseModelToJSON,
} from '../models/index';

export interface ProfileGetRequest {
    acceptVersion?: string;
}

/**
 * ProfileApi - interface
 * 
 * @export
 * @interface ProfileApiInterface
 */
export interface ProfileApiInterface {
    /**
     * 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApiInterface
     */
    profileGetRaw(requestParameters: ProfileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileResponseModel>>;

    /**
     */
    profileGet(requestParameters: ProfileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileResponseModel>;

}

/**
 * 
 */
export class ProfileApi extends runtime.BaseAPI implements ProfileApiInterface {

     /**
     */
    async profileGetRawOriginal(requestParameters: ProfileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async profileGetRaw(requestParameters: ProfileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async profileGet(requestParameters: ProfileGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileResponseModel> {
        const response = await this.profileGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
