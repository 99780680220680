export function getRange(from?: string, to?: string) {
  if (from && to === "NaN") {
    return `≥ ${from}`;
  }

  if (from === "NaN" && to) {
    return `< ${to}`;
  }

  return `${from} - ${to}`;
}

export function getIsInRange(value?: number, from?: number, to?: number) {
  if (!value) {
    return false;
  }

  if (from && to === undefined) {
    return value > from;
  }

  if (from === undefined && to) {
    return value < to;
  }

  return (from ?? 0) <= value && (to ?? 0) >= value;
}
