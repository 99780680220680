import { MeasurementItemType } from "api/event";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { MeasurementTypes } from "models/TestModels";
import { usePatientLaboratoryContext } from "pages/PatientsPage/Details/LaboratoryContext";
import { TestsTable } from "../common/TestsTable";
import { TestsTableColumns } from "../common/TestsTable/model";

interface TestsPerPatientTableProps {
  appliedColumns: TestsTableColumns[];
  measurementType?: MeasurementTypes;
  measurementTypeItem?: MeasurementItemType;
}

export function TestsPerPatientTable({
  appliedColumns,
  measurementType,
  measurementTypeItem,
}: TestsPerPatientTableProps) {
  const tableContext = usePatientLaboratoryContext();
  const { appConfig } = useGlobalConfigContext();

  return (
    <TestsTable
      showTrend={appConfig?.components.tables.Laboratory.Patient.showTrending}
      testsTableStateContext={tableContext}
      appliedColumns={appliedColumns}
      measurementType={measurementType}
      measurementTypeItem={measurementTypeItem}
    />
  );
}
