import * as React from "react";
import { SVGProps } from "react";
const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 10.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0v-6a.75.75 0 0 1 .75-.75ZM10.75 11a.75.75 0 0 0-1.5 0v6a.75.75 0 0 0 1.5 0v-6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 5.25V4A2.75 2.75 0 0 1 10 1.25h4A2.75 2.75 0 0 1 16.75 4v1.25H21a.75.75 0 0 1 0 1.5h-1.25V20A2.75 2.75 0 0 1 17 22.75H7A2.75 2.75 0 0 1 4.25 20V6.75H3a.75.75 0 0 1 0-1.5h4.25Zm1.866-2.134A1.25 1.25 0 0 1 10 2.75h4A1.25 1.25 0 0 1 15.25 4v1.25h-6.5V4c0-.332.132-.65.366-.884ZM5.75 6.75V20A1.25 1.25 0 0 0 7 21.25h10A1.25 1.25 0 0 0 18.25 20V6.75H5.75Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgDelete;
