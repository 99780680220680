import classNames from "classnames";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { resolveUserRole } from "../../utils";
import "./CurrentUserRole.scss";

export function CurrentUserRole() {
  const { userRole, isPatient } = useAdditionalAuthInformationContext();

  if (isPatient) {
    return null;
  }

  return <p className={classNames("CurrentUserRole")}>{resolveUserRole(userRole)}</p>;
}
