import { DashboardMapsGroupBy } from "api/report_service/models/DashboardMapsGroupBy";
import { IconType } from "libs/ui";
import { Select } from "libs/ui/Select";
import { isDefaultModel, isFailed, isLoaded, isLoading } from "models/loadable";
import { Loader } from "pages/DashboardPage/Loader";
import { useTranslation } from "react-i18next";
import { useDashboardMapReportContext } from "../DashboardMapReportDataContext";
import { LeftSideReportTypes } from "../DashboardMapReportDataContext.model";
import { FailedToRetrieveData } from "../FailedToRetrieveData";
import "./DashboardMapAside.scss";
import { TotalNumbers } from "./TotalNumbers";
import { EntityChart } from "./EntityChart";

interface DashboardMapAsideProps {
  groupingOption: DashboardMapsGroupBy;
}

export function DashboardMapAside({ groupingOption }: DashboardMapAsideProps) {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.DashboardMapAside" });
  const { reportData, onSelectReportTypeChange, selectedReportType } =
    useDashboardMapReportContext();

  const options = [
    {
      title: t("patientsWithCKD"),
      value: "patientWithCDK" as LeftSideReportTypes,
      icon: "CreatinineEgfr" as IconType,
    },
    {
      title: t("patientsWithEGfr_60"),
      value: "patientWithEGFR" as LeftSideReportTypes,
      icon: "CreatinineEgfr" as IconType,
    },
  ] as const;

  return (
    <div className="DashboardMapAside">
      <Select
        value={selectedReportType}
        onSelect={onSelectReportTypeChange}
        className="DashboardMapAside__select"
        options={options}
      />

      <div className="DashboardMapAside__result">
        <div className="DashboardMapAside__chart">
          {isLoading(reportData.ChartData) || isDefaultModel(reportData.ChartData) ? (
            <Loader />
          ) : null}
          {isLoaded(reportData.ChartData) ? <EntityChart groupingOption={groupingOption} /> : null}
          {isFailed(reportData.ChartData) ? <FailedToRetrieveData /> : null}
        </div>

        <div className="DashboardMapAside__total">
          {isLoading(reportData.TotalNumbers) || isDefaultModel(reportData.TotalNumbers) ? (
            <Loader />
          ) : null}
          {isLoaded(reportData.TotalNumbers) ? (
            <TotalNumbers
              totalPatients={reportData.TotalNumbers.value?.totalNumberOfPatients ?? 0}
              patientWithRisk={reportData.TotalNumbers.value?.totalNumberOfPatientsWithRisk ?? 0}
              type={selectedReportType}
            />
          ) : null}
          {isFailed(reportData.TotalNumbers) ? <FailedToRetrieveData /> : null}
        </div>
      </div>
    </div>
  );
}
