import { FilterSection } from "components/AppTables/common/Filter/FilterSection";
import { TableFilter } from "components/AppTables/common/Filter/TableFilter";
import { useTranslation } from "react-i18next";
import { UserStatusFilter } from "../../Filter/UserStatusFilter";
import { PartnersTableFilterProps, PARTNERS_FILTER_OPTIONS } from "../model";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import React from "react";
import { StatesFilterSection } from "../../Filter/StatesFilterSection";
import { DropDownToggler } from "libs/ui/DropDownToggler";
import { SideModalElements } from "libs/ui/SideModal";

export function PartnersTableFilter({
  filterSideModelState,
  tableService,
}: Readonly<PartnersTableFilterProps>) {
  const { t: tColumns } = useTranslation("translation", {
    keyPrefix: "AdministratorsTable.columns",
  });
  const { t: tCommonTable } = useTranslation("translation", { keyPrefix: "commonTable" });

  const { appConfig } = useGlobalConfigContext();

  return (
    <TableFilter tableService={tableService} filterSideModelState={filterSideModelState}>
      {({ activeFilters, appliedFilters, onNewFilterApplied, onSingleFilterClear }) => (
        <>
          {activeFilters.map((el, index) => {
            switch (el) {
              case "contains":
              case "states":
                return null;
              case "countries":
                return (
                  <React.Fragment key={el}>
                    <DropDownToggler
                      isExpandedOnInit={(appliedFilters[el] ?? []).length > 0}
                      title={tColumns("countries")}
                      expandChildrenCount={5}
                    >
                      <FilterSection
                        options={PARTNERS_FILTER_OPTIONS()["countries"]}
                        checkedValues={appliedFilters["countries"] ?? []}
                        onFilterApply={value => onNewFilterApplied("countries", value)}
                        withSearch={true}
                      />

                      <StatesFilterSection
                        checkedValues={appliedFilters["states"] ?? []}
                        countries={appliedFilters["countries"] ?? []}
                        onFilterApply={value => onNewFilterApplied("states", value)}
                        onSingleFilterClear={() => onSingleFilterClear("states")}
                      />

                      {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
                    </DropDownToggler>
                  </React.Fragment>
                );

              case "userStatuses":
                return (
                  <React.Fragment key={el}>
                    <DropDownToggler
                      isExpandedOnInit={(appliedFilters[el] ?? []).length > 0}
                      title={tCommonTable("status")}
                      expandChildrenCount={5}
                    >
                      <UserStatusFilter
                        includeDeletedUsers={appConfig?.general.includeDeletedUsers}
                        values={appliedFilters[el] ?? []}
                        onFilterApply={value => onNewFilterApplied(el, value)}
                      />
                    </DropDownToggler>

                    {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
                  </React.Fragment>
                );

              default:
                return (
                  <React.Fragment key={el}>
                    <DropDownToggler
                      isExpandedOnInit={((appliedFilters[el] as Array<any>) ?? []).length > 0}
                      title={tColumns(el)}
                      expandChildrenCount={5}
                    >
                      <FilterSection
                        title={tColumns(el)}
                        options={PARTNERS_FILTER_OPTIONS()[el]}
                        checkedValues={appliedFilters[el] ?? []}
                        onFilterApply={value => onNewFilterApplied(el, value)}
                      />
                    </DropDownToggler>
                  </React.Fragment>
                );
            }
          })}
        </>
      )}
    </TableFilter>
  );
}
