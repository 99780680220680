import { useCallback, useEffect } from "react";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { FormFields } from "./FormFields";
import { TestFormModel, TestFormModelKey, TestFormProps } from "./model";

export function Test({ testData, setTestData, onSubmit, editableFields, person }: TestFormProps) {
  const { organizationId } = useAdditionalAuthInformationContext();

  const onChange = useCallback(
    (
      value:
        | string
        | ((prevValue: TestFormModel) => TestFormModel["measurements"])
        | undefined
        | null,
      inputKey: TestFormModelKey,
    ) => {
      setTestData(prevValue => ({
        ...prevValue,
        [inputKey]: typeof value === "function" ? value(prevValue) : value,
      }));
    },
    [setTestData],
  );

  useEffect(() => {
    if (person?.id) {
      setTestData(prevValue => ({
        ...prevValue,
        patientId: person?.id,
      }));
    }
  }, [person?.id, setTestData]);

  useEffect(() => {
    if (!testData.organizationId) {
      setTestData(prevValue => ({
        ...prevValue,
        organizationId: person?.organizationId ?? organizationId,
      }));
    }
  }, [organizationId, person?.organizationId, setTestData, testData.organizationId]);

  return (
    <FormFields
      onChange={onChange}
      onSubmit={onSubmit}
      testData={testData}
      editableFields={editableFields}
      person={person}
    />
  );
}
