import * as React from "react";
import { SVGProps } from "react";
const SvgArchive = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.25 12a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2.25A.75.75 0 0 0 .25 3v5c0 .414.336.75.75.75h1.25V21c0 .414.336.75.75.75h18a.75.75 0 0 0 .75-.75V8.75H23a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75H1Zm21.25 5v-3.5H1.75v3.5h20.5Zm-18.5 1.5h16.5v11.5H3.75V8.75Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArchive;
