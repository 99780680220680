/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import type { DeviceType } from './DeviceType';
import {
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';
import type { UserStatusType } from './UserStatusType';
import {
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface HcpResponseModel
 */
export interface HcpResponseModel {
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof HcpResponseModel
     */
    shortId: number;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    city: string;
    /**
     * 
     * @type {CountryType}
     * @memberof HcpResponseModel
     */
    country: CountryType;
    /**
     * 
     * @type {number}
     * @memberof HcpResponseModel
     */
    state?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    organizationName: string;
    /**
     * 
     * @type {boolean}
     * @memberof HcpResponseModel
     */
    isMainOrganization?: boolean | null;
    /**
     * 
     * @type {UserStatusType}
     * @memberof HcpResponseModel
     */
    status: UserStatusType;
    /**
     * 
     * @type {UserRoleType}
     * @memberof HcpResponseModel
     */
    role: UserRoleType;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    rowVersion: string;
    /**
     * 
     * @type {boolean}
     * @memberof HcpResponseModel
     */
    isAccountOwner: boolean;
    /**
     * 
     * @type {number}
     * @memberof HcpResponseModel
     */
    hcpType?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    deviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    deviceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    deviceSerialNumber?: string | null;
    /**
     * 
     * @type {DeviceType}
     * @memberof HcpResponseModel
     */
    deviceType?: DeviceType;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    deviceUniqueId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpResponseModel
     */
    deviceRowVersion?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof HcpResponseModel
     */
    additionalProperties: { [key: string]: string; };
}

/**
 * Check if a given object implements the HcpResponseModel interface.
 */
export function instanceOfHcpResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "shortId" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "organizationName" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "rowVersion" in value;
    isInstance = isInstance && "isAccountOwner" in value;
    isInstance = isInstance && "additionalProperties" in value;

    return isInstance;
}

export function HcpResponseModelFromJSON(json: any): HcpResponseModel {
    return HcpResponseModelFromJSONTyped(json, false);
}

export function HcpResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HcpResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'shortId': json['ShortId'],
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'email': json['Email'],
        'phone': json['Phone'],
        'street': json['Street'],
        'city': json['City'],
        'country': CountryTypeFromJSON(json['Country']),
        'state': !exists(json, 'State') ? undefined : json['State'],
        'zipCode': !exists(json, 'ZipCode') ? undefined : json['ZipCode'],
        'organizationId': json['OrganizationId'],
        'organizationName': json['OrganizationName'],
        'isMainOrganization': !exists(json, 'IsMainOrganization') ? undefined : json['IsMainOrganization'],
        'status': UserStatusTypeFromJSON(json['Status']),
        'role': UserRoleTypeFromJSON(json['Role']),
        'rowVersion': json['RowVersion'],
        'isAccountOwner': json['IsAccountOwner'],
        'hcpType': !exists(json, 'HcpType') ? undefined : json['HcpType'],
        'deviceId': !exists(json, 'DeviceId') ? undefined : json['DeviceId'],
        'deviceName': !exists(json, 'DeviceName') ? undefined : json['DeviceName'],
        'deviceSerialNumber': !exists(json, 'DeviceSerialNumber') ? undefined : json['DeviceSerialNumber'],
        'deviceType': !exists(json, 'DeviceType') ? undefined : DeviceTypeFromJSON(json['DeviceType']),
        'deviceUniqueId': !exists(json, 'DeviceUniqueId') ? undefined : json['DeviceUniqueId'],
        'deviceRowVersion': !exists(json, 'DeviceRowVersion') ? undefined : json['DeviceRowVersion'],
        'additionalProperties': json['AdditionalProperties'],
    };
}

export function HcpResponseModelToJSON(value?: HcpResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ShortId': value.shortId,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Email': value.email,
        'Phone': value.phone,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': value.state,
        'ZipCode': value.zipCode,
        'OrganizationId': value.organizationId,
        'OrganizationName': value.organizationName,
        'IsMainOrganization': value.isMainOrganization,
        'Status': UserStatusTypeToJSON(value.status),
        'Role': UserRoleTypeToJSON(value.role),
        'RowVersion': value.rowVersion,
        'IsAccountOwner': value.isAccountOwner,
        'HcpType': value.hcpType,
        'DeviceId': value.deviceId,
        'DeviceName': value.deviceName,
        'DeviceSerialNumber': value.deviceSerialNumber,
        'DeviceType': DeviceTypeToJSON(value.deviceType),
        'DeviceUniqueId': value.deviceUniqueId,
        'DeviceRowVersion': value.deviceRowVersion,
        'AdditionalProperties': value.additionalProperties,
    };
}

