import classNames from "classnames";
import { PropsWithChildren } from "react";
import { TableUtils } from "../../utils";
import { CellProps } from "../model";
import "./Cell.scss";

export function Cell({
  show = true,
  onClick,
  className,
  children,
  loading,
  ...props
}: PropsWithChildren<CellProps>) {
  if (!show) {
    return null;
  }

  return (
    <td
      {...props}
      onClick={onClick}
      className={classNames("Cell", onClick && "Cell--clickable", className)}
    >
      <div className="Cell__content">
        {loading ? <div className="Cell__loader"></div> : TableUtils.resolveValue(children)}
      </div>
    </td>
  );
}
