/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';

/**
 * 
 * @export
 * @interface ReportUserModel
 */
export interface ReportUserModel {
    /**
     * 
     * @type {number}
     * @memberof ReportUserModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReportUserModel
     */
    entityId: string;
    /**
     * 
     * @type {string}
     * @memberof ReportUserModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ReportUserModel
     */
    lastName: string;
    /**
     * 
     * @type {UserRoleType}
     * @memberof ReportUserModel
     */
    role: UserRoleType;
}

/**
 * Check if a given object implements the ReportUserModel interface.
 */
export function instanceOfReportUserModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "entityId" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function ReportUserModelFromJSON(json: any): ReportUserModel {
    return ReportUserModelFromJSONTyped(json, false);
}

export function ReportUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'entityId': json['EntityId'],
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'role': UserRoleTypeFromJSON(json['Role']),
    };
}

export function ReportUserModelToJSON(value?: ReportUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'EntityId': value.entityId,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Role': UserRoleTypeToJSON(value.role),
    };
}

