import "./ErrorButton.scss";
import { PropsWithChildren } from "react";
interface ErrorButtonProps {
  onClick: () => void;
}

export function ErrorButton({ onClick, children, ...props }: PropsWithChildren<ErrorButtonProps>) {
  return (
    <button {...props} onClick={onClick} className="ErrorButton">
      {children}
    </button>
  );
}
