import { MeasurementItemType } from "api/event";
import { TestsTable } from "../common/TestsTable";
import { TestsTableColumns } from "../common/TestsTable/model";
import { useTestsTableStateContext } from "./context";
import { MeasurementTypes } from "models/TestModels";
import { useGlobalConfigContext } from "context/GlobalConfigContext";

interface AllTestsTableProps {
  appliedColumns: TestsTableColumns[];
  title?: string;
  measurementType?: MeasurementTypes;
  measurementTypeItem?: MeasurementItemType;
}

export function AllTestsTable({
  appliedColumns,
  title,
  measurementType,
  measurementTypeItem,
}: AllTestsTableProps) {
  const tableContext = useTestsTableStateContext();
  const { appConfig } = useGlobalConfigContext();

  return (
    <TestsTable
      showTrend={appConfig?.components.tables.Laboratory.Index.showTrending}
      appliedColumns={appliedColumns}
      title={title}
      testsTableStateContext={tableContext}
      measurementType={measurementType}
      measurementTypeItem={measurementTypeItem}
    />
  );
}
