import { RegionType, TimeZoneType } from "api/query";
import { FormFieldBasic } from "../models";
import { createTheme } from "@mui/material";

type DatePicker = "date" | "dateTime";

export interface DatePickerProps extends FormFieldBasic {
  value: Date;
  label: string;
  disabled: boolean;
  onSelect: (value: Date) => void;
  onBlur: () => void;
  disableFuture?: boolean;
  formatter: (
    value: string | Date,
    region?: RegionType | undefined,
    timeZone?: TimeZoneType | undefined,
  ) => string;
  pickerVariant?: DatePicker;
}

export const MUITheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    primary: {
      main: "#8352fe",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.15)",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ ownerState: { className } }) => {
          const isSelected = className?.includes("Mui-selected");

          return {
            ...(isSelected && {
              backgroundColor: "var(--primary-500) !important",
            }),
          };
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: "translate(0, 10px) scale(1)",
          color: "var(--grey-500)",
          left: "12px",
          top: "4px",
          "&.Mui-error": {
            color: "var(--grey-500)",
          },
          "&.Mui-focused, &.MuiFormLabel-filled": {
            transform: "translate(0, -12px) scale(0.75)",
          },
          "&.Mui-focused": {
            color: "var(--primary-500)",
          },
          "&.Mui-disabled": {
            color: "var(--grey-500)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...{ borderRadius: "8px" },
          ...(ownerState?.inputProps?.readOnly
            ? {
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid var(--grey-300)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid var(--grey-300)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid var(--grey-300)",
                  },
              }
            : {
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid var(--grey-200)",
                },
                "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid var(--grey-200)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid var(--grey-200)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid var(--primary-500)",
                  },
                "&.Mui-disabled .MuiOutlinedInput-input": {
                  WebkitTextFillColor: "var(--grey-600)",
                },
              }),
        }),
        input: {
          padding: "12px",
          color: "var(--grey-500)",
          height: "24px",
        },
        notchedOutline: ({ ownerState }) => ({
          ...{ border: "1px solid var(--grey-300)" },
          ...(!ownerState?.inputProps?.readOnly && {
            border: "1px solid var(--grey-200)",
          }),
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "var(--grey-400)",
          borderRadius: "0 8px 8px 0",
        },
      },
    },
  },
});
