import { useTranslation } from "react-i18next";
import "./FailedToRetrieveData.scss";

export function FailedToRetrieveData() {
  const { t } = useTranslation("translation", {
    keyPrefix: "DashboardPage.FailedToRetrieveData",
  });

  return <div className="FailedToRetrieveData">{t("title")}</div>;
}
