import * as React from "react";
import { SVGProps } from "react";
const SvgGps = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#gps_svg__a)" fill="currentColor">
      <path d="M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 1a.75.75 0 0 0-1.5 0v2.282a8.752 8.752 0 0 0-7.968 7.968H1a.75.75 0 0 0 0 1.5h2.282a8.752 8.752 0 0 0 7.968 7.968V23a.75.75 0 0 0 1.5 0v-2.282a8.752 8.752 0 0 0 7.968-7.968H23a.75.75 0 0 0 0-1.5h-2.282a8.752 8.752 0 0 0-7.968-7.968V1ZM12 4.75a7.25 7.25 0 1 0 0 14.5 7.25 7.25 0 0 0 0-14.5Z"
      />
    </g>
    <defs>
      <clipPath id="gps_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGps;
