import * as React from "react";
import { SVGProps } from "react";
const SvgLayout = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 5A2.75 2.75 0 0 1 5 2.25h14A2.75 2.75 0 0 1 21.75 5v14A2.75 2.75 0 0 1 19 21.75H5A2.75 2.75 0 0 1 2.25 19V5ZM5 3.75c-.69 0-1.25.56-1.25 1.25v3.25h16.5V5c0-.69-.56-1.25-1.25-1.25H5Zm15.25 6H9.75v10.5H19c.69 0 1.25-.56 1.25-1.25V9.75Zm-12 0h-4.5V19c0 .69.56 1.25 1.25 1.25h3.25V9.75Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLayout;
