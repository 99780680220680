import { UACRLimitKey } from "utils/hooks/useGetUACRRanges";
import { HeatRowProps } from "../model";
import { HeatResultCell } from "../HeatResultCell";

export function HeatResultRow({ ...props }: HeatRowProps) {
  const cellKeys = Object.keys(UACRLimitKey) as UACRLimitKey[];

  return (
    <tr>
      {cellKeys.map(key => (
        <HeatResultCell key={key} cellKey={key} {...props} />
      ))}
    </tr>
  );
}
