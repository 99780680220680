import { UserGraphModel } from "api/query";
import CarnaCandlestickChart from "components/Chart/CarnaChart/CandlestickChart";
import { ECOption, Formatters, ZoomLevels } from "components/Chart/model";
import { BLOOD_PRESSURE_INTERVAL_GRAPH_VALUE, BLOOD_PRESSURE_MAX_GRAPH_VALUE } from "config/const";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { Suspense } from "react";
import { getOptions as getInitialOptions } from "./model";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
// const CarnaCandlestickChart = React.lazy(
//   () => import("components/Chart/CarnaChart/CandlestickChart"),
// );

export function BloodPressure() {
  const { appConfig } = useGlobalConfigContext();
  const { bloodPressureUnit } = useGetUserPreferredMeasurementUnits();

  const getOptions = (
    formatters: Formatters,
    graphUser: UserGraphModel,
    currentSelectedZoomLevel?: ZoomLevels,
  ): ECOption =>
    getInitialOptions({
      formatters,
      graphUser,
      zoomLevel: currentSelectedZoomLevel,
      showTrending: appConfig?.components.graphs.Patient.Index.showTrending,
      yAxis: { max: BLOOD_PRESSURE_MAX_GRAPH_VALUE, interval: BLOOD_PRESSURE_INTERVAL_GRAPH_VALUE },
    });

  const timeRangeChange = (zoomLevel: ZoomLevels | undefined, action: () => void) => {
    if (zoomLevel === "year") {
      action();
    }
  };

  return (
    <Suspense fallback={null}>
      <CarnaCandlestickChart
        showTrend={appConfig?.components.graphs.Patient.Index.showTrending}
        unitType="bloodPressure"
        dataType={"BloodPressure"}
        className="BloodPressure"
        getOptions={getOptions}
        onTimeRangeChangeCb={timeRangeChange}
        measurementUnit={bloodPressureUnit}
      />
    </Suspense>
  );
}

export default BloodPressure;
