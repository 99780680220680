import { MeasurementUnitType, UserGraphModel } from "api/query";
import { add, formatISO, getDaysInMonth, getMonth, getYear } from "date-fns";
import { max as _max, min as _min } from "lodash-es";
import { CandleStickChartData, SortedBloodPressureDataType } from "./model";
import { Formatters } from "components/Chart/model";
import { TopLevelFormatterParams } from "echarts/types/dist/shared.js";

export const mapBloodPressureDataToGraphData = (
  graphUser: UserGraphModel,
): Array<CandleStickChartData> => {
  return graphUser.measurements.map(el => [
    el.measurementTime,
    el.units.bloodPressure?.systolic.value ?? 0,
    el.units.bloodPressure?.diastolic.value ?? 0,
    el.units.bloodPressure?.diastolic.value ?? 0,
    el.units.bloodPressure?.systolic.value ?? 0,
  ]);
};

export function getMonthlyMinMaxChartData(
  data: Record<string, number[]>,
): Array<CandleStickChartData> {
  return Object.entries(data).map(el => {
    const max = _max(el[1]) ?? 0;
    const min = _min(el[1]) ?? 0;
    return [el[0], max, min, min, max];
  });
}

export function mapBloodPressureDataToYearlyGraphData(
  candleStickChart: CandleStickChartData[][],
): CandleStickChartData[][] {
  return candleStickChart.map(CandleStickBars =>
    CandleStickBars.map(barData => {
      const [date, max, min] = barData;
      if (min === max) {
        // If the min and max values are equal, that bar will not be visible on the candlestick chart.
        // That's why we add and subtract 1 from min and max to make it visible.
        // This happens, eg. when we have one measurement or several measurements with the same result in a month.
        return [date, max + 1, min - 1, min - 1, max + 1];
      }
      return barData;
    }),
  );
}

export function mapBloodPressureDataToYearlyData(data: Array<CandleStickChartData>) {
  const sortedBloodPressureCandleStickData = {
    systolic: {},
    diastolic: {},
  } as SortedBloodPressureDataType;

  data.forEach(el => {
    const itemDate = new Date(el[0]);
    const groupDate = formatISO(new Date(getYear(itemDate), getMonth(itemDate), 1), {
      representation: "date",
    });

    if (!Object.hasOwn(sortedBloodPressureCandleStickData.systolic, groupDate)) {
      sortedBloodPressureCandleStickData.systolic[groupDate] = [];
    }

    if (!Object.hasOwn(sortedBloodPressureCandleStickData.diastolic, groupDate)) {
      sortedBloodPressureCandleStickData.diastolic[groupDate] = [];
    }

    sortedBloodPressureCandleStickData.systolic[groupDate].push(el[1]);
    sortedBloodPressureCandleStickData.diastolic[groupDate].push(el[2]);
  });

  return [
    getMonthlyMinMaxChartData(sortedBloodPressureCandleStickData.systolic),
    getMonthlyMinMaxChartData(sortedBloodPressureCandleStickData.diastolic),
  ];
}

export function tooltipYearlyBloodPressureFormatter(
  params: TopLevelFormatterParams,
  formatters: Formatters,
  data: CandleStickChartData[][],
  measurementUnit: MeasurementUnitType,
) {
  const [dateFormatter] = formatters;
  const currentParams = Array.isArray(params) ? (params as any)[0] : (params as any);

  if (currentParams.componentType === "series") {
    const [date]: [string, number] = currentParams.data;
    const currentDate = new Date(date);
    const currentDateFirstInMonth = new Date(getYear(currentDate), getMonth(currentDate));
    const daysInMonth = getDaysInMonth(currentDateFirstInMonth);

    const dateStr = dateFormatter(currentDateFirstInMonth);
    const endDateStr = dateFormatter(add(currentDateFirstInMonth, { days: daysInMonth - 1 }));

    const root = document.createElement("div");
    root.classList.add("CarnaTooltip", "CarnaTooltip--wide");
    root.style.backgroundColor = "#8352FE";
    root.innerHTML = `
    <p class="CarnaTooltip__label">Systolic</p>
    <h3 class="CarnaTooltip__measurement">${data[0][currentParams.dataIndex][2]}-${
      data[0][currentParams.dataIndex][1]
    }</h3>
    <p class="CarnaTooltip__label">Diastolic</p>
    <h3 class="CarnaTooltip__measurement">${data[1][currentParams.dataIndex][2]}-${
      data[1][currentParams.dataIndex][1]
    }</h3>
    `;
    root.innerHTML += `
        <p class="CarnaTooltip__measurementUnit">${measurementUnit}</p>
        <p class="CarnaTooltip__time">${dateStr}-<br>${endDateStr}</p>
        `;
    return root;
  }
  return "";
}
