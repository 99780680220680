import * as React from "react";
import { SVGProps } from "react";
const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.28 9.28a.75.75 0 1 0-1.06-1.06l-5.5 5.5-2.44-2.44a.75.75 0 0 0-1.06 1.06l2.97 2.97a.75.75 0 0 0 1.06 0l6.03-6.03Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25ZM2.75 12a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCheckCircle;
