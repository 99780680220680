import { PropsWithChildren } from "react";
import { Logo } from "libs/ui";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Copyright } from "components/Copyright";

export default function AuthenticatorWrapper({ children }: PropsWithChildren) {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  if (authStatus === "unauthenticated") {
    return (
      <div className="LogoContentCopyrightLayout">
        <Logo className="LoginLogo" />
        {children}
        <Copyright />
      </div>
    );
  }
  return <>{children}</>;
}
