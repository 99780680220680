import { SideModalElements } from "libs/ui";
import { useTranslation } from "react-i18next";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { SectionDetailsProps } from "../model";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { ColorValue } from "../../elements/ColorValue";
import { DetailsField } from "../../elements/DetailsField";
import { TrendDetailsField } from "../../elements/TrendDetailsField";

interface BloodPressureSectionDetailsProps extends SectionDetailsProps {}

export function BloodPressureSectionDetails({
  data,
  loading,
  showTrend,
}: BloodPressureSectionDetailsProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "Graph.Details.BloodPressureSectionDetails",
  });
  const { numberFormatter } = useLocaleNumberFormatter();
  const { bloodPressureUnit } = useGetUserPreferredMeasurementUnits();

  return (
    <div className="BloodPressureSectionDetails">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={t("labelSystolic")}>
        <ColorValue colorType="white">
          {numberFormatter({ value: data?.measurement?.BloodPressure?.Systolic.Value ?? 0 }).concat(
            ` ${bloodPressureUnit}`,
          )}
        </ColorValue>
      </DetailsField>

      <DetailsField loading={loading} label={t("labelDiastolic")}>
        <ColorValue colorType="white">
          {numberFormatter({
            value: data?.measurement?.BloodPressure?.Diastolic.Value ?? 0,
          }).concat(` ${bloodPressureUnit}`)}
        </ColorValue>
      </DetailsField>

      <TrendDetailsField
        show={showTrend}
        loading={loading}
        trendType={data?.measurement?.BloodPressure?.Systolic.TrendType}
        label={t("labelSystolicTrend")}
        percent={data?.measurement?.BloodPressure?.Systolic.Percent}
      />

      <TrendDetailsField
        loading={loading}
        trendType={data?.measurement?.BloodPressure?.Diastolic.TrendType}
        label={t("labelDiastolicTrend")}
        percent={data?.measurement?.BloodPressure?.Diastolic.Percent}
      />
    </div>
  );
}
