import { AddModalForwardedProps } from "components/AddModal/model";
import { EmptyState, Page } from "libs/ui";
import React, { PropsWithChildren, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Controls } from "./Controls/Controls";
import { EmptyStateIcon } from "../../EmptyStateIcon";
import { ParentPageTemplateProps } from "./model";
import { PageControls } from "./PageControls";

export function ParentPage({
  variant,
  AddModal,
  isEmptyState,
  isTableFailed,
  isTableLoading,
  onFilterToggle,
  searchField,
  className,
  breadCrumbs,
  filtersCount,
  onRefreshTable,
  onDownloadReport,
  showDescription = true,
  children,
}: PropsWithChildren<ParentPageTemplateProps>) {
  const { t } = useTranslation("translation", { keyPrefix: `ParentPage.${variant}` });
  const addModalRef = useRef<AddModalForwardedProps>(null);

  const toggle = () => addModalRef?.current?.toggleModal();

  return (
    <Page
      {...(breadCrumbs ? { breadCrumbs } : { title: t("title") })}
      className={className}
      controls={
        <PageControls
          isEmptyState={isEmptyState}
          isTableFailed={isTableFailed}
          isTableLoading={isTableLoading}
          onRefreshTable={onRefreshTable}
        >
          <Controls
            {...(!!AddModal && { openAddModal: toggle })}
            onFilterToggle={onFilterToggle}
            searchField={searchField}
            variant={variant}
            filtersCount={filtersCount}
            onRefreshTable={onRefreshTable}
            onDownloadReport={onDownloadReport}
          />
        </PageControls>
      }
    >
      {isEmptyState ? (
        <EmptyState
          {...(!!AddModal && { onCreate: toggle })}
          title={t("emptyTitle")}
          description={showDescription ? t("emptyDescription") : undefined}
        >
          <EmptyStateIcon variant={variant} />
        </EmptyState>
      ) : (
        children
      )}
      {AddModal ? React.cloneElement(AddModal, { ref: addModalRef }) : null}
    </Page>
  );
}
