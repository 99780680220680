import { CurrentFormMode } from "models/FormModeModels";
import { ContextsFieldConfig, FieldVisibilityType } from "utils/createGlobalConfigStore";

export interface FieldStatusResolve {
  show: boolean;
  readonly: boolean;
  showNA: boolean;
  optional: boolean;
  visibility: FieldVisibilityType;
}

// Add:
// field Disabled => no render
// field Required/Optional => render

// Update:
// field Disabled && no value => no render
// field Disabled && has value => render with value but keep it readonly
// field Required/Optional => render

// Info:
// field Disabled && no value => no render
// field Disabled && has value => render
// field Optional && no value => render with N/A
// field Optional && has value => render
// field Required => render

export interface FieldWithContexts {
  contexts: ContextsFieldConfig;
}

export interface FieldStatusParam<T extends FieldWithContexts> {
  formMode: CurrentFormMode;
  isReadonly: boolean;
  field?: T;
  value?: any;
}

export function fieldStatusResolve<T extends { contexts: ContextsFieldConfig }>({
  formMode,
  isReadonly,
  field,
  value,
}: FieldStatusParam<T>): FieldStatusResolve {
  const hasValue = value !== undefined && value !== null;
  if (field === undefined) {
    return {
      show: false,
      readonly: isReadonly,
      optional: false,
      showNA: false,
      visibility: "Disabled",
    };
  }

  if (formMode === "Add") {
    return {
      show: field?.contexts.Add.visibility !== "Disabled",
      readonly: isReadonly,
      showNA: false,
      optional: field?.contexts.Add.visibility === "Optional",
      visibility: field?.contexts.Add.visibility,
    };
  }

  if (formMode === "Edit") {
    return {
      show: !(field?.contexts.Update.visibility === "Disabled" && !hasValue),
      readonly: isReadonly || (field?.contexts.Update.visibility === "Disabled" && hasValue),
      showNA: false,
      optional: field?.contexts.Update.visibility === "Optional",
      visibility: field?.contexts.Update.visibility,
    };
  }

  return {
    show: !(field?.contexts.Update.visibility === "Disabled" && !hasValue),
    readonly: true,
    showNA: value === undefined || value === null || value?.toString().length === 0,
    optional: field?.contexts.Update.visibility === "Optional",
    visibility: field?.contexts.Update.visibility,
  };
}
