import { RegionType, UserGraphModel } from "api/query";
import { ECOption, Formatters } from "components/Chart/model";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import i18n from "i18next";
import { getAreaColoredOptions } from "../model";
import { EGFR_INTERVAL_GRAPH_VALUE, EGFR_MAX_GRAPH_VALUE } from "config/const";
import { EGFRStageRanges } from "utils/hooks/useGetEGFRRanges";
import { getColorValue } from "utils";

function getAreas(orderedEGFRStageRanges: EGFRStageRanges) {
  const areas = [
    {
      areaColor: getColorValue("--red-200"),
      start: 0,
      end: orderedEGFRStageRanges.KidneyFailure.to,
      label: i18n.t("Graph.eGFR.G5", { ns: "translation" }),
      labelDetails: i18n.t("Graph.eGFR.G5-details", { ns: "translation" }),
      labelColor: getColorValue("--red-500"),
      legendColor: getColorValue("--red-500"),
    },
    {
      areaColor: getColorValue("--yellow-100"),
      start: orderedEGFRStageRanges.SeverelyDecreased.from,
      end: orderedEGFRStageRanges.SeverelyDecreased.to,
      label: i18n.t("Graph.eGFR.G4", { ns: "translation" }),
      labelDetails: i18n.t("Graph.eGFR.G4-details", { ns: "translation" }),
      labelColor: getColorValue("--yellow-500"),
      legendColor: getColorValue("--yellow-500"),
    },
    {
      areaColor: getColorValue("--yellow-100"),
      start: orderedEGFRStageRanges.ModeratelyToSeverelyDecreased.from,
      end: orderedEGFRStageRanges.ModeratelyToSeverelyDecreased.to,
      label: i18n.t("Graph.eGFR.G3b", { ns: "translation" }),
      labelDetails: i18n.t("Graph.eGFR.G3b-details", { ns: "translation" }),
      labelColor: getColorValue("--yellow-500"),
      legendColor: getColorValue("--yellow-500"),
    },
    {
      areaColor: getColorValue("--yellow-100"),
      start: orderedEGFRStageRanges.MildlyOrModeratelyDecreased.from,
      end: orderedEGFRStageRanges.MildlyOrModeratelyDecreased.to,
      label: i18n.t("Graph.eGFR.G3a", { ns: "translation" }),
      labelDetails: i18n.t("Graph.eGFR.G3a-details", { ns: "translation" }),
      labelColor: getColorValue("--yellow-500"),
      legendColor: getColorValue("--yellow-500"),
    },
    {
      areaColor: getColorValue("--white-100"),
      start: orderedEGFRStageRanges.MildlyDecreased.from,
      end: orderedEGFRStageRanges.MildlyDecreased.to,
      label: i18n.t("Graph.eGFR.G2", { ns: "translation" }),
      labelDetails: i18n.t("Graph.eGFR.G2-details", { ns: "translation" }),
      labelColor: getColorValue("--grey-500"),
      legendColor: getColorValue("--grey-500"),
    },
    {
      areaColor: getColorValue("--white-100"),
      start: orderedEGFRStageRanges.NormalOrHigh.from,
      end: Number.MAX_SAFE_INTEGER,
      label: i18n.t("Graph.eGFR.G1", { ns: "translation" }),
      labelDetails: i18n.t("Graph.eGFR.G1-details", { ns: "translation" }),
      labelColor: getColorValue("--grey-500"),
      legendColor: getColorValue("--grey-500"),
    },
  ];

  return areas;
}

export const getOptions = (
  formatters: Formatters,
  graphUser: UserGraphModel,
  orderedEGFRStageRanges: EGFRStageRanges,
  showTrending?: boolean,
  regionType?: RegionType,
): ECOption =>
  getAreaColoredOptions({
    formatters,
    data: graphUser,
    yAxis: { max: EGFR_MAX_GRAPH_VALUE, interval: EGFR_INTERVAL_GRAPH_VALUE },
    areas: getAreas(orderedEGFRStageRanges),
    unitType: "egfr",
    measurementUnit: MEASUREMENT_UNITS.mLmin173m2,
    showTrending,
    regionType,
  });
