import { FieldValidationProps } from "libs/ui/FieldValidation";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useMUILocaleOptions } from "utils/hooks/useMUILocaleOptions";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import { DatePickerField } from "../DatePickerField";

interface DateTimePickerFieldLazyProps {
  value: Date;
  disabled: boolean;
  onSelect: (value: Date) => void;
  onBlur: () => void;
  validation: FieldValidationProps | undefined;
  label?: string;
  disableFuture?: boolean;
}

export function DateTimePickerFieldLazy({
  value,
  disabled,
  onSelect,
  onBlur,
  validation,
  label,
  disableFuture = true,
}: Readonly<DateTimePickerFieldLazyProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  const { adapter, text } = useMUILocaleOptions();
  const [, , dateTimeFormatter] = useRegionDateTimeFormatter();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapter} localeText={text}>
      <DatePickerField
        data-testid={"date-time-picker"}
        value={value}
        label={label ?? t("time")}
        disabled={disabled}
        onSelect={onSelect}
        onBlur={onBlur}
        validation={validation}
        disableFuture={disableFuture}
        formatter={dateTimeFormatter}
        pickerVariant="dateTime"
      />
    </LocalizationProvider>
  );
}

export default DateTimePickerFieldLazy;
