import { DeviceImage, SelectOptionDescription, SelectOptionTitle } from "libs/ui";
import { SampledByDeviceType } from "models/DeviceModels";
import { TestModel } from "models/TestModels";
import { useTranslation } from "react-i18next";
import "./DeviceDetailsField.scss";
import classNames from "classnames";
import { logger } from "logger";

interface DeviceFieldProps {
  data: TestModel | undefined;
  loading?: boolean | undefined;
}

export function DeviceDetailsField({ data }: DeviceFieldProps) {
  const { t: tLabel } = useTranslation("translation", { keyPrefix: "ui-components.DeviceImage" });
  const { t: tInput } = useTranslation("translation", {
    keyPrefix: "Graph.Details.GeneralSectionDetails",
  });

  if (
    !data?.device &&
    Object.values(SampledByDeviceType).every(el => el !== data?.sampledByDevice)
  ) {
    return <p className="DeviceDetailsField__manualInput">{tInput("manualInput")}</p>;
  }

  const fieldData = () => {
    switch (data?.sampledByDevice) {
      case SampledByDeviceType.NovaMax:
        return {
          title: `${data?.device?.name} - ${data?.device?.serialNumber}`,
          alt: tLabel("novaMaxAlt"),
          descriptionTitle: tLabel("serialNumber"),
          description: <SelectOptionDescription>{data?.device?.name}</SelectOptionDescription>,
        };

      case SampledByDeviceType.Neodocs:
        return {
          title: undefined,
          alt: tLabel("neodocsAlt"),
          descriptionTitle: tLabel("neodocsAlt"),
          description: null,
        };

      case SampledByDeviceType.Allegro:
        return {
          title: `${data.device?.name}`.concat(
            data.device?.serialNumber ? `- ${data.device?.serialNumber}` : "",
          ),
          alt: tLabel("allegroAlt"),
          descriptionTitle: tLabel("allegroAlt"),
          description: null,
        };

      default:
        logger?.error({
          msg: "DeviceDetailsField: invalid sampledByDevice value",
          device: data?.sampledByDevice,
        });

        return {
          title: undefined,
          alt: undefined,
          descriptionTitle: undefined,
          description: null,
        };
    }
  };

  return (
    <div
      className={classNames("DeviceDetailsField", {
        "DeviceDetailsField--align": data?.sampledByDevice === SampledByDeviceType.Neodocs,
      })}
      title={fieldData().title}
    >
      <DeviceImage sampledByDevice={data?.sampledByDevice} alt={fieldData().alt} logger={logger} />
      <SelectOptionTitle>{fieldData().descriptionTitle}</SelectOptionTitle>

      {fieldData().description}
    </div>
  );
}
