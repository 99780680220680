import Ajv from "ajv";
import { ContextsFieldConfig, GlobalConfig } from "utils/createGlobalConfigStore";
import { DeviceModel } from "./model";
import { FormType } from "models/FormModeModels";

const DEVICE_DATA_DEFAULT: DeviceModel = {
  manufacturerType: "NovaBiomedical",
  deviceType: "NovaMaxProCreateEgfrMeter",
  serialNumber: "",
  organizationId: "",
  // uniqueId: "",
};

function getFieldsReady(deviceData: DeviceModel, formType: FormType, globalConfig?: GlobalConfig) {
  const key: keyof ContextsFieldConfig = formType === "Add" ? "Add" : "Update";
  const ajv = new Ajv();

  ajv.addKeyword({
    keyword: "isNotEmpty",
    validate: (schema: any, data: any) => {
      if (schema) {
        return typeof data === "string" && data.trim() !== "";
      } else return true;
    },
  });

  // const disabledUniqueId = globalConfig?.entities.device.uniqueId.fieldVisibility === "Disabled";
  const optionalUniqueId =
    globalConfig?.entities.device.uniqueId.contexts[key].visibility === "Optional";
  const requiredUniqueId =
    globalConfig?.entities.device.uniqueId.contexts[key].visibility === "Required";

  const schema = {
    type: "object",
    properties: {
      manufacturerType: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      deviceType: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      serialNumber: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      organizationId: {
        type: "string",
        nullable: false,
        isNotEmpty: true,
        minLength: 1,
      },
      ...(requiredUniqueId || optionalUniqueId
        ? { uniqueId: { type: "string", nullable: optionalUniqueId, isNotEmpty: requiredUniqueId } }
        : undefined),
      organizationName: { type: "string", nullable: true },
      assignedTo: {
        type: "object",
        nullable: true,
        required: [],
      },
    },

    required: requiredUniqueId
      ? ["manufacturerType", "deviceType", "serialNumber", "organizationId", "uniqueId"]
      : ["manufacturerType", "deviceType", "serialNumber", "organizationId"],

    additionalProperties: false,
  };

  const validate = ajv.compile(schema);

  return validate(deviceData);
}

export const deviceFormUtils = { getFieldsReady, DEVICE_DATA_DEFAULT };
