import { RegionType } from "api/query/models";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { sub, differenceInDays, getDaysInMonth, formatISO, add } from "date-fns";
import { ECharts } from "echarts/core";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ZoomLevels } from "../model";

export type ZoomOptionsType = {
  title: string;
  value: "week" | "month" | "year" | "custom";
}[];

function getSplitNumber(zoomLevel: ZoomLevels, date: Date) {
  switch (zoomLevel) {
    case "week":
      return 7;
    case "month":
      return getDaysInMonth(date);
    default:
      return 12;
  }
}

function partFormatter(
  value: any,
  // TODO !!! new_backend - do we need some converted here for proper like en-GB ?
  regionType: RegionType = "en-GB",
  options?: Intl.DateTimeFormatOptions | undefined,
) {
  return new Intl.DateTimeFormat(regionType, options).format(new Date(value));
}

// TODO !!! new_backend - do we need some converted here for proper like en-GB ?
function xAxisLabelFormatter(zoomLevel: ZoomLevels, value: any, regionType: RegionType = "en-GB") {
  const firstPart = partFormatter(value, regionType, {
    ...(zoomLevel === "week"
      ? { weekday: "long" }
      : zoomLevel === "year"
        ? { month: "short" }
        : { day: "numeric", month: "short" }),
  });

  const secondPart = partFormatter(value, regionType, {
    ...(zoomLevel === "week"
      ? { day: "numeric", month: "short", year: "numeric" }
      : { year: "numeric" }),
  });

  return `{firstPart|${firstPart}}\n{secondPart|${secondPart}}`;
}

export const adjustXAxisMinValue = (zoomLevel: ZoomLevels, lastDate: string, firstDate: string) => {
  const diffDays = differenceInDays(new Date(lastDate), new Date(firstDate));
  const lastDateDaysInMonth = getDaysInMonth(new Date(lastDate));

  if (zoomLevel === "month" && diffDays <= lastDateDaysInMonth) {
    return {
      min: formatISO(sub(new Date(lastDate), { months: 1 }), { representation: "date" }),
    };
  }
  if (zoomLevel === "week" && diffDays <= 7) {
    return {
      min: formatISO(sub(new Date(lastDate), { weeks: 1 }), { representation: "date" }),
    };
  }
  return {};
};

const getZoomValues = (lastDate: string, zoomLevel?: ZoomLevels, startZoomDate?: string) => {
  const zoomLevelMapping: Duration = (() => {
    switch (zoomLevel) {
      case "week":
        return { weeks: 1 } as const;
      case "month":
        return { months: 1 } as const;
      case "year":
        return { years: 1 } as const;
      default:
        return { months: 1 } as const;
    }
  })();

  const startZoomValue = startZoomDate
    ? new Date(startZoomDate)
    : sub(new Date(lastDate), zoomLevelMapping);
  const endZoomValue = startZoomDate
    ? add(new Date(startZoomDate), zoomLevelMapping)
    : new Date(lastDate);

  return [startZoomValue, endZoomValue];
};

export function useChartZoomingOptions() {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.CarnaLineChart" });
  const [currentSelectedZoomLevel, setCurrentSelectedZoomLevel] = useState<ZoomLevels>("month");
  const { globalPreference } = useGlobalPreferenceContext();

  const zoomOptions: ZoomOptionsType = useMemo(() => {
    return (
      currentSelectedZoomLevel === "custom"
        ? (["week", "month", "year", "custom"] as const)
        : (["week", "month", "year"] as const)
    ).map(v => ({ title: t(`zoom.${v}`), value: v }));
  }, [currentSelectedZoomLevel, t]);

  const onZoomChange = useCallback(
    (chartRef: ECharts, zoomLevel?: ZoomLevels, startZoomDate?: string) => {
      if (!zoomLevel || (chartRef as any).getModel().getSeries()[0].option.data.length === 0) {
        return;
      }
      // getSeriesByName
      const seriesData: any[] = (chartRef as any).getModel().getSeries()[0].option.data;

      const [lastDate]: string = seriesData[seriesData.length - 1];
      const [firstDate]: string = seriesData[0];

      const [startZoomValue, endZoomValue] = getZoomValues(lastDate, zoomLevel, startZoomDate);

      if (zoomLevel !== "custom") {
        chartRef.dispatchAction({
          type: "dataZoom",
          dataZoomIndex: 0,
          startValue: +startZoomValue,
          endValue: +endZoomValue,
        });
      }

      chartRef.setOption({
        xAxis: {
          splitNumber: getSplitNumber(zoomLevel, startZoomValue),
          axisLabel: {
            formatter: (val: any) =>
              xAxisLabelFormatter(zoomLevel, val, globalPreference?.regionType),
          },
          ...adjustXAxisMinValue(zoomLevel, lastDate, firstDate),
          ...(zoomLevel === "year"
            ? {
                min: function (val: { min: number; max: number }) {
                  return sub(new Date(val.max), { years: 1 });
                },
              }
            : {}),
        },
      });
    },
    [globalPreference?.regionType],
  );

  return useMemo(
    () =>
      [currentSelectedZoomLevel, setCurrentSelectedZoomLevel, zoomOptions, onZoomChange] as const,
    [currentSelectedZoomLevel, onZoomChange, zoomOptions],
  );
}
