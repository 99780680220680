/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AddNovaMaxTestRequestModel } from './AddNovaMaxTestRequestModel';
import {
    instanceOfAddNovaMaxTestRequestModel,
    AddNovaMaxTestRequestModelFromJSON,
    AddNovaMaxTestRequestModelFromJSONTyped,
    AddNovaMaxTestRequestModelToJSON,
} from './AddNovaMaxTestRequestModel';
import type { AllegroDeviceSpecificDataModel } from './AllegroDeviceSpecificDataModel';
import {
    instanceOfAllegroDeviceSpecificDataModel,
    AllegroDeviceSpecificDataModelFromJSON,
    AllegroDeviceSpecificDataModelFromJSONTyped,
    AllegroDeviceSpecificDataModelToJSON,
} from './AllegroDeviceSpecificDataModel';
import type { NeodocsCkdDeviceSpecificModel } from './NeodocsCkdDeviceSpecificModel';
import {
    instanceOfNeodocsCkdDeviceSpecificModel,
    NeodocsCkdDeviceSpecificModelFromJSON,
    NeodocsCkdDeviceSpecificModelFromJSONTyped,
    NeodocsCkdDeviceSpecificModelToJSON,
} from './NeodocsCkdDeviceSpecificModel';

/**
 * @type AddTestByHcpRequestModelDeviceSpecificData
 * 
 * @export
 */
export type AddTestByHcpRequestModelDeviceSpecificData = AddNovaMaxTestRequestModel | AllegroDeviceSpecificDataModel | NeodocsCkdDeviceSpecificModel;

export function AddTestByHcpRequestModelDeviceSpecificDataFromJSON(json: any): AddTestByHcpRequestModelDeviceSpecificData {
    return AddTestByHcpRequestModelDeviceSpecificDataFromJSONTyped(json, false);
}

export function AddTestByHcpRequestModelDeviceSpecificDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddTestByHcpRequestModelDeviceSpecificData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...AddNovaMaxTestRequestModelFromJSONTyped(json, true), ...AllegroDeviceSpecificDataModelFromJSONTyped(json, true), ...NeodocsCkdDeviceSpecificModelFromJSONTyped(json, true) };
}

export function AddTestByHcpRequestModelDeviceSpecificDataToJSON(value?: AddTestByHcpRequestModelDeviceSpecificData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfAddNovaMaxTestRequestModel(value)) {
        return AddNovaMaxTestRequestModelToJSON(value as AddNovaMaxTestRequestModel);
    }
    if (instanceOfAllegroDeviceSpecificDataModel(value)) {
        return AllegroDeviceSpecificDataModelToJSON(value as AllegroDeviceSpecificDataModel);
    }
    if (instanceOfNeodocsCkdDeviceSpecificModel(value)) {
        return NeodocsCkdDeviceSpecificModelToJSON(value as NeodocsCkdDeviceSpecificModel);
    }

    return {};
}

