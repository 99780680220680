import { CountryType } from "api/query";
import i18n from "i18next";

export function countriesToSelectOptions() {
  const countriesKeys = Object.keys(CountryType);

  const countries = countriesKeys.map(key => ({
    // TODO: This is a temporary solution for now, we should use "nationalities"
    // namespace instead, but this function needs to be adjusted for that
    title: i18n.t(`countries.${key}`, { ns: "nationalities" }),
    value: CountryType[key as keyof typeof CountryType],
  }));

  return countries.splice(1, countries.length);
}
