/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import type { StateType } from './StateType';
import {
    StateTypeFromJSON,
    StateTypeFromJSONTyped,
    StateTypeToJSON,
} from './StateType';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';

/**
 * 
 * @export
 * @interface FormsDefaultValuesUserModel
 */
export interface FormsDefaultValuesUserModel {
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesUserModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesUserModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesUserModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesUserModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesUserModel
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesUserModel
     */
    city?: string | null;
    /**
     * 
     * @type {CountryType}
     * @memberof FormsDefaultValuesUserModel
     */
    country?: CountryType;
    /**
     * 
     * @type {StateType}
     * @memberof FormsDefaultValuesUserModel
     */
    state?: StateType;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesUserModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesUserModel
     */
    rowVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesUserModel
     */
    organizationId?: string | null;
    /**
     * 
     * @type {UserRoleType}
     * @memberof FormsDefaultValuesUserModel
     */
    role?: UserRoleType;
}

/**
 * Check if a given object implements the FormsDefaultValuesUserModel interface.
 */
export function instanceOfFormsDefaultValuesUserModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormsDefaultValuesUserModelFromJSON(json: any): FormsDefaultValuesUserModel {
    return FormsDefaultValuesUserModelFromJSONTyped(json, false);
}

export function FormsDefaultValuesUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormsDefaultValuesUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : CountryTypeFromJSON(json['country']),
        'state': !exists(json, 'state') ? undefined : StateTypeFromJSON(json['state']),
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'rowVersion': !exists(json, 'rowVersion') ? undefined : json['rowVersion'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'role': !exists(json, 'role') ? undefined : UserRoleTypeFromJSON(json['role']),
    };
}

export function FormsDefaultValuesUserModelToJSON(value?: FormsDefaultValuesUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'street': value.street,
        'city': value.city,
        'country': CountryTypeToJSON(value.country),
        'state': StateTypeToJSON(value.state),
        'zipCode': value.zipCode,
        'rowVersion': value.rowVersion,
        'organizationId': value.organizationId,
        'role': UserRoleTypeToJSON(value.role),
    };
}

