/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';

/**
 * 
 * @export
 * @interface TestsPageModel
 */
export interface TestsPageModel {
    /**
     * 
     * @type {Array<UserRoleType>}
     * @memberof TestsPageModel
     */
    addModalAccess: Array<UserRoleType>;
}

/**
 * Check if a given object implements the TestsPageModel interface.
 */
export function instanceOfTestsPageModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "addModalAccess" in value;

    return isInstance;
}

export function TestsPageModelFromJSON(json: any): TestsPageModel {
    return TestsPageModelFromJSONTyped(json, false);
}

export function TestsPageModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestsPageModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addModalAccess': ((json['AddModalAccess'] as Array<any>).map(UserRoleTypeFromJSON)),
    };
}

export function TestsPageModelToJSON(value?: TestsPageModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AddModalAccess': ((value.addModalAccess as Array<any>).map(UserRoleTypeToJSON)),
    };
}

