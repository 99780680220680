import classNames from "classnames";
import { PropsWithChildren } from "react";
import "./SimpleFormLayout.scss";

interface SimpleFormLayoutProps {
  className?: string;
}
export function SimpleFormLayout({
  children,
  className,
}: PropsWithChildren<SimpleFormLayoutProps>) {
  return <div className={classNames("SimpleFormLayout", className)}>{children}</div>;
}
