import { Option } from "libs/ui/Select/Select.model";
import i18n from "i18next";
import { NationalityType } from "api/query/models/NationalityType";

export function nationalityTypeToSelectOptions(): Option<NationalityType>[] {
  const nationalityKeys = Object.keys(NationalityType);

  return nationalityKeys.map(key => ({
    title: i18n.t(`nationalities.${key}`, { ns: "nationalities" }),
    value: NationalityType[key as keyof typeof NationalityType],
  }));
}
