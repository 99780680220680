import { ActionModal, Button } from "libs/ui";
import { isLoading } from "models/loadable";
import { PersonModel } from "models/PersonModels/PersonModel";
import { Trans, useTranslation } from "react-i18next";
import { useResendSignUp } from "utils/hooks/useResendSignUp";
import { preventClickBubbling } from "utils/preventClickBubbling";

interface ResendInviteModalProps {
  onSuccess: () => void;
  onCancel: () => void;
  user?: PersonModel;
  show: boolean;
}

export function ResendInviteModal({ onSuccess, onCancel, user, show }: ResendInviteModalProps) {
  const { t } = useTranslation("modals", { keyPrefix: "ResendInviteModal" });

  const [resendServerResponse, sendInvitation] = useResendSignUp(onSuccess, user);

  if (!user) {
    return null;
  }

  return (
    <ActionModal
      show={show}
      icon={{
        iconType: "Refresh",
        color: "grey",
      }}
      title={t("title")}
      cancelButton={
        <Button
          disabled={isLoading(resendServerResponse)}
          buttonSize="medium"
          buttonType="transparent"
          onClick={preventClickBubbling(onCancel)}
        >
          {t("buttonCancel")}
        </Button>
      }
      submitButton={
        <Button
          disabled={isLoading(resendServerResponse)}
          buttonSize="medium"
          buttonType="green"
          onClick={() =>
            sendInvitation({
              organizationId: user.organizationId,
              userEntityId: user.id,
              resendInviteUserRequestModel: { email: user.email ?? "" },
            })
          }
        >
          {t("buttonResend")}
        </Button>
      }
      inLoadingState={isLoading(resendServerResponse)}
    >
      <Trans
        i18nKey={"description"}
        t={t}
        values={{ targetName: `${user.firstName} ${user.lastName}` }}
      >
        <strong></strong>
      </Trans>
    </ActionModal>
  );
}
