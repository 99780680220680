import classNames from "classnames";
import { Icon } from "libs/ui/Icon";
import { PropsWithChildren } from "react";
import { TableUtils } from "../../utils";
import { HeadCellProps } from "../model";
import "./HeadCell.scss";

export function HeadCell({
  className,
  children,
  hasFilter,
  filterApplied,
  ...props
}: PropsWithChildren<HeadCellProps>) {
  return (
    <th {...props} className={classNames("HeadCell", className)}>
      <div
        className={classNames("HeadCell__label", {
          "HeadCell__label--active": filterApplied,
          "HeadCell__label--clickable": hasFilter,
        })}
      >
        {TableUtils.resolveValue(children)}
        {filterApplied && <Icon scaleTo={20} icon="Filter" />}
        {hasFilter && <Icon icon="CaretDown" />}
      </div>
    </th>
  );
}
