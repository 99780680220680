/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PhoneNumberFieldModel } from './PhoneNumberFieldModel';
import {
    PhoneNumberFieldModelFromJSON,
    PhoneNumberFieldModelFromJSONTyped,
    PhoneNumberFieldModelToJSON,
} from './PhoneNumberFieldModel';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';

/**
 * 
 * @export
 * @interface DefaultValuesModel
 */
export interface DefaultValuesModel {
    /**
     * 
     * @type {PhoneNumberFieldModel}
     * @memberof DefaultValuesModel
     */
    phoneNumberField: PhoneNumberFieldModel;
    /**
     * 
     * @type {Array<UserRoleType>}
     * @memberof DefaultValuesModel
     */
    userManagementRoles: Array<UserRoleType>;
}

/**
 * Check if a given object implements the DefaultValuesModel interface.
 */
export function instanceOfDefaultValuesModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "phoneNumberField" in value;
    isInstance = isInstance && "userManagementRoles" in value;

    return isInstance;
}

export function DefaultValuesModelFromJSON(json: any): DefaultValuesModel {
    return DefaultValuesModelFromJSONTyped(json, false);
}

export function DefaultValuesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultValuesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phoneNumberField': PhoneNumberFieldModelFromJSON(json['PhoneNumberField']),
        'userManagementRoles': ((json['UserManagementRoles'] as Array<any>).map(UserRoleTypeFromJSON)),
    };
}

export function DefaultValuesModelToJSON(value?: DefaultValuesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PhoneNumberField': PhoneNumberFieldModelToJSON(value.phoneNumberField),
        'UserManagementRoles': ((value.userManagementRoles as Array<any>).map(UserRoleTypeToJSON)),
    };
}

