import { CarnaApiEvent } from "config/apiEvent";
import { toastStore } from "config/toast";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useOnEventStatusSubscribe } from "utils/hooks/useOnEventStatusSubscribe";
// import AvatarGroup from "components/PageTemplate/DetailsPage/AvatarGroup"; //TODO NEW_BACKEND
import * as Form from "components/Forms/Patient";
import { patientFormUtils } from "components/Forms/Patient/patientFormUtils";
import { stripNetworkBodyWith } from "components/Forms/helper";
import { AddPatientRequestModelForStripping } from "config/binding";
import { InitialValuesModel, PatientModel } from "models/PersonModels";
import { useTranslation } from "react-i18next";
import { getDBDateFormat } from "utils/converters/getDBDateFormat";
import { GlobalConfig } from "utils/createGlobalConfigStore";
import { CreateNewSideModal } from "../common/CreateNewSideModal";
import { AddModalForwardedProps, AddModalProps } from "../model";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import { DISABLE_FORM_EDITING, EVERY_FORM_FIELD_EDITABLE } from "components/Forms/model";

function getInitialValues(
  appConfig: GlobalConfig,
  initialValuesAsProps?: Partial<InitialValuesModel>,
): PatientModel {
  if (initialValuesAsProps) {
    return {
      ...appConfig.forms.defaultValues.patient,
      organizationId: initialValuesAsProps.organizationId ?? "",
      organizationName: initialValuesAsProps.organizationName ?? "",
      hcpId: initialValuesAsProps.id,
      hcpFirstName: initialValuesAsProps.firstName,
      hcpLastName: initialValuesAsProps.lastName,
    } satisfies PatientModel;
  }
  return appConfig.forms.defaultValues.patient;
}

export const Patient = forwardRef<AddModalForwardedProps, AddModalProps>(
  ({ onSuccessCb, initialValuesAsProps }, ref) => {
    const { t } = useTranslation("modals", { keyPrefix: "AddModal" });
    //const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    //const [imageData, setImageData] = useState<string | undefined>();
    const { appConfig } = useGlobalConfigContext();
    const [patientData, setPatientData] = useState<PatientModel>(() =>
      getInitialValues(NotNullOrUndefined(appConfig), initialValuesAsProps),
    );

    const [onEvent, inLoadingState] = useOnEventStatusSubscribe();

    const resetAndClose = useCallback(() => {
      //setImageData(undefined);
      setPatientData(getInitialValues(NotNullOrUndefined(appConfig), initialValuesAsProps));
      setShowModal(false);
    }, [appConfig, initialValuesAsProps]);

    const onSuccessCallback = useCallback(() => {
      resetAndClose();
      if (onSuccessCb) {
        onSuccessCb();
      }
    }, [onSuccessCb, resetAndClose]);

    const areFieldsReady = patientFormUtils.getFieldsReady(patientData, "Add", appConfig);
    const submitDisabled = !areFieldsReady || appConfig === undefined || inLoadingState;

    const onSubmit = (e?: React.FormEvent) => {
      e?.preventDefault();

      if (submitDisabled) {
        return;
      }

      onEvent(
        CarnaApiEvent.Patient.post(
          {
            organizationId: patientData.organizationId ?? "",
            addPatientRequestModel: {
              ...patientData,
              ancestry: patientData.ancestry ?? undefined,
              nationalityType: patientData.nationalityType ?? undefined,
              dateOfBirth: getDBDateFormat(patientData.dateOfBirth),
            },
          },
          stripNetworkBodyWith(AddPatientRequestModelForStripping),
        ),
        {
          complete() {
            toastStore.toast.success({ msg: t("successAddPatientToastText"), expire: 5000 });
            onSuccessCallback();
            //   if (imageData) {
            //     CarnaApi.DocumentApi.organizationsOrganizationIdUsersIdUploadImagePut(
            //       result.data?.organizationId ?? "",
            //       result.data?.id ?? "",
            //       {
            //         userRoleType: "Patient",
            //         image: imageData,
            //       },
            //     )
            //       .then(onSuccessCallback)
            //       .catch(() => {
            //         toastStore.toast.error({ msg: t("failedToUploadPicture") });
            //         onSuccessCallback();

            //         navigate(
            //           generatePath(mainRoutePaths.patientDetails, {
            //             patientId: result.data?.id ?? "",
            //             organizationId: result.data?.organizationId ?? "",
            //           }),
            //         );
            //       });

            //     return;
            //   }
          },
          error(err) {
            switch (err.code) {
              case "BE_ERROR":
                showBeFieldErrors(err.err, t("errorAddPatientToastText"));
                break;

              case "ACTION_FAILED":
                toastStore.toast.error({ msg: t("errorAddPatientToastText") });
                break;
              case "STATUS_QUERY_ERROR":
                resetAndClose();
                toastStore.toast.error({ msg: t("errorFailedToUpdatePatientTable") });
            }
          },
        },
      );
    };

    const toggleModal = useCallback(() => {
      setShowModal(prevValue => !prevValue);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        toggleModal,
      }),
      [toggleModal],
    );

    return (
      <CreateNewSideModal
        title={t("addPatientTitle")}
        submitButton={{
          onClick: onSubmit,
          text: t("buttonAdd"),
          disabled: submitDisabled,
        }}
        show={showModal}
        closeModal={{
          onClick: resetAndClose,
          disabled: inLoadingState,
        }}
        isLoading={inLoadingState}
      >
        <Form.Patient
          formMode="Add"
          entityData={patientData}
          setEntityData={setPatientData}
          onSubmit={onSubmit}
          editableFields={
            inLoadingState || appConfig === undefined
              ? DISABLE_FORM_EDITING
              : EVERY_FORM_FIELD_EDITABLE
          }
        >
          {/*
          //TODO NEW_BACKEND
        <AvatarGroup
          loading={false}
          editing={true}
          firstName={undefined}
          avatarForCreate={imageData === undefined}
          imageSrc={imageData}
          onSave={(image?: string) => {
            const [, , imgData] = image?.match(/data:(.*);base64,(.*)/) ?? [];

            return Promise.resolve(setImageData(imgData));
          }}
        /> */}
        </Form.Patient>
      </CreateNewSideModal>
    );
  },
);
