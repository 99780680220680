import { SideModalElements } from "libs/ui";
import { DetailsField } from "../../elements/DetailsField";
import { ColorValue } from "../../elements/ColorValue";
import { SectionDetailsProps } from "../model";
import { useTranslation } from "react-i18next";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { SQUrineAlbuminBucketType } from "models/TestModels";
import { logger } from "logger";

interface SQUrineAlbuminSectionDetailsProps extends SectionDetailsProps {}

export function SQUrineAlbuminSectionDetails({ data, loading }: SQUrineAlbuminSectionDetailsProps) {
  const { t: tDetails } = useTranslation("translation", {
    keyPrefix: "Graph.Details.SQUrineAlbuminSectionDetails",
  });

  if (!data?.measurement.SemiQuantitativeUACR?.SemiQuantitativeUrineAlbumin.Bucket) {
    return null;
  }

  const getBucketValue = (bucket: SQUrineAlbuminBucketType | undefined) => {
    switch (bucket) {
      case SQUrineAlbuminBucketType.UpTo10:
        return "<1";
      case SQUrineAlbuminBucketType.From10To30:
        return "1 - 3";
      case SQUrineAlbuminBucketType.From30To80:
        return "3 - 8";
      case SQUrineAlbuminBucketType.From80To150:
        return "8 - 15";
      case SQUrineAlbuminBucketType.From150To210:
        return "15 - 21";
      case SQUrineAlbuminBucketType.From210To700:
        return "21 - 70";
      case SQUrineAlbuminBucketType.From700To2100:
        return ">70";
      default:
        logger.error(`Invalid bucket value: ${bucket}`);
      // throw Error(`Bucket value ${bucket} is invalid`);
    }
  };

  return (
    <div className="SQUrineAlbuminSectionDetails">
      <SideModalElements.SectionTitle>{tDetails("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={tDetails("labelResult")}>
        <ColorValue colorType="white">
          {getBucketValue(
            data.measurement.SemiQuantitativeUACR.SemiQuantitativeUrineAlbumin.Bucket,
          )?.concat(` ${MEASUREMENT_UNITS.mgdL}`)}
        </ColorValue>
      </DetailsField>
    </div>
  );
}
