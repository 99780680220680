import { SideModalElements } from "libs/ui";
import { useTranslation } from "react-i18next";
import { SectionDetailsProps } from "../model";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { ColorValue } from "../../elements/ColorValue";
import { DetailsField } from "../../elements/DetailsField";
import { TrendDetailsField } from "../../elements/TrendDetailsField";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";

interface GlucoseSectionDetailsProps extends SectionDetailsProps {}

export function GlucoseSectionDetails({ data, loading, showTrend }: GlucoseSectionDetailsProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.GlucoseSectionDetails" });
  const { glucoseUnit } = useGetUserPreferredMeasurementUnits();
  const { numberFormatterWithDash } = useLocaleNumberFormatter();

  return (
    <div className="GlucoseSectionDetails">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={t("labelResult")}>
        <ColorValue colorType={"white"}>
          {numberFormatterWithDash({ value: data?.measurement?.Glucose?.Glucose.Value }).concat(
            ` ${glucoseUnit}`,
          )}
        </ColorValue>
      </DetailsField>

      <TrendDetailsField
        show={showTrend}
        loading={loading}
        trendType={data?.measurement?.Glucose?.Glucose.TrendType}
        percent={data?.measurement?.Glucose?.Glucose.Percent}
      />
    </div>
  );
}
