/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AncestryType } from './AncestryType';
import {
    AncestryTypeFromJSON,
    AncestryTypeFromJSONTyped,
    AncestryTypeToJSON,
} from './AncestryType';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import type { GenderType } from './GenderType';
import {
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import type { NationalityType } from './NationalityType';
import {
    NationalityTypeFromJSON,
    NationalityTypeFromJSONTyped,
    NationalityTypeToJSON,
} from './NationalityType';
import type { UserStatusType } from './UserStatusType';
import {
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface AddPatientRequestModel
 */
export interface AddPatientRequestModel {
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    city: string;
    /**
     * 
     * @type {CountryType}
     * @memberof AddPatientRequestModel
     */
    country: CountryType;
    /**
     * 
     * @type {number}
     * @memberof AddPatientRequestModel
     */
    state?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    phone: string;
    /**
     * 
     * @type {GenderType}
     * @memberof AddPatientRequestModel
     */
    gender: GenderType;
    /**
     * 
     * @type {AncestryType}
     * @memberof AddPatientRequestModel
     */
    ancestry?: AncestryType;
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    dateOfBirth: string;
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    registryNumber?: string | null;
    /**
     * 
     * @type {NationalityType}
     * @memberof AddPatientRequestModel
     */
    nationalityType?: NationalityType;
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    hcpId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    deviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    deviceRowVersion?: string | null;
    /**
     * 
     * @type {UserStatusType}
     * @memberof AddPatientRequestModel
     */
    userStatus?: UserStatusType;
    /**
     * 
     * @type {string}
     * @memberof AddPatientRequestModel
     */
    generalPractitionerId?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AddPatientRequestModel
     */
    additionalProperties?: { [key: string]: string; } | null;
}

/**
 * Check if a given object implements the AddPatientRequestModel interface.
 */
export function instanceOfAddPatientRequestModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "dateOfBirth" in value;

    return isInstance;
}

export function AddPatientRequestModelFromJSON(json: any): AddPatientRequestModel {
    return AddPatientRequestModelFromJSONTyped(json, false);
}

export function AddPatientRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPatientRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'email': !exists(json, 'Email') ? undefined : json['Email'],
        'street': json['Street'],
        'city': json['City'],
        'country': CountryTypeFromJSON(json['Country']),
        'state': !exists(json, 'State') ? undefined : json['State'],
        'zipCode': !exists(json, 'ZipCode') ? undefined : json['ZipCode'],
        'phone': json['Phone'],
        'gender': GenderTypeFromJSON(json['Gender']),
        'ancestry': !exists(json, 'Ancestry') ? undefined : AncestryTypeFromJSON(json['Ancestry']),
        'dateOfBirth': json['DateOfBirth'],
        'registryNumber': !exists(json, 'RegistryNumber') ? undefined : json['RegistryNumber'],
        'nationalityType': !exists(json, 'NationalityType') ? undefined : NationalityTypeFromJSON(json['NationalityType']),
        'hcpId': !exists(json, 'HcpId') ? undefined : json['HcpId'],
        'deviceId': !exists(json, 'DeviceId') ? undefined : json['DeviceId'],
        'deviceRowVersion': !exists(json, 'DeviceRowVersion') ? undefined : json['DeviceRowVersion'],
        'userStatus': !exists(json, 'UserStatus') ? undefined : UserStatusTypeFromJSON(json['UserStatus']),
        'generalPractitionerId': !exists(json, 'GeneralPractitionerId') ? undefined : json['GeneralPractitionerId'],
        'additionalProperties': !exists(json, 'AdditionalProperties') ? undefined : json['AdditionalProperties'],
    };
}

export function AddPatientRequestModelToJSON(value?: AddPatientRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Email': value.email,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': value.state,
        'ZipCode': value.zipCode,
        'Phone': value.phone,
        'Gender': GenderTypeToJSON(value.gender),
        'Ancestry': AncestryTypeToJSON(value.ancestry),
        'DateOfBirth': value.dateOfBirth,
        'RegistryNumber': value.registryNumber,
        'NationalityType': NationalityTypeToJSON(value.nationalityType),
        'HcpId': value.hcpId,
        'DeviceId': value.deviceId,
        'DeviceRowVersion': value.deviceRowVersion,
        'UserStatus': UserStatusTypeToJSON(value.userStatus),
        'GeneralPractitionerId': value.generalPractitionerId,
        'AdditionalProperties': value.additionalProperties,
    };
}

