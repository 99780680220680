import { CarnaApiEvent } from "config/apiEvent";
import { toastStore } from "config/toast";
import { ActionModal, Button } from "libs/ui";
import { UserRoleType, UserStatusType } from "models/PersonModels";
import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getProfileEndpointPerRole } from "utils/helpers/getProfileEndpointPerRole";
import { useOnEventStatusSubscribe } from "utils/hooks/useOnEventStatusSubscribe";

interface PersonToUpdateStatus {
  organizationId: string;
  id: string;
  status: UserStatusType;
  role: UserRoleType;
  firstName?: string;
  lastName?: string;
}

interface UpdatePersonStatusModalProps extends PersonToUpdateStatus {
  show: boolean;
  onCloseModal: () => void;
  onSuccess: () => void;
}

async function updatePersonStatus(personToDelete?: PersonToUpdateStatus) {
  const { rowVersion, status } = await getProfileEndpointPerRole(personToDelete);

  return CarnaApiEvent[personToDelete?.role ?? "Admin"].changeStatus({
    organizationId: personToDelete!.organizationId,
    userEntityId: personToDelete!.id,
    updateUserStatusRequestModel: { rowVersion, isActive: status !== "Active" },
  });
}

function getStatusTranslationKey(role: UserRoleType) {
  if (role === "Admin" || role === "Partner") {
    return "UserChangeStatus" as const;
  }

  return `${role}ChangeStatus` as const;
}

export function UpdatePersonStatusModal({
  show,
  onCloseModal,
  onSuccess,
  status,
  role,
  organizationId,
  id,
  firstName,
  lastName,
}: Readonly<UpdatePersonStatusModalProps>) {
  const { t } = useTranslation("modals", { keyPrefix: "UpdatePersonStatusModal" });
  const { t: tStatus } = useTranslation("modals", {
    keyPrefix: getStatusTranslationKey(role),
  });
  const { t: tUserRoleType } = useTranslation("translation", { keyPrefix: "userRoleType" });
  const [onEvent, isLoading] = useOnEventStatusSubscribe();

  const fullName = firstName?.concat(` ${lastName}`);
  const toastPart = status === "Deactivated" ? "Activate" : "Deactivate";

  const updatePersonSuccessToast = fullName
    ? tStatus(`successChangeStatus${toastPart}Toast`, {
        fullName,
      })
    : t("successDeactivateSelfToast");

  const updatePersonFailedToast = fullName
    ? tStatus(`errorChangeStatus${toastPart}Toast`, {
        fullName,
      })
    : t("errorDeactivateSelfToast");

  const onConfirm = useCallback(() => {
    onEvent(updatePersonStatus({ id, organizationId, role, status }), {
      complete() {
        toastStore.toast.success({
          msg: updatePersonSuccessToast,
          expire: 5000,
        });

        onSuccess();
      },
      error(err) {
        switch (err.code) {
          case "ACTION_FAILED":
            toastStore.toast.error({ msg: updatePersonFailedToast });
            break;
          case "STATUS_QUERY_ERROR":
            if (fullName) {
              toastStore.toast.error({ msg: t(`errorFailedToUpdate${role}TableToast`) });
            }

            onCloseModal();
        }
      },
    });
  }, [
    fullName,
    id,
    onCloseModal,
    onEvent,
    onSuccess,
    organizationId,
    role,
    status,
    t,
    updatePersonFailedToast,
    updatePersonSuccessToast,
  ]);

  const getTitle = () => {
    if (fullName) {
      return t(`title.${status === "Active" ? "Deactivate" : "Activate"}`, {
        role: tUserRoleType(role),
      });
    }

    return t("title.Self");
  };

  return (
    <ActionModal
      show={show}
      icon={{
        iconType: status === "Active" ? "Slash" : "CheckCircle",
        color: status === "Active" ? "red" : "green",
      }}
      title={getTitle()}
      cancelButton={
        <Button buttonSize="medium" buttonType="transparent" onClick={onCloseModal}>
          {t("buttonCancel")}
        </Button>
      }
      submitButton={
        <Button
          buttonSize="medium"
          buttonType={status === "Active" ? "red" : "green"}
          onClick={onConfirm}
        >
          {t(`${status === "Active" ? "buttonDeactivate" : "buttonActivate"}`)}
        </Button>
      }
      inLoadingState={isLoading}
    >
      {fullName ? (
        <Trans
          i18nKey={`text.${status === "Active" ? "Deactivate" : "Activate"}`}
          t={t}
          values={{ targetName: fullName }}
        >
          <strong data-testid="actionModalName"></strong>
        </Trans>
      ) : (
        <Trans i18nKey="text.Self" t={t} />
      )}
    </ActionModal>
  );
}
