import {
  BloodPressureModel,
  DiastolicModel,
  MeasurementGraphModel,
  SystolicModel,
  UnitsModel,
  UserGraphModel,
} from "api/query";
import { default as i18n } from "i18n";

import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { MeasurementItemUIType } from "models/TestModels";
import {
  gramsToKilograms,
  gramsToPounds,
  millimetersToCentimeters,
  millimetersToWholeFeet,
  restOfMillimetersToInchesAfterWholeFeet,
} from "utils/converters/unitsConverter";
import { getUrineAlbuminValuePerUserPreferredUnit } from "utils/hooks/useUrineAlbuminValueConvertor";
import { getFormattedCreatininePerMeasurementUnit } from "utils/converters/getFormattedCreatininePerMeasurementUnit";
import "./CarnaTooltip.scss";
import { Formatters } from "./model";
import { TopLevelFormatterParams } from "echarts/types/dist/shared.js";

function getSQUACRValue(value: number) {
  switch (value) {
    case 1:
      return 15;
    case 2:
      return 150;
    case 3:
      return 330;
    default:
      throw new Error(`Value ${value} for SQ UACR not implemented`);
  }
}

function parseValueToValueWithUnit(
  value: number,
  unitType: keyof UnitsModel,
  measurementUnit?: MEASUREMENT_UNITS,
) {
  let result = 0;

  switch (unitType) {
    case "serumCreatinine":
      result = getFormattedCreatininePerMeasurementUnit(value, measurementUnit);
      break;

    case "urineAlbumin":
      result = getUrineAlbuminValuePerUserPreferredUnit(String(value), measurementUnit);
      break;

    case "weight":
      result =
        measurementUnit === "kg"
          ? gramsToKilograms(value)
          : Number(gramsToPounds(value).toFixed(2));
      break;

    case "semiQuantitativeUacr":
      result = getSQUACRValue(value);
      break;

    default:
      result = value;
  }

  return result;
}

type UnitsModelMeasurement =
  | Exclude<UnitsModel[keyof UnitsModel], BloodPressureModel>
  | SystolicModel
  | DiastolicModel;

const printTrending = (
  measurement?: UnitsModelMeasurement,
  showTrending?: boolean,
  measurementType?: MeasurementItemUIType,
) => {
  if (!measurement || showTrending === false) {
    return "";
  }

  const { percent, trendType } = measurement as any;

  if (!percent || trendType === "Equal" || trendType === "None") {
    return "";
  }

  const measurementTypeLabel = measurementType
    ? `${i18n.t(`Graph.tooltip.type.${measurementType}`, { ns: "translation" })} `
    : "";

  return `
    <div class="CarnaTooltip__separator"></div>
    <p class="CarnaTooltip__description">${measurementTypeLabel}${i18n.t(
      `Graph.tooltip.trending.${trendType}`,
      {
        percent,
        ns: "translation",
      },
    )}</p>
  `;
};

export function tooltipFormatter(
  params: TopLevelFormatterParams,
  formatters: Formatters,
  graphUser: UserGraphModel,
  unitType: keyof UnitsModel,
  measurementUnit?: MEASUREMENT_UNITS,
  showTrending?: boolean,
) {
  const [dateFormatter, timeFormatter, numberFormatter] = formatters;

  const currentParams = Array.isArray(params) ? (params as any)[0] : (params as any);

  if (graphUser.measurements.length === 0) {
    return "No data.";
  }

  const selectedMeasurement: MeasurementGraphModel =
    graphUser.measurements[currentParams.dataIndex];

  if (currentParams.componentType === "series") {
    const areaColor = currentParams.color;
    const [date]: [string, number] = currentParams.data;

    const root = document.createElement("div");
    root.classList.add("CarnaTooltip");

    const dateStr = dateFormatter(new Date(date));
    const timeStr = timeFormatter(new Date(date));

    switch (unitType) {
      case "height":
        {
          const selectedUnit = selectedMeasurement.units["height"];
          const exactValue = selectedUnit?.value ?? 0;

          root.style.backgroundColor = areaColor;

          if (measurementUnit === "cm") {
            root.innerHTML = `
              <h3 class="CarnaTooltip__measurement">${numberFormatter({
                value: millimetersToCentimeters(exactValue),
              }).concat(` ${measurementUnit}`)}
              </h3>
              <p class="CarnaTooltip__time">${dateStr}</p>
              <p class="CarnaTooltip__time">${timeStr}</p>
              ${printTrending(selectedUnit, showTrending)}
            `;
          } else {
            root.innerHTML = `
           <h3 class="CarnaTooltip__measurement">${
             millimetersToWholeFeet(exactValue) +
             "’" +
             Math.round(restOfMillimetersToInchesAfterWholeFeet(exactValue)) +
             "”"
           }
           </h3>
           <p class="CarnaTooltip__measurementUnit">ft, inch</p>
           <p class="CarnaTooltip__time">${dateStr}</p>
           <p class="CarnaTooltip__time">${timeStr}</p>
           ${printTrending(selectedUnit, showTrending)}
         `;
          }
        }
        break;
      case "bloodPressure":
        {
          const selectedUnit = selectedMeasurement.units["bloodPressure"];
          root.style.backgroundColor = "#8352FE";
          root.innerHTML = `
            <p class="CarnaTooltip__label">Systolic</p>
            <h3 class="CarnaTooltip__measurement">${
              selectedMeasurement?.units?.bloodPressure?.systolic.value
            }</h3>
            <p class="CarnaTooltip__label">Diastolic</p>
            <h3 class="CarnaTooltip__measurement">${
              selectedMeasurement?.units?.bloodPressure?.diastolic.value
            }</h3>
            <p class="CarnaTooltip__measurementUnit">${i18n.t(`${measurementUnit}`, {
              ns: "translation",
            })}</p>
            <p class="CarnaTooltip__time">${dateStr}</p>
            <p class="CarnaTooltip__time">${timeStr}</p>
            ${printTrending(selectedUnit?.systolic, showTrending, "Systolic")}
            ${printTrending(selectedUnit?.diastolic, showTrending, "Diastolic")}
        `;
        }
        break;

      case "semiQuantitativeUacr":
        {
          const selectedUnit = selectedMeasurement.units["semiQuantitativeUacr"];
          const exactValue = selectedUnit?.value ?? 0;

          const SQUACRFlag = i18n.t(`SQUACRFlags.${exactValue}`, {
            ns: "translation",
          });
          const level = i18n.t(`SQUACRFlags.level.${SQUACRFlag}`, {
            ns: "translation",
          });
          const stadium = i18n.t(`SQUACRFlags.stadium.${SQUACRFlag}`, {
            ns: "translation",
          });

          root.style.backgroundColor = areaColor;

          root.innerHTML = `
            ${level ? `<h3 class="CarnaTooltip__measurement">${level}</h3>` : ""}
            ${stadium ? `<p class="CarnaTooltip__measurementUnit">${stadium}</p>` : ""}
            <div class="CarnaTooltip__separator"></div>
            <p class="CarnaTooltip__time">${dateStr}</p>
            <p class="CarnaTooltip__time">${timeStr}</p>
          `;
        }

        break;

      default:
        {
          /**
           * The value which would come from `currentParams.data` is rounded
           *  if it goes above 7 in mgd/L or above 619 in umol/L
           * Having this in mind, on the graph, it will be rounded, but on the tooltip
           *  we want to show the exact value, ergo we are reading the true value from dataList
           */
          const selectedUnit = selectedMeasurement.units[unitType];
          const exactValue = selectedUnit?.value ?? 0;

          root.style.backgroundColor = areaColor;

          root.innerHTML = `
        <h3 class="CarnaTooltip__measurement">${numberFormatter({
          value: parseValueToValueWithUnit(exactValue, unitType, measurementUnit),
        })}</h3>
        ${
          measurementUnit
            ? `<p class="CarnaTooltip__measurementUnit">${i18n.t(measurementUnit, {
                ns: "translation",
              })}</p>`
            : ""
        }
        <p class="CarnaTooltip__time">${dateStr}</p>
        <p class="CarnaTooltip__time">${timeStr}</p>
        ${printTrending(selectedUnit, showTrending)}
      `;
        }
        break;
    }

    return root;
  }

  return "";
}
