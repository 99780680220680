import classNames from "classnames";
import { isReadonly } from "components/Forms/helper";
import { MAX_BMI_HEIGHT_IN_CM, MAX_BMI_WEIGHT_IN_KG } from "config/const";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { InputNumber } from "libs/ui/InputNumber";
import { SideModalElements } from "libs/ui/SideModal";
import { useCallback, useMemo, useRef } from "react";
import { getNumberSeparators } from "utils/converters/parseLocaleNumber";
import {
  feetToMillimeters,
  gramsToKilograms,
  gramsToPounds,
  inchesToMillimeters,
  millimetersToCentimeters,
  millimetersToWholeFeet,
  poundsToGrams,
  restOfMillimetersToInchesAfterWholeFeet,
} from "utils/converters/unitsConverter";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { useTranslation } from "react-i18next";
import { Group } from "../Group";
import { PatientData, PhysicalSectionProps } from "./model";
import { Bmi } from "./Bmi";

export function Imperial<T extends PatientData>({
  patientData,
  editableFields,
  onChange,
  loading,
  showTitle = true,
}: PhysicalSectionProps<T>) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  // TODO put this in parent ?
  const { globalPreference } = useGlobalPreferenceContext();
  const { weightUnit } = useGetUserPreferredMeasurementUnits();
  const { decimalSeparator } = useMemo(
    () => getNumberSeparators(globalPreference?.regionType),
    [globalPreference?.regionType],
  );

  const localState = useRef<{ feet: null | number; inch: null | number }>({
    feet: patientData.height ? millimetersToWholeFeet(patientData.height) : null,
    inch: patientData.height ? restOfMillimetersToInchesAfterWholeFeet(patientData.height) : null,
  });

  // TODO 2 way binding
  //   useEffect(() => {
  //     if (!!patientData.height === true) {
  //       const feet = millimetersToWholeFeet(patientData.height);
  //       const inch = Math.round(restOfMillimetersToInchesAfterWholeFeet(patientData.height));
  //       if (localState.current.feet !== feet) {
  //         localState.current.feet = feet;
  //       }
  //       if (localState.current.inch !== inch) {
  //         localState.current.inch = inch;
  //       }
  //     }
  //   }, [patientData.height]);

  const onHeightFeetChange = useCallback(
    (e: number | null) => {
      localState.current.feet = e;

      if (!!localState.current.feet === false) {
        onChange(undefined, "height");
        return;
      }

      onChange(
        feetToMillimeters(localState.current.feet ?? 0) +
          inchesToMillimeters(localState.current.inch ?? 0),
        "height",
      );
    },
    [onChange],
  );

  const onHeightInchChange = useCallback(
    (e: number | null) => {
      localState.current.inch = e;
      /**
       * Minimal newborn babies must be bigger than 11.99 inch
       * 12 inch is = 1 feet
       * ! so even newborn babies must have feet input
       * this || is ok here
       */
      if (!!localState.current.feet === false || (localState.current.inch ?? 0) >= 12) {
        onChange(undefined, "height");
        return;
      }

      onChange(
        feetToMillimeters(localState.current.feet ?? 0) +
          inchesToMillimeters(localState.current.inch ?? 0),
        "height",
      );
    },
    [onChange],
  );

  const onWeightChange = useCallback(
    (e: number | null) => {
      onChange(typeof e === "number" ? poundsToGrams(e) : "", "weight");
    },
    [onChange],
  );

  // only whole numbers
  const feetValidator = useCallback((val: string) => val.match(/^\d*$/) !== null, []);
  const inchValidator = useCallback(
    (val: string) =>
      (decimalSeparator === "."
        ? val.match(/^\d*(\.){0,1}\d{0,2}$/)
        : val.match(/^\d*(,){0,1}\d{0,2}$/)) !== null,
    [decimalSeparator],
  );

  const weightValidator = useCallback(
    //TODO this doesn't work for this pattern 12,,,,,,,,
    (val: string) => val.match(/^\d+(,{1}\d*)*(\.)*\d{0,2}$/) !== null,
    [],
  );

  const totalHeightInvalid =
    !!patientData.height === false ||
    millimetersToCentimeters(
      feetToMillimeters(localState.current.feet ?? 0) +
        inchesToMillimeters(localState.current.inch ?? 0),
    ) > MAX_BMI_HEIGHT_IN_CM;

  const inchInvalid = (localState.current.inch ?? 0) >= 12;

  const weightInvalid =
    !!patientData.weight === false || gramsToKilograms(patientData.weight) > MAX_BMI_WEIGHT_IN_KG;

  const everyFieldIsEmpty =
    !!localState.current.feet === false &&
    !!localState.current.inch === false &&
    !!patientData.weight === false;

  const hasValidationError = everyFieldIsEmpty
    ? false
    : totalHeightInvalid || weightInvalid || inchInvalid;

  return (
    <Group data-testid={"Group-PhysicalSection"}>
      {showTitle ? (
        <SideModalElements.SectionTitle>{t("Subtitle.physical")}</SideModalElements.SectionTitle>
      ) : null}
      <div
        data-testerror={hasValidationError?.toString()}
        className={classNames(
          "PhysicalSection",
          globalPreference?.measureUnitType
            ? `PhysicalSection--${globalPreference?.measureUnitType}`
            : undefined,
          {
            "PhysicalSection--error": hasValidationError,
          },
        )}
      >
        <InputNumber
          onNumberChange={onHeightFeetChange}
          data-testid={"height-feet"}
          data-error={hasValidationError && totalHeightInvalid}
          label={t("heightFeet")}
          value={localState.current.feet}
          validateInput={feetValidator}
          validation={{
            errorText:
              hasValidationError && totalHeightInvalid ? t("ValidationMessages.height") : undefined,
          }}
          readOnly={isReadonly("height", editableFields)}
          suffix="ft"
          loading={loading}
          region={globalPreference?.regionType}
        />
        <InputNumber
          onNumberChange={onHeightInchChange}
          data-testid={"height-inch"}
          data-error={hasValidationError && totalHeightInvalid}
          label={t("heightInch")}
          value={localState.current.inch}
          validateInput={inchValidator}
          validation={{
            errorText:
              hasValidationError && inchInvalid
                ? t("ValidationMessages.invalidInch")
                : hasValidationError && totalHeightInvalid
                  ? t("ValidationMessages.height")
                  : undefined,
          }}
          readOnly={isReadonly("height", editableFields)}
          suffix="in"
          loading={loading}
          formattingOptions={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            roundingMode: "trunc",
          }}
          region={globalPreference?.regionType}
        />
        <InputNumber
          data-testid={"weight"}
          onNumberChange={onWeightChange}
          data-error={hasValidationError && weightInvalid}
          validateInput={weightValidator}
          label={t("weight")}
          value={typeof patientData.weight === "number" ? gramsToPounds(patientData.weight) : ""}
          validation={{
            errorText:
              hasValidationError && weightInvalid ? t("ValidationMessages.weight") : undefined,
          }}
          formattingOptions={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            roundingMode: "trunc",
          }}
          readOnly={isReadonly("weight", editableFields)}
          suffix={weightUnit ? weightUnit.toString() : undefined}
          loading={loading}
          region={globalPreference?.regionType}
        />
      </div>
      <Bmi bmiValue={patientData.bmi} />
    </Group>
  );
}
