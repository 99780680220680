import * as React from "react";
import { SVGProps } from "react";
const SvgThumbsDownFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.683 23a1 1 0 0 0 .913-.594L17.332 14h2.35a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3H5.409a3 3 0 0 0-2.994 2.55l-1.38 9A3 3 0 0 0 4.028 16h4.655v3a4 4 0 0 0 4 4Zm5-11h-2V3h2v9Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgThumbsDownFilled;
