import { AvatarState } from "utils/hooks/useAvatarState";

export function onSaveAvatar(
  avatarState: AvatarState,
  dispatchAvatarAction: React.Dispatch<import("utils/hooks/useAvatarState").AvatarAction>,
) {
  return (image?: string) => {
    if (!image && avatarState.latestSavedImage === undefined) {
      return Promise.resolve(
        dispatchAvatarAction({
          type: "ResetToLatestSavedImage",
        }),
      );
    }
    if (!image && avatarState.latestSavedImage !== undefined) {
      return Promise.resolve(
        dispatchAvatarAction({ type: "SetNewImage", payload: { newImage: "delete" } }),
      );
    }

    const [, , newImage] = image?.match(/data:(.*);base64,(.*)/) ?? [];

    if (!newImage) {
      return Promise.reject(new Error("Invalid image data"));
    }

    return Promise.resolve(dispatchAvatarAction({ type: "SetNewImage", payload: { newImage } }));
  };
}
