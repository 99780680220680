import { SVGProps } from "react";
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5149 3.72705H4.75887C3.78788 3.72705 3.00049 4.51445 3.00049 5.48543V19.2415C3.00049 20.2125 3.78788 20.9999 4.75887 20.9999H18.5149C19.4859 20.9999 20.2733 20.2125 20.2733 19.2415V5.48543C20.2733 4.51441 19.4859 3.72705 18.5149 3.72705ZM8.57295 6.85839C8.12881 6.85839 7.7686 6.49818 7.7686 6.05404C7.7686 5.6099 8.12881 5.24969 8.57295 5.24969C9.01709 5.24969 9.3773 5.6099 9.3773 6.05404C9.3773 6.49818 9.01709 6.85839 8.57295 6.85839ZM14.7008 6.85839C14.2567 6.85839 13.8965 6.49818 13.8965 6.05404C13.8965 5.6099 14.2567 5.24969 14.7008 5.24969C15.145 5.24969 15.5052 5.6099 15.5052 6.05404C15.5052 6.49818 15.145 6.85839 14.7008 6.85839Z"
      fill="url(#paint0_linear_32340_11859)"
    />
    <path
      d="M3 12.8768V19.2413C3 20.2126 3.7874 21 4.7587 21H18.5145C19.4858 21 20.2732 20.2126 20.2732 19.2413V12.8768H3Z"
      fill="url(#paint1_linear_32340_11859)"
    />
    <path
      d="M18.5145 3.72705H9.93018V20.9999H18.5145C19.4855 20.9999 20.2729 20.2125 20.2729 19.2415V5.48543C20.2729 4.51441 19.4855 3.72705 18.5145 3.72705ZM14.7004 6.85839C14.2562 6.85839 13.896 6.49818 13.896 6.05404C13.896 5.6099 14.2562 5.24969 14.7004 5.24969C15.1445 5.24969 15.5047 5.6099 15.5047 6.05404C15.5047 6.49818 15.1445 6.85839 14.7004 6.85839Z"
      fill="url(#paint2_linear_32340_11859)"
    />
    <ellipse cx="6.75" cy="12.25" rx="1.25" ry="1.25" fill="#96B9D4" />
    <ellipse cx="6.75" cy="16.2501" rx="1.25" ry="1.25" fill="#96B9D4" />
    <ellipse cx="11.75" cy="12.25" rx="1.25" ry="1.25" fill="#96B9D4" />
    <ellipse cx="11.75" cy="16.2501" rx="1.25" ry="1.25" fill="#96B9D4" />
    <ellipse cx="16.75" cy="12.25" rx="1.25" ry="1.25" fill="#96B9D4" />
    <ellipse cx="16.75" cy="16.2501" rx="1.25" ry="1.25" fill="#96B9D4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75887 3.72705H18.5149C19.4859 3.72705 20.2733 4.51445 20.2733 5.48543V8.66868H3.00049V5.48543C3.00049 4.51445 3.78788 3.72705 4.75887 3.72705ZM6.75333 5.87793C6.75333 6.46554 6.27699 6.94189 5.68938 6.94189C5.10178 6.94189 4.62543 6.46554 4.62543 5.87793C4.62543 5.29033 5.10178 4.81398 5.68938 4.81398C6.27699 4.81398 6.75333 5.29033 6.75333 5.87793ZM10.6952 5.87795C10.6952 6.46555 10.2188 6.9419 9.63121 6.9419C9.04361 6.9419 8.56726 6.46555 8.56726 5.87795C8.56726 5.29034 9.04361 4.814 9.63121 4.814C10.2188 4.814 10.6952 5.29034 10.6952 5.87795ZM13.5731 6.9419C14.1607 6.9419 14.6371 6.46555 14.6371 5.87795C14.6371 5.29034 14.1607 4.814 13.5731 4.814C12.9855 4.814 12.5092 5.29034 12.5092 5.87795C12.5092 6.46555 12.9855 6.9419 13.5731 6.9419ZM18.579 5.87795C18.579 6.46555 18.1026 6.9419 17.515 6.9419C16.9274 6.9419 16.451 6.46555 16.451 5.87795C16.451 5.29034 16.9274 4.814 17.515 4.814C18.1026 4.814 18.579 5.29034 18.579 5.87795Z"
      fill="url(#paint3_linear_32340_11859)"
    />
    <path
      d="M5.70642 6.10465C5.45598 6.10465 5.25293 5.87442 5.25293 5.59045V3.51421C5.25293 3.23023 5.45598 3 5.70642 3C5.95686 3 6.15991 3.23023 6.15991 3.51421V5.59049C6.15991 5.87446 5.9569 6.10465 5.70642 6.10465Z"
      fill="url(#paint4_linear_32340_11859)"
    />
    <path
      d="M9.64831 6.10465C9.39787 6.10465 9.19482 5.87442 9.19482 5.59045V3.51421C9.19482 3.23023 9.39787 3 9.64831 3C9.89875 3 10.1018 3.23023 10.1018 3.51421V5.59049C10.1018 5.87446 9.89879 6.10465 9.64831 6.10465Z"
      fill="url(#paint5_linear_32340_11859)"
    />
    <path
      d="M13.5902 6.10465C13.3398 6.10465 13.1367 5.87442 13.1367 5.59045V3.51421C13.1367 3.23023 13.3398 3 13.5902 3C13.8406 3 14.0437 3.23023 14.0437 3.51421V5.59049C14.0437 5.87446 13.8407 6.10465 13.5902 6.10465Z"
      fill="url(#paint6_linear_32340_11859)"
    />
    <path
      d="M17.5321 6.10465C17.2817 6.10465 17.0786 5.87442 17.0786 5.59045V3.51421C17.0786 3.23023 17.2817 3 17.5321 3C17.7825 3 17.9856 3.23023 17.9856 3.51421V5.59049C17.9856 5.87446 17.7826 6.10465 17.5321 6.10465Z"
      fill="url(#paint7_linear_32340_11859)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_32340_11859"
        x1="7.47126"
        y1="8.19782"
        x2="16.0227"
        y2="16.7493"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset="1" stopColor="#CDE0F3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_32340_11859"
        x1="11.6366"
        y1="19.4022"
        x2="11.6366"
        y2="21.3221"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EBACF" stopOpacity="0" />
        <stop offset="0.2189" stopColor="#9EBACF" stopOpacity="0.219" />
        <stop offset="0.4512" stopColor="#9EBACF" stopOpacity="0.451" />
        <stop offset="0.6896" stopColor="#9EBACF" stopOpacity="0.69" />
        <stop offset="0.9309" stopColor="#9EBACF" stopOpacity="0.931" />
        <stop offset="1" stopColor="#9EBACF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_32340_11859"
        x1="18.2878"
        y1="12.3635"
        x2="20.6773"
        y2="12.3635"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EBACF" stopOpacity="0" />
        <stop offset="0.2189" stopColor="#9EBACF" stopOpacity="0.219" />
        <stop offset="0.4512" stopColor="#9EBACF" stopOpacity="0.451" />
        <stop offset="0.6896" stopColor="#9EBACF" stopOpacity="0.69" />
        <stop offset="0.9309" stopColor="#9EBACF" stopOpacity="0.931" />
        <stop offset="1" stopColor="#9EBACF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_32340_11859"
        x1="11.6369"
        y1="5.31921"
        x2="11.6369"
        y2="9.96273"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9BE2D4" />
        <stop offset="1" stopColor="#4CA291" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_32340_11859"
        x1="5.30794"
        y1="4.55235"
        x2="6.1617"
        y2="4.55235"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#98BBD7" />
        <stop offset="1" stopColor="#5F7587" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_32340_11859"
        x1="9.24983"
        y1="4.55235"
        x2="10.1036"
        y2="4.55235"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#98BBD7" />
        <stop offset="1" stopColor="#5F7587" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_32340_11859"
        x1="13.1917"
        y1="4.55235"
        x2="14.0455"
        y2="4.55235"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#98BBD7" />
        <stop offset="1" stopColor="#5F7587" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_32340_11859"
        x1="17.1336"
        y1="4.55235"
        x2="17.9874"
        y2="4.55235"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#98BBD7" />
        <stop offset="1" stopColor="#5F7587" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgCalendar;
