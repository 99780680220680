import { useResponsive } from "libs/ui/hooks";
import { Hamburger } from "./Hamburger";
import "./TopBar.scss";

export function TopBar() {
  const { isDesktop } = useResponsive();

  if (isDesktop) {
    return null;
  }

  return (
    <header className="TopBar">
      <Hamburger />
    </header>
  );
}
