import { UserGraphModel } from "api/query";
import CarnaLineChart from "components/Chart/CarnaChart/LineChart";
import { useGetMaxWeightValueFromRuleEngineUnit } from "components/Chart/hooks/useGetMaxWeightValueFromRuleEngineUnit";
import { ECOption, Formatters } from "components/Chart/model";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { isLoaded } from "models/loadable";
import { useCallback, useMemo } from "react";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { useGetWeightConverter } from "utils/hooks/useGetWeightConverter/useGetWeightConverter";
import { useGraphDataAPIContext } from "../GraphDataAPIContext";
import { AverageWeight } from "./AverageWeight";
import { getOptions as getInitialOptions } from "./model";
import { calculateAverageWeight } from "./utils";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
// const CarnaLineChart = React.lazy(() => import("components/Chart/CarnaChart/LineChart"));

export function Weight() {
  const { appConfig } = useGlobalConfigContext();

  const { value: maxWeightRange } = useGetMaxWeightValueFromRuleEngineUnit();
  const { weightUnit } = useGetUserPreferredMeasurementUnits();
  const getConvertedWeight = useGetWeightConverter();
  const { data } = useGraphDataAPIContext();
  const { numberFormatterWithDash } = useLocaleNumberFormatter();

  const getOptions = useCallback(
    (formatters: Formatters, data: UserGraphModel): ECOption =>
      getInitialOptions({
        formatters,
        data,
        measurementUnit: weightUnit,
        maxRange: maxWeightRange,
        showTrending: appConfig?.components.graphs.Patient.Index.showTrending,
      }),
    [weightUnit, maxWeightRange, appConfig?.components.graphs.Patient.Index.showTrending],
  );

  const getAverageWeight = useMemo(() => {
    if (isLoaded(data.Weight)) {
      return calculateAverageWeight(data.Weight.value.measurements);
    }
    return 0;
  }, [data.Weight]);

  return (
    <CarnaLineChart
      showTrend={appConfig?.components.graphs.Patient.Index.showTrending}
      dataType="Weight"
      unitType="weight"
      className="Weight"
      getOptions={getOptions}
      chartHeaderSlot={
        <AverageWeight
          value={numberFormatterWithDash({
            value: getConvertedWeight(getAverageWeight),
            additionalOptions: {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
              roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
            },
          })}
        />
      }
      measurementUnit={weightUnit}
    />
  );
}

export default Weight;
