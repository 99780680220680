import * as React from "react";
import { SVGProps } from "react";
const SvgFiMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3 5.25a.75.75 0 0 0 0 1.5h18a.75.75 0 0 0 0-1.5H3ZM2.25 12a.75.75 0 0 1 .75-.75h18a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75ZM2.25 18a.75.75 0 0 1 .75-.75h18a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFiMenu;
