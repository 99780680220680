import { PatientResponseModel } from "api/query";
import { PatientsField } from "components/Forms/FormElements/PatientsField";
import { isReadonly } from "components/Forms/helper";
import DateTimePickerFieldLazy from "libs/ui/DateTimePickerFieldLazy";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TestFormModelValidationFieldsKeys, TestFormFieldsProps } from "../model";
import { Measurements } from "./Measurements";
import { useOnInputValidation } from "components/Forms/hooks";
import { useValidateDate } from "utils/hooks/useValidateDate";

// const DateTimePickerFieldLazy = lazy(() => import("libs/ui/DateTimePickerFieldLazy"));

export function FormFields({
  onChange,
  onSubmit,
  testData,
  editableFields,
  loading,
  person,
}: TestFormFieldsProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });

  const { isNotFutureDate } = useValidateDate();

  const VALIDATION_MAP = useMemo(
    () =>
      ({
        measurementTime: isNotFutureDate,
      }) as const,
    [isNotFutureDate],
  );

  const [validationFieldsText, setValidationFieldsText] = useOnInputValidation(
    onChange,
    VALIDATION_MAP,
  );

  const onPatientChange = useCallback(
    (value?: PatientResponseModel) => {
      onChange(!value ? undefined : value.id ?? "", "patientId");
    },
    [onChange],
  );

  const checkValidationOnBlur = (inputKey: TestFormModelValidationFieldsKeys) => {
    setValidationFieldsText(prevValue => ({
      ...prevValue,
      [inputKey]: !VALIDATION_MAP[inputKey](new Date(testData[inputKey]))
        ? t(`ValidationMessages.${inputKey}`)
        : undefined,
    }));
  };

  const onSelectWithValidation = useCallback(
    (value: any, inputKey: TestFormModelValidationFieldsKeys) => {
      onChange(value, inputKey);

      setValidationFieldsText(prevValue => ({
        ...prevValue,
        [inputKey]: !VALIDATION_MAP[inputKey](new Date(value))
          ? t(`ValidationMessages.${inputKey}`)
          : undefined,
      }));
    },
    [VALIDATION_MAP, onChange, setValidationFieldsText, t],
  );

  const onDateTimeChange = (value: Date) => {
    value.setSeconds(0);
    value.setMilliseconds(0);

    onSelectWithValidation(value, "measurementTime");
  };

  const patientName = person?.firstName.concat(` ${person?.lastName}`);

  return (
    <form className="Form" onSubmit={onSubmit}>
      <div className="Form__field">
        <PatientsField
          readOnly={!!person?.id || isReadonly("patientId", editableFields)}
          organizationId={testData.organizationId}
          initialValue={patientName}
          onSelect={onPatientChange}
          loading={loading}
        />
      </div>

      <div className="Form__field">
        <DateTimePickerFieldLazy
          value={testData.measurementTime}
          disabled={isReadonly("measurementTime", editableFields)}
          onSelect={onDateTimeChange}
          onBlur={() => checkValidationOnBlur("measurementTime")}
          validation={{
            errorText: validationFieldsText.measurementTime,
          }}
          label={t("measurementTime")}
          disableFuture={true}
        />
      </div>

      <Measurements onChange={onChange} testData={testData} />

      <button hidden type="submit" />
    </form>
  );
}
