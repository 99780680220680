import * as React from "react";
import { SVGProps } from "react";
const SvgCornerUpRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.53 3.47a.75.75 0 1 0-1.06 1.06l3.72 3.72H8A4.75 4.75 0 0 0 3.25 13v7a.75.75 0 0 0 1.5 0v-7A3.25 3.25 0 0 1 8 9.75h10.19l-3.72 3.72a.75.75 0 1 0 1.06 1.06l5-5a.747.747 0 0 0 0-1.06l-5-5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCornerUpRight;
