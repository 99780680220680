import * as React from "react";
import { SVGProps } from "react";
const SvgMap = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.349 1.336a.747.747 0 0 0-.733.02L.628 5.348A.75.75 0 0 0 .25 6v16a.75.75 0 0 0 1.122.651l6.652-3.8 7.627 3.813a.746.746 0 0 0 .349.086h.007a.747.747 0 0 0 .377-.105l6.988-3.994A.75.75 0 0 0 23.75 18V2a.75.75 0 0 0-1.122-.651l-6.652 3.8L8.35 1.337ZM7.25 3.292l-5.5 3.143v14.273l5.5-3.143V3.292Zm1.5 14.244V3.215l6.5 3.25v14.322l-6.5-3.25Zm13.5.029-5.5 3.143V6.435l5.5-3.143v14.273Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMap;
