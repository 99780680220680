import * as React from "react";
import { SVGProps } from "react";
const SvgMic = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.348 1.348A3.75 3.75 0 0 1 15.75 4v8a3.75 3.75 0 0 1-7.5 0V4a3.75 3.75 0 0 1 1.098-2.652ZM12 1.75A2.25 2.25 0 0 0 9.75 4v8a2.25 2.25 0 0 0 4.5 0V4A2.25 2.25 0 0 0 12 1.75Z"
      fill="currentColor"
    />
    <path
      d="M12 18.25A6.25 6.25 0 0 0 18.25 12v-2a.75.75 0 0 1 1.5 0v2a7.75 7.75 0 0 1-7 7.714v2.536H16a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1 0-1.5h3.25v-2.536a7.75 7.75 0 0 1-7-7.714v-2a.75.75 0 0 1 1.5 0v2A6.25 6.25 0 0 0 12 18.25Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMic;
