/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasePatientWidgetDataResponseModel } from './BasePatientWidgetDataResponseModel';
import {
    BasePatientWidgetDataResponseModelFromJSON,
    BasePatientWidgetDataResponseModelFromJSONTyped,
    BasePatientWidgetDataResponseModelToJSON,
} from './BasePatientWidgetDataResponseModel';
import type { DeviceModel } from './DeviceModel';
import {
    DeviceModelFromJSON,
    DeviceModelFromJSONTyped,
    DeviceModelToJSON,
} from './DeviceModel';
import type { GenderType } from './GenderType';
import {
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import type { HcpPatientWidgetResponseModel } from './HcpPatientWidgetResponseModel';
import {
    HcpPatientWidgetResponseModelFromJSON,
    HcpPatientWidgetResponseModelFromJSONTyped,
    HcpPatientWidgetResponseModelToJSON,
} from './HcpPatientWidgetResponseModel';
import type { OrganizationPatientWidgetResponseModel } from './OrganizationPatientWidgetResponseModel';
import {
    OrganizationPatientWidgetResponseModelFromJSON,
    OrganizationPatientWidgetResponseModelFromJSONTyped,
    OrganizationPatientWidgetResponseModelToJSON,
} from './OrganizationPatientWidgetResponseModel';

/**
 * 
 * @export
 * @interface PatientInfoWidgetDataModel
 */
export interface PatientInfoWidgetDataModel extends BasePatientWidgetDataResponseModel {
    /**
     * 
     * @type {string}
     * @memberof PatientInfoWidgetDataModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInfoWidgetDataModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInfoWidgetDataModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {OrganizationPatientWidgetResponseModel}
     * @memberof PatientInfoWidgetDataModel
     */
    organization?: OrganizationPatientWidgetResponseModel;
    /**
     * 
     * @type {DeviceModel}
     * @memberof PatientInfoWidgetDataModel
     */
    device?: DeviceModel;
    /**
     * 
     * @type {HcpPatientWidgetResponseModel}
     * @memberof PatientInfoWidgetDataModel
     */
    hcp?: HcpPatientWidgetResponseModel;
    /**
     * 
     * @type {GenderType}
     * @memberof PatientInfoWidgetDataModel
     */
    gender?: GenderType;
    /**
     * 
     * @type {number}
     * @memberof PatientInfoWidgetDataModel
     */
    years?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatientInfoWidgetDataModel
     */
    bmi?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInfoWidgetDataModel
     */
    registryNumber?: string | null;
}

/**
 * Check if a given object implements the PatientInfoWidgetDataModel interface.
 */
export function instanceOfPatientInfoWidgetDataModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatientInfoWidgetDataModelFromJSON(json: any): PatientInfoWidgetDataModel {
    return PatientInfoWidgetDataModelFromJSONTyped(json, false);
}

export function PatientInfoWidgetDataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientInfoWidgetDataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...BasePatientWidgetDataResponseModelFromJSONTyped(json, ignoreDiscriminator),
        'id': !exists(json, 'Id') ? undefined : json['Id'],
        'firstName': !exists(json, 'FirstName') ? undefined : json['FirstName'],
        'lastName': !exists(json, 'LastName') ? undefined : json['LastName'],
        'organization': !exists(json, 'Organization') ? undefined : OrganizationPatientWidgetResponseModelFromJSON(json['Organization']),
        'device': !exists(json, 'Device') ? undefined : DeviceModelFromJSON(json['Device']),
        'hcp': !exists(json, 'Hcp') ? undefined : HcpPatientWidgetResponseModelFromJSON(json['Hcp']),
        'gender': !exists(json, 'Gender') ? undefined : GenderTypeFromJSON(json['Gender']),
        'years': !exists(json, 'Years') ? undefined : json['Years'],
        'bmi': !exists(json, 'Bmi') ? undefined : json['Bmi'],
        'registryNumber': !exists(json, 'RegistryNumber') ? undefined : json['RegistryNumber'],
    };
}

export function PatientInfoWidgetDataModelToJSON(value?: PatientInfoWidgetDataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...BasePatientWidgetDataResponseModelToJSON(value),
        'Id': value.id,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Organization': OrganizationPatientWidgetResponseModelToJSON(value.organization),
        'Device': DeviceModelToJSON(value.device),
        'Hcp': HcpPatientWidgetResponseModelToJSON(value.hcp),
        'Gender': GenderTypeToJSON(value.gender),
        'Years': value.years,
        'Bmi': value.bmi,
        'RegistryNumber': value.registryNumber,
    };
}

