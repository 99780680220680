import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useCallback, useMemo } from "react";
import { getCorrectedValueForCreatinine } from "utils/converters/unitsConverter";

export function useCreatinineValueConvertor() {
  const { appConfig } = useGlobalConfigContext();

  const creatinineConvertor = useCallback(
    (value: string | number, incomingUnit: MEASUREMENT_UNITS) => {
      if (
        !incomingUnit ||
        incomingUnit === appConfig?.laboratory.measurements.serumCreatinine.measurementUnit
      ) {
        return value;
      }

      return getCorrectedValueForCreatinine(
        value,
        appConfig?.laboratory.measurements.serumCreatinine.measurementUnit ??
          MEASUREMENT_UNITS.mgdL,
      );
    },
    [appConfig?.laboratory.measurements.serumCreatinine.measurementUnit],
  );

  return useMemo(() => creatinineConvertor, [creatinineConvertor]);
}
