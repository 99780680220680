/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InfrastructureSectionModel
 */
export interface InfrastructureSectionModel {
    /**
     * 
     * @type {string}
     * @memberof InfrastructureSectionModel
     */
    apiGatewayId: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureSectionModel
     */
    stageName: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureSectionModel
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureSectionModel
     */
    dataTableName: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureSectionModel
     */
    identityPoolId: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureSectionModel
     */
    userPoolId: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureSectionModel
     */
    userTableName: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureSectionModel
     */
    s3BucketName: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureSectionModel
     */
    translationTenantId: string;
}

/**
 * Check if a given object implements the InfrastructureSectionModel interface.
 */
export function instanceOfInfrastructureSectionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apiGatewayId" in value;
    isInstance = isInstance && "stageName" in value;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "dataTableName" in value;
    isInstance = isInstance && "identityPoolId" in value;
    isInstance = isInstance && "userPoolId" in value;
    isInstance = isInstance && "userTableName" in value;
    isInstance = isInstance && "s3BucketName" in value;
    isInstance = isInstance && "translationTenantId" in value;

    return isInstance;
}

export function InfrastructureSectionModelFromJSON(json: any): InfrastructureSectionModel {
    return InfrastructureSectionModelFromJSONTyped(json, false);
}

export function InfrastructureSectionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfrastructureSectionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiGatewayId': json['ApiGatewayId'],
        'stageName': json['StageName'],
        'clientId': json['ClientId'],
        'dataTableName': json['DataTableName'],
        'identityPoolId': json['IdentityPoolId'],
        'userPoolId': json['UserPoolId'],
        'userTableName': json['UserTableName'],
        's3BucketName': json['S3BucketName'],
        'translationTenantId': json['TranslationTenantId'],
    };
}

export function InfrastructureSectionModelToJSON(value?: InfrastructureSectionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ApiGatewayId': value.apiGatewayId,
        'StageName': value.stageName,
        'ClientId': value.clientId,
        'DataTableName': value.dataTableName,
        'IdentityPoolId': value.identityPoolId,
        'UserPoolId': value.userPoolId,
        'UserTableName': value.userTableName,
        'S3BucketName': value.s3BucketName,
        'TranslationTenantId': value.translationTenantId,
    };
}

