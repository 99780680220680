import classNames from "classnames";
import { Page } from "libs/ui";
import { PropsWithChildren } from "react";
import { Params } from "react-router-dom";
import { useBreadCrumbs } from "utils/hooks/Pages/useBreadCrumbs";
import { SettingsPageProps } from "./model";

export interface UseMatchesType {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: unknown;
}

export function SettingsPage({
  controls,
  className,
  children,
}: PropsWithChildren<SettingsPageProps>) {
  const breadCrumbs = useBreadCrumbs();

  return (
    <Page
      {...(breadCrumbs && { breadCrumbs })}
      {...(controls && { controls })}
      className={classNames("SettingsPage", className)}
    >
      {children}
    </Page>
  );
}
