import { Loader } from "libs/ui/Loader";
import { useEffect } from "react";
import { Root, createRoot } from "react-dom/client";
import { map, of, Subject, switchMap, timer } from "rxjs";
import "./SplashScreen.scss";

interface Event {
  event: "SHOW_SPLASH" | "HIDE_SPLASH";
}

const domNode = document.createElement("div");
domNode.classList.toggle("SplashScreen");

let rootSplash: Root | null = null;

const splashSubject = new Subject<Event>();

const splash$ = splashSubject.pipe(
  switchMap(event => {
    if (event.event === "HIDE_SPLASH") {
      return timer(100).pipe(map(() => event));
    }

    return of(event);
  }),
);

function toggleEverythingElse(show: boolean) {
  for (const child of Array.from(document.body.children)) {
    if (child.classList.contains("SplashScreen") && show === false) {
      return;
    }

    (child as HTMLElement).style.display = show ? "" : "none";
  }
}

splash$.subscribe({
  next({ event }) {
    const splashScreenFound = document.querySelector(".SplashScreen");

    switch (event) {
      case "SHOW_SPLASH":
        if (splashScreenFound || rootSplash) {
          return;
        }

        toggleEverythingElse(false);
        rootSplash = createRoot(domNode);
        rootSplash.render(<Loader loading />);
        document.body.prepend(domNode);

        break;

      case "HIDE_SPLASH": {
        toggleEverythingElse(true);
        rootSplash?.unmount();
        rootSplash = null;

        if (splashScreenFound) {
          document.body.removeChild(domNode);
        }

        break;
      }
      default:
        break;
    }
  },
});

export function SplashScreen() {
  useEffect(() => {
    splashSubject.next({ event: "SHOW_SPLASH" });

    return () => {
      splashSubject.next({ event: "HIDE_SPLASH" });
    };
  }, []);

  return null;
}
