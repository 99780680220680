import * as React from "react";
import { SVGProps } from "react";
const SvgNavigationFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22.904 2.428a1 1 0 0 0-1.332-1.332l-19 9a1 1 0 0 0 .185 1.874l7.418 1.855 1.855 7.418a1 1 0 0 0 1.874.185l9-19Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgNavigationFilled;
