import * as React from "react";
import { SVGProps } from "react";
const SvgSkipBack = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.75 4a.75.75 0 0 0-1.218-.586l-10 8a.75.75 0 0 0 0 1.172l10 8A.75.75 0 0 0 19.75 20V4Zm-1.5 14.44L10.2 12l8.05-6.44v12.88Z"
      fill="currentColor"
    />
    <path d="M5.75 5a.75.75 0 0 0-1.5 0v14a.75.75 0 0 0 1.5 0V5Z" fill="currentColor" />
  </svg>
);
export default SvgSkipBack;
