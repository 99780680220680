/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserStatusRequestModel
 */
export interface UpdateUserStatusRequestModel {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserStatusRequestModel
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserStatusRequestModel
     */
    rowVersion: string;
}

/**
 * Check if a given object implements the UpdateUserStatusRequestModel interface.
 */
export function instanceOfUpdateUserStatusRequestModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "rowVersion" in value;

    return isInstance;
}

export function UpdateUserStatusRequestModelFromJSON(json: any): UpdateUserStatusRequestModel {
    return UpdateUserStatusRequestModelFromJSONTyped(json, false);
}

export function UpdateUserStatusRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserStatusRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isActive': json['IsActive'],
        'rowVersion': json['RowVersion'],
    };
}

export function UpdateUserStatusRequestModelToJSON(value?: UpdateUserStatusRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IsActive': value.isActive,
        'RowVersion': value.rowVersion,
    };
}

