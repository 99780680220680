import { FloatingPortal, useHover } from "@floating-ui/react";
import classNames from "classnames";
import { CountryCode } from "libphonenumber-js";
import { DataAttributeKey } from "../models";
import { useTooltip } from "../Tooltip";
import "./CountryFlag.scss";
import { CountryType } from "api/query/models/CountryType";

type FlagSizeType = "small" | "medium" | "large";

interface CountryFlagProps {
  countryCode: CountryType | CountryCode;
  countryName?: string;
  flagSize?: FlagSizeType;
  className?: string;
  loading?: boolean;
  [dataAttribute: DataAttributeKey]: any;
}

export function CountryFlag({
  countryName,
  countryCode,
  flagSize = "small",
  className,
  loading,
  ...rest
}: CountryFlagProps) {
  const [ref, getReferenceProps, Tooltip] = useTooltip({
    interactionProps: [useHover],
  });
  return (
    <>
      <span
        className={classNames(
          "CountryFlag",
          `CountryFlag--${flagSize} fi fis fi-${countryCode.toLowerCase()}`,
          className,
          {
            "CountryFlag--loading": loading,
          },
        )}
        ref={ref}
        {...getReferenceProps()}
        {...rest}
      />
      <FloatingPortal>
        <Tooltip>{countryName}</Tooltip>
      </FloatingPortal>
    </>
  );
}
