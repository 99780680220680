/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MeasurementTrendType } from './MeasurementTrendType';
import {
    MeasurementTrendTypeFromJSON,
    MeasurementTrendTypeFromJSONTyped,
    MeasurementTrendTypeToJSON,
} from './MeasurementTrendType';
import type { MeasurementUnitType } from './MeasurementUnitType';
import {
    MeasurementUnitTypeFromJSON,
    MeasurementUnitTypeFromJSONTyped,
    MeasurementUnitTypeToJSON,
} from './MeasurementUnitType';
import type { UrineAlbumineStatusType } from './UrineAlbumineStatusType';
import {
    UrineAlbumineStatusTypeFromJSON,
    UrineAlbumineStatusTypeFromJSONTyped,
    UrineAlbumineStatusTypeToJSON,
} from './UrineAlbumineStatusType';

/**
 * 
 * @export
 * @interface UrineAlbuminModel
 */
export interface UrineAlbuminModel {
    /**
     * 
     * @type {number}
     * @memberof UrineAlbuminModel
     */
    value: number;
    /**
     * 
     * @type {MeasurementUnitType}
     * @memberof UrineAlbuminModel
     */
    unit: MeasurementUnitType;
    /**
     * 
     * @type {MeasurementTrendType}
     * @memberof UrineAlbuminModel
     */
    trendType: MeasurementTrendType;
    /**
     * 
     * @type {number}
     * @memberof UrineAlbuminModel
     */
    percent: number;
    /**
     * 
     * @type {UrineAlbumineStatusType}
     * @memberof UrineAlbuminModel
     */
    status: UrineAlbumineStatusType;
}

/**
 * Check if a given object implements the UrineAlbuminModel interface.
 */
export function instanceOfUrineAlbuminModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "unit" in value;
    isInstance = isInstance && "trendType" in value;
    isInstance = isInstance && "percent" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function UrineAlbuminModelFromJSON(json: any): UrineAlbuminModel {
    return UrineAlbuminModelFromJSONTyped(json, false);
}

export function UrineAlbuminModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrineAlbuminModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['Value'],
        'unit': MeasurementUnitTypeFromJSON(json['Unit']),
        'trendType': MeasurementTrendTypeFromJSON(json['TrendType']),
        'percent': json['Percent'],
        'status': UrineAlbumineStatusTypeFromJSON(json['Status']),
    };
}

export function UrineAlbuminModelToJSON(value?: UrineAlbuminModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Value': value.value,
        'Unit': MeasurementUnitTypeToJSON(value.unit),
        'TrendType': MeasurementTrendTypeToJSON(value.trendType),
        'Percent': value.percent,
        'Status': UrineAlbumineStatusTypeToJSON(value.status),
    };
}

