/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  DeviceResponseModelListModel,
  DevicesFilterModel,
  HcpResponseModelListModel,
  HcpsFilterModel,
  PatientResponseModelListModel,
  PatientsFilterModel,
  UserDetailsResponseModelListModel,
  UserDetailsResponseModelResponse,
  UsersPerOrganizationFilterModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    DeviceResponseModelListModelFromJSON,
    DeviceResponseModelListModelToJSON,
    DevicesFilterModelFromJSON,
    DevicesFilterModelToJSON,
    HcpResponseModelListModelFromJSON,
    HcpResponseModelListModelToJSON,
    HcpsFilterModelFromJSON,
    HcpsFilterModelToJSON,
    PatientResponseModelListModelFromJSON,
    PatientResponseModelListModelToJSON,
    PatientsFilterModelFromJSON,
    PatientsFilterModelToJSON,
    UserDetailsResponseModelListModelFromJSON,
    UserDetailsResponseModelListModelToJSON,
    UserDetailsResponseModelResponseFromJSON,
    UserDetailsResponseModelResponseToJSON,
    UsersPerOrganizationFilterModelFromJSON,
    UsersPerOrganizationFilterModelToJSON,
} from '../models/index';

export interface DevicesGetRequest {
    filters?: DevicesFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface HcpsGetRequest {
    filters?: HcpsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdAdministratorsGetRequest {
    organizationId: string;
    filters?: UsersPerOrganizationFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdAdministratorsIdGetRequest {
    organizationId: string;
    id: string;
    acceptVersion?: string;
}

export interface PatientsGetRequest {
    filters?: PatientsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface PatientsReportsCsvGetRequest {
    filters?: PatientsFilterModel;
    acceptVersion?: string;
}

/**
 * AdministratorsApi - interface
 * 
 * @export
 * @interface AdministratorsApiInterface
 */
export interface AdministratorsApiInterface {
    /**
     * 
     * @param {DevicesFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministratorsApiInterface
     */
    devicesGetRaw(requestParameters: DevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceResponseModelListModel>>;

    /**
     */
    devicesGet(requestParameters: DevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceResponseModelListModel>;

    /**
     * 
     * @param {HcpsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministratorsApiInterface
     */
    hcpsGetRaw(requestParameters: HcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HcpResponseModelListModel>>;

    /**
     */
    hcpsGet(requestParameters: HcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HcpResponseModelListModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {UsersPerOrganizationFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministratorsApiInterface
     */
    organizationsOrganizationIdAdministratorsGetRaw(requestParameters: OrganizationsOrganizationIdAdministratorsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelListModel>>;

    /**
     */
    organizationsOrganizationIdAdministratorsGet(requestParameters: OrganizationsOrganizationIdAdministratorsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDetailsResponseModelListModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministratorsApiInterface
     */
    organizationsOrganizationIdAdministratorsIdGetRaw(requestParameters: OrganizationsOrganizationIdAdministratorsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelResponse>>;

    /**
     */
    organizationsOrganizationIdAdministratorsIdGet(requestParameters: OrganizationsOrganizationIdAdministratorsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDetailsResponseModelResponse>;

    /**
     * 
     * @param {PatientsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministratorsApiInterface
     */
    patientsGetRaw(requestParameters: PatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientResponseModelListModel>>;

    /**
     */
    patientsGet(requestParameters: PatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientResponseModelListModel>;

    /**
     * 
     * @param {PatientsFilterModel} [filters] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministratorsApiInterface
     */
    patientsReportsCsvGetRaw(requestParameters: PatientsReportsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientResponseModelListModel>>;

    /**
     */
    patientsReportsCsvGet(requestParameters: PatientsReportsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientResponseModelListModel>;

}

/**
 * 
 */
export class AdministratorsApi extends runtime.BaseAPI implements AdministratorsApiInterface {

     /**
     */
    async devicesGetRawOriginal(requestParameters: DevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/devices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async devicesGetRaw(requestParameters: DevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceResponseModelListModel>> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/devices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async devicesGet(requestParameters: DevicesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceResponseModelListModel> {
        const response = await this.devicesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async hcpsGetRawOriginal(requestParameters: HcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/hcps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async hcpsGetRaw(requestParameters: HcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HcpResponseModelListModel>> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/hcps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HcpResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async hcpsGet(requestParameters: HcpsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HcpResponseModelListModel> {
        const response = await this.hcpsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdAdministratorsGetRawOriginal(requestParameters: OrganizationsOrganizationIdAdministratorsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/administrators`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsGetRaw(requestParameters: OrganizationsOrganizationIdAdministratorsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelListModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsGet(requestParameters: OrganizationsOrganizationIdAdministratorsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDetailsResponseModelListModel> {
        const response = await this.organizationsOrganizationIdAdministratorsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdAdministratorsIdGetRawOriginal(requestParameters: OrganizationsOrganizationIdAdministratorsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsIdGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdAdministratorsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/administrators/{id}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsIdGetRaw(requestParameters: OrganizationsOrganizationIdAdministratorsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsIdGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdAdministratorsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators/{id}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsResponseModelResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsIdGet(requestParameters: OrganizationsOrganizationIdAdministratorsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDetailsResponseModelResponse> {
        const response = await this.organizationsOrganizationIdAdministratorsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async patientsGetRawOriginal(requestParameters: PatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/patients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async patientsGetRaw(requestParameters: PatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientResponseModelListModel>> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/patients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async patientsGet(requestParameters: PatientsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientResponseModelListModel> {
        const response = await this.patientsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async patientsReportsCsvGetRawOriginal(requestParameters: PatientsReportsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/patients/reports/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async patientsReportsCsvGetRaw(requestParameters: PatientsReportsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientResponseModelListModel>> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/patients/reports/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async patientsReportsCsvGet(requestParameters: PatientsReportsCsvGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientResponseModelListModel> {
        const response = await this.patientsReportsCsvGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
