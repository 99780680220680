import { useMachine } from "@xstate/react";
import { TestListResponseModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { TableContext, tableStateMachine } from "libs/ui";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { createSafeContext } from "utils/createSafeContext";

export type HcpPatientsMeasurementsTableService = ReturnType<
  typeof useMachine<ReturnType<typeof makeTableFetchStateMachine>>
>;

interface HcpPatientsMeasurementsLaboratoryContext {
  tableService: HcpPatientsMeasurementsTableService;
  fetch: () => void;
}
const Context = createSafeContext<HcpPatientsMeasurementsLaboratoryContext>();

export const useHcpPatientsMeasurementsLaboratoryContext = Context.hook;

async function fetchMeasurements(
  organizationId: string,
  hcpId: string,
  context?: TableContext<TestListResponseModel, GetHcpPatientsMeasurementsFilters>,
) {
  const result = CarnaApiQuery.Hcps.getPatientsMeasurements({
    organizationId,
    userEntityId: hcpId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

type GetHcpPatientsMeasurementsFilters = NonNullable<
  Parameters<typeof CarnaApiQuery.Hcps.getPatientsMeasurements>[0]["filters"]
>;

const makeTableFetchStateMachine = (organizationId: string, patientId: string) =>
  tableStateMachine(
    (context?: TableContext<TestListResponseModel, GetHcpPatientsMeasurementsFilters>) =>
      fetchMeasurements(organizationId, patientId, context),
  );

export function HcpPatientsMeasurementsLaboratoryContextProvider({ children }: PropsWithChildren) {
  const { organizationId = "", hcpId = "" } = useParams<RouteParams["hcpDetails"]>();

  const tableMachine = useMemo(
    () => makeTableFetchStateMachine(organizationId, hcpId),
    [organizationId, hcpId],
  );
  const tableService = useMachine(tableMachine);
  const [, send] = tableService;

  const fetch = useCallback(() => {
    send({ type: "FETCH" });
  }, [send]);

  const value = useMemo(() => ({ tableService, fetch }), [fetch, tableService]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
