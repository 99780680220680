import { IconType } from "../Icon";

export type ToastTypes = "success" | "error" | "information" | "warning";

export interface ToastMsgBase {
  msg: string | string[];
  icon?: JSX.Element;
  /**
   * The css transition needs about 1sec to finish
   * thus css animation will be expire - 1000 ms
   */
  expire?: number;
  onClose?: () => void;
}

export const TOAST_ICON_MAP: Record<ToastTypes, IconType> = {
  information: "Info",
  success: "CheckCircle",
  warning: "AlertCircle",
  error: "CloseCircle",
} as const;

export interface ToastMsg extends ToastMsgBase {
  type: ToastTypes;
}

export interface ToastMsgInternal extends ToastMsg {
  readonly uuid: string;
}
