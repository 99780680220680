import * as React from "react";
import { SVGProps } from "react";
const SvgCreatinineEgfr = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.608 6.084s1.15 2.368.565 5.844c-.585 3.476-3.123 4.615-4.818 4.326-1.696-.29-2.078-1.374-1.768-2.954.31-1.58.582-2.219.168-3.048-.413-.83-1.342-3.267.012-5.29 1.128-1.685 4.586-1.81 5.84 1.122Z"
      fill="url(#creatinine-egfr_svg__a)"
    />
    <path
      d="M14.446 13.343a.428.428 0 0 1-.384-.616c.832-1.698.013-3.32.005-3.337a.428.428 0 0 1 .758-.396c.042.08 1.033 2.013.005 4.11a.428.428 0 0 1-.384.24Z"
      fill="#CFBCFF"
    />
    <path
      d="M13.55 18.887a.428.428 0 0 1-.427-.427v-5.933c0-.79.425-1.526 1.11-1.921l.418-.242a.428.428 0 0 1 .428.74l-.419.243c-.42.243-.682.695-.682 1.18v5.933a.428.428 0 0 1-.427.427Z"
      fill="#CFBCFF"
    />
    <path
      d="M11.743 4.164c0-.073-.044-.143-.134-.195-.2-.115-.547-.1-.777.033-.129.074-.194.17-.19.26v16.473c.003.07.047.137.134.187.2.115.547.101.777-.032.122-.07.186-.16.19-.245V4.163Z"
      fill="url(#creatinine-egfr_svg__b)"
    />
    <path
      d="M10.832 4.002c-.23.132-.255.333-.056.448.2.115.547.1.777-.032.23-.133.255-.334.056-.449-.2-.115-.547-.1-.777.033Z"
      fill="#E6BDFF"
    />
    <path
      d="M12.76 9.496a.428.428 0 0 1-.215-.798l1.449-.837a.428.428 0 1 1 .428.741l-1.449.837a.426.426 0 0 1-.214.057Z"
      fill="url(#creatinine-egfr_svg__c)"
    />
    <path
      d="M13.31 3.272c0-.073-.045-.142-.135-.194-.199-.115-.546-.1-.776.032-.13.074-.194.17-.19.26v16.473c.002.07.047.137.134.188.199.115.547.1.777-.032.122-.071.186-.16.19-.246V3.273Z"
      fill="url(#creatinine-egfr_svg__d)"
    />
    <path
      d="M12.399 3.11c-.23.133-.255.333-.056.448.199.115.547.1.777-.032.23-.133.255-.333.055-.448-.199-.115-.546-.1-.776.032Z"
      fill="#A4EBEC"
    />
    <path
      d="M10.45 20.678a.428.428 0 0 1-.429-.428v-5.864a.205.205 0 0 0-.104-.18.205.205 0 0 0-.208 0l-.734.423a.428.428 0 0 1-.428-.741l.734-.424a1.053 1.053 0 0 1 1.064 0c.333.193.532.537.532.922v5.864a.428.428 0 0 1-.428.428Z"
      fill="#CFBCFF"
    />
    <path
      d="M8.099 12.146a.428.428 0 0 1-.215-.798l2.771-1.6a.428.428 0 0 1 .428.741l-2.77 1.6a.426.426 0 0 1-.214.057Z"
      fill="url(#creatinine-egfr_svg__e)"
    />
    <path
      d="M3.299 12.232s-1.054 3.78.798 6.419c1.39 1.98 3.812 2.544 5.22 1.768 1.49-.82 1.74-2.396 1.313-3.565-.428-1.17-1.084-1.769-.828-3.48.257-1.712.462-4.331-1.825-4.85-2.357-.534-4.13 1.555-4.678 3.709Z"
      fill="url(#creatinine-egfr_svg__f)"
    />
    <defs>
      <linearGradient
        id="creatinine-egfr_svg__a"
        x1={16.699}
        y1={19.761}
        x2={16.699}
        y2={5.301}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5331A8" />
        <stop offset={1} stopColor="#9C75FE" />
      </linearGradient>
      <linearGradient
        id="creatinine-egfr_svg__b"
        x1={11.193}
        y1={20.88}
        x2={11.193}
        y2={1.981}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A149D6" />
        <stop offset={0.41} stopColor="#C15BFF" />
        <stop offset={1} stopColor="#DA9DFF" />
      </linearGradient>
      <linearGradient
        id="creatinine-egfr_svg__c"
        x1={13.204}
        y1={8.826}
        x2={14.909}
        y2={7.754}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3FD5D7" />
        <stop offset={0.406} stopColor="#61DCDE" />
        <stop offset={1} stopColor="#83E4E5" />
      </linearGradient>
      <linearGradient
        id="creatinine-egfr_svg__d"
        x1={12.759}
        y1={13.547}
        x2={12.759}
        y2={-0.375}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28BEC0" />
        <stop offset={0.396} stopColor="#3FD5D7" />
        <stop offset={1} stopColor="#83E4E5" />
      </linearGradient>
      <linearGradient
        id="creatinine-egfr_svg__e"
        x1={7.67}
        y1={10.918}
        x2={11.297}
        y2={10.918}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A149D6" />
        <stop offset={0.41} stopColor="#C15BFF" />
        <stop offset={1} stopColor="#DA9DFF" />
      </linearGradient>
      <linearGradient
        id="creatinine-egfr_svg__f"
        x1={6.909}
        y1={19.761}
        x2={6.909}
        y2={5.301}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5331A8" />
        <stop offset={1} stopColor="#9C75FE" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgCreatinineEgfr;
