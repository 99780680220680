import * as React from "react";
import { SVGProps } from "react";
const SvgCornerUpLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.53 4.53a.75.75 0 0 0-1.06-1.06l-5 5a.748.748 0 0 0 0 1.06l5 5a.75.75 0 0 0 1.06-1.06L5.81 9.75H16A3.25 3.25 0 0 1 19.25 13v7a.75.75 0 0 0 1.5 0v-7A4.75 4.75 0 0 0 16 8.25H5.81l3.72-3.72Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCornerUpLeft;
