import classNames from "classnames";
import React, { PropsWithChildren, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { Icon } from "../Icon";
import "./EmptyState.scss";

interface EmptyStateProps {
  className?: string;
  title: string;
  description?: string;
  onCreate?: () => void;
}

export function EmptyState({
  className,
  title,
  description,
  onCreate,
  children,
}: PropsWithChildren<EmptyStateProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "ui-components.EmptyState" });
  const divRef = useRef<HTMLDivElement>(null);
  const showButton = !!onCreate;

  return (
    <div data-testid="EmptyState" className={classNames("EmptyState", className)}>
      {React.isValidElement(children) ? (
        React.cloneElement(children as any, { className: classNames("EmptyState-icon") })
      ) : (
        <Icon icon="Folder" className="EmptyState-icon" />
      )}
      <h3 className="EmptyState-heading">{title}</h3>
      {description ? <p className="EmptyState-description">{description}</p> : null}
      {showButton ? (
        <div className="EmptyState-actions" ref={divRef}>
          <Button
            buttonSize="medium"
            buttonType="primary"
            buttonIcon={{ icon: "Plus" }}
            onClick={onCreate}
          >
            {t("add")}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
