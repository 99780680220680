/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TrendDetailsFieldModel } from './TrendDetailsFieldModel';
import {
    TrendDetailsFieldModelFromJSON,
    TrendDetailsFieldModelFromJSONTyped,
    TrendDetailsFieldModelToJSON,
} from './TrendDetailsFieldModel';

/**
 * 
 * @export
 * @interface TablesPatientModel
 */
export interface TablesPatientModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof TablesPatientModel
     */
    filterOptions: Array<string>;
    /**
     * 
     * @type {TrendDetailsFieldModel}
     * @memberof TablesPatientModel
     */
    trendDetailsField: TrendDetailsFieldModel;
}

/**
 * Check if a given object implements the TablesPatientModel interface.
 */
export function instanceOfTablesPatientModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filterOptions" in value;
    isInstance = isInstance && "trendDetailsField" in value;

    return isInstance;
}

export function TablesPatientModelFromJSON(json: any): TablesPatientModel {
    return TablesPatientModelFromJSONTyped(json, false);
}

export function TablesPatientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TablesPatientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filterOptions': json['filterOptions'],
        'trendDetailsField': TrendDetailsFieldModelFromJSON(json['TrendDetailsField']),
    };
}

export function TablesPatientModelToJSON(value?: TablesPatientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filterOptions': value.filterOptions,
        'TrendDetailsField': TrendDetailsFieldModelToJSON(value.trendDetailsField),
    };
}

