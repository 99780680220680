import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { RangeTableCell } from "../../RangeTableCell";
import { getRange } from "../../helper";
import { RowProps } from "../model";
import { resolveInRange } from "../helper";

export function StepsRow({ labels, steps, value }: RowProps) {
  const { numberFormatter } = useLocaleNumberFormatter();

  return (
    <tr className="StepsRow">
      {labels.map((label, index) => {
        const [from, to, active] = resolveInRange(steps, value, index);

        return (
          <RangeTableCell cellCount={labels.length} key={label} active={active}>
            {getRange(numberFormatter({ value: from }), numberFormatter({ value: to }))}
          </RangeTableCell>
        );
      })}
    </tr>
  );
}
