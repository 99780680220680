/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PropertyContextModel } from './PropertyContextModel';
import {
    PropertyContextModelFromJSON,
    PropertyContextModelFromJSONTyped,
    PropertyContextModelToJSON,
} from './PropertyContextModel';
import type { VisibilityType } from './VisibilityType';
import {
    VisibilityTypeFromJSON,
    VisibilityTypeFromJSONTyped,
    VisibilityTypeToJSON,
} from './VisibilityType';

/**
 * 
 * @export
 * @interface ZipCodeModel
 */
export interface ZipCodeModel {
    /**
     * 
     * @type {{ [key: string]: PropertyContextModel; }}
     * @memberof ZipCodeModel
     */
    contexts: { [key: string]: PropertyContextModel; };
    /**
     * 
     * @type {VisibilityType}
     * @memberof ZipCodeModel
     */
    visibility?: VisibilityType;
}

/**
 * Check if a given object implements the ZipCodeModel interface.
 */
export function instanceOfZipCodeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contexts" in value;

    return isInstance;
}

export function ZipCodeModelFromJSON(json: any): ZipCodeModel {
    return ZipCodeModelFromJSONTyped(json, false);
}

export function ZipCodeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZipCodeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contexts': (mapValues(json['Contexts'], PropertyContextModelFromJSON)),
        'visibility': !exists(json, 'Visibility') ? undefined : VisibilityTypeFromJSON(json['Visibility']),
    };
}

export function ZipCodeModelToJSON(value?: ZipCodeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Contexts': (mapValues(value.contexts, PropertyContextModelToJSON)),
        'Visibility': VisibilityTypeToJSON(value.visibility),
    };
}

