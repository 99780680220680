import { createContext, useContext } from "react";

export function createSafeContext<T>(defaultValue?: T) {
  const context = createContext<T | undefined>(defaultValue);

  const useSafeContext = () => {
    const value = useContext(context);
    if (!value) {
      throw new Error("useContext must be inside a Provider with a value");
    }

    return value;
  };

  const useUnSafeContext = () => {
    const value = useContext(context);

    return value;
  };

  return {
    hook: useSafeContext,
    hookUnsafe: useUnSafeContext,
    Provider: context.Provider,
  } as const;
}
