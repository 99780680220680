import * as React from "react";
import { SVGProps } from "react";
const SvgBloodPressure = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.853 12.835h-2.37l1.11 2.31 1.26-2.31Z" fill="url(#blood-pressure_svg__a)" />
    <path
      d="M9.989 12.835h1.2l1.842 3.836a.589.589 0 0 0 1.043.026L16.092 13H19.2c.062.3.095.586.095.853a7.147 7.147 0 0 1-14.295 0c0-.267.033-.553.094-.853h2.209l.765 1.259c.222.365.76.377.995.012l.926-1.436Z"
      fill="url(#blood-pressure_svg__b)"
    />
    <path d="M8.602 12.835h-.036l.018.03.018-.03Z" fill="url(#blood-pressure_svg__c)" />
    <path
      d="M12.147 21c3.947 0 7.147-3.2 7.147-7.147 0-.267-.032-.553-.093-.853h-3.11l-2.017 3.697a.589.589 0 0 1-1.043-.026l-2.354-4.903-1.614 2.503a.589.589 0 0 1-.995-.012L7.303 13h-2.21c-.06.3-.093.586-.093.853C5 17.8 8.2 21 12.147 21Z"
      fill="url(#blood-pressure_svg__d)"
    />
    <path
      d="M5.434 11.846h2.2c.205 0 .395.106.501.28l.449.738 1.675-2.597a.589.589 0 0 1 1.022.063l2.312 4.815 1.635-2.995a.586.586 0 0 1 .514-.304h3.118c-1.014-2.715-3.575-6.114-5.225-8.14a1.92 1.92 0 0 0-2.976 0c-1.65 2.026-4.21 5.425-5.225 8.14Z"
      fill="url(#blood-pressure_svg__e)"
    />
    <defs>
      <linearGradient
        id="blood-pressure_svg__a"
        x1={12.147}
        y1={16.906}
        x2={12.147}
        y2={22.058}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF4444" stopOpacity={0} />
        <stop offset={1} stopColor="#DC2626" />
      </linearGradient>
      <linearGradient
        id="blood-pressure_svg__b"
        x1={12.147}
        y1={16.906}
        x2={12.147}
        y2={22.058}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF4444" stopOpacity={0} />
        <stop offset={1} stopColor="#DC2626" />
      </linearGradient>
      <linearGradient
        id="blood-pressure_svg__c"
        x1={12.147}
        y1={16.906}
        x2={12.147}
        y2={22.058}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF4444" stopOpacity={0} />
        <stop offset={1} stopColor="#DC2626" />
      </linearGradient>
      <linearGradient
        id="blood-pressure_svg__d"
        x1={8.677}
        y1={9.612}
        x2={15.336}
        y2={16.271}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F87171" />
        <stop offset={1} stopColor="#DC2626" />
      </linearGradient>
      <linearGradient
        id="blood-pressure_svg__e"
        x1={8.677}
        y1={9.612}
        x2={15.336}
        y2={16.271}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F87171" />
        <stop offset={1} stopColor="#DC2626" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgBloodPressure;
