import { TestMethodType } from "api/config_service/models/TestMethodType";
import i18n from "i18next";

export const testMethodToSelectOptions = () => {
  const testMethodUnits = Object.keys(TestMethodType);

  return testMethodUnits.map(key => ({
    title: i18n.t(`RuleEngine.TestMethod.${key}`, { ns: "translation" }),
    value: TestMethodType[key as keyof typeof TestMethodType],
  }));
};
