import * as AddModal from "components/AddModal/Device";
import { AllDevicesTable } from "components/AppTables/AllDevicesTable";
import { useDevicesTableStateContext } from "components/AppTables/AllDevicesTable/context";
import { ParentPage } from "components/PageTemplate/ParentPage";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useEffect } from "react";
import { usePagesParentTableConfig } from "utils/hooks/Pages/usePagesParentTableConfig";
import { useHasAccess } from "utils/hooks/useHasAccess";

export function Parent() {
  const tableContext = useDevicesTableStateContext();
  const [state, send] = tableContext.tableService;
  const can = useHasAccess();
  const { isPatient } = useAdditionalAuthInformationContext();

  /**
   * ! If you supply {children} for ParentPage
   * ! It can mean this state init here is not the right place
   * ! check TestsRoutes.tsx, there the EventRichTable must fire the init event
   */
  useEffect(() => {
    if (state.matches("init")) {
      send({ type: "FETCH" });
    }
  }, [send, state]);

  const { isTableEmpty, onFilterToggle, onSearchChange } = usePagesParentTableConfig(
    tableContext,
    "items",
  );

  return (
    <ParentPage
      variant="Devices"
      AddModal={
        can("manage-device") ? <AddModal.Device onSuccessCb={tableContext.fetch} /> : undefined
      }
      isEmptyState={isTableEmpty}
      isTableFailed={state.matches("failure")}
      isTableLoading={state.matches("loading")}
      onFilterToggle={onFilterToggle}
      searchField={{
        onChange: onSearchChange,
        value: state.context.filters?.contains ?? "",
      }}
      filtersCount={state.context.countFilters}
      onRefreshTable={tableContext.fetch}
      showDescription={isPatient === false}
    >
      <AllDevicesTable />
    </ParentPage>
  );
}
