import { TestsTableColumns } from "./model";

type theadSuffixesProps = {
  [key in TestsTableColumns]?: string;
};
interface TestsTableColumnsProps {
  t: TFunction;
  theadSuffixes?: theadSuffixesProps;
}

interface AppliedColumnsProps extends TestsTableColumnsProps {
  appliedColumns: TestsTableColumns[];
}

export function resolveAppliedColumns({ appliedColumns, t, theadSuffixes }: AppliedColumnsProps) {
  return appliedColumns.map(name => ({
    label: t(`columns.${name}`) + (theadSuffixes?.[name] ? `\n ${theadSuffixes[name]}` : ""),
    name,
  }));
}
