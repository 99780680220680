import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../Icon";
import "./Breadcrumb.scss";
import { BreadCrumb } from "./model";
import { Avatar } from "../Avatar";
export * from "./model";

export const BreadCrumbs: React.FC<{ breadCrumbs?: BreadCrumb[] }> = ({ breadCrumbs }) => {
  const navigate = useNavigate();

  if (breadCrumbs === undefined) {
    return null;
  }
  // Home without /
  if (breadCrumbs.length === 1) {
    return (
      <div className="BreadCrumbs">
        <Icon onClick={() => navigate(-1)} icon="ArrowLeft" />
        <span>{breadCrumbs[0].title}</span>
      </div>
    );
  }

  return (
    <div className="BreadCrumbs">
      {breadCrumbs.map((breadCrumb, idx) => {
        return (
          <React.Fragment key={idx}>
            {breadCrumb.path ? (
              <>
                {idx === 0 && <Icon onClick={() => navigate(-1)} icon="ArrowLeft" />}
                <Link className="BreadCrumbs__link" to={breadCrumb.path}>
                  {breadCrumb.title}
                </Link>
              </>
            ) : (
              <>
                {breadCrumb.avatarImg ? (
                  <Avatar avatarLoading={false} size={"extraSmall"} src={breadCrumb.avatarImg} />
                ) : null}
                <span>{breadCrumb.title}</span>
              </>
            )}
            {idx !== breadCrumbs.length - 1 && <Icon icon="ChevronRight" />}
          </React.Fragment>
        );
      })}
    </div>
  );
};
