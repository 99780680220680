import { PropsWithChildren } from "react";
import classNames from "classnames";
import { Icon } from "../Icon";
import "./Chip.scss";

export type ChipType = "primary" | "secondary" | "red" | "green" | "grey";
type ChipSize = "small" | "medium" | "large";

interface ChipProps {
  size: ChipSize;
  variant: ChipType;
  loading?: boolean;
  className?: string;
  onClick?: () => void;
}

const closeButtonSizeMap = {
  small: 14,
  medium: 18,
  large: 22,
} as const;

export function Chip({
  children,
  size,
  variant,
  loading,
  className,
  onClick,
}: PropsWithChildren<ChipProps>) {
  if (loading) {
    return null;
  }
  return (
    <div
      className={classNames(
        "UI-Components Chip",
        `Chip--${size}`,
        onClick && size !== "small" && "Chip--spacing",
        `Chip--${variant}`,
        className,
      )}
    >
      {children}
      {onClick && (
        <Icon
          icon="Close"
          scaleTo={closeButtonSizeMap[size]}
          onClick={onClick}
          className="Chip__icon"
        />
      )}
    </div>
  );
}
