/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GenderType } from './GenderType';
import {
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import type { MeasurementGraphModel } from './MeasurementGraphModel';
import {
    MeasurementGraphModelFromJSON,
    MeasurementGraphModelFromJSONTyped,
    MeasurementGraphModelToJSON,
} from './MeasurementGraphModel';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';

/**
 * 
 * @export
 * @interface UserGraphModel
 */
export interface UserGraphModel {
    /**
     * 
     * @type {string}
     * @memberof UserGraphModel
     */
    id: string;
    /**
     * 
     * @type {UserRoleType}
     * @memberof UserGraphModel
     */
    roleType: UserRoleType;
    /**
     * 
     * @type {GenderType}
     * @memberof UserGraphModel
     */
    genderType: GenderType;
    /**
     * 
     * @type {Array<MeasurementGraphModel>}
     * @memberof UserGraphModel
     */
    measurements: Array<MeasurementGraphModel>;
}

/**
 * Check if a given object implements the UserGraphModel interface.
 */
export function instanceOfUserGraphModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "roleType" in value;
    isInstance = isInstance && "genderType" in value;
    isInstance = isInstance && "measurements" in value;

    return isInstance;
}

export function UserGraphModelFromJSON(json: any): UserGraphModel {
    return UserGraphModelFromJSONTyped(json, false);
}

export function UserGraphModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGraphModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'roleType': UserRoleTypeFromJSON(json['RoleType']),
        'genderType': GenderTypeFromJSON(json['GenderType']),
        'measurements': ((json['Measurements'] as Array<any>).map(MeasurementGraphModelFromJSON)),
    };
}

export function UserGraphModelToJSON(value?: UserGraphModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'RoleType': UserRoleTypeToJSON(value.roleType),
        'GenderType': GenderTypeToJSON(value.genderType),
        'Measurements': ((value.measurements as Array<any>).map(MeasurementGraphModelToJSON)),
    };
}

