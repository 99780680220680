import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { InputNumber } from "libs/ui/InputNumber";
import { MeasurementItemUIModel, MeasurementItemUIType } from "models/TestModels";
import { useCallback } from "react";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { useTranslation } from "react-i18next";
import { ValidationErrorMessage } from "..";
import { DECIMAL_NUMBER_ANY_MEASUREMENT } from "utils/regex";

interface UACRFormProps {
  items: MeasurementItemUIModel[];
  onInput: (value: number | null, name: MeasurementItemUIType) => void;
  readOnly?: boolean;
  loading?: boolean;
  fieldErrors?: ValidationErrorMessage[];
}

export function UACRForm({ items, readOnly, loading, onInput, fieldErrors }: UACRFormProps) {
  const { t } = useTranslation("translation", { keyPrefix: "SingleMeasurementField" });
  const { globalPreference } = useGlobalPreferenceContext();
  const { urineAlbuminUnit, urineCreatinineUnit } = useGetUserPreferredMeasurementUnits();

  const numberInputValidation = useCallback(
    (value: any) => DECIMAL_NUMBER_ANY_MEASUREMENT.test(value) || value === "" || value === null,
    [],
  );

  const getConvertedToNumberOrEmptyString = useCallback((value: string) => {
    if (value === "") {
      return "";
    }

    if (Number.isNaN(value)) {
      throw new Error(`${value} can't be converted to number`);
    }

    return Number(value);
  }, []);

  return (
    <>
      {items.map(item => (
        <InputNumber
          className="UACRForm-Field"
          key={item.type}
          data-testid={item.type}
          onNumberChange={value => onInput(value, item.type ?? "Uacr")}
          validateInput={numberInputValidation}
          label={t(`label${item.type}`)}
          value={getConvertedToNumberOrEmptyString(item.value)}
          readOnly={readOnly}
          suffix={item.unit === "mgL" ? urineAlbuminUnit : urineCreatinineUnit}
          loading={loading}
          region={globalPreference?.regionType}
          formattingOptions={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
          }}
          validation={{ errorText: fieldErrors?.find(el => el.type === item.type)?.msg }}
        />
      ))}
    </>
  );
}
