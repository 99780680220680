/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BmiStatusTypeStatusLimitsLimitsValue } from './BmiStatusTypeStatusLimitsLimitsValue';
import {
    BmiStatusTypeStatusLimitsLimitsValueFromJSON,
    BmiStatusTypeStatusLimitsLimitsValueFromJSONTyped,
    BmiStatusTypeStatusLimitsLimitsValueToJSON,
} from './BmiStatusTypeStatusLimitsLimitsValue';
import type { MeasurementUnitType } from './MeasurementUnitType';
import {
    MeasurementUnitTypeFromJSON,
    MeasurementUnitTypeFromJSONTyped,
    MeasurementUnitTypeToJSON,
} from './MeasurementUnitType';

/**
 * 
 * @export
 * @interface UacrStatusTypeStatusLimits
 */
export interface UacrStatusTypeStatusLimits {
    /**
     * 
     * @type {MeasurementUnitType}
     * @memberof UacrStatusTypeStatusLimits
     */
    unit?: MeasurementUnitType;
    /**
     * 
     * @type {{ [key: string]: BmiStatusTypeStatusLimitsLimitsValue; }}
     * @memberof UacrStatusTypeStatusLimits
     */
    limits?: { [key: string]: BmiStatusTypeStatusLimitsLimitsValue; } | null;
}

/**
 * Check if a given object implements the UacrStatusTypeStatusLimits interface.
 */
export function instanceOfUacrStatusTypeStatusLimits(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UacrStatusTypeStatusLimitsFromJSON(json: any): UacrStatusTypeStatusLimits {
    return UacrStatusTypeStatusLimitsFromJSONTyped(json, false);
}

export function UacrStatusTypeStatusLimitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UacrStatusTypeStatusLimits {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unit': !exists(json, 'Unit') ? undefined : MeasurementUnitTypeFromJSON(json['Unit']),
        'limits': !exists(json, 'Limits') ? undefined : (json['Limits'] === null ? null : mapValues(json['Limits'], BmiStatusTypeStatusLimitsLimitsValueFromJSON)),
    };
}

export function UacrStatusTypeStatusLimitsToJSON(value?: UacrStatusTypeStatusLimits | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Unit': MeasurementUnitTypeToJSON(value.unit),
        'Limits': value.limits === undefined ? undefined : (value.limits === null ? null : mapValues(value.limits, BmiStatusTypeStatusLimitsLimitsValueToJSON)),
    };
}

