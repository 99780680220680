/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceType } from './DeviceType';
import {
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';
import type { ManufacturerType } from './ManufacturerType';
import {
    ManufacturerTypeFromJSON,
    ManufacturerTypeFromJSONTyped,
    ManufacturerTypeToJSON,
} from './ManufacturerType';

/**
 * 
 * @export
 * @interface AddDeviceRequestModel
 */
export interface AddDeviceRequestModel {
    /**
     * 
     * @type {ManufacturerType}
     * @memberof AddDeviceRequestModel
     */
    manufacturerType: ManufacturerType;
    /**
     * 
     * @type {DeviceType}
     * @memberof AddDeviceRequestModel
     */
    deviceType: DeviceType;
    /**
     * 
     * @type {string}
     * @memberof AddDeviceRequestModel
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AddDeviceRequestModel
     */
    uniqueId?: string | null;
}

/**
 * Check if a given object implements the AddDeviceRequestModel interface.
 */
export function instanceOfAddDeviceRequestModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "manufacturerType" in value;
    isInstance = isInstance && "deviceType" in value;
    isInstance = isInstance && "serialNumber" in value;

    return isInstance;
}

export function AddDeviceRequestModelFromJSON(json: any): AddDeviceRequestModel {
    return AddDeviceRequestModelFromJSONTyped(json, false);
}

export function AddDeviceRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddDeviceRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'manufacturerType': ManufacturerTypeFromJSON(json['ManufacturerType']),
        'deviceType': DeviceTypeFromJSON(json['DeviceType']),
        'serialNumber': json['SerialNumber'],
        'uniqueId': !exists(json, 'UniqueId') ? undefined : json['UniqueId'],
    };
}

export function AddDeviceRequestModelToJSON(value?: AddDeviceRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ManufacturerType': ManufacturerTypeToJSON(value.manufacturerType),
        'DeviceType': DeviceTypeToJSON(value.deviceType),
        'SerialNumber': value.serialNumber,
        'UniqueId': value.uniqueId,
    };
}

