import { useTranslation } from "react-i18next";
import "./NoReports.scss";

export function NoReports() {
  const { t } = useTranslation("translation", { keyPrefix: "ReportPage.AllReports.NoReports" });

  return (
    <div className="NoReports">
      <svg
        width="92"
        height="80"
        viewBox="0 0 92 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.9935 5.03812C27.9935 2.25568 25.7378 1.03882e-08 22.9554 1.03882e-08H5.96342C3.18098 -0.000176753 0.925293 2.25551 0.925293 5.03812V72.535C0.925293 76.6579 4.26754 79.9999 8.3902 79.9999H70.1181C73.4465 79.9999 76.1448 77.3016 76.1448 73.9732V16.103C76.1448 12.7745 73.4465 10.0762 70.1181 10.0762H33.0316C30.249 10.0762 27.9935 7.82056 27.9935 5.03812Z"
          fill="url(#paint0_linear_29856_18991)"
        />
        <path
          d="M76.1448 73.9733V32.929H0.925293V72.535C0.925293 76.6578 4.26754 79.9999 8.3902 79.9999H70.1181C73.4467 79.9999 76.1448 77.3016 76.1448 73.9733Z"
          fill="url(#paint1_linear_29856_18991)"
        />
        <path
          d="M70.1183 10.0762H61.2156V79.9999H70.1183C73.4467 79.9999 76.145 77.3016 76.145 73.9731V16.1029C76.1449 12.7745 73.4467 10.0762 70.1183 10.0762Z"
          fill="url(#paint2_linear_29856_18991)"
        />
        <path
          d="M81.3277 72.3513C80.3938 76.808 76.4638 79.9999 71.9102 79.9999H8.39014C12.6153 79.9999 16.262 77.0382 17.1285 72.9028L27.0454 25.5753C27.6104 22.8795 29.9875 20.9487 32.742 20.9487H86.6398C89.454 20.9487 91.5561 23.5369 90.979 26.2914L81.3277 72.3513Z"
          fill="url(#paint3_linear_29856_18991)"
        />
        <path
          d="M90.9794 26.2921L81.3281 72.3514C80.393 76.8076 76.4636 80 71.9101 80H63.5527L75.9244 20.9485H86.6399C89.4539 20.9485 91.5557 23.5363 90.9794 26.2921Z"
          fill="url(#paint4_linear_29856_18991)"
        />
        <path
          d="M81.3277 72.3515L87.2548 44.0647H23.1714L17.1287 72.903C16.2622 77.0384 12.6156 80.0001 8.39038 80.0001H71.9103C76.4637 80.0001 80.3939 76.8083 81.3277 72.3515Z"
          fill="url(#paint5_linear_29856_18991)"
        />
        <path
          d="M76.1449 73.9733V20.9487H40.874V79.9999H70.1184C73.4468 79.9999 76.1449 77.3016 76.1449 73.9733Z"
          fill="url(#paint6_linear_29856_18991)"
        />
        <path
          d="M87.2548 44.0646L90.9789 26.2915C91.556 23.5373 89.4543 20.9493 86.6406 20.949H32.741C29.987 20.9495 27.6103 22.8799 27.0455 25.5756L23.1714 44.0647H87.2548V44.0646Z"
          fill="url(#paint7_linear_29856_18991)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_29856_18991"
            x1="7.67838"
            y1="13.7602"
            x2="58.0764"
            y2="64.1582"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#B699FE" />
            <stop offset="1" stop-color="#8352FE" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_29856_18991"
            x1="38.5352"
            y1="47.7679"
            x2="38.5352"
            y2="80.807"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6B42D3" stop-opacity="0" />
            <stop offset="1" stop-color="#6B42D3" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_29856_18991"
            x1="68.8976"
            y1="45.0381"
            x2="78.5017"
            y2="45.0381"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6B42D3" stop-opacity="0" />
            <stop offset="1" stop-color="#6B42D3" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_29856_18991"
            x1="38.3003"
            y1="21.2853"
            x2="59.442"
            y2="78.6321"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#B699FE" />
            <stop offset="1" stop-color="#8352FE" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_29856_18991"
            x1="77.0838"
            y1="50.923"
            x2="89.1038"
            y2="54.1048"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6B42D3" stop-opacity="0" />
            <stop offset="1" stop-color="#6B42D3" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_29856_18991"
            x1="47.8225"
            y1="66.2646"
            x2="47.8225"
            y2="83.3266"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6B42D3" stop-opacity="0" />
            <stop offset="1" stop-color="#6B42D3" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_29856_18991"
            x1="59.7319"
            y1="49.4082"
            x2="99.5356"
            y2="38.3002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6B42D3" stop-opacity="0" />
            <stop offset="1" stop-color="#6B42D3" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_29856_18991"
            x1="56.7797"
            y1="32.5921"
            x2="53.5979"
            y2="14.68"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0" />
            <stop offset="1" stop-color="white" />
          </linearGradient>
        </defs>
      </svg>
      <p className="NoReports__title">{t("title")}</p>
      <p className="NoReports__description">{t("description")}</p>
    </div>
  );
}
