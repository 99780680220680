import { IconType } from "libs/ui/Icon";
import { UAParser } from "ua-parser-js";

export function getTrackingDeviceSystemDetails(deviceUserAgent: string) {
  const parsedUA = UAParser(deviceUserAgent);

  const osString =
    parsedUA.os.name && parsedUA.os.version ? `${parsedUA.os.name} ${parsedUA.os.version}` : null;

  const weHaveBrowserInfo = !!parsedUA.browser.name;
  const weHaveMobileInfo =
    parsedUA.device.type === "mobile"
      ? [parsedUA.device.vendor, parsedUA.device.model].filter(v => v).join(" ")
      : null;

  if (weHaveMobileInfo) {
    return weHaveMobileInfo + ` • ${parsedUA.browser.name ?? "Mobile App"}`;
  }

  if (weHaveBrowserInfo) {
    return `${osString ? osString + " •" : ""} ${parsedUA.browser.name}`;
  }

  return deviceUserAgent;
}

export function getTrackingDeviceIcon(deviceUserAgent?: string | null): IconType {
  if (!deviceUserAgent) {
    return "Computer";
  }

  const parsedUA = UAParser(deviceUserAgent);

  if (parsedUA.device.type === "mobile") {
    return "Smartphone";
  }

  switch (parsedUA.os.name) {
    case "Android":
    case "iOS":
      return parsedUA.device.model === "iPad" ? "Tablet" : "Smartphone";

    case "Mac OS":
      if (!parsedUA.browser.name) {
        return "Smartphone";
      }
      return parsedUA.browser.name === "Mobile Safari" ? "Tablet" : "Computer";

    case "Windows":
    case "Linux":
      return "Computer";

    case "iPadOS":
      return "Tablet";

    default:
      return "Smartphone";
  }
}
