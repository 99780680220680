import {
  AddAdminRequestModel,
  AddDeviceRequestModel,
  AddHcpRequestModel,
  AddOrganizationRequestModel,
  AddPartnerRequestModel,
  AddPatientRequestModel,
  ReplaceAdminRequestModel,
  ReplacePartnerRequestModel,
  UpdateHcpRequestModel,
  UpdateOrganizationRequestModel,
  UpdatePatientRequestModel,
} from "api/event";
import { LoadableModel, isLoaded } from "models/loadable";
import {
  AdditionalPropertyConfigField,
  ConfigFieldContextLikeObject,
  ConfigFieldModel,
  FieldVisibilityType,
  GlobalConfig,
  isConfigFieldContextLikeObject,
} from "utils/createGlobalConfigStore";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

export type BE_EVENT_API_BODY<T extends Record<string, any>> = Omit<
  {
    [key in Capitalize<Extract<keyof T, string>>]: FieldVisibilityType;
  },
  "AdditionalProperties"
> & { AdditionalProperties?: Record<string, FieldVisibilityType> };

export type Capitalized_BE_BODY<T extends Record<string, any>> = Exclude<
  {
    [key in Capitalize<Extract<keyof T, string>>]: T[key];
  },
  "AdditionalProperties"
> & { AdditionalProperties?: Record<string, any> };

function getGlobalConfig() {
  const globalConfigFromStore = NotNullOrUndefined<LoadableModel<GlobalConfig>>(
    window.globalConfigStore?.getConfig(),
  );

  if (!isLoaded(globalConfigFromStore)) {
    throw new Error("This should be loaded");
  }

  const globalConfig = globalConfigFromStore.value;
  return globalConfig;
}

function fieldVisibility(field: ConfigFieldModel<any>, context: "Add" | "Update") {
  return context === "Add" ? field.contexts.Add.visibility : field.contexts.Update.visibility;
}

function NotNullOrUndefinedField(
  field: ConfigFieldModel<any>,
  context: "Add" | "Update",
): FieldVisibilityType {
  return NotNullOrUndefined(fieldVisibility(field, context));
}

function additionalPropertiesWithContext(
  addPropConfigField: AdditionalPropertyConfigField[],
  context: "Add" | "Update",
) {
  return addPropConfigField.reduce(
    (prev, ap) => {
      prev[ap.propertyName] = ap.contexts[context].visibility;
      return prev;
    },
    {} as Record<string, FieldVisibilityType>,
  );
}

function prepareStrippingModel<
  T extends Record<string, ConfigFieldContextLikeObject | ConfigFieldContextLikeObject[]>,
>(inputObj: T, context: "Add" | "Update") {
  return new Proxy(inputObj, {
    get: (target, key) => {
      if (key === "additionalProperties") {
        return additionalPropertiesWithContext(
          target[key as string] as AdditionalPropertyConfigField[],
          context,
        );
      }

      if (isConfigFieldContextLikeObject(target[key as string])) {
        return NotNullOrUndefinedField(target[key as string] as ConfigFieldModel<any>, context);
      }

      throw new Error(`Not sure how to handle ${String(key)} property`, {});
    },
  }) as unknown as Record<string, FieldVisibilityType> & {
    additionalProperties: Record<string, FieldVisibilityType>;
  };
}
// Patient models for stripping
export const AddPatientRequestModelForStripping = (): BE_EVENT_API_BODY<AddPatientRequestModel> => {
  const {
    email,
    zipCode,
    ancestry,
    nationalityType,
    hcp,
    deviceField,
    generalPractitioner,
    additionalProperties,
  } = prepareStrippingModel(getGlobalConfig()?.entities?.patient ?? {}, "Add");

  const { registryNumber } = prepareStrippingModel(
    { registryNumber: getGlobalConfig()?.general.registryNumber },
    "Add",
  );

  return {
    FirstName: "Required",
    LastName: "Required",
    Email: email,
    Street: "Required",
    City: "Required",
    Country: "Required",
    State: "Required",
    ZipCode: zipCode,
    Phone: "Required",
    Gender: "Required",
    Ancestry: ancestry,
    DateOfBirth: "Required",
    RegistryNumber: registryNumber,

    NationalityType: nationalityType,

    HcpId: hcp,

    DeviceId: deviceField,
    DeviceRowVersion: deviceField,

    UserStatus: "Required",

    GeneralPractitionerId: generalPractitioner,

    AdditionalProperties: additionalProperties,
  };
};

export const UpdatePatientRequestModelForStripping =
  (): BE_EVENT_API_BODY<UpdatePatientRequestModel> => {
    const {
      zipCode,
      nationalityType,
      hcp,
      deviceField,
      ancestry,
      generalPractitioner,
      additionalProperties,
    } = prepareStrippingModel(getGlobalConfig()?.entities?.patient ?? {}, "Update");

    const { registryNumber } = prepareStrippingModel(
      { registryNumber: getGlobalConfig()?.general.registryNumber },
      "Update",
    );

    return {
      FirstName: "Required",
      LastName: "Required",
      Street: "Required",
      City: "Required",
      Country: "Required",
      State: "Required",
      ZipCode: zipCode,
      Phone: "Required",
      Gender: "Required",
      Ancestry: ancestry,
      DateOfBirth: "Required",
      RegistryNumber: registryNumber,
      NationalityType: nationalityType,

      HcpId: hcp,

      DeviceId: deviceField,
      OldDeviceRowVersion: deviceField,
      NewDeviceRowVersion: deviceField,

      RowVersion: "Required",

      GeneralPractitionerId: generalPractitioner,

      AdditionalProperties: additionalProperties,
    };
  };

// Hcp models for stripping
export const AddHcpRequestModelForStripping = (): BE_EVENT_API_BODY<AddHcpRequestModel> => {
  const { email, zipCode } = prepareStrippingModel(getGlobalConfig()?.entities?.hcp ?? {}, "Add");
  return {
    FirstName: "Required",
    LastName: "Required",
    Email: email,
    Street: "Required",
    City: "Required",
    Country: "Required",
    State: "Required",
    ZipCode: zipCode,
    Phone: "Required",
    DeviceId: "Required",
    DeviceRowVersion: "Required",
    UserStatus: "Required",
    HcpType: "Required",
  };
};

export const UpdateHcpRequestModelForStripping = (): BE_EVENT_API_BODY<UpdateHcpRequestModel> => {
  const { zipCode } = prepareStrippingModel(getGlobalConfig()?.entities?.hcp ?? {}, "Update");
  return {
    FirstName: "Required",
    LastName: "Required",
    Street: "Required",
    City: "Required",
    Country: "Required",
    State: "Required",
    ZipCode: zipCode,
    Phone: "Required",
    DeviceId: "Required",
    OldDeviceRowVersion: "Required",
    NewDeviceRowVersion: "Required",
    RowVersion: "Required",
    HcpType: "Required",
  };
};

// Admin models for stripping
export const AddAdminRequestModelForStripping = (): BE_EVENT_API_BODY<AddAdminRequestModel> => {
  const { email, zipCode } = prepareStrippingModel(getGlobalConfig()?.entities?.admin ?? {}, "Add");
  return {
    FirstName: "Required",
    LastName: "Required",
    Email: email,
    Street: "Required",
    City: "Required",
    Country: "Required",
    State: "Required",
    ZipCode: zipCode,
    Phone: "Required",
    UserStatus: "Required",
  };
};

export const UpdateAdminRequestModelForStripping =
  (): BE_EVENT_API_BODY<ReplaceAdminRequestModel> => {
    const { zipCode } = prepareStrippingModel(getGlobalConfig()?.entities?.admin ?? {}, "Update");
    return {
      FirstName: "Required",
      LastName: "Required",
      Street: "Required",
      City: "Required",
      Country: "Required",
      State: "Required",
      ZipCode: zipCode,
      Phone: "Required",
      RowVersion: "Required",
    };
  };

// Partner models for stripping
export const AddPartnerRequestModelForStripping = (): BE_EVENT_API_BODY<AddPartnerRequestModel> => {
  const { email, zipCode } = prepareStrippingModel(
    getGlobalConfig()?.entities?.partner ?? {},
    "Add",
  );
  return {
    FirstName: "Required",
    LastName: "Required",
    Email: email,
    Street: "Required",
    City: "Required",
    Country: "Required",
    State: "Required",
    ZipCode: zipCode,
    Phone: "Required",
    UserStatus: "Required",
  };
};

export const UpdatePartnerRequestModelForStripping =
  (): BE_EVENT_API_BODY<ReplacePartnerRequestModel> => {
    const { zipCode } = prepareStrippingModel(getGlobalConfig()?.entities?.partner ?? {}, "Update");
    return {
      FirstName: "Required",
      LastName: "Required",
      Street: "Required",
      City: "Required",
      Country: "Required",
      State: "Required",
      ZipCode: zipCode,
      Phone: "Required",
      RowVersion: "Required",
    };
  };

// Organization models for stripping
export const AddOrganizationModelForStripping =
  (): BE_EVENT_API_BODY<AddOrganizationRequestModel> => {
    const { zipCode } = prepareStrippingModel(
      getGlobalConfig()?.entities.organization ?? {},
      "Add",
    );

    return {
      Name: "Required",
      Street: "Required",
      ZipCode: zipCode,
      Phone: "Required",
      City: "Required",
      Country: "Required",
      State: "Required",
      IsMain: "Required",
      Geolocation: "Required",
    };
  };

export const UpdateOrganizationModelForStripping =
  (): BE_EVENT_API_BODY<UpdateOrganizationRequestModel> => {
    const { zipCode } = prepareStrippingModel(
      getGlobalConfig()?.entities?.organization ?? {},
      "Update",
    );

    return {
      Name: "Required",
      Street: "Required",
      ZipCode: zipCode,
      Phone: "Required",
      City: "Required",
      Country: "Required",
      State: "Required",
      IsMain: "Required",
      RowVersion: "Required",
      Geolocation: "Required",
    };
  };

// Device models for stripping
export const AddDeviceModelForStripping = (): BE_EVENT_API_BODY<AddDeviceRequestModel> => {
  const { uniqueId } = prepareStrippingModel(getGlobalConfig()?.entities?.device ?? {}, "Add");

  return {
    ManufacturerType: "Required",
    DeviceType: "Required",
    SerialNumber: "Required",
    UniqueId: uniqueId,
  };
};
