/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MeasurementTrendType } from './MeasurementTrendType';
import {
    MeasurementTrendTypeFromJSON,
    MeasurementTrendTypeFromJSONTyped,
    MeasurementTrendTypeToJSON,
} from './MeasurementTrendType';
import type { MeasurementUnitType } from './MeasurementUnitType';
import {
    MeasurementUnitTypeFromJSON,
    MeasurementUnitTypeFromJSONTyped,
    MeasurementUnitTypeToJSON,
} from './MeasurementUnitType';
import type { UacrStatusType } from './UacrStatusType';
import {
    UacrStatusTypeFromJSON,
    UacrStatusTypeFromJSONTyped,
    UacrStatusTypeToJSON,
} from './UacrStatusType';

/**
 * 
 * @export
 * @interface SemiQuantitativeUacrModel
 */
export interface SemiQuantitativeUacrModel {
    /**
     * 
     * @type {number}
     * @memberof SemiQuantitativeUacrModel
     */
    value: number;
    /**
     * 
     * @type {MeasurementUnitType}
     * @memberof SemiQuantitativeUacrModel
     */
    unit: MeasurementUnitType;
    /**
     * 
     * @type {MeasurementTrendType}
     * @memberof SemiQuantitativeUacrModel
     */
    trendType: MeasurementTrendType;
    /**
     * 
     * @type {UacrStatusType}
     * @memberof SemiQuantitativeUacrModel
     */
    status?: UacrStatusType;
    /**
     * 
     * @type {string}
     * @memberof SemiQuantitativeUacrModel
     */
    bucket?: string | null;
}

/**
 * Check if a given object implements the SemiQuantitativeUacrModel interface.
 */
export function instanceOfSemiQuantitativeUacrModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "unit" in value;
    isInstance = isInstance && "trendType" in value;

    return isInstance;
}

export function SemiQuantitativeUacrModelFromJSON(json: any): SemiQuantitativeUacrModel {
    return SemiQuantitativeUacrModelFromJSONTyped(json, false);
}

export function SemiQuantitativeUacrModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SemiQuantitativeUacrModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['Value'],
        'unit': MeasurementUnitTypeFromJSON(json['Unit']),
        'trendType': MeasurementTrendTypeFromJSON(json['TrendType']),
        'status': !exists(json, 'Status') ? undefined : UacrStatusTypeFromJSON(json['Status']),
        'bucket': !exists(json, 'Bucket') ? undefined : json['Bucket'],
    };
}

export function SemiQuantitativeUacrModelToJSON(value?: SemiQuantitativeUacrModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Value': value.value,
        'Unit': MeasurementUnitTypeToJSON(value.unit),
        'TrendType': MeasurementTrendTypeToJSON(value.trendType),
        'Status': UacrStatusTypeToJSON(value.status),
        'Bucket': value.bucket,
    };
}

