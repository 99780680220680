import { useMemo } from "react";
import { useDashboardMapReportContext } from "../../DashboardMapReportDataContext";
import { isLoaded } from "models/loadable";
import { DashboardMapsGroupBy } from "api/report_service/models/DashboardMapsGroupBy";

import "./EntityChart.scss";
import { useTranslation } from "react-i18next";
import { SingleEntity } from "./SingleEntity";
import { Count } from "./Count";
import { BackgroundGrid } from "./BackgroundGrid";
import { EmptyChart } from "./EmptyChart";
import classNames from "classnames";

interface EntityChartProps {
  groupingOption: DashboardMapsGroupBy;
}

const HIGHEST_NUMBER_ADJUSTMENT = 1.1;

export function EntityChart({ groupingOption }: EntityChartProps) {
  const { reportData } = useDashboardMapReportContext();
  const { t: tCountries } = useTranslation("nationalities", { keyPrefix: "countries" });

  const totalChartData = useMemo(
    () => (isLoaded(reportData.ChartData) ? (reportData.ChartData.value ?? []) : []),
    [reportData.ChartData],
  );

  const dataPerGrouping = useMemo(() => {
    switch (groupingOption) {
      case "PatientAddressCity":
        return totalChartData
          .map(el => ({
            ...el,
            entityName: el.entityCountryISO
              ? `${el.entityName}, ${tCountries(el.entityCountryISO)}`
              : el.entityName,
          }))
          .sort((a, b) => b.categories.Patients - a.categories.Patients);

      case "PatientAddressCountry":
        return totalChartData
          .map(el => ({
            ...el,
            entityName: el.entityCountryISO ? tCountries(el.entityCountryISO) : "",
          }))
          .sort((a, b) => b.categories.Patients - a.categories.Patients);

      default:
        return totalChartData.sort((a, b) => b.categories.Patients - a.categories.Patients);
    }
  }, [groupingOption, tCountries, totalChartData]);

  const highestNumber = dataPerGrouping.reduce(
    (acc, current) => Math.max(acc, current.categories.Patients),
    0,
  );

  const maxNumber = highestNumber <= 5 ? 5 : highestNumber * HIGHEST_NUMBER_ADJUSTMENT;

  const noData = dataPerGrouping.length === 0

  return (
    <div className="EntityChart">
      <Count maxPatientsNumber={maxNumber} />

      <div className="EntityChart__body">
        <BackgroundGrid />

        <div className={classNames("EntityChart__entities", {
          "EntityChart__entities--center": noData
        })}>
          {noData ? <EmptyChart /> : dataPerGrouping.map((el, index) => (
            <SingleEntity
              key={index}
              totalPatients={el.categories.Patients}
              patientWithRisk={el.categories.PatientWithRisk}
              maxPatientsNumber={maxNumber}
              title={el.entityName}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
