import * as React from "react";
import { SVGProps } from "react";
const SvgSignOut = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 7.21a6.251 6.251 0 0 1 5.501-6.205c.412-.05.749.291.749.705 0 .415-.338.744-.747.809A4.751 4.751 0 0 0 3.5 7.21v9a4.751 4.751 0 0 0 4.003 4.692c.409.065.747.394.747.808 0 .415-.337.755-.749.706A6.251 6.251 0 0 1 2 16.21v-9ZM15.72 6.22a.75.75 0 0 1 1.06 0l5 5a.748.748 0 0 1 0 1.06l-5 5a.75.75 0 1 1-1.06-1.06l3.72-3.72H9.25a.75.75 0 0 1 0-1.5h10.19l-3.72-3.72a.75.75 0 0 1 0-1.06Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSignOut;
