import * as React from "react";
import { SVGProps } from "react";
const SvgQuit = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.75 2.75a.75.75 0 0 0-1.5 0v7a.75.75 0 0 0 1.5 0v-7Z" fill="currentColor" />
    <path
      d="M6.16 6.92A.75.75 0 0 0 5.1 5.86a9.75 9.75 0 1 0 13.79 0 .75.75 0 1 0-1.06 1.06 8.25 8.25 0 1 1-11.67 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgQuit;
