/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentsModel } from './ComponentsModel';
import {
    ComponentsModelFromJSON,
    ComponentsModelFromJSONTyped,
    ComponentsModelToJSON,
} from './ComponentsModel';
import type { FormsModel } from './FormsModel';
import {
    FormsModelFromJSON,
    FormsModelFromJSONTyped,
    FormsModelToJSON,
} from './FormsModel';
import type { PagesModel } from './PagesModel';
import {
    PagesModelFromJSON,
    PagesModelFromJSONTyped,
    PagesModelToJSON,
} from './PagesModel';

/**
 * 
 * @export
 * @interface WebSectionModel
 */
export interface WebSectionModel {
    /**
     * 
     * @type {PagesModel}
     * @memberof WebSectionModel
     */
    pages: PagesModel;
    /**
     * 
     * @type {ComponentsModel}
     * @memberof WebSectionModel
     */
    components: ComponentsModel;
    /**
     * 
     * @type {FormsModel}
     * @memberof WebSectionModel
     */
    forms: FormsModel;
}

/**
 * Check if a given object implements the WebSectionModel interface.
 */
export function instanceOfWebSectionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pages" in value;
    isInstance = isInstance && "components" in value;
    isInstance = isInstance && "forms" in value;

    return isInstance;
}

export function WebSectionModelFromJSON(json: any): WebSectionModel {
    return WebSectionModelFromJSONTyped(json, false);
}

export function WebSectionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebSectionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pages': PagesModelFromJSON(json['Pages']),
        'components': ComponentsModelFromJSON(json['Components']),
        'forms': FormsModelFromJSON(json['forms']),
    };
}

export function WebSectionModelToJSON(value?: WebSectionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Pages': PagesModelToJSON(value.pages),
        'Components': ComponentsModelToJSON(value.components),
        'forms': FormsModelToJSON(value.forms),
    };
}

