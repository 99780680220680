import { v4 } from "uuid";
import "./LoginPagesHeader.scss";

export interface LoginPagesHeaderProps {
  title: string;
  text: string[];
}

export default function LoginPagesHeader({ title, text }: LoginPagesHeaderProps) {
  return (
    <div className="LoginPagesHeader">
      <h2 className="LoginPagesHeader__title">{title}</h2>
      {text.map(textElement => (
        <p key={v4()} className="LoginPagesHeader__text">
          {textElement}
        </p>
      ))}
    </div>
  );
}
