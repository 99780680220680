import i18n from "i18next";
import { UserRoleType } from "models/PersonModels/Roles";

export function userRoleTypeToSelectOptions() {
  const userRoles = Object.keys(UserRoleType);

  return userRoles.map(key => ({
    title: i18n.t(`userRoleType.${key}`, { ns: "translation" }),
    value: UserRoleType[key as keyof typeof UserRoleType],
  }));
}
