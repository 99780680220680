/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  UpdateUserPreferencesRequestModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    UpdateUserPreferencesRequestModelFromJSON,
    UpdateUserPreferencesRequestModelToJSON,
} from '../models/index';

export interface OrganizationsOrganizationIdUsersUserEntityIdPreferencesPostRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    updateUserPreferencesRequestModel?: UpdateUserPreferencesRequestModel;
}

/**
 * UserPreferencesApi - interface
 * 
 * @export
 * @interface UserPreferencesApiInterface
 */
export interface UserPreferencesApiInterface {
    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {UpdateUserPreferencesRequestModel} [updateUserPreferencesRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferencesApiInterface
     */
    organizationsOrganizationIdUsersUserEntityIdPreferencesPostRaw(requestParameters: OrganizationsOrganizationIdUsersUserEntityIdPreferencesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdUsersUserEntityIdPreferencesPost(requestParameters: OrganizationsOrganizationIdUsersUserEntityIdPreferencesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class UserPreferencesApi extends runtime.BaseAPI implements UserPreferencesApiInterface {

     /**
     */
    async organizationsOrganizationIdUsersUserEntityIdPreferencesPostRawOriginal(requestParameters: OrganizationsOrganizationIdUsersUserEntityIdPreferencesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdUsersUserEntityIdPreferencesPost.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdUsersUserEntityIdPreferencesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/users/{userEntityId}/preferences`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserPreferencesRequestModelToJSON(requestParameters.updateUserPreferencesRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdUsersUserEntityIdPreferencesPostRaw(requestParameters: OrganizationsOrganizationIdUsersUserEntityIdPreferencesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdUsersUserEntityIdPreferencesPost.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdUsersUserEntityIdPreferencesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/users/{userEntityId}/preferences`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserPreferencesRequestModelToJSON(requestParameters.updateUserPreferencesRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdUsersUserEntityIdPreferencesPost(requestParameters: OrganizationsOrganizationIdUsersUserEntityIdPreferencesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdUsersUserEntityIdPreferencesPostRaw(requestParameters, initOverrides);
    }

}
