import * as React from "react";
import { SVGProps } from "react";

export const EmptyPatients = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={98}
    height={80}
    viewBox="0 0 98 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M74.9115 49.5074H61.2147C48.8036 49.5074 38.7424 59.4291 38.7424 71.6683C38.7424 72.9352 39.784 73.9622 41.0688 73.9622H95.0575C96.3423 73.9622 97.3839 72.9352 97.3839 71.6683C97.3837 59.4291 87.3225 49.5074 74.9115 49.5074Z"
      fill="url(#paint0_linear_16694_42104)"
    />
    <path
      d="M74.9115 49.5074H61.2147C48.8036 49.5074 38.7424 59.4291 38.7424 71.6683C38.7424 72.9352 39.784 73.9622 41.0688 73.9622H95.0575C96.3423 73.9622 97.3839 72.9352 97.3839 71.6683C97.3837 59.4291 87.3225 49.5074 74.9115 49.5074Z"
      fill="url(#paint1_linear_16694_42104)"
    />
    <path
      d="M97.3842 71.6271C97.3846 72.9114 96.3423 73.9622 95.058 73.9622H41.0684C40.4259 73.9622 39.8444 73.7012 39.4238 73.2806C39.0032 72.86 38.7422 72.2785 38.7422 71.636C38.7422 59.8369 47.8367 50.1609 59.398 49.2356C59.8183 50.6206 60.479 52.5804 61.4536 54.9413C63.8861 60.8345 72.2405 60.8345 74.673 54.9413C75.6474 52.5804 76.3083 50.6206 76.7284 49.2356C82.2089 49.6736 87.1353 52.0795 90.8015 55.7457C94.8675 59.8102 97.3817 65.4249 97.3842 71.6271Z"
      fill="url(#paint2_linear_16694_42104)"
    />
    <path
      d="M76.7286 49.2356C76.3083 50.6206 75.6476 52.5804 74.6731 54.9413C73.3914 58.0464 70.4657 59.5146 67.6283 59.3476V73.9622H95.0582C96.3424 73.9622 97.3848 72.9112 97.3844 71.6271C97.3819 65.4251 94.8676 59.8103 90.8017 55.7457C87.1355 52.0795 82.209 49.6736 76.7286 49.2356Z"
      fill="url(#paint3_linear_16694_42104)"
    />
    <path
      d="M38.7422 71.6361C38.7422 72.2786 39.0032 72.8601 39.4238 73.2807C39.8444 73.7012 40.4259 73.9623 41.0684 73.9623H95.058C96.3422 73.9623 97.3846 72.9113 97.3842 71.6271C97.3826 67.4854 96.2595 63.6064 94.3035 60.2753H41.8231C39.8658 63.6089 38.7422 67.491 38.7422 71.6361Z"
      fill="url(#paint4_linear_16694_42104)"
    />
    <path
      d="M46.1365 30.147C46.1365 27.2787 48.1275 24.8754 50.8031 24.2438C51.5182 24.075 52.2018 24.628 52.2018 25.3628V34.9312C52.2018 35.666 51.5182 36.219 50.8031 36.0502C48.1275 35.4186 46.1365 33.0153 46.1365 30.147Z"
      fill="url(#paint5_linear_16694_42104)"
    />
    <path
      d="M89.9901 30.1469C89.9901 27.2787 87.9991 24.8753 85.3237 24.2437C84.6086 24.0749 83.9249 24.6279 83.9249 25.3627V34.9311C83.9249 35.666 84.6086 36.219 85.3237 36.0502C87.9991 35.4186 89.9901 33.0152 89.9901 30.1469Z"
      fill="url(#paint6_linear_16694_42104)"
    />
    <path
      d="M68.0631 52.4898C58.3736 52.4898 50.5187 44.6349 50.5187 34.9454V25.9482C50.5187 16.2587 58.3736 8.40375 68.0631 8.40375C77.7526 8.40375 85.6076 16.2587 85.6076 25.9482V34.9454C85.6076 44.6349 77.7526 52.4898 68.0631 52.4898Z"
      fill="url(#paint7_linear_16694_42104)"
    />
    <path
      d="M66.6852 3.98083C58.516 3.98083 51.8927 10.6026 51.8927 18.7733V26.6119C51.8927 27.573 51.1135 28.3522 50.1524 28.3522H48.8472C47.8861 28.3522 47.1069 27.573 47.1069 26.6119V22.5135C47.1069 12.2783 55.4044 3.98083 65.6396 3.98083H66.6852Z"
      fill="url(#paint8_linear_16694_42104)"
    />
    <path
      d="M88.7287 22.5135V26.6119C88.7287 27.573 87.9495 28.3522 86.9884 28.3522H85.6832C84.7221 28.3522 83.9429 27.573 83.9429 26.6119V18.7733C83.9429 10.6026 77.3196 3.98083 69.1504 3.98083H70.196C80.4314 3.98083 88.7287 12.2783 88.7287 22.5135Z"
      fill="url(#paint9_linear_16694_42104)"
    />
    <path
      d="M58.2133 7.37756C54.9649 6.07568 50.4674 8.12893 48.1678 11.9634C45.8681 15.7978 46.637 19.9618 49.8854 21.2635C53.1337 22.5652 57.6313 20.5121 59.931 16.6777C62.2306 12.8432 61.4616 8.67944 58.2133 7.37756Z"
      fill="url(#paint10_linear_16694_42104)"
    />
    <path
      d="M84.4012 6.1642L83.4883 5.44111C77.6776 0.838477 69.5765 -0.844094 62.0913 0.393253C54.0946 1.71515 51.2112 9.59649 56.9876 14.172C62.7189 18.7118 70.4199 20.7404 77.9117 20.2581C86.7613 19.6882 90.6796 11.1374 84.4012 6.1642Z"
      fill="url(#paint11_linear_16694_42104)"
    />
    <path
      d="M36.6213 56.5876H22.0208C12.5292 56.5876 4.83447 48.893 4.83447 39.4012V39.1308C4.83447 29.6391 12.529 21.9444 22.0208 21.9444H36.6214C46.1131 21.9444 53.8078 29.6389 53.8078 39.1308V39.4012C53.8076 48.8929 46.1131 56.5876 36.6213 56.5876Z"
      fill="url(#paint12_linear_16694_42104)"
    />
    <path
      d="M36.6213 56.5876H22.0208C12.5292 56.5876 4.83447 48.893 4.83447 39.4012V39.1308C4.83447 29.6391 12.529 21.9444 22.0208 21.9444H36.6214C46.1131 21.9444 53.8078 29.6389 53.8078 39.1308V39.4012C53.8076 48.8929 46.1131 56.5876 36.6213 56.5876Z"
      fill="url(#paint13_linear_16694_42104)"
    />
    <path
      d="M36.1693 55.545H22.4726C10.0615 55.545 0.000366211 65.4667 0.000366211 77.7058C0.000366211 78.9727 1.04193 79.9998 2.32669 79.9998H56.3153C57.6001 79.9998 58.6417 78.9727 58.6417 77.7058C58.6415 65.4667 48.5803 55.545 36.1693 55.545Z"
      fill="url(#paint14_linear_16694_42104)"
    />
    <path
      d="M58.6419 77.6648C58.6423 78.9492 57.6 80 56.3157 80H2.32618C1.68373 80 1.10218 79.739 0.681613 79.3184C0.261043 78.8978 0 78.3163 0 77.6738C0 65.8747 9.09445 56.1987 20.6558 55.2734C21.076 56.6584 21.7368 58.6183 22.7113 60.9791C25.1438 66.8723 33.4982 66.8723 35.9307 60.9791C36.9051 58.6183 37.566 56.6584 37.9861 55.2734C43.4666 55.7114 48.393 58.1174 52.0592 61.7836C56.1251 65.848 58.6394 71.4628 58.6419 77.6648Z"
      fill="url(#paint15_linear_16694_42104)"
    />
    <path
      d="M37.9862 55.2734C37.566 56.6584 36.9052 58.6183 35.9308 60.9791C34.6491 64.0842 31.7234 65.5524 28.886 65.3854V80H56.3158C57.6 80 58.6424 78.949 58.642 77.6648C58.6395 71.4629 56.1253 65.8482 52.0594 61.7836C48.3932 58.1174 43.4667 55.7114 37.9862 55.2734Z"
      fill="url(#paint16_linear_16694_42104)"
    />
    <path
      d="M0 77.6737C0 78.3161 0.261043 78.8977 0.681613 79.3182C1.10218 79.7388 1.68373 79.9999 2.32618 79.9999H56.3157C57.5999 79.9999 58.6423 78.9489 58.6419 77.6647C58.6403 73.5229 57.5172 69.644 55.5611 66.3129H3.08089C1.12365 69.6464 0 73.5286 0 77.6737Z"
      fill="url(#paint17_linear_16694_42104)"
    />
    <path
      d="M7.39478 36.1844C7.39478 33.3161 9.38581 30.9128 12.0614 30.2812C12.7765 30.1124 13.4601 30.6654 13.4601 31.4002V40.9686C13.4601 41.7034 12.7765 42.2564 12.0614 42.0876C9.38595 41.456 7.39478 39.0527 7.39478 36.1844Z"
      fill="url(#paint18_linear_16694_42104)"
    />
    <path
      d="M51.2481 36.1844C51.2481 33.3161 49.257 30.9128 46.5816 30.2812C45.8665 30.1124 45.1829 30.6654 45.1829 31.4002V40.9686C45.1829 41.7034 45.8665 42.2564 46.5816 42.0876C49.2569 41.456 51.2481 39.0527 51.2481 36.1844Z"
      fill="url(#paint19_linear_16694_42104)"
    />
    <path
      d="M29.3212 58.5273C19.6317 58.5273 11.7767 50.6723 11.7767 40.9828V31.9857C11.7767 22.2962 19.6317 14.4413 29.3212 14.4413C39.0106 14.4413 46.8656 22.2962 46.8656 31.9857V40.9828C46.8656 50.6723 39.0106 58.5273 29.3212 58.5273Z"
      fill="url(#paint20_linear_16694_42104)"
    />
    <path
      d="M7.18595 33.6966C2.93283 25.2349 6.34452 14.9274 14.8062 10.6743C16.1403 10.0037 17.7654 10.5416 18.436 11.8757C22.6892 20.3374 19.2775 30.6448 10.8158 34.898C9.48168 35.5686 7.85654 35.0307 7.18595 33.6966Z"
      fill="url(#paint21_linear_16694_42104)"
    />
    <path
      d="M27.9011 6.03772H26.5946C17.6558 6.03772 12.6791 16.5037 18.4417 23.337C23.367 29.1777 30.7388 32.888 38.9772 32.888H48.5189C50.2438 32.888 51.6368 31.4765 51.6353 29.7516C51.6243 16.6529 41.0023 6.03772 27.9011 6.03772Z"
      fill="url(#paint22_linear_16694_42104)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_16694_42104"
        x1="61.139"
        y1="53.8999"
        x2="64.0394"
        y2="60.4743"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_16694_42104"
        x1="68.0631"
        y1="57.5262"
        x2="68.0631"
        y2="50.2692"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F89580" stopOpacity="0" />
        <stop offset="0.9972" stopColor="#C5715A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_16694_42104"
        x1="63.442"
        y1="53.3327"
        x2="69.533"
        y2="75.6664"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61DCDE" />
        <stop offset="1" stopColor="#20989A" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_16694_42104"
        x1="83.871"
        y1="61.5989"
        x2="97.6994"
        y2="61.5989"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#20989A" stopOpacity="0" />
        <stop offset="0.3174" stopColor="#20989A" stopOpacity="0.32" />
        <stop offset="0.8224" stopColor="#20989A" stopOpacity="0.8" />
        <stop offset="1" stopColor="#20989A" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_16694_42104"
        x1="68.0632"
        y1="68.0646"
        x2="68.0632"
        y2="74.9353"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#20989A" stopOpacity="0" />
        <stop offset="0.3174" stopColor="#20989A" stopOpacity="0.32" />
        <stop offset="0.8224" stopColor="#20989A" stopOpacity="0.8" />
        <stop offset="1" stopColor="#20989A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_16694_42104"
        x1="47.9211"
        y1="27.5247"
        x2="52.7996"
        y2="32.4031"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_16694_42104"
        x1="82.0341"
        y1="26.5979"
        x2="88.8607"
        y2="33.4245"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_16694_42104"
        x1="56.2847"
        y1="22.5285"
        x2="79.6881"
        y2="38.2619"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_16694_42104"
        x1="52.0148"
        y1="13.4408"
        x2="56.148"
        y2="15.4711"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A5A5A" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_16694_42104"
        x1="85.2732"
        y1="19.0813"
        x2="89.3339"
        y2="21.4017"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A5A5A" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_16694_42104"
        x1="50.2664"
        y1="10.5376"
        x2="56.9799"
        y2="17.2511"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A5A5A" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_16694_42104"
        x1="67.6354"
        y1="2.22157"
        x2="73.1904"
        y2="16.5993"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A5A5A" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_16694_42104"
        x1="15.6245"
        y1="30.0583"
        x2="42.839"
        y2="48.3537"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A5A5A" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_16694_42104"
        x1="28.4458"
        y1="44.719"
        x2="29.7027"
        y2="36.8877"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#433F43" stopOpacity="0" />
        <stop offset="0.3174" stopColor="#3B373B" stopOpacity="0.317" />
        <stop offset="0.8224" stopColor="#242324" stopOpacity="0.822" />
        <stop offset="1" stopColor="#1A1A1A" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_16694_42104"
        x1="22.3968"
        y1="59.9375"
        x2="25.2973"
        y2="66.5118"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BE9D8C" />
        <stop offset="1" stopColor="#8F6B40" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_16694_42104"
        x1="24.6998"
        y1="59.3705"
        x2="30.7908"
        y2="81.7042"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9C75FE" />
        <stop offset="1" stopColor="#5331A8" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_16694_42104"
        x1="45.1287"
        y1="67.6367"
        x2="58.957"
        y2="67.6367"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5331A8" stopOpacity="0" />
        <stop offset="1" stopColor="#5331A8" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_16694_42104"
        x1="29.3209"
        y1="74.1022"
        x2="29.3209"
        y2="80.9728"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5331A8" stopOpacity="0" />
        <stop offset="1" stopColor="#5331A8" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_16694_42104"
        x1="9.17959"
        y1="33.5621"
        x2="14.058"
        y2="38.4405"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BF9E8D" />
        <stop offset="1" stopColor="#8F6B40" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_16694_42104"
        x1="43.292"
        y1="32.6354"
        x2="50.1186"
        y2="39.462"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BF9E8D" />
        <stop offset="1" stopColor="#8F6B40" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_16694_42104"
        x1="17.5426"
        y1="28.566"
        x2="40.946"
        y2="44.2994"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BF9D8D" />
        <stop offset="1" stopColor="#8F6A40" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_16694_42104"
        x1="8.11062"
        y1="16.5191"
        x2="17.1021"
        y2="28.5076"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A5A5A" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_16694_42104"
        x1="33.8033"
        y1="10.0017"
        x2="33.8033"
        y2="18.413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A5A5A" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
    </defs>
  </svg>
);
