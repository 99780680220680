/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceStatusType } from './DeviceStatusType';
import {
    DeviceStatusTypeFromJSON,
    DeviceStatusTypeFromJSONTyped,
    DeviceStatusTypeToJSON,
} from './DeviceStatusType';
import type { DeviceType } from './DeviceType';
import {
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';
import type { ManufacturerType } from './ManufacturerType';
import {
    ManufacturerTypeFromJSON,
    ManufacturerTypeFromJSONTyped,
    ManufacturerTypeToJSON,
} from './ManufacturerType';
import type { UserDetailsResponseModel } from './UserDetailsResponseModel';
import {
    UserDetailsResponseModelFromJSON,
    UserDetailsResponseModelFromJSONTyped,
    UserDetailsResponseModelToJSON,
} from './UserDetailsResponseModel';

/**
 * 
 * @export
 * @interface DeviceResponseModel
 */
export interface DeviceResponseModel {
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseModel
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseModel
     */
    uniqueId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DeviceResponseModel
     */
    creationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeviceResponseModel
     */
    modifyDate: Date;
    /**
     * 
     * @type {DeviceType}
     * @memberof DeviceResponseModel
     */
    deviceType: DeviceType;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseModel
     */
    rowVersion: string;
    /**
     * 
     * @type {DeviceStatusType}
     * @memberof DeviceResponseModel
     */
    status: DeviceStatusType;
    /**
     * 
     * @type {ManufacturerType}
     * @memberof DeviceResponseModel
     */
    manufacturerType: ManufacturerType;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseModel
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseModel
     */
    organizationId: string;
    /**
     * 
     * @type {UserDetailsResponseModel}
     * @memberof DeviceResponseModel
     */
    assignedTo?: UserDetailsResponseModel;
}

/**
 * Check if a given object implements the DeviceResponseModel interface.
 */
export function instanceOfDeviceResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "serialNumber" in value;
    isInstance = isInstance && "creationDate" in value;
    isInstance = isInstance && "modifyDate" in value;
    isInstance = isInstance && "deviceType" in value;
    isInstance = isInstance && "rowVersion" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "manufacturerType" in value;
    isInstance = isInstance && "organizationName" in value;
    isInstance = isInstance && "organizationId" in value;

    return isInstance;
}

export function DeviceResponseModelFromJSON(json: any): DeviceResponseModel {
    return DeviceResponseModelFromJSONTyped(json, false);
}

export function DeviceResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'serialNumber': json['SerialNumber'],
        'uniqueId': !exists(json, 'UniqueId') ? undefined : json['UniqueId'],
        'creationDate': json['CreationDate'],
        'modifyDate': json['ModifyDate'],
        'deviceType': DeviceTypeFromJSON(json['DeviceType']),
        'rowVersion': json['RowVersion'],
        'status': DeviceStatusTypeFromJSON(json['Status']),
        'manufacturerType': ManufacturerTypeFromJSON(json['ManufacturerType']),
        'organizationName': json['OrganizationName'],
        'organizationId': json['OrganizationId'],
        'assignedTo': !exists(json, 'AssignedTo') ? undefined : UserDetailsResponseModelFromJSON(json['AssignedTo']),
    };
}

export function DeviceResponseModelToJSON(value?: DeviceResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'SerialNumber': value.serialNumber,
        'UniqueId': value.uniqueId,
        'CreationDate': value.creationDate,
        'ModifyDate': value.modifyDate,
        'DeviceType': DeviceTypeToJSON(value.deviceType),
        'RowVersion': value.rowVersion,
        'Status': DeviceStatusTypeToJSON(value.status),
        'ManufacturerType': ManufacturerTypeToJSON(value.manufacturerType),
        'OrganizationName': value.organizationName,
        'OrganizationId': value.organizationId,
        'AssignedTo': UserDetailsResponseModelToJSON(value.assignedTo),
    };
}

