import { RegionType } from "api/query";
import { localeNumberFormat } from "utils/formatters/localeNumberFormat";
import {
  millimetersToCentimeters,
  millimetersToWholeFeet,
  restOfMillimetersToInchesAfterWholeFeet,
} from "./unitsConverter";

export interface LocalHeight {
  cm: string;
  feet: string;
  inches: string;
}

//TODO remove me ?
export const getLocalHeight = (height?: number, region?: RegionType): LocalHeight => {
  if (!height || !region) {
    return { cm: "", feet: "", inches: "" };
  }

  return {
    cm: localeNumberFormat(millimetersToCentimeters(height), region, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
    }),
    feet: millimetersToWholeFeet(height) === 0 ? "" : millimetersToWholeFeet(height).toString(),
    inches:
      restOfMillimetersToInchesAfterWholeFeet(height) === 0
        ? "0" // In case of inches we will allow user to enter 0 value (example: 6'0) while in case of feet this will not be possible
        : localeNumberFormat(restOfMillimetersToInchesAfterWholeFeet(height), region, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
          }),
  };
};
