import { EntityStatus } from "components/AppTables/utils";
import { ReactNode, cloneElement, isValidElement } from "react";

export function isKeyboardClickKey(key: string) {
  return key === "Enter";
}

export function resolveStatusToVariant(status?: EntityStatus) {
  if (!status) {
    return "grey";
  }
  const mapValuesToType = {
    Active: "green",
    Deactivated: "red",
    Inactive: "red",
    Invited: "grey",
    Deleted: "red",
    NoAccess: "grey",
  } as const;

  return mapValuesToType[status];
}

export function renderValidElement<T extends ReactNode>(elem: T | undefined, props?: any) {
  return isValidElement(elem) ? cloneElement(elem, props) : null;
}
