import * as React from "react";
import { SVGProps } from "react";
const SvgSlash = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25Zm-5.989 3.7A9.25 9.25 0 0 1 21.25 12a9.213 9.213 0 0 1-2.2 5.989L6.01 4.95Zm-1.06 1.061A9.25 9.25 0 0 0 12 21.25a9.213 9.213 0 0 0 5.988-2.2L4.95 6.01Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSlash;
