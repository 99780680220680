import { useEffect, RefObject } from "react";

type Event = MouseEvent | TouchEvent;

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  isActive: boolean,
  handler: (event?: Event) => void,
  additionalNodesToIgnore?: () => readonly Node[],
) {
  useEffect(() => {
    if (isActive) {
      const listener = (event: Event) => {
        const el = ref?.current;

        const omitClickEl = additionalNodesToIgnore?.().some(node => {
          return node.contains(event.target as Node);
        });

        if (!el || el.contains(event?.target as Node) || omitClickEl) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }
  }, [ref, handler, isActive, additionalNodesToIgnore]);
}
