import { Icon, Input } from "libs/ui";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SecretCodeInText.scss";
import classNames from "classnames";

interface SecretCodeInTextProps {
  secretCode: string | undefined;
}

export function SecretCodeInText({ secretCode }: SecretCodeInTextProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.login.Tabs.TwoFA",
  });
  const [isCopied, setIsCopied] = useState(false);
  const [errorCopyToClipboard, setErrorCopyToClipboard] = useState<string>();

  const copyTextToClipboard = useCallback(async () => {
    if (!secretCode) {
      return;
    }

    return await navigator.clipboard.writeText(secretCode);
  }, [secretCode]);

  const copyToClipboard = useCallback(() => {
    copyTextToClipboard()
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(() => {
        setErrorCopyToClipboard(t("failedToCopy"));
      });
  }, [copyTextToClipboard, t]);

  return (
    <Input
      className="SecretCodeInText"
      readOnly
      inputIcon={{
        icon: (
          <Icon
            className={classNames({
              IconGreen: isCopied,
            })}
            icon={isCopied ? "CheckCircle" : "Copy"}
            onClick={copyToClipboard}
          />
        ),
        iconPosition: "trailing",
      }}
      label={t("labelSecretCode")}
      value={secretCode}
      validation={{ infoText: errorCopyToClipboard }}
    />
  );
}
