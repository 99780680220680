import { BackendResponse } from "libs/ui";
import { FilterSideModal } from "../FilterSideModal";
import { AppliedFilterStateMap, TableFilterProps } from "./model";
import { useFilterComponentConfig } from "./useFilterComponentConfig";

export function TableFilter<T extends BackendResponse, A extends AppliedFilterStateMap>({
  tableService,
  filterSideModelState,
  children,
}: Readonly<TableFilterProps<T, A>>) {
  const {
    isFilterSideModalOpen,
    onSearchChange,
    onClearAll,
    onUpdateFilters,
    onCloseModal,
    activeFilters,
    appliedFilters,
    onNewFilterApplied,
    onNewRadioFilterApplied,
    onSingleFilterClear,
  } = useFilterComponentConfig({ tableService, filterSideModelState });

  return (
    <FilterSideModal
      show={isFilterSideModalOpen}
      submitButton={{ onClick: onUpdateFilters }}
      clearAllButton={{ onClick: onClearAll }}
      closeModal={{ onClick: onCloseModal }}
    >
      {children({
        isFilterSideModalOpen,
        onSearchChange,
        onClearAll,
        onUpdateFilters,
        onCloseModal,
        activeFilters,
        appliedFilters,
        onNewFilterApplied,
        onNewRadioFilterApplied,
        onSingleFilterClear,
      })}
    </FilterSideModal>
  );
}
