import { Button, Icon } from "libs/ui";
import "./RefreshFailedWidget.scss";
import { useTranslation } from "react-i18next";

interface RefreshFailedWidgetProps {
  show: boolean;
  refreshCb: () => void;
}

export function RefreshFailedWidget({ show, refreshCb }: RefreshFailedWidgetProps) {
  const { t } = useTranslation("widgets", { keyPrefix: "RefreshFailedWidget" });

  if (!show) {
    return null;
  }

  return (
    <div className="RefreshFailedWidget">
      <div className="RefreshFailedWidget__title">
        <Icon icon={"CircleWarning"} />
        {t("title")}
      </div>
      <Button
        onClick={refreshCb}
        buttonSize="medium"
        buttonType="primary"
        buttonIcon={{ icon: "Refresh" }}
      >
        {t("buttonTryAgain")}
      </Button>
    </div>
  );
}
