/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  ReportResponseModel,
  ReportResponseModelListModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    ReportResponseModelFromJSON,
    ReportResponseModelToJSON,
    ReportResponseModelListModelFromJSON,
    ReportResponseModelListModelToJSON,
} from '../models/index';

export interface ReportsGetRequest {
    limit?: number;
    page?: number;
}

export interface ReportsReportIdFileGetRequest {
    reportId: string;
}

export interface ReportsReportIdGetRequest {
    reportId: string;
}

/**
 * ReportsApi - interface
 * 
 * @export
 * @interface ReportsApiInterface
 */
export interface ReportsApiInterface {
    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsGetRaw(requestParameters: ReportsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportResponseModelListModel>>;

    /**
     */
    reportsGet(limit?: number, page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportResponseModelListModel>;

    /**
     * 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsReportIdFileGetRaw(requestParameters: ReportsReportIdFileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    reportsReportIdFileGet(reportId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsReportIdGetRaw(requestParameters: ReportsReportIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportResponseModel>>;

    /**
     */
    reportsReportIdGet(reportId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportResponseModel>;

}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI implements ReportsApiInterface {

     /**
     */
    async reportsGetRawOriginal(requestParameters: ReportsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return this.request({
            path: `/reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async reportsGetRaw(requestParameters: ReportsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportResponseModelListModel>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async reportsGet(limit?: number, page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportResponseModelListModel> {
        const response = await this.reportsGetRaw({ limit: limit, page: page }, initOverrides);
        return await response.value();
    }
    async reportsGetOriginal(limit?: number, page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.reportsGetRawOriginal({ limit: limit, page: page }, initOverrides);
    }

     /**
     */
    async reportsReportIdFileGetRawOriginal(requestParameters: ReportsReportIdFileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling reportsReportIdFileGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return this.request({
            path: `/reports/{reportId}/file`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async reportsReportIdFileGetRaw(requestParameters: ReportsReportIdFileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling reportsReportIdFileGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reports/{reportId}/file`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async reportsReportIdFileGet(reportId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.reportsReportIdFileGetRaw({ reportId: reportId }, initOverrides);
        return await response.value();
    }
    async reportsReportIdFileGetOriginal(reportId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.reportsReportIdFileGetRawOriginal({ reportId: reportId }, initOverrides);
    }

     /**
     */
    async reportsReportIdGetRawOriginal(requestParameters: ReportsReportIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling reportsReportIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return this.request({
            path: `/reports/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async reportsReportIdGetRaw(requestParameters: ReportsReportIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportResponseModel>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling reportsReportIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reports/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async reportsReportIdGet(reportId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportResponseModel> {
        const response = await this.reportsReportIdGetRaw({ reportId: reportId }, initOverrides);
        return await response.value();
    }
    async reportsReportIdGetOriginal(reportId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.reportsReportIdGetRawOriginal({ reportId: reportId }, initOverrides);
    }

}
