/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MeasurementItemModel } from './MeasurementItemModel';
import {
    MeasurementItemModelFromJSON,
    MeasurementItemModelFromJSONTyped,
    MeasurementItemModelToJSON,
} from './MeasurementItemModel';
import type { MeasurementType } from './MeasurementType';
import {
    MeasurementTypeFromJSON,
    MeasurementTypeFromJSONTyped,
    MeasurementTypeToJSON,
} from './MeasurementType';

/**
 * 
 * @export
 * @interface MeasurementModel
 */
export interface MeasurementModel {
    /**
     * 
     * @type {MeasurementType}
     * @memberof MeasurementModel
     */
    measurementType: MeasurementType;
    /**
     * 
     * @type {Array<MeasurementItemModel>}
     * @memberof MeasurementModel
     */
    items: Array<MeasurementItemModel>;
}

/**
 * Check if a given object implements the MeasurementModel interface.
 */
export function instanceOfMeasurementModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "measurementType" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function MeasurementModelFromJSON(json: any): MeasurementModel {
    return MeasurementModelFromJSONTyped(json, false);
}

export function MeasurementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measurementType': MeasurementTypeFromJSON(json['MeasurementType']),
        'items': ((json['Items'] as Array<any>).map(MeasurementItemModelFromJSON)),
    };
}

export function MeasurementModelToJSON(value?: MeasurementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'MeasurementType': MeasurementTypeToJSON(value.measurementType),
        'Items': ((value.items as Array<any>).map(MeasurementItemModelToJSON)),
    };
}

