import * as React from "react";
import { SVGProps } from "react";

export const EmptyHcp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={96}
    height={80}
    viewBox="0 0 96 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M86.4605 23.8694H47.1428C44.6873 27.0324 43.1455 30.3107 42.5421 33.6705C41.7856 37.8831 42.8656 42.1835 45.5054 45.4691C47.9501 48.5119 51.4142 50.2901 55.2598 50.476C55.4858 50.487 78.1174 50.487 78.3434 50.476C82.189 50.2901 85.6532 48.5119 88.098 45.469C90.7376 42.1835 91.8176 37.8831 91.061 33.6705C90.4577 30.3107 88.9161 27.0324 86.4605 23.8694Z"
      fill="url(#paint0_linear_16694_8720)"
    />
    <path
      d="M92.9584 73.563H40.6449C39.4 73.563 38.3909 72.5537 38.3909 71.3089C38.3909 59.283 48.1398 49.5341 60.1657 49.5341H73.4375C85.4634 49.5341 95.2123 59.283 95.2123 71.3089C95.2125 72.5539 94.2032 73.563 92.9584 73.563Z"
      fill="url(#paint1_linear_16694_8720)"
    />
    <path
      d="M60.5697 73.563H50.803V67.1517C50.803 66.4337 51.3851 65.8515 52.1032 65.8515H59.2693C59.9874 65.8515 60.5695 66.4337 60.5695 67.1517L60.5697 73.563Z"
      fill="url(#paint2_linear_16694_8720)"
    />
    <path
      d="M38.3909 71.309C38.3909 72.5539 39.4001 73.5631 40.6449 73.5631H92.9583C94.2032 73.5631 95.2123 72.5539 95.2123 71.3091C95.2123 68.6059 94.7183 66.0183 93.818 63.63H39.7852C38.8849 66.0181 38.3909 68.6056 38.3909 71.309Z"
      fill="url(#paint3_linear_16694_8720)"
    />
    <path
      d="M95.2125 71.3089C95.2125 59.283 85.4635 49.5341 73.4376 49.5341H60.1659C58.8901 49.5341 57.6402 49.6448 56.4246 49.8554V59.779L70.2086 73.5631H92.9584C94.2032 73.563 95.2125 72.5539 95.2125 71.3089Z"
      fill="url(#paint4_linear_16694_8720)"
    />
    <path
      d="M61.1235 61.6122C64.8783 61.6122 67.9221 58.6566 67.9221 55.0106C67.9221 51.3646 64.8783 48.409 61.1235 48.409C57.3687 48.409 54.3248 51.3646 54.3248 55.0106C54.3248 58.6566 57.3687 61.6122 61.1235 61.6122Z"
      fill="url(#paint5_linear_16694_8720)"
    />
    <path
      d="M72.4798 61.6122C76.2346 61.6122 79.2784 58.6566 79.2784 55.0106C79.2784 51.3646 76.2346 48.409 72.4798 48.409C68.725 48.409 65.6812 51.3646 65.6812 55.0106C65.6812 58.6566 68.725 61.6122 72.4798 61.6122Z"
      fill="url(#paint6_linear_16694_8720)"
    />
    <path
      d="M64.3609 59.0546L52.9065 45.6829H80.6969L69.2425 59.0546C68.0865 60.4039 65.5167 60.4039 64.3609 59.0546Z"
      fill="url(#paint7_linear_16694_8720)"
    />
    <path
      d="M45.3596 31.9009C45.3596 29.096 47.3067 26.7457 49.923 26.1281C50.6222 25.9629 51.2908 26.5038 51.2908 27.2224V36.5793C51.2908 37.2979 50.6222 37.8387 49.923 37.6737C47.3067 37.0561 45.3596 34.7058 45.3596 31.9009Z"
      fill="url(#paint8_linear_16694_8720)"
    />
    <path
      d="M88.2438 31.9009C88.2438 29.096 86.2967 26.7457 83.6805 26.1281C82.9812 25.9629 82.3126 26.5038 82.3126 27.2224V36.5793C82.3126 37.2979 82.9812 37.8387 83.6805 37.6737C86.2967 37.0561 88.2438 34.7058 88.2438 31.9009Z"
      fill="url(#paint9_linear_16694_8720)"
    />
    <path
      d="M66.8017 53.7498C57.3264 53.7498 49.645 46.0686 49.645 36.5931V27.7947C49.645 18.3194 57.3263 10.638 66.8017 10.638C76.2771 10.638 83.9585 18.3193 83.9585 27.7947V36.5931C83.9585 46.0686 76.2771 53.7498 66.8017 53.7498Z"
      fill="url(#paint10_linear_16694_8720)"
    />
    <path
      d="M72.7385 6.44251C71.9136 6.43154 71.1033 6.4878 70.3118 6.60607C67.8691 6.97144 65.9615 8.981 65.6146 11.5541C65.5023 12.388 65.4489 13.2417 65.4593 14.1108C65.5666 23.0429 72.4854 30.3301 80.965 30.4419C82.6206 30.4636 84.2179 30.215 85.7203 29.7358C86.5976 29.456 87.2875 28.7591 87.5495 27.864C88.0194 26.2582 88.2637 24.5483 88.2425 22.7749C88.1359 13.8429 81.2179 6.5553 72.7385 6.44251Z"
      fill="url(#paint11_linear_16694_8720)"
    />
    <path
      d="M60.8649 6.44251C61.6898 6.43154 62.5002 6.4878 63.2916 6.60607C65.7343 6.97144 67.6419 8.981 67.9888 11.5541C68.1012 12.388 68.1545 13.2417 68.1442 14.1108C68.0368 23.0429 61.118 30.3301 52.6384 30.4419C50.9828 30.4636 49.3855 30.215 47.8831 29.7358C47.0058 29.456 46.3159 28.7591 46.054 27.864C45.584 26.2582 45.3397 24.5483 45.3609 22.7749C45.4675 13.8429 52.3856 6.5553 60.8649 6.44251Z"
      fill="url(#paint12_linear_16694_8720)"
    />
    <path
      d="M88.243 22.7755C88.2632 24.5482 88.0195 26.2575 87.5492 27.8643C87.2867 28.7586 86.5973 29.4553 85.7203 29.7351C84.2173 30.2155 82.6205 30.4636 80.9647 30.4419C74.6628 30.3583 69.2235 26.3123 66.8017 20.573C66.4122 21.4947 65.9463 22.3717 65.4097 23.1967L54.2542 12.0411V8.08894C56.2605 7.06194 58.502 6.47344 60.8647 6.44171C61.6898 6.43161 62.5004 6.48786 63.2923 6.60614C64.7087 6.81817 65.9449 7.58409 66.8017 8.69186C67.6584 7.58409 68.8946 6.81817 70.311 6.60614C71.1029 6.48786 71.9135 6.43161 72.7386 6.44171C81.2171 6.55566 88.1363 13.8427 88.243 22.7755Z"
      fill="url(#paint13_linear_16694_8720)"
    />
    <path
      d="M51.5347 8.41439C52.4322 10.0051 53.5713 11.4409 54.9017 12.673C55.2455 12.9913 55.7359 13.0964 56.1837 12.9591L57.1584 12.6604C63.2181 10.803 70.3853 10.803 76.445 12.6604L77.4196 12.9591C77.8675 13.0964 78.3579 12.9913 78.7016 12.673C80.0321 11.4409 81.1713 10.0051 82.0686 8.41439C82.6407 7.40283 83.1146 6.32781 83.4767 5.20345C83.7438 4.37406 83.2739 3.48641 82.4408 3.2311L76.445 1.39304C70.3853 -0.464347 63.2181 -0.464347 57.1584 1.39304L51.1626 3.2311C50.3294 3.48655 49.8595 4.37406 50.1266 5.20345C50.4887 6.32795 50.9627 7.40298 51.5347 8.41439Z"
      fill="url(#paint14_linear_16694_8720)"
    />
    <path
      d="M50.1266 5.20358C50.4887 6.32794 50.9626 7.40296 51.5347 8.41452C52.4322 10.0052 53.5712 11.441 54.9018 12.6731C55.2456 12.9914 55.7358 13.0966 56.1837 12.9593L57.1584 12.6605C63.218 10.8031 70.3852 10.8031 76.4449 12.6605L77.4196 12.9593C77.8674 13.0966 78.3579 12.9914 78.7014 12.6731C80.0319 11.441 81.1711 10.0052 82.0686 8.41452C82.6406 7.40296 83.1145 6.32794 83.4767 5.20358C83.5411 5.00366 83.5616 4.80043 83.5456 4.60339H50.0577C50.0417 4.80043 50.0623 5.00352 50.1266 5.20358Z"
      fill="url(#paint15_linear_16694_8720)"
    />
    <path
      d="M70.189 3.6278H68.6515V2.0902C68.6515 1.57309 68.2324 1.15393 67.7153 1.15393H65.8882C65.3711 1.15393 64.9519 1.57309 64.9519 2.0902V3.62766H63.4143C62.8972 3.62766 62.478 4.04682 62.478 4.56393V6.39102C62.478 6.90813 62.8972 7.32729 63.4143 7.32729H64.9519V8.86475C64.9519 9.38186 65.3711 9.80102 65.8882 9.80102H67.7153C68.2324 9.80102 68.6515 9.38186 68.6515 8.86475V7.32744H70.189C70.7061 7.32744 71.1253 6.90827 71.1253 6.39117V4.56407C71.1253 4.04697 70.7061 3.6278 70.189 3.6278Z"
      fill="url(#paint16_linear_16694_8720)"
    />
    <path
      d="M37.7488 79.9999H24.807C23.8671 79.9999 9.80859 78.8895 9.80859 77.5199C9.80859 64.2889 17.1688 53.5631 26.248 53.5631H36.2677C45.3468 53.5631 52.7071 64.2889 52.7071 77.5199C52.7072 78.8896 38.6887 79.9999 37.7488 79.9999Z"
      fill="url(#paint17_linear_16694_8720)"
    />
    <path
      d="M36.2677 53.563H31.2579V79.9999H37.7488C38.6887 79.9999 52.7072 78.8895 52.7072 77.5199C52.7072 64.2889 45.347 53.563 36.2677 53.563Z"
      fill="url(#paint18_linear_16694_8720)"
    />
    <path
      d="M24.8069 79.9999C25.4439 75.1543 26.648 70.6814 28.3624 67.1309C30.0059 63.7275 32.51 63.7275 34.1533 67.1309C35.8678 70.6813 37.0719 75.1543 37.7089 79.9999H24.8069Z"
      fill="url(#paint19_linear_16694_8720)"
    />
    <path
      d="M24.8069 79.9999C25.4439 75.1543 26.648 70.6814 28.3624 67.1309C30.0059 63.7275 32.51 63.7275 34.1533 67.1309C35.8678 70.6813 37.0719 75.1543 37.7089 79.9999H24.8069Z"
      fill="url(#paint20_linear_16694_8720)"
    />
    <path
      d="M24.8069 61.1885C25.4439 63.3074 26.648 65.2634 28.3624 66.8159C30.0059 68.3042 32.51 68.3042 34.1533 66.8159C35.8678 65.2633 37.0719 63.3074 37.7089 61.1885H24.8069Z"
      fill="url(#paint21_linear_16694_8720)"
    />
    <path
      d="M31.258 61.6117C35.0887 61.6117 38.194 58.5064 38.194 54.6757C38.194 50.8451 35.0887 47.7397 31.258 47.7397C27.4274 47.7397 24.322 50.8451 24.322 54.6757C24.322 58.5064 27.4274 61.6117 31.258 61.6117Z"
      fill="url(#paint22_linear_16694_8720)"
    />
    <path
      d="M31.258 61.6117C35.0887 61.6117 38.194 58.5064 38.194 54.6757C38.194 50.8451 35.0887 47.7397 31.258 47.7397C27.4274 47.7397 24.322 50.8451 24.322 54.6757C24.322 58.5064 27.4274 61.6117 31.258 61.6117Z"
      fill="url(#paint23_linear_16694_8720)"
    />
    <path
      d="M0.000244141 77.5199C0.000244141 78.8896 1.11062 79.9999 2.48019 79.9999H24.8071L18.6285 54.1587C7.96224 56.5815 0.000244141 66.1205 0.000244141 77.5199Z"
      fill="url(#paint24_linear_16694_8720)"
    />
    <path
      d="M1.53358 69.0714C0.54307 71.6989 0 74.5458 0 77.5199C0 78.8896 1.11037 79.9998 2.47995 79.9998H24.8068L22.1937 69.0712H1.53358V69.0714Z"
      fill="url(#paint25_linear_16694_8720)"
    />
    <path
      d="M14.3824 68.8453H15.2963C16.1652 68.8453 16.6005 69.896 15.9861 70.5104L15.2177 71.2788C14.8005 71.696 14.8463 72.3852 15.315 72.7434L24.8071 80L18.6821 54.3829L13.4194 67.418C13.1436 68.1005 13.6462 68.8453 14.3824 68.8453Z"
      fill="url(#paint26_linear_16694_8720)"
    />
    <path
      d="M62.5156 77.5199C62.5156 78.8896 61.4052 79.9999 60.0356 79.9999H37.7087L43.8873 54.1587C54.5534 56.5815 62.5156 66.1205 62.5156 77.5199Z"
      fill="url(#paint27_linear_16694_8720)"
    />
    <path
      d="M62.5156 77.5199C62.5156 78.8896 61.4052 79.9999 60.0356 79.9999H37.7087L43.8873 54.1587C54.5534 56.5815 62.5156 66.1205 62.5156 77.5199Z"
      fill="url(#paint28_linear_16694_8720)"
    />
    <path
      d="M60.982 69.0714C61.9725 71.6989 62.5156 74.5458 62.5156 77.5199C62.5156 78.8896 61.4052 79.9998 60.0356 79.9998H37.7087L40.3218 69.0712H60.982V69.0714Z"
      fill="url(#paint29_linear_16694_8720)"
    />
    <path
      d="M48.1333 68.8453H47.2194C46.3504 68.8453 45.9151 69.896 46.5296 70.5104L47.298 71.2788C47.7151 71.696 47.6694 72.3852 47.2006 72.7434L37.7085 80L43.8336 54.3829L49.0962 67.418C49.372 68.1005 48.8695 68.8453 48.1333 68.8453Z"
      fill="url(#paint30_linear_16694_8720)"
    />
    <path
      d="M48.2783 66.7541C47.6809 66.7541 47.1965 66.2698 47.1965 65.6723V61.0566C47.1965 56.934 43.8426 53.5801 39.7201 53.5801H22.7956C18.673 53.5801 15.3191 56.934 15.3191 61.0566V65.6723C15.3191 66.2698 14.8348 66.7541 14.2373 66.7541C13.6399 66.7541 13.1555 66.2698 13.1555 65.6723V61.0566C13.1555 55.741 17.48 51.4165 22.7956 51.4165H39.7199C45.0355 51.4165 49.36 55.741 49.36 61.0566V65.6723C49.3602 66.2698 48.8758 66.7541 48.2783 66.7541Z"
      fill="url(#paint31_linear_16694_8720)"
    />
    <path
      d="M31.2578 61.6114L20.314 49.08L19.6533 49.306C17.9381 49.8925 17.0711 51.8024 17.7585 53.4798L22.6551 65.4254C22.9689 66.1912 23.9045 66.4791 24.5947 66.0223L31.2578 61.6114Z"
      fill="url(#paint32_linear_16694_8720)"
    />
    <path
      d="M31.2578 61.6114L42.2016 49.08L42.8623 49.306C44.5775 49.8925 45.4446 51.8024 44.757 53.4798L39.8604 65.4254C39.5465 66.1912 38.611 66.4791 37.9208 66.0223L31.2578 61.6114Z"
      fill="url(#paint33_linear_16694_8720)"
    />
    <path
      d="M9.77344 35.8195C9.77344 33.0089 11.7243 30.6542 14.3458 30.0354C15.0465 29.8699 15.7163 30.4118 15.7163 31.1317V40.507C15.7163 41.2271 15.0465 41.7688 14.3458 41.6035C11.7243 40.9847 9.77344 38.6299 9.77344 35.8195Z"
      fill="url(#paint34_linear_16694_8720)"
    />
    <path
      d="M52.7422 35.8195C52.7422 33.0089 50.7913 30.6542 48.1699 30.0354C47.4692 29.8699 46.7993 30.4118 46.7993 31.1317V40.507C46.7993 41.2271 47.4692 41.7688 48.1699 41.6035C50.7913 40.9847 52.7422 38.6299 52.7422 35.8195Z"
      fill="url(#paint35_linear_16694_8720)"
    />
    <path
      d="M52.7422 35.8195C52.7422 33.0089 50.7913 30.6542 48.1699 30.0354C47.4692 29.8699 46.7993 30.4118 46.7993 31.1317V40.507C46.7993 41.2271 47.4692 41.7688 48.1699 41.6035C50.7913 40.9847 52.7422 38.6299 52.7422 35.8195Z"
      fill="url(#paint36_linear_16694_8720)"
    />
    <path
      d="M31.258 57.7116C21.7638 57.7116 14.0674 50.0152 14.0674 40.5212V31.7056C14.0674 22.2116 21.7638 14.5151 31.2578 14.5151C40.7518 14.5151 48.4482 22.2116 48.4482 31.7056V40.5212C48.4484 50.0152 40.7519 57.7116 31.258 57.7116Z"
      fill="url(#paint37_linear_16694_8720)"
    />
    <path
      d="M53.6039 25.7076C53.6039 29.1321 52.6483 31.3721 51.3343 32.8334C48.8556 35.5902 44.3436 34.6099 43.0312 31.1428C41.4879 27.0658 37.8422 24.2016 33.5919 24.2016C33.4747 24.2016 33.3576 24.2035 33.2405 24.2091C33.9043 18.6813 38.19 14.416 43.3775 14.416C49.0263 14.416 53.6039 19.4715 53.6039 25.7076Z"
      fill="url(#paint38_linear_16694_8720)"
    />
    <path
      d="M53.6039 25.7076C53.6039 29.1321 52.6483 31.3721 51.3343 32.8334C48.8556 35.5902 44.3436 34.6099 43.0312 31.1428C41.4879 27.0658 37.8422 24.2016 33.5919 24.2016C33.4747 24.2016 33.3576 24.2035 33.2405 24.2091C33.9043 18.6813 38.19 14.416 43.3775 14.416C49.0263 14.416 53.6039 19.4715 53.6039 25.7076Z"
      fill="url(#paint39_linear_16694_8720)"
    />
    <path
      d="M8.91162 25.7076C8.91162 29.1321 9.86722 31.3721 11.1813 32.8334C13.6599 35.5902 18.1719 34.6099 19.4844 31.1428C21.0276 27.0658 24.6733 24.2016 28.9237 24.2016C29.0408 24.2016 29.158 24.2035 29.2751 24.2091C28.6113 18.6813 24.3256 14.416 19.1381 14.416C13.4894 14.416 8.91162 19.4715 8.91162 25.7076Z"
      fill="url(#paint40_linear_16694_8720)"
    />
    <path
      d="M31.258 28.3615C38.6666 28.3615 44.6724 23.4535 44.6724 17.3991C44.6724 11.3448 38.6666 6.43677 31.258 6.43677C23.8494 6.43677 17.8435 11.3448 17.8435 17.3991C17.8435 23.4535 23.8494 28.3615 31.258 28.3615Z"
      fill="url(#paint41_linear_16694_8720)"
    />
    <path
      d="M31.258 28.3615C38.6666 28.3615 44.6724 23.4535 44.6724 17.3991C44.6724 11.3448 38.6666 6.43677 31.258 6.43677C23.8494 6.43677 17.8435 11.3448 17.8435 17.3991C17.8435 23.4535 23.8494 28.3615 31.258 28.3615Z"
      fill="url(#paint42_linear_16694_8720)"
    />
    <path
      d="M48.3313 75.5285C51.6772 75.5285 54.3895 72.8162 54.3895 69.4704C54.3895 66.1246 51.6772 63.4122 48.3313 63.4122C44.9855 63.4122 42.2732 66.1246 42.2732 69.4704C42.2732 72.8162 44.9855 75.5285 48.3313 75.5285Z"
      fill="url(#paint43_linear_16694_8720)"
    />
    <path
      d="M48.3313 73.5813C50.6017 73.5813 52.4422 71.7408 52.4422 69.4704C52.4422 67.2 50.6017 65.3595 48.3313 65.3595C46.061 65.3595 44.2205 67.2 44.2205 69.4704C44.2205 71.7408 46.061 73.5813 48.3313 73.5813Z"
      fill="url(#paint44_linear_16694_8720)"
    />
    <path
      d="M48.3314 71.9946C49.7255 71.9946 50.8556 70.8645 50.8556 69.4704C50.8556 68.0763 49.7255 66.9462 48.3314 66.9462C46.9373 66.9462 45.8071 68.0763 45.8071 69.4704C45.8071 70.8645 46.9373 71.9946 48.3314 71.9946Z"
      fill="url(#paint45_linear_16694_8720)"
    />
    <path
      d="M20.5359 78.7739C19.9385 78.7739 19.4541 78.2895 19.4541 77.6921V72.0667C19.4541 69.2431 17.157 66.9461 14.3335 66.9461C11.51 66.9461 9.21294 69.2431 9.21294 72.0667V77.6921C9.21294 78.2895 8.72858 78.7739 8.13113 78.7739C7.53368 78.7739 7.04932 78.2895 7.04932 77.6921V72.0667C7.04932 68.0501 10.317 64.7825 14.3335 64.7825C18.3501 64.7825 21.6177 68.0501 21.6177 72.0667V77.6921C21.6177 78.2895 21.1334 78.7739 20.5359 78.7739Z"
      fill="url(#paint46_linear_16694_8720)"
    />
    <path
      d="M8.56372 76.1776V77.9354C8.56372 78.5278 9.0201 79.0443 9.61221 79.062C10.2248 79.0805 10.7273 78.5892 10.7273 77.9807V76.1776C10.7273 75.5802 10.243 75.0958 9.64553 75.0958C9.04808 75.0958 8.56372 75.5802 8.56372 76.1776Z"
      fill="url(#paint47_linear_16694_8720)"
    />
    <path
      d="M17.9395 76.1776V77.9354C17.9395 78.5278 18.3958 79.0443 18.9879 79.062C19.6005 79.0805 20.1031 78.5892 20.1031 77.9807V76.1776C20.1031 75.5802 19.6187 75.0958 19.0213 75.0958C18.4238 75.0958 17.9395 75.5802 17.9395 76.1776Z"
      fill="url(#paint48_linear_16694_8720)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_16694_8720"
        x1="61.3138"
        y1="20.9853"
        x2="69.8261"
        y2="44.6095"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6B7280" />
        <stop offset="1" stopColor="#374151" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_16694_8720"
        x1="60.2667"
        y1="50.5423"
        x2="68.2705"
        y2="73.9817"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61DCDE" />
        <stop offset="1" stopColor="#20989A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_16694_8720"
        x1="54.5346"
        y1="66.3152"
        x2="56.4177"
        y2="72.7715"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61DCDE" />
        <stop offset="1" stopColor="#20989A" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_16694_8720"
        x1="66.8017"
        y1="68.2748"
        x2="66.8017"
        y2="73.4201"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#187374" stopOpacity="0" />
        <stop offset="1" stopColor="#187374" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_16694_8720"
        x1="78.5974"
        y1="68.9376"
        x2="67.0581"
        y2="44.633"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#187374" stopOpacity="0" />
        <stop offset="1" stopColor="#187374" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_16694_8720"
        x1="58.5879"
        y1="52.475"
        x2="66.2707"
        y2="60.1578"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61DCDE" />
        <stop offset="1" stopColor="#20989A" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_16694_8720"
        x1="70.761"
        y1="53.2917"
        x2="77.1751"
        y2="59.7059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61DCDE" />
        <stop offset="1" stopColor="#20989A" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_16694_8720"
        x1="66.8915"
        y1="46.7364"
        x2="72.1662"
        y2="52.0111"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDCCB" />
        <stop offset="1" stopColor="#E8947D" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_16694_8720"
        x1="47.1049"
        y1="29.3366"
        x2="51.8756"
        y2="34.1072"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_16694_8720"
        x1="80.4636"
        y1="28.4303"
        x2="87.1392"
        y2="35.1061"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_16694_8720"
        x1="55.2835"
        y1="24.4508"
        x2="78.1698"
        y2="39.8364"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_16694_8720"
        x1="70.6743"
        y1="12.2656"
        x2="81.5832"
        y2="23.1746"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6B7280" />
        <stop offset="1" stopColor="#374151" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_16694_8720"
        x1="52.3908"
        y1="13.0313"
        x2="61.8291"
        y2="25.6382"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6B7280" />
        <stop offset="1" stopColor="#374151" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_16694_8720"
        x1="71.4042"
        y1="20.7941"
        x2="70.8994"
        y2="9.4712"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1F2937" stopOpacity="0" />
        <stop offset="1" stopColor="#1F2937" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_16694_8720"
        x1="64.2293"
        y1="3.38948"
        x2="73.2685"
        y2="12.4286"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61DCDE" />
        <stop offset="1" stopColor="#20989A" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_16694_8720"
        x1="66.8016"
        y1="7.69274"
        x2="66.8016"
        y2="12.9571"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#187374" stopOpacity="0" />
        <stop offset="1" stopColor="#20989A" />
        <stop offset="1" stopColor="#187374" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_16694_8720"
        x1="64.7192"
        y1="3.43481"
        x2="68.5054"
        y2="7.14901"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8ECFE" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_16694_8720"
        x1="24.5703"
        y1="60.1065"
        x2="32.4697"
        y2="76.1524"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5F2FF" />
        <stop offset="1" stopColor="#E9E0FF" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_16694_8720"
        x1="39.7996"
        y1="66.2268"
        x2="34.9675"
        y2="66.948"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B699FE" stopOpacity="0" />
        <stop offset="1" stopColor="#B699FE" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_16694_8720"
        x1="27.1678"
        y1="71.7201"
        x2="31.1854"
        y2="73.7885"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B699FE" />
        <stop offset="0.2568" stopColor="#9C75FE" />
        <stop offset="0.7013" stopColor="#8352FE" />
        <stop offset="1" stopColor="#6B42D3" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_16694_8720"
        x1="31.2579"
        y1="75.3362"
        x2="31.2579"
        y2="80.1926"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5331A8" stopOpacity="0" />
        <stop offset="0.1984" stopColor="#5331A8" stopOpacity="0.2" />
        <stop offset="0.427" stopColor="#5331A8" stopOpacity="0.42" />
        <stop offset="0.6703" stopColor="#5331A8" stopOpacity="0.67" />
        <stop offset="0.9226" stopColor="#5331A8" stopOpacity="0.9" />
        <stop offset="1" stopColor="#5331A8" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_16694_8720"
        x1="31.0024"
        y1="60.9241"
        x2="33.8834"
        y2="66.5032"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B699FE" />
        <stop offset="0.2568" stopColor="#9C75FE" />
        <stop offset="0.7013" stopColor="#8352FE" />
        <stop offset="1" stopColor="#6B42D3" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_16694_8720"
        x1="26.8174"
        y1="50.235"
        x2="34.2919"
        y2="57.7096"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7CB" />
        <stop offset="1" stopColor="#FFCC90" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_16694_8720"
        x1="31.258"
        y1="66.3287"
        x2="31.258"
        y2="57.368"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BC8749" stopOpacity="0" />
        <stop offset="0.9972" stopColor="#BC8749" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_16694_8720"
        x1="7.63538"
        y1="63.7975"
        x2="17.1073"
        y2="77.5004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAF9FA" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_16694_8720"
        x1="12.4035"
        y1="73.5005"
        x2="12.4035"
        y2="81.7847"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7BACDF" stopOpacity="0" />
        <stop offset="1" stopColor="#7BACDF" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_16694_8720"
        x1="16.1991"
        y1="65.2952"
        x2="23.6516"
        y2="67.8434"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAF9FA" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_16694_8720"
        x1="39.8242"
        y1="63.0903"
        x2="56.412"
        y2="80.6878"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAF9FA" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_16694_8720"
        x1="51.1596"
        y1="68.1599"
        x2="39.4039"
        y2="66.9339"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7BACDF" stopOpacity="0" />
        <stop offset="1" stopColor="#7BACDF" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_16694_8720"
        x1="50.1121"
        y1="73.5005"
        x2="50.1121"
        y2="81.7847"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7BACDF" stopOpacity="0" />
        <stop offset="1" stopColor="#7BACDF" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_16694_8720"
        x1="46.3165"
        y1="65.2952"
        x2="38.864"
        y2="67.8434"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAF9FA" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_16694_8720"
        x1="31.2578"
        y1="51.4165"
        x2="31.2578"
        y2="66.7541"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A5A5A" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_16694_8720"
        x1="25.1669"
        y1="55.2658"
        x2="21.0479"
        y2="61.7693"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5F2FF" />
        <stop offset="1" stopColor="#E9E0FF" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_16694_8720"
        x1="37.1805"
        y1="55.2196"
        x2="41.9499"
        y2="61.1452"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5F2FF" />
        <stop offset="1" stopColor="#E9E0FF" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_16694_8720"
        x1="11.5221"
        y1="33.2501"
        x2="16.3021"
        y2="38.0301"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7CB" />
        <stop offset="1" stopColor="#FFCC90" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_16694_8720"
        x1="44.9468"
        y1="32.3421"
        x2="51.6356"
        y2="39.0308"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7CB" />
        <stop offset="1" stopColor="#FFCC90" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_16694_8720"
        x1="51.175"
        y1="36.4345"
        x2="47.3526"
        y2="35.7133"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BC8749" stopOpacity="0" />
        <stop offset="1" stopColor="#BC8749" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_16694_8720"
        x1="19.717"
        y1="28.3547"
        x2="42.6483"
        y2="43.7708"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE9CF" />
        <stop offset="1" stopColor="#FFCB8F" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_16694_8720"
        x1="40.9195"
        y1="19.9515"
        x2="48.5178"
        y2="27.5497"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6B7280" />
        <stop offset="1" stopColor="#374151" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_16694_8720"
        x1="48.9089"
        y1="22.5065"
        x2="41.4804"
        y2="26.1846"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1F2937" stopOpacity="0" />
        <stop offset="1" stopColor="#1F2937" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_16694_8720"
        x1="14.2866"
        y1="20.4509"
        x2="19.3833"
        y2="25.9321"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6B7280" />
        <stop offset="1" stopColor="#374151" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_16694_8720"
        x1="28.3136"
        y1="12.5842"
        x2="34.4438"
        y2="22.609"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6B7280" />
        <stop offset="1" stopColor="#374151" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_16694_8720"
        x1="31.258"
        y1="18.5531"
        x2="31.258"
        y2="28.4505"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1F2937" stopOpacity="0" />
        <stop offset="1" stopColor="#1F2937" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_16694_8720"
        x1="44.9656"
        y1="66.1048"
        x2="51.2346"
        y2="72.3737"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A5A5A" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_16694_8720"
        x1="46.0474"
        y1="67.1866"
        x2="50.3014"
        y2="71.4404"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAF9FA" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_16694_8720"
        x1="49.9541"
        y1="71.0931"
        x2="45.9832"
        y2="67.1224"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAF9FA" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_16694_8720"
        x1="10.1022"
        y1="67.4524"
        x2="13.8525"
        y2="73.222"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A5A5A" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint47_linear_16694_8720"
        x1="8.78427"
        y1="75.742"
        x2="9.62159"
        y2="77.0302"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAF9FA" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_16694_8720"
        x1="18.16"
        y1="75.742"
        x2="18.9973"
        y2="77.0302"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAF9FA" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
    </defs>
  </svg>
);
