import classNames from "classnames";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import {
  CountryCode,
  getCountryCallingCode,
  isPossiblePhoneNumber,
  isSupportedCountry,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import { CountryFlag, FieldLoader, Icon, Input } from "libs/ui";
import { FieldValidation } from "libs/ui/FieldValidation";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Countries } from "./Countries";
import "./PhoneNumberField.scss";

const LABEL_DEFAULT_LEFT_POSITION = 102;
const CHAR_WIDTH_SIZE = 8;

interface PhoneNumberFieldProps {
  phone: string | undefined;
  onChange: (value: string | undefined) => void;
  readOnly?: boolean;
  loading?: boolean;
}

function getCountryCallingCodeSafe(...params: Parameters<typeof getCountryCallingCode>) {
  try {
    return getCountryCallingCode(...params);
  } catch (error) {
    console.error(error);
    return "1";
  }
}

function getSafeCountryCode(countryCode?: string | null): CountryCode {
  if (isSupportedCountry(countryCode ?? "US")) {
    return countryCode as CountryCode;
  } else {
    console.error(`${countryCode} is not supported by libphone lib`);
  }

  return "US";
}

export function PhoneNumberField({ phone, onChange, readOnly, loading }: PhoneNumberFieldProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  const [fieldActive, setFieldActive] = useState(false);
  const { appConfig } = useGlobalConfigContext();
  const [country, setCountry] = useState<CountryCode>(
    getSafeCountryCode(appConfig?.general.phoneNumberField.initCountryCode),
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [validation, setValidation] = useState<{ errorText?: string }>();

  //#region init
  const init = useCallback(() => {
    if (phone && !isPossiblePhoneNumber(phone)) {
      setValidation({ errorText: t("ValidationMessages.phone") });
      return;
    }

    if (phone) {
      try {
        const parsedPhone = parsePhoneNumber(phone);

        if (parsedPhone.country) {
          setCountry(parsedPhone.country);
        }

        setPhoneNumber(parsedPhone.nationalNumber);
      } catch (error) {
        setValidation({ errorText: t("ValidationMessages.phone") });
      }
    }
  }, [phone, t]);

  useEffect(() => {
    if (!phoneNumber) {
      init();
    }
  }, [init, phoneNumber]);
  //#endregion

  const selectedDialNumber = useMemo(
    () => (country ? `+${getCountryCallingCodeSafe(country)}` : null),
    [country],
  );

  const toggleFieldActive = () => setFieldActive(prevValue => !prevValue);

  const onCountrySelect = useCallback(
    (country: CountryCode) => {
      /**
       * These 2 fields should be always in sync
       * it doesnt make sense to set a default country without calling code
       */
      setCountry(country);
      onChange(`+${getCountryCallingCodeSafe(country)}`.concat(phoneNumber));
      inputRef.current?.focus();
    },
    [onChange, phoneNumber],
  );

  const onPhoneNumberChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const newPhoneNumber = selectedDialNumber?.concat(value);

      setPhoneNumber(value);
      onChange(newPhoneNumber);

      setValidation({
        errorText: !isValidPhoneNumber(`${newPhoneNumber}`)
          ? t("ValidationMessages.phone")
          : undefined,
      });
    },
    [onChange, selectedDialNumber, t],
  );

  const onPhoneValidation = useCallback(() => {
    toggleFieldActive();
    setValidation({
      errorText: !isValidPhoneNumber(phone ?? "") ? t("ValidationMessages.phone") : undefined,
    });
  }, [phone, t]);

  const labelLeft = useMemo(
    () =>
      selectedDialNumber
        ? LABEL_DEFAULT_LEFT_POSITION + selectedDialNumber.length * CHAR_WIDTH_SIZE
        : LABEL_DEFAULT_LEFT_POSITION,
    [selectedDialNumber],
  );

  if (loading) {
    return <FieldLoader />;
  }

  if (readOnly) {
    const parsedPhoneNumber = phone ? parsePhoneNumber(phone) : undefined;
    return (
      <Input
        label={t("phone")}
        type="tel"
        value={phone}
        data-testid="Phone"
        readOnly
        loading={loading}
        leadingElement={
          country ? (
            <CountryFlag
              countryCode={parsedPhoneNumber?.country ?? country}
              countryName={parsedPhoneNumber?.country ?? country}
            />
          ) : (
            <Icon icon="Slash" scaleTo={20} />
          )
        }
      />
    );
  }

  return (
    <div
      className={classNames("PhoneNumberField", {
        "PhoneNumberField--active": fieldActive,
      })}
    >
      <div
        data-testid="PhoneNumberField__element"
        className={classNames("PhoneNumberField__element", {
          "PhoneNumberField__element--active": fieldActive,
          "PhoneNumberField__element--error": !!validation?.errorText,
        })}
      >
        <Countries value={country} onCountrySelect={onCountrySelect} />

        <div data-testid="dial-number" className="PhoneNumberField__dial-number">
          {selectedDialNumber}
        </div>

        <input
          ref={inputRef}
          onFocus={toggleFieldActive}
          onBlur={onPhoneValidation}
          type="tel"
          className="PhoneNumberField__input"
          value={phoneNumber}
          onChange={onPhoneNumberChange}
          disabled={!selectedDialNumber}
          data-testid="Phone"
        />

        <label
          className={classNames("PhoneNumberField__label", {
            "PhoneNumberField__label--active": fieldActive || !!phoneNumber,
            "PhoneNumberField__label--error": !!validation?.errorText,
          })}
          style={{
            left: `${labelLeft}px`,
            ...(fieldActive || !!phoneNumber
              ? { transform: `scale(0.75) translate(-${labelLeft + 36}px, calc(-50% - 4px))` }
              : {}),
          }}
        >
          {t("phone")}
        </label>
      </div>

      <FieldValidation {...validation} />
    </div>
  );
}
