import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useMemo } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

export const UACRLimitKey = {
  Normal: "Normal",
  Microalbuminuria: "Microalbuminuria",
  Macroalbuminuria: "Macroalbuminuria",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UACRLimitKey = (typeof UACRLimitKey)[keyof typeof UACRLimitKey];

interface SingleStageRange {
  from?: number;
  to?: number;
}

export type UACRStageRanges = {
  [key in UACRLimitKey]: SingleStageRange;
};

function getRowRanges(data: any, key: UACRLimitKey) {
  if (Object.hasOwn(data, key) === false) {
    throw new Error(`${data} has no ${key} key`);
  }

  switch (key) {
    case "Normal":
      return { to: data[key].value } as const;

    case "Microalbuminuria":
      return { from: data[key].from, to: data[key].to } as const;

    case "Macroalbuminuria":
      return { from: data[key].value } as const;

    default:
      throw new Error(`${key} not covered`);
  }
}

const orderedUACRStages: UACRLimitKey[] = ["Normal", "Microalbuminuria", "Macroalbuminuria"];

export function useGetUACRRanges() {
  const { appConfig } = useGlobalConfigContext();
  let orderedUACRStageRanges: UACRStageRanges = useMemo(
    () => ({
      Normal: {},
      Microalbuminuria: {},
      Macroalbuminuria: {},
    }),
    [],
  );

  const UACRLimits = NotNullOrUndefined(
    appConfig?.laboratory.measurements.limits?.urine.uacr.limits,
  );

  orderedUACRStages.forEach(stage => {
    orderedUACRStageRanges = {
      ...orderedUACRStageRanges,
      [stage]: getRowRanges(UACRLimits, stage),
    };
  });

  const UACRRangePoints = useMemo(() => {
    const set = new Set(
      Object.values(orderedUACRStageRanges)
        .flatMap(el => [el.from, el.to]) // can get [undefined, NUMBER] or [NUMBER, undefined]
        .filter(el => typeof el === "number") as number[], // typescript is stupid
    );

    return Array.from(set);
  }, [orderedUACRStageRanges]);

  return useMemo(
    () => ({ orderedUACRStageRanges, UACRRangePoints }),
    [orderedUACRStageRanges, UACRRangePoints],
  );
}
