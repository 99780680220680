import * as React from "react";
import { SVGProps } from "react";
const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5ZM5.75 7a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0Z"
      fill="currentColor"
    />
    <path
      d="M15.273 2.944a.75.75 0 0 1 .913-.54 4.75 4.75 0 0 1 0 9.203.75.75 0 1 1-.372-1.454 3.25 3.25 0 0 0 0-6.296.75.75 0 0 1-.54-.913ZM5 14.25A4.75 4.75 0 0 0 .25 19v2a.75.75 0 0 0 1.5 0v-2A3.25 3.25 0 0 1 5 15.75h8A3.25 3.25 0 0 1 16.25 19v2a.75.75 0 0 0 1.5 0v-2A4.75 4.75 0 0 0 13 14.25H5ZM20.188 14.404a.75.75 0 1 0-.375 1.452 3.25 3.25 0 0 1 2.437 3.145v2a.75.75 0 0 0 1.5 0v-2.002a4.75 4.75 0 0 0-3.563-4.595Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgUsers;
