import { Icon } from "libs/ui";
import { useTranslation } from "react-i18next";

interface InactiveQRProps {
  onClick: () => void;
}

export function InactiveQR({ onClick }: InactiveQRProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.login.Tabs.TwoFA",
  });

  return (
    <>
      <p className="TwoFATab__text">{t("authentication_inactive_description")}</p>
      <button onClick={onClick} className="TwoFATab__button TwoFATab__button--purple">
        <Icon icon="QrCode" />
        {t("authentication_inactive_button")}
      </button>
    </>
  );
}
