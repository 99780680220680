import * as React from "react";
import { SVGProps } from "react";
const SvgSignIn = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22.25 16.21a6.251 6.251 0 0 1-5.501 6.206c-.412.05-.749-.291-.749-.706 0-.414.338-.743.747-.808a4.751 4.751 0 0 0 4.003-4.692v-9a4.751 4.751 0 0 0-4.003-4.691C16.338 2.454 16 2.125 16 1.71c0-.414.337-.754.749-.705A6.251 6.251 0 0 1 22.25 7.21v9ZM9.22 6.22a.75.75 0 0 1 1.06 0l5 5a.748.748 0 0 1 0 1.06l-5 5a.75.75 0 1 1-1.06-1.06l3.72-3.72H2.75a.75.75 0 0 1 0-1.5h10.19L9.22 7.28a.75.75 0 0 1 0-1.06Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSignIn;
