/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceStatusType } from './DeviceStatusType';
import {
    DeviceStatusTypeFromJSON,
    DeviceStatusTypeFromJSONTyped,
    DeviceStatusTypeToJSON,
} from './DeviceStatusType';
import type { DeviceType } from './DeviceType';
import {
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';

/**
 * 
 * @export
 * @interface ProfileDeviceResponseModel
 */
export interface ProfileDeviceResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ProfileDeviceResponseModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDeviceResponseModel
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDeviceResponseModel
     */
    uniqueId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ProfileDeviceResponseModel
     */
    creationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProfileDeviceResponseModel
     */
    modifyDate: Date;
    /**
     * 
     * @type {DeviceType}
     * @memberof ProfileDeviceResponseModel
     */
    deviceType: DeviceType;
    /**
     * 
     * @type {string}
     * @memberof ProfileDeviceResponseModel
     */
    rowVersion: string;
    /**
     * 
     * @type {DeviceStatusType}
     * @memberof ProfileDeviceResponseModel
     */
    status: DeviceStatusType;
}

/**
 * Check if a given object implements the ProfileDeviceResponseModel interface.
 */
export function instanceOfProfileDeviceResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "serialNumber" in value;
    isInstance = isInstance && "creationDate" in value;
    isInstance = isInstance && "modifyDate" in value;
    isInstance = isInstance && "deviceType" in value;
    isInstance = isInstance && "rowVersion" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function ProfileDeviceResponseModelFromJSON(json: any): ProfileDeviceResponseModel {
    return ProfileDeviceResponseModelFromJSONTyped(json, false);
}

export function ProfileDeviceResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileDeviceResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'serialNumber': json['SerialNumber'],
        'uniqueId': !exists(json, 'UniqueId') ? undefined : json['UniqueId'],
        'creationDate': json['CreationDate'],
        'modifyDate': json['ModifyDate'],
        'deviceType': DeviceTypeFromJSON(json['DeviceType']),
        'rowVersion': json['RowVersion'],
        'status': DeviceStatusTypeFromJSON(json['Status']),
    };
}

export function ProfileDeviceResponseModelToJSON(value?: ProfileDeviceResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'SerialNumber': value.serialNumber,
        'UniqueId': value.uniqueId,
        'CreationDate': value.creationDate,
        'ModifyDate': value.modifyDate,
        'DeviceType': DeviceTypeToJSON(value.deviceType),
        'RowVersion': value.rowVersion,
        'Status': DeviceStatusTypeToJSON(value.status),
    };
}

