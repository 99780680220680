/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OldMobileSectionModel
 */
export interface OldMobileSectionModel {
    /**
     * 
     * @type {string}
     * @memberof OldMobileSectionModel
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof OldMobileSectionModel
     */
    registryNumberName: string;
    /**
     * 
     * @type {number}
     * @memberof OldMobileSectionModel
     */
    rowVersion: number;
}

/**
 * Check if a given object implements the OldMobileSectionModel interface.
 */
export function instanceOfOldMobileSectionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organizationName" in value;
    isInstance = isInstance && "registryNumberName" in value;
    isInstance = isInstance && "rowVersion" in value;

    return isInstance;
}

export function OldMobileSectionModelFromJSON(json: any): OldMobileSectionModel {
    return OldMobileSectionModelFromJSONTyped(json, false);
}

export function OldMobileSectionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OldMobileSectionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationName': json['OrganizationName'],
        'registryNumberName': json['RegistryNumberName'],
        'rowVersion': json['RowVersion'],
    };
}

export function OldMobileSectionModelToJSON(value?: OldMobileSectionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OrganizationName': value.organizationName,
        'RegistryNumberName': value.registryNumberName,
        'RowVersion': value.rowVersion,
    };
}

