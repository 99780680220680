import { Configuration, DashboardsApi, EventsApi, ReportsApi } from "api/report_service";
import { querystring } from "./endpointSerialization";
import { HTTP_FORBIDDEN, HTTP_UNAUTHORIZED } from "./const";
import { injectUnauthorized } from "components/Unathorized";
import { fetchAuthSession } from "@aws-amplify/auth";
import { tryGetLocalStorageValue } from "utils/localStorage";

let checkingForForbiddenUser: Promise<any> | null = null;

export const configurationAuthApi = new Configuration({
  basePath: `${process.env.VITE_APP_REPORT_SERVICE_HOST ?? "VITE_APP_REPORT_SERVICE_HOST-env-variable-missing"}`,
});

export const configuration =
  process.env.MODE === "test"
    ? undefined
    : new Configuration({
        /**
         * check this function in runtime.ts
         */
        queryParamsStringify: querystring,
        basePath: `${
          process.env.VITE_APP_REPORT_SERVICE_HOST ??
          "VITE_APP_REPORT_SERVICE_HOST-env-variable-missing"
        }`,
        // (input: RequestInfo | URL, init?: RequestInit): Promise<Response>
        middleware: [
          {
            async post(param) {
              if (param.response.status === HTTP_UNAUTHORIZED) {
                injectUnauthorized();
                return param.response;
              }

              if (param.response.status === HTTP_FORBIDDEN) {
                // Avoiding concurrency
                if (checkingForForbiddenUser) {
                  await checkingForForbiddenUser;
                } else {
                  try {
                    checkingForForbiddenUser = fetchAuthSession({ forceRefresh: true });
                    await checkingForForbiddenUser;
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  } catch (error) {
                    console.warn("User is possibly logged out");
                  } finally {
                    checkingForForbiddenUser = null;
                  }
                }
              }

              return param.response;
            },
            async pre(context) {
              return {
                url: context.url,
                init: {
                  ...context.init,
                  headers: new Headers({
                    ...context.init.headers,
                    // ...sign.headers,
                    "access-token": `${(await fetchAuthSession()).tokens?.accessToken}`,
                    "identity-token": `${(await fetchAuthSession()).tokens?.idToken}`,
                    "x-tenant": `${tryGetLocalStorageValue<{ "X-Tenant": string }>("lastUsedAuth")?.["X-Tenant"] ?? "missing tenant name"}`,
                  }),
                },
              };
            },
          },
        ],
      });

const DashboardsApiContext = new DashboardsApi(configuration);
const EventsApiContext = new EventsApi(configuration);
const ReportsApiContext = new ReportsApi(configuration);

export const CarnaApiReportService = {
  DashboardsMap: {
    getCDK:
      DashboardsApi.prototype.dashboardsMapsIdentifiedWithCkdRiskGet.bind(DashboardsApiContext),
    getEGFR: DashboardsApi.prototype.dashboardsMapsWithEgfrGet.bind(DashboardsApiContext),
  } as const,
  Event: {
    get: async (id: string) => EventsApi.prototype.eventsIdGet.bind(EventsApiContext)(id),
  },
  Reports: {
    get: ReportsApi.prototype.reportsGet.bind(ReportsApiContext),
    getId: ReportsApi.prototype.reportsReportIdGet.bind(ReportsApiContext),
    getIdFile: ReportsApi.prototype.reportsReportIdFileGet.bind(ReportsApiContext),
  },
} as const;

declare global {
  interface Window {
    ApiReportService: any;
  }
}

if (import.meta.env.MODE === "development") {
  window.ApiReportService = CarnaApiReportService;
}
