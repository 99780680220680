import { useCallback, useState } from "react";
import { FilterSideModalState } from "../Filter/model";

export function useFilterModal<K>(filterOptions: Record<string, any>): FilterSideModalState<K> {
  const [isFilterSideModalOpen, setFilterSideModalOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState<K[]>([]);

  const openFilterModal = useCallback(
    (value?: K) => {
      if (!value) {
        setActiveFilters([...(Object.keys(filterOptions) as unknown as K[])]);
        setFilterSideModalOpen(true);
        return;
      }

      setActiveFilters([value]);
      setFilterSideModalOpen(true);
    },
    [filterOptions],
  );

  const closeModal = useCallback(() => {
    setActiveFilters([]);
    setFilterSideModalOpen(false);
  }, []);

  return {
    isFilterSideModalOpen,
    openFilterModal,
    closeModal,
    activeFilters,
  } as const;
}
