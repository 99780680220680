type Listener = () => void;

export function createBreadcrumbsStore() {
  let currentPageTitle: string = "";
  let currentPageAvatar: string | undefined;
  const listeners = new Set<Listener>();

  const getCurrentPageTitle = () => currentPageTitle;
  const getCurrentPageAvatar = () => currentPageAvatar;

  const setCurrentPageTitle = (value: string) => {
    currentPageTitle = value;

    listeners.forEach(listener => {
      listener();
    });
  };

  const setCurrentPageAvatar = (value?: string) => {
    currentPageAvatar = value;

    listeners.forEach(listener => {
      listener();
    });
  };

  const resetCurrentPageTitle = () => {
    currentPageTitle = "";

    listeners.forEach(listener => {
      listener();
    });
  };

  const subscribe = (listener: Listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };

  return {
    getCurrentPageTitle,
    setCurrentPageTitle,
    resetCurrentPageTitle,
    getCurrentPageAvatar,
    setCurrentPageAvatar,
    subscribe,
  } as const;
}
