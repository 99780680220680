export enum viewType {
  GRID = "grid",
  LIST = "list",
}

export const initialViewsSate = {
  grid: true,
  list: true,
};
export interface ViewsState {
  grid: boolean;
  list: boolean;
}

export interface ViewsAction {
  type: viewType;
}

export const viewsReducer = (state: ViewsState, action: ViewsAction) => {
  const { type } = action;
  switch (type) {
    case viewType.GRID:
      return {
        grid: !state.grid,
        list: state.grid ? true : state.list,
      };
    case viewType.LIST:
      return {
        grid: state.list ? true : state.grid,
        list: !state.list,
      };
    default:
      return state;
  }
};
