/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GraphFilterModel,
  GraphPatientResponseModel,
} from '../models/index';
import {
    GraphFilterModelFromJSON,
    GraphFilterModelToJSON,
    GraphPatientResponseModelFromJSON,
    GraphPatientResponseModelToJSON,
} from '../models/index';

export interface OrganizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGetRequest {
    organizationId: string;
    userEntityId: string;
    filters?: GraphFilterModel;
    acceptVersion?: string;
}

/**
 * GraphsApi - interface
 * 
 * @export
 * @interface GraphsApiInterface
 */
export interface GraphsApiInterface {
    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {GraphFilterModel} [filters] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApiInterface
     */
    organizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GraphPatientResponseModel>>;

    /**
     */
    organizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGet(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GraphPatientResponseModel>;

}

/**
 * 
 */
export class GraphsApi extends runtime.BaseAPI implements GraphsApiInterface {

     /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGetRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}/measurements/graphs`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GraphPatientResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}/measurements/graphs`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GraphPatientResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGet(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GraphPatientResponseModel> {
        const response = await this.organizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
