import * as React from "react";
import { SVGProps } from "react";
const SvgCircleCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#circle-check_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm5.749-15.749a.857.857 0 0 1 0 1.212l-6.892 6.892a.857.857 0 0 1-1.212 0l-3.394-3.394a.857.857 0 0 1 1.212-1.212l2.788 2.788 6.286-6.286a.857.857 0 0 1 1.212 0Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="circle-check_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCircleCheck;
