import * as AddModal from "components/AddModal/User";
import { AddModalForwardedProps } from "components/AddModal/model";
import { hasFilter } from "components/AppTables/utils";
import { EmptyStateIcon } from "components/EmptyStateIcon";
import { NEW_BACKEND } from "config/NEW_BACKEND";
import { ActionButton, EmptyState, TableRefs } from "libs/ui";
import { statusToIcon } from "libs/ui/ActionButton/utils";
import { TableUtils } from "libs/ui/Table/utils";
import { cloneDeep } from "lodash-es";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useChangeStatusModal } from "utils/hooks/ChangeEntityStatus/useChangeStatusModal";
import { useFloatingActionButtons } from "utils/hooks/useFloatingActionButton";
import { BodyRows } from "../AdminPartnerBodyRows";
import { BaseTable } from "../Table/BaseTable";
import { CHANGE_STATUS_ALLOWED } from "../model";
import { PartnersTableFilter } from "./PartnersTableFilter";
import { resolveAppliedColumns } from "./constants";
import { PartnersTableProps, UIPartnersFilters } from "./model";

export function PartnersTable({
  tableStateContext,
  appliedColumns,
  onFilterToggle,
  searchField,
  addButton,
  filterOptions,
}: Readonly<PartnersTableProps>) {
  const { tableService, filterSideModelState, fetch } = tableStateContext;
  const { t } = useTranslation("translation", { keyPrefix: "AdministratorsTable" });

  const [state] = tableService;

  const addModalRef = useRef<AddModalForwardedProps>(null);
  const toggle = () => addModalRef?.current?.toggleModal();

  const { ChangeStateModal, changeStateClick } = useChangeStatusModal(tableService as NEW_BACKEND, {
    onSuccessCb: fetch,
  });

  const columns = resolveAppliedColumns({
    appliedColumns,
    t,
  });

  const tableRef = useRef<TableRefs>(null);
  const [currentRightClickContext, setCurrentRightClickContext] = useFloatingActionButtons<
    Parameters<typeof changeStateClick>[0]
  >(tableRef.current?.containerElem);

  const hasFilters = TableUtils.areFiltersApplied(state.context);
  const noTableData = state.matches("loaded") && !state.context.data?.items?.length && !hasFilters;

  const onOpenFilterModal = (value: any) => filterSideModelState.openFilterModal(value);
  const resolveFilterApplied = (value: string) =>
    !!state.context.filters?.[value as keyof UIPartnersFilters]?.length;

  const resolveShowFilterOption = (columnName: string) => {
    return hasFilter(columnName, NotNullOrUndefined(filterOptions));
  };

  if (noTableData) {
    return (
      <>
        <EmptyState title={t("emptyTitle")} description={t("emptyDescription")} onCreate={toggle}>
          <EmptyStateIcon variant="Admins" />
        </EmptyState>
        <AddModal.User ref={addModalRef} onSuccessCb={fetch} />
      </>
    );
  }

  return (
    <>
      <BaseTable
        innerRef={tableRef}
        tableService={tableService}
        className="PartnersTable"
        columns={columns}
        onOpenFilterModal={onOpenFilterModal}
        resolveFilterApplied={resolveFilterApplied}
        resolveShowFilterOption={resolveShowFilterOption}
        onFilterToggle={onFilterToggle}
        searchField={searchField}
        viewTableSelector={true}
        tableContainerAdditionalChildren={
          currentRightClickContext?.[1] &&
          CHANGE_STATUS_ALLOWED.some(el => el === currentRightClickContext[1].status) && (
            <ActionButton
              style={{
                position: "absolute",
                top: `${currentRightClickContext?.[0].pageY}px`,
                left: `${currentRightClickContext?.[0].pageX}px`,
              }}
              onClick={e => {
                changeStateClick(cloneDeep(currentRightClickContext?.[1]))(e);
                setCurrentRightClickContext(undefined);
              }}
              actionType={currentRightClickContext[1].status}
              iconType={statusToIcon(currentRightClickContext[1].status)}
            >
              {t(`actions.${currentRightClickContext[1].status}`)}
            </ActionButton>
          )
        }
        addButton={addButton}
      >
        <BodyRows
          appliedColumns={appliedColumns}
          changeUserStateClick={changeStateClick}
          tableService={tableService}
          currentRightClickContext={currentRightClickContext}
          setCurrentRightClickContext={setCurrentRightClickContext}
        />
      </BaseTable>

      {ChangeStateModal}

      <PartnersTableFilter
        tableService={tableService}
        filterSideModelState={filterSideModelState}
      />
    </>
  );
}
