/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GroupedByItemModel } from './GroupedByItemModel';
import {
    GroupedByItemModelFromJSON,
    GroupedByItemModelFromJSONTyped,
    GroupedByItemModelToJSON,
} from './GroupedByItemModel';

/**
 * 
 * @export
 * @interface WithEgfrGroupModel
 */
export interface WithEgfrGroupModel {
    /**
     * 
     * @type {GroupedByItemModel}
     * @memberof WithEgfrGroupModel
     */
    groupedBy?: GroupedByItemModel;
    /**
     * 
     * @type {number}
     * @memberof WithEgfrGroupModel
     */
    numberOfPatients?: number;
    /**
     * 
     * @type {number}
     * @memberof WithEgfrGroupModel
     */
    numberOfPatientsWithEgfr?: number;
}

/**
 * Check if a given object implements the WithEgfrGroupModel interface.
 */
export function instanceOfWithEgfrGroupModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WithEgfrGroupModelFromJSON(json: any): WithEgfrGroupModel {
    return WithEgfrGroupModelFromJSONTyped(json, false);
}

export function WithEgfrGroupModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithEgfrGroupModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupedBy': !exists(json, 'GroupedBy') ? undefined : GroupedByItemModelFromJSON(json['GroupedBy']),
        'numberOfPatients': !exists(json, 'NumberOfPatients') ? undefined : json['NumberOfPatients'],
        'numberOfPatientsWithEgfr': !exists(json, 'NumberOfPatientsWithEgfr') ? undefined : json['NumberOfPatientsWithEgfr'],
    };
}

export function WithEgfrGroupModelToJSON(value?: WithEgfrGroupModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'GroupedBy': GroupedByItemModelToJSON(value.groupedBy),
        'NumberOfPatients': value.numberOfPatients,
        'NumberOfPatientsWithEgfr': value.numberOfPatientsWithEgfr,
    };
}

