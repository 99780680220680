import classNames from "classnames";
import { ReactElement, useState } from "react";
import { TabItem, TabVariantType } from "./TabItem";
import "./Tabs.scss";

type TabsProps = {
  className?: string;
  tabVariant?: TabVariantType;
  tabs: {
    label: string;
    Component: ReactElement;
    customHeaderControlElem?: ReactElement;
    disabled?: boolean;
  }[];
};

export function Tabs({ tabs = [], className, tabVariant }: TabsProps) {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className={classNames("UI-Components Tabs", className)}>
      <div
        className={classNames("Tabs__container", {
          [`Tabs__container--${tabVariant}`]: !!tabVariant,
        })}
      >
        <ul className="Tabs__list">
          {tabs.map((tab, index) => (
            <TabItem
              key={index}
              tabVariant={tabVariant}
              isActive={selectedTab === index}
              onClick={() => setSelectedTab(index)}
              disabled={tab.disabled}
            >
              {tab.label}
            </TabItem>
          ))}
        </ul>
        {tabs[selectedTab].customHeaderControlElem}
      </div>
      {tabs[selectedTab].Component}
    </div>
  );
}
