import { MeasurementGraphModel } from "api/query";

export function calculateAverageWeight(measurements?: MeasurementGraphModel[]) {
  if (!Array.isArray(measurements) || measurements.length === 0) {
    return 0;
  }
  const totalWeight = measurements.reduce((acc, measurement) => {
    const weightValue = measurement.units.weight?.value ?? 0;
    return acc + weightValue;
  }, 0);

  return totalWeight / measurements.length;
}
