import { RegionType, TimeZoneType } from "api/query";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { useCallback, useMemo } from "react";
import { getCurrentGlobalPreferences } from "utils/getCurrentGlobalPreferences";
import { dateFormat, timeFormat, dateTimeFormat } from "./dateTimeFormat";

export function useRegionDateTimeFormatter() {
  const { globalPreference } = useGlobalPreferenceContext();

  // TODO, we should remove the value: string from here, it can make errors
  const dateFormatter = useCallback(
    (value: string | Date, region?: RegionType, timeZone?: TimeZoneType) => {
      return dateFormat(
        value,
        region ?? globalPreference?.regionType ?? getCurrentGlobalPreferences().regionType,
        timeZone ?? globalPreference?.timeZoneType ?? getCurrentGlobalPreferences().timeZoneType,
      );
    },
    [globalPreference?.regionType, globalPreference?.timeZoneType],
  );

  // TODO, we should remove the value: string from here, it can make errors
  const timeFormatter = useCallback(
    (value: string | Date, region?: RegionType, timeZone?: TimeZoneType) => {
      return timeFormat(
        value,
        region ?? globalPreference?.regionType ?? getCurrentGlobalPreferences().regionType,
        timeZone ?? globalPreference?.timeZoneType ?? getCurrentGlobalPreferences().timeZoneType,
      );
    },
    [globalPreference?.regionType, globalPreference?.timeZoneType],
  );

  // TODO, we should remove the value: string from here, it can make errors

  const dateTimeFormatter = useCallback(
    (value: string | Date, region?: RegionType, timeZone?: TimeZoneType) => {
      return dateTimeFormat(
        value,
        region ?? globalPreference?.regionType ?? getCurrentGlobalPreferences().regionType,
        timeZone ?? globalPreference?.timeZoneType ?? getCurrentGlobalPreferences().timeZoneType,
      );
    },
    [globalPreference?.regionType, globalPreference?.timeZoneType],
  );

  const getCurrentDateTimePerTimeZonePreferences = useCallback(() => {
    return getCurrentDateTimeISOenUS(globalPreference?.timeZoneType);
  }, [globalPreference?.timeZoneType]);

  const formatters = useMemo(
    () =>
      [
        dateFormatter,
        timeFormatter,
        dateTimeFormatter,
        getCurrentDateTimePerTimeZonePreferences,
      ] as const,
    [dateFormatter, timeFormatter, dateTimeFormatter, getCurrentDateTimePerTimeZonePreferences],
  );

  return formatters;
}

export function getCurrentDateTimeISOenUS(timeZone?: TimeZoneType) {
  const resolveDatePerTimeZone = new Date(
    dateTimeFormat(
      new Date(),
      "en-US",
      timeZone ?? (Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZoneType),
    ),
  );

  resolveDatePerTimeZone.setSeconds(new Date().getSeconds());
  resolveDatePerTimeZone.setMilliseconds(new Date().getMilliseconds());

  return resolveDatePerTimeZone;
}
