import classNames from "classnames";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { GenderType } from "models/PersonModels";
import { getCorrectedValueForCreatinine } from "utils/converters/unitsConverter";
import { useGetSerumCreatinineRange } from "utils/hooks/useGetSerumCreatinineRanges";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import "./Range.scss";

interface RangeProps {
  gender: GenderType;
}

export function Range({ gender }: RangeProps) {
  const { appConfig } = useGlobalConfigContext();
  const { numberFormatter } = useLocaleNumberFormatter();

  const [getSerumCreatinineRange] = useGetSerumCreatinineRange();

  const { serumCreatinine } = appConfig?.laboratory.measurements ?? {};
  const { measurementUnit: unit } = serumCreatinine ?? {};

  const [rangeFrom, rangeTo] = getSerumCreatinineRange(gender);

  const safeRangeFromPerUnit =
    unit === MEASUREMENT_UNITS.mgdL
      ? rangeFrom ?? 1
      : getCorrectedValueForCreatinine(rangeFrom ?? 1, MEASUREMENT_UNITS.umolL);

  const safeRangeToPerUnit =
    unit === MEASUREMENT_UNITS.mgdL
      ? rangeTo ?? 1
      : getCorrectedValueForCreatinine(rangeTo ?? 1, MEASUREMENT_UNITS.umolL);

  const nonSafeRangeAbove =
    unit === MEASUREMENT_UNITS.mgdL
      ? 7
      : getCorrectedValueForCreatinine(7, MEASUREMENT_UNITS.umolL);

  const hasBelow = rangeFrom !== 0;

  return (
    <div className="Range">
      {hasBelow ? (
        <div className="Range__pill Range__pill--notSafe Range__pill--below">
          {numberFormatter({ value: 0 })
            .concat(" - ")
            .concat(numberFormatter({ value: safeRangeFromPerUnit }))}
        </div>
      ) : null}

      <div
        className={classNames("Range__pill Range__pill--safe", {
          "Range__pill--safe-middle": hasBelow,
        })}
      >
        {numberFormatter({ value: safeRangeFromPerUnit })
          .concat(" - ")
          .concat(numberFormatter({ value: safeRangeToPerUnit }))}
      </div>
      <div
        className={classNames("Range__pill Range__pill--notSafe", {
          "Range__pill--above": hasBelow,
        })}
      >
        {numberFormatter({ value: safeRangeToPerUnit })
          .concat(" - ")
          .concat(numberFormatter({ value: nonSafeRangeAbove }))}
      </div>
    </div>
  );
}
