import * as React from "react";
import { SVGProps } from "react";
const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.32 2.684A.75.75 0 0 1 2 2.25h20a.75.75 0 0 1 .573 1.234l-7.823 9.25V21a.75.75 0 0 1-1.085.67l-4-2A.75.75 0 0 1 9.25 19v-6.265l-7.823-9.25a.75.75 0 0 1-.107-.8ZM3.616 3.75l6.957 8.226a.75.75 0 0 1 .177.484v6.076l2.5 1.25V12.46a.75.75 0 0 1 .177-.484l6.957-8.226H3.616Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFilter;
