import { Icon, IconType } from "libs/ui";
import { useTranslation } from "react-i18next";
import "./ActiveDevice.scss";

export interface ActiveDeviceProps {
  icon: IconType;
  systemDetails: string;
  location: string;
  date: string;
  time: string;
  loading: boolean;
}

export function ActiveDevice({
  icon,
  systemDetails,
  location,
  date,
  time,
  loading,
}: ActiveDeviceProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "SecurityAndPrivacy.DeviceHistory.ActiveDevice",
  });

  return (
    <div className="ActiveDevice">
      <div className="ActiveDevice__icon">
        {loading ? (
          <span className="ActiveDevice__icon__loader" />
        ) : (
          <Icon icon={icon} scaleTo={32} />
        )}
      </div>
      <div className="ActiveDevice__content">
        <h4 className="ActiveDevice__title">
          {loading ? <span className="ActiveDevice__title__loader" /> : systemDetails}
        </h4>
        <p className="ActiveDevice__details">
          {loading ? (
            <span className="ActiveDevice__details__loader" />
          ) : (
            t("details", { location, date, time })
          )}
        </p>
      </div>
    </div>
  );
}
