import classNames from "classnames";
import { AddModalForwardedProps } from "components/AddModal/model";
import * as AddModal from "components/AddModal/Patient";
import { hasFilter } from "components/AppTables/utils";
import { EmptyStateIcon } from "components/EmptyStateIcon";
import { NEW_BACKEND } from "config/NEW_BACKEND";
import { ActionButton, EmptyState, TableRefs } from "libs/ui";
import { statusToIcon } from "libs/ui/ActionButton/utils";
import { TableUtils } from "libs/ui/Table/utils";
import { cloneDeep } from "lodash-es";
import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useChangeStatusModal } from "utils/hooks/ChangeEntityStatus/useChangeStatusModal";
import { useDeletePerson } from "utils/hooks/useDeletePerson";
import { useFloatingActionButtons } from "utils/hooks/useFloatingActionButton";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { CHANGE_STATUS_ALLOWED } from "../model";
import { BaseTable } from "../Table/BaseTable";
import { BodyRows } from "./BodyRows";
import { resolveAppliedColumns } from "./constants";
import { PatientsTableProps, UIPatientsFilters } from "./model";
import { PatientsTableFilter } from "./PatientsTableFilter";

export function PatientsTable({
  patientsTableStateContext,
  appliedColumns,
  onFilterToggle,
  searchField,
  hcpsFilter,
  addButton,
  initialValuesAsProps,
  patientsFilterOptions,
}: Readonly<PatientsTableProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "PatientsTable" });

  const { tableService, filterSideModelState, fetch: fetchPatients } = patientsTableStateContext;
  const [state] = tableService;

  const addModalRef = useRef<AddModalForwardedProps>(null);
  const toggle = () => addModalRef?.current?.toggleModal();

  const { ChangeStateModal, changeStateClick } = useChangeStatusModal(tableService as NEW_BACKEND, {
    onSuccessCb: fetchPatients,
  });

  const onDeleteSuccess = useCallback(() => {
    fetchPatients();
  }, [fetchPatients]);

  const [DeleteModal, selectPersonToDelete] = useDeletePerson(onDeleteSuccess);

  const tableRef = useRef<TableRefs>(null);
  const [currentRightClickContext, setCurrentRightClickContext] = useFloatingActionButtons<
    Parameters<typeof changeStateClick>[0]
  >(tableRef.current?.containerElem);

  const columns = useMemo(
    () =>
      resolveAppliedColumns({
        appliedColumns,
        t,
      }),
    [appliedColumns, t],
  );

  const hasFilters = TableUtils.areFiltersApplied(state.context);
  const noTableData = state.matches("loaded") && !state.context.data.items.length && !hasFilters;

  const resolveShowFilterOption = (columnName: string) =>
    hasFilter(columnName, NotNullOrUndefined(patientsFilterOptions));

  const onOpenFilterModal = (value: any) => filterSideModelState.openFilterModal(value);

  const resolveFilterApplied = useCallback(
    (value: string) => {
      if (value === "withDevice") {
        return state.context.filters?.withDevice !== undefined;
      }
      return !!state.context.filters?.[value as keyof Omit<UIPatientsFilters, "withDevice">]
        ?.length;
    },
    [state.context.filters],
  );

  const showFilterButton = patientsFilterOptions
    ?.filter(el => el !== "contains")
    .filter(el => {
      if (!hcpsFilter) {
        return el !== "hcpIds";
      }

      return el;
    }).length;

  if (noTableData) {
    return (
      <>
        <EmptyState title={t("emptyTitle")} description={t("emptyDescription")} onCreate={toggle}>
          <EmptyStateIcon variant="Patients" />
        </EmptyState>

        <AddModal.Patient
          ref={addModalRef}
          onSuccessCb={fetchPatients}
          initialValuesAsProps={initialValuesAsProps}
        />
      </>
    );
  }

  return (
    <>
      <BaseTable
        innerRef={tableRef}
        tableService={tableService}
        className="PatientsTable"
        columns={columns}
        onOpenFilterModal={onOpenFilterModal}
        resolveFilterApplied={resolveFilterApplied}
        resolveShowFilterOption={resolveShowFilterOption}
        onFilterToggle={onFilterToggle}
        searchField={searchField}
        viewTableSelector={true}
        showFilterButton={!!showFilterButton}
        tableContainerAdditionalChildren={
          currentRightClickContext?.[1] &&
          CHANGE_STATUS_ALLOWED.some(el => el === currentRightClickContext[1].status) ? (
            <ActionButton
              onClick={e => {
                changeStateClick(cloneDeep(currentRightClickContext?.[1]))(e);
                setCurrentRightClickContext(undefined);
              }}
              className={classNames("ActionButton--withShadow")}
              actionType={currentRightClickContext[1].status}
              iconType={statusToIcon(currentRightClickContext[1].status)}
            >
              {t(`actions.${currentRightClickContext[1].status}`)}
            </ActionButton>
          ) : null
        }
        addButton={addButton}
      >
        <BodyRows
          appliedColumns={appliedColumns}
          changePatientStateClick={changeStateClick}
          tableService={tableService}
          currentRightClickContext={currentRightClickContext}
          setCurrentRightClickContext={setCurrentRightClickContext}
          onDeletePatient={selectPersonToDelete}
        />
      </BaseTable>

      {ChangeStateModal}
      {DeleteModal}

      <PatientsTableFilter
        tableService={tableService}
        filterSideModelState={filterSideModelState}
        hcpsFilter={hcpsFilter}
      />
    </>
  );
}
