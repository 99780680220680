import { Tabs } from "libs/ui";
import { useTranslation } from "react-i18next";
import { UsersVisibility } from "./tabs/UsersVisibility";
import { ViewOptions } from "./tabs/ViewOptions";
import { SettingsPage } from "components/PageTemplate/SettingsPage";

export function UserView() {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.rule-engine.user-view",
  });

  return (
    <SettingsPage>
      <div className="RuleEngine__UserView">
        <Tabs
          tabs={[
            {
              label: t("tabs.users_visibility"),
              Component: <UsersVisibility />,
            },
            {
              label: t("tabs.view_options"),
              Component: <ViewOptions />,
            },
          ]}
        />
      </div>
    </SettingsPage>
  );
}
