/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceModel } from './DeviceModel';
import {
    DeviceModelFromJSON,
    DeviceModelFromJSONTyped,
    DeviceModelToJSON,
} from './DeviceModel';
import type { MeasurementResponseModel } from './MeasurementResponseModel';
import {
    MeasurementResponseModelFromJSON,
    MeasurementResponseModelFromJSONTyped,
    MeasurementResponseModelToJSON,
} from './MeasurementResponseModel';

/**
 * 
 * @export
 * @interface TestWidgetDataModel
 */
export interface TestWidgetDataModel {
    /**
     * 
     * @type {DeviceModel}
     * @memberof TestWidgetDataModel
     */
    device?: DeviceModel;
    /**
     * 
     * @type {Array<MeasurementResponseModel>}
     * @memberof TestWidgetDataModel
     */
    measurements: Array<MeasurementResponseModel>;
    /**
     * 
     * @type {string}
     * @memberof TestWidgetDataModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TestWidgetDataModel
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof TestWidgetDataModel
     */
    measurementTime: string;
    /**
     * 
     * @type {string}
     * @memberof TestWidgetDataModel
     */
    offset: string;
    /**
     * 
     * @type {string}
     * @memberof TestWidgetDataModel
     */
    measurementUtc: string;
    /**
     * 
     * @type {string}
     * @memberof TestWidgetDataModel
     */
    rowVersion: string;
}

/**
 * Check if a given object implements the TestWidgetDataModel interface.
 */
export function instanceOfTestWidgetDataModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "measurements" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "measurementTime" in value;
    isInstance = isInstance && "offset" in value;
    isInstance = isInstance && "measurementUtc" in value;
    isInstance = isInstance && "rowVersion" in value;

    return isInstance;
}

export function TestWidgetDataModelFromJSON(json: any): TestWidgetDataModel {
    return TestWidgetDataModelFromJSONTyped(json, false);
}

export function TestWidgetDataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestWidgetDataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'device': !exists(json, 'Device') ? undefined : DeviceModelFromJSON(json['Device']),
        'measurements': ((json['Measurements'] as Array<any>).map(MeasurementResponseModelFromJSON)),
        'id': json['Id'],
        'organizationId': json['OrganizationId'],
        'measurementTime': json['MeasurementTime'],
        'offset': json['Offset'],
        'measurementUtc': json['MeasurementUtc'],
        'rowVersion': json['RowVersion'],
    };
}

export function TestWidgetDataModelToJSON(value?: TestWidgetDataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Device': DeviceModelToJSON(value.device),
        'Measurements': ((value.measurements as Array<any>).map(MeasurementResponseModelToJSON)),
        'Id': value.id,
        'OrganizationId': value.organizationId,
        'MeasurementTime': value.measurementTime,
        'Offset': value.offset,
        'MeasurementUtc': value.measurementUtc,
        'RowVersion': value.rowVersion,
    };
}

