import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { useCallback, useMemo } from "react";
import { useGetUserPreferredMeasurementUnits } from "../useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { gramsToKilograms, gramsToPounds } from "utils/converters/unitsConverter";

export function useGetWeightConverter() {
  const { weightUnit } = useGetUserPreferredMeasurementUnits();

  const getConvertedWeight = useCallback(
    (value: number) => {
      return weightUnit === MEASUREMENT_UNITS.kg ? gramsToKilograms(value) : gramsToPounds(value);
    },
    [weightUnit],
  );

  return useMemo(() => getConvertedWeight, [getConvertedWeight]);
}
