import * as React from "react";
import { SVGProps } from "react";
const SvgArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.713 19.693a.748.748 0 0 1-.243-.163l-7-7a.75.75 0 1 1 1.06-1.06l5.72 5.72V5a.75.75 0 0 1 1.5 0v12.19l5.72-5.72a.75.75 0 1 1 1.06 1.06l-7 7M12.529 19.532a.748.748 0 0 1-.816.161"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowDown;
