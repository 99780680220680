/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventResponseModel
 */
export interface EventResponseModel {
    /**
     * 
     * @type {string}
     * @memberof EventResponseModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventResponseModel
     */
    streamId?: string | null;
}

/**
 * Check if a given object implements the EventResponseModel interface.
 */
export function instanceOfEventResponseModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventResponseModelFromJSON(json: any): EventResponseModel {
    return EventResponseModelFromJSONTyped(json, false);
}

export function EventResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'Id') ? undefined : json['Id'],
        'streamId': !exists(json, 'StreamId') ? undefined : json['StreamId'],
    };
}

export function EventResponseModelToJSON(value?: EventResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'StreamId': value.streamId,
    };
}

