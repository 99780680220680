import i18n from "i18next";

export function getMeasurementTabs(translationSource: string) {
  return [
    {
      routePath: "all",
      label: i18n.t(`${translationSource}.tabs.titleAll`, { ns: "translation" }),
    },
    {
      routePath: "serumCreatinine",
      label: i18n.t(`${translationSource}.tabs.titleSerumCreatinine`, { ns: "translation" }),
    },
    {
      routePath: "egfr",
      label: i18n.t(`${translationSource}.tabs.titleeGFR`, { ns: "translation" }),
    },

    {
      routePath: "bmi",
      label: i18n.t(`${translationSource}.tabs.titleBMI`, { ns: "translation" }),
    },
    {
      routePath: "height",
      label: i18n.t(`${translationSource}.tabs.titleHeight`, { ns: "translation" }),
    },
    {
      routePath: "weight",
      label: i18n.t(`${translationSource}.tabs.titleWeight`, { ns: "translation" }),
    },

    {
      routePath: "bloodPressure",
      label: i18n.t(`${translationSource}.tabs.titleBloodPressure`, { ns: "translation" }),
    },

    {
      routePath: "glucose",
      label: i18n.t(`${translationSource}.tabs.titleGlucose`, { ns: "translation" }),
    },
    {
      routePath: "urineCreatinine",
      label: i18n.t(`${translationSource}.tabs.titleUrineCreatinine`, { ns: "translation" }),
    },
    {
      routePath: "urineAlbumin",
      label: i18n.t(`${translationSource}.tabs.titleUrineAlbumin`, { ns: "translation" }),
    },
    {
      routePath: "uacr",
      label: i18n.t(`${translationSource}.tabs.titleUACR`, { ns: "translation" }),
    },
    {
      routePath: "semiQuantitativeUacr",
      label: i18n.t(`${translationSource}.tabs.titleSQUACR`, { ns: "translation" }),
    },
  ] as const;
}
