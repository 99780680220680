import classNames from "classnames";
import React, { useRef, useState } from "react";
import { Dropdown } from "../Dropdown";
import { useOnClickOutside } from "../hooks";
import { Icon } from "../Icon";
import { DataAttributeKey } from "../models/common";
import { isKeyboardClickKey } from "../utils";
import "./Select.scss";
import { SelectOption } from "../SelectOption";
import { SelectProps } from "./Select.model";

export function Select<T>({
  options,
  onSelect,
  placeholder,
  label,
  disabled,
  value,
  className,
  dropdownFloatingProps,
  readOnly,
  ...rest
}: SelectProps<T> & {
  [dataAttribute: DataAttributeKey]: any;
}) {
  const selectRef = useRef(null);
  const selectBoxRef = useRef(null);

  const [showOptions, setShowOptions] = useState(false);

  const currentElement = options.find(o => o.value === value);

  const onOptionSelect = (selectedValue: T) => {
    if (onSelect) {
      onSelect(selectedValue);
    }
  };

  const toggleDropdown = () => {
    if (disabled || readOnly) {
      return;
    }

    setShowOptions(prevValue => !prevValue);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (isKeyboardClickKey(e.key)) {
      e.preventDefault();
      toggleDropdown();
    }
  };

  useOnClickOutside(selectRef, showOptions, toggleDropdown);

  return (
    <div {...rest} ref={selectRef} className={classNames("UI-Components Select", className)}>
      {label && <label className="Select__label">{label}</label>}

      <div
        ref={selectBoxRef}
        className={classNames("Select__current", {
          "Select__current--active": showOptions,
          "Select__current--disabled": disabled ?? readOnly,
        })}
        role="listbox"
        tabIndex={0}
        onClick={toggleDropdown}
        onKeyDown={onKeyDown}
      >
        {currentElement?.icon ? (
          <Icon icon={currentElement.icon} className="Select__current__icon" />
        ) : null}

        <span>{currentElement?.title ?? placeholder}</span>

        <Icon icon="ChevronDown" className="Select__dropdownIcon" color="inherit" />

        <Dropdown
          show={showOptions}
          targetElement={() => selectBoxRef.current}
          floatingProps={dropdownFloatingProps}
        >
          {options.map(({ title, value: val, icon }, index) => (
            <SelectOption<T>
              key={index}
              isActive={val === value}
              onSelect={onOptionSelect}
              title={title}
              value={val}
              icon={icon}
            />
          ))}
        </Dropdown>
      </div>
    </div>
  );
}
