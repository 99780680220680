import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { UacrStatusType } from "models/TestModels";

export function useUACRStageText() {
  const { t } = useTranslation("translation", { keyPrefix: "useUACRStageText" });

  const getUACRStageText = useCallback(
    (status?: UacrStatusType) => {
      if (!status) {
        return undefined;
      }

      return t(status);
    },
    [t],
  );

  return [getUACRStageText] as const;
}
