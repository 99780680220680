import * as React from "react";
import { SVGProps } from "react";
const SvgCreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3.25A2.75 2.75 0 0 0 .25 6v12A2.75 2.75 0 0 0 3 20.75h18A2.75 2.75 0 0 0 23.75 18V6A2.75 2.75 0 0 0 21 3.25H3ZM1.75 6c0-.69.56-1.25 1.25-1.25h18c.69 0 1.25.56 1.25 1.25v2.5H1.75V6Zm0 5.5V18c0 .69.56 1.25 1.25 1.25h18c.69 0 1.25-.56 1.25-1.25v-6.5H1.75Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCreditCard;
