import { clone, uniq } from "lodash-es";
import { Observable, Subject, catchError, defer, filter, from, map, mergeAll, of } from "rxjs";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { fetchPictureUrls } from "utils/helpers/pictures";
import { PictureResolution } from "utils/helpers/pictures.model";

type Url = string;
type UserId = string;
type OrgId = string;

type AvatarModel = {
  [key: OrgId]:
    | {
        [key: UserId]:
          | {
              [key in PictureResolution]?: Url;
            }
          | undefined;
      }
    | undefined;
};

type Listener = () => void;

class ListenerTimer {
  #timerId: any;
  #startTime: number;

  constructor() {
    this.#timerId = null;
    this.#startTime = 0;
  }

  getElapsedTime() {
    return Date.now() - this.#startTime;
  }

  start(initialDuration: number, callback: () => void) {
    this.clearTimer();
    this.#startTime = Date.now();

    this.#timerId = setTimeout(() => {
      this.#timerId = null;
      callback();
    }, initialDuration);
  }

  update(newDuration: number, callback: () => void) {
    if (!this.#timerId) {
      this.start(newDuration, callback);
      return;
    }

    const elapsedTime = this.getElapsedTime();

    if (elapsedTime + newDuration > this.#startTime) {
      this.start(newDuration, callback);
    }
  }

  clearTimer() {
    if (this.#timerId) {
      clearTimeout(this.#timerId);
      this.#timerId = null;
    }
  }
}

function bufferWithResetting<T>(timeInMillis: number) {
  return (source: Observable<T>) => {
    return new Observable<T[]>(subscriber => {
      let buffer: T[] = [];
      let timer: any | null = null;

      const resetTimer = () => {
        if (timer) {
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          if (buffer.length > 0) {
            subscriber.next(buffer);
            buffer = [];
          }
        }, timeInMillis);
      };

      const subscription = source.subscribe({
        next(value) {
          buffer.push(value);
          resetTimer();
        },
        error(err) {
          if (timer) {
            clearTimeout(timer);
          }
          subscriber.error(err);
        },
        complete() {
          if (timer) {
            clearTimeout(timer);
          }
          if (buffer.length > 0) {
            subscriber.next(buffer);
          }
          subscriber.complete();
        },
      });

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
        subscription.unsubscribe();
      };
    });
  };
}

export class MakeAvatarCacher {
  private cache: AvatarModel;
  private rjxGetAvatar: Subject<{
    organizationId: OrgId;
    userId: UserId;
    resolution: PictureResolution;
    resolve: any;
    reject: any;
  }>;
  private listeners: Set<Listener>;
  private alreadyDownloaded: Record<string, string | undefined>;
  private listenerTimer = new ListenerTimer();

  constructor() {
    this.cache = {};
    this.rjxGetAvatar = new Subject<{
      organizationId: OrgId;
      userId: UserId;
      resolution: PictureResolution;
      resolve: any;
      reject: any;
    }>();
    this.listeners = new Set<Listener>();
    this.alreadyDownloaded = {};

    this.rjxGetAvatar
      .pipe(
        bufferWithResetting(100),
        filter(bufferedInputs => bufferedInputs.length > 0),
        map(bufferedInputs => {
          // Create an observable for each batch
          const fetchReqs = bufferedInputs.reduce(
            (p, c) => {
              if (!p[c.organizationId]) {
                p[c.organizationId] = {};
              }

              if (!p[c.organizationId][c.resolution]) {
                p[c.organizationId][c.resolution] = [];
              }
              p[c.organizationId][c.resolution].push(c.userId);
              p[c.organizationId][c.resolution] = uniq(p[c.organizationId][c.resolution]);

              return p;
            },
            {} as Record<string, Record<string, any>>,
          );

          const results = Object.entries(fetchReqs).flatMap(([orgId, rest]) => {
            return Object.entries(rest).flatMap(([resolution, userIds]) => {
              return from(fetchPictureUrls(orgId, userIds, resolution as PictureResolution)).pipe(
                map(data => ({
                  data,
                  error: false,
                  organizationId: orgId,
                  resolution,
                  bInput: userIds.map((uId: string) =>
                    bufferedInputs.find(i => i.userId === uId),
                  ) as typeof bufferedInputs,
                })),
                catchError(err => {
                  return of({
                    data: undefined,
                    error: true,
                    organizationId: orgId,
                    resolution,
                    bInput: userIds.map((uId: string) =>
                      bufferedInputs.find(i => i.userId === uId),
                    ) as typeof bufferedInputs,
                  });
                }),
              );
            });
          });

          return defer(() => results).pipe(mergeAll());
        }),
        mergeAll(), // Me
      )
      .subscribe(result => {
        if (result.error) {
          result.bInput.forEach(b => b.reject("ERROR WHILE FETCHING AVATARS"));
          return;
        }

        const newCache = clone(this.cache);
        const orgId = result.organizationId;
        const resolution = NotNullOrUndefined(result.resolution) as PictureResolution;

        for (const { UserEntityId, Url: url } of result.data ?? []) {
          /**
           * The type we are saving to is `PictureResolution` value,
           * meaning that every type starts with "R" and ends with the resolution
           * e.g. "R320x320" or "R112x112"
           * When resolving regex, we are getting only NxN part without the "R", so we need to add it
           * so we can match the key that holds the avatar
           */
          const resolution = "R" + /(?<resolution>\d+x\d+).png/.exec(url)?.groups?.resolution;

          if (!newCache[orgId]) {
            newCache[orgId] = {};
          }

          if (!newCache?.[orgId]!?.[UserEntityId]) {
            newCache[orgId]![UserEntityId] = {};
          }

          newCache[orgId]![UserEntityId] = {
            ...newCache[orgId]![UserEntityId],
            [resolution]: url,
          };

          this.alreadyDownloaded[`${orgId}${resolution}${UserEntityId}`] =
            /.*Expires=(?<expires>\d+).*/.exec(url)?.groups?.expires ?? "never";
        }

        result.bInput.forEach(b => {
          /**
           * ! careful here
           * ! since we putting the same promise in cache
           * ! we don't want to resolve itself
           * ! newCache[orgId]?.[b.userId]?.[resolution] is cloned from current cache
           */
          b.resolve(
            (newCache[orgId]?.[b.userId]?.[resolution] as any) instanceof Promise
              ? undefined
              : newCache[orgId]?.[b.userId]?.[resolution],
          );
        });

        /**
         * This part is important for react useSyncExternalStore -> it makes comparison with Object.is
         * ! thus we have to assign a new object to trigger a re-render
         */
        this.cache = newCache;
        this.listeners.forEach(listener => {
          listener();
        });
      });
  }

  getState = () => {
    return this.cache;
  };

  subscribe = (listener: Listener) => {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  };

  setAvatar = (
    organizationId: OrgId,
    userId: UserId,
    resolutions: IterableIterator<PictureResolution>,
    data: string,
    expireIn: number = 10000,
  ) => {
    const newCache = clone(this.cache);

    for (const resolution of resolutions) {
      if (!this.alreadyDownloaded[`${organizationId}${resolution}${userId}`]) {
        this.alreadyDownloaded[`${organizationId}${resolution}${userId}`] = String(
          Math.floor((new Date().getTime() + expireIn) / 1000),
        );
      }

      newCache[organizationId] = {
        ...newCache[organizationId],
        [userId]: {
          ...newCache?.[organizationId]?.[userId],
          [resolution]: data,
        },
      };
    }
    this.cache = newCache;

    this.listeners.forEach(listener => listener());
  };

  getAvatarAsync = async (
    organizationId?: OrgId,
    userId?: UserId,
    resolution?: PictureResolution,
    force = false,
  ) => {
    if (process.env.MODE === "test") {
      return "test-env";
    }

    if (!organizationId || !userId || !resolution) {
      throw new Error("Missing required parameters");
    }

    this.processExpiredAvatars();

    if (force) {
      delete this.alreadyDownloaded[`${organizationId}${resolution}${userId}`];
    }

    if (!this.alreadyDownloaded[`${organizationId}${resolution}${userId}`]) {
      /**
       * ! This calms our React UI for 10 mins
       * ! Workaround since we can't possible know if the user has a picture or not :)
       */
      this.alreadyDownloaded[`${organizationId}${resolution}${userId}`] = String(
        /**
         * ! aws returns Expiration in seconds not in miliseconds
         * ! javascript Date works with miliseconds, so we divide
         */
        Math.floor((new Date().getTime() + 10 * 60 * 1000) / 1000),
      );

      let resolve, reject;
      const promise = new Promise<string>((res, rej) => {
        resolve = res;
        reject = rej;
      });

      this.rjxGetAvatar.next({
        organizationId,
        userId,
        resolution,
        resolve,
        reject,
      });

      if (!this.cache?.[organizationId]?.[userId]?.[resolution]) {
        this.cache[organizationId] = {
          ...this.cache[organizationId],
          [userId]: {
            ...this.cache?.[organizationId]?.[userId],
            [resolution]: promise,
          },
        };
      }

      return promise;
    }

    return this.cache?.[organizationId]?.[userId]?.[resolution];
  };

  delete = (
    organizationId?: OrgId | null,
    userId?: UserId | null,
    cacheForMs?: number,
    notifyListenersInMs?: number,
  ) => {
    if (!organizationId || !userId) {
      throw new Error("Missing required parameters");
    }

    const newCache = clone(this.cache);

    for (const resolution of new Set(Object.values(PictureResolution))) {
      if (this.alreadyDownloaded[`${organizationId}${resolution}${userId}`]) {
        delete this.alreadyDownloaded[`${organizationId}${resolution}${userId}`];
      }

      // don't allow a deleted picture to be redownloaded for x amount of ms
      this.alreadyDownloaded[`${organizationId}${resolution}${userId}`] = cacheForMs
        ? String(
            /**
             * ! aws returns Expiration in seconds not in miliseconds
             * ! javascript Date works with miliseconds, so we divide
             */
            Math.floor((new Date().getTime() + cacheForMs) / 1000),
          )
        : undefined;

      if (newCache?.[organizationId]?.[userId]?.[resolution]) {
        delete newCache?.[organizationId]?.[userId]?.[resolution];
      }
    }

    this.cache = newCache;

    const notify = () =>
      this.listeners.forEach(listener => {
        listener();
      });

    /**
     * Essentially, since we don't get back status from BE for avatars
     * we can delay a bit the REACT parts, since if they refresh and they cannot find avatars,
     * they will try to redownload it
     */
    notifyListenersInMs ? this.listenerTimer.update(notifyListenersInMs, notify) : notify();
  };
  get = (
    organizationId?: OrgId | null,
    userId?: UserId | null,
    resolution?: PictureResolution,
    ignoreMissing = false,
    force?: false,
  ) => {
    if (process.env.MODE === "test") {
      return "test-env";
    }

    if (ignoreMissing && (!organizationId || !userId || !resolution)) {
      return undefined;
    } else {
      if (!organizationId || !userId || !resolution) {
        throw new Error("Missing required parameters");
      }

      this.processExpiredAvatars();

      this.getAvatarAsync(organizationId, userId, resolution, force);

      // React will refresh the component anyway via syncExternalStore
      return this.alreadyDownloaded[`${organizationId}${resolution}${userId}`] &&
        this.cache?.[organizationId]?.[userId]?.[resolution] &&
        (this.cache?.[organizationId]?.[userId]?.[resolution] as any) instanceof Promise === false
        ? this.cache?.[organizationId]?.[userId]?.[resolution]
        : undefined;
    }
  };

  private processExpiredAvatars() {
    Object.entries(this.alreadyDownloaded).forEach(([url, ttl]) => {
      if (ttl !== "never" && ttl && +ttl * 1000 < Date.now()) {
        delete this.alreadyDownloaded[url];
      }
    });
  }
}

export const avatarCache = new MakeAvatarCacher();
