import "./AverageWeight.scss";
import { Chip } from "libs/ui";
import { Trans } from "react-i18next";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { useTranslation } from "react-i18next";

interface AverageWeightProps {
  value: string | undefined | number;
}

export function AverageWeight({ value }: Readonly<AverageWeightProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.WeightSectionDetails" });
  const { weightUnit } = useGetUserPreferredMeasurementUnits();

  return (
    <Chip size="small" variant="primary" className="AverageWeight">
      <Trans i18nKey="AverageWeight" t={t} values={{ result: `${value} ${weightUnit}` }}>
        <span className="AverageWeight__result" />
      </Trans>
    </Chip>
  );
}
