import { CarnaApiQuery } from "config/apiQuery";
import { UserRoleType } from "models/PersonModels";

interface PersonData {
  id: string;
  organizationId: string;
  role?: UserRoleType;
}

export function getProfileEndpointPerRole(personToDelete?: PersonData) {
  switch (personToDelete?.role) {
    case "Admin":
      return CarnaApiQuery.Users.getById({ id: personToDelete.id });
    case "Partner":
      return CarnaApiQuery.Partners.getById({
        organizationId: personToDelete.organizationId,
        id: personToDelete.id,
      });
    case "Hcp":
      return CarnaApiQuery.Hcps.getById({
        organizationId: personToDelete.organizationId,
        hcpEntityId: personToDelete.id,
      });
    case "Patient":
      return CarnaApiQuery.Patients.getById({
        organizationId: personToDelete.organizationId,
        patientEntityId: personToDelete.id,
      });

    default:
      throw Error("Role not covered");
  }
}
