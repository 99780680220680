import { EgfrLimitKey } from "utils/hooks/useGetEGFRRanges";
import { UACRLimitKey } from "utils/hooks/useGetUACRRanges";
import { HeatCellProps } from "./model";

type CellColor = "Red" | "Yellow" | "Orange" | "Green";
type CellStatus = `${EgfrLimitKey}-${UACRLimitKey}`;

interface CellStatusData {
  color: CellColor;
  status: CellStatus;
}
export function getNormalColumnColor(rowKey: EgfrLimitKey): CellColor {
  switch (rowKey) {
    case "NormalOrHigh":
    case "MildlyDecreased":
      return "Green";

    case "MildlyOrModeratelyDecreased":
      return "Yellow";

    case "ModeratelyToSeverelyDecreased":
      return "Orange";

    case "KidneyFailure":
    case "SeverelyDecreased":
      return "Red";

    default:
      throw new Error(`${rowKey} not covered`);
  }
}

export function getMicroalbuminuriaColumnColor(rowKey: EgfrLimitKey): CellColor {
  switch (rowKey) {
    case "NormalOrHigh":
    case "MildlyDecreased":
      return "Yellow";

    case "MildlyOrModeratelyDecreased":
      return "Orange";

    case "KidneyFailure":
    case "ModeratelyToSeverelyDecreased":
    case "SeverelyDecreased":
      return "Red";

    default:
      throw new Error(`${rowKey} not covered`);
  }
}

export function getMacroalbuminuriaColumnColor(rowKey: EgfrLimitKey): CellColor {
  switch (rowKey) {
    case "NormalOrHigh":
    case "MildlyDecreased":
      return "Orange";

    case "KidneyFailure":
    case "MildlyOrModeratelyDecreased":
    case "ModeratelyToSeverelyDecreased":
    case "SeverelyDecreased":
      return "Red";

    default:
      throw new Error(`${rowKey} not covered`);
  }
}

export function getCellStatusColor(cellKey: UACRLimitKey, rowKey: EgfrLimitKey): CellColor {
  switch (cellKey) {
    case "Normal":
      return getNormalColumnColor(rowKey);

    case "Microalbuminuria":
      return getMicroalbuminuriaColumnColor(rowKey);

    case "Macroalbuminuria":
      return getMacroalbuminuriaColumnColor(rowKey);

    default:
      throw new Error(`${cellKey} not covered`);
  }
}

export function getCellStatusData(cellKey: UACRLimitKey, rowKey: EgfrLimitKey): CellStatusData {
  return {
    color: getCellStatusColor(cellKey, rowKey),
    status: `${rowKey}-${cellKey}`,
  };
}

export function getIsActive({
  rowKey,
  cellKey,
  UACRStatus,
  eGFRStatus,
}: Omit<HeatCellProps, "risk">) {
  return rowKey === eGFRStatus && cellKey === UACRStatus;
}
