import * as React from "react";
import { SVGProps } from "react";
const SvgGift = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.28 4.139c.732-1.33 2.053-2.889 4.22-2.889a3.25 3.25 0 0 1 2.739 5H22a.75.75 0 0 1 .75.75v5a.75.75 0 0 1-.75.75h-1.25V22a.75.75 0 0 1-.75.75H4a.75.75 0 0 1-.75-.75v-9.25H2a.75.75 0 0 1-.75-.75V7A.75.75 0 0 1 2 6.25h2.761a3.25 3.25 0 0 1 2.739-5c2.167 0 3.488 1.559 4.22 2.889.102.186.195.371.28.553.085-.182.178-.367.28-.553ZM11.25 7.75h-8.5v3.5h8.5v-3.5Zm-.24-1.5a8.778 8.778 0 0 0-.605-1.389C9.762 3.691 8.833 2.75 7.5 2.75a1.75 1.75 0 1 0 0 3.5h3.51Zm6.727-.513a1.75 1.75 0 0 1-1.237.513h-3.51c.138-.407.336-.9.605-1.389.643-1.17 1.572-2.111 2.905-2.111a1.75 1.75 0 0 1 1.237 2.987ZM12.75 11.25v-3.5h8.5v3.5h-8.5Zm0 10h6.5v-8.5h-6.5v8.5Zm-1.5-8.5v8.5h-6.5v-8.5h6.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgGift;
