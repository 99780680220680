import { Dispatch, SetStateAction } from "react";

export interface AvatarCropperModalProps {
  showCropper: boolean;
  setShowCropper: Dispatch<SetStateAction<boolean>>;
  srcImg: string;
  onSave: (image?: string) => PromiseLike<any>;
}

export enum AvatarCropperActionType {
  INIT = "init",
  READY = "ready",
  CHANGED = "changed",
}

export const initialAvatarCropperState = {
  initialZoomVal: undefined,
  zoomVal: undefined,
};
export interface AvatarCropperState {
  initialZoomVal: number | undefined;
  zoomVal: number | undefined;
}

export interface AvatarCropperAction {
  type: AvatarCropperActionType;
  value?: number;
}

export const avatarCropperReducer = (state: AvatarCropperState, action: AvatarCropperAction) => {
  const { type, value } = action;
  switch (type) {
    case AvatarCropperActionType.INIT:
      return initialAvatarCropperState;
    case AvatarCropperActionType.READY:
      return {
        initialZoomVal: value,
        zoomVal: value,
      };
    case AvatarCropperActionType.CHANGED:
      return {
        ...state,
        zoomVal: value,
      };
    default:
      return state;
  }
};
