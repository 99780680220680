import { CountryType } from "api/query/models/CountryType";
import "./SelectOptionCountry.scss";
import { CountryCode } from "libphonenumber-js";
import { CountryFlag, Icon } from "libs/ui";
import { useTranslation } from "react-i18next";

export interface SelectOptionCountryProps {
  title: string;
  countryCode: CountryCode | CountryType;
  isActive?: boolean;
}

export function SelectOptionCountry({
  title,
  countryCode,
  isActive,
}: Readonly<SelectOptionCountryProps>) {
  const { t } = useTranslation("nationalities");
  return (
    <div className="SelectOptionCountry">
      <CountryFlag countryCode={countryCode} countryName={title} />
      <span className="SelectOptionCountry__name">{t(title)}</span>
      {isActive ? <Icon icon={"Check"} className="SelectOption__selectedIcon" /> : null}
    </div>
  );
}
