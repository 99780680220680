import classNames from "classnames";
import { PropsWithChildren } from "react";
import "./PreferenceSection.scss";

interface PreferenceSectionProps {
  title: string;
  subtitle?: string;
  className?: string;
}

export function PreferenceSection({
  title,
  subtitle,
  className,
  children,
}: PropsWithChildren<PreferenceSectionProps>) {
  return (
    <div className={classNames("PreferenceSection", className)}>
      <h3 className="PreferenceSection__title">{title}</h3>
      {subtitle ? <p className="PreferenceSection__subtitle">{subtitle}</p> : null}
      {children}
    </div>
  );
}
