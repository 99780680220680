import i18n from "i18next";
import { GenderType } from "models/PersonModels/Gender";

export function genderTypeToSelectOptions() {
  const gender = Object.keys(GenderType);

  return gender.map(key => ({
    title: i18n.t(`genderType.${key}`, { ns: "translation" }),
    value: GenderType[key as keyof typeof GenderType],
  }));
}
