import { MAX_BMI_HEIGHT_IN_CM, MAX_BMI_WEIGHT_IN_KG, ROW_VERSION_DEFAULT } from "config/const";
import { TestFormModel } from "./model";
import { DECIMAL_NUMBER_UP_TO_2_DECIMALS, WHOLE_NUMBER } from "utils/regex";
import { MeasurementItemUIModel } from "models/TestModels";
import { gramsToKilograms, millimetersToCentimeters } from "utils/converters/unitsConverter";

export const TEST_DATA_DEFAULT: TestFormModel = {
  deviceType: "None",
  measurements: [],
  measurementTime: new Date(),
  deviceId: null,
  organizationId: "",
  rowVersion: ROW_VERSION_DEFAULT,
  patientId: "",
};

export const validateMeasurementInputValue = (data: MeasurementItemUIModel): boolean => {
  const { type, value } = data;

  switch (type) {
    case "SerumCreatinine":
      return !(
        Number(value) <= 0 ||
        Number(value) > 100000 ||
        !DECIMAL_NUMBER_UP_TO_2_DECIMALS.test(value)
      );

    /**
     * TODO use some custom "type" for errors
     * TODO check PsyhicalSection.vite.test
     */
    case "Height":
      return Number(value) > 0 && millimetersToCentimeters(value) <= MAX_BMI_HEIGHT_IN_CM;

    /**
     * TODO use some custom "type" for errors
     * TODO check PsyhicalSection.vite.test
     */
    case "Weight":
      return Number(value) > 0 && gramsToKilograms(value) <= MAX_BMI_WEIGHT_IN_KG;

    case "Glucose":
      return !(
        Number(value) < 0 ||
        Number(value) > 100000 ||
        !DECIMAL_NUMBER_UP_TO_2_DECIMALS.test(value)
      );

    case "Systolic":
    case "Diastolic":
      return !(Number(value) < 0 || Number(value) > 100000 || !WHOLE_NUMBER.test(value));

    case "UrineCreatinine":
      return !(
        Number(value) <= 0 ||
        Number(value) > 100000 ||
        !DECIMAL_NUMBER_UP_TO_2_DECIMALS.test(value)
      );

    case "UrineAlbumin":
      return !(
        Number(value) <= 0 ||
        Number(value) > 100000 ||
        !DECIMAL_NUMBER_UP_TO_2_DECIMALS.test(value)
      );

    default:
      return true;
  }
};
