/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportUserModel } from './ReportUserModel';
import {
    ReportUserModelFromJSON,
    ReportUserModelFromJSONTyped,
    ReportUserModelToJSON,
} from './ReportUserModel';

/**
 * 
 * @export
 * @interface ReportDownloadModel
 */
export interface ReportDownloadModel {
    /**
     * 
     * @type {Date}
     * @memberof ReportDownloadModel
     */
    timestamp: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportDownloadModel
     */
    userEntityId?: string | null;
    /**
     * 
     * @type {ReportUserModel}
     * @memberof ReportDownloadModel
     */
    user?: ReportUserModel;
}

/**
 * Check if a given object implements the ReportDownloadModel interface.
 */
export function instanceOfReportDownloadModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "timestamp" in value;

    return isInstance;
}

export function ReportDownloadModelFromJSON(json: any): ReportDownloadModel {
    return ReportDownloadModelFromJSONTyped(json, false);
}

export function ReportDownloadModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportDownloadModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': json['Timestamp'],
        'userEntityId': !exists(json, 'UserEntityId') ? undefined : json['UserEntityId'],
        'user': !exists(json, 'User') ? undefined : ReportUserModelFromJSON(json['User']),
    };
}

export function ReportDownloadModelToJSON(value?: ReportDownloadModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Timestamp': value.timestamp,
        'UserEntityId': value.userEntityId,
        'User': ReportUserModelToJSON(value.user),
    };
}

