import { UacrStatusType } from "models/TestModels";
import { ColorType } from "models/colors";
import { useCallback } from "react";

export function useUACRColor() {
  const colorResolve = useCallback((status?: UacrStatusType): ColorType => {
    if (!status) {
      return "white" as const;
    }
    switch (status) {
      case "None":
      case "Normal":
        return "white" as const;

      case "Microalbuminuria":
        return "yellow-100" as const;

      case "Macroalbuminuria":
        return "red-50" as const;

      default:
        throw new Error(`color for ${status} not covered`);
    }
  }, []);

  return [colorResolve] as const;
}
