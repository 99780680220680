import { FilterSection } from "components/AppTables/common/Filter/FilterSection";
import { OrganizationsFilterSection } from "components/AppTables/common/Filter/OrganizationsFilterSection";
import { TableFilter } from "components/AppTables/common/Filter/TableFilter";
import { useTranslation } from "react-i18next";
import { AssignedDeviceFilter } from "../../Filter/AssignedDeviceFilter";
import { UserStatusFilter } from "../../Filter/UserStatusFilter";
import { HCPsTableFilterProps, HCPs_FILTER_OPTIONS } from "../model";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import React from "react";
import { StatesFilterSection } from "../../Filter/StatesFilterSection";
import { DropDownToggler } from "libs/ui/DropDownToggler";
import { SideModalElements } from "libs/ui/SideModal";

export function HCPsTableFilter({
  tableService,
  filterSideModelState,
}: Readonly<HCPsTableFilterProps>) {
  const { t: tColumns } = useTranslation("translation", { keyPrefix: "HCPTable.columns" });
  const { t: tFilterTitles } = useTranslation("translation", {
    keyPrefix: "OrganizationsFilterSection",
  });
  const { t: tCommonTable } = useTranslation("translation", { keyPrefix: "commonTable" });

  const { isAdmin, isPatient } = useAdditionalAuthInformationContext();

  return (
    <TableFilter tableService={tableService} filterSideModelState={filterSideModelState}>
      {({
        activeFilters,
        appliedFilters,
        onNewFilterApplied,
        onNewRadioFilterApplied,
        onSingleFilterClear,
      }) => (
        <>
          {activeFilters.map((el, index) => {
            if (el === "contains") {
              return null;
            }

            if (el === "organizationIds") {
              return isAdmin ? (
                <React.Fragment key={el}>
                  <DropDownToggler
                    isExpandedOnInit={(appliedFilters[el] ?? []).length > 0}
                    title={tFilterTitles("organization")}
                    expandChildrenCount={5}
                  >
                    <OrganizationsFilterSection
                      checkedValues={appliedFilters[el] ?? []}
                      onFilterApply={value => onNewFilterApplied(el, value)}
                    />
                  </DropDownToggler>
                  {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
                </React.Fragment>
              ) : null;
            }

            if (el === "withDevice") {
              return isPatient ? null : (
                <React.Fragment key={el}>
                  <DropDownToggler
                    isExpandedOnInit={!!appliedFilters[el]}
                    title={tColumns("device")}
                    expandChildrenCount={5}
                  >
                    <AssignedDeviceFilter
                      key={el}
                      value={appliedFilters[el]}
                      onSelect={value => onNewRadioFilterApplied(el, value)}
                    />
                  </DropDownToggler>
                  {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
                </React.Fragment>
              );
            }

            if (el === "userStatuses") {
              return isPatient ? null : (
                <React.Fragment key={el}>
                  <DropDownToggler
                    isExpandedOnInit={(appliedFilters[el] ?? []).length > 0}
                    title={tCommonTable("status")}
                    expandChildrenCount={5}
                  >
                    <UserStatusFilter
                      values={appliedFilters[el] ?? []}
                      onFilterApply={value => onNewFilterApplied(el, value)}
                    />
                  </DropDownToggler>
                  {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
                </React.Fragment>
              );
            }

            // states can't be standalone filter
            if (el === "states") {
              return null;
            }

            if (el === "countries") {
              return (
                <React.Fragment key={el}>
                  <DropDownToggler
                    isExpandedOnInit={(appliedFilters[el] ?? []).length > 0}
                    title={tColumns("countries")}
                    expandChildrenCount={5}
                  >
                    <FilterSection
                      options={HCPs_FILTER_OPTIONS()["countries"]}
                      checkedValues={appliedFilters["countries"] ?? []}
                      onFilterApply={value => onNewFilterApplied("countries", value)}
                      withSearch={true}
                    />

                    <StatesFilterSection
                      checkedValues={appliedFilters["states"] ?? []}
                      countries={appliedFilters["countries"] ?? []}
                      onFilterApply={value => onNewFilterApplied("states", value)}
                      onSingleFilterClear={() => onSingleFilterClear("states")}
                    />
                  </DropDownToggler>
                  {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={el}>
                <DropDownToggler
                  isExpandedOnInit={((appliedFilters[el] as Array<any>) ?? []).length > 0}
                  title={tColumns(el)}
                  expandChildrenCount={5}
                >
                  <FilterSection
                    options={HCPs_FILTER_OPTIONS()[el]}
                    checkedValues={appliedFilters[el] ?? []}
                    onFilterApply={value => onNewFilterApplied(el, value)}
                  />
                </DropDownToggler>
                {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
              </React.Fragment>
            );
          })}
        </>
      )}
    </TableFilter>
  );
}
