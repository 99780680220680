import { LogoIcon } from "./LogoIcon";
import { LogoName } from "./LogoName";
import "./Logo.scss";
import classNames from "classnames";

interface LogoProps {
  short?: boolean;
  className?: string;
}
export function Logo({ short, className }: LogoProps) {
  return (
    <div className={classNames("Logo", className, { "Logo--short": short })}>
      <LogoIcon />
      <LogoName />
    </div>
  );
}
