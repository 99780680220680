import "./SuccessfulPasswordChange.scss";
import { GreenCheckMark } from "libs/ui/assets/svg/GreenCheckMark";
import { useTranslation } from "react-i18next";
import { Button, Logo } from "libs/ui";
import { Copyright } from "components/Copyright";

interface SuccessfulPasswordChangeProps {
  buttonAction: () => void;
}

export default function SuccessfulPasswordChange({ buttonAction }: SuccessfulPasswordChangeProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Login.SuccessfulPasswordChange" });

  return (
    <div className="SuccessfulPasswordChange">
      <Logo className="LoginLogo" />
      <div className="SuccessfulPasswordChange__container">
        <GreenCheckMark />
        <h2 className="SuccessfulPasswordChange__title">{t("title")}</h2>
        <p className="SuccessfulPasswordChange__text">{t("text")}</p>
        <Button
          buttonType="primary"
          buttonSize="large"
          className="SuccessfulPasswordChange__actionButton"
          onClick={buttonAction}
        >
          {t("buttonText")}
        </Button>
      </div>
      <Copyright />
    </div>
  );
}
