/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserDetailsResponseModel } from './UserDetailsResponseModel';
import {
    UserDetailsResponseModelFromJSON,
    UserDetailsResponseModelFromJSONTyped,
    UserDetailsResponseModelToJSON,
} from './UserDetailsResponseModel';

/**
 * 
 * @export
 * @interface UserDetailsResponseModelListModel
 */
export interface UserDetailsResponseModelListModel {
    /**
     * 
     * @type {number}
     * @memberof UserDetailsResponseModelListModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof UserDetailsResponseModelListModel
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof UserDetailsResponseModelListModel
     */
    queryCount: number;
    /**
     * 
     * @type {Array<UserDetailsResponseModel>}
     * @memberof UserDetailsResponseModelListModel
     */
    items: Array<UserDetailsResponseModel>;
    /**
     * 
     * @type {number}
     * @memberof UserDetailsResponseModelListModel
     */
    readonly pages?: number;
}

/**
 * Check if a given object implements the UserDetailsResponseModelListModel interface.
 */
export function instanceOfUserDetailsResponseModelListModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "queryCount" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function UserDetailsResponseModelListModelFromJSON(json: any): UserDetailsResponseModelListModel {
    return UserDetailsResponseModelListModelFromJSONTyped(json, false);
}

export function UserDetailsResponseModelListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetailsResponseModelListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': json['Limit'],
        'page': json['Page'],
        'queryCount': json['QueryCount'],
        'items': ((json['Items'] as Array<any>).map(UserDetailsResponseModelFromJSON)),
        'pages': !exists(json, 'Pages') ? undefined : json['Pages'],
    };
}

export function UserDetailsResponseModelListModelToJSON(value?: UserDetailsResponseModelListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Limit': value.limit,
        'Page': value.page,
        'QueryCount': value.queryCount,
        'Items': ((value.items as Array<any>).map(UserDetailsResponseModelToJSON)),
    };
}

