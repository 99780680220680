import { toastStore } from "config/toast";
import { defaultModel, failed, LoadableModel, loaded, loading } from "models/loadable";
import { useCallback, useState } from "react";

export type ApiReturnType<T extends (...params: any[]) => Promise<any>> = T extends (
  ...params: any[]
) => Promise<infer U>
  ? U
  : never;

export interface OnResponseCallback<T> {
  onSuccess?: (result: T) => void;
  onError?: (result: any) => void;
}

export interface UseApiProps<Result> {
  toastText?: {
    successText?: string | null;
    errorText?: string | null;
  };
  onResponseCallback?: {
    onSuccess?: (result: Result) => void;
    onError?: (result: any) => void;
  };
}

export function useApi<Params extends any[], Result>(
  callback: (...params: Params) => Promise<Result>,
  { toastText, onResponseCallback }: UseApiProps<Result> = {},
) {
  const [data, setData] = useState<LoadableModel<Result>>(defaultModel());

  const { successText, errorText } = toastText ?? {};
  const { onSuccess, onError } = onResponseCallback ?? {};

  const action = useCallback(
    async function (...params: Params) {
      setData(prevState => loading(prevState.value));
      try {
        const result = await callback(...params);

        setData(loaded(result));

        if (successText) {
          toastStore.pushToast({ type: "success", msg: successText, expire: 5000 });
        }

        if (onSuccess) {
          onSuccess(result);
        }
      } catch (error) {
        setData(failed(error));

        if (errorText) {
          toastStore.toast.error({ msg: errorText });
        }

        if (onError) {
          onError(error);
        }
      }
    },
    [callback, errorText, onError, onSuccess, successText],
  );

  const reset = useCallback((value?: Result) => {
    setData(defaultModel(value));
  }, []);

  return [data, action, reset, setData] as const;
}
