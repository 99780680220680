import classNames from "classnames";
import { PropsWithChildren } from "react";
import "./RuleEngineSection.scss";

interface RuleEngineSectionProps {
  className?: string;
}
export function RuleEngineSection({
  className,
  children,
}: PropsWithChildren<RuleEngineSectionProps>) {
  return <section className={classNames("RuleEngineSection", className)}>{children}</section>;
}
