import classNames from "classnames";
import { ECharts } from "echarts/core";
import { forwardRef, useMemo, useRef } from "react";
import { LineChartForwardedProps, LineChartProps } from "./model";

import { isDefaultModel, isLoaded, isLoading } from "models/loadable";
import { SkeletonLoader } from "../SkeletonLoader";
import { useInitChart } from "../hooks/useInitChart";
import { useSetupLineChartHandles } from "../hooks/useSetupLineChartHandles";
import "./MiniChart.scss";
import { UserGraphModel } from "api/query/models/UserGraphModel";

const INIT_GRAPH_MODEL: UserGraphModel = {
  genderType: "Male",
  id: "",
  measurements: [],
  roleType: "Admin",
};

interface MiniChartInnerWrapperProps extends Omit<LineChartProps, "data"> {
  data: UserGraphModel;
}

// We set up a inner wrapper so that we always have the data,
// the loading logic is done outside of this component.
// This prevents stuff like losing the ref if we render component conditionally
const MiniChartInnerWrapper = forwardRef<LineChartForwardedProps, MiniChartInnerWrapperProps>(
  ({ getOptions, data, dataType, onNodeClick, className, afterInit }, ref) => {
    const chartDivRef = useRef<HTMLDivElement | null>(null);
    const chartRef = useRef<ECharts>(null);

    useInitChart({
      chartDivRef,
      chartRef,
      getOptions,
      data,
      afterInit,
    });
    useSetupLineChartHandles(ref, chartRef, onNodeClick);

    return (
      <div
        className={classNames("MiniChart", className)}
        ref={ref => (chartDivRef.current = ref)}
      />
    );
  },
);

export const MiniChart = forwardRef<LineChartForwardedProps, LineChartProps>(
  ({ getOptions, data, dataType, onNodeClick, className, afterInit }, ref) => {
    const graphData = useMemo(() => (isLoaded(data) ? data.value : INIT_GRAPH_MODEL), [data]);

    const showSkeletonLoader = useMemo(() => isLoading(data) || isDefaultModel(data), [data]);

    return (
      <>
        {showSkeletonLoader ? (
          <SkeletonLoader />
        ) : (
          <MiniChartInnerWrapper
            getOptions={getOptions}
            data={graphData}
            dataType={dataType}
            onNodeClick={onNodeClick}
            afterInit={afterInit}
            className={className}
            ref={ref}
          />
        )}
      </>
    );
  },
);

export default MiniChart;
