import { querystring } from "./endpointSerialization";
import { HTTP_FORBIDDEN, HTTP_UNAUTHORIZED } from "./const";

import { injectUnauthorized } from "components/Unathorized";
import { Configuration, TenantsApi } from "api/config_service";
import { fetchAuthSession } from "@aws-amplify/auth";
import { tryGetLocalStorageValue } from "utils/localStorage";

let checkingForForbiddenUser: Promise<any> | null = null;

export const configuration =
  process.env.MODE === "test"
    ? undefined
    : new Configuration({
        /**
         * check this function in runtime.ts
         */
        queryParamsStringify: querystring,
        basePath: `${
          process.env.VITE_APP_CONFIG_HOST ?? "VITE_APP_CONFIG_HOST-env-variable-missing"
        }`,
        // (input: RequestInfo | URL, init?: RequestInit): Promise<Response>
        middleware: [
          {
            async post(param) {
              if (param.response.status === HTTP_UNAUTHORIZED) {
                injectUnauthorized();
                return param.response;
              }

              if (param.response.status === HTTP_FORBIDDEN) {
                // Avoiding concurrency
                if (checkingForForbiddenUser) {
                  await checkingForForbiddenUser;
                } else {
                  try {
                    checkingForForbiddenUser = fetchAuthSession({ forceRefresh: true });
                    await checkingForForbiddenUser;
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  } catch (error) {
                    console.warn("User is possibly logged out");
                  } finally {
                    checkingForForbiddenUser = null;
                  }
                }
              }
              return param.response;
            },
            async pre(context) {
              // const sign = await signRequest(
              //   context.url,
              //   "execute-api",
              //   context.init?.method,
              //   "eu-central-1",
              //   context.init.body,
              // );
              return {
                url: context.url,
                init: {
                  ...context.init,
                  headers: new Headers({
                    ...context.init.headers,
                    // ...sign.headers,
                    "access-token": `${(await fetchAuthSession()).tokens?.accessToken}`,
                    "identity-token": `${(await fetchAuthSession()).tokens?.idToken}`,
                    "x-tenant": `${tryGetLocalStorageValue<{ "X-Tenant": string }>("lastUsedAuth")?.["X-Tenant"] ?? "missing tenant name"}`,
                  }),
                },
              };
            },
          },
        ],
      });

const TenantsApiContext = new TenantsApi(configuration);

export const CarnaConfigurationService = {
  general: {
    getFullConfig: TenantsApi.prototype.tenantsNameGet.bind(TenantsApiContext),
    getFullConfigRaw: TenantsApi.prototype.tenantsNameGetRaw.bind(TenantsApiContext),
    saveFullConfig: TenantsApi.prototype.tenantsNamePut.bind(TenantsApiContext),
  } as const,
  entities: {
    get: TenantsApi.prototype.tenantsNameSectionsEntitiesGet.bind(TenantsApiContext),
    save: TenantsApi.prototype.tenantsNameSectionsEntitiesPut.bind(TenantsApiContext),
  },
} as const;

declare global {
  interface Window {
    ConfigService: any;
  }
}

if (import.meta.env.MODE === "development") {
  window.ConfigService = CarnaConfigurationService;
}
