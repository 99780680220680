import * as React from "react";
import { SVGProps } from "react";
const SvgChevronsUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.53 5.47a.75.75 0 0 0-1.06 0l-5 5a.75.75 0 1 0 1.06 1.06L12 7.06l4.47 4.47a.75.75 0 1 0 1.06-1.06l-5-5ZM17.53 17.47l-5-5a.75.75 0 0 0-1.06 0l-5 5a.75.75 0 1 0 1.06 1.06L12 14.06l4.47 4.47a.75.75 0 1 0 1.06-1.06Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgChevronsUp;
