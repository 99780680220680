import * as React from "react";
import { SVGProps } from "react";
const SvgRepeat = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.47.47a.75.75 0 0 1 1.06 0l4 4a.747.747 0 0 1 0 1.06l-4 4a.75.75 0 1 1-1.06-1.06l2.72-2.72H7A3.25 3.25 0 0 0 3.75 9v2a.75.75 0 0 1-1.5 0V9A4.75 4.75 0 0 1 7 4.25h12.19l-2.72-2.72a.75.75 0 0 1 0-1.06ZM21 12.25a.75.75 0 0 0-.75.75v2A3.25 3.25 0 0 1 17 18.25H4.81l2.72-2.72a.75.75 0 1 0-1.06-1.06l-4 4a.748.748 0 0 0 0 1.06l4 4a.75.75 0 0 0 1.06-1.06l-2.72-2.72H17A4.75 4.75 0 0 0 21.75 15v-2a.75.75 0 0 0-.75-.75Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgRepeat;
