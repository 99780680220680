import { UserGraphModel } from "api/query";
import { merge } from "lodash-es";
import { useMemo } from "react";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";

import { common, ECOption, GetOptions, ZoomLevels } from "../model";
import { useTranslation } from "react-i18next";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";

export function useGenerateGraphOptions(
  graphUser: UserGraphModel,
  getOptions: GetOptions,
  currentSelectedZoomLevel?: ZoomLevels,
) {
  const { t } = useTranslation("translation", { keyPrefix: "PatientDetails.Graph" });

  const [dateFormatter, timeFormatter] = useRegionDateTimeFormatter();
  const { numberFormatter } = useLocaleNumberFormatter();

  return useMemo(() => {
    const baseCommon = common([dateFormatter, timeFormatter, numberFormatter]);
    const ecOptions = getOptions(
      [dateFormatter, timeFormatter, numberFormatter],
      graphUser,
      currentSelectedZoomLevel,
    );

    const noDataTitle =
      graphUser.measurements.length === 0
        ? ({
            dataZoom: undefined,
            title: {
              show: true,
              textStyle: {
                color: "#6B7280",
                fontSize: 14,
                fontWeight: "normal",
              },
              text: t("noData"),
              left: "center",
              top: "center",
            },
          } as const)
        : ({ title: undefined } as const);

    const newOptions = {
      ...baseCommon,
      ...ecOptions,
      yAxis: merge(baseCommon.yAxis, ecOptions.yAxis),
      ...noDataTitle,
    } as ECOption;

    return newOptions;
  }, [
    currentSelectedZoomLevel,
    dateFormatter,
    getOptions,
    graphUser,
    numberFormatter,
    t,
    timeFormatter,
  ]);
}
