import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";

export interface MeasureSystem {
  serumCreatinineUnit: MEASUREMENT_UNITS;
  egfrUnit: MEASUREMENT_UNITS;
  weightUnit: MEASUREMENT_UNITS;
  bloodPressureUnit: MEASUREMENT_UNITS;
  glucoseUnit: MEASUREMENT_UNITS;
  uacrUnit: MEASUREMENT_UNITS;
  urineAlbuminUnit: MEASUREMENT_UNITS;
  urineCreatinineUnit: MEASUREMENT_UNITS;
  heightUnit: MEASUREMENT_UNITS;
}

export type MeasureSystemUnit = keyof MeasureSystem;

export const METRIC_SYSTEM: MeasureSystem = {
  serumCreatinineUnit: MEASUREMENT_UNITS.mgdL,
  egfrUnit: MEASUREMENT_UNITS.mLmin173m2,
  weightUnit: MEASUREMENT_UNITS.kg,
  bloodPressureUnit: MEASUREMENT_UNITS.mmHg,
  glucoseUnit: MEASUREMENT_UNITS.mgdL,
  uacrUnit: MEASUREMENT_UNITS.mgg,
  urineAlbuminUnit: MEASUREMENT_UNITS.mgL,
  urineCreatinineUnit: MEASUREMENT_UNITS.mgdL,
  heightUnit: MEASUREMENT_UNITS.cm,
};

export const IMPERIAL_SYSTEM: MeasureSystem = {
  serumCreatinineUnit: MEASUREMENT_UNITS.mgdL,
  egfrUnit: MEASUREMENT_UNITS.mLmin173m2,
  weightUnit: MEASUREMENT_UNITS.lb,
  bloodPressureUnit: MEASUREMENT_UNITS.mmHg,
  glucoseUnit: MEASUREMENT_UNITS.mgdL,
  uacrUnit: MEASUREMENT_UNITS.mgg,
  urineAlbuminUnit: MEASUREMENT_UNITS.mgL,
  urineCreatinineUnit: MEASUREMENT_UNITS.mgdL,
  heightUnit: MEASUREMENT_UNITS.ft,
};
