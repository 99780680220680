import { MouseEvent } from "react";

/**
 * Mostly just a helper for ToolbarButton
 */
export const preventClickBubbling =
  <T extends (e?: MouseEvent<any, any>) => any>(cb: T) =>
  (
    e?: T extends (e?: MouseEvent<infer U, infer B>) => any ? MouseEvent<U, B> : unknown,
  ): ReturnType<T> => {
    e?.preventDefault();
    e?.stopPropagation();

    return cb(e);
  };

export default preventClickBubbling;
