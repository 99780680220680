/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthResponseModel,
  CommonProblemDetailsModel,
} from '../models/index';
import {
    AuthResponseModelFromJSON,
    AuthResponseModelToJSON,
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
} from '../models/index';

export interface AuthGetRequest {
    userName?: string;
    acceptVersion?: string;
}

/**
 * AuthApi - interface
 * 
 * @export
 * @interface AuthApiInterface
 */
export interface AuthApiInterface {
    /**
     * 
     * @param {string} [userName] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authGetRaw(requestParameters: AuthGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthResponseModel>>;

    /**
     */
    authGet(requestParameters: AuthGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthResponseModel>;

}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI implements AuthApiInterface {

     /**
     */
    async authGetRawOriginal(requestParameters: AuthGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.userName !== undefined) {
            queryParameters['userName'] = requestParameters.userName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async authGetRaw(requestParameters: AuthGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.userName !== undefined) {
            queryParameters['userName'] = requestParameters.userName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async authGet(requestParameters: AuthGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthResponseModel> {
        const response = await this.authGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
