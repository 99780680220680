import * as AddModal from "components/AddModal/HCP";
import { AddModalForwardedProps } from "components/AddModal/model";
import { hasFilter } from "components/AppTables/utils";
import { EmptyStateIcon } from "components/EmptyStateIcon";
import { NEW_BACKEND } from "config/NEW_BACKEND";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { ActionButton, EmptyState, TableRefs } from "libs/ui";
import { statusToIcon } from "libs/ui/ActionButton/utils";
import { TableUtils } from "libs/ui/Table/utils";
import { cloneDeep } from "lodash-es";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useChangeStatusModal } from "utils/hooks/ChangeEntityStatus/useChangeStatusModal";
import { useFloatingActionButtons } from "utils/hooks/useFloatingActionButton";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { CHANGE_STATUS_ALLOWED } from "../model";
import { BaseTable } from "../Table/BaseTable";
import { BodyRows } from "./BodyRows";
import { resolveAppliedColumns } from "./constants";
import { HCPsTableFilter } from "./HCPsTableFilter";
import "./HCPTable.scss";
import { HCPTableProps, UIHcpsFilters } from "./model";

export function HCPTable({
  HCPTableStateContext,
  appliedColumns,
  onFilterToggle,
  searchField,
  addButton,
  hcpFilterOptions,
  initialValuesAsProps,
}: Readonly<HCPTableProps>) {
  const { tableService, filterSideModelState, fetch } = HCPTableStateContext;
  const { t } = useTranslation("translation", { keyPrefix: "HCPTable" });
  const { isAdmin, isPatient } = useAdditionalAuthInformationContext();
  const can = useHasAccess();

  const { ChangeStateModal, changeStateClick } = useChangeStatusModal(tableService as NEW_BACKEND, {
    onSuccessCb: fetch,
  });

  const [state] = tableService;

  const addModalRef = useRef<AddModalForwardedProps>(null);
  const toggle = () => addModalRef?.current?.toggleModal();

  const tableRef = useRef<TableRefs>(null);
  const [currentRightClickContext, setCurrentRightClickContext] = useFloatingActionButtons<
    Parameters<typeof changeStateClick>[0]
  >(tableRef.current?.containerElem);

  const hasFilters = TableUtils.areFiltersApplied(state.context);
  const noTableData = state.matches("loaded") && !state.context.data.items.length && !hasFilters;

  const columns = resolveAppliedColumns({
    appliedColumns,
    t,
    hasActionRights: can("add-hcp"),
    isPatient: isPatient,
  });

  const resolveShowFilterOption = (columnName: string) => {
    if (columnName === "organizationIds") {
      return isAdmin && hasFilter(columnName, NotNullOrUndefined(hcpFilterOptions));
    }

    return hasFilter(columnName, NotNullOrUndefined(hcpFilterOptions));
  };

  const onOpenFilterModal = (value: any) => filterSideModelState.openFilterModal(value);

  const resolveFilterApplied = (value: string) => {
    if (value === "withDevice") {
      return state.context.filters?.withDevice !== undefined;
    }
    return !!state.context.filters?.[value as keyof Omit<UIHcpsFilters, "withDevice">]?.length;
  };

  if (noTableData) {
    return (
      <>
        <EmptyState title={t("emptyTitle")} description={t("emptyDescription")} onCreate={toggle}>
          <EmptyStateIcon variant="HCP" />
        </EmptyState>
        <AddModal.HCP
          ref={addModalRef}
          onSuccessCb={fetch}
          initialValuesAsProps={initialValuesAsProps}
        />
      </>
    );
  }

  return (
    <>
      <BaseTable
        innerRef={tableRef}
        tableService={tableService}
        className="HCPTable"
        columns={columns}
        onOpenFilterModal={onOpenFilterModal}
        resolveFilterApplied={resolveFilterApplied}
        resolveShowFilterOption={resolveShowFilterOption}
        onFilterToggle={onFilterToggle}
        searchField={searchField}
        viewTableSelector={true}
        tableContainerAdditionalChildren={
          currentRightClickContext?.[1] &&
          CHANGE_STATUS_ALLOWED.some(el => el === currentRightClickContext[1].status) && (
            <ActionButton
              style={{
                position: "absolute",
                top: `${currentRightClickContext?.[0].pageY}px`,
                left: `${currentRightClickContext?.[0].pageX}px`,
              }}
              onClick={e => {
                changeStateClick(cloneDeep(currentRightClickContext?.[1]))(e);
                setCurrentRightClickContext(undefined);
              }}
              actionType={currentRightClickContext[1].status}
              iconType={statusToIcon(currentRightClickContext[1].status)}
            >
              {t(`actions.${currentRightClickContext[1].status}`)}
            </ActionButton>
          )
        }
        addButton={addButton}
      >
        <BodyRows
          appliedColumns={appliedColumns}
          changeHCPStateClick={changeStateClick}
          tableService={tableService}
          currentRightClickContext={currentRightClickContext}
          setCurrentRightClickContext={setCurrentRightClickContext}
        />
      </BaseTable>

      {ChangeStateModal}

      <HCPsTableFilter tableService={tableService} filterSideModelState={filterSideModelState} />
    </>
  );
}
