import "./OrganizationLogo.scss";

interface OrganizationLogoProps {
  isMain?: boolean;
  loading?: boolean;
}

export function OrganizationLogo({ isMain = false, loading }: OrganizationLogoProps) {
  if (loading) {
    return <div className="OrganizationLogo OrganizationLogo__loader" />;
  }

  return (
    <div className="OrganizationLogo">
      {isMain ? (
        <svg
          width="97"
          height="97"
          viewBox="0 0 490 490"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="245" cy="245" r="245" fill="#FEF3C7" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M334.789 16.9758C362.626 27.9458 387.97 43.8657 409.737 63.6511H306.805C299.013 63.6511 292.417 57.2751 292.55 49.4841C292.688 41.3821 299.567 35.4981 307.049 35.8181C309.386 35.9201 311.368 34.0621 311.499 31.7251C312.07 21.4981 322.356 14.0381 332.395 16.9491C333.196 17.1814 334.022 17.1819 334.789 16.9758Z"
            fill="#FDE68A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M295.474 149.304C279.986 144.812 264.118 156.321 263.237 172.099C263.036 175.703 259.978 178.569 256.37 178.417C244.829 177.923 234.217 187 234.003 199.5C233.798 211.518 243.975 221.355 255.995 221.355H437.067C449.735 221.355 459.841 210.384 458.401 197.425C457.317 187.668 449.489 179.756 439.743 178.576C434.982 177.999 430.486 178.986 426.693 181.089C423.493 182.863 419.473 181.887 417.569 178.762C411.972 169.576 401.407 164.252 390.51 165.494C387.301 165.859 384.236 164.045 383.244 160.972C377.253 142.421 359.853 129 339.308 129C324.439 129 311.215 136.03 302.77 146.945C301.053 149.164 298.169 150.086 295.474 149.304Z"
            fill="#FCD34D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.686615 263.482C0.23159 257.381 0 251.217 0 245C0 237.995 0.294027 231.058 0.870414 224.201C2.72697 223.385 4.7174 222.828 6.79491 222.576C11.5569 221.999 16.0519 222.986 19.8449 225.089C23.0449 226.863 27.0649 225.887 28.9689 222.762C34.5659 213.576 45.1309 208.252 56.0279 209.494C59.2369 209.86 62.3009 208.045 63.2939 204.972C69.2849 186.421 86.6849 173 107.23 173C122.099 173 135.323 180.03 143.768 190.945C145.485 193.164 148.369 194.085 151.064 193.304C158.086 191.267 165.182 192.528 170.929 195.977C177.144 217.553 180.483 240.347 180.483 263.923C180.483 264.19 180.479 264.457 180.474 264.724C180.471 264.933 180.467 265.143 180.466 265.354H9.4689C6.34011 265.354 3.36753 264.685 0.686615 263.482Z"
            fill="#FDE68A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M141.642 467.196V281.948H347.882V420.53L350.079 466.385C318.235 481.527 282.607 490 245 490C208.069 490 173.047 481.829 141.642 467.196Z"
            fill="#FBBF24"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M141.642 467.196V281.948H234.043C212.85 281.948 195.67 299.128 195.67 320.321V485.032C176.778 481.17 158.678 475.133 141.642 467.196Z"
            fill="#F59E0B"
          />
          <path d="M164.757 168.263H324.767V282.967H164.757V168.263Z" fill="#FBBF24" />
          <path
            d="M207.785 255.849V195.381C207.785 180.404 219.926 168.263 234.903 168.263H164.757V282.967H234.903C219.926 282.967 207.785 270.826 207.785 255.849Z"
            fill="#F59E0B"
          />
          <path
            d="M244.762 93.835C240.542 93.835 237.12 90.414 237.12 86.193V54.642C237.12 50.422 240.541 47 244.762 47C248.982 47 252.404 50.421 252.404 54.642V86.193C252.403 90.413 248.982 93.835 244.762 93.835Z"
            fill="#FCD34D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.564 476.49V316.064C164.564 311.843 167.986 308.422 172.206 308.422C176.427 308.422 179.848 311.844 179.848 316.064V481.242C174.683 479.821 169.586 478.235 164.564 476.49ZM193.586 484.596V316.064C193.586 311.843 197.008 308.422 201.228 308.422C205.449 308.422 208.87 311.844 208.87 316.065V487.355C203.721 486.594 198.625 485.672 193.586 484.596ZM222.609 488.991V316.064C222.609 311.843 226.031 308.422 230.251 308.422C234.471 308.422 237.893 311.844 237.893 316.064V489.899C232.757 489.753 227.661 489.448 222.609 488.991ZM251.631 489.912V316.064C251.631 311.843 255.053 308.422 259.273 308.422C263.493 308.422 266.914 311.844 266.915 316.064V489.033C261.863 489.481 256.766 489.776 251.631 489.912ZM281 487.374V316.064C281 311.843 284.422 308.422 288.642 308.422C292.862 308.422 296.283 311.844 296.284 316.064V484.624C291.245 485.697 286.149 486.616 281 487.374ZM310.369 481.183V316.064C310.369 311.843 313.791 308.422 318.011 308.422C322.231 308.422 325.652 311.844 325.653 316.064V476.415C320.631 478.165 315.534 479.756 310.369 481.183Z"
            fill="#D97706"
          />
          <path
            d="M201.229 264.627C197.009 264.627 193.587 261.206 193.587 256.985V196.134C193.587 191.914 197.008 188.492 201.229 188.492C205.45 188.492 208.871 191.913 208.871 196.134V256.985C208.87 261.205 205.449 264.627 201.229 264.627Z"
            fill="#D97706"
          />
          <path
            d="M230.251 264.627C226.031 264.627 222.609 261.206 222.609 256.985V196.134C222.609 191.914 226.03 188.492 230.251 188.492C234.471 188.492 237.893 191.913 237.893 196.134V256.985C237.892 261.205 234.471 264.627 230.251 264.627Z"
            fill="#D97706"
          />
          <path
            d="M259.273 264.627C255.053 264.627 251.631 261.206 251.631 256.985V196.134C251.631 191.914 255.052 188.492 259.273 188.492C263.493 188.492 266.915 191.913 266.915 196.134V256.985C266.914 261.205 263.493 264.627 259.273 264.627Z"
            fill="#D97706"
          />
          <path
            d="M288.295 264.627C284.075 264.627 280.653 261.206 280.653 256.985V196.134C280.653 191.914 284.074 188.492 288.295 188.492C292.515 188.492 295.937 191.913 295.937 196.134V256.985C295.937 261.205 292.515 264.627 288.295 264.627Z"
            fill="#D97706"
          />
          <path
            d="M288.294 264.627C284.074 264.627 280.652 261.206 280.652 256.985V196.134C280.652 191.914 284.073 188.492 288.294 188.492C292.514 188.492 295.936 191.913 295.936 196.134V256.985C295.936 261.205 292.515 264.627 288.294 264.627Z"
            fill="#D97706"
          />
          <path d="M202.851 91.8672H286.673V168.263H202.851V91.8672Z" fill="#FBBF24" />
          <path
            d="M237.879 145.145V114.985C237.879 102.217 248.229 91.8672 260.997 91.8672H202.851V168.263H260.997C248.229 168.263 237.879 157.913 237.879 145.145V145.145Z"
            fill="#F59E0B"
          />
          <path
            d="M286.673 99.5081H202.851C198.631 99.5081 195.209 96.0871 195.209 91.8661C195.209 87.6451 198.63 84.2241 202.851 84.2241H286.673C290.893 84.2241 294.315 87.6451 294.315 91.8661C294.315 96.0871 290.893 99.5081 286.673 99.5081Z"
            fill="#FDE68A"
          />
          <path
            d="M324.767 175.905H164.757C160.537 175.905 157.115 172.484 157.115 168.263C157.115 164.043 160.536 160.621 164.757 160.621H324.767C328.987 160.621 332.409 164.042 332.409 168.263C332.409 172.483 328.987 175.905 324.767 175.905Z"
            fill="#FDE68A"
          />
          <path
            d="M347.881 289.59H141.642C137.422 289.59 134 286.169 134 281.948C134 277.728 137.421 274.306 141.642 274.306H347.881C352.101 274.306 355.523 277.727 355.523 281.948C355.523 286.168 352.102 289.59 347.881 289.59Z"
            fill="#FDE68A"
          />
          <path
            d="M230.251 147.599C226.031 147.599 222.609 144.178 222.609 139.957V118.48C222.609 114.26 226.03 110.838 230.251 110.838C234.472 110.838 237.893 114.259 237.893 118.48V139.957C237.893 144.177 234.471 147.599 230.251 147.599Z"
            fill="#D97706"
          />
          <path
            d="M259.273 147.599C255.053 147.599 251.631 144.178 251.631 139.957V118.48C251.631 114.26 255.052 110.838 259.273 110.838C263.494 110.838 266.915 114.259 266.915 118.48V139.957C266.915 144.177 263.493 147.599 259.273 147.599Z"
            fill="#D97706"
          />
        </svg>
      ) : (
        <svg
          width="97"
          height="97"
          viewBox="0 0 97 97"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M64.6133 80.717L89.182 73.0915C93.5815 65.8401 96.1148 57.3306 96.1148 48.2295C96.1148 21.7198 74.6244 0.229492 48.1147 0.229492C21.6051 0.229492 0.114746 21.7198 0.114746 48.2295C0.114746 60.7607 4.91862 72.1688 12.7829 80.717H64.6133Z"
            fill="#F5F2FF"
          />
          <path
            d="M47.6821 19.0747C47.8547 15.9835 50.9635 13.7287 53.9979 14.6088C54.5259 14.762 55.0909 14.5813 55.4273 14.1466C57.0818 12.0082 59.6726 10.6309 62.5857 10.6309C66.6108 10.6309 70.0198 13.2603 71.1935 16.8947C71.3879 17.4968 71.9884 17.8522 72.617 17.7806C74.752 17.5373 76.8218 18.5804 77.9184 20.3801C78.2914 20.9923 79.079 21.1835 79.7059 20.836C80.449 20.424 81.3299 20.2306 82.2626 20.3436C84.172 20.5748 85.7057 22.1249 85.918 24.0365C86.2002 26.5754 84.2202 28.7248 81.7383 28.7248H46.2633C43.9084 28.7248 41.9145 26.7975 41.9547 24.443C41.9966 21.994 44.0757 20.2157 46.3368 20.3125C47.0436 20.3423 47.6428 19.7808 47.6821 19.0747Z"
            fill="#E9E0FF"
          />
          <path
            d="M37.799 43.8216C37.5867 41.9101 36.0528 40.36 34.1436 40.1288C33.2107 40.0157 32.33 40.2091 31.5869 40.6211C30.96 40.9687 30.1724 40.7775 29.7994 40.1652C28.7028 38.3655 26.633 37.3225 24.4981 37.5658C23.8694 37.6375 23.2691 37.2819 23.0745 36.6799C21.9008 33.0454 18.4918 30.416 14.4667 30.416C11.5536 30.416 8.96284 31.7933 7.30832 33.9317C6.97193 34.3665 6.40691 34.5469 5.87891 34.3939C4.50318 33.9948 3.11296 34.2419 1.98702 34.9176C0.7694 39.1447 0.115234 43.6104 0.115234 48.2294C0.115234 48.323 0.117977 48.4161 0.118565 48.5097H33.6197C36.1012 48.5099 38.0812 46.3605 37.799 43.8216Z"
            fill="#E9E0FF"
          />
          <path
            d="M70.8279 69.0012H70.5286V90.6821C78.3063 86.5671 84.817 80.3789 89.3241 72.8517V38.1596L58.9189 23.686V64.2102H70.8281C72.149 64.2102 73.2236 65.2848 73.2236 66.6057C73.2236 67.9266 72.1488 69.0012 70.8279 69.0012Z"
            fill="#CFBCFF"
          />
          <path
            d="M12.6006 80.5209C20.0303 88.6873 30.239 94.2786 41.7316 95.806V69.1007H41.7318H53.0065V41.9502H12.6006V80.5209Z"
            fill="#CFBCFF"
          />
          <path d="M17.1289 19.6777H48.4775V42.1502H17.1289V19.6777Z" fill="#CFBCFF" />
          <path
            d="M18.5885 47.1372C17.7617 47.1372 17.0913 47.8074 17.0913 48.6344V84.8537C18.0576 85.6731 19.056 86.4552 20.0857 87.1971V48.6344C20.0857 47.8076 19.4155 47.1372 18.5885 47.1372Z"
            fill="#9C75FE"
          />
          <path
            d="M24.2745 47.1372C23.4478 47.1372 22.7773 47.8074 22.7773 48.6344V89.0011C23.7526 89.6084 24.7512 90.1817 25.7717 90.7195V48.6346C25.7717 47.8076 25.1015 47.1372 24.2745 47.1372Z"
            fill="#9C75FE"
          />
          <path
            d="M29.9606 47.1372C29.1338 47.1372 28.4634 47.8074 28.4634 48.6344V92.0343C29.4443 92.4751 30.4431 92.8834 31.4578 93.2588V48.6344C31.4578 47.8076 30.7873 47.1372 29.9606 47.1372Z"
            fill="#9C75FE"
          />
          <path
            d="M35.6466 47.1372C34.8198 47.1372 34.1494 47.8074 34.1494 48.6344V94.1633C35.1347 94.4625 36.1327 94.7321 37.1438 94.9685V48.6344C37.1436 47.8076 36.4734 47.1372 35.6466 47.1372Z"
            fill="#9C75FE"
          />
          <path
            d="M41.3322 62.1143C40.5054 62.1143 39.835 61.444 39.835 60.6171V48.6344C39.835 47.8076 40.5052 47.1372 41.3322 47.1372C42.1589 47.1372 42.8294 47.8074 42.8294 48.6344V60.6171C42.8294 61.444 42.1591 62.1143 41.3322 62.1143Z"
            fill="#9C75FE"
          />
          <path
            d="M24.2745 38.5572C23.4478 38.5572 22.7773 37.887 22.7773 37.06V25.1383C22.7773 24.3115 23.4476 23.6411 24.2745 23.6411C25.1015 23.6411 25.7717 24.3113 25.7717 25.1383V37.06C25.7715 37.8868 25.1013 38.5572 24.2745 38.5572Z"
            fill="#9C75FE"
          />
          <path
            d="M29.9606 38.5572C29.1338 38.5572 28.4634 37.887 28.4634 37.06V25.1383C28.4634 24.3115 29.1336 23.6411 29.9606 23.6411C30.7873 23.6411 31.4578 24.3113 31.4578 25.1383V37.06C31.4576 37.8868 30.7873 38.5572 29.9606 38.5572Z"
            fill="#9C75FE"
          />
          <path
            d="M35.6466 38.5572C34.8198 38.5572 34.1494 37.887 34.1494 37.06V25.1383C34.1494 24.3115 34.8196 23.6411 35.6466 23.6411C36.4734 23.6411 37.1438 24.3113 37.1438 25.1383V37.06C37.1436 37.8868 36.4734 38.5572 35.6466 38.5572Z"
            fill="#9C75FE"
          />
          <path
            d="M41.3322 38.5572C40.5054 38.5572 39.835 37.887 39.835 37.06V25.1383C39.835 24.3115 40.5052 23.6411 41.3322 23.6411C42.1589 23.6411 42.8294 24.3113 42.8294 25.1383V37.06C42.8294 37.8868 42.1589 38.5572 41.3322 38.5572Z"
            fill="#A8B7EF"
          />
          <path
            d="M41.3322 38.5572C40.5054 38.5572 39.835 37.887 39.835 37.06V25.1383C39.835 24.3115 40.5052 23.6411 41.3322 23.6411C42.1589 23.6411 42.8294 24.3113 42.8294 25.1383V37.06C42.8294 37.8868 42.1591 38.5572 41.3322 38.5572Z"
            fill="#9C75FE"
          />
          <path
            d="M47.0182 62.1143C46.1914 62.1143 45.521 61.444 45.521 60.6171V48.6344C45.521 47.8076 46.1912 47.1372 47.0182 47.1372C47.8452 47.1372 48.5154 47.8074 48.5154 48.6344V60.6171C48.5154 61.444 47.845 62.1143 47.0182 62.1143Z"
            fill="#9C75FE"
          />
          <path
            d="M41.332 69.1011V95.7505C43.5482 96.0639 45.8119 96.2297 48.1145 96.2297C56.3349 96.2297 64.0719 94.1616 70.8359 90.5197V69.1011H41.332Z"
            fill="#B699FE"
          />
          <path
            d="M82.823 45.9192H65.2208C64.3941 45.9192 63.7236 45.249 63.7236 44.422C63.7236 43.5952 64.3939 42.9248 65.2208 42.9248H82.823C83.6499 42.9248 84.3202 43.595 84.3202 44.422C84.32 45.249 83.6497 45.9192 82.823 45.9192Z"
            fill="#9C75FE"
          />
          <path
            d="M82.823 52.2273H65.2208C64.3941 52.2273 63.7236 51.5571 63.7236 50.7301C63.7236 49.9033 64.3939 49.2329 65.2208 49.2329H82.823C83.6499 49.2329 84.3202 49.9031 84.3202 50.7301C84.32 51.5571 83.6497 52.2273 82.823 52.2273Z"
            fill="#9C75FE"
          />
          <path
            d="M82.823 58.5359H65.2208C64.3941 58.5359 63.7236 57.8657 63.7236 57.0387C63.7236 56.2117 64.3939 55.5415 65.2208 55.5415H82.823C83.6499 55.5415 84.3202 56.2117 84.3202 57.0387C84.3202 57.8657 83.6497 58.5359 82.823 58.5359Z"
            fill="#9C75FE"
          />
          <path
            d="M82.8229 64.8445H77.0255C76.1987 64.8445 75.5283 64.1743 75.5283 63.3473C75.5283 62.5203 76.1986 61.8501 77.0255 61.8501H82.8229C83.6499 61.8501 84.3201 62.5203 84.3201 63.3473C84.3201 64.1743 83.6497 64.8445 82.8229 64.8445Z"
            fill="#9C75FE"
          />
          <path
            d="M82.8229 71.1526H77.0255C76.1987 71.1526 75.5283 70.4824 75.5283 69.6554C75.5283 68.8284 76.1986 68.1582 77.0255 68.1582H82.8229C83.6499 68.1582 84.3201 68.8284 84.3201 69.6554C84.3201 70.4824 83.6497 71.1526 82.8229 71.1526Z"
            fill="#9C75FE"
          />
          <path
            d="M82.8228 77.4612H75.8278C75.001 77.4612 74.3306 76.791 74.3306 75.964C74.3306 75.1372 75.0008 74.4668 75.8278 74.4668H82.8228C83.6497 74.4668 84.32 75.137 84.32 75.964C84.3198 76.7908 83.6495 77.4612 82.8228 77.4612Z"
            fill="#9C75FE"
          />
          <path
            d="M82.823 80.7754H75.8278C75.001 80.7754 74.3306 81.4456 74.3306 82.2726C74.3306 83.0996 75.0008 83.7698 75.8278 83.7698H80.3783C81.3988 82.8429 82.3769 81.8704 83.3143 80.8596C83.1601 80.806 82.9952 80.7754 82.823 80.7754Z"
            fill="#9C75FE"
          />
          <path
            d="M89.5049 39.7483C89.289 39.7483 89.07 39.7015 88.8621 39.6024L57.9351 24.8683C57.1885 24.5127 56.8719 23.6191 57.2275 22.8727C57.583 22.1262 58.4766 21.8092 59.2231 22.165L90.1501 36.8991C90.8965 37.2547 91.2133 38.1483 90.8577 38.8947C90.6009 39.4333 90.0643 39.7483 89.5049 39.7483Z"
            fill="#B699FE"
          />
          <path
            d="M47.5553 78.3241C46.7285 78.3241 46.0581 77.6539 46.0581 76.8269V75.0006C46.0581 74.1738 46.7283 73.5034 47.5553 73.5034C48.3821 73.5034 49.0525 74.1736 49.0525 75.0006V76.8269C49.0525 77.6537 48.3821 78.3241 47.5553 78.3241Z"
            fill="#8352FE"
          />
          <path
            d="M53.2413 78.3241C52.4146 78.3241 51.7441 77.6539 51.7441 76.8269V75.0006C51.7441 74.1738 52.4144 73.5034 53.2413 73.5034C54.0683 73.5034 54.7385 74.1736 54.7385 75.0006V76.8269C54.7385 77.6537 54.0683 78.3241 53.2413 78.3241Z"
            fill="#8352FE"
          />
          <path
            d="M58.9269 78.3241C58.1001 78.3241 57.4297 77.6539 57.4297 76.8269V75.0006C57.4297 74.1738 58.0999 73.5034 58.9269 73.5034C59.7538 73.5034 60.4241 74.1736 60.4241 75.0006V76.8269C60.4241 77.6537 59.7538 78.3241 58.9269 78.3241Z"
            fill="#8352FE"
          />
          <path
            d="M64.6134 78.3241C63.7866 78.3241 63.1162 77.6539 63.1162 76.8269V75.0006C63.1162 74.1738 63.7864 73.5034 64.6134 73.5034C65.4402 73.5034 66.1106 74.1736 66.1106 75.0006V76.8269C66.1104 77.6537 65.4402 78.3241 64.6134 78.3241Z"
            fill="#8352FE"
          />
          <path
            d="M47.5553 86.0688C46.7285 86.0688 46.0581 85.3985 46.0581 84.5716V82.7452C46.0581 81.9185 46.7283 81.248 47.5553 81.248C48.3821 81.248 49.0525 81.9183 49.0525 82.7452V84.5716C49.0525 85.3985 48.3821 86.0688 47.5553 86.0688Z"
            fill="#8352FE"
          />
          <path
            d="M53.2413 86.0688C52.4146 86.0688 51.7441 85.3985 51.7441 84.5716V82.7452C51.7441 81.9185 52.4144 81.248 53.2413 81.248C54.0683 81.248 54.7385 81.9183 54.7385 82.7452V84.5716C54.7385 85.3985 54.0683 86.0688 53.2413 86.0688Z"
            fill="#8352FE"
          />
          <path
            d="M58.9269 86.0688C58.1001 86.0688 57.4297 85.3985 57.4297 84.5716V82.7452C57.4297 81.9185 58.0999 81.248 58.9269 81.248C59.7538 81.248 60.4241 81.9183 60.4241 82.7452V84.5716C60.4241 85.3985 59.7538 86.0688 58.9269 86.0688Z"
            fill="#8352FE"
          />
          <path
            d="M64.6134 86.0688C63.7866 86.0688 63.1162 85.3985 63.1162 84.5716V82.7452C63.1162 81.9185 63.7864 81.248 64.6134 81.248C65.4402 81.248 66.1106 81.9183 66.1106 82.7452V84.5716C66.1104 85.3985 65.4402 86.0688 64.6134 86.0688Z"
            fill="#8352FE"
          />
          <path
            d="M47.5553 93.8134C46.7285 93.8134 46.0581 93.1432 46.0581 92.3162V90.4899C46.0581 89.6631 46.7283 88.9927 47.5553 88.9927C48.3821 88.9927 49.0525 89.6629 49.0525 90.4899V92.3162C49.0525 93.143 48.3821 93.8134 47.5553 93.8134Z"
            fill="#8352FE"
          />
          <path
            d="M53.2413 93.8134C52.4146 93.8134 51.7441 93.1432 51.7441 92.3162V90.4899C51.7441 89.6631 52.4144 88.9927 53.2413 88.9927C54.0683 88.9927 54.7385 89.6629 54.7385 90.4899V92.3162C54.7385 93.143 54.0683 93.8134 53.2413 93.8134Z"
            fill="#8352FE"
          />
          <path
            d="M58.9269 93.8134C58.1001 93.8134 57.4297 93.1432 57.4297 92.3162V90.4899C57.4297 89.6631 58.0999 88.9927 58.9269 88.9927C59.7538 88.9927 60.4241 89.6629 60.4241 90.4899V92.3162C60.4241 93.143 59.7538 93.8134 58.9269 93.8134Z"
            fill="#8352FE"
          />
          <path
            d="M64.6134 88.9927C63.7866 88.9927 63.1162 89.6629 63.1162 90.4899V92.3162C63.1162 92.8573 63.4044 93.3301 63.8346 93.593C64.5787 93.3352 65.3144 93.0597 66.0412 92.7668C66.0861 92.6246 66.1106 92.4733 66.1106 92.3162V90.4899C66.1104 89.6629 65.4402 88.9927 64.6134 88.9927Z"
            fill="#8352FE"
          />
          <path
            d="M70.8366 69.3003H41.3325C39.8441 69.3003 38.6377 68.0939 38.6377 66.6055C38.6377 65.1171 39.8441 63.9106 41.3325 63.9106H70.8364C72.3248 63.9106 73.5313 65.1171 73.5313 66.6055C73.5315 68.0939 72.325 69.3003 70.8366 69.3003Z"
            fill="#9C75FE"
          />
          <path
            d="M48.4777 21.1746H17.129C16.3023 21.1746 15.6318 20.5043 15.6318 19.6774C15.6318 18.8506 16.3021 18.1802 17.129 18.1802H48.4777C49.3044 18.1802 49.9749 18.8504 49.9749 19.6774C49.9749 20.5041 49.3044 21.1746 48.4777 21.1746Z"
            fill="#B699FE"
          />
          <path
            d="M53.0064 43.4475H12.6007C11.7739 43.4475 11.1035 42.7773 11.1035 41.9503C11.1035 41.1236 11.7737 40.4531 12.6007 40.4531H53.0064C53.8332 40.4531 54.5036 41.1234 54.5036 41.9503C54.5036 42.7771 53.8334 43.4475 53.0064 43.4475Z"
            fill="#B699FE"
          />
        </svg>
      )}
    </div>
  );
}
