import { Button, Chip } from "libs/ui";
import { toLower, upperFirst } from "lodash-es";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FilterType, FilterTypeValues, useDashboardMapContext } from "../../DashboardMapContext";
import { useDashboardMapReportContext } from "../../DashboardMapReportDataContext";
import { AGE_OPTIONS, ANCESTRY_OPTIONS, GENDER_OPTIONS } from "../helper";
import "./MapFilterChips.scss";

export function MapFilterChips() {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.DashboardMapFilter" });
  const { filters, dispatchFilters } = useDashboardMapContext();
  const { getReportModelPerFilters, selectedReportType } = useDashboardMapReportContext();

  const { onlyGenders, onlyAgeRanges, onlyAncestries } = filters;

  const showClearAll =
    onlyGenders.length > 0 || onlyAgeRanges.length > 0 || onlyAncestries.length > 0;

  const onChipClose = (filterType: FilterType, val: FilterTypeValues) => {
    const currentValues = new Set<FilterTypeValues>(filters[filterType]);
    currentValues.delete(val);

    dispatchFilters({ type: filterType, payload: [...currentValues.values()] });
    getReportModelPerFilters({
      [filterType]: [...currentValues.values()],
      reportType: selectedReportType,
    });
  };

  const onClearAll = useCallback(() => {
    Object.keys(FilterType).forEach(type => {
      dispatchFilters({
        type: type as FilterType,
        payload: [],
      });
    });

    getReportModelPerFilters({
      onlyGenders: undefined,
      onlyAgeRanges: undefined,
      onlyAncestries: undefined,
      reportType: selectedReportType,
    });
  }, [dispatchFilters, getReportModelPerFilters, selectedReportType]);

  return (
    <div className="MapFilterChips">
      {onlyGenders.map(el => (
        <Chip key={el} size="medium" variant="grey" onClick={() => onChipClose("onlyGenders", el)}>
          <p className="Chip__title">{upperFirst(toLower(t("titleGender")))}</p>
          <p className="Chip__body">{GENDER_OPTIONS.find(gender => gender.value === el)?.title}</p>
        </Chip>
      ))}

      {onlyAgeRanges.map(el => (
        <Chip
          key={el}
          size="medium"
          variant="grey"
          onClick={() => onChipClose("onlyAgeRanges", el)}
        >
          <p className="Chip__title">{upperFirst(toLower(t("titleAge")))}</p>
          <p className="Chip__body">{AGE_OPTIONS.find(age => age.value === el)?.title}</p>
        </Chip>
      ))}

      {onlyAncestries.map(el => (
        <Chip
          key={el}
          size="medium"
          variant="grey"
          onClick={() => onChipClose("onlyAncestries", el)}
        >
          <p className="Chip__title">{upperFirst(toLower(t("titleAncestry")))}</p>
          <p className="Chip__body">
            {ANCESTRY_OPTIONS.find(ancestry => ancestry.value === el)?.title}
          </p>
        </Chip>
      ))}

      {showClearAll ? (
        <Button
          className="ClearAllButton"
          onClick={onClearAll}
          buttonSize="small"
          buttonType="transparent"
        >
          {t("buttonClearAll")}
        </Button>
      ) : null}
    </div>
  );
}
