import { useCallback, useState } from "react";
import {
  LocalStorageKey,
  tryGetLocalStorageValue,
  setLocalStorageItem as setLocalStorage,
} from "../localStorage";

export function useLocalStorage<T>(key: LocalStorageKey) {
  const [storageValue, setStorageValue] = useState(tryGetLocalStorageValue<T>(key));

  const setLocalStorageItem = useCallback(
    (value: T) => {
      setLocalStorage<T>(key, value);
      setStorageValue(value);
    },
    [key],
  );

  const removeLocalStorageItem = useCallback(() => {
    localStorage.removeItem(key);
    setStorageValue(undefined);
  }, [key]);

  return { storageValue, setLocalStorageItem, removeLocalStorageItem, tryGetLocalStorageValue };
}
