/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthResponseModel
 */
export interface AuthResponseModel {
    /**
     * 
     * @type {string}
     * @memberof AuthResponseModel
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResponseModel
     */
    userPoolId: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResponseModel
     */
    identityPoolId: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResponseModel
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResponseModel
     */
    authenticationFlowType: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResponseModel
     */
    env?: string | null;
}

/**
 * Check if a given object implements the AuthResponseModel interface.
 */
export function instanceOfAuthResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "userPoolId" in value;
    isInstance = isInstance && "identityPoolId" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "authenticationFlowType" in value;

    return isInstance;
}

export function AuthResponseModelFromJSON(json: any): AuthResponseModel {
    return AuthResponseModelFromJSONTyped(json, false);
}

export function AuthResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['ClientId'],
        'userPoolId': json['UserPoolId'],
        'identityPoolId': json['IdentityPoolId'],
        'region': json['Region'],
        'authenticationFlowType': json['AuthenticationFlowType'],
        'env': !exists(json, 'Env') ? undefined : json['Env'],
    };
}

export function AuthResponseModelToJSON(value?: AuthResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ClientId': value.clientId,
        'UserPoolId': value.userPoolId,
        'IdentityPoolId': value.identityPoolId,
        'Region': value.region,
        'AuthenticationFlowType': value.authenticationFlowType,
        'Env': value.env,
    };
}

