import { SimpleFormLayout } from "libs/ui/SimpleFormLayout";
import { SimpleFormLayoutGroup } from "libs/ui/SimpleFormLayout/SimpleFormLayoutGroup";
import { SimpleFormLayoutSwitch } from "libs/ui/SimpleFormLayout/SimpleFormLayoutSwitch";
import "./RequestNew.scss";
import { useCallback, useState } from "react";
import { ReportType } from "utils/createGlobalConfigStore";
import { Button } from "libs/ui";
import { useTranslation } from "react-i18next";
import { CarnaApiEvent } from "config/apiEvent";
import { toastStore } from "config/toast";
import { backOff } from "exponential-backoff";
import { generatePath, useNavigate } from "react-router-dom";
import { mainRoutePaths, reportsRoutePaths } from "router";
import { useOnEventStatusSubscribe } from "utils/hooks/useOnEventStatusSubscribe";
import { CarnaApiReportService } from "config/apiReportService";
import { useReportsTableContext } from "../ReportsContext";

const backOffConfig: Parameters<typeof backOff>["1"] = {
  jitter: "full",
  delayFirstAttempt: true,
  startingDelay: 1000,
  numOfAttempts: 3,
  retry(e, attemptNumber) {
    return e?.code === "TooManyRequestsException";
  },
} as const;

export function RequestNew() {
  const { t } = useTranslation("translation", { keyPrefix: "ReportPage.RequestNew" });
  const [onEvent, isLoadingEvent] = useOnEventStatusSubscribe();
  const navigate = useNavigate();
  const fetchLatest = useReportsTableContext(state => state.fetchLatest);

  const [reports, setReports] = useState<ReportType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSwitchToggle = useCallback(
    (type: ReportType) => {
      const currentState = new Set(reports);

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      currentState.has(type) ? currentState.delete(type) : currentState.add(type);

      setReports(Array.from(currentState.values()));
    },
    [reports],
  );

  const getIsCheckedType = (type: ReportType) => reports.some(el => el === type);

  const onRequestNewHandle = async () => {
    if (reports.length === 0) {
      return;
    }

    setIsLoading(true);

    const promises = reports.map(reportType =>
      backOff(
        () => CarnaApiEvent.Reports.post({ requestReportModel: { reportType } }),
        backOffConfig,
      ),
    );

    const result = await Promise.allSettled(promises);

    const successfulResults: Response[] = [];
    const failedResults: string[] = [];

    result.forEach((response, index) => {
      if (response.status === "rejected") {
        failedResults.push(t(`${reports[index]}Title`));
      } else {
        successfulResults.push(response.value);
      }
    });

    if (successfulResults.length > 0) {
      onEvent(
        new Promise(resolve => {
          return resolve(successfulResults[0]);
        }),
        {
          complete: () => {
            navigate(generatePath(mainRoutePaths.reports.concat(reportsRoutePaths.allReports)));
            fetchLatest();
          },

          error: () => {
            toastStore.toast.error({
              msg: t("toastErrorSuccessRequestButFailedToFetchTableData"),
            });
          },
        },
        CarnaApiReportService.Event.get,
        undefined,
        5,
      );
    }

    if (failedResults.length > 0) {
      toastStore.toast.error({
        msg: t("toastError", { allTypes: failedResults.join(", ") }),
      });
    }

    setIsLoading(false);
  };

  const isInLoadingState = isLoading || isLoadingEvent;

  return (
    <div className="RequestNew">
      <p className="RequestNew__description">{t("title")}</p>
      <SimpleFormLayout className="RequestNew__form">
        <SimpleFormLayoutGroup title={t("groupWorkspace")}>
          <SimpleFormLayoutSwitch
            title={t("WorkspaceInfoTitle")}
            description={t("workspaceDescription")}
            onChange={() => onSwitchToggle("WorkspaceInfo")}
            checked={getIsCheckedType("WorkspaceInfo")}
            disabled={isInLoadingState}
          />
          <SimpleFormLayoutSwitch
            title={t("DeviceAndAppsTitle")}
            description={t("devicesDescription")}
            onChange={() => onSwitchToggle("DeviceAndApps")}
            checked={getIsCheckedType("DeviceAndApps")}
            disabled={isInLoadingState}
          />
          <SimpleFormLayoutSwitch
            title={t("PatientsStatsTitle")}
            description={t("patientsDescription")}
            onChange={() => onSwitchToggle("PatientsStats")}
            checked={getIsCheckedType("PatientsStats")}
            disabled={isInLoadingState}
          />
        </SimpleFormLayoutGroup>
        <SimpleFormLayoutGroup title={t("groupMeasurements")}>
          <SimpleFormLayoutSwitch
            title={t("MeasurementsTitle")}
            description={t("measurementsDescription")}
            onChange={() => onSwitchToggle("Measurements")}
            checked={getIsCheckedType("Measurements")}
            disabled={isInLoadingState}
          />
          <SimpleFormLayoutSwitch
            title={t("CkdHeatMapTitle")}
            description={t("CKDDescription")}
            onChange={() => onSwitchToggle("CkdHeatMap")}
            checked={getIsCheckedType("CkdHeatMap")}
            disabled={isInLoadingState}
          />
        </SimpleFormLayoutGroup>
        <SimpleFormLayoutGroup title={t("groupScreening")}>
          <SimpleFormLayoutSwitch
            title={t("ScreeningDatesTitle")}
            description={t("screeningsDescription")}
            onChange={() => onSwitchToggle("ScreeningDates")}
            checked={getIsCheckedType("ScreeningDates")}
            disabled={isInLoadingState}
          />
        </SimpleFormLayoutGroup>
      </SimpleFormLayout>

      <div className="RequestNew__action">
        <Button
          onClick={onRequestNewHandle}
          buttonType="primary"
          buttonSize="medium"
          disabled={reports.length === 0 || isInLoadingState}
          loading={isInLoadingState}
        >
          {t("requestReportButton")}
        </Button>
      </div>
    </div>
  );
}
