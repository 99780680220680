import { SplashScreen } from "components/SplashScreen";
import { i18nNotification } from "i18nNotification";
import { FetchingErrorPage } from "pages/ErrorBoundary/FetchingErrorPage";
import { PropsWithChildren, useSyncExternalStore } from "react";
import { useTranslation } from "react-i18next";

export default function TranslationsLoader({ children }: PropsWithChildren) {
  const { t: tErrorJson } = useTranslation("translation", {
    keyPrefix: "ErrorPages.TranslationErrorJson",
  });
  const { t: tError } = useTranslation("translation", { keyPrefix: "ErrorPages.TranslationError" });
  const currentI18nState = useSyncExternalStore(
    i18nNotification.subscribe,
    i18nNotification.getState,
  );

  if (currentI18nState === "json_error") {
    return (
      <FetchingErrorPage
        // fetchingFunc,
        title={tErrorJson("title")}
        message={tErrorJson("message")}
        fetchingFunc={() => window.location.reload()}
      />
    );
  }

  if (currentI18nState === "failed") {
    return (
      <FetchingErrorPage
        // fetchingFunc,
        title={tError("title")}
        message={tError("message")}
        fetchingFunc={() => window.location.reload()}
      />
    );
  }

  if (currentI18nState !== "ready") {
    return <SplashScreen />;
  }
  return children;
}
