/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  TestListResponseModel,
  TestsFilterModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    TestListResponseModelFromJSON,
    TestListResponseModelToJSON,
    TestsFilterModelFromJSON,
    TestsFilterModelToJSON,
} from '../models/index';

export interface MeasurementsGetRequest {
    filters?: TestsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdDevicesUserEntityIdMeasurementsGetRequest {
    organizationId: string;
    userEntityId: string;
    filters?: TestsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGetRequest {
    organizationId: string;
    userEntityId: string;
    filters?: TestsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdMeasurementsGetRequest {
    organizationId: string;
    filters?: TestsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdPatientsUserEntityIdMeasurementsGetRequest {
    organizationId: string;
    userEntityId: string;
    filters?: TestsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

/**
 * MeasurementsApi - interface
 * 
 * @export
 * @interface MeasurementsApiInterface
 */
export interface MeasurementsApiInterface {
    /**
     * 
     * @param {TestsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementsApiInterface
     */
    measurementsGetRaw(requestParameters: MeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>>;

    /**
     */
    measurementsGet(requestParameters: MeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {TestsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementsApiInterface
     */
    organizationsOrganizationIdDevicesUserEntityIdMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdDevicesUserEntityIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>>;

    /**
     */
    organizationsOrganizationIdDevicesUserEntityIdMeasurementsGet(requestParameters: OrganizationsOrganizationIdDevicesUserEntityIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {TestsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementsApiInterface
     */
    organizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>>;

    /**
     */
    organizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGet(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {TestsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementsApiInterface
     */
    organizationsOrganizationIdMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>>;

    /**
     */
    organizationsOrganizationIdMeasurementsGet(requestParameters: OrganizationsOrganizationIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {TestsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementsApiInterface
     */
    organizationsOrganizationIdPatientsUserEntityIdMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>>;

    /**
     */
    organizationsOrganizationIdPatientsUserEntityIdMeasurementsGet(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel>;

}

/**
 * 
 */
export class MeasurementsApi extends runtime.BaseAPI implements MeasurementsApiInterface {

     /**
     */
    async measurementsGetRawOriginal(requestParameters: MeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/measurements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async measurementsGetRaw(requestParameters: MeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/measurements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestListResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async measurementsGet(requestParameters: MeasurementsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel> {
        const response = await this.measurementsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdDevicesUserEntityIdMeasurementsGetRawOriginal(requestParameters: OrganizationsOrganizationIdDevicesUserEntityIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesUserEntityIdMeasurementsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdDevicesUserEntityIdMeasurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/devices/{userEntityId}/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdDevicesUserEntityIdMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdDevicesUserEntityIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesUserEntityIdMeasurementsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdDevicesUserEntityIdMeasurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/devices/{userEntityId}/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestListResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdDevicesUserEntityIdMeasurementsGet(requestParameters: OrganizationsOrganizationIdDevicesUserEntityIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel> {
        const response = await this.organizationsOrganizationIdDevicesUserEntityIdMeasurementsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGetRawOriginal(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/hcps/{userEntityId}/patients/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{userEntityId}/patients/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestListResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGet(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel> {
        const response = await this.organizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdMeasurementsGetRawOriginal(requestParameters: OrganizationsOrganizationIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdMeasurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdMeasurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestListResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdMeasurementsGet(requestParameters: OrganizationsOrganizationIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel> {
        const response = await this.organizationsOrganizationIdMeasurementsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdMeasurementsGetRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdMeasurementsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdMeasurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TestListResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdMeasurementsGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdMeasurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestListResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdMeasurementsGet(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TestListResponseModel> {
        const response = await this.organizationsOrganizationIdPatientsUserEntityIdMeasurementsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
