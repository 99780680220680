import classNames from "classnames";
import { DetailedHTMLProps, useCallback, useState } from "react";
import { Icon } from "../Icon";
import { IconType } from "libs/ui";
import "./Avatar.scss";
import UploadImageSVG from "../assets/svg/UploadImageIcon";

export type AvatarSize = "extraSmall" | "small" | "medium" | "large";

export type AvatarType = "Hcp" | "Patient" | "Admin" | "Partner";

function resolveAvatarIconValue(val: AvatarType) {
  const iconMap: Record<AvatarType, IconType> = {
    Hcp: "Hcp",
    Patient: "User",
    Admin: "Admin",
    Partner: "User",
  } as const;

  return iconMap[val];
}

export type AvatarProps = DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  firstName?: string | null;
  size?: AvatarSize;
  avatarType?: AvatarType;
  avatarLoading?: boolean;
  avatarForCreate?: boolean;
  src?: string;
  alt?: string;
};

export function Avatar({
  firstName = "",
  size = "small",
  avatarType,
  className,
  src,
  alt,
  avatarLoading = true,
  avatarForCreate = false,
  ...props
}: AvatarProps) {
  const firstLetter = firstName ? firstName[0] : "";

  const [showImage, setShowImage] = useState(false);

  const AvatarWithoutCreate = useCallback(() => {
    if (avatarLoading) {
      return <div className="Avatar__loader" />;
    }

    /**
     * We are going to try to "fetch" the image from the URL
     * However we cannot be sure if it will succeed or has an error
     * Thus we are hiding the image until it succeeds, or hide it forver
     */
    return (
      <>
        {src ? (
          <img
            style={{ display: showImage ? "" : "none" }}
            src={src}
            alt={alt}
            onLoad={() => {
              setShowImage(true);
            }}
            className="Avatar__img"
          />
        ) : null}
        {showImage ? null : (
          <div className="Avatar__placeholder">
            <span className="Avatar__letter">{firstLetter}</span>
          </div>
        )}
      </>
    );
  }, [alt, avatarLoading, firstLetter, showImage, src]);

  return (
    <div
      {...props}
      className={classNames(
        "Avatar",
        `Avatar--${size}`,
        {
          [`Avatar--${avatarType}`]: avatarType,
          "Avatar--for-create": avatarForCreate,
        },
        className,
      )}
    >
      <figure className="Avatar__figure">
        {avatarForCreate ? (
          <div className="UploadImage">
            <UploadImageSVG />
          </div>
        ) : (
          <AvatarWithoutCreate />
        )}
      </figure>
      {avatarType && (
        <Icon
          icon={resolveAvatarIconValue(avatarType)}
          className={classNames("Avatar__icon", `Avatar__icon--${avatarType}`)}
          viewBox="-8 -3 40 40"
        />
      )}
    </div>
  );
}
