import { useMachine } from "@xstate/react";
import { OrganizationDetailsResponseModel } from "api/query";
import { stripNetworkBodyWith } from "components/Forms/helper";
import { CarnaApiEvent } from "config/apiEvent";
import { CarnaApiQuery } from "config/apiQuery";
import { UpdateOrganizationModelForStripping } from "config/binding";
import { breadcrumbsStore } from "config/breadcrumbs";
import { toastStore } from "config/toast";
import i18n from "i18next";
import { OrganizationModel } from "models/OrganizationModels/OrganizationModels";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { firstValueFrom } from "rxjs";
import { getRowVersion } from "utils/helpers/getRowVersion";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import {
  OnEventStatusSubscribeError,
  waitForQueryService,
} from "utils/hooks/useOnEventStatusSubscribe";
import { makeDetailsPageStateMachine } from "utils/machines/pages/details/makeDetailsPageStateMachine";

import { assign, fromPromise } from "xstate";

const successToast = () =>
  toastStore.pushToast({
    expire: 5000,
    type: "success",
    msg: i18n.t("OrganizationDetails.successPutToast", { ns: "translation" }),
  });

// ! FIXME add xstate error event model type
const errorPutToast = (input: any) => {
  const err = input.event.error as OnEventStatusSubscribeError;

  if (err.type === "OnEventStatusSubscribeError") {
    switch (err.code) {
      case "BE_ERROR":
        showBeFieldErrors(
          err.err,
          i18n.t("OrganizationDetails.errorPutToast", { ns: "translation" }),
        );

        break;
      case "ACTION_FAILED":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("OrganizationDetails.errorPutToast", { ns: "translation" }),
        });
        break;
      case "STATUS_QUERY_ERROR":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("OrganizationDetails.queryServiceError", { ns: "translation" }),
        });
    }
    return;
  }

  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("OrganizationDetails.errorPutToast", { ns: "translation" }),
  });
};

const errorFetchToast = () => {
  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("OrganizationDetails.errorFetchToast", { ns: "translation" }),
  });
};

export function useMakeOrganizationPagesDetailState() {
  // const { fetchOrganization, saveOrganization } = useApis();
  const { organizationId } = useParams<RouteParams["organizationDetails"]>();

  const organizationPageStateMachine = useMemo(
    () =>
      makeDetailsPageStateMachine<
        OrganizationModel,
        OrganizationModel
        // DetailsPageServiceList<typeof fetchOrganization, typeof saveOrganization, never>
      >(),
    [],
  );

  const service = useMachine(
    organizationPageStateMachine.provide({
      actors: {
        saveStatus: undefined,
        saveData: fromPromise(async context => {
          const putOrganization = CarnaApiEvent.Organization.put(
            {
              organizationEntityId: organizationId ?? "",
              updateOrganizationRequestModel: {
                ...context.input.event.value,
                // rowVersion: event.value.rowVersion,
              },
            },
            stripNetworkBodyWith(UpdateOrganizationModelForStripping),
          );

          await firstValueFrom(waitForQueryService(putOrganization));

          const result = await putOrganization;

          return {
            ...context.input.event.value,
            ...getRowVersion(result),
          } as OrganizationDetailsResponseModel;
        }),
        fetchData: fromPromise(async () => {
          return CarnaApiQuery.Organizations.getById({ organizationId: organizationId ?? "" });
        }),
      },
      actions: {
        updateSaveStatus: undefined,
        dataSaved: successToast,
        savingFailed: errorPutToast,
        failedToLoadData: errorFetchToast,
        loadSavedData: assign({
          data: input => {
            return (input.event as any).output;
          },
        }),
        loadEntityData: assign({
          data: input => {
            return (input.event as any).output;
          },
        }),
        refreshTable: () => {},
      },
    }),
  );

  return service;
}

export function useSetBreadcrumbsToSelectedOrganization(
  detailsService: ReturnType<typeof useMakeOrganizationPagesDetailState>,
) {
  const [state] = detailsService;

  useEffect(() => {
    if (!state.matches("entity.loading")) {
      breadcrumbsStore.setCurrentPageTitle(`${state.context?.data?.name}`);
    }
    return () => breadcrumbsStore.resetCurrentPageTitle();
  }, [state]);
}
