import { FocusEventHandler, SetStateAction } from "react";
import { TextFieldProps } from "@mui/material";
import { Icon, Input } from "libs/ui";
import classNames from "classnames";
import "./DatePickerInput.scss";

interface DatePickerInputProps {
  value: string;
  label: string;
  disabled: boolean;
  params: TextFieldProps;
  setOpen: (value: SetStateAction<boolean>) => void;
  onBlur: FocusEventHandler<HTMLInputElement> | undefined;
  className?: string;
}

function DatePickerInput({
  value,
  label,
  disabled,
  params,
  setOpen,
  onBlur,
  className,
}: DatePickerInputProps) {
  const { inputProps, inputRef } = params;
  return (
    <Input
      {...inputProps}
      ref={inputRef}
      className={classNames("DatePickerInput", className)}
      data-testid="date-picker"
      value={value}
      label={label}
      placeholder={""}
      onChange={() => null}
      {...(!disabled
        ? {
            onClick: () => setOpen(prev => !prev),
            inputIcon: {
              icon: <Icon icon={"Calendar"} onClick={() => setOpen(prev => !prev)} />,
              iconPosition: "trailing",
            },
            onBlur,
          }
        : undefined)}
    />
  );
}

export default DatePickerInput;
