import * as React from "react";
import { SVGProps } from "react";
const SvgBluetooth = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.713.307a.75.75 0 0 1 .817.163l5.5 5.5a.75.75 0 0 1 0 1.06L13.06 12l4.97 4.97a.75.75 0 0 1 0 1.06l-5.5 5.5a.75.75 0 0 1-1.28-.53v-9.19l-4.22 4.22a.75.75 0 0 1-1.06-1.06L10.94 12 5.97 7.03a.75.75 0 0 1 1.06-1.06l4.22 4.22V1a.75.75 0 0 1 .463-.693Zm1.037 13.504 3.69 3.689-3.69 3.69v-7.38Zm0-3.622V2.811L16.44 6.5l-3.69 3.69Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgBluetooth;
