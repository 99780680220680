import { UserStatusType } from "models/PersonModels";
import i18n from "i18next";

export function userStatusesToSelectOptions() {
  const ancestries = Object.keys(UserStatusType);

  return ancestries.map(key => ({
    title: i18n.t(`userStatusType.${key}`, { ns: "translation" }),
    value: UserStatusType[key as keyof typeof UserStatusType],
  }));
}
