import * as React from "react";
import { SVGProps } from "react";
const SvgThumbsDownEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.727 3.126a1.56 1.56 0 0 0-1.044-.376H17.75v9.5h1.933a1.56 1.56 0 0 0 1.567-1.306V4.055a1.56 1.56 0 0 0-.523-.93ZM16.25 12.84l-3.716 8.36A2.25 2.25 0 0 1 10.75 19v-4a.75.75 0 0 0-.75-.75H4.331a1.25 1.25 0 0 1-1.25-1.437l1.38-9a1.25 1.25 0 0 1 1.25-1.063H16.25v10.09ZM19.664 1.25a3.06 3.06 0 0 1 3.08 2.65.751.751 0 0 1 .006.1v7a.75.75 0 0 1-.007.1 3.06 3.06 0 0 1-3.08 2.65h-2.176l-3.802 8.555a.75.75 0 0 1-.685.445A3.75 3.75 0 0 1 9.25 19v-3.25H4.344a2.75 2.75 0 0 1-2.746-3.163l1.38-9A2.752 2.752 0 0 1 5.724 1.25h13.94Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgThumbsDownEmpty;
