import { Breakpoint, DragAndDropGrid } from "components/DnD";
import { useCallback, useMemo } from "react";
import { MeasurementWidgetType } from "utils/createGlobalConfigStore";
import { useDashboardStatsWidgetContext } from "../DashboardStatsWidgetContext";
import { StatsWidgetType } from "../model";
import { StatsMeasurementWidget } from "./StatsMeasurementWidget";
import { HeatMapResultsWidget } from "./HeatMapResultsWidget";
import { Button } from "libs/ui/Button";
import { useTranslation } from "react-i18next";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";

export function WidgetsDnD() {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.DashboardStatsPage" });
  const { numberFormatterWithDash } = useLocaleNumberFormatter();

  const {
    latestSavedLayouts,
    currentBreakpoint,
    setCurrentBreakpoint,
    onLayoutChange,
    onRemoveWidget,
  } = useDashboardStatsWidgetContext();

  const onBreakpointChange = useCallback(
    (breakpoint: string, _cols: number) => {
      setCurrentBreakpoint(breakpoint as Breakpoint);
    },
    [setCurrentBreakpoint],
  );

  const items = useMemo(
    () =>
      latestSavedLayouts[currentBreakpoint ?? "col-9"].map(item => {
        switch (item.i as StatsWidgetType) {
          case "HeatMap":
            return (
              <HeatMapResultsWidget
                key={item.i}
                w={item.w}
                h={item.h}
                optionsDropDown={
                  <>
                    <Button
                      buttonSize="small"
                      buttonType="red-transparent"
                      buttonIcon={{ icon: "Delete" }}
                      onClick={() => onRemoveWidget(item.i as StatsWidgetType)}
                    >
                      {t("removeWidgetButton")}
                    </Button>
                  </>
                }
              />
            );
          case "SerumCreatinine":
          case "Egfr":
          case "Bmi":
          case "Glucose":
          case "Height":
          case "Weight":
          case "BloodPressure":
          case "UrineCreatinine":
          case "UrineAlbumin":
          case "Uacr":
          case "SemiQuantitativeUacr":
            return (
              <StatsMeasurementWidget
                key={item.i}
                type={item.i as MeasurementWidgetType}
                value={numberFormatterWithDash({ value: 23455 })} // !!! mock
                w={item.w}
                h={item.h}
                optionsDropDown={
                  <>
                    <Button
                      buttonSize="small"
                      buttonType="red-transparent"
                      buttonIcon={{ icon: "Delete" }}
                      onClick={() => onRemoveWidget(item.i as StatsWidgetType)}
                    >
                      {t("removeWidgetButton")}
                    </Button>
                  </>
                }
              />
            );
          default:
            throw new Error(`${item.i} type not mapped for Widget component return`);
        }
      }),
    [currentBreakpoint, latestSavedLayouts, numberFormatterWithDash, onRemoveWidget, t],
  );

  return (
    <div className="WidgetsDnD">
      <DragAndDropGrid
        layouts={latestSavedLayouts}
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
      >
        {items}
      </DragAndDropGrid>
    </div>
  );
}
