import {
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput,
} from "@aws-amplify/auth";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import { tryGetLocalStorageValue } from "utils/localStorage";
import * as Auth from "@aws-amplify/auth";
const authObj = tryGetLocalStorageValue("lastUsedAuth");
export let userAttributesCached: FetchUserAttributesOutput | null = null;

if (authObj) {
  Amplify.configure({ Auth: { Cognito: authObj as any } });
}

// ! E2E needs this
(window as any).Auth = Auth;

export async function fetchUserAttributesWithCache() {
  // try {
  //   const currentUser = getCurrentUser();

  // } catch (error) {

  // }
  if (userAttributesCached === null) {
    // We need this for access token refresh
    await fetchAuthSession();
    return (userAttributesCached = await fetchUserAttributes());
  }

  return userAttributesCached;
}

Hub.listen(
  "auth",
  async data => {
    if (data.payload.event === "signedIn" && userAttributesCached === null) {
      fetchUserAttributesWithCache();
    }
  },
  "userAttributes",
);

Hub.listen(
  "auth",
  async data => {
    if (data.payload.event === "signedOut") {
      userAttributesCached = null;
    }
  },
  "userAttributes",
);
