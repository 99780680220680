/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
} from '../models/index';

export interface OrganizationsOrganizationIdUsersUserEntityIdAccountDataPostRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    body?: object;
}

/**
 * AccountDataApi - interface
 * 
 * @export
 * @interface AccountDataApiInterface
 */
export interface AccountDataApiInterface {
    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDataApiInterface
     */
    organizationsOrganizationIdUsersUserEntityIdAccountDataPostRaw(requestParameters: OrganizationsOrganizationIdUsersUserEntityIdAccountDataPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdUsersUserEntityIdAccountDataPost(requestParameters: OrganizationsOrganizationIdUsersUserEntityIdAccountDataPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class AccountDataApi extends runtime.BaseAPI implements AccountDataApiInterface {

     /**
     */
    async organizationsOrganizationIdUsersUserEntityIdAccountDataPostRawOriginal(requestParameters: OrganizationsOrganizationIdUsersUserEntityIdAccountDataPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdUsersUserEntityIdAccountDataPost.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdUsersUserEntityIdAccountDataPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/users/{userEntityId}/account-data`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdUsersUserEntityIdAccountDataPostRaw(requestParameters: OrganizationsOrganizationIdUsersUserEntityIdAccountDataPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdUsersUserEntityIdAccountDataPost.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdUsersUserEntityIdAccountDataPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/users/{userEntityId}/account-data`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdUsersUserEntityIdAccountDataPost(requestParameters: OrganizationsOrganizationIdUsersUserEntityIdAccountDataPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdUsersUserEntityIdAccountDataPostRaw(requestParameters, initOverrides);
    }

}
