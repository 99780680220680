import { Avatar, Button } from "libs/ui";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { RouteParams, mainRoutePaths } from "router";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { MeasurementDetails } from "../../model";

interface PatientFieldProps<T> {
  patient?: T;
  patientAvatarImage?: string | null;
  avatarsLoading?: boolean;
}

export function PatientField<T extends MeasurementDetails["patient"]>({
  patient,
  patientAvatarImage,
  avatarsLoading,
}: Readonly<PatientFieldProps<T>>) {
  const navigate = useNavigate();
  const { patientId } = useParams<RouteParams["patientDetails"]>();
  const { isPatient } = useAdditionalAuthInformationContext();

  return (
    <Button
      onClick={
        patient?.id === patientId || isPatient
          ? undefined
          : () =>
              navigate(
                generatePath(mainRoutePaths.patientDetails, {
                  patientId: patient?.id ?? "",
                  organizationId: patient?.organizationId ?? "",
                }),
              )
      }
      buttonType={"link-grey"}
      buttonSize={"small"}
      disabled={patient?.id === patientId || isPatient}
    >
      <Avatar
        firstName={patient?.firstName}
        src={patientAvatarImage ?? undefined}
        avatarLoading={avatarsLoading}
        avatarType="Patient"
      />
      {patient?.firstName.concat(" ").concat(patient?.lastName)}
    </Button>
  );
}
