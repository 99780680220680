import i18n from "i18next";
import { UacrStatusType } from "models/TestModels";

export interface SQUACRFlags {
  status: UacrStatusType;
  level: string;
  stadium: string;
}

function getSQUACRFlagFields(status: string): { level: string; stadium: string } {
  return {
    level: i18n.t(`SQUACRFlags.level.${status}`, { ns: "translation" }),
    stadium: i18n.t(`SQUACRFlags.stadium.${status}`, { ns: "translation" }),
  };
}

export function getSQUACRFlags(flag?: number): SQUACRFlags {
  switch (flag) {
    case 1:
      return {
        status: "Normal",
        ...getSQUACRFlagFields("Normal"),
      };
    case 2:
      return {
        status: "Microalbuminuria",
        ...getSQUACRFlagFields("Microalbuminuria"),
      };
    case 3:
      return {
        status: "Macroalbuminuria",
        ...getSQUACRFlagFields("Macroalbuminuria"),
      };
    case undefined:
      return {
        status: "None",
        level: "",
        stadium: "",
      };
    default:
      throw new Error(`${flag} number is not covered in UacrStatusType`);
  }
}
