import classNames from "classnames";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import "./Loader.scss";

interface LoaderProps {
  loading?: boolean;
  loadingText?: string;
  inline?: boolean;
}

export function Loader({ loading, children, loadingText, inline }: PropsWithChildren<LoaderProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "ui-components.Loader" });

  if (!loading) {
    return <>{children}</>;
  }

  return (
    <span
      className={classNames("Loader", `Loader${loading ? "--visible" : "--hidden"}`, {
        "Loader--inline": inline,
      })}
      data-testid="Loader"
    >
      <span className="Loader__spinner" />
      <span>{loadingText ?? t("loading")}</span>
    </span>
  );
}
