import i18n from "i18next";
import { UserRoleType } from "models/PersonModels/Roles";

export function resolveUserRole(role?: UserRoleType) {
  if (role === "Patient" || role === undefined) {
    return null;
  }

  return i18n.t(`CurrentUserRole.${role}`, { ns: "translation" });
}
