import * as React from "react";
import { SVGProps } from "react";
const SvgTag = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7 6a1 1 0 0 0 0 2h.01a1 1 0 0 0 0-2H7Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 1.25a.75.75 0 0 0-.75.75v10c0 .2.08.39.22.53l8.59 8.58a2.75 2.75 0 0 0 3.89 0l7.17-7.17-.53-.53.532.529a2.75 2.75 0 0 0 0-3.878L12.53 1.47a.75.75 0 0 0-.53-.22H2ZM20.422 12c0 .33-.13.646-.363.88l-7.17 7.17a1.25 1.25 0 0 1-1.769 0l-8.37-8.36V2.75h8.94l8.369 8.37c.232.234.363.55.363.88Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgTag;
