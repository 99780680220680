type Listener = () => void;

export function createI18nNotification() {
  let currentState: "failed" | "json_error" | "ready" | null = null;
  const listeners = new Set<Listener>();

  const setState = (value: "failed" | "json_error" | "ready" | null) => {
    currentState = value;

    listeners.forEach(listener => {
      listener();
    });
  };

  const getState = () => currentState;

  const subscribe = (listener: Listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };

  return {
    getState,
    setState,
    subscribe,
  } as const;
}

export const i18nNotification = createI18nNotification();
