import { useEffect } from "react";
import { Observable } from "rxjs";

export function useSubscribe<A, T extends (...params: any[]) => void>(
  pipe: Observable<A>,
  next: T,
) {
  useEffect(() => {
    const subscription = pipe.subscribe({
      next,
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [next, pipe]);
}
