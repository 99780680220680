import classNames from "classnames";
import "./ColorDot.scss";

interface ColorDotProps {
  type: "primary" | "secondary";
}

export function ColorDot({ type }: Readonly<ColorDotProps>) {
  return <span className={classNames("ColorDot", `ColorDot--${type}`)} />;
}
