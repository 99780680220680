import {
  AncestryType,
  DeviceModel,
  EgfrFormulaType,
  IValidationError,
  MeasurementType,
  PatientUserModel,
  UserModel,
} from "api/query";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";

export interface MeasurementUIModel {
  measurementType: MeasurementUIType;
  items: Array<MeasurementItemUIModel>;
}

export interface MeasurementItemUIModel {
  value: string;
  unit: MeasurementUnitUIType;
  type?: MeasurementItemUIType;
}

export const MeasurementTrendType = {
  None: "None",
  Up: "Up",
  Down: "Down",
  Equal: "Equal",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MeasurementTrendType = (typeof MeasurementTrendType)[keyof typeof MeasurementTrendType];

export const SerumCreatinineStatusType = {
  None: "None",
  Normal: "Normal",
  Abnormal: "Abnormal",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SerumCreatinineStatusType =
  (typeof SerumCreatinineStatusType)[keyof typeof SerumCreatinineStatusType];

export const EgfrStatusType = {
  None: "None",
  NormalOrHigh: "NormalOrHigh",
  MildlyDecreased: "MildlyDecreased",
  MildlyOrModeratelyDecreased: "MildlyOrModeratelyDecreased",
  ModeratelyToSeverelyDecreased: "ModeratelyToSeverelyDecreased",
  SeverelyDecreased: "SeverelyDecreased",
  KidneyFailure: "KidneyFailure",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EgfrStatusType = keyof typeof EgfrStatusType;

export const UrineAlbuminStatusType = {
  None: "None",
  Normal: "Normal",
  Microalbuminuria: "Microalbuminuria",
  Macroalbuminuria: "Macroalbuminuria",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UrineAlbuminStatusType = keyof typeof UrineAlbuminStatusType;

export const UrineCreatinineStatusType = {
  None: "None",
  Low: "Low",
  Normal: "Normal",
  High: "High",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UrineCreatinineStatusType = keyof typeof UrineCreatinineStatusType;

export const UacrStatusType = {
  None: "None",
  Normal: "Normal",
  Microalbuminuria: "Microalbuminuria",
  Macroalbuminuria: "Macroalbuminuria",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UacrStatusType = keyof typeof UacrStatusType;

export const BmiStatusType = {
  None: "None",
  Underweight: "Underweight",
  Healthy: "Healthy",
  Overweight: "Overweight",
  Obesity: "Obesity",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BmiStatusType = (typeof BmiStatusType)[keyof typeof BmiStatusType];

export interface MeasurementBase {
  Value: number;
  Unit: MEASUREMENT_UNITS;
  TrendType: MeasurementTrendType;
  Percent: number;
}

interface MeasurementWithStatus<T> extends MeasurementBase {
  Status: T;
}

type SerumCreatinine = MeasurementWithStatus<SerumCreatinineStatusType>;

interface Egfr extends MeasurementWithStatus<EgfrStatusType> {
  FormulaType: EgfrFormulaType;
  Weight: number;
  Age: number;
  Ancestry: AncestryType;
  GraphValue: number;
}

export interface SerumCreatinineModel {
  SerumCreatinine: SerumCreatinine;
  Egfr: Egfr;
}

interface Glucose extends MeasurementBase {
  GraphValue: number;
}

export interface GlucoseModel {
  Glucose: Glucose;
}

export interface BloodPressureModel {
  Systolic: MeasurementBase;
  Diastolic: MeasurementBase;
}

type UrineAlbumin = MeasurementWithStatus<UrineAlbuminStatusType>;

type UrineCreatinine = MeasurementWithStatus<UrineCreatinineStatusType>;

type Uacr = MeasurementWithStatus<UacrStatusType>;

export interface UACRModel {
  UrineAlbumin: UrineAlbumin;
  UrineCreatinine: UrineCreatinine;
  Uacr: Uacr;
}

type Bmi = MeasurementWithStatus<BmiStatusType>;

export interface BMIModel {
  Bmi: Bmi;
  Weight: MeasurementBase;
  Height: MeasurementBase;
}

export const SQUrineCreatinineBucketType = {
  UpTo10: "<10",
  From10To50: "10-50",
  From50To100: "50-100",
  From100To200: "100-200",
  From200To300: "200-300",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SQUrineCreatinineBucketType =
  (typeof SQUrineCreatinineBucketType)[keyof typeof SQUrineCreatinineBucketType];

export const SQUrineAlbuminBucketType = {
  UpTo10: "<10",
  From10To30: "10-30",
  From30To80: "30-80",
  From80To150: "80-150",
  From150To210: "150-210",
  From210To700: "210-700",
  From700To2100: "700-2100",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SQUrineAlbuminBucketType =
  (typeof SQUrineAlbuminBucketType)[keyof typeof SQUrineAlbuminBucketType]; // Map keys to their corresponding values

export interface SQUACRModel {
  SemiQuantitativeUacr: {
    Value: number;
    Unit: MEASUREMENT_UNITS;
    TrendType: MeasurementTrendType;
  };
  SemiQuantitativeUrineAlbumin: {
    Value: number;
    Unit: MEASUREMENT_UNITS;
    TrendType: MeasurementTrendType;
    Bucket: SQUrineAlbuminBucketType;
  };
  SemiQuantitativeUrineCreatinine: {
    Value: number;
    Unit: MEASUREMENT_UNITS;
    TrendType: MeasurementTrendType;
    Bucket: SQUrineCreatinineBucketType;
  };
}

export type MeasurementTypes = Exclude<MeasurementType, "None">;

export type TestMeasurements = {
  SerumCreatinine?: SerumCreatinineModel;
  Glucose?: GlucoseModel;
  BloodPressure?: BloodPressureModel;
  UACR?: UACRModel;
  BMI?: BMIModel;
  SemiQuantitativeUACR?: SQUACRModel;
};

export interface TestModel {
  patient: PatientUserModel;
  testedBy?: UserModel | null;
  device?: DeviceModel;
  measurements: Array<TestMeasurements>;
  id: string;
  testCountId: string;
  organizationId: string;
  measurementTime: string;
  sampledByDevice?: number | null;
  validations?: Array<IValidationError> | null;
}

export interface SideDetailsModel {
  test: TestModel;
  measurement: TestMeasurements;
  measurementItem?: MeasurementItemUIType | "BloodPressure";
}

export const MeasurementUnitUIType = {
  None: "None",
  mgdL: "mgdL",
  mLmin173m2: "mLmin173m2",
  umolL: "umolL",
  mmHg: "mmHg",
  mgL: "mgL",
  mgg: "mgg",
  mm: "mm",
  kgM2: "kgM2",
  g: "g",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MeasurementUnitUIType = keyof typeof MeasurementUnitUIType;

// Group
export const MeasurementUIType = {
  None: "None",
  SerumCreatinine: "SerumCreatinine",
  BloodPressure: "BloodPressure",
  Glucose: "Glucose",
  UACR: "UACR",
  BMI: "BMI",
  SemiQuantitativeUACR: "SemiQuantitativeUACR",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MeasurementUIType = keyof typeof MeasurementUIType;

export function resolveMeasurementUIType(name: string): MeasurementUIType {
  const isBloodPressure = ["Systolic", "Diastolic"].some(el => el === name);
  const isUACR = ["UrineAlbumin", "UrineCreatinine"].some(el => el === name);
  const isBMI = ["Height", "Weight"].some(el => el === name);

  if (isBloodPressure) {
    return "BloodPressure";
  }

  if (isUACR) {
    return "UACR";
  }

  if (isBMI) {
    return "BMI";
  }

  return name as MeasurementUIType;
}

export const UImeasurementTypes: MeasurementUIType[] = [
  "SerumCreatinine",
  "UACR",
  "SemiQuantitativeUACR",
  "Glucose",
  "BMI",
  "BloodPressure",
];

export const MeasurementItemUIType = {
  None: "None",
  Systolic: "Systolic",
  Diastolic: "Diastolic",
  SerumCreatinine: "SerumCreatinine",
  Egfr: "Egfr",
  Glucose: "Glucose",
  UrineAlbumin: "UrineAlbumin",
  UrineCreatinine: "UrineCreatinine",
  Bmi: "Bmi",
  Height: "Height",
  Weight: "Weight",
  Uacr: "Uacr",
  SemiQuantitativeUacr: "SemiQuantitativeUacr",
  SemiQuantitativeUrineAlbumin: "SemiQuantitativeUrineAlbumin",
  SemiQuantitativeUrineCreatinine: "SemiQuantitativeUrineCreatinine",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MeasurementItemUIType = keyof typeof MeasurementItemUIType;

export const MeasurementIconType = {
  CreatinineEgfr: "CreatinineEgfr",
  BloodPressure: "BloodPressure",
  Bmi: "Bmi",
  Weight: "Weight",
  Stale: "Stale",
  Uacr: "Uacr",
  Glucose: "Glucose",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MeasurementIconType = keyof typeof MeasurementIconType;
