import { FloatingPortal, useHover } from "@floating-ui/react";
import classNames from "classnames";
import { TooltipComponent, useGetTooltipProps } from "libs/ui/Tooltip";
import "./SingleBar.scss";
import { NumberStat } from "../../../NumberStat";
import { useReducer } from "react";

type BarType = "primary" | "secondary";

interface SingleBarProps {
  type: BarType;
  maxNumber: number;
  title: string;
  number: number;
  tooltipStats: string | number;
  tooltipKey: string;
}

export function SingleBar({
  type,
  maxNumber,
  title,
  number,
  tooltipKey,
  tooltipStats,
}: SingleBarProps) {
  const [showTooltip, setShowTooltip] = useReducer(prev => !prev, false);
  const getPercentage = () => {
    if (maxNumber === 0 || number === 0) {
      return 1;
    }

    const result = (number / maxNumber) * 100;

    // adding a subtile difference to avoid the bar to be too small
    return result <= 1 ? 1 : result;
  };

  const {
    x,
    y,
    refs,
    strategy,
    arrowRef,
    arrowX,
    arrowY,
    open,
    getReferenceProps,
    getFloatingProps,
  } = useGetTooltipProps({
    interactionProps: [useHover],
    placement: "right-start",
    crossAxis: 0,
    mainAxis: 10,
  });

  return (
    <>
      <div
        className={classNames("SingleBar", `SingleBar--${type}`)}
        style={{ width: `${getPercentage()}%` }}
        ref={refs.setReference}
        {...getReferenceProps()}
        onMouseEnter={setShowTooltip}
        onMouseLeave={setShowTooltip}
      />

      {showTooltip ? (
        <FloatingPortal>
          <TooltipComponent
            type="white"
            ref={refs.setFloating}
            x={x}
            y={y}
            floatingProps={getFloatingProps()}
            open={open}
            strategy={strategy}
            arrowRef={arrowRef}
            arrowX={arrowX}
            arrowY={arrowY}
            arrowSize="small"
            className="SingleBarTooltip"
          >
            <div className="SingleBarTooltip__content">
              <p className="SingleBarTooltip__title">{title}</p>
              <NumberStat transKey={tooltipKey} type={type} value={tooltipStats} />
            </div>
          </TooltipComponent>
        </FloatingPortal>
      ) : null}
    </>
  );
}
