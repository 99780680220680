import * as React from "react";
import { SVGProps } from "react";
const SvgFileMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9 14.25a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5H9Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.055 2.055A2.75 2.75 0 0 1 6 1.25h8a.75.75 0 0 1 .53.22l6 6 .008.008c.131.135.212.319.212.522v12A2.75 2.75 0 0 1 18 22.75H6A2.75 2.75 0 0 1 3.25 20V4c0-.73.29-1.429.805-1.945ZM6 2.75h7.25V8c0 .414.336.75.75.75h5.25V20A1.25 1.25 0 0 1 18 21.25H6A1.25 1.25 0 0 1 4.75 20V4A1.25 1.25 0 0 1 6 2.75Zm8.75 1.06 3.44 3.44h-3.44V3.81Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFileMinus;
