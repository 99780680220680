import { useMemo } from "react";
import { getBMICategory } from "utils/getBMICategory";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import "./Bmi.scss";
import { useTranslation } from "react-i18next";

interface BmiProps {
  bmiValue: number | "" | undefined;
}

export function Bmi({ bmiValue }: BmiProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  const { numberFormatter } = useLocaleNumberFormatter();

  const BMICategory = useMemo(
    () => (bmiValue ? getBMICategory(parseFloat(bmiValue.toString())) : ""),
    [bmiValue],
  );

  if (!bmiValue) {
    return null;
  }

  return (
    <div className="Bmi">
      <p className="Bmi__element">
        {numberFormatter({
          value: bmiValue,
        }).concat(` (${BMICategory})`)}
      </p>
      <label className={"Bmi__label"}>{t("bodyMassIndex")}</label>
    </div>
  );
}
