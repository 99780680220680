import { useTranslation } from "react-i18next";
import { SectionDetailsProps } from "../model";
import { DetailsField } from "../../elements/DetailsField";
import { SideModalElements } from "libs/ui";
import { ColorValue } from "../../elements/ColorValue";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { SQUrineCreatinineBucketType } from "models/TestModels";
import { logger } from "logger";

interface SQUrineCreatinineSectionDetailsProps extends SectionDetailsProps {}

export function SQUrineCreatinineSectionDetails({
  data,
  loading,
}: SQUrineCreatinineSectionDetailsProps) {
  const { t: tDetails } = useTranslation("translation", {
    keyPrefix: "Graph.Details.SQUrineCreatinineSectionDetails",
  });

  if (!data?.measurement.SemiQuantitativeUACR?.SemiQuantitativeUrineCreatinine.Bucket) {
    return null;
  }

  const getBucketValue = (bucket: SQUrineCreatinineBucketType | undefined) => {
    switch (bucket) {
      case SQUrineCreatinineBucketType.UpTo10:
        return "0 - 10";
      case SQUrineCreatinineBucketType.From10To50:
        return "10 - 50";
      case SQUrineCreatinineBucketType.From50To100:
        return "50 - 100";
      case SQUrineCreatinineBucketType.From100To200:
        return "100 - 200";
      case SQUrineCreatinineBucketType.From200To300:
        return "200 - 300";
      default:
        logger.error(`Invalid bucket value: ${bucket}`);
    }
  };

  return (
    <div className="SQUrineCreatinineSectionDetails">
      <SideModalElements.SectionTitle>{tDetails("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={tDetails("labelResult")}>
        <ColorValue colorType="white">
          {getBucketValue(
            data.measurement.SemiQuantitativeUACR.SemiQuantitativeUrineCreatinine.Bucket,
          )?.concat(` ${MEASUREMENT_UNITS.mgdL}`)}
        </ColorValue>
      </DetailsField>
    </div>
  );
}
