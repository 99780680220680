import * as React from "react";
import { SVGProps } from "react";
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.693 11.713a.748.748 0 0 0-.163-.243l-7-7a.75.75 0 1 0-1.06 1.06l5.72 5.72H5a.75.75 0 0 0 0 1.5h12.19l-5.72 5.72a.75.75 0 1 0 1.06 1.06l7-7M19.532 12.529a.748.748 0 0 0 .161-.816"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowRight;
