import { UserGraphModel } from "api/query/models/UserGraphModel";
import { ZoomLevels } from "components/Chart/model";
import * as echarts from "echarts/core";
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo } from "react";

export function useWireUpCarnaLineChartHandlers(
  chartRef: MutableRefObject<echarts.ECharts | undefined | null>,
  onNodeClick: (val: echarts.ECElementEvent) => void,
  onZoomChange: (chartRef: echarts.ECharts, zoomLevel?: ZoomLevels, startZoomDate?: string) => void,
  setCurrentSelectedZoomLevel: Dispatch<SetStateAction<ZoomLevels>>,
  data: UserGraphModel,
) {
  useEffect(() => {
    if (!onNodeClick) {
      return;
    }

    const cTRef = chartRef.current;

    cTRef?.on("click", onNodeClick);

    return () => {
      cTRef?.off("click", onNodeClick);
    };
  }, [chartRef, data, onNodeClick]);

  //#endregion event handlers

  const setZoomLevel = useCallback(
    (zoomLevel?: ZoomLevels, startZoomDate?: string) => {
      if (!zoomLevel || !chartRef.current) {
        return;
      }

      onZoomChange(chartRef.current, zoomLevel, startZoomDate);

      setCurrentSelectedZoomLevel(zoomLevel);
    },
    [chartRef, onZoomChange, setCurrentSelectedZoomLevel],
  );

  // ! This should fire if we reload the data list
  useEffect(() => {
    if (chartRef.current && data.measurements.length > 0) {
      setZoomLevel("month", new Date().toString());
    }
  }, [chartRef, data, setZoomLevel]);

  return useMemo(() => [setZoomLevel] as const, [setZoomLevel]);
}
