import { useMachine } from "@xstate/react";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { mapTableQueryParamsFromURL, useTableURLParams } from "libs/ui/Table/utils";
import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { createSafeContext } from "utils/createSafeContext";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import {
  HCPTableStateContext,
  HCPsFilterOptionsKey,
  HCPs_FILTER_OPTIONS,
  makeTableFetchStateMachine,
} from "../common/HCPTable/model";
import { useFilterModal } from "../common/hooks";
import { getFilterOptionsPerConfiguration } from "../utils";

const Context = createSafeContext<HCPTableStateContext>();

export const useHCPTableStateContext = Context.hook;

export function HCPTableStateProvider({ children }: Readonly<PropsWithChildren>) {
  const [queryParams] = useTableURLParams<ReturnType<typeof HCPs_FILTER_OPTIONS>>();
  const tableMachine = useMemo(
    () =>
      makeTableFetchStateMachine(
        mapTableQueryParamsFromURL<ReturnType<typeof HCPs_FILTER_OPTIONS>>(queryParams),
      ),
    [queryParams],
  );
  const tableService = useMachine(tableMachine);
  const { appConfig } = useGlobalConfigContext();

  const filterSideModelState = useFilterModal<HCPsFilterOptionsKey>(
    getFilterOptionsPerConfiguration(
      HCPs_FILTER_OPTIONS(),
      NotNullOrUndefined(appConfig?.components.tables.Hcp.Index.filterOptions),
    ),
  );

  const [, send] = tableService;

  const fetch = useCallback(() => {
    send({ type: "UPDATE_QUERY", value: mapTableQueryParamsFromURL(queryParams) });
  }, [send, queryParams]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const value = useMemo(
    () => ({
      tableService,
      fetch,
      filterSideModelState,
    }),
    [fetch, filterSideModelState, tableService],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
