import { ConfigFieldModel, ContextsFieldConfig } from "utils/createGlobalConfigStore";
import { IsNullOrWhiteSpace } from "utils/guards";

export function getOptionalFieldConfigForValidationSchema(
  field: ConfigFieldModel<any> | undefined,
  key: keyof ContextsFieldConfig,
  value: any,
) {
  const { visibility } = field?.contexts[key] ?? {};
  /**
   * ! From Perspective of UI
   * ! null, undefined, "" = not set
   * ! Thus we use IsNullOrWhiteSpace
   */
  const fieldHasValue = IsNullOrWhiteSpace(value) === false;

  const mandatoryCheck = (visibility === "Optional" && fieldHasValue) || visibility === "Required";

  const schema = {
    type: "string",
    nullable: mandatoryCheck === false,
    ...(mandatoryCheck ? { minLength: 1, isNotEmpty: true } : undefined),
  };

  return [schema, mandatoryCheck] as const;
}
