/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WithEgfrGroupModel } from './WithEgfrGroupModel';
import {
    WithEgfrGroupModelFromJSON,
    WithEgfrGroupModelFromJSONTyped,
    WithEgfrGroupModelToJSON,
} from './WithEgfrGroupModel';

/**
 * 
 * @export
 * @interface WithEgfrReportModel
 */
export interface WithEgfrReportModel {
    /**
     * 
     * @type {Array<WithEgfrGroupModel>}
     * @memberof WithEgfrReportModel
     */
    groups?: Array<WithEgfrGroupModel> | null;
    /**
     * 
     * @type {number}
     * @memberof WithEgfrReportModel
     */
    totalNumberOfPatients?: number;
    /**
     * 
     * @type {number}
     * @memberof WithEgfrReportModel
     */
    totalNumberOfPatientsWithEgfr?: number;
}

/**
 * Check if a given object implements the WithEgfrReportModel interface.
 */
export function instanceOfWithEgfrReportModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WithEgfrReportModelFromJSON(json: any): WithEgfrReportModel {
    return WithEgfrReportModelFromJSONTyped(json, false);
}

export function WithEgfrReportModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithEgfrReportModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groups': !exists(json, 'Groups') ? undefined : (json['Groups'] === null ? null : (json['Groups'] as Array<any>).map(WithEgfrGroupModelFromJSON)),
        'totalNumberOfPatients': !exists(json, 'TotalNumberOfPatients') ? undefined : json['TotalNumberOfPatients'],
        'totalNumberOfPatientsWithEgfr': !exists(json, 'TotalNumberOfPatientsWithEgfr') ? undefined : json['TotalNumberOfPatientsWithEgfr'],
    };
}

export function WithEgfrReportModelToJSON(value?: WithEgfrReportModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Groups': value.groups === undefined ? undefined : (value.groups === null ? null : (value.groups as Array<any>).map(WithEgfrGroupModelToJSON)),
        'TotalNumberOfPatients': value.totalNumberOfPatients,
        'TotalNumberOfPatientsWithEgfr': value.totalNumberOfPatientsWithEgfr,
    };
}

