import {
  DetailedHTMLProps,
  HTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  CandlestickChartDataType,
  CandlestickChartUnitType,
  GetOptions,
  ZoomLevels,
} from "../model";
import * as echarts from "echarts/core";
import { ECharts } from "echarts/core";
import { useGraphDataAPIContext } from "pages/PatientsPage/Details/Graph/GraphDataAPIContext";
import { isDefaultModel } from "models/loadable";

import { useGraphCandlestickDetails } from "../hooks/useGraphCandlestickDetails";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { CarnaChart } from "./BasicChart";

interface CandlestickChartProps {
  className: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>["className"];
  getOptions: GetOptions;
  unitType: CandlestickChartUnitType;
  afterInit?: (chartRef: MutableRefObject<ECharts | undefined | null>) => void;
  onZoomRange?: (range: [number, number]) => void;
  chartHeaderSlot?: React.ReactElement;
  dataType: CandlestickChartDataType;
  onTimeRangeChangeCb?: (zoomLevel: ZoomLevels | undefined, action: () => void) => void;
  showTrend?: boolean;
  measurementUnit?: MEASUREMENT_UNITS;
}

export function CandlestickChart({
  getOptions,
  unitType,
  afterInit,
  onZoomRange,
  chartHeaderSlot,
  dataType,
  onTimeRangeChangeCb,
  showTrend,
  measurementUnit,
  ...props
}: PropsWithChildren<CandlestickChartProps>) {
  const chartRef = useRef<echarts.ECharts>();

  const { data } = useGraphDataAPIContext();

  const {
    selectedNode,
    closeDetails,
    onNodeClick,
    testData,
    redirectZoomDate,
    setRedirectZoomDate,
  } = useGraphCandlestickDetails(data[dataType].value?.measurements, unitType, chartRef);

  const isNotZoomToDate: boolean = useMemo(() => !redirectZoomDate, [redirectZoomDate]);

  const onRedirectToZoomDate = useCallback(
    (
      setZoomLevel: (
        zoomLevel?: ZoomLevels | undefined,
        startZoomDate?: string | undefined,
      ) => void,
    ) => {
      if (redirectZoomDate) {
        setZoomLevel("month", redirectZoomDate);
        setRedirectZoomDate(undefined);
      }
    },
    [redirectZoomDate, setRedirectZoomDate],
  );

  return (
    <CarnaChart
      showTrend={showTrend}
      data={data[dataType]}
      dataType={dataType}
      getOptions={getOptions}
      afterInit={afterInit}
      onZoomRange={onZoomRange}
      unitType={unitType}
      chartHeaderSlot={chartHeaderSlot}
      selectedNode={selectedNode}
      closeDetails={closeDetails}
      testData={testData}
      chartRef={chartRef}
      showSideDetails={!isDefaultModel(testData) && isNotZoomToDate}
      onNodeClick={onNodeClick}
      className={props.className}
      redirectCb={onRedirectToZoomDate}
      onTimeRangeChangeCb={onTimeRangeChangeCb}
      measurementUnit={measurementUnit}
    />
  );
}

export default CandlestickChart;
