import { UserModel } from "api/query";
import { TableElements } from "libs/ui";
import { TableUtils } from "libs/ui/Table/utils";
import { useTranslation } from "react-i18next";
import { useHasAccess } from "utils/hooks/useHasAccess";

interface TestedByCell {
  testedBy?: UserModel | null;
  loading: boolean;
  avatarImg?: string;
  avatarLoading?: boolean;
  show?: boolean;
}

export const TestedBy = ({
  loading,
  avatarLoading = true,
  avatarImg,
  show = true,
  testedBy,
}: TestedByCell) => {
  const { t } = useTranslation("translation", { keyPrefix: "TestsTable" });
  const tableRedirection = TableUtils.useTableElementsRedirect();
  const can = useHasAccess();

  if (!show) {
    return null;
  }

  return testedBy?.roleType === "Hcp" ? (
    <TableElements.AvatarCell
      loading={loading}
      show={show}
      src={avatarImg}
      avatarLoading={avatarLoading}
      firstName={testedBy.firstName}
      label={testedBy.firstName.concat(` ${testedBy.lastName}`)}
      avatarType={"Hcp"}
      {...(can("view-hcp-details") && {
        onCellClick: e =>
          tableRedirection({
            event: e,
            path: "hcpDetails",
            params: {
              hcpId: testedBy.id,
              organizationId: testedBy.organizationId,
            },
          }),
      })}
    >
      {t("na")}
    </TableElements.AvatarCell>
  ) : (
    <TableElements.Cell loading={loading}>
      {testedBy ? t("selfTested") : t("na")}
    </TableElements.Cell>
  );
};
