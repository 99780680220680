import { useHover } from "@floating-ui/react";
import { Icon, useTooltip } from "libs/ui";
import { useTranslation } from "react-i18next";
import { LeftSideReportTypes } from "../../DashboardMapReportDataContext.model";
import { NumberStat } from "../NumberStat";
import { TotalNumberCandle } from "./TotalNumberCandle";
import "./TotalNumbers.scss";

interface TotalNumbersProps {
  totalPatients: number;
  patientWithRisk: number;
  type: LeftSideReportTypes;
}

export function TotalNumbers({ totalPatients, patientWithRisk, type }: TotalNumbersProps) {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.TotalNumbers" });
  const [ref, getReferenceProps, Tooltip] = useTooltip({
    interactionProps: [useHover],
    placement: "top-start",
  });

  const getPatientWithRiskValue = () => {
    if (totalPatients === 0 || Number.isNaN(totalPatients) || Number.isNaN(patientWithRisk)) {
      return t("NA");
    }

    return patientWithRisk;
  };

  return (
    <div className="TotalNumbers">
      <p className="TotalNumbers__title">{t("title")}</p>

      <TotalNumberCandle totalPatients={totalPatients} patientWithRisk={patientWithRisk} />

      <div className="TotalNumberStats">
        <div className="TotalNumbers__stat">
          {/* !! INFO: if 0 or null or undefined, it will be replaced with N/A */}
          <NumberStat transKey={"totalPatients"} type="primary" value={totalPatients || t("NA")} />
        </div>

        <div className="TotalNumbers__stat">
          <NumberStat transKey={type} type="secondary" value={getPatientWithRiskValue()}>
            <Icon ref={ref} {...getReferenceProps()} icon={"Info"} scaleTo={14} />
            <Tooltip>
              <div className="TotalNumbers__tooltip">{t(`${type}Tooltip`)}</div>
            </Tooltip>
          </NumberStat>
        </div>
      </div>
    </div>
  );
}
