import { CarnaApiQuery } from "config/apiQuery";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { createSafeContext } from "utils/createSafeContext";
import { getCurrentUserData } from "utils/getCurrentUserData";

const Context = createSafeContext<UserAttributes>();

export const useAdditionalAuthInformationContext = Context.hook;

export interface UserAttributes extends Awaited<ReturnType<typeof getCurrentUserData>> {
  firstName: string;
  lastName: string;
  setUserAttributes?: React.Dispatch<React.SetStateAction<UserAttributes | undefined>>;
}

export function AdditionalAuthInformationProvider({ children }: PropsWithChildren) {
  const [userAttributes, setUserAttributes] = useState<UserAttributes>();

  useEffect(() => {
    (async () => {
      const result = await getCurrentUserData();

      const apiResult = await (result.isPartner
        ? CarnaApiQuery.Partners.getById({
            organizationId: result.organizationId,
            id: result.currentUserId,
          })
        : result.isHcp
          ? CarnaApiQuery.Hcps.getById({
              organizationId: result.organizationId,
              hcpEntityId: result.currentUserId,
            })
          : result.isPatient
            ? CarnaApiQuery.Patients.getById({
                organizationId: result.organizationId,
                patientEntityId: result.currentUserId,
              })
            : CarnaApiQuery.Users.getById({ id: result.currentUserId }));

      setUserAttributes({
        ...result,
        firstName: apiResult?.firstName ?? "",
        lastName: apiResult?.lastName ?? "",
      });
    })();
  }, []);

  const value = useMemo(
    () => ({ ...userAttributes, setUserAttributes }) as UserAttributes,
    [userAttributes],
  );

  if (!userAttributes) {
    return null;
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
