import { Button, Checkbox, Icon, SideModal, SideModalElements } from "libs/ui";
import { useCallback, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import "./MapFilters.scss";
import {
  FilterType,
  FilterTypeValues,
  MapFiltersState,
  useDashboardMapContext,
} from "../DashboardMapContext";
import { MapFilterChips } from "./MapFilterChips";
import { isEqual } from "lodash-es";
import { AGE_OPTIONS, ANCESTRY_OPTIONS, GENDER_OPTIONS } from "./helper";
import { useDashboardMapReportContext } from "../DashboardMapReportDataContext";
import { DropDownToggler } from "libs/ui/DropDownToggler";

const CLEAR_ALL_STATE: MapFiltersState = {
  onlyGenders: [],
  onlyAgeRanges: [],
  onlyAncestries: [],
};

interface Action {
  type: FilterType | "clearAll";
  payload?: FilterTypeValues[];
}

function reducer(state: MapFiltersState, action: Action): MapFiltersState {
  if (action.type === "clearAll") {
    return CLEAR_ALL_STATE;
  }

  if (Object.keys(FilterType).every(el => el !== action.type)) {
    throw new Error(`${action.type} not covered`);
  }

  return {
    ...state,
    [action.type]: action.payload,
  };
}

export function MapFilters() {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.DashboardMapFilter" });
  const [showFilter, setShowFilter] = useState(false);
  const { filters: initFiltersState, dispatchFilters } = useDashboardMapContext();
  const { getReportModelPerFilters, selectedReportType } = useDashboardMapReportContext();
  const [filtersState, dispatchLocalState] = useReducer(reducer, initFiltersState);

  const resetToLatestSavedState = useCallback(() => {
    Object.entries(initFiltersState).forEach(([type, values]) => {
      dispatchLocalState({
        type: type as FilterType,
        payload: values,
      });
    });
  }, [initFiltersState]);

  const onOpenFilters = useCallback(() => {
    if (!isEqual(initFiltersState, filtersState)) {
      resetToLatestSavedState();
    }
    setShowFilter(true);
  }, [filtersState, initFiltersState, resetToLatestSavedState]);

  const onCancel = useCallback(() => {
    resetToLatestSavedState();
    setShowFilter(false);
  }, [resetToLatestSavedState]);

  const onApply = useCallback(() => {
    Object.entries(filtersState).forEach(([type, values]) => {
      dispatchFilters({
        type: type as FilterType,
        payload: values as FilterTypeValues[],
      });
    });
    setShowFilter(false);
    getReportModelPerFilters({ ...filtersState, reportType: selectedReportType });
  }, [dispatchFilters, filtersState, getReportModelPerFilters, selectedReportType]);

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const checkedValue = value as FilterTypeValues;
    const filterType = name as FilterType;

    const currentValues = new Set<FilterTypeValues>(filtersState[filterType]);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    currentValues.has(checkedValue)
      ? currentValues.delete(checkedValue)
      : currentValues.add(checkedValue);

    dispatchLocalState({ type: filterType, payload: [...currentValues.values()] });
  };

  const filtersCount = Object.values(initFiltersState).reduce((acc, el) => acc + el.length, 0);

  return (
    <>
      <MapFilterChips />

      <Button
        onClick={onOpenFilters}
        buttonSize="medium"
        buttonType="white"
        buttonIcon={{ icon: "Filter" }}
        className="MapFiltersButton"
      >
        {filtersCount > 0 ? <span className="MapsFiltersButtonCount">{filtersCount}</span> : null}
      </Button>

      <SideModal className="MapFilters" bodyClass="MapFilters__body" show={showFilter}>
        <div className="MapFilters__head">
          <SideModalElements.Header closeModal={{ onClick: onCancel }}>
            <Icon icon="Filter" />
            {t("title")}
          </SideModalElements.Header>

          <SideModalElements.Separator />
        </div>

        <div className="MapFilters__main">
          <DropDownToggler
            isExpandedOnInit={filtersState.onlyGenders.some(el =>
              GENDER_OPTIONS.some(option => option.value === el),
            )}
            title={t("titleGender")}
            expandChildrenCount={5}
          >
            {GENDER_OPTIONS.map(option => (
              <div key={option.value}>
                <Checkbox
                  name={FilterType.onlyGenders}
                  label={option.title}
                  checked={filtersState.onlyGenders.some(el => el === option.value)}
                  value={option.value}
                  onChange={onFilterChange}
                />
              </div>
            ))}
          </DropDownToggler>

          <SideModalElements.Separator />

          <DropDownToggler
            isExpandedOnInit={filtersState.onlyAgeRanges.some(el =>
              AGE_OPTIONS.some(option => option.value === el),
            )}
            title={t("titleAge")}
            expandChildrenCount={5}
          >
            {AGE_OPTIONS.map(option => (
              <div key={option.value}>
                <Checkbox
                  name={FilterType.onlyAgeRanges}
                  label={option.title}
                  checked={filtersState.onlyAgeRanges.some(el => el === option.value)}
                  value={option.value}
                  onChange={onFilterChange}
                />
              </div>
            ))}
          </DropDownToggler>

          <SideModalElements.Separator />

          <DropDownToggler
            isExpandedOnInit={filtersState.onlyAncestries.some(el =>
              ANCESTRY_OPTIONS.some(option => option.value === el),
            )}
            title={t("titleAncestry")}
            expandChildrenCount={5}
          >
            {ANCESTRY_OPTIONS.map(option => (
              <div key={option.value}>
                <Checkbox
                  name={FilterType.onlyAncestries}
                  label={option.title}
                  checked={filtersState.onlyAncestries.some(el => el === option.value)}
                  value={option.value}
                  onChange={onFilterChange}
                />
              </div>
            ))}
          </DropDownToggler>
        </div>

        <SideModalElements.Footer>
          <Button onClick={onCancel} className="CancelButton" buttonType="grey" buttonSize="medium">
            {t("buttonCancel")}
          </Button>

          <Button onClick={onApply} buttonType="primary" buttonSize="medium" type="submit">
            {t("buttonApply")}
          </Button>

          <Button
            buttonSize="medium"
            buttonType="link-primary"
            className="FilterSideModal__ClearAllButton"
            onClick={() => dispatchLocalState({ type: "clearAll" })}
          >
            {t("buttonClearAll")}
          </Button>
        </SideModalElements.Footer>
      </SideModal>
    </>
  );
}
