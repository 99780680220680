import "./SelectFilterResult.scss";

import classNames from "classnames";
import { PropsWithChildren } from "react";

import { Icon, IconType } from "../Icon";
import { DataAttributeKey } from "../models";

export interface SelectFilterRenderSelectedProps {
  hasValue: boolean;
  className?: string;
  label?: string;
  [dataAttribute: DataAttributeKey]: any;
  leadingIcon?: IconType;
}

export function SelectFilterResult({
  className,
  hasValue,
  label,
  children,
  leadingIcon,
  ...rest
}: PropsWithChildren<SelectFilterRenderSelectedProps>) {
  return (
    <div
      data-testid="SelectFilterResult"
      className={classNames("SelectFilterResult", {
        "SelectFilterResult--hasValue": hasValue,
        className,
      })}
      {...rest}
    >
      <div data-testid="SelectFilterResult__input" className={"SelectFilterResult__input"}>
        {leadingIcon ? (
          <Icon className={classNames("SelectFilterResult__leadingIcon")} icon={leadingIcon} />
        ) : null}
        <Icon className={classNames("Icon--input Icon--trailing")} icon="ChevronDown" />
        {!hasValue ? null : children}
        {label ? (
          <label
            data-testid="SelectFilterResult__label"
            className={classNames("SelectFilterResult__label", {
              "SelectFilterResult__label--active": hasValue,
              "SelectFilterResult__label--leadingIcon": leadingIcon,
            })}
          >
            <span className="SelectFilterResult__label-text">{label}</span>
          </label>
        ) : null}
      </div>
    </div>
  );
}
