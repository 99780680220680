import { SVGProps } from "react";

export const MapInputIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="42" height="65" viewBox="0 0 42 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.2382 46.7216C41.2702 46.8781 41.2666 47.0399 41.2276 47.1948C41.1886 47.3498 41.1152 47.494 41.0129 47.6167C40.9106 47.7395 40.782 47.8377 40.6366 47.904C40.4913 47.9703 40.3328 48.003 40.1731 47.9998H1.82649C1.66674 48.003 1.5083 47.9703 1.36291 47.904C1.21753 47.8377 1.08893 47.7395 0.986633 47.6167C0.884337 47.494 0.81097 47.3498 0.771966 47.1948C0.732962 47.0399 0.729321 46.8781 0.761312 46.7216L5.02204 25.4179C5.07184 25.1737 5.2057 24.9547 5.40033 24.799C5.59496 24.6433 5.83803 24.5607 6.08722 24.5658H35.9123C36.1615 24.5607 36.4046 24.6433 36.5992 24.799C36.7939 24.9547 36.9277 25.1737 36.9775 25.4179L41.2382 46.7216Z"
      fill="url(#paint0_linear_28391_23468)"
    />
    <path
      d="M20.9117 0C13.8086 0 8.02979 5.76762 8.02979 12.857C8.02979 17.2424 10.1142 21.9507 14.2252 26.8509C17.2446 30.4501 20.2229 32.7649 20.3483 32.8618C20.5142 32.9899 20.7129 33.0539 20.9118 33.0539C21.1106 33.0539 21.3095 32.9899 21.4754 32.8618C21.6007 32.7649 24.5791 30.4501 27.5985 26.8509C31.7095 21.9507 33.7939 17.2425 33.7939 12.857C33.7936 5.76762 28.0148 0 20.9117 0ZM25.6824 12.8569C25.6824 15.4864 23.5464 17.6183 20.9117 17.6183C18.277 17.6183 16.141 15.4866 16.141 12.8569C16.141 10.2272 18.277 8.09545 20.9117 8.09545C23.5464 8.09545 25.6824 10.2273 25.6824 12.8569Z"
      fill="url(#paint1_linear_28391_23468)"
    />
    <path
      d="M21 21.47C25.7062 21.47 29.5214 17.6549 29.5214 12.9486C29.5214 8.24231 25.7062 4.42712 21 4.42712C16.2937 4.42712 12.4785 8.24231 12.4785 12.9486C12.4785 17.6549 16.2937 21.47 21 21.47Z"
      fill="url(#paint2_linear_28391_23468)"
    />
    <path
      d="M29.8193 38.0296L37.4887 47.9997H29.4252L24.4615 41.5447L14.6405 47.9997H2.99805L36.9987 25.6522L38.3515 32.4161L29.8193 38.0296Z"
      fill="#B4CDE1"
    />
    <path
      d="M10.3479 33.0873H8.21753C7.93502 33.0873 7.66409 32.9751 7.46433 32.7753C7.26457 32.5755 7.15234 32.3046 7.15234 32.0221C7.15234 31.7396 7.26457 31.4687 7.46433 31.2689C7.66409 31.0691 7.93502 30.9569 8.21753 30.9569H10.3479C10.6304 30.9569 10.9013 31.0691 11.1011 31.2689C11.3008 31.4687 11.4131 31.7396 11.4131 32.0221C11.4131 32.3046 11.3008 32.5755 11.1011 32.7753C10.9013 32.9751 10.6304 33.0873 10.3479 33.0873Z"
      fill="#B4CDE1"
    />
    <path
      d="M11.4133 28.8265H9.28296C9.00045 28.8265 8.72952 28.7143 8.52976 28.5145C8.33 28.3148 8.21777 28.0439 8.21777 27.7613C8.21777 27.4788 8.33 27.2079 8.52976 27.0082C8.72952 26.8084 9.00045 26.6962 9.28296 26.6962H11.4133C11.6958 26.6962 11.9668 26.8084 12.1665 27.0082C12.3663 27.2079 12.4785 27.4788 12.4785 27.7613C12.4785 28.0439 12.3663 28.3148 12.1665 28.5145C11.9668 28.7143 11.6958 28.8265 11.4133 28.8265Z"
      fill="#B4CDE1"
    />
    <defs>
      <linearGradient
        id="paint0_linear_28391_23468"
        x1="20.9998"
        y1="47.9998"
        x2="20.9998"
        y2="24.5658"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D3E6F5" />
        <stop offset="1" stopColor="#F0F7FC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_28391_23468"
        x1="20.9118"
        y1="0"
        x2="20.9118"
        y2="33.0539"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" />
        <stop offset="1" stopColor="#5331A8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_28391_23468"
        x1="21"
        y1="4.42712"
        x2="21"
        y2="21.47"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F0F7FC" />
      </linearGradient>
    </defs>
  </svg>
);
