import { UserGraphModel } from "api/query";
import { ECOption, Formatters } from "components/Chart/model";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { getSingleAreaOptions } from "../model";
import { getColorValue } from "utils";

interface GetOptionsProps {
  formatters: Formatters;
  data: UserGraphModel;
  measurementUnit: MEASUREMENT_UNITS;
  maxRange?: number;
  showTrending?: boolean;
}

export const getOptions = ({
  formatters,
  data,
  measurementUnit,
  maxRange = 1,
  showTrending,
}: GetOptionsProps): ECOption =>
  getSingleAreaOptions({
    formatters,
    data,
    yAxis: { max: maxRange },
    unitType: "height",
    measurementUnit,
    areaStyleColorStops: [
      {
        offset: 0,
        color: getColorValue("--grey-gradient-75"),
      },
      {
        offset: 1,
        color: getColorValue("--grey-gradient-0"),
      },
    ],
    showTrending,
  });
