/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AncestryType } from './AncestryType';
import {
    AncestryTypeFromJSON,
    AncestryTypeFromJSONTyped,
    AncestryTypeToJSON,
} from './AncestryType';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import type { GenderType } from './GenderType';
import {
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import type { NationalityType } from './NationalityType';
import {
    NationalityTypeFromJSON,
    NationalityTypeFromJSONTyped,
    NationalityTypeToJSON,
} from './NationalityType';

/**
 * 
 * @export
 * @interface UpdatePatientRequestModel
 */
export interface UpdatePatientRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    city?: string | null;
    /**
     * 
     * @type {CountryType}
     * @memberof UpdatePatientRequestModel
     */
    country?: CountryType;
    /**
     * 
     * @type {number}
     * @memberof UpdatePatientRequestModel
     */
    state?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    phone?: string | null;
    /**
     * 
     * @type {GenderType}
     * @memberof UpdatePatientRequestModel
     */
    gender?: GenderType;
    /**
     * 
     * @type {AncestryType}
     * @memberof UpdatePatientRequestModel
     */
    ancestry?: AncestryType;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    registryNumber?: string | null;
    /**
     * 
     * @type {NationalityType}
     * @memberof UpdatePatientRequestModel
     */
    nationalityType?: NationalityType;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    hcpId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    deviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    oldDeviceRowVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    newDeviceRowVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    rowVersion: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    generalPractitionerId?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdatePatientRequestModel
     */
    additionalProperties?: { [key: string]: string; } | null;
}

/**
 * Check if a given object implements the UpdatePatientRequestModel interface.
 */
export function instanceOfUpdatePatientRequestModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rowVersion" in value;

    return isInstance;
}

export function UpdatePatientRequestModelFromJSON(json: any): UpdatePatientRequestModel {
    return UpdatePatientRequestModelFromJSONTyped(json, false);
}

export function UpdatePatientRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePatientRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'FirstName') ? undefined : json['FirstName'],
        'lastName': !exists(json, 'LastName') ? undefined : json['LastName'],
        'street': !exists(json, 'Street') ? undefined : json['Street'],
        'city': !exists(json, 'City') ? undefined : json['City'],
        'country': !exists(json, 'Country') ? undefined : CountryTypeFromJSON(json['Country']),
        'state': !exists(json, 'State') ? undefined : json['State'],
        'zipCode': !exists(json, 'ZipCode') ? undefined : json['ZipCode'],
        'phone': !exists(json, 'Phone') ? undefined : json['Phone'],
        'gender': !exists(json, 'Gender') ? undefined : GenderTypeFromJSON(json['Gender']),
        'ancestry': !exists(json, 'Ancestry') ? undefined : AncestryTypeFromJSON(json['Ancestry']),
        'dateOfBirth': !exists(json, 'DateOfBirth') ? undefined : json['DateOfBirth'],
        'registryNumber': !exists(json, 'RegistryNumber') ? undefined : json['RegistryNumber'],
        'nationalityType': !exists(json, 'NationalityType') ? undefined : NationalityTypeFromJSON(json['NationalityType']),
        'hcpId': !exists(json, 'HcpId') ? undefined : json['HcpId'],
        'deviceId': !exists(json, 'DeviceId') ? undefined : json['DeviceId'],
        'oldDeviceRowVersion': !exists(json, 'OldDeviceRowVersion') ? undefined : json['OldDeviceRowVersion'],
        'newDeviceRowVersion': !exists(json, 'NewDeviceRowVersion') ? undefined : json['NewDeviceRowVersion'],
        'rowVersion': json['RowVersion'],
        'generalPractitionerId': !exists(json, 'GeneralPractitionerId') ? undefined : json['GeneralPractitionerId'],
        'additionalProperties': !exists(json, 'AdditionalProperties') ? undefined : json['AdditionalProperties'],
    };
}

export function UpdatePatientRequestModelToJSON(value?: UpdatePatientRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': value.state,
        'ZipCode': value.zipCode,
        'Phone': value.phone,
        'Gender': GenderTypeToJSON(value.gender),
        'Ancestry': AncestryTypeToJSON(value.ancestry),
        'DateOfBirth': value.dateOfBirth,
        'RegistryNumber': value.registryNumber,
        'NationalityType': NationalityTypeToJSON(value.nationalityType),
        'HcpId': value.hcpId,
        'DeviceId': value.deviceId,
        'OldDeviceRowVersion': value.oldDeviceRowVersion,
        'NewDeviceRowVersion': value.newDeviceRowVersion,
        'RowVersion': value.rowVersion,
        'GeneralPractitionerId': value.generalPractitionerId,
        'AdditionalProperties': value.additionalProperties,
    };
}

