import * as React from "react";
import { SVGProps } from "react";
const SvgAlertTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 8.25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75ZM12 16a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.65 2.501a2.75 2.75 0 0 1 3.701.97l.002.004 8.477 14.15a2.75 2.75 0 0 1-2.352 4.125H3.522a2.75 2.75 0 0 1-2.351-4.125l.006-.01 8.47-14.14.643.385-.641-.389a2.75 2.75 0 0 1 1.002-.97Zm.282 1.746v.002l-8.465 14.13a1.25 1.25 0 0 0 1.068 1.871h16.93a1.25 1.25 0 0 0 1.068-1.87L13.07 4.249l-.001-.002a1.25 1.25 0 0 0-2.136 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAlertTriangle;
