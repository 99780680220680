import isArray from "lodash-es/isArray";
import isObject from "lodash-es/isObject";
import transform from "lodash-es/transform";

export function lowerCaseFirstLetterRecursive(obj: Record<string, any>): Record<string, any> {
  return transform(obj, (result, value, key) => {
    const lowerCasedKey = key.charAt(0).toLowerCase() + key.slice(1);

    if (isArray(value)) {
      result[lowerCasedKey] = value.map((item: any) =>
        isObject(item) ? lowerCaseFirstLetterRecursive(item) : item,
      );
    } else {
      result[lowerCasedKey] = isObject(value) ? lowerCaseFirstLetterRecursive(value) : value;
    }
  });
}
