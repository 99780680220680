import { Layout, Layouts } from "react-grid-layout";

const PATIENT_INFO_WIDGET_BASE_LAYOUT: Layout = {
  i: "PatientInfo",
  minW: 2,
  maxW: 3,
  minH: 2,
  maxH: 4,
  w: 3,
  h: 4,
  x: 0,
  y: 0,
};

const HEAT_MAP_WIDGET_BASE_LAYOUT: Layout = {
  i: "HeatMap",
  minW: 3,
  maxW: 9,
  minH: 4,
  maxH: 4,
  w: 7,
  h: 4,
  x: 3,
  y: 0,
};

const MEASUREMENT_WIDGET_BASE_LAYOUT: Layout = {
  i: "MeasurementWidget",
  minW: 2,
  maxW: 3,
  maxH: 1,
  w: 3,
  h: 1,
  x: 1,
  y: 1,
};

const LAYOUT_12: Layout[] = [
  {
    ...PATIENT_INFO_WIDGET_BASE_LAYOUT,
    w: 3,
    h: 4,
    x: 0,
    y: 0,
  },
  {
    ...HEAT_MAP_WIDGET_BASE_LAYOUT,
    w: 8,
    h: 5,
    x: 4,
    y: 0,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "SerumCreatinine",
    w: 2,
    h: 1,
    x: 0,
    y: 5,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "Egfr",
    w: 2,
    h: 1,
    x: 2,
    y: 5,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "Uacr",
    w: 2,
    h: 1,
    x: 8,
    y: 5,
  },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineAlbumin", w: 2, h: 1, x: 10, y: 5 },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "UrineCreatinine",
    w: 2,
    h: 1,
    x: 4,
    y: 5,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "SemiQuantitativeUacr",
    w: 2,
    h: 1,
    x: 6,
    y: 5,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "BloodPressure",
    w: 2,
    h: 1,
    x: 0,
    y: 6,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "Glucose",
    w: 2,
    h: 1,
    x: 2,
    y: 6,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "Weight",
    w: 2,
    h: 1,
    x: 4,
    y: 6,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "Bmi",
    w: 2,
    h: 1,
    x: 6,
    y: 6,
  },
];

const LAYOUT_11: Layout[] = [
  {
    ...PATIENT_INFO_WIDGET_BASE_LAYOUT,
    w: 3,
    h: 4,
    x: 0,
    y: 0,
  },
  {
    ...HEAT_MAP_WIDGET_BASE_LAYOUT,
    w: 8,
    h: 4,
    x: 3,
    y: 0,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "SerumCreatinine",
    w: 2,
    h: 1,
    x: 0,
    y: 4,
  },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Egfr", w: 2, h: 1, x: 2, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Uacr", w: 2, h: 1, x: 4, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "SemiQuantitativeUacr", w: 2, h: 1, x: 6, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineAlbumin", w: 2, h: 1, x: 0, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineCreatinine", w: 2, h: 1, x: 8, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "BloodPressure", w: 2, h: 1, x: 2, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Glucose", w: 2, h: 1, x: 4, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Weight", w: 2, h: 1, x: 6, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Bmi", w: 2, h: 1, x: 8, y: 5 },
];

const LAYOUT_10: Layout[] = [
  {
    ...PATIENT_INFO_WIDGET_BASE_LAYOUT,
    w: 3,
    h: 4,
    x: 0,
    y: 0,
  },
  {
    ...HEAT_MAP_WIDGET_BASE_LAYOUT,
    w: 7,
    h: 4,
    x: 3,
    y: 0,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "SerumCreatinine",
    w: 2,
    h: 1,
    x: 0,
    y: 4,
  },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Egfr", w: 2, h: 1, x: 2, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Uacr", w: 2, h: 1, x: 4, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineAlbumin", w: 2, h: 1, x: 0, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineCreatinine", w: 2, h: 1, x: 8, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "SemiQuantitativeUacr", w: 2, h: 1, x: 6, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "BloodPressure", w: 2, h: 1, x: 2, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Glucose", w: 2, h: 1, x: 4, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Weight", w: 2, h: 1, x: 6, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Bmi", w: 2, h: 1, x: 8, y: 5 },
];

const LAYOUT_9: Layout[] = [
  {
    ...PATIENT_INFO_WIDGET_BASE_LAYOUT,
    w: 3,
    h: 4,
    x: 0,
    y: 0,
  },
  {
    ...HEAT_MAP_WIDGET_BASE_LAYOUT,
    w: 6,
    h: 4,
    x: 3,
    y: 0,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "SerumCreatinine",
    w: 2,
    h: 1,
    x: 0,
    y: 4,
  },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Egfr", w: 2, h: 1, x: 2, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Uacr", w: 2, h: 1, x: 4, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineAlbumin", w: 2, h: 1, x: 0, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineCreatinine", w: 2, h: 1, x: 2, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "SemiQuantitativeUacr", w: 2, h: 1, x: 6, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "BloodPressure", w: 2, h: 1, x: 4, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Glucose", w: 2, h: 1, x: 6, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Weight", w: 2, h: 1, x: 0, y: 6 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Bmi", w: 2, h: 1, x: 2, y: 6 },
];

const LAYOUT_8: Layout[] = [
  {
    ...PATIENT_INFO_WIDGET_BASE_LAYOUT,
    w: 3,
    h: 4,
    x: 0,
    y: 0,
  },
  {
    ...HEAT_MAP_WIDGET_BASE_LAYOUT,
    w: 5,
    h: 4,
    x: 3,
    y: 0,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "SerumCreatinine",
    w: 2,
    h: 1,
    x: 0,
    y: 4,
  },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Egfr", w: 2, h: 1, x: 2, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Uacr", w: 2, h: 1, x: 4, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineAlbumin", w: 2, h: 1, x: 2, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineCreatinine", w: 2, h: 1, x: 0, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "SemiQuantitativeUacr", w: 2, h: 1, x: 6, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "BloodPressure", w: 2, h: 1, x: 4, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Glucose", w: 2, h: 1, x: 6, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Weight", w: 2, h: 1, x: 0, y: 6 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Bmi", w: 2, h: 1, x: 2, y: 6 },
];

const LAYOUT_7: Layout[] = [
  {
    ...PATIENT_INFO_WIDGET_BASE_LAYOUT,
    w: 3,
    h: 2,
    x: 0,
    y: 0,
  },
  {
    ...HEAT_MAP_WIDGET_BASE_LAYOUT,
    w: 4,
    h: 4,
    x: 3,
    y: 0,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "SerumCreatinine",
    w: 3,
    h: 1,
    x: 0,
    y: 2,
  },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Egfr", w: 3, h: 1, x: 0, y: 3 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Uacr", w: 2, h: 1, x: 0, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineAlbumin", w: 2, h: 1, x: 0, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineCreatinine", w: 2, h: 1, x: 4, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "SemiQuantitativeUacr", w: 2, h: 1, x: 2, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "BloodPressure", w: 2, h: 1, x: 2, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Glucose", w: 2, h: 1, x: 4, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Weight", w: 2, h: 1, x: 0, y: 6 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Bmi", w: 2, h: 1, x: 2, y: 6 },
];

const LAYOUT_6: Layout[] = [
  {
    ...PATIENT_INFO_WIDGET_BASE_LAYOUT,
    w: 2,
    h: 2,
    x: 0,
    y: 0,
  },
  {
    ...HEAT_MAP_WIDGET_BASE_LAYOUT,
    w: 4,
    h: 4,
    x: 2,
    y: 0,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "SerumCreatinine",
    w: 2,
    h: 1,
    x: 0,
    y: 2,
  },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Egfr", w: 2, h: 1, x: 0, y: 3 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Uacr", w: 2, h: 1, x: 0, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineAlbumin", w: 2, h: 1, x: 0, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineCreatinine", w: 2, h: 1, x: 4, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "SemiQuantitativeUacr", w: 2, h: 1, x: 2, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "BloodPressure", w: 2, h: 1, x: 2, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Glucose", w: 2, h: 1, x: 4, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Weight", w: 2, h: 1, x: 0, y: 6 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Bmi", w: 2, h: 1, x: 2, y: 6 },
];

const LAYOUT_5: Layout[] = [
  {
    ...PATIENT_INFO_WIDGET_BASE_LAYOUT,
    w: 2,
    h: 2,
    x: 0,
    y: 0,
  },
  {
    ...HEAT_MAP_WIDGET_BASE_LAYOUT,
    w: 3,
    h: 4,
    x: 2,
    y: 0,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "SerumCreatinine",
    w: 2,
    h: 1,
    x: 0,
    y: 2,
  },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Egfr", w: 2, h: 1, x: 0, y: 3 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Uacr", w: 2, h: 1, x: 0, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineAlbumin", w: 2, h: 1, x: 2, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineCreatinine", w: 2, h: 1, x: 0, y: 5 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "SemiQuantitativeUacr", w: 2, h: 1, x: 2, y: 4 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "BloodPressure", w: 2, h: 1, x: 0, y: 6 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Glucose", w: 2, h: 1, x: 2, y: 6 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Weight", w: 2, h: 1, x: 0, y: 7 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Bmi", w: 2, h: 1, x: 2, y: 7 },
];

const LAYOUT_4: Layout[] = [
  {
    ...PATIENT_INFO_WIDGET_BASE_LAYOUT,
    w: 2,
    h: 2,
    x: 0,
    y: 4,
  },
  {
    ...HEAT_MAP_WIDGET_BASE_LAYOUT,
    w: 4,
    h: 4,
    x: 0,
    y: 0,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "SerumCreatinine",
    w: 2,
    h: 1,
    x: 2,
    y: 4,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "Egfr",
    w: 2,
    h: 1,
    x: 2,
    y: 5,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "Uacr",
    w: 2,
    h: 1,
    x: 2,
    y: 6,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "UrineAlbumin",
    w: 2,
    h: 1,
    x: 2,
    y: 7,
  },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "UrineCreatinine", w: 2, h: 1, x: 0, y: 7 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "SemiQuantitativeUacr", w: 2, h: 1, x: 0, y: 6 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "BloodPressure", w: 2, h: 1, x: 0, y: 8 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Glucose", w: 2, h: 1, x: 2, y: 8 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Weight", w: 2, h: 1, x: 0, y: 9 },
  { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i: "Bmi", w: 2, h: 1, x: 2, y: 9 },
];

const LAYOUT_3: Layout[] = [
  {
    ...PATIENT_INFO_WIDGET_BASE_LAYOUT,
    w: 3,
    h: 2,
    x: 0,
    y: 4,
  },
  {
    ...HEAT_MAP_WIDGET_BASE_LAYOUT,
    w: 3,
    h: 4,
    x: 0,
    y: 0,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "SerumCreatinine",
    w: 3,
    h: 1,
    x: 0,
    y: 6,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "Egfr",
    w: 3,
    h: 1,
    x: 0,
    y: 7,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "Uacr",
    w: 3,
    h: 1,
    x: 0,
    y: 8,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "UrineAlbumin",
    w: 3,
    h: 1,
    x: 0,
    y: 11,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "UrineCreatinine",
    w: 3,
    h: 1,
    x: 0,
    y: 10,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "SemiQuantitativeUacr",
    w: 3,
    h: 1,
    x: 0,
    y: 9,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "BloodPressure",
    w: 3,
    h: 1,
    x: 0,
    y: 12,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "Glucose",
    w: 3,
    h: 1,
    x: 0,
    y: 13,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "Weight",
    w: 3,
    h: 1,
    x: 0,
    y: 14,
  },
  {
    ...MEASUREMENT_WIDGET_BASE_LAYOUT,
    i: "Bmi",
    w: 3,
    h: 1,
    x: 0,
    y: 15,
  },
];

export const INIT_LAYOUTS: Layouts = {
  "col-12": LAYOUT_12,
  "col-11": LAYOUT_11,
  "col-10": LAYOUT_10,
  "col-9": LAYOUT_9,
  "col-8": LAYOUT_8,
  "col-7": LAYOUT_7,
  "col-6": LAYOUT_6,
  "col-5": LAYOUT_5,
  "col-4": LAYOUT_4,
  "col-3": LAYOUT_3,
};
