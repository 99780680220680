import { RegionType } from "api/query/models";

export function getNumberSeparators(region?: RegionType) {
  const thousandSeparator = Intl.NumberFormat(region).format(11111).replace(/[0-9]/g, "");
  const decimalSeparator = Intl.NumberFormat(region).format(1.1).replace(/[0-9]/g, "");

  return { thousandSeparator, decimalSeparator } as const;
}
export function parseLocaleNumber(value: number | string, region?: RegionType) {
  const { thousandSeparator, decimalSeparator } = getNumberSeparators(region);

  return parseFloat(
    value.toString().replaceAll(thousandSeparator, "").replaceAll(decimalSeparator, "."),
  );
}
