import React, { PropsWithChildren, useCallback, useMemo } from "react";
import { createSafeContext } from "utils/createSafeContext";
import { AvatarAction, AvatarState, useAvatarState } from "utils/hooks/useAvatarState";
import { useAdditionalAuthInformationContext } from "./AdditionalAuthInformationContext";

interface AppMenuContext {
  loadSideAvatar: () => Promise<void>;
  dispatchSideAvatarAction: React.Dispatch<AvatarAction>;
  sideAvatarState: AvatarState;
}

const MenuContext = createSafeContext<AppMenuContext>();

export const useAppMenuContext = MenuContext.hook;

export const AppMenuContextProvider = ({ children }: PropsWithChildren<object>) => {
  const { currentUserId, organizationId } = useAdditionalAuthInformationContext();
  const { avatarState, dispatchAvatarAction, loadAvatar } = useAvatarState();

  const loadSideAvatar = useCallback(
    () => loadAvatar(organizationId, currentUserId),
    [currentUserId, loadAvatar, organizationId],
  );

  const value = useMemo<AppMenuContext>(
    () => ({
      loadSideAvatar,
      dispatchSideAvatarAction: dispatchAvatarAction,
      sideAvatarState: avatarState,
    }),
    [avatarState, dispatchAvatarAction, loadSideAvatar],
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};
