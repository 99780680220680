/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  GeneralPractitionerResponseModel,
  GeneralPractitionerResponseModelListModel,
  GeneralPractitionersFilterModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    GeneralPractitionerResponseModelFromJSON,
    GeneralPractitionerResponseModelToJSON,
    GeneralPractitionerResponseModelListModelFromJSON,
    GeneralPractitionerResponseModelListModelToJSON,
    GeneralPractitionersFilterModelFromJSON,
    GeneralPractitionersFilterModelToJSON,
} from '../models/index';

export interface GeneralPractitionersGeneralPractitionerIdGetRequest {
    generalPractitionerId: string;
    acceptVersion?: string;
}

export interface GeneralPractitionersGetRequest {
    filters?: GeneralPractitionersFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

/**
 * GeneralPractitionersApi - interface
 * 
 * @export
 * @interface GeneralPractitionersApiInterface
 */
export interface GeneralPractitionersApiInterface {
    /**
     * 
     * @param {string} generalPractitionerId 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralPractitionersApiInterface
     */
    generalPractitionersGeneralPractitionerIdGetRaw(requestParameters: GeneralPractitionersGeneralPractitionerIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneralPractitionerResponseModel>>;

    /**
     */
    generalPractitionersGeneralPractitionerIdGet(requestParameters: GeneralPractitionersGeneralPractitionerIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneralPractitionerResponseModel>;

    /**
     * 
     * @param {GeneralPractitionersFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralPractitionersApiInterface
     */
    generalPractitionersGetRaw(requestParameters: GeneralPractitionersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneralPractitionerResponseModelListModel>>;

    /**
     */
    generalPractitionersGet(requestParameters: GeneralPractitionersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneralPractitionerResponseModelListModel>;

}

/**
 * 
 */
export class GeneralPractitionersApi extends runtime.BaseAPI implements GeneralPractitionersApiInterface {

     /**
     */
    async generalPractitionersGeneralPractitionerIdGetRawOriginal(requestParameters: GeneralPractitionersGeneralPractitionerIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.generalPractitionerId === null || requestParameters.generalPractitionerId === undefined) {
            throw new runtime.RequiredError('generalPractitionerId','Required parameter requestParameters.generalPractitionerId was null or undefined when calling generalPractitionersGeneralPractitionerIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/general-practitioners/{generalPractitionerId}`.replace(`{${"generalPractitionerId"}}`, encodeURIComponent(String(requestParameters.generalPractitionerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async generalPractitionersGeneralPractitionerIdGetRaw(requestParameters: GeneralPractitionersGeneralPractitionerIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneralPractitionerResponseModel>> {
        if (requestParameters.generalPractitionerId === null || requestParameters.generalPractitionerId === undefined) {
            throw new runtime.RequiredError('generalPractitionerId','Required parameter requestParameters.generalPractitionerId was null or undefined when calling generalPractitionersGeneralPractitionerIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/general-practitioners/{generalPractitionerId}`.replace(`{${"generalPractitionerId"}}`, encodeURIComponent(String(requestParameters.generalPractitionerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneralPractitionerResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async generalPractitionersGeneralPractitionerIdGet(requestParameters: GeneralPractitionersGeneralPractitionerIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneralPractitionerResponseModel> {
        const response = await this.generalPractitionersGeneralPractitionerIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async generalPractitionersGetRawOriginal(requestParameters: GeneralPractitionersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/general-practitioners`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async generalPractitionersGetRaw(requestParameters: GeneralPractitionersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneralPractitionerResponseModelListModel>> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/general-practitioners`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneralPractitionerResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async generalPractitionersGet(requestParameters: GeneralPractitionersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneralPractitionerResponseModelListModel> {
        const response = await this.generalPractitionersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
