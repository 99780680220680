import "./ActionModal.scss";

import classNames from "classnames";
import { Icon, Modal } from "libs/ui";
import { Loader } from "libs/ui/Loader";
import { PropsWithChildren } from "react";

import { ActionModalProps } from "./model";

export function ActionModal({
  show,
  icon,
  title,
  cancelButton,
  submitButton,
  inLoadingState,
  children,
}: PropsWithChildren<ActionModalProps>) {
  return (
    <Modal show={show} data-testid="ActionModal">
      <div className="ActionModal">
        <div className="ActionModal__content">
          {icon && (
            <span
              className={classNames(
                "ActionModal__icon",
                icon.color && `ActionModal__icon--${icon.color}`,
              )}
            >
              <Icon icon={icon.iconType} />
            </span>
          )}
          <h3 className="ActionModal__title">{title}</h3>
          <p className="ActionModal__text">{children}</p>
          <div className="ActionModal__action">
            <Loader loading={inLoadingState}>
              <>
                {submitButton}
                {cancelButton}
              </>
            </Loader>
          </div>
        </div>
      </div>
    </Modal>
  );
}
