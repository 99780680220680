import { DeletePersonModal } from "components/AppModals/DeletePersonModal";
import { PersonToDelete } from "models/PersonModels";
import { MouseEventHandler, useCallback, useMemo, useState } from "react";

export function useDeletePerson(callBack: () => void) {
  const [personToDelete, setPersonToDelete] = useState<PersonToDelete>();

  const selectPersonToDelete = useCallback(
    <T extends PersonToDelete>(user: T): MouseEventHandler<HTMLButtonElement> =>
      e => {
        e.stopPropagation();
        setPersonToDelete(user);
      },
    [],
  );

  const DeleteModal = useMemo(
    () => (
      <DeletePersonModal
        onClose={() => setPersonToDelete(undefined)}
        personToDelete={personToDelete}
        callBack={callBack}
      />
    ),
    [callBack, personToDelete],
  );

  return [DeleteModal, selectPersonToDelete] as const;
}
