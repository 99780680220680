import { UserGraphModel } from "api/query";
import CarnaLineChart from "components/Chart/CarnaChart/LineChart";
import { useGetMaxGlucoseValueFromRuleEngineUnit } from "components/Chart/hooks/useGetMaxGlucoseValueFromRuleEngineUnit";
import { ECOption, Formatters } from "components/Chart/model";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useCallback } from "react";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { getOptions as getInitialOptions } from "./model";

// const CarnaLineChart = React.lazy(() => import("components/Chart/CarnaChart/LineChart"));

export function Glucose() {
  const { value: maxGlucoseRange } = useGetMaxGlucoseValueFromRuleEngineUnit();
  const { glucoseUnit } = useGetUserPreferredMeasurementUnits();
  const { appConfig } = useGlobalConfigContext();

  const getOptions = useCallback(
    (formatters: Formatters, graphUser: UserGraphModel): ECOption =>
      getInitialOptions({
        formatters,
        data: graphUser,
        measurementUnit: glucoseUnit,
        maxRange: maxGlucoseRange,
        showTrending: appConfig?.components.graphs.Patient.Index.showTrending,
      }),
    [appConfig?.components.graphs.Patient.Index.showTrending, glucoseUnit, maxGlucoseRange],
  );

  return (
    <CarnaLineChart
      showTrend={appConfig?.components.graphs.Patient.Index.showTrending}
      dataType="Glucose"
      unitType="glucose"
      className="glucose"
      getOptions={getOptions}
      measurementUnit={glucoseUnit}
    />
  );
}

export default Glucose;
