import { CarnaApiEvent } from "config/apiEvent";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { UserRoleType } from "models/PersonModels";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getProfileEndpointPerRole } from "utils/helpers/getProfileEndpointPerRole";
import { useClearAndReloadIfSameUser } from "./ChangeEntityStatus/useClearAndReloadIfSameUser";
import { useApi } from "./useApi";

interface Profile {
  role: UserRoleType;
  id: string;
  organizationId: string;
}

async function deletePerson(personToDelete: Profile) {
  const { rowVersion } = await getProfileEndpointPerRole(personToDelete);

  return CarnaApiEvent[personToDelete?.role ?? "Admin"].delete({
    organizationId: personToDelete!.organizationId,
    userEntityId: personToDelete!.id,
    rowVersion: rowVersion,
  });
}

export function useDeleteAccount() {
  const { t } = useTranslation("modals", { keyPrefix: "DeleteAccountModal" });
  const { currentUserId, organizationId, userRole } = useAdditionalAuthInformationContext();
  const [removeLastUsedAuthStorageAndReload] = useClearAndReloadIfSameUser();

  const deletePersonCallback = useCallback(() => {
    return deletePerson({ id: currentUserId, organizationId, role: userRole });
  }, [currentUserId, organizationId, userRole]);

  const [deleteResult, deletePersonCb] = useApi(deletePersonCallback, {
    toastText: {
      successText: t("successDeletePersonToast"),
      errorText: t("errorDeletePersonToast"),
    },
    onResponseCallback: { onSuccess: () => removeLastUsedAuthStorageAndReload(currentUserId) },
  });

  return [deleteResult, deletePersonCb] as const;
}
