import { PartnersTableColumns } from "utils/createGlobalConfigStore";

interface PartnerTableColumnsProps {
  t: TFunction;
}

const allPartnersTableColumns = ({ t }: PartnerTableColumnsProps) => {
  return (
    [
      "userStatuses",
      "email",
      "phone",
      "address",
      "countries",
      "actions",
    ] satisfies PartnersTableColumns[]
  ).map(name => ({
    label: t(`columns.${name}`),
    name,
  }));
};

interface AppliedColumnsProps extends PartnerTableColumnsProps {
  appliedColumns: PartnersTableColumns[];
}

export function resolveAppliedColumns({ appliedColumns, ...rest }: AppliedColumnsProps) {
  return allPartnersTableColumns(rest).filter(column =>
    appliedColumns.some(el => el === column.name),
  );
}
