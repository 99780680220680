import { EgfrFormulaType } from "api/query/models/EgfrFormulaType";
import i18n from "i18next";

export function egfrAlgorithmToSelectOptions() {
  const egfrAlgorithms = Object.keys(EgfrFormulaType);

  return egfrAlgorithms.map(key => ({
    title: i18n.t(`RuleEngine.EgfrAlgorithm.${key}`, { ns: "translation" }),
    value: EgfrFormulaType[key as keyof typeof EgfrFormulaType],
  }));
}
