import { useCallback } from "react";
import { useGetUserPreferredMeasurementUnits } from "../useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { parseLocaleNumber } from "utils/converters/parseLocaleNumber";
/**
 * Currently there is no BE support for `Urine Albumin` preferred measurement unit
 * so we are solving this on FE (global configuration)
 *
 * Since BE is always expecting and returning urine albumin in `MEASUREMENT_UNITS.mgL`
 * we must convert the incoming value from BE and back to BE based on the global config urine albumin unit
 */
export function useUrineAlbuminValueConvertor() {
  const { urineAlbuminUnit } = useGetUserPreferredMeasurementUnits();

  const resolveUrineAlbuminValuePerUserPreferredUnit = useCallback(
    (value: string) => getUrineAlbuminValuePerUserPreferredUnit(value, urineAlbuminUnit),
    [urineAlbuminUnit],
  );

  const convertUrineAlbuminForBE = useCallback(
    (value: string | number) => convertUrineAlbuminValueForBE(value, urineAlbuminUnit),
    [urineAlbuminUnit],
  );

  return [resolveUrineAlbuminValuePerUserPreferredUnit, convertUrineAlbuminForBE] as const;
}

export function convertUrineAlbuminValueForBE(value: string | number, unit: MEASUREMENT_UNITS) {
  switch (unit) {
    case MEASUREMENT_UNITS.mgdL:
      return Number((Number(value) * 10).toPrecision(value.toString().length));
    default:
      return Number(value);
  }
}

export function getUrineAlbuminValuePerUserPreferredUnit(
  value: string | number,
  unit?: MEASUREMENT_UNITS,
) {
  switch (unit) {
    case MEASUREMENT_UNITS.mgdL:
      return Number((Number(parseLocaleNumber(value)) / 10).toPrecision(value.toString().length));
    default:
      return Number(value);
  }
}
