import { Select } from "libs/ui";
import "./DashboardPageNavigation.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { dashboardPaths } from "router";
import { useTranslation } from "react-i18next";

export function DashboardPageNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("translation", {
    keyPrefix: "DashboardPage.DashboardPageNavigation",
  });

  const options = [
    {
      title: t("mapDashboard"),
      value: dashboardPaths.dashboardMap,
    },
    // TODO: Uncomment when Stats page is needed. Also check out DashboardRoutes
    {
      title: t("statsDashboard"),
      value: dashboardPaths.dashboardStats,
    },
  ];

  const onSelect = useCallback(
    (value?: string) => {
      if (value) {
        navigate(value);
      }
    },
    [navigate],
  );

  return (
    <div className="DashboardPageNavigation">
      <Select value={location.pathname} options={options} onSelect={onSelect} />
    </div>
  );
}
