import { Avatar } from "libs/ui";
import { generatePath, Link, useParams } from "react-router-dom";
import { RouteParams, ROUTES } from "router";
import { useTranslation } from "react-i18next";
import { UserRoleType } from "models/PersonModels";
import "./AssignedTo.scss";

interface AssignedToProps {
  firstName?: string;
  lastName?: string;
  userId?: string;
  userRole?: UserRoleType;
}

function getPath(userId: string, organizationId: string, userRole?: UserRoleType) {
  if (!userRole) {
    return generatePath(ROUTES.home);
  }

  const path = userRole === "Hcp" ? ROUTES.hcpDetails : ROUTES.patientDetails;

  return generatePath(path, {
    organizationId,
    hcpId: userId,
    patientId: userId,
  });
}

export function AssignedTo({ firstName, lastName, userId, userRole }: AssignedToProps) {
  const { organizationId = "" } = useParams<RouteParams["deviceDetails"]>();
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  // TODO NEW_BACKEND
  // const avatar = useMakePagesDetailAvatarState(avatarApi.fetchAvatar);
  // const [avatarState, avatarSend] = avatar.service;

  // useEffect(() => {
  //   if (userId) {
  //     avatarSend("LOAD_AVATARS");
  //   }
  // }, [avatarSend, userId]);

  if (!userId) {
    return null;
  }

  return (
    <div className="AssignedTo">
      <label className="AssignedTo__label">{t("assignedTo")}</label>
      <div className="AssignedTo__content">
        <Avatar
          avatarLoading={false}
          firstName={firstName}
          avatarType={userRole}
          // src={getImageSrc(avatarState.context.avatars?.at(0)?.image)}
        />
        <Link to={getPath(userId, organizationId, userRole)} className="AssignedTo__link">
          {firstName} {lastName}
        </Link>
      </div>
    </div>
  );
}
