import { LineChart, CandlestickChart } from "echarts/charts";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";

import {
  DatasetComponent,
  DataZoomComponent,
  GridComponent,
  MarkAreaComponent,
  MarkLineComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  TransformComponent,
  VisualMapComponent,
} from "echarts/components";

import { ZoomLevels } from "./model";
import { ECBasicOption } from "echarts/types/dist/shared.js";

echarts.use([
  MarkAreaComponent,
  MarkLineComponent,
  LineChart,
  CandlestickChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  CanvasRenderer,
  ToolboxComponent,
  VisualMapComponent,
  DataZoomComponent,
]);

export function ChartInit<ECOption extends ECBasicOption>(
  div: HTMLDivElement,
  options: ECOption,
  setCurrentZoomLevel?: React.Dispatch<React.SetStateAction<ZoomLevels>>,
  onZoomRange?: (range: [number, number]) => void,
) {
  const chart = echarts.init(div);

  chart.on("datazoom", () => {
    const { startValue, endValue } = (chart.getOption().dataZoom as any)[0];

    if (setCurrentZoomLevel) {
      setCurrentZoomLevel("custom");
    }

    if (onZoomRange) {
      onZoomRange([startValue, endValue]);
    }
  });

  chart.setOption(options);

  return chart;
}
