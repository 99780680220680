/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceType } from './DeviceType';
import {
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';
import type { MeasurementUnitType } from './MeasurementUnitType';
import {
    MeasurementUnitTypeFromJSON,
    MeasurementUnitTypeFromJSONTyped,
    MeasurementUnitTypeToJSON,
} from './MeasurementUnitType';
import type { NormalRangeModel } from './NormalRangeModel';
import {
    NormalRangeModelFromJSON,
    NormalRangeModelFromJSONTyped,
    NormalRangeModelToJSON,
} from './NormalRangeModel';
import type { TestMethodType } from './TestMethodType';
import {
    TestMethodTypeFromJSON,
    TestMethodTypeFromJSONTyped,
    TestMethodTypeToJSON,
} from './TestMethodType';

/**
 * 
 * @export
 * @interface CreatinineModel
 */
export interface CreatinineModel {
    /**
     * 
     * @type {boolean}
     * @memberof CreatinineModel
     */
    enableMeasurements?: boolean;
    /**
     * 
     * @type {DeviceType}
     * @memberof CreatinineModel
     */
    preferredDevice?: DeviceType;
    /**
     * 
     * @type {TestMethodType}
     * @memberof CreatinineModel
     */
    testMethod?: TestMethodType;
    /**
     * 
     * @type {MeasurementUnitType}
     * @memberof CreatinineModel
     */
    measurementUnit?: MeasurementUnitType;
    /**
     * 
     * @type {NormalRangeModel}
     * @memberof CreatinineModel
     */
    maleNormalRange: NormalRangeModel;
    /**
     * 
     * @type {NormalRangeModel}
     * @memberof CreatinineModel
     */
    femaleNormalRange: NormalRangeModel;
}

/**
 * Check if a given object implements the CreatinineModel interface.
 */
export function instanceOfCreatinineModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "maleNormalRange" in value;
    isInstance = isInstance && "femaleNormalRange" in value;

    return isInstance;
}

export function CreatinineModelFromJSON(json: any): CreatinineModel {
    return CreatinineModelFromJSONTyped(json, false);
}

export function CreatinineModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatinineModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enableMeasurements': !exists(json, 'EnableMeasurements') ? undefined : json['EnableMeasurements'],
        'preferredDevice': !exists(json, 'PreferredDevice') ? undefined : DeviceTypeFromJSON(json['PreferredDevice']),
        'testMethod': !exists(json, 'TestMethod') ? undefined : TestMethodTypeFromJSON(json['TestMethod']),
        'measurementUnit': !exists(json, 'MeasurementUnit') ? undefined : MeasurementUnitTypeFromJSON(json['MeasurementUnit']),
        'maleNormalRange': NormalRangeModelFromJSON(json['MaleNormalRange']),
        'femaleNormalRange': NormalRangeModelFromJSON(json['FemaleNormalRange']),
    };
}

export function CreatinineModelToJSON(value?: CreatinineModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EnableMeasurements': value.enableMeasurements,
        'PreferredDevice': DeviceTypeToJSON(value.preferredDevice),
        'TestMethod': TestMethodTypeToJSON(value.testMethod),
        'MeasurementUnit': MeasurementUnitTypeToJSON(value.measurementUnit),
        'MaleNormalRange': NormalRangeModelToJSON(value.maleNormalRange),
        'FemaleNormalRange': NormalRangeModelToJSON(value.femaleNormalRange),
    };
}

