/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  OrganizationDetailsResponseModel,
  OrganizationDetailsResponseModelListModel,
  OrganizationsFilterModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    OrganizationDetailsResponseModelFromJSON,
    OrganizationDetailsResponseModelToJSON,
    OrganizationDetailsResponseModelListModelFromJSON,
    OrganizationDetailsResponseModelListModelToJSON,
    OrganizationsFilterModelFromJSON,
    OrganizationsFilterModelToJSON,
} from '../models/index';

export interface OrganizationsGetRequest {
    filters?: OrganizationsFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdGetRequest {
    organizationId: string;
    acceptVersion?: string;
}

/**
 * OrganizationsApi - interface
 * 
 * @export
 * @interface OrganizationsApiInterface
 */
export interface OrganizationsApiInterface {
    /**
     * 
     * @param {OrganizationsFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    organizationsGetRaw(requestParameters: OrganizationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationDetailsResponseModelListModel>>;

    /**
     */
    organizationsGet(requestParameters: OrganizationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationDetailsResponseModelListModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    organizationsOrganizationIdGetRaw(requestParameters: OrganizationsOrganizationIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationDetailsResponseModel>>;

    /**
     */
    organizationsOrganizationIdGet(requestParameters: OrganizationsOrganizationIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationDetailsResponseModel>;

}

/**
 * 
 */
export class OrganizationsApi extends runtime.BaseAPI implements OrganizationsApiInterface {

     /**
     */
    async organizationsGetRawOriginal(requestParameters: OrganizationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsGetRaw(requestParameters: OrganizationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationDetailsResponseModelListModel>> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDetailsResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsGet(requestParameters: OrganizationsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationDetailsResponseModelListModel> {
        const response = await this.organizationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdGetRawOriginal(requestParameters: OrganizationsOrganizationIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdGetRaw(requestParameters: OrganizationsOrganizationIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationDetailsResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDetailsResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdGet(requestParameters: OrganizationsOrganizationIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationDetailsResponseModel> {
        const response = await this.organizationsOrganizationIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
