import classNames from "classnames";
import { FieldValidation, FieldValidationProps } from "../FieldValidation";
import { RadioOption } from "./RadioOption";
import { Option } from "./../Select/Select.model";
import "./RadioOptions.scss";

export interface RadioOptionsProps<T> {
  value?: T;
  options: Option<T>[];
  onSelect: (value: T) => void;
  disabled?: boolean;
  className?: string;
  radioOptionClassName?: string;
  label?: string;
  validation?: FieldValidationProps;
}

export function RadioOptions<T>({
  value,
  options,
  onSelect,
  disabled,
  label,
  validation,
  className,
  radioOptionClassName,
}: Readonly<RadioOptionsProps<T>>) {
  const { errorText = undefined, successText = undefined } = validation ?? {};

  return (
    <div className={classNames("UI-Components RadioOptions", className)}>
      {label && <label className="RadioOptions__label">{label}</label>}

      <div className="RadioOptions__list" role="list">
        {options.map(option => (
          <RadioOption
            key={option.title}
            option={option}
            onSelect={onSelect}
            disabled={disabled}
            radioOptionClassName={radioOptionClassName}
            value={value}
          />
        ))}
      </div>

      <FieldValidation successText={successText} errorText={errorText} />
    </div>
  );
}
