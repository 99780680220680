import * as React from "react";
import { SVGProps } from "react";
const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.994 1.317a5.75 5.75 0 0 0-4.045 1.614l-1.728 1.717a.75.75 0 0 0 1.058 1.064l1.716-1.706a4.25 4.25 0 0 1 6.01 6.008L17.01 13.01a4.25 4.25 0 0 1-6.41-.46.75.75 0 1 0-1.2.9 5.75 5.75 0 0 0 8.67.62l3-3 .01-.009a5.75 5.75 0 0 0-4.086-9.744Z"
      fill="currentColor"
    />
    <path
      d="M10.407 8.015a5.75 5.75 0 0 0-4.477 1.67l-3 3-.01.01a5.75 5.75 0 0 0 8.131 8.13l1.72-1.72a.75.75 0 1 0-1.061-1.06l-1.706 1.705a4.25 4.25 0 0 1-6.009-6.01l2.995-2.995a4.25 4.25 0 0 1 6.41.46.75.75 0 1 0 1.2-.9 5.75 5.75 0 0 0-4.193-2.29Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLink;
