export const StatsWidgetType = {
  HeatMap: "HeatMap",
  SerumCreatinine: "SerumCreatinine",
  Egfr: "Egfr",
  Bmi: "Bmi",
  Glucose: "Glucose",
  Height: "Height",
  Weight: "Weight",
  BloodPressure: "BloodPressure",
  UrineCreatinine: "UrineCreatinine",
  UrineAlbumin: "UrineAlbumin",
  Uacr: "Uacr",
  SemiQuantitativeUacr: "SemiQuantitativeUacr",
} as const;

export type StatsWidgetType = (typeof StatsWidgetType)[keyof typeof StatsWidgetType];
