import * as React from "react";
import { SVGProps } from "react";
const SvgCornerLeftDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 3.25A4.75 4.75 0 0 0 8.25 8v10.19l-3.72-3.72a.75.75 0 0 0-1.06 1.06l5 5a.748.748 0 0 0 1.06 0l5-5a.75.75 0 1 0-1.06-1.06l-3.72 3.72V8A3.25 3.25 0 0 1 13 4.75h7a.75.75 0 0 0 0-1.5h-7Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCornerLeftDown;
