import * as Form from "components/Forms/Test";
import { TestFormModel } from "components/Forms/Test/model";
import * as testFormUtils from "components/Forms/Test/testFormUtils";
import { useTestFormFieldsReady } from "components/Forms/Test/useTestFormFieldsReady";
import { CarnaApiEvent } from "config/apiEvent";
import { toastStore } from "config/toast";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { PersonModel } from "models/PersonModels/PersonModel";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { setCurrentSecOnDateTime } from "utils/converters/setCurrentSecOnDateTime";
import { getTestDataWithoutNone } from "utils/getTestDataWithoutNone";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import { useMapTestFormModelForBE } from "utils/hooks/FEtoBE/useMapTestFormModelForBE";
import { useConvertTestTimeZone } from "utils/hooks/useConvertTestTimeZone";
import { useOnEventStatusSubscribe } from "utils/hooks/useOnEventStatusSubscribe";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { CreateNewSideModal } from "../common/CreateNewSideModal";
import { AddModalForwardedProps } from "../model";

interface TestModalProps {
  person?: PersonModel;
  onSuccessCb?: (testData: TestFormModel) => void;
}

function useInit() {
  const { globalPreference } = useGlobalPreferenceContext();
  const init = useCallback((): TestFormModel => {
    return {
      ...testFormUtils.TEST_DATA_DEFAULT,
      measurementTime: setCurrentSecOnDateTime(new Date(), globalPreference?.timeZoneType),
    };
  }, [globalPreference?.timeZoneType]);

  return [init] as const;
}

export const Test = forwardRef<AddModalForwardedProps, TestModalProps>(
  ({ person, onSuccessCb }, ref) => {
    const { t } = useTranslation("modals", { keyPrefix: "AddModal" });
    const [showModal, setShowModal] = useState(false);
    const [init] = useInit();
    const [testData, setTestData] = useState<TestFormModel>(init);
    const [onEvent, inLoadingState] = useOnEventStatusSubscribe();
    const [mapTestFormModelForBE] = useMapTestFormModelForBE();
    const [, , , getCurrentDateTimePerTimeZonePreferences] = useRegionDateTimeFormatter();

    const [getFieldsReady] = useTestFormFieldsReady();

    const { currentUserId } = useAdditionalAuthInformationContext();

    const { convertTestMeasurementTime } = useConvertTestTimeZone();

    const resetAndClose = () => {
      setTestData(testFormUtils.TEST_DATA_DEFAULT);
      setShowModal(false);
    };

    const onSuccessCallback = useCallback(() => {
      resetAndClose();

      if (onSuccessCb) {
        onSuccessCb(testData);
      }
    }, [onSuccessCb, testData]);

    const PostMeasurements = useCallback(
      async (testData: TestFormModel) => {
        const testDataWithoutNone = getTestDataWithoutNone(testData);
        return onEvent(
          CarnaApiEvent.Test.post({
            organizationId: testData.organizationId,
            userEntityId: NotNullOrUndefined(currentUserId),
            addTestByHcpRequestModel: convertTestMeasurementTime(testDataWithoutNone),
          }),
          {
            complete() {
              toastStore.toast.success({ msg: t("successAddTestToastText"), expire: 5000 });
              onSuccessCallback();
            },
            error(err) {
              switch (err.code) {
                case "BE_ERROR":
                  showBeFieldErrors(err.err, t("errorAddTestToastText"));
                  break;

                case "ACTION_FAILED":
                  toastStore.toast.error({ msg: t("errorAddTestToastText") });
                  break;
                case "STATUS_QUERY_ERROR":
                  resetAndClose();
                  toastStore.toast.error({ msg: t("errorFailedToUpdateLaboratoryTable") });
              }
            },
          },
        );
      },
      [onEvent, currentUserId, convertTestMeasurementTime, t, onSuccessCallback],
    );

    const areFieldsReady = getFieldsReady(testData, false);
    const submitDisabled = !areFieldsReady || inLoadingState;

    const onSubmit = (e?: React.FormEvent) => {
      e?.preventDefault();

      if (submitDisabled) {
        return;
      }

      PostMeasurements(mapTestFormModelForBE(testData));
    };

    const toggleModal = useCallback(() => {
      setTestData(prev => ({
        ...prev,
        measurementTime: getCurrentDateTimePerTimeZonePreferences(),
      }));
      setShowModal(prevValue => !prevValue);
    }, [getCurrentDateTimePerTimeZonePreferences]);

    useImperativeHandle(
      ref,
      () => ({
        toggleModal,
      }),
      [toggleModal],
    );

    return (
      <CreateNewSideModal
        data-testid={"Modal-AddTest"}
        title={t("addTestTitle")}
        submitButton={{
          onClick: onSubmit,
          text: t("buttonAdd"),
          disabled: submitDisabled,
        }}
        show={showModal}
        closeModal={{
          onClick: resetAndClose,
          disabled: inLoadingState,
        }}
        isLoading={inLoadingState}
      >
        <Form.Test
          person={person}
          testData={testData}
          setTestData={setTestData}
          onSubmit={onSubmit}
        />
      </CreateNewSideModal>
    );
  },
);
