import { SideModalElements } from "libs/ui";
import { useTranslation } from "react-i18next";
import { SectionDetailsProps } from "../model";
import { ColorValue } from "../../elements/ColorValue";
import { DetailsField } from "../../elements/DetailsField";
import { TrendDetailsField } from "../../elements/TrendDetailsField";
import { useGetLocalHeightLabel } from "components/Chart/hooks/useGetLocalHeightLabel";

interface HeightSectionDetailsProps extends SectionDetailsProps {}

export function HeightSectionDetails({ data, loading, showTrend }: HeightSectionDetailsProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.HeightSectionDetails" });

  const { getLocalHeightLabel } = useGetLocalHeightLabel();

  return (
    <div className="HeightSectionDetails">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={t("labelResult")}>
        <ColorValue colorType={"white"}>
          {getLocalHeightLabel(Number(data?.measurement.BMI?.Height.Value ?? 0))}
        </ColorValue>
      </DetailsField>

      <TrendDetailsField
        show={showTrend}
        loading={loading}
        trendType={data?.measurement?.BMI?.Height.TrendType}
        percent={data?.measurement?.BMI?.Height.Percent}
      />
    </div>
  );
}
