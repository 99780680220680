import { useMachine } from "@xstate/react";
import { OrganizationDetailsResponseModelListModel, OrganizationsFilterModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { SearchQuery, TableContext, tableStateMachine } from "libs/ui";
import { mapTableQueryParamsFromURL, useTableURLParams } from "libs/ui/Table/utils";
import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { OrganizationsTableColumns } from "utils/createGlobalConfigStore";
import { createSafeContext } from "utils/createSafeContext";
import { countriesToSelectOptions } from "utils/mappers/countriesToSelectOptions";
import { statesToSelectOptions } from "utils/mappers/statesToSelectOptions";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { FilterSideModalState } from "../common/Filter/model";
import { useFilterModal } from "../common/hooks";
import { getFilterOptionsPerConfiguration } from "../utils";

// TODO add states filter once BE provides us that filter option
export function ORGANIZATIONS_FILTER_OPTIONS() {
  return {
    countries: countriesToSelectOptions(),
    states: statesToSelectOptions([]),
    contains: "",
  } as const;
}

type OrganizationsFilterOptionsKey = keyof ReturnType<typeof ORGANIZATIONS_FILTER_OPTIONS>;

export interface OrganizationsTableFilterProps {
  tableService: ReturnType<typeof useMachine<ReturnType<typeof makeTableFetchStateMachine>>>;
  filterSideModelState: FilterSideModalState<OrganizationsFilterOptionsKey>;
}
interface OrganizationsTableStateContext extends OrganizationsTableFilterProps {
  fetch: () => void;
}

export type UIOrganizationFilters = Omit<
  OrganizationsFilterModel,
  "excludeMain" | "beginsWith" | "userIds"
>;

export interface BodyRowsProps {
  tableService: ReturnType<typeof useMachine<ReturnType<typeof makeTableFetchStateMachine>>>;
  appliedColumns: OrganizationsTableColumns[];
}

async function getOrganizations(
  context?: TableContext<OrganizationDetailsResponseModelListModel, UIOrganizationFilters>,
) {
  const result = await CarnaApiQuery.Organizations.get({
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

export const makeTableFetchStateMachine = (
  queryParams: SearchQuery<ReturnType<typeof ORGANIZATIONS_FILTER_OPTIONS>>,
) => tableStateMachine(getOrganizations, queryParams as any);

const Context = createSafeContext<OrganizationsTableStateContext>();

export const useOrganizationsTableStateContext = Context.hook;

export function OrganizationsTableStateProvider({ children }: Readonly<PropsWithChildren>) {
  const [queryParams] = useTableURLParams<ReturnType<typeof ORGANIZATIONS_FILTER_OPTIONS>>();
  const tableMachine = useMemo(
    () =>
      makeTableFetchStateMachine(
        mapTableQueryParamsFromURL<ReturnType<typeof ORGANIZATIONS_FILTER_OPTIONS>>(queryParams),
      ),
    [queryParams],
  );
  const tableService = useMachine(tableMachine);

  const { appConfig } = useGlobalConfigContext();

  const filterSideModelState = useFilterModal<OrganizationsFilterOptionsKey>(
    getFilterOptionsPerConfiguration(
      ORGANIZATIONS_FILTER_OPTIONS(),
      NotNullOrUndefined(appConfig?.components.tables.Organization.filterOptions),
    ),
  );

  const [, send] = tableService;

  const fetch = useCallback(() => {
    send({ type: "UPDATE_QUERY", value: mapTableQueryParamsFromURL(queryParams) });
  }, [send, queryParams]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const value = useMemo(
    () => ({
      tableService,
      fetch,
      filterSideModelState,
    }),
    [fetch, filterSideModelState, tableService],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
