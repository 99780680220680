import classNames from "classnames";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useRegistryNumberLabel } from "utils/hooks/useRegistryNumberLabel";
import "./RegistryNumber.scss";

export function RegistryNumber() {
  const { isPatient, userRegistryNumber } = useAdditionalAuthInformationContext();
  const [registryNumberLabel] = useRegistryNumberLabel();

  if (!isPatient || !userRegistryNumber) {
    return null;
  }

  return (
    <p
      className={classNames(
        "RegistryNumber",
        // {
        // "RegistryNumber--loading": isLoading(currentUserName),
        // }
      )}
    >
      {`${registryNumberLabel}`.concat(": ")}
      {userRegistryNumber ?? null}
    </p>
  );
}
