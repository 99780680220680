import { PropsWithChildren } from "react";
import { Logo } from "libs/ui/Logo";
import "./DocumentWrapper.scss";

interface DocumentWrapperProps {
  title: string;
  date: string;
}

export function DocumentWrapper({
  title,
  date,
  children,
}: PropsWithChildren<DocumentWrapperProps>) {
  return (
    <div className="DocumentWrapper">
      <div className="DocumentWrapper__header">
        <div className="DocumentWrapper__logo_container">
          <Logo className="DocumentWrapper__logo" />
        </div>
        <div className="DocumentWrapper__content">
          <h1 className="DocumentWrapper__title">{title}</h1>
          <p className="DocumentWrapper__date">{date}</p>
        </div>
      </div>
      {children}
    </div>
  );
}
