import { breadcrumbsStore } from "config/breadcrumbs";
import { useSyncExternalStore, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getTranslatedRouteObject } from "router";
import { getBreadCrumbsFromCurrentLocation } from "utils/breadCrumbs";
import { useTranslation } from "react-i18next";

export function useBreadCrumbs() {
  const { t } = useTranslation("translation", { keyPrefix: "BreadCrumbs" });

  const currentPageTitle = useSyncExternalStore(
    breadcrumbsStore.subscribe,
    breadcrumbsStore.getCurrentPageTitle,
  );

  const currentPageAvatar = useSyncExternalStore(
    breadcrumbsStore.subscribe,
    breadcrumbsStore.getCurrentPageAvatar,
  );

  const location = useLocation();

  const RouteNames = useMemo(() => getTranslatedRouteObject(t), [t]);

  const breadCrumbs = useMemo(
    () => [
      ...getBreadCrumbsFromCurrentLocation(RouteNames, location.pathname),
      ...(currentPageTitle
        ? [
            {
              title: currentPageTitle,
              avatarImg:
                currentPageAvatar && !currentPageAvatar.includes("delete")
                  ? currentPageAvatar
                  : undefined,
            },
          ]
        : []),
    ],
    [RouteNames, currentPageAvatar, currentPageTitle, location.pathname],
  );

  return breadCrumbs;
}
