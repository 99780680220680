/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConfigurationModel } from './ConfigurationModel';
import {
    ConfigurationModelFromJSON,
    ConfigurationModelFromJSONTyped,
    ConfigurationModelToJSON,
} from './ConfigurationModel';

/**
 * 
 * @export
 * @interface AddConfigurationCommandModel
 */
export interface AddConfigurationCommandModel {
    /**
     * 
     * @type {string}
     * @memberof AddConfigurationCommandModel
     */
    tenantName: string;
    /**
     * 
     * @type {ConfigurationModel}
     * @memberof AddConfigurationCommandModel
     */
    _configuration: ConfigurationModel;
}

/**
 * Check if a given object implements the AddConfigurationCommandModel interface.
 */
export function instanceOfAddConfigurationCommandModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tenantName" in value;
    isInstance = isInstance && "_configuration" in value;

    return isInstance;
}

export function AddConfigurationCommandModelFromJSON(json: any): AddConfigurationCommandModel {
    return AddConfigurationCommandModelFromJSONTyped(json, false);
}

export function AddConfigurationCommandModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddConfigurationCommandModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantName': json['TenantName'],
        '_configuration': ConfigurationModelFromJSON(json['Configuration']),
    };
}

export function AddConfigurationCommandModelToJSON(value?: AddConfigurationCommandModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TenantName': value.tenantName,
        'Configuration': ConfigurationModelToJSON(value._configuration),
    };
}

