import { useGetHeightNoLabel } from "components/Chart/hooks/useGetHeightNoLabel";
import { Chip, Icon } from "libs/ui";
import { getMeasurementTrendIcon } from "utils/getMeasurementTrendIcon";
import { useCreatinineValueConvertor } from "utils/hooks/useCreatinineValueConvertor";
import { useGetWeightConverter } from "utils/hooks/useGetWeightConverter/useGetWeightConverter";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { useUrineAlbuminValueConvertor } from "utils/hooks/useUrineAlbuminValueConvertor";
import { MeasurementItem, MeasurementItemType } from "../model";
import "./MeasurementValue.scss";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { getSQUACRFlags } from "utils/getSQUACRFlags";

interface MeasurementValueProps {
  measurements: MeasurementItem | undefined;
  itemType: MeasurementItemType;
}

const Percent = ({ percent }: { percent?: number }) =>
  percent ? <span>{(percent ?? 0).toString().concat("%")}</span> : null;

export function MeasurementValue({ itemType, measurements }: Readonly<MeasurementValueProps>) {
  const { t } = useTranslation("widgets", { keyPrefix: "MeasurementsWidget" });

  const { numberFormatterWithDash } = useLocaleNumberFormatter();
  const data = measurements?.[itemType];
  const getCreatinineValue = useCreatinineValueConvertor();
  const getConvertedWeight = useGetWeightConverter();
  const [getUrineAlbuminValue] = useUrineAlbuminValueConvertor();
  const [getHeightValue] = useGetHeightNoLabel();

  const getValue = useCallback(() => {
    if (!data) {
      return t("noData");
    }

    switch (itemType) {
      case "SerumCreatinine":
        return numberFormatterWithDash({ value: getCreatinineValue(data.Value, data.Unit) ?? 0 });

      case "Height":
        return getHeightValue(data.Value);

      case "Weight":
        return numberFormatterWithDash({
          value: getConvertedWeight(data.Value ?? 0),
          additionalOptions: {
            maximumFractionDigits: 1,
            minimumFractionDigits: 0,
            roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
          },
        });

      case "UrineAlbumin":
        return numberFormatterWithDash({
          value: getUrineAlbuminValue(data.Value.toString()) ?? 0,
        });

      case "SemiQuantitativeUacr":
        return getSQUACRFlags(data.Value).level;

      default:
        return numberFormatterWithDash({ value: data.Value ?? 0 });
    }
  }, [
    data,
    getConvertedWeight,
    getCreatinineValue,
    getHeightValue,
    getUrineAlbuminValue,
    itemType,
    numberFormatterWithDash,
    t,
  ]);

  return (
    <div className="MeasurementValue">
      <span className="MeasurementValue__value" data-testid="value">
        {getValue()}
      </span>
      {data ? (
        <Chip variant="grey" size="small">
          <Icon scaleTo={16} icon={getMeasurementTrendIcon(data.TrendType)} />
          <Percent percent={data.Percent} />
        </Chip>
      ) : null}
    </div>
  );
}
