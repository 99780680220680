import * as React from "react";
import { SVGProps } from "react";
const SvgCornerRightUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.287 3.307a.748.748 0 0 0-.817.163l-5 5a.75.75 0 0 0 1.06 1.06l3.72-3.72V16A3.25 3.25 0 0 1 11 19.25H4a.75.75 0 0 0 0 1.5h7A4.75 4.75 0 0 0 15.75 16V5.81l3.72 3.72a.75.75 0 1 0 1.06-1.06l-5-5a.748.748 0 0 0-.243-.163Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCornerRightUp;
