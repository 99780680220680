import { Cell } from "../Cell";
import { DeviceImage } from "../../../DeviceImage";
import { DeviceCellProps } from "../model";
import { PropsWithChildren } from "react";
import "./DeviceCell.scss";

export function DeviceCell({
  deviceIcon = true,
  deviceSerialNumber,
  deviceModel,
  sampledByDevice,
  children,
  logger,
  ...props
}: PropsWithChildren<DeviceCellProps>) {
  if (deviceSerialNumber || sampledByDevice) {
    return (
      <Cell {...props} className="DeviceCell">
        <DeviceImage
          sampledByDevice={sampledByDevice}
          alt={deviceSerialNumber ?? ""}
          className="DeviceCell__figure"
          logger={logger}
        />
        {deviceModel ? <div className="DeviceCell__model">{deviceModel}</div> : null}
      </Cell>
    );
  }

  return (
    <Cell {...props} className="DeviceCell">
      <div className="DeviceCell__additionalText">{children}</div>
    </Cell>
  );
}
