import { FieldVisibilityType } from "utils/createGlobalConfigStore";
import isEmail from "validator/lib/isEmail";

function optionalEmail(value: string | null | undefined) {
  if (value === null || value === undefined || value === "") {
    return true;
  }

  return isEmail(value);
}

function requiredEmail(value: string | null | undefined) {
  if (value === null || value === undefined || value === "") {
    return false;
  }

  return isEmail(value);
}

export function validateEmailPerVisibility(
  fieldVisibility: FieldVisibilityType | undefined,
): (value: any) => boolean {
  if (fieldVisibility === "Required") {
    return requiredEmail;
  }

  if (fieldVisibility === "Optional") {
    return optionalEmail;
  }

  return () => true;
}
