import { TableCard } from "components/AppTables/common/TableCard";
import { TableCardDescription } from "components/AppTables/common/TableCardDescription";
import { resolveRenderCell } from "components/AppTables/utils";
import { CountryFlag, TableElements } from "libs/ui";
import { TableUtils } from "libs/ui/Table/utils";
import { useTranslation } from "react-i18next";
import { BodyRowsProps } from "../context";
import { OrganizationDetailsResponseModel } from "api/query";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useMemo } from "react";
import { OrganizationLogo } from "components/OrganizationLogo";

export function BodyRows({ tableService, appliedColumns }: BodyRowsProps) {
  const { t: countriesT } = useTranslation("nationalities", { keyPrefix: "countries" });

  const tableRedirection = TableUtils.useTableElementsRedirect();
  /**
   * TODO grooming
   * This should not be dependant on external stuff
   * we should have defaultProps or something
   */
  const { appConfig } = useGlobalConfigContext();
  const [state] = tableService;

  const defaultValues = useMemo(
    () =>
      Array.from({ length: state.context.linesPerPage }).map((_, idx) => {
        const defaultValue: OrganizationDetailsResponseModel = {
          ...NotNullOrUndefined(appConfig?.forms.defaultValues.organization),
          id: idx.toString(),
          isMain: false,
          rowVersion: "1",
        };
        return defaultValue;
      }),
    [appConfig?.forms.defaultValues.organization, state.context.linesPerPage],
  );

  return (
    <>
      {(state.matches("loading") ? defaultValues : state.context.data.items ?? []).map(org => {
        if (state.context.tableView === "Grid") {
          return (
            <TableCard
              key={org.id}
              loading={state.matches("loading")}
              avatarData={
                <OrganizationLogo isMain={org.isMain} loading={state.matches("loading")} />
              }
              name={org.name}
              description={
                <>
                  <TableCardDescription textType="bold" loading={state.matches("loading")}>
                    {`${org.street}, ${org.city} ${org.zipCode ?? ""}`}
                  </TableCardDescription>
                  <TableCardDescription textType="light" loading={state.matches("loading")}>
                    {org.phone}
                  </TableCardDescription>
                </>
              }
              onClick={() =>
                tableRedirection({
                  path: "organizationDetails",
                  params: {
                    organizationId: org.id,
                    firstTab: org.isMain ? "user-management" : "partners",
                  },
                })
              }
            >
              <CountryFlag
                countryCode={org.country}
                countryName={countriesT(org.country)}
                flagSize="large"
                loading={state.matches("loading")}
              />
            </TableCard>
          );
        }

        return (
          <TableElements.Row
            key={org.id}
            data-is-main={org.isMain}
            onClick={
              state.matches("loading")
                ? undefined
                : () =>
                    tableRedirection({
                      path: "organizationDetails",
                      params: {
                        organizationId: org.id,
                        firstTab: org.isMain ? "user-management" : "partners",
                      },
                    })
            }
          >
            <TableElements.IconCell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "name")}
              icon={org.isMain ? "MainOrganization" : "Organization"}
              style={{ color: org.isMain ? "var(--yellow-500)" : "var(--primary-500)" }}
            >
              {org.name}
            </TableElements.IconCell>
            <TableElements.Cell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "phone")}
            >
              {org.phone}
            </TableElements.Cell>

            <TableElements.Cell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "address")}
            >
              {`${org.street}, ${org.city} ${org.zipCode ?? ""}`}
            </TableElements.Cell>

            <TableElements.FlagCell
              loading={state.matches("loading")}
              {...(org.country && { countryName: countriesT(org.country) })}
              country={org.country}
              show={resolveRenderCell(appliedColumns, "countries")}
            />
          </TableElements.Row>
        );
      })}
    </>
  );
}
