import { AccountData } from "./tabs/AccountData";
import { PrivacyPolicyTab } from "pages/Settings/SecurityAndPrivacy/PrivacyPolicyTab";
import { Tabs } from "libs/ui";
import { useTranslation } from "react-i18next";
// import { EventsLog } from "./tabs/EventsLog";
import { DeviceHistory } from "./tabs/DeviceHistory";
import { SettingsPage } from "components/PageTemplate/SettingsPage";

export function SecurityAndPrivacy() {
  const { t } = useTranslation("translation", { keyPrefix: "SecurityAndPrivacy" });

  return (
    <SettingsPage>
      <Tabs
        tabs={[
          {
            label: t("tabs.device_history"),
            Component: (
              <PrivacyPolicyTab>
                <DeviceHistory />
              </PrivacyPolicyTab>
            ),
          },
          //   {
          //     label: t("tabs.events_log"),
          //     Component: (
          //       <PrivacyPolicyTab>
          //         <EventsLog />
          //       </PrivacyPolicyTab>
          //     ),
          //   },
          {
            label: t("tabs.account_data"),
            Component: (
              <PrivacyPolicyTab>
                <AccountData />
              </PrivacyPolicyTab>
            ),
          },
        ]}
      />
    </SettingsPage>
  );
}
