import { FilterSection } from "components/AppTables/common/Filter/FilterSection";
import { OrganizationsFilterSection } from "components/AppTables/common/Filter/OrganizationsFilterSection";
import { TableFilter } from "components/AppTables/common/Filter/TableFilter";
import { useTranslation } from "react-i18next";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { DevicesTableFilterProps, DEVICES_FILTER_OPTIONS } from "../model";
import React from "react";
import { SideModalElements } from "libs/ui";
import { DropDownToggler } from "libs/ui/DropDownToggler";

export function DevicesTableFilter({
  tableService,
  filterSideModelState,
}: Readonly<DevicesTableFilterProps>) {
  const { t: tColumns } = useTranslation("translation", { keyPrefix: "DevicesTable.columns" });
  const { t: tFilterTitles } = useTranslation("translation", {
    keyPrefix: "OrganizationsFilterSection",
  });
  const { isAdmin } = useAdditionalAuthInformationContext();

  return (
    <TableFilter tableService={tableService} filterSideModelState={filterSideModelState}>
      {({ activeFilters, appliedFilters, onNewFilterApplied }) => (
        <>
          {activeFilters.map((el, index) => {
            if (el === "contains") {
              return null;
            }

            if (el === "organizationIds") {
              return isAdmin ? (
                <React.Fragment key={el}>
                  <DropDownToggler
                    isExpandedOnInit={(appliedFilters[el] ?? []).length > 0}
                    title={tFilterTitles("organization")}
                    expandChildrenCount={5}
                  >
                    <OrganizationsFilterSection
                      checkedValues={appliedFilters[el] ?? []}
                      onFilterApply={value => onNewFilterApplied(el, value)}
                    />
                  </DropDownToggler>
                  {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
                </React.Fragment>
              ) : null;
            }

            return (
              <React.Fragment key={el}>
                <DropDownToggler
                  isExpandedOnInit={((appliedFilters[el] as Array<any>) ?? []).length > 0}
                  title={tColumns(el)}
                  expandChildrenCount={5}
                >
                  <FilterSection
                    options={DEVICES_FILTER_OPTIONS()[el]}
                    checkedValues={appliedFilters[el] ?? []}
                    onFilterApply={value => onNewFilterApplied(el, value)}
                  />
                </DropDownToggler>
                {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
              </React.Fragment>
            );
          })}
        </>
      )}
    </TableFilter>
  );
}
