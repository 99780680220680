import { ActionModal, Button } from "libs/ui";
import { useTranslation } from "react-i18next";

interface DeactivateQRcodeModalProps {
  show: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  isLoading: boolean;
}

export function DeactivateQRcodeModal({
  show,
  onCancel,
  onSubmit,
  isLoading,
}: DeactivateQRcodeModalProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.login.Tabs.TwoFA.DeactivateQRcodeModal",
  });

  return (
    <ActionModal
      show={show}
      icon={{
        iconType: "Quit",
      }}
      title={t("title")}
      cancelButton={
        <Button disabled={isLoading} onClick={onCancel} buttonSize={"medium"} buttonType={"grey"}>
          {t("buttonCancel")}
        </Button>
      }
      submitButton={
        <Button disabled={isLoading} onClick={onSubmit} buttonSize={"medium"} buttonType={"red"}>
          {t("buttonSubmit")}
        </Button>
      }
    >
      {t("message")}
    </ActionModal>
  );
}
