/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BmiStatusType = {
    None: 'None',
    Underweight: 'Underweight',
    Healthy: 'Healthy',
    Overweight: 'Overweight',
    Obesity: 'Obesity'
} as const;
export type BmiStatusType = typeof BmiStatusType[keyof typeof BmiStatusType];


export function BmiStatusTypeFromJSON(json: any): BmiStatusType {
    return BmiStatusTypeFromJSONTyped(json, false);
}

export function BmiStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BmiStatusType {
    return json as BmiStatusType;
}

export function BmiStatusTypeToJSON(value?: BmiStatusType | null): any {
    return value as any;
}

