import { ReportList } from "components/ReportList";
import { Paginator } from "libs/ui/Paginator/Paginator";
import RowsPerPage, { RowsPerPageListType } from "libs/ui/RowsPerPage";
import { isDefaultModel, isLoading } from "models/loadable";
import { useEffect } from "react";
import { useReportsTableContext } from "../ReportsContext";
import "./AllReports.scss";
import { CarnaApiReportService } from "config/apiReportService";
import { toastStore } from "config/toast";
import { useTranslation } from "react-i18next";
import { SyncTableButton } from "components/SyncTableButton";

function getRowPerPageTypePerLimitValue(value: number): RowsPerPageListType {
  switch (value) {
    case 10:
      return RowsPerPageListType.Ten;
    case 20:
      return RowsPerPageListType.Twenty;
    case 50:
      return RowsPerPageListType.Fifty;
    default:
      throw new Error(`${value} not covered for RowsPerPageListType type`);
  }
}

const FILE_EXTENSION = ".xlsx" as const;

export function AllReports() {
  const { t } = useTranslation("translation", { keyPrefix: "ReportPage.AllReports" });
  const { t: tType } = useTranslation("translation", { keyPrefix: "ReportPage.RequestNew" });
  const tableState = useReportsTableContext(state => state.tableState);
  const fetchPerURLSearchParams = useReportsTableContext(state => state.fetchPerURLSearchParams);
  const limit = useReportsTableContext(state => state.limit);
  const page = useReportsTableContext(state => state.page);
  const totalPages = useReportsTableContext(state => state.totalPages);
  const queryCount = useReportsTableContext(state => state.queryCount);
  const updatePage = useReportsTableContext(state => state.updatePage);
  const updateLimit = useReportsTableContext(state => state.updateLimit);
  const items = useReportsTableContext(state => state.items);

  useEffect(() => {
    if (isDefaultModel(tableState)) {
      fetchPerURLSearchParams();
    }
  }, [fetchPerURLSearchParams, tableState]);

  const paginatorDisabled = page >= totalPages || isLoading(tableState);

  const onReportDownload = (id: string, type: string, createdAt: string) => {
    const fileTypeUnderscored = tType(`${type}Title`).replace(/\s/g, "_");
    const fileCreatedUnderscored = createdAt.replace(/\s/g, "_");

    CarnaApiReportService.Reports.getIdFile(id)
      .then(blob => {
        if (blob.size === 0) {
          throw new Error("Report is empty");
        }
        const blobUrl = window.URL.createObjectURL(blob);
        const aElem = document.createElement("a");
        aElem.href = blobUrl;
        aElem.download = `${fileTypeUnderscored}_report_${fileCreatedUnderscored}${FILE_EXTENSION}`;
        aElem.style.display = "none";
        document.body.appendChild(aElem);
        aElem.click();
        document.body.removeChild(aElem);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(() => {
        toastStore.toast.error({ msg: t("failedToDownloadToast") });
      });
  };

  return (
    <div className="AllReports">
      <SyncTableButton
        onClick={() => fetchPerURLSearchParams()}
        className="AllReports__sync-button"
        disabled={isLoading(tableState) || isDefaultModel(tableState)}
      />

      <ReportList
        reports={items}
        isLoading={isDefaultModel(tableState) || isLoading(tableState)}
        onReportDownload={onReportDownload}
      />

      {items.length > 0 ? (
        <div className="AllReports--footer">
          <RowsPerPage
            setRowsPerPage={limit => updateLimit(limit)}
            tableView={"List"}
            currentRowPerPage={getRowPerPageTypePerLimitValue(limit)}
          />
          <Paginator
            currentPage={page}
            queryCount={queryCount}
            limit={limit}
            gotoPage={page => updatePage(page)}
            disabledNext={paginatorDisabled}
          />
        </div>
      ) : null}
    </div>
  );
}
