import { ManufacturerType } from "api/query/models/ManufacturerType";
import i18n from "i18next";

export function manufacturerTypeToSelectOptions() {
  const manufacturers = Object.keys(ManufacturerType);

  return manufacturers.map(key => ({
    title: i18n.t(`manufacturerType.${key}`, { ns: "translation" }),
    value: ManufacturerType[key as keyof typeof ManufacturerType],
  }));
}
