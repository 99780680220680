import { create, interpolateRound, scaleLinear } from "d3";
import { getColorValue } from "utils";

export const maxMarkerCircleSize = 50;
export const minMarkerCircleSize = 20;

/**
 * ! Color is based on the index of params !
 * ! so it depends how many params you input
 */
export function createSVGIcon(...params: number[]) {
  const maxSvgSize = Math.max(...params);
  const colors = [getColorValue("--primary-500"), getColorValue("--secondary-500")] as const;

  const svg = create("svg")
    .attr("width", `${maxSvgSize}`)
    .attr("height", `${maxSvgSize}`)
    .attr("viewbox", `0 0 ${maxSvgSize} ${maxSvgSize}`)
    .attr("xmlns", `http://www.w3.org/2000/svg`)
    .attr("fill", "transparent");

  const filteredData = params
    .map((d, i) => ({ radius: d, originalIndex: i })) // Add original index
    .filter((d, i, arr) => {
      const smallerThanNext = d.radius <= (arr.at(i + 1)?.radius ?? 0);
      return smallerThanNext === false;
    }); // Filter

  svg
    .selectAll("circle")
    .data(filteredData)
    .join("circle")
    .attr("cx", maxSvgSize / 2)
    .attr("cy", maxSvgSize / 2)
    .attr("r", d => d.radius / 2)
    .style("fill", d => colors[d.originalIndex % colors.length])
    .style("fill-opacity", 1);

  return svg.node()?.outerHTML;
}

export function createSVGClusterIconWithText(text: string) {
  const maxSvgSize = maxMarkerCircleSize;
  const colors = [getColorValue("--primary-500")] as const;

  const svg = create("svg")
    .attr("width", `${maxSvgSize}`)
    .attr("height", `${maxSvgSize}`)
    .attr("viewbox", `0 0 ${maxSvgSize} ${maxSvgSize}`)
    .attr("xmlns", `http://www.w3.org/2000/svg`)
    .attr("fill", "transparent");

  svg
    .append("circle")
    .attr("cx", maxSvgSize / 2)
    .attr("cy", maxSvgSize / 2)
    .attr("r", maxSvgSize / 2)
    .style("fill", colors[0])
    .style("fill-opacity", 1);

  svg
    .append("text")
    .text(text)
    .attr("x", "50%")
    .attr("y", "50%")
    .attr("fill", "white")
    .attr("text-anchor", "middle")
    .attr("dominant-baseline", "central");

  return svg.node()?.outerHTML;
}

export function svgToDataURL(svg: any) {
  return `data:image/svg+xml,${encodeURIComponent(svg)}`;
}

function getMinMax(data: number[]) {
  return [Math.min(...data), Math.max(...data)];
}

export const scalarCount = (data: number[]) => {
  return scaleLinear(getMinMax(data), [5, maxMarkerCircleSize])
    .clamp(true)
    .interpolate(interpolateRound);
};
