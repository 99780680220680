import { useHover } from "@floating-ui/react";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import { MeasurementProps } from "../../model";
import { Icon } from "./../../../../Icon";
import { useTooltip } from "./../../../../Tooltip";
import "./TestResultCellContent.scss";

type TestResultCellContentProps = MeasurementProps;

export function TestResultCellContent({
  colorVal,
  label,
  unit,
  percent,
  iconVal,
  tooltip,
  children,
}: Readonly<PropsWithChildren<TestResultCellContentProps>>) {
  const [ref, getReferenceProps, Tooltip] = useTooltip({
    interactionProps: [useHover],
    placement: "top",
  });

  return (
    <div
      ref={ref}
      {...getReferenceProps()}
      className={classNames("TestResultCellContent", {
        [`TestResultCellContent--${colorVal}`]: colorVal,
      })}
    >
      <div className="TestResultCellContent__main">
        {label ? <label className="TestResultCellContent__label">{label}</label> : null}
        <div className="TestResultCellContent__value">{children}</div>
        {unit ? <div className="TestResultCellContent__unit">{unit}</div> : null}
        {tooltip && colorVal ? <Tooltip>{tooltip}</Tooltip> : null}
      </div>
      <div className="TestResultCellContent__aside">
        <div className="TestResultCellContent__percent">
          {iconVal ? <Icon scaleTo={16} icon={iconVal} /> : null}
          {percent ? <span>{percent.toString().concat("%")}</span> : null}
        </div>
      </div>
    </div>
  );
}
