import "./GeneralSectionDetails.scss";

import { SideModalElements } from "libs/ui";
import { useTranslation } from "react-i18next";

import { SectionDetailsProps } from "../model";
import { TestedByField } from "./TestedByField";
import { DeviceField } from "components/Forms/FormElements/DevicesField";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import { DetailsField } from "../../elements/DetailsField";
import { DeviceDetailsField } from "./DeviceDetailsField";
import { useSyncExternalStore } from "react";
import { avatarCache } from "utils/machines/avatars";
import { PictureResolution } from "utils/helpers/pictures.model";
import { DeviceType } from "models/DeviceModels";

export function GeneralSectionDetails({ data, loading }: Readonly<SectionDetailsProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.GeneralSectionDetails" });
  const [dateFormatter, timeFormatter] = useRegionDateTimeFormatter();

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  return (
    <div className="GeneralSectionDetails">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={t("labelDateTime")}>
        {dateFormatter(data?.test.measurementTime ?? new Date())
          .concat(" @ ")
          .concat(timeFormatter(data?.test.measurementTime ?? new Date()))}
      </DetailsField>

      {data?.test.device ? (
        <DeviceField
          readOnly={true}
          mode={"Edit"}
          organizationId={data?.test.patient.organizationId}
          deviceSerialNumber={data?.test.device?.serialNumber}
          deviceType={data?.test.device?.name as DeviceType}
          onSelect={() => ({})}
          loading={loading}
          deviceId={data?.test.device?.id}
        />
      ) : (
        <DetailsField loading={loading} label={t("testingMethodLabel")}>
          <DeviceDetailsField data={data?.test} />
        </DetailsField>
      )}

      <DetailsField loading={loading} label={t("labelTestDoneBy")}>
        <TestedByField
          data={data?.test}
          hcpAvatarImage={avatarCache.get(
            data?.test.testedBy?.organizationId,
            data?.test.testedBy?.id,
            PictureResolution.details,
            true,
          )}
        />
      </DetailsField>
    </div>
  );
}
