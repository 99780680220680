/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrganizationDetailsResponseModel } from './OrganizationDetailsResponseModel';
import {
    OrganizationDetailsResponseModelFromJSON,
    OrganizationDetailsResponseModelFromJSONTyped,
    OrganizationDetailsResponseModelToJSON,
} from './OrganizationDetailsResponseModel';

/**
 * 
 * @export
 * @interface OrganizationDetailsResponseModelListModel
 */
export interface OrganizationDetailsResponseModelListModel {
    /**
     * 
     * @type {number}
     * @memberof OrganizationDetailsResponseModelListModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDetailsResponseModelListModel
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDetailsResponseModelListModel
     */
    queryCount: number;
    /**
     * 
     * @type {Array<OrganizationDetailsResponseModel>}
     * @memberof OrganizationDetailsResponseModelListModel
     */
    items: Array<OrganizationDetailsResponseModel>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDetailsResponseModelListModel
     */
    readonly pages?: number;
}

/**
 * Check if a given object implements the OrganizationDetailsResponseModelListModel interface.
 */
export function instanceOfOrganizationDetailsResponseModelListModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "queryCount" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function OrganizationDetailsResponseModelListModelFromJSON(json: any): OrganizationDetailsResponseModelListModel {
    return OrganizationDetailsResponseModelListModelFromJSONTyped(json, false);
}

export function OrganizationDetailsResponseModelListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationDetailsResponseModelListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': json['Limit'],
        'page': json['Page'],
        'queryCount': json['QueryCount'],
        'items': ((json['Items'] as Array<any>).map(OrganizationDetailsResponseModelFromJSON)),
        'pages': !exists(json, 'Pages') ? undefined : json['Pages'],
    };
}

export function OrganizationDetailsResponseModelListModelToJSON(value?: OrganizationDetailsResponseModelListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Limit': value.limit,
        'Page': value.page,
        'QueryCount': value.queryCount,
        'Items': ((value.items as Array<any>).map(OrganizationDetailsResponseModelToJSON)),
    };
}

