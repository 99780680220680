/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import type { UserStatusType } from './UserStatusType';
import {
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface AddAdminRequestModel
 */
export interface AddAdminRequestModel {
    /**
     * 
     * @type {string}
     * @memberof AddAdminRequestModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdminRequestModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdminRequestModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdminRequestModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdminRequestModel
     */
    city: string;
    /**
     * 
     * @type {CountryType}
     * @memberof AddAdminRequestModel
     */
    country: CountryType;
    /**
     * 
     * @type {number}
     * @memberof AddAdminRequestModel
     */
    state?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddAdminRequestModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddAdminRequestModel
     */
    phone: string;
    /**
     * 
     * @type {UserStatusType}
     * @memberof AddAdminRequestModel
     */
    userStatus?: UserStatusType;
}

/**
 * Check if a given object implements the AddAdminRequestModel interface.
 */
export function instanceOfAddAdminRequestModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "phone" in value;

    return isInstance;
}

export function AddAdminRequestModelFromJSON(json: any): AddAdminRequestModel {
    return AddAdminRequestModelFromJSONTyped(json, false);
}

export function AddAdminRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddAdminRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'email': json['Email'],
        'street': json['Street'],
        'city': json['City'],
        'country': CountryTypeFromJSON(json['Country']),
        'state': !exists(json, 'State') ? undefined : json['State'],
        'zipCode': !exists(json, 'ZipCode') ? undefined : json['ZipCode'],
        'phone': json['Phone'],
        'userStatus': !exists(json, 'UserStatus') ? undefined : UserStatusTypeFromJSON(json['UserStatus']),
    };
}

export function AddAdminRequestModelToJSON(value?: AddAdminRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Email': value.email,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': value.state,
        'ZipCode': value.zipCode,
        'Phone': value.phone,
        'UserStatus': UserStatusTypeToJSON(value.userStatus),
    };
}

