import * as React from "react";
import { SVGProps } from "react";
const SvgCompass = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.951 7.997a.75.75 0 0 0-.948-.948l-6.36 2.12a.75.75 0 0 0-.474.474l-2.12 6.36a.75.75 0 0 0 .948.948l6.36-2.12a.75.75 0 0 0 .475-.474l2.12-6.36Zm-8.005 7.057 1.527-4.581 4.581-1.527-1.527 4.581-4.581 1.527Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25ZM2.75 12a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCompass;
