import { DraggableWidget, DraggableWidgetProps } from "components/DnD/DraggableWidget";
import { forwardRef, PropsWithChildren } from "react";
import "./HeatMapResultsWidget.scss";
import { HeatMapResultsWidgetHeader } from "./HeatMapResultsWidgetHeader";
import classNames from "classnames";
import { getWidgetSize } from "components/DnD/util";
import { UacrResult } from "pages/PatientsPage/Details/Overview/HeatMapWidget/UacrResult";
import { EGFRResult } from "pages/PatientsPage/Details/Overview/HeatMapWidget/EGFRResult";
import { useTranslation } from "react-i18next";
import { HeatMapResultsTable } from "./HeatMapResultsTable";

interface Props extends DraggableWidgetProps {
  w?: number;
  h?: number;
}

export const HeatMapResultsWidget = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      Header,
      optionsDropDown,
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      children, //!!! children is needed so that resize handle is passed to the component
      w,
      h,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation("translation", {
      keyPrefix: "DashboardPage.DashboardStatsPage.HeatMapResultsWidget",
    });
    const shouldShowSmall = !Number.isNaN(w) && Number(w) <= 5;

    return (
      <DraggableWidget
        ref={ref}
        optionsDropDown={optionsDropDown}
        style={style}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        Header={<HeatMapResultsWidgetHeader />}
        className="HeatMapResultsWidget"
        {...rest}
      >
        <div className={classNames("HeatMapResultsWidget__body", getWidgetSize(w, h))}>
          <div className="EGFRGroup">
            <p className="GroupTitle">{t("eGFR")}</p>
            <EGFRResult small={shouldShowSmall} />
          </div>
          <div className="UACRGroup">
            <p className="GroupTitle">{t("UACR")}</p>
            <UacrResult status={"None"} uacrType="UACR" small={shouldShowSmall} />
          </div>
          <HeatMapResultsTable small={shouldShowSmall} />
        </div>
        {children}
      </DraggableWidget>
    );
  },
);
