import * as React from "react";
import { SVGProps } from "react";
const SvgCut = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.068 9.129a3.75 3.75 0 1 1 1.06-1.06L12 10.938l7.47-7.47a.75.75 0 1 1 1.06 1.061L13.06 12l7.47 7.47a.75.75 0 1 1-1.06 1.06L12 13.06l-2.871 2.872a3.75 3.75 0 1 1-1.06-1.06L10.938 12 8.068 9.129ZM3.75 6a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Zm0 12a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCut;
