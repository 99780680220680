/* tslint:disable */
 
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FileGenerationStatusType = {
    Requested: 'Requested',
    InProgress: 'InProgress',
    Success: 'Success',
    Failed: 'Failed'
} as const;
export type FileGenerationStatusType = typeof FileGenerationStatusType[keyof typeof FileGenerationStatusType];


export function FileGenerationStatusTypeFromJSON(json: any): FileGenerationStatusType {
    return FileGenerationStatusTypeFromJSONTyped(json, false);
}

export function FileGenerationStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileGenerationStatusType {
    return json as FileGenerationStatusType;
}

export function FileGenerationStatusTypeToJSON(value?: FileGenerationStatusType | null): any {
    return value as any;
}

