import { PropsWithChildren, RefObject, useMemo, useRef } from "react";
import { createSafeContext } from "utils/createSafeContext";

interface DetailsPageContext {
  detailsPageSideBarRef: RefObject<HTMLDivElement>;
}
const Context = createSafeContext<DetailsPageContext>();

export const useDetailsPageContext = Context.hook;

export const DetailsPageContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const detailsPageSideBarRef = useRef<HTMLDivElement>(null);

  const value = useMemo<DetailsPageContext>(() => ({ detailsPageSideBarRef }), []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
