import * as React from "react";
import { SVGProps } from "react";
const SvgCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5ZM8.75 13a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2.25a.75.75 0 0 0-.624.334L6.599 5.25H3A2.75 2.75 0 0 0 .25 8v11A2.75 2.75 0 0 0 3 21.75h18A2.75 2.75 0 0 0 23.75 19V8A2.75 2.75 0 0 0 21 5.25h-3.599l-1.777-2.666A.75.75 0 0 0 15 2.25H9ZM7.624 6.416 9.401 3.75H14.6l1.777 2.666A.75.75 0 0 0 17 6.75h4A1.25 1.25 0 0 1 22.25 8v11A1.25 1.25 0 0 1 21 20.25H3A1.25 1.25 0 0 1 1.75 19V8A1.25 1.25 0 0 1 3 6.75h4a.75.75 0 0 0 .624-.334Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCamera;
