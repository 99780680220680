import * as React from "react";
import { SVGProps } from "react";
const SvgHeartEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.557 2.724A6.25 6.25 0 0 1 21.37 12.92l-8.84 8.84a.75.75 0 0 1-1.06 0l-8.84-8.84a6.251 6.251 0 1 1 8.84-8.84l.53.53.53-.53a6.25 6.25 0 0 1 2.027-1.356ZM20.31 5.14a4.75 4.75 0 0 0-6.719 0L12.53 6.2a.75.75 0 0 1-1.06 0l-1.06-1.06a4.751 4.751 0 0 0-6.72 6.72L12 20.17l8.31-8.31a4.75 4.75 0 0 0 0-6.72Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgHeartEmpty;
