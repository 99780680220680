import { PropsWithChildren } from "react";
import { IconCell } from "../IconCell";
import { roleToColor, roleToIcon } from "../utils";
import { UserRoleType } from "models/PersonModels";

interface UserRoleCellProps {
  role: UserRoleType;
  label: string;
  show?: boolean;
  loading?: boolean;
}

export function UserRoleCell({
  role,
  label,
  show = true,
  loading,
}: PropsWithChildren<UserRoleCellProps>) {
  return (
    <IconCell
      loading={loading}
      show={show}
      icon={roleToIcon(role)}
      style={{ color: roleToColor(role) }}
    >
      <span>{label}</span>
    </IconCell>
  );
}
