import { PropsWithChildren } from "react";
import { RuleEngineSectionDescription } from "../RuleEngineSectionDescription";
import { RuleEngineSectionSubtitle } from "../RuleEngineSectionSubtitle";
import { RuleEngineSectionTitle } from "../RuleEngineSectionTitle";
import "./RuleEngineFormElement.scss";

interface RuleEngineFormElementProps {
  title?: string;
  subtitle?: string;
  description?: string;
}

export function RuleEngineFormElement({
  title,
  subtitle,
  description,
  children,
}: PropsWithChildren<RuleEngineFormElementProps>) {
  return (
    <div className="RuleEngineFormElement">
      {title ? <RuleEngineSectionTitle>{title}</RuleEngineSectionTitle> : null}
      {subtitle ? <RuleEngineSectionSubtitle>{subtitle}</RuleEngineSectionSubtitle> : null}
      {description ? (
        <RuleEngineSectionDescription>{description}</RuleEngineSectionDescription>
      ) : null}
      {children}
    </div>
  );
}
