import { useGraphDataAPIContext } from "pages/PatientsPage/Details/Graph/GraphDataAPIContext";
import { useMemo } from "react";
import { useGetUrineCreatinineRanges } from "utils/hooks/useGetUrineCreatinineRanges";

export function useGetUrineCreatinineNormalRange(): [number | undefined, number | undefined] {
  const { data } = useGraphDataAPIContext();
  const [, getRangePoints] = useGetUrineCreatinineRanges();

  return useMemo(() => {
    const [from, to] = getRangePoints(data.UrineCreatinine.value?.genderType);

    return [from, to];
  }, [data.UrineCreatinine.value?.genderType, getRangePoints]);
}
