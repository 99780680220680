import { HCPTableColumns } from "utils/createGlobalConfigStore";

interface HCPTableColumnsProps {
  t: TFunction;
  hasActionRights: boolean;
  isPatient: boolean;
}

const allHCPTableColumns = ({ t, hasActionRights, isPatient }: HCPTableColumnsProps) => {
  return (
    [
      "userStatuses",
      "role",
      "organizationIds",
      "email",
      ...(isPatient ? ["phone"] : []),
      "address",
      "countries",
      ...(!isPatient ? ["withDevice"] : []),
      ...(hasActionRights ? ["actions"] : []),
    ] as const
  ).map(name => ({
    label: t(`columns.${name}`),
    name,
  }));
};

interface AppliedColumnsProps extends HCPTableColumnsProps {
  appliedColumns: HCPTableColumns[];
}

export function resolveAppliedColumns({ appliedColumns, ...rest }: AppliedColumnsProps) {
  return allHCPTableColumns(rest).filter(column => appliedColumns.some(el => el === column.name));
}
