import * as React from "react";
import { SVGProps } from "react";
const SvgRuleEngine = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.167 4.083h-2.22A2.912 2.912 0 0 0 16.168 2c-1.317 0-2.42.882-2.781 2.083H2.833a.834.834 0 0 0 0 1.667h10.553a2.912 2.912 0 0 0 2.78 2.083c1.317 0 2.42-.882 2.782-2.083h2.219a.833.833 0 1 0 0-1.667Zm-5 2.084c-.69 0-1.25-.561-1.25-1.25 0-.69.56-1.25 1.25-1.25.689 0 1.25.56 1.25 1.25 0 .689-.561 1.25-1.25 1.25ZM21.167 17.417h-2.22a2.91 2.91 0 0 0-2.78-2.084c-1.317 0-2.42.883-2.781 2.084H2.833a.834.834 0 0 0 0 1.666h10.553a2.91 2.91 0 0 0 2.78 2.084c1.317 0 2.42-.883 2.782-2.084h2.219a.833.833 0 1 0 0-1.666Zm-5 2.083c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25c.689 0 1.25.56 1.25 1.25s-.561 1.25-1.25 1.25ZM10.614 10.75h10.553a.833.833 0 1 1 0 1.667H10.614a2.911 2.911 0 0 1-2.78 2.083 2.911 2.911 0 0 1-2.781-2.083h-2.22a.834.834 0 0 1 0-1.667h2.22a2.911 2.911 0 0 1 2.78-2.083c1.317 0 2.42.882 2.781 2.083Zm-4.03.833c0 .69.56 1.25 1.25 1.25.689 0 1.25-.56 1.25-1.25 0-.689-.561-1.25-1.25-1.25-.69 0-1.25.561-1.25 1.25Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgRuleEngine;
