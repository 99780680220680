import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Modal, ModalProps } from "../../ModalBase";
import "./SideModal.scss";

export interface SideModalProps extends ModalProps {
  bodyClass?: string;
}

export function SideModal({ bodyClass, children, ...rest }: PropsWithChildren<SideModalProps>) {
  return (
    <Modal {...rest}>
      <div className={classNames("SideModal", bodyClass)}>{children}</div>
    </Modal>
  );
}
