import { UserGraphModel } from "api/query";
import CarnaLineChart from "components/Chart/CarnaChart/LineChart";
import { useGetMaxSerumCreatinineValueFromRuleEngineUnit } from "components/Chart/hooks/useGetMaxSerumCreatinineValueFromRuleEngineUnit";
import { useGetSerumCreatinineNormalRange } from "components/Chart/hooks/useGetSerumCreatinineNormalRange";
import { ECOption, Formatters } from "components/Chart/model";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useCallback } from "react";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { getOptions as getInitialOptions } from "./model";
import { useHideClosestTick } from "./useHideClosestTick";
// const CarnaLineChart = React.lazy(() => import("components/Chart/CarnaChart/LineChart"));

export function SerumCreatinine() {
  const serumCreatinineNormalRange = useGetSerumCreatinineNormalRange();
  const maxSerumCreatinineRange = useGetMaxSerumCreatinineValueFromRuleEngineUnit();
  const { serumCreatinineUnit } = useGetUserPreferredMeasurementUnits();
  const { appConfig } = useGlobalConfigContext();

  const getOptions = useCallback(
    (formatters: Formatters, graphUser: UserGraphModel): ECOption =>
      getInitialOptions({
        formatters,
        data: graphUser,
        measurementUnit: serumCreatinineUnit,
        maxCreatinineRange: maxSerumCreatinineRange,
        creatinineNormalRange: serumCreatinineNormalRange,
        showTrending: appConfig?.components.graphs.Patient.Index.showTrending,
      }),
    [
      serumCreatinineUnit,
      maxSerumCreatinineRange,
      serumCreatinineNormalRange,
      appConfig?.components.graphs.Patient.Index.showTrending,
    ],
  );

  const afterInit = useHideClosestTick();

  return (
    <CarnaLineChart
      showTrend={appConfig?.components.graphs.Patient.Index.showTrending}
      dataType="SerumCreatinine"
      afterInit={afterInit}
      unitType="serumCreatinine"
      className="SerumCreatinine"
      getOptions={getOptions}
      measurementUnit={serumCreatinineUnit}
    />
  );
}

export default SerumCreatinine;
