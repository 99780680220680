import { CREATININE_CORRECTION_FACTOR } from "config/const";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { round } from "lodash-es";

export function getFormattedCreatininePerMeasurementUnit(
  value: number,
  measurementUnit?: MEASUREMENT_UNITS,
) {
  switch (measurementUnit) {
    case MEASUREMENT_UNITS.umolL:
      return round(value * CREATININE_CORRECTION_FACTOR, 2);
    default:
      return round(value, 2);
  }
}
