import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useMemo } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useUrineAlbuminValueConvertor } from "./useUrineAlbuminValueConvertor";

type UrineAlbuminLimitKey = "Normal" | "Microalbuminuria" | "Macroalbuminuria";

interface SingleStageRange {
  from?: number;
  to?: number;
}

export type UrineAlbuminStageRanges = {
  [key in UrineAlbuminLimitKey]: SingleStageRange;
};

function getRowRanges(
  data: any,
  key: UrineAlbuminLimitKey,
  getUrineAlbuminValue: (value: string) => number,
) {
  if (Object.hasOwn(data, key) === false) {
    throw new Error(`${data} has no ${key} key`);
  }

  switch (key) {
    case "Normal":
      return {
        to: getUrineAlbuminValue(data[key].value.toString()),
      } as const;

    case "Microalbuminuria":
      return {
        from: getUrineAlbuminValue(data[key].from.toString()),
        to: getUrineAlbuminValue(data[key].to.toString()),
      } as const;

    case "Macroalbuminuria":
      return {
        from: getUrineAlbuminValue(data[key].value.toString()),
      } as const;

    default:
      throw new Error(`${key} not covered`);
  }
}

const orderedAlbuminStages: UrineAlbuminLimitKey[] = [
  "Normal",
  "Microalbuminuria",
  "Macroalbuminuria",
];

export function useGetUrineAlbuminRanges() {
  const { appConfig } = useGlobalConfigContext();
  const [getUrineAlbuminValue] = useUrineAlbuminValueConvertor();

  let orderedUrineAlbuminStageRanges: UrineAlbuminStageRanges = useMemo(
    () => ({
      Normal: {},
      Microalbuminuria: {},
      Macroalbuminuria: {},
    }),
    [],
  );

  const albuminLimits = NotNullOrUndefined(
    appConfig?.laboratory.measurements.limits?.urine.albumine.limits,
  );

  orderedAlbuminStages.forEach(stage => {
    orderedUrineAlbuminStageRanges = {
      ...orderedUrineAlbuminStageRanges,
      [stage]: getRowRanges(albuminLimits, stage, getUrineAlbuminValue),
    };
  });

  const urineAlbuminRangePoints = useMemo(() => {
    const set = new Set(
      Object.values(orderedUrineAlbuminStageRanges)
        .flatMap(el => [el.from, el.to]) // can get [undefined, NUMBER] or [NUMBER, undefined]
        .filter(el => typeof el === "number") as number[], // typescript is stupid
    );

    return Array.from(set);
  }, [orderedUrineAlbuminStageRanges]);

  return useMemo(
    () => ({ orderedUrineAlbuminStageRanges, urineAlbuminRangePoints }),
    [orderedUrineAlbuminStageRanges, urineAlbuminRangePoints],
  );
}
