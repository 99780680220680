/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddPatientRequestModel,
  CommonProblemDetailsModel,
  ResendInviteUserRequestModel,
  UpdatePatientRequestModel,
  UpdateUserStatusRequestModel,
} from '../models/index';
import {
    AddPatientRequestModelFromJSON,
    AddPatientRequestModelToJSON,
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    ResendInviteUserRequestModelFromJSON,
    ResendInviteUserRequestModelToJSON,
    UpdatePatientRequestModelFromJSON,
    UpdatePatientRequestModelToJSON,
    UpdateUserStatusRequestModelFromJSON,
    UpdateUserStatusRequestModelToJSON,
} from '../models/index';

export interface OrganizationsOrganizationIdPatientsPostRequest {
    organizationId: string;
    acceptVersion?: string;
    addPatientRequestModel?: AddPatientRequestModel;
}

export interface OrganizationsOrganizationIdPatientsUserEntityIdDeleteRequest {
    organizationId: string;
    userEntityId: string;
    rowVersion?: string;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdPatientsUserEntityIdPatchRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    updateUserStatusRequestModel?: UpdateUserStatusRequestModel;
}

export interface OrganizationsOrganizationIdPatientsUserEntityIdPutRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    updatePatientRequestModel?: UpdatePatientRequestModel;
}

export interface OrganizationsOrganizationIdPatientsUserEntityIdResendInvitePostRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    resendInviteUserRequestModel?: ResendInviteUserRequestModel;
}

/**
 * PatientsApi - interface
 * 
 * @export
 * @interface PatientsApiInterface
 */
export interface PatientsApiInterface {
    /**
     * 
     * @param {string} organizationId 
     * @param {string} [acceptVersion] 
     * @param {AddPatientRequestModel} [addPatientRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApiInterface
     */
    organizationsOrganizationIdPatientsPostRaw(requestParameters: OrganizationsOrganizationIdPatientsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdPatientsPost(requestParameters: OrganizationsOrganizationIdPatientsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [rowVersion] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApiInterface
     */
    organizationsOrganizationIdPatientsUserEntityIdDeleteRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdPatientsUserEntityIdDelete(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {UpdateUserStatusRequestModel} [updateUserStatusRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApiInterface
     */
    organizationsOrganizationIdPatientsUserEntityIdPatchRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdPatientsUserEntityIdPatch(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {UpdatePatientRequestModel} [updatePatientRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApiInterface
     */
    organizationsOrganizationIdPatientsUserEntityIdPutRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdPatientsUserEntityIdPut(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {ResendInviteUserRequestModel} [resendInviteUserRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApiInterface
     */
    organizationsOrganizationIdPatientsUserEntityIdResendInvitePostRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdPatientsUserEntityIdResendInvitePost(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class PatientsApi extends runtime.BaseAPI implements PatientsApiInterface {

     /**
     */
    async organizationsOrganizationIdPatientsPostRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPatientRequestModelToJSON(requestParameters.addPatientRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsPostRaw(requestParameters: OrganizationsOrganizationIdPatientsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPatientRequestModelToJSON(requestParameters.addPatientRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdPatientsPost(requestParameters: OrganizationsOrganizationIdPatientsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdPatientsPostRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdDeleteRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdDelete.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowVersion !== undefined) {
            queryParameters['rowVersion'] = requestParameters.rowVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdDeleteRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdDelete.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowVersion !== undefined) {
            queryParameters['rowVersion'] = requestParameters.rowVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdDelete(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdPatientsUserEntityIdDeleteRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdPatchRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdPatch.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserStatusRequestModelToJSON(requestParameters.updateUserStatusRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdPatchRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdPatch.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserStatusRequestModelToJSON(requestParameters.updateUserStatusRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdPatch(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdPatientsUserEntityIdPatchRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdPutRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdPut.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePatientRequestModelToJSON(requestParameters.updatePatientRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdPutRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdPut.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePatientRequestModelToJSON(requestParameters.updatePatientRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdPut(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdPatientsUserEntityIdPutRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdResendInvitePostRawOriginal(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdResendInvitePost.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdResendInvitePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}/resend-invite`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendInviteUserRequestModelToJSON(requestParameters.resendInviteUserRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdResendInvitePostRaw(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdResendInvitePost.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPatientsUserEntityIdResendInvitePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{userEntityId}/resend-invite`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendInviteUserRequestModelToJSON(requestParameters.resendInviteUserRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdPatientsUserEntityIdResendInvitePost(requestParameters: OrganizationsOrganizationIdPatientsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdPatientsUserEntityIdResendInvitePostRaw(requestParameters, initOverrides);
    }

}
