/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileGenerationStatusType } from './FileGenerationStatusType';
import {
    FileGenerationStatusTypeFromJSON,
    FileGenerationStatusTypeFromJSONTyped,
    FileGenerationStatusTypeToJSON,
} from './FileGenerationStatusType';
import type { ReportDownloadModel } from './ReportDownloadModel';
import {
    ReportDownloadModelFromJSON,
    ReportDownloadModelFromJSONTyped,
    ReportDownloadModelToJSON,
} from './ReportDownloadModel';
import type { ReportUserModel } from './ReportUserModel';
import {
    ReportUserModelFromJSON,
    ReportUserModelFromJSONTyped,
    ReportUserModelToJSON,
} from './ReportUserModel';

/**
 * 
 * @export
 * @interface ReportResponseModel
 */
export interface ReportResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ReportResponseModel
     */
    entityId: string;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseModel
     */
    rowVersion: string;
    /**
     * 
     * @type {Date}
     * @memberof ReportResponseModel
     */
    requestedAtDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseModel
     */
    reportType: string;
    /**
     * 
     * @type {FileGenerationStatusType}
     * @memberof ReportResponseModel
     */
    status: FileGenerationStatusType;
    /**
     * 
     * @type {number}
     * @memberof ReportResponseModel
     */
    reportId: number;
    /**
     * 
     * @type {Date}
     * @memberof ReportResponseModel
     */
    fileGeneratedAtDate?: Date | null;
    /**
     * 
     * @type {Array<ReportDownloadModel>}
     * @memberof ReportResponseModel
     */
    downloads?: Array<ReportDownloadModel> | null;
    /**
     * 
     * @type {ReportUserModel}
     * @memberof ReportResponseModel
     */
    requestedBy?: ReportUserModel;
}

/**
 * Check if a given object implements the ReportResponseModel interface.
 */
export function instanceOfReportResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entityId" in value;
    isInstance = isInstance && "rowVersion" in value;
    isInstance = isInstance && "requestedAtDate" in value;
    isInstance = isInstance && "reportType" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "reportId" in value;

    return isInstance;
}

export function ReportResponseModelFromJSON(json: any): ReportResponseModel {
    return ReportResponseModelFromJSONTyped(json, false);
}

export function ReportResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': json['EntityId'],
        'rowVersion': json['RowVersion'],
        'requestedAtDate': json['RequestedAtDate'],
        'reportType': json['ReportType'],
        'status': FileGenerationStatusTypeFromJSON(json['Status']),
        'reportId': json['ReportId'],
        'fileGeneratedAtDate': !exists(json, 'FileGeneratedAtDate') ? undefined : json['FileGeneratedAtDate'],
        'downloads': !exists(json, 'Downloads') ? undefined : (json['Downloads'] === null ? null : (json['Downloads'] as Array<any>).map(ReportDownloadModelFromJSON)),
        'requestedBy': !exists(json, 'RequestedBy') ? undefined : ReportUserModelFromJSON(json['RequestedBy']),
    };
}

export function ReportResponseModelToJSON(value?: ReportResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EntityId': value.entityId,
        'RowVersion': value.rowVersion,
        'RequestedAtDate': value.requestedAtDate,
        'ReportType': value.reportType,
        'Status': FileGenerationStatusTypeToJSON(value.status),
        'ReportId': value.reportId,
        'FileGeneratedAtDate': value.fileGeneratedAtDate,
        'Downloads': value.downloads === undefined ? undefined : (value.downloads === null ? null : (value.downloads as Array<any>).map(ReportDownloadModelToJSON)),
        'RequestedBy': ReportUserModelToJSON(value.requestedBy),
    };
}

