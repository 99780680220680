import { signOut } from "@aws-amplify/auth";
import { useCallback } from "react";

import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";

export function useClearAndReloadIfSameUser() {
  const { currentUserId } = useAdditionalAuthInformationContext();

  const clearAndReloadIfSameUser = useCallback(
    async (userId: string | undefined | null) => {
      if (userId === currentUserId) {
        await signOut({ global: true });
      }
    },
    [currentUserId],
  );

  return [clearAndReloadIfSameUser] as const;
}
