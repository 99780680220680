import * as React from "react";
import { SVGProps } from "react";
const SvgChat = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 12a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1ZM12 11a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12ZM16 11a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 12c0 6.075-4.89 11-10.921 11-1.38 0-2.702-.258-3.917-.729a1.051 1.051 0 0 0-.504-.066l-5.364.64a1 1 0 0 1-1.109-1.135l.754-5.25a1.05 1.05 0 0 0-.06-.523A11.044 11.044 0 0 1 1.157 12c0-6.075 4.89-11 10.92-11C18.11 1 23 5.925 23 12Zm-1.5 0c0 5.257-4.228 9.5-9.421 9.5a9.326 9.326 0 0 1-3.376-.628 2.55 2.55 0 0 0-1.223-.157l-4.717.564.661-4.606a2.549 2.549 0 0 0-.144-1.27A9.545 9.545 0 0 1 2.658 12c0-5.257 4.228-9.5 9.42-9.5 5.194 0 9.422 4.243 9.422 9.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgChat;
