/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UacrStatusType = {
    None: 'None',
    Normal: 'Normal',
    Microalbuminuria: 'Microalbuminuria',
    Macroalbuminuria: 'Macroalbuminuria'
} as const;
export type UacrStatusType = typeof UacrStatusType[keyof typeof UacrStatusType];


export function UacrStatusTypeFromJSON(json: any): UacrStatusType {
    return UacrStatusTypeFromJSONTyped(json, false);
}

export function UacrStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UacrStatusType {
    return json as UacrStatusType;
}

export function UacrStatusTypeToJSON(value?: UacrStatusType | null): any {
    return value as any;
}

