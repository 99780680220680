import { TimeZoneType } from "api/query";
import { format } from "date-fns-tz";
import { getCurrentGlobalPreferences } from "utils/getCurrentGlobalPreferences";

export function setCurrentSecOnDateTime(date: Date, timeZone: TimeZoneType | undefined) {
  const tempDate = new Date(date);
  tempDate.setSeconds(new Date(date).getSeconds());
  tempDate.setMilliseconds(new Date(date).getMilliseconds());

  const result = format(tempDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSxxx", {
    timeZone: timeZone ?? getCurrentGlobalPreferences().timeZoneType,
  }) as unknown as Date;
  // The reason we use unknown here it's cause the date format required on the backend has timezone difference attach on the end of the date
  // example: 2023-08-24T12:28:06.9193284+02:00 which is not valid as a javascript Date only as a string
  // this is kinda workaround solution

  return result;
}
