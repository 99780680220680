import classNames from "classnames";
import "./Count.scss";
import { SingleStop } from "./SingleStop";

interface CountProps {
  maxPatientsNumber: number;
}

const LINES_COUNT = 5;

export function Count({ maxPatientsNumber }: CountProps) {
  const stops = Array.from({ length: LINES_COUNT }).map((_, index) => {
    return Math.ceil(((index + 1) / LINES_COUNT) * maxPatientsNumber);
  });

  return (
    <div className="Count">
      <div className="Empty"></div>
      <div className="Counter">
        <div className="Stops">
          {stops.map((el, index) => (
            <div className={classNames("Stop", { "Stop--two": index === 0 })} key={el}>
              {index === 0 ? <div className="Value Value--zero">0</div> : null}
              <SingleStop number={el} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
