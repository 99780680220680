import React, { useCallback, useState } from "react";
import { Button, Icon, Input, TooltipComponent, useGetTooltipProps, useTooltip } from "libs/ui";
import { useTranslation } from "react-i18next";
import "./ConfirmResetPassword.scss";
import { useClick, useFocus, useHover, useRole } from "@floating-ui/react";
import LoginPagesHeader from "layout/Login/LoginPagesHeader";
import { PasswordStrength } from "components/PasswordStrength";
import { passwordStrength } from "utils/validators/passwordStrength";

interface ConfirmResetPasswordProps {
  submit: (formData: { code: string; password: string }) => void;
  errorCode: string;
}

const iconPosition = "trailing";

export default function ConfirmResetPassword({ submit, errorCode }: ConfirmResetPasswordProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Login" });

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const codeError = errorCode === "CodeMismatchException" || errorCode === "ExpiredCodeException";

  const [codeIconRef, , CodeIconTooltip] = useTooltip({
    placement: "right",
    mainAxis: 20,
    interactionProps: [useHover],
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmationVisible, setPasswordConfirmationVisible] = useState(false);

  const {
    x,
    y,
    refs,
    strategy,
    arrowRef,
    arrowX,
    arrowY,
    open,
    getReferenceProps,
    getFloatingProps,
  } = useGetTooltipProps({
    interactionProps: [useFocus, useRole, useClick],
    placement: "top",
    crossAxis: 0,
    mainAxis: 20,
  });

  const passwordStrengthResult = passwordStrength(password);
  const validationFailed = Object.values(passwordStrengthResult).some(v => v === false);

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      submit({ code, password });
    },
    [code, password, submit],
  );

  return (
    <form className="ConfirmResetPassword" onSubmit={onSubmit}>
      <LoginPagesHeader
        title={t("ConfirmResetPassword.title")}
        text={[t("ConfirmResetPassword.text")]}
      />
      <div className="ConfirmResetPassword__inputContainer">
        <Input
          value={code}
          onChange={e => setCode(e.target.value)}
          inputIcon={{
            icon: <Icon icon="Info" ref={codeIconRef} />,
            iconPosition,
          }}
          placeholder={t("ConfirmResetPassword.code")}
          inputClassName="ConfirmResetPassword__input"
          validation={{ errorText: codeError ? t("ConfirmResetPassword.codeError") : "" }}
        />
        <CodeIconTooltip>
          <div className="ConfirmResetPassword__tooltipContainer">
            <p>{t("ConfirmResetPassword.codeTooltipOne")}</p>
            <p>{t("ConfirmResetPassword.codeTooltipTwo")}</p>
          </div>
        </CodeIconTooltip>
        <Input
          value={password}
          onChange={e => setPassword(e.target.value)}
          type={passwordVisible ? "text" : "password"}
          inputIcon={{
            icon: (
              <Icon
                icon={passwordVisible ? "Show" : "Hide"}
                onClick={() => setPasswordVisible(prev => !prev)}
              />
            ),
            iconPosition,
          }}
          placeholder={t("ConfirmResetPassword.newPassword")}
          inputClassName="ConfirmResetPassword__input"
          ref={refs.setReference}
          {...getReferenceProps()}
        />

        <TooltipComponent
          type="white"
          ref={refs.setFloating}
          x={x}
          y={y}
          floatingProps={getFloatingProps()}
          open={open}
          strategy={strategy}
          arrowRef={arrowRef}
          arrowX={arrowX}
          arrowY={arrowY}
          arrowSize="big"
        >
          <PasswordStrength
            minLength={passwordStrengthResult.minLength}
            atLeastOneUppercase={passwordStrengthResult.atLeastOneUppercase}
            atLeastOneNumber={passwordStrengthResult.atLeastOneNumber}
            atLeastOneSpecial={passwordStrengthResult.atLeastOneSpecial}
          />
        </TooltipComponent>

        <Input
          value={passwordConfirmation}
          onChange={e => setPasswordConfirmation(e.target.value)}
          type={passwordConfirmationVisible ? "text" : "password"}
          inputIcon={{
            icon: (
              <Icon
                icon={passwordConfirmationVisible ? "Show" : "Hide"}
                onClick={() => setPasswordConfirmationVisible(prev => !prev)}
              />
            ),
            iconPosition,
          }}
          placeholder={t("ConfirmResetPassword.confirmNewPassword")}
          inputClassName="ConfirmResetPassword__input"
        />
        <Button
          type="submit"
          buttonType="primary"
          buttonSize="large"
          className="ConfirmResetPassword__actionButton"
          disabled={password !== passwordConfirmation || validationFailed || !code}
        >
          {t("ConfirmResetPassword.button")}
        </Button>
      </div>
    </form>
  );
}
