/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddAdminRequestModel,
  CommonProblemDetailsModel,
  ReplaceAdminRequestModel,
  ResendInviteUserRequestModel,
  UpdateUserStatusRequestModel,
} from '../models/index';
import {
    AddAdminRequestModelFromJSON,
    AddAdminRequestModelToJSON,
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    ReplaceAdminRequestModelFromJSON,
    ReplaceAdminRequestModelToJSON,
    ResendInviteUserRequestModelFromJSON,
    ResendInviteUserRequestModelToJSON,
    UpdateUserStatusRequestModelFromJSON,
    UpdateUserStatusRequestModelToJSON,
} from '../models/index';

export interface OrganizationsOrganizationIdAdministratorsPostRequest {
    organizationId: string;
    acceptVersion?: string;
    addAdminRequestModel?: AddAdminRequestModel;
}

export interface OrganizationsOrganizationIdAdministratorsUserEntityIdDeleteRequest {
    organizationId: string;
    userEntityId: string;
    rowVersion?: string;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdAdministratorsUserEntityIdPatchRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    updateUserStatusRequestModel?: UpdateUserStatusRequestModel;
}

export interface OrganizationsOrganizationIdAdministratorsUserEntityIdPutRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    replaceAdminRequestModel?: ReplaceAdminRequestModel;
}

export interface OrganizationsOrganizationIdAdministratorsUserEntityIdResendInvitePostRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    resendInviteUserRequestModel?: ResendInviteUserRequestModel;
}

/**
 * AdministratorsApi - interface
 * 
 * @export
 * @interface AdministratorsApiInterface
 */
export interface AdministratorsApiInterface {
    /**
     * 
     * @param {string} organizationId 
     * @param {string} [acceptVersion] 
     * @param {AddAdminRequestModel} [addAdminRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministratorsApiInterface
     */
    organizationsOrganizationIdAdministratorsPostRaw(requestParameters: OrganizationsOrganizationIdAdministratorsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdAdministratorsPost(requestParameters: OrganizationsOrganizationIdAdministratorsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [rowVersion] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministratorsApiInterface
     */
    organizationsOrganizationIdAdministratorsUserEntityIdDeleteRaw(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdAdministratorsUserEntityIdDelete(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {UpdateUserStatusRequestModel} [updateUserStatusRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministratorsApiInterface
     */
    organizationsOrganizationIdAdministratorsUserEntityIdPatchRaw(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdAdministratorsUserEntityIdPatch(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {ReplaceAdminRequestModel} [replaceAdminRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministratorsApiInterface
     */
    organizationsOrganizationIdAdministratorsUserEntityIdPutRaw(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdAdministratorsUserEntityIdPut(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {ResendInviteUserRequestModel} [resendInviteUserRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministratorsApiInterface
     */
    organizationsOrganizationIdAdministratorsUserEntityIdResendInvitePostRaw(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdAdministratorsUserEntityIdResendInvitePost(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class AdministratorsApi extends runtime.BaseAPI implements AdministratorsApiInterface {

     /**
     */
    async organizationsOrganizationIdAdministratorsPostRawOriginal(requestParameters: OrganizationsOrganizationIdAdministratorsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/administrators`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddAdminRequestModelToJSON(requestParameters.addAdminRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsPostRaw(requestParameters: OrganizationsOrganizationIdAdministratorsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddAdminRequestModelToJSON(requestParameters.addAdminRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsPost(requestParameters: OrganizationsOrganizationIdAdministratorsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdAdministratorsPostRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdAdministratorsUserEntityIdDeleteRawOriginal(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdDelete.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowVersion !== undefined) {
            queryParameters['rowVersion'] = requestParameters.rowVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/administrators/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsUserEntityIdDeleteRaw(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdDelete.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowVersion !== undefined) {
            queryParameters['rowVersion'] = requestParameters.rowVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsUserEntityIdDelete(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdAdministratorsUserEntityIdDeleteRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdAdministratorsUserEntityIdPatchRawOriginal(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdPatch.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/administrators/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserStatusRequestModelToJSON(requestParameters.updateUserStatusRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsUserEntityIdPatchRaw(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdPatch.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserStatusRequestModelToJSON(requestParameters.updateUserStatusRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsUserEntityIdPatch(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdAdministratorsUserEntityIdPatchRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdAdministratorsUserEntityIdPutRawOriginal(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdPut.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/administrators/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReplaceAdminRequestModelToJSON(requestParameters.replaceAdminRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsUserEntityIdPutRaw(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdPut.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReplaceAdminRequestModelToJSON(requestParameters.replaceAdminRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsUserEntityIdPut(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdAdministratorsUserEntityIdPutRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdAdministratorsUserEntityIdResendInvitePostRawOriginal(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdResendInvitePost.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdResendInvitePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/administrators/{userEntityId}/resend-invite`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendInviteUserRequestModelToJSON(requestParameters.resendInviteUserRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsUserEntityIdResendInvitePostRaw(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdResendInvitePost.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdAdministratorsUserEntityIdResendInvitePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators/{userEntityId}/resend-invite`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendInviteUserRequestModelToJSON(requestParameters.resendInviteUserRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsUserEntityIdResendInvitePost(requestParameters: OrganizationsOrganizationIdAdministratorsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdAdministratorsUserEntityIdResendInvitePostRaw(requestParameters, initOverrides);
    }

}
