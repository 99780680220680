import { PatientAgeRange } from "api/report_service/models/PatientAgeRange";
import { Dispatch, PropsWithChildren, useMemo, useReducer } from "react";
import { useSearchParams } from "react-router-dom";
import { createSafeContext } from "utils/createSafeContext";
import { isKeyInFilterWithAdditionalFields } from "./helper";
import { IdentifiedWithCkdRiskFilterModel } from "api/report_service/models/IdentifiedWithCkdRiskFilterModel";
import { AncestryFilter } from "api/report_service/models/AncestryFilter";
import { GenderFilter } from "api/report_service/models/GenderFilter";

export const FilterType = {
  onlyGenders: "onlyGenders",
  onlyAgeRanges: "onlyAgeRanges",
  onlyAncestries: "onlyAncestries",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FilterType = "onlyGenders" | "onlyAgeRanges" | "onlyAncestries";

export type FilterTypeValues = PatientAgeRange | GenderFilter | AncestryFilter;

export type MapFiltersState = {
  onlyAgeRanges: PatientAgeRange[];
  onlyAncestries: AncestryFilter[];
  onlyGenders: GenderFilter[];
};

interface DashboardMapContextData {
  filters: MapFiltersState;
  dispatchFilters: Dispatch<Action>;
}

interface Action {
  type: FilterType;
  payload?: FilterTypeValues[];
}

function reducer(state: MapFiltersState, action: Action): MapFiltersState {
  if (Object.keys(FilterType).every(el => el !== action.type)) {
    throw new Error(`${action.type} not covered`);
  }

  return {
    ...state,
    [action.type]: action.payload,
  };
}

const Context = createSafeContext<DashboardMapContextData>();

export const useDashboardMapContext = Context.hook;

export function DashboardMapContext({ children }: Readonly<PropsWithChildren>) {
  const [searchParams] = useSearchParams();

  const initState = {
    onlyGenders: [],
    onlyAgeRanges: [],
    onlyAncestries: [],
  } as any;

  for (const [key, value] of searchParams.entries()) {
    if (
      !initState.hasOwnProperty(key) &&
      isKeyInFilterWithAdditionalFields(key) &&
      key !== "groupBy" &&
      key !== "reportType"
    ) {
      initState[key as keyof IdentifiedWithCkdRiskFilterModel] = [];
    }

    //not interested in groupBy
    if (key !== "groupBy" && key !== "reportType" && !!value) {
      initState[key] = value.split(",");
    }
  }

  const [filtersState, dispatchFilters] = useReducer(reducer, initState);

  const value = useMemo(() => ({ filters: filtersState, dispatchFilters }), [filtersState]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
