import * as React from "react";
import { SVGProps } from "react";
const SvgMapPin = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.584 23.624 12 23l.416.624a.75.75 0 0 1-.832 0Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5ZM9.75 10a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.584 23.624 12 23c.416.624.417.623.417.623h.002l.006-.005.022-.015a11.758 11.758 0 0 0 .379-.267 31.173 31.173 0 0 0 4.219-3.696c2.28-2.406 4.705-5.838 4.705-9.64a9.75 9.75 0 1 0-19.5 0c0 3.802 2.426 7.234 4.706 9.64a31.168 31.168 0 0 0 4.218 3.697 18.242 18.242 0 0 0 .379.266l.022.015.006.004.003.002ZM6.166 4.166A8.25 8.25 0 0 1 20.25 10c0 3.198-2.074 6.266-4.294 8.61A29.672 29.672 0 0 1 12 22.082a29.677 29.677 0 0 1-3.956-3.473C5.824 16.265 3.75 13.197 3.75 10a8.25 8.25 0 0 1 2.416-5.834Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMapPin;
