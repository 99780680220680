/* tslint:disable */
 
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StateType = {
    None: 'None',
    Alabama: 'Alabama',
    Alaska: 'Alaska',
    Arizona: 'Arizona',
    Arkansas: 'Arkansas',
    California: 'California',
    Colorado: 'Colorado',
    Connecticut: 'Connecticut',
    Delaware: 'Delaware',
    Florida: 'Florida',
    Georgia: 'Georgia',
    Hawaii: 'Hawaii',
    Idaho: 'Idaho',
    Illinois: 'Illinois',
    Indiana: 'Indiana',
    Iowa: 'Iowa',
    Kansas: 'Kansas',
    Kentucky: 'Kentucky',
    Louisiana: 'Louisiana',
    Maine: 'Maine',
    Maryland: 'Maryland',
    Massachusetts: 'Massachusetts',
    Michigan: 'Michigan',
    Minnesota: 'Minnesota',
    Mississippi: 'Mississippi',
    Missouri: 'Missouri',
    Montana: 'Montana',
    Nebraska: 'Nebraska',
    Nevada: 'Nevada',
    NewHampshire: 'NewHampshire',
    NewJersey: 'NewJersey',
    NewMexico: 'NewMexico',
    NewYork: 'NewYork',
    NorthCarolina: 'NorthCarolina',
    NorthDakota: 'NorthDakota',
    Ohio: 'Ohio',
    Oklahoma: 'Oklahoma',
    Oregon: 'Oregon',
    Pennsylvania: 'Pennsylvania',
    RhodeIsland: 'RhodeIsland',
    SouthCarolina: 'SouthCarolina',
    SouthDakota: 'SouthDakota',
    Tennessee: 'Tennessee',
    Texas: 'Texas',
    Utah: 'Utah',
    Vermont: 'Vermont',
    Virginia: 'Virginia',
    Washington: 'Washington',
    WestVirginia: 'WestVirginia',
    Wisconsin: 'Wisconsin',
    Wyoming: 'Wyoming'
} as const;
export type StateType = typeof StateType[keyof typeof StateType];


export function StateTypeFromJSON(json: any): StateType {
    return StateTypeFromJSONTyped(json, false);
}

export function StateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StateType {
    return json as StateType;
}

export function StateTypeToJSON(value?: StateType | null): any {
    return value as any;
}

