import { SingleReportData } from "./SingleReportData";
import { TimeStamps } from "./TimeStamps";
import "./SingleReport.scss";
import { SingleReportProps } from "./model";

export function SingleReport({
  reportId,
  reportType,
  requestedAtDate,
  status,
  downloads,
  fileGeneratedAtDate,
  requestedBy,
  onReportDownload,
}: SingleReportProps) {
  return (
    <div className="SingleReport">
      <SingleReportData
        onReportDownload={onReportDownload}
        reportId={reportId.toString()}
        reportType={reportType}
        status={status}
      />

      <TimeStamps
        requestedBy={requestedBy}
        requestedAtDate={requestedAtDate}
        fileGeneratedAtDate={fileGeneratedAtDate}
        downloads={downloads}
      />
    </div>
  );
}
