import { HTTPRequestInit, InitOverrideFunction, RequestOpts } from "api/event";
import { BE_EVENT_API_BODY, Capitalized_BE_BODY } from "config/binding";

export function isReadonly<T>(field: T, editableFields?: T[]) {
  if (!editableFields) {
    return false;
  }

  return editableFields.every(el => el !== field);
}

export function stripBodyBasedOnConfig<T extends Record<string, any>>(
  incomingObj: Capitalized_BE_BODY<T>,
  stripMap: BE_EVENT_API_BODY<T>,
) {
  const newObj: any = {};

  Object.entries(incomingObj).forEach(([key, value]) => {
    if ((stripMap as Record<string, any>)[key] === "Disabled") {
      return;
    }

    if (key === "AdditionalProperties") {
      newObj.AdditionalProperties = {};

      Object.entries(incomingObj.AdditionalProperties!).forEach(
        ([incAdPropKey, incAdPropValue]) => {
          if (
            stripMap.AdditionalProperties?.[incAdPropKey as keyof typeof stripMap] === "Disabled"
          ) {
            return;
          }
          newObj.AdditionalProperties[incAdPropKey] = incAdPropValue;
        },
      );
    } else {
      newObj[key] = value;
    }
  });

  return newObj;
}

/**
 * ! Aimed for OpenApi Generator fetch functions
 *
 * This function calls the stripping for body properties
 *
 * which is downloaded from global config
 */
export function stripNetworkBodyWith<T extends Record<string, any>>(
  strippingFunction: () => BE_EVENT_API_BODY<T>,
): InitOverrideFunction {
  return async (data: { init: HTTPRequestInit; context: RequestOpts }): Promise<RequestOpts> => {
    data.context.body = stripBodyBasedOnConfig(data.context.body, strippingFunction());

    return data.context as any;
  };
}
