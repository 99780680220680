import { SignOutModal } from "components/AppModals/SignOutModal";
import { Copyright } from "components/Copyright";
import { Button, Logo } from "libs/ui";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Landing.scss";
import { RouteCards } from "./RouteCards";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";

export function Landing() {
  const { t } = useTranslation("translation", { keyPrefix: "Landing" });

  const userAttributes = useAdditionalAuthInformationContext();

  const [showSignOutModal, setShowSignOutModal] = useState(false);

  const toggleSignOutModal = useCallback(() => setShowSignOutModal(prevValue => !prevValue), []);

  return (
    <div className="Landing">
      <Logo className="Landing__logo" />

      <div className="Landing__main">
        <div className="Landing__text">
          <p>
            {t("welcomeMessage", {
              user: userAttributes.firstName ? `, ${userAttributes.firstName}` : "",
            })}
          </p>
          <p>{t("agendaQuestion")}</p>
        </div>

        <RouteCards />

        <Button
          buttonSize="medium"
          buttonType="link-primary"
          onClick={toggleSignOutModal}
          buttonIcon={{ icon: "SignOut" }}
        >
          {t("signOut")}
        </Button>
      </div>

      <Copyright />

      <SignOutModal show={showSignOutModal} onCancel={toggleSignOutModal} />
    </div>
  );
}
