// import { EventsTable } from "components/AppTables";
import { DetailsCommonPerson } from "components/PageTemplate/DetailsPage/DetailsCommonPerson";
// import { Tabs } from "libs/ui";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { DetailsForm } from "./DetailsForm";
import { useMakeUserPagesDetailState } from "./model";

export function Details() {
  // const { t } = useTranslation({ keyPrefix: "UserDetails" });
  const { userId = "", organizationId = "" } = useParams<RouteParams["userDetail"]>();
  const detailsService = useMakeUserPagesDetailState(userId, organizationId);
  const [, /*state*/ send] = detailsService;
  // AvatarState is in DetailsForm
  const onClose = useCallback(() => send({ type: "CANCEL_EDIT" }), [send]);

  return (
    <DetailsCommonPerson
      detailsService={detailsService}
      detailsForm={
        <DetailsForm
          service={detailsService}
          userId={userId}
          organizationId={organizationId}
          onClose={onClose}
        />
      }
    ></DetailsCommonPerson>
  );
}
