export const ROOT_TRANSLATION_FILES = [
  "region",
  "modals",
  "nationalities",
  "measurements",
  "widgets",
  "additionalFields",
] as const;

/**
 * ! In case if we need tenant specific root files, which shows error modal if cannot be fetched
 */
export const TENANT_SPECIFIC_ROOT_TRANSLATION_FILES = [] as const;
export const TENANT_SPECIFIC_TRANSLATION_FILES = [
  "translation",
  ...ROOT_TRANSLATION_FILES,
] as const;
export const FALLBACK_LANG = "en";
export const SUPPORTED_LANGUAGES: string[] = ["en", "en-CAM"]; // This is a temporary solution since we don't have a type for language variants, LanguageType wont't work for this case
