import { ECharts } from "echarts/core";

import { UserGraphModel } from "api/query/models/UserGraphModel";
import { MutableRefObject, RefObject, useEffect } from "react";
import { GetOptions, ZoomLevels } from "../model";
import { useGenerateGraphOptions } from "./useGenerateGraphOptions";
import { useWireUpLineChart } from "./useWireUpLineChart";

interface Props {
  data: UserGraphModel;
  chartDivRef: RefObject<HTMLDivElement>;
  chartRef: MutableRefObject<ECharts | undefined | null>;
  getOptions: GetOptions;
  afterInit?: (chartRef: MutableRefObject<ECharts | undefined | null>) => void;
  setCurrentSelectedZoomLevel?: React.Dispatch<React.SetStateAction<ZoomLevels>>;
  onZoomRange?: (range: [number, number]) => void;
  currentSelectedZoomLevel?: ZoomLevels;
}

export function useInitChart({
  data,
  chartDivRef,
  chartRef,
  getOptions,
  afterInit,
  setCurrentSelectedZoomLevel,
  onZoomRange,
  currentSelectedZoomLevel,
}: Props) {
  const options = useGenerateGraphOptions(data, getOptions, currentSelectedZoomLevel);

  useWireUpLineChart(chartDivRef, chartRef, options, setCurrentSelectedZoomLevel, onZoomRange);

  // call custom logic after init
  useEffect(() => {
    if (afterInit) {
      afterInit(chartRef);
    }
  }, [afterInit, chartRef, options]);
}
