import classNames from "classnames";
import { PropsWithChildren, useRef } from "react";
import "./TabItem.scss";

export type TabVariantType = "classic" | "tile";

export interface TabItemProps {
  isActive: boolean;
  onClick: () => void;
  disabled?: boolean;
  tabVariant?: TabVariantType;
}

export function TabItem({
  isActive,
  onClick,
  disabled,
  children,
  tabVariant = "classic",
}: PropsWithChildren<TabItemProps>) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    buttonRef.current?.scrollIntoView({ behavior: "smooth" });
    onClick?.();
  };
  return (
    <li className={classNames("TabItem", `TabItem--${tabVariant}`)}>
      <button
        ref={buttonRef}
        onClick={handleClick}
        className={classNames(
          "TabItem__button",
          { [`TabItem__button--${tabVariant}-active`]: isActive },
          `TabItem__button--${tabVariant}`,
        )}
        disabled={disabled}
      >
        {children}
      </button>
    </li>
  );
}
