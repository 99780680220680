import { AGE_LIMIT } from "config/const";
import { useCallback, useMemo } from "react";
import { getCurrentDateTimeISOenUS } from "../useRegionDateTimeFormatter";
import { useRegionDateTimeFormatter } from "../useRegionDateTimeFormatter";

export function useValidateDate() {
  const [, , , getCurrentDateTimePerTimeZonePreferences] = useRegionDateTimeFormatter();

  const isNotFutureDate = useCallback(
    (selectedDate: Date) => {
      const currentDate = getCurrentDateTimePerTimeZonePreferences();

      return !isFutureDate(selectedDate, currentDate);
    },
    [getCurrentDateTimePerTimeZonePreferences],
  );

  const isValidDateOfBirth = useCallback((value: Date) => isValidBirthDate(value), []);

  return useMemo(
    () => ({ isNotFutureDate, isValidDateOfBirth }) as const,
    [isNotFutureDate, isValidDateOfBirth],
  );
}

function isPossibleDateOfBirth(minDateOfBirth: Date, selectedDate: Date) {
  return minDateOfBirth.valueOf() < selectedDate.valueOf();
}

function isFutureDate(selectedDate: Date, currentDate: Date) {
  return selectedDate.valueOf() > currentDate.valueOf();
}

export function isValidBirthDate(value: Date) {
  const selectedDate = new Date(value);
  const minDateOfBirth = getCurrentDateTimeISOenUS();
  minDateOfBirth.setFullYear(minDateOfBirth.getFullYear() - AGE_LIMIT);

  return (
    isPossibleDateOfBirth(minDateOfBirth, selectedDate) &&
    !isFutureDate(selectedDate, getCurrentDateTimeISOenUS())
  );
}
