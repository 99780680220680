import { Icon } from "libs/ui";
import { PropsWithChildren } from "react";
import { HeadProps } from "../model";
import "./Head.scss";

export function Head({ onClose, children }: PropsWithChildren<HeadProps>) {
  return (
    <div className="Head" data-testid="edit-details-head">
      <h3 className="Head__title">{children}</h3>
      {onClose && (
        <button onClick={onClose} className="Head__close">
          <Icon icon="Close" />
        </button>
      )}
    </div>
  );
}
