import * as React from "react";
import { SVGProps } from "react";
const SvgLayers = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.335 1.33a.75.75 0 0 0-.67 0l-10 5a.75.75 0 0 0 0 1.34l10 5a.75.75 0 0 0 .67 0l10-5a.75.75 0 0 0 0-1.34l-10-5ZM12 11.16 3.677 7 12 2.839 20.323 7 12 11.162Z"
      fill="currentColor"
    />
    <path
      d="M1.33 11.665a.75.75 0 0 1 1.005-.336L12 16.161l9.665-4.832a.75.75 0 1 1 .67 1.342l-10 5a.75.75 0 0 1-.67 0l-10-5a.75.75 0 0 1-.336-1.006Z"
      fill="currentColor"
    />
    <path
      d="M1.33 16.665a.75.75 0 0 1 1.005-.336L12 21.161l9.665-4.832a.75.75 0 1 1 .67 1.342l-10 5a.75.75 0 0 1-.67 0l-10-5a.75.75 0 0 1-.336-1.006Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLayers;
