import "./DeviceField.scss";
import {
  FieldValidationProps,
  FormFieldBasic,
  DeviceImage,
  SelectOptionTitle,
  SelectOptionDescription,
  SelectFilterResult,
  Icon,
} from "libs/ui";
import { createSelectFilterAsyncOption } from "libs/ui/SelectFilterAsync/SelectFilterAsync.model";
import { memo, useCallback } from "react";

import { generatePath, useNavigate } from "react-router-dom";
import { mainRoutePaths } from "router";
import { SelectFilterAsyncWithCancel } from "components/SelectFilterAsyncWithCancel";
import { SelectFilterAsyncNoData } from "components/SelectFilterAsyncNoData";
import { CarnaApiQuery } from "config/apiQuery";
import { DeviceResponseModel, DevicesFilterModel } from "api/query";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { DeviceType, getSampleByDevicePerDeviceType } from "models/DeviceModels";
import classNames from "classnames";
import { CurrentFormMode } from "models/FormModeModels";

interface DevicesFieldProps extends FormFieldBasic, FieldValidationProps {
  mode: CurrentFormMode;
  deviceSerialNumber?: string;
  deviceType?: DeviceType | null;
  deviceId?: string | null;
  readOnly?: boolean;
  organizationId: string | undefined | null;
  /**
   * For edit persons, we need to fetch his/her device too
   */
  personId?: string;
  onSelect: (value: DeviceResponseModel | undefined) => void;
  loading?: boolean;
  /**
   * To be used for redirecting to the currently selected device
   */
  onRedirect?: () => void;
  optional?: boolean;
}

/**
 * The EDIT UI requires all inactive devices
 * + the device which is assigned to currently edited person
 */
async function fetchDevices(
  mode: CurrentFormMode,
  organizationId: string,
  contains: string | null = null,
  personId: string = "",
): Promise<{ title: string; value: DeviceResponseModel }[]> {
  const api = (filters?: DevicesFilterModel) =>
    CarnaApiQuery.Devices.get({ organizationId, filters, limit: 100, page: 1 });

  const inactiveDevices = api({
    deviceStatuses: ["Inactive"],
    contains,
  });

  const data = await Promise.all(
    mode === "Add"
      ? [inactiveDevices]
      : [
          inactiveDevices,
          personId
            ? api({
                userIds: [personId],
                deviceStatuses: ["Active"],
                contains,
              })
            : undefined,
        ],
  );

  const deviceList = data
    .flatMap(data => data?.items)
    .filter((device): device is DeviceResponseModel => !!device);

  return deviceList.map(device => ({ title: device.serialNumber ?? "", value: device }));
}

export * from "./useOnDeviceChange";

function DeviceFieldComponent({
  mode,
  deviceSerialNumber,
  deviceId,
  deviceType,
  organizationId,
  personId,
  readOnly,
  onSelect,
  loading,
  optional,
}: Readonly<DevicesFieldProps>) {
  const { t: tForm } = useTranslation("translation", { keyPrefix: "Form" });
  const { t: tComponents } = useTranslation("translation", { keyPrefix: "components" });
  const navigate = useNavigate();

  const fetchDevs = createSelectFilterAsyncOption(async (filter?: string) => {
    return fetchDevices(mode, organizationId ?? "", filter, personId);
  });

  const setRenderSelected = useCallback(
    (props?: DeviceResponseModel | undefined) => {
      const hasValue = !!props || !!deviceSerialNumber;

      return (
        <SelectFilterResult
          data-testval={JSON.stringify({ deviceSerialNumber: props?.serialNumber, optional })}
          hasValue={hasValue || !!readOnly}
          label={i18n.format("device", "optionalField", undefined, { optional })}
        >
          {!hasValue ? (
            <p className="DevicesField__notAssigned">{tForm("na")}</p>
          ) : (
            <div
              className="DevicesField__option"
              title={`${props?.deviceType ?? deviceType} - ${
                props?.serialNumber ?? deviceSerialNumber
              }`}
            >
              <DeviceImage
                sampledByDevice={getSampleByDevicePerDeviceType(
                  props?.deviceType ?? deviceType ?? "NovaMaxProCreateEgfrMeter",
                )}
              />
              <div className="DevicesField__details">
                <SelectOptionTitle>{props?.deviceType ?? deviceType}</SelectOptionTitle>
                <SelectOptionDescription>
                  {props?.serialNumber ?? deviceSerialNumber}
                </SelectOptionDescription>
              </div>
            </div>
          )}
        </SelectFilterResult>
      );
    },
    [deviceSerialNumber, readOnly, optional, tForm, deviceType],
  );

  const setRenderOption = useCallback(
    ({ value }: { value?: DeviceResponseModel | undefined }) => (
      <div
        className="DevicesField__option DevicesField__option--list"
        title={`${value?.deviceType} - ${value?.serialNumber}`}
      >
        <DeviceImage
          sampledByDevice={
            value?.deviceType ? getSampleByDevicePerDeviceType(value.deviceType) : undefined
          }
        />
        <div className="DevicesField__details">
          <SelectOptionTitle>{value?.deviceType}</SelectOptionTitle>
          <SelectOptionDescription>{value?.serialNumber}</SelectOptionDescription>
        </div>
        {value?.id === deviceId ? (
          <Icon icon={"Check"} className="SelectOption__selectedIcon" />
        ) : null}
      </div>
    ),
    [deviceId],
  );

  const onRedirect = useCallback(
    () =>
      navigate(
        generatePath(mainRoutePaths.deviceDetails, {
          deviceId: deviceId as string,
          organizationId: organizationId as string,
        }),
      ),
    [deviceId, navigate, organizationId],
  );

  const shouldRedirect = organizationId && deviceId && mode === "Readonly";

  return (
    <SelectFilterAsyncWithCancel
      onRedirect={shouldRedirect ? onRedirect : undefined}
      data-testid="devices-field"
      className={classNames("DevicesField", {
        "DevicesField--link": shouldRedirect,
      })}
      readOnly={readOnly}
      disabled={!organizationId}
      onSelect={onSelect}
      hasInitialValue={!!deviceSerialNumber}
      validation={{
        infoText: !readOnly ? tComponents("DevicesField.infoText") : undefined,
        errorText: tForm("ValidationMessages.deviceNotAvailable") ?? "",
      }}
      getOptions={fetchDevs}
      loading={loading}
      renderSelected={setRenderSelected}
      renderOption={setRenderOption}
      noDataComponent={<SelectFilterAsyncNoData variant="Devices" scaleTo={0.5} />}
    />
  );
}

export const DeviceField = memo(DeviceFieldComponent);
