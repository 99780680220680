import { ECharts } from "echarts/core";
import { MutableRefObject, RefObject, useEffect } from "react";
import { ChartInit } from "../chartInit";
import { ECOption, ZoomLevels } from "../model";

export function useWireUpLineChart(
  chartDivRef: RefObject<HTMLDivElement>,
  chartRef: MutableRefObject<ECharts | undefined | null>,
  options: ECOption,
  setCurrentSelectedZoomLevel?: React.Dispatch<React.SetStateAction<ZoomLevels>>,
  onZoomRange?: (range: [number, number]) => void,
) {
  //#region init
  useEffect(() => {
    if (chartRef.current) {
      return;
    }

    if (
      chartDivRef.current &&
      // chartDivRef.current.checkVisibility() && // Safari does not support this
      chartDivRef.current.clientHeight &&
      chartDivRef.current.clientWidth
    ) {
      chartRef.current = ChartInit(
        chartDivRef.current,
        options,
        setCurrentSelectedZoomLevel,
        onZoomRange,
      );
    }
  }, [chartDivRef, chartRef, options, setCurrentSelectedZoomLevel, onZoomRange]);
  //#endregion

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    function resize() {
      chartRef.current?.resize();
    }

    if (
      chartDivRef.current &&
      // chartDivRef.current.checkVisibility() && // Safari does not support this
      chartDivRef.current.clientHeight &&
      chartDivRef.current.clientWidth
    ) {
      const observer = new ResizeObserver(resize);
      const observedElement = chartDivRef.current;

      observer.observe(observedElement, { box: "border-box" });

      return () => {
        observer.unobserve(observedElement);
        observer.disconnect();
      };
    }

    /**
     * ! We need this to be trigger after first useEffect
     * ! those extra options are necessary
     */
  }, [chartDivRef, chartRef, options, setCurrentSelectedZoomLevel]);

  // ! Can we set everything without using chartRef.current?.clear() ?
  useEffect(() => {
    if (chartRef.current) {
      // replaceMerge property avoids the merge series option which can cause an issue
      // on the chart when a number of different series can be shown (Eg. Blood pressure chart)
      chartRef.current.setOption(options, { replaceMerge: ["series", "title"] });
    }
  }, [chartRef, options]);
}
