import { FieldLoader } from "libs/ui/FieldLoader";
import { useMemo } from "react";
import "./BMIRangeTable.scss";
import { BMIRangeTableRow } from "./BMIRangeTableRow";
import { useGetBMIRanges } from "utils/hooks/useGetBMIRanges";
import { useTranslation } from "react-i18next";

interface BMIRangeTableProps {
  loading?: boolean;
  value: number;
}

export function BMIRangeTable({ value, loading }: Readonly<BMIRangeTableProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.BMISectionDetails" });
  const { orderedBMIStageRanges } = useGetBMIRanges();

  const rows = useMemo(
    () =>
      Object.entries(orderedBMIStageRanges).map(([key, data]) => (
        <BMIRangeTableRow key={key} label={t(key)} value={value} {...data} />
      )),
    [orderedBMIStageRanges, t, value],
  );

  if (loading) {
    return (
      <div className="DetailsField">
        <FieldLoader />
      </div>
    );
  }

  return (
    <table className="BMIRangeTable">
      <tbody className="BMIRangeTable__body">{rows}</tbody>
    </table>
  );
}
