import { useCallback } from "react";
import { TestFormModel } from "components/Forms/Test/model";
import { setCurrentSecOnDateTime } from "utils/converters/setCurrentSecOnDateTime";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";

export function useConvertTestTimeZone() {
  const { globalPreference } = useGlobalPreferenceContext();

  const convertTestMeasurementTime = useCallback(
    (test: TestFormModel) => {
      return {
        ...test,
        measurementTime: setCurrentSecOnDateTime(
          test.measurementTime,
          globalPreference?.timeZoneType,
        ),
      };
    },
    [globalPreference?.timeZoneType],
  );

  return { convertTestMeasurementTime } as const;
}
