import { MeasurementGraphModel, MeasurementResponseModel, UnitsModel } from "api/query";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { getFormattedCreatininePerMeasurementUnit } from "utils/converters/getFormattedCreatininePerMeasurementUnit";
import {
  gramsToKilograms,
  gramsToPounds,
  millimetersToCentimeters,
  millimetersToFeet,
} from "utils/converters/unitsConverter";

import { MeasurementDetails } from "components/MeasurementSideDetails/SideDetails/components/model";
import { MeasurementItemUIType, SideDetailsModel } from "models/TestModels";
import { LineChartDataType } from "./model";
import { getUrineAlbuminValuePerUserPreferredUnit } from "utils/hooks/useUrineAlbuminValueConvertor";

function parseHeightData(value: number, measurementUnit?: MEASUREMENT_UNITS) {
  return measurementUnit === MEASUREMENT_UNITS.cm
    ? millimetersToCentimeters(value)
    : millimetersToFeet(value);
}

function parseWeightData(value: number, measurementUnit?: MEASUREMENT_UNITS) {
  return measurementUnit === MEASUREMENT_UNITS.kg ? gramsToKilograms(value) : gramsToPounds(value);
}

function getSQUACRValue(value: number) {
  switch (value) {
    case 1:
      return 15;
    case 2:
      return 165;
    case 3:
      return 350;
    default:
      throw new Error(`Value ${value} for SQ UACR not implemented`);
  }
}

function getChartDataValue(
  value: number,
  unitType: keyof UnitsModel,
  measurementUnit?: MEASUREMENT_UNITS,
) {
  switch (unitType) {
    case "serumCreatinine":
      return getFormattedCreatininePerMeasurementUnit(value, measurementUnit);

    case "height":
      return parseHeightData(value, measurementUnit);

    case "weight":
      return parseWeightData(value, measurementUnit);

    case "urineAlbumin":
      return getUrineAlbuminValuePerUserPreferredUnit(String(value), measurementUnit);

    case "semiQuantitativeUacr":
      return getSQUACRValue(value);

    case "egfr":
    case "glucose":
    case "uacr":
    case "urineCreatinine":
    case "bmi":
      return value;

    case "bloodPressure":
      throw new Error("bloodPressure can't be implemented");
    default:
      throw new Error(`${unitType} not implemented`);
  }
}

export function mapMeasurementGraphModelToChartData(
  measurements: MeasurementGraphModel[],
  unitType: keyof UnitsModel,
  measurementUnit?: MEASUREMENT_UNITS,
) {
  if (unitType === "bloodPressure") {
    throw new Error("bloodPressure can't be implemented");
  }

  return measurements.map(measurement => [
    new Date(measurement.measurementTime).toISOString(),
    getChartDataValue(measurement.units[unitType]?.value ?? 0, unitType, measurementUnit),
  ]);
}

export const getMeasurementPerType = (
  measurements: MeasurementResponseModel[],
  unitType?: keyof UnitsModel,
) => {
  switch (unitType) {
    case "serumCreatinine":
    case "egfr":
      return measurements.find(el => el.measurementType === "SerumCreatinine");

    case "bloodPressure":
      return measurements.find(el => el.measurementType === "BloodPressure");

    case "glucose":
      return measurements.find(el => el.measurementType === "Glucose");

    case "uacr":
    case "urineCreatinine":
    case "urineAlbumin":
      return measurements.find(el => el.measurementType === "UACR");

    case "bmi":
    case "height":
    case "weight":
      return measurements.find(el => el.measurementType === "BMI");

    case "semiQuantitativeUacr":
      return measurements.find(el => el.measurementType === "SemiQuantitativeUACR");

    default:
      throw new Error(`${unitType} type is not implemented or it does not exist!`);
  }
};

function resolveMeasurementItem(type: LineChartDataType): MeasurementItemUIType | "BloodPressure" {
  switch (type) {
    case "BMI":
      return "Bmi";
    case "UACR":
      return "Uacr";
    case "SemiQuantitativeUACR":
      return "SemiQuantitativeUacr";

    default:
      return type;
  }
}

export const mapToSideDetails = (
  data: MeasurementDetails,
  measurementItemType: LineChartDataType,
): SideDetailsModel => {
  return {
    test: {
      patient: data.patient,
      testedBy: {
        firstName: data.testedBy.firstName ?? "",
        lastName: data.testedBy.lastName ?? "",
        id: data.testedBy.id,
        organizationId: data.organizationId,
        roleType: data.testedBy.roleType,
      },
      device: data.device,
      measurements: [data.units],
      id: data.id,
      testCountId: data.testCountId,
      organizationId: data.organizationId,
      measurementTime: data.measurementTime,
      sampledByDevice: data.sampledByDevice,
    },
    measurement: data.units,
    measurementItem: resolveMeasurementItem(measurementItemType),
  };
};
