/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import type { Geolocation } from './Geolocation';
import {
    GeolocationFromJSON,
    GeolocationFromJSONTyped,
    GeolocationToJSON,
} from './Geolocation';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string | null;
    /**
     * 
     * @type {CountryType}
     * @memberof Address
     */
    country?: CountryType;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    state?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    zipCode?: string | null;
    /**
     * 
     * @type {Geolocation}
     * @memberof Address
     */
    geolocation?: Geolocation;
}

/**
 * Check if a given object implements the Address interface.
 */
export function instanceOfAddress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'street': !exists(json, 'Street') ? undefined : json['Street'],
        'city': !exists(json, 'City') ? undefined : json['City'],
        'country': !exists(json, 'Country') ? undefined : CountryTypeFromJSON(json['Country']),
        'state': !exists(json, 'State') ? undefined : json['State'],
        'zipCode': !exists(json, 'ZipCode') ? undefined : json['ZipCode'],
        'geolocation': !exists(json, 'Geolocation') ? undefined : GeolocationFromJSON(json['Geolocation']),
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': value.state,
        'ZipCode': value.zipCode,
        'Geolocation': GeolocationToJSON(value.geolocation),
    };
}

