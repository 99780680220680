import { User } from "components/Forms/User";
import { UserModel } from "components/Forms/User/model";
import { userFormUtils } from "components/Forms/User/userFormUtils";
import { DetailsCommonPersonForm } from "components/PageTemplate/DetailsPage/DetailsCommonPersonForm";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { Dispatch, PropsWithChildren, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { DetailsFormProps, useMakeUserPagesDetailState } from "../model";

function FormElem({
  children,
  service,
  entityData,
  setEntityData,
  loading,
  onSubmit,
}: PropsWithChildren<{
  service: ReturnType<typeof useMakeUserPagesDetailState>;
  entityData: UserModel;
  setEntityData: Dispatch<SetStateAction<UserModel>>;
  onSubmit: (e?: React.FormEvent<Element> | undefined) => void;
  loading?: boolean;
}>) {
  const [state] = service;

  return (
    <User
      formMode={state.matches("entity.editing") ? "Edit" : "Readonly"}
      key="FormUser"
      entityData={
        state.matches("entity.editing") || state.matches("entity.saving")
          ? entityData
          : (state.context?.data ?? ({} as UserModel))
      }
      setEntityData={setEntityData}
      onSubmit={onSubmit}
      editableFields={
        state.matches("entity.editing")
          ? ["firstName", "lastName", "phone", "street", "city", "country", "state", "zipCode"]
          : []
      }
      loading={loading}
    >
      {children}
    </User>
  );
}

export function DetailsForm({
  service,
  userId,
  organizationId,
  onClose,
}: Readonly<DetailsFormProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "UserDetails" });
  const { appConfig } = useGlobalConfigContext();
  const [, , actor] = service;

  return (
    <DetailsCommonPersonForm
      avatarParams={{
        personId: userId ?? "",
        organizationId: organizationId ?? "",
        latestRowVersion: actor.getSnapshot().context.data?.rowVersion ?? "cannot-find-row-version",
      }}
      getFieldsReady={tempState => userFormUtils.getFieldsReady(tempState, "Edit", appConfig)}
      defaultFormValue={NotNullOrUndefined(appConfig?.forms.defaultValues.user as any)}
      detailsService={service}
      FormElem={FormElem}
      t={t}
      onClose={onClose}
    />
  );
}
