import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./translations/en/translation.json";
// import nationalitiesEn from "./translations/en/nationalities.json";
import { LanguageType } from "api/query";

import LanguageDetector from "i18next-browser-languagedetector";
import { FALLBACK_LANG, SUPPORTED_LANGUAGES } from "config/translations";

export function isSupportedLanguage(lang: LanguageType) {
  return SUPPORTED_LANGUAGES.some(el => el === lang);
}

const translation = {
  ...translationEn,
  // ...nationalitiesEn,
  // ...modalsTranslationEn,
  // ...regionTranslationEn,
} as const;

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

declare global {
  type TFunction = (key: string) => string;
}

i18n
  // .use(resourcesToBackend(fetchTranslation))
  .use(LanguageDetector)
  .use(initReactI18next)
  // for namespaces
  .init({
    returnNull: false,
    fallbackLng: FALLBACK_LANG,
    debug: false,
    lng: "en",
    ns: ["translation", "region", "modals", "nationalities", "measurements"],
    defaultNS: "translation",
    resources: {
      en: {
        translation: translation,
      },
    },
    supportedLngs: SUPPORTED_LANGUAGES,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    partialBundledLanguages: true,
  });

i18n.services.formatter?.add("optionalField", (value, lng, options) => {
  const valT = i18n.t(`Form.${value}`, { ns: "translation" });
  return options?.optional ? `${valT} ${i18n.t(`Form.optional`, { ns: "translation" })}` : valT;
});
// i18n.on("added", function (loaded) {
//   console.log("LOADED", loaded);
// });

// i18n.store.on("added", function (lng, ns) {
//   console.log("ADDED", { lng, ns });
// });
// .then(() => {
//   i18n.reloadResources(i18n.language, ["region", "modals", "nationalities"]);
// });

export default i18n;
