/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserGraphModel } from './UserGraphModel';
import {
    UserGraphModelFromJSON,
    UserGraphModelFromJSONTyped,
    UserGraphModelToJSON,
} from './UserGraphModel';

/**
 * 
 * @export
 * @interface OrganizationPatientModel
 */
export interface OrganizationPatientModel {
    /**
     * 
     * @type {string}
     * @memberof OrganizationPatientModel
     */
    id: string;
    /**
     * 
     * @type {UserGraphModel}
     * @memberof OrganizationPatientModel
     */
    user: UserGraphModel;
}

/**
 * Check if a given object implements the OrganizationPatientModel interface.
 */
export function instanceOfOrganizationPatientModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function OrganizationPatientModelFromJSON(json: any): OrganizationPatientModel {
    return OrganizationPatientModelFromJSONTyped(json, false);
}

export function OrganizationPatientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationPatientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'user': UserGraphModelFromJSON(json['User']),
    };
}

export function OrganizationPatientModelToJSON(value?: OrganizationPatientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'User': UserGraphModelToJSON(value.user),
    };
}

