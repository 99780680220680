import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { TableViewType } from "./model";

interface TableViewProps {
  currentValue: TableViewType;
  onViewToggle: (value: TableViewType) => void;
}

const options = (["List", "Grid"] as const).map(el => ({ icon: el, value: el }));

export function TableView({ currentValue, onViewToggle }: TableViewProps) {
  const { t } = useTranslation("translation", { keyPrefix: "ui-components" });

  const onClick = useCallback((value: TableViewType) => onViewToggle(value), [onViewToggle]);

  return (
    <div className="TableView">
      <span className="TableView__text">{t("Table.tableView")}</span>
      <ul className="TableView__list">
        {options.map(el => (
          <li key={el.value} className="TableView__item">
            <Button
              active={currentValue === el.value}
              onClick={() => onClick(el.value)}
              buttonSize="small"
              buttonType="transparent"
              buttonIcon={{ icon: el.icon }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
