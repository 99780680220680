/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrackingDeviceModel
 */
export interface TrackingDeviceModel {
    /**
     * 
     * @type {string}
     * @memberof TrackingDeviceModel
     */
    deviceKey: string;
    /**
     * 
     * @type {string}
     * @memberof TrackingDeviceModel
     */
    deviceName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TrackingDeviceModel
     */
    deviceLastAuthenticatedDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof TrackingDeviceModel
     */
    deviceLastModifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof TrackingDeviceModel
     */
    lastIpUsed?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackingDeviceModel
     */
    countryName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackingDeviceModel
     */
    cityName?: string | null;
}

/**
 * Check if a given object implements the TrackingDeviceModel interface.
 */
export function instanceOfTrackingDeviceModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deviceKey" in value;

    return isInstance;
}

export function TrackingDeviceModelFromJSON(json: any): TrackingDeviceModel {
    return TrackingDeviceModelFromJSONTyped(json, false);
}

export function TrackingDeviceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackingDeviceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceKey': json['DeviceKey'],
        'deviceName': !exists(json, 'DeviceName') ? undefined : json['DeviceName'],
        'deviceLastAuthenticatedDate': !exists(json, 'DeviceLastAuthenticatedDate') ? undefined : json['DeviceLastAuthenticatedDate'],
        'deviceLastModifiedDate': !exists(json, 'DeviceLastModifiedDate') ? undefined : json['DeviceLastModifiedDate'],
        'lastIpUsed': !exists(json, 'LastIpUsed') ? undefined : json['LastIpUsed'],
        'countryName': !exists(json, 'CountryName') ? undefined : json['CountryName'],
        'cityName': !exists(json, 'CityName') ? undefined : json['CityName'],
    };
}

export function TrackingDeviceModelToJSON(value?: TrackingDeviceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DeviceKey': value.deviceKey,
        'DeviceName': value.deviceName,
        'DeviceLastAuthenticatedDate': value.deviceLastAuthenticatedDate,
        'DeviceLastModifiedDate': value.deviceLastModifiedDate,
        'LastIpUsed': value.lastIpUsed,
        'CountryName': value.countryName,
        'CityName': value.cityName,
    };
}

