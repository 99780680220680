import { Trans, useTranslation } from "react-i18next";
import "./FetchingErrorPage.scss";
import { ErrorTitle } from "components/ErrorElements/ErrorTitle";
import { Message } from "components/ErrorElements/Message";
import { ErrorButton } from "components/ErrorElements/ErrorButton";
import { OptionalMessage } from "components/ErrorElements/OptionalMessage";

interface FetchingErrorPageProps {
  fetchingFunc: () => void;
  title?: string;
  message?: string;
  titleIcon?: string;
}

export function FetchingErrorPage({
  fetchingFunc,
  title,
  message,
  titleIcon,
}: FetchingErrorPageProps) {
  const { t } = useTranslation("translation", { keyPrefix: "ErrorPages.FetchingErrorPage" });

  return (
    <div className="FetchingErrorPage">
      <div className="FetchingErrorPage__content">
        {titleIcon ? <ErrorTitle>{titleIcon}</ErrorTitle> : null}
        <ErrorTitle>{title ? title : t("title")}</ErrorTitle>
        <Message>{message ? message : t("message")}</Message>
        <ErrorButton onClick={fetchingFunc}>{t("button_text")}</ErrorButton>
        <OptionalMessage>
          <Trans i18nKey="optional_message" t={t} values={{ boldText: "support@carna.health" }}>
            <span className="FetchingErrorPage__optionalMessage--bold"></span>
          </Trans>
        </OptionalMessage>
      </div>
    </div>
  );
}
