/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  EventResponseModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    EventResponseModelFromJSON,
    EventResponseModelToJSON,
} from '../models/index';

export interface EventsIdGetRequest {
    id: string;
}

/**
 * EventsApi - interface
 * 
 * @export
 * @interface EventsApiInterface
 */
export interface EventsApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApiInterface
     */
    eventsIdGetRaw(requestParameters: EventsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventResponseModel>>;

    /**
     */
    eventsIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventResponseModel>;

}

/**
 * 
 */
export class EventsApi extends runtime.BaseAPI implements EventsApiInterface {

     /**
     */
    async eventsIdGetRawOriginal(requestParameters: EventsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling eventsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return this.request({
            path: `/events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async eventsIdGetRaw(requestParameters: EventsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling eventsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async eventsIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventResponseModel> {
        const response = await this.eventsIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }
    async eventsIdGetOriginal(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.eventsIdGetRawOriginal({ id: id }, initOverrides);
    }

}
