import { Loader } from "libs/ui/Loader";
import { PropsWithChildren } from "react";
import { BodyProps } from "../model";
import "./Body.scss";

export function Body({ isLoading, children }: PropsWithChildren<BodyProps>) {
  return (
    <div className="Body">
      <Loader loading={isLoading}>{children}</Loader>
    </div>
  );
}
