import * as React from "react";
import { SVGProps } from "react";
const SvgVoicemail = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.174 15.25a5.25 5.25 0 1 0-3.674 1.5h13a5.25 5.25 0 1 0-3.674-1.5H9.174ZM1.75 11.5a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM18.5 7.75a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgVoicemail;
