/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  SupportFormModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    SupportFormModelFromJSON,
    SupportFormModelToJSON,
} from '../models/index';

export interface SupportFormsPostRequest {
    acceptVersion?: string;
    supportFormModel?: SupportFormModel;
}

/**
 * SupportApi - interface
 * 
 * @export
 * @interface SupportApiInterface
 */
export interface SupportApiInterface {
    /**
     * 
     * @param {string} [acceptVersion] 
     * @param {SupportFormModel} [supportFormModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportApiInterface
     */
    supportFormsPostRaw(requestParameters: SupportFormsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    supportFormsPost(requestParameters: SupportFormsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class SupportApi extends runtime.BaseAPI implements SupportApiInterface {

     /**
     */
    async supportFormsPostRawOriginal(requestParameters: SupportFormsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/support/forms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupportFormModelToJSON(requestParameters.supportFormModel),
        }, initOverrides);
    }

    /**
     */
    async supportFormsPostRaw(requestParameters: SupportFormsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/support/forms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupportFormModelToJSON(requestParameters.supportFormModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async supportFormsPost(requestParameters: SupportFormsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.supportFormsPostRaw(requestParameters, initOverrides);
    }

}
