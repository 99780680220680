import { CREATININE_CORRECTION_FACTOR, SERUM_CREATININE_MAX_GRAPH_VALUE } from "config/const";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { useMemo } from "react";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";

export function useGetMaxSerumCreatinineValueFromRuleEngineUnit() {
  const { serumCreatinineUnit } = useGetUserPreferredMeasurementUnits();

  return useMemo(() => {
    switch (serumCreatinineUnit) {
      case MEASUREMENT_UNITS.mgdL:
        return SERUM_CREATININE_MAX_GRAPH_VALUE;
      case MEASUREMENT_UNITS.umolL:
        return Math.round(SERUM_CREATININE_MAX_GRAPH_VALUE * CREATININE_CORRECTION_FACTOR);
      default:
        console.error(`Max value for ${serumCreatinineUnit} unit not implemented`);
        return SERUM_CREATININE_MAX_GRAPH_VALUE;
    }
  }, [serumCreatinineUnit]);
}
