/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  RequestReportModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    RequestReportModelFromJSON,
    RequestReportModelToJSON,
} from '../models/index';

export interface ReportsPostRequest {
    acceptVersion?: string;
    requestReportModel?: RequestReportModel;
}

/**
 * ReportsApi - interface
 * 
 * @export
 * @interface ReportsApiInterface
 */
export interface ReportsApiInterface {
    /**
     * 
     * @param {string} [acceptVersion] 
     * @param {RequestReportModel} [requestReportModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsPostRaw(requestParameters: ReportsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    reportsPost(requestParameters: ReportsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI implements ReportsApiInterface {

     /**
     */
    async reportsPostRawOriginal(requestParameters: ReportsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestReportModelToJSON(requestParameters.requestReportModel),
        }, initOverrides);
    }

    /**
     */
    async reportsPostRaw(requestParameters: ReportsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestReportModelToJSON(requestParameters.requestReportModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reportsPost(requestParameters: ReportsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportsPostRaw(requestParameters, initOverrides);
    }

}
