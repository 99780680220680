import * as React from "react";
import { SVGProps } from "react";
const SvgDrag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.66667 2.5C5.74619 2.5 5 3.24619 5 4.16667C5 5.08714 5.74619 5.83333 6.66667 5.83333C7.58714 5.83333 8.33333 5.08714 8.33333 4.16667C8.33333 3.24619 7.58714 2.5 6.66667 2.5Z"
      fill="#7085A8"
    />
    <path
      d="M5 10C5 9.07953 5.74619 8.33333 6.66667 8.33333C7.58714 8.33333 8.33333 9.07953 8.33333 10C8.33333 10.9205 7.58714 11.6667 6.66667 11.6667C5.74619 11.6667 5 10.9205 5 10Z"
      fill="#7085A8"
    />
    <path
      d="M6.66667 14.1667C5.74619 14.1667 5 14.9129 5 15.8333C5 16.7538 5.74619 17.5 6.66667 17.5C7.58714 17.5 8.33333 16.7538 8.33333 15.8333C8.33333 14.9129 7.58714 14.1667 6.66667 14.1667Z"
      fill="#7085A8"
    />
    <path
      d="M13.3333 2.5C12.4129 2.5 11.6667 3.24619 11.6667 4.16667C11.6667 5.08714 12.4129 5.83333 13.3333 5.83333C14.2538 5.83333 15 5.08714 15 4.16667C15 3.24619 14.2538 2.5 13.3333 2.5Z"
      fill="#7085A8"
    />
    <path
      d="M11.6667 10C11.6667 9.07953 12.4129 8.33333 13.3333 8.33333C14.2538 8.33333 15 9.07953 15 10C15 10.9205 14.2538 11.6667 13.3333 11.6667C12.4129 11.6667 11.6667 10.9205 11.6667 10Z"
      fill="#7085A8"
    />
    <path
      d="M13.3333 14.1667C12.4129 14.1667 11.6667 14.9129 11.6667 15.8333C11.6667 16.7538 12.4129 17.5 13.3333 17.5C14.2538 17.5 15 16.7538 15 15.8333C15 14.9129 14.2538 14.1667 13.3333 14.1667Z"
      fill="#7085A8"
    />
  </svg>
);
export default SvgDrag;
