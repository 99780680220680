import * as React from "react";
import { SVGProps } from "react";
const SvgTrendingUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.693 5.713A.748.748 0 0 0 23 5.25h-6a.75.75 0 0 0 0 1.5h4.19l-7.69 7.69-4.47-4.47a.75.75 0 0 0-1.06 0l-7.5 7.5a.75.75 0 1 0 1.06 1.06l6.97-6.97 4.47 4.47a.75.75 0 0 0 1.06 0l8.22-8.22V12a.75.75 0 0 0 1.5 0V6a.747.747 0 0 0-.057-.287Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgTrendingUp;
