import * as React from "react";
import { SVGProps } from "react";
const SvgGlucose = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.294 13.853a7.147 7.147 0 1 1-14.294 0c0-2.788 3.565-7.575 5.66-10.146a1.92 1.92 0 0 1 2.975 0c2.095 2.571 5.66 7.358 5.66 10.146Z"
      fill="url(#glucose_svg__a)"
    />
    <path
      d="M5 13.853a7.147 7.147 0 1 0 14.294 0c0-.316-.046-.657-.13-1.018H5.13c-.084.36-.13.702-.13 1.018Z"
      fill="url(#glucose_svg__b)"
    />
    <path
      d="m12.333 18-3.225-2.057a.237.237 0 0 1-.108-.2V11.89l.861.234 1.856.825.616.816.445.351v3.121l-.445.762Z"
      fill="#DDE4F3"
    />
    <path
      d="m12.333 13.766.391-.689 2.213-1.026.73-.16v3.851c0 .083-.041.159-.108.201L12.333 18v-4.234Z"
      fill="#CDD7ED"
    />
    <path
      d="M12.229 10.028 9 11.89l3.333 1.875 3.334-1.875-3.23-1.863a.212.212 0 0 0-.208 0Z"
      fill="#EFF3FA"
    />
    <defs>
      <linearGradient
        id="glucose_svg__a"
        x1={8.677}
        y1={9.612}
        x2={15.336}
        y2={16.271}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F87171" />
        <stop offset={1} stopColor="#DC2626" />
      </linearGradient>
      <linearGradient
        id="glucose_svg__b"
        x1={12.147}
        y1={16.906}
        x2={12.147}
        y2={22.058}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DC2626" stopOpacity={0} />
        <stop offset={1} stopColor="#B91C1C" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgGlucose;
