import { SideModalElements } from "libs/ui";
import { useTranslation } from "react-i18next";
import { SectionDetailsProps } from "../model";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { ColorValue } from "../../elements/ColorValue";
import { DetailsField } from "../../elements/DetailsField";
import { TrendDetailsField } from "../../elements/TrendDetailsField";
import { useGetWeightConverter } from "utils/hooks/useGetWeightConverter/useGetWeightConverter";

interface WeightSectionDetailsProps extends SectionDetailsProps {}

export function WeightSectionDetails({ data, loading, showTrend }: WeightSectionDetailsProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.WeightSectionDetails" });
  const { numberFormatter } = useLocaleNumberFormatter();
  const { weightUnit } = useGetUserPreferredMeasurementUnits();
  const getConvertedWeight = useGetWeightConverter();

  return (
    <div className="WeightSectionDetails">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={t("labelResult")}>
        <ColorValue colorType={"white"}>
          {numberFormatter({
            value: getConvertedWeight(data?.measurement.BMI?.Weight.Value ?? 0),
            additionalOptions: {
              maximumFractionDigits: 1,
              minimumFractionDigits: 0,
              roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
            },
          }).concat(` ${weightUnit}`)}
        </ColorValue>
      </DetailsField>

      <TrendDetailsField
        show={showTrend}
        loading={loading}
        trendType={data?.measurement?.BMI?.Weight.TrendType}
        percent={data?.measurement?.BMI?.Weight.Percent}
      />
    </div>
  );
}
