import { RegionType, UserGraphModel } from "api/query";
import { ECOption, Formatters } from "components/Chart/model";
import i18n from "i18next";
import { getAreaColoredOptions } from "../model";
import { BMI_MAX_GRAPH_VALUE } from "config/const";
import { BMIStageRanges } from "utils/hooks/useGetBMIRanges";
import { getColorValue } from "utils";

function getAreas(orderedBMIStageRanges: BMIStageRanges) {
  const areas = [
    {
      areaColor: getColorValue("--yellow-100"),
      start: 0,
      end: orderedBMIStageRanges.Underweight.to,
      label: i18n.t("Graph.BMI.Underweight", { ns: "translation" }),
      labelColor: getColorValue("--yellow-500"),
      legendColor: getColorValue("--yellow-500"),
    },
    {
      areaColor: getColorValue("--white-100"),
      start: orderedBMIStageRanges.Healthy.from,
      end: orderedBMIStageRanges.Healthy.to,
      label: i18n.t("Graph.BMI.Healthy", { ns: "translation" }),
      labelColor: getColorValue("--grey-500"),
      legendColor: getColorValue("--grey-500"),
    },
    {
      areaColor: getColorValue("--yellow-100"),
      start: orderedBMIStageRanges.Overweight.from,
      end: orderedBMIStageRanges.Overweight.to,
      label: i18n.t("Graph.BMI.Overweight", { ns: "translation" }),
      labelColor: getColorValue("--yellow-500"),
      legendColor: getColorValue("--yellow-500"),
    },
    {
      areaColor: getColorValue("--red-200"),
      start: orderedBMIStageRanges.Obesity.from,
      end: Number.MAX_SAFE_INTEGER,
      label: i18n.t("Graph.BMI.Obesity", { ns: "translation" }),
      labelColor: getColorValue("--red-500"),
      legendColor: getColorValue("--red-500"),
    },
  ];

  return areas;
}

export const getOptions = (
  formatters: Formatters,
  graphUser: UserGraphModel,
  orderedBMIStageRanges: BMIStageRanges,
  showTrending?: boolean,
  regionType?: RegionType,
): ECOption =>
  //TODO should we get from backend, the min max value ?
  getAreaColoredOptions({
    formatters,
    data: graphUser,
    yAxis: { max: BMI_MAX_GRAPH_VALUE },
    areas: getAreas(orderedBMIStageRanges),
    unitType: "bmi",
    measurementUnit: undefined,
    showTrending,
    precision: 1,
    regionType,
  });
