import { NotNullOrUndefined as NotNullOrUndefinedOrg } from "utils/NotNullOrUndefined";

function NotNullOrUndefinedWithEnv(envVariableName: string) {
  return NotNullOrUndefinedOrg(process.env[envVariableName], () => {
    throw new Error(`process.env.${envVariableName} must be defined !`);
  });
}
export const DB_DATE_FORMAT = "yyyy-MM-dd";
export const AGE_LIMIT = 120;
export const NUMBER_EXAMPLE = 1000.99;

export const MILLIMETERS_IN_INCH = 25.4;
export const FEET_IN_INCH = 12;
export const ONE_GRAM_IN_PUNDS = 0.00220462262185;
export const MILLIMETERS_IN_FEET = 304.8;

export const CREATININE_CORRECTION_FACTOR = 88.42;

//#region graph
export const SERUM_CREATININE_MAX_GRAPH_VALUE = 4;
export const SERUM_CREATININE_HIDE_MARK_LINE_LABEL_RANGE = 15;
export const SERUM_CREATININE_HIDE_NORMAL_RANGE_FROM_VALUE = 20;
export const EGFR_INTERVAL_GRAPH_VALUE = 25;
export const EGFR_MAX_GRAPH_VALUE = 120;

export const GLUCOSE_MAX_GRAPH_VALUE = 200;

export const BMI_MAX_GRAPH_VALUE = 50;
export const HEIGHT_MAX_GRAPH_VALUE_CM = 250;
export const HEIGHT_MAX_GRAPH_VALUE_FT = 8;
export const WEIGHT_MAX_GRAPH_VALUE_KG = 150;
export const WEIGHT_MAX_GRAPH_VALUE_LB = 330;

export const BLOOD_PRESSURE_MAX_GRAPH_VALUE = 200;
export const BLOOD_PRESSURE_INTERVAL_GRAPH_VALUE = 25;

export const URINE_CREATININE_MAX_GRAPH_VALUE = 400;
export const URINE_ALBUMIN_MAX_GRAPH_VALUE = 400;
export const UACR_MAX_GRAPH_VALUE = 400;

//#endregion
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;

export const BMI_CATEGORIES = {
  Obesity: "Obesity",
  Overweight: "Overweight",
  Healthy: "Healthy",
  Underweight: "Underweight",
} as const;

export const ROW_VERSION_DEFAULT = "DEFAULT";

export const MIN_LENGTH_PASSWORD_CHAR_COUNT = 12;
//#region Measurements / Manual input data types

export const BMI_PRECISION_MARGIN = 0.01;
export const MAX_BMI_HEIGHT_IN_CM = 300;
export const MAX_BMI_WEIGHT_IN_KG = 400;
export const GOOGLE_MAP_API_KEY = NotNullOrUndefinedWithEnv("VITE_APP_GOOGLE_MAP_API_KEY");
export const GOOGLE_MAP_ID = NotNullOrUndefinedWithEnv("VITE_APP_GOOGLE_MAP_ID");
export const GOOGLE_MAP_PLACES_KEY = NotNullOrUndefinedWithEnv("VITE_APP_GOOGLE_MAP_PLACES_KEY");
export const GOOGLE_MAP_PLACES_SEARCH_MAP_ID = NotNullOrUndefinedWithEnv(
  "VITE_APP_GOOGLE_MAP_PLACES_SEARCH_MAP_ID",
);

export const QUERY_SERVER = {
  GET_PROFILE_PICTURE_S3_SIGNED_URL: (organizationId: string, userEntityId: string) =>
    `${process.env.VITE_APP_QUERY_HOST}/organizations/${organizationId}/users/${userEntityId}/images/avatar`,
  GET_PROFILE_PICTURES: (organizationId: string) =>
    `${process.env.VITE_APP_QUERY_HOST}/organizations/${organizationId}/images/avatar`,
} as const;

export const EVENT_SERVER = {
  DELETE_AVATAR: (organizationId: string, userEntityId: string) =>
    `${process.env.VITE_APP_UPDATE_HOST}/organizations/${organizationId}/users/${userEntityId}/images/avatar`,
} as const;

export const AVATAR_NAME_FILE_NAME = "avatar320x320.png";
