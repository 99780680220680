import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { GenderType } from "models/PersonModels";
import { useCallback } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

export function useGetSerumCreatinineRange() {
  const { appConfig } = useGlobalConfigContext();

  const { serumCreatinine } = NotNullOrUndefined(appConfig?.laboratory.measurements);

  const getRanges = useCallback(
    (gender?: GenderType) => {
      if (!gender) {
        return [0, 0];
      }

      return [
        serumCreatinine[gender === "Male" ? "maleNormalRange" : "femaleNormalRange"].from ?? 0,
        serumCreatinine[gender === "Male" ? "maleNormalRange" : "femaleNormalRange"].to ?? 0,
      ];
    },
    [serumCreatinine],
  );

  return [getRanges] as const;
}
