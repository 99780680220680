import {
  CountryType,
  EgfrFormulaType,
  LimitsModel,
  MeasurementsModel,
  MeasurementUnitType,
  OldConfigurationModel,
  PropertyContextModel,
  RegistryNumberModel,
  RegistryNumberType,
  UpdateConfigurationCommandModel,
} from "api/config_service/models";
import { NationalityType } from "api/query/models/NationalityType";
import { UserModel } from "components/Forms/User/model";
import { CarnaConfigurationService } from "config/apiConfigService";
import { ROW_VERSION_DEFAULT } from "config/const";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { toastStore } from "config/toast";
import i18n from "i18next";
import { CountryCode } from "libphonenumber-js";
import { intersection, isObject, isPlainObject, set } from "lodash-es";
import { defaultModel, isDefaultModel, LoadableModel, loaded, loading } from "models/loadable";
import { PatientModel, UserRoleType, HCPModel } from "models/PersonModels";
import { getCurrentUserData } from "utils/getCurrentUserData";
import { isBeErrorModel } from "./guards";
import { showBeFieldErrors } from "./helpers/showBeFieldErrors";
import { lowerCaseFirstLetterRecursive } from "./lowerCaseFirstLetterRecursive";
import { OrganizationModel } from "models/OrganizationModels/OrganizationModels";

const ALLOWED_CSV_DOWNLOAD = "cameroon";
export const ALLOWED_ENVS_REPORTS_PAGE = ["localhost", "test", "uat"];

type BasicFilterOptions = "countries" | "states" | "userStatuses";

export const MeasurementWidgetType = {
  SerumCreatinine: "SerumCreatinine",
  Egfr: "Egfr",
  Uacr: "Uacr",
  SemiQuantitativeUacr: "SemiQuantitativeUacr",
  UrineCreatinine: "UrineCreatinine",
  UrineAlbumin: "UrineAlbumin",
  Glucose: "Glucose",
  Bmi: "Bmi",
  Height: "Height",
  Weight: "Weight",
  BloodPressure: "BloodPressure",
} as const;

export type MeasurementWidgetType =
  (typeof MeasurementWidgetType)[keyof typeof MeasurementWidgetType];

export type PatientFilterOptions =
  | "hcpIds"
  | "contains"
  | "withDevice"
  | "userStatuses"
  | "organizationIds";

export type AdministratorsTableColumns =
  | "userStatuses"
  | "userRoles"
  | "organizationIds"
  | "email"
  | "phone"
  | "address"
  | "countries"
  | "actions";

export type PartnersTableColumns =
  | "userStatuses"
  | "email"
  | "phone"
  | "address"
  | "countries"
  | "actions";

export type DevicesTableColumns =
  | "deviceType"
  | "manufacturerType"
  | "serialNumber"
  | "organizationIds"
  | "deviceStatuses"
  | "userIds"
  | "actions";

export type HCPTableColumns =
  | "userStatuses"
  | "role"
  | "organizationIds"
  | "email"
  | "phone"
  | "address"
  | "countries"
  | "withDevice"
  | "actions";

export type PatientsTableColumns =
  | "userStatuses"
  | "email"
  | "hcpIds"
  | "serumCreatinine"
  | "eGFR"
  | "glucose"
  | "withDevice"
  | "bmi"
  | "actions"
  | "creatinineEgfr"
  | "bloodPressure"
  | "uacr"
  | "squacr";

export type OrganizationsTableColumns = "name" | "phone" | "address" | "countries" | "actions";

export type AdminFilterOptions = BasicFilterOptions | "userRoles" | "contains" | "organizationIds";
export type PartnerFilterOptions = BasicFilterOptions | "contains";
export type OrganizationFilterOptions = Exclude<BasicFilterOptions, "userStatuses"> | "contains";
export type HcpFilterOptions = BasicFilterOptions | "organizationIds" | "contains" | "withDevice";
export type DevicesFilterOptions = "deviceStatuses" | "organizationIds" | "contains" | "userIds";

export const FieldVisibilityType = {
  Optional: "Optional",
  Disabled: "Disabled",
  Required: "Required",
} as const;

export type FieldVisibilityType = (typeof FieldVisibilityType)[keyof typeof FieldVisibilityType];

export interface ConfigFieldModel<ValueType> {
  contexts: ContextsFieldConfig;
  value: ValueType;
  label?: string;
}

export function isConfigFieldContextLikeObject<
  T extends ConfigFieldContextLikeObject | Record<string, any>,
>(model: T): model is T extends ConfigFieldContextLikeObject ? T : never {
  if (isObject(model) && Object.hasOwn(model, "contexts")) {
    return true;
  }
  return false;
}

interface ConfigPhoneNumberFieldModel {
  contexts: ContextsFieldConfig;
  initCountryCode: CountryType;
}

function makeContextsFieldConfig(
  contexts: {
    [key: string]: PropertyContextModel;
  } = {},
): ContextsFieldConfig {
  return {
    Add: {
      visibility: contexts["Add"]?.visibility ?? "Optional",
      validatorTypeName: contexts["Add"]?.validatorTypeName,
    },
    Update: {
      visibility: contexts["Update"]?.visibility ?? "Optional",
      validatorTypeName: contexts["Update"]?.validatorTypeName,
    },
  };
}

export function ConfigField<ValueType>(
  contexts: {
    [key: string]: PropertyContextModel;
  } = {},
  value: ValueType,
  label?: string,
): ConfigFieldModel<ValueType> {
  return { contexts: makeContextsFieldConfig(contexts), value, label };
}

export function ConfigPhoneNumberField(
  contexts: {
    [key: string]: PropertyContextModel;
  } = {},
  initCountryCode: CountryType,
): ConfigPhoneNumberFieldModel {
  return { contexts: makeContextsFieldConfig(contexts), initCountryCode };
}

export function ConfigRegistryNumberConfigField(
  model?: RegistryNumberModel,
): RegistryNumberConfigField {
  return {
    contexts: makeContextsFieldConfig(model?.contexts),
    registryNumberType: model?.registryNumberType ?? "FreeString",
  };
}

interface PageAccess {
  AddModalAccess: UserRoleType[];
  Access: UserRoleType[];
}

interface GraphConfig {
  showTrending: boolean;
}
interface TableConfig<FilterOptions extends string[], Columns extends string[]> {
  filterOptions: FilterOptions;
  columnList: Columns;
}

interface TableWithTrending<FilterOptions extends string[], Columns extends string[]>
  extends TableConfig<FilterOptions, Columns> {
  showTrending: boolean;
}

interface ViewOptionsConfig {
  enableListView?: boolean;
  enableGraphView?: boolean;
  enableCalendarView?: boolean;
}

export function ConfigViewOptions(options?: {
  enableListView?: boolean;
  enableGraphView?: boolean;
  enableCalendarView?: boolean;
}): ViewOptionsConfig {
  return {
    enableListView: options?.enableListView,
    enableGraphView: options?.enableGraphView,
    enableCalendarView: options?.enableCalendarView,
  };
}

interface EGFRConfig {
  enableMeasurements?: boolean;
  preferredAlgorithm?: EgfrFormulaType;
}

export function ConfigEGFR(options?: {
  enableMeasurements?: boolean;
  preferredAlgorithm?: EgfrFormulaType;
}): EGFRConfig {
  return {
    enableMeasurements: options?.enableMeasurements,
    preferredAlgorithm: options?.preferredAlgorithm,
  };
}
interface NormalRangeModel {
  from?: number;
  to?: number;
}

export function getNormalRange(range?: NormalRangeModel): NormalRangeModel {
  return {
    from: range?.from,
    to: range?.to,
  };
}

export type ValuesOf<T extends Record<string, string>> = T[keyof T];

type TestMethodType = {
  Device: "Device";
};

type DeviceType = {
  None: "None";
  NovaMaxProCreateEgfrMeter: "NovaMaxProCreateEgfrMeter";
};

export interface SerumCreatinineConfig {
  enableMeasurements?: boolean;
  preferredDevice?: ValuesOf<DeviceType>;
  testMethod?: ValuesOf<TestMethodType>;
  measurementUnit?: MEASUREMENT_UNITS;
  maleNormalRange: NormalRangeModel;
  femaleNormalRange: NormalRangeModel;
}

export function ConfigSerumCreatinine(options?: {
  enableMeasurements?: boolean;
  preferredDevice?: ValuesOf<DeviceType>;
  testMethod?: ValuesOf<TestMethodType>;
  measurementUnit?: MeasurementUnitType;
  maleNormalRange: NormalRangeModel;
  femaleNormalRange: NormalRangeModel;
}): SerumCreatinineConfig {
  return {
    enableMeasurements: options?.enableMeasurements,
    preferredDevice: options?.preferredDevice,
    testMethod: options?.testMethod,
    measurementUnit: MEASUREMENT_UNITS[options?.measurementUnit ?? "mgdL"],
    maleNormalRange: getNormalRange(options?.maleNormalRange),
    femaleNormalRange: getNormalRange(options?.femaleNormalRange),
  };
}

interface UrineAlbuminConfig {
  measurementUnit?: MEASUREMENT_UNITS;
}

export function makeTableConfig<FilterOptions extends string[], Columns extends string[]>(
  filterOptions: FilterOptions,
  columns: Columns,
): TableConfig<FilterOptions, Columns> {
  return {
    filterOptions,
    columnList: columns,
  } as const;
}

export function makeTrendTableConfig<FilterOptions extends string[], Columns extends string[]>(
  filterOptions: FilterOptions,
  columns: Columns,
  showTrending: boolean,
): TableWithTrending<FilterOptions, Columns> {
  return {
    ...makeTableConfig(filterOptions, columns),
    showTrending,
  } as const;
}

export function makeGraphConfig(showTrending: boolean): GraphConfig {
  return {
    showTrending,
  } as const;
}

export interface LaboratoryConfig {
  measurements: {
    serumCreatinine: SerumCreatinineConfig;
    egfr: EGFRConfig;
    viewOptions: ViewOptionsConfig;
    urineAlbumin: UrineAlbuminConfig;
    limits?: LimitsModel;
  };
}

export interface ContextFieldConfig {
  visibility: FieldVisibilityType;
  validatorTypeName?: string | null;
}

export interface ContextsFieldConfig {
  Add: ContextFieldConfig;
  Update: ContextFieldConfig;
}

export interface Enum {
  N: string;
  V: number;
}

interface WidgetModel {
  id: string;
  index: number;
}

export const ReportType = {
  WorkspaceInfo: "WorkspaceInfo",
  DeviceAndApps: "DeviceAndApps",
  Measurements: "Measurements",
  ScreeningDates: "ScreeningDates",
  PatientsStats: "PatientsStats",
  CkdHeatMap: "CkdHeatMap",
} as const;

export type ReportType = (typeof ReportType)[keyof typeof ReportType];

interface ReportTypeModel {
  id: ReportType;
  index: number;
}

export interface CustomConfigField {
  enums: Record<string, Enum[]> | Record<"UserHcpType", Enum[]>;
  widgets: Array<WidgetModel>;
  reports: Array<ReportTypeModel>;
}

export interface AdditionalPropertyConfigField {
  contexts: ContextsFieldConfig;
  typeName: string;
  propertyName: string;
}

export interface RegistryNumberConfigField {
  contexts: ContextsFieldConfig;
  registryNumberType: RegistryNumberType;
}
export interface ConfigFieldContextLikeObject {
  contexts: ContextsFieldConfig;
  [key: string]: any;
}

export type RoleEntities = Extract<
  keyof GlobalConfig["entities"],
  "admin" | "partner" | "hcp" | "patient"
>;

export interface GlobalConfig {
  general: {
    registryNumber: RegistryNumberConfigField;
    phoneNumberField: ConfigPhoneNumberFieldModel;
    includeDeletedUsers: boolean;
    custom: CustomConfigField;
    translationTenantId?: string;
  };
  pages: {
    TestsPage: PageAccess;
    Settings: {
      RuleEngine: PageAccess;
    };
    PatientsPage: {
      Parent: {
        ShowDownloadReportButton: boolean;
      };
      Details: {
        // ! UPDATE e2e TESTS IF THIS PATH CHANGES !!!
        ShowOverviewTab: boolean;
        MeasurementWidgets: MeasurementWidgetType[];
      };
    };
  };
  laboratory: LaboratoryConfig;
  entities: {
    admin: {
      zipCode: ConfigFieldModel<any>;
      nationalityType: ConfigFieldModel<any>;
      email: ConfigFieldModel<any>;
    };
    partner: {
      zipCode: ConfigFieldModel<any>;
      nationalityType: ConfigFieldModel<any>;
      email: ConfigFieldModel<any>;
    };
    hcp: {
      zipCode: ConfigFieldModel<any>;
      nationalityType: ConfigFieldModel<any>;
      email: ConfigFieldModel<any>;
    };
    patient: {
      ancestry: ConfigFieldModel<any>;
      deviceField: ConfigFieldModel<any>;
      email: ConfigFieldModel<any>;
      hcp: ConfigFieldModel<any>;
      zipCode: ConfigFieldModel<any>;
      nationalityType: ConfigFieldModel<any>;
      generalPractitioner: ConfigFieldModel<any>;
      additionalProperties: AdditionalPropertyConfigField[];
    };
    device: {
      uniqueId: ConfigFieldModel<any>;
    };
    organization: {
      zipCode: ConfigFieldModel<any>;
    };
  };
  components: {
    disabled: {
      patientGraphTabs: boolean;
      deactivatePersons: UserRoleType[];
    };
    graphs: {
      Patient: {
        Index: GraphConfig;
      };
    };
    tables: {
      Patient: {
        Index: TableConfig<PatientFilterOptions[], PatientsTableColumns[]>;
        PatientPerHcpTable: TableWithTrending<PatientFilterOptions[], PatientsTableColumns[]>;
        PatientPerOrganizationTable: TableWithTrending<
          PatientFilterOptions[],
          PatientsTableColumns[]
        >;
      };
      Administrator: {
        Index: TableConfig<AdminFilterOptions[], AdministratorsTableColumns[]>;
        AdministratorPerOrganizationTable: TableConfig<
          AdminFilterOptions[],
          AdministratorsTableColumns[]
        >;
      };
      Partner: {
        PartnerPerOrganizationTable: TableConfig<PartnerFilterOptions[], PartnersTableColumns[]>;
      };
      Organization: TableConfig<OrganizationFilterOptions[], OrganizationsTableColumns[]>;
      Hcp: {
        Index: TableConfig<HcpFilterOptions[], HCPTableColumns[]>;
        HcpPerOrganization: TableConfig<HcpFilterOptions[], HCPTableColumns[]>;
      };
      Devices: {
        Index: TableConfig<DevicesFilterOptions[], DevicesTableColumns[]>;
        DevicesPerOrganization: TableConfig<DevicesFilterOptions[], DevicesTableColumns[]>;
      };
      Laboratory: {
        Index: TableWithTrending<[], []>;
        Patient: TableWithTrending<[], []>;
        Organization: TableWithTrending<[], []>;
        Devices: TableWithTrending<[], []>;
      };
    };
  };
  forms: {
    addModal: {
      users: {
        allowedUserRoles: UserRoleType[];
      };
    };
    defaultValues: {
      user: UserModel;
      patient: PatientModel;
      organization: OrganizationModel;
      hcp: HCPModel;
    };
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface ConfigurationResponse extends OldConfigurationModel {
  // registryNumberName?: string | null;
  // mandatoryRegistryNumber?: boolean;
  // supportsRegistryNumber?: boolean;
}

export const hardcodedGlobalConfig = {
  // general: {
  //   includeDeletedUsers: false,
  //   phoneNumberField: ConfigPhoneNumberField("Optional", "US"),
  //   registryNumber: ConfigField<string>(
  //     "Optional",
  //     "",
  //   ),
  // },
  // entities: {admin: {}, hcp: {}, organization: {}},
  pages: {
    TestsPage: {
      AddModalAccess: [],
      Access: [],
    } satisfies PageAccess,
    Settings: {
      RuleEngine: {
        AddModalAccess: [],
        Access: [],
      } satisfies PageAccess,
    },
    PatientsPage: {
      Parent: {
        ShowDownloadReportButton: false,
      },
      Details: {
        ShowOverviewTab: false,
        MeasurementWidgets: [
          "SerumCreatinine",
          "Egfr",
          "Uacr",
          "SemiQuantitativeUacr",
          "UrineCreatinine",
          "UrineAlbumin",
          "Glucose",
          "Bmi",
          "Weight",
          "BloodPressure",
        ] satisfies MeasurementWidgetType[],
      },
    },
  },
  laboratory: {
    measurements: {
      serumCreatinine: ConfigSerumCreatinine({
        enableMeasurements: false,
        femaleNormalRange: {
          from: 0,
          to: 1.1,
        },
        maleNormalRange: {
          from: 0,
          to: 1.3,
        },
        measurementUnit: "mgdL",
        preferredDevice: "None",
        testMethod: "Device",
      }),
      egfr: ConfigEGFR({ enableMeasurements: false, preferredAlgorithm: "CKDEPI2021" }),
      viewOptions: ConfigViewOptions({
        enableCalendarView: false,
        enableGraphView: true,
        enableListView: true,
      }),
      urineAlbumin: {
        measurementUnit: MEASUREMENT_UNITS.mgdL,
      },
    },
  },
  components: {
    disabled: {
      // "MeasurementSideDetails/SideDetails/elements/TrendDetailsField": false,
      patientGraphTabs: false,
      deactivatePersons: ["Patient"] satisfies UserRoleType[],
    },
    graphs: {
      Patient: {
        Index: makeGraphConfig(true),
      },
    },
    tables: {
      Patient: {
        Index: makeTableConfig<PatientFilterOptions[], PatientsTableColumns[]>(
          ["userStatuses", "contains", "hcpIds", "organizationIds"],
          [
            "userStatuses",
            "hcpIds",
            "creatinineEgfr",
            "uacr",
            "squacr",
            "glucose",
            "bmi",
            "bloodPressure",
            "actions",
          ],
        ),
        PatientPerHcpTable: makeTrendTableConfig<PatientFilterOptions[], PatientsTableColumns[]>(
          ["userStatuses", "contains"],
          [
            "userStatuses",
            "creatinineEgfr",
            "uacr",
            "squacr",
            "glucose",
            "bmi",
            "bloodPressure",
            "withDevice",
            "actions",
          ],
          true,
        ),
        PatientPerOrganizationTable: makeTrendTableConfig<
          PatientFilterOptions[],
          PatientsTableColumns[]
        >(
          ["userStatuses", "contains", "hcpIds"],
          [
            "userStatuses",
            "hcpIds",
            "creatinineEgfr",
            "uacr",
            "squacr",
            "glucose",
            "bmi",
            "bloodPressure",
            "withDevice",
            "actions",
          ],
          true,
        ),
      },
      Administrator: {
        Index: makeTableConfig<AdminFilterOptions[], AdministratorsTableColumns[]>(
          ["contains", "countries", "states", "userRoles", "userStatuses", "organizationIds"],
          [
            "userStatuses",
            "userRoles",
            "organizationIds",
            "email",
            "phone",
            "address",
            "countries",
            "actions",
          ],
        ),
        AdministratorPerOrganizationTable: makeTableConfig<
          AdminFilterOptions[],
          AdministratorsTableColumns[]
        >(
          ["contains", "countries", "states", "userStatuses"],
          ["userStatuses", "phone", "address", "countries", "actions"],
        ),
      },
      Partner: {
        PartnerPerOrganizationTable: makeTableConfig<
          PartnerFilterOptions[],
          PartnersTableColumns[]
        >(
          ["contains", "countries", "states", "userStatuses"],
          ["userStatuses", "phone", "address", "countries", "actions"],
        ),
      },
      Organization: makeTableConfig<OrganizationFilterOptions[], OrganizationsTableColumns[]>(
        ["contains", "countries", "states"],
        ["name", "phone", "address", "countries"],
      ),
      Hcp: {
        Index: makeTableConfig<HcpFilterOptions[], HCPTableColumns[]>(
          ["contains", "countries", "states", "userStatuses", "withDevice", "organizationIds"],
          [
            "userStatuses",
            "organizationIds",
            "email",
            "address",
            "countries",
            "withDevice",
            "actions",
          ],
        ),
        HcpPerOrganization: makeTableConfig<HcpFilterOptions[], HCPTableColumns[]>(
          ["contains", "countries", "states", "userStatuses", "withDevice"],
          ["userStatuses", "role", "address", "countries", "withDevice", "actions"],
        ),
      },
      Devices: {
        Index: makeTableConfig<DevicesFilterOptions[], DevicesTableColumns[]>(
          ["contains", "deviceStatuses", "organizationIds"],
          ["deviceType", "organizationIds", "serialNumber", "deviceStatuses", "userIds"],
        ),
        DevicesPerOrganization: makeTableConfig<DevicesFilterOptions[], DevicesTableColumns[]>(
          ["contains", "deviceStatuses"],
          ["deviceType", "manufacturerType", "serialNumber", "deviceStatuses"],
        ),
      },
      Laboratory: {
        Index: makeTrendTableConfig<[], []>([], [], true),
        Patient: makeTrendTableConfig<[], []>([], [], true),
        Organization: makeTrendTableConfig<[], []>([], [], true),
        Devices: makeTrendTableConfig<[], []>([], [], true),
      },
    },
  },
  forms: {
    addModal: {
      users: { allowedUserRoles: ["Admin"] },
    },
    defaultValues: {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        country: CountryType.BM satisfies CountryCode,
        state: null,
        zipCode: null,
        rowVersion: ROW_VERSION_DEFAULT,
        organizationId: "",
        role: UserRoleType.Admin,
      } as unknown as UserModel,
      patient: {
        firstName: "",
        lastName: "",
        // registryNumber: "", registryNumber configuration is defined in the ConfigurationResponse (supportsRegistryNumber?: boolean)
        email: "",
        phone: "",
        street: "",
        city: "",
        country: CountryType.BM satisfies CountryCode,
        state: null,
        zipCode: null,
        organizationId: "",
        gender: undefined,
        ancestry: null,
        dateOfBirth: "",
        height: undefined,
        weight: undefined,
        nationalityType: null,
        rowVersion: ROW_VERSION_DEFAULT,
        userStatus: "Invited",
      } as unknown as PatientModel,
      organization: {
        name: "",
        phone: "",
        street: "",
        city: "",
        nameUpper: "",
        country: CountryType.BM satisfies CountryCode,
        state: null,
        zipCode: null,
        rowVersion: "",
        isMain: false,
      } as unknown as OrganizationModel,
      hcp: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        country: CountryType.BM satisfies CountryCode,
        state: null,
        zipCode: null,
        rowVersion: ROW_VERSION_DEFAULT,
        organizationId: "",
        isAccountOwner: false,
        // hcpType: "Other",
      } as unknown as HCPModel,
    },
  },
};

type Listener = () => void;

const globalConfigProxy = (
  mappedConfig: any,
  localConfig: any,
  prevPath: string = "",
  showPrevPath = false,
): any =>
  new Proxy(mappedConfig, {
    get(target, p) {
      if (Object.hasOwn(target, p)) {
        if (isPlainObject(target[p])) {
          return globalConfigProxy(
            target[p],
            localConfig[p] ?? {},
            `${prevPath}.${p as string}`,
            showPrevPath,
          );
        }

        if (showPrevPath) {
          if (process.env.MODE !== "production") {
            // ! Modify Sentry breadcrumbs part if you change this
            console.warn(`Returning hardcoded value: ${prevPath}.${p as string}`);
          }
        }

        return target[p];
      } else {
        if (isPlainObject(localConfig[p])) {
          return globalConfigProxy(localConfig[p], {}, `${prevPath}.${p as string}`, true);
        }
        // ! Modify Sentry breadcrumbs part if you change this
        if (process.env.MODE !== "production") {
          console.warn(`Returning hardcoded value: ${prevPath}.${p as string}`);
        }
        return localConfig[p];
      }
    },
  });

export function laboratoryConfigMapper(
  measurements: MeasurementsModel | undefined,
): LaboratoryConfig {
  return {
    measurements: {
      serumCreatinine: ConfigSerumCreatinine(measurements?.creatinine),
      egfr: ConfigEGFR(measurements?.egfr),
      viewOptions: ConfigViewOptions(measurements?.viewOptions),
      urineAlbumin: {
        measurementUnit: MEASUREMENT_UNITS.mgdL,
      },
      limits: measurements?.limits,
    },
  };
}

function generalConfigMapper(
  configurationResponse?: ConfigurationResponse,
): GlobalConfig["general"] {
  return {
    translationTenantId: configurationResponse?.infrastructure?.translationTenantId,
    custom: (configurationResponse?.entities?.custom ?? {}) as CustomConfigField,
    includeDeletedUsers: configurationResponse?.users?.includeDeletedUsers ?? false,
    phoneNumberField: ConfigPhoneNumberField(
      {
        Add: {
          visibility: "Optional",
          validatorTypeName: null,
        },
        Update: {
          visibility: "Optional",
          validatorTypeName: null,
        },
      },
      configurationResponse?.web?.components?.defaultValues?.phoneNumberField?.country ??
        CountryType.None,
    ),
    // registryNumber: ConfigField<string>(
    //   configurationResponse?.entities?.patient?.registryNumber?.visibility ?? "Optional",
    //   "",
    //   configurationResponse?.entities?.patient?.registryNumber?.registryNumberType ?? "MRN",
    // ),
    registryNumber: ConfigRegistryNumberConfigField(
      configurationResponse?.entities?.patient?.registryNumber,
    ),
  };
}

function entitiesConfigMapper(configurationResponse?: ConfigurationResponse) {
  const responseEntities = configurationResponse?.entities;
  return {
    admin: {
      zipCode: ConfigField<string>(responseEntities?.admin?.zipCode?.contexts, ""),
      nationalityType: ConfigField<NationalityType>(
        responseEntities?.admin?.nationalityType?.contexts,
        "None",
      ),
      email: ConfigField<string>(responseEntities?.admin?.email.contexts, ""),
    } satisfies GlobalConfig["entities"]["admin"],
    partner: {
      zipCode: ConfigField<string>(responseEntities?.partner?.zipCode?.contexts, ""),
      nationalityType: ConfigField<NationalityType>(
        responseEntities?.partner?.nationalityType?.contexts,
        "None",
      ),
      email: ConfigField<string>(responseEntities?.partner?.email?.contexts, ""),
    } satisfies GlobalConfig["entities"]["partner"],
    hcp: {
      zipCode: ConfigField<string>(responseEntities?.hcp?.zipCode?.contexts, ""),
      nationalityType: ConfigField<NationalityType>(
        responseEntities?.hcp?.nationalityType?.contexts,
        "None",
      ),
      email: ConfigField<string>(responseEntities?.hcp?.email.contexts, ""),
    } satisfies GlobalConfig["entities"]["hcp"],
    device: {
      uniqueId: ConfigField<string>(responseEntities?.device?.uniqueId.contexts, ""),
    } satisfies GlobalConfig["entities"]["device"],
    patient: {
      ancestry: ConfigField<string>(responseEntities?.patient?.ancestry?.contexts, ""),
      deviceField: ConfigField<string>(responseEntities?.patient?.deviceId?.contexts, ""),
      // ! hardcoded for now
      hcp: ConfigField<string>(
        {
          Add: {
            visibility: "Optional",
            validatorTypeName: null,
          },
          Update: {
            visibility: "Optional",
            validatorTypeName: null,
          },
        },
        "",
      ),
      email: ConfigField<string>(responseEntities?.patient?.email?.contexts, ""),
      zipCode: ConfigField<string>(responseEntities?.patient?.zipCode?.contexts, ""),
      nationalityType: ConfigField<NationalityType>(
        responseEntities?.patient?.nationalityType?.contexts,
        "None",
      ),
      generalPractitioner: ConfigField<string>(
        responseEntities?.patient?.generalPractitioner?.contexts,
        "",
      ),
      additionalProperties:
        (configurationResponse?.entities?.patient?.additionalProperties as any) ?? [],
    } satisfies GlobalConfig["entities"]["patient"],
    organization: {
      zipCode: ConfigField<string>(responseEntities?.organization?.zipCode?.contexts, ""),
    },
  } as const;
}

export function configMapper(configurationResponse?: ConfigurationResponse) {
  const responseTables = configurationResponse?.web?.components?.tables;

  const mappedGlobalConfig = {
    laboratory: laboratoryConfigMapper(configurationResponse?.laboratory?.measurements),
    general: generalConfigMapper(configurationResponse),
    entities: entitiesConfigMapper(configurationResponse),
  };

  if (configurationResponse?.web?.components?.defaultValues.userManagementRoles) {
    set(
      mappedGlobalConfig,
      "forms.addModal.users.allowedUserRoles",
      configurationResponse?.web?.components?.defaultValues.userManagementRoles ?? ["Admin"],
    );
  }

  if (configurationResponse?.web?.forms.defaultValues) {
    set(mappedGlobalConfig, "forms.defaultValues", configurationResponse?.web.forms.defaultValues);
  }

  if (configurationResponse?.web?.components.disabled) {
    const value =
      configurationResponse.web?.components.disabled.pagesSettingsRuleEngine === false
        ? ["Admin"]
        : [];

    set(mappedGlobalConfig, "pages.Settings.RuleEngine.Access", value);

    const { deactivatePersons } = configurationResponse.web?.components.disabled ?? {};

    set(mappedGlobalConfig, "components.disabled.deactivatePersons", deactivatePersons);
  }

  if (configurationResponse?.infrastructure?.translationTenantId) {
    const value =
      configurationResponse?.infrastructure?.translationTenantId === ALLOWED_CSV_DOWNLOAD;

    set(mappedGlobalConfig, "pages.PatientsPage.Parent.ShowDownloadReportButton", value);
  }

  // ! UPDATE e2e TESTS IF THIS PATH CHANGES !!!
  if (configurationResponse?.web?.pages?.patientDetails.showOverviewTab) {
    const value = configurationResponse?.web?.pages?.patientDetails.showOverviewTab;

    set(mappedGlobalConfig, "pages.PatientsPage.Details.ShowOverviewTab", value);
  }

  if (responseTables?.patient) {
    // ! If needed, we can split up in config every individual table config in web section

    set(mappedGlobalConfig, "components.tables.Patient", {
      Index: makeTableConfig<PatientFilterOptions[], PatientsTableColumns[]>(
        intersection(
          ["userStatuses", "contains", "hcpIds", "organizationIds"],
          responseTables.patient.filterOptions,
        ) as PatientFilterOptions[],
        [
          "userStatuses",
          "hcpIds",
          "creatinineEgfr",
          "uacr",
          "squacr",
          "glucose",
          "bmi",
          "bloodPressure",
          "actions",
        ],
      ),
      PatientPerHcpTable: makeTrendTableConfig<PatientFilterOptions[], PatientsTableColumns[]>(
        intersection(
          ["userStatuses", "contains"],
          responseTables.patient.filterOptions,
        ) as PatientFilterOptions[],
        [
          "userStatuses",
          "creatinineEgfr",
          "uacr",
          "squacr",
          "glucose",
          "bmi",
          "bloodPressure",
          "withDevice",
          "actions",
        ],
        true,
      ),
      PatientPerOrganizationTable: makeTrendTableConfig<
        PatientFilterOptions[],
        PatientsTableColumns[]
      >(
        intersection(
          ["userStatuses", "contains", "hcpIds"],
          responseTables.patient.filterOptions,
        ) as PatientFilterOptions[],
        [
          "userStatuses",
          "hcpIds",
          "creatinineEgfr",
          "uacr",
          "squacr",
          "glucose",
          "bmi",
          "bloodPressure",
          "withDevice",
          "actions",
        ],
        true,
      ),
    });
  }

  return globalConfigProxy(mappedGlobalConfig, hardcodedGlobalConfig);
}

export interface CreateGlobalConfigStoreRetVal {
  readonly fetchConfig: (force?: boolean) => Promise<void>;
  readonly getConfig: () => LoadableModel<GlobalConfig>;
  getRawLowerCasedData: () => Promise<OldConfigurationModel | undefined>;
  // Only use it for save plz
  readonly getOrgConfig: () => ConfigurationResponse | null;
  readonly saveConfig: (config: UpdateConfigurationCommandModel["_configuration"]) => Promise<void>;
  readonly subscribe: (listener: Listener) => () => boolean;
  readonly clear: () => void;
}

export function createGlobalConfigStore(): CreateGlobalConfigStoreRetVal {
  let config: LoadableModel<GlobalConfig> = defaultModel();
  const listeners = new Set<Listener>();

  let orgConfig: ConfigurationResponse | null = null;

  const getConfig = () => config;
  // Only use it for save plz
  const getOrgConfig = () => orgConfig;

  const notifySubscribers = () => {
    listeners.forEach(listener => {
      listener();
    });
  };
  const subscribe = (listener: Listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };

  const clear = () => {
    config = defaultModel();
    notifySubscribers();
  };

  const fetchConfig = async (force = false) => {
    if (force === false && !isDefaultModel(config)) {
      return;
    }

    config = loading(config.value);
    notifySubscribers();

    try {
      const { tenantId } = await getCurrentUserData();

      const result = await CarnaConfigurationService.general.getFullConfig(tenantId);

      orgConfig = result;

      config = loaded(configMapper(result));
    } catch (error) {
      console.error(`Something went wrong... failing back to default configuration`);

      // if (import.meta.env.MODE === "development") {
      console.error(error);
      // }

      config = loaded(configMapper(orgConfig ?? undefined));
      toastStore.toast.error({ msg: i18n.t("GlobalConfig.errorGetAppConfigToastText") });
    }
    notifySubscribers();
  };

  const getRawLowerCasedData = async () => {
    try {
      const { tenantId } = await getCurrentUserData();
      const raw = await (
        await CarnaConfigurationService.general.getFullConfigRaw({ name: tenantId })
      ).raw.json();

      return lowerCaseFirstLetterRecursive(raw) as OldConfigurationModel;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_) {
      toastStore.toast.error({ msg: i18n.t("GlobalConfig.errorGetAppConfigToastText") });
    }
  };

  const saveConfig = async (newConfig: UpdateConfigurationCommandModel["_configuration"]) => {
    const { tenantId } = await getCurrentUserData();
    config = loading(config.value);

    notifySubscribers();

    try {
      await CarnaConfigurationService.general.saveFullConfig(tenantId, undefined, {
        tenantName: tenantId,
        _configuration: newConfig,
      });

      toastStore.toast.success({
        msg: i18n.t("GlobalPreferenceContext.successPutPreferenceToastText"),
        expire: 5000,
      });

      await fetchConfig(true);
    } catch (err: any) {
      config = loaded(config.value!);

      const response = err.response as Response;
      const resolveJson = await response.json();

      if (isBeErrorModel(resolveJson)) {
        showBeFieldErrors(
          resolveJson,
          i18n.t("GlobalPreferenceContext.errorPutPreferenceToastText"),
        );
        return;
      }

      toastStore.toast.error({
        msg: i18n.t("GlobalPreferenceContext.errorPutPreferenceToastText"),
      });
    }
  };

  return {
    fetchConfig,
    getConfig,
    getOrgConfig,
    saveConfig,
    subscribe,
    clear,
    getRawLowerCasedData,
  } as const;
}
