import "cropperjs/dist/cropper.css";
import { useCallback, useReducer, useState } from "react";
import Cropper from "react-cropper";

import { Button, Icon, Modal } from "libs/ui";
import { useTranslation } from "react-i18next";
import "./AvatarCropper.scss";
import {
  AvatarCropperActionType,
  AvatarCropperModalProps,
  avatarCropperReducer,
  initialAvatarCropperState,
} from "./model";

const ZOOM_STEP = 0.3;

const MAX_IMG_SIZE = 320;

export const AvatarCropper = ({
  showCropper,
  setShowCropper,
  srcImg,
  onSave,
}: AvatarCropperModalProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "AvatarCropper" });
  const [cropper, setCropper] = useState<Cropper>();
  const [state, dispatch] = useReducer(avatarCropperReducer, initialAvatarCropperState);
  const [loading, setLoading] = useState(false);

  const closeModal = useCallback(() => {
    dispatch({ type: AvatarCropperActionType.INIT });
    setShowCropper(false);
  }, [setShowCropper]);

  const getCropData = async () => {
    if (typeof cropper !== "undefined") {
      setLoading(true);

      const result = cropper
        .getCroppedCanvas({
          height: MAX_IMG_SIZE,
          width: MAX_IMG_SIZE,
        })
        .toDataURL();

      await onSave(result);

      setLoading(false);
      closeModal();
    }
  };

  const handleReady = (readyEvent: Cropper.ReadyEvent<HTMLImageElement>) => {
    const imageData = readyEvent.currentTarget?.cropper.getImageData();
    const initialZoomVal = imageData.width / imageData.naturalWidth;
    dispatch({ type: AvatarCropperActionType.READY, value: initialZoomVal });
    setCropper(readyEvent.currentTarget?.cropper);
  };

  const zoomOut = useCallback(
    () =>
      dispatch(
        state.zoomVal && state.initialZoomVal && state.zoomVal > state.initialZoomVal + ZOOM_STEP
          ? { type: AvatarCropperActionType.CHANGED, value: state.zoomVal - ZOOM_STEP }
          : { type: AvatarCropperActionType.CHANGED, value: state.initialZoomVal },
      ),
    [state],
  );

  const zoomIn = useCallback(
    () =>
      dispatch(
        state.zoomVal
          ? { type: AvatarCropperActionType.CHANGED, value: state.zoomVal + ZOOM_STEP }
          : { type: AvatarCropperActionType.CHANGED, value: ZOOM_STEP },
      ),
    [state],
  );

  return (
    <Modal show={showCropper} className="AvatarCropperModalWrapper">
      <div className="AvatarCropperModal">
        <header className="AvatarCropperModal__header">
          <h3 className="AvatarCropperModal__header__title">{t("editPhoto")}</h3>
          <button className="AvatarCropperModal__header__close" onClick={closeModal}>
            <Icon icon="Close" />
          </button>
        </header>
        <Cropper
          className="AvatarCropperModal__cropper"
          dragMode={"move"}
          cropBoxMovable={true}
          cropBoxResizable={false}
          guides={false}
          center={false}
          highlight={false}
          toggleDragModeOnDblclick={false}
          initialAspectRatio={1}
          aspectRatio={1}
          viewMode={3}
          zoomTo={state.zoomVal}
          src={srcImg}
          zoomOnWheel
          zoomOnTouch
          responsive
          background={false}
          checkOrientation={false}
          ready={handleReady}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
        />
        <footer className="AvatarCropperModal__footer">
          <div className="AvatarCropperModal__footer__actionsWrapper">
            <button onClick={zoomOut} className="AvatarCropperModal__footer--actions">
              <Icon icon="MinusCircle" />
            </button>
            <button onClick={zoomIn} className="AvatarCropperModal__footer--actions">
              <Icon icon="PlusCircle" />
            </button>
          </div>
          <Button
            data-testid="AvatarCropperModal__save"
            loading={loading}
            buttonSize="medium"
            buttonType="primary"
            onClick={getCropData}
          >
            {t("buttonSave")}
          </Button>
        </footer>
      </div>
    </Modal>
  );
};
