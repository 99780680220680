import { Loader } from "libs/ui/Loader";
import { PropsWithChildren } from "react";
import "./SideModalFooter.scss";

interface SideModalFooterProps {
  isLoading?: boolean;
}

export function Footer({ isLoading, children }: PropsWithChildren<SideModalFooterProps>) {
  return (
    <footer className="SideModalFooter">
      <Loader loading={isLoading}>{children}</Loader>
    </footer>
  );
}
