import { backOff } from "exponential-backoff";
import { useRef } from "react";
import { Subject, catchError, defer, delay, of, switchMap } from "rxjs";
import { getPlaceDetails } from "utils/helpers/google";
const backOffConfig: Parameters<typeof backOff>["1"] = {
  jitter: "full",
  delayFirstAttempt: true,
  startingDelay: 500,
  numOfAttempts: 2,
} as const;
export function useTryGeolocation() {
  const tryGeolocationFindSubject = useRef(new Subject<string>());

  const tryGeolocationFind = useRef(
    tryGeolocationFindSubject.current.pipe(
      delay(300),
      switchMap(placeSearch => {
        return defer(() => backOff(() => getPlaceDetails(placeSearch), backOffConfig)).pipe(
          catchError(error => {
            return of(new Error(error));
          }),
        );
      }),
    ),
  );

  return {
    tryGeolocationFindSubject: tryGeolocationFindSubject.current,
    tryGeolocationFind: tryGeolocationFind.current,
  } as const;
}
