import { TrackingDeviceModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { toastStore } from "config/toast";
import { ActionModal, Button } from "libs/ui";
import { isDefaultModel, isLoading, ModelState } from "models/loadable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActiveDevice } from "./ActiveDevice";
import "./DeviceHistory.scss";
import {
  getTrackingDeviceIcon,
  getTrackingDeviceSystemDetails,
} from "utils/getTrackingDeviceDisplayInfo";
import { useLocalStorage } from "utils/hooks/useLocalStorage";
import { useApi } from "utils/hooks/useApi";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import { signOut } from "@aws-amplify/auth";

const TRACKING_DEVICE_DATA_DEFAULT: TrackingDeviceModel = {
  deviceKey: "",
  deviceName: "",
  deviceLastAuthenticatedDate: new Date(),
  deviceLastModifiedDate: new Date(),
  lastIpUsed: "",
  countryName: "",
  cityName: "",
};

/**
 * Used for loading effect until we get back the data from BE
 */
const randomDeviceTrackingModel: TrackingDeviceModel[] = Array.from({ length: 3 }).map(
  (_, idx) => ({
    ...TRACKING_DEVICE_DATA_DEFAULT,
    deviceName: `Device ${idx}`,
  }),
);

export function DeviceHistory() {
  const { t } = useTranslation("translation", { keyPrefix: "SecurityAndPrivacy.DeviceHistory" });
  const [showRemoveDevices, setShowRemoveDevices] = useState(false);
  const { removeLocalStorageItem } = useLocalStorage("lastUsedAuth");
  const [isGloballySigningOut, setIsGloballySigningOut] = useState(false);

  const [dateFormatter, timeFormatter] = useRegionDateTimeFormatter();

  const [trackedDevices, getTrackedDevices] = useApi(CarnaApiQuery.SignedInDeviceHistory.get, {
    toastText: {
      errorText: t("errorLoadDeviceHistoryToastText"),
    },
  });

  const onConfirmRemove = useCallback(async () => {
    setIsGloballySigningOut(true);
    try {
      await signOut({ global: true });
      removeLocalStorageItem();
      window.location.reload();
    } catch (e) {
      setIsGloballySigningOut(false);
      toastStore.toast.error({ msg: t("errorSignOutAllDevicesToastText") });
    }
  }, [removeLocalStorageItem, t]);

  useEffect(() => {
    if (isDefaultModel(trackedDevices)) {
      getTrackedDevices();
      // setTDevices(loaded(mockedValue));
    }
  }, [trackedDevices, getTrackedDevices]);

  const trackedDevicesBuff = useMemo(() => {
    switch (trackedDevices.state) {
      case ModelState.Loaded:
        return trackedDevices.value.toSorted((currentDevice, nextDevice) => {
          if (
            !nextDevice.deviceLastAuthenticatedDate ||
            !currentDevice.deviceLastAuthenticatedDate
          ) {
            return 0;
          }

          return (
            new Date(nextDevice.deviceLastAuthenticatedDate).valueOf() -
            new Date(currentDevice.deviceLastAuthenticatedDate).valueOf()
          );
        });
      case ModelState.Loading:
        // For loading effect
        return randomDeviceTrackingModel;
      // in case of error we should not show anything
      // TODO? instead of popup should we show the actual error instead of the list ?
      default:
        return [];
    }
  }, [trackedDevices]);

  return (
    <div className="DeviceHistory">
      <h3 className="DeviceHistory__title">{t("title")}</h3>
      <ul className="DeviceHistory__list">
        {trackedDevicesBuff.map(device => (
          <li key={crypto.randomUUID()} className="DeviceHistory__item">
            <ActiveDevice
              date={
                device.deviceLastAuthenticatedDate
                  ? dateFormatter(device.deviceLastAuthenticatedDate)
                  : ""
              }
              time={
                device.deviceLastAuthenticatedDate
                  ? timeFormatter(device.deviceLastAuthenticatedDate)
                  : ""
              }
              icon={getTrackingDeviceIcon(device.deviceName)}
              systemDetails={
                device.deviceName ? getTrackingDeviceSystemDetails(device.deviceName) : ""
              }
              location={`${device.cityName}, ${device.countryName}`}
              loading={isLoading(trackedDevices)}
            />
          </li>
        ))}
      </ul>

      <div className="SignOutAll">
        <Button
          onClick={() => setShowRemoveDevices(true)}
          buttonIcon={{ icon: "SignOut", iconPosition: "leading" }}
          buttonType="link-primary"
          buttonSize="medium"
        >
          {t("signOutAll")}
        </Button>
        <p className="SignOutAll__details">{t("signOutAllDetails")}</p>
      </div>

      <ActionModal
        show={showRemoveDevices}
        icon={{
          iconType: "SignOut",
        }}
        title={t("RemoveDevicesModal.title")}
        cancelButton={
          <Button
            onClick={() => setShowRemoveDevices(prevValue => !prevValue)}
            buttonSize={"medium"}
            buttonType={"transparent"}
            disabled={isGloballySigningOut}
          >
            {t("RemoveDevicesModal.buttonCancel")}
          </Button>
        }
        submitButton={
          <Button
            disabled={isGloballySigningOut}
            onClick={onConfirmRemove}
            buttonSize={"medium"}
            buttonType={"red"}
          >
            {t("RemoveDevicesModal.buttonSubmit")}
          </Button>
        }
      >
        {t("RemoveDevicesModal.message")}
      </ActionModal>
    </div>
  );
}
