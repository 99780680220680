import { OrganizationDetailsResponseModel, UserRoleType } from "api/query";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { Input, Selection, SideModalElements } from "libs/ui";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useTranslation } from "react-i18next";
import { isReadonly } from "../../helper";
import { HCPFormFieldsProps } from "../model";
import "./../../Form.scss";
import { DeviceField, useOnDeviceChange } from "components/Forms/FormElements/DevicesField";
import { UserHcpType } from "models/PersonModels";
import { PersonalSection } from "components/Forms/FormElements/PersonalSection";
import { AddressSection } from "components/Forms/FormElements/AddressSection";
import { Group } from "components/Forms/FormElements/Group";
import { OrganizationField } from "components/Forms/FormElements/OrganizationField";
import { userRoleTypeToSelectOptions } from "utils/mappers/userRoleTypeToSelectOptions";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useHasAccess } from "utils/hooks/useHasAccess";
import {
  ReturnTypeOfCustomEnums,
  customEnumsToSelectOptions,
} from "utils/mappers/customEnumsToSelectOptions";
import { fieldStatusResolve } from "components/Forms/fieldStatusResolve";

export function FormFields({
  onChange,
  onSubmit,
  entityData: HCPData,
  editableFields,
  loading,
  children,
  formMode,
}: PropsWithChildren<HCPFormFieldsProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  const { isAdmin } = useAdditionalAuthInformationContext();
  const isAdminInCreateMode = isAdmin && !isReadonly("organizationId", editableFields);
  const can = useHasAccess();
  const [onDeviceChange] = useOnDeviceChange(HCPData, onChange);

  /**
   * TODO grooming
   * This should not be dependant on external stuff
   * we should have defaultProps or something
   */
  const { appConfig } = useGlobalConfigContext();

  const isNotAssigned = isReadonly("hcpType", editableFields) && !HCPData.hcpType;

  const onOrganizationChange = useCallback(
    (value?: OrganizationDetailsResponseModel) => {
      onChange(
        !value ? NotNullOrUndefined(appConfig?.forms.defaultValues.hcp.organizationId) : value.id,
        "organizationId",
      );
      onChange(!value ? undefined : value.name, "organizationName");

      onChange(undefined, "deviceId");
      onChange(undefined, "deviceSerialNumber");
    },
    [appConfig?.forms.defaultValues.hcp.organizationId, onChange],
  );

  const onHcpTypeChange = useCallback(
    (value?: UserHcpType) => {
      onChange(!value ? undefined : value, "hcpType");
    },
    [onChange],
  );

  const deviceMode = useMemo(() => {
    if (formMode === "Readonly") {
      return "Readonly";
    }

    return can("manage-device") && HCPData.id ? "Edit" : "Add";
  }, [HCPData.id, can, formMode]);

  return (
    <form className="Form" onSubmit={onSubmit} data-testid="hcp-form">
      <PersonalSection
        personalData={HCPData}
        onChange={onChange}
        editableFields={editableFields}
        loading={loading}
        role="Hcp"
        formMode={formMode}
      >
        {children}
      </PersonalSection>

      <AddressSection
        addressData={HCPData}
        onChange={onChange}
        editableFields={editableFields}
        loading={loading}
        zipCodeFieldStatus={fieldStatusResolve({
          formMode,
          isReadonly: isReadonly("zipCode", editableFields),
          field: appConfig?.entities.hcp.zipCode,
          value: HCPData.zipCode,
        })}
      />

      <Group>
        <SideModalElements.SectionTitle>
          {t("Subtitle.organizational")}
        </SideModalElements.SectionTitle>
        <div className="Form__field">
          <Selection<UserRoleType>
            options={userRoleTypeToSelectOptions().filter(el => el.value === "Hcp")}
            label={t("userRole")}
            value={"Hcp"}
            readOnly
            loading={loading}
          />
        </div>
        <div className="Form__field">
          <Selection<ReturnTypeOfCustomEnums<typeof customEnumsToSelectOptions>>
            options={customEnumsToSelectOptions(appConfig?.general.custom.enums?.UserHcpType ?? [])}
            data-testid="hcpType"
            data-testval={HCPData.hcpType}
            label={t("hcpType")}
            value={HCPData.hcpType}
            onSelect={onHcpTypeChange}
            loading={loading}
            readOnly={isReadonly("hcpType", editableFields)}
            optional={true}
            showNa={isNotAssigned}
          />
        </div>
        {isAdminInCreateMode && (
          <div className="Form__field">
            <OrganizationField
              onSelect={onOrganizationChange}
              initialValue={HCPData.organizationName}
            />
          </div>
        )}
        {HCPData.organizationName && isReadonly("organizationId", editableFields) ? (
          <div className="Form__field">
            <Input
              data-testid="organization-name"
              label={t("organizationName")}
              value={HCPData.organizationName}
              readOnly
              loading={loading}
            />
          </div>
        ) : null}
        <div className="Form__field">
          <DeviceField
            optional={true}
            readOnly={isReadonly("deviceId", editableFields)}
            key={`device-${HCPData.organizationId}`}
            mode={deviceMode}
            organizationId={HCPData.organizationId}
            // add modal doesn't have patient id
            personId={HCPData.id ?? ""}
            deviceSerialNumber={HCPData.deviceSerialNumber ?? ""}
            deviceType={HCPData.deviceType}
            onSelect={onDeviceChange}
            loading={loading}
            deviceId={HCPData.deviceId}
          />
        </div>
      </Group>
    </form>
  );
}
