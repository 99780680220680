import * as React from "react";
import { SVGProps } from "react";
const SvgBell = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25A6.75 6.75 0 0 0 5.25 8c0 3.39-.725 5.514-1.408 6.766a6.184 6.184 0 0 1-.918 1.296 3.253 3.253 0 0 1-.338.312l-.009.007A.75.75 0 0 0 3 17.75h18a.75.75 0 0 0 .423-1.37l-.009-.006a3.254 3.254 0 0 1-.338-.312 6.185 6.185 0 0 1-.918-1.296C19.475 13.514 18.75 11.389 18.75 8A6.75 6.75 0 0 0 12 1.25Zm6.842 14.234c.157.288.315.542.468.766H4.69a8.77 8.77 0 0 0 .468-.766C5.975 13.986 6.75 11.611 6.75 8a5.25 5.25 0 1 1 10.5 0c0 3.61.775 5.986 1.592 7.484Z"
      fill="currentColor"
    />
    <path
      d="M9.67 19.664a.75.75 0 1 0-1.34.672c.358.716.88 1.324 1.523 1.752.644.43 1.385.662 2.147.662.762 0 1.503-.232 2.147-.662a4.535 4.535 0 0 0 1.524-1.752.75.75 0 0 0-1.341-.672c-.252.5-.605.903-1.015 1.176-.409.273-.862.41-1.315.41-.453 0-.906-.137-1.315-.41a3.038 3.038 0 0 1-1.014-1.176Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgBell;
