/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MeasurementType = {
    None: 'None',
    SerumCreatinine: 'SerumCreatinine',
    BloodPressure: 'BloodPressure',
    Glucose: 'Glucose',
    UACR: 'UACR',
    BMI: 'BMI',
    SemiQuantitativeUACR: 'SemiQuantitativeUACR'
} as const;
export type MeasurementType = typeof MeasurementType[keyof typeof MeasurementType];


export function MeasurementTypeFromJSON(json: any): MeasurementType {
    return MeasurementTypeFromJSONTyped(json, false);
}

export function MeasurementTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementType {
    return json as MeasurementType;
}

export function MeasurementTypeToJSON(value?: MeasurementType | null): any {
    return value as any;
}

