import { RegionType, UserGraphModel } from "api/query";
import { ECOption, Formatters } from "components/Chart/model";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import i18n from "i18next";
import { getAreaColoredOptions } from "../model";
import { UACR_MAX_GRAPH_VALUE } from "config/const";
import { UACRStageRanges } from "utils/hooks/useGetUACRRanges";
import { getColorValue } from "utils";

function getAreas(orderedUACRStageRanges: UACRStageRanges) {
  const areas = [
    {
      areaColor: getColorValue("--white-100"),
      start: 0,
      end: orderedUACRStageRanges.Normal.to,
      label: i18n.t("Graph.UACR.Normal", { ns: "translation" }),
      labelDetails: i18n.t("Graph.UACR.Normal-details", { ns: "translation" }),
      labelColor: getColorValue("--grey-500"),
      legendColor: getColorValue("--grey-500"),
    },
    {
      areaColor: getColorValue("--yellow-100"),
      start: orderedUACRStageRanges.Microalbuminuria.from,
      end: orderedUACRStageRanges.Microalbuminuria.to,
      label: i18n.t("Graph.UACR.Microalbuminuria", { ns: "translation" }),
      labelDetails: i18n.t("Graph.UACR.Microalbuminuria-details", { ns: "translation" }),
      labelColor: getColorValue("--yellow-500"),
      legendColor: getColorValue("--yellow-500"),
    },
    {
      areaColor: getColorValue("--red-50"),
      start: orderedUACRStageRanges.Macroalbuminuria.from,
      end: Number.MAX_SAFE_INTEGER,
      label: i18n.t("Graph.UACR.Macroalbuminuria", { ns: "translation" }),
      labelDetails: i18n.t("Graph.UACR.Macroalbuminuria-details", { ns: "translation" }),
      labelColor: getColorValue("--red-500"),
      legendColor: getColorValue("--red-500"),
    },
  ];

  return areas;
}

export const getOptions = (
  formatters: Formatters,
  graphUser: UserGraphModel,
  orderedUACRStageRanges: UACRStageRanges,
  showTrending?: boolean,
  regionType?: RegionType,
): ECOption =>
  getAreaColoredOptions({
    formatters,
    data: graphUser,
    yAxis: { max: UACR_MAX_GRAPH_VALUE, show: false },
    areas: getAreas(orderedUACRStageRanges),
    customLegendLabels: getAreas(orderedUACRStageRanges).map(el => el.label),
    unitType: "semiQuantitativeUacr",
    measurementUnit: MEASUREMENT_UNITS.mgg,
    // markLineRanges: [30],
    showTrending,
    regionType,
  });
