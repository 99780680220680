/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SerumCreatinineStatusType = {
    None: 'None',
    Normal: 'Normal',
    Abnormal: 'Abnormal'
} as const;
export type SerumCreatinineStatusType = typeof SerumCreatinineStatusType[keyof typeof SerumCreatinineStatusType];


export function SerumCreatinineStatusTypeFromJSON(json: any): SerumCreatinineStatusType {
    return SerumCreatinineStatusTypeFromJSONTyped(json, false);
}

export function SerumCreatinineStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerumCreatinineStatusType {
    return json as SerumCreatinineStatusType;
}

export function SerumCreatinineStatusTypeToJSON(value?: SerumCreatinineStatusType | null): any {
    return value as any;
}

