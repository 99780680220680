/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  TrackingDeviceModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    TrackingDeviceModelFromJSON,
    TrackingDeviceModelToJSON,
} from '../models/index';

export interface TrackingDevicesGetRequest {
    acceptVersion?: string;
}

/**
 * TrackingApi - interface
 * 
 * @export
 * @interface TrackingApiInterface
 */
export interface TrackingApiInterface {
    /**
     * 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingApiInterface
     */
    trackingDevicesGetRaw(requestParameters: TrackingDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TrackingDeviceModel>>>;

    /**
     */
    trackingDevicesGet(requestParameters: TrackingDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TrackingDeviceModel>>;

}

/**
 * 
 */
export class TrackingApi extends runtime.BaseAPI implements TrackingApiInterface {

     /**
     */
    async trackingDevicesGetRawOriginal(requestParameters: TrackingDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/tracking/devices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async trackingDevicesGetRaw(requestParameters: TrackingDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TrackingDeviceModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/tracking/devices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrackingDeviceModelFromJSON));
    }

    /**
     */
    async trackingDevicesGet(requestParameters: TrackingDevicesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TrackingDeviceModel>> {
        const response = await this.trackingDevicesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
