import classNames from "classnames";
import { PropsWithChildren } from "react";
import { BreadCrumbs } from "../BreadCrumbs";
import "./Page.scss";
import { PageProps } from "./PageProps";

export function Page({
  children,
  className,
  breadCrumbs,
  controls,
  title,
  customBreadcrumbsElem,
  ...rest
}: PropsWithChildren<PageProps>) {
  return (
    <div className={classNames("UI-Components Page", className)} {...rest}>
      <div className="Page__header">
        {customBreadcrumbsElem ? (
          <div className="Page__breadCrumbs">
            <BreadCrumbs breadCrumbs={breadCrumbs} />
            {customBreadcrumbsElem}
          </div>
        ) : (
          <BreadCrumbs breadCrumbs={breadCrumbs} />
        )}

        {title && <span className="Page__title">{title}</span>}
        {controls}
      </div>
      {children}
    </div>
  );
}
