import classNames from "classnames";
import { PropsWithChildren } from "react";
import "./TableCardStatus.scss";

type StatusType = "ok" | "error";

interface TableCardStatusProps {
  status?: StatusType | boolean;
  loading?: boolean;
}

export function TableCardStatus({
  status,
  loading,
  children,
}: PropsWithChildren<TableCardStatusProps>) {
  const value = status === true || status === "ok" ? "ok" : "error";
  return (
    <p
      className={classNames("TableCardStatus", {
        [`TableCardStatus--${value}`]: status,
        "TableCardStatus--loading": loading,
      })}
    >
      {loading ? null : children}
    </p>
  );
}
