import * as React from "react";
import { SVGProps } from "react";
const SvgShow = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5ZM8.75 12a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.67 11.664 23 12l.67.336v.003l-.004.006-.01.02-.04.076a18.215 18.215 0 0 1-.72 1.216 20.43 20.43 0 0 1-2.224 2.856C18.734 18.58 15.8 20.75 12 20.75c-3.8 0-6.734-2.17-8.672-4.237a20.426 20.426 0 0 1-2.796-3.802 11.85 11.85 0 0 1-.187-.345l-.011-.021-.003-.006-.001-.002s0-.002.67-.337l-.67-.336v-.003l.004-.006.01-.02.04-.076a18.235 18.235 0 0 1 .72-1.216 20.425 20.425 0 0 1 2.224-2.856C5.266 5.42 8.2 3.25 12 3.25c3.8 0 6.734 2.17 8.672 4.237a20.43 20.43 0 0 1 2.796 3.802 11.896 11.896 0 0 1 .187.345l.011.021.003.006.002.003Zm-21.306 1.18A16.834 16.834 0 0 1 1.853 12a18.924 18.924 0 0 1 2.57-3.487C6.233 6.58 8.8 4.75 12 4.75s5.766 1.83 7.578 3.763A18.924 18.924 0 0 1 22.148 12a18.925 18.925 0 0 1-2.57 3.487C17.766 17.42 15.2 19.25 12 19.25s-5.766-1.83-7.578-3.763c-.899-.96-1.59-1.921-2.058-2.644Z"
      fill="currentColor"
    />
    <path
      d="m1 12-.67.335a.751.751 0 0 1 0-.671L1 12ZM23 12l.67-.336a.752.752 0 0 1 0 .672L23 12Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgShow;
