import * as React from "react";
import { SVGProps } from "react";
const SvgAdmin = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 7a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0ZM12 3.75a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5ZM4.641 15.641A4.75 4.75 0 0 1 8 14.251h8A4.75 4.75 0 0 1 20.75 19v2a.75.75 0 0 1-1.5 0v-2A3.25 3.25 0 0 0 16 15.75h-2.171l.37 3.703a1.75 1.75 0 0 1-.504 1.412l-.458.457a1.75 1.75 0 0 1-2.474 0l-.458-.457a1.75 1.75 0 0 1-.504-1.412l.37-3.704H8A3.25 3.25 0 0 0 4.75 19v2a.75.75 0 0 1-1.5 0v-2c0-1.26.5-2.468 1.391-3.359Zm7.038.11-.386 3.852a.25.25 0 0 0 .072.202l.458.458a.25.25 0 0 0 .354 0l.458-.458a.25.25 0 0 0 .072-.202l-.386-3.853h-.642Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAdmin;
