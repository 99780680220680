/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RegionType = {
    aa_DJ: 'aa-DJ',
    aa_ER: 'aa-ER',
    aa_ET: 'aa-ET',
    af_NA: 'af-NA',
    af_ZA: 'af-ZA',
    agq_CM: 'agq-CM',
    ak_GH: 'ak-GH',
    am_ET: 'am-ET',
    ar_001: 'ar-001',
    ar_AE: 'ar-AE',
    ar_BH: 'ar-BH',
    ar_DJ: 'ar-DJ',
    ar_DZ: 'ar-DZ',
    ar_EG: 'ar-EG',
    ar_ER: 'ar-ER',
    ar_IL: 'ar-IL',
    ar_IQ: 'ar-IQ',
    ar_JO: 'ar-JO',
    ar_KM: 'ar-KM',
    ar_KW: 'ar-KW',
    ar_LB: 'ar-LB',
    ar_LY: 'ar-LY',
    ar_MA: 'ar-MA',
    ar_MR: 'ar-MR',
    ar_OM: 'ar-OM',
    ar_PS: 'ar-PS',
    ar_QA: 'ar-QA',
    ar_SA: 'ar-SA',
    ar_SD: 'ar-SD',
    ar_SO: 'ar-SO',
    ar_SS: 'ar-SS',
    ar_SY: 'ar-SY',
    ar_TD: 'ar-TD',
    ar_TN: 'ar-TN',
    ar_YE: 'ar-YE',
    arn_CL: 'arn-CL',
    as_IN: 'as-IN',
    asa_TZ: 'asa-TZ',
    ast_ES: 'ast-ES',
    az_AZ: 'az-AZ',
    ba_RU: 'ba-RU',
    bas_CM: 'bas-CM',
    be_BY: 'be-BY',
    bem_ZM: 'bem-ZM',
    bez_TZ: 'bez-TZ',
    bg_BG: 'bg-BG',
    bm_ML: 'bm-ML',
    bn_BD: 'bn-BD',
    bn_IN: 'bn-IN',
    bo_CN: 'bo-CN',
    bo_IN: 'bo-IN',
    br_FR: 'br-FR',
    brx_IN: 'brx-IN',
    bs_BA: 'bs-BA',
    byn_ER: 'byn-ER',
    ca_AD: 'ca-AD',
    ca_ES: 'ca-ES',
    ca_FR: 'ca-FR',
    ca_IT: 'ca-IT',
    ccp_BD: 'ccp-BD',
    ccp_IN: 'ccp-IN',
    ce_RU: 'ce-RU',
    ceb_PH: 'ceb-PH',
    cgg_UG: 'cgg-UG',
    chr_US: 'chr-US',
    ckb_IQ: 'ckb-IQ',
    ckb_IR: 'ckb-IR',
    co_FR: 'co-FR',
    cs_CZ: 'cs-CZ',
    cu_RU: 'cu-RU',
    cy_GB: 'cy-GB',
    da_DK: 'da-DK',
    da_GL: 'da-GL',
    dav_KE: 'dav-KE',
    de_AT: 'de-AT',
    de_BE: 'de-BE',
    de_CH: 'de-CH',
    de_DE: 'de-DE',
    de_IT: 'de-IT',
    de_LI: 'de-LI',
    de_LU: 'de-LU',
    dje_NE: 'dje-NE',
    dsb_DE: 'dsb-DE',
    dua_CM: 'dua-CM',
    dv_MV: 'dv-MV',
    dyo_SN: 'dyo-SN',
    dz_BT: 'dz-BT',
    ebu_KE: 'ebu-KE',
    ee_GH: 'ee-GH',
    ee_TG: 'ee-TG',
    el_CY: 'el-CY',
    el_GR: 'el-GR',
    en_001: 'en-001',
    en_150: 'en-150',
    en_AE: 'en-AE',
    en_AG: 'en-AG',
    en_AI: 'en-AI',
    en_AS: 'en-AS',
    en_AT: 'en-AT',
    en_AU: 'en-AU',
    en_BB: 'en-BB',
    en_BE: 'en-BE',
    en_BI: 'en-BI',
    en_BM: 'en-BM',
    en_BS: 'en-BS',
    en_BW: 'en-BW',
    en_BZ: 'en-BZ',
    en_CA: 'en-CA',
    en_CC: 'en-CC',
    en_CH: 'en-CH',
    en_CK: 'en-CK',
    en_CM: 'en-CM',
    en_CX: 'en-CX',
    en_CY: 'en-CY',
    en_DE: 'en-DE',
    en_DK: 'en-DK',
    en_DM: 'en-DM',
    en_ER: 'en-ER',
    en_FI: 'en-FI',
    en_FJ: 'en-FJ',
    en_FK: 'en-FK',
    en_FM: 'en-FM',
    en_GB: 'en-GB',
    en_GD: 'en-GD',
    en_GG: 'en-GG',
    en_GH: 'en-GH',
    en_GI: 'en-GI',
    en_GM: 'en-GM',
    en_GU: 'en-GU',
    en_GY: 'en-GY',
    en_HK: 'en-HK',
    en_IE: 'en-IE',
    en_IL: 'en-IL',
    en_IM: 'en-IM',
    en_IN: 'en-IN',
    en_IO: 'en-IO',
    en_JE: 'en-JE',
    en_JM: 'en-JM',
    en_KE: 'en-KE',
    en_KI: 'en-KI',
    en_KN: 'en-KN',
    en_KY: 'en-KY',
    en_LC: 'en-LC',
    en_LR: 'en-LR',
    en_LS: 'en-LS',
    en_MG: 'en-MG',
    en_MH: 'en-MH',
    en_MO: 'en-MO',
    en_MP: 'en-MP',
    en_MS: 'en-MS',
    en_MT: 'en-MT',
    en_MU: 'en-MU',
    en_MW: 'en-MW',
    en_MY: 'en-MY',
    en_NA: 'en-NA',
    en_NF: 'en-NF',
    en_NG: 'en-NG',
    en_NL: 'en-NL',
    en_NR: 'en-NR',
    en_NU: 'en-NU',
    en_NZ: 'en-NZ',
    en_PG: 'en-PG',
    en_PH: 'en-PH',
    en_PK: 'en-PK',
    en_PN: 'en-PN',
    en_PR: 'en-PR',
    en_PW: 'en-PW',
    en_RW: 'en-RW',
    en_SB: 'en-SB',
    en_SC: 'en-SC',
    en_SD: 'en-SD',
    en_SE: 'en-SE',
    en_SG: 'en-SG',
    en_SH: 'en-SH',
    en_SI: 'en-SI',
    en_SL: 'en-SL',
    en_SS: 'en-SS',
    en_SX: 'en-SX',
    en_SZ: 'en-SZ',
    en_TC: 'en-TC',
    en_TK: 'en-TK',
    en_TO: 'en-TO',
    en_TT: 'en-TT',
    en_TV: 'en-TV',
    en_TZ: 'en-TZ',
    en_UG: 'en-UG',
    en_UM: 'en-UM',
    en_US: 'en-US',
    en_VC: 'en-VC',
    en_VG: 'en-VG',
    en_VI: 'en-VI',
    en_VU: 'en-VU',
    en_WS: 'en-WS',
    en_ZA: 'en-ZA',
    en_ZM: 'en-ZM',
    en_ZW: 'en-ZW',
    eo_001: 'eo-001',
    es_419: 'es-419',
    es_AR: 'es-AR',
    es_BO: 'es-BO',
    es_BR: 'es-BR',
    es_BZ: 'es-BZ',
    es_CL: 'es-CL',
    es_CO: 'es-CO',
    es_CR: 'es-CR',
    es_CU: 'es-CU',
    es_DO: 'es-DO',
    es_EC: 'es-EC',
    es_ES: 'es-ES',
    es_GQ: 'es-GQ',
    es_GT: 'es-GT',
    es_HN: 'es-HN',
    es_MX: 'es-MX',
    es_NI: 'es-NI',
    es_PA: 'es-PA',
    es_PE: 'es-PE',
    es_PH: 'es-PH',
    es_PR: 'es-PR',
    es_PY: 'es-PY',
    es_SV: 'es-SV',
    es_US: 'es-US',
    es_UY: 'es-UY',
    es_VE: 'es-VE',
    et_EE: 'et-EE',
    eu_ES: 'eu-ES',
    ewo_CM: 'ewo-CM',
    fa_AF: 'fa-AF',
    fa_IR: 'fa-IR',
    ff_BF: 'ff-BF',
    ff_CM: 'ff-CM',
    ff_GH: 'ff-GH',
    ff_GM: 'ff-GM',
    ff_GN: 'ff-GN',
    ff_GW: 'ff-GW',
    ff_LR: 'ff-LR',
    ff_MR: 'ff-MR',
    ff_NE: 'ff-NE',
    ff_NG: 'ff-NG',
    ff_SL: 'ff-SL',
    ff_SN: 'ff-SN',
    fi_FI: 'fi-FI',
    fil_PH: 'fil-PH',
    fo_DK: 'fo-DK',
    fo_FO: 'fo-FO',
    fr_BE: 'fr-BE',
    fr_BF: 'fr-BF',
    fr_BI: 'fr-BI',
    fr_BJ: 'fr-BJ',
    fr_BL: 'fr-BL',
    fr_CA: 'fr-CA',
    fr_CD: 'fr-CD',
    fr_CF: 'fr-CF',
    fr_CG: 'fr-CG',
    fr_CH: 'fr-CH',
    fr_CI: 'fr-CI',
    fr_CM: 'fr-CM',
    fr_DJ: 'fr-DJ',
    fr_DZ: 'fr-DZ',
    fr_FR: 'fr-FR',
    fr_GA: 'fr-GA',
    fr_GF: 'fr-GF',
    fr_GN: 'fr-GN',
    fr_GP: 'fr-GP',
    fr_GQ: 'fr-GQ',
    fr_HT: 'fr-HT',
    fr_KM: 'fr-KM',
    fr_LU: 'fr-LU',
    fr_MA: 'fr-MA',
    fr_MC: 'fr-MC',
    fr_MF: 'fr-MF',
    fr_MG: 'fr-MG',
    fr_ML: 'fr-ML',
    fr_MQ: 'fr-MQ',
    fr_MR: 'fr-MR',
    fr_MU: 'fr-MU',
    fr_NC: 'fr-NC',
    fr_NE: 'fr-NE',
    fr_PF: 'fr-PF',
    fr_PM: 'fr-PM',
    fr_RE: 'fr-RE',
    fr_RW: 'fr-RW',
    fr_SC: 'fr-SC',
    fr_SN: 'fr-SN',
    fr_SY: 'fr-SY',
    fr_TD: 'fr-TD',
    fr_TG: 'fr-TG',
    fr_TN: 'fr-TN',
    fr_VU: 'fr-VU',
    fr_WF: 'fr-WF',
    fr_YT: 'fr-YT',
    fur_IT: 'fur-IT',
    fy_NL: 'fy-NL',
    ga_IE: 'ga-IE',
    gd_GB: 'gd-GB',
    gl_ES: 'gl-ES',
    gn_PY: 'gn-PY',
    gsw_CH: 'gsw-CH',
    gsw_FR: 'gsw-FR',
    gsw_LI: 'gsw-LI',
    gu_IN: 'gu-IN',
    guz_KE: 'guz-KE',
    gv_IM: 'gv-IM',
    ha_GH: 'ha-GH',
    ha_NE: 'ha-NE',
    ha_NG: 'ha-NG',
    haw_US: 'haw-US',
    he_IL: 'he-IL',
    hi_IN: 'hi-IN',
    hr_BA: 'hr-BA',
    hr_HR: 'hr-HR',
    hsb_DE: 'hsb-DE',
    hu_HU: 'hu-HU',
    hy_AM: 'hy-AM',
    ia_001: 'ia-001',
    id_ID: 'id-ID',
    ig_NG: 'ig-NG',
    ii_CN: 'ii-CN',
    is_IS: 'is-IS',
    it_CH: 'it-CH',
    it_IT: 'it-IT',
    it_SM: 'it-SM',
    it_VA: 'it-VA',
    iu_CA: 'iu-CA',
    ja_JP: 'ja-JP',
    jgo_CM: 'jgo-CM',
    jmc_TZ: 'jmc-TZ',
    jv_ID: 'jv-ID',
    ka_GE: 'ka-GE',
    kab_DZ: 'kab-DZ',
    kam_KE: 'kam-KE',
    kde_TZ: 'kde-TZ',
    kea_CV: 'kea-CV',
    khq_ML: 'khq-ML',
    ki_KE: 'ki-KE',
    kk_KZ: 'kk-KZ',
    kkj_CM: 'kkj-CM',
    kl_GL: 'kl-GL',
    kln_KE: 'kln-KE',
    km_KH: 'km-KH',
    kn_IN: 'kn-IN',
    ko_KP: 'ko-KP',
    ko_KR: 'ko-KR',
    kok_IN: 'kok-IN',
    ks_IN: 'ks-IN',
    ksb_TZ: 'ksb-TZ',
    ksf_CM: 'ksf-CM',
    ksh_DE: 'ksh-DE',
    kw_GB: 'kw-GB',
    ky_KG: 'ky-KG',
    lag_TZ: 'lag-TZ',
    lb_LU: 'lb-LU',
    lg_UG: 'lg-UG',
    lkt_US: 'lkt-US',
    ln_AO: 'ln-AO',
    ln_CD: 'ln-CD',
    ln_CF: 'ln-CF',
    ln_CG: 'ln-CG',
    lo_LA: 'lo-LA',
    lrc_IQ: 'lrc-IQ',
    lrc_IR: 'lrc-IR',
    lt_LT: 'lt-LT',
    lu_CD: 'lu-CD',
    luo_KE: 'luo-KE',
    luy_KE: 'luy-KE',
    lv_LV: 'lv-LV',
    mas_KE: 'mas-KE',
    mas_TZ: 'mas-TZ',
    mer_KE: 'mer-KE',
    mfe_MU: 'mfe-MU',
    mg_MG: 'mg-MG',
    mgh_MZ: 'mgh-MZ',
    mgo_CM: 'mgo-CM',
    mi_NZ: 'mi-NZ',
    mk_MK: 'mk-MK',
    ml_IN: 'ml-IN',
    mn_CN: 'mn-CN',
    mn_MN: 'mn-MN',
    moh_CA: 'moh-CA',
    mr_IN: 'mr-IN',
    ms_BN: 'ms-BN',
    ms_MY: 'ms-MY',
    ms_SG: 'ms-SG',
    mt_MT: 'mt-MT',
    mua_CM: 'mua-CM',
    my_MM: 'my-MM',
    mzn_IR: 'mzn-IR',
    naq_NA: 'naq-NA',
    nb_NO: 'nb-NO',
    nb_SJ: 'nb-SJ',
    nd_ZW: 'nd-ZW',
    nds_DE: 'nds-DE',
    nds_NL: 'nds-NL',
    ne_IN: 'ne-IN',
    ne_NP: 'ne-NP',
    nl_AW: 'nl-AW',
    nl_BE: 'nl-BE',
    nl_BQ: 'nl-BQ',
    nl_CW: 'nl-CW',
    nl_NL: 'nl-NL',
    nl_SR: 'nl-SR',
    nl_SX: 'nl-SX',
    nmg_CM: 'nmg-CM',
    nn_NO: 'nn-NO',
    nnh_CM: 'nnh-CM',
    nqo_GN: 'nqo-GN',
    nr_ZA: 'nr-ZA',
    nso_ZA: 'nso-ZA',
    nus_SS: 'nus-SS',
    nyn_UG: 'nyn-UG',
    oc_FR: 'oc-FR',
    om_ET: 'om-ET',
    om_KE: 'om-KE',
    or_IN: 'or-IN',
    os_GE: 'os-GE',
    os_RU: 'os-RU',
    pa_IN: 'pa-IN',
    pa_PK: 'pa-PK',
    pl_PL: 'pl-PL',
    prg_001: 'prg-001',
    ps_AF: 'ps-AF',
    ps_PK: 'ps-PK',
    pt_AO: 'pt-AO',
    pt_BR: 'pt-BR',
    pt_CH: 'pt-CH',
    pt_CV: 'pt-CV',
    pt_GQ: 'pt-GQ',
    pt_GW: 'pt-GW',
    pt_LU: 'pt-LU',
    pt_MO: 'pt-MO',
    pt_MZ: 'pt-MZ',
    pt_PT: 'pt-PT',
    pt_ST: 'pt-ST',
    pt_TL: 'pt-TL',
    qu_BO: 'qu-BO',
    qu_EC: 'qu-EC',
    qu_PE: 'qu-PE',
    quc_GT: 'quc-GT',
    rm_CH: 'rm-CH',
    rn_BI: 'rn-BI',
    ro_MD: 'ro-MD',
    ro_RO: 'ro-RO',
    rof_TZ: 'rof-TZ',
    ru_BY: 'ru-BY',
    ru_KG: 'ru-KG',
    ru_KZ: 'ru-KZ',
    ru_MD: 'ru-MD',
    ru_RU: 'ru-RU',
    ru_UA: 'ru-UA',
    rw_RW: 'rw-RW',
    rwk_TZ: 'rwk-TZ',
    sa_IN: 'sa-IN',
    sah_RU: 'sah-RU',
    saq_KE: 'saq-KE',
    sbp_TZ: 'sbp-TZ',
    sd_PK: 'sd-PK',
    se_FI: 'se-FI',
    se_NO: 'se-NO',
    se_SE: 'se-SE',
    seh_MZ: 'seh-MZ',
    ses_ML: 'ses-ML',
    sg_CF: 'sg-CF',
    shi_MA: 'shi-MA',
    si_LK: 'si-LK',
    sk_SK: 'sk-SK',
    sl_SI: 'sl-SI',
    sma_NO: 'sma-NO',
    sma_SE: 'sma-SE',
    smj_NO: 'smj-NO',
    smj_SE: 'smj-SE',
    smn_FI: 'smn-FI',
    sms_FI: 'sms-FI',
    sn_ZW: 'sn-ZW',
    so_DJ: 'so-DJ',
    so_ET: 'so-ET',
    so_KE: 'so-KE',
    so_SO: 'so-SO',
    sq_AL: 'sq-AL',
    sq_MK: 'sq-MK',
    sq_XK: 'sq-XK',
    sr_BA: 'sr-BA',
    sr_ME: 'sr-ME',
    sr_RS: 'sr-RS',
    sr_XK: 'sr-XK',
    ss_SZ: 'ss-SZ',
    ss_ZA: 'ss-ZA',
    ssy_ER: 'ssy-ER',
    st_LS: 'st-LS',
    st_ZA: 'st-ZA',
    sv_AX: 'sv-AX',
    sv_FI: 'sv-FI',
    sv_SE: 'sv-SE',
    sw_CD: 'sw-CD',
    sw_KE: 'sw-KE',
    sw_TZ: 'sw-TZ',
    sw_UG: 'sw-UG',
    syr_SY: 'syr-SY',
    ta_IN: 'ta-IN',
    ta_LK: 'ta-LK',
    ta_MY: 'ta-MY',
    ta_SG: 'ta-SG',
    te_IN: 'te-IN',
    teo_KE: 'teo-KE',
    teo_UG: 'teo-UG',
    tg_TJ: 'tg-TJ',
    th_TH: 'th-TH',
    ti_ER: 'ti-ER',
    ti_ET: 'ti-ET',
    tig_ER: 'tig-ER',
    tk_TM: 'tk-TM',
    tn_BW: 'tn-BW',
    tn_ZA: 'tn-ZA',
    to_TO: 'to-TO',
    tr_CY: 'tr-CY',
    tr_TR: 'tr-TR',
    ts_ZA: 'ts-ZA',
    tt_RU: 'tt-RU',
    twq_NE: 'twq-NE',
    tzm_MA: 'tzm-MA',
    ug_CN: 'ug-CN',
    uk_UA: 'uk-UA',
    ur_IN: 'ur-IN',
    ur_PK: 'ur-PK',
    uz_AF: 'uz-AF',
    uz_UZ: 'uz-UZ',
    vai_LR: 'vai-LR',
    ve_ZA: 've-ZA',
    vi_VN: 'vi-VN',
    vo_001: 'vo-001',
    vun_TZ: 'vun-TZ',
    wae_CH: 'wae-CH',
    wal_ET: 'wal-ET',
    wo_SN: 'wo-SN',
    xh_ZA: 'xh-ZA',
    xog_UG: 'xog-UG',
    yav_CM: 'yav-CM',
    yi_001: 'yi-001',
    yo_BJ: 'yo-BJ',
    yo_NG: 'yo-NG',
    zgh_MA: 'zgh-MA',
    zh_CN: 'zh-CN',
    zh_HK: 'zh-HK',
    zh_MO: 'zh-MO',
    zh_SG: 'zh-SG',
    zh_TW: 'zh-TW',
    zu_ZA: 'zu-ZA'
} as const;
export type RegionType = typeof RegionType[keyof typeof RegionType];


export function RegionTypeFromJSON(json: any): RegionType {
    return RegionTypeFromJSONTyped(json, false);
}

export function RegionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegionType {
    return json as RegionType;
}

export function RegionTypeToJSON(value?: RegionType | null): any {
    return value as any;
}

