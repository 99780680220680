import { MeasurementItemType } from "api/event";
import { TestResponseModel } from "api/query";
import { MeasurementTypes, MeasurementUIType, TestMeasurements } from "models/TestModels";
import { Dispatch, MouseEventHandler, SetStateAction, useMemo, useSyncExternalStore } from "react";
import { FakeData, isRealData } from "utils/guards";
import { PictureResolution } from "utils/helpers/pictures.model";
import { avatarCache } from "utils/machines/avatars";
import { v4 } from "uuid";
import { BodyRowsProps, TestModel } from "../model";
import { TestUnitType } from "../utils";
import { MeasurementRow } from "./MeasurementRow";

interface TestsBodyRowsProps extends BodyRowsProps {
  onRowClick: {
    getTest: (request: {
      organizationId: string;
      userEntityId: string;
      testEntityId: string;
    }) => Promise<void>;
    setSelectedTestUnit: Dispatch<SetStateAction<TestUnitType | undefined>>;
  };
  activeDetailsId?: string | null;
  onDeleteClick: (
    test: TestModel,
    measurementType: MeasurementUIType,
  ) => MouseEventHandler<HTMLButtonElement>;
  onEditClick: (organizationId: string, testId: string) => void;
}

export const filterMeasurements = (
  measurements?: TestMeasurements[],
  measurementType?: MeasurementTypes,
  measurementTypeItem?: MeasurementItemType,
) => {
  return measurements
    ? measurements.filter(measurement => {
        const isSelectedMeasurementType =
          measurementType && Object.hasOwn(measurement, measurementType);
        const isAllMeasurementsTable = !measurementType;
        const isNotCreatinine = measurementTypeItem !== "SerumCreatinine";
        const isNotEgfr = measurementTypeItem !== "Egfr";

        if (isSelectedMeasurementType || (isAllMeasurementsTable && isNotCreatinine && isNotEgfr)) {
          return measurement;
        }
        return null;
      })
    : [];
};

const convertTestResponse = (tests?: TestResponseModel[]): TestModel[] | undefined =>
  tests?.map(test => {
    return {
      ...test,
      measurements: test.measurements.flatMap(measurement => {
        return {
          [measurement.measurementType ?? "None"]: measurement.item,
        };
      }),
    };
  });

export function BodyRows({
  tableService,
  appliedColumns,
  onRowClick,
  activeDetailsId,
  measurementType,
  measurementTypeItem,
  onDeleteClick,
  onEditClick,
}: Readonly<TestsBodyRowsProps>) {
  const [state] = tableService;

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  const defaultValues = useMemo(
    () =>
      Array.from({ length: state.context.linesPerPage }).map((_, idx) => {
        const defaultValue: FakeData<TestModel> = {
          id: idx.toString(),
          _fake: true,
          measurementTime: "",
          measurements: [
            {
              BloodPressure: {
                Diastolic: {
                  Value: 0,
                  Percent: 0,
                  Unit: "mmHg",
                  TrendType: "None",
                },
                Systolic: {
                  Value: 0,
                  Percent: 0,
                  Unit: "mmHg",
                  TrendType: "None",
                },
              },
            },
          ],
          organizationId: "",
          patient: {
            firstName: "",
            lastName: "",
            gender: "Male",
            id: "",
            height: 0,
            weight: 0,
            roleType: "Admin",
            organizationId: "",
            ancestry: "Other",
            age: 0,
          },
          testCountId: "",
          testedBy: {
            id: "",
            firstName: "",
            lastName: "",
            organizationId: "",
            roleType: "Hcp",
          },
          device: {
            id: "",
            name: "",
            serialNumber: "",
          },
        };
        return defaultValue;
      }),
    [state.context.linesPerPage],
  );

  const testItems = useMemo(
    () => convertTestResponse(state.context.data.items),
    [state.context.data.items],
  );

  return (
    <>
      {(state.matches("loading") ? defaultValues : testItems ?? []).map(test => {
        return filterMeasurements(test?.measurements, measurementType, measurementTypeItem).map(
          measurement => {
            return (
              <MeasurementRow
                key={v4()}
                test={test}
                avatars={{
                  patientAvatar: isRealData(test)
                    ? avatarCache.get(
                        test.patient.organizationId,
                        test.patient.id,
                        PictureResolution.table,
                      )
                    : undefined,
                  hcpAvatar:
                    isRealData(test) && test.testedBy?.id
                      ? avatarCache.get(
                          test.testedBy.organizationId,
                          test.testedBy.id,
                          PictureResolution.table,
                        )
                      : undefined,
                }}
                measurement={measurement}
                measurementTypeItem={measurementTypeItem}
                onRowClick={onRowClick}
                loading={state.matches("loading")}
                appliedColumns={appliedColumns}
                activeDetailsId={activeDetailsId}
                onDeleteClick={onDeleteClick}
                onEditClick={onEditClick}
              />
            );
          },
        );
      })}
    </>
  );
}
