import classNames from "classnames";
import { useMemo } from "react";
import { useGetUACRRanges } from "utils/hooks/useGetUACRRanges";
import { RowHorizontal } from "../../RangeTable/RangeTableBody/RowHorizontal";
import { UACRRangeTableBodyBaseProps } from "../model";
import { useTranslation } from "react-i18next";
import { RangeTableCell } from "../../RangeTable/RangeTableBody/RangeTableCell";
import { getRange } from "../../RangeTable/RangeTableBody/helper";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import "./UACRRangeTableBody.scss";

interface UACRRangeTableBodyProps extends UACRRangeTableBodyBaseProps {}

export function UACRRangeTableBody({
  status,
  orientation,
  small,
}: Readonly<UACRRangeTableBodyProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "UACRRangeTable" });
  const { orderedUACRStageRanges } = useGetUACRRanges();
  const { numberFormatter } = useLocaleNumberFormatter();

  const rows = useMemo(() => {
    if (orientation === "horizontal") {
      return (
        <>
          {Object.entries(orderedUACRStageRanges).map(([label, range]) => (
            <RowHorizontal
              key={label}
              label={small ? t(`small${label}`) : t(label)}
              from={range.from}
              to={range.to}
              active={status === label}
            />
          ))}
        </>
      );
    }

    return (
      <>
        <tr className="HeadRowVertical">
          {Object.entries(orderedUACRStageRanges).map(([label]) => (
            <RangeTableCell
              cellCount={Object.entries(orderedUACRStageRanges).length}
              key={label}
              active={status === label}
            >
              {small ? t(`small${label}`) : t(label)}
            </RangeTableCell>
          ))}
        </tr>

        <tr className="RowVertical">
          {Object.entries(orderedUACRStageRanges).map(([label, range]) => {
            return (
              <RangeTableCell
                cellCount={Object.entries(orderedUACRStageRanges).length}
                key={label}
                active={status === label}
              >
                {getRange(
                  numberFormatter({
                    value: range.from,
                    additionalOptions: {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 0,
                      roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
                    },
                  }),
                  numberFormatter({
                    value: range.to,
                    additionalOptions: {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 0,
                      roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
                    },
                  }),
                )}
              </RangeTableCell>
            );
          })}
        </tr>
      </>
    );
  }, [numberFormatter, orderedUACRStageRanges, orientation, small, status, t]);

  return (
    <tbody className={classNames("UACRRangeTableBody", `UACRRangeTableBody--${orientation}`)}>
      {rows}
    </tbody>
  );
}
