import { querystring } from "./endpointSerialization";
import { HTTP_FORBIDDEN, HTTP_UNAUTHORIZED } from "./const";
import { Configuration } from "api/event";
import {
  UserPreferencesApi,
  AdministratorsApi,
  PartnersApi,
  HcpsApi,
  OrganizationsApi,
  DevicesApi,
  PatientsApi,
  TestsApi,
  ReportsApi,
} from "api/event/apis";
import { injectUnauthorized } from "components/Unathorized";
import { cloneDeep } from "lodash-es";
import { fetchAuthSession } from "@aws-amplify/auth";
import { tryGetLocalStorageValue } from "utils/localStorage";

let checkingForForbiddenUser: Promise<any> | null = null;

export const configuration =
  process.env.MODE === "test"
    ? undefined
    : new Configuration({
        /**
         * check this function in runtime.ts
         */
        queryParamsStringify: querystring,
        basePath: `${
          process.env.VITE_APP_UPDATE_HOST ?? "VITE_APP_UPDATE_HOST-env-variable-missing"
        }`,
        // (input: RequestInfo | URL, init?: RequestInit): Promise<Response>
        middleware: [
          {
            async post(param) {
              if (param.response.status === HTTP_UNAUTHORIZED) {
                injectUnauthorized();
                return param.response;
              }

              if (param.response.status === HTTP_FORBIDDEN) {
                // Avoiding concurrency
                if (checkingForForbiddenUser) {
                  await checkingForForbiddenUser;
                } else {
                  try {
                    checkingForForbiddenUser = fetchAuthSession({ forceRefresh: true });
                    await checkingForForbiddenUser;
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  } catch (error) {
                    console.warn("User is possibly logged out");
                  } finally {
                    checkingForForbiddenUser = null;
                  }
                }
              }

              return param.response;
            },
            async pre(context) {
              // const sign = await signRequest(
              //   context.url,
              //   "execute-api",
              //   context.init?.method,
              //   "eu-central-1",
              //   context.init.body,
              // );
              return {
                url: context.url,
                init: {
                  ...context.init,
                  headers: new Headers({
                    ...context.init.headers,
                    // ...sign.headers,
                    "access-token": `${(await fetchAuthSession()).tokens?.accessToken}`,
                    "identity-token": `${(await fetchAuthSession()).tokens?.idToken}`,
                    "x-tenant": `${tryGetLocalStorageValue<{ "X-Tenant": string }>("lastUsedAuth")?.["X-Tenant"] ?? "missing tenant name"}`,
                  }),
                },
              };
            },
          },
        ],
      });

const AdminApiContext = new AdministratorsApi(configuration);
const PartnerApiContext = new PartnersApi(configuration);
// const UsersApiContext = new UsersApi(configuration);
const OrganizationsApiContext = new OrganizationsApi(configuration);
const PatientApiContext = new PatientsApi(configuration);
const HcpApiContext = new HcpsApi(configuration);
const DevicesApiContext = new DevicesApi(configuration);
// const MeasurementApiApiContext = new MeasurementApi(configuration);
// const DocumentApContext = new DocumentApi(configuration);
// const SupportFormApiContext = new SupportFormApi(configuration);
// const ConfigurationApiContext = new ConfigurationsApi(configuration);
// const GraphApiContext = new GraphApi(configuration);
// const TrackingDevicesApiContext = new TrackingDevicesApi(configuration);
// const AccountDataApiContext = new AccountDataApi(configuration);
const TestsApiContext = new TestsApi(configuration);
const ReportsApiContext = new ReportsApi(configuration);

const UserPreferenceApiContext = new UserPreferencesApi(configuration);

function convertArraysToStrings(obj: any) {
  const newObj = {};

  function convert(obj: any, result = {} as any, keys = Object.keys(obj), index = 0) {
    if (index === keys.length) {
      return result;
    }

    const key = keys[index];
    const value = obj[key];

    if (Array.isArray(value)) {
      result[key] = value.join(",");
    } else {
      result[key] = value;
    }

    return convert(obj, result, keys, index + 1);
  }

  return convert(obj, newObj);
}

export const CarnaApiEvent = {
  Admin: {
    put: AdministratorsApi.prototype.organizationsOrganizationIdAdministratorsUserEntityIdPutRawOriginal.bind(
      AdminApiContext,
    ),
    post: AdministratorsApi.prototype.organizationsOrganizationIdAdministratorsPostRawOriginal.bind(
      AdminApiContext,
    ),
    delete:
      AdministratorsApi.prototype.organizationsOrganizationIdAdministratorsUserEntityIdDeleteRawOriginal.bind(
        AdminApiContext,
      ),
    changeStatus:
      AdministratorsApi.prototype.organizationsOrganizationIdAdministratorsUserEntityIdPatchRawOriginal.bind(
        AdminApiContext,
      ),
    resendInvite:
      AdministratorsApi.prototype.organizationsOrganizationIdAdministratorsUserEntityIdResendInvitePostRawOriginal.bind(
        AdminApiContext,
      ),
  },
  Partner: {
    put: PartnersApi.prototype.organizationsOrganizationIdPartnersUserEntityIdPutRawOriginal.bind(
      PartnerApiContext,
    ),
    post: PartnersApi.prototype.organizationsOrganizationIdPartnersPostRawOriginal.bind(
      PartnerApiContext,
    ),
    delete:
      PartnersApi.prototype.organizationsOrganizationIdPartnersUserEntityIdDeleteRawOriginal.bind(
        PartnerApiContext,
      ),
    changeStatus:
      PartnersApi.prototype.organizationsOrganizationIdPartnersUserEntityIdPatchRawOriginal.bind(
        PartnerApiContext,
      ),
    resendInvite:
      PartnersApi.prototype.organizationsOrganizationIdPartnersUserEntityIdResendInvitePostRawOriginal.bind(
        PartnerApiContext,
      ),
  },
  Hcp: {
    put: HcpsApi.prototype.organizationsOrganizationIdHcpsUserEntityIdPutRawOriginal.bind(
      HcpApiContext,
    ),
    post: HcpsApi.prototype.organizationsOrganizationIdHcpsPostRawOriginal.bind(HcpApiContext),
    delete:
      HcpsApi.prototype.organizationsOrganizationIdHcpsUserEntityIdDeleteRawOriginal.bind(
        HcpApiContext,
      ),
    changeStatus:
      HcpsApi.prototype.organizationsOrganizationIdHcpsUserEntityIdPatchRawOriginal.bind(
        HcpApiContext,
      ),
    resendInvite:
      HcpsApi.prototype.organizationsOrganizationIdHcpsUserEntityIdResendInvitePostRawOriginal.bind(
        HcpApiContext,
      ),
  },
  Organization: {
    put: OrganizationsApi.prototype.organizationsOrganizationEntityIdPutRawOriginal.bind(
      OrganizationsApiContext,
    ),
    post: OrganizationsApi.prototype.organizationsPostRawOriginal.bind(OrganizationsApiContext),
  },
  Device: {
    post: DevicesApi.prototype.organizationsOrganizationIdDevicesPostRawOriginal.bind(
      DevicesApiContext,
    ),
  },
  Patient: {
    // post: PatientsApi.prototype.organizationsOrganizationIdPatientsPostRawOriginal.bind(
    //   PatientApiContext,
    // ),
    post: async (
      ...params: Parameters<
        typeof PatientsApi.prototype.organizationsOrganizationIdPatientsPostRawOriginal
      >
    ) => {
      const newModel = cloneDeep(params[0]) as Parameters<
        typeof PatientsApi.prototype.organizationsOrganizationIdPatientsPostRawOriginal
      >[0];
      newModel.addPatientRequestModel!.additionalProperties = convertArraysToStrings(
        newModel?.addPatientRequestModel?.additionalProperties ?? {},
      );

      return PatientsApi.prototype.organizationsOrganizationIdPatientsPostRawOriginal.bind(
        PatientApiContext,
      )(newModel, params[1]);
    },
    put: async (
      ...params: Parameters<
        typeof PatientsApi.prototype.organizationsOrganizationIdPatientsUserEntityIdPutRawOriginal
      >
    ) => {
      const newModel = cloneDeep(params[0]) as Parameters<
        typeof PatientsApi.prototype.organizationsOrganizationIdPatientsUserEntityIdPutRawOriginal
      >[0];
      newModel.updatePatientRequestModel!.additionalProperties = convertArraysToStrings(
        newModel?.updatePatientRequestModel?.additionalProperties ?? {},
      );

      return PatientsApi.prototype.organizationsOrganizationIdPatientsUserEntityIdPutRawOriginal.bind(
        PatientApiContext,
      )(newModel, params[1]);
    },

    delete:
      PatientsApi.prototype.organizationsOrganizationIdPatientsUserEntityIdDeleteRawOriginal.bind(
        PatientApiContext,
      ),
    changeStatus:
      PatientsApi.prototype.organizationsOrganizationIdPatientsUserEntityIdPatchRawOriginal.bind(
        PatientApiContext,
      ),
    resendInvite:
      PatientsApi.prototype.organizationsOrganizationIdPatientsUserEntityIdResendInvitePostRawOriginal.bind(
        PatientApiContext,
      ),
  },
  Test: {
    post: TestsApi.prototype.organizationsOrganizationIdHcpsUserEntityIdTestsPostRawOriginal.bind(
      TestsApiContext,
    ),
    put: TestsApi.prototype.organizationsOrganizationIdHcpsUserEntityIdTestsTestEntityIdPutRawOriginal.bind(
      TestsApiContext,
    ),
    delete:
      TestsApi.prototype.organizationsOrganizationIdTestsTestEntityIdDeleteRawOriginal.bind(
        TestsApiContext,
      ),
  },
  Reports: {
    post: ReportsApi.prototype.reportsPostRawOriginal.bind(ReportsApiContext),
  },
  // Admin: {
  //   devicesGet: AdminApi.prototype.devicesGet.bind(AdminApiContext),
  //   devicesIdGet: AdminApi.prototype.devicesIdGet.bind(AdminApiContext),
  //   hcpsGet: AdminApi.prototype.hcpsGet.bind(AdminApiContext),
  //   hcpsIdGet: AdminApi.prototype.hcpsIdGet.bind(AdminApiContext),
  //   organizationsOrganizationIdAdministratorsGet:
  //     AdminApi.prototype.organizationsOrganizationIdAdministratorsGet.bind(AdminApiContext),
  //   organizationsOrganizationIdAdministratorsIdGet:
  //     AdminApi.prototype.organizationsOrganizationIdAdministratorsIdGet.bind(AdminApiContext),
  //   organizationsOrganizationIdAdministratorsIdPatch:
  //     AdminApi.prototype.organizationsOrganizationIdAdministratorsIdPatch.bind(AdminApiContext),
  //   organizationsOrganizationIdAdministratorsIdPut:
  //     AdminApi.prototype.organizationsOrganizationIdAdministratorsIdPut.bind(AdminApiContext),
  //   organizationsOrganizationIdAdministratorsPost:
  //     AdminApi.prototype.organizationsOrganizationIdAdministratorsPost.bind(AdminApiContext),
  //   patientsGet: AdminApi.prototype.patientsGet.bind(AdminApiContext),
  //   patientsIdGet: AdminApi.prototype.patientsIdGet.bind(AdminApiContext),
  //   organizationsOrganizationIdAdministratorsIdDelete:
  //     AdminApi.prototype.organizationsOrganizationIdAdministratorsIdDelete.bind(AdminApiContext),
  //   organizationsOrganizationIdAdministratorsIdResendSignUpPost:
  //     AdminApi.prototype.organizationsOrganizationIdAdministratorsIdResendSignUpPost.bind(
  //       AdminApiContext,
  //     ),
  // } as const,

  // Partner: {
  //   organizationsOrganizationIdPartnersGet:
  //     PartnerApi.prototype.organizationsOrganizationIdPartnersGet.bind(PartnerApiContext),
  //   organizationsOrganizationIdPartnersIdGet:
  //     PartnerApi.prototype.organizationsOrganizationIdPartnersIdGet.bind(PartnerApiContext),
  //   organizationsOrganizationIdPartnersIdPatch:
  //     PartnerApi.prototype.organizationsOrganizationIdPartnersIdPatch.bind(PartnerApiContext),
  //   organizationsOrganizationIdPartnersIdPut:
  //     PartnerApi.prototype.organizationsOrganizationIdPartnersIdPut.bind(PartnerApiContext),
  //   organizationsOrganizationIdPartnersPost:
  //     PartnerApi.prototype.organizationsOrganizationIdPartnersPost.bind(PartnerApiContext),
  //   organizationsOrganizationIdPartnersIdDelete:
  //     PartnerApi.prototype.organizationsOrganizationIdPartnersIdDelete.bind(PartnerApiContext),
  //   organizationsOrganizationIdPartnersIdResendSignUpPost:
  //     PartnerApi.prototype.organizationsOrganizationIdPartnersIdResendSignUpPost.bind(
  //       PartnerApiContext,
  //     ),
  // } as const,

  // Users: {
  //   usersGet: UsersApi.prototype.usersGet.bind(UsersApiContext),
  //   usersIdGet: UsersApi.prototype.usersIdGet.bind(UsersApiContext),
  // } as const,

  // Organization: {
  //   organizationsGet: OrganizationApi.prototype.organizationsGet.bind(OrganizationApiContext),
  //   organizationsIdGet: OrganizationApi.prototype.organizationsIdGet.bind(OrganizationApiContext),
  //   organizationsIdPut: OrganizationApi.prototype.organizationsIdPut.bind(OrganizationApiContext),
  //   organizationsPost: OrganizationApi.prototype.organizationsPost.bind(OrganizationApiContext),
  // } as const,

  // Patient: {
  //   organizationsOrganizationIdPatientsGet:
  //     PatientApi.prototype.organizationsOrganizationIdPatientsGet.bind(PatientApiContext),
  //   organizationsOrganizationIdPatientsIdGet:
  //     PatientApi.prototype.organizationsOrganizationIdPatientsIdGet.bind(PatientApiContext),
  //   organizationsOrganizationIdPatientsIdPatch:
  //     PatientApi.prototype.organizationsOrganizationIdPatientsIdPatch.bind(PatientApiContext),
  //   organizationsOrganizationIdPatientsIdPut:
  //     PatientApi.prototype.organizationsOrganizationIdPatientsIdPut.bind(PatientApiContext),
  //   organizationsOrganizationIdPatientsPost:
  //     PatientApi.prototype.organizationsOrganizationIdPatientsPost.bind(PatientApiContext),
  //   organizationsOrganizationIdPatientsIdDelete:
  //     PatientApi.prototype.organizationsOrganizationIdPatientsIdDelete.bind(PatientApiContext),
  //   organizationsOrganizationIdPatientsIdResendSignUpPost:
  //     PatientApi.prototype.organizationsOrganizationIdPatientsIdResendSignUpPost.bind(
  //       PatientApiContext,
  //     ),
  // } as const,

  // Hcp: {
  //   organizationsOrganizationIdHcpsGet:
  //     HcpApi.prototype.organizationsOrganizationIdHcpsGet.bind(HcpApiContext),
  //   organizationsOrganizationIdHcpsIdGet:
  //     HcpApi.prototype.organizationsOrganizationIdHcpsIdGet.bind(HcpApiContext),
  //   organizationsOrganizationIdHcpsIdPatch:
  //     HcpApi.prototype.organizationsOrganizationIdHcpsIdPatch.bind(HcpApiContext),
  //   organizationsOrganizationIdHcpsIdPatientsGet:
  //     HcpApi.prototype.organizationsOrganizationIdHcpsIdPatientsGet.bind(HcpApiContext),
  //   organizationsOrganizationIdHcpsIdPut:
  //     HcpApi.prototype.organizationsOrganizationIdHcpsIdPut.bind(HcpApiContext),
  //   organizationsOrganizationIdHcpsPost:
  //     HcpApi.prototype.organizationsOrganizationIdHcpsPost.bind(HcpApiContext),
  //   organizationsOrganizationIdHcpsIdDelete:
  //     HcpApi.prototype.organizationsOrganizationIdHcpsIdDelete.bind(HcpApiContext),
  //   organizationsOrganizationIdHcpsIdResendSignUpPost:
  //     HcpApi.prototype.organizationsOrganizationIdHcpsIdResendSignUpPost.bind(HcpApiContext),
  // } as const,

  // Devices: {
  //   organizationsOrganizationIdDevicesGet:
  //     DevicesApi.prototype.organizationsOrganizationIdDevicesGet.bind(DevicesApiContext),
  //   organizationsOrganizationIdDevicesIdGet:
  //     DevicesApi.prototype.organizationsOrganizationIdDevicesIdGet.bind(DevicesApiContext),
  //   organizationsOrganizationIdDevicesPost:
  //     DevicesApi.prototype.organizationsOrganizationIdDevicesPost.bind(DevicesApiContext),
  //   organizationsOrganizationIdPatientsIdDevicesGet:
  //     DevicesApi.prototype.organizationsOrganizationIdPatientsIdDevicesGet.bind(DevicesApiContext),
  // } as const,

  // MeasurementApi: {
  //   measurementsGet: MeasurementApi.prototype.measurementsGet.bind(MeasurementApiApiContext),
  //   organizationsOrganizationIdDevicesIdMeasurementsGet:
  //     MeasurementApi.prototype.organizationsOrganizationIdDevicesIdMeasurementsGet.bind(
  //       MeasurementApiApiContext,
  //     ),
  //   organizationsOrganizationIdHcpsIdMeasurementsBatchPost:
  //     MeasurementApi.prototype.organizationsOrganizationIdHcpsIdMeasurementsBatchPost.bind(
  //       MeasurementApiApiContext,
  //     ),
  //   organizationsOrganizationIdHcpsIdMeasurementsPost:
  //     MeasurementApi.prototype.organizationsOrganizationIdHcpsIdMeasurementsPost.bind(
  //       MeasurementApiApiContext,
  //     ),
  //   organizationsOrganizationIdMeasurementsGet:
  //     MeasurementApi.prototype.organizationsOrganizationIdMeasurementsGet.bind(
  //       MeasurementApiApiContext,
  //     ),
  //   organizationsOrganizationIdPatientsIdMeasurementsBatchPost:
  //     MeasurementApi.prototype.organizationsOrganizationIdPatientsIdMeasurementsBatchPost.bind(
  //       MeasurementApiApiContext,
  //     ),
  //   organizationsOrganizationIdPatientsIdMeasurementsGet:
  //     MeasurementApi.prototype.organizationsOrganizationIdPatientsIdMeasurementsGet.bind(
  //       MeasurementApiApiContext,
  //     ),
  //   organizationsOrganizationIdPatientsIdMeasurementsPost:
  //     MeasurementApi.prototype.organizationsOrganizationIdPatientsIdMeasurementsPost.bind(
  //       MeasurementApiApiContext,
  //     ),
  //   organizationsOrganizationIdHcpsIdPatientsMeasurementsGet:
  //     MeasurementApi.prototype.organizationsOrganizationIdHcpsIdPatientsMeasurementsGet.bind(
  //       MeasurementApiApiContext,
  //     ),
  // } as const,

  // DocumentApi: {
  //   downloadImagesPost: DocumentApi.prototype.downloadImagesPost.bind(DocumentApContext),
  //   organizationsOrganizationIdUsersIdDeleteImageDelete:
  //     DocumentApi.prototype.organizationsOrganizationIdUsersIdDeleteImageDelete.bind(
  //       DocumentApContext,
  //     ),
  //   organizationsOrganizationIdUsersIdUploadImagePut:
  //     DocumentApi.prototype.organizationsOrganizationIdUsersIdUploadImagePut.bind(
  //       DocumentApContext,
  //     ),
  // } as const,

  // SupportFormApi: {
  //   supportFormPost: SupportFormApi.prototype.supportFormPost.bind(SupportFormApiContext),
  // } as const,

  UserPreferences: {
    post: UserPreferencesApi.prototype.organizationsOrganizationIdUsersUserEntityIdPreferencesPost.bind(
      UserPreferenceApiContext,
    ),
  } as const,

  // GraphApi: {
  //   patientGraphGet:
  //     GraphApi.prototype.organizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGet.bind(
  //       GraphApiContext,
  //     ),
  // } as const,

  // TrackingDevicesApi: {
  //   trackingDevicesGet:
  //     TrackingDevicesApi.prototype.trackingDevicesGet.bind(TrackingDevicesApiContext),
  // } as const,

  // AccountDataApi: {
  //   accountDataPost: AccountDataApi.prototype.accountDataPost.bind(AccountDataApiContext),
  // } as const,
} as const;
