import * as React from "react";
import { SVGProps } from "react";
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.634 17.695a8.75 8.75 0 1 1 1.06-1.06l3.836 3.835a.75.75 0 0 1-1.06 1.06l-3.836-3.835ZM3.75 11a7.25 7.25 0 1 1 12.457 5.045.752.752 0 0 0-.162.162A7.25 7.25 0 0 1 3.75 11Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSearch;
