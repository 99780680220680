import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import { DocumentWrapper } from "../DocumentWrapper";
import "./TermsAndConditions.scss";

export function TermsAndConditionsEn() {
  const [dateFormatter] = useRegionDateTimeFormatter();

  return (
    <DocumentWrapper title="Terms&Conditions" date={dateFormatter("Nov 29, 2023")}>
      <div className="TermsAndConditions">
        <div className="TermsAndConditions__section">
          <p>
            The following are the Terms and Conditions (the "Agreement") which govern your access
            and use of our online platform through which therapy may be provided (collectively the
            "Platform"). This website is owned and operated by Carna Inc. (dba Carna Health) located
            at 131 Dartmouth Street, 3rd Floor, Boston, MA 02116, United States
            (office@carna.health). The Platform may be provided or be accessible via multiple
            websites or applications whether owned and/or operated by us or by third parties,
            including, without limitation, the web portal https://portal.carna.health/ , its related
            apps. and website www.carna.health.
          </p>
          <p>
            By accessing or using the Platform, you are entering into this Agreement. You should
            read this Agreement carefully before starting to use the Platform. If you do not agree
            to be bound to any term of this Agreement, you must not access the Platform.
          </p>

          <p>
            When the terms "we", "us", "our" or similar are used in this Agreement, they refer to
            any company that owns and operates the Platform (the "Company").
          </p>

          <p className="TermsAndConditions__text--bold">
            IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION AND A CLASS ACTION
            WAIVER AS DETAILED IN SECTION 6.
          </p>
        </div>

        <div className="TermsAndConditions__section">
          <h3>1. Privacy and Security</h3>
          <p>
            Protecting and safeguarding any information you provide through the Platform is
            extremely important to us. Information about our security and privacy practices can be
            found on our Privacy Policy available at carna.health/privacy (The 'Privacy Policy').
          </p>
          <p className="TermsAndConditions__text--bold">
            BY AGREEING TO THIS AGREEMENT AND/OR BY USING THE PLATFORM, YOU ARE ALSO AGREEING TO THE
            TERMS OF THE PRIVACY POLICY. THE PRIVACY POLICY IS INCORPORATED INTO AND DEEMED A PART
            OF THIS AGREEMENT. THE SAME RULES THAT APPLY REGARDING CHANGES AND REVISIONS OF THIS
            AGREEMENT ALSO APPLY TO CHANGES AND REVISIONS OF THE PRIVACY POLICY.
          </p>

          <h3>2. Third Party Content</h3>
          <p>
            The Platform may contain other content, products or services which are offered or
            provided by third parties ("Third Party Content") and links to Third Party Content
            (including but not limited to links to other websitest. We have no responsibility for
            the creation of any such Third Party Content, including (but not limited to) any related
            products, practices, terms or policies, and we will not be liable for any damage or loss
            caused by any Third Party Content.
          </p>

          <h3>3. Disclaimer of Warranty and Limitation of Liability</h3>
          <p className="TermsAndConditions__text--bold">
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU HEREBY RELEASE US AND AGREE TO HOLD US
            HARMLESS FROM ANY AND ALL CAUSES OF ACTION OR THE PLATFORM, INCLUDING (WITHOUT
            LIMITATION) ANY ACT, OMISSION, OPINION, RESPONSE, ADVICE, SUGGESTION, INFORMATION AND/OR
            ANY OTHER CONTENT OR INFORMATION ACCESSIBLE THROUGH THE PLATFORM. YOU UNDERSTAND, AGREE
            AND ACKNOWLEDGE THAT THE PLATFORM IS PROVIDED "AS IS" WITHOUT ANY EXPRESS OR IMPLIED
            WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, NON-INFRINGEMENT,
            SECURITY, FITNESS FOR A PARTICULAR PURPOSE OR ACCURACY. THE USE OF THE PLATFORM IS AT
            YOUR OWN RISK. TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES OF
            ANY KIND, WHETHER EXPRESSED OR IMPLIED. YOU UNDERSTAND, AGREE AND ACKNOWLEDGE THAT WE
            SHALL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL,
            CONSEQUENTIAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES. YOU UNDERSTAND, AGREE AND
            ACKNOWLEDGE THAT OUR AGGREGATE LIABILITY FOR DAMAGES ARISING WITH RESPECT TO THIS
            AGREEMENT AND ANY AND ALL USE OF THE PLATFORM WILL NOT EXCEED THE TOTAL AMOUNT OF MONEY
            PAID BY YOU OR ON YOUR BEHALF THROUGH THE PLATFORM IN THE 12 MONTHS PERIOD PRIOR TO THE
            DATE OF THE CLAIM.
          </p>
          <p>
            If the applicable law does not allow the limitation of liability as set forth above, the
            limitation will be deemed modified solely to the extent necessary to comply with
            applicable law. This section (limitation of liability) shall survive the termination or
            expiration of this Agreement.
          </p>

          <h3>4. Arbitration</h3>
          <p className="TermsAndConditions__text--bold">
            THIS SECTION 4 OF THIS AGREEMENT SHALL BE REFERRED TO AS THE "ARBITRATION AGREEMENT."
          </p>
          <p>
            a. By accepting the terms of this Arbitration Agreement, you and the Company
            (collectively, "Parties") agree that any dispute, claim, or controversy (except those
            specifically exempted below) arising out of or relating to (i) this Agreement and prior
            versions of this Agreement, or concerning the existence, applicability, breach,
            termination, enforcement, interpretation, scope, waiver, or validity thereof; and (ii)
            the use of the Platform (collectively, "Disputes") will be resolved on an individual
            basis by final and binding arbitration, regardless of its date of accrual. Except as it
            otherwise provides, this Arbitration Agreement is intended to apply to the resolution of
            Disputes that otherwise would be resolved in a court of law or before a forum other than
            arbitration. All claims in arbitration are subject to the same statutes of limitation
            that would apply in court. By accepting the terms of this Arbitration Agreement, you and
            the Company mutually agree to waive their respective rights to trial by jury.
          </p>
          <p>
            The Parties acknowledge that the Agreement involves interstate commerce and agree that
            all issues relating to arbitration or enforceability of this Arbitration Agreement will
            be governed by the Federal Arbitration Act (9 U.S.C. §§ 1 et seq.). The arbitration
            shall be administered by the American Arbitration Association ("AAA") under its Consumer
            Arbitration Rules ("AAA Consumer Rules"), which are available via the internet at
            www.adr.org or by using a service such as Google to search for "AAA Consumer Arbitration
            Rules"; provided however, that if there is a conflict between the AAA Consumer Rules and
            this Arbitration Agreement, this Arbitration Agreement shall govern. The following terms
            and procedures shall apply:
          </p>

          <ol type="i">
            <li>
              The arbitration shall be heard by one arbitrator selected in accordance with the AAA
              Consumer Rules. The arbitrator shall be a member of the bar of the state in which the
              arbitration will be conducted or a retired judge from any jurisdiction and, in either
              case, shall have experience in the law underlying the dispute.
            </li>
            <li>
              Other than issues related to the Class Action Waiver (as defined below), the
              arbitrator, and not any federal, state, or local court or agency, shall have exclusive
              authority to resolve any dispute arising out of or relating to the interpretation,
              applicability, enforceability or formation of this Arbitration Agreement, any part of
              it, or of the Agreement including, but not limited to, any claim that all or any part
              of this Arbitration Agreement or Agreement is void or voidable.
            </li>
            <li>
              If the parties cannot otherwise agree on a location for the arbitration, the
              arbitration shall take place in Wilmington, Delaware.
            </li>
            <li>
              Each party will pay the fees for its own attorneys, subject to any remedies to which
              that party may later be entitled under applicable law. In all cases where required by
              law, the Company will pay the arbitrator's and arbitration fees. If under applicable
              law the Company is not required to pay all of the arbitrator's and/or arbitration
              fees, such fee(s) will be apportioned between the Parties in accordance with said
              applicable law. Any disputes regarding whether the Company is required to pay the
              arbitrator’s and arbitration fees and/or how those fees are to be apportioned between
              the Parties will be resolved by the arbitrator.
            </li>
            <li>
              The arbitrator shall issue orders (including subpoenas to third parties for
              pre-hearing discovery) allowing the parties to conduct discovery sufficient to allow
              each party to prepare its claims and/or defenses, taking into consideration that
              arbitration is designed to be a speedy and efficient method for resolving disputes.
            </li>
            <li>
              Except as provided in the Class Action Waiver (as defined below), the arbitrator may
              award all remedies to which a party is entitled under applicable law and which would
              otherwise be available in a court of law, but shall not be empowered to award any
              remedies that would not have been available in a court of law for the claims presented
              in arbitration. The arbitrator shall apply the state or federal substantive law, or
              both, as is applicable.
            </li>
            <li>
              Either party shall be permitted to file a motion to dismiss and/or motion for summary
              judgment and the arbitrator will apply the standards of the Federal Rules of Civil
              Procedure governing such motions.
            </li>
            <li>
              The arbitrator's decision or award shall be in writing with findings of fact and
              conclusions of law. Judgment may be entered on the arbitrator's decision or award in
              any court of competent jurisdiction.
            </li>
            <li>
              A party may apply to a court of competent jurisdiction for temporary or preliminary
              injunctive relief in connection with an arbitrable controversy in accordance with
              applicable law, and any such application shall not be deemed incompatible with or
              waiver of this Arbitration Agreement. The court to which the application is made is
              authorized to consider the merits of the arbitrable controversy to the extent it deems
              necessary in making its ruling, but only to the extent permitted by applicable law.
              All determinations of final relief, however, will be decided in arbitration.
            </li>
          </ol>

          <p>
            a. If for any reason the AAA will not administer the arbitration, either party may apply
            to a court of competent jurisdiction with authority over the location where the
            arbitration will be conducted for appointment of a neutral arbitrator.
            <br />
            Nothing in this Arbitration Agreement prevents the investigation by a government agency
            of any report, claim or charge otherwise covered by this Arbitration Agreement. This
            Arbitration Agreement also does not prevent federal administrative agencies from
            adjudicating claims and awarding remedies based on those claims, even if the claims
            would otherwise be covered by this Arbitration Agreement. Nothing in this Arbitration
            Agreement prevents or excuses a party from satisfying any conditions precedent and/or
            exhausting administrative remedies under applicable law before bringing a claim in
            arbitration.
          </p>
          <p>
            b. Class, Collective, and Other Representative Action Waivers ("Class Action Waiver").
            You and the Company mutually agree that by entering into this Arbitration Agreement,
            both waive their right to have any dispute, claim, or controversy brought, heard or
            arbitrated as a class, collective, and/or other representative action to the maximum
            extent permitted by law, and an arbitrator will not have any authority to hear or
            arbitrate any such class, collective, or other representative action. You also waive
            your right to receive notice of any class, collective, or other representative action
            that may be filed. Notwithstanding any other clause contained in this Arbitration
            Agreement or the AAA Consumer Rules, as defined above and incorporated herein by
            reference, any claim that all or part of this Class Action Waiver is unenforceable,
            unconscionable, void or voidable may be determined only by a court of competent
            jurisdiction and not by an arbitrator. In any case in which (i) the dispute is filed as
            a class, collective, or other representative action; and (ii) there is a final judicial
            determination that all or part of the Class Action Waiver is unenforceable, the class,
            collective, or other representative action to that extent must be litigated in a civil
            court of competent jurisdiction, but the portion of the Class Action Waiver that is
            enforceable shall be enforced in arbitration.
          </p>
          <p>
            c. Mass Arbitration. To the extent permitted by applicable law, to increase efficiency
            of resolution, in the event 25 or more arbitration demands of a similar nature are filed
            against the Company, presented by or with the assistance or involvement of the same law
            firm or organization, the Parties agree that this will constitute a "Mass Arbitration"
            and the following terms and procedures shall apply:
          </p>

          <ol type="i">
            <li>
              The Parties shall cooperate to group the arbitration demands into randomized batches
              of no more than 100 demands per batch. To the extent there are fewer than 100
              arbitration demands left over after the batching previously described, a final batch
              shall consist of the remaining demands.
            </li>
            <li>
              Claimants’ counsel shall organize and present the batched demands to the arbitration
              provider in a format as directed by the arbitration provider.
            </li>
            <li>
              The arbitration provider shall treat each batch of demands as one case, with each case
              having one demand for arbitration, one appointed arbitrator, and one set of
              administrative documents, and administrative and filing fees per batch.
            </li>
            <li>
              A separate arbitrator will be appointed to, and administrative and filing fees
              assessed for, each batch of demands.
            </li>
          </ol>

          <p>
            b. The Parties agree that arbitration demands are of a "similar nature" if they arise
            out of or relate to a similar factual scenario and raise the same or similar legal
            issues and seek the same or similar relief. You agree to cooperate in good faith with
            the Company and the arbitration provider to implement such a batch approach to
            resolution and fees. Disagreements over the applicability of this batch arbitration
            process will be settled in a single, consolidated arbitration proceeding that includes
            all affected parties and is resolved by a single arbitrator subject to the requirements
            of this section. Notwithstanding any provision in the Agreement to the contrary, batch
            arbitrations shall take place in Wilmington, Delaware.
          </p>
          <p>
            c. Exceptions to Arbitration. Notwithstanding the foregoing, this Arbitration Agreement
            shall not require arbitration of the following claims: (i) individual claims brought in
            small claims court so long as the matter remains in such court and advances only on an
            individual basis; (ii) individual claims of sexual assault or sexual harassment
            occurring in connection with your use of the Platform; and/or (iii) injunctive or other
            equitable relief in a court of competent jurisdiction to prevent the actual or
            threatened infringement, misappropriation, or violation of a party’s copyrights,
            trademarks, trade secrets, patents, or other intellectual property rights. Also, this
            Arbitration Agreement does not apply to disputes that may not be subject to arbitration
            as expressly provided by a controlling federal or state statute. Disputes between the
            parties that may not be subject to a pre-dispute arbitration agreement as provided by
            the Dodd-Frank Wall Street Reform and Consumer Protection Act (Public Law 111-203),
            Ending Forced Arbitration of Sexual Assault and Sexual Harassment Act (H.R. 4445), or as
            provided by an Act of Congress are excluded from the coverage of this Arbitration
            Agreement.
          </p>
          <p>
            d. Optional Pre-Arbitration Dispute Resolution and Notification. Before initiating
            arbitration, you and the Company may first attempt to resolve any Disputes informally
            for 30 days, unless this time period is mutually extended by the Parties. The informal
            negotiations begin upon receipt of written notice from one party to the other ("Notice
            of Dispute"). The Notice of Dispute must: (i) include the full name and contact
            information of the complaining party; (ii) describe the nature and basis of the dispute,
            claim, or controversy; and (iii) set forth the specific relief sought. The Company will
            send its Notice of Dispute to your billing or email address. You will send your Notice
            of Dispute to: Carna Inc. (dba Carna Health), 131 Dartmouth Street, 3rd Floor Boston, MA
            02116, United States. All offers, promises, conduct and statements, whether oral or
            written, made in the course of the negotiation by any of the Parties, their agents,
            employees, and attorneys are confidential, privileged and inadmissible for any purpose,
            including as evidence of liability or for impeachment, in arbitration or other
            proceeding involving the parties, provided that evidence that is otherwise admissible or
            discoverable shall not be rendered inadmissible as a result of its use in the
            negotiation.
          </p>

          <p>
            e. Application to Third Parties. This Arbitration Agreement shall be binding upon, and
            shall include any claims brought by or against any third parties, including but not
            limited to your spouses, heirs, third-party beneficiaries and assigns, where their
            underlying claim(s) arise out of or relate to your use of the Platform. To the extent
            that any third-party beneficiary to this Agreement brings claims against the Parties,
            those claims shall also be subject to this Arbitration Agreement.
          </p>
          <p>
            f. Right to Opt Out of Arbitration. You may opt out from this Arbitration Agreement
            within 30 days after you first access or use the Platform by sending written notice of
            your decision to opt-out to optout@carna.health, using the subject line "Arbitration
            Opt-Out." Please state that you are opting out of this Arbitration Agreement and Class
            Action Waiver and provide your name and address. If you opt out of this Arbitration
            Agreement and Class Action Waiver within the 30-day period, neither you nor Carna Inc.
            will be required to arbitrate disputes and may instead litigate those disputes without
            regard to this Arbitration Agreement and Class Action Waiver. Carna Health members who
            accessed the Platform before April 6, 2023 will have the option to opt out of the
            Arbitration Agreement and Class Action Waiver within 30 days of April 6, 2023. Should
            you not opt out of this Arbitration Agreement and Class Action Waiver within the 30-day
            period, you and Carna Health shall be bound by the terms of this Arbitration Agreement
            and Class Action Waiver. You have the right to consult with counsel of your choice
            concerning this Arbitration Agreement and Class Action Waiver.
          </p>
          <p>
            g. Survival. This Arbitration Agreement survives after the termination or expiration of
            the Parties' relationship.
          </p>
          <p>
            d. Your Account, Representations, Conduct and Commitments You hereby confirm that you
            are legally able to enter into a contract or have the consent of a parent or guardian.
          </p>
        </div>
        <div className="TermsAndConditions__section">
          <p>
            Minor Consent: Where consent from a parent or guardian is required to receive access to
            the Platform, you hereby confirm that as the consenting parent or guardian, you have the
            sole right to consent to access to the Platform for the minor and are not legally
            mandated to confer with or get consent from any other legal guardian before consenting.
            You also give affirmative consent to the provisions set forth in the accompanying
            Privacy Policy regarding the collection, process, and use of personal information on
            behalf of the minor. You also agree that consent to receive access to the Platform
            remains valid until membership is canceled.
          </p>

          <p>
            You hereby confirm and agree that all the information that you provided in or through
            the Platform, and the information that you will provide in or through the Platform in
            the future, is accurate, true, current and complete. Furthermore, you agree that during
            the term of this Agreement you will make sure to maintain and update this information so
            it will continue to be accurate, current and complete. You agree, confirm and
            acknowledge that you are responsible for maintaining the confidentiality of your
            password and any other security information related to your account (collectively
            "Account Access"). We advise you to change your password frequently and to take extra
            care in safeguarding your password.
          </p>

          <p>
            You agree to notify us immediately of any unauthorized use of your Account Access or any
            other concern for breach of your account security.
          </p>
          <p>
            You agree, confirm and acknowledge that we will not be liable for any loss or damage
            that incurred as a result of someone else using your account, either with or without
            your consent and/or knowledge.
          </p>
          <p>
            You agree, confirm and acknowledge that you are solely and fully liable and responsible
            for all activities performed using your Account Access. You further acknowledge and
            agree that we will hold you liable and responsible for any damage or loss incurred as a
            result of the use of your Account Access by any person whether authorized by you or not,
            and you agree to indemnify us for any such damage or loss.
          </p>

          <p>
            You agree and commit not to use the account or Account Access of any other person for
            any reason.
          </p>
          <p>
            You agree and confirm that your use of the Platform is for your own personal use only
            and that you are not using the Platform for or on behalf of any other person or
            organization.
          </p>
          <p>
            You agree and commit not to interfere with or disrupt, or attempt to interfere with or
            disrupt, any of our systems, services, servers, networks or infrastructure, or any of
            the Platform's systems, services, servers, networks or infrastructure, including without
            limitation obtaining unauthorized access to the aforementioned.
          </p>
          <p>
            You agree and commit not to make any use of the Platform for the posting, sending or
            delivering of either of the following: (a) unsolicited email and/or advertisement or
            promotion of goods and services; (b) malicious software or code; (c) unlawful,
            harassing, privacy invading, abusive, threatening, vulgar, obscene, racist or
            potentially harmful content; (d) any content that infringes a third party right
            including intellectual property rights; (e) any content that may cause damage to a third
            party; (f) any content which may constitute, cause or encourage a criminal action or
            violate any applicable law.
          </p>
          <p>
            You agree and commit not to violate any applicable local, state, national or
            international law, statute, ordinance, rule, regulation or ethical code in relation to
            your use of the Platform and us.
          </p>
          <p>
            If you receive any file from us, whether through the Platform or not, you agree to check
            and scan this file for any virus or malicious software prior to opening or using this
            file.
          </p>
          <p>
            You will indemnify us, defend us, and hold us harmless from and against any and all
            claims, losses, causes of action, demands, liabilities, costs or expenses (including,
            but not limited to, litigation and reasonable attorneys' fees and expenses) arising out
            of or relating to any of the following: (a) your access to or use of the Platform; (b)
            any actions made with your account or Account Access whether by you or by someone else;
            (c) your violation of any of the provisions of this Agreement; (d) non-payment for any
            of the services which were provided through the Platform; (e) your violation of any
            third party right, including, without limitation, any intellectual property right,
            publicity, confidentiality, property or privacy right. This clause shall survive
            expiration or termination of this Agreement.
          </p>
          <p>
            You confirm and agree to use only credit cards or other payment means (collectively
            “Payment Means”) which you are duly and fully authorized to use, and that all payment
            related information that you provided and will provide in the future, to or through the
            Platform, is accurate, current and correct and will continue to be accurate, current and
            correct. You agree to pay all fees and charges associated with your account on a timely
            basis and according to the fees schedule, the terms and the rates as published in the
            Platform. By providing us with your Payment Means you authorize us to bill and charge
            you through that Payment Means and you agree to maintain valid Payment Means information
            in your account information.
          </p>
        </div>

        <div className="TermsAndConditions__section">
          <p>
            e. Modifications, Termination, Interruption and Disruptions to the Platform You
            understand, agree and acknowledge that we may modify, suspend, disrupt or discontinue
            the Platform, any part of the Platform or the use of the Platform, whether to all
            clients or to you specifically, at any time with or without notice to you. You agree and
            acknowledge that we will not be liable for any of the aforementioned actions or for any
            losses or damages that are caused by any of the aforementioned actions.
          </p>
          <p>
            The Platform depends on various factors such as software, hardware and tools, either our
            own or those owned and/or operated by our contractors and suppliers. While we make
            commercially reasonable efforts to ensure the Platform's reliability and accessibility,
            you understand and agree that no platform can be 100% reliable and accessible and so we
            cannot guarantee that access to the Platform will be uninterrupted or that it will be
            accessible, consistent, timely or error-free at all times.
          </p>

          <p>
            f. Export Controls and Sanctions
            <br />
            Carna Health products may be subject to US export and re-export control laws and
            regulations or similar laws applicable in other jurisdictions, including the Export
            Administration Regulations ("EAR") maintained by the US Department of Commerce, trade
            and economic sanctions maintained by the US Treasury Department's Office of Foreign
            Assets Control ("OFAC"), and the International Traffic in Arms Regulations ("ITAR")
            maintained by the US Department of State. You warrant that you are (1) not located in
            any country to which the United States has embargoed goods or has otherwise applied any
            economic sanctions; and (2) not a denied party as specified in any applicable export or
            re-export laws or regulations or similar laws applicable in other jurisdictions or
            otherwise listed on any US government list of prohibited or restricted parties.
            <br />
            You agree to comply with all applicable export and reexport control laws and
            regulations, including without limitation the EAR and trade and economic sanctions
            maintained by OFAC. Specifically, you agree not to – directly or indirectly – use, sell,
            export, reexport, transfer, divert, release, or otherwise dispose of any products,
            software, or technology (including products derived from or based on such technology)
            received from Carna Health under these Terms to any destination, entity, or person or
            for any end-use prohibited by the EAR, trade and economic sanctions maintained by OFAC,
            or any applicable laws or regulations of the United States or any other jurisdiction
            without obtaining any required prior authorization from the competent government
            authorities as required by those laws and regulations.
          </p>
          <p>
            g. Notices We may provide notices or other communications to you regarding this
            Agreement or any aspect of the Platform, by email to the email address that we have on
            record, by regular mail or by posting it online. The date of receipt shall be deemed the
            date on which such notice is given. Notices sent to us must be delivered by email to
            contact@carna.health.
          </p>
          <p>
            h. Important Notes about our Agreement This Agreement and our relationship with you
            shall both be interpreted solely in accordance with the laws of the State of Delaware
            excluding any rules governing choice of laws.
          </p>
          <p className="TermsAndConditions__text--bold">
            THIS AGREEMENT CONSTITUTES THE ENTIRE AGREEMENT BETWEEN YOU AND US. YOU CONFIRM THAT YOU
            HAVE NOT RELIED UPON ANY PROMISES OR REPRESENTATIONS BY US EXCEPT AS SET FORTH IN THIS
            AGREEMENT.
          </p>
        </div>

        <div className="TermsAndConditions__section">
          <p>
            You irrevocably agree that the exclusive venue for any action or proceeding arising out
            of relating to this Agreement or our relationship with you, regardless of theory, shall
            be the US District Court for the Northern District of California, or the state courts
            located in Santa Clara County in California. You irrevocably consent to the personal
            jurisdiction of the aforementioned courts and hereby waive any objection to the exercise
            of jurisdiction by the aforementioned courts. Nothing in this Agreement, including the
            choice of the laws of the State of Delaware, affects your statutory rights as a consumer
            to rely on the mandatory consumer protection provisions contained in the law of the
            country in which you live.
          </p>
          <p>
            We may change this Agreement by posting modifications on the Platform. Unless otherwise
            specified by us, all modifications shall be effective upon posting. Therefore, you are
            encouraged to check the terms of this Agreement frequently. The last update date of this
            Agreement is posted at the bottom of the Agreement. By using the Platform after the
            changes become effective, you agree to be bound by such changes to the Agreement. If you
            do not agree to the changes, you must terminate access to the Platform and participation
            in its services.
          </p>
          <p>
            We may freely transfer or assign this Agreement or any of its obligations hereunder. The
            paragraph headings in this Agreement are solely for the sake of convenience and will not
            be applied in the interpretation of this Agreement.
          </p>
          <p>
            If any provision of this Agreement is held by a court of competent jurisdiction to be
            illegal, invalid, unenforceable, or otherwise contrary to law, the remaining provisions
            of this Agreement will remain in full force and effect.
          </p>
          <p>
            To clear any doubt, all clauses regarding arbitration, limitations of liabilities, and
            indemnification shall survive the termination or expiration of this Agreement.
          </p>
        </div>
      </div>
    </DocumentWrapper>
  );
}
