/* tslint:disable */
 
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EgfrFormulaType = {
    Unknown: 'Unknown',
    MDRD: 'MDRD',
    IDMSTraceable: 'IDMSTraceable',
    CockcrockGalult: 'CockcrockGalult',
    BedsideSchwartz: 'BedsideSchwartz',
    CounahantBarat: 'CounahantBarat',
    CKDEPI2009: 'CKDEPI2009',
    CKDEPI2021: 'CKDEPI2021'
} as const;
export type EgfrFormulaType = typeof EgfrFormulaType[keyof typeof EgfrFormulaType];


export function EgfrFormulaTypeFromJSON(json: any): EgfrFormulaType {
    return EgfrFormulaTypeFromJSONTyped(json, false);
}

export function EgfrFormulaTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EgfrFormulaType {
    return json as EgfrFormulaType;
}

export function EgfrFormulaTypeToJSON(value?: EgfrFormulaType | null): any {
    return value as any;
}

