import { Button } from "libs/ui";
import { PropsWithChildren } from "react";
import "./SideDetailsHead.scss";

interface SideDetailsHeadProps {
  onClose: () => void;
}

export function SideDetailsHead({ onClose, children }: PropsWithChildren<SideDetailsHeadProps>) {
  return (
    <div className="SideDetailsHead">
      <h3 className="SideDetailsHead__title">{children}</h3>
      <Button
        onClick={onClose}
        buttonSize="small"
        buttonType="transparent"
        buttonIcon={{ icon: "Close" }}
        data-testid="close-button"
      />
    </div>
  );
}
