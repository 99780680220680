/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalPropertyDefinitionModel } from './AdditionalPropertyDefinitionModel';
import {
    AdditionalPropertyDefinitionModelFromJSON,
    AdditionalPropertyDefinitionModelFromJSONTyped,
    AdditionalPropertyDefinitionModelToJSON,
} from './AdditionalPropertyDefinitionModel';
import type { AncestryModel } from './AncestryModel';
import {
    AncestryModelFromJSON,
    AncestryModelFromJSONTyped,
    AncestryModelToJSON,
} from './AncestryModel';
import type { DeviceIdModel } from './DeviceIdModel';
import {
    DeviceIdModelFromJSON,
    DeviceIdModelFromJSONTyped,
    DeviceIdModelToJSON,
} from './DeviceIdModel';
import type { EmailModel } from './EmailModel';
import {
    EmailModelFromJSON,
    EmailModelFromJSONTyped,
    EmailModelToJSON,
} from './EmailModel';
import type { GeneralPractitionerModel } from './GeneralPractitionerModel';
import {
    GeneralPractitionerModelFromJSON,
    GeneralPractitionerModelFromJSONTyped,
    GeneralPractitionerModelToJSON,
} from './GeneralPractitionerModel';
import type { NationalityTypeModel } from './NationalityTypeModel';
import {
    NationalityTypeModelFromJSON,
    NationalityTypeModelFromJSONTyped,
    NationalityTypeModelToJSON,
} from './NationalityTypeModel';
import type { RegistryNumberModel } from './RegistryNumberModel';
import {
    RegistryNumberModelFromJSON,
    RegistryNumberModelFromJSONTyped,
    RegistryNumberModelToJSON,
} from './RegistryNumberModel';
import type { ZipCodeModel } from './ZipCodeModel';
import {
    ZipCodeModelFromJSON,
    ZipCodeModelFromJSONTyped,
    ZipCodeModelToJSON,
} from './ZipCodeModel';

/**
 * 
 * @export
 * @interface PatientModel
 */
export interface PatientModel {
    /**
     * 
     * @type {Array<AdditionalPropertyDefinitionModel>}
     * @memberof PatientModel
     */
    additionalProperties: Array<AdditionalPropertyDefinitionModel>;
    /**
     * 
     * @type {AncestryModel}
     * @memberof PatientModel
     */
    ancestry: AncestryModel;
    /**
     * 
     * @type {EmailModel}
     * @memberof PatientModel
     */
    email: EmailModel;
    /**
     * 
     * @type {RegistryNumberModel}
     * @memberof PatientModel
     */
    registryNumber: RegistryNumberModel;
    /**
     * 
     * @type {ZipCodeModel}
     * @memberof PatientModel
     */
    zipCode: ZipCodeModel;
    /**
     * 
     * @type {NationalityTypeModel}
     * @memberof PatientModel
     */
    nationalityType: NationalityTypeModel;
    /**
     * 
     * @type {GeneralPractitionerModel}
     * @memberof PatientModel
     */
    generalPractitioner: GeneralPractitionerModel;
    /**
     * 
     * @type {DeviceIdModel}
     * @memberof PatientModel
     */
    deviceId: DeviceIdModel;
}

/**
 * Check if a given object implements the PatientModel interface.
 */
export function instanceOfPatientModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "additionalProperties" in value;
    isInstance = isInstance && "ancestry" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "registryNumber" in value;
    isInstance = isInstance && "zipCode" in value;
    isInstance = isInstance && "nationalityType" in value;
    isInstance = isInstance && "generalPractitioner" in value;
    isInstance = isInstance && "deviceId" in value;

    return isInstance;
}

export function PatientModelFromJSON(json: any): PatientModel {
    return PatientModelFromJSONTyped(json, false);
}

export function PatientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'additionalProperties': ((json['AdditionalProperties'] as Array<any>).map(AdditionalPropertyDefinitionModelFromJSON)),
        'ancestry': AncestryModelFromJSON(json['Ancestry']),
        'email': EmailModelFromJSON(json['Email']),
        'registryNumber': RegistryNumberModelFromJSON(json['RegistryNumber']),
        'zipCode': ZipCodeModelFromJSON(json['ZipCode']),
        'nationalityType': NationalityTypeModelFromJSON(json['NationalityType']),
        'generalPractitioner': GeneralPractitionerModelFromJSON(json['GeneralPractitioner']),
        'deviceId': DeviceIdModelFromJSON(json['DeviceId']),
    };
}

export function PatientModelToJSON(value?: PatientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AdditionalProperties': ((value.additionalProperties as Array<any>).map(AdditionalPropertyDefinitionModelToJSON)),
        'Ancestry': AncestryModelToJSON(value.ancestry),
        'Email': EmailModelToJSON(value.email),
        'RegistryNumber': RegistryNumberModelToJSON(value.registryNumber),
        'ZipCode': ZipCodeModelToJSON(value.zipCode),
        'NationalityType': NationalityTypeModelToJSON(value.nationalityType),
        'GeneralPractitioner': GeneralPractitionerModelToJSON(value.generalPractitioner),
        'DeviceId': DeviceIdModelToJSON(value.deviceId),
    };
}

