import { NotFoundPage } from "pages/ErrorBoundary/NotFoundPage";
import { Outlet, useParams } from "react-router";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { PropsWithChildren } from "react";

interface RouteEntryAccessLevelProps {
  check: (
    can: ReturnType<typeof useHasAccess>,
    routeParams?: Record<string, string | undefined>,
  ) => boolean;
}
/**
 * ! Most Routes what we have doesn't require fetched attributes before showing
 * ! Most Routes only need "view" permission to enter
 */
export function AccessLevel({ check, children }: PropsWithChildren<RouteEntryAccessLevelProps>) {
  const params = useParams();
  const can = useHasAccess();

  if (!check(can, params)) {
    return <NotFoundPage />;
  }

  if (children) {
    return children;
  }

  return <Outlet />;
}
