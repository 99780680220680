/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';
import type { UserStatusType } from './UserStatusType';
import {
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface UserDetailsResponseModel
 */
export interface UserDetailsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof UserDetailsResponseModel
     */
    shortId: number;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    city: string;
    /**
     * 
     * @type {CountryType}
     * @memberof UserDetailsResponseModel
     */
    country: CountryType;
    /**
     * 
     * @type {number}
     * @memberof UserDetailsResponseModel
     */
    state?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    organizationName: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsResponseModel
     */
    isMainOrganization?: boolean | null;
    /**
     * 
     * @type {UserStatusType}
     * @memberof UserDetailsResponseModel
     */
    status: UserStatusType;
    /**
     * 
     * @type {UserRoleType}
     * @memberof UserDetailsResponseModel
     */
    role: UserRoleType;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    rowVersion: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsResponseModel
     */
    isAccountOwner: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserDetailsResponseModel
     */
    additionalProperties: { [key: string]: string; };
}

/**
 * Check if a given object implements the UserDetailsResponseModel interface.
 */
export function instanceOfUserDetailsResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "shortId" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "organizationName" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "rowVersion" in value;
    isInstance = isInstance && "isAccountOwner" in value;
    isInstance = isInstance && "additionalProperties" in value;

    return isInstance;
}

export function UserDetailsResponseModelFromJSON(json: any): UserDetailsResponseModel {
    return UserDetailsResponseModelFromJSONTyped(json, false);
}

export function UserDetailsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetailsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'shortId': json['ShortId'],
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'email': json['Email'],
        'phone': json['Phone'],
        'street': json['Street'],
        'city': json['City'],
        'country': CountryTypeFromJSON(json['Country']),
        'state': !exists(json, 'State') ? undefined : json['State'],
        'zipCode': !exists(json, 'ZipCode') ? undefined : json['ZipCode'],
        'organizationId': json['OrganizationId'],
        'organizationName': json['OrganizationName'],
        'isMainOrganization': !exists(json, 'IsMainOrganization') ? undefined : json['IsMainOrganization'],
        'status': UserStatusTypeFromJSON(json['Status']),
        'role': UserRoleTypeFromJSON(json['Role']),
        'rowVersion': json['RowVersion'],
        'isAccountOwner': json['IsAccountOwner'],
        'additionalProperties': json['AdditionalProperties'],
    };
}

export function UserDetailsResponseModelToJSON(value?: UserDetailsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ShortId': value.shortId,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Email': value.email,
        'Phone': value.phone,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': value.state,
        'ZipCode': value.zipCode,
        'OrganizationId': value.organizationId,
        'OrganizationName': value.organizationName,
        'IsMainOrganization': value.isMainOrganization,
        'Status': UserStatusTypeToJSON(value.status),
        'Role': UserRoleTypeToJSON(value.role),
        'RowVersion': value.rowVersion,
        'IsAccountOwner': value.isAccountOwner,
        'AdditionalProperties': value.additionalProperties,
    };
}

