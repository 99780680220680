import { ColorDot } from "libs/ui/ColorDot";
import { PropsWithChildren } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./NumberStat.scss";

interface NumberStatProps {
  type: "primary" | "secondary";
  transKey: string;
  value: string | number;
}
export function NumberStat({
  transKey,
  type,
  value,
  children,
}: PropsWithChildren<NumberStatProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.TotalNumbers" });

  return (
    <div className="NumberStat">
      <ColorDot type={type} />

      <Trans i18nKey={transKey} t={t} values={{ value }}>
        <span style={{ whiteSpace: "pre-wrap" }}></span>
        <strong></strong>
      </Trans>

      {children}
    </div>
  );
}
