import { Layout } from "layout";
import { ErrorBoundary } from "pages/ErrorBoundary";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import "./Main.scss";
import { DevicesRoutes } from "./routes/DevicesRoutes";
import { HcpRoutes } from "./routes/HcpRoutes";
import { PatientsRoutes } from "./routes/PatientsRoutes";
import { SettingsRoutes } from "./routes/SettingsRoutes";
import { TestsRoutes } from "./routes/TestsRoutes";
import { mainRoutePaths } from "router";
import { PolicyRoutes } from "./routes/PolicyRoutes";
import * as Sentry from "@sentry/react";
import { DashboardRoutes } from "./routes/DashboardRoutes";
import { ReportRoutes } from "./routes/ReportRoutes";
import { ALLOWED_ENVS_REPORTS_PAGE } from "utils/createGlobalConfigStore";

const AppRoutes = [
  {
    path: mainRoutePaths.home,
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      ...createRoutesFromElements(DevicesRoutes, [2]),
      ...createRoutesFromElements(HcpRoutes, [3]),
      ...createRoutesFromElements(PatientsRoutes, [4]),
      ...createRoutesFromElements(SettingsRoutes, [5]),
      ...createRoutesFromElements(TestsRoutes, [6]),
      ...createRoutesFromElements(DashboardRoutes, [7]),
      ...(ALLOWED_ENVS_REPORTS_PAGE.some(el => el === process.env.VITE_APP_ENVIRONMENT)
        ? createRoutesFromElements(ReportRoutes, [8])
        : []),
    ],
  },
  {
    children: [...createRoutesFromElements(PolicyRoutes, [1])],
  },
] satisfies RouteObject[];

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

// const router = createBrowserRouter(AppRoutes);
const router = sentryCreateBrowserRouter(AppRoutes);

export function Main() {
  return <RouterProvider router={router} />;
}
