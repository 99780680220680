import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { FallbackPage } from "./FallbackPage";
import { NotFoundPage } from "./NotFoundPage";
import { AccessDenied } from "models/error";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { getCurrentUserData } from "utils/getCurrentUserData";

function isNotFoundError(error: any) {
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return true;
    }
  }

  return false;
}

function isAccessDeniedError(error: any) {
  return error instanceof AccessDenied;
}
/**
 * ! Only issue here that we show errors in console instead of filtering
 * ! Some libs can catch them unnecessarily
 * ! https://github.com/facebook/react/issues/15069
 */
export function ErrorBoundary() {
  const error = useRouteError();

  // !https://github.com/getsentry/sentry-javascript/discussions/6912
  useEffect(() => {
    if (isNotFoundError(error) || isAccessDeniedError(error)) {
      return;
    }
    (async () => {
      const userData = await getCurrentUserData();
      Sentry.captureException(error, {
        level: "fatal",
        user: { id: userData.currentUserId, tenant: userData.tenantId },
        extra: { OopsPage: true },
      });
    })();
  }, [error]);

  if (isNotFoundError(error)) {
    return <NotFoundPage />;
  }

  if (isAccessDeniedError(error)) {
    return <div>Access denied</div>;
  }

  return <FallbackPage />;
}
