import "./SingleEntity.scss";
import { SingleBar } from "./SingleBar";
import { useTranslation } from "react-i18next";
import { useDashboardMapReportContext } from "pages/DashboardPage/DashboardMap/DashboardMapReportDataContext";
import { TooltipComponent, useGetTooltipProps } from "libs/ui/Tooltip";
import { FloatingPortal, useHover } from "@floating-ui/react";
import { useCallback, useReducer } from "react";
import { NoData } from "./NoData";

interface SingleEntityProps {
  totalPatients: number;
  patientWithRisk: number;
  maxPatientsNumber: number;
  title: string;
}

export function SingleEntity({
  totalPatients,
  patientWithRisk,
  maxPatientsNumber,
  title,
}: SingleEntityProps) {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.SingleEntity" });
  const bars = ["primary", "secondary"] as const;
  const { selectedReportType } = useDashboardMapReportContext();
  const [showTooltip, setShowTooltip] = useReducer(prev => !prev, false);

  const {
    x,
    y,
    refs,
    strategy,
    arrowRef,
    arrowX,
    arrowY,
    open,
    getReferenceProps,
    getFloatingProps,
  } = useGetTooltipProps({
    interactionProps: [useHover],
    placement: "right-end",
    crossAxis: 0,
    mainAxis: 4,
  });

  const getTooltipStatsData = useCallback(
    (type: "primary" | "secondary") => {
      switch (type) {
        case "primary":
          return totalPatients || t("NA");

        case "secondary":
          if (totalPatients === 0 || Number.isNaN(totalPatients) || Number.isNaN(patientWithRisk)) {
            return t("NA");
          }

          return patientWithRisk;
        default:
          throw new Error(`${type} is not a valid type`);
      }
    },
    [patientWithRisk, t, totalPatients],
  );

  const getTooltipKey = useCallback(
    (type: "primary" | "secondary") => {
      switch (type) {
        case "primary":
          return "totalPatients";

        case "secondary":
          return selectedReportType;

        default:
          throw new Error(`${type} is not a valid type`);
      }
    },
    [selectedReportType],
  );

  return (
    <div className="SingleEntity">
      <div ref={refs.setReference} {...getReferenceProps()} className="SingleEntity__title">
        <p onMouseEnter={setShowTooltip} onMouseLeave={setShowTooltip}>
          {title}
        </p>
      </div>

      {showTooltip ? (
        <FloatingPortal>
          <TooltipComponent
            type="grey"
            ref={refs.setFloating}
            x={x}
            y={y}
            floatingProps={getFloatingProps()}
            open={open}
            strategy={strategy}
            arrowRef={arrowRef}
            arrowX={arrowX}
            arrowY={arrowY}
            arrowSize="small"
            className="SingleEntity__tooltip"
          >
            {title}
          </TooltipComponent>
        </FloatingPortal>
      ) : null}

      {totalPatients > 0 ? (
        <div className="SingleEntityBars">
          {bars.map(bar => (
            <SingleBar
              key={bar}
              type={bar}
              maxNumber={maxPatientsNumber}
              title={title}
              number={bar === "primary" ? totalPatients : patientWithRisk}
              tooltipKey={getTooltipKey(bar)}
              tooltipStats={getTooltipStatsData(bar)}
            />
          ))}
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
}
