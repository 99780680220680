import * as React from "react";
import { SVGProps } from "react";
const SvgMicOff = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#mic-off_svg__a)" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.53.47A.75.75 0 0 0 .47 1.53l7.78 7.78V12a3.75 3.75 0 0 0 5.816 3.127l1.82 1.82A6.25 6.25 0 0 1 5.75 12.005V10a.75.75 0 1 0-1.5 0v1.997a7.75 7.75 0 0 0 7 7.768v2.485H8a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5h-3.25v-2.485a7.738 7.738 0 0 0 4.202-1.752l5.518 5.517a.75.75 0 1 0 1.06-1.06l-22-22ZM9.75 12v-1.19l3.218 3.219a2.256 2.256 0 0 1-1.829.047A2.25 2.25 0 0 1 9.75 12Z"
      />
      <path d="M15.5 8.669a.75.75 0 0 1-1.5 0V4.75a2.25 2.25 0 0 0-4.047-1.355c-.29.387-.943.594-1.285.253-.222-.223-.293-.562-.131-.832A3.75 3.75 0 0 1 15.5 4.75v3.919ZM19 9.25a.75.75 0 0 1 .75.75v2c0 .457-.041.913-.122 1.362a.75.75 0 0 1-1.476-.264c.065-.363.098-.73.098-1.099V10a.75.75 0 0 1 .75-.75Z" />
    </g>
    <defs>
      <clipPath id="mic-off_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMicOff;
