import { CommonProblemDetailsModel } from "api/event/models";
import { toastStore } from "config/toast";

export function showBeFieldErrors(errorModel: CommonProblemDetailsModel, fallbackMsg?: string) {
  if (Array.isArray(errorModel.errors)) {
    toastStore.pushToast({
      // expire: 5000,
      type: "error",
      msg: errorModel.errors.flatMap(err => {
        return err.message ?? "";
      }),
    });
    return;
  }

  if (fallbackMsg) {
    toastStore.pushToast({
      expire: 5000,
      type: "error",
      msg: fallbackMsg,
    });
  }
}
