import * as React from "react";
import { SVGProps } from "react";
const SvgList = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 5.75A.75.75 0 0 1 5.75 5h13a.75.75 0 0 1 0 1.5h-13A.75.75 0 0 1 5 5.75ZM5 11.75a.75.75 0 0 1 .75-.75h13a.75.75 0 0 1 0 1.5h-13a.75.75 0 0 1-.75-.75ZM5.75 17a.75.75 0 0 0 0 1.5h13a.75.75 0 0 0 0-1.5h-13Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgList;
