/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DefaultValuesModel } from './DefaultValuesModel';
import {
    DefaultValuesModelFromJSON,
    DefaultValuesModelFromJSONTyped,
    DefaultValuesModelToJSON,
} from './DefaultValuesModel';
import type { DisabledModel } from './DisabledModel';
import {
    DisabledModelFromJSON,
    DisabledModelFromJSONTyped,
    DisabledModelToJSON,
} from './DisabledModel';
import type { TablesModel } from './TablesModel';
import {
    TablesModelFromJSON,
    TablesModelFromJSONTyped,
    TablesModelToJSON,
} from './TablesModel';

/**
 * 
 * @export
 * @interface ComponentsModel
 */
export interface ComponentsModel {
    /**
     * 
     * @type {DisabledModel}
     * @memberof ComponentsModel
     */
    disabled: DisabledModel;
    /**
     * 
     * @type {DefaultValuesModel}
     * @memberof ComponentsModel
     */
    defaultValues: DefaultValuesModel;
    /**
     * 
     * @type {TablesModel}
     * @memberof ComponentsModel
     */
    tables: TablesModel;
}

/**
 * Check if a given object implements the ComponentsModel interface.
 */
export function instanceOfComponentsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "disabled" in value;
    isInstance = isInstance && "defaultValues" in value;
    isInstance = isInstance && "tables" in value;

    return isInstance;
}

export function ComponentsModelFromJSON(json: any): ComponentsModel {
    return ComponentsModelFromJSONTyped(json, false);
}

export function ComponentsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disabled': DisabledModelFromJSON(json['Disabled']),
        'defaultValues': DefaultValuesModelFromJSON(json['defaultValues']),
        'tables': TablesModelFromJSON(json['tables']),
    };
}

export function ComponentsModelToJSON(value?: ComponentsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Disabled': DisabledModelToJSON(value.disabled),
        'defaultValues': DefaultValuesModelToJSON(value.defaultValues),
        'tables': TablesModelToJSON(value.tables),
    };
}

