import { HcpResponseModelListModel } from "api/query";
import { Option } from "libs/ui/Select/Select.model";
import { isLoaded, LoadableModel } from "models/loadable";

export function hcpCollectionToSelectOptions(
  data: LoadableModel<HcpResponseModelListModel>,
): Option<string>[] {
  if (!isLoaded(data) || !data.value.items) {
    return [];
  }

  const hcpOptions: Option<string>[] = data.value.items.map(user => ({
    title: user.firstName.concat(` ${user.lastName}`),
    value: user.id,
  }));

  return hcpOptions;
}
