import { Button } from "libs/ui";
import { useTranslation } from "react-i18next";
import "./RuleEngineSectionActions.scss";

interface RuleEngineSectionActionsProps {
  onCancel?: () => void;
  onSave?: () => void;
  saveButtonDisabled?: boolean;
  cancelButtonDisabled?: boolean;
}

export function RuleEngineSectionActions({
  onCancel,
  onSave,
  saveButtonDisabled,
  cancelButtonDisabled,
}: RuleEngineSectionActionsProps) {
  const { t } = useTranslation("translation", { keyPrefix: "PageTemplate.Settings.rule-engine" });
  return (
    <div className="RuleEngineSectionActions">
      <Button
        data-testid={"cancel-button"}
        onClick={onCancel}
        disabled={cancelButtonDisabled}
        buttonSize="medium"
        buttonType="grey"
      >
        {t("buttonCancel")}
      </Button>
      <Button
        data-testid={"save-button"}
        onClick={onSave}
        disabled={saveButtonDisabled}
        buttonSize="medium"
        buttonType="primary"
      >
        {t("buttonSave")}
      </Button>
    </div>
  );
}
