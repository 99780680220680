import * as React from "react";
import { SVGProps } from "react";
const SvgCircleWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#circle-warning_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-10.846-.154a.923.923 0 1 1-1.846 0V6.923a.923.923 0 0 1 1.846 0v4.923ZM11 16.77c0 .68.551 1.231 1.23 1.231h.013a1.23 1.23 0 0 0 0-2.461h-.012c-.68 0-1.231.55-1.231 1.23Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="circle-warning_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCircleWarning;
