import * as React from "react";
import { SVGProps } from "react";
const SvgBookmarkFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 2a3 3 0 0 0-3 3v16a1 1 0 0 0 1.581.814L12 17.229l6.419 4.585A1 1 0 0 0 20 21V5a3 3 0 0 0-3-3H7Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgBookmarkFilled;
