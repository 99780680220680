import { Logo } from "libs/ui/Logo";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./NotFoundPage.scss";
import { Code } from "components/ErrorElements/Code";
import { ErrorButton } from "components/ErrorElements/ErrorButton";
import { ErrorFooter } from "components/ErrorElements/ErrorFooter";
import { ErrorTitle } from "components/ErrorElements/ErrorTitle";
import { Message } from "components/ErrorElements/Message";

export function NotFoundPage() {
  const { t } = useTranslation("translation", { keyPrefix: "ErrorPages.NotFoundPage" });
  const navigate = useNavigate();

  return (
    <div data-testid="not-found-page" className="NotFoundPage">
      <Logo className="NotFoundPage__logo" />
      <div className="NotFoundPage__content">
        <Code errorCode={t("error_code")} />
        <ErrorTitle>{t("title")}</ErrorTitle>
        <Message>{t("message")}</Message>
        <ErrorButton onClick={() => navigate("/")}>{t("button_text")}</ErrorButton>
      </div>
      <ErrorFooter>{t("footer", { year: new Date().getFullYear() })}</ErrorFooter>
    </div>
  );
}
