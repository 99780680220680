import * as React from "react";
import { SVGProps } from "react";
const SvgOrganization = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 7a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4ZM9.5 10.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 5a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V5Zm-2-.5H8a.5.5 0 0 0-.5.5v14a.5.5 0 0 0 .5.5h1.5V17a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v2.5H16a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5Zm-3 15v-2h-2v2h2Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgOrganization;
