import i18n from "i18next";
import { Checkbox, Input, Selection } from "libs/ui";
import React, { PropsWithChildren, ReactNode, useMemo } from "react";
import {
  AdditionalPropertyConfigField,
  CustomConfigField,
  Enum,
} from "utils/createGlobalConfigStore";

import "./DynamicFormGenerator.scss";
import { FieldType, isAdditionalProperties } from "./model";
import { CurrentFormMode } from "models/FormModeModels";
import { fieldStatusResolve } from "components/Forms/fieldStatusResolve";
import { useTranslation } from "react-i18next";

function getInputType(TypeName: string, enums: CustomConfigField["enums"]): FieldType {
  const [, textInput, arrayLike] = TypeName.match(/^(.*?)(\[\])?$/) ?? [];

  if (arrayLike && Object.keys(enums).includes(textInput)) {
    const options = (enums as any)?.[textInput].map((e: Enum) => ({
      title: e["N"],
      value: e["V"],
    }));

    return { type: "CheckList", options: options };
  }

  if (textInput !== "string" && Object.keys(enums).includes(textInput)) {
    const options = (enums as any)[textInput].map((e: Enum) => ({ title: e["N"], value: e["V"] }));

    return { type: "SelectBox", options: options };
  }

  if (textInput === "string") {
    return { type: "TextInput" };
  }

  return { type: "NotSupported" };
}

interface GenerateInputProps {
  addProp: AdditionalPropertyConfigField;
  key: string;
  formState: Record<string, any>;
  onChange: (value: any, inputKey: any) => void;
  fieldType: FieldType;
  inputType: CurrentFormMode;
  t: TFunction;
  label: string;
  isReadonly: boolean;
}

function GenerateInput({
  addProp,
  key,
  formState,
  onChange,
  fieldType,
  inputType,
  t,
  label,
  isReadonly,
}: Readonly<GenerateInputProps>) {
  const propName = addProp.propertyName;

  const currentField = fieldStatusResolve({
    formMode: inputType,
    isReadonly,
    field: addProp,
    value: formState[propName],
  });

  function getLabel(label: string) {
    if (currentField.optional) {
      return label + " " + i18n.t("Form.optional", { ns: "translation" });
    }
    return label;
  }

  if (currentField.show === false) {
    return null;
  }

  switch (fieldType.type) {
    case "NotSupported":
      throw new Error(`NOT SUPPORTED ELEMENT`, {
        cause: JSON.stringify({
          currentField,
          key,
          propName,
          formState,
          onChange,
          fieldType,
          inputType,
        }),
      });
    case "CheckList": {
      /**
       * !!! currently we are trying to create array from strings in `apiQuery` via `convertStringsToArrays`
       * but if the checked value is only one, there will be no comma `( , )` in the string to split by
       * meaning we are not going to get the array format back, which is fine for `SelectBox` and `TextInput`
       * but for `CheckList` we need to have the array all the time
       *
       * Here we are making a fake copy of the needed array to make `CheckList` component work properly
       * when there is only one option checked
       *
       * ! if there are more options checked, the component works properly without this fake creation of
       * an array
       */

      const copyStateArray =
        formState[propName] === undefined
          ? []
          : Array.isArray(formState[propName])
            ? formState[propName]
            : Array.of(formState[propName]);

      return (
        <div
          data-form-type="Dynamic"
          data-form-element={fieldType.type}
          data-form-element-visibility={currentField.visibility}
          className="CheckList"
          key={propName}
        >
          <label className="Input__label Input__label--active">{getLabel(label)}</label>
          <div className="CheckListBody">
            <React.Fragment key={propName}>
              {fieldType.options.map(checkboxProps => {
                if (currentField.readonly) {
                  return copyStateArray.includes(String(checkboxProps.value)) ? (
                    <div className="CheckList__option" key={checkboxProps.value}>
                      <Checkbox
                        key={checkboxProps.value}
                        label={checkboxProps.title}
                        readOnly={currentField.readonly}
                        checked={!!copyStateArray.includes(String(checkboxProps.value))}
                        value={checkboxProps.value ?? ""}
                      />
                    </div>
                  ) : null;
                }

                return (
                  <div className="CheckList__option" key={checkboxProps.value}>
                    <Checkbox
                      key={checkboxProps.value}
                      label={checkboxProps.title}
                      checked={!!copyStateArray.includes(String(checkboxProps.value))}
                      value={checkboxProps.value ?? ""}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const currentToggles = new Set<string>(copyStateArray);
                        currentToggles.has(e.target.value)
                          ? currentToggles.delete(e.target.value)
                          : currentToggles.add(e.target.value);

                        onChange(
                          {
                            ...formState,
                            [propName]: [...currentToggles.values()],
                          },
                          "additionalProperties",
                        );
                      }}
                    />
                  </div>
                );
              })}

              {currentField.showNA ? <p className="CheckList--no-value">{t("na")}</p> : null}
            </React.Fragment>
          </div>
        </div>
      );
    }
    case "SelectBox":
      return (
        <div
          data-form-type="Dynamic"
          data-form-element={fieldType.type}
          data-form-element-visibility={currentField.visibility}
          className="Form__field"
          key={propName}
        >
          <Selection
            data-testid="SelectBox"
            key={key}
            options={fieldType.options}
            label={getLabel(label)}
            value={
              currentField.showNA
                ? t("na")
                : fieldType.options.find(el => el.value === Number(formState[propName]))?.value ??
                  ""
            }
            onSelect={
              value =>
                onChange({ ...formState, [propName]: value.toString() }, "additionalProperties")
              // setForm(currentForm => ({
              //   ...currentForm,
              //   additionalProps: {
              //     ...currentForm["additionalProps"],
              //     [propName]: value,
              //   },
              // }))
            }
            readOnly={currentField.readonly}
            showNa={currentField.showNA}
          />
        </div>
      );
    case "TextInput":
      return (
        <div
          data-form-type="Dynamic"
          data-form-element={fieldType.type}
          data-form-element-visibility={currentField.visibility}
          className="Form__field"
          key={propName}
        >
          <Input
            key={key}
            optional={currentField.optional}
            label={label}
            value={currentField.showNA ? t("na") : formState[propName] ?? ""}
            onChange={
              (e: React.ChangeEvent<HTMLInputElement>) =>
                onChange({ ...formState, [propName]: e.target.value }, "additionalProperties")

              // setForm(currentForm => ({
              //   ...currentForm,
              //   additionalProps: {
              //     ...currentForm["additionalProps"],
              //     [propName]: e.target.value,
              //   },
              // }))
            }
            readOnly={currentField.readonly}
          />
        </div>
      );

    default:
      return null;
  }
}

interface CustomFormProps {
  additionalProps?: AdditionalPropertyConfigField[];
  custom?: CustomConfigField;
  formState: Record<string, any>;
  onChange: (value: any, inputKey: any) => void;
  formMode: CurrentFormMode;
  isReadonly: boolean;
}

// export const DynamicFormGenerator = forwardRef<CustomFormForwardProps, CustomFormProps>(
export const DynamicFormGenerator = ({
  additionalProps,
  custom,
  formState,
  onChange,
  formMode,
  isReadonly,
}: PropsWithChildren<CustomFormProps>) => {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  const { t: label } = useTranslation("additionalFields", { keyPrefix: "additionalFields.label" });
  /**
   * TODO all this could be faster with refs, unfortunately <Selection doesnt support it yet
   */
  // const formState = useState({} as Record<string, any>);

  // useImperativeHandle(
  //   ref,
  //   () => ({
  //     formState,
  //   }),
  //   [formState],
  // );

  const fields = useMemo(() => {
    // Dont show the form in case of empty props
    if (
      additionalProps === undefined ||
      (Array.isArray(additionalProps) && additionalProps.length === 0)
    ) {
      return null;
    }

    const arrElems: ReactNode[] = [];
    const enums = custom?.enums ?? {};

    for (const addProp of additionalProps) {
      /**
       * ! EDGE CASES
       * TODO context.Update can be "OPTIONAL" while context.Add is "REQUIRED" & vice versa
       * TODO context.Add = undefined but context.Update is not
       */
      // ! Disabled = from BE it means not visible, thus we don't render
      // ! Editable field = false, Visibility = True => means disabled

      if (isAdditionalProperties(addProp)) {
        const key = `${addProp.propertyName}`;

        const Element = GenerateInput({
          addProp,
          key,
          formState,
          onChange,
          fieldType: getInputType(addProp.typeName, enums),
          inputType: formMode,
          t,
          label: label(addProp.propertyName),
          isReadonly,
        });

        arrElems.push(Element);
      }
    }

    return arrElems;
    //TODO  We can avoid this dependency if we use refs, <Selection
  }, [additionalProps, custom?.enums, formMode, formState, isReadonly, label, onChange, t]);

  return <>{fields}</>;
};
