import { Breakpoint } from "components/DnD";
import {
  HEAT_MAP_RESULTS_WIDGET_BASE_LAYOUT,
  MEASUREMENT_WIDGET_BASE_LAYOUT,
  MEASUREMENT_WIDGET_DEFAULT_W_SIZE,
} from "./layoutModels";
import { StatsWidgetType } from "./model";
import { Layout } from "react-grid-layout";

const TWO_MEASUREMENT_WIDGET_COL_SPACE = 2 * MEASUREMENT_WIDGET_DEFAULT_W_SIZE;

function getHeatMapLayout(breakpoint: Breakpoint): Layout {
  const colNum = Number(breakpoint.split("col-")[1]);
  switch (breakpoint) {
    case "col-12":
    case "col-11":
    case "col-10":
      return {
        ...HEAT_MAP_RESULTS_WIDGET_BASE_LAYOUT,
        w: colNum - TWO_MEASUREMENT_WIDGET_COL_SPACE,
      };

    case "col-9":
      return { ...HEAT_MAP_RESULTS_WIDGET_BASE_LAYOUT, w: 5 };
    case "col-8":
      return { ...HEAT_MAP_RESULTS_WIDGET_BASE_LAYOUT, w: 4 };

    case "col-7":
    case "col-6":
    case "col-5":
    case "col-4":
    case "col-3":
      return { ...HEAT_MAP_RESULTS_WIDGET_BASE_LAYOUT, x: 0, y: 0, w: colNum };

    default:
      throw new Error(`Breakpoint ${breakpoint} not covered in HeatMapResult layout mapping`);
  }
}

function getMeasurementLayoutWithTwoInRowOption(i: StatsWidgetType): Layout {
  switch (i) {
    case "SerumCreatinine":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 0, y: 0 };
    case "Egfr":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 2, y: 0 };
    case "Bmi":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 0, y: 4 };
    case "Glucose":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 2, y: 3 };
    case "Height":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 0, y: 5 };
    case "Weight":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 2, y: 4 };
    case "BloodPressure":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 0, y: 3 };
    case "UrineCreatinine":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 0, y: 2 };
    case "UrineAlbumin":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 2, y: 2 };
    case "Uacr":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 0, y: 1 };
    case "SemiQuantitativeUacr":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 2, y: 1 };
    default:
      throw new Error(`${i} not covered in two in row option grid layout`);
  }
}

function getMeasurementLayout6and7Col(i: StatsWidgetType): Layout {
  switch (i) {
    case "SerumCreatinine":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 0, y: 4 };
    case "Egfr":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 2, y: 4 };
    case "Bmi":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 4, y: 6 };
    case "Glucose":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 2, y: 6 };
    case "Height":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 0, y: 7 };
    case "Weight":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 2, y: 7 };
    case "BloodPressure":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 0, y: 6 };
    case "UrineCreatinine":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 0, y: 5 };
    case "UrineAlbumin":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 2, y: 5 };
    case "Uacr":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 4, y: 4 };
    case "SemiQuantitativeUacr":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 4, y: 5 };
    default:
      throw new Error(`${i} not covered in two in row option grid layout`);
  }
}

function getMeasurementWidgetLayout(
  i: StatsWidgetType,
  breakpoint: Breakpoint,
  currentLayout: Layout[],
  index: number,
): Layout {
  switch (breakpoint) {
    case "col-12":
    case "col-11":
    case "col-10":
    case "col-9":
    case "col-8":
    case "col-5":
    case "col-4":
      return getMeasurementLayoutWithTwoInRowOption(i);

    case "col-7":
    case "col-6":
      return getMeasurementLayout6and7Col(i);

    case "col-3":
      return { ...MEASUREMENT_WIDGET_BASE_LAYOUT, i, x: 0, y: 1, w: 3 };

    default:
      throw new Error(`Breakpoint ${breakpoint} not covered in MeasurementWidget layout mapping`);
  }
}

function mapToWidgetLayout(
  item: StatsWidgetType,
  breakpoint: Breakpoint,
  currentLayout: Layout[],
  index: number,
): Layout {
  switch (item) {
    case "HeatMap":
      return getHeatMapLayout(breakpoint);
    case "SerumCreatinine":
    case "Egfr":
    case "Uacr":
    case "SemiQuantitativeUacr":
    case "UrineCreatinine":
    case "UrineAlbumin":
    case "Glucose":
    case "BloodPressure":
    case "Bmi":
    case "Weight":
    case "Height":
      return getMeasurementWidgetLayout(item, breakpoint, currentLayout, index);
    default:
      throw new Error(`${item} type case not implemented`);
  }
}

export function getUpdatedLayout(
  currentLayout: Layout[],
  addedWidgetTypes: StatsWidgetType[],
  breakpoint: Breakpoint,
) {
  const layout = addedWidgetTypes.map((item, index) => {
    const alreadyCreated = currentLayout.find(el => el.i === item);
    return alreadyCreated
      ? alreadyCreated
      : mapToWidgetLayout(item, breakpoint, currentLayout, index);
  });

  return layout;
}
