import "./SelectFilterAsync.scss";

import classNames from "classnames";
import { isEqual } from "lodash-es";
import { defaultModel, isDefaultModel, isFailed, isLoaded, LoadableModel } from "models/loadable";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSubscribe } from "utils/hooks/useSubscribe";

import { Dropdown } from "../Dropdown";
import { FieldValidation } from "../FieldValidation";
import { useOnClickOutside } from "../hooks";
import { Input } from "../Input";
import { Loader } from "../Loader";
import { SelectOption, SelectOptionProps } from "../SelectOption";
import {
  ResetSelectedOptionProps,
  SelectFilterAsyncOption,
  SelectFilterAsyncProps,
} from "./SelectFilterAsync.model";

export const SelectFilterAsync = forwardRef<ResetSelectedOptionProps, SelectFilterAsyncProps<any>>(
  <T,>(
    {
      getOptions,
      onSelect,
      disabled,
      className,
      dropdownFloatingProps,
      readOnly,
      validation,
      renderSelected,
      renderOption,
      noDataComponent,
      customDropdownContent,
      ...rest
    }: SelectFilterAsyncProps<T>,
    ref: any,
  ) => {
    const { t } = useTranslation("translation", { keyPrefix: "ui-components.SelectFilterAsync" });

    const selectRef = useRef<HTMLDivElement>(null);

    const filterInputRef = useRef<HTMLInputElement | null>(null);

    const [options, setOptions] =
      useState<LoadableModel<SelectFilterAsyncOption<T>[]>>(defaultModel());
    const [selectedOption, setSelectedOption] = useState<T | undefined>();

    const [showOptionsDropdown, setShowOptionsDropdown] = useState(false);

    const [showDropDownLoading, setShowDropDownLoading] = useState(false);
    const timerRef = useRef<any>();

    const [filterCallback, pipe] = getOptions;

    const noDataOnActiveFilter =
      isLoaded(options) &&
      options.value.length === 0 &&
      !readOnly &&
      !disabled &&
      !!noDataComponent;

    const setSelectedOptionWithParent = useCallback(
      (option: T | undefined) => {
        setSelectedOption(prevOption => option ?? prevOption);

        // notify anybody else
        if (onSelect) {
          onSelect(option);
        }
      },
      [onSelect],
    );

    const clearSelectedOptionWithParent = useCallback(() => {
      setSelectedOption(undefined);

      // notify anybody else
      if (onSelect) {
        onSelect(undefined);
      }
    }, [onSelect]);

    const onOptionSelect = useCallback<NonNullable<SelectOptionProps<T>["onSelect"]>>(
      (value_: T, e) => {
        e.stopPropagation();

        setShowOptionsDropdown(false);
        setSelectedOptionWithParent(value_);
      },
      [setSelectedOptionWithParent],
    );

    const handleOptionsFetch = useCallback(
      ([incomingData, compareTo]: [
        data: LoadableModel<SelectFilterAsyncOption<T>[]>,
        compareTo?: string,
      ]) => {
        setShowDropDownLoading(false);
        clearTimeout(timerRef.current);

        setOptions(incomingData);

        if (isLoaded(incomingData)) {
          const dataList = incomingData.value;
          const foundData = dataList.find(
            data =>
              // Maybe we don't need to compare to filterinput at all in this case..
              data.title.toLowerCase() ===
              (compareTo ?? filterInputRef.current!.value.toLowerCase()),
          );

          // Preselect if we found our value on the list
          if (foundData !== undefined) {
            setShowOptionsDropdown(false);

            setSelectedOptionWithParent(foundData.value);
          }
        }
      },
      [setSelectedOptionWithParent],
    );

    useSubscribe(pipe, handleOptionsFetch);

    // INIT
    useEffect(() => {
      if (disabled || readOnly) {
        return;
      }

      /**
       * Nicer user experience
       * if we prefetch and you already have the list on click
       */
      filterCallback("", "");
    }, [disabled, filterCallback, readOnly]);

    useEffect(() => {
      if (isDefaultModel(options) && (disabled || readOnly) === false) {
        filterCallback("", "");
      }
    }, [disabled, filterCallback, options, readOnly]);

    const hideDropdown = useCallback(() => {
      setShowOptionsDropdown(false);
    }, []);

    // ! TODO
    // const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    //   // ! TODO don't show dropdown if we have already one element preselected
    //   if (isKeyboardClickKey(e.key)) {
    //     e.preventDefault();
    //     // toggleDropdown();
    //   }
    // };

    useOnClickOutside(selectRef, showOptionsDropdown, hideDropdown);

    const onFilterChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
      e => {
        /**
         * We should get off the loading state
         */
        setOptions(defaultModel());

        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          setShowDropDownLoading(true);
        }, 1300);

        filterCallback(e.target.value);
      },
      [filterCallback],
    );

    const onFocus = useCallback(() => {
      if (isLoaded(options) && selectedOption !== undefined) {
        return;
      }

      if (showOptionsDropdown === false) {
        setShowOptionsDropdown(true);
        setTimeout(() => {
          filterInputRef.current?.focus();
        }, 1);
      }
    }, [options, selectedOption, showOptionsDropdown]);

    const onClick = useCallback(
      (e: any) => {
        if (isLoaded(options) && selectedOption !== undefined) {
          /**
           * The prev list if user entered something will reload
           * having this flickering effect
           * it is better, to clear it
           */
          if (filterInputRef.current!.value !== "") {
            setOptions(defaultModel());
            filterInputRef.current!.value = "";
            filterCallback("");

            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
              setShowDropDownLoading(true);
            }, 1300);
          }

          setShowOptionsDropdown(true);
          setTimeout(() => {
            filterInputRef.current!.focus();
          }, 1);
        }
      },
      [filterCallback, options, selectedOption],
    );

    useImperativeHandle(ref, () => {
      return {
        clearSelectedOption: () => {
          clearSelectedOptionWithParent();
        },
      };
    }, [clearSelectedOptionWithParent]);

    if (renderOption && customDropdownContent) {
      throw new Error("You can't use both renderOption and customDropdownContent");
    }

    const DropDownContent = useMemo(() => {
      if (customDropdownContent) {
        return customDropdownContent({
          showOptionsDropdown,
          inputRef: filterInputRef,
          onFilterChange,
          showDropDownLoading,
          options,
          selectRef,
          selectedOption,
          onOptionSelect,
          clearSelectedOptionWithParent,
          setShowOptionsDropdown,
        });
      }

      return (
        <>
          <Input
            data-testid={"SelectFilterAsync-search"}
            className="SelectFilterAsync__search"
            inputIcon={{ icon: "Search", iconPosition: "leading" }}
            inputRef={filterInputRef}
            placeholder={t("search")}
            onChange={onFilterChange}
          />
          {showDropDownLoading ? (
            <Loader loading />
          ) : (
            isLoaded(options) && (
              <div className="SelectFilterAsync__options">
                {options.value.map(({ title, value }, index) => (
                  <SelectOption<T>
                    render={renderOption}
                    key={`${index}`}
                    isActive={isEqual(selectedOption, value)}
                    onSelect={onOptionSelect}
                    title={title}
                    value={value}
                  />
                ))}
                {noDataOnActiveFilter ? noDataComponent : null}
              </div>
            )
          )}
        </>
      );
    }, [
      clearSelectedOptionWithParent,
      customDropdownContent,
      noDataComponent,
      noDataOnActiveFilter,
      onFilterChange,
      onOptionSelect,
      options,
      renderOption,
      selectedOption,
      showDropDownLoading,
      showOptionsDropdown,
      t,
    ]);

    return (
      <div
        data-testid="SelectFilterAsync"
        data-status={options.state}
        data-readonly={readOnly}
        data-disabled={disabled}
        ref={selectRef}
        className={classNames("SelectFilterAsync", className, {
          "SelectFilterAsync--hasValue": !!selectedOption,
        })}
        // without this, you can't really focus the element
        // TODO forward from props
        // tabIndex={1}
        {...(!(readOnly || disabled) ? { onFocus, onClick, tabIndex: 0 } : undefined)}
        {...rest}
      >
        {renderSelected(selectedOption)}
        <FieldValidation
          data-testid="SelectFilterAsync-validation"
          infoText={isFailed(options) ? undefined : validation?.infoText}
          errorText={isFailed(options) ? (validation?.errorText ?? t("failedToFetch")) : undefined}
        />
        <Dropdown
          data-testid="SelectFilterAsync-dropdown"
          show={showOptionsDropdown}
          targetElement={() => selectRef.current}
          floatingProps={dropdownFloatingProps}
        >
          {DropDownContent}
        </Dropdown>
      </div>
    );
  },
);
