import { MIN_LENGTH_PASSWORD_CHAR_COUNT } from "config/const";

export function passwordStrength(password: string) {
  return {
    minLength: password.length >= MIN_LENGTH_PASSWORD_CHAR_COUNT,
    atLeastOneUppercase: /[A-Z]/.test(password),
    atLeastOneNumber: /\d/.test(password),
    atLeastOneSpecial: /[^A-Za-z0-9]/.test(password),
  } as const;
}
