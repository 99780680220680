import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { PatientsTable } from "../common/PatientsTable";
import { usePatientsTableStateContext } from "./context";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

export function AllPatientsTable() {
  const tableContext = usePatientsTableStateContext();
  const { appConfig } = useGlobalConfigContext();

  return (
    <PatientsTable
      appliedColumns={NotNullOrUndefined(appConfig?.components.tables.Patient.Index.columnList)}
      patientsTableStateContext={tableContext}
      patientsFilterOptions={appConfig?.components.tables.Patient.Index.filterOptions}
    />
  );
}
