import { UrineCreatinineLimitsModel } from "api/config_service/models/UrineCreatinineLimitsModel";
import { GenderType } from "api/query";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useCallback } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

type UrineCreatinineLimitKey = "Low" | "Normal" | "High";

interface SingleStageRange {
  from?: number;
  to?: number;
}

export type UrineCreatinineStageRanges = {
  [key in UrineCreatinineLimitKey]: SingleStageRange;
};

function getRowRanges(data: any, key: UrineCreatinineLimitKey) {
  if (Object.hasOwn(data, key) === false) {
    throw new Error(`${data} has no ${key} key`);
  }

  switch (key) {
    case "Low":
      return { to: data[key].value } as const;

    case "Normal":
      return { from: data[key].from, to: data[key].to } as const;

    case "High":
      return { from: data[key].value } as const;

    default:
      throw new Error(`${key} not covered`);
  }
}

const orderedStages: UrineCreatinineLimitKey[] = ["Low", "Normal", "High"];

export function useGetUrineCreatinineRanges() {
  const { appConfig } = useGlobalConfigContext();

  const getUrineCreatinineRanges = useCallback(
    (gender?: GenderType) => {
      let orderedUrineCreatinineStageRanges: UrineCreatinineStageRanges = {
        Low: {},
        Normal: {},
        High: {},
      };

      if (!gender) {
        return orderedUrineCreatinineStageRanges;
      }

      const urineCreatinineLimits = NotNullOrUndefined(
        appConfig?.laboratory.measurements.limits?.urine.creatinine[
          gender.toLowerCase() as keyof UrineCreatinineLimitsModel
        ].limits,
      );

      orderedStages.forEach(stage => {
        orderedUrineCreatinineStageRanges = {
          ...orderedUrineCreatinineStageRanges,
          [stage]: getRowRanges(urineCreatinineLimits, stage),
        };
      });

      return orderedUrineCreatinineStageRanges;
    },
    [appConfig?.laboratory.measurements.limits?.urine.creatinine],
  );

  const getRangePoints = useCallback(
    (gender?: GenderType) => {
      if (!gender) {
        return [0, 0];
      }

      const set = new Set(
        Object.values(getUrineCreatinineRanges(gender))
          .flatMap(el => [el.from, el.to]) // can get [undefined, NUMBER] or [NUMBER, undefined]
          .filter(el => typeof el === "number") as number[], // typescript is stupid
      );

      return Array.from(set);
    },
    [getUrineCreatinineRanges],
  );

  return [getUrineCreatinineRanges, getRangePoints] as const;
}
