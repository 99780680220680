import classNames from "classnames";
import { Button } from "libs/ui";
import { Children, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "./DropDownToggler.scss";

interface DropDownTogglerProps {
  title: string;
  isExpandedOnInit?: boolean;
  expandChildrenCount?: number;
  className?: string;
}

export function DropDownToggler({
  title,
  children,
  expandChildrenCount,
  isExpandedOnInit,
  className,
}: PropsWithChildren<DropDownTogglerProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "ui-components.DropDownToggler" });

  const [isExpanded, setIsExpanded] = useState(() => isExpandedOnInit ?? true);
  const [showFullContent, setShowFullContent] = useState(() => expandChildrenCount === undefined);

  const renderShowAllOptionsButton = useMemo(
    () =>
      expandChildrenCount !== undefined ? Children.count(children) > expandChildrenCount : false,
    [children, expandChildrenCount],
  );

  const toggleContent = useCallback(() => {
    if (renderShowAllOptionsButton && showFullContent) {
      setShowFullContent(false);
    }
    setIsExpanded(prevValue => !prevValue);
  }, [renderShowAllOptionsButton, showFullContent]);

  const toggleFullContent = useCallback(() => setShowFullContent(prevValue => !prevValue), []);

  return (
    <div className={classNames("DropDownToggler", className)}>
      <div className="DropDownToggler__header">
        <p className="DropDownToggler__title">{title}</p>

        <Button
          onClick={toggleContent}
          buttonSize="small"
          buttonType="transparent"
          buttonIcon={{ icon: isExpanded ? "ChevronUp" : "ChevronDown" }}
        />
      </div>

      <div
        className={classNames("DropDownToggler__content", {
          "DropDownToggler__content--collapsed": isExpanded === false,
          "DropDownToggler__content--full": renderShowAllOptionsButton && showFullContent,
        })}
      >
        {children}
      </div>

      {isExpanded && renderShowAllOptionsButton ? (
        <Button
          className="FullContentToggler"
          onClick={toggleFullContent}
          buttonSize="medium"
          buttonType="link-grey"
        >
          {t(showFullContent ? "showLess" : "showAllButton")}
        </Button>
      ) : null}
    </div>
  );
}
