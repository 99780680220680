import { FilterSection } from "components/AppTables/common/Filter/FilterSection";
import { TableFilter } from "components/AppTables/common/Filter/TableFilter";
import { useTranslation } from "react-i18next";
import { OrganizationsTableFilterProps, ORGANIZATIONS_FILTER_OPTIONS } from "../context";
import { StatesFilterSection } from "components/AppTables/common/Filter/StatesFilterSection";
import { DropDownToggler } from "libs/ui/DropDownToggler";
import React from "react";
import { SideModalElements } from "libs/ui/SideModal";

export function OrganizationsTableFilter({
  tableService,
  filterSideModelState,
}: Readonly<OrganizationsTableFilterProps>) {
  const { t: tColumns } = useTranslation("translation", {
    keyPrefix: "OrganizationsTable.columns",
  });

  return (
    <TableFilter tableService={tableService} filterSideModelState={filterSideModelState}>
      {({ activeFilters, appliedFilters, onNewFilterApplied, onSingleFilterClear }) => (
        <>
          {activeFilters.map((el, index) => {
            if (el === "contains") {
              return null;
            }

            // states can't be standalone filter
            if (el === "states") {
              return null;
            }

            if (el === "countries") {
              return (
                <DropDownToggler
                  isExpandedOnInit={(appliedFilters["countries"] ?? []).length > 0}
                  title={tColumns("countries")}
                  expandChildrenCount={5}
                  key={el}
                >
                  <FilterSection
                    options={ORGANIZATIONS_FILTER_OPTIONS()["countries"]}
                    checkedValues={appliedFilters["countries"] ?? []}
                    onFilterApply={value => onNewFilterApplied("countries", value)}
                    withSearch={true}
                  />

                  <StatesFilterSection
                    checkedValues={appliedFilters["states"] ?? []}
                    countries={appliedFilters["countries"] ?? []}
                    onFilterApply={value => onNewFilterApplied("states", value)}
                    onSingleFilterClear={() => onSingleFilterClear("states")}
                  />
                </DropDownToggler>
              );
            }

            return (
              <React.Fragment key={el}>
                <DropDownToggler
                  isExpandedOnInit={((appliedFilters[el] as Array<any>) ?? []).length > 0}
                  title={tColumns(el)}
                  expandChildrenCount={5}
                >
                  <FilterSection
                    title={tColumns(el)}
                    options={ORGANIZATIONS_FILTER_OPTIONS()[el]}
                    checkedValues={appliedFilters[el] ?? []}
                    onFilterApply={value => onNewFilterApplied(el, value)}
                  />
                </DropDownToggler>
                {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
              </React.Fragment>
            );
          })}
        </>
      )}
    </TableFilter>
  );
}
