import { RegionType } from "api/query/models/RegionType";
import i18n from "i18next";

export function regionTypeToSelectOptions() {
  const regionTypes = Object.keys(RegionType);

  return regionTypes.map(key => ({
    title: i18n.t(`RegionType.${key}`, { ns: "region" }),
    value: RegionType[key as keyof typeof RegionType],
  }));
}
