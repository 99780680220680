import classNames from "classnames";
import React, { forwardRef, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Icon, isIconType } from "../Icon";
import "./Button.scss";
import { ButtonProps } from "./model";

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    { buttonType, buttonSize, buttonIcon, className, loading = false, active, children, ...props },
    ref,
  ) => {
    const { t } = useTranslation("translation", { keyPrefix: "ui-components.Button" });
    const { icon, iconPosition = "leading" } = buttonIcon ?? {};

    const hasChildren = !!React.Children.count(children);

    return (
      <button
        {...props}
        ref={ref}
        className={classNames(
          "UI-Components Button",
          `Button--${buttonType} Button--${buttonSize}`,
          { [`Button--${buttonType}-active`]: active },
          className,
        )}
      >
        {loading ? (
          <>
            <span className="Loading" />
            {t("loading")}
          </>
        ) : (
          <>
            {iconPosition === "leading" && icon && isIconType(icon) ? (
              <Icon {...(hasChildren && { className: "Icon--leading" })} icon={icon} />
            ) : null}
            {children}
            {iconPosition === "trailing" && icon && isIconType(icon) ? (
              <Icon {...(hasChildren && { className: "Icon--trailing" })} icon={icon} />
            ) : null}
          </>
        )}
      </button>
    );
  },
);
