/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalPropertyDefinitionModel } from './AdditionalPropertyDefinitionModel';
import {
    AdditionalPropertyDefinitionModelFromJSON,
    AdditionalPropertyDefinitionModelFromJSONTyped,
    AdditionalPropertyDefinitionModelToJSON,
} from './AdditionalPropertyDefinitionModel';
import type { EmailModel } from './EmailModel';
import {
    EmailModelFromJSON,
    EmailModelFromJSONTyped,
    EmailModelToJSON,
} from './EmailModel';
import type { NationalityTypeModel } from './NationalityTypeModel';
import {
    NationalityTypeModelFromJSON,
    NationalityTypeModelFromJSONTyped,
    NationalityTypeModelToJSON,
} from './NationalityTypeModel';
import type { ZipCodeModel } from './ZipCodeModel';
import {
    ZipCodeModelFromJSON,
    ZipCodeModelFromJSONTyped,
    ZipCodeModelToJSON,
} from './ZipCodeModel';

/**
 * 
 * @export
 * @interface HcpModel
 */
export interface HcpModel {
    /**
     * 
     * @type {Array<AdditionalPropertyDefinitionModel>}
     * @memberof HcpModel
     */
    additionalProperties: Array<AdditionalPropertyDefinitionModel>;
    /**
     * 
     * @type {EmailModel}
     * @memberof HcpModel
     */
    email: EmailModel;
    /**
     * 
     * @type {ZipCodeModel}
     * @memberof HcpModel
     */
    zipCode: ZipCodeModel;
    /**
     * 
     * @type {NationalityTypeModel}
     * @memberof HcpModel
     */
    nationalityType: NationalityTypeModel;
}

/**
 * Check if a given object implements the HcpModel interface.
 */
export function instanceOfHcpModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "additionalProperties" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "zipCode" in value;
    isInstance = isInstance && "nationalityType" in value;

    return isInstance;
}

export function HcpModelFromJSON(json: any): HcpModel {
    return HcpModelFromJSONTyped(json, false);
}

export function HcpModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HcpModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'additionalProperties': ((json['AdditionalProperties'] as Array<any>).map(AdditionalPropertyDefinitionModelFromJSON)),
        'email': EmailModelFromJSON(json['Email']),
        'zipCode': ZipCodeModelFromJSON(json['ZipCode']),
        'nationalityType': NationalityTypeModelFromJSON(json['NationalityType']),
    };
}

export function HcpModelToJSON(value?: HcpModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AdditionalProperties': ((value.additionalProperties as Array<any>).map(AdditionalPropertyDefinitionModelToJSON)),
        'Email': EmailModelToJSON(value.email),
        'ZipCode': ZipCodeModelToJSON(value.zipCode),
        'NationalityType': NationalityTypeModelToJSON(value.nationalityType),
    };
}

