/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import type { StateType } from './StateType';
import {
    StateTypeFromJSON,
    StateTypeFromJSONTyped,
    StateTypeToJSON,
} from './StateType';
import type { UserStatusType } from './UserStatusType';
import {
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface FormsDefaultValuesPatientModel
 */
export interface FormsDefaultValuesPatientModel {
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    registryNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    city?: string | null;
    /**
     * 
     * @type {CountryType}
     * @memberof FormsDefaultValuesPatientModel
     */
    country?: CountryType;
    /**
     * 
     * @type {StateType}
     * @memberof FormsDefaultValuesPatientModel
     */
    state?: StateType;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    organizationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    ancestry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    height?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    weight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    nationalityType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormsDefaultValuesPatientModel
     */
    rowVersion?: string | null;
    /**
     * 
     * @type {UserStatusType}
     * @memberof FormsDefaultValuesPatientModel
     */
    userStatus?: UserStatusType;
}

/**
 * Check if a given object implements the FormsDefaultValuesPatientModel interface.
 */
export function instanceOfFormsDefaultValuesPatientModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormsDefaultValuesPatientModelFromJSON(json: any): FormsDefaultValuesPatientModel {
    return FormsDefaultValuesPatientModelFromJSONTyped(json, false);
}

export function FormsDefaultValuesPatientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormsDefaultValuesPatientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'registryNumber': !exists(json, 'registryNumber') ? undefined : json['registryNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : CountryTypeFromJSON(json['country']),
        'state': !exists(json, 'state') ? undefined : StateTypeFromJSON(json['state']),
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'ancestry': !exists(json, 'ancestry') ? undefined : json['ancestry'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : json['dateOfBirth'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'nationalityType': !exists(json, 'nationalityType') ? undefined : json['nationalityType'],
        'rowVersion': !exists(json, 'rowVersion') ? undefined : json['rowVersion'],
        'userStatus': !exists(json, 'userStatus') ? undefined : UserStatusTypeFromJSON(json['userStatus']),
    };
}

export function FormsDefaultValuesPatientModelToJSON(value?: FormsDefaultValuesPatientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'registryNumber': value.registryNumber,
        'email': value.email,
        'phone': value.phone,
        'street': value.street,
        'city': value.city,
        'country': CountryTypeToJSON(value.country),
        'state': StateTypeToJSON(value.state),
        'zipCode': value.zipCode,
        'organizationId': value.organizationId,
        'gender': value.gender,
        'ancestry': value.ancestry,
        'dateOfBirth': value.dateOfBirth,
        'height': value.height,
        'weight': value.weight,
        'nationalityType': value.nationalityType,
        'rowVersion': value.rowVersion,
        'userStatus': UserStatusTypeToJSON(value.userStatus),
    };
}

