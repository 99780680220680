import { UserGraphModel } from "api/query/models/UserGraphModel";
import CarnaLineChart from "components/Chart/CarnaChart/LineChart";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useCallback } from "react";
import { getOptions } from "./model";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { useGetUACRRanges } from "utils/hooks/useGetUACRRanges";
import { Formatters } from "components/Chart/model";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
// const CarnaLineChart = React.lazy(() => import("components/Chart/CarnaChart/LineChart"));

export function UACR() {
  const { appConfig } = useGlobalConfigContext();
  const { uacrUnit } = useGetUserPreferredMeasurementUnits();
  const { orderedUACRStageRanges } = useGetUACRRanges();
  const { globalPreference } = useGlobalPreferenceContext();

  const getOptionsWithTrend = useCallback(
    (formatters: Formatters, graphUser: UserGraphModel) => {
      return getOptions(
        formatters,
        graphUser,
        orderedUACRStageRanges,
        appConfig?.components.graphs.Patient.Index.showTrending,
        globalPreference?.regionType,
      );
    },
    [
      appConfig?.components.graphs.Patient.Index.showTrending,
      globalPreference?.regionType,
      orderedUACRStageRanges,
    ],
  );

  return (
    <CarnaLineChart
      showTrend={appConfig?.components.graphs.Patient.Index.showTrending}
      dataType="UACR"
      unitType="uacr"
      className="UACR"
      getOptions={getOptionsWithTrend}
      measurementUnit={uacrUnit}
    />
  );
}

export default UACR;
