import * as React from "react";
import { SVGProps } from "react";
const SvgSort = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.47 9.773a.75.75 0 0 0 1.06 0l2.963-2.962v12.432a.75.75 0 1 0 1.5 0V6.81l2.962 2.962a.75.75 0 1 0 1.06-1.06L7.774 4.47a.75.75 0 0 0-1.06 0L2.47 8.712a.75.75 0 0 0 0 1.061ZM17.993 5a.75.75 0 0 0-1.5 0v12.432L13.53 14.47a.75.75 0 1 0-1.06 1.06l4.242 4.243a.75.75 0 0 0 1.061 0l4.243-4.243a.75.75 0 0 0-1.061-1.06l-2.962 2.962V5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSort;
