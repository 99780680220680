import * as React from "react";
import { SVGProps } from "react";
const SvgRewind = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.75 5a.75.75 0 0 0-1.21-.592l-9 7a.75.75 0 0 0 0 1.184l9 7A.75.75 0 0 0 11.75 19V5Zm-1.5 12.466L3.222 12l7.028-5.467v10.933ZM22.75 5a.75.75 0 0 0-1.21-.592l-9 7a.75.75 0 0 0 0 1.184l9 7A.75.75 0 0 0 22.75 19V5Zm-1.5 12.466L14.222 12l7.028-5.467v10.933Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgRewind;
