import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { AdministratorsTable } from "../common/AdministratorsTable";
import { useAdministratorsTableStateContext } from "./context";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

export function UsersTable() {
  const tableContext = useAdministratorsTableStateContext();
  const { appConfig } = useGlobalConfigContext();

  return (
    <AdministratorsTable
      appliedColumns={NotNullOrUndefined(
        appConfig?.components.tables.Administrator.Index.columnList,
      )}
      adminTableStateContext={tableContext}
      adminFilterOptions={appConfig?.components.tables.Administrator.Index.filterOptions}
    />
  );
}
