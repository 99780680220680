import { UserGraphModel } from "api/query";
import { ECOption, Formatters } from "components/Chart/model";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { getSingleAreaOptions } from "../model";
import { SERUM_CREATININE_HIDE_NORMAL_RANGE_FROM_VALUE } from "config/const";

interface GetOptionsProps {
  formatters: Formatters;
  data: UserGraphModel;
  measurementUnit: MEASUREMENT_UNITS;
  maxCreatinineRange?: number;
  creatinineNormalRange?: [number, number];
  showTrending?: boolean;
}

export const getOptions = ({
  formatters,
  data,
  measurementUnit,
  maxCreatinineRange = 1,
  creatinineNormalRange = [0, 0],
  showTrending,
}: GetOptionsProps): ECOption => {
  const { measurements } = data;

  const showNormalRange = !measurements.some(
    measurement =>
      (measurement.units.serumCreatinine?.value ?? 0) >
      SERUM_CREATININE_HIDE_NORMAL_RANGE_FROM_VALUE,
  );

  const normalAreaRange: [number | undefined, number | undefined] = [
    showNormalRange ? creatinineNormalRange[0] : undefined,
    creatinineNormalRange[1],
  ] as const;

  return getSingleAreaOptions({
    formatters,
    data,
    unitType: "serumCreatinine",
    measurementUnit,
    yAxis: { max: maxCreatinineRange },
    normalAreaRange,
    showTrending,
  });
};
