import * as React from "react";
import { SVGProps } from "react";
const SvgFolderMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9 13.25a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5H9Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2.25A2.75 2.75 0 0 0 1.25 5v14A2.75 2.75 0 0 0 4 21.75h16A2.75 2.75 0 0 0 22.75 19V8A2.75 2.75 0 0 0 20 5.25h-8.599L9.624 2.584A.75.75 0 0 0 9 2.25H4Zm-.884 1.866A1.25 1.25 0 0 1 4 3.75h4.599l1.777 2.666A.75.75 0 0 0 11 6.75h9A1.25 1.25 0 0 1 21.25 8v11A1.25 1.25 0 0 1 20 20.25H4A1.25 1.25 0 0 1 2.75 19V5c0-.332.132-.65.366-.884Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFolderMinus;
