import { Button, SideModal, SideModalElements, SideModalProps, Icon } from "libs/ui";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import "./CreateNewSideModal.scss";

interface CreateNewSideModalSubmitButton {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

interface CreateNewSideModalProps extends SideModalProps {
  submitButton: CreateNewSideModalSubmitButton;
  isLoading?: boolean;
}

export function CreateNewSideModal({
  show,
  submitButton,
  closeModal,
  title,
  children,
  isLoading,
  ...rest
}: PropsWithChildren<CreateNewSideModalProps>) {
  const { t } = useTranslation("modals", { keyPrefix: "CreateNewSideModal" });

  return (
    <SideModal show={show} {...rest} bodyClass="CreateNewSideModal">
      <SideModalElements.Header closeModal={closeModal}>
        <Icon icon="PlusCircle" />
        {title}
      </SideModalElements.Header>

      <SideModalElements.Separator />
      {children}

      <SideModalElements.Footer>
        <Button
          disabled={closeModal?.disabled}
          onClick={closeModal?.onClick}
          buttonType="grey"
          buttonSize="medium"
          data-testid="CreateNewSideModal--Cancel"
        >
          {t("buttonCancel")}
        </Button>
        <Button
          onClick={submitButton.onClick}
          buttonType="primary"
          buttonSize="medium"
          disabled={submitButton.disabled}
          data-testid="CreateNewSideModal--Save"
          type="submit"
          loading={isLoading}
        >
          {submitButton.text}
        </Button>
      </SideModalElements.Footer>
    </SideModal>
  );
}
