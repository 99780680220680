import { Icon } from "libs/ui";
import classNames from "classnames";
import "./CollapseToggler.scss";
import { useMatch } from "react-router-dom";

export interface CollapseTogglerProps {
  toggleCollapsed: boolean;
  onToggleCollapse: () => void;
  className?: string;
}

export function CollapseToggler({
  toggleCollapsed,
  onToggleCollapse,
  className,
}: CollapseTogglerProps) {
  const onDashboard = useMatch({ path: "/dashboard/*", end: false });

  return (
    <button
      className={classNames(
        "CollapseToggler",
        {
          "CollapseToggler--collapsed": toggleCollapsed,
          "CollapseToggler--dashboard": onDashboard,
        },
        className,
      )}
      onClick={onToggleCollapse}
    >
      <Icon icon={toggleCollapsed ? "ChevronRight" : "ChevronLeft"} />
    </button>
  );
}
