import * as React from "react";
import { SVGProps } from "react";
const SvgBmi = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.476 8.382a2.691 2.691 0 1 0 0-5.382 2.691 2.691 0 0 0 0 5.382Z"
      fill="url(#bmi_svg__a)"
    />
    <path d="M15.167 5.69a2.691 2.691 0 0 1-5.382 0h5.382Z" fill="url(#bmi_svg__b)" />
    <path
      d="M9.827 15.16a1.151 1.151 0 1 1 1.995 1.152l-2.374 4.112a1.151 1.151 0 1 1-1.994-1.151l2.373-4.112Z"
      fill="url(#bmi_svg__c)"
    />
    <path
      d="M10.9 9.023a1.152 1.152 0 0 1 0 2.303H6.151a1.151 1.151 0 1 1 0-2.303h4.747Z"
      fill="url(#bmi_svg__d)"
    />
    <path
      d="M10 10.759C10 9.787 10.67 9 11.497 9h2.006C14.33 9 15 9.787 15 10.759v3.482c0 .972-.67 1.759-1.497 1.759h-2.006C10.67 16 10 15.213 10 14.241V10.76Z"
      fill="url(#bmi_svg__e)"
    />
    <path
      d="M14.053 11.326a1.152 1.152 0 0 1 0-2.303H18.8a1.151 1.151 0 1 1 0 2.303h-4.747Z"
      fill="url(#bmi_svg__f)"
    />
    <path
      d="M12.98 16.312a1.152 1.152 0 0 1 1.995-1.151l2.374 4.112a1.151 1.151 0 1 1-1.994 1.151l-2.374-4.112Z"
      fill="url(#bmi_svg__g)"
    />
    <defs>
      <linearGradient
        id="bmi_svg__a"
        x1={11.384}
        y1={4.6}
        x2={13.639}
        y2={6.854}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" />
        <stop offset={1} stopColor="#5331A8" />
      </linearGradient>
      <linearGradient
        id="bmi_svg__b"
        x1={12.476}
        y1={6.763}
        x2={12.476}
        y2={8.1}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5331A8" stopOpacity={0} />
        <stop offset={1} stopColor="#5331A8" />
      </linearGradient>
      <linearGradient
        id="bmi_svg__c"
        x1={9.813}
        y1={16.095}
        x2={11.439}
        y2={19.135}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" />
        <stop offset={1} stopColor="#5331A8" />
      </linearGradient>
      <linearGradient
        id="bmi_svg__d"
        x1={10.083}
        y1={9.478}
        x2={8.263}
        y2={12.406}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" />
        <stop offset={1} stopColor="#5331A8" />
      </linearGradient>
      <linearGradient
        id="bmi_svg__e"
        x1={10.987}
        y1={10.953}
        x2={14.082}
        y2={15.16}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" />
        <stop offset={1} stopColor="#5331A8" />
      </linearGradient>
      <linearGradient
        id="bmi_svg__f"
        x1={14.869}
        y1={10.872}
        x2={16.689}
        y2={7.944}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" />
        <stop offset={1} stopColor="#5331A8" />
      </linearGradient>
      <linearGradient
        id="bmi_svg__g"
        x1={13.783}
        y1={16.792}
        x2={17.229}
        y2={16.904}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" />
        <stop offset={1} stopColor="#5331A8" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgBmi;
