/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IStatusLimit } from './IStatusLimit';
import {
    IStatusLimitFromJSON,
    IStatusLimitFromJSONTyped,
    IStatusLimitToJSON,
} from './IStatusLimit';

/**
 * 
 * @export
 * @interface GreaterThan
 */
export interface GreaterThan extends IStatusLimit {
    /**
     * 
     * @type {number}
     * @memberof GreaterThan
     */
    value: number;
}

/**
 * Check if a given object implements the GreaterThan interface.
 */
export function instanceOfGreaterThan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function GreaterThanFromJSON(json: any): GreaterThan {
    return GreaterThanFromJSONTyped(json, false);
}

export function GreaterThanFromJSONTyped(json: any, ignoreDiscriminator: boolean): GreaterThan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...IStatusLimitFromJSONTyped(json, ignoreDiscriminator),
        'value': json['Value'],
    };
}

export function GreaterThanToJSON(value?: GreaterThan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IStatusLimitToJSON(value),
        'Value': value.value,
    };
}

