import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import "./Card.scss";

type CardSize = "large" | "medium" | "small";

export interface CardProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string;
  size?: CardSize;
}

export function Card({
  className,
  size = "medium",
  onClick,
  children,
  ...props
}: PropsWithChildren<CardProps>) {
  return (
    <div
      {...props}
      onClick={onClick}
      className={classNames("Card", className, `Card--${size}`, {
        "Card--clickable": !!onClick,
      })}
    >
      {children}
    </div>
  );
}
