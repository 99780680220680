/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UrineAlbumineStatusType = {
    None: 'None',
    Normal: 'Normal',
    Microalbuminuria: 'Microalbuminuria',
    Macroalbuminuria: 'Macroalbuminuria'
} as const;
export type UrineAlbumineStatusType = typeof UrineAlbumineStatusType[keyof typeof UrineAlbumineStatusType];


export function UrineAlbumineStatusTypeFromJSON(json: any): UrineAlbumineStatusType {
    return UrineAlbumineStatusTypeFromJSONTyped(json, false);
}

export function UrineAlbumineStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrineAlbumineStatusType {
    return json as UrineAlbumineStatusType;
}

export function UrineAlbumineStatusTypeToJSON(value?: UrineAlbumineStatusType | null): any {
    return value as any;
}

