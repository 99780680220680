import { MeasurementUnit } from "./MeasurementUnit";
import { MeasurementValue } from "./MeasurementValue";
import { MeasurementItem, WidgetBodyProps } from "./model";
import "./WidgetBody.scss";
import { DeviceImage } from "libs/ui/DeviceImage";
import { MeasurementStatus } from "./MeasurementStatus";
import { useTranslation } from "react-i18next";
import { MeasurementPatientWidgetDataModel } from "api/query/models/MeasurementPatientWidgetDataModel";
import { MeasurementWidgetType } from "utils/createGlobalConfigStore";
import classNames from "classnames";

const MeasurementType = {
  None: "None",
  SerumCreatinine: "SerumCreatinine",
  BloodPressure: "BloodPressure",
  Glucose: "Glucose",
  UACR: "UACR",
  BMI: "BMI",
  SemiQuantitativeUACR: "SemiQuantitativeUACR",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type MeasurementType = (typeof MeasurementType)[keyof typeof MeasurementType];

function getMeasurementType(type: MeasurementWidgetType): MeasurementType {
  switch (type) {
    case "SerumCreatinine":
    case "Egfr":
      return "SerumCreatinine";

    case "BloodPressure":
      return "BloodPressure";

    case "Glucose":
      return "Glucose";

    case "Bmi":
    case "Height":
    case "Weight":
      return "BMI";

    case "Uacr":
    case "UrineAlbumin":
    case "UrineCreatinine":
      return "UACR";

    case "SemiQuantitativeUacr":
      return "SemiQuantitativeUACR";

    default:
      throw new Error(`Type ${type} not covered`);
  }
}

function SerumCreatinineDevice({
  data,
}: Readonly<{ data: MeasurementPatientWidgetDataModel | null }>) {
  const { t } = useTranslation("widgets", { keyPrefix: "MeasurementsWidget" });
  if (data === null) {
    return null;
  }

  if (data.device) {
    return <DeviceImage alt={data.device.serialNumber} />;
  }

  return <>{t("manualInput")}</>;
}

export function WidgetBody({ data, type, onClick }: Readonly<WidgetBodyProps>) {
  const widgetData: MeasurementItem | undefined = data?.measurements?.find(
    el => el.measurementType === getMeasurementType(type),
  )?.item;

  return (
    <div
      className={classNames("WidgetBody", {
        "WidgetBody--clickable": onClick !== undefined,
      })}
      onClick={onClick}
    >
      <div className="WidgetBody__content">
        {type === "BloodPressure" ? (
          <div className="WidgetBody__values">
            <MeasurementValue measurements={widgetData} itemType="Systolic" />
            <MeasurementValue measurements={widgetData} itemType="Diastolic" />
          </div>
        ) : (
          <MeasurementValue measurements={widgetData} itemType={type} />
        )}

        <MeasurementUnit type={type} measurements={widgetData} />
      </div>

      <div className="WidgetBody__status">
        {type === "SerumCreatinine" ? (
          <SerumCreatinineDevice data={data} />
        ) : (
          <MeasurementStatus itemType={type} measurements={widgetData} />
        )}
      </div>
    </div>
  );
}
