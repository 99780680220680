import { useMachine } from "@xstate/react";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { mapTableQueryParamsFromURL, useTableURLParams } from "libs/ui/Table/utils";
import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { createSafeContext } from "utils/createSafeContext";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useFilterModal } from "../common/hooks";
import { getFilterOptionsPerConfiguration } from "../utils";
import {
  ADMINISTRATORS_FILTER_OPTIONS,
  AdministratorsFilterOptionsKey,
  AdministratorsTableStateContext,
  makeTableFetchStateMachine,
} from "./../common/AdministratorsTable/model";

const Context = createSafeContext<AdministratorsTableStateContext>();

export const useAdministratorsTableStateContext = Context.hook;

export function AdministratorsTableStateProvider({ children }: Readonly<PropsWithChildren>) {
  const [queryParams] = useTableURLParams<ReturnType<typeof ADMINISTRATORS_FILTER_OPTIONS>>();
  const tableMachine = useMemo(
    () =>
      makeTableFetchStateMachine(
        mapTableQueryParamsFromURL<ReturnType<typeof ADMINISTRATORS_FILTER_OPTIONS>>(queryParams),
      ),
    [queryParams],
  );
  const tableService = useMachine(tableMachine);

  const { appConfig } = useGlobalConfigContext();
  const filterSideModelState = useFilterModal<AdministratorsFilterOptionsKey>(
    getFilterOptionsPerConfiguration(
      ADMINISTRATORS_FILTER_OPTIONS(),
      NotNullOrUndefined(appConfig?.components.tables.Administrator.Index.filterOptions),
    ),
  );

  const [, send] = tableService;

  const fetch = useCallback(() => {
    send({ type: "UPDATE_QUERY", value: mapTableQueryParamsFromURL(queryParams) });
  }, [send, queryParams]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const value = useMemo(
    () => ({
      tableService,
      fetch,
      filterSideModelState,
    }),
    [tableService, fetch, filterSideModelState],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
