import "./RuleEngine.scss";

import { CardLink } from "libs/ui";
import { useTranslation } from "react-i18next";
import { NavRoute, NOT_FOUND_ROUTE, settingsRoutePaths } from "router";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { SettingsPage } from "components/PageTemplate/SettingsPage";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";

export function RuleEngine() {
  const { t } = useTranslation("translation", { keyPrefix: "PageTemplate.Settings.rule-engine" });
  const { appConfig } = useGlobalConfigContext();
  const navigate = useNavigate();
  const { userRole } = useAdditionalAuthInformationContext();

  const canAccess = appConfig?.pages.Settings.RuleEngine.Access.includes(userRole);

  useEffect(() => {
    if (canAccess === false) {
      navigate(NOT_FOUND_ROUTE, { replace: true });
    }
  }, [canAccess, navigate]);

  const cardLinks: NavRoute[] = [
    {
      to: settingsRoutePaths.ruleEngineUserView,
      name: t("user-view.title"),
      icon: "UserCheck",
      accessLevel: true,
    },
    {
      to: settingsRoutePaths.ruleEngineLaboratory,
      name: t("laboratory.title"),
      icon: "Results",
      accessLevel: true,
    },
  ];

  return (
    <>
      {canAccess ? (
        <SettingsPage>
          <div className="RuleEngine">
            {cardLinks.map(card =>
              card.accessLevel ? (
                <CardLink key={card.to} path={card.to} title={card.name} icon={card.icon} />
              ) : null,
            )}
          </div>
        </SettingsPage>
      ) : null}
    </>
  );
}
