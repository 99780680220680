import { MeasurementGraphModel, UnitsModel } from "api/query";
import { MeasurementDetails } from "components/MeasurementSideDetails/SideDetails/components/model";
import { CarnaApiQuery } from "config/apiQuery";
import { ECElementEvent, ECharts } from "echarts/core";
import { isLoaded } from "models/loadable";
import { MutableRefObject, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { useApi } from "utils/hooks/useApi";
import { getMeasurementPerType } from "../utils";
import { mapSideDetailsUnits } from "components/MeasurementSideDetails/utils";

export function useGraphLineDetails(
  dataList: MeasurementGraphModel[],
  unitType: Exclude<keyof UnitsModel, "bloodPressure">,
  chartRef: MutableRefObject<ECharts | undefined | null>,
) {
  const { organizationId = "", patientId = "" } = useParams<RouteParams["patientGraph"]>();

  const [testData, getTestData, resetTestData] = useApi(
    CarnaApiQuery.Test.getByOrganizationPatientId,
  );

  const selectedNode: MeasurementDetails | undefined = useMemo(
    () =>
      isLoaded(testData)
        ? ({
            measurementTime: testData.value.measurementTime,
            units: mapSideDetailsUnits(
              getMeasurementPerType(testData.value.measurements, unitType),
              unitType,
            ),
            patient: {
              ...testData.value.patient,
            },
            organizationId,
            device: testData.value.device,
            id: testData.value.id,
            testedBy: {
              ...testData.value.testedBy,
              id: testData.value.testedBy?.id ?? "",
              roleType: testData.value.testedBy?.roleType ?? "Admin",
            },
            testCountId: testData.value.testCountId,
            sampledByDevice: testData.value.sampledByDevice,
          } satisfies MeasurementDetails)
        : undefined,
    [organizationId, testData, unitType],
  );

  const closeDetails = useCallback(() => {
    chartRef?.current?.dispatchAction({
      type: "unselect",
      dataIndex: dataList.findIndex(el => el.id === selectedNode?.id),
    });
    resetTestData();
  }, [chartRef, dataList, resetTestData, selectedNode?.id]);

  const onNodeClick = useCallback(
    (val: ECElementEvent) => {
      if (val.componentType === "series") {
        const clickedNode: MeasurementGraphModel | undefined = dataList.at(val.dataIndex);

        if (clickedNode?.id === selectedNode?.id) {
          resetTestData();
          return;
        }

        if (clickedNode?.units[unitType] !== undefined) {
          getTestData({ organizationId, userEntityId: patientId, testEntityId: clickedNode.id });
        }
      }
    },
    [dataList, getTestData, organizationId, resetTestData, selectedNode?.id, unitType, patientId],
  );

  return {
    selectedNode,
    onNodeClick,
    closeDetails,
    testData,
  } as const;
}
