import { v4 } from "uuid";
import { ToastMsg, ToastMsgBase, ToastMsgInternal } from "./model";

type Listener = () => void;

export function createToastsStore() {
  let queue: ToastMsgInternal[] = [];
  let listeners = new Set<Listener>();

  const getQueue = () => queue;

  const subscribe = (listener: Listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };

  const clear = () => {
    queue = [];
    listeners.forEach(listener => {
      listener();
    });
  };

  const removeToast = (uuid: string) => {
    /**
     * We have some animation here to disappear
     */
    queue = queue.filter(list => list.uuid !== uuid);

    listeners.forEach(listener => {
      listener();
    });
  };

  const pushToast = (msg: ToastMsg): ToastMsgInternal => {
    const msgUuid = v4();
    const newToast = { ...msg, uuid: msgUuid };

    if (msg.expire) {
      setTimeout(() => {
        removeToast(msgUuid);
      }, msg.expire);
    }

    queue = [...queue, newToast];

    listeners.forEach(listener => {
      listener();
    });

    return newToast;
  };

  const success = (toastData: ToastMsgBase) => pushToast({ ...toastData, type: "success" });
  const error = (toastData: ToastMsgBase) => pushToast({ ...toastData, type: "error" });
  const information = (toastData: ToastMsgBase) => pushToast({ ...toastData, type: "information" });
  const warning = (toastData: ToastMsgBase) => pushToast({ ...toastData, type: "warning" });

  return {
    getQueue,
    subscribe,
    removeToast,
    pushToast,
    clear,
    toast: {
      success,
      error,
      information,
      warning,
    },
  } as const;
}
