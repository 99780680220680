import { Icon } from "libs/ui/Icon";
import { PropsWithChildren } from "react";
import "./SideModalHeader.scss";

interface SideModalHeaderProps {
  closeModal?: {
    onClick: () => void;
    disabled?: boolean;
  };
}

export function Header({ closeModal, children }: PropsWithChildren<SideModalHeaderProps>) {
  return (
    <header className="SideModalHeader">
      {children}
      <button
        disabled={closeModal?.disabled}
        onClick={closeModal?.onClick}
        className="SideModalHeader__close"
      >
        <Icon icon="Close" />
      </button>
    </header>
  );
}
