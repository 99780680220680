import * as React from "react";
import { SVGProps } from "react";
const SvgBookmarkEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3.75A1.25 1.25 0 0 0 5.75 5v14.543l5.814-4.153a.75.75 0 0 1 .872 0l5.814 4.153V5A1.25 1.25 0 0 0 17 3.75H7Zm-1.945-.695A2.75 2.75 0 0 1 7 2.25h10A2.75 2.75 0 0 1 19.75 5v16a.75.75 0 0 1-1.186.61L12 16.922 5.436 21.61A.75.75 0 0 1 4.25 21V5c0-.73.29-1.429.805-1.945Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgBookmarkEmpty;
