import { SideModalElements } from "libs/ui";
import { useTranslation } from "react-i18next";
import { SectionDetailsProps } from "../model";
import { PatientField } from "./PatientField";
import { PhysicalSection } from "components/Forms/FormElements/PhysicalSection";
import { DetailsField } from "../../elements/DetailsField";
import { useRegistryNumberLabel } from "utils/hooks/useRegistryNumberLabel";
import { TestUnitType } from "components/AppTables/common/TestsTable/utils";
import { useSyncExternalStore } from "react";
import { avatarCache } from "utils/machines/avatars";
import { PictureResolution } from "utils/helpers/pictures.model";

interface PatientSectionDetailsProps extends SectionDetailsProps {
  selectedTestUnit?: TestUnitType;
}

export function PatientSectionDetails({
  data,
  loading,
  selectedTestUnit,
}: Readonly<PatientSectionDetailsProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.PatientSectionDetails" });
  const { t: tAncestry } = useTranslation("translation", { keyPrefix: "ancestryType" });
  const { t: tGender } = useTranslation("translation", { keyPrefix: "genderType" });
  const [registryNumberLabel] = useRegistryNumberLabel();

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  const patientHasBmi =
    (data?.test.patient.height ?? 0) &&
    (data?.test.patient.weight ?? 0) &&
    (data?.test.patient.bmi ?? 0);

  return (
    <>
      <div data-testid={"PatientSectionDetails"} className="PatientSectionDetails">
        <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>
        <DetailsField loading={loading} label={t("labelPatient")}>
          <PatientField
            patient={data?.test.patient}
            patientAvatarImage={avatarCache.get(
              data?.test.patient.organizationId,
              data?.test.patient.id,
              PictureResolution.details,
              true,
            )}
            avatarsLoading={false}
          />
        </DetailsField>

        {data?.test.patient.registryNumber ? (
          <DetailsField loading={loading} label={registryNumberLabel}>
            {data?.test.patient.registryNumber}
          </DetailsField>
        ) : null}

        <DetailsField loading={loading} label={t("labelAge")}>
          {data?.test.patient.age}
        </DetailsField>

        {data?.test.patient.gender ? (
          <DetailsField loading={loading} label={t("labelGender")}>
            {tGender(data.test.patient.gender)}
          </DetailsField>
        ) : null}

        {data?.test.patient.ancestry ? (
          <DetailsField loading={loading} label={t("labelAncestry")}>
            {tAncestry(data.test.patient.ancestry)}
          </DetailsField>
        ) : null}
      </div>

      {selectedTestUnit !== "BMI" && patientHasBmi ? (
        <PhysicalSection
          loading={loading}
          editableFields={[]}
          patientData={{
            height: data?.test.patient.height ?? 0,
            weight: data?.test.patient.weight ?? 0,
            bmi: data?.test.patient.bmi ?? 0,
          }}
          onChange={() => ({})}
        />
      ) : null}
    </>
  );
}
