import { EditableDetails } from "components/EditableDetails/EditableDetails";
import { Test } from "components/Forms/Test";
import { TestFormModel } from "components/Forms/Test/model";
import * as testFormUtils from "components/Forms/Test/testFormUtils";
import { useTestFormFieldsReady } from "components/Forms/Test/useTestFormFieldsReady";
import { CarnaApiEvent } from "config/apiEvent";
import { CarnaApiQuery } from "config/apiQuery";
import { toastStore } from "config/toast";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { ActionModal, Button, SideModal } from "libs/ui";
import { isEqual } from "lodash-es";
import { MeasurementUIModel, UImeasurementTypes } from "models/TestModels";
import { isLoaded, isLoading } from "models/loadable";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTestDataWithoutNone } from "utils/getTestDataWithoutNone";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import { preventClickBubbling } from "utils/preventClickBubbling";
import { useMapTestResponseModelToTestFormModel } from "./BEtoFE/useMapTestResponseModelToTestFormModel";
import { useMapTestFormModelForBE } from "./FEtoBE/useMapTestFormModelForBE";
import { useApi } from "./useApi";
import { useConvertTestTimeZone } from "./useConvertTestTimeZone";
import { useOnEventStatusSubscribe } from "./useOnEventStatusSubscribe";

const prepareTests = (test: TestFormModel): TestFormModel => ({
  ...test,
  measurements: [
    ...test.measurements,
    ...(test.measurements.length >= UImeasurementTypes.length
      ? []
      : [
          {
            measurementType: "None",
            items: [],
          } as MeasurementUIModel,
        ]),
  ],
});

export function useEditTest(onSuccessCb: () => void) {
  const { t } = useTranslation("modals", { keyPrefix: "EditTestModal" });

  const [convertToTestFormModel] = useMapTestResponseModelToTestFormModel();
  const [mapTestFormModelForBE] = useMapTestFormModelForBE();
  const [getFieldsReady] = useTestFormFieldsReady();

  const [showModal, setShowModal] = useState({ delete: false, edit: false });
  const [test, setTest] = useState<TestFormModel>(testFormUtils.TEST_DATA_DEFAULT);

  const [onEvent, inLoadingState] = useOnEventStatusSubscribe();
  const { currentUserId } = useAdditionalAuthInformationContext();
  const { convertTestMeasurementTime } = useConvertTestTimeZone();

  const [data, getTestDataCb] = useApi(CarnaApiQuery.Test.getByOrganizationId, {
    toastText: {
      errorText: t("errorFailedToRetrieveTest"),
    },
    onResponseCallback: {
      onSuccess: res => {
        setTest(prepareTests(convertToTestFormModel(res)));
      },
      onError: () => {
        setShowModal(prevValue => ({ ...prevValue, edit: false }));
      },
    },
  });

  const isFormChanged =
    isLoaded(data) && !isEqual(convertToTestFormModel(data.value), getTestDataWithoutNone(test));

  const onEditClick = useCallback(
    (organizationId: string, testId: string) => {
      setShowModal(prevValue => ({ ...prevValue, edit: true }));
      getTestDataCb({ organizationId, id: testId });
    },
    [getTestDataCb],
  );

  const resetAndClose = useCallback(() => {
    setTest(testFormUtils.TEST_DATA_DEFAULT);

    setShowModal({ edit: false, delete: false });

    onSuccessCb();
  }, [onSuccessCb]);

  const submitDisabled = !getFieldsReady(test, true) || inLoadingState || !isFormChanged;

  const onTestUpdate = useCallback(
    (e?: React.FormEvent) => {
      e?.preventDefault();

      const testDataWithoutNone = getTestDataWithoutNone(mapTestFormModelForBE(test));

      if (testDataWithoutNone.measurements.length > 0) {
        onEvent(
          CarnaApiEvent.Test.put({
            organizationId: testDataWithoutNone.organizationId,
            userEntityId: currentUserId,
            testEntityId: testDataWithoutNone.id ?? "",
            replaceTestByHcpRequestModel: convertTestMeasurementTime(testDataWithoutNone),
          }),
          {
            complete() {
              toastStore.toast.success({ msg: t("successEditMeasurementToast"), expire: 5000 });
              resetAndClose();
            },
            error(err) {
              switch (err.code) {
                case "BE_ERROR":
                  showBeFieldErrors(err.err, t("errorEditMeasurementToast"));
                  break;
                case "ACTION_FAILED":
                  toastStore.toast.error({ msg: t("errorEditMeasurementToast") });
                  break;
                case "STATUS_QUERY_ERROR":
                  resetAndClose();
                  toastStore.toast.error({ msg: t("errorFailedToUpdateTestsTable") });
              }
            },
          },
        );
      } else {
        setShowModal(prevValue => ({ ...prevValue, delete: true }));
      }
    },
    [
      convertTestMeasurementTime,
      currentUserId,
      mapTestFormModelForBE,
      onEvent,
      resetAndClose,
      t,
      test,
    ],
  );

  const onDeleteTest = useCallback(() => {
    onEvent(
      CarnaApiEvent.Test.delete({
        organizationId: test.organizationId,
        testEntityId: test.id ?? "",
        rowVersion: test.rowVersion,
      }),
      {
        complete() {
          toastStore.toast.success({ msg: t("successDeleteMeasurementToast"), expire: 5000 });
          resetAndClose();
        },
        error(err) {
          switch (err.code) {
            case "ACTION_FAILED":
              toastStore.toast.error({ msg: t("errorDeleteMeasurementToast") });
              break;
            case "STATUS_QUERY_ERROR":
              resetAndClose();
              toastStore.toast.error({ msg: t("errorFailedToUpdateTestsTable") });
          }
        },
      },
    );
  }, [onEvent, resetAndClose, t, test.id, test.organizationId, test.rowVersion]);

  const ConfirmationDeleteModal = useMemo(() => {
    return (
      <ActionModal
        show={showModal.delete}
        title={t("ConfirmDeleteModal.title")}
        icon={{
          iconType: "AlertTriangle",
          color: "red",
        }}
        inLoadingState={inLoadingState}
        cancelButton={
          <Button
            disabled={inLoadingState}
            buttonSize="medium"
            buttonType="transparent"
            onClick={preventClickBubbling(() =>
              setShowModal(prevValue => ({ ...prevValue, delete: false })),
            )}
          >
            {t("ConfirmDeleteModal.buttonCancel")}
          </Button>
        }
        submitButton={
          <Button
            disabled={inLoadingState}
            buttonSize="medium"
            buttonType="red"
            onClick={onDeleteTest}
          >
            {t("ConfirmDeleteModal.buttonProceed")}
          </Button>
        }
      >
        {t("ConfirmDeleteModal.description")}
      </ActionModal>
    );
  }, [inLoadingState, onDeleteTest, showModal.delete, t]);

  const EditTestSideModal = useMemo(
    () => (
      <SideModal show={showModal.edit}>
        <EditableDetails
          title={t("title")}
          onClose={resetAndClose}
          cancel={{
            onClick: resetAndClose,
            disabled: isLoading(data) || inLoadingState,
          }}
          save={{ onClick: onTestUpdate, disabled: submitDisabled, isLoading: inLoadingState }}
          className="EditableDetails--Test"
          editMode
        >
          {isLoaded(data) ? (
            <Test
              testData={test}
              setTestData={setTest}
              onSubmit={onTestUpdate}
              person={data.value.patient}
            />
          ) : null}
        </EditableDetails>
      </SideModal>
    ),
    [data, inLoadingState, onTestUpdate, resetAndClose, showModal.edit, submitDisabled, t, test],
  );

  return { onEditClick, EditTestSideModal, ConfirmationDeleteModal } as const;
}
