import { useMachine } from "@xstate/react";
import { HcpResponseModelListModel, HcpsFilterModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { SearchQuery, TableContext, tableStateMachine } from "libs/ui";
import { HCPModel, InitialValuesModel, PersonModel } from "models/PersonModels";
import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import { HCPTableColumns, HcpFilterOptions } from "utils/createGlobalConfigStore";
import { getCurrentUserData } from "utils/getCurrentUserData";
import { BASIC_FILTER_OPTIONS, FilterSideModalState } from "../Filter/model";
import { BaseTableFilterProps } from "../Table/model";

async function getAdminHCPList(context?: TableContext<HcpResponseModelListModel, HcpsFilterModel>) {
  const result = await CarnaApiQuery.Admin.getHcps({
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

async function getHCPListPerOrganizationId(
  context?: TableContext<HcpResponseModelListModel, HcpsFilterModel>,
) {
  const { organizationId, isPatient } = await getCurrentUserData();

  const result = await CarnaApiQuery.Hcps.get({
    organizationId,
    filters: { ...context?.filters, ...(isPatient ? { userStatuses: ["Active"] } : {}) }, // Patients should see only Active HCPs
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

export type UIHcpsFilters = Omit<HcpsFilterModel, "beginsWith" | "userIds">;

async function tableFetch(context?: TableContext<HcpResponseModelListModel, UIHcpsFilters>) {
  const { isAdmin } = await getCurrentUserData();

  const list = await (isAdmin ? getAdminHCPList(context) : getHCPListPerOrganizationId(context));

  return list;
}

export const makeTableFetchStateMachine = (
  queryParams: SearchQuery<ReturnType<typeof HCPs_FILTER_OPTIONS>>,
) => tableStateMachine(tableFetch, queryParams as any);

export function HCPs_FILTER_OPTIONS() {
  return {
    ...BASIC_FILTER_OPTIONS(),
    contains: "",
    withDevice: null,
    organizationIds: [],
  } as const;
}

export type HCPsFilterOptionsKey = keyof ReturnType<typeof HCPs_FILTER_OPTIONS>;

type TableService = ReturnType<typeof useMachine<ReturnType<typeof makeTableFetchStateMachine>>>;

export type HCPTableStateContext = {
  tableService: TableService;
  fetch: () => void;
  filterSideModelState: FilterSideModalState<HCPsFilterOptionsKey>;
};

export interface HCPTableProps extends BaseTableFilterProps {
  HCPTableStateContext: HCPTableStateContext;
  appliedColumns: HCPTableColumns[];
  hcpFilterOptions?: HcpFilterOptions[];
  initialValuesAsProps?: Partial<InitialValuesModel>;
}

export interface HCPsTableFilterProps {
  tableService: TableService;
  filterSideModelState: FilterSideModalState<HCPsFilterOptionsKey>;
}

export interface BodyRowsProps {
  tableService: TableService;
  changeHCPStateClick: (hcp: HCPModel) => MouseEventHandler<HTMLButtonElement>;
  appliedColumns: HCPTableColumns[];
  currentRightClickContext:
    | [React.MouseEvent<HTMLTableRowElement, MouseEvent>, PersonModel]
    | undefined;
  setCurrentRightClickContext: Dispatch<
    SetStateAction<[React.MouseEvent<HTMLTableRowElement, MouseEvent>, PersonModel] | undefined>
  >;
}
