import { ActionModal, Button } from "libs/ui";
import { isLoading } from "models/loadable";
import { useTranslation } from "react-i18next";
import { useDeleteAccount } from "utils/hooks/useDeleteAccount";

interface DeleteAccountModalProps {
  show: boolean;
  onClose: () => void;
}

export function DeleteAccountModal({ show, onClose }: DeleteAccountModalProps) {
  const { t } = useTranslation("modals", {
    keyPrefix: "DeleteAccountModal",
  });

  const [deleteResult, deleteAccount] = useDeleteAccount();

  return (
    <ActionModal
      show={show}
      icon={{
        iconType: "Delete",
        color: "red",
      }}
      title={t("title")}
      cancelButton={
        <Button
          onClick={onClose}
          buttonSize={"medium"}
          buttonType={"transparent"}
          disabled={isLoading(deleteResult)}
        >
          {t("buttonCancel")}
        </Button>
      }
      submitButton={
        <Button
          onClick={deleteAccount}
          buttonSize={"medium"}
          buttonType={"red"}
          disabled={isLoading(deleteResult)}
        >
          {t("buttonDelete")}
        </Button>
      }
    >
      {t("message")}
    </ActionModal>
  );
}
