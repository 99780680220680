import { TestFormModel } from "./model";
import { validateMeasurementInputValue } from "./testFormUtils";
import { isValid } from "date-fns";
import { useCallback } from "react";
import { useValidateDate } from "utils/hooks/useValidateDate";

function getFieldsReady(
  data: TestFormModel,
  isNotFutureDate: (value: Date) => boolean,
  allowEmptyFormSubmit: boolean = false,
) {
  const measurementWithoutNone = data.measurements.filter(
    measurement => measurement.measurementType !== "None",
  );

  const hasMeasurements = !!measurementWithoutNone.length;
  const measurementsValuesInputted = measurementWithoutNone.every(measure =>
    measure.items.every(item => {
      if (item.value === undefined || item.value.length === 0) {
        return false;
      }
      return validateMeasurementInputValue(item);
    }),
  );
  const measurementTimeDate = new Date(data.measurementTime);
  const measurementTimeSelected =
    isValid(measurementTimeDate) && isNotFutureDate(measurementTimeDate);
  const organizationSelected = !!data.organizationId.length;
  const patientSelected = !!data.patientId.length;

  return (
    (hasMeasurements || allowEmptyFormSubmit) &&
    measurementsValuesInputted &&
    measurementTimeSelected &&
    organizationSelected &&
    patientSelected
  );
}

export function useTestFormFieldsReady() {
  const { isNotFutureDate } = useValidateDate();

  const checkFields = useCallback(
    (data: TestFormModel, allowEmptyFormSubmit: boolean = false) =>
      getFieldsReady(data, isNotFutureDate, allowEmptyFormSubmit),
    [isNotFutureDate],
  );

  return [checkFields] as const;
}
