import * as React from "react";
import { SVGProps } from "react";
const SvgChevronsRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.53 6.47a.75.75 0 0 0-1.06 1.06L9.94 12l-4.47 4.47a.75.75 0 1 0 1.06 1.06l5-5a.75.75 0 0 0 0-1.06l-5-5ZM13.53 6.47a.75.75 0 1 0-1.06 1.06L16.94 12l-4.47 4.47a.75.75 0 1 0 1.06 1.06l5-5a.75.75 0 0 0 0-1.06l-5-5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgChevronsRight;
