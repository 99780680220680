import "./PrivacyLinks.scss";

import { DotSeparator } from "libs/ui/DotSeparator";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useResponsive } from "libs/ui/hooks";
import { v4 } from "uuid";

export function PrivacyLinks() {
  const { t } = useTranslation("translation", { keyPrefix: "PageTemplate.Settings.footer" });
  const { isMobile } = useResponsive();
  const privacyLinks: NavLinkProps[] = [
    {
      to: "/policy/privacy-policy",
      title: t("privacyPolicy"),
    },
    {
      to: "/policy/terms-and-conditions",
      title: t("termsAndConditions"),
    },
    {
      to: "/policy/cookie-policy",
      title: t("cookiePolicy"),
    },
  ];

  return (
    <ul className="PrivacyLinks">
      {privacyLinks.map((link, index, arr) => (
        <li className="PrivacyLinks__item" key={v4()}>
          <NavLink className="PrivacyLinks__link" to={link.to} target="_blank">
            {link.title}
          </NavLink>
          {arr.length !== index + 1 && !isMobile && <DotSeparator />}
        </li>
      ))}
    </ul>
  );
}
