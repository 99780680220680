import * as React from "react";
import { SVGProps } from "react";

export const EmptyOrgs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={85}
    height={80}
    viewBox="0 0 85 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.7673 79.9998H4.59438C2.97497 79.9998 1.66211 78.687 1.66211 77.0676V21.6552H21.7675L21.7673 79.9998Z"
      fill="url(#paint0_linear_16699_190430)"
    />
    <path
      d="M13.9157 40.3382H9.51602C8.33136 40.3382 7.37109 39.3779 7.37109 38.1933V27.9636C7.37109 26.779 8.33136 25.8187 9.51602 25.8187H13.9159C15.1006 25.8187 16.0608 26.779 16.0608 27.9636V38.1933C16.0607 39.3779 15.1004 40.3382 13.9157 40.3382Z"
      fill="url(#paint1_linear_16699_190430)"
    />
    <path
      d="M21.769 34.9937L14.4535 27.6782C14.1526 27.2841 13.679 27.0288 13.145 27.0288H10.2881C9.37815 27.0288 8.64062 27.7665 8.64062 28.6763V37.4808C8.64062 38.0151 8.89604 38.4885 9.29004 38.7894L21.7692 51.2686L21.769 34.9937Z"
      fill="url(#paint2_linear_16699_190430)"
    />
    <path
      d="M13.1448 39.1283H10.2881C9.37815 39.1283 8.64062 38.3908 8.64062 37.4808V28.6763C8.64062 27.7663 9.37815 27.0288 10.2881 27.0288H13.145C14.0549 27.0288 14.7924 27.7663 14.7924 28.6763V37.4808C14.7924 38.3906 14.0547 39.1283 13.1448 39.1283Z"
      fill="url(#paint3_linear_16699_190430)"
    />
    <path
      d="M13.9157 57.5547H9.51602C8.33136 57.5547 7.37109 56.5944 7.37109 55.4098V45.1801C7.37109 43.9955 8.33136 43.0352 9.51602 43.0352H13.9159C15.1006 43.0352 16.0608 43.9955 16.0608 45.1801V55.4098C16.0607 56.5944 15.1004 57.5547 13.9157 57.5547Z"
      fill="url(#paint4_linear_16699_190430)"
    />
    <path
      d="M21.769 52.2102L14.4534 44.8945C14.1524 44.5004 13.6789 44.2451 13.1448 44.2451H10.2881C9.37815 44.2451 8.64062 44.9828 8.64062 45.8926V54.6972C8.64062 55.2314 8.89604 55.7048 9.29004 56.0057L21.7692 68.4849L21.769 52.2102Z"
      fill="url(#paint5_linear_16699_190430)"
    />
    <path
      d="M13.1448 56.3448H10.2881C9.37815 56.3448 8.64062 55.6073 8.64062 54.6973V45.8928C8.64062 44.9828 9.37815 44.2453 10.2881 44.2453H13.145C14.0549 44.2453 14.7924 44.9828 14.7924 45.8928V54.6973C14.7924 55.6071 14.0547 56.3448 13.1448 56.3448Z"
      fill="url(#paint6_linear_16699_190430)"
    />
    <path
      d="M13.9157 74.7712H9.51602C8.33136 74.7712 7.37109 73.8109 7.37109 72.6263V62.3966C7.37109 61.212 8.33136 60.2517 9.51602 60.2517H13.9159C15.1006 60.2517 16.0608 61.212 16.0608 62.3966V72.6263C16.0607 73.8109 15.1004 74.7712 13.9157 74.7712Z"
      fill="url(#paint7_linear_16699_190430)"
    />
    <path
      d="M21.7692 69.427V79.9998H16.067L9.2907 73.2218C8.89637 72.9215 8.64062 72.448 8.64062 71.9134V63.1094C8.64062 62.1986 9.37815 61.4611 10.2889 61.4611H13.145C13.6795 61.4611 14.1531 61.7168 14.4534 62.1111L21.7692 69.427Z"
      fill="url(#paint8_linear_16699_190430)"
    />
    <path
      d="M13.1448 73.5613H10.2881C9.37815 73.5613 8.64062 72.8238 8.64062 71.9138V63.1091C8.64062 62.1991 9.37815 61.4616 10.2881 61.4616H13.145C14.0549 61.4616 14.7924 62.1991 14.7924 63.1091V71.9136C14.7924 72.8236 14.0547 73.5613 13.1448 73.5613Z"
      fill="url(#paint9_linear_16699_190430)"
    />
    <path
      d="M1.66211 24.515L21.7673 44.6203V21.6552H1.66211V24.515Z"
      fill="url(#paint10_linear_16699_190430)"
    />
    <path
      d="M22.178 24.5151H1.25115C0.560155 24.5151 0 23.955 0 23.264V20.8166C0 20.1256 0.560155 19.5655 1.25115 19.5655H22.1778C22.8688 19.5655 23.429 20.1256 23.429 20.8166V23.264C23.4292 23.9548 22.869 24.5151 22.178 24.5151Z"
      fill="url(#paint11_linear_16699_190430)"
    />
    <path
      d="M79.5518 79.9998H62.3789V21.6552H82.4843V77.0676C82.4841 78.6871 81.1712 79.9998 79.5518 79.9998Z"
      fill="url(#paint12_linear_16699_190430)"
    />
    <path
      d="M74.6308 40.3382H70.2309C69.0462 40.3382 68.0859 39.3779 68.0859 38.1933V27.9636C68.0859 26.779 69.0462 25.8187 70.2309 25.8187H74.6308C75.8154 25.8187 76.7757 26.779 76.7757 27.9636V38.1933C76.7757 39.3779 75.8154 40.3382 74.6308 40.3382Z"
      fill="url(#paint13_linear_16699_190430)"
    />
    <path
      d="M82.484 34.9938L75.1684 27.6781C74.8674 27.2839 74.3939 27.0287 73.8598 27.0287H71.0029C70.093 27.0287 69.3555 27.7664 69.3555 28.6762V37.4807C69.3555 38.015 69.6109 38.4883 70.0049 38.7893L82.484 51.2685V34.9938Z"
      fill="url(#paint14_linear_16699_190430)"
    />
    <path
      d="M73.8598 39.1283H71.0029C70.093 39.1283 69.3555 38.3908 69.3555 37.4808V28.6763C69.3555 27.7663 70.093 27.0288 71.0029 27.0288H73.8598C74.7698 27.0288 75.5073 27.7663 75.5073 28.6763V37.4808C75.5074 38.3906 74.7698 39.1283 73.8598 39.1283Z"
      fill="url(#paint15_linear_16699_190430)"
    />
    <path
      d="M74.6308 57.5547H70.2309C69.0462 57.5547 68.0859 56.5944 68.0859 55.4098V45.1801C68.0859 43.9955 69.0462 43.0352 70.2309 43.0352H74.6308C75.8154 43.0352 76.7757 43.9955 76.7757 45.1801V55.4098C76.7757 56.5944 75.8154 57.5547 74.6308 57.5547Z"
      fill="url(#paint16_linear_16699_190430)"
    />
    <path
      d="M82.484 52.2102L75.1684 44.8945C74.8674 44.5004 74.3939 44.2451 73.8598 44.2451H71.0029C70.093 44.2451 69.3555 44.9828 69.3555 45.8926V54.6972C69.3555 55.2314 69.6109 55.7048 70.0049 56.0057L82.484 68.4849V52.2102Z"
      fill="url(#paint17_linear_16699_190430)"
    />
    <path
      d="M73.8598 56.3448H71.0029C70.093 56.3448 69.3555 55.6073 69.3555 54.6973V45.8928C69.3555 44.9828 70.093 44.2453 71.0029 44.2453H73.8598C74.7698 44.2453 75.5073 44.9828 75.5073 45.8928V54.6973C75.5074 55.6071 74.7698 56.3448 73.8598 56.3448Z"
      fill="url(#paint18_linear_16699_190430)"
    />
    <path
      d="M74.6308 74.7712H70.2309C69.0462 74.7712 68.0859 73.8109 68.0859 72.6263V62.3966C68.0859 61.212 69.0462 60.2517 70.2309 60.2517H74.6308C75.8154 60.2517 76.7757 61.212 76.7757 62.3966V72.6263C76.7757 73.8109 75.8154 74.7712 74.6308 74.7712Z"
      fill="url(#paint19_linear_16699_190430)"
    />
    <path
      d="M82.484 69.427V77.0675C82.484 78.6869 81.1712 79.9998 79.5518 79.9998H76.7819L70.0055 73.2218C69.6112 72.9215 69.3555 72.448 69.3555 71.9134V63.1094C69.3555 62.1991 70.0935 61.4611 71.0038 61.4611H73.8598C74.3944 61.4611 74.8679 61.7168 75.1682 62.1111L82.484 69.427Z"
      fill="url(#paint20_linear_16699_190430)"
    />
    <path
      d="M73.8598 73.5613H71.0029C70.093 73.5613 69.3555 72.8238 69.3555 71.9138V63.1091C69.3555 62.1991 70.093 61.4616 71.0029 61.4616H73.8598C74.7698 61.4616 75.5073 62.1991 75.5073 63.1091V71.9136C75.5074 72.8236 74.7698 73.5613 73.8598 73.5613Z"
      fill="url(#paint21_linear_16699_190430)"
    />
    <path
      d="M62.3789 24.515L82.4841 44.6203V21.6552H62.3789V24.515Z"
      fill="url(#paint22_linear_16699_190430)"
    />
    <path
      d="M82.8948 24.5152H61.9679C61.277 24.5152 60.7168 23.9551 60.7168 23.2641V20.8167C60.7168 20.1257 61.277 19.5656 61.9679 19.5656H82.8946C83.5856 19.5656 84.1458 20.1257 84.1458 20.8167V23.2641C84.1459 23.9549 83.5858 24.5152 82.8948 24.5152Z"
      fill="url(#paint23_linear_16699_190430)"
    />
    <path
      d="M79.5518 79.9998H62.3789V21.6552H82.4843V77.0676C82.4841 78.6871 81.1712 79.9998 79.5518 79.9998Z"
      fill="url(#paint24_linear_16699_190430)"
    />
    <path
      d="M65.5517 79.9999H18.5977V17.4845C18.5977 15.7317 20.0186 14.3108 21.7713 14.3108H62.378C64.1307 14.3108 65.5517 15.7317 65.5517 17.4845V79.9999Z"
      fill="url(#paint25_linear_16699_190430)"
    />
    <path
      d="M65.5507 17.4853V55.7507L28.9022 19.3085C28.3165 18.9867 28.0723 18.3647 28.0723 17.6486V14.3108H62.3778C64.1301 14.3108 65.5507 15.7314 65.5507 17.4853Z"
      fill="url(#paint26_linear_16699_190430)"
    />
    <path
      d="M56.9235 79.9998H27.2246V53.2958C27.2246 52.38 27.9671 51.6373 28.8831 51.6373H55.265C56.1809 51.6373 56.9235 52.3798 56.9235 53.2958V79.9998Z"
      fill="url(#paint27_linear_16699_190430)"
    />
    <path
      d="M53.9525 79.9998H30.1934V55.9C30.1934 55.123 30.8231 54.4932 31.6001 54.4932H52.5458C53.3227 54.4932 53.9525 55.123 53.9525 55.9V79.9998Z"
      fill="url(#paint28_linear_16699_190430)"
    />
    <path
      d="M54.4333 19.5407H47.363V26.611C47.363 27.6564 46.5156 28.5038 45.4702 28.5038H38.6782C37.6328 28.5038 36.7854 27.6564 36.7854 26.611V19.5407H29.7151C28.6697 19.5407 27.8223 18.6933 27.8223 17.6479V10.8559C27.8223 9.8105 28.6697 8.96309 29.7151 8.96309H36.7854V1.89293C36.7854 0.847532 37.6328 0.00012207 38.6782 0.00012207H45.4702C46.5156 0.00012207 47.363 0.847532 47.363 1.89293V8.96326H54.4333C55.4787 8.96326 56.3261 9.81067 56.3261 10.8561V17.648C56.3261 18.6933 55.4787 19.5407 54.4333 19.5407Z"
      fill="url(#paint29_linear_16699_190430)"
    />
    <path
      d="M45.4701 28.5039H42.0742V0H45.4701C46.5155 0 47.3629 0.847409 47.3629 1.89281V26.6109C47.3629 27.6563 46.5155 28.5039 45.4701 28.5039Z"
      fill="url(#paint30_linear_16699_190430)"
    />
    <path
      d="M56.326 10.8559V14.2519H27.8223V10.8559C27.8223 9.81054 28.6697 8.96313 29.7151 8.96313H54.4332C55.4786 8.96313 56.326 9.81054 56.326 10.8559Z"
      fill="url(#paint31_linear_16699_190430)"
    />
    <path
      d="M52.5467 54.4932H41.668V79.9998H53.9534V55.9C53.9534 55.123 53.3236 54.4932 52.5467 54.4932Z"
      fill="url(#paint32_linear_16699_190430)"
    />
    <path
      d="M1.66211 68.485V77.0677C1.66211 78.6871 2.97497 80 4.59438 80H79.5528C81.1722 80 82.4851 78.6871 82.4851 77.0677V68.485H1.66211Z"
      fill="url(#paint33_linear_16699_190430)"
    />
    <path
      d="M18.5977 44.4571L54.1405 79.9999H65.5517V43.0352H18.5977V44.4571Z"
      fill="url(#paint34_linear_16699_190430)"
    />
    <path
      d="M65.7994 44.2087H18.3488C17.3278 44.2087 16.5 43.381 16.5 42.3599V41.1074C16.5 40.0864 17.3276 39.2587 18.3488 39.2587H65.7996C66.8205 39.2587 67.6483 40.0863 67.6483 41.1074V42.3599C67.6482 43.381 66.8205 44.2087 65.7994 44.2087Z"
      fill="url(#paint35_linear_16699_190430)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_16699_190430"
        x1="1.70913"
        y1="50.8276"
        x2="18.3113"
        y2="50.8276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset="1" stopColor="#D5E8FE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_16699_190430"
        x1="17.4397"
        y1="33.0785"
        x2="4.89239"
        y2="33.0785"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset="1" stopColor="#D5E8FE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_16699_190430"
        x1="17.8823"
        y1="39.2672"
        x2="-4.06193"
        y2="26.5627"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity="0" />
        <stop offset="0.2708" stopColor="#074459" stopOpacity="0.271" />
        <stop offset="0.597" stopColor="#05394A" stopOpacity="0.597" />
        <stop offset="0.9505" stopColor="#032632" stopOpacity="0.951" />
        <stop offset="1" stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_16699_190430"
        x1="8.72098"
        y1="30.083"
        x2="12.5302"
        y2="33.8922"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61DCDE" />
        <stop offset="1" stopColor="#28BEC0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_16699_190430"
        x1="17.4397"
        y1="50.295"
        x2="4.89239"
        y2="50.295"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset="1" stopColor="#D5E8FE" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_16699_190430"
        x1="17.8823"
        y1="56.4837"
        x2="-4.06193"
        y2="43.7792"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity="0" />
        <stop offset="0.2708" stopColor="#074459" stopOpacity="0.271" />
        <stop offset="0.597" stopColor="#05394A" stopOpacity="0.597" />
        <stop offset="0.9505" stopColor="#032632" stopOpacity="0.951" />
        <stop offset="1" stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_16699_190430"
        x1="8.72098"
        y1="47.2995"
        x2="12.5302"
        y2="51.1087"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61DCDE" />
        <stop offset="1" stopColor="#28BEC0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_16699_190430"
        x1="17.4397"
        y1="67.5114"
        x2="4.89239"
        y2="67.5114"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset="1" stopColor="#D5E8FE" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_16699_190430"
        x1="16.9426"
        y1="71.9557"
        x2="-2.26466"
        y2="60.8356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity="0" />
        <stop offset="0.2708" stopColor="#074459" stopOpacity="0.271" />
        <stop offset="0.597" stopColor="#05394A" stopOpacity="0.597" />
        <stop offset="0.9505" stopColor="#032632" stopOpacity="0.951" />
        <stop offset="1" stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_16699_190430"
        x1="8.72098"
        y1="64.516"
        x2="12.5302"
        y2="68.3252"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61DCDE" />
        <stop offset="1" stopColor="#28BEC0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_16699_190430"
        x1="11.7147"
        y1="33.5623"
        x2="11.7147"
        y2="14.5053"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity="0" />
        <stop offset="0.2708" stopColor="#074459" stopOpacity="0.271" />
        <stop offset="0.597" stopColor="#05394A" stopOpacity="0.597" />
        <stop offset="0.9505" stopColor="#032632" stopOpacity="0.951" />
        <stop offset="1" stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_16699_190430"
        x1="11.7146"
        y1="20.1153"
        x2="11.7146"
        y2="26.6347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBD0E4" />
        <stop offset="1" stopColor="#6A7D83" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_16699_190430"
        x1="62.4259"
        y1="50.8276"
        x2="79.0281"
        y2="50.8276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset="1" stopColor="#D5E8FE" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_16699_190430"
        x1="78.1547"
        y1="33.0785"
        x2="65.6074"
        y2="33.0785"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset="1" stopColor="#D5E8FE" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_16699_190430"
        x1="78.5973"
        y1="39.2673"
        x2="56.6531"
        y2="26.5627"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity="0" />
        <stop offset="0.2708" stopColor="#074459" stopOpacity="0.271" />
        <stop offset="0.597" stopColor="#05394A" stopOpacity="0.597" />
        <stop offset="0.9505" stopColor="#032632" stopOpacity="0.951" />
        <stop offset="1" stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_16699_190430"
        x1="69.436"
        y1="30.0831"
        x2="73.2452"
        y2="33.8924"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3FD5D7" />
        <stop offset="1" stopColor="#28BEC0" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_16699_190430"
        x1="78.1547"
        y1="50.295"
        x2="65.6074"
        y2="50.295"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset="1" stopColor="#D5E8FE" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_16699_190430"
        x1="78.5973"
        y1="56.4837"
        x2="56.6531"
        y2="43.7792"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity="0" />
        <stop offset="0.2708" stopColor="#074459" stopOpacity="0.271" />
        <stop offset="0.597" stopColor="#05394A" stopOpacity="0.597" />
        <stop offset="0.9505" stopColor="#032632" stopOpacity="0.951" />
        <stop offset="1" stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_16699_190430"
        x1="69.436"
        y1="47.2996"
        x2="73.2451"
        y2="51.1088"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3FD5D7" />
        <stop offset="1" stopColor="#28BEC0" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_16699_190430"
        x1="78.1547"
        y1="67.5114"
        x2="65.6074"
        y2="67.5114"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset="1" stopColor="#D5E8FE" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_16699_190430"
        x1="77.1085"
        y1="71.6379"
        x2="58.9305"
        y2="61.1137"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity="0" />
        <stop offset="0.2708" stopColor="#074459" stopOpacity="0.271" />
        <stop offset="0.597" stopColor="#05394A" stopOpacity="0.597" />
        <stop offset="0.9505" stopColor="#032632" stopOpacity="0.951" />
        <stop offset="1" stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_16699_190430"
        x1="69.436"
        y1="64.516"
        x2="73.2452"
        y2="68.3252"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3FD5D7" />
        <stop offset="1" stopColor="#28BEC0" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_16699_190430"
        x1="72.4315"
        y1="33.5623"
        x2="72.4315"
        y2="14.5053"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity="0" />
        <stop offset="0.2708" stopColor="#074459" stopOpacity="0.271" />
        <stop offset="0.597" stopColor="#05394A" stopOpacity="0.597" />
        <stop offset="0.9505" stopColor="#032632" stopOpacity="0.951" />
        <stop offset="1" stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_16699_190430"
        x1="72.4314"
        y1="20.1153"
        x2="72.4314"
        y2="26.6347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBD0E4" />
        <stop offset="1" stopColor="#6A7D83" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_16699_190430"
        x1="74.9064"
        y1="50.8276"
        x2="53.9495"
        y2="50.8276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity="0" />
        <stop offset="0.2708" stopColor="#074459" stopOpacity="0.271" />
        <stop offset="0.597" stopColor="#05394A" stopOpacity="0.597" />
        <stop offset="0.9505" stopColor="#032632" stopOpacity="0.951" />
        <stop offset="1" stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_16699_190430"
        x1="31.7797"
        y1="37.7899"
        x2="68.9197"
        y2="74.9299"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset="1" stopColor="#D5E8FE" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_16699_190430"
        x1="52.1952"
        y1="36.6914"
        x2="39.4907"
        y2="-8.51696"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity="0" />
        <stop offset="0.2708" stopColor="#074459" stopOpacity="0.271" />
        <stop offset="0.597" stopColor="#05394A" stopOpacity="0.597" />
        <stop offset="0.9505" stopColor="#032632" stopOpacity="0.951" />
        <stop offset="1" stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_16699_190430"
        x1="55.2782"
        y1="79.5084"
        x2="22.661"
        y2="46.8914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDF5FF" />
        <stop offset="1" stopColor="#D5E8FE" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_16699_190430"
        x1="35.9281"
        y1="61.5137"
        x2="45.4952"
        y2="71.0809"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3FD5D7" />
        <stop offset="1" stopColor="#28BEC0" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_16699_190430"
        x1="34.2026"
        y1="6.38028"
        x2="52.3932"
        y2="24.5709"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B699FE" />
        <stop offset="1" stopColor="#8352FE" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_16699_190430"
        x1="43.9629"
        y1="14.2479"
        x2="28.547"
        y2="20.3187"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" stopOpacity="0" />
        <stop offset="0.0467" stopColor="#8352FE" stopOpacity="0.05" />
        <stop offset="0.4669" stopColor="#8352FE" stopOpacity="0.46" />
        <stop offset="0.7991" stopColor="#8352FE" stopOpacity="0.8" />
        <stop offset="1" stopColor="#8352FE" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_16699_190430"
        x1="42.0385"
        y1="12.1114"
        x2="42.7206"
        y2="19.7511"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" stopOpacity="0" />
        <stop offset="0.0467" stopColor="#8352FE" stopOpacity="0.05" />
        <stop offset="0.4669" stopColor="#8352FE" stopOpacity="0.46" />
        <stop offset="0.7991" stopColor="#8352FE" stopOpacity="0.8" />
        <stop offset="1" stopColor="#8352FE" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_16699_190430"
        x1="46.9097"
        y1="67.0529"
        x2="27.5504"
        y2="56.9332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0B799D" stopOpacity="0" />
        <stop offset="0.1739" stopColor="#117D9E" stopOpacity="0.174" />
        <stop offset="0.414" stopColor="#2389A0" stopOpacity="0.414" />
        <stop offset="0.6928" stopColor="#409CA3" stopOpacity="0.693" />
        <stop offset="0.9986" stopColor="#69B7A8" stopOpacity="0.999" />
        <stop offset="1" stopColor="#69B7A8" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_16699_190430"
        x1="42.0736"
        y1="73.2433"
        x2="42.0736"
        y2="81.8799"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity="0" />
        <stop offset="0.2708" stopColor="#074459" stopOpacity="0.1" />
        <stop offset="0.597" stopColor="#05394A" stopOpacity="0.25" />
        <stop offset="0.9505" stopColor="#032632" stopOpacity="0.5" />
        <stop offset="1" stopColor="#03232E" stopOpacity="0.5" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_16699_190430"
        x1="42.0747"
        y1="63.6736"
        x2="42.0747"
        y2="24.6225"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07485E" stopOpacity="0" />
        <stop offset="0.2708" stopColor="#074459" stopOpacity="0.271" />
        <stop offset="0.597" stopColor="#05394A" stopOpacity="0.597" />
        <stop offset="0.9505" stopColor="#032632" stopOpacity="0.951" />
        <stop offset="1" stopColor="#03232E" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_16699_190430"
        x1="42.0741"
        y1="39.8088"
        x2="42.0741"
        y2="46.3282"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBD0E4" />
        <stop offset="1" stopColor="#6A7D83" />
      </linearGradient>
    </defs>
  </svg>
);
