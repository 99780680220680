import { useTranslation } from "react-i18next";
import "./NoWidgets.scss";
import { Button } from "libs/ui/Button";
import { useDashboardStatsWidgetContext } from "../DashboardStatsWidgetContext";

export function NoWidgets() {
  const { t } = useTranslation("translation", {
    keyPrefix: "DashboardPage.DashboardStatsPage.NoWidgets",
  });
  const { hasWidgets, toggleAddWidgetModal } = useDashboardStatsWidgetContext();

  if (hasWidgets) {
    return null;
  }

  return (
    <div className="NoWidgets">
      <h3 className="NoWidgets__title">{t("title")}</h3>
      <p className="NoWidgets__description">{t("description")}</p>
      <Button
        data-testid="OpenAddModal"
        buttonType="primary"
        buttonSize="medium"
        buttonIcon={{ icon: "Plus" }}
        onClick={toggleAddWidgetModal}
      >
        {t("addWidgetButton")}
      </Button>
    </div>
  );
}
