import { IconType } from "libs/ui";
import { MeasurementTrendType } from "models/TestModels";

export function getMeasurementTrendIcon(trendType?: MeasurementTrendType): IconType | null {
  switch (trendType) {
    case "Up":
      return "ArrowUpRight";
    case "Down":
      return "ArrowDownRight";
    case "Equal":
      return "Stale";
    case "None":
    default:
      return null;
  }
}
