/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OldLaboratorySectionModel } from './OldLaboratorySectionModel';
import {
    OldLaboratorySectionModelFromJSON,
    OldLaboratorySectionModelFromJSONTyped,
    OldLaboratorySectionModelToJSON,
} from './OldLaboratorySectionModel';

/**
 * 
 * @export
 * @interface UpdateLaboratorySectionCommandModel
 */
export interface UpdateLaboratorySectionCommandModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateLaboratorySectionCommandModel
     */
    tenantName: string;
    /**
     * 
     * @type {OldLaboratorySectionModel}
     * @memberof UpdateLaboratorySectionCommandModel
     */
    configurationSection: OldLaboratorySectionModel;
}

/**
 * Check if a given object implements the UpdateLaboratorySectionCommandModel interface.
 */
export function instanceOfUpdateLaboratorySectionCommandModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tenantName" in value;
    isInstance = isInstance && "configurationSection" in value;

    return isInstance;
}

export function UpdateLaboratorySectionCommandModelFromJSON(json: any): UpdateLaboratorySectionCommandModel {
    return UpdateLaboratorySectionCommandModelFromJSONTyped(json, false);
}

export function UpdateLaboratorySectionCommandModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLaboratorySectionCommandModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantName': json['TenantName'],
        'configurationSection': OldLaboratorySectionModelFromJSON(json['ConfigurationSection']),
    };
}

export function UpdateLaboratorySectionCommandModelToJSON(value?: UpdateLaboratorySectionCommandModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TenantName': value.tenantName,
        'ConfigurationSection': OldLaboratorySectionModelToJSON(value.configurationSection),
    };
}

