import * as React from "react";
import { SVGProps } from "react";
const SvgUserPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 2.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5ZM5.25 7a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0Z"
      fill="currentColor"
    />
    <path
      d="M20.75 8a.75.75 0 0 0-1.5 0v2.25H17a.75.75 0 0 0 0 1.5h2.25V14a.75.75 0 0 0 1.5 0v-2.25H23a.75.75 0 0 0 0-1.5h-2.25V8ZM5 14.25A4.75 4.75 0 0 0 .25 19v2a.75.75 0 0 0 1.5 0v-2A3.25 3.25 0 0 1 5 15.75h7A3.25 3.25 0 0 1 15.25 19v2a.75.75 0 0 0 1.5 0v-2A4.75 4.75 0 0 0 12 14.25H5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgUserPlus;
