import { Switch } from "libs/ui";
import { cloneDeep, isEqual } from "lodash-es";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { globalConfigStore } from "config/globalConfig";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { isLoaded } from "models/loadable";
import {
  RuleEngineFormElement,
  RuleEngineSection,
  RuleEngineSectionActions,
} from "../../../elements";

export function UsersVisibility() {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.rule-engine.user-view.users-visibility",
  });
  const { appConfig, isLoading } = useGlobalConfigContext();

  const [hideDeletedUsers, setHideDeletedUsers] = useState(
    !!appConfig?.general.includeDeletedUsers,
  );

  const toggleHideDeletedUsers = useCallback(() => {
    setHideDeletedUsers(prevValue => !prevValue);
  }, []);

  const saveDisabled =
    isLoading || isEqual(hideDeletedUsers, !!appConfig?.general.includeDeletedUsers);

  const onSave = useCallback(async () => {
    const newConfig = cloneDeep(globalConfigStore.getOrgConfig())!;
    newConfig.users.includeDeletedUsers = hideDeletedUsers;

    try {
      await globalConfigStore.saveConfig(newConfig);
      const newConfigMapped = globalConfigStore.getConfig();

      if (isLoaded(newConfigMapped)) {
        setHideDeletedUsers(newConfigMapped.value.general.includeDeletedUsers);
      }
    } catch {}
  }, [hideDeletedUsers]);

  const onCancel = useCallback(() => {
    setHideDeletedUsers(!!appConfig?.general.includeDeletedUsers);
  }, [appConfig?.general.includeDeletedUsers]);

  return (
    <RuleEngineSection>
      <RuleEngineFormElement
        title={t("deletedUsers.sectionTitle")}
        subtitle={t("deletedUsers.sectionSubtitle")}
        description={t("deletedUsers.description")}
      >
        <Switch
          name={"hide-deleted-users"}
          className="RuleEngine__switch"
          loading={isLoading}
          checked={!hideDeletedUsers}
          onChange={toggleHideDeletedUsers}
        />
      </RuleEngineFormElement>
      <RuleEngineFormElement
        title={t("deactivatedUsers.sectionTitle")}
        subtitle={t("deactivatedUsers.sectionSubtitle")}
        description={t("deactivatedUsers.description")}
      >
        <Switch name={"hide-deactivated-users"} className="RuleEngine__switch" />
      </RuleEngineFormElement>
      <RuleEngineFormElement
        title={t("invitedUsers.sectionTitle")}
        subtitle={t("invitedUsers.sectionSubtitle")}
        description={t("invitedUsers.description")}
      >
        <Switch name={"hide-invited-users"} className="RuleEngine__switch" />
      </RuleEngineFormElement>
      <RuleEngineSectionActions
        onCancel={onCancel}
        cancelButtonDisabled={isLoading}
        onSave={onSave}
        saveButtonDisabled={saveDisabled}
      />
    </RuleEngineSection>
  );
}
