import { RegionType, LanguageType, MeasureUnitType, TimeZoneType } from "api/query/models";
import { GlobalPreference } from "models/globalPreference";

export function getCurrentGlobalPreferences(): GlobalPreference {
  const languageType = document.documentElement.lang as LanguageType;
  const regionType = navigator.language as RegionType;
  const timeZoneType = Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZoneType;
  const measureUnitType: MeasureUnitType = regionType.toLowerCase().includes("us")
    ? "Imperial"
    : "Metric";

  return { languageType, regionType, timeZoneType, measureUnitType } as const;
}
