import { UserGraphModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import sub from "date-fns/sub";
import { LoadableModel, defaultModel, failed, loaded, loading } from "models/loadable";
import { PropsWithChildren, useCallback, useMemo, useReducer } from "react";
import { createSafeContext } from "utils/createSafeContext";

export interface GraphMeasurements {
  SerumCreatinine: LoadableModel<UserGraphModel>;
  Egfr: LoadableModel<UserGraphModel>;
  BloodPressure: LoadableModel<UserGraphModel>;
  Glucose: LoadableModel<UserGraphModel>;
  UACR: LoadableModel<UserGraphModel>;
  UrineCreatinine: LoadableModel<UserGraphModel>;
  UrineAlbumin: LoadableModel<UserGraphModel>;
  BMI: LoadableModel<UserGraphModel>;
  Height: LoadableModel<UserGraphModel>;
  Weight: LoadableModel<UserGraphModel>;
  SemiQuantitativeUACR: LoadableModel<UserGraphModel>;
}

export type GraphMeasurementsKeys = keyof GraphMeasurements;

interface Action {
  type: GraphMeasurementsKeys;
  payload: LoadableModel<UserGraphModel>;
}

const DATA_INIT: GraphMeasurements = {
  SerumCreatinine: defaultModel(),
  Egfr: defaultModel(),
  BloodPressure: defaultModel(),
  Glucose: defaultModel(),
  UACR: defaultModel(),
  UrineCreatinine: defaultModel(),
  UrineAlbumin: defaultModel(),
  BMI: defaultModel(),
  Height: defaultModel(),
  Weight: defaultModel(),
  SemiQuantitativeUACR: defaultModel(),
};

function reducer(state: GraphMeasurements, action: Action): GraphMeasurements {
  switch (action.type) {
    case "SerumCreatinine":
      return {
        ...state,
        SerumCreatinine: action.payload,
      };

    case "Egfr":
      return {
        ...state,
        Egfr: action.payload,
      };

    case "BloodPressure":
      return {
        ...state,
        BloodPressure: action.payload,
      };

    case "Glucose":
      return {
        ...state,
        Glucose: action.payload,
      };

    case "UACR":
      return {
        ...state,
        UACR: action.payload,
      };

    case "UrineCreatinine":
      return {
        ...state,
        UrineCreatinine: action.payload,
      };

    case "UrineAlbumin":
      return {
        ...state,
        UrineAlbumin: action.payload,
      };

    case "BMI":
      return {
        ...state,
        BMI: action.payload,
      };

    case "Height":
      return {
        ...state,
        Height: action.payload,
      };

    case "Weight":
      return {
        ...state,
        Weight: action.payload,
      };

    case "SemiQuantitativeUACR": {
      return {
        ...state,
        SemiQuantitativeUACR: action.payload,
      };
    }

    default:
      throw Error(`${action.type} not implemented`);
  }
}

type GetGraphData = (
  organizationId: string,
  patientId: string,
  types: GraphMeasurementsKeys[],
) => void;
interface DataContextModel {
  data: GraphMeasurements;
  getGraphData: GetGraphData;
  // getCachedGraphData: (...params: Parameters<GetGraphData>) => void;
}

const Context = createSafeContext<DataContextModel>();

export const useGraphDataAPIContext = Context.hook;

export function GraphDataAPIContextProvider({ children }: Readonly<PropsWithChildren>) {
  const [data, dispatch] = useReducer(reducer, DATA_INIT);

  const getGraphData = useCallback(
    (organizationId: string, patientId: string, types: GraphMeasurementsKeys[]) => {
      const promises = types.map(async type => {
        dispatch({ type, payload: loading() });
        try {
          const response = await CarnaApiQuery.GraphsApi.get({
            organizationId,
            userEntityId: patientId,
            filters: {
              from: sub(new Date(), { years: 100 }),
              until: new Date(),
              measurementTypes: [type],
            },
          });
          return { type, payload: loaded(response?.organization?.user) };
        } catch (error) {
          return { type, payload: failed(error) };
        }
      });

      Promise.allSettled(promises).then(results => {
        results.forEach(result => {
          dispatch(result.status === "fulfilled" ? result.value : result.reason);
        });
      });
    },
    [],
  );

  // TODO implement some proper caching
  // This will consume huge amount of memory, depending on the data
  // const getCachedGraphData = useCallback(
  //   (...params: Parameters<typeof getGraphData>) => {
  //     const requestFilters = params[2];

  //     const nonLoadedFilters = requestFilters.filter(reqFilter => !isLoaded(data[reqFilter]));

  //     getGraphData(params[0], params[1], nonLoadedFilters);
  //   },
  //   [data, getGraphData],
  // );

  const value = useMemo(() => ({ data, getGraphData }), [data, getGraphData]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
