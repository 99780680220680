import { AdministratorsPerOrganizationTable } from "components/AppTables/AdministratorsPerOrganizationTable";
import { AdministratorsTableStateProvider } from "components/AppTables/AllAdministratorsTable/context";
import { DevicesPerOrganizationTable } from "components/AppTables/DevicesPerOrganizationTable";
import { HCPsPerOrganizationTable } from "components/AppTables/HCPsPerOrganizationTable";
import { OrganizationsTableStateProvider } from "components/AppTables/OrganizationsTable/context";
import { PartnersPerOrganizationTable } from "components/AppTables/PartnersPerOrganizationTable";
import { PatientsPerOrganizationTable } from "components/AppTables/PatientsPerEntity/PatientsPerOrganizationTable";
import { TestsPerOrganizationTable } from "components/AppTables/TestsPerOrganizationTable";
import { DetailsPageContextProvider } from "components/PageTemplate/DetailsPage/context";
import { Settings } from "pages/Settings";
import { GlobalPreferences } from "pages/Settings/GlobalPreferences";
import { Login } from "pages/Settings/Login";
import { Details as OrganizationsDetails } from "pages/Settings/Organizations/Details";
import { OrganizationDetailsServiceContextProvider } from "pages/Settings/Organizations/Details/Context";
import { EventEnrichedTabsForSettingsOrg } from "pages/Settings/Organizations/Details/EventEnrichedTabsForSettingsOrg";
import { SettingsOrgLaboratoryContextProvider } from "pages/Settings/Organizations/Details/LaboratoryContext";
import { Parent as OrganizationsParent } from "pages/Settings/Organizations/Parent";
import { PersonalInformation } from "pages/Settings/PersonalInformation";
import { RuleEngine } from "pages/Settings/RuleEngine";
import { Laboratory } from "pages/Settings/RuleEngine/Laboratory";
import { UserView } from "pages/Settings/RuleEngine/UserView";
import { SecurityAndPrivacy } from "pages/Settings/SecurityAndPrivacy";
import { Support } from "pages/Settings/Support";
import { Details as UsersManagementDetails } from "pages/Settings/UsersManagement/Details";
import { Parent as UsersManagementParent } from "pages/Settings/UsersManagement/Parent";
import { applyTo } from "ramda";
import { Navigate, Route } from "react-router";
import { Outlet } from "react-router-dom";
import { mainRoutePaths, settingsRoutePaths } from "router";
import { AccessLevel } from "../utils/AccessLevel";

export const SettingsRoutes = (
  <Route
    path={mainRoutePaths.settings}
    element={
      <DetailsPageContextProvider>
        <Outlet />
      </DetailsPageContextProvider>
    }
  >
    <Route index element={<Settings />} />
    <Route path={settingsRoutePaths.globalPreferences} element={<GlobalPreferences />} />
    <Route path={settingsRoutePaths.login} element={<Login />} />
    <Route
      path={settingsRoutePaths.projectOrganizations}
      element={<AccessLevel check={applyTo("manage-organization")} />}
    >
      <Route
        index
        element={
          <OrganizationsTableStateProvider>
            <OrganizationsParent />
          </OrganizationsTableStateProvider>
        }
      />

      <Route
        path=":organizationId/*"
        element={
          <OrganizationDetailsServiceContextProvider>
            <OrganizationsDetails />
          </OrganizationDetailsServiceContextProvider>
        }
      >
        {/**
         * Administrators table is available for Main organization only
         * Check `src/pages/Settings/Organizations/Details/index.tsx`
         */}
        <Route path="user-management" element={<AdministratorsPerOrganizationTable />} />
        {/**
         * Partners table is available for non Main organizations
         */}
        <Route path="partners" element={<PartnersPerOrganizationTable />} />
        <Route path="hcps" element={<HCPsPerOrganizationTable />} />
        <Route path="devices" element={<DevicesPerOrganizationTable />} />
        <Route path="patients" element={<PatientsPerOrganizationTable />} />

        <Route
          path="tests"
          element={
            <SettingsOrgLaboratoryContextProvider>
              <EventEnrichedTabsForSettingsOrg />
            </SettingsOrgLaboratoryContextProvider>
          }
        >
          <Route
            path="all"
            element={
              <TestsPerOrganizationTable
                appliedColumns={[
                  "measurementType",
                  "date",
                  "testResult",
                  "patient",
                  "testedBy",
                  "testingMethod",
                ]}
              />
            }
          />

          <Route
            path="serumCreatinine"
            element={
              <TestsPerOrganizationTable
                measurementType="SerumCreatinine"
                measurementTypeItem="SerumCreatinine"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "serumCreatinine",
                  "patient",
                  "testedBy",
                  "testingMethod",
                ]}
              />
            }
          />
          <Route
            path="egfr"
            element={
              <TestsPerOrganizationTable
                measurementType="SerumCreatinine"
                measurementTypeItem="Egfr"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "eGFR",
                  "patient",
                  "testedBy",
                  "testingMethod",
                ]}
              />
            }
          />

          <Route
            path="bmi"
            element={
              <TestsPerOrganizationTable
                measurementType="BMI"
                measurementTypeItem="Bmi"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "bmi",
                  "patient",
                  "testedBy",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />

          <Route
            path="height"
            element={
              <TestsPerOrganizationTable
                measurementType="BMI"
                measurementTypeItem="Height"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "height",
                  "patient",
                  "testedBy",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />

          <Route
            path="weight"
            element={
              <TestsPerOrganizationTable
                measurementType="BMI"
                measurementTypeItem="Weight"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "weight",
                  "patient",
                  "testedBy",
                  "testingMethod",
                  "actions",
                ]}
              />
            }
          />

          <Route
            path="bloodPressure"
            element={
              <TestsPerOrganizationTable
                measurementType="BloodPressure"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "bloodPressure",
                  "patient",
                  "testedBy",
                  "testingMethod",
                ]}
              />
            }
          />

          <Route
            path="glucose"
            element={
              <TestsPerOrganizationTable
                measurementType="Glucose"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "glucose",
                  "patient",
                  "testedBy",
                  "testingMethod",
                ]}
              />
            }
          />

          <Route
            path="urineCreatinine"
            element={
              <TestsPerOrganizationTable
                measurementType="UACR"
                measurementTypeItem="UrineCreatinine"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "urineCreatinine",
                  "patient",
                  "testedBy",
                  "testingMethod",
                ]}
              />
            }
          />

          <Route
            path="urineAlbumin"
            element={
              <TestsPerOrganizationTable
                measurementType="UACR"
                measurementTypeItem="UrineAlbumin"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "urineAlbumin",
                  "patient",
                  "testedBy",
                  "testingMethod",
                ]}
              />
            }
          />

          <Route
            path="uacr"
            element={
              <TestsPerOrganizationTable
                measurementType="UACR"
                measurementTypeItem="Uacr"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "uacr",
                  "patient",
                  "testedBy",
                  "testingMethod",
                ]}
              />
            }
          />

          <Route
            path="semiQuantitativeUacr"
            element={
              <TestsPerOrganizationTable
                measurementType="SemiQuantitativeUACR"
                measurementTypeItem="SemiQuantitativeUacr"
                appliedColumns={[
                  "measurementType",
                  "date",
                  "squacr",
                  "patient",
                  "testedBy",
                  "testingMethod",
                ]}
              />
            }
          />

          <Route path="*" element={<Navigate to="all" replace />} />
        </Route>

        <Route path="*" element={<Navigate to="user-management" replace />} />
      </Route>
    </Route>

    <Route path={settingsRoutePaths.personalInformation} element={<PersonalInformation />} />
    <Route path={settingsRoutePaths.securityAndPrivacy} element={<SecurityAndPrivacy />} />
    <Route path={settingsRoutePaths.support} element={<Support />} />
    <Route
      path={settingsRoutePaths.users}
      element={<AccessLevel check={applyTo("manage-users")} />}
    >
      <Route
        index
        element={
          <AdministratorsTableStateProvider>
            <UsersManagementParent />
          </AdministratorsTableStateProvider>
        }
      />
      <Route path={":organizationId/:role/:userId"} element={<UsersManagementDetails />} />
    </Route>
    <Route path={settingsRoutePaths.ruleEngine}>
      <Route index element={<RuleEngine />} />
      <Route path={settingsRoutePaths.ruleEngineUserView} element={<UserView />} />
      <Route path={settingsRoutePaths.ruleEngineLaboratory} element={<Laboratory />} />
    </Route>
  </Route>
);
