import { MeasurementResponseModel, UnitsModel } from "api/query";
import { TestUnitType } from "components/AppTables/common/TestsTable/utils";
import {
  BMIModel,
  BloodPressureModel,
  GlucoseModel,
  SQUACRModel,
  SerumCreatinineModel,
  UACRModel,
} from "models/TestModels";

export const mapSideDetailsUnits = (
  data: MeasurementResponseModel | undefined,
  unitType: keyof UnitsModel | TestUnitType | undefined,
) => {
  switch (data?.measurementType) {
    case "SerumCreatinine":
      return {
        SerumCreatinine: {
          SerumCreatinine: {
            ...data.item["SerumCreatinine"],
          },
          Egfr: {
            ...data.item["Egfr"],
          },
        } satisfies SerumCreatinineModel,
      };
    case "Glucose":
      return {
        Glucose: {
          Glucose: {
            ...data.item["Glucose"],
          },
        } satisfies GlucoseModel,
      };
    case "UACR":
      return {
        UACR: {
          Uacr: {
            ...data.item["Uacr"],
          },
          UrineCreatinine: {
            ...data.item["UrineCreatinine"],
          },
          UrineAlbumin: {
            ...data.item["UrineAlbumin"],
          },
        } satisfies UACRModel,
      };
    case "BloodPressure":
      return {
        BloodPressure: {
          Systolic: {
            ...data.item["Systolic"],
          },
          Diastolic: {
            ...data.item["Diastolic"],
          },
        } satisfies BloodPressureModel,
      };
    case "BMI":
      return {
        BMI: {
          Bmi: {
            ...data.item["Bmi"],
          },
          Height: {
            ...data.item["Height"],
          },
          Weight: {
            ...data.item["Weight"],
          },
        } satisfies BMIModel,
      };
    case "SemiQuantitativeUACR":
      return {
        SemiQuantitativeUACR: {
          SemiQuantitativeUacr: {
            ...data.item["SemiQuantitativeUacr"],
          },
          SemiQuantitativeUrineCreatinine: {
            ...data.item["SemiQuantitativeUrineCreatinine"],
          },
          SemiQuantitativeUrineAlbumin: {
            ...data.item["SemiQuantitativeUrineAlbumin"],
          },
        } satisfies SQUACRModel,
      };
    default:
      throw Error(`${unitType} type is not implemented or it does not exist!`);
  }
};
