import "./PhysicalSection.scss";

import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { Imperial } from "./Imperial";
import { Metric } from "./Metric";
import { PatientData, PhysicalSectionProps } from "./model";

export function PhysicalSection<T extends PatientData>(props: PhysicalSectionProps<T>) {
  const { globalPreference } = useGlobalPreferenceContext();

  return globalPreference?.measureUnitType.toString() === "Metric" ? (
    <Metric {...props} />
  ) : (
    <Imperial {...props} />
  );
}
