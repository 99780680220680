import * as AddModal from "components/AddModal/Test";
import { AddModalForwardedProps } from "components/AddModal/model";
import { TestFormModel } from "components/Forms/Test/model";
import { DetailsCommonPerson } from "components/PageTemplate/DetailsPage/DetailsCommonPerson";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { Button } from "libs/ui";
import { TabsForRouter } from "libs/ui/TabsForRouter";
import { MeasurementItemUIType, MeasurementUIModel } from "models/TestModels";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMatch, useParams } from "react-router-dom";
import { mainRoutePaths } from "router";
import { RouteParams } from "router/model";
import { usePatientDetailsServiceContext } from "./Context";
import { DetailsForm } from "./DetailsForm";
import "./Graph/Graph.scss";
import { GraphMeasurementsKeys, useGraphDataAPIContext } from "./Graph/GraphDataAPIContext";
import { usePatientLaboratoryContext } from "./LaboratoryContext";
import { WidgetType, useOverviewAPIContext } from "./Overview/OverviewAPIContext";
import { getListOfMeasurementWidgetsForRefresh } from "./helper";

type RouteTab =
  | MeasurementItemUIType
  | "BloodPressure"
  | "UACR"
  | "BMI"
  | "All"
  | "SemiQuantitativeUACR";

export function routeMatchesForCurrentMeasurementType(
  routeTab: RouteTab,
  measurements: MeasurementUIModel[],
) {
  if (routeTab === "All") {
    return true;
  }

  return (
    measurements.some(measurement => measurement.measurementType === routeTab) ||
    measurements.some(measurement => measurement.items.some(item => item.type === routeTab))
  );
}

function getMeasurementTypePerTabRoute(routePath?: string) {
  switch (routePath) {
    case "serumCreatinine":
      return "SerumCreatinine";

    case "egfr":
      return "Egfr";

    case "bloodPressure":
      return "BloodPressure";

    case "glucose":
      return "Glucose";

    case "urineCreatinine":
      return "UrineCreatinine";

    case "urineAlbumin":
      return "UrineAlbumin";

    case "uacr":
      return "UACR";

    case "bmi":
      return "BMI";

    case "height":
      return "Height";

    case "weight":
      return "Weight";

    case "semiQuantitativeUacr":
      return "SemiQuantitativeUACR";

    case "all":
      return "All";

    default:
      throw new Error(`${routePath} case not implemented`);
  }
}

function shouldUpdateCurrentRoute(route: string | undefined, measurements: MeasurementUIModel[]) {
  return (
    route &&
    routeMatchesForCurrentMeasurementType(getMeasurementTypePerTabRoute(route), measurements)
  );
}

export function Details() {
  const { t } = useTranslation("translation", { keyPrefix: "PatientDetails" });
  const { organizationId = "", patientId = "" } = useParams<RouteParams["patientDetails"]>();
  const { detailsService } = usePatientDetailsServiceContext();
  const tableContext = usePatientLaboratoryContext();
  const { getWidgetData } = useOverviewAPIContext();

  const refreshWidgets = useCallback(
    (widgets: WidgetType[]) => getWidgetData(organizationId, patientId, widgets),
    [getWidgetData, organizationId, patientId],
  );
  const matchTestsTab = useMatch({
    path: `${mainRoutePaths.patientDetails}/:selectedSubTabRoute`,
    end: true,
  });
  const matchGraphTab = useMatch({
    path: `${mainRoutePaths.patientGraphs}/:selectedSubTabRoute`,
    end: true,
  });
  const matchOverviewTab = useMatch({
    path: `${mainRoutePaths.patientOverview}`,
    end: true,
  });

  const [state, send] = detailsService;

  const [, , tableActor] = tableContext.tableService;

  const addModalRef = useRef<AddModalForwardedProps>(null);
  const { isHcp } = useAdditionalAuthInformationContext();
  const { appConfig } = useGlobalConfigContext();
  const { data, getGraphData } = useGraphDataAPIContext();
  // AvatarState is in DetailsForm

  const onClose = useCallback(() => send({ type: "CANCEL_EDIT" }), [send]);

  const refreshComponents = useCallback(
    (testData: TestFormModel) => {
      if (
        testData.measurements.find(el => el.measurementType === "BMI") &&
        state.matches("info.show")
      ) {
        send({ type: "LOAD_DATA" });
      }

      //#region Table update
      if (
        shouldUpdateCurrentRoute(matchTestsTab?.params.selectedSubTabRoute, testData.measurements)
      ) {
        tableContext.fetch();
      }
      //#endregion

      //#region Graph update
      if (
        shouldUpdateCurrentRoute(matchGraphTab?.params.selectedSubTabRoute, testData.measurements)
      ) {
        const filter = getMeasurementTypePerTabRoute(matchGraphTab?.params.selectedSubTabRoute);

        getGraphData(
          organizationId,
          patientId,
          filter === "All" ? (Object.keys(data) as GraphMeasurementsKeys[]) : [filter],
        );
      }
      //#endregion

      //#region Overview update
      if (matchOverviewTab) {
        const refreshableWidgetsList = new Set<WidgetType>(
          getListOfMeasurementWidgetsForRefresh(testData.measurements),
        );

        testData.measurements.forEach(measurement => {
          switch (measurement.measurementType) {
            case "UACR":
            case "SemiQuantitativeUACR":
            case "SerumCreatinine":
              refreshableWidgetsList.add("HeatMap");
              break;
            case "BMI":
              refreshableWidgetsList.add("PatientInfo");
          }
        });

        if (refreshWidgets.length > 0) {
          refreshWidgets([...refreshableWidgetsList]);
        }
      }

      //#endregion
    },
    [
      data,
      getGraphData,
      matchGraphTab?.params.selectedSubTabRoute,
      matchOverviewTab,
      matchTestsTab?.params.selectedSubTabRoute,
      organizationId,
      patientId,
      refreshWidgets,
      send,
      state,
      tableContext,
    ],
  );

  const patientCanHaveMeasurements =
    state.context.data?.status !== "Deactivated" && state.context.data?.status !== "Deleted";

  const showAddTestButton = patientCanHaveMeasurements && isHcp;

  return (
    <DetailsCommonPerson
      detailsPageControlsSlot={
        showAddTestButton ? (
          <Button
            onClick={() => addModalRef?.current?.toggleModal()}
            buttonType="primary"
            buttonSize="medium"
            buttonIcon={{ icon: "Plus" }}
            data-testid="AddTest"
          >
            {t("addTest")}
          </Button>
        ) : null
      }
      detailsService={detailsService}
      detailsForm={
        <DetailsForm
          service={detailsService}
          patientId={patientId}
          organizationId={organizationId}
          onClose={onClose}
        />
      }
    >
      <TabsForRouter
        tabs={[
          ...(appConfig?.pages.PatientsPage.Details.ShowOverviewTab
            ? [
                {
                  routePath: "overview",
                  label: t("tabs.labelOverview"),
                  to: "overview",
                },
              ]
            : []),
          {
            routePath: "tests",
            to: "tests/all",
            label: t("tabs.labelTests"),
            onClick: () => {
              tableActor.send({ type: "UPDATE_FILTERS" });
            },
          },
          ...(appConfig?.laboratory?.measurements?.viewOptions?.enableGraphView
            ? [
                {
                  routePath: "graph",
                  to: "graph/all",
                  label: t("tabs.labelGraph"),
                },
              ]
            : []),
        ]}
      />

      <AddModal.Test
        ref={addModalRef}
        person={state.context.data}
        onSuccessCb={refreshComponents}
      />
    </DetailsCommonPerson>
  );
}
