import { ECElementEvent, ECharts } from "echarts/core";
import { ForwardedRef, MutableRefObject, useEffect, useImperativeHandle } from "react";
import { LineChartForwardedProps } from "../MiniChart/model";

export function useSetupLineChartHandles(
  ref: ForwardedRef<LineChartForwardedProps>,
  chartRef: MutableRefObject<ECharts | undefined | null>,
  onNodeClick?: (val: ECElementEvent) => void,
) {
  useImperativeHandle(
    ref,
    () => {
      /**
       * ! We cannot depend on chartRef.current
       * ! chartRef initializes only once on mount
       * ! we have to forward chartRef as whole coz of memory reference,
       *
       * ! useImperativeHandle won't be called again
       * ! even if we put chartRef.current as dependency
       */
      return {
        forwardedChartRef: chartRef,
      };
    },
    [chartRef],
  );

  //#region event handlers
  useEffect(() => {
    if (!onNodeClick) {
      return;
    }

    const cTRef = chartRef.current;

    cTRef?.on("click", onNodeClick);

    return () => {
      cTRef?.off("click", onNodeClick);
    };
  }, [chartRef, onNodeClick]);

  //#endregion
}
