import * as React from "react";
import { SVGProps } from "react";
const SvgUACR = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.294 13.853a7.147 7.147 0 1 1-14.294 0c0-2.788 3.565-7.575 5.66-10.146a1.92 1.92 0 0 1 2.975 0c2.095 2.571 5.66 7.358 5.66 10.146Z"
      fill="url(#uacr_svg__a)"
    />
    <path
      d="M5 13.853a7.147 7.147 0 1 0 14.294 0c0-.316-.046-.657-.13-1.018H5.13c-.084.36-.13.702-.13 1.018Z"
      fill="url(#uacr_svg__b)"
    />
    <defs>
      <linearGradient
        id="uacr_svg__a"
        x1={8.677}
        y1={9.612}
        x2={15.336}
        y2={16.271}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDE68A" />
        <stop offset={1} stopColor="#FBBF24" />
      </linearGradient>
      <linearGradient
        id="uacr_svg__b"
        x1={12.147}
        y1={16.906}
        x2={12.147}
        y2={22.058}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBBF24" stopOpacity={0} />
        <stop offset={1} stopColor="#FBBF24" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgUACR;
