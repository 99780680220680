import { SettingsPage } from "components/PageTemplate/SettingsPage";
import { PrivacyLinks } from "./PrivacyLinks";
import { SettingsCards } from "./SettingsCards";

export function Settings() {
  return (
    <SettingsPage>
      <SettingsCards />
      <PrivacyLinks />
    </SettingsPage>
  );
}
