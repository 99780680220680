import * as React from "react";
import { SVGProps } from "react";
const SvgCircleError = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#circle-error_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-8.234-2.634a.8.8 0 0 0-1.132-1.132L12 10.87 9.366 8.234a.8.8 0 0 0-1.132 1.132L10.87 12l-2.635 2.634a.8.8 0 0 0 1.132 1.132L12 13.13l2.634 2.635a.8.8 0 0 0 1.132-1.132L13.13 12l2.635-2.634Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="circle-error_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCircleError;
