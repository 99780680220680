import * as React from "react";
import { SVGProps } from "react";
const SvgMaximize = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.25 3a.75.75 0 0 1 .75-.75h6a.748.748 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V4.81l-5.72 5.72a.75.75 0 1 1-1.06-1.06l5.72-5.72H15a.75.75 0 0 1-.75-.75ZM10.53 14.53a.75.75 0 1 0-1.06-1.06l-5.72 5.72V15a.75.75 0 0 0-1.5 0v6a.748.748 0 0 0 .75.75h6a.75.75 0 0 0 0-1.5H4.81l5.72-5.72Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMaximize;
