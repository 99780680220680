/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AncestryType } from './AncestryType';
import {
    AncestryTypeFromJSON,
    AncestryTypeFromJSONTyped,
    AncestryTypeToJSON,
} from './AncestryType';
import type { EgfrFormulaType } from './EgfrFormulaType';
import {
    EgfrFormulaTypeFromJSON,
    EgfrFormulaTypeFromJSONTyped,
    EgfrFormulaTypeToJSON,
} from './EgfrFormulaType';
import type { GenderType } from './GenderType';
import {
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import type { SampleType } from './SampleType';
import {
    SampleTypeFromJSON,
    SampleTypeFromJSONTyped,
    SampleTypeToJSON,
} from './SampleType';
import type { WeightCategoryType } from './WeightCategoryType';
import {
    WeightCategoryTypeFromJSON,
    WeightCategoryTypeFromJSONTyped,
    WeightCategoryTypeToJSON,
} from './WeightCategoryType';

/**
 * 
 * @export
 * @interface NovaMaxDeviceDataModel
 */
export interface NovaMaxDeviceDataModel {
    /**
     * 
     * @type {string}
     * @memberof NovaMaxDeviceDataModel
     */
    sequenceNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NovaMaxDeviceDataModel
     */
    age?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NovaMaxDeviceDataModel
     */
    weight?: number | null;
    /**
     * 
     * @type {AncestryType}
     * @memberof NovaMaxDeviceDataModel
     */
    ancestryType?: AncestryType;
    /**
     * 
     * @type {GenderType}
     * @memberof NovaMaxDeviceDataModel
     */
    genderType?: GenderType;
    /**
     * 
     * @type {SampleType}
     * @memberof NovaMaxDeviceDataModel
     */
    sampleType?: SampleType;
    /**
     * 
     * @type {EgfrFormulaType}
     * @memberof NovaMaxDeviceDataModel
     */
    egfrFormulaType?: EgfrFormulaType;
    /**
     * 
     * @type {WeightCategoryType}
     * @memberof NovaMaxDeviceDataModel
     */
    weightCategoryType?: WeightCategoryType;
}

/**
 * Check if a given object implements the NovaMaxDeviceDataModel interface.
 */
export function instanceOfNovaMaxDeviceDataModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NovaMaxDeviceDataModelFromJSON(json: any): NovaMaxDeviceDataModel {
    return NovaMaxDeviceDataModelFromJSONTyped(json, false);
}

export function NovaMaxDeviceDataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NovaMaxDeviceDataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sequenceNumber': !exists(json, 'SequenceNumber') ? undefined : json['SequenceNumber'],
        'age': !exists(json, 'Age') ? undefined : json['Age'],
        'weight': !exists(json, 'Weight') ? undefined : json['Weight'],
        'ancestryType': !exists(json, 'AncestryType') ? undefined : AncestryTypeFromJSON(json['AncestryType']),
        'genderType': !exists(json, 'GenderType') ? undefined : GenderTypeFromJSON(json['GenderType']),
        'sampleType': !exists(json, 'SampleType') ? undefined : SampleTypeFromJSON(json['SampleType']),
        'egfrFormulaType': !exists(json, 'EgfrFormulaType') ? undefined : EgfrFormulaTypeFromJSON(json['EgfrFormulaType']),
        'weightCategoryType': !exists(json, 'WeightCategoryType') ? undefined : WeightCategoryTypeFromJSON(json['WeightCategoryType']),
    };
}

export function NovaMaxDeviceDataModelToJSON(value?: NovaMaxDeviceDataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SequenceNumber': value.sequenceNumber,
        'Age': value.age,
        'Weight': value.weight,
        'AncestryType': AncestryTypeToJSON(value.ancestryType),
        'GenderType': GenderTypeToJSON(value.genderType),
        'SampleType': SampleTypeToJSON(value.sampleType),
        'EgfrFormulaType': EgfrFormulaTypeToJSON(value.egfrFormulaType),
        'WeightCategoryType': WeightCategoryTypeToJSON(value.weightCategoryType),
    };
}

