import { MeasurementUnitType } from "api/query";
import { HEIGHT_MAX_GRAPH_VALUE_CM, HEIGHT_MAX_GRAPH_VALUE_FT } from "config/const";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { useMemo } from "react";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";

export function useGetMaxHeightValueFromRuleEngineUnit() {
  const { heightUnit } = useGetUserPreferredMeasurementUnits();

  const value = useMemo(() => {
    switch (heightUnit) {
      case undefined:
      case MEASUREMENT_UNITS.cm:
        return HEIGHT_MAX_GRAPH_VALUE_CM;
      case MEASUREMENT_UNITS.ft:
        return HEIGHT_MAX_GRAPH_VALUE_FT;
      default:
        console.error(`${heightUnit} should be implemented`);
        return HEIGHT_MAX_GRAPH_VALUE_CM;
    }
  }, [heightUnit]);

  return { unit: MeasurementUnitType.mm, value };
}
