/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IdentifiedWithCkdRiskGroupModel } from './IdentifiedWithCkdRiskGroupModel';
import {
    IdentifiedWithCkdRiskGroupModelFromJSON,
    IdentifiedWithCkdRiskGroupModelFromJSONTyped,
    IdentifiedWithCkdRiskGroupModelToJSON,
} from './IdentifiedWithCkdRiskGroupModel';

/**
 * 
 * @export
 * @interface IdentifiedWithCkdRiskReportModel
 */
export interface IdentifiedWithCkdRiskReportModel {
    /**
     * 
     * @type {Array<IdentifiedWithCkdRiskGroupModel>}
     * @memberof IdentifiedWithCkdRiskReportModel
     */
    groups?: Array<IdentifiedWithCkdRiskGroupModel> | null;
    /**
     * 
     * @type {number}
     * @memberof IdentifiedWithCkdRiskReportModel
     */
    totalNumberOfPatients?: number;
    /**
     * 
     * @type {number}
     * @memberof IdentifiedWithCkdRiskReportModel
     */
    totalNumberOfPatientsInCkdRisk?: number;
}

/**
 * Check if a given object implements the IdentifiedWithCkdRiskReportModel interface.
 */
export function instanceOfIdentifiedWithCkdRiskReportModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IdentifiedWithCkdRiskReportModelFromJSON(json: any): IdentifiedWithCkdRiskReportModel {
    return IdentifiedWithCkdRiskReportModelFromJSONTyped(json, false);
}

export function IdentifiedWithCkdRiskReportModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentifiedWithCkdRiskReportModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groups': !exists(json, 'Groups') ? undefined : (json['Groups'] === null ? null : (json['Groups'] as Array<any>).map(IdentifiedWithCkdRiskGroupModelFromJSON)),
        'totalNumberOfPatients': !exists(json, 'TotalNumberOfPatients') ? undefined : json['TotalNumberOfPatients'],
        'totalNumberOfPatientsInCkdRisk': !exists(json, 'TotalNumberOfPatientsInCkdRisk') ? undefined : json['TotalNumberOfPatientsInCkdRisk'],
    };
}

export function IdentifiedWithCkdRiskReportModelToJSON(value?: IdentifiedWithCkdRiskReportModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Groups': value.groups === undefined ? undefined : (value.groups === null ? null : (value.groups as Array<any>).map(IdentifiedWithCkdRiskGroupModelToJSON)),
        'TotalNumberOfPatients': value.totalNumberOfPatients,
        'TotalNumberOfPatientsInCkdRisk': value.totalNumberOfPatientsInCkdRisk,
    };
}

