/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormsDefaultValuesHcpModel } from './FormsDefaultValuesHcpModel';
import {
    FormsDefaultValuesHcpModelFromJSON,
    FormsDefaultValuesHcpModelFromJSONTyped,
    FormsDefaultValuesHcpModelToJSON,
} from './FormsDefaultValuesHcpModel';
import type { FormsDefaultValuesOrganizationModel } from './FormsDefaultValuesOrganizationModel';
import {
    FormsDefaultValuesOrganizationModelFromJSON,
    FormsDefaultValuesOrganizationModelFromJSONTyped,
    FormsDefaultValuesOrganizationModelToJSON,
} from './FormsDefaultValuesOrganizationModel';
import type { FormsDefaultValuesPatientModel } from './FormsDefaultValuesPatientModel';
import {
    FormsDefaultValuesPatientModelFromJSON,
    FormsDefaultValuesPatientModelFromJSONTyped,
    FormsDefaultValuesPatientModelToJSON,
} from './FormsDefaultValuesPatientModel';
import type { FormsDefaultValuesUserModel } from './FormsDefaultValuesUserModel';
import {
    FormsDefaultValuesUserModelFromJSON,
    FormsDefaultValuesUserModelFromJSONTyped,
    FormsDefaultValuesUserModelToJSON,
} from './FormsDefaultValuesUserModel';

/**
 * 
 * @export
 * @interface FormsDefaultValuesModel
 */
export interface FormsDefaultValuesModel {
    /**
     * 
     * @type {FormsDefaultValuesUserModel}
     * @memberof FormsDefaultValuesModel
     */
    user: FormsDefaultValuesUserModel;
    /**
     * 
     * @type {FormsDefaultValuesPatientModel}
     * @memberof FormsDefaultValuesModel
     */
    patient: FormsDefaultValuesPatientModel;
    /**
     * 
     * @type {FormsDefaultValuesOrganizationModel}
     * @memberof FormsDefaultValuesModel
     */
    organization: FormsDefaultValuesOrganizationModel;
    /**
     * 
     * @type {FormsDefaultValuesHcpModel}
     * @memberof FormsDefaultValuesModel
     */
    hcp: FormsDefaultValuesHcpModel;
}

/**
 * Check if a given object implements the FormsDefaultValuesModel interface.
 */
export function instanceOfFormsDefaultValuesModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "patient" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "hcp" in value;

    return isInstance;
}

export function FormsDefaultValuesModelFromJSON(json: any): FormsDefaultValuesModel {
    return FormsDefaultValuesModelFromJSONTyped(json, false);
}

export function FormsDefaultValuesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormsDefaultValuesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': FormsDefaultValuesUserModelFromJSON(json['user']),
        'patient': FormsDefaultValuesPatientModelFromJSON(json['patient']),
        'organization': FormsDefaultValuesOrganizationModelFromJSON(json['organization']),
        'hcp': FormsDefaultValuesHcpModelFromJSON(json['hcp']),
    };
}

export function FormsDefaultValuesModelToJSON(value?: FormsDefaultValuesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': FormsDefaultValuesUserModelToJSON(value.user),
        'patient': FormsDefaultValuesPatientModelToJSON(value.patient),
        'organization': FormsDefaultValuesOrganizationModelToJSON(value.organization),
        'hcp': FormsDefaultValuesHcpModelToJSON(value.hcp),
    };
}

