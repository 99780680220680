import * as React from "react";
import { SVGProps } from "react";
const SvgTablet = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12 17a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.25A2.75 2.75 0 0 0 3.25 4v16A2.75 2.75 0 0 0 6 22.75h12A2.75 2.75 0 0 0 20.75 20V4A2.75 2.75 0 0 0 18 1.25H6ZM4.75 4c0-.69.56-1.25 1.25-1.25h12c.69 0 1.25.56 1.25 1.25v16c0 .69-.56 1.25-1.25 1.25H6c-.69 0-1.25-.56-1.25-1.25V4Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgTablet;
