import { getIsInRange } from "../helper";

export function resolveInRange(
  steps: ReadonlyArray<number>,
  value: number | undefined,
  index: number,
) {
  const from = index === 0 ? undefined : steps[index - 1];
  const to = steps[index];

  if (from === undefined && to === undefined) {
    throw new Error("You must provide at least one param");
  }

  return [from, to, getIsInRange(value, from, to)] as const;
}
