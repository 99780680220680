import classNames from "classnames";
import { KeyboardEvent } from "react";
import { isKeyboardClickKey } from "../../utils";
import { Option } from "./../../Select/Select.model";
import "./RadioOption.scss";

interface RadioOptionProps<T> {
  value?: T;
  option: Option<T>;
  onSelect: (value: T) => void;
  radioOptionClassName?: string;
  disabled?: boolean;
}

export function RadioOption<T>({
  value,
  option,
  onSelect,
  radioOptionClassName,
  disabled,
}: Readonly<RadioOptionProps<T>>) {
  const { title, value: val } = option;

  const onKeyDown = (e: KeyboardEvent) => {
    if (isKeyboardClickKey(e.key)) {
      e.preventDefault();
      onSelect(val);
    }
  };

  return (
    <div
      className={classNames(
        "RadioOption",
        disabled && "RadioOption--disabled",
        radioOptionClassName,
      )}
      onClick={disabled ? undefined : () => onSelect(val)}
      onKeyDown={disabled ? undefined : onKeyDown}
      role="option"
      aria-selected={value === val ? "true" : "false"}
      tabIndex={0}
    >
      <div
        className={classNames("RadioOption__icon", {
          "RadioOption__icon--active": value === val,
        })}
      >
        {value === val && <span className="RadioOption__active" />}
      </div>
      <span className="RadioOption__label">{title}</span>
    </div>
  );
}
