import * as React from "react";
import { SVGProps } from "react";
const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.713 4.307a.748.748 0 0 0-.243.163l-7 7a.75.75 0 1 0 1.06 1.06l5.72-5.72V19a.75.75 0 0 0 1.5 0V6.81l5.72 5.72a.75.75 0 1 0 1.06-1.06l-7-7M12.529 4.468a.748.748 0 0 0-.816-.161"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowUp;
