import { DeviceType } from "api/query";
import i18n from "i18next";

export function deviceTypeToSelectOptions() {
  return Object.entries(DeviceType)
    .filter(([key]) => key !== "None")
    .map(([key, deviceValue]) => {
      return {
        title: i18n.t(`deviceType.${key}`, { ns: "translation" }),
        value: deviceValue,
      };
    });
}
