import * as React from "react";
import { SVGProps } from "react";
const SvgVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.75 10.543V7A2.75 2.75 0 0 0 14 4.25H3A2.75 2.75 0 0 0 .25 7v10A2.75 2.75 0 0 0 3 19.75h11A2.75 2.75 0 0 0 16.75 17v-3.543l5.814 4.153A.75.75 0 0 0 23.75 17V7a.75.75 0 0 0-1.186-.61l-5.814 4.153ZM1.75 7c0-.69.56-1.25 1.25-1.25h11c.69 0 1.25.56 1.25 1.25v10c0 .69-.56 1.25-1.25 1.25H3c-.69 0-1.25-.56-1.25-1.25V7Zm20.5 8.543L17.29 12l4.96-3.543v7.086Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgVideo;
