import {
  CREATININE_CORRECTION_FACTOR,
  FEET_IN_INCH,
  MILLIMETERS_IN_FEET,
  MILLIMETERS_IN_INCH,
  ONE_GRAM_IN_PUNDS,
} from "config/const";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { localeNumberFormat } from "utils/formatters/localeNumberFormat";
import { parseLocaleNumber } from "./parseLocaleNumber";

function throwIfNaN<InputValue extends string | number, RestOfParams extends any[]>(
  func: (value: number, ...rest: RestOfParams) => number,
) {
  const wrap = (value: InputValue, ...rest: RestOfParams) => {
    if (Number.isNaN(Number(value))) {
      throw new Error("Please input a number");
    }

    return func(Number(value), ...rest);
  };

  return wrap;
}

/**
 * ! do not use with big numbers
 */
export const millimetersToCentimeters = throwIfNaN((value: string | number) => +value / 10);
export const centimetersToMillimeters = throwIfNaN((value: string | number) => +value * 10);

export const gramsToKilograms = throwIfNaN((value: string | number) => +value / 1000);
export const kilogramsToGrams = throwIfNaN((value: string | number) => +value * 1000);

export const gramsToPounds = throwIfNaN((value: string | number) => +value * ONE_GRAM_IN_PUNDS);
export const poundsToGrams = throwIfNaN((value: string | number) => +value / ONE_GRAM_IN_PUNDS);

export const feetToMillimeters = throwIfNaN((value: string | number) => {
  return +value * MILLIMETERS_IN_FEET;
});

export const inchesToMillimeters = throwIfNaN((value: string | number) => {
  return +value * MILLIMETERS_IN_INCH;
});

export const millimetersToInches = throwIfNaN((value: string | number) => {
  return +value / MILLIMETERS_IN_INCH;
});

export const millimetersToFeet = throwIfNaN((value: string | number) => {
  return millimetersToInches(value) / FEET_IN_INCH;
});

export const millimetersToWholeFeet = throwIfNaN((value: string | number) => {
  return Math.trunc(millimetersToInches(value) / FEET_IN_INCH);
});

export const restOfMillimetersToInchesAfterWholeFeet = throwIfNaN((value: string | number) => {
  const maxFeet = millimetersToWholeFeet(value);

  return millimetersToInches(value) - maxFeet * FEET_IN_INCH;
});

function formatSerumCreatinine(value: number) {
  return parseLocaleNumber(
    localeNumberFormat(value, "en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      roundingMode: "halfEven", // Key part: will round as expected: 1.336 => 1.34 || 1.444 => 1.44 ...
    }),
    "en-US",
  );
}

export const getCorrectedValueForCreatinine = throwIfNaN(
  (value: number, currentUnit: MEASUREMENT_UNITS, targetedUnit?: MEASUREMENT_UNITS) => {
    if (targetedUnit) {
      if (targetedUnit === currentUnit) {
        return value;
      }

      return targetedUnit === MEASUREMENT_UNITS.umolL
        ? formatSerumCreatinine(value * CREATININE_CORRECTION_FACTOR)
        : formatSerumCreatinine(value / CREATININE_CORRECTION_FACTOR);
    }

    return currentUnit === MEASUREMENT_UNITS.umolL
      ? formatSerumCreatinine(value * CREATININE_CORRECTION_FACTOR)
      : formatSerumCreatinine(value);
  },
);
