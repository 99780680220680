import {
  AdvancedMarker,
  APIProvider,
  Map as GoogleMap,
  MapMouseEvent,
} from "@vis.gl/react-google-maps";
import { CustomMapControls } from "components/CustomMapControls";
import { GOOGLE_MAP_API_KEY, GOOGLE_MAP_PLACES_SEARCH_MAP_ID } from "config/const";
import { MapBaloonMarkerIcon } from "libs/ui/assets/svg/mapBaloonMarkerIcon";
import { logger } from "logger";
import { PlaceType } from "models/PlaceType";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getPlaceDetails, getReverseGeoCoding } from "utils/helpers/google";
import { GeolocationModel } from "..";
import "./AddressMap.scss";

type CustomMapControlsRef = { map: google.maps.Map | null };

export interface AddressMapPropsRef {
  centerMap: (bounds: google.maps.LatLngBounds) => void;
}

interface AddressMapProps {
  onMapSelect: (value?: PlaceType) => void;
  geolocation?: GeolocationModel;
  isReadonly?: boolean;
}

async function getPlaceDetailsResult(lat: number, lng: number) {
  let results: Awaited<ReturnType<typeof getPlaceDetails>> = [];
  if (lat && lng) {
    try {
      results = await getReverseGeoCoding(lat, lng);
    } catch (error) {
      logger.error(error);
    }

    if (results?.length > 0) {
      return results[0];
    }
  }
}

export const AddressMap = forwardRef<AddressMapPropsRef, Readonly<AddressMapProps>>(
  ({ onMapSelect, geolocation, isReadonly }, ref) => {
    const { t } = useTranslation("translation", {
      keyPrefix: "ui-components.PlaceSuggestionInput",
    });
    const controlRef = useRef<CustomMapControlsRef>(null);
    const firstInit = useRef(true);

    const centerMap = useCallback((bounds: google.maps.LatLngBounds) => {
      if (controlRef.current?.map) {
        controlRef.current.map.fitBounds(bounds, { top: 100, bottom: 100, left: 50, right: 50 });
      }
    }, []);

    useEffect(() => {
      if (isReadonly || firstInit.current === false || !controlRef.current?.map) {
        return;
      }

      firstInit.current = false;

      centerMap(
        new google.maps.LatLngBounds(new google.maps.LatLng(0, 0), new google.maps.LatLng(0, 0)),
      );
    }, [centerMap, isReadonly]);

    const onMapClick = useCallback(
      async (event: MapMouseEvent) => {
        if (event.detail.latLng?.lat && event.detail.latLng?.lng) {
          const place = await getPlaceDetailsResult(
            event.detail.latLng.lat,
            event.detail.latLng.lng,
          );

          if (place) {
            onMapSelect(place);
          }

          if (place?.viewport) {
            controlRef.current?.map?.setCenter({
              lat: place.viewport.getCenter().lat(),
              lng: place.viewport.getCenter().lng(),
            });
          }
        }
      },
      [onMapSelect],
    );

    const setCurrentLocation = useCallback(
      async (lat: number, lng: number) => {
        const place = await getPlaceDetailsResult(lat, lng);

        if (place) {
          onMapSelect(place);
        }
      },
      [onMapSelect],
    );

    useImperativeHandle(ref, () => {
      return {
        centerMap,
      };
    }, [centerMap]);

    if (geolocation === undefined && isReadonly) {
      return null;
    }

    return (
      <>
        <div className="AddressMap">
          <APIProvider apiKey={GOOGLE_MAP_API_KEY}>
            <GoogleMap
              clickableIcons={false}
              disableDefaultUI={true}
              zoomControl={false}
              mapTypeControl={false}
              fullscreenControl={false}
              streetViewControl={false}
              mapId={GOOGLE_MAP_PLACES_SEARCH_MAP_ID}
              onClick={isReadonly ? undefined : onMapClick}
              defaultZoom={1}
              defaultCenter={{ lat: geolocation?.latitude ?? 0, lng: geolocation?.longitude ?? 0 }}
            />

            {geolocation && (
              <AdvancedMarker position={{ lat: geolocation.latitude, lng: geolocation.longitude }}>
                <MapBaloonMarkerIcon className="AddressMap__marker-icon" />
              </AdvancedMarker>
            )}

            {isReadonly ? null : (
              <CustomMapControls ref={controlRef} setCurrentLocation={setCurrentLocation} />
            )}
          </APIProvider>
        </div>
        <p className="AddressMapInfo">{t("map-description")}</p>
      </>
    );
  },
);
