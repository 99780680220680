import * as React from "react";
import { SVGProps } from "react";
const SvgWeight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.172 21H5.287C4.577 21 4 20.424 4 19.713V6.869A3.869 3.869 0 0 1 7.869 3h8.722a3.869 3.869 0 0 1 3.868 3.869v12.844c0 .71-.576 1.287-1.287 1.287Z"
      fill="url(#weight_svg__a)"
    />
    <path
      d="M4 17.69v2.023C4 20.423 4.576 21 5.287 21h13.885c.711 0 1.287-.576 1.287-1.287V17.69H4Z"
      fill="url(#weight_svg__b)"
    />
    <path
      d="M12.23 11.608a8.361 8.361 0 0 1-5.403-1.97c-.688-.58-1.74-.096-1.74.804v8.23c0 .616.501 1.116 1.118 1.116h12.05c.616 0 1.116-.5 1.116-1.116v-8.23c0-.9-1.05-1.383-1.739-.804a8.36 8.36 0 0 1-5.402 1.97Z"
      fill="url(#weight_svg__c)"
    />
    <path
      d="M10.979 14.642H6.96a.478.478 0 1 1 0-.956h4.019a.478.478 0 1 1 0 .956Z"
      fill="url(#weight_svg__d)"
    />
    <path
      d="M10.979 16.215H6.96a.478.478 0 1 1 0-.955h4.019a.478.478 0 0 1 0 .955Z"
      fill="url(#weight_svg__e)"
    />
    <path
      d="M10.979 17.79H6.96a.478.478 0 1 1 0-.956h4.019a.478.478 0 0 1 0 .955Z"
      fill="url(#weight_svg__f)"
    />
    <path
      d="M17.499 14.642H13.48a.478.478 0 1 1 0-.956H17.5a.478.478 0 1 1 0 .956Z"
      fill="url(#weight_svg__g)"
    />
    <path
      d="M17.499 16.215H13.48a.478.478 0 1 1 0-.955H17.5a.478.478 0 0 1 0 .955Z"
      fill="url(#weight_svg__h)"
    />
    <path
      d="M17.499 17.79H13.48a.478.478 0 1 1 0-.956H17.5a.478.478 0 0 1 0 .955Z"
      fill="url(#weight_svg__i)"
    />
    <path
      d="M17.302 8.754H7.158a.675.675 0 0 1-.676-.675v-.89a2.863 2.863 0 0 1 2.864-2.862h5.767a2.863 2.863 0 0 1 2.864 2.863v.89a.675.675 0 0 1-.675.674Z"
      fill="url(#weight_svg__j)"
    />
    <path
      d="M12.148 5.613a.264.264 0 0 1-.264-.264v-.512a.264.264 0 0 1 .528 0v.512a.264.264 0 0 1-.264.264Z"
      fill="url(#weight_svg__k)"
    />
    <path
      d="M14.78 8.244c0-.146.118-.264.264-.264h.511a.264.264 0 0 1 0 .528h-.511a.264.264 0 0 1-.264-.264Z"
      fill="url(#weight_svg__l)"
    />
    <path
      d="M8.477 8.244c0-.146.118-.264.264-.264h.512a.264.264 0 0 1 0 .528H8.74a.264.264 0 0 1-.264-.264Z"
      fill="url(#weight_svg__m)"
    />
    <path
      d="M14.009 6.383a.264.264 0 0 1 0-.373l.362-.362a.264.264 0 1 1 .373.373l-.362.362a.264.264 0 0 1-.373 0Z"
      fill="url(#weight_svg__n)"
    />
    <path
      d="M9.552 5.648a.264.264 0 0 1 .374 0l.362.362a.264.264 0 1 1-.374.373l-.362-.362a.264.264 0 0 1 0-.373Z"
      fill="url(#weight_svg__o)"
    />
    <path
      d="M13.132 5.804a.264.264 0 0 1-.146-.344l.192-.475a.264.264 0 0 1 .49.198l-.192.474a.264.264 0 0 1-.344.147Z"
      fill="url(#weight_svg__p)"
    />
    <path
      d="M8.743 6.871a.264.264 0 0 1 .344-.146l.475.191a.264.264 0 0 1-.198.49l-.474-.191a.264.264 0 0 1-.147-.344Z"
      fill="url(#weight_svg__q)"
    />
    <path
      d="M14.57 7.214a.264.264 0 0 1 .14-.346l.47-.2a.264.264 0 1 1 .207.485l-.471.2a.264.264 0 0 1-.346-.139Z"
      fill="url(#weight_svg__r)"
    />
    <path
      d="M10.711 4.866a.264.264 0 0 1 .347.14l.2.47a.264.264 0 1 1-.486.207l-.2-.471a.264.264 0 0 1 .14-.346Z"
      fill="url(#weight_svg__s)"
    />
    <path
      d="M14.886 8.754h-2.434l-.482-.481-.128-.276-.687-1.483a.098.098 0 0 1-.027-.059l-.378-.348a.096.096 0 0 1-.013-.092l.223-.606a.1.1 0 0 1 .127-.06l.606.224a.097.097 0 0 1 .05.04l3.143 3.141Z"
      fill="url(#weight_svg__t)"
    />
    <path
      d="m12.683 7.825.93.93H12.24l-.243-.244a.486.486 0 1 1 .686-.686Z"
      fill="url(#weight_svg__u)"
    />
    <path
      d="m11.652 5.888.966 2.084c.017.036-.014.046-.05.062l-.518.24c-.036.017-.063.034-.08-.002l-.966-2.084a.1.1 0 0 0-.132-.049.1.1 0 0 1-.135-.124l.223-.606a.1.1 0 0 1 .127-.059l.606.223a.1.1 0 0 1 .008.183.1.1 0 0 0-.049.132Z"
      fill="url(#weight_svg__v)"
    />
    <path
      d="M12.568 8.034c.036-.016.067-.026.05-.062l-.966-2.084a.1.1 0 0 1 .049-.132.1.1 0 0 0-.008-.184l-.606-.222a.099.099 0 0 0-.076.003l1.298 2.801.259-.12Z"
      fill="url(#weight_svg__w)"
    />
    <path
      d="M12.309 8.624a.487.487 0 1 0 0-.975.487.487 0 0 0 0 .975Z"
      fill="url(#weight_svg__x)"
    />
    <defs>
      <linearGradient
        id="weight_svg__a"
        x1={7.174}
        y1={7.009}
        x2={21.7}
        y2={23.667}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65E0DB" />
        <stop offset={0.185} stopColor="#5EDAD4" />
        <stop offset={0.457} stopColor="#4AC9C0" />
        <stop offset={0.781} stopColor="#2BADA0" />
        <stop offset={1} stopColor="#119786" />
      </linearGradient>
      <linearGradient
        id="weight_svg__b"
        x1={12.23}
        y1={19.335}
        x2={12.23}
        y2={21.488}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#119786" stopOpacity={0} />
        <stop offset={1} stopColor="#119786" />
      </linearGradient>
      <linearGradient
        id="weight_svg__c"
        x1={12.23}
        y1={28.368}
        x2={12.23}
        y2={7.326}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65E0DB" />
        <stop offset={0.185} stopColor="#5EDAD4" />
        <stop offset={0.457} stopColor="#4AC9C0" />
        <stop offset={0.781} stopColor="#2BADA0" />
        <stop offset={1} stopColor="#119786" />
      </linearGradient>
      <linearGradient
        id="weight_svg__d"
        x1={8.97}
        y1={13.236}
        x2={8.97}
        y2={15.248}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65E0DB" />
        <stop offset={0.185} stopColor="#5EDAD4" />
        <stop offset={0.457} stopColor="#4AC9C0" />
        <stop offset={0.781} stopColor="#2BADA0" />
        <stop offset={1} stopColor="#119786" />
      </linearGradient>
      <linearGradient
        id="weight_svg__e"
        x1={8.97}
        y1={14.81}
        x2={8.97}
        y2={16.822}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65E0DB" />
        <stop offset={0.185} stopColor="#5EDAD4" />
        <stop offset={0.457} stopColor="#4AC9C0" />
        <stop offset={0.781} stopColor="#2BADA0" />
        <stop offset={1} stopColor="#119786" />
      </linearGradient>
      <linearGradient
        id="weight_svg__f"
        x1={8.97}
        y1={16.384}
        x2={8.97}
        y2={18.395}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65E0DB" />
        <stop offset={0.185} stopColor="#5EDAD4" />
        <stop offset={0.457} stopColor="#4AC9C0" />
        <stop offset={0.781} stopColor="#2BADA0" />
        <stop offset={1} stopColor="#119786" />
      </linearGradient>
      <linearGradient
        id="weight_svg__g"
        x1={15.489}
        y1={13.236}
        x2={15.489}
        y2={15.248}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65E0DB" />
        <stop offset={0.185} stopColor="#5EDAD4" />
        <stop offset={0.457} stopColor="#4AC9C0" />
        <stop offset={0.781} stopColor="#2BADA0" />
        <stop offset={1} stopColor="#119786" />
      </linearGradient>
      <linearGradient
        id="weight_svg__h"
        x1={15.489}
        y1={14.81}
        x2={15.489}
        y2={16.822}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65E0DB" />
        <stop offset={0.185} stopColor="#5EDAD4" />
        <stop offset={0.457} stopColor="#4AC9C0" />
        <stop offset={0.781} stopColor="#2BADA0" />
        <stop offset={1} stopColor="#119786" />
      </linearGradient>
      <linearGradient
        id="weight_svg__i"
        x1={15.489}
        y1={16.384}
        x2={15.489}
        y2={18.395}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65E0DB" />
        <stop offset={0.185} stopColor="#5EDAD4" />
        <stop offset={0.457} stopColor="#4AC9C0" />
        <stop offset={0.781} stopColor="#2BADA0" />
        <stop offset={1} stopColor="#119786" />
      </linearGradient>
      <linearGradient
        id="weight_svg__j"
        x1={11.38}
        y1={6.331}
        x2={15.033}
        y2={9.985}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset={0.404} stopColor="#F7F2FB" />
        <stop offset={0.764} stopColor="#F3E8FB" />
        <stop offset={1} stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="weight_svg__k"
        x1={12.071}
        y1={5.015}
        x2={12.403}
        y2={5.347}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset={1} stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="weight_svg__l"
        x1={15.377}
        y1={8.167}
        x2={15.045}
        y2={8.499}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset={1} stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="weight_svg__m"
        x1={9.074}
        y1={8.167}
        x2={8.742}
        y2={8.499}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset={1} stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="weight_svg__n"
        x1={14.376}
        y1={5.905}
        x2={14.376}
        y2={6.375}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset={1} stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="weight_svg__o"
        x1={10.031}
        y1={6.016}
        x2={9.562}
        y2={6.016}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset={1} stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="weight_svg__p"
        x1={13.28}
        y1={5.22}
        x2={13.464}
        y2={5.652}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset={1} stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="weight_svg__q"
        x1={9.33}
        y1={7.019}
        x2={8.898}
        y2={7.202}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset={1} stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="weight_svg__r"
        x1={15.087}
        y1={6.907}
        x2={14.911}
        y2={7.342}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset={1} stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="weight_svg__s"
        x1={11.022}
        y1={5.384}
        x2={10.586}
        y2={5.208}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset={1} stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="weight_svg__t"
        x1={13.761}
        y1={6.876}
        x2={10.22}
        y2={7.645}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFDCFB" stopOpacity={0} />
        <stop offset={0.486} stopColor="#BAAAD6" stopOpacity={0.486} />
        <stop offset={1} stopColor="#8679B2" />
      </linearGradient>
      <linearGradient
        id="weight_svg__u"
        x1={13.147}
        y1={8.975}
        x2={12.232}
        y2={8.06}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFDCFB" stopOpacity={0} />
        <stop offset={0.486} stopColor="#BAAAD6" stopOpacity={0.486} />
        <stop offset={1} stopColor="#8679B2" />
      </linearGradient>
      <linearGradient
        id="weight_svg__v"
        x1={11.137}
        y1={6.774}
        x2={12.96}
        y2={7.128}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset={0.304} stopColor="#FFCD3E" />
        <stop offset={0.856} stopColor="#FFAD2B" />
        <stop offset={1} stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="weight_svg__w"
        x1={11.951}
        y1={6.319}
        x2={11.394}
        y2={9.83}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F35227" stopOpacity={0} />
        <stop offset={1} stopColor="#F35227" />
      </linearGradient>
      <linearGradient
        id="weight_svg__x"
        x1={12}
        y1={8}
        x2={12.602}
        y2={8.462}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FECACA" />
        <stop offset={0.259} stopColor="#FECACA" />
        <stop offset={0.476} stopColor="#FCA5A5" />
        <stop offset={0.676} stopColor="#F87171" />
        <stop offset={0.867} stopColor="#EF4444" />
        <stop offset={1} stopColor="#EF4444" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgWeight;
