/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GeolocationModel } from './GeolocationModel';
import {
    GeolocationModelFromJSON,
    GeolocationModelFromJSONTyped,
    GeolocationModelToJSON,
} from './GeolocationModel';

/**
 * 
 * @export
 * @interface GroupedByItemModel
 */
export interface GroupedByItemModel {
    /**
     * If GroupByItem refers to an entity, this property will have its EntityId.<br />
     * So, it will be set only when grouping by Organization.
     * @type {string}
     * @memberof GroupedByItemModel
     */
    entityId?: string | null;
    /**
     * Display name of the GroupByItem.<br />
     * When grouping by Organization it will be Country's two-letter ISO code.
     * @type {string}
     * @memberof GroupedByItemModel
     */
    name: string;
    /**
     * Name of the State, if State is specified in the Address.
     * @type {string}
     * @memberof GroupedByItemModel
     */
    state?: string | null;
    /**
     * Two-letter ISO code of the Country in the Address.
     * @type {string}
     * @memberof GroupedByItemModel
     */
    countryIso?: string | null;
    /**
     * 
     * @type {GeolocationModel}
     * @memberof GroupedByItemModel
     */
    geolocation?: GeolocationModel;
}

/**
 * Check if a given object implements the GroupedByItemModel interface.
 */
export function instanceOfGroupedByItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function GroupedByItemModelFromJSON(json: any): GroupedByItemModel {
    return GroupedByItemModelFromJSONTyped(json, false);
}

export function GroupedByItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupedByItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': !exists(json, 'EntityId') ? undefined : json['EntityId'],
        'name': json['Name'],
        'state': !exists(json, 'State') ? undefined : json['State'],
        'countryIso': !exists(json, 'CountryIso') ? undefined : json['CountryIso'],
        'geolocation': !exists(json, 'Geolocation') ? undefined : GeolocationModelFromJSON(json['Geolocation']),
    };
}

export function GroupedByItemModelToJSON(value?: GroupedByItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EntityId': value.entityId,
        'Name': value.name,
        'State': value.state,
        'CountryIso': value.countryIso,
        'Geolocation': GeolocationModelToJSON(value.geolocation),
    };
}

