import { PropsWithChildren } from "react";
import { IconCell } from "../IconCell";
import { UserOrganizationCellProps } from "../model";
import "./UserOrganizationCell.scss";

export function UserOrganizationCell({
  show = true,
  role,
  label,
  onCellClick,
  loading,
  isMain,
}: PropsWithChildren<UserOrganizationCellProps>) {
  return (
    <IconCell
      className="OrganizationCell"
      loading={loading}
      show={show}
      onCellClick={onCellClick}
      icon={isMain ? "MainOrganization" : "Organization"}
      style={{ color: isMain ? "var(--yellow-500)" : "var(--primary-500)" }}
    >
      <span className="IconCell__label">{label}</span>
    </IconCell>
  );
}
