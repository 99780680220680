import * as React from "react";
import { SVGProps } from "react";
const SvgStarEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25a.75.75 0 0 1 .673.418l2.915 5.907 6.52.953a.75.75 0 0 1 .415 1.28l-4.717 4.594 1.113 6.491a.75.75 0 0 1-1.088.79L12 18.618l-5.83 3.067a.75.75 0 0 1-1.09-.79l1.114-6.492-4.717-4.595a.75.75 0 0 1 .415-1.28l6.52-.952 2.915-5.907A.75.75 0 0 1 12 1.25Zm0 2.444L9.583 8.592a.75.75 0 0 1-.565.41l-5.407.79 3.912 3.81a.75.75 0 0 1 .216.665l-.923 5.382 4.835-2.543a.75.75 0 0 1 .698 0l4.835 2.543-.923-5.382a.75.75 0 0 1 .216-.664l3.912-3.81-5.407-.79a.75.75 0 0 1-.564-.411L12 3.694Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgStarEmpty;
