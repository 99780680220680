import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import "./PatientInfoValue.scss";
import classNames from "classnames";

type Size = "small" | "large";

interface Props {
  size?: Size;
  link?: string;
}

export function PatientInfoValue({ size = "large", link, children }: PropsWithChildren<Props>) {
  if (link) {
    return (
      <Link
        className={classNames(
          "PatientInfoValue PatientInfoValue--link",
          `PatientInfoValue--${size}`,
        )}
        to={link}
      >
        {children}
      </Link>
    );
  }

  return (
    <div className={classNames("PatientInfoValue", `PatientInfoValue--${size}`)}>{children}</div>
  );
}
