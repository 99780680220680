import i18n from "i18next";

export function withDeviceToSelectOptions() {
  return [
    {
      title: i18n.t(`withDevice.Yes`, { ns: "translation" }),
      value: true,
    },
    {
      title: i18n.t(`withDevice.No`, { ns: "translation" }),
      value: false,
    },
  ];
}
