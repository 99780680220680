import { FieldLoader } from "libs/ui";
import { PropsWithChildren } from "react";
import "./DetailsField.scss";

interface DetailsFieldProps {
  label: string;
  loading?: boolean;
}

export function DetailsField({
  label,
  loading = false,
  children,
}: PropsWithChildren<DetailsFieldProps>) {
  if (loading) {
    return (
      <div className="DetailsField">
        <FieldLoader />
      </div>
    );
  }

  return (
    <div className="DetailsField">
      <label className="DetailsField__label">{label}</label>
      {children}
    </div>
  );
}
