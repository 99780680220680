import * as React from "react";
import { SVGProps } from "react";
const SvgFiPrinter = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.25a.75.75 0 0 0-.75.75v6.25H4A2.75 2.75 0 0 0 1.25 11v5A2.75 2.75 0 0 0 4 18.75h1.25V22c0 .414.336.75.75.75h12a.75.75 0 0 0 .75-.75v-3.25H20A2.75 2.75 0 0 0 22.75 16v-5A2.75 2.75 0 0 0 20 8.25h-1.25V2a.75.75 0 0 0-.75-.75H6Zm11.25 7v-5.5H6.75v5.5h10.5ZM20 9.75A1.25 1.25 0 0 1 21.25 11v5A1.25 1.25 0 0 1 20 17.25h-1.25V14a.75.75 0 0 0-.75-.75H6a.75.75 0 0 0-.75.75v3.25H4A1.25 1.25 0 0 1 2.75 16v-5A1.25 1.25 0 0 1 4 9.75h16Zm-2.75 5v6.5H6.75v-6.5h10.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFiPrinter;
