import { Icon } from "libs/ui/Icon";
import "./WidgetHeader.scss";
import { useTranslation } from "react-i18next";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import { WidgetHeaderProps } from "../model";
import { getIcon } from "../utils";

export function WidgetHeader({ type, date, withIcon = true }: Readonly<WidgetHeaderProps>) {
  const { t } = useTranslation("widgets", { keyPrefix: "MeasurementsWidget" });

  const [dateFormatter] = useRegionDateTimeFormatter();

  return (
    <div className="WidgetHeader">
      {withIcon ? <Icon icon={getIcon(type)} /> : null}
      <h3 className="WidgetHeader__title">{t(type)}</h3>
      {date ? <span className="WidgetHeader__date">{dateFormatter(date)}</span> : null}
    </div>
  );
}
