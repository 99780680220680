import React from "react";
import { Icon } from "../Icon";
import { useTranslation } from "react-i18next";
import "./Paginator.scss";

export interface PaginatorProps {
  currentPage: number;
  queryCount: number;
  limit: number;
  disabledNext?: boolean;
  gotoPage: (page: number) => void;
}

export function getFromRow(currentPage: number, limit: number, queryCount: number) {
  if (currentPage <= 0 || limit <= 0) {
    throw new Error(
      `Both currentPage and limit must be positive. Got: ${JSON.stringify({ currentPage, limit })}`,
    );
  }

  if (queryCount === 0) {
    return 0;
  }

  if (currentPage === 1) {
    return 1;
  }

  return (currentPage - 1) * limit + 1;
}

export function getCurrentMaxRow(currentPage: number, limit: number, queryCount: number) {
  if (currentPage <= 0 || limit <= 0) {
    throw new Error(
      `Both currentPage and limit must be positive. Got: ${JSON.stringify({ currentPage, limit })}`,
    );
  }

  const total = currentPage * limit;

  return total < queryCount ? total : queryCount;
}

export const Paginator: React.FC<PaginatorProps> = ({
  currentPage,
  disabledNext,
  limit,
  queryCount,
  gotoPage,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "ui-components.Paginator" });
  if (Boolean(currentPage && limit && queryCount) === false) {
    return null;
  }

  if (Number.isNaN(limit) || Number.isNaN(queryCount) || Number.isNaN(currentPage)) {
    throw new Error(
      `Limit, queryCount and currentPage must be numbers. got ${JSON.stringify({ limit, queryCount, currentPage })}`,
    );
  }

  return (
    <div className="Paginator">
      <Icon
        data-testid="paginator-prev"
        icon="ChevronLeft"
        data-disabled={currentPage === 1}
        onClick={() => (currentPage === 1 ? undefined : gotoPage(currentPage - 1))}
      />
      <div className="Paginator__count">
        {t("tableItemsRange", {
          from: getFromRow(currentPage, limit, queryCount),
          to: getCurrentMaxRow(currentPage, limit, queryCount),
          of: queryCount,
        })}
      </div>
      <Icon
        data-testid="paginator-next"
        icon="ChevronRight"
        data-disabled={disabledNext}
        onClick={() => gotoPage(currentPage + 1)}
      />
    </div>
  );
};
