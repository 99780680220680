import { createToastsStore } from "libs/ui/Toaster/createToastsStore";

export const toastStore = createToastsStore();
// declare global {
//   interface Window {
//     toastStore: any;
//   }
// }

// window.toastStore = toastStore;
