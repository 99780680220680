import classNames from "classnames";
import { useRef } from "react";
import { Button } from "../../libs/ui/Button";
import { SelectFilterAsync } from "../../libs/ui/SelectFilterAsync";
import {
  ResetSelectedOptionProps,
  SelectFilterAsyncProps,
} from "../../libs/ui/SelectFilterAsync/SelectFilterAsync.model";
import { FieldLoader } from "libs/ui";

import "./SelectFilterAsyncWithCancel.scss";

export type SelectFilterAsyncWithCancelProps<T> = SelectFilterAsyncProps<T> & {
  hasInitialValue?: boolean;
  onRedirect?: () => void;
};

export function SelectFilterAsyncWithCancel<T>({
  noDataComponent,
  className,
  readOnly,
  disabled,
  getOptions,
  onSelect,
  validation,
  renderSelected,
  renderOption,
  hasInitialValue = false,
  dropdownFloatingProps,
  loading,
  onRedirect,
  ...rest
}: SelectFilterAsyncWithCancelProps<T>) {
  const selectFilterAsyncRef = useRef<ResetSelectedOptionProps>(null);

  const showCancelButton = (!!readOnly || !!disabled) === false && hasInitialValue;

  if (loading) {
    return <FieldLoader />;
  }
  return (
    <div
      onClick={readOnly && onRedirect ? onRedirect : undefined}
      className={classNames("SelectFilterAsyncWithCancel", className)}
      {...rest}
    >
      <SelectFilterAsync
        ref={selectFilterAsyncRef}
        getOptions={getOptions}
        readOnly={readOnly}
        disabled={disabled}
        onSelect={onSelect}
        renderSelected={renderSelected}
        renderOption={renderOption}
        validation={validation}
        dropdownFloatingProps={dropdownFloatingProps}
        noDataComponent={noDataComponent}
      />
      {showCancelButton ? (
        <Button
          data-testid="cancel_button"
          className="SelectFilterAsyncWithCancel__cancel"
          onClick={(e: any) => {
            e.preventDefault();
            selectFilterAsyncRef?.current?.clearSelectedOption();
          }}
          buttonSize="medium"
          buttonType="white"
          buttonIcon={{ icon: "Close" }}
        />
      ) : null}
    </div>
  );
}
