import { CkdRisk } from "api/query/models/CkdRisk";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "./Recommendation.scss";

interface RecommendationProps {
  risk?: CkdRisk;
  clampLine: number;
}

export function getSeparatorColor(risk: CkdRisk) {
  switch (risk) {
    case "VeryHighRisk":
      return "Red";
    case "HighRisk":
      return "Orange";
    case "ModerateRisk":
      return "Yellow";
    case "LowRisk":
      return "Green";

    default:
      throw new Error(`${risk} not covered`);
  }
}

export function Recommendation({ risk, clampLine }: RecommendationProps) {
  const { t } = useTranslation("widgets", { keyPrefix: "HeatMapWidget.Recommendation" });
  const clamp = { WebkitLineClamp: clampLine, lineClamp: clampLine } as any;

  return (
    <div className="Recommendation">
      {risk ? (
        <>
          <span
            className={classNames(
              "Recommendation__separator",
              `Recommendation__separator--${getSeparatorColor(risk)}`,
            )}
          />
          <p title={t(risk)} style={clamp} className="Recommendation__content">
            {t(risk)}
          </p>
        </>
      ) : null}
    </div>
  );
}
