import * as React from "react";
import { SVGProps } from "react";

export const EmptyDevices = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={72}
    height={96}
    viewBox="0 0 72 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.47967 24.9725H1.94058C0.868814 24.9725 0 24.1578 0 23.1528V16.8784C0 15.8734 0.868814 15.0587 1.94058 15.0587H3.47967V24.9725Z"
      fill="url(#paint0_linear_16694_65282)"
    />
    <path
      d="M3.47967 36.5175H1.94058C0.868814 36.5175 0 35.7029 0 34.6979V28.4234C0 27.4184 0.868814 26.6038 1.94058 26.6038H3.47967V36.5175Z"
      fill="url(#paint1_linear_16694_65282)"
    />
    <path
      d="M68.5195 24.9725H70.0586C71.1304 24.9725 71.9992 24.1578 71.9992 23.1528V16.8784C71.9992 15.8734 71.1304 15.0587 70.0586 15.0587H68.5195V24.9725Z"
      fill="url(#paint2_linear_16694_65282)"
    />
    <path
      d="M69.9231 83.7899V12.2101C69.9231 5.46673 64.0934 0 56.9018 0H15.0995C7.90789 0 2.07812 5.46673 2.07812 12.2101V83.7899C2.07812 90.5335 7.90809 96 15.0995 96H56.9018C64.0934 96 69.9231 90.5333 69.9231 83.7899Z"
      fill="url(#paint3_linear_16694_65282)"
    />
    <path
      d="M54.8421 2.76898H17.1583C10.8592 2.76898 5.75269 7.55731 5.75269 13.4639V82.5358C5.75269 88.4424 10.8592 93.2307 17.1583 93.2307H54.8423C61.1414 93.2307 66.2481 88.4424 66.2481 82.5356V13.4641C66.2479 7.55731 61.1414 2.76898 54.8421 2.76898Z"
      fill="url(#paint4_linear_16694_65282)"
    />
    <path
      d="M2.07812 52.2038V83.7897C2.07812 90.5332 7.90809 95.9997 15.0995 95.9997H56.902C64.0936 95.9997 69.9233 90.533 69.9233 83.7897V52.2038H2.07812Z"
      fill="url(#paint5_linear_16694_65282)"
    />
    <path
      d="M62.7108 76.8964V14.4994C62.7108 9.81838 58.6639 6.02356 53.6716 6.02356H18.3301C13.338 6.02356 9.29102 9.81838 9.29102 14.4994V76.8964C9.29102 81.5774 13.338 85.3723 18.3301 85.3723H53.6718C58.6639 85.3723 62.7108 81.5774 62.7108 76.8964Z"
      fill="url(#paint6_linear_16694_65282)"
    />
    <path
      d="M52.0877 13.5L19.9653 13.5C18.7639 13.5 17.7903 14.4132 17.7903 15.5395C17.7903 16.6659 18.7641 17.579 19.9653 17.579L52.0877 17.579C53.2889 17.579 54.2627 16.6658 54.2627 15.5395C54.263 14.413 53.2891 13.5 52.0877 13.5Z"
      fill="white"
    />
    <path
      d="M35.137 22.9153C35.137 21.789 34.1632 20.8758 32.962 20.8758H19.9653C18.7639 20.8758 17.7903 21.789 17.7903 22.9153C17.7903 24.0418 18.7641 24.9548 19.9653 24.9548H32.962C34.1634 24.955 35.137 24.0418 35.137 22.9153Z"
      fill="white"
    />
    <path
      d="M58.5294 84.0449L54.2627 74.1H17.7903L22.6264 85.3723H53.6707C55.4591 85.3723 57.1262 84.8852 58.5294 84.0449Z"
      fill="url(#paint7_linear_16694_65282)"
    />
    <rect
      x="17.7903"
      y="69.3"
      width="36.4724"
      height="9.6"
      rx="4.8"
      fill="url(#paint8_linear_16694_65282)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.2993 34.8008C31.1562 34.8255 31.8918 35.38 32.1017 36.1595L36.5558 52.7068L38.5751 46.523C38.7691 45.929 39.2759 45.4708 39.916 45.3108C40.5561 45.1507 41.239 45.3115 41.7226 45.736L47.4815 50.7917H54.2631C55.3233 50.7917 56.1827 51.5975 56.1827 52.5917C56.1827 53.5858 55.3233 54.3917 54.2631 54.3917H46.7232C46.2359 54.3917 45.7669 54.2179 45.4112 53.9057L41.3451 50.3361L38.2131 59.927C37.9609 60.6994 37.1909 61.2196 36.3297 61.1994C35.4686 61.1793 34.7272 60.6237 34.5164 59.8405L30.0259 43.1582L26.6336 53.1383C26.3798 53.8848 25.6402 54.3917 24.8046 54.3917H17.7907C16.7305 54.3917 15.8711 53.5858 15.8711 52.5917C15.8711 51.5975 16.7305 50.7917 17.7907 50.7917H23.4017L28.4115 36.0534C28.6718 35.2874 29.4423 34.7762 30.2993 34.8008Z"
      fill="url(#paint9_linear_16694_65282)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_16694_65282"
        x1="0.000200743"
        y1="20.0155"
        x2="2.54281"
        y2="20.0155"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAF9FA" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_16694_65282"
        x1="0.000200743"
        y1="31.5607"
        x2="2.54281"
        y2="31.5607"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAF9FA" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_16694_65282"
        x1="71.9992"
        y1="20.0155"
        x2="69.4564"
        y2="20.0155"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAF9FA" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_16694_65282"
        x1="8.1544"
        y1="21.8886"
        x2="64.2386"
        y2="81.6993"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBF5FB" />
        <stop offset="1" stopColor="#B7DCFF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_16694_65282"
        x1="68.1373"
        y1="59.9714"
        x2="9.57107"
        y2="35.1592"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DFEFFC" />
        <stop offset="1" stopColor="#B8DDFF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_16694_65282"
        x1="36.0006"
        y1="80.5061"
        x2="36.0006"
        y2="94.9729"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#67AFD5" stopOpacity="0" />
        <stop offset="0.2251" stopColor="#62ADD4" stopOpacity="0.15" />
        <stop offset="0.572" stopColor="#60ABD3" stopOpacity="0.25" />
        <stop offset="1" stopColor="#49A1CA" stopOpacity="0.5" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_16694_65282"
        x1="30.3939"
        y1="33.8073"
        x2="50.0353"
        y2="81.1786"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61DCDE" />
        <stop offset="0.1912" stopColor="#61DCDE" />
        <stop offset="0.5232" stopColor="#3FD5D7" />
        <stop offset="0.954" stopColor="#28BEC0" />
        <stop offset="1" stopColor="#28BEC0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_16694_65282"
        x1="53.7828"
        y1="82.65"
        x2="52.8806"
        y2="66.8711"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0B799D" stopOpacity="0" />
        <stop offset="0.1739" stopColor="#117D9E" stopOpacity="0.174" />
        <stop offset="0.414" stopColor="#2389A0" stopOpacity="0.414" />
        <stop offset="0.6928" stopColor="#409CA3" stopOpacity="0.693" />
        <stop offset="0.9986" stopColor="#69B7A8" stopOpacity="0.999" />
        <stop offset="1" stopColor="#69B7A8" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_16694_65282"
        x1="25.9543"
        y1="71.4488"
        x2="28.9705"
        y2="82.908"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B699FE" />
        <stop offset="1" stopColor="#8352FE" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_16694_65282"
        x1="28.3485"
        y1="36.15"
        x2="24.82"
        y2="56.6476"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1875" stopColor="#3FD5D7" />
        <stop offset="0.69945" stopColor="#28BEC0" />
        <stop offset="1" stopColor="#28BEC0" />
      </linearGradient>
    </defs>
  </svg>
);
