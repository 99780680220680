import * as React from "react";
import { SVGProps } from "react";
const SvgFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.25c-1.585 0-2.68.264-3.404.554-.36.144-.625.293-.809.416a2.66 2.66 0 0 0-.302.234l-.008.009-.004.003-.002.002s.101-.09-.001.002a.75.75 0 0 0-.22.53v19a.75.75 0 0 0 1.5 0v-6.612c.099-.056.232-.123.404-.192.526-.21 1.43-.446 2.846-.446 1.336 0 2.458.44 3.722.946l.043.018c1.225.49 2.59 1.036 4.235 1.036 1.584 0 2.68-.264 3.404-.554.36-.144.625-.293.809-.416a2.641 2.641 0 0 0 .302-.234l.008-.009.004-.003.002-.002S20.53 15.53 20 15l.53.53a.75.75 0 0 0 .22-.53V3a.75.75 0 0 0-1.268-.542l-.008.006a1.175 1.175 0 0 1-.093.068c-.098.065-.27.166-.535.272-.526.21-1.43.446-2.846.446-1.335 0-2.458-.44-3.722-.946l-.043-.018C11.01 1.796 9.645 1.25 8 1.25ZM4.75 13.745V3.388c.099-.056.232-.123.404-.192.526-.21 1.43-.446 2.846-.446 1.336 0 2.458.44 3.722.946l.043.018c1.225.49 2.59 1.036 4.235 1.036 1.476 0 2.528-.23 3.25-.495v10.357a3.322 3.322 0 0 1-.404.192c-.526.21-1.43.446-2.846.446-1.335 0-2.458-.44-3.722-.946l-.043-.018C11.01 13.796 9.645 13.25 8 13.25c-1.476 0-2.528.23-3.25.495Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFlag;
