import * as React from "react";
import type { SVGProps } from "react";
const SvgFemale = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke="url(#female_svg__a)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 15.333a6.666 6.666 0 1 0 0-13.332 6.666 6.666 0 0 0 0 13.332m0 0v4.445m0 0V22m0-2.222H9.778m2.222 0h2.222"
    />
    <defs>
      <linearGradient
        id="female_svg__a"
        x1={12}
        x2={12}
        y1={2}
        y2={22}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CDD7ED" />
        <stop offset={1} stopColor="#7085A8" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgFemale;
