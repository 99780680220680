import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Cell } from "../Cell";
import "./Status.scss";

type StatusType = "ok" | "error";

interface StatusProps {
  show?: boolean;
  status?: StatusType | boolean;
  label?: string;
  loading?: boolean;
}

export function Status({
  show = true,
  status: incomingStatus,
  label,
  loading,
}: PropsWithChildren<StatusProps>) {
  const status = incomingStatus === true || incomingStatus === "ok" ? "ok" : "error";

  return (
    <Cell loading={loading} show={show}>
      <span className={classNames("Status", incomingStatus ? `Status--${status}` : "")}>
        {label}
      </span>
    </Cell>
  );
}
