import { ReportResponseModel } from "api/report_service/models/ReportResponseModel";
import { CarnaApiReportService } from "config/apiReportService";
import { defaultModel, failed, LoadableModel, loaded, loading } from "models/loadable";
import { PropsWithChildren, useState } from "react";
import { createSafeContext } from "utils/createSafeContext";
import { createStore, useStore } from "zustand";

interface ReportsTableStore {
  tableState: LoadableModel<null>;
  page: number;
  totalPages: number;
  limit: number;
  queryCount: number;
  items: Array<ReportResponseModel>;
  fetch: (limit: number, page: number) => Promise<void>;
  fetchLatest: () => void;
  fetchPerURLSearchParams: () => void;
  setPage: (page: number) => void;
  updatePage: (newPage: number) => void;
  setLimit: (limit: number) => void;
  updateLimit: (limit: number) => void;
}

function updateSearchQueryKey(key: string, value: string) {
  const url = new URL(window.location.href);
  const { searchParams } = url;
  searchParams.set(key, value);
  history.replaceState(null, "", url.toString());
}

const createReportsTableStore = () => {
  return createStore<ReportsTableStore>()((set, get) => ({
    tableState: defaultModel(),
    page: 1,
    totalPages: 1,
    limit: 10,
    queryCount: 0,
    items: [],

    setPage: (page: number) => {
      updateSearchQueryKey("page", page.toString());
      set({ page });
    },
    updatePage: (page: number) => {
      const { fetch, limit, setPage } = get();

      setPage(page);
      fetch(limit, page);
    },

    setLimit: (limit: number) => {
      updateSearchQueryKey("limit", limit.toString());
      set({ limit });
    },
    updateLimit: (limit: number) => {
      const { fetch, setPage, setLimit } = get();
      setLimit(limit);
      setPage(1);

      fetch(limit, 1);
    },

    fetch: async (limit: number, page: number) => {
      set({ tableState: loading() });

      try {
        const { items, queryCount, pages } = await CarnaApiReportService.Reports.get(limit, page);

        set({
          items,
          queryCount,
          totalPages: pages,
          tableState: loaded(null),
        });
      } catch (error) {
        set({
          tableState: failed(error),
        });
      }
    },
    fetchLatest: () => {
      const { fetch, limit, setPage } = get();
      setPage(1);
      fetch(limit, 1);
    },
    fetchPerURLSearchParams: () => {
      const urlParams = new URLSearchParams(window.location.search);
      const limit = Number(urlParams.get("limit") ?? 10);
      const page = Number(urlParams.get("page") ?? 1);

      const { fetch, setPage, setLimit } = get();
      setLimit(limit);
      setPage(page);
      fetch(limit, page);
    },
  }));
};

type ReportsTableContext = ReturnType<typeof createReportsTableStore>;

const ReportsTableContext = createSafeContext<ReportsTableContext>();

export function ReportsTableContextProvider({ children }: PropsWithChildren) {
  /**
   * useState for One-Time Initializations
   * {@link https://tkdodo.eu/blog/use-state-for-one-time-initializations}
   */
  const [value] = useState(createReportsTableStore);

  return <ReportsTableContext.Provider value={value}>{children}</ReportsTableContext.Provider>;
}

export function useReportsTableContext<T>(selector: (state: ReportsTableStore) => T): T {
  /**
   * custom hook for atomic select
   * {@link https://zustand.docs.pmnd.rs/guides/initialize-state-with-props#extracting-context-logic-into-a-custom-hook}
   */
  return useStore(ReportsTableContext.hook(), selector);
}
