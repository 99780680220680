export interface FetchPictureUrlsResult {
  UserEntityId: string;
  Url: string;
}

export interface GetSignedUrlForUploadParams {
  fileSize: number;
}

export interface GetSignedUrlForUploadResult {
  UserEntityId: {
    Value: "string";
  };
  Url: "string";
}

export const PictureResolution = {
  "320x320": "R320x320",
  "112x112": "R112x112",
  "36x36": "R36x36",
  table: "R36x36",
  breadcrumb: "R36x36",
  details: "R36x36",
  grid: "R112x112",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PictureResolution = (typeof PictureResolution)[keyof typeof PictureResolution];
