import "./TextArea.scss";

import classNames from "classnames";
import { useCallback, useState } from "react";

import { FieldValidation } from "../FieldValidation";
import { FormFieldBasic } from "../models";
import { PositiveIntegers } from "models";

type HTMLTextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;
interface TextAreaProps<N extends number> extends HTMLTextAreaProps, FormFieldBasic {
  textareaClassName?: string;
  countLimit?: PositiveIntegers<N>;
}

export function TextArea<N extends number>({
  label,
  validation,
  className,
  textareaClassName,
  value,
  variant = "regular",
  countLimit,
  onChange,
  ...props
}: TextAreaProps<N>) {
  const [active, setActive] = useState(false);
  const { successText, errorText } = validation ?? {};

  const onTextAreaChange = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement, Element>) => {
      const { value } = event.target;

      if (onChange && ((countLimit && value.length <= countLimit) || !countLimit)) {
        onChange(event);
      }
    },
    [countLimit, onChange],
  );

  return (
    <div data-type={variant} className={classNames("UI-Components TextArea", className)}>
      <textarea
        {...props}
        className={classNames(
          "TextArea__element",
          errorText && "TextArea__element--error",
          successText && "TextArea__element--success",
          textareaClassName,
        )}
        value={value}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        onChange={onTextAreaChange}
      />
      {label && (
        <label
          className={classNames(
            "TextArea__label",
            (value || active) && "TextArea__label--active",
            errorText && "TextArea__label--error",
            successText && "TextArea__label--success",
          )}
        >
          {label}
        </label>
      )}
      {!!countLimit && (
        <span className="TextArea__count">{`${String(value ?? "").length}/${countLimit}`}</span>
      )}
      <FieldValidation {...validation} />
    </div>
  );
}
