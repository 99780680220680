import { useMachine } from "@xstate/react";
import { UseMatchesType } from "components/PageTemplate/SettingsPage";
import { breadcrumbsStore } from "config/breadcrumbs";
import { BreadCrumb } from "libs/ui";
import { useEffect, useSyncExternalStore } from "react";
import { generatePath, matchPath, useParams } from "react-router-dom";
import { RouteValues } from "router/model";
import { PictureResolution } from "./helpers/pictures.model";
import { avatarCache } from "./machines/avatars";
import { makeDetailsPageStateMachine } from "./machines/pages/details/makeDetailsPageStateMachine";

/**
 *
 * @param routeNames
 * const RouteNames = {
 *   dashboard: mainRoutePaths.dashboard,
 *   ...
 * } as const;
 */

export function getBreadCrumbsFromMatches(routeNames: RouteValues, matches: UseMatchesType[]) {
  const myBreadCrumb: BreadCrumb[] = [];

  for (const match of matches) {
    const [pathTitle] =
      Object.entries(routeNames).find(([, value]) => matchPath(value, match?.pathname)) ?? [];

    if (pathTitle && match?.pathname !== "/" && !match?.id.endsWith("-0")) {
      myBreadCrumb.push({
        title: pathTitle,
        path: generatePath(match?.pathname),
      });
    }
  }

  return myBreadCrumb;
}

export function getBreadCrumbsFromCurrentLocation(
  routeNames: RouteValues, //Record<string, string>,
  pathname: string,
) {
  const myBreadCrumb: BreadCrumb[] = [];

  const currentLocation = pathname.split("/");

  for (let index = 0; index < currentLocation.length; index++) {
    const currentPath = currentLocation.slice(0, index + 1).join("/");

    const [pathTitle, pathValue] =
      Object.entries(routeNames)
        .reverse()
        .find(([, value]) => matchPath(value, currentPath)) ?? [];

    if (pathTitle && pathValue) {
      const { params } = matchPath(pathValue, currentPath) ?? {};

      myBreadCrumb.push({
        title: pathTitle,
        path: generatePath(pathValue, params as any),
      });
    }
  }

  return myBreadCrumb;
}

export function useDetailsSetBreadcrumbsToName<
  State extends ReturnType<
    typeof useMachine<ReturnType<typeof makeDetailsPageStateMachine<any, any>>>
  >[0],
>(state: State) {
  useEffect(() => {
    if (!state.matches(`entity.loading`)) {
      breadcrumbsStore.setCurrentPageTitle(
        `${state.context?.data?.firstName} ${state.context?.data?.lastName}`,
      );
    }
    return () => breadcrumbsStore.resetCurrentPageTitle();
  }, [state]);
}

export function useDetailsSetAvatarBreadcrumbs() {
  const params = useParams();
  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  /**
   * ! Should be outside of useEffect
   * ! useSyncExternalStore will refresh this avatar if something changes it
   */
  const avatarImg =
    params.organizationId && (params.patientId ?? params.userId ?? params.hcpId)
      ? avatarCache.get(
          params.organizationId,
          params.patientId ?? params.userId ?? params.hcpId,
          PictureResolution["320x320"],
        )
      : undefined;

  useEffect(() => {
    breadcrumbsStore.setCurrentPageAvatar(avatarImg ?? "");

    return () => breadcrumbsStore.setCurrentPageAvatar();
  }, [avatarImg]);
}
