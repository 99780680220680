import classNames from "classnames";
import "./TotalNumberCandle.scss";

interface TotalNumberCandleProps {
  totalPatients: number;
  patientWithRisk: number;
}

function percentage(totalPatients: number, patientWithRisk: number) {
  if (totalPatients === 0 || patientWithRisk === 0) {
    return 0;
  }

  return (patientWithRisk / totalPatients) * 100;
}

export function TotalNumberCandle({ totalPatients, patientWithRisk }: TotalNumberCandleProps) {
  const noData = totalPatients === 0;

  return (
    <div className="TotalNumberCandle">
      <div
        className={classNames("TotalNumberCandle__bar", {
          "TotalNumberCandle__bar--no-data": noData,
        })}
      >
        <div
          className="TotalNumberCandle__bar-inner"
          style={{ width: `${percentage(totalPatients, patientWithRisk)}%` }}
        />
      </div>
    </div>
  );
}
