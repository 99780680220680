/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  DeviceResponseModel,
  DeviceResponseModelListModel,
  DevicesPerOrganizationFilterModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    DeviceResponseModelFromJSON,
    DeviceResponseModelToJSON,
    DeviceResponseModelListModelFromJSON,
    DeviceResponseModelListModelToJSON,
    DevicesPerOrganizationFilterModelFromJSON,
    DevicesPerOrganizationFilterModelToJSON,
} from '../models/index';

export interface OrganizationsOrganizationIdDevicesDeviceEntityIdGetRequest {
    organizationId: string;
    deviceEntityId: string;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdDevicesGetRequest {
    organizationId: string;
    filters?: DevicesPerOrganizationFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdDevicesUserUserEntityIdGetRequest {
    organizationId: string;
    userEntityId: string;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

/**
 * DevicesApi - interface
 * 
 * @export
 * @interface DevicesApiInterface
 */
export interface DevicesApiInterface {
    /**
     * 
     * @param {string} organizationId 
     * @param {string} deviceEntityId 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApiInterface
     */
    organizationsOrganizationIdDevicesDeviceEntityIdGetRaw(requestParameters: OrganizationsOrganizationIdDevicesDeviceEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceResponseModel>>;

    /**
     */
    organizationsOrganizationIdDevicesDeviceEntityIdGet(requestParameters: OrganizationsOrganizationIdDevicesDeviceEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceResponseModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {DevicesPerOrganizationFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApiInterface
     */
    organizationsOrganizationIdDevicesGetRaw(requestParameters: OrganizationsOrganizationIdDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceResponseModelListModel>>;

    /**
     */
    organizationsOrganizationIdDevicesGet(requestParameters: OrganizationsOrganizationIdDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceResponseModelListModel>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApiInterface
     */
    organizationsOrganizationIdDevicesUserUserEntityIdGetRaw(requestParameters: OrganizationsOrganizationIdDevicesUserUserEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceResponseModelListModel>>;

    /**
     */
    organizationsOrganizationIdDevicesUserUserEntityIdGet(requestParameters: OrganizationsOrganizationIdDevicesUserUserEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceResponseModelListModel>;

}

/**
 * 
 */
export class DevicesApi extends runtime.BaseAPI implements DevicesApiInterface {

     /**
     */
    async organizationsOrganizationIdDevicesDeviceEntityIdGetRawOriginal(requestParameters: OrganizationsOrganizationIdDevicesDeviceEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesDeviceEntityIdGet.');
        }

        if (requestParameters.deviceEntityId === null || requestParameters.deviceEntityId === undefined) {
            throw new runtime.RequiredError('deviceEntityId','Required parameter requestParameters.deviceEntityId was null or undefined when calling organizationsOrganizationIdDevicesDeviceEntityIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/devices/{deviceEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"deviceEntityId"}}`, encodeURIComponent(String(requestParameters.deviceEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdDevicesDeviceEntityIdGetRaw(requestParameters: OrganizationsOrganizationIdDevicesDeviceEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceResponseModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesDeviceEntityIdGet.');
        }

        if (requestParameters.deviceEntityId === null || requestParameters.deviceEntityId === undefined) {
            throw new runtime.RequiredError('deviceEntityId','Required parameter requestParameters.deviceEntityId was null or undefined when calling organizationsOrganizationIdDevicesDeviceEntityIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/devices/{deviceEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"deviceEntityId"}}`, encodeURIComponent(String(requestParameters.deviceEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdDevicesDeviceEntityIdGet(requestParameters: OrganizationsOrganizationIdDevicesDeviceEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceResponseModel> {
        const response = await this.organizationsOrganizationIdDevicesDeviceEntityIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdDevicesGetRawOriginal(requestParameters: OrganizationsOrganizationIdDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/devices`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdDevicesGetRaw(requestParameters: OrganizationsOrganizationIdDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceResponseModelListModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/devices`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdDevicesGet(requestParameters: OrganizationsOrganizationIdDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceResponseModelListModel> {
        const response = await this.organizationsOrganizationIdDevicesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async organizationsOrganizationIdDevicesUserUserEntityIdGetRawOriginal(requestParameters: OrganizationsOrganizationIdDevicesUserUserEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesUserUserEntityIdGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdDevicesUserUserEntityIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/devices/user/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdDevicesUserUserEntityIdGetRaw(requestParameters: OrganizationsOrganizationIdDevicesUserUserEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceResponseModelListModel>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesUserUserEntityIdGet.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdDevicesUserUserEntityIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/devices/user/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdDevicesUserUserEntityIdGet(requestParameters: OrganizationsOrganizationIdDevicesUserUserEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceResponseModelListModel> {
        const response = await this.organizationsOrganizationIdDevicesUserUserEntityIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
