import className from "classnames";
import { useAppMenuTogglerContext } from "context/AppMenuTogglerContext";
import "./Hamburger.scss";

export const Hamburger = () => {
  const menuOpen = useAppMenuTogglerContext(state => state.menuOpen);
  const setMenuOpen = useAppMenuTogglerContext(state => state.onMenuToggle);

  return (
    <button
      className={className("Hamburger", {
        "Hamburger--active": menuOpen,
      })}
      type="button"
      onClick={() => setMenuOpen(!menuOpen)}
    >
      <span className="Hamburger__bar Hamburger__bar--top" />
      <span className="Hamburger__bar Hamburger__bar--middle" />
      <span className="Hamburger__bar Hamburger__bar--bottom" />
    </button>
  );
};
