/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PatientDetailsModel } from './PatientDetailsModel';
import {
    PatientDetailsModelFromJSON,
    PatientDetailsModelFromJSONTyped,
    PatientDetailsModelToJSON,
} from './PatientDetailsModel';
import type { TestsPageModel } from './TestsPageModel';
import {
    TestsPageModelFromJSON,
    TestsPageModelFromJSONTyped,
    TestsPageModelToJSON,
} from './TestsPageModel';

/**
 * 
 * @export
 * @interface PagesModel
 */
export interface PagesModel {
    /**
     * 
     * @type {TestsPageModel}
     * @memberof PagesModel
     */
    testsPage: TestsPageModel;
    /**
     * 
     * @type {PatientDetailsModel}
     * @memberof PagesModel
     */
    patientDetails: PatientDetailsModel;
}

/**
 * Check if a given object implements the PagesModel interface.
 */
export function instanceOfPagesModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "testsPage" in value;
    isInstance = isInstance && "patientDetails" in value;

    return isInstance;
}

export function PagesModelFromJSON(json: any): PagesModel {
    return PagesModelFromJSONTyped(json, false);
}

export function PagesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'testsPage': TestsPageModelFromJSON(json['TestsPage']),
        'patientDetails': PatientDetailsModelFromJSON(json['PatientDetails']),
    };
}

export function PagesModelToJSON(value?: PagesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TestsPage': TestsPageModelToJSON(value.testsPage),
        'PatientDetails': PatientDetailsModelToJSON(value.patientDetails),
    };
}

