import * as React from "react";
import { SVGProps } from "react";
const SvgLaboratory = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#laboratory_svg__a)" fill="currentColor">
      <path d="M10.17 13.54a.703.703 0 1 1-1.406 0 .703.703 0 0 1 1.407 0ZM5.483 18.264a.703.703 0 1 1-1.406 0 .703.703 0 0 1 1.406 0ZM9.176 16.523a.703.703 0 1 1-1.406 0 .703.703 0 0 1 1.406 0Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.28 5.196 18.806.72a2.461 2.461 0 0 0-4.059 2.568L1.234 16.839a4.222 4.222 0 0 0 .322 6.256c1.697 1.389 4.223 1.13 5.787-.433l13.37-13.406a2.461 2.461 0 0 0 2.567-4.06ZM6.348 21.668c-1.089 1.088-2.803 1.237-3.902.338a2.813 2.813 0 0 1-.217-4.175l7.995-8.015c.278.062.69.198.983.492.48.48.54 1.271.541 1.283.008.135.1 1.346.951 2.198.277.277.592.473.896.612l-7.247 7.267ZM22.286 7.682c-.41.41-1.075.413-1.488.003l-.75-.75a.703.703 0 0 0-.994.995l.498.498-4.85 4.863c-.277-.06-.708-.196-1.009-.497-.481-.481-.54-1.278-.54-1.282-.008-.136-.1-1.347-.951-2.198a3.064 3.064 0 0 0-.876-.604l4.249-4.26.497.498a.703.703 0 0 0 .994-.995l-.746-.746a1.054 1.054 0 1 1 1.491-1.491l4.475 4.474c.411.411.411 1.08 0 1.492Z"
      />
    </g>
    <defs>
      <clipPath id="laboratory_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLaboratory;
