import { useTranslation } from "react-i18next";
import { EmptyFolder } from "./../../../assets/svg";
import "./NoFilterResults.scss";

export function NoFilterResults() {
  const { t } = useTranslation("translation", { keyPrefix: "ui-components.Table" });

  return (
    <div className="NoFilterResults">
      <EmptyFolder className="NoFilterResults__icon" />
      <h3 className="NoFilterResults__title">{t("noFilterDataTitle")}</h3>
      <p className="NoFilterResults__description">{t("noFilterDataDescription")}</p>
    </div>
  );
}
