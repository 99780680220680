import { Tabs } from "libs/ui";
import { useTranslation } from "react-i18next";
import { TwoFATab } from "./TwoFATab";
import { PasswordTab } from "./PasswordTab";
import { SettingsPage } from "components/PageTemplate/SettingsPage";

export function Login() {
  const { t } = useTranslation("translation", { keyPrefix: "PageTemplate.Settings.login" });
  return (
    <SettingsPage>
      <Tabs
        tabs={[
          {
            label: t("Tabs.Password.tab_title"),
            Component: <PasswordTab />,
          },
          {
            label: t("Tabs.TwoFA.tab_title"),
            Component: <TwoFATab />,
          },
        ]}
      />
    </SettingsPage>
  );
}
