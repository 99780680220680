import { toastStore } from "config/toast";
import { Button, Icon, Input, TooltipComponent, useGetTooltipProps } from "libs/ui";
import { useResponsive } from "libs/ui/hooks";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Password.scss";
import { useClick, useFocus, useRole } from "@floating-ui/react";
import { PasswordStrength } from "components/PasswordStrength";
import { passwordStrength } from "utils/validators/passwordStrength";
import { fetchAuthSession, updatePassword } from "@aws-amplify/auth";

interface PasswordState {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface PasswordHiddenState {
  currentPassword: boolean;
  newPassword: boolean;
  confirmPassword: boolean;
}

const PASSWORD_INIT = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export function PasswordTab() {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.login.Tabs.Password",
  });
  const { t: tAws } = useTranslation("translation", { keyPrefix: "AWS-Cognito" });

  const [loading, setLoading] = useState(false);
  const [invalidCurrentPassword, setInvalidCurrentPassword] = useState(false);

  const [passwords, setPasswords] = useState<PasswordState>(PASSWORD_INIT);
  const [showText, setShowText] = useState<PasswordHiddenState>({
    confirmPassword: false,
    currentPassword: false,
    newPassword: false,
  });

  const { isDesktop } = useResponsive();

  const {
    x,
    y,
    refs,
    strategy,
    arrowRef,
    arrowX,
    arrowY,
    open,
    getReferenceProps,
    getFloatingProps,
  } = useGetTooltipProps({
    interactionProps: [useFocus, useRole, useClick],
    placement: isDesktop ? "right" : "top",
    crossAxis: isDesktop ? 30 : 0,
    mainAxis: isDesktop ? 40 : 20,
  });

  const passwordStrengthResult = passwordStrength(passwords.newPassword);
  const validationFailed = Object.values(passwordStrengthResult).some(v => v === false);

  const onPasswordChange = useCallback(
    (key: keyof PasswordState) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setPasswords(prev => ({
        ...prev,
        [key]: e.target.value,
      }));
    },
    [],
  );

  const onSubmit = useCallback(() => {
    setLoading(true);
    setInvalidCurrentPassword(false);

    fetchAuthSession()
      .then(user => {
        return updatePassword({
          oldPassword: passwords.currentPassword,
          newPassword: passwords.newPassword,
        });
      })
      .then(data => {
        setLoading(false);
        setPasswords(PASSWORD_INIT);
        toastStore.pushToast({ type: "success", msg: t("success"), expire: 5000 });
      })
      .catch(err => {
        setLoading(false);
        if (err.code === "NotAuthorizedException") {
          setInvalidCurrentPassword(true);
          return;
        }

        toastStore.pushToast({ type: "error", msg: tAws(err.code) });
      });
  }, [passwords.currentPassword, passwords.newPassword, t, tAws]);

  const toggleShowPassword = useCallback(
    (key: keyof PasswordHiddenState) =>
      setShowText(prevValue => ({
        ...prevValue,
        [key]: !prevValue[key],
      })),
    [],
  );

  const newPasswordValidation =
    passwords.confirmPassword.length > 0 && passwords.newPassword.length === 0
      ? t("insert_new_pass_first")
      : undefined;

  const confirmPasswordValidation =
    !!passwords.confirmPassword.length && passwords.confirmPassword !== passwords.newPassword
      ? t("confirm_pass_must_match_new")
      : undefined;

  return (
    <div className="Settings-Login-Password-Tab">
      <p>{t("page_title")}</p>
      <Input
        validation={invalidCurrentPassword ? { errorText: t("invalidCurrentPassword") } : undefined}
        disabled={loading}
        onChange={onPasswordChange("currentPassword")}
        id="current-password"
        label={t("current_password")}
        type={showText.currentPassword ? "text" : "password"}
        autoComplete="current-password"
        required
        value={passwords.currentPassword}
        inputIcon={{
          icon: (
            <Icon
              icon={showText.currentPassword ? "Hide" : "Show"}
              onClick={() => toggleShowPassword("currentPassword")}
            />
          ),
          iconPosition: "trailing",
        }}
      />
      <p>{t("set_new_password")}</p>
      <Input
        disabled={loading}
        onChange={onPasswordChange("newPassword")}
        id="new-password"
        label={t("new_password")}
        type={showText.newPassword ? "text" : "password"}
        autoComplete="new-password"
        required
        value={passwords.newPassword}
        ref={refs.setReference}
        {...getReferenceProps()}
        inputIcon={{
          icon: (
            <Icon
              icon={showText.newPassword ? "Hide" : "Show"}
              onClick={() => toggleShowPassword("newPassword")}
            />
          ),
          iconPosition: "trailing",
        }}
        validation={{ errorText: newPasswordValidation }}
      />

      <TooltipComponent
        type="white"
        ref={refs.setFloating}
        x={x}
        y={y}
        floatingProps={getFloatingProps()}
        open={open}
        strategy={strategy}
        alwaysShown={isDesktop}
        arrowRef={arrowRef}
        arrowX={arrowX}
        arrowY={arrowY}
        arrowSize="big"
        arrowPlacement={isDesktop ? "right" : "top"}
      >
        <PasswordStrength
          minLength={passwordStrengthResult.minLength}
          atLeastOneUppercase={passwordStrengthResult.atLeastOneUppercase}
          atLeastOneNumber={passwordStrengthResult.atLeastOneNumber}
          atLeastOneSpecial={passwordStrengthResult.atLeastOneSpecial}
        />
      </TooltipComponent>

      <Input
        disabled={loading}
        onChange={onPasswordChange("confirmPassword")}
        label={t("confirm_password")}
        type={showText.confirmPassword ? "text" : "password"}
        autoComplete="new-password"
        required
        value={passwords.confirmPassword}
        inputIcon={{
          icon: (
            <Icon
              icon={showText.confirmPassword ? "Hide" : "Show"}
              onClick={() => toggleShowPassword("confirmPassword")}
            />
          ),
          iconPosition: "trailing",
        }}
        validation={{
          errorText: confirmPasswordValidation,
        }}
      />

      <div className="Settings-Login-Password-Tab__actions">
        <Button buttonType={"grey"} buttonSize={"medium"}>
          {t("cancel")}
        </Button>
        <Button
          onClick={onSubmit}
          buttonType={"primary"}
          buttonSize={"medium"}
          disabled={
            loading ||
            !!passwords.currentPassword === false ||
            passwords.newPassword !== passwords.confirmPassword ||
            validationFailed
          }
          loading={loading}
        >
          {t("update_password")}
        </Button>
      </div>
    </div>
  );
}
