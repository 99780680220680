import { Tabs } from "libs/ui";
import { useTranslation } from "react-i18next";
import { SettingsPage } from "components/PageTemplate/SettingsPage";
import { SerumCreatinineEgfr } from "./tabs/SerumCreatinineEgfr";

export function Laboratory() {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.rule-engine.laboratory",
  });

  return (
    <SettingsPage>
      <div className="RuleEngine__Laboratory">
        <Tabs
          tabs={[
            {
              label: t("tabs.serum_creatinine_and_egfr"),
              Component: <SerumCreatinineEgfr />,
            },
            {
              label: t("tabs.blood_pressure"),
              Component: <p>Under construction</p>,
              disabled: true,
            },
            {
              label: t("tabs.bmi"),
              Component: <p>Under construction</p>,
              disabled: true,
            },
            {
              label: t("tabs.temperature"),
              Component: <p>Under construction</p>,
              disabled: true,
            },
          ]}
        />
      </div>
    </SettingsPage>
  );
}
