/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UniqueIdModel } from './UniqueIdModel';
import {
    UniqueIdModelFromJSON,
    UniqueIdModelFromJSONTyped,
    UniqueIdModelToJSON,
} from './UniqueIdModel';

/**
 * 
 * @export
 * @interface DeviceModel
 */
export interface DeviceModel {
    /**
     * 
     * @type {UniqueIdModel}
     * @memberof DeviceModel
     */
    uniqueId: UniqueIdModel;
}

/**
 * Check if a given object implements the DeviceModel interface.
 */
export function instanceOfDeviceModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uniqueId" in value;

    return isInstance;
}

export function DeviceModelFromJSON(json: any): DeviceModel {
    return DeviceModelFromJSONTyped(json, false);
}

export function DeviceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uniqueId': UniqueIdModelFromJSON(json['UniqueId']),
    };
}

export function DeviceModelToJSON(value?: DeviceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'UniqueId': UniqueIdModelToJSON(value.uniqueId),
    };
}

