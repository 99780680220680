/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddPartnerRequestModel,
  CommonProblemDetailsModel,
  ReplacePartnerRequestModel,
  ResendInviteUserRequestModel,
  UpdateUserStatusRequestModel,
} from '../models/index';
import {
    AddPartnerRequestModelFromJSON,
    AddPartnerRequestModelToJSON,
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    ReplacePartnerRequestModelFromJSON,
    ReplacePartnerRequestModelToJSON,
    ResendInviteUserRequestModelFromJSON,
    ResendInviteUserRequestModelToJSON,
    UpdateUserStatusRequestModelFromJSON,
    UpdateUserStatusRequestModelToJSON,
} from '../models/index';

export interface OrganizationsOrganizationIdPartnersPostRequest {
    organizationId: string;
    acceptVersion?: string;
    addPartnerRequestModel?: AddPartnerRequestModel;
}

export interface OrganizationsOrganizationIdPartnersUserEntityIdDeleteRequest {
    organizationId: string;
    userEntityId: string;
    rowVersion?: string;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdPartnersUserEntityIdPatchRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    updateUserStatusRequestModel?: UpdateUserStatusRequestModel;
}

export interface OrganizationsOrganizationIdPartnersUserEntityIdPutRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    replacePartnerRequestModel?: ReplacePartnerRequestModel;
}

export interface OrganizationsOrganizationIdPartnersUserEntityIdResendInvitePostRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    resendInviteUserRequestModel?: ResendInviteUserRequestModel;
}

/**
 * PartnersApi - interface
 * 
 * @export
 * @interface PartnersApiInterface
 */
export interface PartnersApiInterface {
    /**
     * 
     * @param {string} organizationId 
     * @param {string} [acceptVersion] 
     * @param {AddPartnerRequestModel} [addPartnerRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApiInterface
     */
    organizationsOrganizationIdPartnersPostRaw(requestParameters: OrganizationsOrganizationIdPartnersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdPartnersPost(requestParameters: OrganizationsOrganizationIdPartnersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [rowVersion] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApiInterface
     */
    organizationsOrganizationIdPartnersUserEntityIdDeleteRaw(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdPartnersUserEntityIdDelete(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {UpdateUserStatusRequestModel} [updateUserStatusRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApiInterface
     */
    organizationsOrganizationIdPartnersUserEntityIdPatchRaw(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdPartnersUserEntityIdPatch(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {ReplacePartnerRequestModel} [replacePartnerRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApiInterface
     */
    organizationsOrganizationIdPartnersUserEntityIdPutRaw(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdPartnersUserEntityIdPut(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {ResendInviteUserRequestModel} [resendInviteUserRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApiInterface
     */
    organizationsOrganizationIdPartnersUserEntityIdResendInvitePostRaw(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdPartnersUserEntityIdResendInvitePost(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class PartnersApi extends runtime.BaseAPI implements PartnersApiInterface {

     /**
     */
    async organizationsOrganizationIdPartnersPostRawOriginal(requestParameters: OrganizationsOrganizationIdPartnersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/partners`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPartnerRequestModelToJSON(requestParameters.addPartnerRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPartnersPostRaw(requestParameters: OrganizationsOrganizationIdPartnersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/partners`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPartnerRequestModelToJSON(requestParameters.addPartnerRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdPartnersPost(requestParameters: OrganizationsOrganizationIdPartnersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdPartnersPostRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdPartnersUserEntityIdDeleteRawOriginal(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdDelete.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowVersion !== undefined) {
            queryParameters['rowVersion'] = requestParameters.rowVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/partners/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPartnersUserEntityIdDeleteRaw(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdDelete.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowVersion !== undefined) {
            queryParameters['rowVersion'] = requestParameters.rowVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/partners/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdPartnersUserEntityIdDelete(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdPartnersUserEntityIdDeleteRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdPartnersUserEntityIdPatchRawOriginal(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdPatch.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/partners/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserStatusRequestModelToJSON(requestParameters.updateUserStatusRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPartnersUserEntityIdPatchRaw(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdPatch.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/partners/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserStatusRequestModelToJSON(requestParameters.updateUserStatusRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdPartnersUserEntityIdPatch(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdPartnersUserEntityIdPatchRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdPartnersUserEntityIdPutRawOriginal(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdPut.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/partners/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReplacePartnerRequestModelToJSON(requestParameters.replacePartnerRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPartnersUserEntityIdPutRaw(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdPut.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/partners/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReplacePartnerRequestModelToJSON(requestParameters.replacePartnerRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdPartnersUserEntityIdPut(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdPartnersUserEntityIdPutRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdPartnersUserEntityIdResendInvitePostRawOriginal(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdResendInvitePost.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdResendInvitePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/partners/{userEntityId}/resend-invite`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendInviteUserRequestModelToJSON(requestParameters.resendInviteUserRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdPartnersUserEntityIdResendInvitePostRaw(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdResendInvitePost.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdPartnersUserEntityIdResendInvitePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/partners/{userEntityId}/resend-invite`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendInviteUserRequestModelToJSON(requestParameters.resendInviteUserRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdPartnersUserEntityIdResendInvitePost(requestParameters: OrganizationsOrganizationIdPartnersUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdPartnersUserEntityIdResendInvitePostRaw(requestParameters, initOverrides);
    }

}
