import classNames from "classnames";
import { PropsWithChildren } from "react";

interface SimpleFormLayoutGroupProps {
  classname?: string;
  title: string;
}
export function SimpleFormLayoutGroup({
  children,
  classname,
  title,
}: PropsWithChildren<SimpleFormLayoutGroupProps>) {
  return (
    <div className={classNames("SimpleFormLayoutGroup", classname)}>
      <div className="SimpleFormLayoutGroup--title">{title}</div>
      {children}
    </div>
  );
}
