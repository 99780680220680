/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceResponseModel } from './DeviceResponseModel';
import {
    DeviceResponseModelFromJSON,
    DeviceResponseModelFromJSONTyped,
    DeviceResponseModelToJSON,
} from './DeviceResponseModel';

/**
 * 
 * @export
 * @interface DeviceResponseModelListModel
 */
export interface DeviceResponseModelListModel {
    /**
     * 
     * @type {number}
     * @memberof DeviceResponseModelListModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceResponseModelListModel
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceResponseModelListModel
     */
    queryCount: number;
    /**
     * 
     * @type {Array<DeviceResponseModel>}
     * @memberof DeviceResponseModelListModel
     */
    items: Array<DeviceResponseModel>;
    /**
     * 
     * @type {number}
     * @memberof DeviceResponseModelListModel
     */
    readonly pages?: number;
}

/**
 * Check if a given object implements the DeviceResponseModelListModel interface.
 */
export function instanceOfDeviceResponseModelListModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "queryCount" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function DeviceResponseModelListModelFromJSON(json: any): DeviceResponseModelListModel {
    return DeviceResponseModelListModelFromJSONTyped(json, false);
}

export function DeviceResponseModelListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceResponseModelListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': json['Limit'],
        'page': json['Page'],
        'queryCount': json['QueryCount'],
        'items': ((json['Items'] as Array<any>).map(DeviceResponseModelFromJSON)),
        'pages': !exists(json, 'Pages') ? undefined : json['Pages'],
    };
}

export function DeviceResponseModelListModelToJSON(value?: DeviceResponseModelListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Limit': value.limit,
        'Page': value.page,
        'QueryCount': value.queryCount,
        'Items': ((value.items as Array<any>).map(DeviceResponseModelToJSON)),
    };
}

