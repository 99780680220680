import { DeviceResponseModel, DeviceType } from "api/query";
import { useCallback } from "react";
import { IsNullOrUndefined } from "utils/guards";

interface Model {
  deviceId?: string | null;
  deviceSerialNumber?: string | null;
  deviceType?: DeviceType;
  deviceRowVersion?: string | null;
  oldDeviceRowVersion?: string | null;
  newDeviceRowVersion?: string | null;
}

export function useOnDeviceChange<M extends Model>(
  person: M,
  onChange: <K extends keyof M>(value: M[K], inputKey: K) => void,
) {
  const getIsDeviceSwap = useCallback(
    (value?: DeviceResponseModel) =>
      !!(
        value !== undefined &&
        person.deviceRowVersion &&
        value.rowVersion !== person.deviceRowVersion
      ),
    [person.deviceRowVersion],
  );

  const getIsFirstDeviceAssign = useCallback(
    (value?: DeviceResponseModel) =>
      !!(value !== undefined && IsNullOrUndefined(person.deviceRowVersion)),
    [person.deviceRowVersion],
  );

  const getIsDeviceDeselected = useCallback(
    (value?: DeviceResponseModel) =>
      !!(value === undefined && !IsNullOrUndefined(person.deviceRowVersion)),
    [person.deviceRowVersion],
  );

  const onDeviceChange = useCallback(
    (value?: DeviceResponseModel) => {
      onChange(!value ? undefined : value.id ?? undefined, "deviceId");
      onChange(!value ? undefined : value.serialNumber ?? undefined, "deviceSerialNumber");

      if (getIsDeviceSwap(value)) {
        onChange(person.deviceRowVersion, "oldDeviceRowVersion");
        onChange(value?.rowVersion, "newDeviceRowVersion");
        return;
      }

      if (getIsFirstDeviceAssign(value)) {
        onChange(undefined, "oldDeviceRowVersion");
        onChange(value?.rowVersion, "newDeviceRowVersion");
        onChange(value?.rowVersion, "deviceRowVersion"); // When creating new patient, we need to add this to `deviceRowVersion` field
        return;
      }

      if (getIsDeviceDeselected()) {
        onChange(person.deviceRowVersion, "oldDeviceRowVersion");
        onChange(undefined, "newDeviceRowVersion");
      }
    },
    [
      getIsDeviceDeselected,
      getIsDeviceSwap,
      getIsFirstDeviceAssign,
      onChange,
      person.deviceRowVersion,
    ],
  );

  return [onDeviceChange] as const;
}
