import * as React from "react";
import { SVGProps } from "react";
const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 17a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1ZM10.78 8.056a2.25 2.25 0 0 1 3.39 1.943V10c0 .602-.461 1.156-1.166 1.626a6.305 6.305 0 0 1-1.302.656l-.017.006h-.003a.75.75 0 0 0 .475 1.423L11.92 13l.238.711h.001l.004-.001.01-.004.032-.011.11-.041a7.8 7.8 0 0 0 1.52-.78c.796-.53 1.835-1.476 1.835-2.873a3.75 3.75 0 0 0-7.288-1.25.75.75 0 1 0 1.415.498 2.25 2.25 0 0 1 .983-1.193Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12ZM12 2.75a9.25 9.25 0 1 0 0 18.5 9.25 9.25 0 0 0 0-18.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgHelp;
