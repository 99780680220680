/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface ProfileOrganizationResponseModel
 */
export interface ProfileOrganizationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ProfileOrganizationResponseModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOrganizationResponseModel
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOrganizationResponseModel
     */
    isMain: boolean;
    /**
     * 
     * @type {Address}
     * @memberof ProfileOrganizationResponseModel
     */
    address: Address;
    /**
     * 
     * @type {string}
     * @memberof ProfileOrganizationResponseModel
     */
    phone: string;
}

/**
 * Check if a given object implements the ProfileOrganizationResponseModel interface.
 */
export function instanceOfProfileOrganizationResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isMain" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "phone" in value;

    return isInstance;
}

export function ProfileOrganizationResponseModelFromJSON(json: any): ProfileOrganizationResponseModel {
    return ProfileOrganizationResponseModelFromJSONTyped(json, false);
}

export function ProfileOrganizationResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileOrganizationResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'isMain': json['IsMain'],
        'address': AddressFromJSON(json['Address']),
        'phone': json['Phone'],
    };
}

export function ProfileOrganizationResponseModelToJSON(value?: ProfileOrganizationResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'IsMain': value.isMain,
        'Address': AddressToJSON(value.address),
        'Phone': value.phone,
    };
}

