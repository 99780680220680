type in_millimeters = number;
type in_gramms = number;

export function getBodyMassIndex(height_in_mm: in_millimeters, weight_in_gramms: in_gramms) {
  if (height_in_mm > 0 && weight_in_gramms > 0) {
    const height_in_meter = height_in_mm / 100;

    return weight_in_gramms / Math.pow(height_in_meter, 2);
  }

  return 0;
}
