import { ActionModal, Button } from "libs/ui";
import { useTranslation } from "react-i18next";
import { preventClickBubbling } from "utils/preventClickBubbling";

interface DeleteTestModalProps {
  onSuccess?: () => void;
  onCancel: () => void;
  show: boolean;
  isInLoadingState: boolean;
}

export function DeleteTestModal({
  onSuccess,
  onCancel,
  show,
  isInLoadingState,
}: DeleteTestModalProps) {
  const { t } = useTranslation("modals", { keyPrefix: "DeleteTestModal" });

  return (
    <ActionModal
      show={show}
      title={t("title")}
      icon={{
        iconType: "Delete",
        color: "red",
      }}
      inLoadingState={isInLoadingState}
      cancelButton={
        <Button
          disabled={isInLoadingState}
          buttonSize="medium"
          buttonType="transparent"
          onClick={preventClickBubbling(onCancel)}
        >
          {t("buttonCancel")}
        </Button>
      }
      submitButton={
        <Button
          disabled={isInLoadingState}
          buttonSize="medium"
          buttonType="red"
          onClick={onSuccess}
        >
          {t("buttonDelete")}
        </Button>
      }
    >
      {t("description")}
    </ActionModal>
  );
}
