import classNames from "classnames";
import { DataAttributeKey } from "../models";
import "./FieldValidation.scss";

export interface FieldValidationProps {
  errorText?: string | null;
  successText?: string | null;
  infoText?: string;
  [dataAttribute: DataAttributeKey]: any;
}

export function FieldValidation({
  errorText,
  successText,
  infoText,
  ...props
}: FieldValidationProps) {
  if (!errorText && !successText && !infoText) {
    return null;
  }

  return (
    <div
      className={classNames(
        "UI-Components FieldValidation",
        successText && "FieldValidation--success",
        errorText && "FieldValidation--error",
        infoText && "FieldValidation--info",
      )}
      {...props}
    >
      {successText && <p>{successText}</p>}
      {errorText && <p>{errorText}</p>}
      {infoText && <p>{infoText}</p>}
    </div>
  );
}
