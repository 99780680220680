import { OrganizationDetailsResponseModel } from "api/query";
import { SelectFilterAsyncNoData } from "components/SelectFilterAsyncNoData";
import { SelectFilterAsyncWithCancel } from "components/SelectFilterAsyncWithCancel";
import { CarnaApiQuery } from "config/apiQuery";
import i18n from "i18next";
import { FieldValidationProps, FormFieldBasic, SelectFilterResult } from "libs/ui";
import { createSelectFilterAsyncOption } from "libs/ui/SelectFilterAsync/SelectFilterAsync.model";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface OrganizationFieldProps extends FormFieldBasic, FieldValidationProps {
  initialValue?: string | null;
  // you are not in edit mode, you will see the input without border
  readOnly?: boolean;
  disabled?: boolean;
  onSelect: (value: OrganizationDetailsResponseModel | undefined) => void;
  loading?: boolean;
}

export function OrganizationField({
  initialValue,
  onSelect,
  readOnly,
  disabled,
  loading,
}: OrganizationFieldProps) {
  const { t: tComponents } = useTranslation("translation", { keyPrefix: "components" });
  const { t: tForm } = useTranslation("translation", { keyPrefix: "Form" });

  const fetchOrgs = useMemo(
    () =>
      createSelectFilterAsyncOption(async (filter?: string) => {
        const data = await CarnaApiQuery.Organizations.get({
          filters: {
            contains: filter,
            excludeMain: true,
          },
          limit: 100,
          page: 1,
        });

        return data?.items?.map(org => ({ title: org.name, value: org })) ?? [];
      }),
    [],
  );

  const setRenderSelected = useCallback(
    (props?: OrganizationDetailsResponseModel, optional?: boolean) => (
      <SelectFilterResult
        data-testid="organization-field-result"
        data-testval={JSON.stringify({ organizationName: props?.name, optional })}
        hasValue={!!(props ?? initialValue)}
        label={i18n.format("organization", "optionalField", undefined, { optional })}
      >
        {props?.name ?? initialValue}
      </SelectFilterResult>
    ),
    [initialValue],
  );

  return (
    <SelectFilterAsyncWithCancel
      data-testid="organization-field"
      className="OrganizationField"
      readOnly={readOnly}
      disabled={disabled}
      hasInitialValue={!!initialValue}
      onSelect={onSelect}
      validation={{
        infoText: !readOnly ? tComponents("OrganizationField.infoText") : undefined,
        errorText: tForm("ValidationMessages.organizationsNotAvailable") ?? "",
      }}
      getOptions={fetchOrgs}
      loading={loading}
      renderSelected={setRenderSelected}
      noDataComponent={<SelectFilterAsyncNoData variant="Organizations" scaleTo={0.6} />}
    />
  );
}
