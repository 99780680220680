/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DeviceStatusType = {
    Inactive: 'Inactive',
    Active: 'Active'
} as const;
export type DeviceStatusType = typeof DeviceStatusType[keyof typeof DeviceStatusType];


export function DeviceStatusTypeFromJSON(json: any): DeviceStatusType {
    return DeviceStatusTypeFromJSONTyped(json, false);
}

export function DeviceStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceStatusType {
    return json as DeviceStatusType;
}

export function DeviceStatusTypeToJSON(value?: DeviceStatusType | null): any {
    return value as any;
}

