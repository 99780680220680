import * as React from "react";
import { SVGProps } from "react";
const SvgQrCode = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3a1 1 0 0 1 1-1h3.833a1 1 0 0 1 1 1v3.833a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3Zm3.167 3.167a1 1 0 0 0 1-1v-.5a1 1 0 0 0-1-1h-.5a1 1 0 0 0-1 1v.5a1 1 0 0 0 1 1h.5ZM2 17.166a1 1 0 0 1 1-1h3.833a1 1 0 0 1 1 1V21a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-3.834Zm3.167 3.168a1 1 0 0 0 1-1v-.5a1 1 0 0 0-1-1h-.5a1 1 0 0 0-1 1v.5a1 1 0 0 0 1 1h.5ZM17.167 2a1 1 0 0 0-1 1v3.833a1 1 0 0 0 1 1H21a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-3.833Zm3.166 3.167a1 1 0 0 1-1 1h-.5a1 1 0 0 1-1-1v-.5a1 1 0 0 1 1-1h.5a1 1 0 0 1 1 1v.5Z"
      fill="currentColor"
    />
    <path
      d="M20.333 10.333a.833.833 0 0 1 1.667 0v4.833a1 1 0 0 1-1 1h-4a.833.833 0 0 1 0-1.666h2.333a1 1 0 0 0 1-1v-3.167ZM17.167 17.834a1 1 0 0 0-1 1v2.333a.833.833 0 0 0 1.666 0V20.5a1 1 0 0 1 1-1h.5a1 1 0 0 1 1 1v.667a.833.833 0 0 0 1.667 0v-2.334a1 1 0 0 0-1-1h-3.833ZM9.5 2.833c0-.46.373-.833.833-.833H13.5a1 1 0 0 1 1 1v4a.833.833 0 1 1-1.667 0V4.667a1 1 0 0 0-1-1h-1.5a.833.833 0 0 1-.833-.834Z"
      fill="currentColor"
    />
    <path
      d="M13.833 9.5a1 1 0 0 0-1 1v1.334a1 1 0 0 1-1 1H10.5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1.333a1 1 0 0 1 1 1v2.333a.833.833 0 0 0 1.667 0v-4a1 1 0 0 0-1-1H12a.833.833 0 0 1 0-1.667h1.5a1 1 0 0 0 1-1V12a.833.833 0 1 1 1.667 0 .833.833 0 0 0 1.666 0v-1.5a1 1 0 0 0-1-1h-3ZM11.167 20.333v.834a.833.833 0 1 1-1.667 0v-.834a.833.833 0 1 1 1.667 0ZM7 12.832h-.833a.833.833 0 0 0 0 1.667H7a.833.833 0 1 0 0-1.667Z"
      fill="currentColor"
    />
    <path
      d="M9.5 6.167a.833.833 0 0 1 1.667 0v4a1 1 0 0 1-1 1h-5.5a1 1 0 0 0-1 1v1.5a.833.833 0 1 1-1.667 0V10.5a1 1 0 0 1 1-1h5.5a1 1 0 0 0 1-1V6.167Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgQrCode;
