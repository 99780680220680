import * as React from "react";
import { SVGProps } from "react";
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#settings_svg__a)" fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M12 8.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5ZM9.75 12a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z" />
      <path d="M20.086 15.303 19.4 15l.69.295a.9.9 0 0 1 .822-.545H21a2.75 2.75 0 1 0 0-5.5h-.168a.9.9 0 0 1-.77-.443.747.747 0 0 0-.056-.19.9.9 0 0 1 .178-.99l.056-.057a2.749 2.749 0 0 0 0-3.89 2.749 2.749 0 0 0-3.89 0l-.057.056a.9.9 0 0 1-.99.178l-.008-.003a.9.9 0 0 1-.545-.823V3a2.75 2.75 0 1 0-5.5 0v.168a.9.9 0 0 1-.443.77.75.75 0 0 0-.19.056.9.9 0 0 1-.99-.178L7.57 3.76a2.75 2.75 0 1 0-3.89 3.89l.056.057a.9.9 0 0 1 .178.99.745.745 0 0 0-.018.044.9.9 0 0 1-.816.589H3a2.75 2.75 0 1 0 0 5.5h.168a.9.9 0 0 1 .823.546l.003.007a.9.9 0 0 1-.178.99l-.056.057a2.75 2.75 0 0 0 1.945 4.697 2.75 2.75 0 0 0 1.946-.807l.056-.056a.9.9 0 0 1 .99-.178l.044.018a.9.9 0 0 1 .589.816V21a2.75 2.75 0 1 0 5.5 0v-.168a.9.9 0 0 1 .546-.823l.007-.003a.9.9 0 0 1 .99.178l.056.056a2.749 2.749 0 0 0 3.891 0 2.748 2.748 0 0 0 0-3.89l-.056-.057a.899.899 0 0 1-.178-.99Zm-8.97-13.187A1.25 1.25 0 0 1 13.25 3v.093a2.4 2.4 0 0 0 1.45 2.195 2.4 2.4 0 0 0 2.645-.482l.066-.066a1.25 1.25 0 1 1 1.769 1.77l-.06.06-.006.006a2.4 2.4 0 0 0-.54 2.497.753.753 0 0 0 .057.222 2.4 2.4 0 0 0 2.196 1.455H21a1.25 1.25 0 0 1 0 2.5h-.093a2.4 2.4 0 0 0-2.195 1.45 2.4 2.4 0 0 0 .482 2.645l.066.066a1.25 1.25 0 1 1-1.77 1.769l-.06-.06-.006-.006a2.4 2.4 0 0 0-2.643-.482 2.4 2.4 0 0 0-1.451 2.195V21a1.25 1.25 0 0 1-2.5 0v-.108a2.4 2.4 0 0 0-1.549-2.188 2.4 2.4 0 0 0-2.625.49l-.067.066a1.25 1.25 0 1 1-1.769-1.77l.06-.06.006-.006a2.4 2.4 0 0 0 .482-2.643 2.4 2.4 0 0 0-2.195-1.451H3a1.25 1.25 0 0 1 0-2.5h.108A2.4 2.4 0 0 0 5.296 9.28a2.4 2.4 0 0 0-.49-2.625l-.066-.067A1.25 1.25 0 1 1 6.51 4.82l.06.06.006.006a2.4 2.4 0 0 0 2.497.54.75.75 0 0 0 .222-.057 2.4 2.4 0 0 0 1.455-2.196V3c0-.332.132-.65.366-.884Z" />
    </g>
    <defs>
      <clipPath id="settings_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSettings;
