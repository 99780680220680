import { fetchAuthSession } from "@aws-amplify/auth";

import { fetchUserAttributesWithCache } from "auth.startup";
import { UserRoleType } from "models/PersonModels/Roles";
import { NotNullOrUndefined } from "./NotNullOrUndefined";

function isAdmin(role?: UserRoleType) {
  return role === "Admin";
}

function isPartner(role?: UserRoleType) {
  return role === "Partner";
}

function isHCP(role?: UserRoleType) {
  return role === "Hcp";
}

function isPatient(role?: UserRoleType) {
  return role === "Patient";
}

interface CurrentUserDataReturnType {
  isAdmin: boolean;
  isHcp: boolean;
  isPartner: boolean;
  isPatient: boolean;
  tenantId: string;
  hasOrganizationLevelRights: boolean;
  hasAnyCreationRights: boolean;
  organizationId: string;
  currentUserId: string;
  userRegistryNumber?: string;
  email?: string | undefined;
  phone_number?: string | undefined;
  userRole: UserRoleType;
}

export async function getCurrentUserData(): Promise<CurrentUserDataReturnType> {
  const userAttributes = await fetchUserAttributesWithCache();
  const accessToken = (await fetchAuthSession()).tokens?.accessToken;
  const userRole = NotNullOrUndefined(
    accessToken?.payload["cognito:groups"] as ReadonlyArray<any>,
  )[0] as UserRoleType;
  const organizationId = userAttributes["custom:organizationId"] as string;
  const currentUserId = userAttributes["custom:userId"] as string;
  const tenantId = userAttributes["custom:tenantId"] as string;
  const userRegistryNumber = userAttributes["custom:userRegistryNumber"] as string;

  const hasOrganizationLevelRights = isAdmin(userRole) || isPartner(userRole);

  return {
    isAdmin: isAdmin(userRole),
    isHcp: isHCP(userRole),
    isPartner: isPartner(userRole),
    isPatient: isPatient(userRole),
    tenantId,
    hasOrganizationLevelRights,
    hasAnyCreationRights: hasOrganizationLevelRights || isHCP(userRole),
    organizationId,
    currentUserId,
    userRegistryNumber,
    userRole,
    email: userAttributes.email,
    phone_number: userAttributes.phone_number,
  };
}
