import { SideModalElements } from "libs/ui/SideModal";
import { Group } from "../Group";
import { PatientData, PhysicalSectionProps } from "./model";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { isReadonly } from "components/Forms/helper";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { InputNumber } from "libs/ui/InputNumber";
import { useCallback, useMemo } from "react";
import {
  centimetersToMillimeters,
  gramsToKilograms,
  kilogramsToGrams,
  millimetersToCentimeters,
} from "utils/converters/unitsConverter";
import { MAX_BMI_HEIGHT_IN_CM, MAX_BMI_WEIGHT_IN_KG } from "config/const";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { getNumberSeparators } from "utils/converters/parseLocaleNumber";
import { Bmi } from "./Bmi";

export function Metric<T extends PatientData>({
  patientData,
  editableFields,
  onChange,
  loading,
  showTitle = true,
}: Readonly<PhysicalSectionProps<T>>) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  // TODO put this in parent ?
  const { globalPreference } = useGlobalPreferenceContext();
  const { weightUnit } = useGetUserPreferredMeasurementUnits();
  const { decimalSeparator } = useMemo(
    () => getNumberSeparators(globalPreference?.regionType),
    [globalPreference?.regionType],
  );
  const onHeightChange = useCallback(
    (e: number | null) => {
      // TODO use some custom "type" for errors
      // if (!!e === false || (typeof e === "number" && Number(e) > MAX_BMI_HEIGHT_IN_CM)) {
      //   onChange("error", "height");
      //   return;
      // }

      onChange(typeof e === "number" ? centimetersToMillimeters(e) : "", "height");
    },
    [onChange],
  );

  const onWeightChange = useCallback(
    (e: number | null) => {
      // TODO use some custom "type" for errors
      // if (!!e === false || (typeof e === "number" && Number(e) > MAX_BMI_WEIGHT_IN_KG)) {
      //   onChange("error", "weight");
      //   return;
      // }
      onChange(typeof e === "number" ? kilogramsToGrams(e) : "", "weight");
    },
    [onChange],
  );

  const weightValidator = useCallback(
    //TODO this doesn't work for this pattern 12,,,,,,,,
    (val: string) =>
      (decimalSeparator === "."
        ? val.match(/^\d+(,{1}\d*)*(\.)*\d{0,2}$/)
        : val.match(/^\d+(\.{1}\d*)*(,)*\d{0,2}$/)) !== null,
    [decimalSeparator],
  );

  const heightInvalid =
    !!patientData.height === false ||
    millimetersToCentimeters(patientData.height) > MAX_BMI_HEIGHT_IN_CM;

  const weightInvalid =
    !!patientData.weight === false || gramsToKilograms(patientData.weight) > MAX_BMI_WEIGHT_IN_KG;

  const everyFieldIsEmpty =
    (patientData.height === "" && patientData.weight === "") ||
    (patientData.height === null && patientData.weight === null);

  const hasValidationError = everyFieldIsEmpty ? false : heightInvalid || weightInvalid;

  return (
    <Group data-testid={"Group-PhysicalSection"}>
      {showTitle ? (
        <SideModalElements.SectionTitle>{t("Subtitle.physical")}</SideModalElements.SectionTitle>
      ) : null}
      <div
        data-testerror={hasValidationError?.toString()}
        className={classNames(
          "PhysicalSection",
          globalPreference?.measureUnitType
            ? `PhysicalSection--${globalPreference?.measureUnitType}`
            : undefined,
          {
            "PhysicalSection--error": hasValidationError,
          },
        )}
      >
        <InputNumber
          data-testid={"height-cm"}
          data-error={hasValidationError && heightInvalid}
          onNumberChange={onHeightChange}
          label={t("height")}
          value={
            typeof patientData.height === "number"
              ? millimetersToCentimeters(patientData.height)
              : ""
          }
          validation={{
            errorText:
              hasValidationError && heightInvalid ? t("ValidationMessages.height") : undefined,
          }}
          readOnly={isReadonly("height", editableFields)}
          suffix="cm"
          loading={loading}
          region={globalPreference?.regionType}
          formattingOptions={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
          }}
        />
        <InputNumber
          data-testid={"weight"}
          data-error={hasValidationError && weightInvalid}
          onNumberChange={onWeightChange}
          label={t("weight")}
          validateInput={weightValidator}
          value={typeof patientData.weight === "number" ? gramsToKilograms(patientData.weight) : ""}
          validation={{
            errorText:
              hasValidationError && weightInvalid ? t("ValidationMessages.weight") : undefined,
          }}
          readOnly={isReadonly("weight", editableFields)}
          suffix={weightUnit}
          region={globalPreference?.regionType}
          formattingOptions={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
          }}
          loading={loading}
        />
      </div>
      <Bmi bmiValue={patientData.bmi} />
    </Group>
  );
}
