import { assign, createMachine } from "xstate";

export interface DetailsPageStateContext<ContextType> {
  data?: ContextType;
  error?: any;
}

export type SaveEvent<SaveModel> = {
  type: "SAVE_DATA";
  value: SaveModel;
  saveAvatar?: (rowVersion?: string) => Promise<void>;
};
export type ConfirmStatusEvent = { type: "CONFIRM_STATUS"; value: boolean };

export type DetailsPageStateEvents<SaveModel> =
  | { type: "LOAD_DATA" }
  | { type: "EDIT" }
  | { type: "CANCEL_EDIT" }
  | SaveEvent<SaveModel>
  | ConfirmStatusEvent
  | { type: "SHOW_ACTIVATE_MODAL" }
  | { type: "HIDE_ACTIVATE_MODAL" }
  | { type: "SHOW_RESEND_MODAL" }
  | { type: "HIDE_RESEND_MODAL" }
  | { type: "SHOW_INFO" }
  | { type: "HIDE_INFO" };

export interface DetailsPageStateServiceMap<FetchDataReturnType, SaveStatusReturnType> {
  fetchData: {
    data: FetchDataReturnType | undefined;
  };
  saveStatus: {
    data: SaveStatusReturnType | undefined;
  };
  saveData: any;
}

export function makeDetailsPageStateMachine<
  ContextType,
  SaveModel,
  // ServiceMapT,
>(machineId = "DetailsPage") {
  return createMachine(
    {
      type: "parallel",
      id: machineId,
      context: { data: undefined, error: undefined },
      types: {
        events: {} as DetailsPageStateEvents<SaveModel>,
        context: {} as DetailsPageStateContext<ContextType>,
      },
      states: {
        entity: {
          initial: "none",
          on: {
            LOAD_DATA: {
              target: ".loading",
            },
            SAVE_DATA: [
              {
                target: ".saving",
              },
            ],
          },
          states: {
            loaded: {
              on: {
                EDIT: {
                  target: "loading_for_edit",
                },
              },
            },
            failed: {
              on: {
                EDIT: {
                  target: "loading_for_edit",
                },
              },
            },
            editing: {
              on: {
                CANCEL_EDIT: {
                  target: "loaded",
                },
              },
            },
            saving: {
              invoke: {
                src: "saveData",
                id: "saveData",
                input: ({ context, event }) => ({ context, event }),
                onDone: [
                  {
                    actions: ["loadSavedData", "dataSaved", "_clearContextError", "refreshTable"],
                    target: "loaded",
                  },
                ],
                /**
                 * We should save somewhere in context if endpoint gives back some error
                 */
                onError: [
                  {
                    actions: ["savingFailed", "_setContextSaveError"],
                    target: "editing",
                  },
                ],
              },
            },
            loading_for_edit: {
              entry: ["_clearContextError"],
              invoke: {
                src: "fetchData",
                id: "fetchData",
                onDone: [
                  {
                    actions: "loadEntityData",
                    target: "editing",
                  },
                ],
                onError: [
                  {
                    actions: "failedToLoadData",
                    target: "failed",
                  },
                ],
              },
            },
            loading: {
              invoke: {
                src: "fetchData",
                id: "fetchData",
                onDone: [
                  {
                    actions: "loadEntityData",
                    target: "loaded",
                  },
                ],
                onError: [
                  {
                    actions: "failedToLoadData",
                    target: "failed",
                  },
                ],
              },
            },
            none: {},
          },
        },
        status: {
          initial: "none",
          states: {
            failed: {},
            saving: {
              invoke: {
                src: "saveStatus",
                id: "saveStatus",
                onDone: [
                  {
                    actions: ["updateSaveStatus", "dataSaved", "refreshTable"],
                    target: [`#${machineId}.modal.activate.hide`, "none"],
                  },
                ],
                onError: [
                  {
                    actions: "savingFailed",
                    target: "failed",
                  },
                ],
              },
            },
            none: {},
          },
        },
        modal: {
          initial: "activate",
          on: {
            SHOW_RESEND_MODAL: {
              target: `.resend.show`,
            },
            HIDE_RESEND_MODAL: {
              target: `.resend.hide`,
            },
            SHOW_ACTIVATE_MODAL: {
              target: `.activate.show`,
            },
            HIDE_ACTIVATE_MODAL: {
              target: `.activate.hide`,
            },
          },
          states: {
            activate: {
              initial: "hide",
              states: {
                show: {
                  on: {
                    CONFIRM_STATUS: {
                      target: `#${machineId}.status.saving`,
                    },
                  },
                },
                hide: {},
              },
            },
            resend: {
              initial: "hide",
              states: {
                show: {},
                hide: {},
              },
            },
          },
        },
        info: {
          initial: "hide",
          on: {
            SHOW_INFO: {
              target: ".show",
            },
            HIDE_INFO: {
              target: ".hide",
            },
          },
          states: {
            show: {},
            hide: {},
          },
        },
      },
    },
    {
      actions: {
        _clearContextError: assign({
          error: () => {
            // console.log("_clearContextError", context);
            return undefined;
          },
        }),
        _setContextSaveError: assign({
          error: (context: any) => {
            // console.log("_setContextSaveError", context);
            return context.event;
          },
        }),
      },
    },
  );
}
