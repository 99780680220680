/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AncestryType } from './AncestryType';
import {
    AncestryTypeFromJSON,
    AncestryTypeFromJSONTyped,
    AncestryTypeToJSON,
} from './AncestryType';
import type { GenderType } from './GenderType';
import {
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';

/**
 * 
 * @export
 * @interface PatientUserModel
 */
export interface PatientUserModel {
    /**
     * 
     * @type {string}
     * @memberof PatientUserModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserModel
     */
    organizationId: string;
    /**
     * 
     * @type {UserRoleType}
     * @memberof PatientUserModel
     */
    roleType: UserRoleType;
    /**
     * 
     * @type {string}
     * @memberof PatientUserModel
     */
    registryNumber?: string | null;
    /**
     * 
     * @type {GenderType}
     * @memberof PatientUserModel
     */
    gender: GenderType;
    /**
     * 
     * @type {number}
     * @memberof PatientUserModel
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof PatientUserModel
     */
    bmi?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientUserModel
     */
    weight: number;
    /**
     * 
     * @type {AncestryType}
     * @memberof PatientUserModel
     */
    ancestry?: AncestryType;
    /**
     * 
     * @type {number}
     * @memberof PatientUserModel
     */
    age: number;
}

/**
 * Check if a given object implements the PatientUserModel interface.
 */
export function instanceOfPatientUserModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "roleType" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "age" in value;

    return isInstance;
}

export function PatientUserModelFromJSON(json: any): PatientUserModel {
    return PatientUserModelFromJSONTyped(json, false);
}

export function PatientUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'id': json['Id'],
        'organizationId': json['OrganizationId'],
        'roleType': UserRoleTypeFromJSON(json['RoleType']),
        'registryNumber': !exists(json, 'RegistryNumber') ? undefined : json['RegistryNumber'],
        'gender': GenderTypeFromJSON(json['Gender']),
        'height': json['Height'],
        'bmi': !exists(json, 'Bmi') ? undefined : json['Bmi'],
        'weight': json['Weight'],
        'ancestry': !exists(json, 'Ancestry') ? undefined : AncestryTypeFromJSON(json['Ancestry']),
        'age': json['Age'],
    };
}

export function PatientUserModelToJSON(value?: PatientUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Id': value.id,
        'OrganizationId': value.organizationId,
        'RoleType': UserRoleTypeToJSON(value.roleType),
        'RegistryNumber': value.registryNumber,
        'Gender': GenderTypeToJSON(value.gender),
        'Height': value.height,
        'Bmi': value.bmi,
        'Weight': value.weight,
        'Ancestry': AncestryTypeToJSON(value.ancestry),
        'Age': value.age,
    };
}

