import {
  APIProvider,
  AdvancedMarker,
  Map as GoogleMap,
  MapMouseEvent,
} from "@vis.gl/react-google-maps";
import { SelectFilterAsyncNoData } from "components/SelectFilterAsyncNoData";
import { GOOGLE_MAP_API_KEY, GOOGLE_MAP_PLACES_SEARCH_MAP_ID } from "config/const";
import {
  Button,
  Icon,
  Input,
  Loader,
  SelectFilterAsync,
  SelectFilterResult,
  SelectOption,
  SelectOptionDescription,
  SelectOptionTitle,
} from "libs/ui";
import {
  CustomDropdownProps,
  ResetSelectedOptionProps,
  createSelectFilterAsyncOption,
} from "libs/ui/SelectFilterAsync/SelectFilterAsync.model";
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPlaceDetails, getReverseGeoCoding } from "utils/helpers/google";

import { CustomMapControls, CustomMapControlsRef } from "components/CustomMapControls";
import { MapBaloonMarkerIcon } from "libs/ui/assets/svg/mapBaloonMarkerIcon";
import { isEqual } from "lodash-es";
import { logger } from "logger";
import { PlaceType } from "models/PlaceType";
import { isLoaded } from "models/loadable";
import preventClickBubbling from "utils/preventClickBubbling";
import "./PlaceSuggestionInput.scss";

export interface PlaceSuggestionInputRef {
  clearPlaceSuggestionInput(): void;
}

interface PlaceSuggestionInputProps {
  onSelect: (place: PlaceType | undefined) => void;
  withMapOption?: boolean;
}

export function getAddressDescription(placeSuggestion?: PlaceType) {
  if (!placeSuggestion) {
    return "";
  }

  const street =
    placeSuggestion.streetNumber &&
    `${placeSuggestion.street ?? ""} ${placeSuggestion.streetNumber ?? ""}`.toLowerCase() !==
      placeSuggestion.displayName?.toLowerCase()
      ? `${placeSuggestion.street ?? ""} ${placeSuggestion.streetNumber ?? ""}, `
      : "";

  return street
    .concat(placeSuggestion.city ? `${placeSuggestion.city}, ` : "")
    .concat(placeSuggestion.state ? `${placeSuggestion.state}, ` : "")
    .concat(placeSuggestion.countryCode ?? "");
}

export const PlaceSuggestionInput = forwardRef<PlaceSuggestionInputRef, PlaceSuggestionInputProps>(
  ({ onSelect, withMapOption = false }, ref) => {
    const [currentLocation, setCurrentLocation] = useState<{ lat: number; lng: number } | null>(
      null,
    );
    const [showMap, setShowMap] = useState(false);

    const { t } = useTranslation("translation", {
      keyPrefix: "ui-components.PlaceSuggestionInput",
    });
    const { t: tSelectFilterAsync } = useTranslation("translation", {
      keyPrefix: "ui-components.SelectFilterAsync",
    });

    const selectFilterAsyncRef = useRef<ResetSelectedOptionProps>(null);
    const controlRef = useRef<CustomMapControlsRef>(null);

    const fetchPlaceSuggestions = useMemo(
      () =>
        createSelectFilterAsyncOption(async (filter?: string) => {
          if (!filter) {
            return [];
          }

          const results = await getPlaceDetails(filter);

          return results.map(result => {
            return {
              title: result.placeId,
              value: result,
            } as const;
          });
        }),
      [],
    );

    const renderSelectedPlaceSuggestion = useCallback(
      (props?: PlaceType) => {
        const hasValue = !!props;

        return (
          <SelectFilterResult
            data-testval={JSON.stringify(props)}
            hasValue={hasValue}
            label={t("placeholder")}
            leadingIcon="Search"
          >
            {!hasValue ? null : <div className="PlaceSuggestion__option">{props.displayName}</div>}
          </SelectFilterResult>
        );
      },
      [t],
    );

    const renderOption = useCallback(({ value: placeSuggestion }: { value?: PlaceType }) => {
      if (!placeSuggestion) {
        return null;
      }

      return (
        <div className="PlaceSuggestionInput__option">
          <Icon icon={"MapPin"} />
          <SelectOptionTitle>{placeSuggestion.displayName}</SelectOptionTitle>
          <SelectOptionDescription>
            {getAddressDescription(placeSuggestion)}
          </SelectOptionDescription>
        </div>
      );
    }, []);

    const onPlaceItemSelect = useCallback(
      (props?: PlaceType) => {
        if (!props) {
          onSelect(undefined);
          return;
        }

        if (props.coordinates?.lat() && props.coordinates?.lng()) {
          controlRef.current?.map?.setCenter({
            lat: props.coordinates?.lat(),
            lng: props.coordinates?.lng(),
          });
          controlRef.current?.map?.setZoom(14);
          setCurrentLocation({ lat: props.coordinates?.lat(), lng: props.coordinates?.lng() });
        }
        onSelect(props);
      },
      [onSelect],
    );

    const onMapClick = useCallback(
      async (event: MapMouseEvent) => {
        setCurrentLocation(event.detail.latLng);
        let results: Awaited<ReturnType<typeof getPlaceDetails>> = [];
        if (event.detail.latLng?.lat && event.detail.latLng?.lng) {
          try {
            results = await getReverseGeoCoding(event.detail.latLng?.lat, event.detail.latLng?.lng);
          } catch (error) {
            logger.error(error);
          }

          if (results?.length > 0) {
            onSelect(results[0]);
          }
        }
      },
      [onSelect],
    );

    const handleShowMap = useCallback(() => {
      if (showMap === false) {
        setShowMap(true);
      }
    }, [showMap]);

    const closeMap = useCallback(() => {
      setShowMap(false);
    }, []);

    const customDropdownContent = useCallback<
      (props: CustomDropdownProps<PlaceType>) => JSX.Element
    >(
      ({
        inputRef,
        onFilterChange,
        options,
        selectedOption,
        selectRef,
        showDropDownLoading,
        setShowOptionsDropdown,
        onOptionSelect,
        clearSelectedOptionWithParent,
      }) => {
        const handleShowMapWithHide = () => {
          clearSelectedOptionWithParent();
          handleShowMap();
          setTimeout(() => {
            setShowOptionsDropdown(false);
            selectRef?.current?.blur();
          }, 1);
        };

        return (
          <>
            <Input
              data-testid="PlaceSuggestionInput-search"
              className="SelectFilterAsync__search"
              inputIcon={{ icon: "Search", iconPosition: "leading" }}
              inputRef={inputRef}
              placeholder={tSelectFilterAsync("search")}
              onChange={onFilterChange}
            />
            {showDropDownLoading ? (
              <Loader loading />
            ) : (
              isLoaded(options) && (
                <div
                  data-testid="PlaceSuggestionInput-options"
                  className="SelectFilterAsync__options PlaceSuggestionInput__options"
                >
                  {options.value.slice(0, 3).map(({ title, value }, index) => (
                    <SelectOption<PlaceType>
                      render={renderOption}
                      key={`${value.placeId}`}
                      isActive={isEqual(selectedOption, value)}
                      onSelect={onOptionSelect}
                      title={getAddressDescription(value)}
                      value={value}
                    />
                  ))}
                  {options.value.length === 0 ? (
                    <SelectFilterAsyncNoData
                      handleShowMap={withMapOption ? handleShowMapWithHide : undefined}
                      variant="PlaceSuggestionInput"
                    />
                  ) : (
                    <>
                      {withMapOption === true ? (
                        <p
                          className="SelectFilterAsyncNoData__showMap PlaceSuggestionInput__showMap"
                          onClick={handleShowMapWithHide}
                        >
                          {tSelectFilterAsync(`noData.PlaceSuggestionInput.select-on-the-map`)}
                        </p>
                      ) : null}
                    </>
                  )}
                </div>
              )
            )}
          </>
        );
      },
      [handleShowMap, renderOption, tSelectFilterAsync, withMapOption],
    );

    const clearPlaceSuggestionInput = useCallback(() => {
      onPlaceItemSelect();
      setCurrentLocation(null);
      selectFilterAsyncRef.current?.clearSelectedOption();
    }, [onPlaceItemSelect]);

    useImperativeHandle(
      ref,
      () => {
        return {
          clearPlaceSuggestionInput,
        };
      },
      [clearPlaceSuggestionInput],
    );

    return (
      <>
        <SelectFilterAsync
          ref={selectFilterAsyncRef}
          data-testid="place-suggestion-input"
          className="PlaceSuggestionInput"
          onSelect={onPlaceItemSelect}
          getOptions={fetchPlaceSuggestions}
          renderSelected={renderSelectedPlaceSuggestion}
          customDropdownContent={customDropdownContent}
          validation={{ infoText: t("description") }}
        />
        {withMapOption ? (
          <>
            <div className="PlaceSuggestionInput__map" style={{ display: showMap ? "" : "none" }}>
              <Button
                className="PlaceSuggestionInput__close-button"
                buttonType={"white"}
                buttonSize={"small"}
                buttonIcon={{ icon: "Close" }}
                onClick={preventClickBubbling(closeMap)}
              />
              <APIProvider apiKey={GOOGLE_MAP_API_KEY}>
                <GoogleMap
                  clickableIcons={false}
                  disableDefaultUI={true}
                  zoomControl={false}
                  mapTypeControl={false}
                  fullscreenControl={false}
                  streetViewControl={false}
                  mapId={GOOGLE_MAP_PLACES_SEARCH_MAP_ID}
                  defaultZoom={11}
                  defaultCenter={{ lat: 32.299507, lng: -64.790337 }}
                  onClick={onMapClick}
                />
                {currentLocation && (
                  <AdvancedMarker position={currentLocation}>
                    <MapBaloonMarkerIcon className="PlaceSuggestionInput__map-baloon-marker-icon" />
                  </AdvancedMarker>
                )}
                <CustomMapControls
                  ref={controlRef}
                  setCurrentLocation={(lat, lng) => setCurrentLocation({ lat, lng })}
                />
              </APIProvider>
            </div>
            <p
              style={{ display: showMap ? "" : "none" }}
              className="PlaceSuggestionInput__map-description"
            >
              {t("map-description")}
            </p>
          </>
        ) : null}
      </>
    );
  },
);
