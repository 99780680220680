import { useGraphDataAPIContext } from "pages/PatientsPage/Details/Graph/GraphDataAPIContext";
import { useMemo } from "react";
import { getFormattedCreatininePerMeasurementUnit } from "utils/converters/getFormattedCreatininePerMeasurementUnit";
import { useGetSerumCreatinineRange } from "utils/hooks/useGetSerumCreatinineRanges";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";

export function useGetSerumCreatinineNormalRange(): [number, number] {
  const { data } = useGraphDataAPIContext();
  const { serumCreatinineUnit } = useGetUserPreferredMeasurementUnits();
  const [getRangePoints] = useGetSerumCreatinineRange();

  return useMemo(() => {
    const [from, to] = getRangePoints(data.SerumCreatinine.value?.genderType);

    return [
      getFormattedCreatininePerMeasurementUnit(from ?? 0, serumCreatinineUnit),
      getFormattedCreatininePerMeasurementUnit(to ?? 0, serumCreatinineUnit),
    ];
  }, [data.SerumCreatinine.value?.genderType, getRangePoints, serumCreatinineUnit]);
}
