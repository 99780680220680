import { TableElements } from "libs/ui";
import { TheadSlotProps } from "../model";
import "./TheadSlot.scss";

export function TheadSlot({
  columns,
  resolveShowFilterOption,
  onOpenFilterModal,
  resolveFilterApplied,
}: Readonly<TheadSlotProps>) {
  return (
    <TableElements.Row>
      {columns.map(column => (
        <TableElements.HeadCell
          key={column.name}
          className={`table-column-${column.name}`}
          {...(resolveShowFilterOption?.(column.name) &&
            onOpenFilterModal && {
              onClick: () => onOpenFilterModal(column.name),
            })}
          hasFilter={resolveShowFilterOption?.(column.name)}
          filterApplied={resolveFilterApplied?.(column.name)}
        >
          {column.label}
        </TableElements.HeadCell>
      ))}
    </TableElements.Row>
  );
}
