/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ViewOptionsModel
 */
export interface ViewOptionsModel {
    /**
     * 
     * @type {boolean}
     * @memberof ViewOptionsModel
     */
    enableListView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ViewOptionsModel
     */
    enableGraphView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ViewOptionsModel
     */
    enableCalendarView?: boolean;
}

/**
 * Check if a given object implements the ViewOptionsModel interface.
 */
export function instanceOfViewOptionsModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ViewOptionsModelFromJSON(json: any): ViewOptionsModel {
    return ViewOptionsModelFromJSONTyped(json, false);
}

export function ViewOptionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewOptionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enableListView': !exists(json, 'EnableListView') ? undefined : json['EnableListView'],
        'enableGraphView': !exists(json, 'EnableGraphView') ? undefined : json['EnableGraphView'],
        'enableCalendarView': !exists(json, 'EnableCalendarView') ? undefined : json['EnableCalendarView'],
    };
}

export function ViewOptionsModelToJSON(value?: ViewOptionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EnableListView': value.enableListView,
        'EnableGraphView': value.enableGraphView,
        'EnableCalendarView': value.enableCalendarView,
    };
}

