import classNames from "classnames";
import { DraggableWidget, DraggableWidgetProps } from "components/DnD/DraggableWidget";
import { getWidgetSize } from "components/DnD/util";
import { WidgetHeader } from "components/Widget/WidgetHeader";
import { Icon } from "libs/ui/Icon";
import { forwardRef, PropsWithChildren } from "react";
import { MeasurementWidgetType } from "utils/createGlobalConfigStore";
import { getIcon } from "components/Widget/utils";
import "./StatsMeasurementWidget.scss";

interface StatsMeasurementWidgetProps extends DraggableWidgetProps {
  type: MeasurementWidgetType;
  value: string;
  w?: number;
  h?: number;
}

export const StatsMeasurementWidget = forwardRef<
  HTMLDivElement,
  PropsWithChildren<StatsMeasurementWidgetProps>
>(
  (
    {
      Header,
      optionsDropDown,
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      type,
      children, //!!! children is needed so that resize handle is passed to the component
      value,
      w,
      h,
      ...rest
    },
    ref,
  ) => {
    return (
      <DraggableWidget
        ref={ref}
        optionsDropDown={optionsDropDown}
        style={style}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        data-type={type}
        data-testid={`DraggableWidget-${type}`}
        Header={<WidgetHeader type={type} withIcon={false} />}
        className={classNames("StatsMeasurementWidget", getWidgetSize(w, h))}
        {...rest}
      >
        <div className="StatsMeasurementWidget__content">
          <span className="StatsMeasurementWidget__value">{value}</span>
          <Icon className="StatsMeasurementWidget__icon" scaleTo={48} icon={getIcon(type)} />
        </div>

        {children}
      </DraggableWidget>
    );
  },
);
