import { SVGProps } from "react";

const UploadImageSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="62"
    height="82"
    viewBox="0 0 62 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7139 60.7716L2.39183 68.5838C1.55137 69.0421 0.796054 69.6054 0.0932617 70.2194C8.44344 77.26 19.2226 81.5076 30.9998 81.5076C42.6901 81.5076 53.4004 77.3234 61.727 70.3788C60.959 69.7304 60.1258 69.1471 59.202 68.687L43.8656 61.0197C41.884 60.0289 40.6324 58.0039 40.6324 55.7886V49.7714C41.0635 49.2806 41.5562 48.6502 42.0833 47.9058C44.1735 44.9533 45.7548 41.7056 46.8507 38.2985C48.8177 37.6917 49.4999 35.875 49.4999 33.7159L50.2668 27.293C50.2668 25.8801 49.6383 24.6176 48.662 23.7337V14.4489C48.662 14.4489 50.5693 0 31.0016 0C11.4339 0 13.3412 14.4489 13.3412 14.4489V23.7337C12.3631 24.6176 11.7364 25.8801 11.7364 27.293L12.4999 35.0076C12.4999 36.6993 13.6686 37.4382 14.9999 38.2985C16.6047 45.2848 19.7642 49.7714 19.7642 49.7714V55.6401C19.7624 57.7775 18.5923 59.7464 16.7139 60.7716Z"
      fill="#E5E7EB"
    />
  </svg>
);

export default UploadImageSVG;
