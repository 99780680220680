import { Button } from "libs/ui";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "./GlobalPreferences.scss";
import { GlobalPreferenceForm } from "components/GlobalPreferenceForm";
import { getCurrentGlobalPreferences } from "utils/getCurrentGlobalPreferences";
import { GlobalPreference } from "models/globalPreference";
import {
  DEFAULT_GLOBAL_PREFERENCE,
  useGlobalPreferenceContext,
} from "context/GlobalPreferenceContext";
import { cloneDeep, isEqual, omit } from "lodash-es";
import { SettingsPage } from "components/PageTemplate/SettingsPage";
import { UserGlobalPreferencesResponseModel } from "api/query";
import { ROW_VERSION_DEFAULT } from "config/const";

function fieldsReady(changedPreferences: UserGlobalPreferencesResponseModel) {
  return Object.values(changedPreferences).every(el => el !== undefined && !!el.toString().length);
}

export function GlobalPreferences() {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.GlobalPreferences",
  });
  const { globalPreference, postGlobalPreference, postLoading } = useGlobalPreferenceContext();
  const [changedPreferences, setChangedPreferences] = useState<UserGlobalPreferencesResponseModel>(
    () => cloneDeep(globalPreference ?? DEFAULT_GLOBAL_PREFERENCE),
  );

  const onSelect = (value: any, inputKey: keyof GlobalPreference) =>
    setChangedPreferences(prevValue => ({
      ...prevValue,
      [inputKey]: value,
    }));

  const formData = useMemo<GlobalPreference>(
    () => ({
      ...getCurrentGlobalPreferences(),
      ...changedPreferences,
    }),
    [changedPreferences],
  );

  const saveButtonDisabled =
    isEqual(omit(changedPreferences, ["rowVersion"]), omit(globalPreference, ["rowVersion"])) ||
    !fieldsReady(changedPreferences) ||
    postLoading;

  const onCancel = useCallback(
    () => setChangedPreferences(cloneDeep(globalPreference ?? DEFAULT_GLOBAL_PREFERENCE)),
    [globalPreference],
  );

  const onSave = useCallback(() => {
    postGlobalPreference({
      languageType: changedPreferences.languageType ?? getCurrentGlobalPreferences().languageType,
      regionType: changedPreferences.regionType ?? getCurrentGlobalPreferences().regionType,
      timeZoneType: changedPreferences.timeZoneType ?? getCurrentGlobalPreferences().timeZoneType,
      measureUnitType:
        changedPreferences.measureUnitType ?? getCurrentGlobalPreferences().measureUnitType,
      rowVersion: globalPreference?.rowVersion ?? ROW_VERSION_DEFAULT,
    });
  }, [
    changedPreferences.languageType,
    changedPreferences.measureUnitType,
    changedPreferences.regionType,
    changedPreferences.timeZoneType,
    globalPreference?.rowVersion,
    postGlobalPreference,
  ]);

  return (
    <SettingsPage>
      <div className="GlobalPreferences">
        <div className="GlobalPreferences__body">
          <GlobalPreferenceForm onSelect={onSelect} formData={formData} />
        </div>

        <div className="GlobalPreferences__action">
          <Button
            data-testid="cancelButton"
            buttonSize="medium"
            buttonType="grey"
            onClick={onCancel}
            disabled={postLoading}
          >
            {t("buttonCancel")}
          </Button>
          <Button
            data-testid="saveButton"
            onClick={onSave}
            buttonSize="medium"
            buttonType="primary"
            disabled={saveButtonDisabled}
          >
            {t("buttonSave")}
          </Button>
        </div>
      </div>
    </SettingsPage>
  );
}
