/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EnumItemModel } from './EnumItemModel';
import {
    EnumItemModelFromJSON,
    EnumItemModelFromJSONTyped,
    EnumItemModelToJSON,
} from './EnumItemModel';
import type { ReportModel } from './ReportModel';
import {
    ReportModelFromJSON,
    ReportModelFromJSONTyped,
    ReportModelToJSON,
} from './ReportModel';
import type { WidgetModel } from './WidgetModel';
import {
    WidgetModelFromJSON,
    WidgetModelFromJSONTyped,
    WidgetModelToJSON,
} from './WidgetModel';

/**
 * 
 * @export
 * @interface CustomDefinitionsModel
 */
export interface CustomDefinitionsModel {
    /**
     * 
     * @type {{ [key: string]: Array<EnumItemModel>; }}
     * @memberof CustomDefinitionsModel
     */
    enums: { [key: string]: Array<EnumItemModel>; };
    /**
     * 
     * @type {Array<WidgetModel>}
     * @memberof CustomDefinitionsModel
     */
    widgets: Array<WidgetModel>;
    /**
     * 
     * @type {Array<ReportModel>}
     * @memberof CustomDefinitionsModel
     */
    reports: Array<ReportModel>;
}

/**
 * Check if a given object implements the CustomDefinitionsModel interface.
 */
export function instanceOfCustomDefinitionsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enums" in value;
    isInstance = isInstance && "widgets" in value;
    isInstance = isInstance && "reports" in value;

    return isInstance;
}

export function CustomDefinitionsModelFromJSON(json: any): CustomDefinitionsModel {
    return CustomDefinitionsModelFromJSONTyped(json, false);
}

export function CustomDefinitionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomDefinitionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enums': json['Enums'],
        'widgets': ((json['Widgets'] as Array<any>).map(WidgetModelFromJSON)),
        'reports': ((json['Reports'] as Array<any>).map(ReportModelFromJSON)),
    };
}

export function CustomDefinitionsModelToJSON(value?: CustomDefinitionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Enums': value.enums,
        'Widgets': ((value.widgets as Array<any>).map(WidgetModelToJSON)),
        'Reports': ((value.reports as Array<any>).map(ReportModelToJSON)),
    };
}

