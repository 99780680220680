/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UrineCreatinineStatusType = {
    None: 'None',
    Low: 'Low',
    Normal: 'Normal',
    High: 'High'
} as const;
export type UrineCreatinineStatusType = typeof UrineCreatinineStatusType[keyof typeof UrineCreatinineStatusType];


export function UrineCreatinineStatusTypeFromJSON(json: any): UrineCreatinineStatusType {
    return UrineCreatinineStatusTypeFromJSONTyped(json, false);
}

export function UrineCreatinineStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrineCreatinineStatusType {
    return json as UrineCreatinineStatusType;
}

export function UrineCreatinineStatusTypeToJSON(value?: UrineCreatinineStatusType | null): any {
    return value as any;
}

