import { PropsWithChildren } from "react";
import "./PatientInfoSection.scss";
import classNames from "classnames";

type Variant = "grey" | "transparent";

interface Props {
  variant?: Variant;
}

export function PatientInfoSection({
  variant = "transparent",
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className={classNames("PatientInfoSection", `PatientInfoSection--${variant}`)}>
      {children}
    </div>
  );
}
