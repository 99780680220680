import classNames from "classnames";
import "./BackgroundGrid.scss";

const LINES_COUNT = 5;

export function BackgroundGrid() {
  return (
    <div className="BackgroundGrid">
      <div className="Empty" />
      <div className="Grid">
        {Array.from({ length: LINES_COUNT }).map((_, index) => (
          <div
            key={index}
            className={classNames("Line", {
              "Line--zero": index === 0,
            })}
          />
        ))}
      </div>
    </div>
  );
}
