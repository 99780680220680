/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddHcpRequestModel,
  CommonProblemDetailsModel,
  ResendInviteUserRequestModel,
  UpdateHcpRequestModel,
  UpdateUserStatusRequestModel,
} from '../models/index';
import {
    AddHcpRequestModelFromJSON,
    AddHcpRequestModelToJSON,
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    ResendInviteUserRequestModelFromJSON,
    ResendInviteUserRequestModelToJSON,
    UpdateHcpRequestModelFromJSON,
    UpdateHcpRequestModelToJSON,
    UpdateUserStatusRequestModelFromJSON,
    UpdateUserStatusRequestModelToJSON,
} from '../models/index';

export interface OrganizationsOrganizationIdHcpsPostRequest {
    organizationId: string;
    acceptVersion?: string;
    addHcpRequestModel?: AddHcpRequestModel;
}

export interface OrganizationsOrganizationIdHcpsUserEntityIdDeleteRequest {
    organizationId: string;
    userEntityId: string;
    rowVersion?: string;
    acceptVersion?: string;
}

export interface OrganizationsOrganizationIdHcpsUserEntityIdPatchRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    updateUserStatusRequestModel?: UpdateUserStatusRequestModel;
}

export interface OrganizationsOrganizationIdHcpsUserEntityIdPutRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    updateHcpRequestModel?: UpdateHcpRequestModel;
}

export interface OrganizationsOrganizationIdHcpsUserEntityIdResendInvitePostRequest {
    organizationId: string;
    userEntityId: string;
    acceptVersion?: string;
    resendInviteUserRequestModel?: ResendInviteUserRequestModel;
}

/**
 * HcpsApi - interface
 * 
 * @export
 * @interface HcpsApiInterface
 */
export interface HcpsApiInterface {
    /**
     * 
     * @param {string} organizationId 
     * @param {string} [acceptVersion] 
     * @param {AddHcpRequestModel} [addHcpRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpsApiInterface
     */
    organizationsOrganizationIdHcpsPostRaw(requestParameters: OrganizationsOrganizationIdHcpsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdHcpsPost(requestParameters: OrganizationsOrganizationIdHcpsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [rowVersion] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpsApiInterface
     */
    organizationsOrganizationIdHcpsUserEntityIdDeleteRaw(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdHcpsUserEntityIdDelete(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {UpdateUserStatusRequestModel} [updateUserStatusRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpsApiInterface
     */
    organizationsOrganizationIdHcpsUserEntityIdPatchRaw(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdHcpsUserEntityIdPatch(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {UpdateHcpRequestModel} [updateHcpRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpsApiInterface
     */
    organizationsOrganizationIdHcpsUserEntityIdPutRaw(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdHcpsUserEntityIdPut(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} userEntityId 
     * @param {string} [acceptVersion] 
     * @param {ResendInviteUserRequestModel} [resendInviteUserRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpsApiInterface
     */
    organizationsOrganizationIdHcpsUserEntityIdResendInvitePostRaw(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    organizationsOrganizationIdHcpsUserEntityIdResendInvitePost(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class HcpsApi extends runtime.BaseAPI implements HcpsApiInterface {

     /**
     */
    async organizationsOrganizationIdHcpsPostRawOriginal(requestParameters: OrganizationsOrganizationIdHcpsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/hcps`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddHcpRequestModelToJSON(requestParameters.addHcpRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdHcpsPostRaw(requestParameters: OrganizationsOrganizationIdHcpsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddHcpRequestModelToJSON(requestParameters.addHcpRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdHcpsPost(requestParameters: OrganizationsOrganizationIdHcpsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdHcpsPostRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdDeleteRawOriginal(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdDelete.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowVersion !== undefined) {
            queryParameters['rowVersion'] = requestParameters.rowVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/hcps/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdDeleteRaw(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdDelete.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowVersion !== undefined) {
            queryParameters['rowVersion'] = requestParameters.rowVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdDelete(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdHcpsUserEntityIdDeleteRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdPatchRawOriginal(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPatch.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/hcps/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserStatusRequestModelToJSON(requestParameters.updateUserStatusRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdPatchRaw(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPatch.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserStatusRequestModelToJSON(requestParameters.updateUserStatusRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdPatch(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdHcpsUserEntityIdPatchRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdPutRawOriginal(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPut.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/hcps/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHcpRequestModelToJSON(requestParameters.updateHcpRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdPutRaw(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPut.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{userEntityId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHcpRequestModelToJSON(requestParameters.updateHcpRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdPut(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdHcpsUserEntityIdPutRaw(requestParameters, initOverrides);
    }

     /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdResendInvitePostRawOriginal(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdResendInvitePost.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdResendInvitePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/organizations/{organizationId}/hcps/{userEntityId}/resend-invite`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendInviteUserRequestModelToJSON(requestParameters.resendInviteUserRequestModel),
        }, initOverrides);
    }

    /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdResendInvitePostRaw(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdResendInvitePost.');
        }

        if (requestParameters.userEntityId === null || requestParameters.userEntityId === undefined) {
            throw new runtime.RequiredError('userEntityId','Required parameter requestParameters.userEntityId was null or undefined when calling organizationsOrganizationIdHcpsUserEntityIdResendInvitePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{userEntityId}/resend-invite`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"userEntityId"}}`, encodeURIComponent(String(requestParameters.userEntityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendInviteUserRequestModelToJSON(requestParameters.resendInviteUserRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationsOrganizationIdHcpsUserEntityIdResendInvitePost(requestParameters: OrganizationsOrganizationIdHcpsUserEntityIdResendInvitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdHcpsUserEntityIdResendInvitePostRaw(requestParameters, initOverrides);
    }

}
