import { CarnaMap } from "components/CarnaMap";
import { useRef } from "react";
import "./DashboardMap.scss";

export function DashboardMap() {
  const mapContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="DashboardMap">
      <div className="DashboardMap__container" ref={mapContainerRef}>
        <CarnaMap containerRef={mapContainerRef} />
      </div>
    </div>
  );
}
