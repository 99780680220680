import * as React from "react";
import { SVGProps } from "react";
const SvgStarFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.897 1.557a1 1 0 0 0-1.794 0l-2.857 5.79-6.39.934a1 1 0 0 0-.554 1.705l4.624 4.503-1.092 6.362a1 1 0 0 0 1.451 1.054L12 18.9l5.715 3.005a1 1 0 0 0 1.45-1.054l-1.09-6.362 4.623-4.503a1 1 0 0 0-.553-1.705l-6.39-.935-2.858-5.789Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgStarFilled;
