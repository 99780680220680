import "./Toolbar.scss";

import classNames from "classnames";
import { v4 } from "uuid";

import { ToolbarButton, ToolbarButtonProps } from "./ToolbarButton";

interface ToolbarProps {
  buttons: ToolbarButtonProps[];
  className?: string;
}

export function Toolbar({ buttons, className, ...rest }: ToolbarProps) {
  return (
    <div {...rest} className={classNames("UI-Components Toolbar", className)}>
      <ul className="Toolbar__list">
        {buttons.map(button => (
          <li key={v4()} className="Toolbar__item">
            <ToolbarButton {...button} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export * from "./ToolbarButton";
