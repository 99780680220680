import classNames from "classnames";
import { Icon } from "libs/ui";
import { NavLink } from "react-router-dom";
import { NavRoute } from "router";
import "./NavigationRoute.scss";

interface NavigationRouteProps {
  route: NavRoute;
  handleRouteChange?: () => void;
  sidebarCollapsed?: boolean;
}

export function NavigationRoute({
  route,
  handleRouteChange,
  sidebarCollapsed,
}: NavigationRouteProps) {
  return (
    <li
      className="NavigationRoute"
      key={route.to}
      data-testid="NavigationRoute"
      data-route={route.to}
    >
      {route.disabled ? (
        <div
          className={classNames("NavigationRoute__link", {
            "NavigationRoute__link--disabled": route.disabled,
          })}
        >
          <Icon icon={route.icon} />
          <span
            className={classNames(
              "NavigationRoute__link-text",
              sidebarCollapsed && "NavigationRoute__link-text--collapsed",
            )}
          >
            {route.name}
          </span>
        </div>
      ) : (
        <NavLink
          to={route.to}
          className={({ isActive }) =>
            classNames("NavigationRoute__link", {
              "NavigationRoute__link--active": isActive,
            })
          }
          onClick={handleRouteChange}
          {...(sidebarCollapsed && { title: route.name })}
        >
          <Icon icon={route.icon} />
          <span
            className={classNames(
              "NavigationRoute__link-text",
              sidebarCollapsed && "NavigationRoute__link-text--collapsed",
            )}
          >
            {route.name}
          </span>
        </NavLink>
      )}
    </li>
  );
}
