/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddConfigurationCommandModel,
  CommandResultModel,
  CommonProblemDetailsModel,
  OldConfigurationModel,
  OldConfigurationWithoutWebModel,
  OldEntitiesSectionModel,
  OldInfrastructureSectionModel,
  OldLaboratorySectionModel,
  OldMobileSectionModel,
  OldUsersSectionModel,
  OldWebSectionModel,
  UpdateConfigurationCommandModel,
  UpdateEntitiesSectionCommandModel,
  UpdateInfrastructureSectionCommandModel,
  UpdateLaboratorySectionCommandModel,
  UpdateMobileSectionCommandModel,
  UpdateUsersSectionCommandModel,
  UpdateWebSectionCommandModel,
} from '../models/index';
import {
    AddConfigurationCommandModelFromJSON,
    AddConfigurationCommandModelToJSON,
    CommandResultModelFromJSON,
    CommandResultModelToJSON,
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    OldConfigurationModelFromJSON,
    OldConfigurationModelToJSON,
    OldConfigurationWithoutWebModelFromJSON,
    OldConfigurationWithoutWebModelToJSON,
    OldEntitiesSectionModelFromJSON,
    OldEntitiesSectionModelToJSON,
    OldInfrastructureSectionModelFromJSON,
    OldInfrastructureSectionModelToJSON,
    OldLaboratorySectionModelFromJSON,
    OldLaboratorySectionModelToJSON,
    OldMobileSectionModelFromJSON,
    OldMobileSectionModelToJSON,
    OldUsersSectionModelFromJSON,
    OldUsersSectionModelToJSON,
    OldWebSectionModelFromJSON,
    OldWebSectionModelToJSON,
    UpdateConfigurationCommandModelFromJSON,
    UpdateConfigurationCommandModelToJSON,
    UpdateEntitiesSectionCommandModelFromJSON,
    UpdateEntitiesSectionCommandModelToJSON,
    UpdateInfrastructureSectionCommandModelFromJSON,
    UpdateInfrastructureSectionCommandModelToJSON,
    UpdateLaboratorySectionCommandModelFromJSON,
    UpdateLaboratorySectionCommandModelToJSON,
    UpdateMobileSectionCommandModelFromJSON,
    UpdateMobileSectionCommandModelToJSON,
    UpdateUsersSectionCommandModelFromJSON,
    UpdateUsersSectionCommandModelToJSON,
    UpdateWebSectionCommandModelFromJSON,
    UpdateWebSectionCommandModelToJSON,
} from '../models/index';

export interface TenantsNameGetRequest {
    name: string;
    accept_version?: string;
}

export interface TenantsNamePutRequest {
    name: string;
    accept_version?: string;
    UpdateConfigurationCommandModel?: UpdateConfigurationCommandModel;
}

export interface TenantsNameSectionsEntitiesGetRequest {
    name: string;
    accept_version?: string;
}

export interface TenantsNameSectionsEntitiesPutRequest {
    name: string;
    accept_version?: string;
    UpdateEntitiesSectionCommandModel?: UpdateEntitiesSectionCommandModel;
}

export interface TenantsNameSectionsInfrastructureGetRequest {
    name: string;
    accept_version?: string;
}

export interface TenantsNameSectionsInfrastructurePutRequest {
    name: string;
    accept_version?: string;
    UpdateInfrastructureSectionCommandModel?: UpdateInfrastructureSectionCommandModel;
}

export interface TenantsNameSectionsInternalGetRequest {
    name: string;
    accept_version?: string;
}

export interface TenantsNameSectionsLaboratoryGetRequest {
    name: string;
    accept_version?: string;
}

export interface TenantsNameSectionsLaboratoryPutRequest {
    name: string;
    accept_version?: string;
    UpdateLaboratorySectionCommandModel?: UpdateLaboratorySectionCommandModel;
}

export interface TenantsNameSectionsMobileGetRequest {
    name: string;
    accept_version?: string;
}

export interface TenantsNameSectionsMobilePutRequest {
    name: string;
    accept_version?: string;
    UpdateMobileSectionCommandModel?: UpdateMobileSectionCommandModel;
}

export interface TenantsNameSectionsUsersGetRequest {
    name: string;
    accept_version?: string;
}

export interface TenantsNameSectionsUsersPutRequest {
    name: string;
    accept_version?: string;
    UpdateUsersSectionCommandModel?: UpdateUsersSectionCommandModel;
}

export interface TenantsNameSectionsWebGetRequest {
    name: string;
    accept_version?: string;
}

export interface TenantsNameSectionsWebPutRequest {
    name: string;
    accept_version?: string;
    UpdateWebSectionCommandModel?: UpdateWebSectionCommandModel;
}

export interface TenantsPostRequest {
    accept_version?: string;
    AddConfigurationCommandModel?: AddConfigurationCommandModel;
}

/**
 * TenantsApi - interface
 * 
 * @export
 * @interface TenantsApiInterface
 */
export interface TenantsApiInterface {
    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameGetRaw(requestParameters: TenantsNameGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldConfigurationModel>>;

    /**
     */
    tenantsNameGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldConfigurationModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {UpdateConfigurationCommandModel} [UpdateConfigurationCommandModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNamePutRaw(requestParameters: TenantsNamePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>>;

    /**
     */
    tenantsNamePut(name: string, accept_version?: string, UpdateConfigurationCommandModel?: UpdateConfigurationCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsEntitiesGetRaw(requestParameters: TenantsNameSectionsEntitiesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldEntitiesSectionModel>>;

    /**
     */
    tenantsNameSectionsEntitiesGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldEntitiesSectionModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {UpdateEntitiesSectionCommandModel} [UpdateEntitiesSectionCommandModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsEntitiesPutRaw(requestParameters: TenantsNameSectionsEntitiesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>>;

    /**
     */
    tenantsNameSectionsEntitiesPut(name: string, accept_version?: string, UpdateEntitiesSectionCommandModel?: UpdateEntitiesSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsInfrastructureGetRaw(requestParameters: TenantsNameSectionsInfrastructureGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldInfrastructureSectionModel>>;

    /**
     */
    tenantsNameSectionsInfrastructureGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldInfrastructureSectionModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {UpdateInfrastructureSectionCommandModel} [UpdateInfrastructureSectionCommandModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsInfrastructurePutRaw(requestParameters: TenantsNameSectionsInfrastructurePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>>;

    /**
     */
    tenantsNameSectionsInfrastructurePut(name: string, accept_version?: string, UpdateInfrastructureSectionCommandModel?: UpdateInfrastructureSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsInternalGetRaw(requestParameters: TenantsNameSectionsInternalGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldConfigurationWithoutWebModel>>;

    /**
     */
    tenantsNameSectionsInternalGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldConfigurationWithoutWebModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsLaboratoryGetRaw(requestParameters: TenantsNameSectionsLaboratoryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldLaboratorySectionModel>>;

    /**
     */
    tenantsNameSectionsLaboratoryGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldLaboratorySectionModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {UpdateLaboratorySectionCommandModel} [UpdateLaboratorySectionCommandModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsLaboratoryPutRaw(requestParameters: TenantsNameSectionsLaboratoryPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>>;

    /**
     */
    tenantsNameSectionsLaboratoryPut(name: string, accept_version?: string, UpdateLaboratorySectionCommandModel?: UpdateLaboratorySectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsMobileGetRaw(requestParameters: TenantsNameSectionsMobileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldMobileSectionModel>>;

    /**
     */
    tenantsNameSectionsMobileGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldMobileSectionModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {UpdateMobileSectionCommandModel} [UpdateMobileSectionCommandModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsMobilePutRaw(requestParameters: TenantsNameSectionsMobilePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>>;

    /**
     */
    tenantsNameSectionsMobilePut(name: string, accept_version?: string, UpdateMobileSectionCommandModel?: UpdateMobileSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsUsersGetRaw(requestParameters: TenantsNameSectionsUsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldUsersSectionModel>>;

    /**
     */
    tenantsNameSectionsUsersGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldUsersSectionModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {UpdateUsersSectionCommandModel} [UpdateUsersSectionCommandModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsUsersPutRaw(requestParameters: TenantsNameSectionsUsersPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>>;

    /**
     */
    tenantsNameSectionsUsersPut(name: string, accept_version?: string, UpdateUsersSectionCommandModel?: UpdateUsersSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsWebGetRaw(requestParameters: TenantsNameSectionsWebGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldWebSectionModel>>;

    /**
     */
    tenantsNameSectionsWebGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldWebSectionModel>;

    /**
     * 
     * @param {string} name 
     * @param {string} [accept_version] 
     * @param {UpdateWebSectionCommandModel} [UpdateWebSectionCommandModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsNameSectionsWebPutRaw(requestParameters: TenantsNameSectionsWebPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>>;

    /**
     */
    tenantsNameSectionsWebPut(name: string, accept_version?: string, UpdateWebSectionCommandModel?: UpdateWebSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel>;

    /**
     * 
     * @param {string} [accept_version] 
     * @param {AddConfigurationCommandModel} [AddConfigurationCommandModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    tenantsPostRaw(requestParameters: TenantsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>>;

    /**
     */
    tenantsPost(accept_version?: string, AddConfigurationCommandModel?: AddConfigurationCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel>;

}

/**
 * 
 */
export class TenantsApi extends runtime.BaseAPI implements TenantsApiInterface {

     /**
     */
    async tenantsNameGetRawOriginal(requestParameters: TenantsNameGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async tenantsNameGetRaw(requestParameters: TenantsNameGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldConfigurationModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OldConfigurationModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldConfigurationModel> {
        const response = await this.tenantsNameGetRaw({ name: name, accept_version: accept_version }, initOverrides);
        return await response.value();
    }
    async tenantsNameGetOriginal(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameGetRawOriginal({ name: name, accept_version: accept_version }, initOverrides);
    }

     /**
     */
    async tenantsNamePutRawOriginal(requestParameters: TenantsNamePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNamePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateConfigurationCommandModelToJSON(requestParameters.UpdateConfigurationCommandModel),
        }, initOverrides);
    }

    /**
     */
    async tenantsNamePutRaw(requestParameters: TenantsNamePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNamePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateConfigurationCommandModelToJSON(requestParameters.UpdateConfigurationCommandModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandResultModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNamePut(name: string, accept_version?: string, UpdateConfigurationCommandModel?: UpdateConfigurationCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel> {
        const response = await this.tenantsNamePutRaw({ name: name, accept_version: accept_version, UpdateConfigurationCommandModel: UpdateConfigurationCommandModel }, initOverrides);
        return await response.value();
    }
    async tenantsNamePutOriginal(name: string, accept_version?: string, UpdateConfigurationCommandModel?: UpdateConfigurationCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNamePutRawOriginal({ name: name, accept_version: accept_version, UpdateConfigurationCommandModel: UpdateConfigurationCommandModel }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsEntitiesGetRawOriginal(requestParameters: TenantsNameSectionsEntitiesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsEntitiesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections/entities`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsEntitiesGetRaw(requestParameters: TenantsNameSectionsEntitiesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldEntitiesSectionModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsEntitiesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections/entities`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OldEntitiesSectionModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsEntitiesGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldEntitiesSectionModel> {
        const response = await this.tenantsNameSectionsEntitiesGetRaw({ name: name, accept_version: accept_version }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsEntitiesGetOriginal(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsEntitiesGetRawOriginal({ name: name, accept_version: accept_version }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsEntitiesPutRawOriginal(requestParameters: TenantsNameSectionsEntitiesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsEntitiesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections/entities`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEntitiesSectionCommandModelToJSON(requestParameters.UpdateEntitiesSectionCommandModel),
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsEntitiesPutRaw(requestParameters: TenantsNameSectionsEntitiesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsEntitiesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections/entities`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEntitiesSectionCommandModelToJSON(requestParameters.UpdateEntitiesSectionCommandModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandResultModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsEntitiesPut(name: string, accept_version?: string, UpdateEntitiesSectionCommandModel?: UpdateEntitiesSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel> {
        const response = await this.tenantsNameSectionsEntitiesPutRaw({ name: name, accept_version: accept_version, UpdateEntitiesSectionCommandModel: UpdateEntitiesSectionCommandModel }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsEntitiesPutOriginal(name: string, accept_version?: string, UpdateEntitiesSectionCommandModel?: UpdateEntitiesSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsEntitiesPutRawOriginal({ name: name, accept_version: accept_version, UpdateEntitiesSectionCommandModel: UpdateEntitiesSectionCommandModel }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsInfrastructureGetRawOriginal(requestParameters: TenantsNameSectionsInfrastructureGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsInfrastructureGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections/infrastructure`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsInfrastructureGetRaw(requestParameters: TenantsNameSectionsInfrastructureGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldInfrastructureSectionModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsInfrastructureGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections/infrastructure`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OldInfrastructureSectionModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsInfrastructureGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldInfrastructureSectionModel> {
        const response = await this.tenantsNameSectionsInfrastructureGetRaw({ name: name, accept_version: accept_version }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsInfrastructureGetOriginal(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsInfrastructureGetRawOriginal({ name: name, accept_version: accept_version }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsInfrastructurePutRawOriginal(requestParameters: TenantsNameSectionsInfrastructurePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsInfrastructurePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections/infrastructure`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInfrastructureSectionCommandModelToJSON(requestParameters.UpdateInfrastructureSectionCommandModel),
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsInfrastructurePutRaw(requestParameters: TenantsNameSectionsInfrastructurePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsInfrastructurePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections/infrastructure`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInfrastructureSectionCommandModelToJSON(requestParameters.UpdateInfrastructureSectionCommandModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandResultModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsInfrastructurePut(name: string, accept_version?: string, UpdateInfrastructureSectionCommandModel?: UpdateInfrastructureSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel> {
        const response = await this.tenantsNameSectionsInfrastructurePutRaw({ name: name, accept_version: accept_version, UpdateInfrastructureSectionCommandModel: UpdateInfrastructureSectionCommandModel }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsInfrastructurePutOriginal(name: string, accept_version?: string, UpdateInfrastructureSectionCommandModel?: UpdateInfrastructureSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsInfrastructurePutRawOriginal({ name: name, accept_version: accept_version, UpdateInfrastructureSectionCommandModel: UpdateInfrastructureSectionCommandModel }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsInternalGetRawOriginal(requestParameters: TenantsNameSectionsInternalGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsInternalGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections-internal`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsInternalGetRaw(requestParameters: TenantsNameSectionsInternalGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldConfigurationWithoutWebModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsInternalGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections-internal`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OldConfigurationWithoutWebModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsInternalGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldConfigurationWithoutWebModel> {
        const response = await this.tenantsNameSectionsInternalGetRaw({ name: name, accept_version: accept_version }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsInternalGetOriginal(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsInternalGetRawOriginal({ name: name, accept_version: accept_version }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsLaboratoryGetRawOriginal(requestParameters: TenantsNameSectionsLaboratoryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsLaboratoryGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections/laboratory`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsLaboratoryGetRaw(requestParameters: TenantsNameSectionsLaboratoryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldLaboratorySectionModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsLaboratoryGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections/laboratory`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OldLaboratorySectionModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsLaboratoryGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldLaboratorySectionModel> {
        const response = await this.tenantsNameSectionsLaboratoryGetRaw({ name: name, accept_version: accept_version }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsLaboratoryGetOriginal(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsLaboratoryGetRawOriginal({ name: name, accept_version: accept_version }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsLaboratoryPutRawOriginal(requestParameters: TenantsNameSectionsLaboratoryPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsLaboratoryPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections/laboratory`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLaboratorySectionCommandModelToJSON(requestParameters.UpdateLaboratorySectionCommandModel),
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsLaboratoryPutRaw(requestParameters: TenantsNameSectionsLaboratoryPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsLaboratoryPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections/laboratory`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLaboratorySectionCommandModelToJSON(requestParameters.UpdateLaboratorySectionCommandModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandResultModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsLaboratoryPut(name: string, accept_version?: string, UpdateLaboratorySectionCommandModel?: UpdateLaboratorySectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel> {
        const response = await this.tenantsNameSectionsLaboratoryPutRaw({ name: name, accept_version: accept_version, UpdateLaboratorySectionCommandModel: UpdateLaboratorySectionCommandModel }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsLaboratoryPutOriginal(name: string, accept_version?: string, UpdateLaboratorySectionCommandModel?: UpdateLaboratorySectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsLaboratoryPutRawOriginal({ name: name, accept_version: accept_version, UpdateLaboratorySectionCommandModel: UpdateLaboratorySectionCommandModel }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsMobileGetRawOriginal(requestParameters: TenantsNameSectionsMobileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsMobileGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections/mobile`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsMobileGetRaw(requestParameters: TenantsNameSectionsMobileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldMobileSectionModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsMobileGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections/mobile`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OldMobileSectionModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsMobileGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldMobileSectionModel> {
        const response = await this.tenantsNameSectionsMobileGetRaw({ name: name, accept_version: accept_version }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsMobileGetOriginal(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsMobileGetRawOriginal({ name: name, accept_version: accept_version }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsMobilePutRawOriginal(requestParameters: TenantsNameSectionsMobilePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsMobilePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections/mobile`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMobileSectionCommandModelToJSON(requestParameters.UpdateMobileSectionCommandModel),
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsMobilePutRaw(requestParameters: TenantsNameSectionsMobilePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsMobilePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections/mobile`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMobileSectionCommandModelToJSON(requestParameters.UpdateMobileSectionCommandModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandResultModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsMobilePut(name: string, accept_version?: string, UpdateMobileSectionCommandModel?: UpdateMobileSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel> {
        const response = await this.tenantsNameSectionsMobilePutRaw({ name: name, accept_version: accept_version, UpdateMobileSectionCommandModel: UpdateMobileSectionCommandModel }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsMobilePutOriginal(name: string, accept_version?: string, UpdateMobileSectionCommandModel?: UpdateMobileSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsMobilePutRawOriginal({ name: name, accept_version: accept_version, UpdateMobileSectionCommandModel: UpdateMobileSectionCommandModel }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsUsersGetRawOriginal(requestParameters: TenantsNameSectionsUsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsUsersGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections/users`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsUsersGetRaw(requestParameters: TenantsNameSectionsUsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldUsersSectionModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsUsersGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections/users`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OldUsersSectionModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsUsersGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldUsersSectionModel> {
        const response = await this.tenantsNameSectionsUsersGetRaw({ name: name, accept_version: accept_version }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsUsersGetOriginal(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsUsersGetRawOriginal({ name: name, accept_version: accept_version }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsUsersPutRawOriginal(requestParameters: TenantsNameSectionsUsersPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsUsersPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections/users`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUsersSectionCommandModelToJSON(requestParameters.UpdateUsersSectionCommandModel),
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsUsersPutRaw(requestParameters: TenantsNameSectionsUsersPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsUsersPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections/users`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUsersSectionCommandModelToJSON(requestParameters.UpdateUsersSectionCommandModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandResultModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsUsersPut(name: string, accept_version?: string, UpdateUsersSectionCommandModel?: UpdateUsersSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel> {
        const response = await this.tenantsNameSectionsUsersPutRaw({ name: name, accept_version: accept_version, UpdateUsersSectionCommandModel: UpdateUsersSectionCommandModel }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsUsersPutOriginal(name: string, accept_version?: string, UpdateUsersSectionCommandModel?: UpdateUsersSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsUsersPutRawOriginal({ name: name, accept_version: accept_version, UpdateUsersSectionCommandModel: UpdateUsersSectionCommandModel }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsWebGetRawOriginal(requestParameters: TenantsNameSectionsWebGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsWebGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections/web`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsWebGetRaw(requestParameters: TenantsNameSectionsWebGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OldWebSectionModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsWebGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections/web`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OldWebSectionModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsWebGet(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OldWebSectionModel> {
        const response = await this.tenantsNameSectionsWebGetRaw({ name: name, accept_version: accept_version }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsWebGetOriginal(name: string, accept_version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsWebGetRawOriginal({ name: name, accept_version: accept_version }, initOverrides);
    }

     /**
     */
    async tenantsNameSectionsWebPutRawOriginal(requestParameters: TenantsNameSectionsWebPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsWebPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants/{name}/sections/web`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWebSectionCommandModelToJSON(requestParameters.UpdateWebSectionCommandModel),
        }, initOverrides);
    }

    /**
     */
    async tenantsNameSectionsWebPutRaw(requestParameters: TenantsNameSectionsWebPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling tenantsNameSectionsWebPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants/{name}/sections/web`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWebSectionCommandModelToJSON(requestParameters.UpdateWebSectionCommandModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandResultModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsNameSectionsWebPut(name: string, accept_version?: string, UpdateWebSectionCommandModel?: UpdateWebSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel> {
        const response = await this.tenantsNameSectionsWebPutRaw({ name: name, accept_version: accept_version, UpdateWebSectionCommandModel: UpdateWebSectionCommandModel }, initOverrides);
        return await response.value();
    }
    async tenantsNameSectionsWebPutOriginal(name: string, accept_version?: string, UpdateWebSectionCommandModel?: UpdateWebSectionCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsNameSectionsWebPutRawOriginal({ name: name, accept_version: accept_version, UpdateWebSectionCommandModel: UpdateWebSectionCommandModel }, initOverrides);
    }

     /**
     */
    async tenantsPostRawOriginal(requestParameters: TenantsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        return this.request({
            path: `/tenants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddConfigurationCommandModelToJSON(requestParameters.AddConfigurationCommandModel),
        }, initOverrides);
    }

    /**
     */
    async tenantsPostRaw(requestParameters: TenantsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommandResultModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept_version !== undefined && requestParameters.accept_version !== null) {
            headerParameters['accept-version'] = String(requestParameters.accept_version);
        }

        const response = await this.request({
            path: `/tenants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddConfigurationCommandModelToJSON(requestParameters.AddConfigurationCommandModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandResultModelFromJSON(jsonValue));
    }

    /**
     */
    async tenantsPost(accept_version?: string, AddConfigurationCommandModel?: AddConfigurationCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommandResultModel> {
        const response = await this.tenantsPostRaw({ accept_version: accept_version, AddConfigurationCommandModel: AddConfigurationCommandModel }, initOverrides);
        return await response.value();
    }
    async tenantsPostOriginal(accept_version?: string, AddConfigurationCommandModel?: AddConfigurationCommandModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        return this.tenantsPostRawOriginal({ accept_version: accept_version, AddConfigurationCommandModel: AddConfigurationCommandModel }, initOverrides);
    }

}
