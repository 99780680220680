import { useTranslation } from "react-i18next";
import "./HeatMapResultsWidgetHeader.scss";
import { Select } from "libs/ui/Select";
import { useState } from "react";
import { GenderType } from "models/PersonModels";
import { autoPlacement, autoUpdate } from "@floating-ui/react-dom";
import { genderTypeToSelectOptions } from "utils/mappers/genderTypeToSelectOptions";

export function HeatMapResultsWidgetHeader() {
  const { t } = useTranslation("translation", {
    keyPrefix: "DashboardPage.DashboardStatsPage.HeatMapResultsWidget.Header",
  });
  const [gender, setGender] = useState<GenderType>("Male");

  return (
    <div className="HeatMapResultsWidgetHeader">
      <p className="HeatMapResultsWidgetHeader__title">{t("title")}</p>
      <Select
        onSelect={val => setGender(val ?? "Male")}
        value={gender}
        className="DashboardMap__select"
        options={genderTypeToSelectOptions()}
        dropdownFloatingProps={{
          strategy: "absolute",
          placement: "bottom-end",
          middleware: [autoPlacement({ allowedPlacements: ["bottom-end"] })],
          whileElementsMounted: autoUpdate,
        }}
      />
    </div>
  );
}
