import { useTranslation } from "react-i18next";
import { FilterSection } from "../FilterSection";
import { CountryType } from "api/config_service";
import { statesToSelectOptions } from "utils/mappers/statesToSelectOptions";
import { useEffect } from "react";
import { useStatesPerCountry } from "utils/hooks/useStatesPerCountry";

interface StatesFilterSectionProps {
  countries: CountryType[];
  checkedValues: number[];
  onFilterApply: (value: number) => void;
  onSingleFilterClear: () => void;
}

export function StatesFilterSection({
  countries,
  checkedValues,
  onFilterApply,
  onSingleFilterClear,
}: Readonly<StatesFilterSectionProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "StatesFilterSection" });
  const [getStates] = useStatesPerCountry();

  // we can't apply `states` filter on multiple countries, since `states` are per country
  const country = countries.length === 1 ? countries[0] : undefined;
  const states = getStates(country);

  useEffect(() => {
    if (countries.length !== 1 && checkedValues.length > 0) {
      onSingleFilterClear();
    }
  }, [checkedValues.length, countries.length, onSingleFilterClear]);

  if (states === null) {
    return null;
  }

  return (
    <FilterSection
      title={t("title")}
      options={statesToSelectOptions(states)}
      checkedValues={checkedValues.map(el => Number(el))}
      onFilterApply={value => onFilterApply(value)}
      withSearch={true}
    />
  );
}
