import { DraggableWidget, DraggableWidgetProps } from "components/DnD/DraggableWidget";
import { useDetailsPageContext } from "components/PageTemplate/DetailsPage/context";
import { forwardRef, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { SideDetails } from "components/MeasurementSideDetails/SideDetails/SideDetails";
import { TestsSideDetails } from "components/AppTables/common/TestsTable/TestsSideDetails";
import { GeneralSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/GeneralSectionDetails";
import { PatientSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/PatientSectionDetails";
import { TestUnitType, mapToSideDetails } from "components/AppTables/common/TestsTable/utils";

import { isDefaultModel, isFailed, isLoaded, isLoading, LoadedModel } from "models/loadable";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useApi } from "utils/hooks/useApi";
import { CarnaApiQuery } from "config/apiQuery";
import { MeasurementWidgetType } from "utils/createGlobalConfigStore";
import { useOverviewAPIContext } from "../OverviewAPIContext";
import { MeasurementPatientWidgetDataModel } from "api/query/models/MeasurementPatientWidgetDataModel";
import { WidgetBody } from "./WidgetBody";
import { RefreshFailedWidget } from "../RefreshFailedWidget";
import { WidgetLoader } from "../WidgetLoader";
import classNames from "classnames";
import { WidgetHeader } from "components/Widget/WidgetHeader";
import { getWidgetSize } from "components/DnD/util";

interface MeasurementWidgetProps extends DraggableWidgetProps {
  type: MeasurementWidgetType;
  organizationId: string;
  patientId: string;
  w?: number;
  h?: number;
}

function getTestUnitType(type: MeasurementWidgetType): TestUnitType {
  switch (type) {
    case "SerumCreatinine":
    case "Egfr":
      return "SerumCreatinine";

    case "Bmi":
    case "Height":
    case "Weight":
      return "BMI";

    case "Uacr":
    case "UrineAlbumin":
    case "UrineCreatinine":
      return "UACR";

    case "SemiQuantitativeUacr":
      return "SemiQuantitativeUACR";

    case "BloodPressure":
    case "Glucose":
      return type;

    default:
      throw new Error(`type ${type} not found in TestUnitType`);
  }
}

export const MeasurementWidget = forwardRef<
  HTMLDivElement,
  PropsWithChildren<MeasurementWidgetProps>
>(
  (
    {
      Header,
      optionsDropDown,
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      type,
      children, //!!! children is needed so that resize handle is passed to the component
      organizationId,
      patientId,
      w,
      h,
      ...rest
    },
    ref,
  ) => {
    const { appConfig } = useGlobalConfigContext();
    const { detailsPageSideBarRef } = useDetailsPageContext();

    const { data, getWidgetData } = useOverviewAPIContext();
    const widgetData = data[type];

    const refreshWidgetData = useCallback(() => {
      getWidgetData(organizationId, patientId, [type]);
    }, [getWidgetData, organizationId, patientId, type]);

    const [testUnitType, setTestUnitType] = useState<{
      type: TestUnitType;
      subtype: MeasurementWidgetType;
    }>();

    const [testData, getTestData, resetTestData] = useApi(
      CarnaApiQuery.Test.getByOrganizationPatientId,
    );
    const onWidgetClick = () => {
      if (type === testUnitType?.subtype) {
        return;
      }

      if (isLoaded(data[type])) {
        setTestUnitType({
          type: getTestUnitType(type),
          subtype: type,
        });
        const { value } = data[type] as LoadedModel<MeasurementPatientWidgetDataModel>;
        const { id } = value;
        getTestData({ organizationId, userEntityId: patientId, testEntityId: id ?? "" });
      }
    };
    const testSideDetails = useMemo(
      () => (isLoaded(testData) ? mapToSideDetails(testData.value, testUnitType?.type) : undefined),
      [testData, testUnitType],
    );
    const onSideDetailsClose = useCallback(() => {
      setTestUnitType(undefined);
      resetTestData();
    }, [resetTestData]);

    return (
      <DraggableWidget
        ref={ref}
        optionsDropDown={optionsDropDown}
        style={style}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        data-type={type}
        data-testid={`DraggableWidget-${type}`}
        Header={<WidgetHeader type={type} date={widgetData.value?.measurementTime} />}
        className={classNames("MeasurementWidget", getWidgetSize(w, h))}
        {...rest}
      >
        {isLoading(widgetData) || isDefaultModel(widgetData) ? <WidgetLoader /> : null}

        {isLoaded(widgetData) ? (
          <>
            <WidgetBody onClick={onWidgetClick} data={widgetData.value} type={type} />

            {children}

            {detailsPageSideBarRef.current
              ? createPortal(
                  <SideDetails
                    show={!isDefaultModel(testData)}
                    title={isLoaded(testData) ? `#${testSideDetails?.test.testCountId}` : ""}
                    onClose={onSideDetailsClose}
                    data-testid={testUnitType?.subtype}
                  >
                    <>
                      <TestsSideDetails
                        showTrend={appConfig?.components.tables.Laboratory.Patient.showTrending}
                        measurementTabType={testUnitType?.subtype}
                        sideDetails={testSideDetails}
                        loading={isLoading(testData)}
                      />

                      <GeneralSectionDetails
                        data={testSideDetails}
                        loading={isLoading(testData)}
                        //   hcpAvatarImage={hcpAvatarImage}
                        //   avatarsLoading={avatarsState.matches("loading")}
                      />
                      <PatientSectionDetails
                        data={testSideDetails}
                        loading={isLoading(testData)}
                        selectedTestUnit={testUnitType?.type}
                      />
                    </>
                  </SideDetails>,
                  detailsPageSideBarRef.current,
                )
              : null}
          </>
        ) : (
          <RefreshFailedWidget show={isFailed(widgetData)} refreshCb={refreshWidgetData} />
        )}
      </DraggableWidget>
    );
  },
);
