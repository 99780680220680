import React, { useCallback, useEffect } from "react";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { FormFields } from "./FormFields";
import { DeviceFormProps, DeviceModelKey } from "./model";

export function Device({
  deviceData,
  setDeviceData,
  onSubmit,
  editableFields,
  formMode,
}: Readonly<DeviceFormProps>) {
  const { isAdmin, organizationId } = useAdditionalAuthInformationContext();

  const onChange = useCallback(
    (value: string | undefined, inputKey: DeviceModelKey) => {
      setDeviceData(prevValue => ({
        ...prevValue,
        [inputKey]: value,
      }));
    },
    [setDeviceData],
  );

  useEffect(() => {
    if (!isAdmin && !deviceData.organizationId) {
      setDeviceData(prevValue => ({
        ...prevValue,
        organizationId,
      }));
    }
  }, [deviceData.organizationId, isAdmin, organizationId, setDeviceData]);

  return (
    <FormFields
      formMode={formMode}
      onChange={onChange}
      onSubmit={onSubmit}
      deviceData={deviceData}
      editableFields={editableFields}
    />
  );
}
