import * as React from "react";
import { SVGProps } from "react";
const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.22 9.28a.75.75 0 0 1 0-1.06l5-5a.748.748 0 0 1 1.06 0l5 5a.75.75 0 0 1-1.06 1.06L12.5 5.56v10.19a.75.75 0 0 1-1.5 0V5.56L7.28 9.28a.75.75 0 0 1-1.06 0Z"
      fill="currentColor"
    />
    <path
      d="M7.21 21.625a6.251 6.251 0 0 1-6.205-5.501c-.05-.412.291-.749.705-.749.415 0 .744.338.809.747a4.751 4.751 0 0 0 4.691 4.003h9a4.751 4.751 0 0 0 4.692-4.003c.065-.409.394-.747.808-.747.415 0 .755.337.706.749a6.251 6.251 0 0 1-6.206 5.501h-9Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgUpload;
