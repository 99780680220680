import { SideModalElements } from "libs/ui";
import { useTranslation } from "react-i18next";
import { SectionDetailsProps } from "../model";
import { useBMIColor } from "utils/hooks/useBMIColor";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { BMIRangeTable } from "../../elements/BMIRangeTable/BMIRangeTable";
import { ColorValue } from "../../elements/ColorValue";
import { DetailsField } from "../../elements/DetailsField";
import { TrendDetailsField } from "../../elements/TrendDetailsField";

interface BMISectionDetailsProps extends SectionDetailsProps {}

export function BMISectionDetails({ data, loading, showTrend }: BMISectionDetailsProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.BMISectionDetails" });
  const { numberFormatter } = useLocaleNumberFormatter();
  const [getBMIColor] = useBMIColor();

  return (
    <div className="BMISectionDetails">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={t("labelResult")}>
        <ColorValue colorType={getBMIColor(data?.measurement?.BMI?.Bmi.Status)}>
          {numberFormatter({
            value: data?.measurement.BMI?.Bmi.Value,
            additionalOptions: {
              maximumFractionDigits: 1,
              minimumFractionDigits: 0,
              roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
            },
          })}
        </ColorValue>
      </DetailsField>

      <TrendDetailsField
        show={showTrend}
        loading={loading}
        trendType={data?.measurement?.BMI?.Bmi.TrendType}
        percent={data?.measurement?.BMI?.Bmi.Percent}
      />

      <BMIRangeTable loading={loading} value={Number(data?.measurement.BMI?.Bmi.Value)} />
    </div>
  );
}
