import { SideModalElements } from "libs/ui";
import { useTranslation } from "react-i18next";
import { SectionDetailsProps } from "../model";
import { useUACRColor } from "utils/hooks/useUACRColor";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { ColorValue } from "../../elements/ColorValue";
import { DetailsField } from "../../elements/DetailsField";
import { TrendDetailsField } from "../../elements/TrendDetailsField";
import { UACRRangeTable } from "../../elements/UACRRangeTable";

interface UACRSectionDetailsProps extends SectionDetailsProps {}

export function UACRSectionDetails({ data, loading, showTrend }: UACRSectionDetailsProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.UACRSectionDetails" });
  const { numberFormatter } = useLocaleNumberFormatter();
  const [getUACRColor] = useUACRColor();
  const { uacrUnit } = useGetUserPreferredMeasurementUnits();

  return (
    <div className="UACRSectionDetails">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={t("labelResult")}>
        <ColorValue colorType={getUACRColor(data?.measurement?.UACR?.Uacr.Status)}>
          {numberFormatter({ value: Number(data?.measurement?.UACR?.Uacr.Value ?? 0) }).concat(
            ` ${uacrUnit}`,
          )}
        </ColorValue>
      </DetailsField>

      <TrendDetailsField
        show={showTrend}
        loading={loading}
        trendType={data?.measurement?.UACR?.Uacr.TrendType}
        percent={data?.measurement?.UACR?.Uacr.Percent}
      />

      <UACRRangeTable status={data?.measurement?.UACR?.Uacr.Status ?? null} />
    </div>
  );
}
