import React, { PropsWithChildren } from "react";
import "./DetailsPageControls.scss";

interface DetailsPageControlsProps {
  detailsPageControlsSlot?: React.ReactNode;
}

export function DetailsPageControls({
  detailsPageControlsSlot,
  children,
}: PropsWithChildren<DetailsPageControlsProps>) {
  if (!React.Children.count(children)) {
    return null;
  }

  return (
    <div className="DetailsPageControls">
      {children}
      {React.isValidElement(detailsPageControlsSlot) ? <>{detailsPageControlsSlot}</> : null}
    </div>
  );
}
