import * as React from "react";
import { SVGProps } from "react";
const SvgThumbsUpFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 1a1 1 0 0 0-.914.594L6.35 10H4a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h14.275a3 3 0 0 0 2.994-2.55l1.38-9A3.002 3.002 0 0 0 19.655 8H15V5a4 4 0 0 0-4-4ZM6 12h2v9H6v-9Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgThumbsUpFilled;
