import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { HCPTable } from "../common/HCPTable";
import { useHCPTableStateContext } from "./context";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useMemo } from "react";

export function AllHCPTable() {
  const tableContext = useHCPTableStateContext();
  const { isPatient } = useAdditionalAuthInformationContext();
  const { appConfig } = useGlobalConfigContext();

  const appliedColumns = useMemo(() => {
    const arr = [...NotNullOrUndefined(appConfig?.components.tables.Hcp.Index.columnList)];
    if (isPatient) {
      arr.splice(4, 0, "phone");
    }
    return arr;
  }, [appConfig?.components.tables.Hcp.Index.columnList, isPatient]);

  const hcpFilterOptions = useMemo(() => {
    if (isPatient) {
      return appConfig?.components.tables.Hcp.Index.filterOptions.filter(
        val => val !== "userStatuses",
      );
    }

    return appConfig?.components.tables.Hcp.Index.filterOptions;
  }, [appConfig?.components.tables.Hcp.Index.filterOptions, isPatient]);

  return (
    <HCPTable
      appliedColumns={appliedColumns}
      HCPTableStateContext={tableContext}
      hcpFilterOptions={hcpFilterOptions}
    />
  );
}
