import * as React from "react";
import { SVGProps } from "react";
const SvgPause = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.75 4a.75.75 0 0 0-1.5 0v16a.75.75 0 0 0 1.5 0V4ZM16.75 4a.75.75 0 0 0-1.5 0v16a.75.75 0 0 0 1.5 0V4Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPause;
