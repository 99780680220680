/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MeasurementTrendType = {
    None: 'None',
    Up: 'Up',
    Down: 'Down',
    Equal: 'Equal'
} as const;
export type MeasurementTrendType = typeof MeasurementTrendType[keyof typeof MeasurementTrendType];


export function MeasurementTrendTypeFromJSON(json: any): MeasurementTrendType {
    return MeasurementTrendTypeFromJSONTyped(json, false);
}

export function MeasurementTrendTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementTrendType {
    return json as MeasurementTrendType;
}

export function MeasurementTrendTypeToJSON(value?: MeasurementTrendType | null): any {
    return value as any;
}

