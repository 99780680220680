export function clearFilters() {
  const url = new URL(window.location.href);
  const { searchParams } = url;

  searchParams.forEach((_, key) => {
    if (["tableView", "linesPerPage", "currentPage"].includes(key as any)) {
      return;
    }
    searchParams.delete(key);
  });

  history.replaceState(null, "", url.toString());
}

export function updateQueryURL(key: string, value: any) {
  const url = new URL(window.location.href);
  const { searchParams } = url;

  searchParams.set(key, value);
  history.replaceState(null, "", url.toString());
}

export function updateFiltersQueryURL(deleteKeys: string[], updateValues: Record<string, any>) {
  const url = new URL(window.location.href);
  const { searchParams } = url;

  deleteKeys.forEach(key => searchParams.delete(key));

  Object.entries(updateValues).forEach(([key, value]) => {
    searchParams.set(key, Array.isArray(value) ? value.join(",") : value);
  });

  history.replaceState(null, "", url.toString());
}
