import { useEffect, useMemo, useSyncExternalStore } from "react";

/**
 * This function is made to compare some stuff in e2e if it's the same
 * with data-attr
 */
export async function stringToHash(data: string) {
  const encoder = new TextEncoder();
  const stringBuffer = encoder.encode(data);

  // Hash the phone number using SHA-256
  const hashBuffer = await crypto.subtle.digest("SHA-256", stringBuffer);

  // Convert hash ArrayBuffer to hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, "0")).join("");
  return hashHex;
}

type Listener = () => void;
// (window as any)["stringToHash"] = stringToHash;

export function createStringToHashPromises() {
  const listeners = new Set<Listener>();
  let hashResults: Record<string, any> = {};

  const getState = () => hashResults;

  const subscribe = (listener: Listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };

  const update = (hashes: string[]) => {
    hashResults = {};
    Promise.all(
      hashes.map(async inputString => {
        const result = await stringToHash(inputString);
        return [inputString, result] as const;
      }),
    ).then(bundledResult => {
      bundledResult.forEach(([inputString, result]) => {
        hashResults[inputString] = result;
      });

      listeners.forEach(notify => notify());
    });
  };

  return {
    getState,
    subscribe,
    update,
  } as const;
}

export function useStringToHashPromises(hashes: string[], dependencies: unknown[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const result = useMemo(() => createStringToHashPromises(), []);

  useEffect(() => {
    result.update(hashes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return useSyncExternalStore(result.subscribe, result.getState);
}
