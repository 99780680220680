/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PatientWidgetResponseModel } from './PatientWidgetResponseModel';
import {
    PatientWidgetResponseModelFromJSON,
    PatientWidgetResponseModelFromJSONTyped,
    PatientWidgetResponseModelToJSON,
} from './PatientWidgetResponseModel';

/**
 * 
 * @export
 * @interface PatientWidgetsResponseModel
 */
export interface PatientWidgetsResponseModel {
    /**
     * 
     * @type {Array<PatientWidgetResponseModel>}
     * @memberof PatientWidgetsResponseModel
     */
    widgets?: Array<PatientWidgetResponseModel> | null;
}

/**
 * Check if a given object implements the PatientWidgetsResponseModel interface.
 */
export function instanceOfPatientWidgetsResponseModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatientWidgetsResponseModelFromJSON(json: any): PatientWidgetsResponseModel {
    return PatientWidgetsResponseModelFromJSONTyped(json, false);
}

export function PatientWidgetsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientWidgetsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'widgets': !exists(json, 'Widgets') ? undefined : (json['Widgets'] === null ? null : (json['Widgets'] as Array<any>).map(PatientWidgetResponseModelFromJSON)),
    };
}

export function PatientWidgetsResponseModelToJSON(value?: PatientWidgetsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Widgets': value.widgets === undefined ? undefined : (value.widgets === null ? null : (value.widgets as Array<any>).map(PatientWidgetResponseModelToJSON)),
    };
}

