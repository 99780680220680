import { DB_DATE_FORMAT } from "config/const";
import { format } from "date-fns";

export function getDBDateFormat(value: Date | null) {
  return value && value.toString() !== "Invalid Date" ? format(value, DB_DATE_FORMAT) : "";
}

export function BEDateOfBirthToDate(value: string) {
  const [year, month, day] = value.split("-");

  if (!year || !month || !day) {
    throw new Error("This is not valid BE Date");
  }

  return new Date(+year, +month - 1, +day);
}
