import { SideModalElements } from "libs/ui";
import { useTranslation } from "react-i18next";
import { SectionDetailsProps } from "../model";

import { useMemo } from "react";
import { useGetUrineCreatinineRanges } from "utils/hooks/useGetUrineCreatinineRanges";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { useUrineCreatinineColor } from "utils/hooks/useUrineCreatinineColor";
import { ColorValue } from "../../elements/ColorValue";
import { DetailsField } from "../../elements/DetailsField";
import { RangeTable } from "../../elements/RangeTable/RangeTable";
import { TrendDetailsField } from "../../elements/TrendDetailsField";

interface UrineCreatinineSectionDetailsProps extends SectionDetailsProps {}
export function UrineCreatinineSectionDetails({
  data,
  loading,
  showTrend,
}: UrineCreatinineSectionDetailsProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "Graph.Details.UrineCreatinineSectionDetails",
  });
  const { numberFormatter } = useLocaleNumberFormatter();
  const [getUrineCreatineColor] = useUrineCreatinineColor();
  const { urineCreatinineUnit } = useGetUserPreferredMeasurementUnits();
  const [, getRangePoints] = useGetUrineCreatinineRanges();

  const labels = [t("low"), t("normal"), t("high")] as const;

  const steps = useMemo(
    () => getRangePoints(data?.test.patient.gender ?? "Male"),
    [data?.test.patient.gender, getRangePoints],
  );

  return (
    <div className="UrineCreatinineSectionDetails">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={t("labelResult")}>
        <ColorValue
          colorType={getUrineCreatineColor(data?.measurement?.UACR?.UrineCreatinine?.Status)}
        >
          {numberFormatter({
            value: data?.measurement?.UACR?.UrineCreatinine?.Value,
          }).concat(` ${urineCreatinineUnit}`)}
        </ColorValue>
      </DetailsField>

      <TrendDetailsField
        show={showTrend}
        loading={loading}
        trendType={data?.measurement?.UACR?.UrineCreatinine?.TrendType}
        percent={data?.measurement?.UACR?.UrineCreatinine?.Percent}
      />

      <RangeTable
        value={Number(data?.measurement?.UACR?.UrineCreatinine?.Value ?? 0)}
        labels={labels}
        steps={steps}
      />
    </div>
  );
}
