import { GeneralPractitionerResponseModel } from "api/query";
import { SelectFilterAsyncNoData } from "components/SelectFilterAsyncNoData";
import { SelectFilterAsyncWithCancel } from "components/SelectFilterAsyncWithCancel";
import { CarnaApiQuery } from "config/apiQuery";
import i18n from "i18next";
import { FieldValidationProps, FormFieldBasic, SelectFilterResult } from "libs/ui";
import { createSelectFilterAsyncOption } from "libs/ui/SelectFilterAsync/SelectFilterAsync.model";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface GeneralPractitionersFieldProps extends FormFieldBasic, FieldValidationProps {
  initialValue?: string;
  readOnly?: boolean;
  onSelect: (value: GeneralPractitionerResponseModel | undefined) => void;
  loading?: boolean;
  optional?: boolean;
}

export function GeneralPractitionersField({
  initialValue,
  readOnly,
  onSelect,
  optional,
  loading,
}: Readonly<GeneralPractitionersFieldProps>) {
  const { t: tForm } = useTranslation("translation", { keyPrefix: "Form" });
  const { t: tComponents } = useTranslation("translation", { keyPrefix: "components" });

  const fetchGeneralPractitioners = useMemo(
    () =>
      createSelectFilterAsyncOption(async (filter?: string) => {
        const data = await CarnaApiQuery.GeneralPractitioners.get({
          filters: { contains: filter },
          limit: 100,
          page: 1,
        });
        return (
          data.items.map(item => ({
            title: `${item.title} ${item.firstName} ${item.lastName}, ${item.registrationNumber}`,
            value: item,
          })) ?? []
        );
      }),
    [],
  );

  const setRenderSelected = useCallback(
    (props?: GeneralPractitionerResponseModel | undefined) => {
      const hasNoPropsAndInitValue = !props && !initialValue;
      const fullName =
        !!props?.title && !!props?.firstName && !!props?.lastName && !!props?.registrationNumber
          ? `${props?.title} ${props?.firstName} ${props?.lastName}, ${props?.registrationNumber}`
          : initialValue;

      return (
        <SelectFilterResult
          data-testval={JSON.stringify({ fullName: `${props?.firstName} ${props?.lastName}` })}
          hasValue={!!(props ?? initialValue)}
          label={i18n.format("GeneralPractitioner", "optionalField", undefined, { optional })}
        >
          {hasNoPropsAndInitValue ? null : fullName}
        </SelectFilterResult>
      );
    },
    [initialValue, optional],
  );

  return (
    <SelectFilterAsyncWithCancel
      data-testid="GeneralPractitionersField"
      readOnly={readOnly}
      onSelect={onSelect}
      hasInitialValue={!!initialValue}
      validation={{
        infoText: !readOnly ? tComponents("GeneralPractitionersField.infoText") : undefined,
        errorText: tForm("ValidationMessages.generalPractitionerNotAvailable") ?? "",
      }}
      getOptions={fetchGeneralPractitioners}
      loading={loading}
      renderSelected={setRenderSelected}
      noDataComponent={<SelectFilterAsyncNoData variant="GeneralPractitioner" scaleTo={0.6} />}
    />
  );
}
