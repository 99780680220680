import * as React from "react";
import { SVGProps } from "react";
const SvgVideoOff = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#video-off_svg__a)" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.53.47A.75.75 0 0 0 .47 1.53l2.72 2.72H3A2.75 2.75 0 0 0 .25 7v10A2.75 2.75 0 0 0 3 19.75h11a2.75 2.75 0 0 0 2.655-2.034l5.815 5.814a.75.75 0 1 0 1.06-1.06l-18-18-4-4Zm13.72 15.84L4.69 5.75H3A1.25 1.25 0 0 0 1.75 7v10A1.25 1.25 0 0 0 3 18.25h11A1.25 1.25 0 0 0 15.25 17v-.69Z"
      />
      <path d="M10.66 4.25a.75.75 0 0 0 0 1.5H14A1.25 1.25 0 0 1 15.25 7v3.34c0 .199.079.39.22.53l1 1a.75.75 0 0 0 .97.078l4.81-3.48V17a.75.75 0 0 0 1.5 0V7a.75.75 0 0 0-1.19-.608l-5.482 3.966-.328-.329V7A2.75 2.75 0 0 0 14 4.25h-3.34Z" />
    </g>
    <defs>
      <clipPath id="video-off_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgVideoOff;
