import * as React from "react";
import { SVGProps } from "react";
const SvgClipboard = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 3.25V3c0-.966.784-1.75 1.75-1.75h6c.966 0 1.75.784 1.75 1.75v.25H18A2.75 2.75 0 0 1 20.75 6v14A2.75 2.75 0 0 1 18 22.75H6A2.75 2.75 0 0 1 3.25 20V6A2.75 2.75 0 0 1 6 3.25h1.25ZM8.75 3A.25.25 0 0 1 9 2.75h6a.25.25 0 0 1 .25.25v2a.25.25 0 0 1-.25.25H9A.25.25 0 0 1 8.75 5V3Zm8 1.75H18A1.25 1.25 0 0 1 19.25 6v14A1.25 1.25 0 0 1 18 21.25H6A1.25 1.25 0 0 1 4.75 20V6A1.25 1.25 0 0 1 6 4.75h1.25V5c0 .966.784 1.75 1.75 1.75h6A1.75 1.75 0 0 0 16.75 5v-.25Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgClipboard;
