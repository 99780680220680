import * as React from "react";
import { SVGProps } from "react";
const SvgMove = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.287 1.307c.088.037.171.09.243.163l3 3a.75.75 0 0 1-1.06 1.06l-1.72-1.72v7.44h7.44l-1.72-1.72a.75.75 0 0 1 1.06-1.06l3 3a.748.748 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72h-7.44v7.44l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.748.748 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l1.72 1.72v-7.44H3.81l1.72 1.72a.75.75 0 1 1-1.06 1.06l-3-3a.748.748 0 0 1 0-1.06l3-3a.75.75 0 0 1 1.06 1.06l-1.72 1.72h7.44V3.81L9.53 5.53a.75.75 0 0 1-1.06-1.06l3-3a.748.748 0 0 1 .817-.163Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMove;
