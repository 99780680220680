import { ReportResponseModel } from "api/report_service/models/ReportResponseModel";
import { SingleReport } from "./SingleReport";
import { SingleReportLoader } from "./SingleReportLoader";
import { NoReports } from "./NoReports";
import { useTranslation } from "react-i18next";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";

interface ReportListProps {
  isLoading: boolean;
  reports: ReportResponseModel[];
  onReportDownload: (id: string, type: string, createdAt: string) => void;
}

export function ReportList({ reports, isLoading, onReportDownload }: ReportListProps) {
  const { t } = useTranslation("translation", { keyPrefix: "ReportPage.SingleReport.TimeStamps" });
  const [dateFormatter, timeFormatter] = useRegionDateTimeFormatter();

  if (isLoading) {
    return (
      <div className="ReportList">
        {Array.from({ length: 10 }).map((_, index) => (
          <SingleReportLoader key={index} />
        ))}
      </div>
    );
  }

  if (reports.length === 0) {
    return <NoReports />;
  }

  return (
    <div className="ReportList">
      {reports.map(report => {
        return (
          <SingleReport
            key={report.reportId}
            {...report}
            onReportDownload={() =>
              onReportDownload(
                report.entityId,
                report.reportType,
                report.fileGeneratedAtDate
                  ? t("created", {
                      date: dateFormatter(report.fileGeneratedAtDate),
                      time: timeFormatter(report.fileGeneratedAtDate),
                    })
                  : "",
              )
            }
          />
        );
      })}
    </div>
  );
}
