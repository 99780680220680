import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useBreadCrumbs } from "./useBreadCrumbs";
import { useMemo } from "react";

// We are using this hook to alter breadcrumbs that we get when
// we are logged in as patient because we don't want to allow him to navigate
// to the pages path which breadCrumbs will return and a patient doesn't have
// access to them

export function useFilteredBreadCrumbs() {
  const breadCrumbs = useBreadCrumbs();
  const { isPatient } = useAdditionalAuthInformationContext();

  const filteredBreadCrumbs = useMemo(() => {
    if (isPatient) {
      return breadCrumbs.filter(breadCrumb => breadCrumb.path === undefined);
    }
    return breadCrumbs;
  }, [breadCrumbs, isPatient]);

  return filteredBreadCrumbs;
}
