import "./SelectFilter.scss";

import classNames from "classnames";
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../Dropdown";
import { FieldValidation } from "../FieldValidation";
import { useOnClickOutside } from "../hooks";
import { Input } from "../Input";
import { DataAttributeKey } from "../models/common";
import { SelectOption } from "../SelectOption";
import { FieldLoader } from "../FieldLoader";
import { SelectProps, Option } from "../Select/Select.model";

export type SelectFilterProps<T> = SelectProps<T> & {
  renderSelected?: (selectedOption?: Option<T>) => ReactNode;
  renderOption?: (props?: Readonly<{ value: Option<T>; isActive: boolean }>) => ReactNode;
  [dataAttribute: DataAttributeKey]: any;
};

export function SelectFilter<T>({
  options,
  onSelect,
  label,
  disabled,
  value,
  className,
  dropdownFloatingProps,
  readOnly,
  validation,
  renderSelected,
  renderOption,
  loading,
  ...rest
}: SelectFilterProps<T> & {
  [dataAttribute: DataAttributeKey]: any;
}) {
  const { t } = useTranslation("translation", { keyPrefix: "ui-components.SelectFilterAsync" });
  const selectRef = useRef(null);
  const filterInputRef = useRef<HTMLInputElement | null>(null);
  const [filter, setFilter] = useState("");
  const [selectedOption, setSelectedOption] = useState<Option<T> | undefined>();
  const [showOptions, setShowOptions] = useState(false);

  const init = useCallback(() => {
    const currentOption = options.find(el => el.value === value);
    setSelectedOption(currentOption);
  }, [options, value]);

  useEffect(() => {
    init();
  }, [init]);

  const noMatchFilter = useMemo(
    () => !filter || options.every(el => el.title.toLowerCase() !== filter.toLowerCase()),
    [filter, options],
  );

  const toggleDropdown = useCallback(
    (withValue?: T) => {
      if (disabled || readOnly) {
        return;
      }

      if (!withValue && noMatchFilter) {
        setFilter("");
      }

      if (
        !withValue &&
        selectedOption &&
        options.some(el => el.title.toLowerCase() === selectedOption.title.toLowerCase())
      ) {
        const withSameTitle = options.filter(
          el => el.title.toLowerCase() === selectedOption.title.toLowerCase(),
        );
        const singleOption = withSameTitle.length === 1 ? withSameTitle[0] : undefined;

        onSelect && onSelect(singleOption?.value ?? undefined);
      }

      setShowOptions(prevValue => !prevValue);
    },
    [disabled, noMatchFilter, onSelect, options, readOnly, selectedOption],
  );

  const onOptionSelect = useCallback(
    (value_: Option<T>) => {
      if (onSelect) {
        const currentOption = options.find(el => el.value === value_?.value);

        setFilter("");
        setSelectedOption(prevValue => currentOption ?? prevValue);
        onSelect(value_?.value);
        toggleDropdown(value_?.value);
      }
    },
    [onSelect, options, toggleDropdown],
  );

  const onFocus = useCallback(() => {
    if (showOptions === false) {
      setShowOptions(true);
      setTimeout(() => {
        filterInputRef.current!.focus();
      }, 1);
    }
  }, [showOptions]);

  useOnClickOutside(selectRef, showOptions, () => toggleDropdown());

  const onFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const filteredOptions = useMemo(
    () => options.filter(el => el.title.toLowerCase().includes(filter.toLowerCase())),
    [filter, options],
  );

  if (loading) {
    return <FieldLoader />;
  }

  return (
    <div
      {...rest}
      ref={selectRef}
      className={classNames("SelectFilter", className, {
        "SelectFilter--hasValue": !!selectedOption,
      })}
      data-readonly={readOnly}
      data-disabled={disabled}
      {...(!(readOnly || disabled) ? { onFocus, tabIndex: 0 } : undefined)}
    >
      {renderSelected?.(selectedOption)}

      <FieldValidation {...validation} />

      {label ? (
        <label
          className={classNames("SelectFilter__label", {
            "SelectFilter__label--active": !!selectedOption || showOptions,
          })}
        >
          <span className="SelectFilter__label-text">{label}</span>
        </label>
      ) : null}

      <Dropdown
        show={showOptions}
        targetElement={() => selectRef.current}
        floatingProps={dropdownFloatingProps}
      >
        <Input
          data-testid="SelectFilter-search"
          className="SelectFilter__search"
          inputIcon={{ icon: "Search", iconPosition: "leading" }}
          value={filter}
          placeholder={t("search")}
          onChange={onFilter}
          inputRef={filterInputRef}
        />
        <div className="SelectFilter__options">
          {filteredOptions.map(option => (
            <SelectOption<Option<T>>
              render={renderOption}
              key={`${option.value}`}
              isActive={option.value === value}
              onSelect={onOptionSelect}
              title={option.title}
              value={option}
            />
          ))}
        </div>
      </Dropdown>
    </div>
  );
}
