import classNames from "classnames";
import React, {
  ChangeEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FieldValidation } from "../FieldValidation";
import { Icon, isIconType } from "../Icon";
import { FormFieldBasic } from "../models";
import { ElementIcon } from "../models/common";
import "./InputNumber.scss";
import { renderValidElement } from "../utils";
import { localeNumberFormat } from "utils/formatters/localeNumberFormat";
import { parseLocaleNumber } from "utils/converters/parseLocaleNumber";

type HTMLInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface InputNumberProps
  extends Pick<HTMLInputProps, "readOnly" | "about" | "onFocus" | "onBlur" | "className">,
    FormFieldBasic {
  inputClassName?: string;
  inputIcon?: ElementIcon;
  leadingElement?: React.ReactNode;
  loading?: boolean;
  value: number | null | "";
  onNumberChange: (value: number | null) => void;
  region?: string;
  disabled?: boolean;
  formattingOptions?: Parameters<typeof Intl.NumberFormat>[1] & { roundingMode: string };
  validateInput?: (value: string) => boolean;
}

export const InputNumber = forwardRef<{}, InputNumberProps>(
  (
    {
      label,
      validation,
      className,
      inputClassName,
      inputIcon,
      variant = "regular",
      value: incomingValue,
      onFocus,
      onBlur,
      readOnly,
      suffix,
      leadingElement,
      region,
      onNumberChange,
      validateInput,
      loading,
      formattingOptions,
      ...props
    },
    ref,
  ) => {
    const internalState = useRef<number | null>(null);
    const previousValue = useRef<string>("");

    const inputRef = useRef<HTMLInputElement>(null);

    const [active, setActive] = useState(false);
    const { successText, errorText } = validation ?? {};
    const { icon, iconPosition } = inputIcon ?? {};

    useEffect(() => {
      // update if we have null or ""
      if (incomingValue === "" || incomingValue === null) {
        internalState.current = null;
        if (inputRef.current) {
          inputRef.current!.value = "";
          previousValue.current = "";
        }
        return;
      }

      // const formatedIncomingUIValue = parseLocaleNumber(
      //   localeNumberFormat(incomingValue, region as any, formattingOptions),
      //   region as any,
      // );

      // console.log({
      //   incomingValue,
      //   internalState: internalState.current,
      //   toPrecision: incomingValue.toPrecision(internalState.current?.toString().length),
      //   startsWith: incomingValue.toString().startsWith(internalState.current?.toString() ?? ""),
      //   UIValue: inputRef.current!.value,
      // });

      /**
       * ! SYNCING WITH PARENT STATE
       * ! This makes 2 way binding
       */
      if (
        Number(incomingValue.toPrecision(internalState.current?.toString().length)) !==
        internalState.current
      ) {
        internalState.current = incomingValue;
        if (inputRef.current) {
          inputRef.current.value = localeNumberFormat(
            incomingValue,
            region as any,
            formattingOptions,
          );
          previousValue.current = inputRef.current.value;
        }
      }
    }, [formattingOptions, incomingValue, region]);

    const onInputFocus = useCallback(
      (event: React.FocusEvent<HTMLInputElement, Element>) => {
        if (onFocus) {
          onFocus(event);
        }

        setActive(true);
      },
      [onFocus],
    );

    const onInputBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement, Element>) => {
        if (onBlur) {
          onBlur(event);
        }

        setActive(false);
      },
      [onBlur],
    );

    const onInput: ChangeEventHandler<HTMLInputElement> = useCallback(
      event => {
        const val = event.currentTarget.value;
        if (val === "") {
          internalState.current = null;
          previousValue.current = "";
          onNumberChange(null);
          return;
        }

        if (validateInput && validateInput(val) === false) {
          inputRef.current!.value = previousValue.current;
          return;
        }

        previousValue.current = val;

        const valNum = parseLocaleNumber(val, region as any);
        if (isNaN(valNum as any)) {
          internalState.current = null;
          inputRef.current!.value = "";
          previousValue.current = "";
          onNumberChange(null);
          return;
        }

        // everything is fine, send data up
        internalState.current = valNum;
        onNumberChange(valNum);
      },
      [onNumberChange, region, validateInput],
    );

    // if we would do basic check !!incomingValue, if incomingValue would be 0 we would get false
    // which is wrong, since 0 is acceptable
    const isLabelActive = !Number.isNaN(incomingValue) || !!inputRef?.current?.value || active;

    const iconPositionClass = iconPosition ? `Input__element--${iconPosition}` : undefined;

    const hasValidLeadingElement = React.isValidElement(leadingElement);

    const iconClickable = !props.disabled;

    const iconClassName = classNames(`Icon--input`, {
      "Icon--disabled": props.disabled,
      "Icon--error": errorText,
      "Icon--clickable": iconClickable,
      "Icon--trailing": iconPosition === "trailing",
      "Icon--leading": iconPosition === "leading",
    });

    return (
      <div
        className={classNames(`UI-Components Input InputNumber`, className, {
          "InputNumber--loading": loading,
        })}
        data-type={variant}
      >
        {inputIcon &&
          (isIconType(icon) ? (
            <Icon icon={icon} className={iconClassName} />
          ) : (
            renderValidElement(icon, {
              className: classNames(iconClassName, icon?.props.className),
            })
          ))}

        {renderValidElement(<div className="Input__leading-element">{leadingElement}</div>)}

        {readOnly ? (
          <p
            className={classNames(
              "Input__element Input__element--disabled",
              iconPositionClass,
              inputClassName,
              {
                "Input__element--with-suffix": suffix,
                "Input__element--leading": hasValidLeadingElement,
              },
            )}
          >
            {incomingValue && region
              ? localeNumberFormat(incomingValue ?? "", region as any, formattingOptions)
              : ""}
          </p>
        ) : (
          <input
            ref={inputRef}
            {...props}
            className={classNames(
              "Input__element",
              {
                "Input__element--error": errorText,
                "Input__element--success": successText,
                "Input__element--with-suffix": suffix,
                "Input__element--disabled": props.disabled,
                "Input__element--leading": hasValidLeadingElement,
                "Input__element--loading": loading,
              },
              iconPositionClass,
              inputClassName,
            )}
            onInput={onInput}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            readOnly={readOnly}
          />
        )}

        {label && (
          <label
            className={classNames("Input__label", {
              "Input__label--leading": iconPosition === "leading" || hasValidLeadingElement,
              "Input__label--active": isLabelActive,
              "Input__label--error": !!errorText,
              "Input__label--success": !!successText,
              "InputNumber__label--loading": loading,
            })}
          >
            {label}
          </label>
        )}
        {suffix && <span className="Input__suffix">{suffix}</span>}
        <FieldValidation {...validation} />
      </div>
    );
  },
);
