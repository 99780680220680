import { SerumCreatinineStatusType } from "api/query";
import { ColorType } from "models/colors";
import { useCallback } from "react";

/**
 * !!! This is at least fourth color change
 *
 * There were several color changes and in each iteration there would be several colors
 *
 * For now, we will keep this hook in case we change the color palette again
 */

export function useCreatinineColor() {
  const colorResolve = useCallback((status?: SerumCreatinineStatusType): ColorType => {
    if (!status) {
      return "white";
    }
    switch (status) {
      case "None":
      case "Normal":
      case "Abnormal":
        return "white";
      default:
        throw new Error(`color for ${status} not covered`);
    }
  }, []);

  return [colorResolve] as const;
}
