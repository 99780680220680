/* eslint-disable testing-library/no-await-sync-queries */
import {
  DashboardMapsGroupBy,
  IdentifiedWithCkdRiskReportModel,
  WithEgfrReportModel,
} from "api/report_service";
import { IdentifiedWithCkdRiskFilterModel } from "api/report_service/models/IdentifiedWithCkdRiskFilterModel";
import { CarnaApiReportService } from "config/apiReportService";
import {
  defaultModel,
  failed,
  isDefaultModel,
  LoadableModel,
  loaded,
  loading,
} from "models/loadable";
import { PropsWithChildren, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { createSafeContext } from "utils/createSafeContext";
import {
  ChartData,
  FilterWithAdditionalFields,
  LeftSideReportTypes,
  ReportData,
} from "./DashboardMapReportDataContext.model";
import { isKeyInFilterWithAdditionalFields, useSetReportServiceSearchParams } from "./helper";

export interface MapData {
  patientCount: number;
  patientRiskCount: number;
  reportType: LeftSideReportTypes;
  coordinates: [longitude: number, latitude: number];
  name: string;
  entityState?: string | null;
  entityCountryISO?: string | null;
}

interface TotalNumbers {
  totalNumberOfPatients: number;
  totalNumberOfPatientsWithRisk: number;
}

type DashboardMapReportState = {
  ChartData: LoadableModel<ChartData[] | null>;
  MapData: LoadableModel<MapData[] | null>;
  TotalNumbers: LoadableModel<TotalNumbers | null>;
  appliedFilters: IdentifiedWithCkdRiskFilterModel;
  isLoadingState: boolean;
};

const DATA_INIT: DashboardMapReportState = {
  ChartData: defaultModel(),
  MapData: defaultModel(),
  TotalNumbers: defaultModel(),
  appliedFilters: {},
  isLoadingState: false,
};

type ActionType = "Load" | "Set" | "Failed";

interface Action {
  type: ActionType;
  payload: {
    data: LoadableModel<ReportData | null>;
    filters: IdentifiedWithCkdRiskFilterModel;
  };
}

function mapMapData(data: ReportData): MapData[] {
  const { groups, reportType } = data;

  return (groups ?? []).map(el => ({
    patientCount: el.numberOfPatients ?? 0,
    reportType,
    patientRiskCount: el.numberOfPatientsInCkdRisk ?? el.numberOfPatientsWithEgfr ?? 0,
    coordinates: [
      el.groupedBy?.geolocation?.longitude ?? 0,
      el.groupedBy?.geolocation?.latitude ?? 0,
    ],
    name: el.groupedBy?.name ?? "",
    entityState: el.groupedBy?.state,
    entityCountryISO: el.groupedBy?.countryIso,
  })) satisfies MapData[];
}

function mapChartData(data: ReportData): ChartData[] {
  const { groups, reportType } = data;

  return (groups ?? []).map(el => ({
    entityName: el.groupedBy?.name ?? "",
    entityState: el.groupedBy?.state ?? "",
    entityCountryISO: el.groupedBy?.countryIso ?? "",
    reportType,
    categories: {
      Patients: el.numberOfPatients ?? 0,
      PatientWithRisk: el.numberOfPatientsInCkdRisk ?? el.numberOfPatientsWithEgfr ?? 0,
    },
  })) satisfies ChartData[];
}

function mapTotalNumbers(data: ReportData): TotalNumbers {
  const { totalNumberOfPatients = 0, totalNumberOfPatientsWithRisk = 0 } = data;

  return {
    totalNumberOfPatients,
    totalNumberOfPatientsWithRisk,
  };
}

function reducer(state: DashboardMapReportState, action: Action): DashboardMapReportState {
  switch (action.type) {
    case "Load":
      return {
        ...state,
        isLoadingState: true,
        ChartData: loading(),
        MapData: loading(),
        TotalNumbers: loading(),
        appliedFilters: {
          ...state.appliedFilters,
          ...action.payload.filters,
        },
      };

    case "Set":
      return {
        ...state,
        isLoadingState: false,
        ChartData: loaded(mapChartData(loaded(action.payload.data.value).value)),
        MapData: loaded(mapMapData(loaded(action.payload.data.value).value)),
        TotalNumbers: loaded(mapTotalNumbers(loaded(action.payload.data.value).value)),
        appliedFilters: {
          ...state.appliedFilters,
          ...action.payload.filters,
        },
      };

    case "Failed":
      return {
        ...state,
        isLoadingState: false,
        ChartData: failed(null),
        MapData: failed(null),
        TotalNumbers: failed(null),
        appliedFilters: {
          ...state.appliedFilters,
          ...action.payload.filters,
        },
      };

    default:
      throw new Error(`${action.type} case for DashboardMapReportState not implemented`);
  }
}

interface DashboardMapReportStateContextData {
  reportData: DashboardMapReportState;
  getReportModelPerGroupingOption: (groupBy: DashboardMapsGroupBy) => Promise<void>;
  getReportModelPerFilters: (filters: FilterWithAdditionalFields) => Promise<void>;
  groupingOption: DashboardMapsGroupBy;
  onGroupingOptionChange: (val?: DashboardMapsGroupBy) => void;
  onSelectReportTypeChange: (reportType?: LeftSideReportTypes) => void;
  selectedReportType: LeftSideReportTypes;
}

const Context = createSafeContext<DashboardMapReportStateContextData>();

export const useDashboardMapReportContext = Context.hook;

export function DashboardMapReportContextProvider({ children }: Readonly<PropsWithChildren>) {
  const [reportData, dispatch] = useReducer(reducer, DATA_INIT);
  const [selectedReportType, setSelectedReportType] =
    useState<LeftSideReportTypes>("patientWithCDK");

  const [searchParams, setSearchParams] = useSetReportServiceSearchParams();

  const getReportModel = useCallback(
    async (filters: FilterWithAdditionalFields) => {
      async function getReportModel(): Promise<ReportData> {
        const results: IdentifiedWithCkdRiskReportModel & WithEgfrReportModel =
          await (filters.reportType === "patientWithCDK"
            ? CarnaApiReportService.DashboardsMap.getCDK(filters)
            : CarnaApiReportService.DashboardsMap.getEGFR(filters));

        return {
          ...results,
          reportType: filters.reportType,
          totalNumberOfPatientsWithRisk:
            results?.totalNumberOfPatientsInCkdRisk ?? results.totalNumberOfPatientsWithEgfr,
        } satisfies ReportData;
      }

      setSearchParams(filters);
      dispatch({
        type: "Load",
        payload: { data: loading(), filters },
      });

      getReportModel()
        .then(result =>
          dispatch({
            type: "Set",
            payload: {
              data: loaded(result),
              filters,
            },
          }),
        )
        .catch(() =>
          dispatch({
            type: "Failed",
            payload: { data: failed(null), filters },
          }),
        );
    },
    [setSearchParams],
  );

  const getReportModelPerFilters = useCallback(
    async (filters: FilterWithAdditionalFields) => {
      const totalFilters = {
        ...reportData.appliedFilters,
        ...filters,
      };

      getReportModel(totalFilters);
    },
    [getReportModel, reportData.appliedFilters],
  );

  const getReportModelPerGroupingOption = useCallback(
    async (groupBy: DashboardMapsGroupBy) => {
      const totalFilters = {
        ...reportData.appliedFilters,
        reportType: selectedReportType,
        groupBy,
      };

      getReportModel(totalFilters);
    },
    [getReportModel, reportData.appliedFilters, selectedReportType],
  );

  const getTotalFiltersFromUrl = useCallback(
    function getTotalFiltersFromUrl() {
      const allParams = {} as any;
      for (const [key, value] of searchParams.entries()) {
        if (!allParams.hasOwnProperty(key) && isKeyInFilterWithAdditionalFields(key) && value) {
          switch (key) {
            case "groupBy":
            case "reportType":
              allParams[key] = value;
              break;
            default:
              allParams[key] = value.split(",");
              break;
          }
        }
      }

      const totalFilters: FilterWithAdditionalFields = {
        ...allParams,
        reportType: allParams.reportType ?? "patientWithCDK",
        groupBy: allParams.groupBy ? allParams.groupBy : "Organization",
      };
      return totalFilters;
    },
    [searchParams],
  );

  useEffect(() => {
    const isInInitState =
      isDefaultModel(reportData.ChartData) &&
      isDefaultModel(reportData.MapData) &&
      isDefaultModel(reportData.TotalNumbers);

    if (isInInitState) {
      const totalFilters: FilterWithAdditionalFields = getTotalFiltersFromUrl();
      setSelectedReportType(totalFilters.reportType);
      getReportModel(totalFilters);
    }
  }, [
    getReportModel,
    getTotalFiltersFromUrl,
    reportData.ChartData,
    reportData.MapData,
    reportData.TotalNumbers,
  ]);

  const initValue = (searchParams.get("groupBy") ?? "Organization") as DashboardMapsGroupBy;
  const [groupingOption, setGroupingOption] = useState<DashboardMapsGroupBy>(initValue);

  const onGroupingOptionChange = useCallback(
    (val: DashboardMapsGroupBy = "Organization") => {
      setGroupingOption(val);
      getReportModelPerGroupingOption(val);
    },
    [getReportModelPerGroupingOption, setGroupingOption],
  );

  const onSelectReportTypeChange = useCallback(
    (reportType?: LeftSideReportTypes) => {
      if (!reportType) {
        return;
      }
      setSelectedReportType(reportType);
      getReportModel({ ...getTotalFiltersFromUrl(), reportType });
    },
    [getReportModel, getTotalFiltersFromUrl],
  );

  const value = useMemo(
    () => ({
      reportData,
      getReportModelPerGroupingOption,
      getReportModelPerFilters,
      groupingOption,
      onGroupingOptionChange,
      selectedReportType,
      onSelectReportTypeChange,
    }),
    [
      getReportModelPerFilters,
      getReportModelPerGroupingOption,
      groupingOption,
      onGroupingOptionChange,
      onSelectReportTypeChange,
      reportData,
      selectedReportType,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
