import { AppMenuContextProvider } from "context/AppMenuContext";
import { Outlet, useMatch } from "react-router-dom";
import { Landing } from "./Landing";
import "./Layout.scss";
import { SideBar } from "./SideBar";
import { TopBar } from "./TopBar";
import { AppMenuTogglerContextProvider } from "context/AppMenuTogglerContext";

export function Layout() {
  const onRoot = useMatch("/");

  if (onRoot) {
    return <Landing />;
  }

  return (
    <div className="Layout">
      <AppMenuTogglerContextProvider>
        <AppMenuContextProvider>
          <TopBar />
          <SideBar />
          <main className="Main">
            <Outlet />
          </main>
        </AppMenuContextProvider>
      </AppMenuTogglerContextProvider>
    </div>
  );
}
