import { isDefaultModel, isFailed, isLoaded, isLoading } from "models/loadable";
import { forwardRef, PropsWithChildren, useCallback } from "react";
import { useOverviewAPIContext } from "../OverviewAPIContext";
import { RefreshFailedWidget } from "../RefreshFailedWidget";
import { WidgetLoader } from "../WidgetLoader";
import { EGFRResult } from "./EGFRResult";
import { HeatMapHeader } from "./HeatMapHeader";
import { HeatMapTable } from "./HeatMapTable";
import "./HeatMapWidget.scss";
import { Recommendation } from "./Recommendation";
import { SingleMeasurement } from "./SingleMeasurement";
import { UacrResult } from "./UacrResult";
import { DraggableWidget, DraggableWidgetProps } from "components/DnD/DraggableWidget";
import classNames from "classnames";
import { getWidgetSize } from "components/DnD/util";
interface Props extends DraggableWidgetProps {
  organizationId: string;
  patientId: string;
  w?: number;
  h?: number;
}

export const HeatMapWidget = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      Header,
      optionsDropDown,
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      children, //!!! children is needed so that resize handle is passed to the component
      organizationId,
      patientId,
      w,
      h,
      ...rest
    },
    ref,
  ) => {
    const {
      data: { HeatMap },
      getWidgetData,
      getHeatMapHistory,
    } = useOverviewAPIContext();

    const refreshWidgetData = useCallback(() => {
      getWidgetData(organizationId, patientId, ["HeatMap"]);
    }, [getWidgetData, organizationId, patientId]);

    const eGFRData = isLoaded(HeatMap) ? HeatMap.value?.egfrTest : null;
    const eGFRTest = isLoaded(HeatMap)
      ? eGFRData?.measurements.find(el => el.measurementType === "SerumCreatinine")?.item["Egfr"]
      : null;

    const UACRData = isLoaded(HeatMap)
      ? (HeatMap.value?.semiQuantitativeUacrTest ?? HeatMap.value?.uacrTest)
      : null;
    const UACRTest = isLoaded(HeatMap)
      ? HeatMap.value?.semiQuantitativeUacrTest
        ? UACRData?.measurements.find(el => el.measurementType === "SemiQuantitativeUACR")?.item[
            "SemiQuantitativeUacr"
          ]
        : UACRData?.measurements.find(el => el.measurementType === "UACR")?.item["Uacr"]
      : null;
    const UACRType = isLoaded(HeatMap)
      ? HeatMap.value?.semiQuantitativeUacrTest
        ? "SQUACR"
        : "UACR"
      : null;

    const shouldShowSmall = !Number.isNaN(w) && Number(w) <= 5;

    return (
      <DraggableWidget
        ref={ref}
        optionsDropDown={optionsDropDown}
        style={style}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        Header={
          <HeatMapHeader
            heatMapData={HeatMap}
            getHeatMapHistory={getHeatMapHistory}
            eGFRDate={eGFRData?.measurementTime}
            UACRDate={UACRData?.measurementTime}
            organizationId={organizationId}
            patientId={patientId}
            className={getWidgetSize(w, h)}
          />
        }
        {...rest}
      >
        {isLoading(HeatMap) || isDefaultModel(HeatMap) ? <WidgetLoader /> : null}

        {isLoaded(HeatMap) ? (
          <>
            <div className={classNames("HeatMapWidget__body", getWidgetSize(w, h))}>
              <div className="HeatMapWidget__diagnosis">
                <Recommendation risk={HeatMap.value?.risk} clampLine={w ?? 4} />

                <SingleMeasurement
                  measurementUnit="eGFR"
                  value={eGFRTest?.Value}
                  date={eGFRData?.measurementTime}
                  trendType={eGFRTest?.TrendType}
                  percent={eGFRTest?.Percent}
                />
              </div>

              <UacrResult
                value={UACRTest?.Value}
                date={UACRData?.measurementTime}
                trendType={UACRTest?.TrendType}
                percent={UACRTest?.Percent}
                status={UACRTest?.Status}
                uacrType={UACRType ?? "UACR"}
                small={shouldShowSmall}
              />

              <EGFRResult value={eGFRTest?.Value} small={shouldShowSmall} />

              <HeatMapTable
                eGFRStatus={eGFRTest?.Status}
                UACRStatus={UACRTest?.Status}
                risk={HeatMap.value?.risk}
                small={shouldShowSmall}
              />
            </div>
          </>
        ) : (
          <RefreshFailedWidget show={isFailed(HeatMap)} refreshCb={refreshWidgetData} />
        )}
        {children}
      </DraggableWidget>
    );
  },
);
