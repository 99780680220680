import { MeasurementUIModel, MeasurementUIType } from "models/TestModels";
import { WidgetType } from "./Overview/OverviewAPIContext";

//#region Measurement widgets
export function getWidgetTypePerMeasurementType(measurementType: MeasurementUIType): WidgetType[] {
  switch (measurementType) {
    case "None":
      return [];
    case "SerumCreatinine":
      return ["SerumCreatinine", "Egfr"];
    case "BloodPressure":
      return ["BloodPressure"];
    case "Glucose":
      return ["Glucose"];
    case "UACR":
      return ["Uacr", "UrineAlbumin", "UrineCreatinine"];
    case "BMI":
      return ["Bmi", "Height", "Weight"];
    case "SemiQuantitativeUACR":
      return ["SemiQuantitativeUacr"];

    default:
      throw new Error(`measurementType ${measurementType} not covered`);
  }
}

export function getListOfMeasurementWidgetsForRefresh(measurements: MeasurementUIModel[]) {
  const result = measurements.flatMap(el => getWidgetTypePerMeasurementType(el.measurementType));
  return result;
}

//#endregion
