import classNames from "classnames";
import { PropsWithChildren } from "react";
import "./ColorValue.scss";
import { ColorType } from "models/colors";

interface ColorValueProps {
  colorType: ColorType;
  sideSlot?: React.ReactNode;
}

export function ColorValue({ colorType, sideSlot, children }: PropsWithChildren<ColorValueProps>) {
  return (
    <div>
      <span
        className={classNames("ColorValue", {
          [`ColorValue--${colorType}`]: colorType,
        })}
      >
        <span>{children}</span>
      </span>
      {sideSlot}
    </div>
  );
}
