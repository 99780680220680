import classNames from "classnames";
import { SideDetailsHead } from "./SideDetailsHead";
import "./SideDetails.scss";
import { CSSProperties, PropsWithChildren } from "react";
import { DataAttributeKey } from "libs/ui/models/common";

interface SideDetailsProps {
  show: boolean;
  onClose: () => void;
  title: string;
  className?: string;
  style?: CSSProperties | undefined;
  [dataAttribute: DataAttributeKey]: any;
}

export function SideDetails({
  show,
  title,
  onClose,
  className,
  children,
  ...rest
}: PropsWithChildren<SideDetailsProps>) {
  if (!show) {
    return null;
  }

  return (
    <div className={classNames("SideDetails", className)} {...rest}>
      <SideDetailsHead onClose={onClose}>{title}</SideDetailsHead>

      <div className="SideDetails__body">{children}</div>
    </div>
  );
}
