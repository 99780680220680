/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';

/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    organizationId: string;
    /**
     * 
     * @type {UserRoleType}
     * @memberof UserModel
     */
    roleType: UserRoleType;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    registryNumber?: string | null;
}

/**
 * Check if a given object implements the UserModel interface.
 */
export function instanceOfUserModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "roleType" in value;

    return isInstance;
}

export function UserModelFromJSON(json: any): UserModel {
    return UserModelFromJSONTyped(json, false);
}

export function UserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'id': json['Id'],
        'organizationId': json['OrganizationId'],
        'roleType': UserRoleTypeFromJSON(json['RoleType']),
        'registryNumber': !exists(json, 'RegistryNumber') ? undefined : json['RegistryNumber'],
    };
}

export function UserModelToJSON(value?: UserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Id': value.id,
        'OrganizationId': value.organizationId,
        'RoleType': UserRoleTypeToJSON(value.roleType),
        'RegistryNumber': value.registryNumber,
    };
}

