import { EgfrLimitKey, useGetEGFRRanges } from "utils/hooks/useGetEGFRRanges";
import { HeatResultRow } from "./HeatResultRow";
import "./HeatMapResultsTable.scss";
import { HeatMapResultsTableBase } from "./model";

export function HeatMapResultsTable({ small }: HeatMapResultsTableBase) {
  const { orderedEGFRStageRanges } = useGetEGFRRanges();

  const rowKeys = Object.keys(orderedEGFRStageRanges) as EgfrLimitKey[];

  return (
    <table className="HeatMapResultsTable">
      <tbody className="HeatMapResultsTable__body">
        {rowKeys.map(key => (
          <HeatResultRow key={key} rowKey={key} small={small} />
        ))}
      </tbody>
    </table>
  );
}
