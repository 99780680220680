import "./Support.scss";
import { Button, Input, TextArea } from "libs/ui";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useState } from "react";
import { isLoading } from "models/loadable";
import { CarnaApiQuery } from "config/apiQuery";
import { Group } from "components/Forms/FormElements/Group";
import { SettingsPage } from "components/PageTemplate/SettingsPage";
import { useApi } from "utils/hooks/useApi";

interface SupportForm {
  subject: string;
  message: string;
}

const SUPPORT_INIT_FIELDS = {
  subject: "",
  message: "",
};

export function Support() {
  const { t } = useTranslation("translation", { keyPrefix: "PageTemplate.Settings.support" });

  const [support, setSupport] = useState<SupportForm>(SUPPORT_INIT_FIELDS);

  const clearForm = useCallback(() => {
    setSupport(SUPPORT_INIT_FIELDS);
  }, []);

  const [sendSupportResult, sendSupportMessage] = useApi(CarnaApiQuery.Support.post, {
    toastText: {
      successText: t("successMessageSentToast"),
      errorText: t("unsuccessfulMessageSentToast"),
    },
    onResponseCallback: {
      onSuccess: clearForm,
    },
  });

  const submitDisabled =
    !support.subject.length || !support.message.length || isLoading(sendSupportResult);

  const onMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value: message } = e.target;
    setSupport(prevValue => ({ ...prevValue, message }));
  };

  const onSubjectChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: subject } = e.target;
    setSupport(prevValue => ({ ...prevValue, subject }));
  };

  const onSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();

    if (submitDisabled) {
      return;
    }

    sendSupportMessage({ supportFormModel: support });
  };

  return (
    <SettingsPage>
      <div className={"Support"}>
        <h3 className={"Support__title"}>{t("formTitle")}</h3>
        <p className={"Support__description"}>{t("formDescription")}</p>
        <form className="Form" onSubmit={onSubmit}>
          <Group>
            <Input
              label={t("subject")}
              onChange={onSubjectChange}
              value={support.subject}
              className="Support__subject"
            />
            <TextArea
              label={t("message")}
              className="Support__message"
              textareaClassName="Support__message__textarea"
              countLimit={1000}
              onChange={onMessageChange}
              value={support.message}
            />
            <Button
              className="Support__submit"
              onClick={onSubmit}
              disabled={submitDisabled}
              loading={isLoading(sendSupportResult)}
              buttonType="primary"
              buttonSize="medium"
              type="submit"
            >
              {t("submit")}
            </Button>
          </Group>
        </form>
      </div>
    </SettingsPage>
  );
}
