/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';

/**
 * 
 * @export
 * @interface DisabledModel
 */
export interface DisabledModel {
    /**
     * 
     * @type {boolean}
     * @memberof DisabledModel
     */
    formElementsCityZipFieldZipCode?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DisabledModel
     */
    measurementSideDetailsSideDetailsElementsTrendDetailsField?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DisabledModel
     */
    pagesSettingsRuleEngine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DisabledModel
     */
    patientFormElementsDeviceField?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DisabledModel
     */
    patientGraphTabs?: boolean;
    /**
     * 
     * @type {Array<UserRoleType>}
     * @memberof DisabledModel
     */
    deactivatePersons: Array<UserRoleType>;
}

/**
 * Check if a given object implements the DisabledModel interface.
 */
export function instanceOfDisabledModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deactivatePersons" in value;

    return isInstance;
}

export function DisabledModelFromJSON(json: any): DisabledModel {
    return DisabledModelFromJSONTyped(json, false);
}

export function DisabledModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisabledModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formElementsCityZipFieldZipCode': !exists(json, 'FormElements/CityZipField.zipCode') ? undefined : json['FormElements/CityZipField.zipCode'],
        'measurementSideDetailsSideDetailsElementsTrendDetailsField': !exists(json, 'MeasurementSideDetails/SideDetails/elements/TrendDetailsField') ? undefined : json['MeasurementSideDetails/SideDetails/elements/TrendDetailsField'],
        'pagesSettingsRuleEngine': !exists(json, 'pages/Settings/RuleEngine') ? undefined : json['pages/Settings/RuleEngine'],
        'patientFormElementsDeviceField': !exists(json, 'Patient/FormElements/DeviceField') ? undefined : json['Patient/FormElements/DeviceField'],
        'patientGraphTabs': !exists(json, 'patientGraphTabs') ? undefined : json['patientGraphTabs'],
        'deactivatePersons': ((json['deactivatePersons'] as Array<any>).map(UserRoleTypeFromJSON)),
    };
}

export function DisabledModelToJSON(value?: DisabledModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FormElements/CityZipField.zipCode': value.formElementsCityZipFieldZipCode,
        'MeasurementSideDetails/SideDetails/elements/TrendDetailsField': value.measurementSideDetailsSideDetailsElementsTrendDetailsField,
        'pages/Settings/RuleEngine': value.pagesSettingsRuleEngine,
        'Patient/FormElements/DeviceField': value.patientFormElementsDeviceField,
        'patientGraphTabs': value.patientGraphTabs,
        'deactivatePersons': ((value.deactivatePersons as Array<any>).map(UserRoleTypeToJSON)),
    };
}

