import * as React from "react";
import type { SVGProps } from "react";
const SvgHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="url(#heart_svg__a)"
      d="M12 19.635c-.363 0-.726-.081-1.051-.234-.982-.462-2.9-1.453-4.708-2.897-2.353-1.879-3.747-3.896-4.145-5.994a5.46 5.46 0 0 1 1.21-4.566c.998-1.178 2.412-1.866 3.982-1.938Q7.428 4 7.566 4c1.471 0 2.86.533 3.91 1.501q.285.263.524.555.24-.292.524-.555c1.05-.968 2.439-1.5 3.91-1.5q.138 0 .278.005c1.57.072 2.984.76 3.982 1.938a5.46 5.46 0 0 1 1.21 4.566c-.398 2.098-1.793 4.115-4.145 5.994-1.809 1.444-3.726 2.435-4.708 2.897a2.5 2.5 0 0 1-1.051.234"
    />
    <path
      fill="url(#heart_svg__b)"
      d="M11.476 5.501C10.426 4.533 9.037 4 7.566 4a6 6 0 0 0-.278.006c-1.57.072-2.984.76-3.982 1.938a5.46 5.46 0 0 0-1.21 4.566q.332 1.738 1.563 3.39L12 6.055a5.4 5.4 0 0 0-.524-.555"
    />
    <defs>
      <linearGradient
        id="heart_svg__a"
        x1={10.4}
        x2={13.852}
        y1={6.46}
        y2={13.175}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCA5A5" />
        <stop offset={1} stopColor="#EF4444" />
      </linearGradient>
      <linearGradient
        id="heart_svg__b"
        x1={6.676}
        x2={12.07}
        y1={7.709}
        y2={4.206}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF4444" stopOpacity={0} />
        <stop offset={0.346} stopColor="#EF4444" stopOpacity={0.3} />
        <stop offset={0.804} stopColor="#EF4444" stopOpacity={0.8} />
        <stop offset={1} stopColor="#EF4444" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgHeart;
