/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MeasurementTrendType } from './MeasurementTrendType';
import {
    MeasurementTrendTypeFromJSON,
    MeasurementTrendTypeFromJSONTyped,
    MeasurementTrendTypeToJSON,
} from './MeasurementTrendType';
import type { MeasurementUnitType } from './MeasurementUnitType';
import {
    MeasurementUnitTypeFromJSON,
    MeasurementUnitTypeFromJSONTyped,
    MeasurementUnitTypeToJSON,
} from './MeasurementUnitType';
import type { SerumCreatinineStatusType } from './SerumCreatinineStatusType';
import {
    SerumCreatinineStatusTypeFromJSON,
    SerumCreatinineStatusTypeFromJSONTyped,
    SerumCreatinineStatusTypeToJSON,
} from './SerumCreatinineStatusType';

/**
 * 
 * @export
 * @interface SerumCreatinineModel
 */
export interface SerumCreatinineModel {
    /**
     * 
     * @type {number}
     * @memberof SerumCreatinineModel
     */
    value: number;
    /**
     * 
     * @type {MeasurementUnitType}
     * @memberof SerumCreatinineModel
     */
    unit: MeasurementUnitType;
    /**
     * 
     * @type {MeasurementTrendType}
     * @memberof SerumCreatinineModel
     */
    trendType: MeasurementTrendType;
    /**
     * 
     * @type {number}
     * @memberof SerumCreatinineModel
     */
    percent: number;
    /**
     * 
     * @type {number}
     * @memberof SerumCreatinineModel
     */
    graphValue: number;
    /**
     * 
     * @type {SerumCreatinineStatusType}
     * @memberof SerumCreatinineModel
     */
    status: SerumCreatinineStatusType;
}

/**
 * Check if a given object implements the SerumCreatinineModel interface.
 */
export function instanceOfSerumCreatinineModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "unit" in value;
    isInstance = isInstance && "trendType" in value;
    isInstance = isInstance && "percent" in value;
    isInstance = isInstance && "graphValue" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function SerumCreatinineModelFromJSON(json: any): SerumCreatinineModel {
    return SerumCreatinineModelFromJSONTyped(json, false);
}

export function SerumCreatinineModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerumCreatinineModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['Value'],
        'unit': MeasurementUnitTypeFromJSON(json['Unit']),
        'trendType': MeasurementTrendTypeFromJSON(json['TrendType']),
        'percent': json['Percent'],
        'graphValue': json['GraphValue'],
        'status': SerumCreatinineStatusTypeFromJSON(json['Status']),
    };
}

export function SerumCreatinineModelToJSON(value?: SerumCreatinineModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Value': value.value,
        'Unit': MeasurementUnitTypeToJSON(value.unit),
        'TrendType': MeasurementTrendTypeToJSON(value.trendType),
        'Percent': value.percent,
        'GraphValue': value.graphValue,
        'Status': SerumCreatinineStatusTypeToJSON(value.status),
    };
}

