import { useCallback, useState } from "react";
import { AccountAction } from "./AccountAction";
import { useTranslation } from "react-i18next";
import { AccountDataDescription } from "./Description";
import { DeleteAccountModal } from "../../../../../components/AppModals/DeleteAccountModal";
import { RequestPersonalDataModal } from "./RequestPersonalDataModal";
import { CarnaApiQuery } from "config/apiQuery";
import { isDefaultModel, isFailed, isLoaded, isLoading } from "models/loadable";
import "./AccountData.scss";
import { UpdatePersonStatusModal } from "components/AppModals/UpdatePersonStatusModal";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useApi } from "utils/hooks/useApi";
import { useClearAndReloadIfSameUser } from "utils/hooks/ChangeEntityStatus/useClearAndReloadIfSameUser";

export function AccountData() {
  const { organizationId, currentUserId, userRole } = useAdditionalAuthInformationContext();
  const { t } = useTranslation("translation", { keyPrefix: "SecurityAndPrivacy.AccountData" });
  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);

  const [removeLastUsedAuthStorageAndReload] = useClearAndReloadIfSameUser();

  const toggleDeactivateUserModal = useCallback((e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    setShowDeactivateUserModal(prevValue => !prevValue);
  }, []);

  const toggleDeleteAccountModal = useCallback((e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    setShowDeleteAccountModal(prevValue => !prevValue);
  }, []);

  const [showRequestPersonalDataModal, setShowRequestPersonalDataModal] = useState(false);

  const toggleRequestPersonalDataModal = useCallback((e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    setShowRequestPersonalDataModal(prevValue => !prevValue);
  }, []);

  const [personalData, retrievePersonalData] = useApi(CarnaApiQuery.AccountData.post, {
    toastText: {
      errorText: t("RequestPersonalDataModal.errorGetAccountDataToastText"),
      successText: t("RequestPersonalDataModal.successGetAccountDataToastText"),
    },
  });

  const requestPersonalDataConfirm = useCallback(() => {
    retrievePersonalData({ organizationId, userEntityId: currentUserId, body: {} }).then(() => {
      setShowRequestPersonalDataModal(false);
    });
  }, [currentUserId, organizationId, retrievePersonalData]);

  const onUpdatePersonSuccess = useCallback(() => {
    toggleDeactivateUserModal();
    removeLastUsedAuthStorageAndReload(currentUserId);
  }, [currentUserId, removeLastUsedAuthStorageAndReload, toggleDeactivateUserModal]);

  return (
    <div className="AccountData">
      <AccountAction icon={"Slash"} type={"deactivate"} onClick={toggleDeactivateUserModal}>
        <AccountDataDescription>{t("deactivate.description")}</AccountDataDescription>
        <UpdatePersonStatusModal
          id={currentUserId}
          organizationId={organizationId}
          role={userRole}
          status="Active"
          show={showDeactivateUserModal}
          onCloseModal={toggleDeactivateUserModal}
          onSuccess={onUpdatePersonSuccess}
        />
      </AccountAction>

      <AccountAction
        icon={"Results"}
        type={"request"}
        onClick={toggleRequestPersonalDataModal}
        disabled={isLoading(personalData) || isLoaded(personalData)}
      >
        <AccountDataDescription>
          {t(
            isDefaultModel(personalData) || isFailed(personalData)
              ? "request.description"
              : "request.sentDescription",
          )}
        </AccountDataDescription>

        <RequestPersonalDataModal
          isLoading={isLoading(personalData)}
          show={showRequestPersonalDataModal}
          onCancel={toggleRequestPersonalDataModal}
          onConfirm={requestPersonalDataConfirm}
        />
      </AccountAction>

      <AccountAction icon={"Delete"} type={"delete"} onClick={toggleDeleteAccountModal}>
        <AccountDataDescription>{t("delete.description")}</AccountDataDescription>

        <DeleteAccountModal show={showDeleteAccountModal} onClose={toggleDeleteAccountModal} />
      </AccountAction>
    </div>
  );
}
