import { useTranslation } from "react-i18next";
import "./NoData.scss";

export function NoData() {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.SingleEntity" });

  return (
    <div className="NoData">
      <p className="NoData__title">{t("noData")}</p>
    </div>
  );
}
