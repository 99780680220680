import { Layout, Layouts } from "react-grid-layout";

export const MEASUREMENT_WIDGET_DEFAULT_W_SIZE = 2;

export const MEASUREMENT_WIDGET_BASE_LAYOUT: Layout = {
  i: "MeasurementWidget",
  minW: 2,
  maxW: 3,
  maxH: 1,
  w: MEASUREMENT_WIDGET_DEFAULT_W_SIZE,
  h: 1,
  x: 0,
  y: 0,
};

export const HEAT_MAP_RESULTS_WIDGET_BASE_LAYOUT: Layout = {
  i: "HeatMap",
  minW: 3,
  maxW: 9,
  minH: 4,
  maxH: 4,
  w: 7,
  h: 4,
  x: 4,
  y: 0,
};

export const INIT_LAYOUTS: Layouts = {
  "col-12": [],
  "col-11": [],
  "col-10": [],
  "col-9": [],
  "col-8": [],
  "col-7": [],
  "col-6": [],
  "col-5": [],
  "col-4": [],
  "col-3": [],
};
