import { SideModalElements } from "libs/ui";
import { useCreatinineValueConvertor } from "utils/hooks/useCreatinineValueConvertor";
import { SectionDetailsProps } from "../model";
import { useTranslation } from "react-i18next";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { useCreatinineColor } from "utils/hooks/useCreatinineColor";
import { ColorValue } from "../../elements/ColorValue";
import { DetailsField } from "../../elements/DetailsField";
import { TrendDetailsField } from "../../elements/TrendDetailsField";
import { Range } from "../../elements/Range";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";

interface SerumCreatinineSectionDetailsProps extends SectionDetailsProps {}

export function SerumCreatinineSectionDetails({
  data,
  loading,
  showTrend,
}: SerumCreatinineSectionDetailsProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "Graph.Details.SerumCreatinineSectionDetails",
  });
  const [getCreatineColor] = useCreatinineColor();
  const getCreatinineValue = useCreatinineValueConvertor();
  const { numberFormatter } = useLocaleNumberFormatter();
  const { serumCreatinineUnit } = useGetUserPreferredMeasurementUnits();

  return (
    <div className="SerumCreatinineSectionDetails">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={t("labelResult")}>
        <ColorValue
          colorType={getCreatineColor(data?.measurement?.SerumCreatinine?.SerumCreatinine.Status)}
        >
          {numberFormatter({
            value: Number(
              getCreatinineValue(
                data?.measurement?.SerumCreatinine?.SerumCreatinine.Value ?? 0,
                data?.measurement?.SerumCreatinine?.SerumCreatinine.Unit ?? MEASUREMENT_UNITS.mgdL,
              ),
            ),
          }).concat(` ${serumCreatinineUnit}`)}
        </ColorValue>
      </DetailsField>

      <DetailsField loading={loading} label={t("labelRange")}>
        <Range gender={data?.test.patient.gender ?? "Female"} />
      </DetailsField>

      <TrendDetailsField
        show={showTrend}
        loading={loading}
        trendType={data?.measurement?.SerumCreatinine?.SerumCreatinine.TrendType}
        percent={data?.measurement?.SerumCreatinine?.SerumCreatinine.Percent}
      />
    </div>
  );
}
