import { AvatarType, CardProps } from "libs/ui";
import { ReactNode } from "react";

export interface OrganizationData {
  id?: string | null;
  name?: string | null;
  isMain?: boolean;
}

export interface AvatarData {
  src?: string;
  loading: boolean;
  avatarType?: AvatarType;
}

export interface TableCardProps extends CardProps {
  inactive?: boolean;
  avatarData?: AvatarData | ReactNode;
  name?: string | null;
  description?: ReactNode;
  organization?: OrganizationData;
  actionChildren?: ReactNode;
  userDataChildren?: ReactNode;
  deviceDataChildren?: ReactNode;
  statusChildren?: ReactNode;
  countryChildren?: ReactNode;
  loading?: boolean;
}

export function isAvatarData(value: any): value is AvatarData {
  return Object.hasOwn(value, "src") && Object.hasOwn(value, "loading");
}
