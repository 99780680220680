import { useMachine } from "@xstate/react";
import { PatientResponseModelListModel, PatientsFilterModel } from "api/query";
import { useFilterModal } from "components/AppTables/common/hooks";
import {
  PATIENTS_FILTER_OPTIONS,
  PatientsFilterOptionsKey,
} from "components/AppTables/common/PatientsTable/model";
import { getFilterOptionsPerConfiguration } from "components/AppTables/utils";
import { CarnaApiQuery } from "config/apiQuery";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { SearchQuery, TableContext, tableStateMachine } from "libs/ui";
import { mapTableQueryParamsFromURL, useTableURLParams } from "libs/ui/Table/utils";
import { useOrganizationDetailsServiceContext } from "pages/Settings/Organizations/Details/Context";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { PatientsPerEntity } from "..";

async function fetchPatients(
  organizationId: string,
  context?: TableContext<PatientResponseModelListModel, PatientsFilterModel>,
) {
  const result = CarnaApiQuery.Patients.get({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

const makeTableFetchStateMachine = (
  organizationId: string,
  queryParams: SearchQuery<ReturnType<typeof PATIENTS_FILTER_OPTIONS>>,
) =>
  tableStateMachine(
    (context?: TableContext<PatientResponseModelListModel, PatientsFilterModel>) =>
      fetchPatients(organizationId, context),
    queryParams as any,
  );

export function PatientsPerOrganizationTable() {
  const { organizationId = "" } = useParams<RouteParams["organizationDetails"]>();

  const [queryParams] = useTableURLParams<ReturnType<typeof PATIENTS_FILTER_OPTIONS>>();
  const tableMachine = useMemo(
    () =>
      makeTableFetchStateMachine(
        organizationId,
        mapTableQueryParamsFromURL<ReturnType<typeof PATIENTS_FILTER_OPTIONS>>(queryParams),
      ),
    [queryParams, organizationId],
  );

  const tableService = useMachine(tableMachine);

  const { detailsService } = useOrganizationDetailsServiceContext();
  const [detailsState] = detailsService;

  const { appConfig } = useGlobalConfigContext();

  const filterSideModelState = useFilterModal<Partial<PatientsFilterOptionsKey>>(
    getFilterOptionsPerConfiguration(
      PATIENTS_FILTER_OPTIONS(),
      NotNullOrUndefined(
        appConfig?.components.tables.Patient.PatientPerOrganizationTable.filterOptions,
      ),
    ),
  );

  return detailsState.matches("entity.loaded") ? (
    <PatientsPerEntity
      tableService={tableService}
      filterSideModelState={filterSideModelState}
      appliedColumns={NotNullOrUndefined(
        appConfig?.components.tables.Patient.PatientPerOrganizationTable.columnList,
      )}
      patientsFilterOptions={
        appConfig?.components.tables.Patient.PatientPerOrganizationTable.filterOptions
      }
      initialValuesAsProps={{
        organizationId: detailsState.context.data?.id,
        organizationName: detailsState.context.data?.name,
      }}
    />
  ) : null;
}
