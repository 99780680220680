import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { InputNumber } from "libs/ui/InputNumber";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./NormalRange.scss";
import { DECIMAL_NUMBER_UP_TO_2_DECIMALS } from "utils/regex";

type RangeType = "from" | "to";

export interface ChangeRangeData {
  value: number | null;
  range: RangeType;
}

interface NormalRangeProps {
  onChange: (data: ChangeRangeData) => void;
  from: number | null;
  to: number | null;
  isLoading?: boolean;
}

export function getFromValueError(from: number | null, to: number | null) {
  if (to === null || to === 0) {
    return undefined;
  }
  if (from === null) {
    return "cantBeEmpty";
  }

  if (!DECIMAL_NUMBER_UP_TO_2_DECIMALS.test(from.toString())) {
    return "maxTwoDecimals";
  }

  if (from > to) {
    return "fromMustBeLowerThenTo";
  }

  return from >= 0 && from < to ? undefined : "enterValidFrom";
}

export function getToValueError(to: number | null) {
  if (to === null) {
    return "cantBeEmpty";
  }

  if (!DECIMAL_NUMBER_UP_TO_2_DECIMALS.test(to.toString())) {
    return "maxTwoDecimals";
  }

  return to > 0 ? undefined : "toMustBeHigherThenZero";
}

export function NormalRange({ onChange, from, to, isLoading }: NormalRangeProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.rule-engine.laboratory.normalRange",
  });
  const { globalPreference } = useGlobalPreferenceContext();

  const onRangeChange = useCallback(
    (data: ChangeRangeData) => {
      onChange(data);
    },
    [onChange],
  );

  const printError = (value: string | undefined) => {
    if (!value) {
      return undefined;
    }

    return t(value);
  };

  return (
    <div className="NormalRange">
      <InputNumber
        onNumberChange={value => onRangeChange({ value, range: "from" })}
        label={t("from")}
        value={from}
        region={globalPreference?.regionType}
        formattingOptions={{
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
          roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
        }}
        validation={{
          errorText: printError(getFromValueError(from, to)),
        }}
        loading={isLoading}
      />
      <InputNumber
        onNumberChange={value => onRangeChange({ value, range: "to" })}
        label={t("to")}
        value={to}
        region={globalPreference?.regionType}
        formattingOptions={{
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
          roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
        }}
        validation={{
          errorText: printError(getToValueError(to)),
        }}
        loading={isLoading}
      />
    </div>
  );
}
