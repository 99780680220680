import classNames from "classnames";
import { uniqueId } from "lodash-es";
import "./SkeletonLoader.scss";

interface SkeletonLoaderProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  showRange?: boolean;
}

function SkeletonLoaderBar() {
  return (
    <div className="SkeletonLoaderBar">
      {Array.from({ length: 4 }).map(() => (
        <div key={uniqueId()} className="SkeletonLoaderBar__block" />
      ))}
      <div className="SkeletonLoaderBar__time">
        <div className="SkeletonLoaderBar__loader SkeletonLoaderBar__loader--small" />
        <div className="SkeletonLoaderBar__loader SkeletonLoaderBar__loader--big" />
      </div>
    </div>
  );
}

function SkeletonLoaderYAxis() {
  return (
    <div className="SkeletonLoaderYAxis">
      {Array.from({ length: 4 }).map(() => (
        <div key={uniqueId()} className="SkeletonLoaderYAxis__block">
          <span className="SkeletonLoaderYAxis__loader" />
        </div>
      ))}
      <div className="SkeletonLoaderYAxis__time">
        <div className="SkeletonLoaderYAxis__loader" />
        <div className="SkeletonLoaderYAxis__loader SkeletonLoaderYAxis__loader--hidden" />
      </div>
    </div>
  );
}

export function SkeletonLoader({ showRange = true, ...rest }: SkeletonLoaderProps) {
  return (
    <div
      {...rest}
      className={classNames("SkeletonLoader", { "SkeletonLoader--withRange": showRange })}
    >
      <div className="SkeletonLoader__body">
        <SkeletonLoaderYAxis />

        <div className="SkeletonLoader__grid">
          {Array.from({ length: 7 }).map(() => (
            <SkeletonLoaderBar key={uniqueId()} />
          ))}
        </div>
      </div>

      {showRange ? (
        <div className="SkeletonLoader__range">
          <div />
          <div className="SkeletonLoader__range-bar" />
        </div>
      ) : null}
    </div>
  );
}
