import { useMap } from "@vis.gl/react-google-maps";
import { Icon } from "libs/ui/Icon";
import { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import "./CustomMapControls.scss";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

export interface CustomMapControlsProps {
  setCurrentLocation?: (lat: number, lng: number) => void;
  setZoom?: (zoom: number) => void;
  deckGl?: boolean;
  deckGlViewState?: any;
}

export type CustomMapControlsRef = { map: google.maps.Map | null };

export const CustomMapControls = forwardRef<CustomMapControlsRef, CustomMapControlsProps>(
  function CustomMapControls(
    { setCurrentLocation, setZoom, deckGl = false, deckGlViewState },
    ref,
  ) {
    const { t: tGeo } = useTranslation("translation", { keyPrefix: "Browser.Geolocation" });

    const map = useMap();

    const zoomIn = () => {
      const currentZoom = NotNullOrUndefined(
        deckGlViewState ? (deckGlViewState.zoom as number) : map?.getZoom(),
      );

      if (setZoom) {
        setZoom(currentZoom + 1);
      }

      if (!deckGl && map) {
        map.setZoom(currentZoom + 1);
      }
    };

    const zoomOut = () => {
      const currentZoom = NotNullOrUndefined(
        deckGlViewState ? (deckGlViewState.zoom as number) : map?.getZoom(),
      );

      if (setZoom) {
        setZoom(currentZoom - 1);
      }

      if (!deckGl && map) {
        map.setZoom(currentZoom - 1);
      }
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          map,
        };
      },
      [map],
    );

    const getCurrentLocation = () => {
      if (!navigator.geolocation) {
        alert(tGeo("not_supported"));
        return;
      }
      const getLocation = () => {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            if (!deckGl) {
              map?.setCenter({ lat: latitude, lng: longitude });
              map?.setZoom(14); // Optionally adjust the zoom level
            }

            if (setCurrentLocation) {
              setCurrentLocation(latitude, longitude);
            }
          },
          error => {
            // Handle error case based on error code
            switch (error.code) {
              case error.PERMISSION_DENIED:
                alert(tGeo("permission_denied"));
                break;
              case error.POSITION_UNAVAILABLE:
                alert(tGeo("position_unavailable"));
                break;
              case error.TIMEOUT:
                alert(tGeo("timeout"));
                break;
              default:
                alert(tGeo("unknown"));
                break;
            }
          },
        );
      };

      navigator.permissions.query({ name: "geolocation" }).then(result => {
        switch (result.state) {
          case "granted":
          case "prompt":
            getLocation();
            break;
          case "denied":
            alert(tGeo("permission_denied"));
            break;
        }
      });
    };

    return (
      <div className="CustomMapControls">
        <Icon icon={"Plus"} className="CustomMapControls-zoom-in" onClick={zoomIn} />
        <Icon icon={"Minus"} className="CustomMapControls-zoom-out" onClick={zoomOut} />
        <Icon
          icon={"Gps"}
          className="CustomMapControls-zoom-current-location"
          onClick={getCurrentLocation}
        />
      </div>
    );
  },
);
