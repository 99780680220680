import {
  FetchMFAPreferenceOutput,
  fetchAuthSession,
  fetchMFAPreference,
  setUpTOTP,
} from "@aws-amplify/auth";
import { toastStore } from "config/toast";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { backOff } from "exponential-backoff";
import { Loader } from "libs/ui";
import { logger } from "logger";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActiveQR } from "./ActiveQR";
import { InactiveQR } from "./InactiveQR";
import "./TwoFATab.scss";

export type MFAOption = "TOTP" | "SMS";

export interface TwoFAData {
  secretCode: string | undefined;
  qrCode: string | undefined;
  preferredMFA: MFAOption | undefined;
  isLoading: boolean;
  isLoadedWithError: boolean;
}

const backOffConfig: Parameters<typeof backOff>["1"] = {
  jitter: "full",
  delayFirstAttempt: true,
  startingDelay: 1000,
  numOfAttempts: 10,
  retry(e, attemptNumber) {
    return e?.code === "TooManyRequestsException";
  },
} as const;
export function TwoFATab() {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.login.Tabs.TwoFA",
  });

  const { email, phone_number } = useAdditionalAuthInformationContext();
  const { t: tAws } = useTranslation("translation", { keyPrefix: "AWS-Cognito" });

  const [twoFAData, setTwoFAData] = useState<TwoFAData>({
    secretCode: undefined,
    qrCode: undefined,
    preferredMFA: undefined,
    isLoading: true,
    isLoadedWithError: false,
  });

  const [cognitoMfaConfig, setCognitoMfaConfig] = useState<FetchMFAPreferenceOutput>();

  const onOpenQRCode = useCallback(
    async (currentUserParam?: any) => {
      try {
        await backOff(() => fetchAuthSession(), backOffConfig);

        const code = await backOff(() => setUpTOTP(), backOffConfig);

        const qrCode =
          "otpauth://totp/" +
          email +
          "?secret=" +
          code.sharedSecret +
          `&issuer=CARNA: ${email ?? phone_number ?? ""}`;

        setTwoFAData(prevValue => ({
          ...prevValue,
          qrCode,
          isLoading: false,
          secretCode: code.sharedSecret,
        }));
      } catch (error: any) {
        setTwoFAData(prevValue => ({ ...prevValue, isLoadedWithError: true, isLoading: false }));

        if (error?.code !== "ConcurrentModificationException") {
          toastStore.pushToast({ type: "error", msg: tAws(error.code) });
          return;
        }

        if (error.message) {
          toastStore.pushToast({ type: "error", msg: error.message });
          return;
        }
        toastStore.pushToast({ type: "error", msg: JSON.stringify(error) });
        logger.error(error);
      }
    },
    [email, phone_number, tAws],
  );

  const onActivateQR = useCallback(async () => {
    setTwoFAData(prevValue => ({ ...prevValue, isLoading: true }));
    await onOpenQRCode();
  }, [onOpenQRCode]);

  useEffect(() => {
    (async () => {
      try {
        await backOff(() => fetchAuthSession(), backOffConfig);

        const data = await backOff(() => fetchMFAPreference(), backOffConfig);
        setCognitoMfaConfig(data);
        setTwoFAData(prevValue => ({ ...prevValue, isLoading: false }));
      } catch (error: any) {
        setTwoFAData(prevValue => ({ ...prevValue, isLoadedWithError: true, isLoading: false }));

        if (error.message) {
          toastStore.pushToast({ type: "error", msg: error.message });
          return;
        }
        toastStore.pushToast({ type: "error", msg: JSON.stringify(error) });
      }
    })();
  }, [onOpenQRCode]);

  return (
    <div className="TwoFATab">
      <h3 className="TwoFATab__title">{t("sms_title")}</h3>
      <p className="TwoFATab__text">{t("sms_description")}</p>

      <h3 className="TwoFATab__title">{t("authentication_title")}</h3>
      <Loader loading={twoFAData.isLoading}>
        {cognitoMfaConfig?.enabled?.includes("TOTP") || twoFAData.qrCode ? (
          <ActiveQR
            cognitoMfaConfig={cognitoMfaConfig}
            secretCode={twoFAData.secretCode}
            qrCode={twoFAData.qrCode}
            preferredMFA={twoFAData.preferredMFA}
            setTwoFAData={setTwoFAData}
            setCognitoMfaConfig={setCognitoMfaConfig}
          />
        ) : (
          <InactiveQR onClick={onActivateQR} />
        )}
      </Loader>
    </div>
  );
}
