import { MeasureUnitType } from "api/query/models/MeasureUnitType";
import i18n from "i18next";

export function measureUnitToSelectOptions() {
  const measureUnits = Object.keys(MeasureUnitType);

  return measureUnits.map(key => ({
    title: i18n.t(`MeasureUnit.${key}`, { ns: "translation" }),
    value: MeasureUnitType[key as keyof typeof MeasureUnitType],
  }));
}
