import "./TestResultCell.scss";
import { getMeasurementTrendIcon } from "utils/getMeasurementTrendIcon";
import { useTranslation } from "react-i18next";
import { Cell } from "../Cell";
import { TestResultCellProps } from "../model";
import { useMemo } from "react";
import { useCreatinineColor } from "utils/hooks/useCreatinineColor";
import { useCreatinineValueConvertor } from "utils/hooks/useCreatinineValueConvertor";
import { useEGFRColor } from "utils/hooks/useEGFRColor";
import { useEGFRStageText } from "utils/hooks/useEGFRStageText";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { useUrineAlbuminColor } from "utils/hooks/useUrineAlbuminColor";
import { useUrineCreatinineColor } from "utils/hooks/useUrineCreatinineColor";
import { useBMIColor } from "utils/hooks/useBMIColor";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { useGetWeightConverter } from "utils/hooks/useGetWeightConverter/useGetWeightConverter";
import { getBodyMassIndex } from "utils/converters/getBodyMassIndex";
import { gramsToKilograms, millimetersToCentimeters } from "utils/converters/unitsConverter";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { useUrineAlbuminValueConvertor } from "utils/hooks/useUrineAlbuminValueConvertor";
import { useUACRStageText } from "utils/hooks/useUACRStageText";
import { TestResultCellContent } from "./TestResultCellContent";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { useGetHeightNoLabel } from "components/Chart/hooks/useGetHeightNoLabel";
import { useUACRColor } from "utils/hooks/useUACRColor";
import { getSQUACRFlags } from "utils/getSQUACRFlags";

export function TestResultCell({ show = true, loading, type, measurement }: TestResultCellProps) {
  const { t } = useTranslation("translation", { keyPrefix: "TestsTable" });
  const [getCreatineColor] = useCreatinineColor();
  const creatinineConvertor = useCreatinineValueConvertor();
  const { serumCreatinineUnit, urineCreatinineUnit } = useGetUserPreferredMeasurementUnits();
  const [getEGFRColor] = useEGFRColor();
  const [getEGFRStageText] = useEGFRStageText();
  const {
    egfrUnit,
    glucoseUnit,
    urineAlbuminUnit,
    uacrUnit,
    weightUnit,
    heightUnit,
    bloodPressureUnit,
  } = useGetUserPreferredMeasurementUnits();
  const [getUrineCreatineColor] = useUrineCreatinineColor();
  const [getUrineAlbuminColor] = useUrineAlbuminColor();
  const [getBmiColor] = useBMIColor();
  const { numberFormatterWithDash } = useLocaleNumberFormatter();
  const getConvertedWeight = useGetWeightConverter();
  const [getUrineAlbuminValue] = useUrineAlbuminValueConvertor();
  const [getUACRStageText] = useUACRStageText();
  const [getUACRColor] = useUACRColor();
  const { t: imperialUnit } = useTranslation("measurements", { keyPrefix: "Measurements" });
  const { globalPreference } = useGlobalPreferenceContext();
  const [getHeightValue] = useGetHeightNoLabel();

  const Component = useMemo(() => {
    switch (type) {
      case "SerumCreatinine":
        return (
          <Cell className="TestResultCell" loading={loading} show={show}>
            <TestResultCellContent
              type={"serumCreatinine"}
              label={t("LabList.labels.serumCreatinine")}
              colorVal={getCreatineColor(measurement.SerumCreatinine?.SerumCreatinine?.Status)}
              unit={serumCreatinineUnit}
              percent={measurement.SerumCreatinine?.SerumCreatinine?.Percent}
              iconVal={getMeasurementTrendIcon(
                measurement.SerumCreatinine?.SerumCreatinine?.TrendType,
              )}
            >
              {numberFormatterWithDash({
                value: measurement.SerumCreatinine?.SerumCreatinine?.Value
                  ? +creatinineConvertor(
                      measurement.SerumCreatinine?.SerumCreatinine?.Value,
                      measurement.SerumCreatinine?.SerumCreatinine?.Unit ?? MEASUREMENT_UNITS.mgdL,
                    )
                  : measurement.SerumCreatinine?.SerumCreatinine?.Value,
              })}
            </TestResultCellContent>

            <TestResultCellContent
              type={"egfr"}
              label={t("LabList.labels.egfr")}
              colorVal={getEGFRColor(measurement.SerumCreatinine?.Egfr?.Status)}
              unit={egfrUnit}
              percent={measurement.SerumCreatinine?.Egfr?.Percent}
              iconVal={getMeasurementTrendIcon(measurement.SerumCreatinine?.Egfr?.TrendType)}
              tooltip={getEGFRStageText(measurement.SerumCreatinine?.Egfr?.Status)}
            >
              {numberFormatterWithDash({
                value: measurement.SerumCreatinine?.Egfr?.Value,
              })}
            </TestResultCellContent>
          </Cell>
        );

      case "BloodPressure":
        return (
          <Cell className="TestResultCell" loading={loading} show={show}>
            <TestResultCellContent
              type={"bloodPressure"}
              label={t("LabList.labels.systolic")}
              colorVal={"white"}
              unit={bloodPressureUnit}
              percent={measurement.BloodPressure?.Systolic.Percent}
              iconVal={getMeasurementTrendIcon(measurement.BloodPressure?.Systolic.TrendType)}
            >
              {numberFormatterWithDash({
                value: measurement.BloodPressure?.Systolic.Value,
              })}
            </TestResultCellContent>

            <TestResultCellContent
              type={"bloodPressure"}
              label={t("LabList.labels.diastolic")}
              colorVal={"white"}
              unit={bloodPressureUnit}
              percent={measurement.BloodPressure?.Diastolic.Percent}
              iconVal={getMeasurementTrendIcon(measurement.BloodPressure?.Diastolic.TrendType)}
            >
              {numberFormatterWithDash({
                value: measurement.BloodPressure?.Diastolic.Value,
              })}
            </TestResultCellContent>
          </Cell>
        );

      case "Glucose":
        return (
          <Cell className="TestResultCell" loading={loading} show={show}>
            <TestResultCellContent
              type={"glucose"}
              label={t("LabList.labels.glucose")}
              colorVal={"white"}
              unit={glucoseUnit}
              percent={measurement.Glucose?.Glucose?.Percent}
              iconVal={getMeasurementTrendIcon(measurement.Glucose?.Glucose?.TrendType)}
            >
              {numberFormatterWithDash({
                value: measurement.Glucose?.Glucose?.Value,
              })}
            </TestResultCellContent>
          </Cell>
        );

      case "SemiQuantitativeUACR": {
        const content =
          getSQUACRFlags(measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value).status !==
          "None"
            ? getSQUACRFlags(measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value).level
            : "-";

        return (
          <Cell className="TestResultCell" loading={loading} show={show}>
            <TestResultCellContent
              type={"squacr"}
              label={t("LabList.labels.squacr")}
              colorVal={getUACRColor(
                getSQUACRFlags(measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value).status,
              )}
              iconVal={getMeasurementTrendIcon(
                measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.TrendType,
              )}
              tooltip={getUACRStageText(
                getSQUACRFlags(measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value).status,
              )}
              unit={
                getSQUACRFlags(measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value).stadium
              }
            >
              {content}
            </TestResultCellContent>
          </Cell>
        );
      }

      case "UACR":
        return (
          <Cell className="TestResultCell" loading={loading} show={show}>
            <TestResultCellContent
              type={"uacr"}
              label={t("LabList.labels.uacr")}
              colorVal={getUACRColor(measurement.UACR?.Uacr.Status)}
              unit={uacrUnit}
              percent={measurement.UACR?.Uacr.Percent}
              iconVal={getMeasurementTrendIcon(measurement.UACR?.Uacr.TrendType)}
              tooltip={getUACRStageText(measurement.UACR?.Uacr.Status)}
            >
              {numberFormatterWithDash({ value: measurement.UACR?.Uacr.Value })}
            </TestResultCellContent>

            <TestResultCellContent
              type={"urineCreatinine"}
              label={t("LabList.labels.urineCreatinine")}
              colorVal={getUrineCreatineColor(measurement.UACR?.UrineCreatinine?.Status)}
              unit={urineCreatinineUnit}
              percent={measurement.UACR?.UrineCreatinine?.Percent}
              iconVal={getMeasurementTrendIcon(measurement.UACR?.UrineCreatinine?.TrendType)}
            >
              {numberFormatterWithDash({
                value: measurement.UACR?.UrineCreatinine?.Value,
              })}
            </TestResultCellContent>

            <TestResultCellContent
              type={"urineAlbumin"}
              label={t("LabList.labels.urineAlbumin")}
              colorVal={getUrineAlbuminColor(measurement.UACR?.UrineAlbumin.Status)}
              unit={urineAlbuminUnit}
              percent={measurement.UACR?.UrineAlbumin.Percent}
              iconVal={getMeasurementTrendIcon(measurement.UACR?.UrineAlbumin.TrendType)}
            >
              {numberFormatterWithDash({
                value: measurement.UACR?.UrineAlbumin.Value
                  ? getUrineAlbuminValue(String(measurement.UACR?.UrineAlbumin.Value))
                  : measurement.UACR?.UrineAlbumin.Value,
              })}
            </TestResultCellContent>
          </Cell>
        );

      case "BMI":
        return (
          <Cell className="TestResultCell" loading={loading} show={show}>
            <TestResultCellContent
              type={"bmi"}
              label={t("LabList.labels.bmi")}
              colorVal={getBmiColor(measurement.BMI?.Bmi.Status)}
              percent={measurement.BMI?.Bmi.Percent}
              iconVal={getMeasurementTrendIcon(measurement.BMI?.Bmi.TrendType)}
            >
              {numberFormatterWithDash({
                value:
                  measurement.BMI?.Height.Value && measurement.BMI?.Weight.Value
                    ? getBodyMassIndex(
                        millimetersToCentimeters(measurement.BMI?.Height.Value),
                        gramsToKilograms(measurement.BMI?.Weight.Value),
                      )
                    : undefined,
                additionalOptions: {
                  maximumFractionDigits: 1,
                  minimumFractionDigits: 0,
                  roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
                },
              })}
            </TestResultCellContent>

            <TestResultCellContent
              type={"height"}
              label={t("LabList.labels.height")}
              colorVal={"white"}
              percent={measurement.BMI?.Height.Percent}
              iconVal={getMeasurementTrendIcon(measurement.BMI?.Height.TrendType)}
              unit={
                globalPreference?.measureUnitType === "Metric"
                  ? heightUnit
                  : imperialUnit("heightImperialUnit")
              }
            >
              {getHeightValue(Number(measurement.BMI?.Height.Value ?? 0))}
            </TestResultCellContent>

            <TestResultCellContent
              type={"weight"}
              label={t("LabList.labels.weight")}
              colorVal={"white"}
              percent={measurement.BMI?.Weight.Percent}
              iconVal={getMeasurementTrendIcon(measurement.BMI?.Weight.TrendType)}
              unit={weightUnit}
            >
              {numberFormatterWithDash({
                value: measurement.BMI?.Weight.Value
                  ? Number(getConvertedWeight(measurement.BMI?.Weight.Value))
                  : measurement.BMI?.Weight.Value,
                additionalOptions: {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                  roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
                },
              })}
            </TestResultCellContent>
          </Cell>
        );
      default:
        console.error(`${type} is not implemented`);
        return <Cell> {`${type} is not implemented`}</Cell>;
    }
  }, [
    bloodPressureUnit,
    creatinineConvertor,
    egfrUnit,
    getBmiColor,
    getConvertedWeight,
    getCreatineColor,
    getEGFRColor,
    getEGFRStageText,
    getHeightValue,
    getUACRColor,
    getUACRStageText,
    getUrineAlbuminColor,
    getUrineAlbuminValue,
    getUrineCreatineColor,
    globalPreference?.measureUnitType,
    glucoseUnit,
    heightUnit,
    imperialUnit,
    loading,
    measurement.BMI?.Bmi.Percent,
    measurement.BMI?.Bmi.Status,
    measurement.BMI?.Bmi.TrendType,
    measurement.BMI?.Height.Percent,
    measurement.BMI?.Height.TrendType,
    measurement.BMI?.Height.Value,
    measurement.BMI?.Weight.Percent,
    measurement.BMI?.Weight.TrendType,
    measurement.BMI?.Weight.Value,
    measurement.BloodPressure?.Diastolic.Percent,
    measurement.BloodPressure?.Diastolic.TrendType,
    measurement.BloodPressure?.Diastolic.Value,
    measurement.BloodPressure?.Systolic.Percent,
    measurement.BloodPressure?.Systolic.TrendType,
    measurement.BloodPressure?.Systolic.Value,
    measurement.Glucose?.Glucose?.Percent,
    measurement.Glucose?.Glucose?.TrendType,
    measurement.Glucose?.Glucose?.Value,
    measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.TrendType,
    measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value,
    measurement.SerumCreatinine?.Egfr?.Percent,
    measurement.SerumCreatinine?.Egfr?.Status,
    measurement.SerumCreatinine?.Egfr?.TrendType,
    measurement.SerumCreatinine?.Egfr?.Value,
    measurement.SerumCreatinine?.SerumCreatinine?.Percent,
    measurement.SerumCreatinine?.SerumCreatinine?.Status,
    measurement.SerumCreatinine?.SerumCreatinine?.TrendType,
    measurement.SerumCreatinine?.SerumCreatinine?.Unit,
    measurement.SerumCreatinine?.SerumCreatinine?.Value,
    measurement.UACR?.Uacr.Percent,
    measurement.UACR?.Uacr.Status,
    measurement.UACR?.Uacr.TrendType,
    measurement.UACR?.Uacr.Value,
    measurement.UACR?.UrineAlbumin.Percent,
    measurement.UACR?.UrineAlbumin.Status,
    measurement.UACR?.UrineAlbumin.TrendType,
    measurement.UACR?.UrineAlbumin.Value,
    measurement.UACR?.UrineCreatinine?.Percent,
    measurement.UACR?.UrineCreatinine?.Status,
    measurement.UACR?.UrineCreatinine?.TrendType,
    measurement.UACR?.UrineCreatinine?.Value,
    numberFormatterWithDash,
    serumCreatinineUnit,
    show,
    t,
    type,
    uacrUnit,
    urineAlbuminUnit,
    urineCreatinineUnit,
    weightUnit,
  ]);

  return Component;
}
