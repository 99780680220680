import { useCallback, useRef, useTransition } from "react";

const DEFAULT_DEBOUNCE_TIME = 400;

export function useDebounce() {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [, startTransition] = useTransition();

  const debounce = useCallback((callback: () => void, debounceTime = DEFAULT_DEBOUNCE_TIME) => {
    const update = () =>
      startTransition(() => {
        callback();
      });

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => update(), debounceTime);
  }, []);

  return [debounce] as const;
}
