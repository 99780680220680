import { URINE_CREATININE_MAX_GRAPH_VALUE } from "config/const";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { useMemo } from "react";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";

export function useGetMaxUrineCreatinineValueFromRuleEngineUnit() {
  const { urineCreatinineUnit } = useGetUserPreferredMeasurementUnits();

  // !!!TODO!!!: once rule engine is implemented, check the urine creatinine measurement unit and implement
  // !!! everything accordingly
  return useMemo(() => {
    switch (urineCreatinineUnit) {
      case undefined:
      case MEASUREMENT_UNITS.mgdL:
        return URINE_CREATININE_MAX_GRAPH_VALUE;
      default:
        console.error(`Max value for ${urineCreatinineUnit} unit not implemented`);
        return URINE_CREATININE_MAX_GRAPH_VALUE;
    }
  }, [urineCreatinineUnit]);
}
