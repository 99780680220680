/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BloodPressureModel } from './BloodPressureModel';
import {
    BloodPressureModelFromJSON,
    BloodPressureModelFromJSONTyped,
    BloodPressureModelToJSON,
} from './BloodPressureModel';
import type { BmiModel } from './BmiModel';
import {
    BmiModelFromJSON,
    BmiModelFromJSONTyped,
    BmiModelToJSON,
} from './BmiModel';
import type { EgfrModel } from './EgfrModel';
import {
    EgfrModelFromJSON,
    EgfrModelFromJSONTyped,
    EgfrModelToJSON,
} from './EgfrModel';
import type { GlucoseModel } from './GlucoseModel';
import {
    GlucoseModelFromJSON,
    GlucoseModelFromJSONTyped,
    GlucoseModelToJSON,
} from './GlucoseModel';
import type { HeightModel } from './HeightModel';
import {
    HeightModelFromJSON,
    HeightModelFromJSONTyped,
    HeightModelToJSON,
} from './HeightModel';
import type { SemiQuantitativeUacrModel } from './SemiQuantitativeUacrModel';
import {
    SemiQuantitativeUacrModelFromJSON,
    SemiQuantitativeUacrModelFromJSONTyped,
    SemiQuantitativeUacrModelToJSON,
} from './SemiQuantitativeUacrModel';
import type { SerumCreatinineModel } from './SerumCreatinineModel';
import {
    SerumCreatinineModelFromJSON,
    SerumCreatinineModelFromJSONTyped,
    SerumCreatinineModelToJSON,
} from './SerumCreatinineModel';
import type { UacrModel } from './UacrModel';
import {
    UacrModelFromJSON,
    UacrModelFromJSONTyped,
    UacrModelToJSON,
} from './UacrModel';
import type { UrineAlbuminModel } from './UrineAlbuminModel';
import {
    UrineAlbuminModelFromJSON,
    UrineAlbuminModelFromJSONTyped,
    UrineAlbuminModelToJSON,
} from './UrineAlbuminModel';
import type { UrineCreatinineModel } from './UrineCreatinineModel';
import {
    UrineCreatinineModelFromJSON,
    UrineCreatinineModelFromJSONTyped,
    UrineCreatinineModelToJSON,
} from './UrineCreatinineModel';
import type { WeightModel } from './WeightModel';
import {
    WeightModelFromJSON,
    WeightModelFromJSONTyped,
    WeightModelToJSON,
} from './WeightModel';

/**
 * 
 * @export
 * @interface UnitsModel
 */
export interface UnitsModel {
    /**
     * 
     * @type {SerumCreatinineModel}
     * @memberof UnitsModel
     */
    serumCreatinine?: SerumCreatinineModel;
    /**
     * 
     * @type {EgfrModel}
     * @memberof UnitsModel
     */
    egfr?: EgfrModel;
    /**
     * 
     * @type {GlucoseModel}
     * @memberof UnitsModel
     */
    glucose?: GlucoseModel;
    /**
     * 
     * @type {BloodPressureModel}
     * @memberof UnitsModel
     */
    bloodPressure?: BloodPressureModel;
    /**
     * 
     * @type {UrineAlbuminModel}
     * @memberof UnitsModel
     */
    urineAlbumin?: UrineAlbuminModel;
    /**
     * 
     * @type {UrineCreatinineModel}
     * @memberof UnitsModel
     */
    urineCreatinine?: UrineCreatinineModel;
    /**
     * 
     * @type {UacrModel}
     * @memberof UnitsModel
     */
    uacr?: UacrModel;
    /**
     * 
     * @type {HeightModel}
     * @memberof UnitsModel
     */
    height?: HeightModel;
    /**
     * 
     * @type {WeightModel}
     * @memberof UnitsModel
     */
    weight?: WeightModel;
    /**
     * 
     * @type {BmiModel}
     * @memberof UnitsModel
     */
    bmi?: BmiModel;
    /**
     * 
     * @type {SemiQuantitativeUacrModel}
     * @memberof UnitsModel
     */
    semiQuantitativeUacr?: SemiQuantitativeUacrModel;
}

/**
 * Check if a given object implements the UnitsModel interface.
 */
export function instanceOfUnitsModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UnitsModelFromJSON(json: any): UnitsModel {
    return UnitsModelFromJSONTyped(json, false);
}

export function UnitsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnitsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serumCreatinine': !exists(json, 'SerumCreatinine') ? undefined : SerumCreatinineModelFromJSON(json['SerumCreatinine']),
        'egfr': !exists(json, 'Egfr') ? undefined : EgfrModelFromJSON(json['Egfr']),
        'glucose': !exists(json, 'Glucose') ? undefined : GlucoseModelFromJSON(json['Glucose']),
        'bloodPressure': !exists(json, 'BloodPressure') ? undefined : BloodPressureModelFromJSON(json['BloodPressure']),
        'urineAlbumin': !exists(json, 'UrineAlbumin') ? undefined : UrineAlbuminModelFromJSON(json['UrineAlbumin']),
        'urineCreatinine': !exists(json, 'UrineCreatinine') ? undefined : UrineCreatinineModelFromJSON(json['UrineCreatinine']),
        'uacr': !exists(json, 'Uacr') ? undefined : UacrModelFromJSON(json['Uacr']),
        'height': !exists(json, 'Height') ? undefined : HeightModelFromJSON(json['Height']),
        'weight': !exists(json, 'Weight') ? undefined : WeightModelFromJSON(json['Weight']),
        'bmi': !exists(json, 'Bmi') ? undefined : BmiModelFromJSON(json['Bmi']),
        'semiQuantitativeUacr': !exists(json, 'SemiQuantitativeUacr') ? undefined : SemiQuantitativeUacrModelFromJSON(json['SemiQuantitativeUacr']),
    };
}

export function UnitsModelToJSON(value?: UnitsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SerumCreatinine': SerumCreatinineModelToJSON(value.serumCreatinine),
        'Egfr': EgfrModelToJSON(value.egfr),
        'Glucose': GlucoseModelToJSON(value.glucose),
        'BloodPressure': BloodPressureModelToJSON(value.bloodPressure),
        'UrineAlbumin': UrineAlbuminModelToJSON(value.urineAlbumin),
        'UrineCreatinine': UrineCreatinineModelToJSON(value.urineCreatinine),
        'Uacr': UacrModelToJSON(value.uacr),
        'Height': HeightModelToJSON(value.height),
        'Weight': WeightModelToJSON(value.weight),
        'Bmi': BmiModelToJSON(value.bmi),
        'SemiQuantitativeUacr': SemiQuantitativeUacrModelToJSON(value.semiQuantitativeUacr),
    };
}

