import { SideModal, SideModalElements } from "libs/ui/SideModal";
import { useDashboardStatsWidgetContext } from "../DashboardStatsWidgetContext";
import { Button } from "libs/ui/Button";
import { Icon, IconType } from "libs/ui/Icon";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "./AddWidgetModal.scss";
import { StatsWidgetType } from "../model";
import { Checkbox } from "libs/ui/Checkbox";
import { Layout } from "react-grid-layout";
import { getIcon } from "components/Widget/utils";
import { MeasurementWidgetType } from "utils/createGlobalConfigStore";

function getOptionIcon(key: StatsWidgetType): IconType {
  if (key === "HeatMap") {
    return "Results";
  }

  return getIcon(key as MeasurementWidgetType);
}

function init(layout: Layout[], hasWidgets: boolean) {
  if (!hasWidgets) {
    return undefined;
  }
  return layout.map(item => item.i) as StatsWidgetType[];
}

export function AddWidgetModal() {
  const { t } = useTranslation("translation", {
    keyPrefix: "DashboardPage.DashboardStatsPage.AddWidgetModal",
  });
  const {
    hasWidgets,
    latestSavedLayouts,
    currentBreakpoint,
    toggleAddWidgetModal,
    onManageWidgets,
  } = useDashboardStatsWidgetContext();

  const [widgetsToAdd, setWidgetsToAdd] = useState<StatsWidgetType[] | undefined>(() =>
    init(latestSavedLayouts[currentBreakpoint ?? "col-3"], hasWidgets),
  );

  const onAddWidget = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const checkedValue = value as StatsWidgetType;

      const currentValues = new Set<StatsWidgetType>(widgetsToAdd);

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      currentValues.has(checkedValue)
        ? currentValues.delete(checkedValue)
        : currentValues.add(checkedValue);

      const currentValuesToArray = Array.from(currentValues);

      setWidgetsToAdd(
        currentValuesToArray.length === 0 && !hasWidgets ? undefined : currentValuesToArray,
      );
    },
    [hasWidgets, widgetsToAdd],
  );

  const onApply = useCallback(() => {
    if (!widgetsToAdd) {
      return;
    }
    onManageWidgets(widgetsToAdd);
    toggleAddWidgetModal();
  }, [onManageWidgets, toggleAddWidgetModal, widgetsToAdd]);

  const onClearAll = useCallback(() => setWidgetsToAdd(hasWidgets ? [] : undefined), [hasWidgets]);

  return (
    <SideModal bodyClass="AddWidgetModal" show={true}>
      <div>
        <SideModalElements.Header closeModal={{ onClick: toggleAddWidgetModal }}>
          <Icon icon="PlusCircle" />
          {t("title")}
        </SideModalElements.Header>

        <SideModalElements.Separator />
      </div>

      <div className="AddWidgetModal__main">
        {Object.keys(StatsWidgetType).map(key => (
          <div className="WidgetOption" key={key}>
            <Checkbox
              label={t(key)}
              checked={widgetsToAdd?.some(el => el === key)}
              value={key}
              onChange={onAddWidget}
            >
              <Icon className="WidgetOption__icon" icon={getOptionIcon(key as StatsWidgetType)} />
            </Checkbox>
          </div>
        ))}
      </div>

      <SideModalElements.Footer>
        <Button
          onClick={toggleAddWidgetModal}
          className="CancelButton"
          buttonType="grey"
          buttonSize="medium"
        >
          {t("buttonCancel")}
        </Button>

        <Button
          onClick={onApply}
          buttonType="primary"
          buttonSize="medium"
          type="submit"
          disabled={widgetsToAdd === undefined}
        >
          {t("buttonApply")}
        </Button>

        <Button
          onClick={onClearAll}
          buttonType="link-primary"
          buttonSize="medium"
          disabled={widgetsToAdd === undefined || widgetsToAdd?.length === 0}
          className="AddWidgetModal__clearAll"
        >
          {t("buttonClearAll")}
        </Button>
      </SideModalElements.Footer>
    </SideModal>
  );
}
