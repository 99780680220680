import { MeasurementGraphModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { ECElementEvent, ECharts } from "echarts/core";
import { isLoaded } from "models/loadable";

import { MutableRefObject, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { useApi } from "utils/hooks/useApi";
import { CandlestickChartSeriesType, CandlestickChartUnitType } from "../model";
import { CandleStickChartData } from "pages/PatientsPage/Details/Graph/BloodPressure/model";
import { MeasurementDetails } from "components/MeasurementSideDetails/SideDetails/components/model";
import { getMeasurementPerType } from "../utils";
import { mapSideDetailsUnits } from "components/MeasurementSideDetails/utils";

export function useGraphCandlestickDetails(
  dataList: MeasurementGraphModel[],
  unitType: CandlestickChartUnitType,
  chartRef: MutableRefObject<ECharts | undefined>,
) {
  const { organizationId = "", patientId = "" } = useParams<RouteParams["patientGraph"]>();

  const [testData, getTestData, resetTestData] = useApi(
    CarnaApiQuery.Test.getByOrganizationPatientId,
  );
  const [redirectZoomDate, setRedirectZoomDate] = useState<string | undefined>();

  const closeTooltipOnSidebarToggle = useCallback(() => {
    chartRef?.current?.dispatchAction({
      type: "hideTip",
    });
  }, [chartRef]);

  const selectedNode: MeasurementDetails | undefined = useMemo(
    () =>
      isLoaded(testData) && testData.value.measurementTime
        ? ({
            measurementTime: testData.value.measurementTime,
            units: mapSideDetailsUnits(
              getMeasurementPerType(testData.value.measurements, unitType),
              unitType,
            ),
            patient: {
              ...testData.value.patient,
            },
            organizationId,
            device: testData.value.device,
            id: testData.value.id,
            testedBy: {
              ...testData.value.testedBy,
              id: testData.value.testedBy?.id ?? "",
              roleType: testData.value.testedBy?.roleType ?? "Admin",
            },
            testCountId: testData.value.testCountId,
          } satisfies MeasurementDetails)
        : undefined,
    [organizationId, testData, unitType],
  );

  const closeDetails = useCallback(() => {
    chartRef?.current?.dispatchAction({
      type: "unselect",
      dataIndex: dataList.findIndex(el => el.id === selectedNode?.id),
    });
    resetTestData();
  }, [chartRef, dataList, resetTestData, selectedNode?.id]);

  const onNodeClick = useCallback(
    (val: ECElementEvent) => {
      if (val.componentType === "series") {
        const clickedNode: MeasurementGraphModel | undefined = dataList.at(val.dataIndex);
        const clickedValue = (val?.value ?? []) as CandleStickChartData;

        const isYearlyGraphNode = val.seriesName !== CandlestickChartSeriesType.Monthly;

        if (isYearlyGraphNode && !!clickedValue.length) {
          setRedirectZoomDate(clickedValue[0]);
        }

        if (clickedNode?.id === selectedNode?.id) {
          resetTestData();
          closeTooltipOnSidebarToggle(); // Fixes Tooltip bug on Blood Pressure chart that occurs when switching from yearly to monthly view
          return;
        }

        if (clickedNode?.units[unitType] !== undefined && !isYearlyGraphNode) {
          getTestData({ organizationId, userEntityId: patientId, testEntityId: clickedNode.id });
        }
      }
    },
    [
      closeTooltipOnSidebarToggle,
      dataList,
      getTestData,
      organizationId,
      patientId,
      resetTestData,
      selectedNode?.id,
      unitType,
    ],
  );

  return {
    selectedNode,
    onNodeClick,
    closeDetails,
    redirectZoomDate,
    setRedirectZoomDate,
    testData,
  } as const;
}
