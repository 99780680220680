import { HcpsFilterSection } from "components/AppTables/common/Filter/HcpsFilterSection";
import { TableFilter } from "components/AppTables/common/Filter/TableFilter";
import { AssignedDeviceFilter } from "../../Filter/AssignedDeviceFilter";
import { UserStatusFilter } from "../../Filter/UserStatusFilter";
import { PatientsTableFilterProps } from "../model";
import { OrganizationsFilterSection } from "../../Filter/OrganizationsFilterSection";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import React from "react";
import { DropDownToggler } from "libs/ui/DropDownToggler";
import { useTranslation } from "react-i18next";
import { SideModalElements } from "libs/ui/SideModal";

export function PatientsTableFilter({
  tableService,
  filterSideModelState,
  hcpsFilter,
}: PatientsTableFilterProps) {
  const { isAdmin } = useAdditionalAuthInformationContext();
  const { t } = useTranslation("translation", { keyPrefix: "PatientsTable.columns" });
  const { t: tCommonTable } = useTranslation("translation", { keyPrefix: "commonTable" });

  return (
    <TableFilter tableService={tableService} filterSideModelState={filterSideModelState}>
      {({ activeFilters, appliedFilters, onNewFilterApplied, onNewRadioFilterApplied }) => (
        <>
          {activeFilters.map((el, index) => {
            switch (el) {
              case "contains":
                return null;
              case "hcpIds":
                return (
                  <React.Fragment key={el}>
                    <DropDownToggler
                      isExpandedOnInit={(appliedFilters[el] ?? []).length > 0}
                      title={t("hcpIds")}
                      expandChildrenCount={5}
                    >
                      <HcpsFilterSection
                        checkedValues={appliedFilters[el] ?? []}
                        onFilterApply={value => onNewFilterApplied(el, value)}
                        hcpsFilter={hcpsFilter}
                      />
                    </DropDownToggler>
                    {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
                  </React.Fragment>
                );
              case "withDevice":
                return (
                  <React.Fragment key={el}>
                    <DropDownToggler
                      isExpandedOnInit={!!appliedFilters[el]}
                      title={t("device")}
                      expandChildrenCount={5}
                    >
                      <AssignedDeviceFilter
                        value={appliedFilters[el]}
                        onSelect={value => onNewRadioFilterApplied(el, value)}
                      />
                    </DropDownToggler>
                    {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
                  </React.Fragment>
                );
              case "organizationIds":
                if (isAdmin) {
                  return (
                    <React.Fragment key={el}>
                      <DropDownToggler
                        isExpandedOnInit={(appliedFilters[el] ?? []).length > 0}
                        title={t("organization")}
                        expandChildrenCount={5}
                      >
                        <OrganizationsFilterSection
                          checkedValues={appliedFilters[el] ?? []}
                          onFilterApply={value => onNewFilterApplied(el, value)}
                        />
                      </DropDownToggler>
                      {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
                    </React.Fragment>
                  );
                }
                return null;
              case "userStatuses":
                return (
                  <React.Fragment key={el}>
                    <DropDownToggler
                      isExpandedOnInit={(appliedFilters[el] ?? []).length > 0}
                      title={tCommonTable("status")}
                      expandChildrenCount={5}
                    >
                      <UserStatusFilter
                        values={appliedFilters[el] ?? []}
                        onFilterApply={value => onNewFilterApplied(el, value)}
                      />
                    </DropDownToggler>
                    {activeFilters.length !== index + 1 ? <SideModalElements.Separator /> : null}
                  </React.Fragment>
                );

              default:
                return null;
            }
          })}
        </>
      )}
    </TableFilter>
  );
}
