/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommonProblemDetailsModel,
  UserDetailsResponseModel,
  UserDetailsResponseModelListModel,
  UsersFilterModel,
} from '../models/index';
import {
    CommonProblemDetailsModelFromJSON,
    CommonProblemDetailsModelToJSON,
    UserDetailsResponseModelFromJSON,
    UserDetailsResponseModelToJSON,
    UserDetailsResponseModelListModelFromJSON,
    UserDetailsResponseModelListModelToJSON,
    UsersFilterModelFromJSON,
    UsersFilterModelToJSON,
} from '../models/index';

export interface UsersGetRequest {
    filters?: UsersFilterModel;
    limit?: number;
    page?: number;
    acceptVersion?: string;
}

export interface UsersIdGetRequest {
    id: string;
    acceptVersion?: string;
}

/**
 * UsersApi - interface
 * 
 * @export
 * @interface UsersApiInterface
 */
export interface UsersApiInterface {
    /**
     * 
     * @param {UsersFilterModel} [filters] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersGetRaw(requestParameters: UsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelListModel>>;

    /**
     */
    usersGet(requestParameters: UsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDetailsResponseModelListModel>;

    /**
     * 
     * @param {string} id 
     * @param {string} [acceptVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersIdGetRaw(requestParameters: UsersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModel>>;

    /**
     */
    usersIdGet(requestParameters: UsersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDetailsResponseModel>;

}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI implements UsersApiInterface {

     /**
     */
    async usersGetRawOriginal(requestParameters: UsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async usersGetRaw(requestParameters: UsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelListModel>> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsResponseModelListModelFromJSON(jsonValue));
    }

    /**
     */
    async usersGet(requestParameters: UsersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDetailsResponseModelListModel> {
        const response = await this.usersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

     /**
     */
    async usersIdGetRawOriginal(requestParameters: UsersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Response> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        return this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
    }

    /**
     */
    async usersIdGetRaw(requestParameters: UsersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptVersion !== undefined && requestParameters.acceptVersion !== null) {
            headerParameters['accept-version'] = String(requestParameters.acceptVersion);
        }

        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async usersIdGet(requestParameters: UsersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDetailsResponseModel> {
        const response = await this.usersIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
