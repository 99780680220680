import classNames from "classnames";
import { TextFieldProps, ThemeProvider } from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { useMemo, forwardRef, useCallback, useState } from "react";
import { Icon, IconType } from "../Icon";
import { FieldValidation } from "../FieldValidation";
import { getCurrentGlobalPreferences } from "utils/getCurrentGlobalPreferences";
import DatePickerInput from "libs/DatePickerInput";
import { DatePickerProps, MUITheme } from "./model";

import "./DatePickerField.scss";

export const CustomIcon = (icon: IconType, testId?: string) =>
  forwardRef((props, _ref) => <Icon {...props} data-testid={testId} icon={icon} />);

export function DatePickerField({
  value,
  label,
  disabled,
  onSelect,
  onBlur,
  disableFuture = true,
  validation,
  formatter,
  pickerVariant = "date",
}: Readonly<DatePickerProps>) {
  const { errorText } = validation ?? {};
  const [open, setOpen] = useState<boolean>(false);

  const OpenPickerIcon = useMemo(() => CustomIcon("Calendar", "calendar-icon"), []);
  const LeftArrowIcon = useMemo(() => CustomIcon("ArrowLeft"), []);
  const RightArrowIcon = useMemo(() => CustomIcon("ArrowRight"), []);

  const onChangeDateHandler = useCallback(
    (date: Date | null) => {
      if (date) {
        onSelect(date);
      }
    },
    [onSelect],
  );

  const valueFormatted = useMemo(() => {
    if (new Date(value).toString() === "Invalid Date") {
      return "";
    }

    return formatter(value, undefined, getCurrentGlobalPreferences().timeZoneType);
  }, [formatter, value]);

  const getCustomDatePickerInput = useCallback(
    (params: TextFieldProps) => (
      <DatePickerInput
        value={valueFormatted}
        label={label}
        disabled={disabled}
        params={params}
        setOpen={setOpen}
        onBlur={onBlur}
      />
    ),
    [disabled, label, onBlur, valueFormatted],
  );

  return (
    <ThemeProvider theme={MUITheme}>
      <div
        className={classNames(
          "DatePicker",
          disabled && "DatePicker--disabled",
          errorText && "DatePicker--error",
          open ? "DatePicker--focus" : "",
        )}
      >
        {pickerVariant === "date" ? (
          <DatePicker
            open={open}
            onClose={() => setOpen(false)}
            label={label}
            value={value}
            readOnly={disabled}
            disableFuture={disableFuture}
            onChange={onChangeDateHandler}
            renderInput={getCustomDatePickerInput}
            /**
             * ! These must not change (memoize them) else the calendar button, will need multiple clicks to work
             */
            components={{
              OpenPickerIcon,
              LeftArrowIcon,
              RightArrowIcon,
            }}
            views={["year", "month", "day"]}
          />
        ) : (
          <DateTimePicker
            open={open}
            onClose={() => setOpen(false)}
            label={label}
            value={value}
            readOnly={disabled}
            disableFuture={disableFuture}
            onChange={onChangeDateHandler}
            renderInput={getCustomDatePickerInput}
            /**
             * ! These must not change (memoize them) else the calendar button, will need multiple clicks to work
             */
            components={{
              OpenPickerIcon,
              LeftArrowIcon,
              RightArrowIcon,
            }}
          />
        )}
        <FieldValidation {...validation} />
      </div>
    </ThemeProvider>
  );
}
