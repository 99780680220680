import { PropsWithChildren, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { createSafeContext } from "utils/createSafeContext";
import { useMakeHcpPagesDetailState } from "./model";

interface HcpDetailsServiceContext {
  detailsService: ReturnType<typeof useMakeHcpPagesDetailState>;
}
const Context = createSafeContext<HcpDetailsServiceContext>();

export const useHcpDetailsServiceContext = Context.hook;

export const HcpDetailsServiceContextProvider = ({ children }: PropsWithChildren<object>) => {
  const { organizationId = "", hcpId = "" } = useParams<RouteParams["hcpDetails"]>();
  const detailsService = useMakeHcpPagesDetailState(hcpId, organizationId);

  const value = useMemo<HcpDetailsServiceContext>(() => ({ detailsService }), [detailsService]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
