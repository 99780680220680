/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';

/**
 * 
 * @export
 * @interface PhoneNumberFieldModel
 */
export interface PhoneNumberFieldModel {
    /**
     * 
     * @type {CountryType}
     * @memberof PhoneNumberFieldModel
     */
    country?: CountryType;
}

/**
 * Check if a given object implements the PhoneNumberFieldModel interface.
 */
export function instanceOfPhoneNumberFieldModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PhoneNumberFieldModelFromJSON(json: any): PhoneNumberFieldModel {
    return PhoneNumberFieldModelFromJSONTyped(json, false);
}

export function PhoneNumberFieldModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneNumberFieldModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': !exists(json, 'country') ? undefined : CountryTypeFromJSON(json['country']),
    };
}

export function PhoneNumberFieldModelToJSON(value?: PhoneNumberFieldModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': CountryTypeToJSON(value.country),
    };
}

