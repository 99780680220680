import { Button, Chip, Icon } from "libs/ui";
import { useTranslation } from "react-i18next";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import "./HeatMapHeader.scss";
import { LoadableModel, isLoaded } from "models/loadable";
import { HeatMapPatientWidgetResponseModel } from "api/query";
import classNames from "classnames";

interface HeatMapHeaderProps {
  eGFRDate?: Date | string;
  UACRDate?: Date | string;
  organizationId: string;
  patientId: string;
  heatMapData: LoadableModel<HeatMapPatientWidgetResponseModel | null>;
  getHeatMapHistory: (organizationId: string, patientId: string, option: "prev" | "next") => void;
  className?: string;
}

export function resolveDate(eGFRDate?: Date | string, UACRDate?: Date | string) {
  if (eGFRDate === undefined || UACRDate === undefined) {
    return null;
  }
  const egfr = new Date(eGFRDate);
  const uacr = new Date(UACRDate);

  if (egfr.toString() === "Invalid Date" || uacr.toString() === "Invalid Date") {
    return null;
  }

  if (egfr.valueOf() > uacr.valueOf()) {
    return egfr.toString();
  }

  return uacr.toString();
}

export function HeatMapHeader({
  eGFRDate,
  UACRDate,
  organizationId,
  patientId,
  heatMapData: HeatMap,
  getHeatMapHistory,
  className,
}: Readonly<HeatMapHeaderProps>) {
  const { t } = useTranslation("widgets", { keyPrefix: "HeatMapWidget" });
  const [dateFormatter] = useRegionDateTimeFormatter();

  const latestDate = resolveDate(eGFRDate, UACRDate);

  return (
    <div className={classNames("HeatMapHeader", className)}>
      <div className="HeatMapHeader__title">
        <Icon icon={"Results"} />
        {t("title")}
      </div>
      {isLoaded(HeatMap) && latestDate ? (
        <div className="HeatMapHeader__date">
          <Button
            buttonIcon={{ icon: "ChevronLeft" }}
            buttonSize="small"
            buttonType="transparent"
            className="HeatMapHeader__button"
            onClick={() => getHeatMapHistory(organizationId, patientId, "prev")}
            disabled={!HeatMap.value?.previous}
          />

          {HeatMap.value?.next ? null : (
            <Chip className="HeatMapHeader__chip" size="small" variant="primary">
              {t("latest")}
            </Chip>
          )}

          {dateFormatter(latestDate)}

          <Button
            disabled={!HeatMap.value?.next}
            buttonIcon={{ icon: "ChevronRight" }}
            buttonSize="small"
            buttonType="transparent"
            className="HeatMapHeader__button"
            onClick={() => getHeatMapHistory(organizationId, patientId, "next")}
          />
        </div>
      ) : null}
    </div>
  );
}
