import * as React from "react";
import { SVGProps } from "react";
const SvgBook = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2.25a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h7A2.25 2.25 0 0 1 11.25 21a.75.75 0 0 0 1.5 0A2.25 2.25 0 0 1 15 18.75h7a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75h-6a4.75 4.75 0 0 0-4 2.188A4.748 4.748 0 0 0 8 2.25H2ZM12.75 18a3.75 3.75 0 0 1 2.25-.75h6.25V3.75H16A3.25 3.25 0 0 0 12.75 7v11Zm-1.5 0V7A3.25 3.25 0 0 0 8 3.75H2.75v13.5H9a3.75 3.75 0 0 1 2.25.75Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgBook;
