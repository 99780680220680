import { MeasurementResponseModel, TestResponseModel } from "api/query";
import { mapSideDetailsUnits } from "components/MeasurementSideDetails/utils";
import { MeasurementTypes, SideDetailsModel } from "models/TestModels";

/**
 * Checks if the list of test response items contains any items that match a specified filter,
 * or returns true if no filter is provided.
 *
 * @param items - The list of test response items.
 * @param filter - The filter to apply (optional).
 * @returns True if items match the filter or no filter is provided, false otherwise.
 */
export const hasMatchingItems = (
  items: TestResponseModel[] | undefined,
  filter: MeasurementTypes | undefined,
): boolean => {
  if (!items || items.length === 0) {
    return false; // No items to match.
  }

  if (!filter) {
    return true; // No filter specified, so all items match.
  }

  // Check if at least one item matches the filter.
  return items.some(item =>
    item.measurements.some(measurement => measurement.measurementType === filter),
  );
};

export type TestUnitType =
  | "BMI"
  | "UACR"
  | "BloodPressure"
  | "Glucose"
  | "SerumCreatinine"
  | "SemiQuantitativeUACR"
  | "None";

const getMeasurementPerMeasurementType = (
  measurements: MeasurementResponseModel[],
  unit: TestUnitType | undefined,
) => {
  return measurements.find(el => el.measurementType === unit);
};

export const mapToSideDetails = (
  data: TestResponseModel,
  measurementType?: TestUnitType,
): SideDetailsModel => {
  return {
    test: {
      id: data.id,
      organizationId: data.organizationId,
      testCountId: data.testCountId,
      measurements: [
        mapSideDetailsUnits(
          getMeasurementPerMeasurementType(data.measurements, measurementType),
          measurementType,
        ),
      ],
      measurementTime: data.measurementTime,
      patient: data.patient,
      testedBy: data.testedBy,
      device: data.device,
      sampledByDevice: data.sampledByDevice,
    },
    measurement: mapSideDetailsUnits(
      getMeasurementPerMeasurementType(data.measurements, measurementType),
      measurementType,
    ),
  };
};
