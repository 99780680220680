import { PickingInfo } from "@deck.gl/core";
import { Trans, useTranslation } from "react-i18next";
import "./MapTooltip.scss";
import { ColorDot } from "libs/ui";
import { MapData } from "pages/DashboardPage/DashboardMap/DashboardMapReportDataContext";

interface TooltipProps {
  info: PickingInfo<MapData>;
}
interface CalculatePositionProps {
  x: number;
  y: number;
  viewportWidth?: number;
  viewportHeight?: number;
}
function calculatePositionCss({ x, y, viewportWidth, viewportHeight }: CalculatePositionProps) {
  if (!viewportWidth || !viewportHeight) {
    return { horizontalPosition: "right", verticalPosition: "top" } as const;
  }

  const horizontalPosition = x > viewportWidth / 2 ? "right" : "left";
  const verticalPosition = y > viewportHeight / 2 ? "bottom" : "top";

  return { horizontalPosition, verticalPosition } as const;
}

export function MapTooltip({ info }: Readonly<TooltipProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.TotalNumbers" });
  const { t: tCountry } = useTranslation("nationalities", { keyPrefix: "countries" });

  const { object, x, y } = info;

  if (!object) {
    return null;
  }

  const { horizontalPosition, verticalPosition } = calculatePositionCss({
    x: info.x,
    y: info.y,
    viewportWidth: info.viewport?.width,
    viewportHeight: info.viewport?.height,
  });

  const style = {
    [horizontalPosition]:
      horizontalPosition === "left" ? x + 5 : Math.abs(x - (info.viewport?.width ?? 0) - 5),
    [verticalPosition]:
      verticalPosition === "top" ? y + 5 : Math.abs(y - (info.viewport?.height ?? 0) - 5),
  } as const;

  const isCountry = object.name === object.entityCountryISO;

  return (
    <div className="MapTooltip" style={style}>
      <p className="MapTooltip__title">
        {isCountry ? tCountry(object.entityCountryISO ?? object.name) : object.name}
      </p>
      <p className="MapTooltip__stat">
        <ColorDot type="primary" />

        <Trans i18nKey={"totalPatients"} t={t} values={{ value: object.patientCount }}>
          <span style={{ whiteSpace: "pre-wrap" }}></span>
          <strong></strong>
        </Trans>
      </p>
      <p className="MapTooltip__stat">
        <ColorDot type="secondary" />

        <Trans i18nKey={object.reportType} t={t} values={{ value: object.patientRiskCount }}>
          <span style={{ whiteSpace: "pre-wrap" }}></span>
          <strong></strong>
        </Trans>
      </p>
    </div>
  );
}
