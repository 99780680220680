import { RegionType, TimeZoneType } from "api/query";
import { NUMBER_EXAMPLE } from "config/const";
import { useTranslation } from "react-i18next";

import "./RegionalPreview.scss";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";

interface RegionalPreviewProps {
  locale: RegionType;
  timeZone: TimeZoneType;
}

export function RegionalPreview({ locale, timeZone }: RegionalPreviewProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "GlobalPreferenceForm.RegionalPreview",
  });
  const date = new Date();
  const [dateFormatter, timeFormatter] = useRegionDateTimeFormatter();
  const { numberFormatter } = useLocaleNumberFormatter();

  return (
    <div className="RegionalPreview">
      <h4 className="RegionalPreview__title">{t("title")}</h4>

      <p className="RegionalPreview__example" data-testid="date">
        {t("shortDate", { value: dateFormatter(date, locale) })}
      </p>

      <p className="RegionalPreview__example" data-testid="time">
        {t("time", {
          value: timeFormatter(date, locale, timeZone),
        })}
      </p>

      <p className="RegionalPreview__example" data-testid="number">
        {t("number", {
          value: numberFormatter({ value: NUMBER_EXAMPLE, region: locale }),
        })}
      </p>
    </div>
  );
}
