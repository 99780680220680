import * as React from "react";
import { SVGProps } from "react";
const SvgCircleInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#circle-info_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12ZM11 7.23c0-.679.551-1.23 1.23-1.23h.013a1.23 1.23 0 0 1 0 2.462h-.012A1.23 1.23 0 0 1 11 7.23Zm2.154 4.924a.923.923 0 1 0-1.846 0v4.923a.923.923 0 0 0 1.846 0v-4.923Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="circle-info_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCircleInfo;
