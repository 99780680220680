import { HCPModel } from "models/PersonModels";
import { SelectFilterAsyncNoData } from "components/SelectFilterAsyncNoData";
import { SelectFilterAsyncWithCancel } from "components/SelectFilterAsyncWithCancel";
import { CarnaApiQuery } from "config/apiQuery";
import { FieldValidationProps, FormFieldBasic, SelectFilterResult } from "libs/ui";
import { createSelectFilterAsyncOption } from "libs/ui/SelectFilterAsync/SelectFilterAsync.model";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

interface HcpsFieldProps extends FormFieldBasic, FieldValidationProps {
  initialValue?: string;
  readOnly?: boolean;
  organizationId: string | undefined | null;
  onSelect: (value: HCPModel | undefined) => void;
  loading?: boolean;
  optional: boolean;
}

export function HcpsField({
  initialValue,
  organizationId,
  readOnly,
  onSelect,
  loading,
  optional,
}: Readonly<HcpsFieldProps>) {
  const { t: tForm } = useTranslation("translation", { keyPrefix: "Form" });
  const { t: tComponents } = useTranslation("translation", { keyPrefix: "components" });

  const fetchHcps = useMemo(
    () =>
      createSelectFilterAsyncOption(async (filter?: string) => {
        const data = await CarnaApiQuery.Hcps.get({
          organizationId: organizationId ?? "",
          filters: { contains: filter, userStatuses: ["Active", "Invited"] },
          limit: 100,
          page: 1,
        });

        return (
          data.items.map(hcp => ({
            title: `${hcp.firstName} ${hcp.lastName}`,
            value: hcp,
          })) ?? []
        );
      }),
    [organizationId],
  );

  const setRenderSelected = useCallback(
    (props?: HCPModel | undefined) => {
      const hasNoPropsAndInitValue = !props && !initialValue;
      const hcpsName =
        // TODO ModelFix
        !!props?.firstName && !!props?.lastName
          ? `${props?.firstName} ${props?.lastName}`
          : initialValue;

      return (
        <SelectFilterResult
          data-testval={JSON.stringify({ hcpsName: `${props?.firstName} ${props?.lastName}` })}
          hasValue={!!(props ?? initialValue)}
          label={i18n.format("HCP", "optionalField", undefined, {
            optional: optional,
          })}
        >
          {hasNoPropsAndInitValue ? null : hcpsName}
        </SelectFilterResult>
      );
    },
    [initialValue, optional],
  );

  return (
    <SelectFilterAsyncWithCancel
      data-testid="hcps-field"
      className="HCPField"
      readOnly={readOnly}
      disabled={!organizationId}
      onSelect={onSelect}
      hasInitialValue={!!initialValue}
      validation={{
        infoText: !readOnly ? tComponents("HcpsField.infoText") : undefined,
        errorText: tForm("ValidationMessages.hcpNotAvailable") ?? "",
      }}
      getOptions={fetchHcps}
      loading={loading}
      renderSelected={setRenderSelected}
      noDataComponent={<SelectFilterAsyncNoData variant="HCP" scaleTo={0.6} />}
    />
  );
}
