import { AncestryType } from "api/query/models/AncestryType";
import i18n from "i18next";

export function ancestryTypeToSelectOptions() {
  const ancestries = Object.keys(AncestryType);

  return ancestries.map(key => ({
    title: i18n.t(`ancestryType.${key}`, { ns: "translation" }),
    value: AncestryType[key as keyof typeof AncestryType],
  }));
}
