import { useHover } from "@floating-ui/react";
import { AddModalForwardedProps } from "components/AddModal/model";
import { Button, useTooltip } from "libs/ui";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import "./TabModalControl.scss";
import { User } from "components/AddModal/User";
import { Organization } from "components/AddModal/Organization";
import { Patient } from "components/AddModal/Patient";
import { HCP } from "components/AddModal/HCP";
import { Device } from "components/AddModal/Device";
import { InitialValuesModel } from "models/PersonModels";

type TabModalControlVariant = "User" | "Organization" | "Patient" | "HCP" | "Device";
interface TabModalControlProps {
  variant: TabModalControlVariant;
  onSuccessCb?: () => void;
  initialValuesAsProps?: Partial<InitialValuesModel>;
}

const modalMap = {
  User: <User />,
  Organization: <Organization />,
  Patient: <Patient />,
  HCP: <HCP />,
  Device: <Device />,
} as const;

export function TabModalControl({
  variant,
  onSuccessCb,
  initialValuesAsProps,
}: Readonly<TabModalControlProps>) {
  const addModalRef = useRef<AddModalForwardedProps>(null);
  const { t } = useTranslation("translation", { keyPrefix: "Tooltip" });
  const toggle = () => addModalRef?.current?.toggleModal();

  const [ref, getReferenceProps, Tooltip] = useTooltip({
    placement: "bottom",
    interactionProps: [useHover],
  });

  return (
    <>
      <Button
        data-testid={`add-${variant}`}
        onClick={toggle}
        buttonSize="medium"
        buttonType="white"
        buttonIcon={{ icon: "PlusCircle" }}
        className="TabModalControl__toggler"
        ref={ref}
        {...getReferenceProps({ onClick: toggle })}
      />
      <Tooltip>{t("add")}</Tooltip>
      {React.cloneElement(modalMap[variant], {
        ref: addModalRef,
        onSuccessCb,
        initialValuesAsProps,
      })}
    </>
  );
}
