import { Icon } from "libs/ui";
import { NavLink } from "react-router-dom";
import { useGetNavRoutes } from "utils/hooks/useGetNavRoutes";
import "./RouteCards.scss";

export function RouteCards() {
  const [routes] = useGetNavRoutes();

  return (
    <div className="RouteCards">
      <ul className="RouteCards__list">
        {routes.map(route =>
          route.accessLevel ? (
            <li key={route.to} className="RouteCards__item">
              {route.disabled ? (
                <div className="RouteCard RouteCard--disabled">
                  <Icon icon={route.icon} />
                  <span className="RouteCard__name">{route.name}</span>
                </div>
              ) : (
                <NavLink to={route.to} className="RouteCard">
                  <Icon icon={route.icon} />
                  <span className="RouteCard__name">{route.name}</span>
                </NavLink>
              )}
            </li>
          ) : null,
        )}
      </ul>
    </div>
  );
}
