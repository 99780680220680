import * as React from "react";
import { SVGProps } from "react";
const SvgLock = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 10.25V7a5.75 5.75 0 1 1 11.5 0v3.25H18A2.75 2.75 0 0 1 20.75 13v7A2.75 2.75 0 0 1 18 22.75H6A2.75 2.75 0 0 1 3.25 20v-7A2.75 2.75 0 0 1 6 10.25h.25Zm2.745-6.255A4.25 4.25 0 0 1 16.25 7v3.25h-8.5V7a4.25 4.25 0 0 1 1.245-3.005ZM6 11.75c-.69 0-1.25.56-1.25 1.25v7c0 .69.56 1.25 1.25 1.25h12c.69 0 1.25-.56 1.25-1.25v-7c0-.69-.56-1.25-1.25-1.25H6Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLock;
