/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeneralPractitionerResponseModel
 */
export interface GeneralPractitionerResponseModel {
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerResponseModel
     */
    entityId: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerResponseModel
     */
    rowVersion: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerResponseModel
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerResponseModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerResponseModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerResponseModel
     */
    registrationNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof GeneralPractitionerResponseModel
     */
    initialRegistrationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralPractitionerResponseModel
     */
    medicalDiscipline?: string | null;
}

/**
 * Check if a given object implements the GeneralPractitionerResponseModel interface.
 */
export function instanceOfGeneralPractitionerResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entityId" in value;
    isInstance = isInstance && "rowVersion" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "registrationNumber" in value;

    return isInstance;
}

export function GeneralPractitionerResponseModelFromJSON(json: any): GeneralPractitionerResponseModel {
    return GeneralPractitionerResponseModelFromJSONTyped(json, false);
}

export function GeneralPractitionerResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralPractitionerResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': json['EntityId'],
        'rowVersion': json['RowVersion'],
        'title': json['Title'],
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'registrationNumber': json['RegistrationNumber'],
        'initialRegistrationDate': !exists(json, 'InitialRegistrationDate') ? undefined : json['InitialRegistrationDate'],
        'medicalDiscipline': !exists(json, 'MedicalDiscipline') ? undefined : json['MedicalDiscipline'],
    };
}

export function GeneralPractitionerResponseModelToJSON(value?: GeneralPractitionerResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EntityId': value.entityId,
        'RowVersion': value.rowVersion,
        'Title': value.title,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'RegistrationNumber': value.registrationNumber,
        'InitialRegistrationDate': value.initialRegistrationDate,
        'MedicalDiscipline': value.medicalDiscipline,
    };
}

