import * as React from "react";
import { SVGProps } from "react";
const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 18A2.756 2.756 0 0 0 4 20.75h16A2.756 2.756 0 0 0 22.75 18L23 6c0-1.514-1.486-2.75-3-2.75H4A2.756 2.756 0 0 0 1.25 6v12ZM2.802 5.646A1.258 1.258 0 0 1 4 4.75h16c.563 0 1.044.38 1.198.896L12 12.085l-9.198-6.44ZM2.75 7.44l8.82 6.174a.75.75 0 0 0 .86 0l8.82-6.174V18c0 .686-.564 1.25-1.25 1.25H4c-.686 0-1.25-.564-1.25-1.25V7.44Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMail;
