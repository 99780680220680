import * as React from "react";
import { SVGProps } from "react";
const SvgGlobe = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25Zm-1.704 1.657A9.255 9.255 0 0 0 2.78 11.25h4.502a16.05 16.05 0 0 1 3.014-8.343Zm3.408 0a16.05 16.05 0 0 1 3.014 8.343h4.502a9.255 9.255 0 0 0-7.516-8.343Zm1.51 8.343A14.55 14.55 0 0 0 12 3.149a14.55 14.55 0 0 0-3.214 8.101h6.428Zm-6.428 1.5h6.428A14.55 14.55 0 0 1 12 20.851a14.55 14.55 0 0 1-3.214-8.101Zm-1.504 0H2.78a9.255 9.255 0 0 0 7.516 8.343 16.05 16.05 0 0 1-3.014-8.343Zm6.422 8.343a16.051 16.051 0 0 0 3.014-8.343h4.502a9.255 9.255 0 0 1-7.516 8.343Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgGlobe;
