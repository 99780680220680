import * as React from "react";
import { SVGProps } from "react";
const SvgGrid = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.25 4c0-.966.784-1.75 1.75-1.75h5c.966 0 1.75.784 1.75 1.75v5A1.75 1.75 0 0 1 20 10.75h-5A1.75 1.75 0 0 1 13.25 9V4ZM15 3.75a.25.25 0 0 0-.25.25v5c0 .138.112.25.25.25h5a.25.25 0 0 0 .25-.25V4a.25.25 0 0 0-.25-.25h-5ZM4 13.25A1.75 1.75 0 0 0 2.25 15v5c0 .966.784 1.75 1.75 1.75h5A1.75 1.75 0 0 0 10.75 20v-5A1.75 1.75 0 0 0 9 13.25H4ZM3.75 15a.25.25 0 0 1 .25-.25h5a.25.25 0 0 1 .25.25v5a.25.25 0 0 1-.25.25H4a.25.25 0 0 1-.25-.25v-5ZM15 13.25A1.75 1.75 0 0 0 13.25 15v5c0 .966.784 1.75 1.75 1.75h5A1.75 1.75 0 0 0 21.75 20v-5A1.75 1.75 0 0 0 20 13.25h-5ZM14.75 15a.25.25 0 0 1 .25-.25h5a.25.25 0 0 1 .25.25v5a.25.25 0 0 1-.25.25h-5a.25.25 0 0 1-.25-.25v-5ZM4 2.25A1.75 1.75 0 0 0 2.25 4v5c0 .966.784 1.75 1.75 1.75h5A1.75 1.75 0 0 0 10.75 9V4A1.75 1.75 0 0 0 9 2.25H4ZM3.75 4A.25.25 0 0 1 4 3.75h5a.25.25 0 0 1 .25.25v5a.25.25 0 0 1-.25.25H4A.25.25 0 0 1 3.75 9V4Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgGrid;
