/* tslint:disable */
 
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RegistryNumberType = {
    None: 'None',
    FreeString: 'FreeString',
    Nhs: 'Nhs',
    Upi: 'Upi'
} as const;
export type RegistryNumberType = typeof RegistryNumberType[keyof typeof RegistryNumberType];


export function RegistryNumberTypeFromJSON(json: any): RegistryNumberType {
    return RegistryNumberTypeFromJSONTyped(json, false);
}

export function RegistryNumberTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistryNumberType {
    return json as RegistryNumberType;
}

export function RegistryNumberTypeToJSON(value?: RegistryNumberType | null): any {
    return value as any;
}

