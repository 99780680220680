import { LoadableModel, failed, loaded } from "models/loadable";
import { ReactNode } from "react";
import { Subject, catchError, debounceTime, from, map, of, switchMap } from "rxjs";
import { DropdownProps } from "../Dropdown";
import { Option } from "../Select/Select.model";
import { SelectOptionProps } from "../SelectOption";
import { FormFieldBasic } from "../models";
import { DataAttributeKey } from "../models/common";

export type SelectFilterAsyncOption<T> = Option<T>;

export interface CustomDropdownProps<T> {
  showOptionsDropdown: boolean;
  inputRef?: React.RefObject<HTMLInputElement> | undefined;
  onFilterChange: React.ChangeEventHandler<HTMLInputElement>;
  showDropDownLoading: boolean;
  options: LoadableModel<SelectFilterAsyncOption<T>[]>;
  selectedOption?: T;
  selectRef: React.MutableRefObject<HTMLDivElement | null>;
  onOptionSelect: (
    value: T,
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
  clearSelectedOptionWithParent: () => void;
  setShowOptionsDropdown: (value: React.SetStateAction<boolean>) => void;
}
export interface SelectFilterAsyncProps<T> extends FormFieldBasic {
  disabled?: boolean;
  onSelect?: (value?: T) => void;
  placeholder?: string;
  className?: string;
  dropdownFloatingProps?: DropdownProps["floatingProps"];
  readOnly?: boolean;
  renderSelected: (selectedOption?: T) => ReactNode;
  renderOption?: SelectOptionProps<T>["render"];
  /**
   * In case full custimized dropdown,
   * ! in this case renderOption will be ignored
   */
  customDropdownContent?: (props: CustomDropdownProps<T>) => JSX.Element;
  [dataAttribute: DataAttributeKey]: any;
  loading?: boolean;
  noDataComponent?: JSX.Element;
  getOptions: ReturnType<typeof createSelectFilterAsyncOption<T>>;
}

export interface ResetSelectedOptionProps {
  clearSelectedOption: () => void;
}

const SELECT_FILTER_DEBOUNCE_TIME = 500;

type SearchEvent = {
  filter?: string;
  compareTo?: string;
};

export function createSelectFilterAsyncOption<T>(
  cb: (filter?: string) => Promise<{ title: string; value: T }[]>,
) {
  const filterSubject = new Subject<SearchEvent>();
  const filterPipe = filterSubject.pipe(
    // distinctUntilChange
    debounceTime(SELECT_FILTER_DEBOUNCE_TIME),
    switchMap(event =>
      from(cb(event.filter)).pipe(
        map(result => [loaded(result), event.compareTo] as const),
        catchError(result => of([failed(result), event.compareTo] as const)),
      ),
    ),
  );

  return [
    (filter?: string, compareTo?: string) => {
      filterSubject.next({ filter, compareTo });
    },
    filterPipe,
  ] as const;
}
