import { anyPass, either } from "ramda";
import { useMemo } from "react";
import { isAdmin, isHCP, isPartner, isPatient } from "../helpers/roles";
import { UserRoleType } from "models/PersonModels/Roles";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";

export type Permissions =
  | "view-reports"
  | "manage-dashboard-admin"
  | "manage-dashboard-patient"
  | "manage-organization"
  | "manage-users"
  | "add-hcp"
  | "edit-hcp"
  | "assign-hcp-to-patient"
  | "patient-hcp-view"
  | "manage-patient"
  | "view-hcp-details"
  | "manage-device";

interface TargetedUser {
  id?: string | null;
  roleType?: UserRoleType;
  organizationId?: string | null;
}

function hasPermissions(userRole: UserRoleType | undefined, currentUserId: any) {
  const isSameUser = (targetedUser?: TargetedUser) => targetedUser?.id === currentUserId;

  const manageUsers = () => either(isAdmin, isPartner)(userRole);

  const addHcp = () => either(isAdmin, isPartner)(userRole);
  const editHcp = (targetedUser?: TargetedUser) =>
    anyPass([isAdmin, isPartner])(userRole) || isSameUser(targetedUser);

  const viewHcpDetails = () => anyPass([isAdmin, isPartner, isHCP])(userRole);

  const assignHcpToPatient = (isSameOrg: boolean) =>
    (anyPass([isAdmin, isPartner, isHCP])(userRole) && isSameOrg) ?? false;

  const managePatient = () => anyPass([isAdmin, isPartner, isHCP])(userRole);

  const manageDevice = () => anyPass([isAdmin, isPartner, isHCP])(userRole);

  const permissionsMap = {
    "manage-dashboard-admin": () => isAdmin(userRole),
    "manage-dashboard-patient": () => isPatient(userRole),
    "manage-organization": () => isAdmin(userRole),
    "view-reports": () => isAdmin(userRole),
    "manage-users": manageUsers,
    "add-hcp": addHcp,
    "edit-hcp": editHcp,
    "view-hcp-details": viewHcpDetails,
    "assign-hcp-to-patient": assignHcpToPatient,
    "patient-hcp-view": () => isPatient(userRole),
    "manage-patient": managePatient,
    "manage-device": manageDevice,
  } as const;

  const can = <T extends Permissions>(
    permission: T,
    ...params: Parameters<(typeof permissionsMap)[T]>
  ) => {
    return (permissionsMap[permission] as any)(...params);
  };

  return can;
}
export function useHasAccess() {
  const { userRole, currentUserId } = useAdditionalAuthInformationContext();

  const can = useMemo(() => hasPermissions(userRole, currentUserId), [currentUserId, userRole]);

  return can;
}
