import * as React from "react";
import { SVGProps } from "react";

export const EmptyTests = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.97529 76.0127C-1.3251 70.7123 -1.3251 62.1186 3.97529 56.8182L52.4767 8.31671L71.6712 27.5112L23.1698 76.0127C17.8692 81.3131 9.27569 81.3131 3.97529 76.0127Z"
      fill="url(#paint0_linear_16694_83316)"
    />
    <path
      d="M42.5919 18.2017L61.7864 37.3962L71.6714 27.5112L52.4769 8.31671L42.5919 18.2017Z"
      fill="url(#paint1_linear_16694_83316)"
    />
    <path
      d="M14.508 46.2855L3.97529 56.8181C-1.3251 62.1185 -1.3251 70.7122 3.97529 76.0126C9.27569 81.313 17.8694 81.313 23.1698 76.0126L33.7023 65.48L14.508 46.2855Z"
      fill="url(#paint2_linear_16694_83316)"
    />
    <path
      d="M7.11052 72.8779C3.3655 69.1328 3.3655 63.061 7.11052 59.3159L39.0192 27.4072C40.3227 26.1038 42.4361 26.1038 43.7398 27.4072L52.5813 36.2488C53.8848 37.5523 53.8848 39.6657 52.5813 40.9693L20.6724 72.8779C16.9274 76.623 10.8555 76.623 7.11052 72.8779Z"
      fill="url(#paint3_linear_16694_83316)"
    />
    <path
      d="M18.1978 66.0257L13.962 61.79C13.0569 60.8849 13.0569 59.4175 13.962 58.5125L47.9347 24.5398C48.8399 23.6347 50.3073 23.6347 51.2122 24.5398L55.448 28.7756C56.3531 29.6807 56.3531 31.1481 55.448 32.053L21.4754 66.0257C20.5703 66.9307 19.1029 66.9307 18.1978 66.0257Z"
      fill="url(#paint4_linear_16694_83316)"
    />
    <path
      d="M71.7167 27.5241L53.9412 9.74854L6.22432 57.4652C0.923926 62.7656 0.923926 71.3593 6.22432 76.6597C7.62223 78.0576 9.25022 79.0829 10.9853 79.7435C15.2744 80.5875 19.8921 79.3487 23.2154 76.0255L71.7167 27.5241Z"
      fill="url(#paint5_linear_16694_83316)"
    />
    <path
      d="M31.4452 53.163L22.7777 61.8306C22.3183 62.2899 21.5735 62.2899 21.1141 61.8306C20.6547 61.3712 20.6547 60.6263 21.1141 60.167L29.7816 51.4994C30.241 51.04 30.9859 51.04 31.4452 51.4994C31.9046 51.9588 31.9046 52.7036 31.4452 53.163Z"
      fill="#B699FE"
    />
    <path
      d="M34.1547 44.539L19.8206 58.8731C19.3613 59.3324 18.6164 59.3324 18.157 58.8731C17.6977 58.4137 17.6977 57.6688 18.157 57.2095L32.4911 42.8754C32.9505 42.416 33.6953 42.416 34.1547 42.8754C34.6141 43.3348 34.6141 44.0796 34.1547 44.539Z"
      fill="#9C75FE"
    />
    <path
      d="M69.1635 29.0613L50.9269 10.8248C49.7394 9.63716 49.7394 7.71164 50.9269 6.52405L56.5603 0.890692C57.7479 -0.296897 59.6734 -0.296897 60.861 0.890692L79.0975 19.1272C80.2851 20.3148 80.2851 22.2403 79.0975 23.4279L73.4642 29.0613C72.2766 30.2489 70.3511 30.2489 69.1635 29.0613Z"
      fill="url(#paint6_linear_16694_83316)"
    />
    <path
      d="M66.4113 26.3097L53.678 13.5763C52.6648 12.5632 52.6648 10.9204 53.678 9.90738L59.9431 3.64227C60.9563 2.62909 62.599 2.62909 63.612 3.64227L76.3455 16.3758C77.3587 17.3889 77.3587 19.0317 76.3455 20.0447L70.0804 26.3098C69.0672 27.3229 67.4245 27.3228 66.4113 26.3097Z"
      fill="url(#paint7_linear_16694_83316)"
    />
    <path
      d="M62.1008 21.9982L57.9904 17.8877C57.3574 17.2547 57.3574 16.2284 57.9904 15.5954L65.6322 7.95351C66.2652 7.32051 67.2916 7.32051 67.9246 7.95351L72.035 12.064C72.668 12.697 72.668 13.7233 72.035 14.3563L64.3932 21.9982C63.7602 22.6312 62.7338 22.6312 62.1008 21.9982Z"
      fill="url(#paint8_linear_16694_83316)"
    />
    <path
      d="M55.067 2.38391L51.2972 6.15371C49.9051 7.54581 49.5978 9.49564 50.611 10.5088L69.4793 29.3771C70.4925 30.3903 72.4423 30.083 73.8344 28.6909L77.6042 24.9211L55.067 2.38391Z"
      fill="url(#paint9_linear_16694_83316)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_16694_83316"
        x1="23.4306"
        y1="37.3583"
        x2="42.6222"
        y2="56.55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAF6FF" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_16694_83316"
        x1="56.1487"
        y1="23.8394"
        x2="63.0527"
        y2="16.9354"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7BACDF" stopOpacity="0" />
        <stop offset="1" stopColor="#0681AF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_16694_83316"
        x1="11.8932"
        y1="68.0875"
        x2="1.8291"
        y2="78.1516"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7BACDF" stopOpacity="0" />
        <stop offset="1" stopColor="#0681AF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_16694_83316"
        x1="20.858"
        y1="45.563"
        x2="34.418"
        y2="59.123"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3FD5D7" />
        <stop offset="1" stopColor="#20989A" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_16694_83316"
        x1="31.5531"
        y1="42.1202"
        x2="41.214"
        y2="51.7811"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#B3DAFE" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_16694_83316"
        x1="22.9894"
        y1="17.8719"
        x2="39.6144"
        y2="54.5723"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EAF6FF" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_16694_83316"
        x1="56.2661"
        y1="6.22836"
        x2="72.2387"
        y2="22.201"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B699FE" />
        <stop offset="1" stopColor="#8352FE" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_16694_83316"
        x1="69.9887"
        y1="9.99158"
        x2="53.5821"
        y2="26.3982"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" stopOpacity="0" />
        <stop offset="0.2774" stopColor="#8352FE" stopOpacity="0.277" />
        <stop offset="0.7284" stopColor="#8352FE" stopOpacity="0.728" />
        <stop offset="1" stopColor="#8352FE" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_16694_83316"
        x1="68.098"
        y1="11.8859"
        x2="57.9272"
        y2="22.0567"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" stopOpacity="0" />
        <stop offset="0.2774" stopColor="#8352FE" stopOpacity="0.277" />
        <stop offset="0.7284" stopColor="#8352FE" stopOpacity="0.728" />
        <stop offset="1" stopColor="#8352FE" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_16694_83316"
        x1="63.9604"
        y1="16.0204"
        x2="59.3111"
        y2="20.6697"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" stopOpacity="0" />
        <stop offset="0.2774" stopColor="#8352FE" stopOpacity="0.277" />
        <stop offset="0.7284" stopColor="#8352FE" stopOpacity="0.728" />
        <stop offset="1" stopColor="#8352FE" />
      </linearGradient>
    </defs>
  </svg>
);
