import { PropsWithChildren, useEffect } from "react";

interface TriggerOnLoadProps {
  /**
   * example:
   *  const onLoad = useMemo(() => [onDataListLoad], [onDataListLoad]);
   */
  callbacks: (() => void)[];
}

/**
 * Use it with Suspense.
 * Triggers a series of callbacks.
 * Example if dataList is loaded before the lazy component,
 * and you need to trigger a zoom on some chart,
 * AFTER the component is loaded
 */
export function TriggerOnLoad({ callbacks, children }: PropsWithChildren<TriggerOnLoadProps>) {
  useEffect(() => {
    callbacks.forEach(cb => cb());
  }, [callbacks]);

  return <>{children}</>;
}
