import * as React from "react";
import type { SVGProps } from "react";
const SvgHeight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="url(#height_svg__a)"
      d="m21.643 5.502-3.145-3.145a1.22 1.22 0 0 0-1.722 0L2.356 16.776a1.22 1.22 0 0 0 0 1.722l3.146 3.145a1.22 1.22 0 0 0 1.722 0l14.42-14.419a1.22 1.22 0 0 0 0-1.722m-3.499 1.146a.792.792 0 1 1 0-1.584.792.792 0 0 1 0 1.584"
    />
    <path
      fill="url(#height_svg__b)"
      d="M21.643 7.224 7.224 21.644a1.22 1.22 0 0 1-1.722 0l-.407-.407-.63-.63L18.522 6.553a.8.8 0 0 0 .318-.318l1.769-1.768 1.035 1.036a1.22 1.22 0 0 1 0 1.722"
    />
    <path
      fill="#47546B"
      d="m8.2 20.667-.415.416-.822-.822a.294.294 0 1 1 .416-.415zM9.632 19.236l-.416.416-.821-.822a.294.294 0 1 1 .415-.416zM11.063 17.805l-.416.415-.821-.821a.294.294 0 1 1 .416-.416zM12.495 16.373l-.416.416-.822-.822a.294.294 0 1 1 .416-.415zM13.926 14.942l-.416.415-.821-.821a.294.294 0 1 1 .415-.416zM15.357 13.51l-.415.416-.822-.822a.294.294 0 1 1 .416-.415zM16.789 12.079l-.416.416-.822-.822a.294.294 0 1 1 .416-.416zM18.22 10.648l-.415.415-.822-.821a.294.294 0 1 1 .416-.416zM19.652 9.216l-.416.416-.822-.822a.294.294 0 1 1 .416-.415zM21.083 7.785l-.416.416-.821-.822a.294.294 0 1 1 .415-.416z"
    />
    <path
      fill="url(#height_svg__c)"
      d="M18.144 4.4a1.456 1.456 0 1 0 0 2.912 1.456 1.456 0 0 0 0-2.911m0 2.248a.792.792 0 1 1 0-1.584.792.792 0 0 1 0 1.584"
    />
    <path
      fill="url(#height_svg__d)"
      d="m8.944 19.924-4.868-4.867-1.72 1.719a1.22 1.22 0 0 0 0 1.722l3.146 3.145a1.22 1.22 0 0 0 1.722 0z"
    />
    <defs>
      <linearGradient
        id="height_svg__a"
        x1={10.039}
        x2={14.157}
        y1={10.039}
        y2={14.157}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCD34D" />
        <stop offset={1} stopColor="#F59E0B" />
      </linearGradient>
      <linearGradient
        id="height_svg__b"
        x1={15.434}
        x2={12.58}
        y1={15.435}
        y2={12.581}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDE68A" />
        <stop offset={1} stopColor="#F59E0B" />
      </linearGradient>
      <linearGradient
        id="height_svg__c"
        x1={18.144}
        x2={18.144}
        y1={7.658}
        y2={3.449}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDE68A" />
        <stop offset={1} stopColor="#F59E0B" />
      </linearGradient>
      <linearGradient
        id="height_svg__d"
        x1={5.203}
        x2={3.678}
        y1={18.798}
        y2={20.322}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D97706" stopOpacity={0} />
        <stop offset={0.185} stopColor="#D97706" stopOpacity={0.18} />
        <stop offset={0.431} stopColor="#D97706" stopOpacity={0.43} />
        <stop offset={0.711} stopColor="#D97706" stopOpacity={0.7} />
        <stop offset={1} stopColor="#D97706" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgHeight;
