import { Button } from "libs/ui";
import { Loader } from "libs/ui/Loader";
import { useTranslation } from "react-i18next";
import { FooterProps } from "../model";
import "./Footer.scss";

export function Footer({ cancel, save, isSaving, editMode }: FooterProps) {
  const { t } = useTranslation("translation", { keyPrefix: "EditableDetails" });

  if (!editMode) {
    return null;
  }

  return (
    <footer className="Footer">
      <Loader loading={isSaving}>
        <Button
          onClick={cancel?.onClick}
          disabled={cancel?.disabled}
          buttonSize="medium"
          buttonType="grey"
          data-testid="EditableDetails--Cancel"
        >
          {t("buttonCancel")}
        </Button>
        <Button
          onClick={save?.onClick}
          disabled={save?.disabled}
          buttonSize="medium"
          buttonType="primary"
          type="submit"
          data-testid="EditableDetails--Save"
          loading={save?.isLoading}
        >
          {t("buttonSave")}
        </Button>
      </Loader>
    </footer>
  );
}
