import * as React from "react";
import { SVGProps } from "react";
const SvgArrowUpLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.763 6.357A.748.748 0 0 1 7.05 6.3h9.9a.75.75 0 1 1 0 1.5H8.86l8.62 8.62a.75.75 0 0 1-1.06 1.06L7.8 8.86v8.09a.75.75 0 0 1-1.5 0v-9.9"
      fill="currentColor"
    />
    <path d="M6.3 7.048a.748.748 0 0 1 .463-.69" fill="currentColor" />
  </svg>
);
export default SvgArrowUpLeft;
