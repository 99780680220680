import { MeasurementUnitType } from "api/query/models/MeasurementUnitType";
import { GLUCOSE_MAX_GRAPH_VALUE } from "config/const";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { useMemo } from "react";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";

export function useGetMaxGlucoseValueFromRuleEngineUnit() {
  const { glucoseUnit } = useGetUserPreferredMeasurementUnits();

  const value = useMemo(() => {
    switch (glucoseUnit) {
      case undefined:
      case MEASUREMENT_UNITS.mgdL:
        return GLUCOSE_MAX_GRAPH_VALUE;
      // case "umolL":
      // return Math.round(MAX_GLUCOSE_GRAPH_VALUE * CREATININE_CORRECTION_FACTOR);
      default:
        console.error("This should be fixed for third unit");
        return GLUCOSE_MAX_GRAPH_VALUE;
    }
  }, [glucoseUnit]);

  return { unit: MeasurementUnitType.mgdL, value };
}
