import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { EditableDetailsBase } from "../model";
import { renderValidElement } from "libs/ui";

export function Group({ children, className, ...props }: PropsWithChildren<EditableDetailsBase>) {
  return (
    <div
      className={classNames(className, "Group")}
      data-loading={props.isLoading}
      data-loaded={props.isLoaded}
    >
      {React.Children.map(children, child => {
        return renderValidElement(child, { ...props });
      })}
    </div>
  );
}
