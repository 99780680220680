import { RegionType, TimeZoneType } from "api/query";

export function dateFormat(value: string | Date, region: RegionType, timeZone?: TimeZoneType) {
  return Intl.DateTimeFormat(region, {
    dateStyle: "medium",
    ...(timeZone ? { timeZone } : undefined),
  }).format(value instanceof Date ? value : new Date(value));
}

export function timeFormat(value: string | Date, region: RegionType, timeZone: TimeZoneType) {
  return Intl.DateTimeFormat(region, {
    timeStyle: "short",
    timeZone,
  }).format(value instanceof Date ? value : new Date(value));
}

export function dateTimeFormat(
  value: string | Date,
  region: RegionType | undefined,
  timeZone: TimeZoneType,
) {
  return Intl.DateTimeFormat(region, {
    dateStyle: "medium",
    timeStyle: "medium",
    timeZone,
  }).format(value instanceof Date ? value : new Date(value));
}
