import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useMemo } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

export const EgfrLimitKey = {
  NormalOrHigh: "NormalOrHigh",
  MildlyDecreased: "MildlyDecreased",
  MildlyOrModeratelyDecreased: "MildlyOrModeratelyDecreased",
  ModeratelyToSeverelyDecreased: "ModeratelyToSeverelyDecreased",
  SeverelyDecreased: "SeverelyDecreased",
  KidneyFailure: "KidneyFailure",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EgfrLimitKey = (typeof EgfrLimitKey)[keyof typeof EgfrLimitKey];

interface SingleStageRange {
  from?: number;
  to?: number;
}

export type EGFRStageRanges = {
  [key in EgfrLimitKey]: SingleStageRange;
};

function getRowRanges(data: any, key: EgfrLimitKey) {
  if (Object.hasOwn(data, key) === false) {
    throw new Error(`${data} has no ${key} key`);
  }

  switch (key) {
    case "NormalOrHigh":
      return { from: data[key].value } as const;

    case "MildlyDecreased":
      return { from: data[key].from, to: data[key].to } as const;

    case "MildlyOrModeratelyDecreased":
      return { from: data[key].from, to: data[key].to } as const;

    case "ModeratelyToSeverelyDecreased":
      return { from: data[key].from, to: data[key].to } as const;

    case "SeverelyDecreased":
      return { from: data[key].from, to: data[key].to } as const;

    case "KidneyFailure":
      return { to: data[key].value } as const;

    default:
      throw new Error(`${key} not covered`);
  }
}

const orderedEGFRStages: EgfrLimitKey[] = [
  "NormalOrHigh",
  "MildlyDecreased",
  "MildlyOrModeratelyDecreased",
  "ModeratelyToSeverelyDecreased",
  "SeverelyDecreased",
  "KidneyFailure",
];

export function useGetEGFRRanges() {
  const { appConfig } = useGlobalConfigContext();
  let orderedEGFRStageRanges: EGFRStageRanges = useMemo(
    () => ({
      NormalOrHigh: {},
      MildlyDecreased: {},
      MildlyOrModeratelyDecreased: {},
      ModeratelyToSeverelyDecreased: {},
      SeverelyDecreased: {},
      KidneyFailure: {},
    }),
    [],
  );

  const egfrLimits = NotNullOrUndefined(
    appConfig?.laboratory.measurements.limits?.blood.egfr.limits,
  );

  orderedEGFRStages.forEach(stage => {
    orderedEGFRStageRanges = {
      ...orderedEGFRStageRanges,
      [stage]: getRowRanges(egfrLimits, stage),
    };
  });

  return useMemo(() => ({ orderedEGFRStageRanges }), [orderedEGFRStageRanges]);
}
