import * as React from "react";
import { SVGProps } from "react";
const SvgArrowUpRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.237 6.357a.748.748 0 0 0-.287-.057h-9.9a.75.75 0 0 0 0 1.5h8.09l-8.62 8.62a.75.75 0 0 0 1.06 1.06l8.62-8.62v8.09a.75.75 0 0 0 1.5 0v-9.9"
      fill="currentColor"
    />
    <path d="M17.7 7.048a.747.747 0 0 0-.463-.69" fill="currentColor" />
  </svg>
);
export default SvgArrowUpRight;
