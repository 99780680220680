import classNames from "classnames";
import { Selection, Button, Input } from "libs/ui";
import { Option } from "libs/ui/Select/Select.model";
import { useTranslation } from "react-i18next";
import { DECIMAL_NUMBER_ANY_MEASUREMENT } from "utils/regex";
import "./SingleMeasurementField.scss";
import { useCallback, useMemo, useState } from "react";
import { validateMeasurementInputValue } from "components/Forms/Test/testFormUtils";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import {
  MeasurementItemUIModel,
  MeasurementItemUIType,
  MeasurementUIModel,
  MeasurementUIType,
  resolveMeasurementUIType,
} from "models/TestModels";
import { PhysicalSection } from "components/Forms/FormElements/PhysicalSection";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { UACRForm } from "./UACRForm";
import { InputNumber } from "libs/ui/InputNumber";
import { SQUACRForm } from "./SQUACRForm";

interface SingleMeasurementFieldProps {
  model: MeasurementUIModel;
  optionTypes: MeasurementUIType[];
  onMeasurementTypeSelect: (type: MeasurementUIType, previousType: MeasurementUIType) => void;
  onMeasurementValueChange: (
    type: MeasurementUIType,
    value: string,
    itemType?: MeasurementItemUIType,
  ) => void;
  onMeasurementDelete?: (type: MeasurementUIType) => void;
  validationError: boolean;
}

export interface ValidationErrorMessage {
  msg: string;
  type: MeasurementItemUIType;
}

export function SingleMeasurementField({
  optionTypes,
  model,
  onMeasurementTypeSelect,
  onMeasurementValueChange,
  onMeasurementDelete,
  validationError,
}: Readonly<SingleMeasurementFieldProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "SingleMeasurementField" });
  const { globalPreference } = useGlobalPreferenceContext();
  const { serumCreatinineUnit, bloodPressureUnit, glucoseUnit } =
    useGetUserPreferredMeasurementUnits();

  const [fieldErrors, setFieldErrors] = useState<ValidationErrorMessage[]>([]);

  const options: Option<MeasurementUIType>[] = optionTypes.map(el => ({
    title: t(el),
    value: el,
  }));

  if (model.measurementType !== "None") {
    options.push({ title: t(model.measurementType), value: model.measurementType });
  }

  const onTypeChange = (type?: MeasurementUIType) => {
    if (!type || type === model.measurementType) {
      return;
    }

    onMeasurementTypeSelect(type, model.measurementType);
  };

  const validateInputValue = useCallback(
    ({ value, type, unit }: MeasurementItemUIModel) => {
      if (!type) {
        return;
      }
      const isValueValid = validateMeasurementInputValue({ value, type, unit });
      const errorIndex = fieldErrors.findIndex(el => el.type === type);

      if (!isValueValid && errorIndex === -1) {
        setFieldErrors(prevValue => [
          ...prevValue,
          {
            msg: t(`errorMsg.${type}`),
            type,
          },
        ]);
        return;
      }

      if (isValueValid && errorIndex !== -1) {
        setFieldErrors(prevValue => {
          prevValue.splice(errorIndex, 1);
          return [...prevValue];
        });
      }
    },
    [fieldErrors, t],
  );

  const numberInputValidation = useCallback(
    (value: any) => DECIMAL_NUMBER_ANY_MEASUREMENT.test(value) || value === "" || value === null,
    [],
  );

  const onMeasurementChange = useCallback(
    (value: any, name: MeasurementItemUIType) => {
      if (DECIMAL_NUMBER_ANY_MEASUREMENT.test(value) || value === "" || value === null) {
        const result = (value ?? "").toString();

        onMeasurementValueChange(
          resolveMeasurementUIType(name),
          result,
          name as MeasurementItemUIType,
        );

        validateInputValue({
          type: name as MeasurementItemUIType,
          value: result,
          unit: "mgdL",
        });
      }
    },
    [onMeasurementValueChange, validateInputValue],
  );

  const onInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;

      onMeasurementChange(value, name as MeasurementItemUIType);
    },
    [onMeasurementChange],
  );

  const onPhysicalChange = useCallback(
    (value: any, inputKey: "height" | "weight") => {
      onMeasurementValueChange(
        resolveMeasurementUIType(inputKey === "height" ? "Height" : "Weight"),
        (value ?? "").toString(),
        inputKey === "height" ? "Height" : "Weight",
      );
    },
    [onMeasurementValueChange],
  );

  const getPhysicalValue = useCallback(
    (items: MeasurementItemUIModel[], type: "Height" | "Weight") => {
      const value = items.find(el => el.type === type)?.value;

      if (!value) {
        return "";
      }

      return Number(value);
    },
    [],
  );

  const onSQUACRChange = useCallback(
    (value?: string) => {
      if (!value) {
        return;
      }
      onMeasurementValueChange("SemiQuantitativeUACR", value, "SemiQuantitativeUacr");
    },
    [onMeasurementValueChange],
  );

  const MeasurementField = useMemo(() => {
    switch (model.measurementType) {
      case "SerumCreatinine":
        return (
          <div className="SingleMeasurementField__data SingleMeasurementField__data--creatinine">
            <InputNumber
              onNumberChange={value => onMeasurementChange(value ?? "", "SerumCreatinine")}
              validateInput={numberInputValidation}
              label={t("labelValue")}
              value={model.items[0].value ? Number(model.items[0].value) : ""}
              suffix={serumCreatinineUnit}
              region={globalPreference?.regionType}
              formattingOptions={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
                roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
              }}
              validation={{
                errorText: fieldErrors.find(el => el.type === "SerumCreatinine")?.msg,
              }}
            />
          </div>
        );
      case "BloodPressure":
        return (
          <div className="SingleMeasurementField__data SingleMeasurementField__data--bloodPressure">
            {model.items.map(item => {
              return (
                <Input
                  key={item.type}
                  name={item.type}
                  label={t(`label${item.type}`)}
                  suffix={bloodPressureUnit}
                  onChange={onInput}
                  value={item.value}
                  validation={{
                    errorText: fieldErrors.find(el => el.type === item.type)?.msg,
                  }}
                />
              );
            })}
          </div>
        );
      case "Glucose":
        return (
          <div className="SingleMeasurementField__data SingleMeasurementField__data--glucose">
            <InputNumber
              onNumberChange={value => onMeasurementChange(value ?? "", "Glucose")}
              validateInput={numberInputValidation}
              label={t("labelValue")}
              value={model.items[0].value ? Number(model.items[0].value) : ""}
              suffix={glucoseUnit}
              region={globalPreference?.regionType}
              formattingOptions={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
                roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
              }}
              validation={{
                errorText: fieldErrors.find(el => el.type === "Glucose")?.msg,
              }}
            />
          </div>
        );

      case "UACR":
        return (
          <UACRForm items={model.items} onInput={onMeasurementChange} fieldErrors={fieldErrors} />
        );

      case "BMI":
        return (
          <PhysicalSection
            showTitle={false}
            patientData={{
              height: getPhysicalValue(model.items, "Height"),
              weight: getPhysicalValue(model.items, "Weight"),
            }}
            onChange={onPhysicalChange}
          />
        );

      case "SemiQuantitativeUACR":
        return <SQUACRForm items={model.items} onMeasurementValueChange={onSQUACRChange} />;

      case "None":
        return null;
      default:
        throw Error(`${model.measurementType} isn't implemented in SingleMeasurementField`);
    }
  }, [
    model.measurementType,
    model.items,
    numberInputValidation,
    t,
    serumCreatinineUnit,
    globalPreference?.regionType,
    fieldErrors,
    glucoseUnit,
    onMeasurementChange,
    getPhysicalValue,
    onPhysicalChange,
    onSQUACRChange,
    bloodPressureUnit,
    onInput,
  ]);

  if (options.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames("SingleMeasurementField", {
        "SingleMeasurementField--error": validationError,
      })}
      data-testid={`SingleMeasurementField-${model.measurementType}`}
      data-measurement-type={`${model.measurementType}`}
    >
      <div className="SingleMeasurementField__row">
        <Selection
          options={options}
          onSelect={onTypeChange}
          label={t("label")}
          value={model.measurementType === "None" ? undefined : model.measurementType}
        />
        <Button
          disabled={!onMeasurementDelete}
          onClick={
            onMeasurementDelete ? () => onMeasurementDelete(model.measurementType) : undefined
          }
          data-action="delete"
          type="button"
          className="RedButton"
          buttonSize="medium"
          buttonType="white"
          buttonIcon={{ icon: "Delete" }}
        />
      </div>
      {MeasurementField}
    </div>
  );
}
