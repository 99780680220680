import { UserGraphModel } from "api/query/models/UserGraphModel";
import { sub } from "date-fns/esm";
import {
  CandlestickSeriesOption,
  ComposeOption,
  DataZoomComponentOption,
  DatasetComponentOption,
  GridComponentOption,
  LineSeriesOption,
  MarkAreaComponentOption,
  MarkLineComponentOption,
  TitleComponentOption,
  ToolboxComponentOption,
  TooltipComponentOption,
  VisualMapComponentOption,
} from "echarts";
import { GraphMeasurementsKeys } from "pages/PatientsPage/Details/Graph/GraphDataAPIContext";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";

export type ECOption = ComposeOption<
  | MarkAreaComponentOption
  | MarkLineComponentOption
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | DatasetComponentOption
  | VisualMapComponentOption
  | ToolboxComponentOption
  | DataZoomComponentOption
  | CandlestickSeriesOption
>;

export type ZoomLevels = "week" | "month" | "year" | "custom";

export type GetOptions = (
  formatters: Formatters,
  graphUser: UserGraphModel,
  currentSelectedZoomLevel?: ZoomLevels,
) => ECOption;

export type LineChartDataType = GraphMeasurementsKeys;

export type CandlestickChartDataType = "BloodPressure";
export type CandlestickChartUnitType = "bloodPressure";
export enum CandlestickChartSeriesType {
  Monthly = "monthly",
  YearlyTop = "yearly top",
  YearlyBottom = "yearly bottom",
}

export const common = (formatters: Formatters): ECOption => {
  const [dateFormatter] = formatters;
  return {
    dataZoom: [
      {
        type: "inside",
        minValueSpan: 3600 * 24 * 1000 * 7,
        filterMode: "none",
      },
      {
        dataBackground: {
          areaStyle: { color: "#E9E0FF", opacity: 1 },
          lineStyle: { color: "#E9E0FF" },
        },
        brushStyle: { color: "#E9E0FF" },
        selectedDataBackground: {
          areaStyle: { color: "#CFBCFF", opacity: 1 },
          lineStyle: { color: "#CFBCFF" },
        },
        startValue: sub(new Date(), {
          months: 1,
        }),
        endValue: +new Date(),
        type: "slider",
        minValueSpan: 3600 * 24 * 1000 * 7,
        labelFormatter: function (_: any, value: string) {
          if (value) {
            return dateFormatter(value);
          }
          return "";
        },
      },
    ],
    xAxis: {
      type: "time",
      position: "bottom",
      axisTick: {
        show: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#E5E7EB",
        },
      },
      axisLine: {
        lineStyle: {
          color: "#E5E7EB",
        },
      },
      axisLabel: {
        fontFamily: "Poppins",
        fontSize: 11,
        hideOverlap: true,
        align: "center",
        rich: {
          firstPart: {
            color: "#47546B",
            lineHeight: 18,
          },
          secondPart: {
            color: "#9BAFD0",
            fontWeight: "normal",
          },
        },
      },
      boundaryGap: false,
    } as any,
    yAxis: {
      type: "value",
      axisLabel: {
        color: "#4B5563",
        formatter: function (value: number) {
          if (value >= 1000) {
            // Reduce font size for values greater or equal than 1000
            return `{a|${value.toString()}}`;
          }
          return value.toString();
        },
        rich: {
          a: {
            fontSize: 10,
          },
        },
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#E5E7EB",
        },
      },
      min: 0,
      max: (val: { min: number; max: number }) => Math.ceil(val.max),
    },
  };
};

type DateTimeFormattersType = ReturnType<typeof useRegionDateTimeFormatter>;

export type Formatters = Readonly<
  [
    DateTimeFormattersType["0"],
    DateTimeFormattersType["1"],
    ReturnType<typeof useLocaleNumberFormatter>["numberFormatter"],
  ]
>;
