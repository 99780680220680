import { SideModalElements } from "libs/ui/SideModal";
import { SectionDetailsProps } from "../model";
import { useTranslation } from "react-i18next";
import { useUACRColor } from "utils/hooks/useUACRColor";
import { DetailsField } from "../../elements/DetailsField";
import { ColorValue } from "../../elements/ColorValue";
import { UACRRangeTable } from "../../elements/UACRRangeTable";
import { getSQUACRFlags } from "utils/getSQUACRFlags";

interface SQUACRSectionDetailsProps extends SectionDetailsProps {}

// !!! TODO: Change `data?.measurement?.UACR?.Uacr` to BE model for SQ UACR
export function SQUACRSectionDetails({ data, loading }: SQUACRSectionDetailsProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.SQUACRSectionDetails" });
  const [getUACRColor] = useUACRColor();

  return (
    <div className="SQUACRSectionDetails">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={t("labelResult")}>
        <ColorValue
          colorType={getUACRColor(
            getSQUACRFlags(data?.measurement?.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value)
              .status,
          )}
        >
          {t(
            getSQUACRFlags(data?.measurement?.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value)
              .status,
          )}
        </ColorValue>
      </DetailsField>

      <UACRRangeTable
        status={
          getSQUACRFlags(data?.measurement?.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value)
            .status ?? null
        }
        loading={loading}
      />
    </div>
  );
}
