import { AVATAR_NAME_FILE_NAME, EVENT_SERVER, QUERY_SERVER } from "config/const";
import { fetchWithToken, getAccessHeaders } from "./apiFetch";
import {
  FetchPictureUrlsResult,
  GetSignedUrlForUploadParams,
  GetSignedUrlForUploadResult,
  PictureResolution,
} from "./pictures.model";

export async function getSignedUrlForUpload(
  organizationId: string,
  userEntityId: string,
  fileSizeInBytes: number,
) {
  return fetchWithToken<GetSignedUrlForUploadParams>(
    QUERY_SERVER.GET_PROFILE_PICTURE_S3_SIGNED_URL(organizationId, userEntityId),
    {
      method: "GET",
      body: {
        fileSize: fileSizeInBytes,
      },
    },
  );
}

export async function uploadPicture(img: string, organizationId: string, userEntityId: string) {
  const binaryString = atob(img);
  const content = Uint8Array.from(binaryString, c => c.charCodeAt(0));

  const file = new File([content], AVATAR_NAME_FILE_NAME, { type: "image/png" });

  const signedUrlResult = (await (
    await getSignedUrlForUpload(organizationId, userEntityId, file.size)
  ).json()) as GetSignedUrlForUploadResult;

  const result = await fetch(signedUrlResult.Url, {
    method: "PUT",
    body: file,
  });

  return result;
}

export async function deleteAvatarPicture(
  organizationId: string,
  userEntityId: string,
  latestRowVersion: string,
) {
  const headers = await getAccessHeaders();

  const result = await fetch(
    `${EVENT_SERVER.DELETE_AVATAR(organizationId, userEntityId)}?${new URLSearchParams({
      rowVersion: latestRowVersion,
      organizationId,
      userEntityId,
    }).toString()}`,
    {
      headers,
      method: "DELETE",
    },
  );

  return result;
}

export async function fetchPictureUrls(
  organizationId: string,
  userEntityIds: string[],
  resolution: PictureResolution,
) {
  const result = await fetchWithToken<{
    Resolution: string;
    UserIds: string[];
  }>(QUERY_SERVER.GET_PROFILE_PICTURES(organizationId), {
    method: "POST",
    body: {
      UserIds: userEntityIds,
      Resolution: resolution,
    },
  });

  if (result.ok) {
    return result.json() as unknown as FetchPictureUrlsResult[];
  }

  return [];
}

export async function fetchPictureUrlsForMultipleOrgs(
  params: { organizationId: string; userEntityIds: string[]; resolution: PictureResolution }[],
) {
  const result = await Promise.allSettled(
    params.map(({ organizationId, userEntityIds, resolution }) =>
      fetchPictureUrls(organizationId, userEntityIds, resolution),
    ),
  );

  if (result.every(r => r.status === "fulfilled")) {
    return result.map(r => (r as PromiseFulfilledResult<FetchPictureUrlsResult[]>).value).flat();
  }

  // If any of the promises are rejected, return an empty array
  return [];
}
