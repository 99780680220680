import { useAuthenticator } from "@aws-amplify/ui-react";
import { PasswordStrength } from "components/PasswordStrength";
import { Icon } from "libs/ui/Icon";
import { useState } from "react";
import { passwordStrength } from "utils/validators/passwordStrength";
import LoginPagesHeader, { LoginPagesHeaderProps } from "../LoginPagesHeader";
import "./ForceNewPasswordFormFields.scss";

type ForceNewPasswordFormFieldsProps = LoginPagesHeaderProps;

export function ForceNewPasswordFormFields({
  title,
  text,
  ...rest
}: ForceNewPasswordFormFieldsProps) {
  const { updateForm, updateBlur, validationErrors } = useAuthenticator(context => [
    context.updateForm,
    context.updateBlur,
    context.validationErrors,
  ]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrengthResult, setPasswordStrengthResult] = useState<ReturnType<
    typeof passwordStrength
  > | null>(null);

  return (
    <>
      <LoginPagesHeader title={title} text={text} />
      {/* <Authenticator.ForceNewPassword.FormFields /> */}

      <div className="ForceNewPasswordFormFields">
        <input
          type={showPassword ? "text" : "password"}
          onChange={event => {
            event.preventDefault();
            event.stopPropagation();
            updateForm({
              name: "password",
              value: event.target.value,
            });
            setPasswordStrengthResult(passwordStrength(event.target.value));
          }}
          onBlur={event => {
            event.preventDefault();
            event.stopPropagation();
            updateBlur({
              name: "password",
            });
          }}
        />
        <Icon
          scaleTo={16}
          icon={showPassword ? "Hide" : "Show"}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            setShowPassword(!showPassword);
          }}
        />
      </div>
      <div className="ForceNewPasswordFormFields">
        <input
          type={showConfirmPassword ? "text" : "password"}
          onChange={event => {
            event.preventDefault();
            event.stopPropagation();
            updateForm({
              name: "confirm_password",
              value: event.target.value,
            });
          }}
          onBlur={event => {
            event.preventDefault();
            event.stopPropagation();
            updateBlur({
              name: "confirm_password",
            });
          }}
        />
        <Icon
          scaleTo={16}
          icon={showConfirmPassword ? "Hide" : "Show"}
          onClick={() => {
            setShowConfirmPassword(!showConfirmPassword);
          }}
        />
        <span className="ForceNewPasswordFormFields--error">
          {validationErrors.confirm_password ?? ""}
        </span>
      </div>
      <PasswordStrength
        atLeastOneNumber={!!passwordStrengthResult?.atLeastOneNumber}
        atLeastOneSpecial={!!passwordStrengthResult?.atLeastOneSpecial}
        atLeastOneUppercase={!!passwordStrengthResult?.atLeastOneUppercase}
        minLength={!!passwordStrengthResult?.minLength}
      />
    </>
  );
}
