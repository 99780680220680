import * as React from "react";
import { SVGProps } from "react";
const SvgArrowDownLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.357 17.237a.748.748 0 0 1-.057-.287v-9.9a.75.75 0 0 1 1.5 0v8.09l8.62-8.62a.75.75 0 0 1 1.06 1.06L8.86 16.2h8.09a.75.75 0 0 1 0 1.5h-9.9"
      fill="currentColor"
    />
    <path d="M7.048 17.7a.747.747 0 0 1-.69-.463" fill="currentColor" />
  </svg>
);
export default SvgArrowDownLeft;
