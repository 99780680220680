import classNames from "classnames";
import { Avatar, AvatarSize } from "libs/ui/Avatar";
import { CurrentUserRole } from "./CurrentUserRole";
import { RegistryNumber } from "./RegistryNumber";

import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useAppMenuContext } from "context/AppMenuContext";
import { useEffect } from "react";
import "./BasicDetails.scss";

interface BasicDetailsProps<T extends HTMLDivElement> {
  sidebarCollapsed?: boolean;
  mainRef?: (node: T | null) => void;
  onClick?: () => void;
  avatarSize?: AvatarSize;
}

export function BasicDetails<T extends HTMLDivElement>({
  sidebarCollapsed,
  mainRef,
  onClick,
  avatarSize,
}: Readonly<BasicDetailsProps<T>>) {
  const { loadSideAvatar, sideAvatarState } = useAppMenuContext();
  const { firstName, lastName } = useAdditionalAuthInformationContext();

  useEffect(() => {
    if (sideAvatarState._init) {
      loadSideAvatar();
    }
  }, [loadSideAvatar, sideAvatarState._init]);

  return (
    <div
      ref={mainRef}
      onClick={onClick}
      className={classNames("BasicDetails", { "BasicDetails--clickable": !!onClick })}
    >
      <Avatar
        avatarLoading={sideAvatarState.loading}
        src={sideAvatarState.latestSavedImage}
        firstName={firstName ?? ""}
        size={avatarSize}
      />
      <div
        className={classNames(
          "BasicDetails__content",
          sidebarCollapsed && "BasicDetails__content--collapsed",
        )}
      >
        <h4 className={classNames("BasicDetails__name")}>{`${firstName} ${lastName}`}</h4>

        <CurrentUserRole />
        <RegistryNumber />
      </div>
    </div>
  );
}
