import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Icon } from "../../../Icon";
import { Cell } from "../Cell";
import { IconCellProps } from "../model";
import "./IconCell.scss";

export function IconCell({
  show = true,
  onCellClick,
  children,
  ref: _ref,
  loading,
  className,
  ...props
}: PropsWithChildren<IconCellProps>) {
  return (
    <Cell
      loading={loading}
      show={show}
      className={classNames("IconCell", { [`IconCell--${className}`]: className })}
      onClick={onCellClick}
    >
      <Icon className={className} {...props} />
      {children}
    </Cell>
  );
}
