import { OrganizationDetailsResponseModel, UserRoleType } from "api/query";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { Input, Selection, SideModalElements } from "libs/ui";
import { PropsWithChildren, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import "./../../Form.scss";
import { isReadonly } from "./../../helper";
import { UserFormFieldsProps } from "./../model";
import { PersonalSection } from "components/Forms/FormElements/PersonalSection";
import { AddressSection } from "components/Forms/FormElements/AddressSection";
import { Group } from "components/Forms/FormElements/Group";
import { OrganizationField } from "components/Forms/FormElements/OrganizationField";
import { userRoleTypeToSelectOptions } from "utils/mappers/userRoleTypeToSelectOptions";
import { fieldStatusResolve } from "components/Forms/fieldStatusResolve";

export function FormFields({
  onChange,
  onSubmit,
  entityData: userData,
  allowedUserRoles,
  editableFields,
  loading,
  children,
  formMode,
}: PropsWithChildren<UserFormFieldsProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  const { isAdmin, organizationId } = useAdditionalAuthInformationContext();
  /**
   * TODO grooming
   * This should not be dependant on external stuff
   * we should have defaultProps or something
   */
  const { appConfig } = useGlobalConfigContext();
  const isAdminInCreateMode =
    isAdmin && userData.role === "Partner" && !isReadonly("organizationId", editableFields);

  const onUserRoleSelect = useCallback(
    (value?: UserRoleType) => {
      onChange(value ?? "", "role");
      onChange(value === "Admin" ? organizationId : undefined, "organizationId");
    },
    [onChange, organizationId],
  );

  const onOrganizationChange = useCallback(
    (value?: OrganizationDetailsResponseModel) => {
      onChange(
        !value ? appConfig?.forms.defaultValues.user.organizationId : value.id,
        "organizationId",
      );
      onChange(!value ? undefined : value.name, "organizationName");
    },
    [appConfig?.forms.defaultValues.user.organizationId, onChange],
  );

  const userRoleOptions = allowedUserRoles
    ? userRoleTypeToSelectOptions().filter(el => allowedUserRoles.some(role => role === el.value))
    : userRoleTypeToSelectOptions();

  const isUserRoleFieldReadonly = isAdmin
    ? isReadonly("userRole", editableFields) || allowedUserRoles?.length === 1
    : true;

  return (
    <form className="Form" onSubmit={onSubmit} data-testid="user-form">
      <PersonalSection
        personalData={userData}
        onChange={onChange}
        editableFields={editableFields}
        loading={loading}
        role={userData.role ?? "Admin"}
        formMode={formMode}
      >
        {children}
      </PersonalSection>

      <AddressSection
        addressData={userData}
        onChange={onChange}
        editableFields={editableFields}
        zipCodeFieldStatus={fieldStatusResolve({
          formMode,
          isReadonly: isReadonly("zipCode", editableFields),
          field: appConfig?.entities.admin.zipCode,
          value: userData.zipCode,
        })}
        loading={loading}
      />

      <Group>
        <SideModalElements.SectionTitle>
          {t("Subtitle.organizational")}
        </SideModalElements.SectionTitle>
        <div className="Form__field">
          <Selection<UserRoleType>
            options={
              isAdmin
                ? userRoleOptions
                : userRoleTypeToSelectOptions().filter(el => el.value === "Partner")
            }
            {...(isAdmin && { onSelect: onUserRoleSelect })}
            label={t("userRole")}
            data-testid="UserRoleSelect"
            value={isAdmin ? userData.role : "Partner"}
            readOnly={isUserRoleFieldReadonly}
            loading={loading}
          />
        </div>
        {isAdminInCreateMode ? (
          <div className="Form__field">
            <OrganizationField
              onSelect={onOrganizationChange}
              initialValue={userData.organizationId}
              loading={loading}
            />
          </div>
        ) : null}
        {userData.organizationName && isReadonly("organizationId", editableFields) && (
          <div className="Form__field">
            <Input
              data-testid="OrganizationName"
              label={t("organizationName")}
              value={userData.organizationName}
              readOnly
              loading={loading}
            />
          </div>
        )}
      </Group>

      <button hidden type="submit" />
    </form>
  );
}
