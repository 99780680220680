import { Switch } from "libs/ui/Switch";
import { useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";

import {
  RuleEngineFormElement,
  RuleEngineSection,
  RuleEngineSectionActions,
  RuleEngineSectionTitle,
} from "../../../elements";
import { initialViewsSate, viewsReducer, viewType } from "./model";

export function ViewOptions() {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.rule-engine.user-view.view-options",
  });
  const [availableViews, toggleAvailableViews] = useReducer(viewsReducer, initialViewsSate);

  const onSave = useCallback(() => {
    console.log("Save");
  }, []);

  const onCancel = useCallback(() => {
    console.log("Cancel");
  }, []);

  return (
    <RuleEngineSection>
      <RuleEngineSectionTitle>{t("title")}</RuleEngineSectionTitle>
      <RuleEngineFormElement
        subtitle={t("listView.sectionSubtitle")}
        description={t("listView.description")}
      >
        <Switch
          name={"show-list-view"}
          className="RuleEngine__switch"
          checked={availableViews.grid}
          onChange={() => toggleAvailableViews({ type: viewType.GRID })}
        />
      </RuleEngineFormElement>
      <RuleEngineFormElement
        subtitle={t("gridView.sectionSubtitle")}
        description={t("gridView.description")}
      >
        <Switch
          name={"show-grid-view"}
          className="RuleEngine__switch"
          checked={availableViews.list}
          onChange={() => toggleAvailableViews({ type: viewType.LIST })}
        />
      </RuleEngineFormElement>
      <RuleEngineSectionActions
        onCancel={onCancel}
        cancelButtonDisabled={false}
        onSave={onSave}
        saveButtonDisabled={false}
      />
    </RuleEngineSection>
  );
}
