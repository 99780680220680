/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddTestByHcpRequestModelDeviceSpecificData } from './AddTestByHcpRequestModelDeviceSpecificData';
import {
    AddTestByHcpRequestModelDeviceSpecificDataFromJSON,
    AddTestByHcpRequestModelDeviceSpecificDataFromJSONTyped,
    AddTestByHcpRequestModelDeviceSpecificDataToJSON,
} from './AddTestByHcpRequestModelDeviceSpecificData';
import type { DeviceType } from './DeviceType';
import {
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';
import type { MeasurementModel } from './MeasurementModel';
import {
    MeasurementModelFromJSON,
    MeasurementModelFromJSONTyped,
    MeasurementModelToJSON,
} from './MeasurementModel';

/**
 * 
 * @export
 * @interface ReplaceTestByHcpRequestModel
 */
export interface ReplaceTestByHcpRequestModel {
    /**
     * 
     * @type {DeviceType}
     * @memberof ReplaceTestByHcpRequestModel
     */
    deviceType?: DeviceType;
    /**
     * 
     * @type {AddTestByHcpRequestModelDeviceSpecificData}
     * @memberof ReplaceTestByHcpRequestModel
     */
    deviceSpecificData?: AddTestByHcpRequestModelDeviceSpecificData | null;
    /**
     * 
     * @type {Array<MeasurementModel>}
     * @memberof ReplaceTestByHcpRequestModel
     */
    measurements: Array<MeasurementModel>;
    /**
     * 
     * @type {Date}
     * @memberof ReplaceTestByHcpRequestModel
     */
    measurementTime: Date;
    /**
     * 
     * @type {string}
     * @memberof ReplaceTestByHcpRequestModel
     */
    patientId: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceTestByHcpRequestModel
     */
    deviceId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceTestByHcpRequestModel
     */
    sampledByDevice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceTestByHcpRequestModel
     */
    rowVersion: string;
}

/**
 * Check if a given object implements the ReplaceTestByHcpRequestModel interface.
 */
export function instanceOfReplaceTestByHcpRequestModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "measurements" in value;
    isInstance = isInstance && "measurementTime" in value;
    isInstance = isInstance && "patientId" in value;
    isInstance = isInstance && "rowVersion" in value;

    return isInstance;
}

export function ReplaceTestByHcpRequestModelFromJSON(json: any): ReplaceTestByHcpRequestModel {
    return ReplaceTestByHcpRequestModelFromJSONTyped(json, false);
}

export function ReplaceTestByHcpRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplaceTestByHcpRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceType': !exists(json, 'DeviceType') ? undefined : DeviceTypeFromJSON(json['DeviceType']),
        'deviceSpecificData': !exists(json, 'DeviceSpecificData') ? undefined : AddTestByHcpRequestModelDeviceSpecificDataFromJSON(json['DeviceSpecificData']),
        'measurements': ((json['Measurements'] as Array<any>).map(MeasurementModelFromJSON)),
        'measurementTime': json['MeasurementTime'],
        'patientId': json['PatientId'],
        'deviceId': !exists(json, 'DeviceId') ? undefined : json['DeviceId'],
        'sampledByDevice': !exists(json, 'SampledByDevice') ? undefined : json['SampledByDevice'],
        'rowVersion': json['RowVersion'],
    };
}

export function ReplaceTestByHcpRequestModelToJSON(value?: ReplaceTestByHcpRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DeviceType': DeviceTypeToJSON(value.deviceType),
        'DeviceSpecificData': AddTestByHcpRequestModelDeviceSpecificDataToJSON(value.deviceSpecificData),
        'Measurements': ((value.measurements as Array<any>).map(MeasurementModelToJSON)),
        'MeasurementTime': value.measurementTime,
        'PatientId': value.patientId,
        'DeviceId': value.deviceId,
        'SampledByDevice': value.sampledByDevice,
        'RowVersion': value.rowVersion,
    };
}

