import { SVGProps } from "react";

export const EmptyAdmins = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="80"
    viewBox="0 0 64 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M60.9122 80.0002H2.51624C1.12659 80.0002 0 78.8736 0 77.4839C0 64.0598 10.8825 53.1772 24.3067 53.1772H39.1216C52.5458 53.1772 63.4283 64.0598 63.4283 77.4839C63.4283 78.8736 62.3018 80.0002 60.9122 80.0002Z"
      fill="url(#paint0_linear_16699_191953)"
    />
    <path
      d="M37.1778 79.9991L34.3286 71.3653C32.8845 66.9896 30.5433 66.9896 29.0993 71.3653L26.25 79.9991H37.1778Z"
      fill="url(#paint1_linear_16699_191953)"
    />
    <path
      d="M29.0993 71.8434L24.5898 67.3339L31.7141 64.2139L38.8382 67.3337L34.3287 71.8432C32.8846 73.2874 30.5434 73.2874 29.0993 71.8434Z"
      fill="url(#paint2_linear_16699_191953)"
    />
    <path
      d="M29.0993 71.8434L24.5898 67.3339L31.7141 64.2139L38.8382 67.3337L34.3287 71.8432C32.8846 73.2874 30.5434 73.2874 29.0993 71.8434Z"
      fill="url(#paint3_linear_16699_191953)"
    />
    <path
      d="M19.6397 53.6265L26.1613 79.9998H2.51624C1.12659 79.9998 0 78.8734 0 77.4836C0.000156863 65.656 8.44863 55.8031 19.6397 53.6265Z"
      fill="#374151"
    />
    <path
      d="M19.6397 53.6265L26.1613 79.9998H2.51624C1.12659 79.9998 0 78.8734 0 77.4836C0.000156863 65.656 8.44863 55.8031 19.6397 53.6265Z"
      fill="url(#paint4_linear_16699_191953)"
    />
    <path
      d="M0 77.4834C0 78.8733 1.1258 79.9991 2.51577 79.9991H26.1606L22.6869 65.9541H2.90526C1.04988 69.3853 0 73.3113 0 77.4834Z"
      fill="url(#paint5_linear_16699_191953)"
    />
    <path
      d="M17.0683 54.2812C17.9079 54.0196 18.7647 53.7968 19.6404 53.6265L26.162 80H22.3127L14.4086 64.368C14.1491 63.8549 14.1046 63.2596 14.2848 62.7137L17.0683 54.2812Z"
      fill="url(#paint6_linear_16699_191953)"
    />
    <path
      d="M43.7892 53.6265L37.2676 79.9998H60.9126C62.3023 79.9998 63.4289 78.8734 63.4289 77.4836C63.4287 65.656 54.9802 55.8031 43.7892 53.6265Z"
      fill="#374151"
    />
    <path
      d="M43.7892 53.6265L37.2676 79.9998H60.9126C62.3023 79.9998 63.4289 78.8734 63.4289 77.4836C63.4287 65.656 54.9802 55.8031 43.7892 53.6265Z"
      fill="url(#paint7_linear_16699_191953)"
    />
    <path
      d="M63.4282 77.4834C63.4282 78.8733 62.3024 79.9991 60.9125 79.9991H37.2676L40.7413 65.9541H60.523C62.3785 69.3853 63.4282 73.3113 63.4282 77.4834Z"
      fill="url(#paint8_linear_16699_191953)"
    />
    <path
      d="M46.3612 54.2812C45.5217 54.0196 44.6649 53.7968 43.7892 53.6265L37.2676 80H41.1168L49.021 64.368C49.2804 63.8549 49.325 63.2596 49.1448 62.7137L46.3612 54.2812Z"
      fill="url(#paint9_linear_16699_191953)"
    />
    <path
      d="M31.7151 48.6875H22.1426C22.1426 58.6163 31.7151 64.2141 31.7151 64.2141C31.7151 64.2141 41.2877 58.6163 41.2877 48.6875H31.7151Z"
      fill="url(#paint10_linear_16699_191953)"
    />
    <path
      d="M31.7151 48.6875H22.1426C22.1426 58.6163 31.7151 64.2141 31.7151 64.2141C31.7151 64.2141 41.2877 58.6163 41.2877 48.6875H31.7151Z"
      fill="url(#paint11_linear_16699_191953)"
    />
    <path
      d="M31.715 64.2142L20.0096 50.8105L19.3027 51.0523C17.4682 51.6796 16.5408 53.7225 17.2762 55.5164L22.5136 68.2934C22.8492 69.1125 23.85 69.4204 24.5881 68.9318L31.715 64.2142Z"
      fill="url(#paint12_linear_16699_191953)"
    />
    <path
      d="M31.7148 64.2142L43.4203 50.8105L44.1271 51.0523C45.9616 51.6796 46.889 53.7225 46.1536 55.5164L40.9163 68.2934C40.5806 69.1125 39.5798 69.4204 38.8417 68.9318L31.7148 64.2142Z"
      fill="url(#paint13_linear_16699_191953)"
    />
    <path
      d="M7.99805 32.6076C7.99805 29.5051 10.1516 26.9056 13.0456 26.2225C13.8191 26.0399 14.5585 26.638 14.5585 27.4328V37.7823C14.5585 38.5772 13.8191 39.1753 13.0456 38.9927C10.1516 38.3095 7.99805 35.71 7.99805 32.6076Z"
      fill="url(#paint14_linear_16699_191953)"
    />
    <path
      d="M55.4314 32.6076C55.4314 29.5051 53.2778 26.9056 50.384 26.2225C49.6105 26.0399 48.8711 26.638 48.8711 27.4328V37.7823C48.8711 38.5772 49.6105 39.1753 50.384 38.9927C53.2778 38.3095 55.4314 35.71 55.4314 32.6076Z"
      fill="url(#paint15_linear_16699_191953)"
    />
    <path
      d="M31.711 56.7747C21.2305 56.7747 12.7344 48.2786 12.7344 37.7981V28.0665C12.7344 17.586 21.2305 9.08984 31.711 9.08984C42.1915 9.08984 50.6877 17.586 50.6877 28.0665V37.7981C50.6877 48.2786 42.1915 56.7747 31.711 56.7747Z"
      fill="url(#paint16_linear_16699_191953)"
    />
    <path
      d="M31.7136 4.30615C22.8775 4.30615 15.7136 11.4685 15.7136 20.3062V28.7846C15.7136 29.8241 14.8708 30.667 13.8312 30.667H12.4195C11.3799 30.667 10.5371 29.8241 10.5371 28.7846V24.3517C10.5371 13.2809 19.5119 4.30615 30.5826 4.30615H31.7136Z"
      fill="url(#paint17_linear_16699_191953)"
    />
    <path
      d="M52.8913 24.3517V28.7846C52.8913 29.8241 52.0485 30.667 51.009 30.667H49.5972C48.5577 30.667 47.7149 29.8241 47.7149 28.7846V20.3062C47.7149 11.4685 40.5509 4.30615 31.7148 4.30615H32.8458C43.9167 4.30615 52.8913 13.2809 52.8913 24.3517Z"
      fill="url(#paint18_linear_16699_191953)"
    />
    <path
      d="M21.0608 7.97981C17.5473 6.57165 12.6826 8.79252 10.1953 12.94C7.70789 17.0874 8.53957 21.5913 12.0531 22.9993C15.5666 24.4073 20.4313 22.1866 22.9187 18.0391C25.4061 13.8917 24.5742 9.38797 21.0608 7.97981Z"
      fill="url(#paint19_linear_16699_191953)"
    />
    <path
      d="M51.8552 7.39687L50.7937 6.5291C44.0375 1.00628 34.618 -1.01286 25.9146 0.471845C16.6166 2.05804 13.2641 11.5153 19.9805 17.0057C26.6445 22.4532 35.5987 24.8875 44.3096 24.3087C54.5994 23.6251 59.1553 13.3644 51.8552 7.39687Z"
      fill="url(#paint20_linear_16699_191953)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_16699_191953"
        x1="27.6417"
        y1="66.7313"
        x2="35.1712"
        y2="81.8686"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAFAFB" />
        <stop offset="1" stopColor="#C6F3F3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_16699_191953"
        x1="28.8381"
        y1="74.0412"
        x2="34.1192"
        y2="74.0412"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B699FE" />
        <stop offset="1" stopColor="#8352FE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_16699_191953"
        x1="30.9833"
        y1="65.9831"
        x2="33.5715"
        y2="70.7674"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B699FE" />
        <stop offset="1" stopColor="#8352FE" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_16699_191953"
        x1="31.8245"
        y1="67.5033"
        x2="30.0598"
        y2="64.7974"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8352FE" stopOpacity="0" />
        <stop offset="0.3461" stopColor="#8352FE" stopOpacity="0.35" />
        <stop offset="0.8036" stopColor="#8352FE" stopOpacity="0.8" />
        <stop offset="1" stopColor="#8352FE" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_16699_191953"
        x1="6.02887"
        y1="70.8033"
        x2="20.5029"
        y2="67.3713"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#374151" stopOpacity="0" />
        <stop offset="1" stopColor="#374151" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_16699_191953"
        x1="13.0803"
        y1="71.6244"
        x2="13.0803"
        y2="80.5086"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#111827" stopOpacity="0" />
        <stop offset="1" stopColor="#111827" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_16699_191953"
        x1="23.1879"
        y1="65.1934"
        x2="20.0544"
        y2="69.0731"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6B7280" />
        <stop offset="1" stopColor="#4B5563" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_16699_191953"
        x1="57.4"
        y1="70.8033"
        x2="42.9259"
        y2="67.3713"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#374151" stopOpacity="0" />
        <stop offset="1" stopColor="#374151" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_16699_191953"
        x1="50.3479"
        y1="71.6244"
        x2="50.3479"
        y2="80.5086"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#111827" stopOpacity="0" />
        <stop offset="1" stopColor="#111827" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_16699_191953"
        x1="40.2415"
        y1="65.1934"
        x2="43.3752"
        y2="69.0731"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6B7280" />
        <stop offset="1" stopColor="#4B5563" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_16699_191953"
        x1="32.5644"
        y1="52.4255"
        x2="36.9518"
        y2="58.4083"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_16699_191953"
        x1="31.7151"
        y1="61.3658"
        x2="31.7151"
        y2="55.5419"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F89580" stopOpacity="0" />
        <stop offset="0.9972" stopColor="#C5715A" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_16699_191953"
        x1="22.7504"
        y1="60.3842"
        x2="19.2994"
        y2="64.2273"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAFAFB" />
        <stop offset="1" stopColor="#C6F3F3" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_16699_191953"
        x1="40.011"
        y1="59.289"
        x2="43.0698"
        y2="61.9557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAFAFB" />
        <stop offset="1" stopColor="#C6F3F3" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_16699_191953"
        x1="9.9284"
        y1="29.7712"
        x2="15.2051"
        y2="35.0479"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_16699_191953"
        x1="46.8259"
        y1="28.7688"
        x2="54.2098"
        y2="36.1527"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_16699_191953"
        x1="18.9711"
        y1="24.3677"
        x2="44.285"
        y2="41.3854"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_16699_191953"
        x1="15.6576"
        y1="13.3711"
        x2="21.7846"
        y2="17.49"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F3F4F6" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_16699_191953"
        x1="36.8353"
        y1="13.3711"
        x2="42.9624"
        y2="17.49"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F3F4F6" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_16699_191953"
        x1="12.4652"
        y1="11.3979"
        x2="19.7267"
        y2="18.6593"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5E7EB" />
        <stop offset="1" stopColor="#9CA3AF" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_16699_191953"
        x1="32.2828"
        y1="2.76016"
        x2="38.8836"
        y2="19.8446"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5E7EB" />
        <stop offset="1" stopColor="#9CA3AF" />
      </linearGradient>
    </defs>
  </svg>
);
