import { useGetUrineCreatinineNormalRange } from "components/Chart/hooks/useGetUrineCreatinineNormalRange";
import { SERUM_CREATININE_HIDE_MARK_LINE_LABEL_RANGE } from "config/const";
import { ECharts } from "echarts/core";
import { MutableRefObject, useCallback } from "react";

export function useHideClosestTick() {
  const [, from] = useGetUrineCreatinineNormalRange();

  const afterInit = useCallback(
    (chartRef: MutableRefObject<ECharts | undefined | null>) => {
      /**
       * ! getModel() is an inner private method
       * ! we are generally hacking here a bit
       * ! all this functions which we are using are not documented
       */
      const cTref: any | null = chartRef.current;
      if (!cTref) {
        return;
      }

      cTref.setOption({
        yAxis: {
          axisLabel: {
            formatter: function (value: string, index: number) {
              /**
               * The idea here is that we find on the yAxis vertical line
               * the point scaled down close to each tick
               */
              const yAxisComponent = cTref.getModel().getComponent("yAxis");

              const tickCoords: { coord: number; tickValue: number }[] =
                yAxisComponent.axis.getTicksCoords();

              const markLineCoordOnYAxis = yAxisComponent
                .getCoordSysModel()
                .coordinateSystem.getAxis("y")
                .dataToCoord(from);

              const closestOne = tickCoords.findIndex(coord => {
                const inRange =
                  /**
                   * For perfect solution we should use canvas with font size
                   * This is close enough
                   */
                  coord.coord + SERUM_CREATININE_HIDE_MARK_LINE_LABEL_RANGE >=
                    markLineCoordOnYAxis &&
                  coord.coord - SERUM_CREATININE_HIDE_MARK_LINE_LABEL_RANGE <= markLineCoordOnYAxis;
                return inRange;
              });

              return index === closestOne ? "" : value;
            },
          },
        },
      });
    },
    [from],
  );

  return afterInit;
}
