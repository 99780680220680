import { useAuthenticator } from "@aws-amplify/ui-react";
import { globalConfigStore } from "config/globalConfig";
import { isDefaultModel, isFailed, isLoaded, isLoading, isLoadingWithValue } from "models/loadable";
import { PropsWithChildren, useEffect, useMemo, useSyncExternalStore } from "react";
import { createSafeContext } from "utils/createSafeContext";
import { useTranslation } from "react-i18next";
import { FetchingErrorPage } from "pages/ErrorBoundary/FetchingErrorPage";
import { SplashScreen } from "components/SplashScreen";
import { GlobalConfigContext } from "./GlobalConfigContext.model";

const Context = createSafeContext<GlobalConfigContext>();
export const useGlobalConfigContext = Context.hook;

export function GlobalConfigProvider({ children }: Readonly<PropsWithChildren>) {
  const { authStatus } = useAuthenticator(context => [context.user, context.authStatus]);
  const { t: tErrorPages } = useTranslation("translation", {
    keyPrefix: "ErrorPages.NoNetworkErrorPage",
  });

  /**
   * Let's prevent reacts magical behaviors with state and like multiple fetching
   */
  const globalConfig = useSyncExternalStore(
    globalConfigStore.subscribe,
    globalConfigStore.getConfig,
  );

  useEffect(() => {
    if (isDefaultModel(globalConfig) && process.env.MODE === "test") {
      globalConfigStore.fetchConfig();
      return;
    }

    if (isDefaultModel(globalConfig) && authStatus === "authenticated") {
      globalConfigStore.fetchConfig();
    }
  }, [authStatus, globalConfig]);

  const value = useMemo(
    () => ({
      appConfig:
        isLoaded(globalConfig) || isLoadingWithValue(globalConfig) ? globalConfig.value : undefined,
      isLoading: isLoadingWithValue(globalConfig) || isLoading(globalConfig),
    }),
    [globalConfig],
  );

  if (isFailed(globalConfig)) {
    return (
      <FetchingErrorPage
        fetchingFunc={() => {
          window.location.reload();
        }}
        title={tErrorPages("title")}
        message={tErrorPages("message")}
        titleIcon="⚠️"
      />
    );
  }

  if (isLoaded(globalConfig) || isLoadingWithValue(globalConfig)) {
    return <Context.Provider value={value}>{children}</Context.Provider>;
  }

  return <SplashScreen />;
}
