import * as React from "react";
import { SVGProps } from "react";
const SvgCart = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 .25a.75.75 0 1 0 0 1.5h3.385l.873 4.362a.747.747 0 0 0 .015.071l1.672 8.354a2.75 2.75 0 0 0 2.742 2.213h9.706a2.75 2.75 0 0 0 2.743-2.214l1.6-8.395A.75.75 0 0 0 23 5.25H6.616L5.736.853A.75.75 0 0 0 5 .25H1Zm7.416 13.994-1.5-7.494h15.177l-1.429 7.494v.002a1.25 1.25 0 0 1-1.25 1.004H9.666a1.25 1.25 0 0 1-1.25-1.006Z"
      fill="currentColor"
    />
    <path
      d="M7 21a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM18 21a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCart;
