import { HCP } from "components/Forms/HCP";
import { HCPFormUtils } from "components/Forms/HCP/HCPFormUtils";
import { DetailsCommonPersonForm } from "components/PageTemplate/DetailsPage/DetailsCommonPersonForm";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { Dispatch, PropsWithChildren, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { useMakeHcpPagesDetailState } from "../model";
import { HCPModel } from "models/PersonModels/PersonModel";

interface DetailsFormProps {
  service: ReturnType<typeof useMakeHcpPagesDetailState>;
  hcpId: string;
  organizationId: string;
}

function FormElem({
  children,
  service,
  entityData,
  setEntityData,
  onSubmit,
}: PropsWithChildren<{
  service: ReturnType<typeof useMakeHcpPagesDetailState>;
  entityData: HCPModel;
  setEntityData: Dispatch<SetStateAction<HCPModel>>;
  onSubmit: (e?: React.FormEvent<Element> | undefined) => void;
}>) {
  const [state] = service;

  return (
    <HCP
      formMode={state.matches("entity.editing") ? "Edit" : "Readonly"}
      key="FormUser"
      entityData={
        state.matches("entity.editing") || state.matches("entity.saving")
          ? entityData
          : // TODO fix model
            (state.context?.data ?? ({} as any))
      }
      setEntityData={setEntityData}
      onSubmit={onSubmit}
      editableFields={
        state.matches("entity.editing")
          ? [
              "firstName",
              "lastName",
              "phone",
              "street",
              "city",
              "country",
              "state",
              "zipCode",
              "deviceId",
              "hcpType",
            ]
          : []
      }
      loading={state.matches("entity.loading")}
    >
      {children}
    </HCP>
  );
}

export function DetailsForm({ service, hcpId, organizationId }: DetailsFormProps) {
  const { t } = useTranslation("translation", { keyPrefix: "HCPDetails" });

  const { appConfig } = useGlobalConfigContext();
  const can = useHasAccess();
  const [state, send, actor] = service;

  const onClose = useCallback(() => send({ type: "CANCEL_EDIT" }), [send]);

  return (
    <DetailsCommonPersonForm
      avatarParams={{
        personId: hcpId ?? "",
        organizationId: organizationId ?? "",
        latestRowVersion: actor.getSnapshot().context.data?.rowVersion ?? "cannot-find-row-version",
      }}
      canEdit={can("edit-hcp", state.context.data)}
      getFieldsReady={tempData => HCPFormUtils.getFieldsReady(tempData, "Edit", appConfig)}
      defaultFormValue={NotNullOrUndefined(appConfig?.forms.defaultValues.hcp)}
      detailsService={service}
      FormElem={FormElem}
      t={t}
      onClose={onClose}
    />
  );
}
