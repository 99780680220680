import { CarnaApiEvent } from "config/apiEvent";
import { toastStore } from "config/toast";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
// import AvatarGroup from "components/PageTemplate/DetailsPage/AvatarGroup"; //TODO NEW_BACKEND
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useTranslation } from "react-i18next";
import { CreateNewSideModal } from "../common/CreateNewSideModal";
import { AddModalForwardedProps, AddModalProps } from "../model";
import { UserModel } from "components/Forms/User/model";
import { userFormUtils } from "components/Forms/User/userFormUtils";
import * as Form from "components/Forms/User";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useOnEventStatusSubscribe } from "utils/hooks/useOnEventStatusSubscribe";
import { isAdmin as isRoleAdmin } from "utils/helpers/roles";
import { stripNetworkBodyWith } from "components/Forms/helper";
import {
  AddAdminRequestModelForStripping,
  AddPartnerRequestModelForStripping,
} from "config/binding";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import { DISABLE_FORM_EDITING, EVERY_FORM_FIELD_EDITABLE } from "components/Forms/model";

export const User = forwardRef<AddModalForwardedProps, AddModalProps>(({ onSuccessCb }, ref) => {
  const { t } = useTranslation("modals", { keyPrefix: "AddModal" });
  // const navigate = useNavigate();

  const { appConfig } = useGlobalConfigContext();
  const [showModal, setShowModal] = useState(false);
  const [, /*imageData*/ setImageData] = useState<string | undefined>();
  const [userData, setUserData] = useState<UserModel>(
    NotNullOrUndefined(appConfig?.forms.defaultValues.user),
  );
  const { isAdmin, organizationId } = useAdditionalAuthInformationContext();
  const [onEvent, inLoadingState] = useOnEventStatusSubscribe();

  const resetAndClose = useCallback(() => {
    setImageData(undefined);
    setUserData(NotNullOrUndefined(appConfig?.forms.defaultValues.user));
    setShowModal(false);
  }, [appConfig?.forms.defaultValues.user]);

  const onSuccessCallback = useCallback(() => {
    resetAndClose();
    if (onSuccessCb) {
      onSuccessCb();
    }
  }, [onSuccessCb, resetAndClose]);

  const areFieldsReady = userFormUtils.getFieldsReady(userData, "Add", appConfig);
  const submitDisabled = !areFieldsReady || appConfig === undefined || inLoadingState;

  const onSubmit = useCallback(
    (e?: React.FormEvent) => {
      e?.preventDefault();

      if (submitDisabled) {
        return;
      }

      if (isAdmin && isRoleAdmin(userData.role)) {
        onEvent(
          CarnaApiEvent.Admin.post(
            { organizationId, addAdminRequestModel: userData },
            stripNetworkBodyWith(AddAdminRequestModelForStripping),
          ),
          {
            complete() {
              toastStore.toast.success({ msg: t("successAddUserToastText"), expire: 5000 });
              onSuccessCallback();
              // if (imageData) {
              //   CarnaApi.DocumentApi.organizationsOrganizationIdUsersIdUploadImagePut(
              //     result.data?.organizationId ?? "",
              //     result.data?.id ?? "",
              //     {
              //       userRoleType: result.data?.role ?? "Admin",
              //       image: imageData,
              //     },
              //   )
              //     .then(onSuccessCallback)
              //     .catch(() => {
              //       toastStore.toast.error({ msg: t("failedToUploadPicture") });
              //       onSuccessCallback();

              //       navigate(
              //         generatePath(settingsRoutePaths.userDetail, {
              //           userId: result.data?.id ?? "",
              //           organizationId: result.data?.organizationId ?? "",
              //           role: result.data?.role ?? "Admin",
              //         }),
              //       );
              //     });

              //   return;
              // }
            },
            error(err) {
              switch (err.code) {
                case "BE_ERROR":
                  showBeFieldErrors(err.err, t("errorFailedToUpdateUserTable"));
                  break;

                case "ACTION_FAILED":
                  toastStore.toast.error({ msg: t("errorAddUserToastText") });
                  break;
                case "STATUS_QUERY_ERROR":
                  resetAndClose();
                  toastStore.toast.error({ msg: t("errorFailedToUpdateUserTable") });
              }
            },
          },
        );
        return;
      }

      if (!userData.organizationId) {
        return;
      }

      onEvent(
        CarnaApiEvent.Partner.post(
          {
            organizationId: userData.organizationId,
            addPartnerRequestModel: userData,
          },
          stripNetworkBodyWith(AddPartnerRequestModelForStripping),
        ),
        {
          complete() {
            toastStore.toast.success({ msg: t("successAddUserToastText"), expire: 5000 });
            onSuccessCallback();
            // if (imageData) {
            //   CarnaApi.DocumentApi.organizationsOrganizationIdUsersIdUploadImagePut(
            //     result.data?.organizationId ?? "",
            //     result.data?.id ?? "",
            //     {
            //       userRoleType: result.data?.role ?? "Admin",
            //       image: imageData,
            //     },
            //   )
            //     .then(onSuccessCallback)
            //     .catch(() => {
            //       toastStore.toast.error({ msg: t("failedToUploadPicture") });
            //       onSuccessCallback();

            //       navigate(
            //         generatePath(settingsRoutePaths.userDetail, {
            //           userId: result.data?.id ?? "",
            //           organizationId: result.data?.organizationId ?? "",
            //           role: result.data?.role ?? "Admin",
            //         }),
            //       );
            //     });

            //   return;
            // }
          },
          error(err) {
            switch (err.code) {
              case "BE_ERROR":
                showBeFieldErrors(err.err, t("errorAddUserToastText"));
                break;

              case "ACTION_FAILED":
                toastStore.toast.error({ msg: t("errorAddUserToastText") });
                break;
              case "STATUS_QUERY_ERROR":
                resetAndClose();
                toastStore.toast.error({ msg: t("errorFailedToUpdateUserTable") });
            }
          },
        },
      );
    },
    [
      isAdmin,
      onEvent,
      onSuccessCallback,
      organizationId,
      resetAndClose,
      submitDisabled,
      t,
      userData,
    ],
  );

  const toggleModal = () => setShowModal(prevValue => !prevValue);

  useImperativeHandle(
    ref,
    () => ({
      toggleModal,
    }),
    [],
  );

  return (
    <CreateNewSideModal
      title={t("addUserTitle")}
      submitButton={{
        onClick: onSubmit,
        text: t("buttonAdd"),
        disabled: submitDisabled,
      }}
      show={showModal}
      closeModal={{
        onClick: resetAndClose,
        disabled: inLoadingState,
      }}
      data-testid={"AddUserModal"}
      isLoading={inLoadingState}
    >
      <Form.User
        formMode="Add"
        entityData={userData}
        setEntityData={setUserData}
        onSubmit={onSubmit}
        allowedUserRoles={NotNullOrUndefined(appConfig?.forms.addModal.users.allowedUserRoles)}
        editableFields={inLoadingState ? DISABLE_FORM_EDITING : EVERY_FORM_FIELD_EDITABLE}
      >
        {/*
          //TODO NEW_BACKEND
        <AvatarGroup
          loading={false}
          editing={true}
          firstName={undefined}
          avatarForCreate={imageData === undefined}
          imageSrc={imageData}
          onSave={(image?: string) => {
            const [, , imgData] = image?.match(/data:(.*);base64,(.*)/) ?? [];

            return Promise.resolve(setImageData(imgData));
          }}
        /> */}
      </Form.User>
    </CreateNewSideModal>
  );
});
