import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { DevicesTable } from "../common/DevicesTable";
import { useDevicesTableStateContext } from "./context";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

export function AllDevicesTable() {
  const tableContext = useDevicesTableStateContext();
  const { appConfig } = useGlobalConfigContext();

  return (
    <DevicesTable
      appliedColumns={NotNullOrUndefined(appConfig?.components.tables.Devices.Index.columnList)}
      devicesTableStateContext={tableContext}
      devicesFilterOptions={appConfig?.components.tables.Devices.Index.filterOptions}
    />
  );
}
