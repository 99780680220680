import { TestResponseModel } from "api/query/models/TestResponseModel";
import { useCallback } from "react";
import { sortTestFormFields } from "./sortTestFormFields";
import { TestFormModel } from "components/Forms/Test/model";
import { MeasurementItemUIModel, MeasurementItemUIType } from "models/TestModels";
import { MeasurementValueModel, invalidMeasurementKeys } from "./model";
import { useGetUserPreferredMeasurementUnits } from "../useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { getCorrectedValueForCreatinine } from "utils/converters/unitsConverter";
import { useUrineAlbuminValueConvertor } from "../useUrineAlbuminValueConvertor";
import { useRegionDateTimeFormatter } from "../useRegionDateTimeFormatter";

function useParseValue() {
  const { serumCreatinineUnit } = useGetUserPreferredMeasurementUnits();
  const [getUrineAlbuminValue] = useUrineAlbuminValueConvertor();

  const parseValue = useCallback(
    (type: MeasurementItemUIType, value: number | string) => {
      switch (type) {
        case "SerumCreatinine":
          return getCorrectedValueForCreatinine(value, "mg/dL", serumCreatinineUnit);

        case "UrineAlbumin":
          return getUrineAlbuminValue(value.toString());

        default:
          return value;
      }
    },
    [getUrineAlbuminValue, serumCreatinineUnit],
  );

  return [parseValue] as const;
}

export function useMapTestResponseModelToTestFormModel() {
  const [parseValue] = useParseValue();
  const [, , dateTimeFormatter] = useRegionDateTimeFormatter();

  const setStartTime = useCallback(
    (date: string) => {
      const tempDate = new Date(dateTimeFormatter(date, "en-US"));

      tempDate.setSeconds(new Date(date).getSeconds());
      tempDate.setMilliseconds(new Date(date).getMilliseconds());

      return tempDate;
    },
    [dateTimeFormatter],
  );

  const convertToTestFormModel = useCallback(
    (test: TestResponseModel): TestFormModel => ({
      measurements: test.measurements.map(measurement => {
        const items: MeasurementItemUIModel[] = [];
        for (const [key, value] of Object.entries(measurement.item)) {
          const measurementValue = value as MeasurementValueModel;
          const measurementKey = key as MeasurementItemUIType;
          if (!invalidMeasurementKeys.includes(measurementKey)) {
            items.push({
              value: parseValue(measurementKey, measurementValue.Value).toString(),
              unit: measurementValue.Unit,
              type: measurementKey ?? "None",
            });
          }
        }
        sortTestFormFields(measurement.measurementType, items);
        return {
          measurementType: measurement.measurementType ?? "None",
          items: items.map(item => ({
            ...item,
          })),
        };
      }),
      measurementTime: setStartTime(test.measurementTime),
      patientId: test.patient.id,
      rowVersion: test.rowVersion,
      organizationId: test.organizationId,
      id: test.id,
    }),
    [setStartTime, parseValue],
  );

  return [convertToTestFormModel] as const;
}
