import { MeasurementItemType } from "api/event";
import { MeasurementTypes } from "models/TestModels";
import { useHcpPatientsMeasurementsLaboratoryContext } from "pages/HCPPage/Details/LaboratoryContext";
import { TestsTable } from "../common/TestsTable";
import { TestsTableColumns } from "../common/TestsTable/model";
import { useGlobalConfigContext } from "context/GlobalConfigContext";

interface PatientTestsPerHcpTableProps {
  appliedColumns: TestsTableColumns[];
  measurementType?: MeasurementTypes;
  measurementTypeItem?: MeasurementItemType;
}

export function PatientTestsPerHcpTable({
  appliedColumns,
  measurementType,
  measurementTypeItem,
}: PatientTestsPerHcpTableProps) {
  const tableContext = useHcpPatientsMeasurementsLaboratoryContext();
  const { appConfig } = useGlobalConfigContext();

  return (
    <TestsTable
      showTrend={appConfig?.components.tables.Patient.PatientPerHcpTable.showTrending}
      appliedColumns={appliedColumns}
      testsTableStateContext={tableContext}
      measurementType={measurementType}
      measurementTypeItem={measurementTypeItem}
    />
  );
}
