import { PersonModel } from "models/PersonModels/PersonModel";
import { useApi } from "./useApi";
import { useTranslation } from "react-i18next";
import { CarnaApiEvent } from "config/apiEvent";

export function useResendSignUp(onSuccess: () => void, user?: PersonModel) {
  const { t } = useTranslation("modals", { keyPrefix: "ResendInviteModal" });

  const { role } = user ?? {};

  const [resendServerResponse, sendInvitation] = useApi(
    CarnaApiEvent[role ?? "Admin"].resendInvite,
    {
      toastText: {
        successText: t("success"),
        errorText: t("error"),
      },
      onResponseCallback: {
        onSuccess,
      },
    },
  );

  return [resendServerResponse, sendInvitation] as const;
}
