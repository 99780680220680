/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrganizationPatientModel } from './OrganizationPatientModel';
import {
    OrganizationPatientModelFromJSON,
    OrganizationPatientModelFromJSONTyped,
    OrganizationPatientModelToJSON,
} from './OrganizationPatientModel';

/**
 * 
 * @export
 * @interface GraphPatientResponseModel
 */
export interface GraphPatientResponseModel {
    /**
     * 
     * @type {OrganizationPatientModel}
     * @memberof GraphPatientResponseModel
     */
    organization: OrganizationPatientModel;
}

/**
 * Check if a given object implements the GraphPatientResponseModel interface.
 */
export function instanceOfGraphPatientResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organization" in value;

    return isInstance;
}

export function GraphPatientResponseModelFromJSON(json: any): GraphPatientResponseModel {
    return GraphPatientResponseModelFromJSONTyped(json, false);
}

export function GraphPatientResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GraphPatientResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization': OrganizationPatientModelFromJSON(json['Organization']),
    };
}

export function GraphPatientResponseModelToJSON(value?: GraphPatientResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Organization': OrganizationPatientModelToJSON(value.organization),
    };
}

