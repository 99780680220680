import { Enum } from "utils/createGlobalConfigStore";
import { Option } from "libs/ui/Select/Select.model";

// export function customEnumsToSelectOptions<ListType extends symbol>(data: Enum[]) {
//   return data.map(el => ({
//     title: el.N,
//     value: String(el.V),
//   })) as unknown as readonly Option<UniqueType<string, ListType>>[];
// }

export type ReturnTypeOfCustomEnums<T extends (...params: any[]) => any> = T extends (
  ...params: any[]
) => readonly Option<infer B>[]
  ? B
  : never;

export function customEnumsToSelectOptions(data: Enum[]) {
  return data.map(el => ({
    title: el.N,
    value: el.V,
  })) as unknown as readonly Option<number>[];
}
