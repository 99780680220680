/* tslint:disable */
 
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VisibilityType = {
    Optional: 'Optional',
    Required: 'Required',
    Disabled: 'Disabled'
} as const;
export type VisibilityType = typeof VisibilityType[keyof typeof VisibilityType];


export function VisibilityTypeFromJSON(json: any): VisibilityType {
    return VisibilityTypeFromJSONTyped(json, false);
}

export function VisibilityTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisibilityType {
    return json as VisibilityType;
}

export function VisibilityTypeToJSON(value?: VisibilityType | null): any {
    return value as any;
}

