import * as React from "react";
import { SVGProps } from "react";
const SvgHcp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 3a.75.75 0 0 0-1.5 0v.25H3a.75.75 0 0 0-.75.75v2.667c0 1.598.595 3.14 1.667 4.284a5.639 5.639 0 0 0 3.333 1.747V16c0 1.598.595 3.14 1.667 4.284 1.074 1.146 2.542 1.8 4.083 1.8 1.541 0 3.009-.654 4.083-1.8C18.155 19.14 18.75 17.598 18.75 16v-.354a2.751 2.751 0 1 0-1.5 0V16c0 1.23-.459 2.402-1.262 3.258-.8.855-1.877 1.325-2.988 1.325s-2.187-.47-2.988-1.325C9.209 18.402 8.75 17.231 8.75 16v-3.302a5.638 5.638 0 0 0 3.333-1.747c1.072-1.144 1.667-2.686 1.667-4.284V4a.75.75 0 0 0-.75-.75h-1.75V3a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-.25h1v1.917c0 1.23-.459 2.402-1.262 3.258-.8.855-1.877 1.325-2.988 1.325s-2.187-.47-2.988-1.325C4.209 9.069 3.75 7.897 3.75 6.667V4.75h1V5a.75.75 0 0 0 1.5 0V3Zm10.5 10a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgHcp;
