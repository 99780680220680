import { AncestryFilter } from "api/report_service/models/AncestryFilter";
import { GenderFilter } from "api/report_service/models/GenderFilter";
import { PatientAgeRange } from "api/report_service/models/PatientAgeRange";
import i18n from "i18n";

export const GENDER_OPTIONS = (["Male", "Female", "NotAvailable"] as const).map(key => ({
  title: i18n.t(`DashboardPage.DashboardMapFilter.gender.${key}`, { ns: "translation" }),
  value: key as GenderFilter,
}));

export const AGE_OPTIONS = (
  [
    "From18To24",
    "From25To29",
    "From30To39",
    "From40To49",
    "From50To59",
    "From60To69",
    "From70To79",
    "From80To89",
    "From90To99",
    "From100",
    "NotAvailable",
  ] as const satisfies PatientAgeRange[]
).map(key => ({
  title: i18n.t(`DashboardPage.DashboardMapFilter.age.${key}`, { ns: "translation" }),
  value: key satisfies PatientAgeRange,
}));

export const ANCESTRY_OPTIONS = (["Black", "Other", "NotAvailable"] as const).map(key => ({
  title: i18n.t(`DashboardPage.DashboardMapFilter.ancestry.${key}`, { ns: "translation" }),
  value: key as AncestryFilter,
}));
