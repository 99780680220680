/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreatinineModel } from './CreatinineModel';
import {
    CreatinineModelFromJSON,
    CreatinineModelFromJSONTyped,
    CreatinineModelToJSON,
} from './CreatinineModel';
import type { EgfrModel } from './EgfrModel';
import {
    EgfrModelFromJSON,
    EgfrModelFromJSONTyped,
    EgfrModelToJSON,
} from './EgfrModel';
import type { LimitsModel } from './LimitsModel';
import {
    LimitsModelFromJSON,
    LimitsModelFromJSONTyped,
    LimitsModelToJSON,
} from './LimitsModel';
import type { ViewOptionsModel } from './ViewOptionsModel';
import {
    ViewOptionsModelFromJSON,
    ViewOptionsModelFromJSONTyped,
    ViewOptionsModelToJSON,
} from './ViewOptionsModel';

/**
 * 
 * @export
 * @interface MeasurementsModel
 */
export interface MeasurementsModel {
    /**
     * 
     * @type {CreatinineModel}
     * @memberof MeasurementsModel
     */
    creatinine: CreatinineModel;
    /**
     * 
     * @type {EgfrModel}
     * @memberof MeasurementsModel
     */
    egfr: EgfrModel;
    /**
     * 
     * @type {ViewOptionsModel}
     * @memberof MeasurementsModel
     */
    viewOptions: ViewOptionsModel;
    /**
     * 
     * @type {LimitsModel}
     * @memberof MeasurementsModel
     */
    limits: LimitsModel;
}

/**
 * Check if a given object implements the MeasurementsModel interface.
 */
export function instanceOfMeasurementsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "creatinine" in value;
    isInstance = isInstance && "egfr" in value;
    isInstance = isInstance && "viewOptions" in value;
    isInstance = isInstance && "limits" in value;

    return isInstance;
}

export function MeasurementsModelFromJSON(json: any): MeasurementsModel {
    return MeasurementsModelFromJSONTyped(json, false);
}

export function MeasurementsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creatinine': CreatinineModelFromJSON(json['Creatinine']),
        'egfr': EgfrModelFromJSON(json['Egfr']),
        'viewOptions': ViewOptionsModelFromJSON(json['ViewOptions']),
        'limits': LimitsModelFromJSON(json['Limits']),
    };
}

export function MeasurementsModelToJSON(value?: MeasurementsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Creatinine': CreatinineModelToJSON(value.creatinine),
        'Egfr': EgfrModelToJSON(value.egfr),
        'ViewOptions': ViewOptionsModelToJSON(value.viewOptions),
        'Limits': LimitsModelToJSON(value.limits),
    };
}

