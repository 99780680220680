import { CommittedToPrivacyCard } from "./CommittedToPrivacyCard";
import { useTranslation } from "react-i18next";
import "./CommittedToPrivacy.scss";
import { v4 } from "uuid";
import { policyPaths } from "router";

export function CommittedToPrivacy() {
  const { t } = useTranslation("translation", { keyPrefix: "CommittedToPrivacy" });

  const committedToPrivacyLinks = [
    { title: t("privacy_policy"), link: policyPaths.privacyPolicy },
    { title: t("terms_and_conditions"), link: policyPaths.termsAndConditions },
    { title: t("cookie_policy"), link: policyPaths.cookiePolicy },
    {
      title: t("uk_gdpr_compliance"),
      link: "https://www.naqcyber.com/non-uk-gdpr-certs/carnahealth",
    },
    {
      title: t("eu_gdpr_compliance"),
      link: "https://www.naqcyber.com/non-eu-gdpr-certs/carna-health",
    },
  ] as const;

  return (
    <div className="CommittedToPrivacy">
      <h3 className="CommittedToPrivacy__title">{t("title")}</h3>
      <p className="CommittedToPrivacy__description">{t("description")}</p>
      <ul className="CommittedToPrivacy__list">
        {committedToPrivacyLinks.map(item => (
          <li key={v4()}>
            <CommittedToPrivacyCard title={item.title} externalLink={item.link} />
          </li>
        ))}
      </ul>
    </div>
  );
}
