import classNames from "classnames";
import {
  EmptyAdmins,
  EmptyDevices,
  EmptyFolder,
  EmptyHcp,
  EmptyOrgs,
  EmptyPatients,
  EmptyTests,
} from "libs/ui";
import React from "react";
import { EmptyStateIconProps } from "../PageTemplate/ParentPage/model";

const iconMap = {
  Admins: <EmptyAdmins />,
  Users: <EmptyFolder />,
  Organizations: <EmptyOrgs />,
  Patients: <EmptyPatients />,
  HCP: <EmptyHcp />,
  Tests: <EmptyTests />,
  Devices: <EmptyDevices />,
} as const;

export function EmptyStateIcon({ variant, className }: EmptyStateIconProps) {
  return React.cloneElement(iconMap[variant], { className: classNames(className) });
}
