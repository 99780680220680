import { SVGProps } from "react";

export const EmptyFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="89"
    height="64"
    viewBox="0 0 89 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.9892 2.58586C40.9892 1.15315 39.836 0 38.4033 0H13.8202C12.3875 0 11.2343 1.15315 11.2343 2.58586C11.2343 4.01856 10.0812 5.17172 8.64849 5.17172H8.50871C7.076 5.17172 5.92285 6.32487 5.92285 7.75758V61.4141C5.92285 62.8469 7.076 64 8.50871 64H80.2488C81.6815 64 82.8347 62.8469 82.8347 61.4141V7.77505C82.8347 6.34234 81.6815 5.18919 80.2488 5.18919H43.5925C42.1598 5.17172 40.9892 4.01856 40.9892 2.58586Z"
      fill="#B699FE"
    />
    <path
      d="M80.2488 5.17172H43.5925C42.1598 5.17172 41.0067 4.01856 41.0067 2.58586C41.0067 1.15315 39.8535 0 38.4208 0H13.8202C12.3875 0 11.2343 1.15315 11.2343 2.58586C11.2343 4.01856 10.0812 5.17172 8.64849 5.17172H8.50871C7.076 5.17172 5.92285 6.32487 5.92285 7.75757V9.01556C5.92285 7.58286 7.076 6.4297 8.50871 6.4297H8.64849C10.0812 6.4297 11.2343 5.27655 11.2343 3.84384C11.2343 2.41114 12.405 1.25798 13.8202 1.25798H38.4033C39.836 1.25798 40.9892 2.41114 40.9892 3.84384C40.9892 5.27655 42.1423 6.4297 43.575 6.4297H80.2313C81.664 6.4297 82.8172 7.58286 82.8172 9.01556V7.75757C82.8347 6.34234 81.6815 5.17172 80.2488 5.17172Z"
      fill="#CFBCFF"
    />
    <path
      d="M86.1716 10.4658H2.58546C1.03045 10.4658 -0.157648 11.8112 0.017072 13.3487L5.66053 61.7112C5.81778 63.0216 6.91852 64.0001 8.22892 64.0001H80.5456C81.856 64.0001 82.9742 63.0216 83.114 61.7112L88.7574 13.3487C88.9147 11.8286 87.7091 10.4658 86.1716 10.4658Z"
      fill="#CFBCFF"
    />
    <path
      d="M0.0694881 13.7504C0.296624 12.5798 1.32747 11.6538 2.60293 11.6538H86.1891C87.4645 11.6538 88.4779 12.5623 88.7225 13.7504L88.7749 13.366C88.9496 11.8285 87.7441 10.4832 86.2065 10.4832H2.58546C1.03045 10.4832 -0.157648 11.8285 0.017072 13.366L0.0694881 13.7504Z"
      fill="#E9E0FF"
    />
  </svg>
);
