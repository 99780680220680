import { PropsWithChildren, ReactNode } from "react";
import "./PatientInfoSectionItem.scss";

interface Props {
  head: ReactNode;
  body: ReactNode;
  label: string;
}

export function PatientInfoSectionItem({ head, body, label }: PropsWithChildren<Props>) {
  return (
    <div className="PatientInfoSectionItem">
      <div className="PatientInfoSectionItem__head">{head}</div>
      <div className="PatientInfoSectionItem__body">{body}</div>
      <div className="PatientInfoSectionItem__label">{label}</div>
    </div>
  );
}
