import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import { DocumentWrapper } from "../DocumentWrapper";
import "../Policy.scss";

export function PrivacyPolicyEn() {
  // const { t } = useTranslation({ keyPrefix: "PrivacyPolicyDocument" });
  const [dateFormatter] = useRegionDateTimeFormatter();

  // const links = {
  //   applicationSettings: {
  //     to: "#",
  //     title: t("applicationSettings"),
  //   },
  //   //TO DO: change support email when provided
  //   supportEmail: {
  //     to: "#",
  //     title: t("supportEmail"),
  //   },
  //   securityEmail: {
  //     to: "#",
  //     title: t("securityEmail"),
  //   },
  //   cookiePolicy: {
  //     to: "#",
  //     title: t("cookiePolicy"),
  //   },
  // };

  //TO DO: Find a way to handle translation of documents

  return (
    <DocumentWrapper title={"Privacy Policy"} date={dateFormatter(new Date(2023, 9, 23))}>
      <div className="Policy">
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Introduction</h2>
          <p>
            Carna Health respects the privacy of its customers, suppliers and partners. We have
            therefore formulated and implemented a policy on complete transparency regarding the
            processing of personal data, its purpose(s) and the possibilities to exercise your legal
            rights in the best possible way. For employees, we have formulated a separate privacy
            policy, available upon employment and upon request. Not Applicable
          </p>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Definitions</h2>
          <ul>
            <li>
              Party responsible for processing personal data: Carna Health; with registered address
              at 131 Dartmouth Street, 3rd Floor , in United States of America; company registration
              number 123456 and Data Protection Officer David Vogel who can be reached at
              david.vogel@carna.health (the “Controller”).
            </li>
            <li>
              Data Protection Authority: The Data Protection Authority of United States of America.
            </li>
            <li>
              Data Protection laws:
              <ul>
                <li>
                  For European citizens or residents, the EU GDPR 2018; the EU e-privacy directive
                  2002 (soon to be replaced by the EU e-privacy regulation);
                </li>
                <li>
                  For UK citizens or residents, the UK GDPR 2020 and the UK Data Protection Act 2018
                </li>
                <li>and the national laws of the countries where we operate.</li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Collection of data</h2>
          <ul>
            <li>Your personal data will be collected by Carna Health and its data processors.</li>
            <li>
              Personal data means any information relating to an identified or identifiable natural
              person (`data subject`).
            </li>
            <li>
              An identifiable natural person is one who can be identified, directly or indirectly,
              in particular by reference to an identifier such as a name, an identification number,
              location data, an online identifier or to one or more factors specific to the
              physical, physiological, genetic, mental, economic, cultural or social identity of
              that natural person.
            </li>
          </ul>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">The types of personal data we may process:</h2>
          <table className="Policy__table">
            <thead>
              <tr>
                <th>
                  <b>Business process</b>
                </th>
                <th>
                  <b>Type</b>
                </th>
                <th>
                  <b>Data subject</b>
                </th>
                <th>
                  <b>Legal basis</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Website</td>
                <td>Contracts, Identification, Location</td>
                <td>Customers</td>
                <td>Consent</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>Not applicable</td>
                <td>Not applicable</td>
                <td>Legitimate interest</td>
              </tr>
              <tr>
                <td>Storage and exchange of documents</td>
                <td>Not applicable</td>
                <td>Not applicable</td>
                <td>Legitimate interest</td>
              </tr>
              <tr>
                <td>Delivery of goods and services</td>
                <td>Not applicable</td>
                <td>Not applicable</td>
                <td>Performance of a contract</td>
              </tr>
              <tr>
                <td>Financial and business administration</td>
                <td>Not applicable</td>
                <td>Not applicable</td>
                <td>Legitimate interest</td>
              </tr>
              <tr>
                <td>Marketing</td>
                <td>Contracts, Identification, Location</td>
                <td>Customers</td>
                <td>Consent</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Purposes</h2>
          <p>Carna Health processes personal data for one or more of the following purposes:</p>
          <ul>
            <li>Customer, employee, contractor, partner or supplier management</li>
            <li>Business and financial administration</li>
            <li>Direct marketing</li>
            <li>Delivery of goods or services</li>
            <li>Work planning</li>
          </ul>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">
            How we collect, store or otherwise process your data:
          </h2>
          <p>
            The following business processes describe how we may collect, store or otherwise process
            the types of personal information set out in the table above:
          </p>
          <ul>
            <li>
              Collection of cookies, subscription to newsletter or filling out the contact form on
              the website(s);
            </li>
            <li>
              Analyse trends and profiles, for our legitimate interest to aim to enhance, modify,
              personalise and improve our services and communications for the benefit of our
              customers;
            </li>
            <li>
              Process and respond to support requests, enquiries and complaints received from you
              through use of business email;
            </li>
            <li>
              Provide services and products requested and/or purchased by you and to communicate
              with you about such services and/or products. We do this as necessary in order to
              carry out a contract with you and in accordance with our legitimate interest to
              operate a business;
            </li>
            <li>
              Carry out administrative activities such as invoicing and collecting payments either
              locally on devices or using cloud-services;
            </li>
            <li>
              Store and exchange personal information contained in documents through email and
              cloud-services;
            </li>
            <li>Marketing and customer acquisition through email or using cloud-services.</li>
          </ul>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Sharing data with third parties</h2>
          <p>
            We may have to share your data with third parties, including third-party service
            providers. We require third parties to respect the security of your data and to treat it
            in accordance with the law.
          </p>

          <p>
            We may transfer your Personal Data outside United States of America. If we do, you can
            expect a similar degree of protection in respect of your Personal Data.
          </p>

          <p>
            We will only share your Personal Data with third parties in accordance with the GDPR and
            as outlined in the legal justification table above.
          </p>

          <p>
            We share your personal data with the following enterprise third parties. We also share
            your data with SME third parties, details of which are available upon request. You will
            be notified when we have engaged with a new third party recipient of your personal data.
          </p>

          <p>Not Applicable</p>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">International data transfers</h2>
          <p>
            The third parties we have engaged for the abovementioned business process may transfer
            your personal information to outside of your jurisdiction. Carna Health’s third party
            processors take all necessary measures to ensure the confidentiality, availability and
            integrity of personal data and to comply with the GDPR with regards to international
            data transfers. The international nature of its compliance certifications, as well as
            far-reaching technical security measures (including but not limited to encryption of the
            personal data, making the data illegible to an unauthorised recipient) are sufficient to
            ensure that the data subjects continue to benefit from the fundamental rights they are
            entitled to under the GDPR.
          </p>

          <p>
            Where Carna Health transfers data to third countries, it relies on the following legal
            grounds for international data transfers:
          </p>

          <ul>
            <li>An Adequacy Decision in accordance with article 45 of the GDPR</li>
            <li>
              In the absence of an Adequacy Decision, appropriate safeguards in the form of Standard
              Contractual Clauses or Binding Corporate Rules.
            </li>
          </ul>

          <p>
            In the event that Carna Health is reliant on Standard Contractual Clauses for the
            legality of its international data transfer, it ensures that the Processor or
            Subprocessor takes supplementary security measures to safeguard the international data
            transfer with one or more of the following measures:
          </p>

          <ul>
            <li>Encryption;</li>
            <li>Anonymisation;</li>
            <li>Pseudonymisation.</li>
          </ul>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Storage and protection of data</h2>
          <p>
            Your data is protected by Carna Health and its processors in pursuance to all legal
            requirements set by the relevant data processing laws. Carna Health has taken technical
            and organizational security measures to protect your data and requires its data
            processors to meet the same requirements. Carna Health has signed processing agreements
            with its processors to ensure an adequate level of data protection.
          </p>
          <p>
            The following security measures are taken by Carna Health to protect your personal data
            in the course of the listed business processes:
          </p>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Organisational security measures</h2>
          <h3>Staff</h3>
          <p>
            Carna Health staff members are required to conduct themselves in a manner consistent
            with Carna Health's guidelines regarding confidentiality, business ethics, appropriate
            usage, and professional standards. All staff members undergo appropriate background
            checks prior to hiring and sign a confidentiality agreement outlining their
            responsibility in protecting customer data.
          </p>

          <p>
            We continuously train staff members on best security practices, including how to
            identify social hacks, phishing scams, and hackers.
          </p>

          <h3>Access controls</h3>

          <p>
            Carna Health maintains your data privacy by allowing only authorized individuals access
            to information when it is critical to complete tasks for you. Carna Health staff members
            will not process customer data without authorization.
          </p>

          <h3>Data hosting</h3>

          <p>
            As a rule, data is hosted within countries and areas that provide a substantially
            similar level of protection as data subjects have under the GDPR. To ensure this, we
            rely on Adequacy Decisions as a legal basis for our international data transfers. In
            exceptional circumstances, where data is transferred to a country or area not subject to
            an Adequacy Decision, we rely on Standard Contractual Clauses with the recipient and
            take supplementary security measures to secure this data transfer, such as
            anonymisation.
          </p>

          <h3>Physical security</h3>

          <p>
            The data centres on which personal data is hosted are secured and monitored 24/7 and
            physical access to facilities is strictly limited to select staff.
          </p>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Technical security measures</h2>
          <p>
            All devices which are used to access personal data for which we are responsible are
            secured with antivirus software, firewalls, encryption and access management. We
            regularly update operating systems and software to ensure vulnerabilities cannot be
            exploited.
          </p>

          <p>
            We carry out regular vulnerability scanning of our website and have engaged credentialed
            external auditors to verify the adequacy of our security and privacy measures.
          </p>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Your rights regarding information</h2>
          <p>
            Each data subject has the right to information on and access to, and rectification,
            erasure and restriction of processing of their personal data, as well as the right to
            object to the processing and the right to data portability.You also have the right to
            request that you are not made subject to decision making based solely on automated
            processes, including profiling, if these decisions would have a significant effect on
            you.
          </p>

          <p>
            You can exercise these rights by contacting us at the following email address:
            office@carna.health. If we have any doubts as to your identity, we may request you to
            provide us with proof of identification, such as through sending us a copy of your valid
            ID. Ensure that you write “Data Request” in the subject line of your email.
          </p>

          <p>
            Within one month of the submitted request, you will receive an answer from us. We will
            not charge you for submitting your request unless the request is manifestly unfounded or
            otherwise unreasonable in its nature. Depending on the complexity and the number of the
            requests this period may be extended to two months.
          </p>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Marketing</h2>
          <ul>
            <li>
              You may receive commercial offers from Carna Health. If you do not wish to receive
              them (anymore), please send us an email to the following address: office@carna.health
              and ensure that you write “DataOpt-Out” in the subject line of your email.
            </li>
            <li>Your personal data will not be used by our partners for commercial purposes.</li>
            <li>
              If you encounter any personal data from other data subjects while visiting our
              website, you are to refrain from collection, any unauthorized use or any other act
              that constitutes an infringement of the privacy of the data subject(s) in question.
              The collector is not responsible in these circumstances.
            </li>
          </ul>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Data retention</h2>
          <p>
            The collected data are used and retained for the duration determined by law. You may, at
            any time, request your data to be deleted from any Carna Health account, system or other
            data processing medium in accordance with the process described above.
          </p>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Applicable law</h2>
          <p>
            These conditions are governed by the laws and regulations of the country where we are
            headquartered. The court in the district where we are headquartered has the sole
            jurisdiction if any dispute regarding these conditions may arise, save when a legal
            exception applies.
          </p>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Children's Data</h2>
          <p>
            We do not knowingly process children's data, unless specifically stated in this Privacy
            Policy. If you have concerns about or knowledge of a child using our services, products,
            websites or apps without parental consent, please contact our DPO via
            david.vogel@carna.health to ensure we can take appropriate action as soon as possible.
          </p>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">Contact</h2>
          <p>
            For questions about this privacy policy, product information or information about the
            website itself, please contact: office@carna.health.
          </p>
        </div>
        <div className="Policy__section">
          <h2 className="Policy__subtitle">International data transfers</h2>
          <p>Not Applicable Not Applicable</p>
        </div>
      </div>
    </DocumentWrapper>
  );
}
