import * as React from "react";
import { SVGProps } from "react";
const SvgCornerDownLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.75 4a.75.75 0 0 0-1.5 0v7A3.25 3.25 0 0 1 16 14.25H5.81l3.72-3.72a.75.75 0 1 0-1.06-1.06l-5 5a.748.748 0 0 0 0 1.06l5 5a.75.75 0 0 0 1.06-1.06l-3.72-3.72H16A4.75 4.75 0 0 0 20.75 11V4Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCornerDownLeft;
