/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrendDetailsFieldModel
 */
export interface TrendDetailsFieldModel {
    /**
     * 
     * @type {boolean}
     * @memberof TrendDetailsFieldModel
     */
    show?: boolean;
}

/**
 * Check if a given object implements the TrendDetailsFieldModel interface.
 */
export function instanceOfTrendDetailsFieldModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TrendDetailsFieldModelFromJSON(json: any): TrendDetailsFieldModel {
    return TrendDetailsFieldModelFromJSONTyped(json, false);
}

export function TrendDetailsFieldModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrendDetailsFieldModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'show': !exists(json, 'show') ? undefined : json['show'],
    };
}

export function TrendDetailsFieldModelToJSON(value?: TrendDetailsFieldModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'show': value.show,
    };
}

