import { GlucoseSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/GlucoseSectionDetails";
import { BloodPressureSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/BloodPressureSectionDetails";
import { MeasurementItemUIType, MeasurementUIType, SideDetailsModel } from "models/TestModels";
import { EGFRSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/EGFRSectionDetails";
import { SerumCreatinineSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/SerumCreatinineSectionDetails";
import { UACRSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/UACRSectionDetails";
import { UrineAlbuminSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/UrineAlbuminSectionDetails";
import { UrineCreatinineSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/UrineCreatinineSectionDetails";
import { BMISectionDetails } from "components/MeasurementSideDetails/SideDetails/components/BMISectionDetails";
import { WeightSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/WeightSectionDetails";
import { HeightSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/HeightSectionDetails";
import { SQUACRSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/SQUACRSectionDetails";
import { SQUrineAlbuminSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/SQUrineAlbuminSectionDetails";
import { SQUrineCreatinineSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/SQUrineCreatinineSectionDetails";
import { SampledByDeviceType } from "models/DeviceModels";

export type MeasurementTabType = MeasurementItemUIType | MeasurementUIType;
// MeasurementItemUIType is used for single measurement tab, while we use MeasurementUIType in case when are on "All" tabs

interface TestsSideDetailsProps {
  measurementTabType: MeasurementTabType | undefined;
  sideDetails?: SideDetailsModel;
  loading?: boolean;
  showTrend?: boolean;
}

export function TestsSideDetails({
  measurementTabType,
  sideDetails,
  loading,
  showTrend,
}: Readonly<TestsSideDetailsProps>) {
  switch (measurementTabType) {
    case "SerumCreatinine":
      return (
        <>
          <SerumCreatinineSectionDetails
            showTrend={showTrend}
            data={sideDetails}
            loading={loading}
          />
          <EGFRSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
        </>
      );
    case "Egfr":
      return (
        <>
          <EGFRSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
          <SerumCreatinineSectionDetails data={sideDetails} loading={loading} />
        </>
      );
    case "Glucose":
      return <GlucoseSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />;
    case "BloodPressure":
    case "Systolic":
    case "Diastolic":
      return (
        <BloodPressureSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
      );
    case "Uacr":
    case "UACR":
      return (
        <>
          <UACRSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
          <UrineCreatinineSectionDetails
            showTrend={showTrend}
            data={sideDetails}
            loading={loading}
          />
          <UrineAlbuminSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
        </>
      );
    case "UrineCreatinine":
      return (
        <>
          <UrineCreatinineSectionDetails
            showTrend={showTrend}
            data={sideDetails}
            loading={loading}
          />
          <UrineAlbuminSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
          <UACRSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
        </>
      );
    case "UrineAlbumin":
      return (
        <>
          <UrineAlbuminSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
          <UrineCreatinineSectionDetails
            showTrend={showTrend}
            data={sideDetails}
            loading={loading}
          />
          <UACRSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
        </>
      );
    case "BMI":
    case "Bmi":
      return (
        <>
          <BMISectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
          <HeightSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
          <WeightSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
        </>
      );
    case "Height":
      return (
        <>
          <HeightSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
          <BMISectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
        </>
      );
    case "Weight":
      return (
        <>
          <WeightSectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
          <BMISectionDetails showTrend={showTrend} data={sideDetails} loading={loading} />
        </>
      );

    case "SemiQuantitativeUacr":
    case "SemiQuantitativeUACR":
      return (
        <>
          <SQUACRSectionDetails data={sideDetails} loading={loading} />
          {sideDetails?.test.sampledByDevice === SampledByDeviceType.Neodocs ? null : (
            <>
              <SQUrineAlbuminSectionDetails data={sideDetails} loading={loading} />
              <SQUrineCreatinineSectionDetails data={sideDetails} loading={loading} />
            </>
          )}
        </>
      );

    default:
      throw Error(`${measurementTabType} is not supported`);
  }
}
