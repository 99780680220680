import { NoWidgets } from "./NoWidgets";
import "./DashboardStats.scss";
import { useDashboardStatsWidgetContext } from "./DashboardStatsWidgetContext";
import { WidgetsDnD } from "./WidgetsDnD";
import { AddWidgetModal } from "./AddWidgetModal";

export function DashboardStats() {
  const { showAddWidgetModal } = useDashboardStatsWidgetContext();

  return (
    <div className="DashboardStats">
      <WidgetsDnD />
      <NoWidgets />
      {showAddWidgetModal ? <AddWidgetModal /> : null}
    </div>
  );
}
