import { FieldLoader } from "libs/ui/FieldLoader";
import "./RangeTable.scss";
import { RangeTableProps } from "./model";
import { RangeTableBody } from "./RangeTableBody";

export function RangeTable({
  labels,
  steps,
  value,
  loading,
  orientation = "horizontal",
}: Readonly<RangeTableProps>) {
  if (loading) {
    return (
      <div className="DetailsField">
        <FieldLoader />
      </div>
    );
  }

  return (
    <table className="RangeTable">
      <RangeTableBody labels={labels} steps={steps} value={value} orientation={orientation} />
    </table>
  );
}
