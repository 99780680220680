import { MeasurementIconType, TestMeasurements } from "models/TestModels";

export function getMeasurementIconType(measurement: TestMeasurements): MeasurementIconType {
  if (Object.keys(measurement)[0] === "SerumCreatinine" || Object.keys(measurement)[0] === "Egfr") {
    return "CreatinineEgfr";
  }

  if (
    Object.keys(measurement)[0] === "UACR" ||
    Object.keys(measurement)[0] === "SemiQuantitativeUACR"
  ) {
    return "Uacr";
  }

  if (Object.keys(measurement)[0] === "BMI") {
    return "Bmi";
  }

  return (Object.keys(measurement)[0] as MeasurementIconType) ?? "Stale";
}
