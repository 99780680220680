import * as AddModal from "components/AddModal/Test";
import { useTestsTableStateContext } from "components/AppTables/AllTestsTable/context";
import { ParentPage } from "components/PageTemplate/ParentPage";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { usePatientLaboratoryContext } from "pages/PatientsPage/Details/LaboratoryContext";
import { PropsWithChildren } from "react";
import { usePagesParentTableConfig } from "utils/hooks/Pages/usePagesParentTableConfig";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

export function TestsPage({ children }: PropsWithChildren<object>) {
  const { appConfig } = useGlobalConfigContext();
  const { userRole, isPatient } = useAdditionalAuthInformationContext();

  const tableContextPatient = usePatientLaboratoryContext();
  const tableContextAdminPartner = useTestsTableStateContext();

  const tableContext = isPatient ? tableContextPatient : tableContextAdminPartner;
  const [state] = tableContext.tableService;

  const { isTableEmpty } = usePagesParentTableConfig(tableContext, "items");

  return (
    <ParentPage
      variant="Tests"
      AddModal={
        NotNullOrUndefined(appConfig?.pages.TestsPage.AddModalAccess).some(
          role => role === userRole,
        ) ? (
          <AddModal.Test onSuccessCb={tableContext.fetch} />
        ) : undefined
      }
      isEmptyState={isTableEmpty}
      isTableFailed={state.matches("failure")}
      isTableLoading={state.matches("loading")}
      filtersCount={state.context.countFilters}
      onRefreshTable={tableContext.fetch}
    >
      {children}
    </ParentPage>
  );
}
