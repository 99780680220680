import classNames from "classnames";
import { PropsWithChildren } from "react";
import "./TableCardDescription.scss";

type TextType = "light" | "bold";

type TextLengthType = "truncate" | "full";

interface TableCardDescriptionProps {
  title?: string;
  className?: string;
  textType?: TextType;
  textLength?: TextLengthType;
  loading?: boolean;
}

export function TableCardDescription({
  title,
  textType,
  textLength = "full",
  className,
  loading,
  children,
}: PropsWithChildren<TableCardDescriptionProps>) {
  return (
    <p
      title={title}
      className={classNames(
        "TableCardDescription",
        { [`TableCardDescription--${textType}`]: textType },
        { [`TableCardDescription--${textLength}`]: textLength },
        { "TableCardDescription--loading": loading },
        className,
      )}
    >
      {loading ? null : children}
    </p>
  );
}
