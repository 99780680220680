import { AddModalForwardedProps } from "components/AddModal/model";
import { hasFilter } from "components/AppTables/utils";
import { EmptyStateIcon } from "components/EmptyStateIcon";
import { EmptyState } from "libs/ui";
import { TableUtils } from "libs/ui/Table/utils";
import { useRef } from "react";

import * as AddModal from "components/AddModal/Device";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useTranslation } from "react-i18next";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { BaseTable } from "../Table/BaseTable";
import { BodyRows } from "./BodyRows";
import "./DevicesTable.scss";
import { DevicesTableFilter } from "./DevicesTableFilter";
import { resolveAppliedColumns } from "./constants";
import { DevicesTableProps, UIDeviceFilters } from "./model";

export function DevicesTable({
  devicesTableStateContext,
  appliedColumns,
  onFilterToggle,
  searchField,
  addButton,
  devicesFilterOptions,
  initialValuesAsProps,
}: Readonly<DevicesTableProps>) {
  const { tableService, filterSideModelState, fetch } = devicesTableStateContext;
  const { t } = useTranslation("translation", { keyPrefix: "DevicesTable" });
  const [state] = tableService;
  const { isAdmin, isPatient } = useAdditionalAuthInformationContext();

  const addModalRef = useRef<AddModalForwardedProps>(null);
  const toggle = () => addModalRef?.current?.toggleModal();

  const can = useHasAccess();

  const columns = resolveAppliedColumns({
    appliedColumns,
    t,
    showUserIds: can("manage-device"),
    showActions: true,
  });

  const hasFilters = TableUtils.areFiltersApplied(state.context);
  const noTableData = state.matches("loaded") && !state.context.data.items.length && !hasFilters;

  const resolveShowFilterOption = (columnName: string) => {
    if (columnName === "organizationIds") {
      return isAdmin && hasFilter(columnName, NotNullOrUndefined(devicesFilterOptions));
    }

    return hasFilter(columnName, NotNullOrUndefined(devicesFilterOptions));
  };

  const onOpenFilterModal = (value: any) => filterSideModelState.openFilterModal(value);

  const resolveFilterApplied = (value: string) =>
    !!state.context.filters?.[value as keyof UIDeviceFilters]?.length;

  if (noTableData) {
    return (
      <>
        <EmptyState
          title={isPatient ? t("emptyTitleForPatient") : t("emptyTitle")}
          description={isPatient ? t("emptyDescriptionForPatient") : t("emptyDescription")}
          onCreate={toggle}
        >
          <EmptyStateIcon variant="Devices" />
        </EmptyState>

        <AddModal.Device
          ref={addModalRef}
          onSuccessCb={fetch}
          initialValuesAsProps={initialValuesAsProps}
        />
      </>
    );
  }

  return (
    <>
      <BaseTable
        tableService={tableService}
        className="DeviceTable"
        columns={columns}
        onOpenFilterModal={onOpenFilterModal}
        resolveFilterApplied={resolveFilterApplied}
        resolveShowFilterOption={resolveShowFilterOption}
        onFilterToggle={onFilterToggle}
        searchField={searchField}
        viewTableSelector={true}
        addButton={addButton}
      >
        <BodyRows appliedColumns={appliedColumns} tableService={tableService} />
      </BaseTable>

      <DevicesTableFilter tableService={tableService} filterSideModelState={filterSideModelState} />
    </>
  );
}
