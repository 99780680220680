import classNames from "classnames";
import { Avatar } from "libs/ui";
import { TableUtils } from "libs/ui/Table/utils";
import { PropsWithChildren, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AvatarPersonData } from "../model";
import { TableCardDescription } from "../TableCardDescription";
import "./TableCardPersonAvatar.scss";
import { UserRoleType } from "models/PersonModels";

interface UserData extends AvatarPersonData {
  mrn?: string;
  role?: UserRoleType;
}

interface TableCardPersonAvatarProps {
  show?: boolean;
  userData?: UserData;
  withFullName?: boolean;
  loading?: boolean;
}

export function TableCardPersonAvatar({
  show,
  userData,
  withFullName = true,
  loading,
  children,
}: PropsWithChildren<TableCardPersonAvatarProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "TableCardPersonAvatar" });
  const tableRedirection = TableUtils.useTableElementsRedirect();

  const redirectToUser = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!userData) {
        return undefined;
      }

      return tableRedirection({
        event: e,
        path: userData.role === "Patient" ? "patientDetails" : "hcpDetails",
        params: {
          organizationId: userData.organizationId,
          ...(userData.role === "Patient" ? { patientId: userData.id } : { hcpId: userData.id }),
        } as any,
      });
    },
    [tableRedirection, userData],
  );

  if (!show) {
    return null;
  }

  if (!userData || (!userData.id && userData.organizationId)) {
    return <>{children}</>;
  }

  return (
    <div
      className={classNames("TableCardPerson", { "TableCardPerson--full": withFullName })}
      onClick={redirectToUser}
    >
      <Avatar
        className="TableCardPersonAvatar"
        size="small"
        src={userData.src ?? ""}
        avatarLoading={userData.loading ?? loading}
        firstName={userData.name}
        avatarType={userData.role}
        title={userData.name}
      />
      {withFullName ? (
        <div className="TableCardPerson__content">
          <TableCardDescription title={userData.name} loading={loading}>
            {userData.name}
          </TableCardDescription>
          {userData.mrn ? (
            <TableCardDescription
              title={t("mrn", { mrn: userData.mrn })}
              className="TableCardPerson__mrn"
              loading={loading}
            >
              {t("mrn", { mrn: userData.mrn })}
            </TableCardDescription>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
