import { AvatarCropper } from "components/AvatarCropper";
import { AvatarCropperModalProps } from "components/AvatarCropper/model";
import { Avatar, Toolbar, ToolbarButtonProps } from "libs/ui";
import { ChangeEventHandler, PropsWithChildren, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./AvatarGroup.scss";

interface AvatarGroupProps {
  editing: boolean;
  imageSrc?: string;
  firstName?: string;
  loading?: boolean;
  avatarForCreate?: boolean;
  onSave: AvatarCropperModalProps["onSave"];
}

//? TODO this component is used by AddModals too, should we put it in better dir, rather than DetailsPage?
export default function AvatarGroup({
  editing,
  imageSrc,
  loading,
  firstName,
  avatarForCreate,
  onSave,
}: PropsWithChildren<AvatarGroupProps>) {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.DetailsPage.AvatarGroup",
  });
  const { t: tTip } = useTranslation("translation", { keyPrefix: "Tooltip" });
  const [showCropperModal, setShowCropperModal] = useState(false);
  const [image, setImage] = useState("");
  const imageRef = useRef<HTMLInputElement>(null);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    e.preventDefault();

    const files = Array.from(e.target.files ?? []).filter(file => file.type.includes("image/"));

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
      setShowCropperModal(true);
    };

    if (files.length > 0) {
      reader.readAsDataURL(files[0]);
    }
  }, []);

  const onDelete = useCallback(() => {
    setImage("");
    onSave(undefined);
  }, [onSave]);

  const deleteDisabled = !!imageSrc === false;

  const toolbarButtons: ToolbarButtonProps[] = [
    {
      icon: "Upload",
      variant: "grey",
      onClick: () => imageRef.current?.click(),
      tooltip: tTip("uploadImage"),
      testId: "UploadImage__button",
    },
    {
      icon: "Delete",
      variant: "red",
      onClick: onDelete,
      tooltip: tTip("deleteImage"),
      disabled: deleteDisabled,
      testId: "DeleteImage__button",
    },
  ];

  return (
    <div className="PageTemplate DetailsPage AvatarGroup">
      <Avatar
        avatarForCreate={avatarForCreate}
        avatarLoading={loading}
        firstName={firstName}
        size="large"
        src={imageSrc}
        data-testid="UploadAvatar"
      />
      {editing && (
        <>
          <span className="AvatarGroup__supportedFormats">{t("supportedFormats")}</span>
          <Toolbar className="AvatarGroup__toolbar" buttons={toolbarButtons} />
        </>
      )}

      <input
        ref={imageRef}
        hidden
        type="file"
        accept="image/png, image/jpeg"
        onClick={e => (e.currentTarget.value = "")}
        onChange={onChange}
      />
      <AvatarCropper
        srcImg={image}
        onSave={onSave}
        showCropper={showCropperModal}
        setShowCropper={setShowCropperModal}
      />
    </div>
  );
}
