import * as React from "react";
import { SVGProps } from "react";
const SvgPieChart = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25a.75.75 0 0 0-.75.75v10c0 .414.336.75.75.75h10a.75.75 0 0 0 .75-.75A10.75 10.75 0 0 0 12 1.25Zm.75 10V2.78a9.25 9.25 0 0 1 8.47 8.47h-8.47Z"
      fill="currentColor"
    />
    <path
      d="M8.3 3.517a.75.75 0 0 0-.6-1.374 10.75 10.75 0 1 0 14.2 14.04.75.75 0 0 0-1.38-.585A9.25 9.25 0 1 1 8.3 3.518Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPieChart;
