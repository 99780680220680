import { CurrentFormMode } from "models/FormModeModels";

export interface PersonFormFieldsModel<T> {
  readonly editableFields?: (keyof T)[];
  loading?: boolean;
  onSubmit: () => void;
  formMode: CurrentFormMode;
}

export const EVERY_FORM_FIELD_EDITABLE = undefined;
export const DISABLE_FORM_EDITING = [];
